import {ChangeDetectionStrategy, Component, Injector} from '@angular/core';
import {
  ActionButton,
  CorePortalEntityOverviewBaseComponent,
  DatatableActionButton,
  DeleteEntityModel
} from '@nexnox-web/core-portal';
import {AppEntityType, AppPermissions, ErrorCodeDto, Mappers} from '@nexnox-web/core-shared';
import {errorCodeListStore} from '../../store';

@Component({
  selector: 'nexnox-web-connected-error-codes-error-code-list',
  templateUrl: './error-code-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ErrorCodeListComponent extends CorePortalEntityOverviewBaseComponent<ErrorCodeDto> {
  public title = 'tech-portal.connected.error-codes.subtitles.list';
  public createTitle = 'tech-portal.connected.error-codes.actions.create';
  public idProperty = 'errorCodeId';
  public displayProperty = 'displayName';
  public datatableConfigName = 'ErrorCodeDefault';
  public componentId = 'ErrorCodeListComponent';

  constructor(
    protected injector: Injector
  ) {
    super(injector, errorCodeListStore, Mappers.ErrorCodeListDto.serializedName, AppEntityType.None);
  }

  /* istanbul ignore next */
  public getRowActionButtons(): DatatableActionButton[] {
    return this.getDefaultRowActionButtons(
      'tech-portal.connected.error-codes.actions.edit',
      (row: ErrorCodeDto) => `/connected/error-codes/${ row.errorCodeId }`,
      [AppPermissions.UpdateErrorCode],
      {
        module: 'settings'
      }
    );
  }

  public getDeleteEntityModel(): DeleteEntityModel {
    return {
      titleKey: 'tech-portal.connected.error-codes.actions.delete',
      descriptionKey: 'tech-portal.connected.error-codes.descriptions.delete',
      deletePermission: AppPermissions.DeleteErrorCode
    };
  }

  protected async getActionButtons(): Promise<ActionButton[]> {
    return this.getDefaultActionButtons(
      'tech-portal.connected.error-codes.actions.create',
      AppPermissions.CreateErrorCode
    );
  }
}
