import {ChangeDetectionStrategy, Component, Injector} from '@angular/core';
import {
  ActionButton,
  CorePortalEntityOverviewBaseComponent,
  DatatableActionButton,
  DeleteEntityModel
} from '@nexnox-web/core-portal';
import {AppEntityType, AppPermissions, Mappers, TaskJobStateReasonSetDto} from '@nexnox-web/core-shared';
import {cancellationReasonsListStore} from '../../store';

@Component({
  selector: 'nexnox-web-cancellation-reasons-cancellation-reasons-list',
  templateUrl: './cancellation-reasons-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CancellationReasonsListComponent extends CorePortalEntityOverviewBaseComponent<TaskJobStateReasonSetDto> {
  public title = 'orga-portal.cancellation-reasons.subtitles.cancellation-reasons-list';
  public createTitle = 'orga-portal.cancellation-reasons.actions.create-cancellation-reasons';
  public idProperty = 'taskJobStateReasonSetId';
  public displayProperty = 'title';
  public datatableConfigName = 'CancellationReasonsDefault';
  public componentId = 'CancellationReasonsListComponent';

  constructor(
    protected injector: Injector
  ) {
    super(injector, cancellationReasonsListStore, Mappers.TaskJobStateReasonSetListDto.serializedName, AppEntityType.None);
  }

  public getRowActionButtons(): DatatableActionButton[] {
    return this.getDefaultRowActionButtons(
      'orga-portal.cancellation-reasons.actions.edit-cancellation-reasons',
      (row: TaskJobStateReasonSetDto) => `cancellation-reasons/${ row.taskJobStateReasonSetId }`,
      [AppPermissions.UpdateTaskJobStateReasonSet],
      {
        module: 'settings'
      }
    );
  }

  public getDeleteEntityModel(): DeleteEntityModel {
    return {
      titleKey: 'orga-portal.cancellation-reasons.actions.delete-cancellation-reasons',
      descriptionKey: 'orga-portal.cancellation-reasons.descriptions.delete-cancellation-reasons',
      confirmKey: 'orga-portal.cancellation-reasons.actions.delete-cancellation-reasons',
      deletePermission: AppPermissions.DeleteTaskJobStateReasonSet
    };
  }

  protected async getActionButtons(): Promise<ActionButton[]> {
    return this.getDefaultActionButtons(
      'orga-portal.cancellation-reasons.actions.create-cancellation-reasons',
      AppPermissions.CreateTaskJobStateReasonSet
    );
  }
}
