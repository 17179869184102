import {dashboardDetailStore, DashboardDetailStoreState, dashboardListStore, DashboardListStoreState} from './stores';

export interface DashboardsState {
  dashboardList: DashboardListStoreState;
  dashboardDetail: DashboardDetailStoreState;
}

export const getInitialDashboardsState = (): DashboardsState => ({
  dashboardList: dashboardListStore.getInitialState(),
  dashboardDetail: dashboardDetailStore.getInitialState()
});
