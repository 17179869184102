import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  QueryList,
  ViewChildren
} from '@angular/core';
import {HierarchicalTreeViewItem} from '../../models';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {HierarchicalTreeViewItemComponent} from '../hierarchical-tree-view-item/hierarchical-tree-view-item.component';
import {Paging, ViewChildrenHelper} from '@nexnox-web/core-shared';
import {filter, scan, take} from 'rxjs/operators';
import {faPlus} from '@fortawesome/free-solid-svg-icons/faPlus';

@Component({
  selector: 'nexnox-web-hierarchical-tree-view',
  templateUrl: './hierarchical-tree-view.component.html',
  styleUrls: ['./hierarchical-tree-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HierarchicalTreeViewComponent implements AfterViewInit {
  @Input() public paging: Paging;
  @Input() public loading: boolean;
  @Input() public nextLoading: boolean;
  @Input() public canSelectItem: () => Promise<void>;
  @Input() public disabled: boolean;
  @ViewChildren('hierarchicalTreeViewItemComponent') public treeViewItemComponents: QueryList<HierarchicalTreeViewItemComponent>;
  @Output() public selectItem: EventEmitter<HierarchicalTreeViewItemComponent> = new EventEmitter<HierarchicalTreeViewItemComponent>();
  @Output() public expandItem: EventEmitter<HierarchicalTreeViewItemComponent> = new EventEmitter<HierarchicalTreeViewItemComponent>();
  @Output() public moreItem: EventEmitter<HierarchicalTreeViewItemComponent> = new EventEmitter<HierarchicalTreeViewItemComponent>();
  @Output() public moreRoot: EventEmitter<void> = new EventEmitter<void>();
  public itemSelectedEvent = new Subject<HierarchicalTreeViewItem>();
  public componentsLoaded$: Observable<boolean>;
  public faPlus = faPlus;
  private componentsLoaded = new BehaviorSubject<boolean>(false);

  private _treeViewItems: HierarchicalTreeViewItem[];

  public get treeViewItems(): HierarchicalTreeViewItem[] {
    return this._treeViewItems;
  }

  @Input()
  public set treeViewItems(treeViewItems: HierarchicalTreeViewItem[]) {
    this.componentsLoaded.next(false);
    this.childrenLoadedListener();
    this._treeViewItems = treeViewItems;
  }

  public ngAfterViewInit(): void {
    this.componentsLoaded$ = this.componentsLoaded.asObservable();
    this.childrenLoadedListener();
  }

  public selectFromIds(ids: number[], expandLast: boolean = false): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.getAllComponents(ids).then(components => {
        if (!components || !components.length) {
          reject();
        }

        for (let i = 0; i < components.length; i++) {
          const component = components[i];
          const isLast = i === components.length - 1;

          if (component.item.hasChildren && (isLast && expandLast || !isLast)) {
            component.onExpandChildren(false);
          }

          if (isLast) {
            component.onSelect(false);
            resolve();
          }
        }
      }).catch(() => reject());
    });
  }

  public collapseAll(exceptIds: number[] = []): void {
    for (const treeViewItemComponent of this.treeViewItemComponents) {
      if (!exceptIds.includes(treeViewItemComponent.item.id)) {
        treeViewItemComponent.onCollapseChildren();
      }

      this.collapseAllChildren(treeViewItemComponent.children, exceptIds);
    }
  }

  public onSelectItem(selectedItemComponent: HierarchicalTreeViewItemComponent): void {
    this.itemSelectedEvent.next(selectedItemComponent.item);
    this.selectItem.emit(selectedItemComponent);
  }

  public onDeselect(): void {
    this.itemSelectedEvent.next(null);
  }

  public onExpandItem(expandedItemComponent: HierarchicalTreeViewItemComponent): void {
    this.expandItem.emit(expandedItemComponent);
  }

  public onMoreItem(moreItemComponent: HierarchicalTreeViewItemComponent): void {
    this.moreItem.emit(moreItemComponent);
  }

  public onMore(): void {
    this.moreRoot.emit();
  }

  public trackItemsById(index: number, item: HierarchicalTreeViewItem): number {
    return item.id;
  }

  private childrenLoadedListener(): void {
    ViewChildrenHelper.untilViewChildrenLoaded$(this.treeViewItemComponents).pipe(
      scan((all, current) => [...all, current], []),
      filter(all => all.length === this.treeViewItems.length)
    ).subscribe(() => this.componentsLoaded.next(true));
  }

  private collapseAllChildren(children: QueryList<HierarchicalTreeViewItemComponent>, exceptIds: number[] = []): void {
    for (const child of children) {
      if (!exceptIds.includes(child.item.id)) {
        child.onCollapseChildren();
      }

      this.collapseAllChildren(child.children, exceptIds);
    }
  }

  private getAllComponents(ids: number[]): Promise<HierarchicalTreeViewItemComponent[]> {
    return new Promise<HierarchicalTreeViewItemComponent[]>(async (resolve, reject) => {
      try {
        const firstComponent = await this.getFirstComponent(ids);
        resolve(await this.getMergedComponents(ids, firstComponent));
      } catch (_) {
        return reject();
      }
    });
  }

  private getFirstComponent(ids: number[]): Promise<HierarchicalTreeViewItemComponent> {
    return new Promise<HierarchicalTreeViewItemComponent>(async (resolve, reject) => {
      if (!this.treeViewItems || !this.treeViewItems.length) {
        return reject();
      }

      const treeViewItemComponents = await ViewChildrenHelper.untilAllViewChildrenLoaded$<HierarchicalTreeViewItemComponent>(
        this.treeViewItemComponents,
        this.treeViewItems.length
      ).toPromise();
      const firstComponent = treeViewItemComponents.find(x => x && x.item && x.item.id === ids[0]);

      if (firstComponent) {
        return resolve(firstComponent);
      }

      reject();
    });
  }

  private getMergedComponents(
    ids: number[],
    firstComponent: HierarchicalTreeViewItemComponent
  ): Promise<HierarchicalTreeViewItemComponent[]> {
    const components: HierarchicalTreeViewItemComponent[] = [];
    components.push(firstComponent);

    return new Promise<HierarchicalTreeViewItemComponent[]>(async (resolve, reject) => {
      for (let i = 0; i < ids.length; i++) {
        const id = ids[i];

        if (i === 0) {
          continue;
        }

        try {
          if (components[i - 1].item.hasChildren) {
            const itemChildren = await components[i - 1].item.getChildren().pipe(
              take(1)
            ).toPromise();

            const componentChildren = await ViewChildrenHelper.untilAllViewChildrenLoaded$<HierarchicalTreeViewItemComponent>(
              components[i - 1].children,
              itemChildren.length
            ).toPromise();

            if (!componentChildren) {
              return reject();
            }

            const nextComponent = componentChildren.find(x => x && x.item && x.item.id === id);
            if (!nextComponent) {
              return reject();
            }

            components.push(nextComponent);
          }
        } catch (error) {
          return reject(error);
        }
      }

      resolve(components);
    });
  }
}
