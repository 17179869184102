import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRouteSnapshot} from '@angular/router';
import {Observable, of} from 'rxjs';
import {map, withLatestFrom} from 'rxjs/operators';
import {CoreSharedBreadcrumb, CoreSharedBreadcrumbsResolver} from '../../modules';
import {AppPermissions, CoreSharedLocalStorageService, I18nBreadcrumbResolver} from "@nexnox-web/core-shared";
import {EntityXsStore} from "@nexnox-web/core-store";
import {select, Store} from "@ngrx/store";
import {isArray, isString} from "lodash";


// Usage is within data
// data: {
//   breadcrumbs: I18nEntityBreadcrumbResolver,
//   text: 'core.shared.fields.name'
//   entityStore: companyDetailStore,
export interface I18nEntityBreadcrumbConfig {
  text: string; // i18n key
  permissions?: AppPermissions[];
  entityStore: EntityXsStore<any>;
  displayProperty: string;
  idProperty: string;
}

@Injectable()
export class I18nEntityBreadcrumbResolver extends CoreSharedBreadcrumbsResolver {
  constructor(
    private store: Store,
    private translate: TranslateService,
    private i18nBreadcrumbResolver: I18nBreadcrumbResolver,
    private localStorage: CoreSharedLocalStorageService
  ) {
    super();
  }

  public resolve(route: ActivatedRouteSnapshot): Observable<CoreSharedBreadcrumb[]> {

    const config = route.data as I18nEntityBreadcrumbConfig;
    const entityId = route.paramMap.get(config.idProperty);
    const fullPath = this.getFullPath(route);

    if (this.isBreadcrumbStored(fullPath)) {
      return of([{
        path: fullPath,
        text: this.getLocalBreadcrumb(fullPath),
        permissions: config.permissions
      }]);
    }

    if (route?.paramMap?.has(config.idProperty)) {
      return this.store.pipe(
        select(config.entityStore.selectors.selectEntity),
        withLatestFrom(this.translate.stream(config.text)),
        map(([entity, translatedText]) => {
          // Get name / title of entity
          const displayText = entity[config.displayProperty];
          // Store local
          if (displayText) this.storeLocal(fullPath, displayText);
          // Map breadcrumb
          return [{
            path: fullPath,
            text: displayText ?? translatedText,
            permissions: config.permissions
          }]
        })
      )
    }
    // Fallback
    return this.i18nBreadcrumbResolver.resolve(route);
  }

  public getLocalBreadcrumb(identifier: string): string {
    return this.getLocalBreadcrumbs().get(identifier)
  }

  private isBreadcrumbStored(identifier): boolean {
    return isString(this.getLocalBreadcrumbs().get(identifier));
  }

  private getLocalBreadcrumbs(): Map<string, string> {
    const breadcrumbs = this.localStorage.get('breadcrumbs');
    return this.getMap(breadcrumbs);
  }

  private storeLocal(identifier: string, value: string): void {
    let data = this.localStorage.get('breadcrumbs');
    if (!isArray(data)) data = [];
    data = [...data, [identifier, value]];
    this.localStorage.set('breadcrumbs', Array.from(this.getMap(data)));
  }

  private getMap(data?: [[string, string]]): Map<string, string> {
    try {
      return new Map(data);
    } catch (error) {
      return new Map();
    }
  }
}
