import {ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {AppPermissions, TenantInfoDto, UnsubscribeHelper} from '@nexnox-web/core-shared';
import {SidebarItem, SidebarModule} from '../../models';
import {faUser} from '@fortawesome/free-solid-svg-icons/faUser';
import {CORE_PORTAL_SIDEBAR_ITEMS} from '../../tokens';
import {BehaviorSubject, combineLatest, Observable, of} from 'rxjs';
import {map, startWith, switchMap, withLatestFrom} from 'rxjs/operators';
import {Router} from '@angular/router';
import {CorePortalAuthService, CorePortalCurrentModuleService, CorePortalPermissionService} from '../../../../services';
import {
  authStore,
  CORE_PORTAL_FEATURE_MODULES,
  CorePortalFeatureModule,
  CorePortalSidebarService,
  CorePortalTenantRouter
} from "@nexnox-web/libs/core-portal/src";
import {faCog} from '@fortawesome/free-solid-svg-icons/faCog';
import {CorePortalSettingsMenuService, SidebarSettingsItemDto} from "@nexnox-web/core-portal/features/settings/menu";
import {Store} from "@ngrx/store";

@Component({
  selector: 'nexnox-web-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss', './../sidebar-item/sidebar-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidebarComponent extends UnsubscribeHelper implements OnInit {

  @Input() public isOpen: boolean;
  @Input() public logoPath: string;
  @Input() public loggedIn: boolean;
  @Input() public selectedTenant: TenantInfoDto;
  @Input() public checkPermissionFn: (permission: AppPermissions) => Observable<boolean>;
  @Input() public isModuleMenuExpanded = false;

  @Output() public isOpenChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  public sidebarContent$: Observable<SidebarModule[]>;

  public mode: 'over' | 'push' | 'slide' = 'push';

  public trackItemsBy: any;
  public modules$: Observable<CorePortalFeatureModule[]>;
  public settingsItem$: Observable<SidebarSettingsItemDto>;

  public showTrialVersionBanner$: Observable<boolean>;
  public trialVersionExpiry$: Observable<string>;

  public faUser = faUser;
  public faCog = faCog;

  public isAnimating$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    @Inject(CORE_PORTAL_SIDEBAR_ITEMS) private sidebarItems: SidebarItem[],
    private store: Store,
    private router: Router,
    private authService: CorePortalAuthService,
    private currentModuleService: CorePortalCurrentModuleService,
    private tenantRouter: CorePortalTenantRouter,
    private permissionService: CorePortalPermissionService,
    private sidebarService: CorePortalSidebarService,
    private settingsMenuService: CorePortalSettingsMenuService
  ) {
    super();

    this.trackItemsBy = (index: number, item: SidebarItem) => `${ item.module }-${ item.path }`;

    this.modules$ = combineLatest(CORE_PORTAL_FEATURE_MODULES.map(module => of(module).pipe(
      switchMap(module => (module.permissions ? this.permissionService.hasPermissions$(module.permissions) : of(true)).pipe(
        map(hasPermission => ({ module, hasPermission }))
      )),
      map(({ module, hasPermission }) => ({ ...module, hide: !hasPermission })), // hide by permission
      map((module) => ({ ...module, hide: module.path === 'settings' ? true : module.hide })) // do not display settings
    )));
  }

  public ngOnInit(): void {

    this.sidebarContent$ = this.modules$.pipe(
      map(modules => this.sidebarService.mapSidebarContent(this.sidebarItems, modules)),
      map(modules => this._expandActiveModule(modules))
    );

    this.settingsItem$ = this.settingsMenuService.getSettingsItem$();

    this.showTrialVersionBanner$ = this.store.select(authStore.selectors.selectActiveTenant).pipe(
      withLatestFrom(this.permissionService.hasPermission$(AppPermissions.ShowExpiredSubscription)),
      map(([tenant, canShow]) => this.authService.hasTrialSubscriptions(tenant) && canShow)
    );

    this.trialVersionExpiry$ = this.store.select(authStore.selectors.selectActiveTenant).pipe(
      map(tenant => this.authService.getShortestSubscriptionExpiry(tenant))
    );

    this._subscribeToMediaQuery();
    this._subscribeToRouteChange();
  }

  public onOpenedChanged(opened: boolean): void {
    this.isOpenChanged.emit(opened);
  }

  public toggleModule(module: SidebarModule, allModules: SidebarModule[]): void {
    const expanded = module.expanded;
    this.isAnimating$.next(true);

    // open active item
    module.items.map(item => item.expanded = this.tenantRouter.isActive(item.path, false));

    // set max-height value by opened items
    module.maxHeight = this.sidebarService.getModuleProcessHeight(module);

    // toggle module accordion
    allModules.map(module => module.expanded = false);
    module.expanded = !expanded;

    // trigger when animation finished
    setTimeout(() => {
      this.isAnimating$.next(false);

      // set complete max-height
      module.maxHeight = this.sidebarService.getModuleMaxHeight(module);

    }, 700) // css transition time
  }

  public onItemClicked(isRouteChange: boolean): void {
    const matchesLG = window.matchMedia('(max-width: 1199px)').matches;
    if (matchesLG && isRouteChange) {
      this.sidebarService.setOpen(false);
    }
  }

  private _subscribeToMediaQuery(): void {
    const matchesLG = window.matchMedia('(max-width: 1199px)');
    this._checkSidebarMode(matchesLG.matches);

    setTimeout(() => {
      if (matchesLG.matches) {
        this.isOpenChanged.emit(false);
      }
    });

    matchesLG.onchange = event => this._checkSidebarMode(event.matches);
  }

  private _subscribeToRouteChange(): void {
    this.subscribe(this.router.events.pipe(startWith(undefined)), () => {
      const matchesLG = window.matchMedia('(max-width: 1199px)').matches;
      if (matchesLG) this.isOpenChanged.next(false);
    });
  }

  private _checkSidebarMode(matchesLG: boolean): void {
    this.mode = matchesLG ? 'over' : 'push';

    if (this.mode === 'push') {
      this.isOpenChanged.emit(true);
    }
  }

  private _expandActiveModule(modules: SidebarModule[]): SidebarModule[] {
    return modules.map(module => ({ ...module, expanded: this.currentModuleService.getModule() === module.path }));
  }
}
