import {ModuleWithProviders, NgModule} from '@angular/core';
import {StateMachinesStoreModule} from './state-machines-store.module';
import {StateMachinesRoutingModule} from './state-machines-routing.module';
import {StateMachinesDeclarationsModule} from './state-machines-declarations.module';

@NgModule({
  imports: [
    StateMachinesDeclarationsModule,
    StateMachinesRoutingModule,
    StateMachinesStoreModule
  ]
})
export class TechPortalFeatureTicketSettingsStateMachinesModule {
  public static forFeature(): ModuleWithProviders<StateMachinesDeclarationsModule> {
    return { ngModule: StateMachinesDeclarationsModule };
  }
}
