<p-table [columns]="columns"
         [loadingIcon]="faSpinner | nexnoxWebFaIconStringPipe:'fa-spin'"
         [loading]="loading"
         [ngClass]="''"
         [resizableColumns]="true"
         [responsive]="true"
         [tableStyle]="{'width': '100%'}"
         [value]="rows"
         columnResizeMode="expand">
  <ng-template let-columns pTemplate="header">
    <tr>
      <th>
        <div class="row">
          <div class="col-10">
            <div *ngIf="labelDisplayMode === labelModes.Regular" class="row">
              <div *ngFor="let head of header" [class]="head.class">
                {{ head.label | translate }}
              </div>
            </div>
            <div *ngIf="labelDisplayMode === labelModes.Complex" class="row">
              <div class="col-12">
                {{ complexHeader| translate }}
              </div>
            </div>
          </div>
          <div class="col-2 text-right">
            {{ 'core-shared.shared.table.actions' | translate }}
          </div>
        </div>
      </th>
    </tr>
  </ng-template>
  <ng-template let-columns="columns" let-rowData let-rowIndex="rowIndex" pTemplate="body">
    <tr>
      <td>
        <nexnox-web-formly-table-row (deleteRow)="onDeleteRow($event)"
                                     (modelChange)="onModelChange($event)"
                                     (validChange)="onRowValidityChange($event)"
                                     [fields]="rowData?.fields"
                                     [labelDisplayMode]="labelDisplayMode"
                                     [localId]="rowData?.localId"
                                     [model]="rowData?.model">
        </nexnox-web-formly-table-row>
      </td>
    </tr>
  </ng-template>

</p-table>
<div [class.createRow]="labelDisplayMode === labelModes.Complex" class="p-3">
  <nexnox-web-formly-table-row (addRow)="onAddRow($event)"
                               [fields]="fields"
                               [labelDisplayMode]="labelDisplayMode">
  </nexnox-web-formly-table-row>
</div>

