import {
  CorePortalFeatureAuditListXsStore,
  CorePortalFeatureAuditListXsStoreState
} from '@nexnox-web/core-portal/features/audit';
import {Action, createSelector} from '@ngrx/store';
import {selectMissionsState} from '../../missions.selectors';
import {Injectable, Injector} from '@angular/core';

export interface MissionAuditStoreState extends CorePortalFeatureAuditListXsStoreState {
}

/* istanbul ignore next */
export const missionAuditStore = new CorePortalFeatureAuditListXsStore({
  actionLabel: 'Tech Portal - Missions - Mission Audit',
  stateSelector: createSelector(selectMissionsState, state => state.missionAudit)
});

export function missionAuditStoreReducer(state: MissionAuditStoreState, action: Action): any {
  return missionAuditStore.reducer(state, action);
}

@Injectable()
export class MissionAuditStoreEffects extends missionAuditStore.effects {
  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }
}
