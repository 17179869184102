<nexnox-web-modal #modalComponent
                  [cancelButtonText]="'core-portal.core.general.cancel' | translate"
                  [confirmButtonText]="'core-shared.shared.custom-property-signature.confirm' | translate"
                  [preConfirmFn]="preConfirmFn"
                  [title]="'core-shared.shared.custom-property-signature.title' | translate">
  <div class="d-flex flex-row mw-100">
    <div #signaturePadWrapper
         [class.is-blocked]="isUploading$ | async"
         class="signature-pad-wrapper w-100">
      <signature-pad #signaturePad
                     (onEndEvent)="onDrawComplete()"
                     [options]="signaturePadOptions">
      </signature-pad>
    </div>

    <div class="d-flex flex-column ml-2">
      <button (click)="onClear()"
              [disabled]="isEmpty$ | async"
              [icon]="faTrashAlt | nexnoxWebFaIconStringPipe"
              [title]="'core-shared.shared.select.clear' | translate"
              class="p-button-secondary p-button-text p-button-icon-only"
              pButton
              type="button">
      </button>
    </div>
  </div>

</nexnox-web-modal>
