import {Injectable} from '@angular/core';

export interface RGB {
  b: number;
  g: number;
  r: number;
}

export interface HSL {
  h: number;
  l: number;
  s: number;
}

@Injectable()
export class CoreSharedColorUtilityService {

  public componentToHex(c): string {
    const hex = c.toString(16);
    return hex.length === 1 ? `0${ hex }` : hex;
  }

  public expandHex(hex: string): string {
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, (_m, r, g, b) => {
      return r + r + g + g + b + b;
    });

    return `#${ hex.replace('#', '') }`;
  }

  public hexToRGB(hex: string): RGB {
    hex = this.expandHex(hex);
    hex = hex.replace('#', '');
    const intValue: number = parseInt(hex, 16);

    return {
      r: (intValue >> 16) & 255,
      g: (intValue >> 8) & 255,
      b: intValue & 255
    };
  }

  public hslToRGB({ h, s, l }: HSL): RGB {
    h = h / 360;
    s = s / 100;
    l = l / 100;
    if (s === 0) {
      l = Math.round(l * 255);
      return {
        r: l,
        g: l,
        b: l
      };
    }

    // tslint:disable-next-line:no-shadowed-variable
    const hue2rgb = (p, q, t): number => {
        if (t < 0) t += 1;
        if (t > 1) t -= 1;
        if (t < 1 / 6) return p + (q - p) * 6 * t;
        if (t < 1 / 2) return q;
        if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
        return p;
      },
      q = l < 0.5 ? l * (1 + s) : l + s - l * s,
      p = 2 * l - q,
      r = hue2rgb(p, q, h + (1 / 3)),
      g = hue2rgb(p, q, h),
      b = hue2rgb(p, q, h - (1 / 3));

    return {
      r: Math.round(r * 255),
      g: Math.round(g * 255),
      b: Math.round(b * 255)
    };
  }

  public rgbToHex({ r, g, b }: RGB): string {
    return '#' + this.componentToHex(r) + this.componentToHex(g) + this.componentToHex(b);
  }

  public rgbToHSL({ r, g, b }: RGB): HSL {
    r = Math.max(Math.min(r / 255, 1), 0);
    g = Math.max(Math.min(g / 255, 1), 0);
    b = Math.max(Math.min(b / 255, 1), 0);
    const max = Math.max(r, g, b),
      min = Math.min(r, g, b),
      l = Math.min(1, Math.max(0, (max + min) / 2));
    let d, h, s;

    if (max !== min) {
      d = max - min;
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
      if (max === r) {
        h = (g - b) / d + (g < b ? 6 : 0);
      } else if (max === g) {
        h = (b - r) / d + 2;
      } else {
        h = (r - g) / d + 4;
      }
      h = h / 6;
    } else {
      h = s = 0;
    }
    return {
      h: Math.round(h * 360),
      s: Math.round(s * 100),
      l: Math.round(l * 100)
    };
  }

  public hslToHEX(hsl: HSL): string {
    return this.rgbToHex(this.hslToRGB(hsl));
  }

  public hexToHSL(hex: string): HSL {
    return this.rgbToHSL(this.hexToRGB(hex));
  }

  public rgbToYIQ({ r, g, b }: RGB): number {
    return ((r * 299) + (g * 587) + (b * 114)) / 1000;
  }

  public isDarkContrast(hex: string, threshold = 128): boolean {
    return this.rgbToYIQ(this.hexToRGB(hex)) >= threshold;
  }
}
