import {Directive, EventEmitter, Input, Output, TemplateRef} from '@angular/core';

@Directive({
  selector: '[nexnoxWebTabTemplate]'
})
export class TabTemplateDirective {
  @Input() public tabId: string;
  @Input() public heading: string;
  @Input() public hasError: boolean;
  @Input() public lazy: boolean;
  @Input() public hide: boolean;

  @Output() public selectTab: EventEmitter<void> = new EventEmitter<void>();

  public wasActive = false;

  constructor(
    public template: TemplateRef<any>
  ) {
  }
}
