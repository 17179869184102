<nexnox-web-cardbox [indicatorNum]="(missions$ | async)?.length"
                    [isAccordion]="true"
                    [isAutoExpandOnIndicator]="true"
                    [isCollapsed]="true"
                    [loading]="(missions$ | async) === undefined || loading"
                    [title]="'missions.subtitles.missions' | translate"
                    [transparent]="true"
                    paddingClasses="px-3">
  <div class="d-flex flex-column pb-2">
    <div *ngFor="let mission of missions$ | async" class="d-flex flex-row mb-2">
      <fa-icon [fixedWidth]="true" [icon]="faClipboardCheck" class="mr-2" size="sm"></fa-icon>
      <a [nexnoxWebTenantRouterLink]="['/missions', mission.missionId]"
         class="btn-link"
         module="inventory"
         target="_blank">
        {{ mission.title }}
      </a>
    </div>
    <div *ngIf="!(missions$ | async)?.length" class="mb-2">{{ 'core-shared.shared.table.empty' | translate }}</div>
  </div>
</nexnox-web-cardbox>
