import {ChangeDetectionStrategy, Component, Injector, OnInit} from '@angular/core';
import {missionMyRequestsStore} from '../../store';
import {CorePortalEntityOverviewBaseComponent, DatatableActionButton} from "@nexnox-web/core-portal";
import {AppEntityType, Mappers, OpenEditorRequestOnContactListDto} from "@nexnox-web/core-shared";
import {faTimesCircle} from "@fortawesome/free-solid-svg-icons/faTimesCircle";
import {faCheckCircle} from "@fortawesome/free-solid-svg-icons/faCheckCircle";

@Component({
  selector: 'nexnox-web-missions-my-requests',
  templateUrl: './mission-my-requests.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MissionMyRequestsComponent extends CorePortalEntityOverviewBaseComponent<OpenEditorRequestOnContactListDto> implements OnInit {
  public componentId = 'MissionMyRequestsComponent';
  public idProperty = 'missionId';
  public title = 'missions.subtitles.my-requests';
  public displayProperty = 'mission';
  public datatableConfigName = 'MyRequestsDefault';

  public canDelete = false;
  public canCreate = false;

  constructor(
    protected injector: Injector,
  ) {
    super(injector, missionMyRequestsStore, Mappers.OpenEditorRequestOnContactListDto.serializedName, AppEntityType.None);
  }

  public ngOnInit(): void {
    super.ngOnInit();

  }

  public getRowActionButtons(): DatatableActionButton[] {
    return [
      {
        isolate: false,
        tooltip: 'missions.actions.mission-accept',
        icon: faCheckCircle,
        onClick: (row) => this.onAcceptMission(row.missionId, row.contactId)
      },
      {
        isolate: false,
        tooltip: 'missions.actions.mission-decline',
        icon: faTimesCircle,
        onClick: (row) => this.onDeclineMission(row.missionId, row.contactId)
      }
    ];
  }

  public onAcceptMission(missionId: number, contactId: number): void {
    this.store.dispatch(missionMyRequestsStore.actions.acceptMission({ missionId, contactId }));
  }

  public onDeclineMission(missionId: number, contactId: number): void {
    this.store.dispatch(missionMyRequestsStore.actions.declineMission({ missionId, contactId }));
  }
}
