<nexnox-web-tabs [internal]="true"
                 [isTransparent]="true"
                 startTab="archive">
  <!-- Archive Tab -->
  <ng-template [heading]="'orga-portal.tasks.subtitles.archive' | translate"
               [lazy]="true"
               nexnoxWebTabTemplate
               tabId="archive">
    <nexnox-web-tasks-task-archive-list *ngIf="taskId"
                                        [canCreate]="false"
                                        [getEntityOnModeChange]="false"
                                        [isCollection]="isCollection"
                                        [isEmbedded]="true"
                                        [taskId]="taskId">
    </nexnox-web-tasks-task-archive-list>
  </ng-template>

  <!-- Active Tab -->
  <ng-template [heading]="'orga-portal.tasks.subtitles.active' | translate"
               [lazy]="true"
               nexnoxWebTabTemplate
               tabId="active">
    <nexnox-web-tasks-task-active-list *ngIf="taskId"
                                       [canCreate]="false"
                                       [getEntityOnModeChange]="false"
                                       [isCollection]="isCollection"
                                       [isEmbedded]="true"
                                       [taskId]="taskId">
    </nexnox-web-tasks-task-active-list>
  </ng-template>
</nexnox-web-tabs>
