<p-sidebar #sidebarComponent
           (visibleChange)="onVisibleChange($event)"
           [transitionOptions]="transitionOptions"
           [visible]="visible$ | async"
           appendTo="body"
           position="right"
           styleClass="p-sidebar-xl">
  <div *ngIf="(loading$ | async) === false; else loadingTemplate"
       class="d-flex align-content-between flex-column h-100">
    <div class="w-100 flex-grow-1 sidebar-form-container">
      <div class="row">
        <div class="col-12 mb-2">
          <h5>{{ 'core-shared.shared.skeletons.skeleton' | translate }}</h5>
        </div>
      </div>
      <div class="row w-100">
        <form [formGroup]="form" class="w-100">
          <formly-form (modelChange)="onModelChange($event)"
                       [(model)]="model"
                       [fields]="fields"
                       [form]="form"
                       class="row w-100">
          </formly-form>
        </form>

      </div>
      <div class="row mt-3">
        <div class="col-12 mb-3">
          <h5 class="mb-3">{{ 'core-shared.shared.fields.resources' | translate }}</h5>
          <nexnox-web-formly-table (modelChange)="onResourcesChange($event)"
                                   (validChange)="tableValidSubject.next($event)"
                                   [fields]="createSkeletonFields()"
                                   [model$]="skeletons$">
          </nexnox-web-formly-table>
        </div>
      </div>
    </div>


    <div class="w-100 flex-shrink-1 d-flex justify-content-end">
      <button (click)="onHide()"
              [icon]="faTimes | nexnoxWebFaIconStringPipe"
              [label]="'core-portal.core.general.cancel' | translate"
              class="p-button p-button-secondary mr-3"
              pButton
              type="button">
      </button>

      <button (click)="onCreate()"
              [disabled]="isCreateDisabled()"
              [icon]="faUser | nexnoxWebFaIconStringPipe"
              [label]="'core-shared.shared.skeletons.create-resources'  | translate"
              class="p-button p-button-primary"
              pButton
              type="button">
      </button>
    </div>
  </div>
</p-sidebar>

<ng-template #loadingTemplate>
  <nexnox-web-loading-icon class="d-flex w-100 h-100 align-items-center justify-content-center flex-column p-5"
                           size="5x">
  </nexnox-web-loading-icon>
</ng-template>
