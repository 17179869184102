import {FieldType} from '@ngx-formly/core';
import {AfterViewInit, Component, OnDestroy} from '@angular/core';
import {CoreSharedModalService} from '@nexnox-web/core-shared';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {distinctUntilChanged} from 'rxjs/operators';
import {faTrashAlt} from '@fortawesome/free-solid-svg-icons/faTrashAlt';
import {faCheck} from '@fortawesome/free-solid-svg-icons/faCheck';
import {faSpinner} from '@fortawesome/free-solid-svg-icons/faSpinner';
import {FormlySignatureModalComponent} from '../../modals';

@Component({
  selector: 'nexnox-web-formly-signature',
  styleUrls: ['./formly-signature.component.scss'],
  templateUrl: './formly-signature.component.html'
})
export class FormlySignatureComponent extends FieldType implements AfterViewInit, OnDestroy {
  public signatureUrl$: Observable<string>;

  public faTrashAlt = faTrashAlt;
  public faCheck = faCheck;
  public faSpinner = faSpinner;

  private signatureUrlSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  private fieldChangeSubscription: Subscription;

  constructor(
    private modalService: CoreSharedModalService
  ) {
    super();

    this.signatureUrl$ = this.signatureUrlSubject.asObservable();
  }

  public ngAfterViewInit(): void {
    this.fieldChangeSubscription = this.field.options.fieldChanges.pipe(
      distinctUntilChanged()
    ).subscribe(() => {
      if (!this.formControl.value) {
        this.signatureUrlSubject.next(null);
      } else {
        this.signatureUrlSubject.next(this.formControl.value?.uri ?? null);
      }
    });
  }

  public ngOnDestroy(): void {
    if (this.fieldChangeSubscription && !this.fieldChangeSubscription.closed) {
      this.fieldChangeSubscription.unsubscribe();
    }
  }

  public onAddSignature(): void {
    if (this.to.disabled) return;

    this.modalService.showModal(FormlySignatureModalComponent)
      .then(({ value }) => {
        this.signatureUrlSubject.next(value);
        this.formControl.setValue(value);
      })
      .catch(() => null);
  }

  public onClear(): void {
    this.signatureUrlSubject.next(null);
    this.formControl.setValue(null);
  }
}
