import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {
  ClosingLocationOptionsDto,
  CoreSharedSidebarBaseComponent,
  LocationDto,
  LocationSimpleDto
} from '@nexnox-web/core-shared';
import {faTimes} from '@fortawesome/free-solid-svg-icons/faTimes';
import {BehaviorSubject, Observable} from "rxjs";
import {faDoorClosed} from '@fortawesome/free-solid-svg-icons/faDoorClosed';
import {faDoorOpen} from '@fortawesome/free-solid-svg-icons/faDoorOpen';
import {FormlyFieldConfig} from "@ngx-formly/core";
import {
  CorePortalFormlyReadonlyTypes,
  CorePortalFormlyReadonlyTyping,
  noClosedLocationsFilter$
} from "@nexnox-web/core-portal";
import {FormGroup} from "@angular/forms";
import {CorePortalFeatureMasterDataLocationService} from "../../store";
import {filter, take} from "rxjs/operators";

@Component({
  selector: 'nexnox-web-location-status-sidebar',
  templateUrl: './location-status-sidebar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LocationStatusSidebarComponent extends CoreSharedSidebarBaseComponent implements OnInit {
  @ViewChild('locationSelectLabelTitleTemplate', { static: true }) public locationSelectLabelTitleTemplate: TemplateRef<any>;
  @ViewChild('locationSelectOptionTitleTemplate', { static: true }) public locationSelectOptionTitleTemplate: TemplateRef<any>;

  @Input() public isCloseLocation: boolean;
  @Input() public currentLocationId$: Observable<string | number>;

  @Output() public closeLocation: EventEmitter<ClosingLocationOptionsDto> = new EventEmitter<ClosingLocationOptionsDto>();
  @Output() public openLocation: EventEmitter<void> = new EventEmitter<void>();

  public form: FormGroup;
  public model: ClosingLocationOptionsDto;
  public fields: FormlyFieldConfig[];

  public currentLocationId: string | number;

  public loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public faDoorOpen = faDoorOpen;
  public faDoorClosed = faDoorClosed;
  public faTimes = faTimes;

  constructor(private locationService: CorePortalFeatureMasterDataLocationService) {
    super();
  }

  public ngOnInit(): void {
    this.form = new FormGroup({});
    this.loading$.next(true);
    if (this.currentLocationId$) {
      this.subscribe(this.currentLocationId$.pipe(filter(id => id > 0), take(1)), (locationId) => {
        this.currentLocationId = locationId;
        this.fields = this.createForm();
        this.loading$.next(false);
      })
    }
    this.model = {} as any;
  }

  public onShow(isCloseLocation: boolean): void {
    this.isCloseLocation = isCloseLocation;
    this.loading$.next(false);
    super.onShow();
  }

  public onHide(): void {
    this.form.reset();
    super.onHide();
  }

  public onCloseLocation(): void {
    this.loading$.next(true);
    this.closeLocation.emit({
      shouldCancelOpenMissions: this.model.shouldCancelOpenMissions ?? false,
      shouldMoveResources: this.model.shouldMoveResources ?? false,
      shouldMoveResourcesTarget: this.model.shouldMoveResources ? this.mapToSimpleLocation(this.model.shouldMoveResourcesTarget) : null
    });
    this.form.reset();
    this.onHide();
  }

  public onOpenLocation(): void {
    this.loading$.next(true);
    this.openLocation.emit();
    this.form.reset();
    this.onHide();
  }

  /* istanbul ignore next */
  protected createForm(): FormlyFieldConfig[] {
    return [
      {
        key: 'shouldCancelOpenMissions',
        type: 'core-portal-switch',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-12',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-portal.master-data.location.deactivate-options.should-cancel-open-missions'
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.BOOLEAN
          } as CorePortalFormlyReadonlyTyping,
          readonly: false
        }
      },
      {
        key: 'shouldMoveResources',
        type: 'core-portal-switch',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-12',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-portal.master-data.location.deactivate-options.should-move-resources'
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.BOOLEAN
          } as CorePortalFormlyReadonlyTyping,
          readonly: false
        },
      },
      {
        key: 'shouldMoveResourcesTarget',
        type: 'core-portal-entity-select',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-12',
        defaultValue: null,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.location',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.ENTITY,
            displayKey: 'name',
            link: (location: LocationSimpleDto) => location?.locationId ? ['/masterdata', 'locations', location.locationId] : null,
            module: 'management'
          } as CorePortalFormlyReadonlyTyping,
          entityService: this.locationService,
          idKey: 'locationId',
          displayKey: 'name',
          wholeObject: true,
          multiple: false,
          mapSearchFilter: CorePortalFeatureMasterDataLocationService.mapSearchFilter,
          search: CorePortalFeatureMasterDataLocationService.searchCompare,
          selectLabelTitleTemplate: this.locationSelectLabelTitleTemplate,
          selectOptionTitleTemplate: this.locationSelectOptionTitleTemplate,
          showAll: true,
          defaultFilters$: noClosedLocationsFilter$,
          excludedIds: [this.currentLocationId]
        },
        expressionProperties: {
          'templateOptions.required': () => this.model.shouldMoveResources
        },
        hideExpression: () => !this.model.shouldMoveResources
      }
    ];
  }

  private mapToSimpleLocation(location: LocationDto): LocationSimpleDto {
    return {
      locationId: location.locationId,
      locationNumber: location.locationNumber,
      name: location.name,
      externalId: location.externalId
    } as LocationSimpleDto;
  }
}
