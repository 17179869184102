import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChildren
} from '@angular/core';
import {
  AppEntityType,
  AppPermissions,
  CustomPropertyType,
  CustomSetReferenceDto,
  TenantInfoDto
} from '@nexnox-web/core-shared';
import {select, Store} from '@ngrx/store';
import {BehaviorSubject, Observable} from 'rxjs';
import {authStore, CorePortalCardboxAction} from '@nexnox-web/core-portal';
import {faPlus} from '@fortawesome/free-solid-svg-icons/faPlus';
import {map} from 'rxjs/operators';
import {cloneDeep, isNull, isUndefined, maxBy, minBy} from 'lodash';
import {CustomPropertySetEditComponent} from '../custom-property-set-edit/custom-property-set-edit.component';
import {
  CustomPropertySetType,
  LocalCustomPropertySetDto,
  LocalCustomSetReferenceDto,
  LocalStereotypeDto
} from '../../models';
import {BindObservable} from 'bind-observable';
import {cleanUpStereoTypePositions} from "../../functions";

@Component({
  selector: 'nexnox-web-settings-stereotypes-custom-property-sets-edit',
  templateUrl: './custom-property-sets-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomPropertySetsEditComponent implements OnInit {
  @Input() public customPropertyDropdownOptions: { label: string, value: CustomPropertyType }[];
  @Input() public customPropertySetType: CustomPropertySetType;
  @Input() @BindObservable() public readonly: boolean;
  public readonly$!: Observable<boolean>;
  @Input() public loading: boolean;
  @Output() public modelChange: EventEmitter<LocalStereotypeDto> = new EventEmitter<LocalStereotypeDto>();
  @ViewChildren('customPropertySetEditComponent') public customPropertySetEditComponents: QueryList<CustomPropertySetEditComponent>;
  public customPropertySets$: Observable<CustomSetReferenceDto[]>;
  public activeTenant$: Observable<TenantInfoDto>;
  public isRateable$: Observable<boolean>;
  /* istanbul ignore next */
  public headerActions: CorePortalCardboxAction[] = [{
    label: 'core-portal.settings.actions.create-custom-property-set',
    icon: faPlus,
    class: 'btn-outline-primary',
    permission: AppPermissions.CreateCustomPropertySet,
    shouldShow: () => this.readonly$.pipe(
      map(readonly => !readonly)
    ),
    callback: () => this.onAddCustomPropertySet()
  }];
  public trackSetsBy: any;
  private modelSubject: BehaviorSubject<LocalStereotypeDto> = new BehaviorSubject<LocalStereotypeDto>(null);

  constructor(
    private store: Store<any>
  ) {
    this.trackSetsBy = (index: number, customSetReference: LocalCustomSetReferenceDto) =>
      customSetReference.customPropertySetId ?? customSetReference.localId;
  }

  public get model(): LocalStereotypeDto {
    return this.modelSubject.getValue();
  }

  @Input()
  public set model(model: LocalStereotypeDto) {
    this.modelSubject.next(model);
  }

  public ngOnInit(): void {
    this.customPropertySets$ = this.modelSubject.asObservable().pipe(
      map(model => model?.customPropertySets ?? [])
    );
    this.activeTenant$ = this.store.pipe(select(authStore.selectors.selectActiveTenant));

    const rateableEntityTypes = [AppEntityType.MissionInspectionReport];
    this.isRateable$ = this.modelSubject.asObservable().pipe(
      map(model => rateableEntityTypes.includes(model?.entityType))
    );
  }

  public onCustomPropertySetChange(customPropertySet: LocalCustomPropertySetDto, position: number): void {
    const newModel = cloneDeep(this.model);
    const index = newModel.customPropertySets.findIndex(x => x.position === position);
    newModel.customPropertySets[index].customPropertySet = customPropertySet;
    this.modelChange.emit({
      ...this.model,
      customPropertySets: newModel.customPropertySets.map(customSetReference => ({
        ...customSetReference,
        customPropertySet: ({
          ...customSetReference.customPropertySet,
          properties: customSetReference.customPropertySet.properties.map(property => {
            if (property.type === CustomPropertyType.Dropdown) {
              return property;
            }

            let newDefaultValues = [];

            if (property.defaultValues?.length &&
              !isUndefined((property.defaultValues[0] as any).value) &&
              !isNull((property.defaultValues[0] as any).value)) {
              newDefaultValues = property.defaultValues.map(x => ({
                ...x,
                type: property.type
              }));
            }

            return { ...property, defaultValues: newDefaultValues };
          })
        })
      }))
    });
  }

  public onAddCustomPropertySet(): void {
    const newModel = cloneDeep(this.model);

    if (!newModel.customPropertySets) {
      newModel.customPropertySets = [];
    }

    const maxIdSet = newModel.customPropertySets.length ?
      maxBy(newModel.customPropertySets, x => x.customPropertySetId ?? x.localId) : null;
    const localId = (maxIdSet?.customPropertySetId ?? (maxIdSet?.localId ?? 0)) + 1;

    newModel.customPropertySets.push({
      customPropertySet: {
        name: '',
        properties: []
      },
      customSetReferenceAnchorId: localId.toString(),
      localId,
      position: newModel.customPropertySets.length ? maxBy(newModel.customPropertySets, x => x.position).position + 1 : 1
    } as LocalCustomSetReferenceDto);

    this.modelChange.emit({
      ...this.model,
      customPropertySets: newModel.customPropertySets
    });
  }

  public onMoveUp(position: number): void {
    const newModel = cloneDeep(this.model);
    const index = newModel.customPropertySets.findIndex(x => x.position === position);
    const previousIndex = newModel.customPropertySets.findIndex(x => x.position === maxBy(
      newModel.customPropertySets.filter(y => y.position < position), y => y.position
    )?.position);

    newModel.customPropertySets[index].position--;
    newModel.customPropertySets[previousIndex].position++;
    this.modelChange.emit(
      cleanUpStereoTypePositions({
        ...this.model,
        customPropertySets: newModel.customPropertySets
      })
    );
  }

  public onMoveDown(position: number): void {
    const newModel = cloneDeep(this.model);
    const index = newModel.customPropertySets.findIndex(x => x.position === position);
    const nextIndex = newModel.customPropertySets.findIndex(x => x.position === minBy(
      newModel.customPropertySets.filter(y => y.position > position), y => y.position
    )?.position);

    newModel.customPropertySets[nextIndex].position--;
    newModel.customPropertySets[index].position++;
    this.modelChange.emit(
      cleanUpStereoTypePositions({
        ...this.model,
        customPropertySets: newModel.customPropertySets
      })
    );
  }

  public onDelete(position: number): void {
    const newModel = cloneDeep(this.model);
    const index = newModel.customPropertySets.findIndex(x => x.position === position);
    const nextIndexes = newModel.customPropertySets
      .filter(x => x.position > position)
      .map(x => newModel.customPropertySets.findIndex(y => y.position === x.position));

    for (const nextIndex of nextIndexes) {
      newModel.customPropertySets[nextIndex].position--;
    }
    newModel.customPropertySets.splice(index, 1);

    this.modelChange.emit(
      cleanUpStereoTypePositions({
        ...this.model,
        customPropertySets: newModel.customPropertySets
      })
    );
  }

  public isModelValid(): boolean {
    return this.customPropertySetEditComponents.toArray().every(x => x.isModelValid());
  }
}
