import {NgModule} from '@angular/core';
import {inventoryNumbersServices} from './store';
import {CorePortalFeatureInventoryNumberPatternEditComponent} from './components';
import {CoreSharedModule} from '@nexnox-web/core-shared';
import {CorePortalEntityModule} from '@nexnox-web/core-portal';
import {
  InventoryNumberPatternDetailComponent,
  InventoryNumberPatternListComponent,
  ReservationListComponent
} from './containers';
import {ReserveInventoryNumberSidebarComponent} from "./sidebars";

@NgModule({
  declarations: [
    InventoryNumberPatternListComponent,
    InventoryNumberPatternDetailComponent,
    ReservationListComponent,

    CorePortalFeatureInventoryNumberPatternEditComponent,

    ReserveInventoryNumberSidebarComponent
  ],
  imports: [
    CoreSharedModule,
    CorePortalEntityModule
  ],
  exports: [
    CorePortalFeatureInventoryNumberPatternEditComponent
  ],
  providers: [
    ...inventoryNumbersServices
  ]
})
export class InventoryNumbersDeclarationsModule {
}
