import {ChangeDetectionStrategy, Component, Injector, Input, OnInit} from '@angular/core';
import {ContactSimpleDto, CoreSharedModalBaseComponent} from '@nexnox-web/core-shared';
import {FormGroup} from '@angular/forms';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {CorePortalFeatureMasterDataContactService} from '@nexnox-web/core-portal/features/master-data/features/contacts';

@Component({
  selector: 'nexnox-web-tickets-ticket-assign-modal',
  templateUrl: './ticket-assign-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TicketAssignModalComponent extends CoreSharedModalBaseComponent implements OnInit {
  @Input() public service: CorePortalFeatureMasterDataContactService;

  public form: FormGroup;
  public model: { contact: ContactSimpleDto };
  public fields: FormlyFieldConfig[];

  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }

  public ngOnInit(): void {
    super.ngOnInit();

    this.form = new FormGroup({});
    this.fields = this.createForm();
    this.model = {} as any;

    this.subscribe(this.form.valueChanges, () => {
      this.form.valid ? this.enableConfirmButton() : this.disableConfirmButton();
    });
  }

  /* istanbul ignore next */
  protected createForm(): FormlyFieldConfig[] {
    return [
      {
        key: 'contact',
        type: 'core-portal-entity-select',
        wrappers: ['core-portal-translated'],
        className: 'col-md-12 px-0',
        defaultValue: null,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.editor',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          entityService: this.service,
          idKey: 'contactId',
          displayKey: 'displayName',
          clearable: false,
          firstToDefault: true,
          wholeObject: true,
          required: true
        }
      }
    ];
  }

  /* istanbul ignore next */
  protected onPreConfirm(): any {
    return this.model;
  }

  /* istanbul ignore next */
  protected onConfirmButtonAvailable(): void {
    super.onConfirmButtonAvailable();

    this.disableConfirmButton();
  }
}
