import {ChangeDetectionStrategy, Component, Injector, Input, OnInit} from '@angular/core';
import {CorePortalEntityOverviewBaseComponent} from '@nexnox-web/core-portal';
import {AppEntityType, Mappers, Paging, TicketEventCompleteDto} from '@nexnox-web/core-shared';
import {ofType} from '@ngrx/effects';
import {ticketDetailStore, ticketEventsStore} from '../../store';
import {faTrashAlt} from '@fortawesome/free-solid-svg-icons/faTrashAlt';

@Component({
  selector: 'nexnox-web-tickets-ticket-events',
  templateUrl: './ticket-events.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TicketEventsComponent extends CorePortalEntityOverviewBaseComponent<TicketEventCompleteDto> implements OnInit {
  @Input() public loading: boolean;

  public title = 'tickets.subtitles.ticket-events';
  public createTitle = '';
  public idProperty = 'ticketEventId';
  public displayProperty = null;
  public componentId = 'TicketEventsComponent';

  public faTrashAlt = faTrashAlt;

  constructor(
    protected injector: Injector
  ) {
    super(injector, ticketEventsStore, Mappers.TicketEventCompleteDto.serializedName, AppEntityType.None);
  }

  public ngOnInit(): void {
    super.ngOnInit();

    this.subscribe(this.actions$.pipe(
      ofType(ticketDetailStore.actions.changeStateSuccess)
    ), () => this.getEntities());

    this.subscribe(this.actions$.pipe(
      ofType(ticketDetailStore.actions.followUpSuccess)
    ), () => this.getEntities());
  }

  public onMore(paging: Paging): void {
    this.getEntities(
      paging.pageNumber < paging.totalPages ? paging.pageNumber + 1 : paging.pageNumber,
      undefined,
      undefined,
      undefined,
      true
    );
  }

  public onCancelEvent(id: number | string): void {
    // ToDo: Use facade
    this.store.dispatch(ticketEventsStore.actions.cancelOne({ id, parentIds: this.parentIds }));
  }
}
