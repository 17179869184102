import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild} from "@angular/core";
import {FormGroup} from "@angular/forms";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {
  CoreSharedSortableListComponent,
  CoreSharedSortableListItem,
  IssueTemplateSimpleDto,
  SortableListRowAction,
  UnsubscribeHelper
} from "@nexnox-web/core-shared";
import {FormlyFieldConfig} from "@ngx-formly/core";
import {BindObservable} from "bind-observable";
import {cloneDeep, find} from "lodash";
import {BehaviorSubject, Observable} from "rxjs";
import {map} from "rxjs/operators";
import {CorePortalIssueTemplateService} from "@nexnox-web/core-portal/features/settings/features/issue-templates/src/lib/store";
import {CorePortalFormlyReadonlyTypes, CorePortalFormlyReadonlyTyping} from "@nexnox-web/core-portal";

@Component({
  selector: 'nexnox-web-issue-templates-sortable-list-edit',
  templateUrl: 'issue-templates-sortable-list-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IssueTemplatesSortableListEditComponent extends UnsubscribeHelper implements OnInit {
  @Input() public issueTemplatesSubject: BehaviorSubject<IssueTemplateSimpleDto[]>;
  @Input() public loading: boolean;
  @Input() public actions: SortableListRowAction[];

  @Input() @BindObservable() public readonly: boolean;
  public readonly$!: Observable<boolean>;

  @ViewChild('issueTemplatesListEdit') public issueTemplatesListEdit: CoreSharedSortableListComponent;

  @Output() public issueTemplatesChange = new EventEmitter<any>();

  public issueTemplatesItems$: Observable<CoreSharedSortableListItem[]>;

  public addIssueTemplateForm: FormGroup;
  public addIssueTemplateFields: FormlyFieldConfig[];
  public addIssueTemplateModelSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  public faPlus = faPlus;

  constructor(private issueTemplateService: CorePortalIssueTemplateService) {
    super();
  }

  public ngOnInit(): void {
    this.issueTemplatesItems$ = this.issueTemplatesSubject.asObservable().pipe(
      map((issueTemplates: IssueTemplateSimpleDto[]) => this.mapIssueTemplatesToSortableItems(issueTemplates))
    );

    this.addIssueTemplateForm = new FormGroup({});
    this.addIssueTemplateFields = this.createIssueTemplateFormFields();
  }

  public onAddIssueTemplate(): void {
    const issueTemplateToAdd = cloneDeep(this.addIssueTemplateModelSubject.getValue().issueTemplates);
    if (issueTemplateToAdd) {
      this.issueTemplatesSubject.next([
        ...cloneDeep(this.issueTemplatesSubject.getValue()),
        {
          issueTemplateId: issueTemplateToAdd.issueTemplateId,
          name: issueTemplateToAdd.name
        } as IssueTemplateSimpleDto
      ]);

      this.addIssueTemplateModelSubject.next({} as any);
      this.addIssueTemplateForm.reset();
      this.addIssueTemplateForm.markAsUntouched();

      this.issueTemplatesChange.emit(cloneDeep(this.issueTemplatesSubject.getValue()));
    }
  }

  public onReset(): void {
    this.issueTemplatesListEdit.ngOnInit();
  }

  public onIssueTemplatesChange(issueTemplateSortableList: CoreSharedSortableListItem[]): void {
    this.issueTemplatesSubject.next(issueTemplateSortableList.map(issueTemplateSortableItem => ({
      ...issueTemplateSortableItem.getExternalData(),
      position: issueTemplateSortableItem.position
    })));
    this.issueTemplatesChange.emit(cloneDeep(this.issueTemplatesSubject.getValue()));
  }

  public createIssueTemplateFormFields(): any {

    /* istanbul ignore next */
    return [
      {
        key: 'issueTemplates',
        type: 'core-portal-entity-select',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-12',
        defaultValue: null,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-portal.settings.issue-template.titles.detail',
            validationMessages: {
              required: 'core-portal.core.validation.required',
              duplicate: 'core-portal.core.validation.duplicate'
            }
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.ENTITY,
            displayKey: 'name',
            link: (issueTemplate: IssueTemplateSimpleDto) => issueTemplate?.issueTemplateId ? ['settings', 'issues', issueTemplate.issueTemplateId] : null,
            module: 'communication'
          } as CorePortalFormlyReadonlyTyping,
          entityService: this.issueTemplateService,
          idKey: 'issueTemplateId',
          displayKey: 'name',
          skipGetOne: true,
          wholeObject: true,
          clearable: false,
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.readonly': () => this.readonly
        },
        validators: {
          duplicate: control => !find(this.issueTemplatesSubject.getValue(), { issueTemplateId: control?.value?.issueTemplateId })
        }
      }
    ]
  }

  /* istanbul ignore next */
  private mapIssueTemplatesToSortableItems(issueTemplates: IssueTemplateSimpleDto[]): CoreSharedSortableListItem[] {
    return issueTemplates.map((issueTemplate: IssueTemplateSimpleDto) => ({
      title: issueTemplate.name,
      position: undefined,
      getExternalData: () => cloneDeep(issueTemplate)
    }));
  }

}
