<nexnox-web-entity-edit [entityEditBaseComponent]="this"
                        [isTitleEditable]="false"
                        displayKey="name">
</nexnox-web-entity-edit>

<nexnox-web-cardbox [loading]="loading"
                    [title]="'core-portal.settings.subtitles.dashboard-items' | translate"
                    paddingClasses="px-3 pb-3">
  <div class="d-flex flex-row mb-3">
    <div class="d-flex flex-grow-1">
      <nexnox-web-entity-select [(model)]="selectedItem"
                                [disabled]="readonly"
                                [options]="selectOptions"
                                class="w-100">
      </nexnox-web-entity-select>
    </div>

    <button (click)="onAddItem()"
            [disabled]="!selectedItem || readonly"
            class="btn btn-outline-primary ml-3">
      {{ 'core-shared.shared.select.add-item' | translate }}
    </button>
  </div>

  <nexnox-web-sortable-list (itemsChange)="onItemsChange($event)"
                            [disabled]="readonly"
                            [items]="items$ | async">
  </nexnox-web-sortable-list>
</nexnox-web-cardbox>

<ng-template #selectOptionTemplate let-item="item">
  <ng-container *ngIf="dashboardConfig[item.pageOperation] as dashboardItem">
    <br>
    <span class="text-muted">{{ ('core-shared.shared.entity-types.' + dashboardItem.entityType) | translate }}</span>
  </ng-container>
</ng-template>

<!-- Location Templates -->
<ng-template #locationSelectLabelTitleTemplate let-item="item">
  <nexnox-web-master-data-locations-location-location-number [label]="true"
                                                             [location]="item">
  </nexnox-web-master-data-locations-location-location-number>
</ng-template>

<ng-template #locationSelectOptionTitleTemplate let-item="item" let-searchTerm="searchTerm">
  <nexnox-web-master-data-locations-location-location-number [label]="false"
                                                             [location]="item"
                                                             [searchTerm]="searchTerm">
  </nexnox-web-master-data-locations-location-location-number>
</ng-template>
