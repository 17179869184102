<div [id]="customProperty.propertyAnchorId">
  <p-panel styleClass="custom-property-panel">
    <ng-template pTemplate="header">
      <div class="d-flex flex-row flex-wrap w-100">
        <div class="d-flex flex-grow-1 align-items-center mw-100">
          <div *ngIf="showPositionBadge"
               [class.mr-2]="readonly"
               class="d-flex align-items-center flex-wrap"
          ><span class="text-ellipsis rounded-sm text-lg px-3 py-1 bg-primary"
          >{{ customProperty.position }}</span>
          </div>
          <nexnox-web-cardbox-actions [actions]="headerActions"
                                      [hasFlexContent]="true"
                                      [readonly]="readonly"
                                      [useNew]="true"
                                      class="w-100">
            <div [style.min-width.px]="50" class="w-100 align-content-center">
              <span *ngIf="readonly;"
                    [title]="customProperty.name"
                    class="text-ellipsis">
                {{ customProperty.name }}
              </span>

              <input #nameEditInput
                     (ngModelChange)="!readonly ? onNameChange($event) : null"
                     [ngModel]="customProperty.name"
                     [required]="true"
                     [style.display]="readonly ? 'none' : 'inline'"
                     [style.height.px]="29"
                     class="form-control"
                     pInputText
                     title=""
                     type="text">
            </div>
            <div *ngIf="customProperty.type" class="d-flex align-items-center flex-wrap text-nowrap mr-2">
            <span *ngIf="readonly"
                  class="font-weight-bold text-xs" style="color: var(--gray-500) !important"
            >{{ 'core-shared.shared.custom-property-types.' + customProperty.type | translate }}</span>
              <span *ngIf="!readonly"
                    class="text-ellipsis rounded-sm px-3 py-1 bg-primary"
              >{{ 'core-shared.shared.custom-property-types.' + customProperty.type | translate }}</span>
            </div>
          </nexnox-web-cardbox-actions>
        </div>
      </div>
    </ng-template>

    <form #formElement
          [class.cursor-pointer]="showCursorPointer$ | async"
          [formGroup]="form">
      <formly-form (modelChange)="onCustomPropertyChange($event)"
                   [fields]="fields"
                   [form]="form"
                   [model]="customProperty"
                   class="row">
      </formly-form>
    </form>
  </p-panel>
</div>
