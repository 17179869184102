import {TaskState} from '@nexnox-web/core-shared';

export const taskStatesEnumOptions = [
  { label: 'orga-portal.tasks.task-states.0', value: TaskState.Inactive },
  { label: 'orga-portal.tasks.task-states.1', value: TaskState.Active }
];

export const allTaskStatesEnumOptions = [
  ...taskStatesEnumOptions,
  { label: 'orga-portal.tasks.task-states.2', value: TaskState.Archived },
  { label: 'orga-portal.tasks.task-states.3', value: TaskState.Deleted }
];
