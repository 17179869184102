import {Injectable} from '@angular/core';
import {
  CoreSharedHttpTechRequestService,
  IMonitorService,
  MissionType,
  StartingMissionDto,
  StartingMissionEditorType
} from '@nexnox-web/core-shared';
import {Observable, of} from "rxjs";
import {map, switchMap} from "rxjs/operators";
import {omit} from "lodash";


export interface TicketPreviewPayload {
  parentId: string | number;
}

@Injectable()
export class TicketMissionPreviewService implements IMonitorService<StartingMissionDto> {

  constructor(private httpRequestService: CoreSharedHttpTechRequestService) {
  }

  public modifyModel(payload: TicketPreviewPayload, key: string,
                     model: StartingMissionDto): Observable<StartingMissionDto> {
    return this.httpRequestService.post<StartingMissionDto>(`mission/preview`, {
      ticketId: payload.parentId,
      type: MissionType.Ticket
    }).pipe(
      map(response => response.body),
      switchMap((preview: StartingMissionDto) => {
          return of({
            // Get previously model
            ...model,
            // Overwrite with preview
            ...omit(preview, 'stereotypeId', 'stereotypeRowVersion'),
            // Check editor
            editor: !!preview?.editor ? preview.editor : {
              contact: null,
              type: StartingMissionEditorType.Contact
            } as any
          });
        }
      ));
  }
}
