import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Injector,
  Input,
  OnInit,
  Output,
  TemplateRef
} from '@angular/core';
import {DatatableTableColumn, DatatableTableColumnType} from '../../models';
import {
  CombineOperator,
  Filter,
  FilterDtoWithLabel,
  FilterKind,
  FilterOperators,
  FilterTypes,
  ResourceDto,
  UnsubscribeHelper
} from '@nexnox-web/core-shared';
import {TranslateService} from '@ngx-translate/core';
import {isArray, isEqual, isUndefined, sortBy} from 'lodash';
import {BehaviorSubject, merge, Observable} from 'rxjs';
import dayjs from 'dayjs';
import {CorePortalEntitySelectOptions} from '../../../entity-select';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {faTimes} from '@fortawesome/free-solid-svg-icons/faTimes';
import {Dictionary} from '@ngrx/entity';
import {CorePortalResourceInheritableService} from "@nexnox-web/libs/core-portal/src/lib/services";
import {relativeFilterAllowedProperties} from "./relative-filter-allowed-properties";

export interface FilterType {
  type: DatatableTableColumnType;
  values?: any[];
}

@Component({
  selector: 'nexnox-web-entity-datatable-filter',
  templateUrl: './entity-datatable-filter.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CorePortalEntityDatatableFilterComponent extends UnsubscribeHelper implements OnInit {
  @Input() public disabled: boolean;
  @Input() public showError: boolean;
  @Input() public showAll = false;
  @Input() public clearable = true;
  @Input() public isDatatableSettings = false;
  @Input() public templates: Dictionary<TemplateRef<any>>;
  @Output() public searchBy: EventEmitter<Filter> = new EventEmitter<Filter>();
  public currentValue$: Observable<any>;
  public filterType: BehaviorSubject<FilterType> = new BehaviorSubject(null);
  public currentDirection: FilterOperators = FilterOperators.Default;
  public isRelative = false;
  public useAlternate = false;
  public allowRelative = false;
  public referenceEntitySelectOptions: CorePortalEntitySelectOptions = null;
  public pathEntitySelectOptions: CorePortalEntitySelectOptions = null;
  public alternateReferenceEntitySelectOptions: CorePortalEntitySelectOptions = null;
  public faTimes = faTimes;
  private currentValueSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private filterSubject: BehaviorSubject<Filter> = new BehaviorSubject<Filter>({});
  private columnSubject: BehaviorSubject<DatatableTableColumn> = new BehaviorSubject<DatatableTableColumn>(null);

  constructor(
    private injector: Injector,
    private translate: TranslateService,
    private resourceService: CorePortalResourceInheritableService
  ) {
    super();

    this.currentValue$ = this.currentValueSubject.asObservable();
  }

  public get filter(): Filter {
    return this.filterSubject.getValue();
  }

  @Input()
  public set filter(filter: Filter) {
    this.filterSubject.next(filter);
  }

  public get column(): DatatableTableColumn {
    return this.columnSubject.getValue();
  }

  @Input()
  public set column(column: DatatableTableColumn) {
    this.columnSubject.next(column);

    if (this.isDatatableSettings) {
      this.setAllowRelative();
    }
  }

  public ngOnInit(): void {
    this.updateFilter();

    if (this.isDatatableSettings) {
      this.setAllowRelative();
    }

    this.subscribe(merge(
      this.filterSubject.asObservable().pipe(distinctUntilChanged((a, b) => isEqual(a, b))),
      this.columnSubject.asObservable().pipe(distinctUntilChanged((a, b) => isEqual(a, b)))
    ).pipe(debounceTime(400)), () => this.updateFilter());
  }

  public onChangeFilter(value: any): void {
    if (!isEqual(this.currentValueSubject.getValue(), value)) {
      this.currentValueSubject.next(value);
      this.prepareFilter();
      this.searchBy.emit(this.filter);
    }
  }

  public onChangePathFilter(value: any): void {
    if (!isEqual(this.currentValueSubject.getValue(), value)) {
      this.currentValueSubject.next(value);
      this.preparePathFilter(value);
      this.searchBy.emit(this.filter);
    }
  }

  public onChangeDateFilter(value: string): void {
    if (!value || !dayjs(value).isValid()) {
      this.currentValueSubject.next(null);
      this.searchBy.emit(null);
      return;
    }

    if (this.currentValueSubject.getValue() !== new Date(value)) {
      this.currentValueSubject.next(new Date(value));
      this.prepareFilter(dayjs(value).format('YYYY-MM-DD'));
      this.searchBy.emit(this.filter);
    }
  }

  public onChangeRelativeDateFilter(value: string): void {
    if (!value) {
      this.currentValueSubject.next(null);
      this.searchBy.emit(null);
      return;
    }

    this.currentValueSubject.next(value);
    this.prepareFilter(value);
    this.filter = {
      ...this.filter,
      kind: FilterKind.Relative
    };

    this.searchBy.emit(this.filter);
  }

  public onChangeDirection(direction: FilterOperators): void {
    this.currentDirection = direction;
    let currentValue = this.currentValueSubject.getValue() ?? null;

    if (this.column.type === DatatableTableColumnType.DATE && !this.isRelative) {
      if (!currentValue || !dayjs(currentValue).isValid()) {
        currentValue = null;
      }
      this.prepareFilter(currentValue ? dayjs(currentValue).format('YYYY-MM-DD') : null);
    } else {
      this.prepareFilter(currentValue);
    }

    if (currentValue) {
      this.searchBy.emit(this.filter);
    }
  }

  public onChangeRelative(relative: boolean): void {
    this.isRelative = relative;
    this.clear();
    this.prepareFilter(null);

    if (this.isDatatableSettings) {
      this.searchBy.emit(this.filter);
    }
  }

  public onChangeAlternate(useAlternate: boolean): void {
    const oldValue = this.currentValueSubject.getValue();

    this.useAlternate = useAlternate;
    this.clear();
    this.prepareFilter(null);

    if (oldValue?.length) {
      this.searchBy.emit(this.filter);
    }
  }

  public clear(): void {
    this.currentValueSubject.next(null);
    this.currentDirection = FilterOperators.Default;
  }

  private updateFilter(): void {
    if (this.filter) {
      const filterType = this.filter.type;
      const filterKind = this.filter.kind;

      this.isRelative = filterKind === FilterKind.Relative;
      this.useAlternate = filterType === FilterTypes.Grouped && filterKind === FilterKind.Grouped;

      if (filterType !== FilterTypes.Grouped) {
        if (this.column?.type === DatatableTableColumnType.DATE && !this.isRelative) {
          this.currentValueSubject.next(this.filter.value ? dayjs(this.filter.value).toDate() : null);
        } else {
          this.currentValueSubject.next(this.filter.value);
        }

        this.currentDirection = this.filter.operator as FilterOperators;
      } else {
        this.currentValueSubject.next((this.filter.children ?? [])
          .map(child => this.mapFilterChild(child)));
      }
    } else {
      this.currentValueSubject.next(null);
    }

    this.filterType.next(this.getFilterType());

    if (this.filterType.getValue()?.type === DatatableTableColumnType.PATH) {
      this.pathEntitySelectOptions = {
        idKey: 'resourceId',
        displayKey: 'name',
        entityService: this.resourceService,
        multiple: true,
        wholeObject: true,
        showAll: this.showAll,
        clearable: true,
        appendTo: 'body',
        minimal: true
      };
    }

    if (this.filterType.getValue()?.type === DatatableTableColumnType.REFERENCE) {
      this.referenceEntitySelectOptions = {
        idKey: this.column.idKey,
        displayKey: this.column.displayKey,
        additionalSearchProperties: this.column.additionalSearchProperties,
        template: this.column.template,
        entityService: this.injector.get(this.column.service),
        multiple: true,
        wholeObject: true,
        showAll: this.showAll,
        clearable: this.clearable,
        appendTo: 'body',
        defaultFilters$: this.column.filters$ ?? undefined,
        selectLabelTitleTemplate: this.column.labelTitleTemplateKey ? this.templates[this.column.labelTitleTemplateKey] : undefined,
        selectOptionTitleTemplate: this.column.optionTitleTemplateKey ? this.templates[this.column.optionTitleTemplateKey] : undefined,
        minimal: true
      };

      if (this.column.alternateFilter) {
        this.alternateReferenceEntitySelectOptions = {
          idKey: this.column.alternateFilter.idKey,
          displayKey: this.column.alternateFilter.displayKey,
          additionalSearchProperties: this.column.additionalSearchProperties,
          template: this.column.template,
          entityService: this.injector.get(this.column.alternateFilter.service),
          multiple: true,
          wholeObject: true,
          showAll: this.showAll,
          clearable: this.clearable,
          appendTo: 'body',
          defaultFilters$: this.column.alternateFilter.filters$ ?? undefined,
          minimal: true
        };
      }
    }
  }

  private prepareFilter(value?: any): void {
    const columnType = this.column.type;
    switch (this.column.type) {
      case DatatableTableColumnType.REFERENCE:
        this.prepareReferenceFilter(value);
        break;
      case DatatableTableColumnType.PATH:
        this.preparePathFilter(value);
        break;
      case DatatableTableColumnType.ENUM:
        this.prepareEnumFilter(value);
        break;
      case DatatableTableColumnType.ARRAY:
        this.prepareArrayFilter(value);
        break;
      default: {
        let property: string = this.column.prop?.toString();

        if (columnType === DatatableTableColumnType.HTML) {
          property = this.column.filterProp?.toString()
        }

        this.filter = {
          value: value ?? this.currentValueSubject.getValue(),
          operator: this.currentDirection ?? FilterOperators.Default,
          kind: this.isRelative ? FilterKind.Relative : FilterKind.Default,
          property,
          type: FilterTypes.DataTransferObject
        };

        if (!isUndefined(this.column.customPropertyId)) {
          this.filter = {
            ...this.filter,
            property: this.column.customPropertyId.toString(),
            type: FilterTypes.Custom
          };
        }

        if ((!this.filter.value || this.filter.value === '') && !this.isDatatableSettings) {
          this.filter = null;
        }
        break;
      }
    }
  }

  private getFilterType(): FilterType {
    const filterType: FilterType = this.column.type ? { type: this.column.type } as FilterType : undefined;

    if (filterType) {
      switch (this.column.type) {
        case DatatableTableColumnType.CURRENCY:
          return { type: DatatableTableColumnType.NUMBER };
        case DatatableTableColumnType.PHONE:
        case DatatableTableColumnType.HTML:
          return { type: DatatableTableColumnType.TEXT };
        default:
          if (this.column.enumOptions) {
            filterType.values = sortBy(
              this.column.enumOptions,
              option => (isArray(this.currentValueSubject.getValue()) ? (this.currentValueSubject.getValue() ?? []) : [])
                .find(x => x.value === option.value)
            );
          }
          return filterType;
      }
    }

    if (this.column.prop?.toString()?.toLowerCase()?.endsWith('id')) {
      return { type: DatatableTableColumnType.NUMBER };
    }

    return { type: DatatableTableColumnType.TEXT };
  }

  private prepareReferenceFilter(value?: any): void {
    const property: string = this.column.filterProp ?? `${ this.column.prop.toString() }.${ this.column.idKey }`;

    const filterValues: {
      id: string,
      label: string
    }[] = ((value ?? this.currentValueSubject.getValue()) ?? []).map(x => ({
      id: x[!this.useAlternate ? this.column.idKey : this.column.alternateFilter.idKey],
      label: x[!this.useAlternate ? this.column.displayKey : this.column.alternateFilter.displayKey]
    }));

    this.filter = (!this.isRelative ? {
      type: FilterTypes.Grouped,
      combinedAs: CombineOperator.Or,
      property: isUndefined(this.column.customPropertyId) ? property : this.column.customPropertyId.toString(),
      kind: this.useAlternate ? FilterKind.Grouped : FilterKind.Default,
      children: filterValues.map(filterValue => ({
        type: isUndefined(this.column.customPropertyId) ? FilterTypes.DataTransferObject : FilterTypes.Custom,
        operator: this.currentDirection ?? FilterOperators.Default,
        property: isUndefined(this.column.customPropertyId) ? property : this.column.customPropertyId.toString(),
        kind: this.useAlternate ? FilterKind.Grouped : FilterKind.Default,
        value: filterValue.id?.toString() ?? undefined,
        label: filterValue.label
      }))
    } : {
      property: isUndefined(this.column.customPropertyId) ? this.column.prop.toString() : this.column.customPropertyId.toString(),
      kind: FilterKind.Relative,
      type: FilterTypes.DataTransferObject
    }) as Filter;
  }

  private preparePathFilter(value?: ResourceDto[]): void {
    const values = ((value ?? this.currentValueSubject?.getValue() ?? []).map(x => ({
      id: x[(!this.useAlternate ? this.column?.idKey : this.column.alternateFilter?.idKey) ?? 'resourceId'],
      label: x[(!this.useAlternate ? this.column?.displayKey : this.column.alternateFilter?.displayKey) ?? 'name']
    })));

    const children: any[] =
      values.map((child: any) => ({
        type: FilterTypes.DataTransferObject,
        operator: FilterOperators.Equal,
        kind: FilterKind.Default,
        property: 'path.id',
        value: child.id?.toString() ?? undefined,
        label: child.label
      }));

    this.filter = {
      type: FilterTypes.Grouped,
      kind: FilterKind.Default,
      combinedAs: CombineOperator.Or,
      property: 'path.id',
      children
    } as Filter;
  }

  private prepareEnumFilter(value?: any): void {
    const property: string = this.column.prop.toString();
    const filterValues: {
      value: string,
      label: string
    }[] = ((value ?? this.currentValueSubject.getValue()) ?? []).map(x => {
      let label = (this.column.enumOptions ?? []).find(y => +y.value === +x.value)?.label;

      if (this.column.translate) {
        label = this.translate.instant(label);
      }

      return { value: x.value, label };
    });

    this.filter = {
      type: FilterTypes.Grouped,
      combinedAs: CombineOperator.Or,
      property: isUndefined(this.column.customPropertyId) ? property : this.column.customPropertyId.toString(),
      children: filterValues.map(filterValue => ({
        type: isUndefined(this.column.customPropertyId) ? FilterTypes.DataTransferObject : FilterTypes.Custom,
        operator: this.currentDirection ?? FilterOperators.Default,
        property: isUndefined(this.column.customPropertyId) ? property : this.column.customPropertyId.toString(),
        value: filterValue.value?.toString() ?? undefined,
        label: filterValue.label
      }))
    } as Filter;
  }

  private prepareArrayFilter(value?: any): void {
    const property: string = this.column.displayKey ? `${ this.column.prop }.${ this.column.displayKey }` : this.column.prop.toString();
    value = (value ?? this.currentValueSubject.getValue()).trim() === '' ? null : (value ?? this.currentValueSubject.getValue());

    this.filter = {
      operator: FilterOperators.Contains,
      kind: FilterKind.Default,
      type: FilterTypes.DataTransferObject,
      value,
      property
    } as Filter;
  }

  private mapFilterChild(child: FilterDtoWithLabel): { [key: string]: any } {
    const columnType = this.column.type;
    const alternateFilter = this.column.alternateFilter;
    let label: string;

    if (this.column.type === DatatableTableColumnType.ENUM) {
      label = (this.column.enumOptions ?? []).find(y => +y.value === +child.value)?.label;

      if (this.column.translate) {
        label = this.translate.instant(label);
      }
    } else if (columnType === DatatableTableColumnType.REFERENCE) {
      label = child.label;
    }

    switch (columnType) {
      case DatatableTableColumnType.PATH:
        return {
          [(!this.useAlternate ? this.column?.idKey : alternateFilter?.idKey) ?? 'resourceId']: child.value ? +child.value : null,
          [(!this.useAlternate ? this.column?.displayKey : alternateFilter?.displayKey) ?? 'name']: child.label ?? null
        };
      default:
        return {
          [(!this.useAlternate ? this.column?.idKey : alternateFilter?.idKey) ?? 'value']: child.value ? +child.value : null,
          [(!this.useAlternate ? this.column?.displayKey : alternateFilter?.displayKey) ?? 'label']: label ?? null
        };
    }
  }

  private setAllowRelative(): void {
    const columnType = this.column?.type;
    switch (columnType) {
      case DatatableTableColumnType.DATE:
        this.allowRelative = true;
        break;
      case DatatableTableColumnType.REFERENCE:
        this.allowRelative =
          relativeFilterAllowedProperties[columnType]?.allowedIdKeys.some(prop => prop === this.column?.idKey?.toString()) ?? false;
        break;
      default:
        this.allowRelative = false;
        break;
    }
  }
}
