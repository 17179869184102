import {createSelector, MemoizedSelector, MemoizedSelectorWithProps} from '@ngrx/store';
import {EntityXsStoreState} from './entity-xs-store.state';
import {StereotypeDto} from '@nexnox-web/core-shared';
import {EntityXsStoreEntityData} from './models';
import {isDeepEqualWith, IsDeepEqualWithCustomizer, sortDeep} from '@nexnox-web/lodash';

export interface EntityXsStoreSelectors<E, M, S> {
  selectEntity: MemoizedSelector<S, E>;
  selectModel: MemoizedSelector<S, M>;
  selectLoading: MemoizedSelector<S, boolean>;
  selectLoaded: MemoizedSelector<S, boolean>;
  selectStereotypes: MemoizedSelector<S, StereotypeDto[]>;
  selectStereotypesLoading: MemoizedSelector<S, boolean>;
  selectStereotypesLoaded: MemoizedSelector<S, boolean>;
  selectHasChanged: MemoizedSelector<S, boolean>;
  selectEntityData: MemoizedSelector<S, EntityXsStoreEntityData>;
  selectEntityDataLoading: MemoizedSelectorWithProps<S, { key: string }, boolean>;
}

export const createEntityXsStoreSelectors = <E, M, S extends EntityXsStoreState<E, M>>(
  stateSelector: MemoizedSelector<any, S>,
  sanitizeModel?: (model: M, entity: E) => E,
  changeCustomizers?: IsDeepEqualWithCustomizer[]
): EntityXsStoreSelectors<E, M, S> => {
  const selectEntityData = createSelector(stateSelector, state => state.entityData);

  return {
    selectEntity: createSelector(stateSelector, state => state.entity ?? {} as E),
    selectModel: createSelector(stateSelector, state => state.model ?? {} as M),
    selectLoading: createSelector(stateSelector, state => state.loading),
    selectLoaded: createSelector(stateSelector, state => state.loaded),
    selectStereotypes: createSelector(stateSelector, state => state.stereotypes),
    selectStereotypesLoading: createSelector(stateSelector, state => state.stereotypesLoading),
    selectStereotypesLoaded: createSelector(stateSelector, state => state.stereotypesLoaded),

    selectHasChanged: createSelector(
      stateSelector,
      state => {
        const entity = state.entity ?? {} as any;
        const model = sanitizeModel(state.model ?? {} as M, entity);

        return !isDeepEqualWith(sortDeep(entity), sortDeep(model), {
          customizers: changeCustomizers ?? []
        });
      }
    ),

    selectEntityData,
    selectEntityDataLoading: createSelector(selectEntityData, (state, { key }) => state.loading[key])
  };
};
