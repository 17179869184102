import {
  ContactAreaService,
  ContactLocationGroupService,
  ContactLocationService,
  ContactOpenRequestsService,
  ContactRoleService,
  CorePortalFeatureMasterDataContactService,
} from './services';
import {
  contactAreaDetailStore,
  contactAreaListStore,
  contactDetailStore,
  ContactDetailStoreEffects,
  ContactListStoreEffects,
  contactListStoreReducer,
  ContactLocationGroupListStoreEffects,
  contactLocationGroupListStoreReducer,
  ContactLocationListStoreEffects,
  contactLocationListStoreReducer,
  contactOpenRequestListStore,
  ContactRoleListStoreEffects,
  contactRoleListStoreReducer
} from './stores';

export * from './stores';
export * from './services';

export * from './contacts.state';
export * from './contacts.selectors';

export const contactsReducers = {
  contactList: contactListStoreReducer,
  contactDetail: contactDetailStore.reducer,

  contactLocationList: contactLocationListStoreReducer,
  contactLocationGroupList: contactLocationGroupListStoreReducer,
  contactRoleList: contactRoleListStoreReducer,
  contactAreaList: contactAreaListStore.reducer,
  contactAreaDetail: contactAreaDetailStore.reducer,
  contactOpenRequestList: contactOpenRequestListStore.reducer
};

export const contactsEffects = [
  ContactListStoreEffects,
  ContactDetailStoreEffects,

  ContactLocationListStoreEffects,
  ContactLocationGroupListStoreEffects,
  ContactRoleListStoreEffects,
  contactAreaListStore.effects,
  contactAreaDetailStore.effects,
  contactOpenRequestListStore.effects
];

export const contactsServices = [
  CorePortalFeatureMasterDataContactService,

  ContactLocationService,
  ContactLocationGroupService,
  ContactRoleService,
  ContactAreaService,
  ContactOpenRequestsService
];
