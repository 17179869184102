import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CorePortalEntityDetailComponent} from './components';
import {CorePortalTabsModule} from '../../tabs';
import {CorePortalCardboxModule} from '../../cardbox';
import {CorePortalFormlyModule} from '../../formly';
import {TranslateModule} from "@ngx-translate/core";

const exportedComponents = [
  CorePortalEntityDetailComponent
];

const sharedModules = [
  CorePortalTabsModule,
  CorePortalCardboxModule,
  CorePortalFormlyModule,
  TranslateModule
];

@NgModule({
  declarations: [
    ...exportedComponents
  ],
  imports: [
    CommonModule,
    ...sharedModules
  ],
  exports: [
    ...exportedComponents,
    ...sharedModules
  ]
})
export class CorePortalEntityDetailModule {
}
