import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {
  CoreSharedModalService,
  Mappers,
  Orders,
  SnapComponent,
  SortObject,
  StateDto,
  StateMachineDto,
  StateTransitionDto,
  UnsubscribeHelper
} from '@nexnox-web/core-shared';
import {BehaviorSubject, Observable} from 'rxjs';
import {
  CORE_PORTAL_DATATABLE_STANDARD_CONFIG,
  CorePortalDatatableStandardConfig,
  CorePortalEntityDatatableComponent,
  DatatableHeaderAction,
  DatatableLoadPagePayload,
  DatatableTableColumn,
  DatatableTableColumnTyping
} from '@nexnox-web/core-portal';
import {cloneDeep, flatten, remove, uniqBy} from 'lodash';
import {debounceTime, distinctUntilChanged, map, skip} from 'rxjs/operators';
import {faPlus} from '@fortawesome/free-solid-svg-icons/faPlus';
import {faTimes} from '@fortawesome/free-solid-svg-icons/faTimes';
import {faFlag} from '@fortawesome/free-solid-svg-icons/faFlag';
import {faFlagCheckered} from '@fortawesome/free-solid-svg-icons/faFlagCheckered';
import {StateMachineAddStateModalComponent} from '../../modals';
import {AsyncPipe} from '@angular/common';
import {StateMachineTransitionsSnap} from './state-machine-transitions-snap';

@Component({
  selector: 'nexnox-web-ticket-settings-state-machines-state-machine-transitions-edit',
  templateUrl: './state-machine-transitions-edit.component.html',
  styleUrls: ['./state-machine-transitions-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StateMachineTransitionsEditComponent extends UnsubscribeHelper implements OnInit, AfterViewInit {
  @Input() public model: StateMachineDto;

  @Output() public modelChange: EventEmitter<StateMachineDto> = new EventEmitter<StateMachineDto>();

  @ViewChild('snapContainer', { read: ElementRef }) public snapContainer: ElementRef;
  @ViewChild('snapComponent') public snapComponent: SnapComponent;
  @ViewChild('datatableComponent') public datatableComponent: CorePortalEntityDatatableComponent;

  public states$: Observable<StateDto[]>;
  public unfilteredStates$: Observable<StateDto[]>;
  public transitionMode = false;
  public selectedState: StateDto = null;

  public prependColumns: DatatableTableColumn[];
  public datatableHeaderActions: DatatableHeaderAction[];
  public excludedColumns: string[] = [];
  public defaultColumns: string[] = [];
  public columnTypings: DatatableTableColumnTyping[];
  public faPlus = faPlus;
  public faTimes = faTimes;
  public faFlag = faFlag;
  public faFlagCheckered = faFlagCheckered;
  @ViewChild('addTransitionCellTemplate', { static: true }) private addTransitionCellTemplate: TemplateRef<any>;
  private transitionsSubject: BehaviorSubject<StateTransitionDto[]> = new BehaviorSubject<StateTransitionDto[]>([]);
  private statesSubject: BehaviorSubject<StateDto[]> = new BehaviorSubject<StateDto[]>([]);
  private readonlySubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private asyncPipe: AsyncPipe;
  private paddingToNextColumn = 25;
  private sortObject: SortObject;
  private stateMachinesTransitionSnap: StateMachineTransitionsSnap;

  constructor(
    private changeDetector: ChangeDetectorRef,
    private modalService: CoreSharedModalService,
    @Inject(CORE_PORTAL_DATATABLE_STANDARD_CONFIG) private datatableConfig: CorePortalDatatableStandardConfig
  ) {
    super();

    this.asyncPipe = new AsyncPipe(changeDetector);
    /* istanbul ignore next */
    this.stateMachinesTransitionSnap = new StateMachineTransitionsSnap(
      () => this.snapComponent,
      () => this.statesSubject.getValue(),
      () => this.transitionsSubject.getValue(),
      () => this.readonly,
      columnIndex => this.onDeleteTransition(columnIndex)
    );
  }

  public get readonly(): boolean {
    return this.readonlySubject.getValue();
  }

  @Input()
  public set readonly(readonly: boolean) {
    this.readonlySubject.next(readonly);
  }

  public get snapWidth(): string {
    if (!this.transitionsSubject.getValue().length) {
      return '0px';
    }

    return `${ (this.transitionsSubject.getValue().length * this.paddingToNextColumn) + 5 }px`;
  }

  public get snapHeight(): string {
    return this.states$ ? `${ 39 * (this.asyncPipe.transform(this.states$).length ?? 0) }px` : '0px';
  }

  public ngOnInit(): void {
    this.unfilteredStates$ = this.statesSubject.asObservable();
    this.states$ = this.unfilteredStates$.pipe(
      map(states => this.sortStates(states))
    );

    this.prependColumns = [
      {
        name: 'transitions',
        translate: true,
        width: 200,
        minWidth: 200,
        maxWidth: 200,
        sortable: false
      },
      {
        width: 147,
        minWidth: 147,
        maxWidth: 147,
        sortable: false,
        cellTemplate: this.addTransitionCellTemplate
      }
    ];
    /* istanbul ignore next */
    this.datatableHeaderActions = [
      {
        icon: faPlus,
        title: 'core-portal.settings.actions.ticket-settings.add-state',
        className: 'btn-outline-primary',
        onClick: () => this.onAddState(),
        disabled$: this.readonlySubject.asObservable()
      }
    ];

    if (this.datatableConfig && this.datatableConfig.SettingsTicketSettingsStateDefault) {
      const config = this.datatableConfig.SettingsTicketSettingsStateDefault;
      this.excludedColumns = config.excludedColumns;
      this.defaultColumns = config.defaultColumns;
      this.columnTypings = config.columnTypings;
    }
  }

  public async ngAfterViewInit(): Promise<void> {
    await this.datatableComponent.createColumns(Mappers.StateListDto.serializedName);
    this.updateSnap();

    this.subscribe(this.readonlySubject.asObservable().pipe(
      distinctUntilChanged(),
      skip(1),
      debounceTime(400)
    ), () => this.updateSnap());

    this.subscribe(this.transitionsSubject.asObservable(), transitions => this.modelChange.emit({
      ...this.model,
      transitions
    }));
  }

  public onLoadPage(payload: DatatableLoadPagePayload): void {
    this.sortObject = payload.sortOptions;
    this.onUpdateStates(this.statesSubject.getValue());
  }

  public onUpdateStates(states: StateDto[]): void {
    this.statesSubject.next(states);
    this.stateMachinesTransitionSnap.update();
    setTimeout(() => this.changeDetector.detectChanges());
  }

  public onAddState(): void {
    this.modalService.showModal(StateMachineAddStateModalComponent, instance => {
      instance.filteredStateIds = this.statesSubject.getValue().map(state => state.stateId);
    })
      .then(result => {
        if (result?.value) {
          const states = cloneDeep(this.statesSubject.getValue());
          states.push(result.value);
          this.onUpdateStates(states);
        }
      })
      .catch(() => null);
  }

  public onRemoveState(state: StateDto): void {
    const states = cloneDeep(this.statesSubject.getValue());
    remove(states, x => x.stateId === state.stateId);

    const transitions = cloneDeep(this.transitionsSubject.getValue());
    remove(transitions, x => x.inStateId === state.stateId || x.outStateId === state.stateId);
    this.transitionsSubject.next(transitions);

    this.onUpdateStates(states);
  }

  public onMakeTransition(state: StateDto): void {
    this.transitionMode ? this.onMakeTransitionEnd(state) : this.onMakeTransitionStart(state);
  }

  public onMakeTransitionStart(state: StateDto): void {
    this.transitionMode = true;
    this.selectedState = state;
  }

  public onMakeTransitionEnd(state: StateDto): void {
    const transitions = cloneDeep(this.transitionsSubject.getValue());
    transitions.push({
      inStateId: this.selectedState.stateId,
      inState: this.selectedState,
      outStateId: state.stateId,
      outState: state
    } as StateTransitionDto);
    this.transitionsSubject.next(transitions);

    this.transitionMode = false;
    this.selectedState = null;
    this.stateMachinesTransitionSnap.update();

    if (this.snapContainer?.nativeElement) {
      this.changeDetector.detectChanges();
      this.snapContainer.nativeElement.scrollBy(150, 0);
    }
  }

  public onCancel(): void {
    this.transitionMode = false;
    this.selectedState = null;
  }

  public onMarkStateAsStart(state: StateDto): void {
    const transitions = cloneDeep(this.transitionsSubject.getValue());
    transitions.push({
      inStateId: null,
      outStateId: state.stateId,
      outState: state
    } as StateTransitionDto);
    this.transitionsSubject.next(transitions);

    this.stateMachinesTransitionSnap.update();
  }

  public onMarkStateAsEnd(state: StateDto): void {
    const transitions = cloneDeep(this.transitionsSubject.getValue());
    transitions.push({
      inStateId: state.stateId,
      inState: state,
      outStateId: null
    } as StateTransitionDto);
    this.transitionsSubject.next(transitions);

    this.stateMachinesTransitionSnap.update();
  }

  public onDeleteTransition(transitionIndex: number): void {
    const transitions = cloneDeep(this.transitionsSubject.getValue());
    transitions.splice(transitionIndex, 1);
    this.transitionsSubject.next(transitions);

    this.stateMachinesTransitionSnap.update();
  }

  public shouldShowMakeTransition(state: StateDto): boolean {
    if (this.transitionMode) {
      if (this.selectedState?.stateId === state.stateId) {
        return false;
      }

      return this.transitionsSubject.getValue()
        .filter(x => x.inStateId === this.selectedState?.stateId)
        .findIndex(x => x.outStateId === state.stateId) < 0;
    }

    const transitionsWithStateAsIn = this.transitionsSubject.getValue()
      .filter(x => x.inStateId !== null && x.outStateId !== null)
      .filter(x => x.inStateId === state.stateId);
    const otherStates = this.statesSubject.getValue().filter(x => x.stateId !== state.stateId);
    return transitionsWithStateAsIn.length < otherStates.length;
  }

  public shouldDisableMarkAsStart(state: StateDto): boolean {
    const transitionsWithStateAsOut = this.transitionsSubject.getValue().filter(x => x.outStateId === state.stateId);
    return Boolean(transitionsWithStateAsOut.filter(x => x.inStateId === null).length);
  }

  public shouldDisableMarkAsEnd(state: StateDto): boolean {
    const transitionsWithStateAsIn = this.transitionsSubject.getValue().filter(x => x.inStateId === state.stateId);
    return Boolean(transitionsWithStateAsIn.filter(x => x.outStateId === null).length);
  }

  private updateSnap(): void {
    this.transitionsSubject.next(this.model?.transitions ?? []);
    this.statesSubject.next(uniqBy(
      flatten(this.transitionsSubject.getValue().map(transition => [transition.inState, transition.outState])),
      state => state?.stateId
    ).filter(x => Boolean(x)));

    this.stateMachinesTransitionSnap.update();
    this.changeDetector.detectChanges();
  }

  private sortStates(states: StateDto[]): StateDto[] {
    if (!this.sortObject) {
      return states;
    }

    const ascending = (a, b, field): number => a[field] < b[field] ? -1 : 1;
    const descending = (a, b, field): number => a[field] < b[field] ? 1 : -1;

    return states.sort((a, b) => {
      switch (this.sortObject.sort) {
        case Orders.Descending:
          return descending(a, b, this.sortObject.sortField);
        default:
          return ascending(a, b, this.sortObject.sortField);
      }
    });
  }
}
