import {FieldWrapper, FormlyTemplateOptions} from '@ngx-formly/core';
import {ChangeDetectionStrategy, Component, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {isObservable, Observable, of} from 'rxjs';
import {distinctUntilChanged, filter, map, mergeMap, shareReplay, startWith, switchMap} from 'rxjs/operators';
import {isEmpty, isEqual, isNull, isUndefined} from 'lodash';
import * as dayjs from 'dayjs';
import {minutesTo} from '@nexnox-web/lodash';
import {FileDto} from '@nexnox-web/core-shared';
import {IconDefinition} from '@fortawesome/fontawesome-common-types';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import anchorme from "anchorme";

export enum CorePortalFormlyReadonlyTypes {
  BASIC,
  BOOLEAN,
  ENUM,
  DATE,
  TIME,
  ENTITY,
  IMAGE,
  HTML,
  PHONE
}

export interface CorePortalFormlyReadonlyEnumOption {
  label: string;
  value: any;
}

export type CorePortalFormlyReadonlyTyping =
  BasicTyping
  | DateTyping
  | EnumTyping
  | EntityTyping
  | ImageTyping
  | HTMLTyping;

interface ReadonlyTyping {
  translate?: boolean;
  hasValue?: boolean;
  icon?: IconDefinition;
  iconColor?: string;
  suffix?: (model: any) => string
}

export interface ReadonlyWithLinkTyping extends ReadonlyTyping {
  link?: (value: any) => string | any[];
  customLinkAction?: (value: any) => string | any[];
  module?: string;
}

interface BasicTyping extends ReadonlyTyping {
  type: CorePortalFormlyReadonlyTypes.BASIC | CorePortalFormlyReadonlyTypes.BOOLEAN | CorePortalFormlyReadonlyTypes.TIME | CorePortalFormlyReadonlyTypes.PHONE;
  template?: (value: any) => string;
  format?: boolean;
}

interface DateTyping extends ReadonlyTyping {
  type: CorePortalFormlyReadonlyTypes.DATE,
  format: string;
  utc?: boolean
}

interface EnumTyping extends ReadonlyWithLinkTyping {
  type: CorePortalFormlyReadonlyTypes.ENUM;
  enumOptions: Observable<CorePortalFormlyReadonlyEnumOption[]> | CorePortalFormlyReadonlyEnumOption[];
  compareFn?: (a: any, b: any) => boolean;
}

interface EntityTyping extends ReadonlyWithLinkTyping {
  type: CorePortalFormlyReadonlyTypes.ENTITY;
  displayKey: Observable<string> | string;
  template?: (value: any) => string;
}

interface ImageTyping extends ReadonlyTyping {
  type: CorePortalFormlyReadonlyTypes.IMAGE;
}

interface HTMLTyping extends ReadonlyTyping {
  type: CorePortalFormlyReadonlyTypes.HTML;
  isTrustedHTML: boolean;
  template?: (value: any) => string;
}

interface FormlyReadonlyTemplateOptions extends FormlyTemplateOptions {
  corePortalReadonly: CorePortalFormlyReadonlyTyping;
}

@Component({
  templateUrl: './formly-readonly.component.html',
  styleUrls: ['./formly-readonly.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormlyReadonlyComponent extends FieldWrapper implements OnInit {
  @ViewChild('fieldComponent', { read: ViewContainerRef }) public fieldComponent: ViewContainerRef;

  public readonly to: FormlyReadonlyTemplateOptions;

  public value$: Observable<any>;
  public hasValue$: Observable<boolean>;
  public basicValue$: Observable<string>;
  public enumValue$: Observable<string>;
  public timeValue$: Observable<string>;
  public dateValue$: Observable<string>;
  public imageValue$: Observable<string>;
  public entityValue$: Observable<string>;
  public htmlValue$: Observable<string>;

  public readonly urlRegex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#()?&//=]*)/igm;
  public readonly emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  public isPlainText = true;

  public types = CorePortalFormlyReadonlyTypes;

  constructor(private sanitizer: DomSanitizer) {
    super();
  }

  public ngOnInit(): void {
    this.value$ = this.formControl.valueChanges.pipe(
      startWith(this.formControl.value),
      distinctUntilChanged(),
      map(value => value ?? this.formControl.value),
      map(value => this.convertURLs(value)),
      shareReplay(1)
    );

    this.hasValue$ = this.value$.pipe(
      map(value =>
        !isUndefined(value) &&
        !isNull(value) &&
        (this.to.corePortalReadonly?.type === CorePortalFormlyReadonlyTypes.BASIC ? !isEmpty(value?.toString()) : true) ||
        this.to.corePortalReadonly.hasValue
      )
    );

    switch (this.to.corePortalReadonly?.type) {
      case CorePortalFormlyReadonlyTypes.BASIC:
        this.initBasicType(this.to.corePortalReadonly);
        break;
      case CorePortalFormlyReadonlyTypes.ENUM:
        this.initEnumType(this.to.corePortalReadonly);
        break;
      case CorePortalFormlyReadonlyTypes.TIME:
        this.initTimeType();
        break;
      case CorePortalFormlyReadonlyTypes.DATE:
        this.initDateType();
        break;
      case CorePortalFormlyReadonlyTypes.IMAGE:
        this.initImageType();
        break;
      case CorePortalFormlyReadonlyTypes.ENTITY:
        this.initEntityType(this.to.corePortalReadonly);
        break;
      case CorePortalFormlyReadonlyTypes.HTML:
        this.initHTMLType();
        break;
    }
  }

  public bypassHTMLSanitizer(value: string): SafeHtml | string {
    return (this.to.corePortalReadonly as HTMLTyping)?.isTrustedHTML ? this.sanitizer.bypassSecurityTrustHtml(value) : value;
  }

  public convertURLs(value: string = ''): string {
    this.isPlainText = true;
    const markup = value?.toString();

    if (this.urlRegex.test(markup) || this.emailRegex.test(markup)) {
      this.isPlainText = false;
      return anchorme({
        input: markup,
        options: {
          protocol: (text) => {
            if (anchorme.validate.email(text)) {
              return "mailto:";
            } else {
              return "https://";
            }
          },
          attributes: {
            target: "_blank"
          }
        }
      });
    }
    return value;
  }

  private initBasicType(typing: BasicTyping): void {
    this.basicValue$ = this.value$.pipe(
      map(value => value?.toLocaleString(undefined, {
        useGrouping: !(!isUndefined(typing.format) && !typing.format)
      })),
      shareReplay(1)
    );
  }

  private initEnumType(typing: EnumTyping): void {
    this.enumValue$ = (isObservable(typing.enumOptions) ? typing.enumOptions : of(typing.enumOptions)).pipe(
      mergeMap(enumOptions => this.value$.pipe(
        map(value => enumOptions.find(enumOption => {
          if (typing.compareFn) {
            return typing.compareFn(enumOption.value, value);
          }

          return isEqual(enumOption.value, value);
        })),
        filter(enumOption => Boolean(enumOption)),
        map(enumOption => enumOption.label)
      ))
    );
  }

  private initTimeType(): void {
    this.timeValue$ = this.value$.pipe(
      map(value => {
        const { days, hours, minutes } = minutesTo(value);
        return !isNull(days) && !isNull(hours) && !isNull(minutes) ? dayjs()
          .startOf('day')
          .add(days ?? 0, 'day')
          .add(hours ?? 0, 'hour')
          .add(minutes ?? 0, 'minute')
          .format() : null;
      })
    );
  }

  private initImageType(): void {
    this.imageValue$ = this.value$.pipe(
      map((value: FileDto) => value?.uri ?? null)
    );
  }

  private initDateType(): void {
    this.dateValue$ = this.value$.pipe(
      map((value) => {
        if ((this.to.corePortalReadonly as DateTyping).utc === true && typeof value === 'string' && !value.endsWith('Z')) {
          value = `${ value }Z`;
        }
        return value;
      })
    );
  }

  private initHTMLType(): void {
    this.htmlValue$ = this.value$.pipe(
      map(value => value ? value : '')
    );
  }

  private initEntityType(typing: EntityTyping): void {
    this.entityValue$ = this.value$.pipe(
      switchMap(value => {
        if (typing.template) {
          return of(typing.template(value));
        }

        return (isObservable(typing.displayKey) ? typing.displayKey : of(typing.displayKey)).pipe(
          map(displayKey => value && value.hasOwnProperty(displayKey) ? value[displayKey] : null)
        )
      })
    );
  }
}
