import {InjectionToken, NgModule} from '@angular/core';
import {ActionReducerMap, StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {dashboardsEffects, dashboardsReducers} from './store';

export const REDUCER_TOKEN = new InjectionToken<ActionReducerMap<any>>('@nexnox-web/core-portal/settings/dashboards: Store Reducers');

@NgModule({
  imports: [
    StoreModule.forFeature('core-portal-feature-settings-dashboards', REDUCER_TOKEN),
    EffectsModule.forFeature(dashboardsEffects)
  ],
  providers: [
    {
      provide: REDUCER_TOKEN,
      useValue: dashboardsReducers
    }
  ]
})
export class DashboardsStoreModule {
}
