import {Injectable} from '@angular/core';
import {CanDeactivate} from '@angular/router';
import {from, isObservable, Observable, of} from 'rxjs';
import {UnsavedChanges} from './unsaved-changes.model';
import {CoreSharedModalService} from '../../services';
import {mergeMap, take, tap} from 'rxjs/operators';

@Injectable()
export class UnsavedChangesGuard implements CanDeactivate<UnsavedChanges> {

  constructor(
    private modalService: CoreSharedModalService
  ) {
  }

  public canDeactivate(component: UnsavedChanges | any): Observable<boolean> | boolean {

    if (!component || !component.hasUnsavedChanges || component.isDeactivateUnsavedChangesModal) {
      return true;
    }

    const hasUnsavedChanges = component.hasUnsavedChanges();

    if (isObservable(hasUnsavedChanges)) {
      return hasUnsavedChanges.pipe(
        take(1),
        mergeMap(result => result ? this.dialog(component) : of(true))
      );
    }
    return hasUnsavedChanges ? this.dialog(component) : true;
  }

  private dialog(component: any): Observable<boolean> {
    return from(this.modalService.promptUnsavedChangesModal()).pipe(
      tap(answer => component.isDeactivateUnsavedChangesModal = answer)
    );
  }
}
