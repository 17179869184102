<ng-template #entityEditTemplate
             let-id="id"
             let-title="title">
  <nexnox-web-service-tenants-tenant-edit #modelComponent
                                          (modelChange)="onModelChange($event)"
                                          [creating]="false"
                                          [id]="id"
                                          [loading]="loading$ | async"
                                          [model]="model$ | async"
                                          [readonly]="readonly$ | async"
                                          [stereotyped]="false"
                                          [title]="title">
  </nexnox-web-service-tenants-tenant-edit>
</ng-template>

<nexnox-web-entity-detail [entityDetailBaseComponent]="this"
                          [entityEditTemplate]="entityEditTemplate"
                          [tabCommands]="['.']"
                          [tabHeading]="title | translate"
                          tabId="tenant">
  <!-- Subscriptions Tab -->
  <ng-template (selectTab)="onNavigateToTab(['subscriptions'])"
               [heading]="'core-portal.service.tenants.subscriptions' | translate"
               [lazy]="true"
               nexnoxWebTabTemplate
               tabId="subscriptions">
    <nexnox-web-service-tenants-tenant-subscription-list *ngIf="passedIds"
                                                         [canCreate]="true"
                                                         [getEntityOnModeChange]="false"
                                                         [isEmbedded]="true"
                                                         [tenantId]="passedIds[0]">
    </nexnox-web-service-tenants-tenant-subscription-list>
  </ng-template>

  <!-- Systemcontacts Tab -->
  <ng-template (selectTab)="onNavigateToTab(['systemcontacts'])"
               [heading]="'core-portal.service.tenants.systemcontacts' | translate"
               [lazy]="true"
               nexnoxWebTabTemplate
               tabId="systemcontacts">
    <nexnox-web-service-tenants-tenant-systemcontact-list *ngIf="passedIds"
                                                          [canCreate]="false"
                                                          [getEntityOnModeChange]="false"
                                                          [isEmbedded]="true"
                                                          [parentIds]="passedIds"
                                                          [tenantId]="passedIds[0]">
    </nexnox-web-service-tenants-tenant-systemcontact-list>
  </ng-template>

  <!-- Usage Tab -->
  <ng-template (selectTab)="onNavigateToTab(['tenant-usage'])"
               [heading]="'core-portal.service.tenants.license-usage' | translate"
               [lazy]="true"
               nexnoxWebTabTemplate
               tabId="tenant-usage">
    <nexnox-web-service-tenants-tenant-usage *ngIf="passedIds"
                                             [loading$]="loading$"
                                             [tenantId]="passedIds[0]">
    </nexnox-web-service-tenants-tenant-usage>
  </ng-template>
</nexnox-web-entity-detail>
