import {PagedEntitiesXsStore, PagedEntitiesXsStoreState} from '@nexnox-web/core-store';
import {AppEntityType, TicketDto} from '@nexnox-web/core-shared';
import {createSelector} from '@ngrx/store';
import {selectLocationsState} from '../../locations.selectors';
import {LocationTicketService} from '../../services';

export interface LocationTicketListStoreState extends PagedEntitiesXsStoreState<TicketDto> {
}

/* istanbul ignore next */
export const locationTicketListStore = new PagedEntitiesXsStore<TicketDto>({
  actionLabel: 'Core Portal - Master Data - Location - Ticket List',
  stateSelector: createSelector(selectLocationsState, state => state.locationTicketList),
  serviceType: LocationTicketService,
  entityType: AppEntityType.Ticket,
  selectId: ticket => ticket.entity?.ticketId ?? ticket.model?.ticketId
});
