import {
  BaseXsStore,
  BaseXsStoreReducerTypes,
  PagedEntitiesXsStore,
  PagedEntitiesXsStoreActions,
  PagedEntitiesXsStoreEffects,
  pagedEntitiesXsStoreSetLoadingForId,
  PagedEntitiesXsStoreState,
  PropsAction
} from '@nexnox-web/core-store';
import {AppEntityType, ContactSimpleDto, StateDto, TicketDto} from '@nexnox-web/core-shared';
import {Action, createAction, on, props, select} from '@ngrx/store';
import {Injectable, Injector, Type} from '@angular/core';
import {TechPortalTicketService} from '@nexnox-web/tech-portal-lib';
import {Observable, of} from 'rxjs';
import {createEffect, ofType} from '@ngrx/effects';
import {catchError, exhaustMap, groupBy, map, mergeMap, tap, withLatestFrom} from 'rxjs/operators';
import {authStore} from '@nexnox-web/core-portal';
import {immerOn} from 'ngrx-immer/store';

export interface TicketListXsStoreUpdateStatePayload {
  id: number;
  state: StateDto;
  reason: string;
  parentIds?: Array<number | string>;
}

export interface TicketListXsStoreUpdateStateSuccessPayload {
  id: number;
  state: StateDto;
}

export interface TicketListXsStoreAssignToMePayload {
  id: number;
  parentIds?: Array<number | string>;
}

export interface TicketListXsStoreAssignToMeSuccessPayload {
  id: number;
  contact: ContactSimpleDto;
  parentIds?: Array<number | string>;
}

export interface TicketListXsStoreAssignToPayload {
  id: number;
  contact: ContactSimpleDto;
  parentIds?: Array<number | string>;
}

export interface TicketListXsStoreAssignToSuccessPayload {
  id: number;
  contact: ContactSimpleDto;
  parentIds?: Array<number | string>;
}

export interface TicketListXsStoreExportPayload {
  id: number;
  templateId?: number | string;
}

export interface TicketListXsStoreExportSuccessPayload {
  id: number;
  uri: string;
  templateId?: number | string;
}

export interface TicketListXsStoreActions extends PagedEntitiesXsStoreActions<TicketDto> {
  changeState: PropsAction<TicketListXsStoreUpdateStatePayload>;
  changeStateSuccess: PropsAction<TicketListXsStoreUpdateStateSuccessPayload>;

  assignToMe: PropsAction<TicketListXsStoreAssignToMePayload>;
  assignToMeSuccess: PropsAction<TicketListXsStoreAssignToMeSuccessPayload>;

  assignTo: PropsAction<TicketListXsStoreAssignToPayload>;
  assignToSuccess: PropsAction<TicketListXsStoreAssignToSuccessPayload>;

  export: PropsAction<TicketListXsStoreExportPayload>;
  exportSuccess: PropsAction<TicketListXsStoreExportSuccessPayload>;
}

export class TicketListXsStore extends PagedEntitiesXsStore<TicketDto> {
  public actions: TicketListXsStoreActions;

  protected createActions(label: string): TicketListXsStoreActions {
    return {
      ...super.createActions(label),

      changeState: createAction(
        BaseXsStore.getType(label, 'Change state'),
        props<TicketListXsStoreUpdateStatePayload>()
      ),
      changeStateSuccess: createAction(
        BaseXsStore.getType(label, 'Change state success'),
        props<TicketListXsStoreUpdateStateSuccessPayload>()
      ),

      assignToMe: createAction(
        BaseXsStore.getType(label, 'Assign to me'),
        props<TicketListXsStoreAssignToMePayload>()
      ),
      assignToMeSuccess: createAction(
        BaseXsStore.getType(label, 'Assign to me success'),
        props<TicketListXsStoreAssignToMeSuccessPayload>()
      ),

      assignTo: createAction(
        BaseXsStore.getType(label, 'Assign to'),
        props<TicketListXsStoreAssignToPayload>()
      ),
      assignToSuccess: createAction(
        BaseXsStore.getType(label, 'Assign to success'),
        props<TicketListXsStoreAssignToSuccessPayload>()
      ),

      export: createAction(
        BaseXsStore.getType(label, 'Export'),
        props<TicketListXsStoreExportPayload>()
      ),
      exportSuccess: createAction(
        BaseXsStore.getType(label, 'Export success'),
        props<TicketListXsStoreExportSuccessPayload>()
      )
    };
  }

  protected createReducerArray(
    initialState: PagedEntitiesXsStoreState<TicketDto>
  ): BaseXsStoreReducerTypes<PagedEntitiesXsStoreState<TicketDto>, TicketListXsStoreActions>[] {
    return [
      ...super.createReducerArray(initialState),

      immerOn(this.actions.changeState, (draft, { id }) => {
        draft.entityData = pagedEntitiesXsStoreSetLoadingForId(draft.entityData, id, {
          changeState: true
        });
      }),
      on(this.actions.changeStateSuccess, (state, { id, state: newState }) => this.adapter.updateOne({
        id: id.toString(),
        changes: {
          entity: {
            ...state.entities[id.toString()].entity,
            currentState: newState
          },
          model: {
            ...state.entities[id.toString()].model,
            currentState: newState
          }
        }
      }, {
        ...state,
        entityData: pagedEntitiesXsStoreSetLoadingForId(state.entityData, id, {
          changeState: false
        })
      })),

      immerOn(this.actions.assignToMe, (draft, { id }) => {
        draft.entityData = pagedEntitiesXsStoreSetLoadingForId(draft.entityData, id, {
          assignToMe: true
        });
      }),
      on(this.actions.assignToMeSuccess, (state, { id, contact }) => this.adapter.updateOne({
        id: id.toString(),
        changes: {
          entity: {
            ...state.entities[id.toString()].entity,
            editor: contact
          },
          model: {
            ...state.entities[id.toString()].model,
            editor: contact
          }
        }
      }, {
        ...state,
        entityData: pagedEntitiesXsStoreSetLoadingForId(state.entityData, id, {
          assignToMe: false
        })
      })),

      immerOn(this.actions.assignTo, (draft, { id }) => {
        draft.entityData = pagedEntitiesXsStoreSetLoadingForId(draft.entityData, id, {
          assignTo: true
        });
      }),
      on(this.actions.assignToSuccess, (state, { id, contact }) => this.adapter.updateOne({
        id: id.toString(),
        changes: {
          entity: {
            ...state.entities[id.toString()].entity,
            editor: contact
          },
          model: {
            ...state.entities[id.toString()].model,
            editor: contact
          }
        }
      }, {
        ...state,
        entityData: pagedEntitiesXsStoreSetLoadingForId(state.entityData, id, {
          assignTo: false
        })
      })),

      immerOn(this.actions.export, (draft, { id }) => {
        draft.entityData = pagedEntitiesXsStoreSetLoadingForId(draft.entityData, id, {
          export: true
        });
      }),
      immerOn(this.actions.exportSuccess, (draft, { id }) => {
        draft.entityData = pagedEntitiesXsStoreSetLoadingForId(draft.entityData, id, {
          export: false
        });
      })
    ];
  }

  protected createEffects(
    serviceType: Type<TechPortalTicketService>,
    entityType: AppEntityType,
    prepareEntity: (entity: TicketDto) => TicketDto,
    prepareModel: (entity: TicketDto, model: TicketDto) => TicketDto,
    sanitizeModel: (model: TicketDto, entity: TicketDto) => TicketDto,
    ...args
  ): Type<PagedEntitiesXsStoreEffects<TicketDto>> {
    const actions = this.actions;
    const selectors = this.selectors;

    @Injectable()
    class Effects extends PagedEntitiesXsStoreEffects<TicketDto> {
      public changeState$: Observable<Action>;
      public changeStateSuccess$: Observable<Action>;

      public assignToMe$: Observable<Action>;
      public assignToMeSuccess$: Observable<Action>;

      public assignTo$: Observable<Action>;
      public assignToSuccess$: Observable<Action>;

      public export$: Observable<Action>;
      public exportSuccess$: Observable<Action>;

      protected service: TechPortalTicketService;

      constructor(
        protected injector: Injector
      ) {
        super(injector, actions, selectors, serviceType, entityType, prepareEntity, prepareModel, sanitizeModel, true);
      }

      protected createEffects(): void {
        super.createEffects();

        this.changeState$ = createEffect(() => this.actions$.pipe(
          ofType(actions.changeState),
          groupBy(({ id }) => id),
          mergeMap(group => group.pipe(
            exhaustMap(({
                          id,
                          state,
                          reason,
                          parentIds
                        }) => this.service.changeState(id, state.stateId, reason, parentIds).pipe(
              map(() => actions.changeStateSuccess({ id, state })),
              catchError(error => of(actions.error({ error, action: actions.changeState })))
            ))
          ))
        ));
        this.changeStateSuccess$ = createEffect(() => this.actions$.pipe(
          ofType(actions.changeStateSuccess),
          tap(action => this.actionCallback(action, false))
        ), { dispatch: false });

        this.assignToMe$ = createEffect(() => this.actions$.pipe(
          ofType(actions.assignToMe),
          groupBy(({ id }) => id),
          mergeMap(group => group.pipe(
            exhaustMap(({ id, parentIds }) => this.service.assignToMe(id, parentIds).pipe(
              withLatestFrom(this.store.pipe(select(authStore.selectors.selectActiveTenant))),
              map(([_, tenant]) => actions.assignToMeSuccess({
                id,
                contact: (tenant?.names ?? []).length ? tenant.names[0] : null,
                parentIds
              })),
              catchError(error => of(actions.error({ error, action: actions.assignToMe })))
            ))
          ))
        ));
        this.assignToMeSuccess$ = createEffect(() => this.actions$.pipe(
          ofType(actions.assignToMeSuccess),
          tap(action => this.actionCallback(action, false))
        ), { dispatch: false });

        this.assignTo$ = createEffect(() => this.actions$.pipe(
          ofType(actions.assignTo),
          groupBy(({ id }) => id),
          mergeMap(group => group.pipe(
            exhaustMap(({ id, contact, parentIds }) => this.service.assignTo(id, contact.contactId, parentIds).pipe(
              map(() => actions.assignToSuccess({ id, contact, parentIds })),
              catchError(error => of(actions.error({ error, action: actions.assignTo })))
            ))
          ))
        ));
        this.assignToSuccess$ = createEffect(() => this.actions$.pipe(
          ofType(actions.assignToSuccess),
          tap(action => this.actionCallback(action, false))
        ), { dispatch: false });

        this.export$ = createEffect(() => this.actions$.pipe(
          ofType(actions.export),
          groupBy(({ id }) => id),
          mergeMap(group => group.pipe(
            exhaustMap(({ id, templateId }) => this.service.export(id, templateId).pipe(
              map(({ uri }) => actions.exportSuccess({ id, uri, templateId })),
              catchError(error => of(actions.error({ error, action: actions.export })))
            ))
          ))
        ));
        this.exportSuccess$ = createEffect(() => this.actions$.pipe(
          ofType(actions.exportSuccess),
          tap(action => this.actionCallback(action, false))
        ), { dispatch: false });
      }

      protected actionCallback(action: Action, isError: boolean = false): void {
        super.actionCallback(action, isError);

        this.checkAction(actions.changeStateSuccess, action, payload => this.changeStateSuccessActionCallback(payload));
        this.checkAction(actions.assignToMeSuccess, action, () => this.assignToMeSuccessActionCallback());
        this.checkAction(actions.assignToSuccess, action, ({ contact }) => this.assignToSuccessActionCallback(contact));
        this.checkAction(actions.exportSuccess, action, ({ uri }) => this.exportSuccessActionCallback(uri));
      }

      protected changeStateSuccessActionCallback({ state }: TicketListXsStoreUpdateStateSuccessPayload): void {
        this.apiNotificationService.showTranslatedSuccess('tickets.toasts.state-changed', {
          name: state.name
        });
      }

      protected assignToMeSuccessActionCallback(): void {
        this.apiNotificationService.showTranslatedSuccess('tickets.toasts.assign-to-me');
      }

      protected assignToSuccessActionCallback(contact: ContactSimpleDto): void {
        this.apiNotificationService.showTranslatedSuccess('tickets.toasts.assign-to', { contact: contact.displayName });
      }

      protected exportSuccessActionCallback(uri: string): void {
        window.open(uri, '_blank');
      }
    }

    return Effects;
  }
}
