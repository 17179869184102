import {
  CorePortalFeatureAuditListXsStore,
  CorePortalFeatureAuditListXsStoreState
} from 'libs/core-portal/features/audit/src';
import {Action, createSelector} from '@ngrx/store';
import {selectLocationsState} from '../../locations.selectors';
import {Injectable, Injector} from '@angular/core';

export interface LocationAuditListStoreState extends CorePortalFeatureAuditListXsStoreState {
}

/* istanbul ignore next */
export const locationAuditListStore = new CorePortalFeatureAuditListXsStore({
  actionLabel: 'Core Portal - Locations - Location Audit',
  stateSelector: createSelector(selectLocationsState, state => state.locationAuditList),
});

export function locationAuditListStoreReducer(state: LocationAuditListStoreState, action: Action): any {
  return locationAuditListStore.reducer(state, action);
}

@Injectable()
export class LocationAuditListStoreEffects extends locationAuditListStore.effects {
  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }
}
