import {ChangeDetectionStrategy, Component, Injector} from '@angular/core';
import {CorePortalEntityOverviewBaseComponent, DatatableActionButton, DeleteEntityModel} from '@nexnox-web/core-portal';
import {AddressDto, AppEntityType, AppPermissions, ControllerOperationId, Mappers} from '@nexnox-web/core-shared';
import {addressListStore} from '../../store';

@Component({
  selector: 'nexnox-web-master-data-addresses-address-list',
  templateUrl: './address-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddressListComponent extends CorePortalEntityOverviewBaseComponent<AddressDto> {
  public title = 'core-portal.master-data.subtitles.address-list';
  public idProperty = 'addressId';
  public displayProperty = 'street';
  public datatableConfigName = 'MasterDataAddressDefault';
  public componentId = 'AddressListComponent';

  public pageOperation = ControllerOperationId.AddressControllerList;
  public enableViews = true;

  constructor(
    protected injector: Injector
  ) {
    super(injector, addressListStore, Mappers.AddressListDto.serializedName, AppEntityType.Address);
  }

  public getRowActionButtons(): DatatableActionButton[] {
    return this.getDefaultRowActionButtons(
      'core-portal.master-data.actions.edit-address',
      (row: AddressDto) => `/masterdata/addresses/${ row.addressId }`,
      [AppPermissions.UpdateCompany],
      {
        module: 'management'
      }
    );
  }

  public getDeleteEntityModel(): DeleteEntityModel {
    return {
      titleKey: 'core-portal.master-data.actions.delete-address',
      descriptionKey: 'core-portal.master-data.descriptions.delete-address',
      confirmKey: 'core-portal.master-data.actions.delete-address',
      deletePermission: AppPermissions.DeleteAddress
    };
  }
}
