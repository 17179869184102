<div *ngIf="!loading; else loadingTemplate"
     [class.fullheight-container]="fullHeight"
     [class.is-accordion]="isAccordion"
     [class.is-border-bottom]="!isCollapsed && isAccordion"
     [class.is-transparent]="transparent"
     [class.mb-0]="noMarginBottom"
     [class.overflow-auto]="overflowAuto"
     [class.shadow-margin-bottom]="shadowMarginBottom"
     [class.w-100]="fullWidth"
     [class]="'cardbox w-100 ' + classes">
  <ng-container *ngIf="!noTitle">
    <div [class.align-items-center]="!alignTitleStart"
         [class.align-items-start]="alignTitleStart"
         [class.header-accent]="withAccent"
         [class.p-3]="!isTitleEditable"
         [class.py-1]="isTitleEditable"
         [style.border-left-color]="customAccent ? customAccent : undefined"
         class="d-flex flex-row cardbox-header">
      <div [class.flex-wrap]="wrapTitle"
           class="d-flex flex-shrink-1 flex-grow-1 overflow-hidden">
        <h5 (click)="toggleCollapsed()"
            *ngIf="prependTitle"
            [class.mr-2]="!isTitleEditable"
            [class.pl-3]="isTitleEditable"
            [class.pointer]="isAccordion"
            class="d-flex align-items-center mb-0 text-muted">
          {{ prependTitle }}
        </h5>

        <h5 (click)="toggleCollapsed()"
            *ngIf="!isTitleEditable; else editableTitleTemplate"
            [class.pointer]="isAccordion"
            class="d-flex align-items-center flex-grow-1 mb-0">
          <span class="align-items-center fade-text">
            <fa-icon *ngIf="icon"
                     [icon]="icon"
                     [size]="iconSize"
                     [style.color]="iconColor"
                     class="mr-2">
            </fa-icon>
            {{ title }}
            <span *ngIf="(indicator$ | async) as indicator">
              ({{ indicator }})
            </span>
          </span>
          <p-badge *ngIf="showBadge && badgeTitle"
                   [styleClass]="'whitespace-nowrap align-self-center ' + badgeStyleClasses"
                   [value]="badgeTitle">
          </p-badge>
        </h5>

        <ng-template #editableTitleTemplate>
          <form [formGroup]="titleForm"
                class="w-100 py-1">
            <formly-form (modelChange)="onTitleChange($event)"
                         [fields]="titleFields"
                         [form]="titleForm"
                         [model]="titleModel"
                         class="row">
            </formly-form>
          </form>
        </ng-template>

        <ng-container *ngIf="appendTitleTemplate"
                      [ngTemplateOutlet]="appendTitleTemplate"></ng-container>
      </div>

      <div *ngIf="headerActions?.length"
           [class.mr-3]="isTitleEditable"
           class="d-flex flex-shrink-0 ml-2">
        <nexnox-web-cardbox-actions [actions]="headerActions"
                                    [data]="actionsData"></nexnox-web-cardbox-actions>
      </div>

      <div *ngIf="isAccordion"
           class="cardbox-accordion-btn ml-2">
        <button (click)="toggleCollapsed()"
                *ngIf="isCollapsed"
                [icon]="faChevronDown | nexnoxWebFaIconStringPipe"
                class="p-button-text p-button-secondary p-button-minimal py-0 px-2"
                pButton
                pRipple
                type="button">
        </button>
        <button (click)="toggleCollapsed()"
                *ngIf="!isCollapsed"
                [icon]="faChevronUp | nexnoxWebFaIconStringPipe"
                class="p-button-text p-button-secondary p-button-minimal py-0 px-2"
                pButton
                pRipple
                type="button">
        </button>
      </div>
    </div>

    <hr [class.mb-0]="noDividerMarginBottom || isCollapsed"
        class="mt-0 w-100">
  </ng-container>

  <ng-container *ngIf="!isCollapsed">

    <div [class.fullheight-content]="fullHeight"
         [class.w-100]="fullWidth"
         [class]="paddingClasses"
         class="cardbox-content">
      <ng-content></ng-content>
    </div>

    <ng-container *ngIf="footerActions?.length">
      <hr class="my-0">

      <div class="d-flex flex-row align-items-center justify-content-end p-3">
        <nexnox-web-cardbox-actions [actions]="footerActions"
                                    [data]="actionsData"></nexnox-web-cardbox-actions>
      </div>
    </ng-container>

  </ng-container>
</div>

<ng-template #loadingTemplate>
  <nexnox-web-cardbox-ghost [fullHeight]="fullHeight"
                            [fullWidth]="fullWidth"
                            [noDividerMarginBottom]="noDividerMarginBottom"
                            [noMarginBottom]="noMarginBottom"
                            [paddingClasses]="ghostPaddingClasses ?? paddingClasses"
                            [transparent]="transparent">
  </nexnox-web-cardbox-ghost>
</ng-template>
