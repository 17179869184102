import {ChangeDetectionStrategy, Component, Injector} from '@angular/core';
import {ActionButton, CorePortalEntityDetailBaseComponent} from '@nexnox-web/core-portal';
import {AppPermissions, ArticleDto, ConcreteTaskTypes} from '@nexnox-web/core-shared';
import {articleDetailStore} from '../../store';

@Component({
  selector: 'nexnox-web-articles-article-detail',
  templateUrl: './article-detail.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ArticleDetailComponent extends CorePortalEntityDetailBaseComponent<ArticleDto> {
  public title = 'articles.subtitles.article-detail';

  protected idParam = 'articleId';
  protected displayKey = 'name';

  protected readonly ConcreteTaskTypes = ConcreteTaskTypes;

  constructor(
    protected injector: Injector
  ) {
    super(injector, articleDetailStore);
  }

  protected async getActionButtons(): Promise<ActionButton[]> {
    return this.getDefaultActionButtons(
      'articles.actions.edit-article',
      'articles.actions.save-article',
      'articles.actions.delete-article',
      'articles.descriptions.delete-article',
      AppPermissions.UpdateArticle,
      AppPermissions.DeleteArticle,
      ['/articles']
    );
  }
}
