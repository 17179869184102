import {Observable, Subscription} from 'rxjs';
import {Directive, OnDestroy} from '@angular/core';

@Directive()
export class UnsubscribeHelper implements OnDestroy {
  private subscriptions: Subscription[] = [];

  public ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      if (!subscription.closed) {
        subscription.unsubscribe();
      }
    }
    this.subscriptions = [];
  }

  protected subscribe<T>(
    subscribable: Observable<T>,
    next?: (value: T) => void,
    error?: (error: any) => void
  ): void {
    this.subscriptions.push(subscribable.subscribe(next, error));
  }
}
