import {AppEntityType, ContactSimpleDto, ResourceDto, TicketDto} from '@nexnox-web/core-shared';
import {Action, createSelector, select} from '@ngrx/store';
import {selectTicketsState} from '../../tickets.selectors';
import {TicketEventService} from '../../services';
import {Injectable, Injector} from '@angular/core';
import {TicketDetailXsStore} from './ticket-detail.xs-store';
import {TicketDetailXsStoreState} from './ticket-detail-xs-store.state';
import {
  TicketDetailXsStoreActions,
  TicketDetailXsStoreUpdateStateSuccessPayload
} from './ticket-detail-xs-store.actions';
import {createEffect, ofType} from '@ngrx/effects';
import {catchError, debounceTime, exhaustMap, filter, map, switchMap, tap, withLatestFrom} from 'rxjs/operators';
import {EntityXsStoreModelUpdatePayload} from '@nexnox-web/core-store';
import {of} from 'rxjs';
import {authStore} from '@nexnox-web/core-portal';
import {TechPortalTicketService} from '@nexnox-web/tech-portal-lib';

export interface TicketDetailStoreState extends TicketDetailXsStoreState {
}

/* istanbul ignore next */
export const ticketDetailStore = new TicketDetailXsStore({
  actionLabel: 'Tech Portal - Tickets - Ticket Detail',
  stateSelector: createSelector(selectTicketsState, state => state.ticketDetail),
  serviceType: TechPortalTicketService,
  entityType: AppEntityType.Ticket,
  prepareModel: (entity, model, base) => ({
    ...base(entity, model),
    location: entity?.location ?? entity.resource?.location ?? null
  }),
  sanitizeModel: (model, entity, base) => {
    const { location, ...rest } = base(model, entity) as TicketDto;
    return {
      ...rest,
      resource: !rest.resource ? null : {
        resourceId: rest.resource.resourceId,
        name: rest.resource.name,
        path: rest.resource.path,
        location: rest.resource.location,
        inventoryNumber: rest.resource.inventoryNumber,
        tenantId: rest.resource.tenantId,
        hasChildren: rest.resource.hasChildren
      },
      priority: !rest.priority ? null : {
        priorityId: rest.priority.priorityId,
        name: rest.priority.name,
        tenantId: rest.priority.tenantId
      }
    };
  }
});

@Injectable()
export class TicketDetailStoreEffects extends ticketDetailStore.effects {
  public actions: TicketDetailXsStoreActions;
  public service: TechPortalTicketService;

  public getPreviewStates$: any;

  public changeState$: any;
  public changeStateSuccess$: any;

  public followUp$: any;
  public followUpSuccess$: any;

  public assignContactToMe$: any;
  public assignContactToMeSuccess$: any;

  public assignContact$: any;
  public assignContactSuccess$: any;

  public unassignContact$: any;
  public unassignContactSuccess$: any;

  public assignResource$: any;
  public assignResourceSuccess$: any;

  public unassignResource$: any;
  public unassignResourceSuccess$: any;

  public export$: any;
  public exportSuccess$: any;

  protected ticketEventService: TicketEventService;

  private lastStereotypeId: number = null;

  constructor(
    protected injector: Injector
  ) {
    super(injector);

    this.ticketEventService = injector.get(TicketEventService);
  }

  protected createEffects(): void {
    super.createEffects();

    this.getPreviewStates$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.getPreviewStates),
      withLatestFrom(this.store.pipe(select(this.selectors.selectModel))),
      switchMap(([_, ticket]) => this.store.pipe(
        select(this.selectors.selectStereotypes),
        filter(stereotypes => Boolean(stereotypes && stereotypes.length && ticket)),
        map(stereotypes => stereotypes.find(stereotype => stereotype.stereotypeId === ticket.stereotypeId)),
        filter(stereotype => Boolean(stereotype)),
        debounceTime(400),
        switchMap(stereotype => this.service.getPreviewStates(stereotype?.stateMachine?.stateMachineId, ticket?.currentState?.stateId).pipe(
          map(({ nextStates }) => this.actions.getPreviewStatesSuccess({ states: nextStates })),
          catchError(error => of(this.actions.error({ error, action: this.actions.getPreviewStates })))
        ))
      ))
    ));

    this.changeState$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.changeState),
      withLatestFrom(this.store.pipe(select(this.selectors.selectEntity))),
      exhaustMap(([{ state, reason, parentIds }, { ticketId }]) => this.service.changeState(
        ticketId,
        state.stateId,
        reason,
        parentIds
      ).pipe(
        map(apiEntity => this.actions.changeStateSuccess({ state })),
        catchError(error => of(this.actions.error({ error, action: this.actions.changeState })))
      ))
    ));

    this.changeStateSuccess$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.changeStateSuccess),
      tap(action => this.actionCallback(action, false))
    ), { dispatch: false });

    this.followUp$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.followUp),
      exhaustMap(({
                    stateId,
                    triggersAt,
                    parentIds
                  }) => this.ticketEventService.followUp(stateId, triggersAt, parentIds).pipe(
        map(() => this.actions.followUpSuccess({ stateId, triggersAt, parentIds })),
        catchError(error => of(this.actions.error({ error, action: this.actions.followUp })))
      ))
    ));

    this.followUpSuccess$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.followUpSuccess),
      tap(action => this.actionCallback(action, false))
    ), { dispatch: false });

    this.assignContactToMe$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.assignContactToMe),
      withLatestFrom(this.store.pipe(select(this.selectors.selectModel))),
      exhaustMap(([{ parentIds }, { ticketId }]) => this.service.assignToMe(ticketId, parentIds).pipe(
        withLatestFrom(this.store.pipe(select(authStore.selectors.selectActiveTenant))),
        map(([_, tenant]) => this.actions.assignContactToMeSuccess({
          contact: (tenant?.names ?? []).length ? tenant.names[0] : null,
          parentIds
        })),
        catchError(error => of(this.actions.error({ error, action: this.actions.assignContactToMe })))
      ))
    ));

    this.assignContactToMeSuccess$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.assignContactToMeSuccess),
      tap(action => this.actionCallback(action, false))
    ), { dispatch: false });

    this.assignContact$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.assignContact),
      withLatestFrom(this.store.pipe(select(this.selectors.selectModel))),
      exhaustMap(([{
        contact,
        parentIds
      }, { ticketId }]) => this.service.assignTo(ticketId, contact.contactId, parentIds).pipe(
        map(() => this.actions.assignContactSuccess({ contact, parentIds })),
        catchError(error => of(this.actions.error({ error, action: this.actions.assignContact })))
      ))
    ));

    this.assignContactSuccess$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.assignContactSuccess),
      tap(action => this.actionCallback(action, false))
    ), { dispatch: false });

    this.unassignContact$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.unassignContact),
      withLatestFrom(this.store.pipe(select(this.selectors.selectModel))),
      exhaustMap(([{ parentIds }, { ticketId }]) => this.service.unassign(ticketId, parentIds).pipe(
        map(() => this.actions.unassignContactSuccess({ parentIds })),
        catchError(error => of(this.actions.error({ error, action: this.actions.unassignContact })))
      ))
    ));

    this.unassignContactSuccess$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.unassignContactSuccess),
      tap(action => this.actionCallback(action, false))
    ), { dispatch: false });

    this.assignResource$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.assignResource),
      exhaustMap(({
                    ticketId,
                    resource,
                    isUpdateRelations
                  }) => this.service.assignResource(ticketId, resource?.resourceId, isUpdateRelations).pipe(
        map((newResource: ResourceDto) => this.actions.assignResourceSuccess({
          ticketId,
          resource: newResource,
          isUpdateRelations
        })),
        catchError(error => of(this.actions.error({ error, action: this.actions.assignResource })))
      ))
    ));

    this.assignResourceSuccess$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.assignResourceSuccess),
      tap(action => this.actionCallback(action, false))
    ), { dispatch: false });

    this.unassignResource$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.unassignResource),
      withLatestFrom(this.store.pipe(select(this.selectors.selectModel))),
      exhaustMap(([{ parentIds }, { ticketId }]) => this.service.unassignResource(ticketId, parentIds).pipe(
        map(() => this.actions.unassignResourceSuccess({ parentIds })),
        catchError(error => of(this.actions.error({ error, action: this.actions.unassignResource })))
      ))
    ));

    this.unassignResourceSuccess$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.unassignResourceSuccess),
      tap(action => this.actionCallback(action, false))
    ), { dispatch: false });

    this.export$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.export),
      withLatestFrom(this.store.pipe(select(this.selectors.selectModel))),
      exhaustMap(([{ templateId }, model]) => this.service.export(model?.ticketId, templateId).pipe(
        map(({ uri }) => this.actions.exportSuccess({ uri, templateId })),
        catchError(error => of(this.actions.error({ error, action: this.actions.export })))
      ))
    ));

    this.exportSuccess$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.exportSuccess),
      tap(action => this.actionCallback(action, false))
    ), { dispatch: false });
  }

  protected actionCallback(action: Action, isError: boolean): void {
    super.actionCallback(action, isError);

    this.checkAction(this.actions.changeStateSuccess, action, payload => this.changeStateSuccessActionCallback(payload));
    this.checkAction(this.actions.followUpSuccess, action, () => this.followUpSuccessActionCallback());
    this.checkAction(this.actions.assignContactToMeSuccess, action, () => this.assignToMeSuccessActionCallback());
    this.checkAction(this.actions.assignContactSuccess, action, ({ contact }) => this.assignToSuccessActionCallback(contact));
    this.checkAction(this.actions.unassignContactSuccess, action, () => this.unassignSuccessActionCallback());
    this.checkAction(this.actions.exportSuccess, action, ({ uri }) => this.exportSuccessActionCallback(uri));
    this.checkAction(this.actions.assignResourceSuccess, action, () => this.assignResourceSuccessCallback());
    this.checkAction(this.actions.unassignResourceSuccess, action, () => this.unassignSuccessActionCallback());
  }

  protected getStereotypeSuccessActionCallback(): void {
    super.getStereotypeSuccessActionCallback();

    this.store.dispatch(this.actions.getPreviewStates());
  }

  protected saveSuccessActionCallback(): void {
    super.saveSuccessActionCallback();

    this.store.dispatch(this.actions.getPreviewStates());
  }

  protected changeStateSuccessActionCallback(payload: TicketDetailXsStoreUpdateStateSuccessPayload): void {
    this.apiNotificationService.showTranslatedSuccess('tickets.toasts.state-changed', {
      name: payload.state.name
    });

    this.store.dispatch(this.actions.getPreviewStates());
  }

  protected followUpSuccessActionCallback(): void {
    this.apiNotificationService.showTranslatedSuccess('tickets.toasts.follow-up');
  }

  protected assignToMeSuccessActionCallback(): void {
    this.apiNotificationService.showTranslatedSuccess('tickets.toasts.assign-to-me');
  }

  protected assignToSuccessActionCallback(contact: ContactSimpleDto): void {
    this.apiNotificationService.showTranslatedSuccess('tickets.toasts.assign-to', { contact: contact.displayName });
  }

  protected unassignSuccessActionCallback(): void {
    this.apiNotificationService.showTranslatedSuccess('tickets.toasts.unassign');
  }

  protected assignResourceSuccessCallback(): void {
    this.apiNotificationService.showTranslatedSuccess('tickets.toasts.assign-resource');
  }

  protected exportSuccessActionCallback(uri: string): void {
    window.open(uri, '_blank');
  }

  protected modelUpdateActionCallback(payload: EntityXsStoreModelUpdatePayload<TicketDto>): void {
    super.modelUpdateActionCallback(payload);

    if (this.lastStereotypeId !== payload.model?.stereotypeId) {
      this.store.dispatch(this.actions.getPreviewStates());
      this.lastStereotypeId = payload.model?.stereotypeId;
    }
  }
}
