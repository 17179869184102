import {
  pathsDetailStore,
  PathsDetailStoreState,
  pathsListStore,
  PathsListStoreState,
  pathsProgressContactListStore,
  PathsProgressContactListStoreState,
  pathsProgressContactReadUnreadListStore,
  PathsProgressContactReadUnreadListStoreState,
  pathsProgressFunctionListStore,
  PathsProgressFunctionListStoreState,
  pathsProgressLocationListStore,
  PathsProgressLocationListStoreState
} from './stores';

export interface PathsState {
  pathsList: PathsListStoreState;
  pathsDetail: PathsDetailStoreState;

  pathsProgressFunctionList: PathsProgressFunctionListStoreState;
  pathsProgressLocationList: PathsProgressLocationListStoreState;
  pathsProgressContactList: PathsProgressContactListStoreState;
  pathsProgressContactReadUnreadList: PathsProgressContactReadUnreadListStoreState;
}

export const getInitialPathsState = (): PathsState => ({
  pathsList: pathsListStore.getInitialState(),
  pathsDetail: pathsDetailStore.getInitialState(),

  pathsProgressFunctionList: pathsProgressFunctionListStore.getInitialState(),
  pathsProgressLocationList: pathsProgressLocationListStore.getInitialState(),
  pathsProgressContactList: pathsProgressContactListStore.getInitialState(),
  pathsProgressContactReadUnreadList: pathsProgressContactReadUnreadListStore.getInitialState()
});
