import {holidaysDetailStore, HolidaysDetailStoreState, holidaysListStore, HolidaysListStoreState} from './stores';

export interface HolidaysState {
  holidaysList: HolidaysListStoreState;
  holidaysDetail: HolidaysDetailStoreState;
}

export const getInitialHolidaysState = (): HolidaysState => ({
  holidaysList: holidaysListStore.getInitialState(),
  holidaysDetail: holidaysDetailStore.getInitialState()
});
