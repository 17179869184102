<nexnox-web-cardbox [fullHeight]="true"
                    [fullWidth]="true"
                    [icon]="faList"
                    [noDividerMarginBottom]="true"
                    [noMarginBottom]="true"
                    [title]="'resources.subtitles.resource-list' | translate"
                    classes="d-flex flex-column"
                    paddingClasses="overflow-isHidden">

  <div class="refresh-btn">
    <button (click)="onRefresh()" [disabled]="listLoading$ | async" class="btn" type="button">
      <fa-icon [icon]="faSync" [spin]="listLoading$ | async" size="lg"></fa-icon>
    </button>
  </div>

  <div class="d-flex flex-column align-content-between h-100">

    <div class="resource-scrollbar w-100 flex-grow-1">
      <nexnox-web-hierarchical-tree-view #treeViewComponent
                                         (expandItem)="onExpandItem($event)"
                                         (moreItem)="onMoreItem($event)"
                                         (moreRoot)="onMore()"
                                         (selectItem)="selectItem.emit()"
                                         [disabled]="preloadSubject | async"
                                         [loading]="listLoading$ | async"
                                         [nextLoading]="nextLoading$ | async"
                                         [paging]="paging$ | async"
                                         [treeViewItems]="items$ | async">
      </nexnox-web-hierarchical-tree-view>
    </div>
    <div *ngIf="(listLoading$ | async) === false && (someItemsInProgress$ | async)"
         class="flex-shrink-1 w-100 align-self-end pt-2">
      <div (click)="toggleProgressMenu()"
           class="resource-menu-title d-flex justify-space-between pr-4 align-items-center cursor-pointer">
        <div>
          <span>{{ 'resources.subtitles.in-progress' | translate }}</span>
          <span>&nbsp;({{ inProgressIndicator$ | async }})</span>
        </div>
        <div *ngIf="isProgressMenuSubject.asObservable() | async">
          <fa-icon [icon]="faChevronUp"></fa-icon>
        </div>
        <div *ngIf="(isProgressMenuSubject.asObservable() | async) === false">
          <fa-icon [icon]="faChevronDown"></fa-icon>
        </div>
      </div>
      <div #boundingElement *ngIf="isProgressMenuSubject.asObservable() | async"
           class="w-100 tree-view progress-menu-content resource-scrollbar">
        <ng-container *ngFor="let item of itemsInProgress$ | async; trackBy: trackItemsById">
          <nexnox-web-hierarchical-tree-view-item #hierarchicalTreeViewItemComponent
                                                  (expandItem)="onExpandItem($event)"
                                                  (moreItem)="onMoreItem($event)"
                                                  [boundingElement]="boundingElement"
                                                  [item]="item"
                                                  [nestLevel]="0">
          </nexnox-web-hierarchical-tree-view-item>
        </ng-container>
      </div>
    </div>
  </div>
</nexnox-web-cardbox>

