import {ChangeDetectionStrategy, Component, Injector} from '@angular/core';
import {CorePortalEntityOverviewBaseComponent, DatatableActionButton} from '@nexnox-web/core-portal';
import {
  AppEntityType,
  ControllerOperationId,
  CoreSharedIssueLinkUtilityService,
  IssueListDto,
  Mappers,
  MissionIssueListDto
} from '@nexnox-web/core-shared';
import {missionIssueListStore} from "../../store";
import {faCircle} from '@fortawesome/free-solid-svg-icons/faCircle';
import {faExternalLinkAlt} from "@fortawesome/free-solid-svg-icons/faExternalLinkAlt";

@Component({
  selector: 'nexnox-web-issues-mission-issue-list',
  templateUrl: './mission-issue-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TechPortalFeatureMissionIssueListComponent extends CorePortalEntityOverviewBaseComponent<MissionIssueListDto> {

  public title = 'issues.subtitles.mission-issue-list';
  public createTitle = 'issues.subtitles.mission-issue-create';
  public idProperty = 'issueId';
  public displayProperty = 'issue.title';
  public pageOperation = ControllerOperationId.MissionIssueControllerId;
  public componentId = 'TechPortalFeatureMissionIssueListComponent';
  public enableViews = true;
  public datatableConfigName = 'MissionIssueDefault';
  public canDelete = false;

  public faCircle = faCircle;

  constructor(
    protected injector: Injector,
    private issueUtilityService: CoreSharedIssueLinkUtilityService
  ) {
    super(injector, missionIssueListStore, Mappers.MissionIssueListDto.serializedName, AppEntityType.Issue);
  }

  public detailFn = (row: IssueListDto): void => {
    this.tenantRouter.navigate(this.issueUtilityService.getIssueSegments(row)).then()
  };

  /* istanbul ignore next */
  public getRowActionButtons(): DatatableActionButton[] {
    return [
      {
        tooltip: 'core-shared.shared.actions.new-tab',
        icon: faExternalLinkAlt,
        link: (row: MissionIssueListDto) => this.issueUtilityService.getIssueLink(row),
        module: 'communication',
        target: '_blank'
      }
    ];
  }
}
