import {PagedEntitiesXsStore, PagedEntitiesXsStoreState} from '@nexnox-web/core-store';
import {AppEntityType, ReservedInventoryNumberDto} from '@nexnox-web/core-shared';
import {Action, createSelector} from '@ngrx/store';
import {selectInventoryNumbersState} from '../../inventory-numbers.selector';
import {CorePortalFeatureReservationService} from '../../services';
import {Injectable, Injector} from '@angular/core';

export interface ReservationListStoreState extends PagedEntitiesXsStoreState<ReservedInventoryNumberDto> {
}

/* istanbul ignore next */
export const reservationListStore = new PagedEntitiesXsStore<ReservedInventoryNumberDto>({
  actionLabel: 'Core Portal - Settings - Inventory Numbers - Reservation List',
  stateSelector: createSelector(selectInventoryNumbersState, state => state.reservationList),
  serviceType: CorePortalFeatureReservationService,
  entityType: AppEntityType.None,
  selectId: pattern => pattern.entity?.reservedInventoryNumberId ?? pattern.model?.reservedInventoryNumberId
});

export function reservationListStoreReducer(state: ReservationListStoreState, action: Action): any {
  return reservationListStore.reducer(state, action);
}

@Injectable()
export class ReservationListStoreEffects extends reservationListStore.effects {
  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }
}
