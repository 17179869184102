<ng-template #entityEditTemplate
             let-id="id"
             let-title="title">
  <nexnox-web-settings-manufacturer-edit #modelComponent
                                         (modelChange)="onModelChange($event)"
                                         [creating]="false"
                                         [id]="id"
                                         [loading]="loading$ | async"
                                         [model]="model$ | async"
                                         [readonly]="readonly$ | async"
                                         [stereotyped]="false"
                                         [title]="title">
  </nexnox-web-settings-manufacturer-edit>
</ng-template>

<nexnox-web-merge-manufacturer-sidebar #mergeManufacturerSidebar
                                       (merge)="mergeManufacturer($event)"
                                       [manufacturer]="manufacturerToMergeSubject">
</nexnox-web-merge-manufacturer-sidebar>

<nexnox-web-entity-detail [entityDetailBaseComponent]="this"
                          [entityEditTemplate]="entityEditTemplate"
                          [tabCommands]="['.']"
                          [tabHeading]="title | translate"
                          tabId="manufacturer">

  <!-- Missions Tab -->
  <ng-template (selectTab)="onNavigateToTab(['model'])"
               [heading]="'core-portal.settings.subtitles.model-list' | translate"
               [lazy]="true"
               nexnoxWebTabTemplate
               tabId="model">
    <nexnox-web-settings-model-list *ngIf="passedIds"
                                    [isEmbedded]="true"
                                    [parentIds]="passedIds">

    </nexnox-web-settings-model-list>
  </ng-template>
</nexnox-web-entity-detail>
