import {ChangeDetectionStrategy, Component, Injector} from '@angular/core';
import {
  ActionButton,
  CorePortalEntityOverviewBaseComponent,
  DatatableActionButton,
  DeleteEntityModel
} from '@nexnox-web/core-portal';
import {AppEntityType, AppPermissions, EscalationLevelDto, Mappers} from '@nexnox-web/core-shared';
import {escalationLevelListStore} from '../../store/stores';

@Component({
  selector: 'nexnox-web-ticket-settings-escalation-levels-escalation-level-list',
  templateUrl: './escalation-level-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EscalationLevelListComponent extends CorePortalEntityOverviewBaseComponent<EscalationLevelDto> {
  public title = 'core-portal.settings.subtitles.ticket-settings.escalation-level-list';
  public createTitle = 'core-portal.settings.subtitles.ticket-settings.escalation-level-create';
  public idProperty = 'escalationLevelId';
  public displayProperty = 'name';
  public datatableConfigName = 'SettingsTicketSettingsEscalationLevelDefault';
  public componentId = 'EscalationLevelListComponent';

  constructor(
    protected injector: Injector
  ) {
    super(injector, escalationLevelListStore, Mappers.EscalationLevelListDto.serializedName, AppEntityType.None);
  }

  /* istanbul ignore next */
  public getRowActionButtons(): DatatableActionButton[] {
    return this.getDefaultRowActionButtons(
      'core-portal.settings.actions.ticket-settings.edit-escalation-level',
      (row: EscalationLevelDto) => `/escalation-levels/${ row.escalationLevelId }`,
      [AppPermissions.UpdateEscalationLevel],
      {
        module: 'settings'
      }
    );
  }

  public getDeleteEntityModel(): DeleteEntityModel {
    return {
      titleKey: 'core-portal.settings.actions.ticket-settings.delete-escalation-level',
      descriptionKey: 'core-portal.settings.descriptions.ticket-settings.delete-escalation-level',
      confirmKey: 'core-portal.settings.actions.ticket-settings.delete-escalation-level',
      deletePermission: AppPermissions.DeleteEscalationLevel
    };
  }

  protected async getActionButtons(): Promise<ActionButton[]> {
    return this.getDefaultActionButtons(
      'core-portal.settings.actions.ticket-settings.create-escalation-level',
      AppPermissions.CreateEscalationLevel
    );
  }
}
