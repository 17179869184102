<ng-template #entityEditTemplate let-id="id" let-title="title">
  <nexnox-web-resource-tasks-resource-task-edit #modelComponent
                                                (modelChange)="onModelChange($event)"
                                                [creating]="false"
                                                [id]="id"
                                                [loading]="loading$ | async"
                                                [model]="model$ | async"
                                                [readonly]="readonly$ | async"
                                                [title]="title">
  </nexnox-web-resource-tasks-resource-task-edit>
</ng-template>

<nexnox-web-entity-detail [entityDetailBaseComponent]="this"
                          [entityEditTemplate]="entityEditTemplate"
                          [tabCommands]="['.']"
                          [tabHeading]="title | translate"
                          tabId="resourceTask">
  <!-- Resource Selection -->
  <ng-template (selectTab)="onNavigateToTab(['selection'])"
               [hasError]="isNoResourceFilter$ | async"
               [heading]="'resources.subtitles.resource-list' | translate"
               nexnoxWebTabTemplate
               tabId="resourceSelection">
    <nexnox-web-resource-tasks-resource-selection-edit (selectorChange)="onSelectorChange($event, model)"
                                                       *ngIf="model$ | async as model"
                                                       [canCreate]="false"
                                                       [canRouteToDetail]="false"
                                                       [isEmbedded]="true"
                                                       [loading]="loading$ | async"
                                                       [readonly]="readonly$ | async"
                                                       [selector]="model?.selector">
    </nexnox-web-resource-tasks-resource-selection-edit>
  </ng-template>

  <!-- Missions -->
  <ng-template (selectTab)="onNavigateToTab(['missions'])"
               *ngIf="canDisplayMissions$ | async"
               [heading]="'missions.subtitles.missions' | translate"
               [lazy]="true"
               nexnoxWebTabTemplate
               tabId="missions">
    <nexnox-web-resource-task-missions *ngIf="passedIds"
                                       [canCreate]="false"
                                       [getEntityOnModeChange]="false"
                                       [isEmbedded]="true"
                                       [parentIds]="passedIds">
    </nexnox-web-resource-task-missions>
  </ng-template>
</nexnox-web-entity-detail>
