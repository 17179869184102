<nexnox-web-cardbox *ngIf="withCardbox; else attachmentsTemplate"
                    [loading]="loading"
                    [title]="cardboxTitle | translate"
                    paddingClasses="px-3 pb-3">
  <ng-container *ngTemplateOutlet="attachmentsTemplate"></ng-container>
</nexnox-web-cardbox>

<ng-template #attachmentsTemplate>
  <nexnox-web-sortable-list (itemsChange)="onItemsChange($event)"
                            [disabled]="readonly || (attachmentsLoading$ | async)"
                            [hideItemName]="true"
                            [items]="items$ | async"
                            [sortable]="false">
    <ng-template let-item="item" nexnoxWebSortableListItemTemplate>
      <ng-container [ngTemplateOutletContext]="{ item: item }"
                    [ngTemplateOutlet]="attachmentItemTemplate"></ng-container>
    </ng-template>
  </nexnox-web-sortable-list>

  <ng-container *ngIf="!readonly">
    <div class="row align-items-center">

      <div class="col-md mt-3 pl-0 pr-0 pr-md-2">
        <div class="custom-file">
          <input #fileInput
                 (change)="onUploadFile($any($event.target).files)"
                 [disabled]="readonly || (loadingSubject | async) || (attachmentsLoading$ | async)"
                 accept=".pdf,.png,.jpg,.jpeg"
                 class="form-control-file custom-file-input"
                 id="fileInput"
                 type="file">
          <label class="custom-file-label" for="fileInput">
            <ng-container
              *ngIf="(selectedFileSubject | async) === null && (selectedFileAttachmentSubject | async) === null; else (selectedFileAttachmentSubject | async) ? selectedFileAttachmentTemplate : selectedFileTemplate">
              {{ 'core-shared.shared.actions.select-file' | translate }}...
            </ng-container>

            <ng-template #selectedFileAttachmentTemplate>
              {{ (selectedFileAttachmentSubject | async).file?.name }}
            </ng-template>

            <ng-template #selectedFileTemplate>
              {{ (selectedFileSubject | async).name }}
            </ng-template>
          </label>
        </div>
      </div>

      <div *ngIf="canAddExisting" class="col-md-3 mt-3 pl-0 pl-md-2 pr-0 pr-md-2">
        <button (click)="onAddExisting()"
                [disabled]="loading || (isInProgress$ | async) || (attachmentsLoading$ | async)"
                [label]="'tech-portal.actions.add-existing-attachment' | translate"
                class="p-button-secondary p-button-outlined w-100"
                pButton
                type="button">
        </button>
      </div>
    </div>

    <div *ngIf="progressSubject | async as progress" class="progress mt-3">
      <div [style.width.%]="progress * 100" class="progress-bar bg-primary">{{ progress | percent }}</div>
    </div>
  </ng-container>
</ng-template>

<ng-template #attachmentItemTemplate let-item="item">
  <div *ngIf="item?.file" class="d-flex w-100 flex-wrap">
    <div class="d-flex flex-grow-1 align-items-center">
      <div class="attachment-thumbnail">
        <fa-icon *ngIf="!isImage(item.file)" [fixedWidth]="true" [icon]="getFileImage(item.file)" size="lg"></fa-icon>
        <p-image *ngIf="isImage(item.file)" [alt]="item.file.name" [src]="item.file.uri"></p-image>
      </div>
      <a [href]="item.file.uri" target="_blank">{{ item.file.name }}</a>&nbsp;
      ({{ item.file.size | filesize }})
    </div>

    <div class="d-md-flex align-items-center d-none">
      <span>
        <span *ngIf="item.member">
          <span>{{ 'tech-portal.descriptions.in' | translate }} </span>
          <a [module]="item.member.module"
             [nexnoxWebTenantRouterLink]="item.member.link"
             target="'_blank'">
            {{ item.member.title }}
          </a>
        </span>

        <span>
          {{
            'core-shared.shared.time-phrases.at-date' | translate:{
              date: item.file.creationDateTime | nexnoxWebBsDate:'L LT' | async
            }
          }}
        </span>
      </span>
    </div>
  </div>
</ng-template>
