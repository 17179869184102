import {ChangeDetectionStrategy, Component, Injector, Input} from '@angular/core';
import {CorePortalEntityOverviewBaseComponent} from '@nexnox-web/core-portal';
import {AppEntityType, ArticleUsageListDto, Mappers} from '@nexnox-web/core-shared';
import {articleUsagesListStore} from '../../store';

@Component({
  selector: 'nexnox-web-articles-article-usages-list',
  templateUrl: './article-usages-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ArticleUsagesListComponent extends CorePortalEntityOverviewBaseComponent<ArticleUsageListDto> {

  @Input() public articleId: number | string;

  public title = 'article-usages.title';
  public idProperty = null;
  public displayProperty = null;
  public componentId = 'ArticleUsagesListComponent';
  public datatableConfigName = 'ArticleUsagesDefault';
  public isEmbedded = true;

  constructor(
    protected injector: Injector
  ) {
    super(injector, articleUsagesListStore, Mappers.ArticleUsageListDto.serializedName, AppEntityType.None);
  }
}
