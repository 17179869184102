<nexnox-web-entity-edit [entityEditBaseComponent]="this"
                        [isTitleEditable]="false"
                        displayKey="name">
</nexnox-web-entity-edit>

<nexnox-web-cardbox [loading]="loading"
                    [title]="'core-portal.settings.subtitles.ticket-settings.priority-set-items' | translate"
                    paddingClasses="px-3 pb-3">
  <div class="d-flex flex-row mb-3">
    <div class="d-flex flex-grow-1">
      <input #priorityNameControl
             (keyup.enter)="priorityName ? onAddPriority() : null"
             [(ngModel)]="priorityName"
             [disabled]="readonly"
             class="form-control"
             type="text">
    </div>

    <button (click)="onAddPriority()"
            [disabled]="!priorityName || readonly"
            class="btn btn-outline-primary ml-3">
      {{ 'core-shared.shared.select.add-item' | translate }}
    </button>
  </div>

  <nexnox-web-sortable-list (itemsChange)="onPrioritiesChange($event)"
                            [disabled]="readonly"
                            [editable]="true"
                            [items]="priorities$ | async"
                            [sortable]="false">
  </nexnox-web-sortable-list>
</nexnox-web-cardbox>
