<nexnox-web-tabs [internal]="true"
                 [isTransparent]="true"
                 startTab="issue-template-edit">

  <!-- Issue template edit tab -->
  <ng-template [hasError]="!isOwnModelValid()"
               [heading]="title | translate"
               nexnoxWebTabTemplate
               tabId="issue-template-edit">
    <nexnox-web-entity-edit [entityEditBaseComponent]="this" displayKey="name"></nexnox-web-entity-edit>

    <!-- Mission Content priorities -->
    <nexnox-web-cardbox *ngIf="isMissionEditorPrioritySearch"
                        [loading]="loading"
                        [title]="'core-portal.settings.subtitles.ticket-settings.priority-set-items' | translate"
                        paddingClasses="px-3 pb-3">
      <div *ngIf="!readonly && missionPriorityEntitySelectOptions"
           class="d-flex flex-row mb-3">
        <div class="d-flex flex-grow-1">
          <nexnox-web-entity-select [(model)]="selectedMissionPriorityItem"
                                    [disabled]="readonly"
                                    [options]="missionPriorityEntitySelectOptions"
                                    class="w-100">
          </nexnox-web-entity-select>
        </div>

        <button (click)="onAddMissionPriority()"
                [disabled]="!selectedMissionPriorityItem"
                class="btn btn-outline-primary ml-3">
          {{ 'core-shared.shared.select.add-item' | translate }}
        </button>
      </div>

      <nexnox-web-sortable-list (deleteItem)="onDeleteMissionPriority($event)"
                                [disabled]="readonly"
                                [items]="missionPriorities$ | async">
      </nexnox-web-sortable-list>
    </nexnox-web-cardbox>
  </ng-template>

  <!-- Issue template notification edit tab -->
  <ng-template [heading]="'core-portal.settings.issue-template.notifications' | translate"
               nexnoxWebTabTemplate
               tabId="notifications">
    <nexnox-web-settings-notification-issue (notificationsChanged)="onNotificationsChanged($event)"
                                            [issueContentType]="issueContentType"
                                            [loading]="loading"
                                            [notifications]="notifications"
                                            [readonly]="readonly">
    </nexnox-web-settings-notification-issue>
  </ng-template>

  <!-- Issue template published resources tab -->
  <ng-template *ngIf="readonly || !creating"
               [heading]="'core-portal.settings.issue-template.published-resources' | translate"
               nexnoxWebTabTemplate
               tabId="published-resources">
    <nexnox-web-settings-issue-published-resources [loading]="loading"
                                                   [readonly]="readonly"
                                                   [resources]="resources">
    </nexnox-web-settings-issue-published-resources>
  </ng-template>
</nexnox-web-tabs>
