<ng-template #entityEditTemplate let-id="id" let-title="title">
  <nexnox-web-articles-article-edit #modelComponent
                                    (modelChange)="onModelChange($event)"
                                    [creating]="false"
                                    [id]="id"
                                    [loading]="loading$ | async"
                                    [model]="model$ | async"
                                    [readonly]="readonly$ | async"
                                    [stereotypes$]="stereotypes$"
                                    [title]="title">
  </nexnox-web-articles-article-edit>
</ng-template>

<nexnox-web-entity-detail [entityDetailBaseComponent]="this"
                          [entityEditTemplate]="entityEditTemplate"
                          [ready$]="loaded$"
                          [tabCommands]="['.']"
                          [tabHeading]="title | translate"
                          tabId="article">

  <ng-container *ngIf="(model$ | async)?.articleId as articleId">
    <!-- Artikel Usages Tab -->
    <ng-template (selectTab)="onNavigateToTab(['usages'])"
                 [heading]="'article-usages.title' | translate"
                 nexnoxWebTabTemplate
                 tabId="usages">
      <nexnox-web-articles-article-usages-list [parentIds]="[articleId]">
      </nexnox-web-articles-article-usages-list>
    </ng-template>
  </ng-container>

</nexnox-web-entity-detail>
