<p-confirmPopup key="edit-parent"></p-confirmPopup>

<ng-template #editTemplate
             let-tabsComponent="tabsComponent">
  <nexnox-web-entity-edit [customTitle]="creating ? title : (customTitle$ | async)"
                          [entityEditBaseComponent]="this"
                          [inheritance]="true"
                          [tabsComponent]="tabsComponent"
                          [titleFieldFn]="titleFieldFn"
                          [useTitle]="true"
                          displayKey="name">

    <div *ngIf="model && !creating" class="d-flex w-100 justify-content-end text-muted p-2">
                <span [title]="model.lastModified | nexnoxWebBsDate:'L LT' | async">
                    {{ 'core-shared.shared.time-phrases.modified' | translate }} {{ model.lastModified | nexnoxWebDayjsRelative | async }}
                </span>
      ,&nbsp;
      <span [title]="model.createdAt | nexnoxWebBsDate:'L LT' | async">
                    {{ 'core-shared.shared.time-phrases.created' | translate }} {{ model.createdAt | nexnoxWebDayjsRelative | async }}
                </span>
    </div>
  </nexnox-web-entity-edit>
</ng-template>

<ng-container *ngIf="forceHideTabs" [ngTemplateOutletContext]="{ tabsComponent: null }"
              [ngTemplateOutlet]="editTemplate">
</ng-container>

<ng-container *ngIf="!forceHideTabs">
  <nexnox-web-tabs #tabsComponent
                   [isTransparent]="true"
                   startTab="entityEdit">
    <!-- Entity Edit Tab -->
    <ng-template (selectTab)="onNavigateToTab(['.'])"
                 [hasError]="!isOwnModelValid() ?? false"
                 [heading]="title | translate"
                 nexnoxWebTabTemplate
                 tabId="entityEdit">
      <ng-container [ngTemplateOutletContext]="{ tabsComponent }"
                    [ngTemplateOutlet]="editTemplate">
      </ng-container>
    </ng-template>

    <!-- Children Tab -->
    <ng-template (selectTab)="onNavigateToTab(['children'])"
                 *ngIf="!creating && shouldShowTab('children')"
                 [heading]="'resources.subtitles.resource-children' | translate"
                 [lazy]="true"
                 nexnoxWebTabTemplate
                 tabId="children">
      <nexnox-web-resources-resource-list [canCreate]="false"
                                          [getEntityOnModeChange]="false"
                                          [isEmbedded]="true"
                                          [parentResource]="model"
                                          componentId="ResourceDetailListChildrenComponent">
      </nexnox-web-resources-resource-list>
    </ng-template>

    <!-- Editor Suggestions Templates Tab -->
    <ng-template (selectTab)="onNavigateToTab(['editor-suggestions'])"
                 *ngIf="(readEditorSuggestionsPermission$ | async) && shouldShowTab('editor-suggestions')"
                 [heading]="'resources.subtitles.editor-suggestions' | translate"
                 nexnoxWebTabTemplate
                 tabId="editor-suggestions">
      <nexnox-web-resources-editor-suggestions #editorSuggestionsComponent
                                               (editorSuggestionsChanged)="onEditorSuggestionsChanged($event)"
                                               [alternativeSuggestions$]="alternativeEditorSuggestions$"
                                               [loading]="loading"
                                               [primarySuggestions$]="primaryEditorSuggestions$"
                                               [readonly]="readonly"
                                               [resourceSubject]="resourceSubject"
                                               [stereotypeId$]="selectedStereotypeId$">
      </nexnox-web-resources-editor-suggestions>
    </ng-template>

    <!-- Escalation Rules Tab -->
    <ng-template (selectTab)="onNavigateToTab(['escalation-rules'])"
                 *ngIf="(readEscalationRulesPermission$ | async) && shouldShowTab('escalation-rules')"
                 [hasError]="escalationRulesComponent ? !escalationRulesComponent.isModelValid() : false"
                 [heading]="'resources.subtitles.escalation-rules' | translate"
                 nexnoxWebTabTemplate
                 tabId="escalation-rules">
      <nexnox-web-resources-escalation-rules #escalationRulesComponent
                                             (rulesChange)="onEscalationRulesChange($event)"
                                             [loading]="loading"
                                             [readonly]="readonly"
                                             [rules$]="escalationRules$">
      </nexnox-web-resources-escalation-rules>
    </ng-template>

    <!-- Links -->
    <ng-template (selectTab)="onNavigateToTab(['links'])"
                 *ngIf="(readLinksPermission$ | async) && shouldShowTab('links')"
                 [hasError]="linksComponent ? !linksComponent.isModelValid() : false"
                 [heading]="('tech-portal.subtitles.links' | translate) + (linksComponent ? (linksComponent.linksString$ | async) : '')"
                 nexnoxWebTabTemplate
                 tabId="links">
      <nexnox-web-tech-links #linksComponent
                             (linksChange)="onLinksChange($event)"
                             [entityId]="model?.resourceId"
                             [links$]="links$"
                             [loading]="loading"
                             [readonly]="readonly"
                             [type]="linkedElementTypes.Resource">
      </nexnox-web-tech-links>
    </ng-template>

    <!-- Attachments -->
    <ng-template (selectTab)="onNavigateToTab(['attachments'])"
                 *ngIf="(readAttachmentsPermission$ | async) && shouldShowTab('attachments')"
                 [hasError]="attachmentsComponent ? !attachmentsComponent.isModelValid() : false"
                 [heading]="('tech-portal.subtitles.attachments' | translate) + (attachmentsComponent ? (attachmentsComponent.attachmentsString$ | async) : '')"
                 nexnoxWebTabTemplate
                 tabId="attachments">
      <nexnox-web-tech-attachments #attachmentsComponent
                                   (attachmentsChange)="onAttachmentsChange($event)"
                                   [attachments$]="attachments$"
                                   [loading]="loading"
                                   [readonly]="readonly">
      </nexnox-web-tech-attachments>
    </ng-template>

    <!-- Tickets -->
    <ng-template (selectTab)="onNavigateToTab(['tickets'])"
                 *ngIf="!creating && (readTicketsPermission$ | async) && shouldShowTab('tickets')"
                 [heading]="'tickets.title' | translate"
                 [lazy]="true"
                 nexnoxWebTabTemplate
                 tabId="tickets">
      <nexnox-web-resources-resource-tickets *ngIf="model"
                                             [descendantId]="model.resourceId"
                                             [isEmbedded]="true"
                                             [parentIds]="[model.resourceId]">
      </nexnox-web-resources-resource-tickets>
    </ng-template>

    <!-- Missions -->
    <ng-template (selectTab)="onNavigateToTab(['missions'])"
                 *ngIf="!creating && (readMissionsPermission$ | async) && shouldShowTab('missions')"
                 [heading]="'missions.title' | translate"
                 [lazy]="true"
                 nexnoxWebTabTemplate
                 tabId="missions">
      <nexnox-web-resources-resource-missions *ngIf="model"
                                              [descendantId]="model.resourceId"
                                              [isEmbedded]="true"
                                              [parentIds]="[model.resourceId]">
      </nexnox-web-resources-resource-missions>
    </ng-template>

    <!-- Issues -->
    <ng-template (selectTab)="onNavigateToTab(['issues'])"
                 *ngIf="!creating && (readIssuesPermission$ | async) && shouldShowTab('issues')"
                 [heading]="'issues.title' | translate"
                 [lazy]="true"
                 nexnoxWebTabTemplate
                 tabId="issues">
      <nexnox-web-resources-resource-issues *ngIf="model"
                                            [descendantId]="model.resourceId"
                                            [isEmbedded]="true"
                                            [parentIds]="[model.resourceId]">
      </nexnox-web-resources-resource-issues>
    </ng-template>

    <!-- Connected -->
    <ng-template (selectTab)="onNavigateToTab(['connected'])"
                 *ngIf="!creating && (displayConnectedDefinitionPermission$ | async) && shouldShowTab('connected')"
                 [heading]="'tech-portal.connected.title' | translate"
                 [lazy]="true"
                 nexnoxWebTabTemplate
                 tabId="connected">
      <nexnox-web-resources-resource-connected *ngIf="model"
                                               [parentIds]="[model.resourceId]">
      </nexnox-web-resources-resource-connected>
    </ng-template>

    <!-- Control Protocol -->
    <ng-template (selectTab)="onNavigateToTab(['control-protocol'])"
                 *ngIf="!creating && (displayControlProtocolPermission$ | async) && shouldShowTab('control-protocol')"
                 [heading]="'tech-portal.control-protocol.title' | translate"
                 [lazy]="true"
                 nexnoxWebTabTemplate
                 tabId="control-protocol">
      <nexnox-web-resources-resource-control-protocol *ngIf="model"
                                                      [parentIds]="[model.resourceId]">
      </nexnox-web-resources-resource-control-protocol>
    </ng-template>

    <!-- Audit -->
    <ng-template (selectTab)="onNavigateToTab(['audit'])"
                 *ngIf="!creating && (readAuditPermission$ | async) && shouldShowTab('audit')"
                 [heading]="'core-portal.audit.title' | translate"
                 [lazy]="true"
                 nexnoxWebTabTemplate
                 tabId="audit">
      <nexnox-web-resources-resource-audit [isEmbedded]="true"
                                           [parentIds]="[entityTypes.Resource, model.resourceId]">
      </nexnox-web-resources-resource-audit>
    </ng-template>

    <!-- Knowledge Articles -->
    <ng-template (selectTab)="onNavigateToTab(['knowledge-articles'])"
                 *ngIf="!creating && (readAuditPermission$ | async) && shouldShowTab('knowledge-articles')"
                 [heading]="'orga-portal.knowledge.subtitles.article-list' | translate"
                 [lazy]="true"
                 nexnoxWebTabTemplate
                 tabId="knowledge-articles">
      <nexnox-web-resources-resource-articles (articlesChange)="onArticlesChange($event)"
                                              [articlesSubject]="articlesSubject"
                                              [loading]="loading"
                                              [readonly]="readonly"
                                              [resourceSubject]="resourceSubject">
      </nexnox-web-resources-resource-articles>
    </ng-template>

    <!-- Issue Templates -->
    <ng-template (selectTab)="onNavigateToTab(['issue-templates'])"
                 *ngIf="!creating && (readIssueTemplatesPermission$ | async) && shouldShowTab('issue-templates')"
                 [heading]="'core-portal.settings.issue-template.titles.list' | translate"
                 [lazy]="true"
                 nexnoxWebTabTemplate
                 tabId="issue-templates">
      <nexnox-web-resources-resource-issue-templates (issueTemplatesChange)="onIssueTemplatesChange($event)"
                                                     [issueTemplatesSubject]="issueTemplatesSubject"
                                                     [loading]="loading"
                                                     [readonly]="readonly"
                                                     [resourceSubject]="resourceSubject">
      </nexnox-web-resources-resource-issue-templates>
    </ng-template>

    <!-- Quick Access -->
    <ng-template (selectTab)="onNavigateToTab(['quick-access'])"
                 *ngIf="!creating && (displayQuickAccessConfigPermission$ | async) && shouldShowTab('quick-access')"
                 [hasError]="quickAccessComponent ? !quickAccessComponent.isModelValid() : false"
                 [heading]="'resources.subtitles.quick-access' | translate"
                 [lazy]="true"
                 nexnoxWebTabTemplate
                 tabId="quick-access">
      <nexnox-web-resources-resource-quick-access #quickAccessComponent
                                                  (quickAccessChange)="onQuickAccessChange($event)"
                                                  [loading]="loading"
                                                  [readonly]="readonly"
                                                  [resourceSubject]="resourceSubject">
      </nexnox-web-resources-resource-quick-access>
    </ng-template>


  </nexnox-web-tabs>
</ng-container>

<!-- Location Templates -->
<ng-template #locationSelectLabelTitleTemplate
             let-item="item">
  <nexnox-web-master-data-locations-location-location-number [label]="true"
                                                             [location]="item">
  </nexnox-web-master-data-locations-location-location-number>
</ng-template>

<ng-template #locationSelectOptionTitleTemplate
             let-item="item"
             let-searchTerm="searchTerm">
  <nexnox-web-master-data-locations-location-location-number [label]="false"
                                                             [location]="item"
                                                             [searchTerm]="searchTerm">
  </nexnox-web-master-data-locations-location-location-number>
</ng-template>

<!-- Resource Templates -->
<ng-template #resourceSelectLabelTitleTemplate
             let-item="item">
  <nexnox-web-resources-resource-inventory-number [label]="true"
                                                  [resource]="item">
  </nexnox-web-resources-resource-inventory-number>
</ng-template>

<ng-template #resourceSelectOptionTitleTemplate
             let-item="item"
             let-searchTerm="searchTerm">
  <nexnox-web-resources-resource-inventory-number [label]="false"
                                                  [resource]="item"
                                                  [searchTerm]="searchTerm">
  </nexnox-web-resources-resource-inventory-number>
</ng-template>

<ng-template #resourceSelectOptionTemplate
             let-item="item">
  <br>
  <nexnox-web-resources-resource-path [resource]="item"></nexnox-web-resources-resource-path>
</ng-template>

<ng-template #locationGroupDescriptionTemplate let-item="item">
  <span class="small mt-1">{{ 'resources.descriptions.virtual-resource-description' | translate }}</span>
</ng-template>
