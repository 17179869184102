import {InjectionToken, NgModule} from '@angular/core';
import {ActionReducerMap, StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {infosEffects, infosReducers} from './store';

export const REDUCER_TOKEN = new InjectionToken<ActionReducerMap<any>>(
  '@nexnox-web/orga-portal/infos: Store Reducers'
);

@NgModule({
  imports: [
    StoreModule.forFeature('orga-portal-feature-infos', REDUCER_TOKEN),
    EffectsModule.forFeature(infosEffects)
  ],
  providers: [
    {
      provide: REDUCER_TOKEN,
      useValue: infosReducers
    }
  ]
})
export class InfosStoreModule {
}
