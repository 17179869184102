import {InjectionToken, NgModule} from '@angular/core';
import {ActionReducerMap, StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {processesEffects, processesReducers} from './store';

export const REDUCER_TOKEN = new InjectionToken<ActionReducerMap<any>>(
  '@nexnox-web/tech-portal/ticket-settings/processes: Store Reducers'
);

@NgModule({
  imports: [
    StoreModule.forFeature('tech-portal-feature-ticket-settings-processes', REDUCER_TOKEN),
    EffectsModule.forFeature(processesEffects)
  ],
  providers: [
    {
      provide: REDUCER_TOKEN,
      useValue: processesReducers
    }
  ]
})
export class ProcessesStoreModule {
}
