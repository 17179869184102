import {EntityXsStore, EntityXsStoreState} from '@nexnox-web/core-store';
import {AppEntityType, CompanyDto} from '@nexnox-web/core-shared';
import {Action, createSelector} from '@ngrx/store';
import {selectCompaniesState} from '../../companies.selectors';
import {CorePortalFeatureMasterDataCompanyService} from '../../services';
import {Injectable, Injector} from '@angular/core';

export interface CompanyDetailStoreState extends EntityXsStoreState<CompanyDto> {
}

export const companyDetailStore = new EntityXsStore<CompanyDto>({
  actionLabel: 'Core Portal - Master Data - Company Detail',
  stateSelector: createSelector(selectCompaniesState, state => state.companyDetail),
  serviceType: CorePortalFeatureMasterDataCompanyService,
  entityType: AppEntityType.Company
});

export function companyDetailStoreReducer(state: CompanyDetailStoreState, action: Action): any {
  return companyDetailStore.reducer(state, action);
}

@Injectable()
export class CompanyDetailStoreEffects extends companyDetailStore.effects {
  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }
}
