import {ChangeDetectionStrategy, Component, Injector, TemplateRef, ViewChild} from '@angular/core';
import {
  CorePortalEntityEditBaseComponent,
  CorePortalFormlyReadonlyTypes,
  CorePortalFormlyReadonlyTyping
} from '@nexnox-web/core-portal';
import {DeviceDto, LocationDto} from '@nexnox-web/core-shared';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {
  CorePortalFeatureMasterDataLocationService,
  noClosedLocationsFilter$
} from '@nexnox-web/core-portal/features/master-data/features/locations';
import {platformEnumOptions} from '../../models';

@Component({
  selector: 'nexnox-web-devices-device-edit',
  templateUrl: './device-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrgaPortalFeatureDeviceEditComponent extends CorePortalEntityEditBaseComponent<DeviceDto> {
  @ViewChild('locationSelectLabelTitleTemplate', { static: true }) public locationSelectLabelTitleTemplate: TemplateRef<any>;
  @ViewChild('locationSelectOptionTitleTemplate', { static: true }) public locationSelectOptionTitleTemplate: TemplateRef<any>;

  constructor(
    protected injector: Injector,
    private locationService: CorePortalFeatureMasterDataLocationService
  ) {
    super(injector);
  }

  /* istanbul ignore next */
  protected createForm(): FormlyFieldConfig[] {
    return [
      {
        key: 'name',
        type: 'input',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.name',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.BASIC
          } as CorePortalFormlyReadonlyTyping,
          type: 'text'
        },
        expressionProperties: {
          'templateOptions.required': () => this.creating && !this.readonly,
          'templateOptions.disabled': () => !this.creating || this.readonly,
          'templateOptions.readonly': () => !this.creating || this.readonly
        },
        hideExpression: () => !this.creating
      },
      { key: 'locationId' },
      {
        key: 'location',
        type: 'core-portal-entity-select',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        defaultValue: null,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.location',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.ENTITY,
            displayKey: 'name',
            link: (location: LocationDto) => location?.locationId ? ['/masterdata', 'locations', location.locationId] : null
          } as CorePortalFormlyReadonlyTyping,
          entityService: this.locationService,
          idKey: 'locationId',
          displayKey: 'name',
          wholeObject: true,
          skipGetOne: true,
          mapSearchFilter: CorePortalFeatureMasterDataLocationService.mapSearchFilter,
          search: CorePortalFeatureMasterDataLocationService.searchCompare,
          link: (location: LocationDto) => location?.locationId ? ['/masterdata', 'locations', location.locationId] : null,
          selectLabelTitleTemplate: this.locationSelectLabelTitleTemplate,
          selectOptionTitleTemplate: this.locationSelectOptionTitleTemplate,
          defaultFilters$: noClosedLocationsFilter$
        },
        expressionProperties: {
          'templateOptions.required': () => this.creating && !this.readonly,
          'templateOptions.disabled': () => !this.creating || this.readonly,
          'templateOptions.readonly': () => !this.creating || this.readonly
        },
        hooks: {
          onInit: field => this.subscribe(field.formControl.valueChanges, (value: LocationDto) => {
            this.form.controls.locationId.setValue(value?.locationId ?? null);
          })
        }
      },
      {
        key: 'platform',
        type: 'core-portal-ng-select',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.platform'
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.ENUM,
            enumOptions: platformEnumOptions,
            translate: true,
            hasValue: true
          } as CorePortalFormlyReadonlyTyping,
          corePortalNgSelect: {
            items: platformEnumOptions,
            translate: true
          },
          readonly: true
        },
        hideExpression: () => this.creating
      },
      {
        key: 'isRegisteredForPush',
        type: 'core-portal-switch',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        templateOptions: {
          corePortalTranslated: {
            label: 'orga-portal.devices.fields.push-notifications'
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.BOOLEAN
          } as CorePortalFormlyReadonlyTyping,
          readonly: true
        },
        hideExpression: () => this.creating
      }
    ];
  }
}
