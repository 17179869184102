<p-dropdown (ngModelChange)="onTenantChange($event)"
            [disabled]="loading$ | async"
            [emptyFilterMessage]="'core-shared.shared.table.empty' | translate"
            [filter]="true"
            [ngModel]="activeTenantItem$ | async"
            [options]="tenantItems$ | async"
            [pTooltip]="'core-portal.core.header.tooltip.change-role' | translate"
            [tooltipDisabled]="embedded"
            styleClass="w-100"
            tooltipPosition="left">

  <ng-template pTemplate="selectedItem">
    {{ (activeTenant$ | async)?.name }}
  </ng-template>

  <ng-template let-item pTemplate="item">
    <span>{{ item.label }}</span>
  </ng-template>

</p-dropdown>
