import {CoreSharedApiBaseService} from '../api-base/api-base.service';
import {Product} from '../../models';
import {Injectable, Injector} from '@angular/core';

@Injectable()
export class CoreSharedKnowledgeArticleService extends CoreSharedApiBaseService {
  constructor(
    protected injector: Injector
  ) {
    super(injector, Product.ORGA, 'v3/knowledgearticle');
  }
}
