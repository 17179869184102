<p-sidebar #sidebarComponent
           (visibleChange)="onVisibleChange($event)"
           [transitionOptions]="transitionOptions"
           [visible]="visible$ | async"
           appendTo="body"
           position="right"
           styleClass="p-sidebar-md p-2">

  <h4 class="mb-4">{{ 'missions.calendar-subscription.title' | translate }}</h4>

  <ng-container *ngIf="subscriptionLink$ | async as link else loadingIconTemplate">
    <div class="alert alert-light">
      <p>{{ 'missions.calendar-subscription.info' | translate }}</p>
      <p>{{ 'missions.calendar-subscription.warning' | translate }}</p>
    </div>
    <textarea [autoResize]="true" [readOnly]="true" [value]="link" class="mb-4" cols="30" pInputTextarea
              rows="1"></textarea>
    <button (click)="onCopy(link)"
            class="btn btn-primary w-50"
            type="button">
      <fa-icon [fixedWidth]="true" [icon]="faClipboard" size="lg"></fa-icon>
      {{ 'missions.calendar-subscription.copy-link' | translate }}
    </button>
  </ng-container>

</p-sidebar>

<ng-template #loadingIconTemplate>
  <div *ngIf="loadingError$ | async; else loading">
    {{ 'missions.calendar-subscription.loading-error' | translate }}
  </div>
  <ng-template #loading>
    <nexnox-web-loading-icon class="d-flex w-100 h-100 align-items-center justify-content-center flex-column p-5"
                             size="5x">
    </nexnox-web-loading-icon>
  </ng-template>
</ng-template>
