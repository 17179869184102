import {ChangeDetectionStrategy, Component, Injector} from '@angular/core';
import {ActionButton, CorePortalEntityDetailBaseComponent} from '@nexnox-web/core-portal';
import {AppPermissions, DashboardDto, ExportType} from '@nexnox-web/core-shared';
import {dashboardDetailStore} from '../../store/stores';

@Component({
  selector: 'nexnox-web-settings-dashboards-dashboard-detail',
  templateUrl: './dashboard-detail.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardDetailComponent extends CorePortalEntityDetailBaseComponent<DashboardDto> {
  public title = 'core-portal.settings.subtitles.dashboard-detail';

  protected idParam = 'dashboardId';
  protected displayKey = 'name';

  constructor(
    protected injector: Injector
  ) {
    super(injector, dashboardDetailStore);
  }

  protected async getActionButtons(): Promise<ActionButton[]> {
    return this.getDefaultActionButtons(
      'core-portal.settings.actions.edit-dashboard',
      'core-portal.settings.actions.save-dashboard',
      'core-portal.settings.actions.delete-dashboard',
      'core-portal.settings.descriptions.delete-dashboard',
      AppPermissions.UpdateDashboard,
      AppPermissions.DeleteDashboard,
      undefined,
      undefined,
      undefined,
      ExportType.DASHBOARD
    );
  }
}
