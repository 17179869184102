import {InjectionToken, NgModule} from '@angular/core';
import {ActionReducerMap, StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {functionsEffects, functionsReducers} from './store';

export const REDUCER_TOKEN = new InjectionToken<ActionReducerMap<any>>(
  '@nexnox-web/core-portal/master-data/functions: Store Reducers'
);

@NgModule({
  imports: [
    StoreModule.forFeature('core-portal-feature-master-data-functions', REDUCER_TOKEN),
    EffectsModule.forFeature(functionsEffects)
  ],
  providers: [
    {
      provide: REDUCER_TOKEN,
      useValue: functionsReducers
    }
  ]
})
export class FunctionsStoreModule {
}
