import {ControlConditionType, ControlTriggerType} from "@nexnox-web/core-shared";

export const controlConditionTypeEnumOptions = [
  { value: ControlConditionType.FixedTime, label: 'controls.control-condition-types.2' },
  { value: ControlConditionType.Consumption, label: 'controls.control-condition-types.3' },
  { value: ControlConditionType.Error, label: 'controls.control-condition-types.5' }
];

export const controlConsumptionTriggerTypeEnumOptions = [
  { label: 'controls.consumption-trigger-types.2', value: ControlTriggerType.Stepped },
  { label: 'controls.consumption-trigger-types.3', value: ControlTriggerType.OutOfRange }
];
