import {ModuleWithProviders, NgModule} from '@angular/core';
import {TenantsDeclarationsModule} from './tenants-declarations.module';
import {TenantsStoreModule} from './tenants-store.module';
import {TenantsRoutingModule} from './tenants-routing.module';

@NgModule({
  imports: [
    TenantsDeclarationsModule,
    TenantsStoreModule,
    TenantsRoutingModule
  ]
})
export class CorePortalFeatureServiceTenantsModule {
  public static forFeature(): ModuleWithProviders<TenantsDeclarationsModule> {
    return { ngModule: TenantsRoutingModule };
  }
}
