import {
  AppEntityType,
  CustomDefaultDto,
  CustomPropertyType,
  Mappers,
  X_API_ENTITY_TYPE,
  X_API_FILTERABLE,
  X_API_OPTIONAL
} from '@nexnox-web/core-shared';
import {CompositeMapper, CompositeMapperType, Mapper} from '@azure/ms-rest-js';
import {MapperMetadata, MapperMetadataModelType, MapperMetadataProperty} from '../../models';
import {flatten, keys, uniqBy} from 'lodash';

export interface DatatablePreColumn {
  name: string;
  serializedName?: string;
  optional?: boolean;
  filterable?: boolean;
  sortable?: boolean;
  entityType?: AppEntityType;
  customPropertyId?: number;
  customPropertyType?: CustomPropertyType;
  defaultValues?: CustomDefaultDto[];
  isOfArchivedStereotype?: boolean;
  clickable?: boolean;
}

interface ProcessedProperty {
  path: string,
  serializedNames: string[];
  hasParent: boolean;
}

export function getAllNestedProperties(mapper: CompositeMapper, metadata: MapperMetadata): {
  name: string,
  serializedName?: string,
  optional: boolean,
  sortable: boolean,
  filterable: boolean,
  entityType: AppEntityType
}[] {
  const mapperMetadata = metadata.modelTypes.find(x => x.serializedName === mapper.serializedName);
  const properties = processMapper(mapper);
  addParentProperties(properties);

  return properties.map(property => {
    const propertyPathLength = property.path.split('.').length;
    let modelMetadata: MapperMetadataModelType;
    let propertyMetadata: MapperMetadataProperty & { dto: string };

    if (property.serializedNames.length > 1 || (propertyPathLength > 1 && propertyPathLength < 3)) {
      if (property.path.split('.').length > property.serializedNames.length) {
        modelMetadata = metadata.modelTypes.find(
          x => x.serializedName === property.serializedNames[property.serializedNames.length - 1]
        );
      } else {
        modelMetadata = metadata.modelTypes.find(
          x => x.serializedName === property.serializedNames[property.serializedNames.length - 2]
        );
      }

      propertyMetadata = modelMetadata ? {
        ...modelMetadata.properties.find(
          x => x.serializedName === property.path.substring(property.path.lastIndexOf('.') + 1, property.path.length)
        ),
        serializedName: property.path,
        dto: property.serializedNames[(property.path.split('.').length ?? 2) - 2]
      } : null;
    } else {
      propertyMetadata = {
        ...mapperMetadata.properties.find(x => x.serializedName === property.path),
        dto: mapper.serializedName
      };
    }

    if (propertyMetadata) {
      const propertyKey = propertyMetadata.serializedName?.substring(propertyMetadata.serializedName.lastIndexOf('.') + 1, propertyMetadata.serializedName.length);
      const propertyMapper = Mappers[propertyMetadata.dto];
      const propertyType = propertyMapper.type.modelProperties[propertyKey];

      return {
        name: propertyMetadata.serializedName,
        serializedName: propertyType?.type?.className ?? undefined,
        optional: isAnyParentOptional(property, metadata) ||
          (propertyMetadata.extensions ? Boolean(propertyMetadata.extensions[X_API_OPTIONAL]) : false),
        sortable: !property.hasParent,
        filterable: propertyMetadata.extensions ? Boolean(propertyMetadata.extensions[X_API_FILTERABLE]) : false,
        entityType: propertyMetadata.extensions ? propertyMetadata.extensions[X_API_ENTITY_TYPE] : AppEntityType.None
      };
    }

    return null;
  });
}

function processMapper(mapper: Mapper, previous: string = '', serializedNames: string[] = []): ProcessedProperty[] {
  if ((mapper as CompositeMapper).type?.modelProperties) {
    return flatten(keys((mapper as CompositeMapper).type.modelProperties)
      .map(key => (mapper as CompositeMapper).type.modelProperties[key])
      .map(property => processMapper(property, previous, serializedNames)));
  } else if ((mapper.type as CompositeMapperType)?.className) {
    const newMapper = Mappers[(mapper.type as CompositeMapperType)?.className];
    return processMapper(
      newMapper,
      `${ previous }${ mapper.serializedName }.`,
      newMapper ? [...serializedNames, newMapper.serializedName] : serializedNames
    );
  } else {
    return [{ path: `${ previous }${ mapper.serializedName }`, serializedNames, hasParent: Boolean(previous) }];
  }
}

function addParentProperties(properties: ProcessedProperty[]): void {
  for (const property of uniqBy(properties.filter(x => x.serializedNames.length), 'serializedNames')) {
    properties.push({
      path: property.path.substring(0, property.path.lastIndexOf('.')),
      serializedNames: property.serializedNames,
      hasParent: property.hasParent
    });
  }
}

function isAnyParentOptional(property: ProcessedProperty, metadata: MapperMetadata): boolean {
  const parentExtensions = property.serializedNames.map((name, index) => {
    const parts = property.path.split('.');
    parts.splice(0, 1);
    return metadata.modelTypes.find(x => x.serializedName === name).properties
      .find(x => x.serializedName === parts[index])?.extensions;
  });
  return parentExtensions.some(x => x ? x[X_API_FILTERABLE] : false);
}
