import {ReplaySubject} from 'rxjs';
import {UnsubscribeHelper} from '../../../helper';

export class SnapElement extends UnsubscribeHelper {
  public element: any;
  protected elementMap: Map<SnapElement, any>;
  protected hoverInSubject: ReplaySubject<void>;
  protected hoverOutSubject: ReplaySubject<void>;
  protected clickSubject: ReplaySubject<void>;

  constructor(
    x: number,
    y: number,
    parent: SnapElement = null
  ) {
    super();

    this._x = x;
    this._y = y;
    this._parent = parent;
    this.elementMap = new Map<SnapElement, any>();
  }

  public get absoluteX(): number {
    return this._parent ? this._parent.absoluteX + this._x : this._x;
  }

  public get absoluteY(): number {
    return this._parent ? this._parent.absoluteY + this._y : this._y;
  }

  public get centerPoint(): { x: number, y: number } {
    return { x: this._x, y: this._y };
  }

  protected _x: number;

  public get x(): number {
    return this._x;
  }

  public set x(x: number) {
    this._x = x;
    this.updateAttributes();
  }

  protected _y: number;

  public get y(): number {
    return this._y;
  }

  public set y(y: number) {
    this._y = y;
    this.updateAttributes();
  }

  protected _parent: SnapElement;

  public get parent(): SnapElement {
    return this._parent;
  }

  public set parent(parent: SnapElement) {
    this._parent = parent;
    this.updateAttributes();
  }

  protected _fill: string;

  public get fill(): string {
    return this._fill;
  }

  public set fill(fill: string) {
    this._fill = fill;
    this.updateAttributes();
  }

  protected _stroke: string;

  public get stroke(): string {
    return this._stroke;
  }

  public set stroke(stroke: string) {
    this._stroke = stroke;
    this.updateAttributes();
  }

  protected _strokeWidth: number;

  public get strokeWidth(): number {
    return this._strokeWidth;
  }

  public set strokeWidth(strokeWidth: number) {
    this._strokeWidth = strokeWidth;
    this.updateAttributes();
  }

  public createSnapElement(snapPaper: any): any {
    this.element = this.createElement(snapPaper);
    this.updateAttributes();
    return this.element;
  }

  public remove(): void {
    this.element.remove();
  }

  public updateAttributes(): void {
    this.element.attr({
      x: this.absoluteX,
      y: this.absoluteY,
      fill: this._fill,
      stroke: this._stroke,
      strokeWidth: this._strokeWidth
    });
  }

  public addClass(value: string): void {
    this.element.addClass(value);
  }

  public removeClass(value: string): void {
    this.element.removeClass(value);
  }

  public onHoverIn(fn: () => void): void {
    if (!this.hoverInSubject) {
      this.prepareHoverListener();
    }

    this.subscribe(this.hoverInSubject, () => fn());
  }

  public onHoverOut(fn: () => void): void {
    if (!this.hoverOutSubject) {
      this.prepareHoverListener();
    }

    this.subscribe(this.hoverOutSubject, () => fn());
  }

  public onClick(fn: () => void): void {
    if (!this.clickSubject) {
      this.prepareClickListener();
    }

    this.subscribe(this.clickSubject, () => fn());
  }

  protected createElement(snapPaper: any): any {
    return snapPaper.rect(this.absoluteX, this.absoluteY, 0, 0);
  }

  protected prepareHoverListener(): void {
    this.hoverInSubject = new ReplaySubject<void>();
    this.hoverOutSubject = new ReplaySubject<void>();
    this.element.hover(
      () => this.hoverInSubject.next(),
      () => this.hoverOutSubject.next()
    );
  }

  protected prepareClickListener(): void {
    this.clickSubject = new ReplaySubject<void>();
    this.element.click(() => this.clickSubject.next());
  }
}
