import {Injectable, Injector} from '@angular/core';
import {CoreSharedApiBaseService, Product} from '@nexnox-web/core-shared';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable()
export class OrgaPortalFeatureDeviceService extends CoreSharedApiBaseService {
  constructor(
    protected injector: Injector
  ) {
    super(injector, Product.IDENTITY, 'device');
  }

  public createOne<T>(data: T, parentIds?: Array<number | string>): Observable<T> {
    return this.httpRequestService.post<T>(`${ this.buildPath(parentIds) }/code`, data).pipe(
      map(response => response.body)
    );
  }

  public reboot(id: number | string, parentIds?: Array<number | string>): Observable<any> {
    return this.httpRequestService.post(`${ this.buildPathForOne(id, parentIds) }/reboot`, null).pipe(
      map(response => response.body)
    );
  }
}
