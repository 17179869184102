import {EntityXsStore, EntityXsStoreState} from '@nexnox-web/core-store';
import {AddressDto, AppEntityType} from '@nexnox-web/core-shared';
import {Action, createSelector} from '@ngrx/store';
import {selectAddressesState} from '../../addresses.selectors';
import {CorePortalFeatureMasterDataAddressService} from '../../services';
import {Injectable, Injector} from '@angular/core';

export interface AddressDetailStoreState extends EntityXsStoreState<AddressDto> {
}

export const addressDetailStore = new EntityXsStore<AddressDto>({
  actionLabel: 'Core Portal - Master Data - Address Detail',
  stateSelector: createSelector(selectAddressesState, state => state.addressDetail),
  serviceType: CorePortalFeatureMasterDataAddressService,
  entityType: AppEntityType.Address
});

export function addressDetailStoreReducer(state: AddressDetailStoreState, action: Action): any {
  return addressDetailStore.reducer(state, action);
}

@Injectable()
export class AddressDetailStoreEffects extends addressDetailStore.effects {
  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }
}
