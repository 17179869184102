<div class="alert alert-info d-flex align-items-center">
  <fa-icon [fixedWidth]="true" [icon]="faInfoCircle" class="mr-2"></fa-icon>
  {{ 'controls.descriptions.resource-list' | translate }}
</div>

<nexnox-web-entity-overview #overviewComponent
                            (datatableConfigChange)="onDatatableConfigChange($event)"
                            *ngIf="(datatableConfig$ | async) as config"
                            [allowMargin]="true"
                            [customInit$]="ready$"
                            [datatableConfig]="config"
                            [disableFilters]="readonly"
                            [disableSettingsViewSelect]="true"
                            [entityOverviewBaseComponent]="this"
                            [isEmbedded]="true"
                            [saveFiltersOnChange]="true"
                            [showSettings]="!readonly"
                            [title]="title | translate"
                            detailLink="/resources"
                            module="inventory">
</nexnox-web-entity-overview>
