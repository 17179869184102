import {CorePortalManufacturerService, CorePortalModelService} from './services';
import {
  manufacturerDetailStore,
  ManufacturerDetailStoreEffects,
  manufacturerListStore,
  modelDetailStore,
  ModelDetailStoreEffects,
  modelListStore
} from './stores';

export * from './services';
export * from './stores';

export * from './manufacturer.state';
export * from './manufacturer.selectors';

export const manufacturerReducers = {
  manufacturerList: manufacturerListStore.reducer,
  manufacturerDetail: manufacturerDetailStore.reducer,
  modelList: modelListStore.reducer,
  modelDetail: modelDetailStore.reducer,
};

export const manufacturerEffects = [
  manufacturerListStore.effects,
  ManufacturerDetailStoreEffects,
  modelListStore.effects,
  ModelDetailStoreEffects,
];

export const manufacturerServices = [
  CorePortalManufacturerService,
  CorePortalModelService
];
