<ng-template #entityEditTemplate let-id="id" let-title="title">
  <nexnox-web-settings-searchlists-searchlist-edit #modelComponent
                                                   (modelChange)="onModelChange($event)"
                                                   [creating]="false"
                                                   [id]="id"
                                                   [loading]="loading$ | async"
                                                   [model]="model$ | async"
                                                   [readonly]="readonly$ | async"
                                                   [title]="title">
  </nexnox-web-settings-searchlists-searchlist-edit>
</ng-template>

<nexnox-web-entity-detail [entityDetailBaseComponent]="this"
                          [entityEditTemplate]="entityEditTemplate"
                          [useTabs]="false">
</nexnox-web-entity-detail>
