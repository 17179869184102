import {PagedEntitiesXsStore, PagedEntitiesXsStoreState} from '@nexnox-web/core-store';
import {AppEntityType, LocationGroupDto} from '@nexnox-web/core-shared';
import {Action, createSelector} from '@ngrx/store';
import {selectContactsState} from '../../contacts.selectors';
import {ContactLocationGroupService} from '../../services';
import {Injectable, Injector} from '@angular/core';

export interface ContactLocationGroupListStoreState extends PagedEntitiesXsStoreState<LocationGroupDto> {
}

/* istanbul ignore next */
export const contactLocationGroupListStore = new PagedEntitiesXsStore<LocationGroupDto>({
  actionLabel: 'Core Portal - Master Data - Contact - Location Group List',
  stateSelector: createSelector(selectContactsState, state => state.contactLocationGroupList),
  serviceType: ContactLocationGroupService,
  entityType: AppEntityType.LocationGroup,
  selectId: locationGroup => locationGroup.entity?.locationGroupId ?? locationGroup.model?.locationGroupId
});

export function contactLocationGroupListStoreReducer(state: ContactLocationGroupListStoreState, action: Action): any {
  return contactLocationGroupListStore.reducer(state, action);
}

@Injectable()
export class ContactLocationGroupListStoreEffects extends contactLocationGroupListStore.effects {
  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }
}
