import {ChangeDetectionStrategy, Component, Injector} from '@angular/core';
import {
  CorePortalEntityEditBaseComponent,
  CorePortalFormlyReadonlyTypes,
  CorePortalFormlyReadonlyTyping
} from '@nexnox-web/core-portal';
import {
  CombineOperator,
  DataTableDto,
  DocumentTemplateContextType,
  DocumentTemplateSimpleDto,
  Filter,
  FilterDto,
  FilterKind,
  FilterOperators,
  FilterTypes,
  GroupedFilterDto,
  NotificationRuleDto,
  NotificationTrigger,
  TemplateContextType
} from '@nexnox-web/core-shared';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {notificationTriggerTypeOptions} from "../../models";
import {NotificationTargetLocalDto} from "../../components";
import {BehaviorSubject, distinctUntilChanged, map, Observable, startWith} from "rxjs";
import {
  TechPortalFeatureDocumentTemplateService,
  TechPortalFeatureTextTemplateService
} from "@nexnox-web/tech-portal/features/templates/src/lib/store/services";
import {filter} from "rxjs/operators";
import {documentContextTypeEnumOptions} from "@nexnox-web/tech-portal/features/templates";
import {isEqual} from "lodash";

interface DocumentTemplateContextTypeLabel {
  value: DocumentTemplateContextType;
  label: string;
}

@Component({
  selector: 'nexnox-web-notification-rules-edit',
  templateUrl: './notification-rules-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CorePortalNotificationRulesEditComponent extends CorePortalEntityEditBaseComponent<NotificationRuleDto> {

  public title = 'core-portal.settings.subtitles.notification-rules.notification-rules-create';

  public triggerTypeSubject: BehaviorSubject<NotificationTrigger> = new BehaviorSubject<NotificationTrigger>(null);

  public pdfAttachmentContextTypeFilter$: Observable<GroupedFilterDto[]>;
  public subjectContextTypeFilter$: Observable<Filter[]>;
  public contentContextTypeFilter$: Observable<Filter[]>;
  public defaultTrigger = notificationTriggerTypeOptions[1].value;

  constructor(
    protected injector: Injector,
    private textTemplateService: TechPortalFeatureTextTemplateService,
    private documentTemplateService: TechPortalFeatureDocumentTemplateService,
  ) {
    super(injector);

    this.contentContextTypeFilter$ = this.triggerTypeSubject.asObservable().pipe(
      startWith(this.defaultTrigger),
      filter(trigger => !!trigger),
      map((trigger) => [{
        property: 'context',
        type: FilterTypes.DataTransferObject,
        operator: FilterOperators.Equal,
        value: `${ this._getTemplateContextType(trigger) }`
      }]),
      // Filter for nothing when NotificationTrigger.ContractChangedState (Id -1)
      map((trigger) => this._isTemplateFieldDisabled() ? [...trigger, {
        property: 'templateId',
        type: FilterTypes.DataTransferObject,
        operator: FilterOperators.Equal,
        value: -1
      }] as Filter[] : trigger)
    );

    this.subjectContextTypeFilter$ = this.triggerTypeSubject.asObservable().pipe(
      startWith(this.defaultTrigger),
      filter(trigger => !!trigger),
      map((trigger) => [{
        property: 'context',
        type: FilterTypes.DataTransferObject,
        operator: FilterOperators.Equal,
        value: `${ this._getTemplateContextType(trigger, true) }`
      }]),
      // Filter for nothing when NotificationTrigger.ContractChangedState (Id -1)
      map((trigger) => this._isTemplateFieldDisabled() ? [...trigger, {
        property: 'templateId',
        type: FilterTypes.DataTransferObject,
        operator: FilterOperators.Equal,
        value: -1
      }] as Filter[] : trigger)
    );

    this.pdfAttachmentContextTypeFilter$ = this.triggerTypeSubject.asObservable().pipe(
      map(trigger => this._getPDFAttachmentContextTypeFilters(trigger)))

  }

  public onRestrictionChange(restriction: DataTableDto): void {
    this.onModelChange({ ...this.model, restriction });
  }

  public onTargetsChanged(targets: NotificationTargetLocalDto[]): void {
    this.onModelChange({ ...this.model, targets });
  }

  /* istanbul ignore next */
  protected createForm(): FormlyFieldConfig[] {
    return [
      {
        key: 'title',
        type: 'input',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.title',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.BASIC
          } as CorePortalFormlyReadonlyTyping,
          type: 'text'
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        },
        hideExpression: () => !this.creating
      },
      {
        key: 'trigger',
        type: 'core-portal-ng-select',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: this.creating ? 'col-md-6' : 'col-md-12',
        defaultValue: this.defaultTrigger,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-portal.settings.notification-rules.trigger-type',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.ENUM,
            enumOptions: notificationTriggerTypeOptions,
            translate: true
          } as CorePortalFormlyReadonlyTyping,
          corePortalNgSelect: {
            items: notificationTriggerTypeOptions,
            translate: true
          }
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        },
        hooks: {
          onInit: () => this.subscribe(this.form.get('trigger').valueChanges, (trigger) => this.triggerTypeSubject.next(trigger))
        }
      },
      {
        key: 'emailSubjectTemplate',
        type: 'core-portal-entity-select',
        wrappers: ['core-portal-translated'],
        className: 'col-md-6',
        defaultValue: null,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-portal.settings.fields.text-template-for-mail-subject',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          entityService: this.textTemplateService,
          idKey: 'templateId',
          displayKey: 'title',
          clearable: false,
          wholeObject: true,
          refresh$: this.subjectContextTypeFilter$.pipe(filter(filter => !!filter)),
          defaultFilters$: this.subjectContextTypeFilter$
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly || this._isTemplateFieldDisabled(),
          'templateOptions.readonly': () => this.readonly
        }
      },
      {
        key: 'emailContentTemplate',
        type: 'core-portal-entity-select',
        wrappers: ['core-portal-translated'],
        className: 'col-md-6',
        defaultValue: null,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-portal.settings.fields.text-template-for-mail-content',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          entityService: this.textTemplateService,
          idKey: 'templateId',
          displayKey: 'title',
          clearable: false,
          wholeObject: true,
          refresh$: this.contentContextTypeFilter$.pipe(filter(filter => !!filter)),
          defaultFilters$: this.contentContextTypeFilter$
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly || this._isTemplateFieldDisabled(),
          'templateOptions.readonly': () => this.readonly
        }
      },
      {
        key: 'attachments',
        type: 'core-portal-entity-select',
        wrappers: ['core-portal-translated'],
        className: 'col-md-6',
        defaultValue: null,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.pdf-attachment',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.ENTITY,
            displayKey: 'title',
            link: (row: DocumentTemplateSimpleDto) => row?.documentTemplateId ? ['templates/document', row.documentTemplateId] : null,
            module: 'settings'
          } as CorePortalFormlyReadonlyTyping,
          entityService: this.documentTemplateService,
          idKey: 'documentTemplateId',
          displayKey: 'title',
          wholeObject: true,
          defaultFilters$: this.pdfAttachmentContextTypeFilter$,
          refresh$: this.pdfAttachmentContextTypeFilter$.pipe(distinctUntilChanged((pre, cur) => isEqual(pre, cur))),
          multiple: true,
          showAll: true
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly || !this._isDocumentTemplateSelectable(this.triggerTypeSubject.getValue())
        }
      }
    ];
  }

  private _getPDFAttachmentContextTypeFilters(trigger: NotificationTrigger): GroupedFilterDto[] {
    let types: DocumentTemplateContextTypeLabel[];
    // Prepare array by trigger
    switch (trigger) {
      case NotificationTrigger.TicketChangedResource:
      case NotificationTrigger.TicketChangedState:
      case NotificationTrigger.TicketChangedEditor:
        types = [
          documentContextTypeEnumOptions[0],
        ];
        break;
      case NotificationTrigger.MissionChangedResource:
      case NotificationTrigger.MissionChangedState:
      case NotificationTrigger.MissionChangedEditor:
        types = [
          documentContextTypeEnumOptions[1],
          documentContextTypeEnumOptions[2],
          documentContextTypeEnumOptions[3],
          documentContextTypeEnumOptions[4],
          documentContextTypeEnumOptions[5],
          documentContextTypeEnumOptions[6],
          documentContextTypeEnumOptions[7],
          documentContextTypeEnumOptions[8],
          documentContextTypeEnumOptions[9]
        ];
        break;
      default:
        types = [];
    }
    // Map to GroupedFilterDto
    const groupedFilter = [
      {
        type: FilterTypes.Grouped,
        combinedAs: CombineOperator.Or,
        kind: FilterKind.Grouped,
        children: types.map(type => ({
          property: 'contextType',
          operator: FilterOperators.Equal,
          type: FilterTypes.DataTransferObject,
          value: type?.value.toString()
        }) as FilterDto)
      } as GroupedFilterDto
    ];
    // return
    return types.length > 0 ? groupedFilter : [];
  }

  private _getTemplateContextType(trigger: NotificationTrigger, isSubject: boolean = false): TemplateContextType {

    switch (trigger) {
      case NotificationTrigger.MissionChangedEditor:
      case NotificationTrigger.MissionChangedResource:
      case NotificationTrigger.MissionChangedState:
        return isSubject ? TemplateContextType.MissionNotificationSubject : TemplateContextType.MissionNotificationContent
      case NotificationTrigger.TicketChangedEditor:
      case NotificationTrigger.TicketChangedResource:
      case NotificationTrigger.TicketChangedState:
        return isSubject ? TemplateContextType.TicketNotificationSubject : TemplateContextType.TicketNotificationContent
      case NotificationTrigger.ActivatedInfo:
        return isSubject ? TemplateContextType.InfoNotificationSubject : TemplateContextType.InfoNotificationContent
      default:
        return isSubject ? TemplateContextType.MissionNotificationSubject : TemplateContextType.MissionNotificationContent
    }

  }

  private _isTemplateFieldDisabled(): boolean {
    return this.triggerTypeSubject.getValue() === NotificationTrigger.ContractChangedState
  }

  private _isDocumentTemplateSelectable(trigger: NotificationTrigger): boolean {
    switch (trigger) {
      case NotificationTrigger.TicketChangedResource:
      case NotificationTrigger.TicketChangedState:
      case NotificationTrigger.TicketChangedEditor:
      case NotificationTrigger.MissionChangedResource:
      case NotificationTrigger.MissionChangedState:
      case NotificationTrigger.MissionChangedEditor:
        return true;
      default:
        return false;
    }
  }
}
