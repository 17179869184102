import {Injectable, Injector} from '@angular/core';
import {
  CoreSharedApiBaseService,
  Product,
  ResourceModelDto,
  ResourceModelMergeDto,
  ResourceModelMoveDto
} from '@nexnox-web/core-shared';
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {CorePortalModelService} from "../";

@Injectable({
  providedIn: 'root'
})
export class CorePortalManufacturerModelService extends CoreSharedApiBaseService {

  public useFilterOperation = false;

  constructor(
    protected injector: Injector,
    private modelService: CorePortalModelService,
  ) {
    super(injector, Product.TECH, 'model', ['v2/manufacturer']);
  }

  public deleteOne<T>(id: number | string, parentIds?: Array<number | string>): Observable<T> {
    return this.httpRequestService.delete<T>(`v2/model/${ id }`).pipe(
      map(response => response.body)
    );
  }

  public getOne<T>(id: number | string, parentIds?: Array<number | string>): Observable<T> {
    return this.httpRequestService.get<T>(`v2/model/${ id }`).pipe(
      map(response => response.body)
    );
  }

  // Reused function from CorePortalModelService as they use the same endpoints
  public mergeModel(model: ResourceModelDto, newModelId: number | string): Observable<ResourceModelMergeDto> {
    return this.modelService.mergeModel(model, newModelId);
  }

  // Reused function from CorePortalModelService as they use the same endpoints
  public moveModel(model: ResourceModelDto, newManufacturerId: number | string): Observable<ResourceModelMoveDto> {
    return this.modelService.moveModel(model, newManufacturerId);
  }
}
