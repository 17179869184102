import {Injectable, Injector} from '@angular/core';
import {CoreSharedApiBaseService, Product} from '@nexnox-web/core-shared';
import {map, Observable} from 'rxjs';

@Injectable()
export class TechPortalFeatureMissionReceiptService extends CoreSharedApiBaseService {
  constructor(
    protected injector: Injector
  ) {
    super(injector, Product.TECH, 'receipt', ['mission']);
  }

  public getReceipt<T>(parentIds: Array<number | string>): Observable<T> {
    return this.httpRequestService.get<T>(`${ this.buildPath(parentIds) }`).pipe(
      map(response => response.body)
    );
  }
}
