import {PermissionPackageDto} from '@nexnox-web/core-shared';

export interface PackagesXsStoreState {
  packages: PermissionPackageDto[];
  loading: boolean;
  loaded: boolean;
}

export const getInitialPackagesXsStoreState = (): PackagesXsStoreState => ({
  packages: [],
  loading: false,
  loaded: false
});
