import {ChangeDetectionStrategy, Component, Injector} from '@angular/core';
import {
  ActionButton,
  CorePortalEntityOverviewBaseComponent,
  DatatableActionButton,
  DeleteEntityModel
} from '@nexnox-web/core-portal';
import {AppEntityType, AppPermissions, ExportType, Mappers, StateDto} from '@nexnox-web/core-shared';
import {stateListStore} from '../../store';

@Component({
  selector: 'nexnox-web-ticket-settings-states-state-list',
  templateUrl: './state-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StateListComponent extends CorePortalEntityOverviewBaseComponent<StateDto> {
  public title = 'core-portal.settings.subtitles.ticket-settings.state-list';
  public createTitle = 'core-portal.settings.subtitles.ticket-settings.state-create';
  public idProperty = 'stateId';
  public displayProperty = 'name';
  public datatableConfigName = 'SettingsTicketSettingsStateDefault';
  public componentId = 'StateListComponent';

  constructor(
    protected injector: Injector
  ) {
    super(injector, stateListStore, Mappers.StateListDto.serializedName, AppEntityType.None);
  }

  /* istanbul ignore next */
  public getRowActionButtons(): DatatableActionButton[] {
    return [
      ...this.getDefaultRowActionButtons(
        'core-portal.settings.actions.ticket-settings.edit-state',
        (row: StateDto) => `/states/${ row.stateId }`,
        [AppPermissions.UpdateState],
        {
          module: 'settings'
        }
      ),
      this.exportService.getRowActionExportButton(ExportType.STATE, this.idProperty)
    ];
  }

  public getDeleteEntityModel(): DeleteEntityModel {
    return {
      titleKey: 'core-portal.settings.actions.ticket-settings.delete-state',
      descriptionKey: 'core-portal.settings.descriptions.ticket-settings.delete-state',
      confirmKey: 'core-portal.settings.actions.ticket-settings.delete-state',
      deletePermission: AppPermissions.DeleteState
    };
  }

  protected async getActionButtons(): Promise<ActionButton[]> {
    return this.getDefaultActionButtons(
      'core-portal.settings.actions.ticket-settings.create-state',
      AppPermissions.CreateState
    );
  }
}
