<ng-template #entityEditTemplate let-title="title" let-id="id">
  <nexnox-web-controls-control-edit #modelComponent
                                    [title]="title"
                                    [id]="id"
                                    [model]="model$ | async"
                                    [stereotyped]="false"
                                    [readonly]="(readonly$ | async)"
                                    [creating]="false"
                                    [loading]="loading$ | async"
                                    (modelChange)="onModelChange($event)">
  </nexnox-web-controls-control-edit>
</ng-template>

<nexnox-web-entity-detail tabId="control"
                          [entityDetailBaseComponent]="this"
                          [entityEditTemplate]="entityEditTemplate"
                          [tabHeading]="title | translate"
                          [tabCommands]="['.']">

  <!-- Control notification edit tab -->
  <ng-template nexnoxWebTabTemplate
               tabId="notifications"
               [heading]="'controls.fields.notifications' | translate"
               (selectTab)="onNavigateToTab(['notifications'])">
    <nexnox-web-controls-notification-edit *ngIf="(model$ | async) as model"
                                           [notifications]="model.notifications ?? []"
                                           [readonly]="readonly$ | async"
                                           [loading]="loading$ | async"
                                           [controlContentType]="model.contentType"
                                           (notificationsChanged)="onNotificationsChanged($event, model)">
    </nexnox-web-controls-notification-edit>
  </ng-template>

  <!--   Control resource edit tab -->
  <ng-template nexnoxWebTabTemplate
               tabId="resources"
               [heading]="'resources.subtitles.resource-list' | translate"
               [hasError]="isNoResourceFilter$ | async"
               (selectTab)="onNavigateToTab(['resources'])">
    <nexnox-web-control-resources-edit *ngIf="passedIds && (model$ | async) as model"
                                       [dataTable]="model.dataTable"
                                       [isEmbedded]="true"
                                       [parentIds]="passedIds"
                                       [canCreate]="false"
                                       [canRouteToDetail]="false"
                                       [readonly]="readonly$ | async"
                                       [loading]="loading$ | async"
                                       (datatableConfigChange)="onDatatableChanged($event, model)">
    </nexnox-web-control-resources-edit>
  </ng-template>

  <!--   Control issue list tab -->
  <ng-template nexnoxWebTabTemplate
               tabId="issues"
               [heading]="'core-portal.core.header.breadcrumbs.issues.issue-list' | translate"
               (selectTab)="onNavigateToTab(['issues'])">
    <nexnox-web-controls-control-issue-list *ngIf="passedIds && (model$ | async) as model"
                                            [isEmbedded]="true"
                                            [parentIds]="passedIds"
                                            [canCreate]="false"
                                            [canRouteToDetail]="false"
                                            [readonly]="readonly$ | async"
                                            (datatableConfigChange)="onDatatableChanged($event, model)">
    </nexnox-web-controls-control-issue-list>
  </ng-template>
</nexnox-web-entity-detail>


