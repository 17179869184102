import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {map, startWith} from 'rxjs/operators';
import {interval, merge, Observable} from 'rxjs';

import dayjs from 'dayjs';
import dayjsUtcPlugin from 'dayjs/plugin/utc';
import dayjsRelativeTimePlugin from 'dayjs/plugin/relativeTime';
import dayjsUpdateLocalePlugin from 'dayjs/plugin/updateLocale';
import dayjsWeekOfYearPlugin from 'dayjs/plugin/weekOfYear';

dayjs.extend(dayjsUtcPlugin);
dayjs.extend(dayjsRelativeTimePlugin);
dayjs.extend(dayjsUpdateLocalePlugin);
dayjs.extend(dayjsWeekOfYearPlugin);

@Pipe({
  name: 'nexnoxWebDayjsRelative'
})
export class NexnoxWebDayjsRelativePipe implements PipeTransform {
  constructor(
    private translate: TranslateService
  ) {
  }

  public transform(date: string, withoutSuffix: boolean = false): Observable<string> {
    return merge(
      interval(60000),
      this.translate.onLangChange.asObservable()
    ).pipe(
      startWith(null),
      map(() => dayjs().to(dayjs(date), withoutSuffix))
    );
  }
}
