import {NgModule} from '@angular/core';
import {StateMachineDetailComponent, StateMachineListComponent} from './containers';
import {StateMachineTransitionsEditComponent, TechPortalFeatureStateMachineEditComponent} from './components';
import {StateMachineAddStateModalComponent} from './modals';
import {CorePortalEntityModule} from '@nexnox-web/core-portal';
import {CoreSharedModule} from '@nexnox-web/core-shared';
import {stateMachinesServices} from './store';
import {TechPortalFeatureTicketSettingsStatesModule} from '@nexnox-web/tech-portal/features/ticket-settings/features/states';

const modals = [
  StateMachineAddStateModalComponent
];

@NgModule({
  declarations: [
    StateMachineListComponent,
    StateMachineDetailComponent,
    TechPortalFeatureStateMachineEditComponent,
    StateMachineTransitionsEditComponent,
    ...modals
  ],
  imports: [
    CoreSharedModule,
    CorePortalEntityModule,
    TechPortalFeatureTicketSettingsStatesModule.forFeature()
  ],
  exports: [
    TechPortalFeatureStateMachineEditComponent
  ],
  providers: [
    ...stateMachinesServices
  ]
})
export class StateMachinesDeclarationsModule {
}
