import {OrgaPortalFeatureCancellationReasonsService} from './services';
import {cancellationReasonsDetailStore, cancellationReasonsListStore} from './stores';

export * from './services';
export * from './stores';

export * from './cancellation-reasons.state';
export * from './cancellation-reasons.selectors';

export const cancellationReasonsReducers = {
  cancellationReasonsList: cancellationReasonsListStore.reducer,
  cancellationReasonsDetail: cancellationReasonsDetailStore.reducer
};

export const cancellationReasonsEffects = [
  cancellationReasonsListStore.effects,
  cancellationReasonsDetailStore.effects
];

export const cancellationReasonsServices = [
  OrgaPortalFeatureCancellationReasonsService
];
