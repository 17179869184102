<nexnox-web-entity-overview #overviewComponent
                            [cardBoxHeaderActions]="getCardboxHeaderActions()"
                            [entityOverviewBaseComponent]="this"
                            detailLink="/masterdata/contacts"
                            module="management">
</nexnox-web-entity-overview>

<nexnox-web-location-create-area-assignment-sidebar #areaAssignmentSidebar
                                                    (refreshList)="onRefresh()"
                                                    [location$]="location$"
></nexnox-web-location-create-area-assignment-sidebar>
