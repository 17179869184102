import {Injectable, Injector} from '@angular/core';
import {
  CoreSharedApiBaseService,
  Product,
  ResourceManufacturerDto,
  ResourceManufacturerMergeDto
} from '@nexnox-web/core-shared';
import {Observable} from "rxjs";
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class CorePortalManufacturerService extends CoreSharedApiBaseService {

  public useFilterOperation = false;

  constructor(
    protected injector: Injector
  ) {
    super(injector, Product.TECH, 'v2/manufacturer');
  }

  public mergeManufacturer(manufacturer: ResourceManufacturerDto,
                           newManufacturerId: number | string): Observable<ResourceManufacturerMergeDto> {
    return this.httpRequestService.post<ResourceManufacturerMergeDto>
    (`v2/manufacturer/${ manufacturer.resourceManufacturerId }/merge`, { newManufacturerId }).pipe(
      map(response => response.body)
    )
  }
}
