import {ChangeDetectionStrategy, Component, Injector} from '@angular/core';
import {CorePortalEntityOverviewBaseComponent, DatatableActionButton} from '@nexnox-web/core-portal';
import {
  AppEntityType,
  ControllerOperationId,
  CoreSharedIssueLinkUtilityService,
  IssueListDto,
  Mappers,
  WinterhalterServiceCallIssueListDto
} from '@nexnox-web/core-shared';
import {faCircle} from '@fortawesome/free-solid-svg-icons/faCircle';
import {faExternalLinkAlt} from "@fortawesome/free-solid-svg-icons/faExternalLinkAlt";
import {winterhalterServiceCallIssueListStore} from "../../store";

@Component({
  selector: 'nexnox-web-issues-winterhalter-service-call-issue-list',
  templateUrl: './winterhalter-service-call-issue-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TechPortalFeatureWinterhalterServiceCallIssueListComponent extends CorePortalEntityOverviewBaseComponent<WinterhalterServiceCallIssueListDto> {

  public title = 'issues.subtitles.winterhalter-service-call-issue-list';
  public createTitle = 'issues.subtitles.winterhalter-service-call-issue-create';
  public idProperty = 'issueId';
  public displayProperty = 'issue.title';
  public pageOperation = ControllerOperationId.WinterhalterServiceCallIssueControllerId;
  public componentId = 'TechPortalFeatureWinterhalterServiceCallIssueListComponent';
  public enableViews = true;
  public datatableConfigName = 'WinterhalterServiceCallIssueDefault';
  public canDelete = false;

  public faCircle = faCircle;

  constructor(
    protected injector: Injector,
    private issueUtilityService: CoreSharedIssueLinkUtilityService
  ) {
    super(injector, winterhalterServiceCallIssueListStore, Mappers.WinterhalterServiceCallIssueListDto.serializedName, AppEntityType.Issue);
  }

  public detailFn = (row: IssueListDto): void => {
    this.tenantRouter.navigate(this.issueUtilityService.getIssueSegments(row)).then()
  };

  /* istanbul ignore next */
  public getRowActionButtons(): DatatableActionButton[] {
    return [
      {
        tooltip: 'core-shared.shared.actions.new-tab',
        icon: faExternalLinkAlt,
        link: (row: WinterhalterServiceCallIssueListDto) => this.issueUtilityService.getIssueLink(row),
        module: 'communication',
        target: '_blank'
      }
    ];
  }
}
