import {EntityXsStore, EntityXsStoreState} from '@nexnox-web/core-store';
import {
  AppEntityType,
  AttachmentFormFieldDto,
  AttachmentTypes,
  FileOrgaAttachmentDto,
  FolderOrgaAttachmentDto,
  FormDto,
  FormFieldTypes,
  FormRowTypes,
  KnowledgeArticleOrgaAttachmentDto,
  LinkOrgaAttachmentDto
} from '@nexnox-web/core-shared';
import {createSelector} from '@ngrx/store';
import {selectFormsState} from '../../forms.selectors';
import {OrgaPortalFeatureFormService} from '../../services';
import {LocalFormRowDto} from '../../../models';

export interface FormDetailStoreState extends EntityXsStoreState<FormDto> {
}

/* istanbul ignore next */
export const formDetailStore = new EntityXsStore<FormDto>({
  actionLabel: 'Orga Portal - Forms - Form Detail',
  stateSelector: createSelector(selectFormsState, state => state.formDetail),
  serviceType: OrgaPortalFeatureFormService,
  entityType: AppEntityType.Form,
  stereotyped: false,
  prepareEntity: (entity: FormDto, base: (entity: FormDto) => FormDto) => {
    const newEntity = base(entity);

    for (const formRow of (newEntity.rows ?? []) as LocalFormRowDto[]) {
      for (const formField of (formRow.fields ?? [])) {
        if (formField.type === FormFieldTypes.Info) {
          delete formField.defaultValues;
        }
      }
    }

    return newEntity;
  },
  sanitizeModel: (model: FormDto, entity: FormDto, base: (model: FormDto, entity: FormDto) => FormDto) => {
    const newModel = base(model, entity);

    for (const formRow of (newModel.rows ?? []) as LocalFormRowDto[]) {
      if (formRow.type !== FormRowTypes.Repeatable) {
        delete formRow.minRepeat;
        delete formRow.maxRepeat;
        delete formRow.repeatLabel;
      }

      for (const formField of (formRow.fields ?? [])) {
        if (formField.type === FormFieldTypes.Info) {
          delete formField.defaultValues;
        }

        if (formField.type === FormFieldTypes.Attachment) {
          if ((formField as AttachmentFormFieldDto).attachment) {
            if ((formField as AttachmentFormFieldDto).attachment.type !== AttachmentTypes.File) {
              delete ((formField as AttachmentFormFieldDto).attachment as FileOrgaAttachmentDto).file;
              delete ((formField as AttachmentFormFieldDto).attachment as FileOrgaAttachmentDto).fileId;
            }

            if ((formField as AttachmentFormFieldDto).attachment.type !== AttachmentTypes.Knowledge) {
              delete ((formField as AttachmentFormFieldDto).attachment as KnowledgeArticleOrgaAttachmentDto).knowledgeArticle;
              delete ((formField as AttachmentFormFieldDto).attachment as KnowledgeArticleOrgaAttachmentDto).knowledgeArticleId;
            }

            if ((formField as AttachmentFormFieldDto).attachment.type !== AttachmentTypes.Link) {
              delete ((formField as AttachmentFormFieldDto).attachment as LinkOrgaAttachmentDto).name;
              delete ((formField as AttachmentFormFieldDto).attachment as LinkOrgaAttachmentDto).link;
            }

            if ((formField as AttachmentFormFieldDto).attachment.type !== AttachmentTypes.Folder) {
              delete ((formField as AttachmentFormFieldDto).attachment as FolderOrgaAttachmentDto).path;
            }
          }
        }
      }
    }

    return newModel;
  }
});
