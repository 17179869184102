import {
  controlDetailStore,
  ControlDetailStoreState,
  controlIssueListStore,
  ControlIssueListStoreState,
  controlListStore,
  ControlListStoreState,
  controlResourcesStore,
  ControlResourcesStoreState
} from './stores';

export interface ControlsState {
  controlDetail: ControlDetailStoreState;
  controlList: ControlListStoreState;
  controlIssueList: ControlIssueListStoreState;
  controlResources: ControlResourcesStoreState;
}

export const getInitialControlsState = (): ControlsState => ({
  controlDetail: controlDetailStore.getInitialState(),
  controlList: controlListStore.getInitialState(),
  controlIssueList: controlIssueListStore.getInitialState(),
  controlResources: controlResourcesStore.getInitialState()
});
