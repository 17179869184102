<nexnox-web-entity-edit *ngIf="!creating"
                        [entityEditBaseComponent]="this"
                        displayKey="title">
</nexnox-web-entity-edit>

<nexnox-web-tabs *ngIf="creating"
                 [internal]="true"
                 [isTransparent]="true"
                 startTab="entityEdit">

  <!-- Entity edit tab -->
  <ng-template [hasError]="!isOwnModelValid()"
               [heading]="title | translate"
               nexnoxWebTabTemplate
               tabId="entityEdit">
    <nexnox-web-entity-edit [entityEditBaseComponent]="this"></nexnox-web-entity-edit>
  </ng-template>

  <!-- Filter selection -->
  <ng-template [hasError]="(model?.restriction?.filters ?? []).length === 0"
               [heading]="'core-portal.settings.notification-rules.restriction' | translate"
               [lazy]="true"
               nexnoxWebTabTemplate
               tabId="restriction">
    <nexnox-web-settings-notification-rules-restriction (restrictionChanged)="onRestrictionChange($event)"
                                                        [creating]="creating"
                                                        [loading]="loading"
                                                        [readonly]="readonly"
                                                        [restriction]="model?.restriction"
                                                        [trigger]="model?.trigger">
    </nexnox-web-settings-notification-rules-restriction>
  </ng-template>

  <!-- Target selection -->
  <ng-template [hasError]="(model?.targets ?? []).length === 0"
               [heading]="'core-portal.settings.notification-rules.target' | translate"
               nexnoxWebTabTemplate
               tabId="targets">
    <nexnox-web-settings-notification-rules-target-edit (targetsChanged)="onTargetsChanged($event)"
                                                        [loading]="loading"
                                                        [readonly]="readonly"
                                                        [targets]="model?.targets">
    </nexnox-web-settings-notification-rules-target-edit>
  </ng-template>
</nexnox-web-tabs>
