<p-sidebar #sidebarComponent
           (visibleChange)="onVisibleChange($event)"
           [transitionOptions]="transitionOptions"
           [visible]="visible$ | async"
           appendTo="body"
           position="right"
           styleClass="p-sidebar-lg cross-creation">

  <div class="d-flex align-content-between flex-column h-100">
    <div class="w-100 flex-grow-1">
      <ng-container #editComponentContainer>
      </ng-container>
    </div>

    <div *ngIf="config && editComponent" class="w-100 flex-shrink-1 d-flex justify-content-end">
      <button (click)="onHide()"
              [icon]="faTimes | nexnoxWebFaIconStringPipe"
              [label]="'core-portal.core.general.cancel' | translate"
              class="p-button p-button-secondary mr-3"
              pButton
              type="button">
      </button>

      <button (click)="onCreate()"
              [disabled]="disablePrimaryAction$ | async"
              [icon]="faPlus | nexnoxWebFaIconStringPipe"
              [label]="config.createTitle | translate"
              class="p-button p-button-primary"
              pButton
              type="button">
      </button>
    </div>
  </div>
</p-sidebar>
