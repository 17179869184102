import {ChangeDetectionStrategy, Component, EventEmitter, Injector, OnInit, Output} from '@angular/core';
import {
  CorePortalEntityEditBaseComponent,
  CorePortalFormlyReadonlyTypes,
  CorePortalFormlyReadonlyTyping,
  CorePortalPermissionService
} from '@nexnox-web/core-portal';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {TechPortalFeatureTicketSettingsPriorityService} from '@nexnox-web/tech-portal/features/ticket-settings/features/priority-sets';
import {filter, map, startWith, take, withLatestFrom} from 'rxjs/operators';
import {
  AppEntityType,
  AppPermissions,
  ContactDto,
  CoreSharedIssueLinkUtilityService,
  EscalationLevelDto,
  FilterDto,
  FilterOperators,
  FilterTypes,
  IssueSimpleDto,
  LocationSimpleDto,
  PriorityDto,
  ResourceInMissionDto,
  TicketDto
} from '@nexnox-web/core-shared';
import {isNumber} from 'lodash';
import {TechPortalFeatureTicketSettingsEscalationLevelService} from '@nexnox-web/tech-portal/features/ticket-settings/features/escalation-levels';
import {faCircle} from '@fortawesome/free-solid-svg-icons/faCircle';
import {faPencilAlt} from '@fortawesome/free-solid-svg-icons/faPencilAlt';
import {firstValueFrom, Observable} from 'rxjs';
import {faFolder} from '@fortawesome/free-solid-svg-icons/faFolder';
import {faUser} from '@fortawesome/free-solid-svg-icons/faUser';
import {faHome} from "@fortawesome/free-solid-svg-icons/faHome";
import {ticketDetailStore} from "@nexnox-web/tech-portal/features/tickets/src/lib/store";
import {Store} from '@ngrx/store';

@Component({
  selector: 'nexnox-web-tickets-ticket-status-edit',
  templateUrl: './ticket-status-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TicketStatusEditComponent extends CorePortalEntityEditBaseComponent<TicketDto> implements OnInit {
  @Output() public editEditor: EventEmitter<void> = new EventEmitter<void>();

  public editor$: Observable<ContactDto>;
  public resource$: Observable<ResourceInMissionDto>;
  public resourcePath$: Observable<any[]>;

  public locationReference$: Observable<LocationSimpleDto>;

  public canAssignTo$: Observable<boolean>;

  public faCircle = faCircle;
  public faPencilAlt = faPencilAlt;
  public faFolder = faFolder;
  public faUser = faUser;
  public faHome = faHome;
  public originPrioritySetId$: Observable<number>;

  constructor(
    protected injector: Injector,
    private store: Store<any>,
    private escalationLevelService: TechPortalFeatureTicketSettingsEscalationLevelService,
    private priorityService: TechPortalFeatureTicketSettingsPriorityService,
    private permissionService: CorePortalPermissionService,
    private issueUtilityService: CoreSharedIssueLinkUtilityService
  ) {
    super(injector);
  }

  public async ngOnInit(): Promise<void> {
    super.ngOnInit();

    this.originPrioritySetId$ = this.store.select(ticketDetailStore.selectors.selectEntity).pipe(
      withLatestFrom(this.stereotypes$),
      map(([entity, stereotypes]) => stereotypes.find(s => s.stereotypeId === entity.stereotypeId)?.prioritySet?.prioritySetId)
    );

    this.editor$ = this.modelSubject.asObservable().pipe(
      filter(model => Boolean(model)),
      map(model => model.editor)
    );

    this.resource$ = this.modelSubject.asObservable().pipe(
      filter(model => Boolean(model)),
      map(model => model.resource)
    );

    this.resourcePath$ = this.resource$.pipe(
      filter(resource => Boolean(resource)),
      map(resource => (['/resources', resource.resourceId]))
    );

    this.locationReference$ = this.modelSubject.asObservable().pipe(
      filter(ticket => Boolean(ticket?.location)),
      map(ticket => ticket.location)
    );

    this.canAssignTo$ = this.permissionService.hasPermission$(AppPermissions.AssignTicketEditor);
  }

  public getIssueLink(primaryIssue: IssueSimpleDto): Array<string | number> {
    return this.issueUtilityService.getIssueSegments(primaryIssue);
  }

  /* istanbul ignore next */
  protected createForm(): FormlyFieldConfig[] {
    return [
      { key: 'escalationLevelId' },
      {
        key: 'escalationLevel',
        type: 'core-portal-entity-select',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-12',
        templateOptions: {
          corePortalTranslated: {
            label: 'tickets.fields.escalation-level'
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.ENTITY,
            displayKey: 'name',
            link: (escalationLevel: EscalationLevelDto) => escalationLevel?.escalationLevelId ?
              ['escalation-levels', escalationLevel.escalationLevelId] : null,
            module: 'settings'
          } as CorePortalFormlyReadonlyTyping,
          entityService: this.escalationLevelService,
          idKey: 'escalationLevelId',
          displayKey: 'name',
          wholeObject: true,
          skipGetOne: true,
          link: (escalationLevel: EscalationLevelDto) => escalationLevel?.escalationLevelId ?
            ['escalation-levels', escalationLevel.escalationLevelId] : null,
          module: 'settings'
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        },
        hooks: {
          onInit: field => this.subscribe(field.formControl.valueChanges, (value: EscalationLevelDto) => {
            this.form.controls.escalationLevelId.setValue(value?.escalationLevelId ?? null);
          })
        }
      },
      { key: 'priorityId' },
      {
        key: 'priority',
        type: 'core-portal-entity-select',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-12',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.priority',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.ENTITY,
            displayKey: 'name'
          } as CorePortalFormlyReadonlyTyping,
          entityService: this.priorityService,
          idKey: 'priorityId',
          displayKey: 'name',
          wholeObject: true,
          firstToDefault: true,
          clearable: false,
          defaultFilters$: this.selectedStereotype$.pipe(
            map(stereotype => {
              if (!stereotype || stereotype.entityType !== AppEntityType.Ticket) {
                return [];
              }

              return [{
                property: 'prioritySetId',
                type: FilterTypes.DataTransferObject,
                operator: FilterOperators.Equal,
                value: stereotype.prioritySet?.prioritySetId?.toString()
              }] as FilterDto[];
            })
          ),
          skipGetOne: true,
          waitUntil$: this.selectedStereotype$.pipe(
            filter(selectedStereotype => Boolean(selectedStereotype)),
            take(1)
          )
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.disabled': () => this.readonly || !isNumber(this.model?.stereotypeId),
          'templateOptions.readonly': () => this.readonly
        },
        hooks: {
          onInit: async (field) => {
            this.subscribe(field.formControl.valueChanges.pipe(
              startWith(field.formControl.value),
              map(priority => priority?.priorityId)
            ), (value: PriorityDto) => {
              this.form.controls.priorityId.setValue(value ?? null)
            });

            this.subscribe(this.selectedStereotype$.pipe(
              filter(s => !!s),
              map(stereotype => (stereotype?.prioritySet?.prioritySetId)),
              withLatestFrom(this.originPrioritySetId$)
            ), async ([newPrioritySetId, oldPrioritySetId]) => {
              if (newPrioritySetId !== oldPrioritySetId) {
                field.formControl.setValue(null);
              } else {
                // Reset priority
                const originPriority = await firstValueFrom(this.store.select(ticketDetailStore.selectors.selectEntity)
                  .pipe(map(entity => entity.priority)))
                field.formControl.setValue(originPriority);
              }
            });
          }
        }
      },
      {
        key: 'solution.threadMailAddress',
        type: 'input',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-12',
        templateOptions: {
          corePortalTranslated: {
            label: 'tech-portal.fields.ticket-address'
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.BASIC
          } as CorePortalFormlyReadonlyTyping,
          readonly: true
        }
      }
    ];
  }
}
