<p-sidebar #sidebarComponent
           (visibleChange)="onVisibleChange($event)"
           [transitionOptions]="transitionOptions"
           [visible]="visible$ | async"
           appendTo="body"
           position="right"
           styleClass="p-sidebar-lg">

  <h4>{{ 'missions.subtitles.editor-suggestions-current-editor' | translate }}</h4>
  <div class="d-flex flex-row mb-4">
    <ng-container *ngIf="currentSolutionContact$ | async as solutionContact; else noSolutionContactTemplate">
      <fa-icon [fixedWidth]="true"
               [icon]="faUser"
               class="mr-2"
               size="sm"></fa-icon>
      <a [nexnoxWebTenantRouterLink]="['/masterdata/contacts', solutionContact.contactId]"
         class="btn-link"
         module="management"
         target="_blank">
        {{ solutionContact.displayName }}
      </a>
    </ng-container>
    <ng-template #noSolutionContactTemplate>
      {{ 'core-shared.shared.select.empty' | translate }}
    </ng-template>
  </div>

  <h4 class="mt-4">{{ 'missions.subtitles.editor-suggestions-inquire' | translate }}</h4>
  <div class="row no-gutters">
    <div class="col-7 p-0">
      <form [formGroup]="form" class="w-100">
        <formly-form [(model)]="model"
                     [fields]="fields"
                     [form]="form"
                     class="w-100">
        </formly-form>
      </form>
    </div>
    <div class="col-5 p-0 pl-2">
      <button (click)="onStartQuest(model?.functionGroup)"
              [disabled]="!model?.functionGroup?.functionId"
              [icon]="faUsers | nexnoxWebFaIconStringPipe"
              [label]="'missions.actions.start-quest' | translate"
              class="p-button p-button-primary"
              pButton
              type="button">
      </button>
    </div>
  </div>

  <ng-container *ngIf="editors$ | async as editors;">
    <h4 class="mt-4">{{ 'missions.subtitles.editor-suggestions' | translate }}</h4>
    <nexnox-web-basic-table [config]="config"
                            [data]="editors"
                            [loading]="loading">
    </nexnox-web-basic-table>
  </ng-container>
</p-sidebar>
