import {NgModule} from '@angular/core';
import {CoreSharedModule} from '@nexnox-web/core-shared';
import {CorePortalEntityModule, CorePortalRouterOverridesModule} from '@nexnox-web/core-portal';
import {formsServices} from './store';
import {FormEditComponent, FormFieldEditComponent, FormRowEditComponent, FormRowsEditComponent} from './components';
import {FormDetailComponent, FormListComponent} from './containers';
import {PanelModule} from 'primeng/panel';
import {InputTextModule} from 'primeng/inputtext';
import {CreateFormFieldSidebarComponent, CreateFormRowSidebarComponent} from './sidebars';
import {BadgeModule} from 'primeng/badge';
import {FormlyFormFieldDropdownComponent} from './formly-types';
import {FormlyModule} from '@ngx-formly/core';
import {OrgaPortalModule} from '@nexnox-web/orga-portal-lib';
import {TieredMenuModule} from 'primeng/tieredmenu';
import {CorePortalFeatureSettingsSearchListsModule} from "@nexnox-web/core-portal/features/settings/features/search-lists/src/lib/search-lists.module";

@NgModule({
  declarations: [
    FormEditComponent,
    FormRowsEditComponent,
    FormRowEditComponent,
    FormFieldEditComponent,

    CreateFormRowSidebarComponent,
    CreateFormFieldSidebarComponent,

    FormListComponent,
    FormDetailComponent,

    FormlyFormFieldDropdownComponent
  ],
  imports: [
    CoreSharedModule,
    CorePortalEntityModule,
    CorePortalRouterOverridesModule,
    OrgaPortalModule,
    CorePortalFeatureSettingsSearchListsModule.forFeature(),

    FormlyModule.forChild({
      types: [{
        name: 'orga-portal-forms-form-field-dropdown',
        component: FormlyFormFieldDropdownComponent
      }]
    }),

    PanelModule,
    InputTextModule,
    BadgeModule,
    TieredMenuModule
  ],
  providers: [
    ...formsServices
  ]
})
export class FormsDeclarationsModule {
}
