import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer, SafeValue} from '@angular/platform-browser';

@Pipe({
  name: 'nexnoxWebBypassStylePipe'
})
export class NexnoxWebBypassStylePipe implements PipeTransform {
  constructor(
    private sanitizer: DomSanitizer
  ) {
  }

  public transform(style: string): SafeValue {
    return this.sanitizer.bypassSecurityTrustStyle(style);
  }
}
