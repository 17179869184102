import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {faCheck} from '@fortawesome/free-solid-svg-icons/faCheck';
import {faKey} from '@fortawesome/free-solid-svg-icons/faKey';
import {faSpinner} from '@fortawesome/free-solid-svg-icons/faSpinner';
import {CorePortalFormlyTranslatedTyping} from '@nexnox-web/core-portal';
import {CoreSharedSidebarBaseComponent} from '@nexnox-web/core-shared';
import {Actions, ofType} from '@ngrx/effects';
import {select, Store} from '@ngrx/store';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {Observable} from 'rxjs';
import {contactDetailStore} from '../../store';
import {cloneDeep} from "lodash";


export interface ForcePasswordEvent {
  password: string;
}

@Component({
  selector: 'nexnox-web-master-data-contacts-force-password-sidebar',
  templateUrl: './force-password-sidebar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ForcePasswordSidebarComponent extends CoreSharedSidebarBaseComponent implements OnInit {

  public form: FormGroup;
  public model: ForcePasswordEvent;
  public fields: FormlyFieldConfig[];

  public loading$: Observable<boolean>;

  public faCheck = faCheck;
  public faSpinner = faSpinner;
  public faKey = faKey;

  constructor(
    private store: Store,
    private actions$: Actions
  ) {
    super();

    this.loading$ = this.store.pipe(
      select(contactDetailStore.selectors.selectEntityDataLoading, { key: contactDetailStore.actions.forcePassword.type })
    );
  }

  public ngOnInit(): void {
    this.form = new FormGroup({});
    this.model = {} as ForcePasswordEvent;
    this.fields = this.createForm();
  }

  public onHide(): void {
    super.onHide();

    this.form.reset();
    this.form.markAsPristine();
  }

  public onShow(): void {
    super.onShow();
    this.subscribe(this.actions$.pipe(
      ofType(contactDetailStore.actions.forcePasswordSuccess)
    ), () => this.onHide())
  }

  public onForcePassword(): void {
    const passwordBase64 = btoa(cloneDeep(this.model.password).trim());
    this.store.dispatch(contactDetailStore.actions.forcePassword({ passwordBase64 }));
  }

  public onGeneratePassword(): void {
    this.form.get('password').setValue(Math.random().toString(36).substr(2, 8));
  }

  /* istanbul ignore next */
  private createForm(): FormlyFieldConfig[] {
    return [
      {
        key: 'password',
        type: 'input',
        wrappers: ['core-portal-translated'],
        className: 'w-100',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.password',
            validationMessages: {
              required: 'core-portal.core.validation.required',
              minLength: 'core-portal.user.validation.password-length'
            }
          } as CorePortalFormlyTranslatedTyping,
          type: 'text',
          required: true
        },
        validators: {
          minLength: (ctrl) => (ctrl.value ?? '').length >= 8
        }
      }
    ];
  }
}
