import {FieldType, FormlyTemplateOptions} from '@ngx-formly/core';
import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {faExternalLinkAlt} from '@fortawesome/free-solid-svg-icons/faExternalLinkAlt';
import {isEqual} from 'lodash';
import {Observable} from 'rxjs';
import {faCheckCircle} from '@fortawesome/free-solid-svg-icons/faCheckCircle';
import {faExclamationCircle} from '@fortawesome/free-solid-svg-icons/faExclamationCircle';
import {faLevelUpAlt} from '@fortawesome/free-solid-svg-icons/faLevelUpAlt';

export interface CorePortalFormlyNgSelectOption {
  label: string;
  readonlyLabel?: string;
  value: any;
  flag?: string;
  accepted?: boolean;
  depth?: number;
}

export interface CorePortalFormlyNgSelectTyping {
  items$?: Observable<CorePortalFormlyNgSelectOption[]>;
  items?: CorePortalFormlyNgSelectOption[];
  compareFn?: (a: any, b: any) => boolean;
  translate?: boolean;
  link?: (value: any) => string;
  module?: string;
  noClear?: boolean;
  rateable?: boolean;
  noSearch?: boolean;
  groupByFn?: (value: any) => any;
  groupValueFn?: (term: string, children: any[]) => { label: string };
}

interface FormlyNgSelectTemplateOptions extends FormlyTemplateOptions {
  corePortalNgSelect: CorePortalFormlyNgSelectTyping;
}

@Component({
  selector: 'nexnox-web-formly-ng-select',
  templateUrl: './formly-ng-select.component.html'
})
export class FormlyNgSelectComponent extends FieldType implements OnInit {
  public readonly to: FormlyNgSelectTemplateOptions;

  public compareFn: any;
  public searchFn: any;
  public groupByFn: any;
  public groupValueFn: any;

  public faExternalLinkAlt = faExternalLinkAlt;
  public faCheckCircle = faCheckCircle;
  public faExclamationCircle = faExclamationCircle;
  public faLevelUpAlt = faLevelUpAlt;

  constructor(
    private translate: TranslateService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.compareFn = (a: CorePortalFormlyNgSelectOption, b: any) => this.compare(a, b);
    this.searchFn = (term: string, item: any) => this.search(term, item);
    this.groupByFn = this.to.corePortalNgSelect.groupByFn ?
      (option: CorePortalFormlyNgSelectOption) => this.to.corePortalNgSelect.groupByFn(option.value) : undefined;
    this.groupValueFn = (term: string, children: CorePortalFormlyNgSelectOption[]) => this.groupValue(term, children);
  }

  private search(term: string, item: any): boolean {
    if (this.to.corePortalNgSelect.translate) {
      return this.translate.instant(item.label).toLowerCase().includes(term.toLowerCase());
    } else {
      return item.label.toLowerCase().includes(term.toLowerCase());
    }
  }

  private compare(a: CorePortalFormlyNgSelectOption, b: any): boolean {
    if (this.to.corePortalNgSelect.compareFn) {
      return this.to.corePortalNgSelect.compareFn(a.value, b);
    }

    return isEqual(a.value, b);
  }

  private groupValue(term: string, children: CorePortalFormlyNgSelectOption[]): { label: string } {
    if (this.to.corePortalNgSelect.groupValueFn) {
      return this.to.corePortalNgSelect.groupValueFn(term, children.map(x => x.value));
    }

    return { label: term };
  }
}
