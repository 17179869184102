import {EntityXsStore, EntityXsStoreState} from '@nexnox-web/core-store';
import {AppEntityType, FunctionDto} from '@nexnox-web/core-shared';
import {createSelector} from '@ngrx/store';
import {CorePortalFeatureMasterDataFunctionService} from '../../services';
import {selectFunctionsState} from '../../functions.selectors';

export interface FunctionDetailStoreState extends EntityXsStoreState<FunctionDto> {
}

export const functionDetailStore = new EntityXsStore<FunctionDto>({
  actionLabel: 'Core Portal - Master Data - Function Detail',
  stateSelector: createSelector(selectFunctionsState, state => state.functionDetail),
  serviceType: CorePortalFeatureMasterDataFunctionService,
  entityType: AppEntityType.Function
});
