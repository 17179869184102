import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRouteSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {CoreSharedBreadcrumb, CoreSharedBreadcrumbsResolver} from '../../modules';

@Injectable()
export class I18nBreadcrumbResolver extends CoreSharedBreadcrumbsResolver {
  constructor(
    private translate: TranslateService
  ) {
    super();
  }

  public resolve(route: ActivatedRouteSnapshot): Observable<CoreSharedBreadcrumb[]> {
    const path = this.getFullPath(route);
    const permissions = route.routeConfig.data?.breadcrumbPermissions;

    const parent = this.getParentBreadcrumb(route);

    if (parent) {
      return this.translate.stream(route.data.text).pipe(
        map(text => ([parent, { text, path, permissions }]))
      );
    }

    return this.translate.stream(route.data.text).pipe(
      map(text => ([{ text, path, permissions }]))
    );
  }

  private getParentBreadcrumb(route: ActivatedRouteSnapshot): CoreSharedBreadcrumb {
    const parentData = route.routeConfig.data?.parentBreadcrumb;

    if (!parentData) return null;

    const path = this.removeLastSegment(this.getFullPath(route));
    const text = this.translate.instant(parentData.text);
    const permissions = parentData.permissions ?? [];

    return {
      text,
      path,
      permissions
    };
  }

  private removeLastSegment(path: string): string {
    return path.replace(/\/[^/]*$/, '');
  }
}
