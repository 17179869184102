import {
  prioritySetDetailStore,
  PrioritySetDetailStoreState,
  prioritySetListStore,
  PrioritySetListStoreState
} from './stores';

export interface PrioritySetsState {
  prioritySetList: PrioritySetListStoreState;
  prioritySetDetail: PrioritySetDetailStoreState;
}

export const getInitialPrioritySetsState = (): PrioritySetsState => ({
  prioritySetList: prioritySetListStore.getInitialState(),
  prioritySetDetail: prioritySetDetailStore.getInitialState()
});
