<ng-template #entityEditTemplate let-title="title">
  <nexnox-web-settings-inventory-number-patterns-inventory-number-pattern-edit #editComponent
                                                                               (modelChange)="createModel$.next($event)"
                                                                               [creating]="true"
                                                                               [loading]="(loaded$ | async) === false"
                                                                               [model]="createModel$ | async"
                                                                               [readonly]="false"
                                                                               [stereotypes$]="stereotypes$"
                                                                               [title]="title">
  </nexnox-web-settings-inventory-number-patterns-inventory-number-pattern-edit>
</ng-template>

<nexnox-web-entity-overview #overviewComponent
                            [entityEditTemplate]="entityEditTemplate"
                            [entityOverviewBaseComponent]="this"
                            [showSettings]="false"
                            detailLink="inventory-number-patterns"
                            module="settings">
</nexnox-web-entity-overview>
