<p-sidebar #sidebarComponent
           (visibleChange)="onVisibleChange($event)"
           [transitionOptions]="transitionOptions"
           [visible]="visible$ | async"
           appendTo="body"
           position="right"
           styleClass="p-sidebar-md">
  <h4>{{ 'orga-portal.forms.actions.create-form-field' | translate }}</h4>

  <div class="d-flex flex-grow-1 flex-column h-100 mt-1">
    <form [formGroup]="form">
      <formly-form [(model)]="model"
                   [fields]="fields"
                   [form]="form"
                   class="row w-100">
      </formly-form>
    </form>
  </div>

  <div class="d-flex flex-shrink-0 justify-content-end pt-3">
    <button (click)="onCreateFormField()"
            [disabled]="form.invalid"
            [icon]="faPlus | nexnoxWebFaIconStringPipe"
            [label]="'orga-portal.forms.actions.create-form-field' | translate"
            class="p-button-primary ml-2"
            pButton
            type="button">
    </button>
  </div>
</p-sidebar>
