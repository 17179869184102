import {PagedEntitiesXsStore, PagedEntitiesXsStoreState} from 'libs/core-store/src';
import {AppEntityType, KnowledgePathListDto} from 'libs/core-shared/src';
import {createSelector} from '@ngrx/store';
import {selectKnowledgePathState} from '../../paths.selectors';
import {OrgaPortalKnowledgePathService} from 'libs/orga-portal/src';

export interface PathsListStoreState extends PagedEntitiesXsStoreState<KnowledgePathListDto> {
}

/* istanbul ignore next */
export const pathsListStore = new PagedEntitiesXsStore<KnowledgePathListDto>({
  actionLabel: 'Orga Portal - Knowledge - Knowledge Paths List',
  stateSelector: createSelector(selectKnowledgePathState, state => state.pathsList),
  serviceType: OrgaPortalKnowledgePathService,
  entityType: AppEntityType.KnowledgePath,
  selectId: knowledgePath => knowledgePath.entity?.knowledgePathId ?? knowledgePath.model?.knowledgePathId,
  stereotyped: false
});
