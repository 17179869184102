<ng-template #entityEditTemplate let-id="id" let-title="title">
  <nexnox-web-master-data-location-groups-location-group-edit #modelComponent
                                                              (modelChange)="onModelChange($event)"
                                                              [creating]="false"
                                                              [id]="id"
                                                              [loading]="loading$ | async"
                                                              [model]="model$ | async"
                                                              [readonly]="(readonly$ | async) || (model$ | async)?.isSystem"
                                                              [stereotypes$]="stereotypes$"
                                                              [title]="title">
  </nexnox-web-master-data-location-groups-location-group-edit>
</ng-template>

<nexnox-web-entity-detail [entityDetailBaseComponent]="this"
                          [entityEditTemplate]="entityEditTemplate"
                          [tabCommands]="['.']"
                          [tabHeading]="title | translate"
                          tabId="locationGroup">
  <ng-container *ngIf="(loading$ | async) === false">
    <div *ngIf="(model$ | async)?.isSystem" class="alert alert-warning">
      <fa-icon [fixedWidth]="true" [icon]="faExclamationTriangle"></fa-icon>
      {{ 'core-portal.master-data.location-group.descriptions.is-system' | translate }}
    </div>
  </ng-container>

  <!-- Locations Tab -->
  <ng-template (selectTab)="onNavigateToTab(['locations'])"
               [heading]="'core-portal.master-data.subtitles.location-list' | translate"
               [lazy]="true"
               nexnoxWebTabTemplate
               tabId="locations">
    <nexnox-web-master-data-location-groups-location-group-location-list *ngIf="passedIds"
                                                                         [isEmbedded]="true"
                                                                         [parentIds]="passedIds">
    </nexnox-web-master-data-location-groups-location-group-location-list>
  </ng-template>
</nexnox-web-entity-detail>
