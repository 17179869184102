import {ChangeDetectionStrategy, Component, Injector} from '@angular/core';
import {ActionButton, CorePortalEntityDetailBaseComponent} from '@nexnox-web/core-portal';
import {AppPermissions, ExportType, StateDto} from '@nexnox-web/core-shared';
import {stateDetailStore} from '../../store';

@Component({
  selector: 'nexnox-web-ticket-settings-states-state-detail',
  templateUrl: './state-detail.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StateDetailComponent extends CorePortalEntityDetailBaseComponent<StateDto> {
  public title = 'core-portal.settings.subtitles.ticket-settings.state-detail';

  protected idParam = 'stateId';
  protected displayKey = 'name';

  constructor(
    protected injector: Injector
  ) {
    super(injector, stateDetailStore);
  }

  protected async getActionButtons(): Promise<ActionButton[]> {
    return this.getDefaultActionButtons(
      'core-portal.settings.actions.ticket-settings.edit-state',
      'core-portal.settings.actions.ticket-settings.save-state',
      'core-portal.settings.actions.ticket-settings.delete-state',
      'core-portal.settings.descriptions.ticket-settings.delete-state',
      AppPermissions.UpdateState,
      AppPermissions.DeleteState,
      ['states'],
      undefined,
      undefined,
      ExportType.STATE
    );
  }
}
