<div *ngIf="entityDetailBaseComponent.isArchived$ | async"
     class="alert alert-warning">{{ 'core-shared.shared.detail.is-archived' | translate }}
</div>
<div [class.overflow-hidden]="hideOverflow"
     class="min-h-100">
  <ng-content></ng-content>

  <nexnox-web-tabs #tabsComponent
                   *ngIf="useTabs; else noTabs"
                   [isTransparent]="true"
                   [ready$]="ready$">
    <!-- Entity Tab -->
    <ng-template (selectTab)="onNavigateToTab(tabCommands)"
                 [hasError]="entityDetailBaseComponent ? (entityDetailBaseComponent.isOwnModelValid$ | async) === false : false"
                 [heading]="tabHeading"
                 [tabId]="tabId"
                 nexnoxWebTabTemplate>
      <ng-container
        *ngTemplateOutlet="entityEditTemplate; context: { title: tabHeading, id: entityDetailBaseComponent.id }">
      </ng-container>
    </ng-template>

    <!-- Other Tabs -->
    <ng-template (selectTab)="tabTemplate.selectTab.emit()"
                 *ngFor="let tabTemplate of tabTemplates"
                 [hasError]="tabTemplate.hasError"
                 [heading]="tabTemplate.heading"
                 [hide]="tabTemplate.hide"
                 [lazy]="tabTemplate.lazy"
                 [tabId]="tabTemplate.tabId"
                 nexnoxWebTabTemplate>
      <ng-container *ngIf="tabTemplate.template" [ngTemplateOutlet]="tabTemplate.template"></ng-container>
    </ng-template>
  </nexnox-web-tabs>
</div>

<ng-template #noTabs>
  <ng-container
    *ngTemplateOutlet="entityEditTemplate; context: { title: tabHeading, id: entityDetailBaseComponent.id }">
  </ng-container>
</ng-template>
