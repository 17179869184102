import {Pipe, PipeTransform} from '@angular/core';
import {minutesTo} from '@nexnox-web/lodash';
import {padStart} from 'lodash';

@Pipe({
  name: 'nexnoxWebMinutesToTimespan'
})
export class NexnoxWebMinutesToTimespanPipe implements PipeTransform {
  public transform(value: number): string {
    const { days, hours, minutes } = minutesTo(value, ['years', 'weeks']);
    const padNumber = (numberToPad: number): string => padStart((numberToPad ?? 0).toString(), 2, '0');
    return `${ days ? `${ days }.` : '' }${ padNumber(hours) }:${ padNumber(minutes) }`;
  }
}
