import {PagedEntitiesXsStore, PagedEntitiesXsStoreState} from '@nexnox-web/core-store';
import {AppEntityType, DashboardDto} from '@nexnox-web/core-shared';
import {Action, createSelector} from '@ngrx/store';
import {selectDashboardsState} from '../../dashboards.selectors';
import {CorePortalDashboardService} from '../../services';
import {Injectable, Injector} from '@angular/core';

export interface DashboardListStoreState extends PagedEntitiesXsStoreState<DashboardDto> {
}

/* istanbul ignore next */
export const dashboardListStore = new PagedEntitiesXsStore<DashboardDto>({
  actionLabel: 'Core Portal - Settings - Dashboards - Dashboard List',
  stateSelector: createSelector(selectDashboardsState, state => state.dashboardList),
  serviceType: CorePortalDashboardService,
  entityType: AppEntityType.None,
  selectId: dashboard => dashboard.entity?.dashboardId ?? dashboard.model?.dashboardId
});

export function dashboardListStoreReducer(state: DashboardListStoreState, action: Action): any {
  return dashboardListStore.reducer(state, action);
}

@Injectable()
export class DashboardListStoreEffects extends dashboardListStore.effects {
  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }
}
