<ng-template #entityEditTemplate let-id="id" let-title="title">
  <nexnox-web-ticket-settings-priority-sets-priority-set-edit #modelComponent
                                                              (modelChange)="onModelChange($event)"
                                                              [creating]="false"
                                                              [id]="id"
                                                              [loading]="loading$ | async"
                                                              [model]="model$ | async"
                                                              [readonly]="readonly$ | async"
                                                              [stereotypes$]="stereotypes$"
                                                              [title]="title">
  </nexnox-web-ticket-settings-priority-sets-priority-set-edit>
</ng-template>

<nexnox-web-entity-detail [entityDetailBaseComponent]="this"
                          [entityEditTemplate]="entityEditTemplate"
                          [tabCommands]="['.']"
                          [tabHeading]="title | translate"
                          tabId="prioritySet">
</nexnox-web-entity-detail>
