<ng-template #entityEditTemplate
             let-id="id"
             let-title="title">
  <nexnox-web-master-data-locations-location-edit #modelComponent
                                                  (modelChange)="onModelChange($event)"
                                                  [creating]="false"
                                                  [id]="id"
                                                  [loading]="loading$ | async"
                                                  [model]="model$ | async"
                                                  [readonly]="readonly$ | async"
                                                  [stereotypes$]="stereotypes$"
                                                  [title]="title">
  </nexnox-web-master-data-locations-location-edit>
</ng-template>

<nexnox-web-entity-detail [entityDetailBaseComponent]="this"
                          [entityEditTemplate]="entityEditTemplate"
                          [tabCommands]="['.']"
                          [tabHeading]="title | translate"
                          tabId="location">

  <!-- Location Groups Tab -->
  <ng-template (selectTab)="onNavigateToTab(['location-groups'])"
               *ngIf="displayLocationGroupsTab$ | async"
               [heading]="'core-portal.master-data.subtitles.location-group-list' | translate"
               [lazy]="true"
               nexnoxWebTabTemplate
               tabId="location-groups">
    <nexnox-web-master-data-locations-locationgroup-list *ngIf="passedIds"
                                                         [isEmbedded]="true"
                                                         [parentIds]="passedIds">
    </nexnox-web-master-data-locations-locationgroup-list>
  </ng-template>

  <!-- Addresses Tab -->
  <ng-template (selectTab)="onNavigateToTab(['addresses'])"
               *ngIf="displayAddressesTab$ | async"
               [heading]="'core-portal.master-data.subtitles.address-list' | translate"
               [lazy]="true"
               nexnoxWebTabTemplate
               tabId="addresses">
    <nexnox-web-master-data-locations-location-address-list *ngIf="passedIds"
                                                            [canCreate]="true"
                                                            [isEmbedded]="true"
                                                            [parentIds]="passedIds">
    </nexnox-web-master-data-locations-location-address-list>
  </ng-template>

  <!-- Contacts Tab -->
  <ng-template (selectTab)="onNavigateToTab(['contacts'])"
               *ngIf="displayContactsTab$ | async"
               [heading]="'core-portal.master-data.subtitles.contact-list' | translate"
               [lazy]="true"
               nexnoxWebTabTemplate
               tabId="contacts">
    <nexnox-web-master-data-locations-location-contact-list *ngIf="passedIds"
                                                            [canCreate]="false"
                                                            [getEntityOnModeChange]="false"
                                                            [isEmbedded]="true"
                                                            [location$]="model$"
                                                            [parentIds]="passedIds">
    </nexnox-web-master-data-locations-location-contact-list>
  </ng-template>

  <!-- Resources Tab -->
  <ng-template (selectTab)="onNavigateToTab(['resources'])"
               *ngIf="displayResourcesTab$ | async"
               [heading]="'resources.subtitles.resource-list' | translate"
               [lazy]="true"
               nexnoxWebTabTemplate
               tabId="resources">
    <nexnox-web-master-data-locations-location-resource-list *ngIf="passedIds"
                                                             [canCreate]="false"
                                                             [getEntityOnModeChange]="false"
                                                             [isEmbedded]="true"
                                                             [locationStereotypeId$]="locationStereotypeId$"
                                                             [parentIds]="passedIds">
    </nexnox-web-master-data-locations-location-resource-list>
  </ng-template>

  <!-- Tickets Tab -->
  <ng-template (selectTab)="onNavigateToTab(['tickets'])"
               *ngIf="displayTicketsTab$ | async"
               [heading]="'tickets.subtitles.ticket-list' | translate"
               [lazy]="true"
               nexnoxWebTabTemplate
               tabId="tickets">
    <nexnox-web-master-data-locations-location-ticket-list *ngIf="passedIds"
                                                           [canCreate]="false"
                                                           [descendantId]="+passedIds[0]"
                                                           [getEntityOnModeChange]="false"
                                                           [isEmbedded]="true"
                                                           [locationStereotypeId$]="locationStereotypeId$"
                                                           [parentIds]="passedIds">
    </nexnox-web-master-data-locations-location-ticket-list>
  </ng-template>

  <!-- Missions Tab -->
  <ng-template (selectTab)="onNavigateToTab(['missions'])"
               *ngIf="displayMissionsTab$ | async"
               [heading]="'missions.subtitles.missions' | translate"
               [lazy]="true"
               nexnoxWebTabTemplate
               tabId="missions">
    <nexnox-web-master-data-locations-location-mission-list *ngIf="passedIds"
                                                            [canCreate]="false"
                                                            [getEntityOnModeChange]="false"
                                                            [isEmbedded]="true"
                                                            [parentIds]="passedIds">
    </nexnox-web-master-data-locations-location-mission-list>
  </ng-template>

  <!-- Issues Tab -->
  <ng-template (selectTab)="onNavigateToTab(['issues'])"
               *ngIf="displayIssuesTab$ | async"
               [heading]="'issues.title' | translate"
               [lazy]="true"
               nexnoxWebTabTemplate
               tabId="issues">
    <nexnox-web-master-data-locations-location-issue-list *ngIf="passedIds"
                                                          [canCreate]="false"
                                                          [getEntityOnModeChange]="false"
                                                          [isEmbedded]="true"
                                                          [parentIds]="passedIds">
    </nexnox-web-master-data-locations-location-issue-list>
  </ng-template>

  <!-- Contracts Tab -->
  <ng-template (selectTab)="onNavigateToTab(['contracts'])"
               *ngIf="displayContractsTab$ | async"
               [heading]="'contracts.subtitles.contract-list' | translate"
               [lazy]="true"
               nexnoxWebTabTemplate
               tabId="contracts">
    <nexnox-web-master-data-locations-location-contract-list *ngIf="passedIds"
                                                             [canCreate]="false"
                                                             [getEntityOnModeChange]="false"
                                                             [isEmbedded]="true"
                                                             [parentIds]="passedIds">
    </nexnox-web-master-data-locations-location-contract-list>
  </ng-template>

  <!-- Attachments Tab -->
  <ng-template (selectTab)="onNavigateToTab(['attachments'])"
               *ngIf="displayAttachmentsTab$ | async"
               [heading]="('core-portal.core.general.attachments' | translate) + (attachmentsComponent ? (attachmentsComponent.attachmentsString$ | async) : '')"
               [lazy]="true"
               nexnoxWebTabTemplate
               tabId="attachments">
    <nexnox-web-tech-attachments #attachmentsComponent
                                 (attachmentsChange)="onAttachmentsChange($event, model)"
                                 *ngIf="model$ | async as model"
                                 [attachments$]="attachments$"
                                 [loading]="loading$ | async"
                                 [readonly]="readonly$ | async">
    </nexnox-web-tech-attachments>
  </ng-template>

  <!-- Tasks Tab -->
  <ng-template (selectTab)="onNavigateToTab(['tasks'])"
               *ngIf="displayTasksTab$ | async"
               [heading]="'resource-tasks.subtitles.resource-task-list' | translate"
               [lazy]="true"
               nexnoxWebTabTemplate
               tabId="tasks">
    <nexnox-web-master-data-locations-location-task-list *ngIf="passedIds"
                                                         [canCreate]="false"
                                                         [getEntityOnModeChange]="false"
                                                         [isEmbedded]="true"
                                                         [parentIds]="passedIds">
    </nexnox-web-master-data-locations-location-task-list>
  </ng-template>

  <!-- Planned Tab -->
  <ng-template (selectTab)="onNavigateToTab(['planned'])"
               *ngIf="displayPlannedTab$ | async"
               [heading]="'orga-portal.tasks.subtitles.location-planned-tasks' | translate"
               nexnoxWebTabTemplate
               tabId="planned">
    <nexnox-web-location-tasks-planned-tasks *ngIf="passedIds"
                                             [locationId]="(model$ | async).locationId">
    </nexnox-web-location-tasks-planned-tasks>
  </ng-template>

  <!-- Audit -->
  <ng-template (selectTab)="onNavigateToTab(['audit'])"
               *ngIf="readAuditPermission$ | async"
               [heading]="'core-portal.audit.title' | translate"
               [lazy]="true"
               nexnoxWebTabTemplate
               tabId="audit">
    <nexnox-web-location-audit-list *ngIf="passedIds"
                                    [isEmbedded]="true"
                                    [parentIds]="[entityTypes.Location, passedIds[0]]">
    </nexnox-web-location-audit-list>
  </ng-template>
</nexnox-web-entity-detail>

<nexnox-web-location-status-sidebar #locationStatusSidebar
                                    (closeLocation)="onCloseLocation($event)"
                                    (openLocation)="onOpenLocation()"
                                    [currentLocationId$]="locationId$">
</nexnox-web-location-status-sidebar>
