<nexnox-web-modal #modalComponent
                  [cancelButtonText]="'core-portal.core.general.cancel' | translate"
                  [confirmButtonText]="'missions.actions.assign-to' | translate"
                  [preConfirmFn]="preConfirmFn"
                  [title]="'missions.actions.assign-to' | translate">
  <div class="row w-100">
    <form [formGroup]="form" class="w-100">
      <formly-form [(model)]="model"
                   [fields]="fields"
                   [form]="form"
                   class="row w-100">
      </formly-form>
    </form>
  </div>
</nexnox-web-modal>
