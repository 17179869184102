import {Injectable, Injector} from '@angular/core';
import {CorePortalEmbeddedEntityBaseService, CorePortalRoleService} from '@nexnox-web/core-portal';

@Injectable()
export class ContactRoleService extends CorePortalEmbeddedEntityBaseService {
  constructor(
    protected injector: Injector
  ) {
    super(injector, CorePortalRoleService, null, 'role', ['contact']);
  }
}
