import {FieldType} from '@ngx-formly/core';
import {Component, OnInit} from '@angular/core';
import {DualListBoxItem} from '@nexnox-web/core-shared';
import {merge, Observable, of} from 'rxjs';
import {map, mergeMap} from 'rxjs/operators';

@Component({
  selector: 'nexnox-web-formly-dual-list-box',
  templateUrl: './formly-dual-list-box.component.html'
})
export class FormlyDualListBoxComponent extends FieldType implements OnInit {
  public availableItems$: Observable<DualListBoxItem[]>;
  public activeItems$: Observable<DualListBoxItem[]>;

  public ngOnInit(): void {
    this.activeItems$ = merge(
      of(this.formControl.value),
      this.formControl.valueChanges
    ).pipe(
      map(model => model ?? [])
    );
    this.availableItems$ = (this.to.availableItems$ ?? of([])).pipe(
      mergeMap((availableItems: DualListBoxItem[]) => this.activeItems$.pipe(
        map(activeItems => availableItems.filter(x => !activeItems.find(y => y.externalId === x.externalId)))
      ))
    );
  }

  public onActiveItemsChange(items: DualListBoxItem[]): void {
    this.formControl.setValue(items);
  }
}
