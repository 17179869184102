import {PagedEntitiesXsStore, PagedEntitiesXsStoreState} from '@nexnox-web/core-store';
import {AppEntityType, ContactDto} from '@nexnox-web/core-shared';
import {createSelector} from '@ngrx/store';
import {FunctionContactService} from '../../services';
import {selectFunctionsState} from '../../functions.selectors';

export interface FunctionContactListStoreState extends PagedEntitiesXsStoreState<ContactDto> {
}

/* istanbul ignore next */
export const functionContactListStore = new PagedEntitiesXsStore<ContactDto>({
  actionLabel: 'Core Portal - Master Data - Function - Contact List',
  stateSelector: createSelector(selectFunctionsState, state => state.functionContactList),
  serviceType: FunctionContactService,
  entityType: AppEntityType.Contact,
  selectId: contact => contact.entity?.contactId ?? contact.model?.contactId
});
