import {InjectionToken, NgModule} from '@angular/core';
import {ActionReducerMap, StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {issueTemplateEffects, issueTemplateReducers} from './store';

export const REDUCER_TOKEN = new InjectionToken<ActionReducerMap<any>>(
  '@nexnox-web/core-portal/settings/issue-templates: Store Reducers'
);

@NgModule({
  imports: [
    StoreModule.forFeature('core-portal-feature-settings-issue-templates', REDUCER_TOKEN),
    EffectsModule.forFeature(issueTemplateEffects)
  ],
  providers: [
    {
      provide: REDUCER_TOKEN,
      useValue: issueTemplateReducers
    }
  ]
})
export class IssueTemplatesStoreModule {
}
