<nexnox-web-cardbox [loading]="loading"
                    [title]="'tech-portal.subtitles.links' | translate"
                    paddingClasses="px-3 pb-3">
  <nexnox-web-rule-editor-list #linksEdit
                               (itemsChange)="onLinksChange($event)"
                               [disabled]="readonly"
                               [inheritedFieldsFn]="linksFieldsFn"
                               [items]="links$ | async"
                               [title]="linksTitle">
  </nexnox-web-rule-editor-list>
</nexnox-web-cardbox>
