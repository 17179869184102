import {ChangeDetectionStrategy, Component, Injector, OnDestroy, OnInit} from '@angular/core';
import {
  ActionButton,
  authStore,
  CorePortalCardboxAction,
  CorePortalContactService,
  CorePortalEntityOverviewBaseComponent,
  DatatableActionButton,
  DeleteEntityModel
} from '@nexnox-web/core-portal';
import {
  AppEntityType,
  AppPermissions,
  ControllerOperationId,
  DataTableViewType,
  Mappers,
  MissionCompleteDto,
  MissionState,
  MissionType,
  TenantInfoDto
} from '@nexnox-web/core-shared';
import {missionListStore, MissionResourcePreviewService} from '../../store';
import {faPlus} from '@fortawesome/free-solid-svg-icons/faPlus';
import {combineLatestWith, delay, distinctUntilChanged, map, mergeMap} from 'rxjs/operators';
import {BehaviorSubject, Observable} from 'rxjs';
import {cloneDeep, isEqual} from 'lodash';
import {faExchangeAlt} from '@fortawesome/free-solid-svg-icons/faExchangeAlt';
import {faUserCheck} from '@fortawesome/free-solid-svg-icons/faUserCheck';
import {TechPortalFeatureMissionAssignModalComponent} from '../../modals';
import {select} from '@ngrx/store';
import {faFileExport} from '@fortawesome/free-solid-svg-icons/faFileExport';
import {TechPortalExportByTemplateModalComponent} from '@nexnox-web/tech-portal-lib';
import {
  documentContextTypeEnumOptions,
  TechPortalFeatureDocumentTemplateService
} from '@nexnox-web/tech-portal/features/templates';
import {missionStateLifecycle, techPortalFeatureMissionStateEnumOptions} from '../../models';
import {TechPortalFeatureMissionActionsFacade, XsStoreMissionActionsFacade} from '../../facades';
import {getCardBoxHeaderActions} from '../../components';
import {faPlayCircle} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'nexnox-web-missions-mission-list',
  templateUrl: './mission-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TechPortalFeatureMissionListComponent extends CorePortalEntityOverviewBaseComponent<MissionCompleteDto> implements OnInit, OnDestroy {
  public title = 'missions.subtitles.missions';
  public createTitle = 'missions.actions.create-mission';
  public idProperty = 'missionId';
  public displayProperty = 'title';
  public pageOperation = ControllerOperationId.MissionControllerList;
  public componentId = 'MissionListComponent';
  public enableViews = true;
  public datatableConfigName = 'MissionDefault';

  public createTitle$: Observable<string>;

  public createMissionTypeSubject: BehaviorSubject<MissionType> = new BehaviorSubject<MissionType>(MissionType.Manual);
  public mode: BehaviorSubject<DataTableViewType>;
  public dataTableViewType = DataTableViewType;

  declare protected entityActionsFacade: TechPortalFeatureMissionActionsFacade;

  private activeTenantSubject = new BehaviorSubject<TenantInfoDto>(null);

  constructor(
    protected injector: Injector,
    private corePortalContactService: CorePortalContactService,
    private documentTemplateService: TechPortalFeatureDocumentTemplateService
  ) {
    super(injector, missionListStore, Mappers.MissionCompleteDto.serializedName, AppEntityType.Mission);
  }

  public ngOnInit(): void {
    this.customActionsFacade = this.customActionsFacade ?? new XsStoreMissionActionsFacade(this.injector, missionListStore);

    this.mode = new BehaviorSubject<DataTableViewType>((this.viewType as any) ?? DataTableViewType.Table);

    this.subscribe(this.mode.asObservable(), (mode) => {
      this.viewType = mode as any;
    });

    super.ngOnInit();

    this.monitors.add([
      {
        name: 'missionResourcePreview',
        keys: ['resource'],
        service: MissionResourcePreviewService,
        payload: {
          missionType: this.createMissionTypeSubject.getValue()
        }
      }
    ]);

    this.createTitle$ = this.createMissionTypeSubject.asObservable().pipe(
      distinctUntilChanged(),
      map(missionCreateType => {
        switch (missionCreateType) {
          case MissionType.Manual:
            return 'missions.actions.create-mission-manual';
          default:
            return this.createTitle;
        }
      })
    );

    this.subscribe(this.store.pipe(
      select(authStore.selectors.selectActiveTenant),
      distinctUntilChanged((a, b) => isEqual(a, b))
    ), tenant => this.activeTenantSubject.next(tenant));
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();

    this.createMissionTypeSubject.next(MissionType.Manual);
    this.activeTenantSubject.next(null);
  }

  /* istanbul ignore next */
  public getRowActionButtons(): DatatableActionButton[] {
    return [
      ...this.getDefaultRowActionButtons(
        'missions.actions.edit-mission',
        (row: MissionCompleteDto) => `/missions/${ row.missionId }`,
        [AppPermissions.UpdateMission],
        {
          module: 'inventory'
        }
      ),

      null,

      {
        id: 'assignToMe',
        tooltip: 'missions.actions.assign-to-me',
        icon: faUserCheck,
        permissions: [AppPermissions.UpdateMission],
        disabled: (row: MissionCompleteDto) => {
          const tenant = this.activeTenantSubject.getValue();
          return (tenant?.names ?? []).length ? tenant.names[0].contactId === row.solutionContact?.contactId : true;
        },
        onClick: (row: MissionCompleteDto) => this.entityActionsFacade.assignToMe({ id: row.missionId })
      },
      {
        id: 'assignTo',
        tooltip: 'missions.actions.assign-to',
        icon: faUserCheck,
        permissions: [AppPermissions.UpdateMission],
        onClick: (row: MissionCompleteDto) => this.modalService.showModal(TechPortalFeatureMissionAssignModalComponent, instance => {
          instance.service = this.corePortalContactService;
        })
          .then(({ value: { contact } }) => this.entityActionsFacade.assignTo({
            id: row.missionId,
            contact
          }))
          .catch(() => null)
      },
      {
        id: 'changeState',
        tooltip: 'missions.actions.change-state',
        icon: faExchangeAlt,
        permissions: [AppPermissions.UpdateMission],
        buttons: (row: MissionCompleteDto): DatatableActionButton[] => {
          const states = !row.isCompleted ? missionStateLifecycle[row.state ?? 0].map(state =>
            techPortalFeatureMissionStateEnumOptions[state]
          ) : [];
          if (row.state === MissionState.Done || row.state === MissionState.Canceled) {
            states.unshift({
              label: 'missions.actions.complete-mission',
              value: null
            });
          }
          return states.map(state => {
            return ({
              tooltip: !state?.value ? state?.label : `missions.mission-states-change.${ state?.value }`,
              onClick: () => this.changeRowMissionState(row, row.missionId, state?.value)
            })
          });
        },
        show: (row: MissionCompleteDto) => !row.isCompleted
      },
      {
        id: 'resume',
        tooltip: 'missions.actions.resume-mission',
        icon: faPlayCircle,
        permissions: [AppPermissions.UpdateClosedMission],
        onClick: (row: MissionCompleteDto) => this.entityActionsFacade.resume({ id: row.missionId }),
        show: (row: MissionCompleteDto) => row.isCompleted
      },
      {
        id: 'export',
        tooltip: 'missions.actions.export-mission',
        icon: faFileExport,
        permissions: [AppPermissions.ReadDocumentTemplate],
        onClick: (row: MissionCompleteDto) => this.modalService.showModal(TechPortalExportByTemplateModalComponent, instance => {
          instance.service = this.documentTemplateService;

          switch (row?.type) {
            case MissionType.Ticket:
              instance.contextTypes = [
                documentContextTypeEnumOptions[1],
                documentContextTypeEnumOptions[2],
                documentContextTypeEnumOptions[3],
                documentContextTypeEnumOptions[6],
                documentContextTypeEnumOptions[7]
              ];
              break;
            case MissionType.Task:
              instance.contextTypes = [
                documentContextTypeEnumOptions[1],
                documentContextTypeEnumOptions[2],
                documentContextTypeEnumOptions[5],
                documentContextTypeEnumOptions[2],
                documentContextTypeEnumOptions[6],
                documentContextTypeEnumOptions[9]
              ];
              break;
            default:
              instance.contextTypes = [
                documentContextTypeEnumOptions[1],
                documentContextTypeEnumOptions[2]
              ];
          }
        })
          .then(({ value: { template } }) => this.entityActionsFacade.export({
            id: row.missionId,
            templateId: template.documentTemplateId
          }))
          .catch(() => null)
      }
    ];
  }

  public getDeleteEntityModel(): DeleteEntityModel {
    return {
      titleKey: 'missions.actions.delete-mission',
      descriptionKey: 'missions.descriptions.delete-mission',
      confirmKey: 'missions.actions.delete-mission',
      deletePermission: AppPermissions.DeleteMission
    };
  }

  public onCreate(): void {
    const model = cloneDeep(this.editComponent?.getSanitizedModel() ?? this.createModel$.getValue());
    const parentIds = this.parentIds;

    // No redirection after creation
    this.detailLinkAdditionalSubject.next([]);

    this.isDeactivateUnsavedChangesModal = true;

    switch (this.createMissionTypeSubject.getValue()) {
      case MissionType.Manual:

        this.store.dispatch(missionListStore.actions.createOneManually({ model, parentIds }));
        break;
    }
  }

  public getCardBoxHeaderActions(): CorePortalCardboxAction[] {
    return !this.disableSettingsViewSelect ? getCardBoxHeaderActions(this.mode, this.permissionService, this.translate, this.tenantRouter) : [];
  }

  /* istanbul ignore next */
  protected async getActionButtons(): Promise<ActionButton[]> {
    return [
      this.getDefaultActionButtons(null, null)[0],
      {
        label: 'missions.actions.create-mission',
        type: 'button',
        class: 'btn-primary',
        permission: AppPermissions.CreateMission,
        icon: faPlus,
        shouldShow: () => this.activeTenant$.pipe(
          combineLatestWith(this.createMissionTypeSubject.asObservable(), this.isCreateVisible$),
          map(([activeTenant, missionType, isCreateVisible]) => Boolean(activeTenant) && (isCreateVisible && missionType === MissionType.Manual || !isCreateVisible))
        ),
        isDisabled: () => this.createModel$.asObservable().pipe(
          delay(0),
          mergeMap(() => this.isCreateVisible$.asObservable().pipe(
            map((isCreateVisible) => isCreateVisible && !this.editComponent?.isModelValid())
          ))
        ),
        isLoading: () => this.loading$,
        callback: () => {
          this.createMissionTypeSubject.next(MissionType.Manual);
          this.onCreateAction();
        }
      }
    ];
  }

  protected async onCancelAction(): Promise<void> {
    await super.onCancelAction();

    this.createMissionTypeSubject.next(MissionType.Manual);
  }

  private changeRowMissionState(mission: any, id: number, newState: MissionState): void {
    if (!newState && !mission.isCompleted) {
      this.entityActionsFacade.complete({ id });
    } else {
      this.entityActionsFacade.changeState({ id, state: newState });
    }
  }
}
