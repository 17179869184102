import {PagedEntitiesXsStore, PagedEntitiesXsStoreState} from '@nexnox-web/core-store';
import {AppEntityType, HolidaySetDto} from '@nexnox-web/core-shared';
import {createSelector} from '@ngrx/store';
import {selectHolidaysState} from '../../holidays.selectors';
import {CorePortalFeatureMasterDataHolidaySetService} from '../../services';

export interface HolidaysListStoreState extends PagedEntitiesXsStoreState<HolidaySetDto> {
}

/* istanbul ignore next */
export const holidaysListStore = new PagedEntitiesXsStore<HolidaySetDto>({
  actionLabel: 'Core Portal - Master Data - Holidays List',
  stateSelector: createSelector(selectHolidaysState, state => state.holidaysList),
  serviceType: CorePortalFeatureMasterDataHolidaySetService,
  entityType: AppEntityType.None,
  selectId: holidaySet => holidaySet.entity?.holidaySetId ?? holidaySet.model?.holidaySetId,
  stereotyped: false
});
