import {EntityXsStoreState, getInitialEntityXsStoreState} from './entity-xs-store.state';
import {AppEntityType, CoreSharedApiBaseService, IStereotypedDto} from '@nexnox-web/core-shared';
import {Injectable, Injector, Type} from '@angular/core';
import {createEntityXsStoreActions, EntityXsStoreActions} from './entity-xs-store.actions';
import {createEntityXsStoreReducer} from './entity-xs-store.reducer';
import {createEntityXsStoreSelectors, EntityXsStoreSelectors} from './entity-xs-store.selectors';
import {EntityXsStoreEffects} from './entity-xs-store.effects';
import {ModelXsStore, ModelXsStoreOptions} from '../model';
import {isUndefined} from 'lodash';
import {BaseXsStoreReducerTypes} from '../base';
import {IsDeepEqualWithCustomizer} from '@nexnox-web/lodash';
import {entityXsStoreCustomPropertyValuesChangeCustomizer} from './entity-xs-store.change-customizers';

export interface EntityXsStoreOptions<E, M, S extends EntityXsStoreState<E, M>> extends ModelXsStoreOptions<E, M, S> {
  serviceType: Type<CoreSharedApiBaseService>;
  entityType: AppEntityType;
  stereotyped?: boolean;
  inheritance?: boolean;
  previewFields?: string[];
  changeCustomizers?: IsDeepEqualWithCustomizer[];
}

export class EntityXsStore<E, M = E, S extends EntityXsStoreState<E, M> = EntityXsStoreState<E, M>> extends ModelXsStore<E, M> {
  public actions: EntityXsStoreActions<E, M>;
  public effects: Type<EntityXsStoreEffects<E, M, S>>;
  public selectors: EntityXsStoreSelectors<E, M, S>;

  constructor(
    protected options: EntityXsStoreOptions<E, M, S>
  ) {
    super({
      ...options,
      createEffectsArgs: options.createEffectsArgs ?? [
        options.serviceType,
        options.entityType,
        EntityXsStore.getPrepareEntityFn(options),
        EntityXsStore.getPrepareModelFn(options),
        EntityXsStore.getSanitizeModelFn(options)
      ]
    });
  }

  protected static prepareEntity<E extends IStereotypedDto>(entity: E): E {
    const newEntity: E = super.prepareEntity(entity);

    if (this.isStereotypedDto(newEntity)) {
      for (const filledCustomValue of newEntity.customPropertyValues) {
        delete filledCustomValue.filledCustomValueId;

        for (const customValue of filledCustomValue.customValues) {
          delete customValue.customValueId;
          delete customValue.filledCustomValueId;
          delete customValue.customValue.customValueId;
        }
      }
    }

    return newEntity;
  }

  protected static getChangeCustomizers<E, M, S extends EntityXsStoreState<E, M>>(
    options: EntityXsStoreOptions<E, M, S>
  ): IsDeepEqualWithCustomizer[] {
    const stereotyped = !isUndefined(options.stereotyped) ? options.stereotyped : true;

    return [
      ...(options.changeCustomizers ?? []),
      ...(stereotyped ? [entityXsStoreCustomPropertyValuesChangeCustomizer] : [])
    ];
  }

  private static isStereotypedDto(entity: any): entity is IStereotypedDto {
    return 'stereotypeId' in entity && 'customPropertyValues' in entity;
  }

  public getInitialState(): EntityXsStoreState<E, M> {
    return getInitialEntityXsStoreState();
  }

  protected createActions(label: string): EntityXsStoreActions<E, M> {
    return createEntityXsStoreActions(label);
  }

  protected createReducerArray(
    initialState: S
  ): BaseXsStoreReducerTypes<S, EntityXsStoreActions<E, M>>[] | any[] {
    return [
      ...super.createReducerArray(initialState),
      ...createEntityXsStoreReducer(this.actions, initialState, this.options.previewFields)
    ];
  }

  protected createEffects(
    serviceType: Type<CoreSharedApiBaseService>,
    entityType: AppEntityType,
    prepareEntity: (entity: E) => E,
    prepareModel: (entity: E, model: M) => M,
    sanitizeModel: (model: M, entity: E) => E,
    ...args: any[]
  ): Type<EntityXsStoreEffects<E, M, S>> {
    const actions = this.actions;
    const selectors = this.selectors;
    const stereotyped = !isUndefined(this.options.stereotyped) ? this.options.stereotyped : true;
    const inheritance = !isUndefined(this.options.inheritance) ? this.options.inheritance : false;

    @Injectable()
    class Effects extends EntityXsStoreEffects<E, M, S> {
      constructor(
        protected injector: Injector
      ) {
        super(
          injector,
          actions,
          selectors,
          serviceType,
          entityType,
          prepareEntity,
          prepareModel,
          sanitizeModel,
          stereotyped,
          inheritance
        );
      }
    }

    return Effects;
  }

  protected createSelectors(): EntityXsStoreSelectors<E, M, S> {
    return createEntityXsStoreSelectors(
      this.options.stateSelector,
      EntityXsStore.getSanitizeModelFn(this.options),
      EntityXsStore.getChangeCustomizers(this.options)
    );
  }
}
