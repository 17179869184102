<ng-template #entityEditTemplate let-id="id" let-title="title">
  <nexnox-web-notification-rules-edit #modelComponent
                                      (modelChange)="onModelChange($event)"
                                      [creating]="false"
                                      [id]="id"
                                      [loading]="loading$ | async"
                                      [model]="model$ | async"
                                      [readonly]="readonly$ | async"
                                      [title]="title">
  </nexnox-web-notification-rules-edit>
</ng-template>

<nexnox-web-entity-detail [entityDetailBaseComponent]="this"
                          [entityEditTemplate]="entityEditTemplate"
                          [tabCommands]="['.']"
                          [tabHeading]="title | translate"
                          tabId="notificationRule">
  <!-- Filter selection -->
  <ng-template *ngIf="model$ | async as model"
               [hasError]="(model?.restriction?.filters ?? []).length === 0"
               [heading]="'core-portal.settings.notification-rules.restriction' | translate"
               [lazy]="true"
               nexnoxWebTabTemplate
               tabId="restriction">
    <nexnox-web-settings-notification-rules-restriction (restrictionChanged)="onRestrictionChange($event, model)"
                                                        [creating]="false"
                                                        [loading]="loading$ | async"
                                                        [readonly]="readonly$ | async"
                                                        [restriction]="model?.restriction"
                                                        [trigger]="model?.trigger">
    </nexnox-web-settings-notification-rules-restriction>
  </ng-template>

  <!-- Target selection -->
  <ng-template *ngIf="model$ | async as model"
               [hasError]="(model?.targets ?? []).length === 0"
               [heading]="'core-portal.settings.notification-rules.target' | translate"
               nexnoxWebTabTemplate
               tabId="targets">
    <nexnox-web-settings-notification-rules-target-edit (targetsChanged)="onTargetsChanged($event, model)"
                                                        [loading]="loading$ | async"
                                                        [readonly]="readonly$ | async"
                                                        [targets]="model?.targets">
    </nexnox-web-settings-notification-rules-target-edit>
  </ng-template>

</nexnox-web-entity-detail>
