import {MailInStatus} from "@nexnox-web/core-shared";

export const mailInStatusTypeEnumOptions: { label: string, value: MailInStatus }[] = [
  {
    label: 'core-portal.settings.mail-in-status.0',
    value: MailInStatus.Processing
  },
  {
    label: 'core-portal.settings.mail-in-status.1',
    value: MailInStatus.Failed
  },
  {
    label: 'core-portal.settings.mail-in-status.2',
    value: MailInStatus.Success
  }
];
