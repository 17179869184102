<p-sidebar #sidebarComponent
           (visibleChange)="onVisibleChange($event)"
           [transitionOptions]="transitionOptions"
           [visible]="visible$ | async"
           appendTo="body"
           position="right"
           styleClass="p-sidebar-lg bg-content">

  <ng-container #editComponentContainer></ng-container>

  <div *ngIf="resource$ | async as resource" class="text-center">
    <a [nexnoxWebTenantRouterLink]="'/resources/' + resource?.resourceId" module="inventory" target="_blank">
      {{ 'core-shared.shared.actions.new-tab' | translate }}
    </a>
  </div>

</p-sidebar>
