import {PagedEntitiesXsStoreState} from '@nexnox-web/core-store';
import {AppEntityType, OccurredHintListDto} from '@nexnox-web/core-shared';
import {Action, ActionReducer, createSelector} from '@ngrx/store';
import {selectResourcesState} from '../../resources.selectors';
import {TechPortalFeatureResourceOccurredHintsService} from '../../services';
import {Injectable, Injector} from '@angular/core';
import {cloneDeep} from 'lodash';
import {createEffect, ofType} from "@ngrx/effects";
import {catchError, map, mergeMap, tap} from "rxjs/operators";
import {of} from "rxjs";
import {
  ResourceOccurredHintsListXsStore,
  ResourceOccurredHintsListXsStoreActions,
  ResourceOccurredHintsListXsStoreState
} from "@nexnox-web/tech-portal/features/resources/src/lib/store/stores/resource-occurred-hints/resource-occurred-hints.xs-store";

export interface LocalOccurredHintListDto extends OccurredHintListDto {
  hintCodeId?: number;
  displayName?: string;
  externalId?: string;
}

export interface ResourceOccurredHintsStoreState extends PagedEntitiesXsStoreState<OccurredHintListDto, LocalOccurredHintListDto> {
}

/* istanbul ignore next */
export const resourceOccurredHintsListStore = new ResourceOccurredHintsListXsStore({
  actionLabel: 'Tech Portal - Resources - Resource Occurred Hints List',
  stateSelector: createSelector(selectResourcesState, state => state.resourceOccurredHintsList),
  entityType: AppEntityType.None,
  serviceType: TechPortalFeatureResourceOccurredHintsService,
  selectId: hint => hint.entity?.hint.hintCodeId ?? hint.model?.hint?.hintCodeId,
  stereotyped: false,
  prepareModel: (entity: OccurredHintListDto, model: LocalOccurredHintListDto, base: (entity: LocalOccurredHintListDto,
                                                                                      model: LocalOccurredHintListDto) => LocalOccurredHintListDto) => {
    const newModel = cloneDeep(base(entity, model));

    newModel.hintCodeId = newModel?.hint?.hintCodeId;
    newModel.displayName = newModel?.hint?.displayName;
    newModel.externalId = newModel?.hint?.externalId;

    return newModel;
  }
});

export function resourceOccurredHintListStoreReducer(state: ResourceOccurredHintsListXsStoreState,
                                                     action: Action): ActionReducer<any> {
  return resourceOccurredHintsListStore.reducer(state, action);
}

@Injectable()
export class ResourceOccurredHintListStoreEffects extends resourceOccurredHintsListStore.effects {
  public actions: ResourceOccurredHintsListXsStoreActions;

  public deleteOccurredHint$: any;
  public deleteOccurredHintSuccess$: any;

  protected service: TechPortalFeatureResourceOccurredHintsService;

  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }

  protected createEffects(): void {
    super.createEffects();

    this.deleteOccurredHint$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.deleteOccurredHint),
      mergeMap(({
                  occurredHintId,
                  hintCodeId
                }) => this.service.deleteOccurredHint<OccurredHintListDto>(occurredHintId).pipe(
        map(() => this.actions.deleteOccurredHintSuccess({ occurredHintId, hintCodeId })),
        catchError(error => of(this.actions.error({ error, action: this.actions.deleteOccurredHint })))
      ))
    ));

    this.deleteOccurredHintSuccess$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.deleteOccurredHintSuccess),
      tap((action) => this.actionCallback(action)),
    ), { dispatch: false });
  }

  protected actionCallback(action: Action, isError: boolean = false): void {
    super.actionCallback(action, isError);

    this.checkAction(this.actions.deleteOccurredHintSuccess, action, () => this.deleteOccurredHintSuccessActionCallback());
  }

  protected deleteOccurredHintSuccessActionCallback(): void {
    this.apiNotificationService.showTranslatedSuccess('core-shared.shared.toast.entity-deleted');
  }
}

