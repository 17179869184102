import {DatatableTableColumnTyping} from '@nexnox-web/core-portal';
import {InjectionToken} from '@angular/core';

export interface CorePortalDatatableStandardConfig {
  [name: string]: {
    excludedColumns: string[];
    defaultColumns: string[];
    columnTypings: DatatableTableColumnTyping[];
  }
}

export const CORE_PORTAL_DATATABLE_STANDARD_CONFIG = new InjectionToken<CorePortalDatatableStandardConfig>(
  '@nexnox-web/core-portal: Datatable standard config'
);
