import {ChangeDetectionStrategy, Component, Injector} from '@angular/core';
import {CorePortalEntityEditBaseComponent} from '@nexnox-web/core-portal';
import {EscalationLevelDto} from '@nexnox-web/core-shared';
import {FormlyFieldConfig} from '@ngx-formly/core';

@Component({
  selector: 'nexnox-web-ticket-settings-escalation-levels-escalation-level-edit',
  templateUrl: './escalation-level-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TechPortalFeatureTicketSettingsEscalationLevelEditComponent extends CorePortalEntityEditBaseComponent<EscalationLevelDto> {
  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }

  protected createForm(): FormlyFieldConfig[] {
    return [
      {
        key: 'name',
        type: 'input',
        wrappers: ['core-portal-translated'],
        className: 'col-md-12',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.name',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          type: 'text'
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.disabled': () => this.readonly
        }
      }
    ];
  }
}
