import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, Type, ViewChild} from '@angular/core';
import {BehaviorSubject, merge, Observable, Subscription} from 'rxjs';
import {cloneDeep, isEmpty, isEqual, isNull, isUndefined} from 'lodash';
import {CorePortalEntitySelectStore} from './entity-select.store';
import {NgSelectComponent} from '@ng-select/ng-select';
import {debounceTime, distinctUntilChanged, filter, map, skip, take, withLatestFrom} from 'rxjs/operators';
import {AddTagFn} from '@ng-select/ng-select/lib/ng-select.component';
import {faExternalLinkAlt} from '@fortawesome/free-solid-svg-icons/faExternalLinkAlt';
import {faSearch} from '@fortawesome/free-solid-svg-icons/faSearch';
import {
  ApiNotificationService,
  CombineOperator,
  CoreSharedApiBaseService,
  CoreSharedModalService,
  CrossCreationTypes,
  Filter,
  FilterDto,
  FilterOperators,
  FilterTypes,
  PageableRequest,
  Paging,
  UnsubscribeHelper
} from '@nexnox-web/core-shared';
import {CorePortalEntitySelectDatatableSearchModalBaseComponent} from '../../../entity-select-datatable-search/modals';
import {faTimes} from '@fortawesome/free-solid-svg-icons/faTimes';
import {faPencilAlt} from '@fortawesome/free-solid-svg-icons/faPencilAlt';
import {CrossCreationSidebarComponent} from "../../sidebars";

export enum CorePortalEntitySelectLayout {
  DEFAULT = 0,
  TEXT = 1,
  HEADLINE = 2
}

export interface CorePortalEntitySelectOptions {
  /**
   * An entity id that needs to be excluded, because this is a select for a child of the same type for example.
   */
  entityId?: number;

  /**
   * The id property of the entity. Used to identify the entity.
   */
  idKey: string;

  /**
   * The display property of the entity. Used to display the entity.
   */
  displayKey: string;

  /**
   * Whether this entity select interacts with an object or just an id. Also outputs just an id on selection if not enabled.
   *
   * @default false
   */
  wholeObject?: boolean;

  /**
   * Other properties than the {@link displayKey} that are used to search.
   *
   * @default Empty
   */
  additionalSearchProperties?: string[];

  /**
   * A custom function to display an entity. Useful for showing multiple properties of an entity for example.
   *
   * @param value - The entity displayed
   */
  template?: (value: any) => string;

  /**
   * The entity service used to fetch entities.
   */
  entityService: CoreSharedApiBaseService;

  /**
   * Whether you can select multiple entities or not.
   *
   * @default false
   */
  multiple?: boolean;

  /**
   * Default filters.
   */
  defaultFilters$?: Observable<FilterDto[]>;

  /**
   * Refreshes the entity select whenever the provided observable emits.
   */
  refresh$?: Observable<void>;

  /**
   * Whether to leave the current fetched page and leave the current selection intact (Even if invalid) or not. Useful for cases where
   * the selected entity data needs to be preserved even if deleted for example.
   *
   * @todo The usage of this needs to be refactored, because the only current use of this flag actually has a bug in it, so that's not
   * great
   *
   * @default false
   */
  onlyRefreshEntity?: boolean;

  /**
   * Custom search function used for highlighting.
   *
   * @param term - The search term
   * @param item - The compared item
   */
  search?: (term: string, item: any) => boolean;

  /**
   * Customise the default filter when searching.
   *
   * @param filter - The default filter
   */
  mapSearchFilter?: (filter: Filter) => Filter;

  /**
   * Custom compare function used for ng-select. Presumably to determine if items are the same and for trackBy.
   *
   * @param a - Item A
   * @param b - Item B
   */
  compareWith?: (a: any, b: any) => boolean;

  /**
   * Whether to show the clear button or not.
   *
   * @default true
   */
  clearable?: boolean;

  /**
   * IDs to exclude from every page fetch.
   */
  excludedIds?: number[];

  /**
   * Whether to fetch the first page on creation and automatically select the first item or not.
   *
   * @default false
   */
  firstToDefault?: boolean;

  /**
   * Whether to skip fetching the first entity on creation or not. Very useful when used in combination with {@link wholeObject}, because
   * we don't need to fetch the first entity if it is already loaded.
   *
   * @default false
   */
  skipGetOne?: boolean;

  /**
   * Wait until the provided Observable emits. Will show a loading indicator.
   */
  waitUntil$?: Observable<void>;

  /**
   * Will not initialise until this observable emits and then initialise with the emitted config. Every emitted value with re-initialise
   * the entity select.
   */
  customInit$?: Observable<CorePortalEntitySelectOptions>;

  /**
   * If enabled, will try several hacks to really clear everything when {@link customInit$} emits.
   *
   * @default false
   */
  customInitExtraClear?: boolean;

  /**
   * Custom function to override the default get page behaviour.
   *
   * @param pageNumber - The requested page number
   * @param pageSize - The requested page size
   * @param filters - The requested filters
   */
  overrideGetPage?: (pageNumber: number, pageSize: number, filters: FilterDto[]) => Promise<PageableRequest<any>>;

  /**
   * The link to the selected entity shown next to the entity select. Uses {@link module} to determine the module and if not provided,
   * will use the current module.
   *
   * @param value - The selected entity
   */
  link?: (value: any) => string | any[];

  /**
   * The module used for {@link link}.
   */
  module?: string;

  /**
   * If provided, allows to search for entities in a datatable modal. The button is shown next to the entity select.
   */
  datatableSearch?: Type<CorePortalEntitySelectDatatableSearchModalBaseComponent<any>>;

  /**
   * Where to append the dropdown. In most cases it's needed to override, it will be 'body'.
   */
  appendTo?: string;

  /**
   * Whether to show all selected entities instead of hiding them if more than one is selected. Only works if {@link multiple} is enabled.
   *
   * @default false
   */
  showAll?: boolean;

  /**
   * Shows an edit button next to the entity select and disables it. If the button is pressed it enables the field again unless a custom
   * logic from {@link onEdit} is defined.
   *
   * @default false
   */
  editable?: boolean;

  /**
   * Custom logic when the edit button from {@link editable} is clicked.
   *
   * @param event - The {@link MouseEvent}
   * @param base - The base callback which enables the field again
   */
  onEdit?: (event: MouseEvent, base: () => void) => void;

  /**
   * If enabled, will hide the clear button and the id of the entity.
   *
   * @default false
   */
  minimal?: boolean;

  /**
   * Sets different looks / designs to the dropdown
   *
   *
   */
  layout?: CorePortalEntitySelectLayout;

  /**
   * Optional columns that need to be resolved.
   */
  optionalColumns?: string[];

  /**
   * Placeholder for the entity select.
   */
  placeholder?: string;

  /**
   * If defined with a cross creation type it will enable cross creation sidebar
   *
   * @default undefined
   */
  enableCrossCreation?: CrossCreationTypes;

  selectLabelTitleTemplate?: TemplateRef<any>;
  selectLabelTemplate?: TemplateRef<any>;
  selectOptionTitleTemplate?: TemplateRef<any>;
  selectOptionTemplate?: TemplateRef<any>;
}

@Component({
  selector: 'nexnox-web-entity-select',
  templateUrl: './entity-select.component.html',
  styleUrls: ['./entity-select.component.scss']
})
export class CorePortalEntitySelectComponent extends UnsubscribeHelper implements OnInit, OnDestroy {

  @ViewChild('crossCreationSidebar', { static: false }) public crossCreationSidebar: CrossCreationSidebarComponent;
  @Input() public showError: boolean;
  @Input() public disabled: boolean;
  @Input() public addTagFn: boolean | AddTagFn = undefined;
  @Output() public modelChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() public touched: EventEmitter<void> = new EventEmitter<void>();
  @Output() public dirty: EventEmitter<void> = new EventEmitter<void>();
  @Output() public loadedChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() public availableChange: EventEmitter<number> = new EventEmitter<number>();
  @ViewChild('selectComponent', { static: true }) public selectComponent: NgSelectComponent;
  public model$: Observable<any>;
  public items$: Observable<any[]>;
  public paging$: Observable<Paging>;
  public loading$: Observable<boolean>;
  public initializing$: Observable<boolean>;
  public waiting$: Observable<boolean>;
  public hasError$: Observable<boolean>;
  public store: CorePortalEntitySelectStore;
  public initializingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public waitingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public editableSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public searchFn: any;
  public compareWithFn: any;
  public scrollFn: any;
  public faSearch = faSearch;
  public faExternalLinkAlt = faExternalLinkAlt;
  public faTimes = faTimes;
  public faPencilAlt = faPencilAlt;
  public layouts = CorePortalEntitySelectLayout;
  public firstOpen = true;
  private refreshSubscription: Subscription;
  private modelSetInterval: any;
  private optionsSubject: BehaviorSubject<CorePortalEntitySelectOptions> = new BehaviorSubject<CorePortalEntitySelectOptions>(null);
  private modelSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private filtersSubject: BehaviorSubject<Filter[]> = new BehaviorSubject<Filter[]>([]);
  private searchSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private defaultFilterSubject: BehaviorSubject<Filter[]> = new BehaviorSubject<Filter[]>([]);
  private customInit$: Observable<CorePortalEntitySelectOptions>;

  constructor(
    private apiNotificationService: ApiNotificationService,
    private modalService: CoreSharedModalService
  ) {
    super();

    /**
     * Default search function that highlights the {@link options.displayKey} and {@link options.additionalSearchProperties} if configured.
     *
     * @param term - The search term
     * @param item - The compared item
     */
    this.searchFn = (term: string, item: any): boolean => {
      if (item[this.options.displayKey]?.toLowerCase()?.includes(term.toLowerCase())) return true;

      if (this.options.additionalSearchProperties?.length) {
        for (const searchProp of this.options.additionalSearchProperties) {
          if (item[searchProp]?.toLowerCase()?.includes(term.toLowerCase())) {
            return true;
          }
        }
      }

      return false;
    };
    this.compareWithFn = (a: any, b: any) => isEqual(a, b) || isEqual(a[this.options.idKey], b[this.options.idKey]);
    this.scrollFn = (event: Event) => this.onScroll(event);
  }

  public get model(): any {
    return this.modelSubject.getValue();
  }

  @Input()
  public set model(model: any) {
    this.modelSubject.next(model);
  }

  public get options(): CorePortalEntitySelectOptions {
    return this.optionsSubject.getValue();
  }

  @Input()
  public set options(options: CorePortalEntitySelectOptions) {
    const lastOptions = this.options;
    this.optionsSubject.next(options);

    if (Boolean(lastOptions) && !isEqual(lastOptions, options) && !this.waitingSubject.getValue()) {
      this.ngOnDestroy();

      this.init();

      this.subscribeToRefresh();
      this.subscribeToFilters();
    }
  }

  public ngOnInit(): void {
    if (this.options?.layout === CorePortalEntitySelectLayout.TEXT ||
      this.options?.layout === CorePortalEntitySelectLayout.HEADLINE) {
      this.options.minimal = true;
    }

    if (this.options?.enableCrossCreation) {
      this.addTagFn = (displayTitle) => this.openCrossCreationSidebar(displayTitle);
    }

    if (this.options?.customInit$) {
      this.customInit$ = this.options.customInit$;
      this.subscribeToCustomInit(true);
    } else {
      this.init();

      this.subscribeToRefresh();
      this.subscribeToFilters();
    }

    window.addEventListener('scroll', this.scrollFn, true);
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();

    this.refreshSubscription?.unsubscribe();

    if (this.modelSetInterval) {
      clearInterval(this.modelSetInterval);
    }

    window.removeEventListener('scroll', this.scrollFn, true);
  }

  /**
   * Emits the changed model and marks the entity select as dirty.
   *
   * @param value - The changed model
   */
  public onModelChange(value: any): void {
    this.dirty.emit();
    this.modelChange.emit(value);
  }

  /**
   * Fetches the first/initial page depending on configuration and marks the entity select as touched. Is only executed on first open
   * after initialisation or refresh.
   */
  public onOpen(): void {
    this.touched.emit();

    if (this.firstOpen) {
      if (
        this.options.multiple ? isEmpty(this.model) :
          (isNull(this.model) || (this.options.wholeObject ? !this.model[this.options.idKey] : false))
      ) {
        this.store.getFirstPage(this.filtersSubject.getValue(), this.getExcludedIds(), this.options.optionalColumns);
      } else {
        this.store.getInitialPage(
          this.options.wholeObject ? this.model[this.options.idKey] : this.model,
          this.filtersSubject.getValue(),
          this.getExcludedIds(),
          this.options.skipGetOne || this.options.multiple ? false : undefined,
          this.options.wholeObject ? (this.options.multiple ? [] : [this.model]) : undefined,
          this.options.wholeObject ? (this.options.multiple ? this.model : []) : undefined,
          undefined,
          this.options.optionalColumns
        );
      }

      this.firstOpen = false;
    }
  }

  /**
   * Clears the current search if a search exists.
   */
  public onClose(): void {
    if (!isEmpty(this.searchSubject.getValue())) {
      this.onClear();
    }
  }

  /**
   * Emits a search.
   *
   * @param value - The search term
   */
  public onSearch(value: string): void {
    this.searchSubject.next(value);
  }

  /**
   * Fetches the next page if there is a next page and not already waiting for a next page fetch.
   */
  public onScrollToEnd(): void {
    if (this.store.hasNextPage() && this.store.getLastAction() !== 'GET_NEXT_PAGE') {
      this.store.untilNotLoading().then(() =>
        this.store.getNextPage(this.filtersSubject.getValue(), this.getExcludedIds(), this.options.optionalColumns));
    }
  }

  /**
   * Sets the entity to the selected entity from the datatable search modal.
   */
  public onDatatableSearch(): void {
    this.modalService.showModal(this.options.datatableSearch, /* istanbul ignore next */ instance => {
      instance.defaultFilters = this.filtersSubject.getValue();
    })
      .then(item => {
        const value = item.value;

        if (value && value[this.options.idKey]) {
          this.onModelChange(this.options.wholeObject ? value : value[this.options.idKey]);
        }
      })
      .catch(() => null);
  }

  /**
   * Marks the field as editable unless otherwise configured via {@link options.onEdit} function.
   *
   * @param event - The {@link MouseEvent}
   */
  public onEdit(event: MouseEvent): void {
    const base = (): void => this.editableSubject.next(true);

    if (this.options?.onEdit) {
      this.options.onEdit(event, base);
    } else {
      base();
    }
  }

  /**
   * Clears the current search.
   */
  public onClear(): void {
    this.searchSubject.next(null);
  }

  /**
   * Checks if the entity select is clearable.
   *
   * @return true if not configured in {@link options.clearable}
   */
  public isClearable(): boolean {
    return !isUndefined(this.options?.clearable) ? this.options.clearable : true;
  }

  /**
   * Initialises the entity select and store.
   */
  private init(): void {
    this.initializingSubject = new BehaviorSubject<boolean>(true);
    this.waitingSubject = new BehaviorSubject<boolean>(false);
    this.firstOpen = true;
    this.filtersSubject = new BehaviorSubject<FilterDto[]>([]);
    this.searchSubject = new BehaviorSubject<string>(null);

    this.store = new CorePortalEntitySelectStore(
      this.options.entityService,
      this.apiNotificationService,
      this.options.idKey,
      this.options.overrideGetPage
    );

    this.items$ = this.store.selectItems(() => this.options.excludedIds);

    this.model$ = merge(
      this.items$,
      this.modelSubject.asObservable().pipe(
        withLatestFrom(this.items$),
        map(([_, items]) => items)
      )
    ).pipe(
      map(items => {
        if (this.options.multiple) {
          return (this.model ?? [])?.map(x => items.find(y => isEqual(
            y[this.options.idKey],
            this.options.wholeObject ? (x ? x[this.options.idKey] : x) : x
          )) ?? x);
        }

        return items.find(x => isEqual(
          x[this.options.idKey],
          this.options.wholeObject ? (this.model ? this.model[this.options.idKey] : this.model) : this.model
        )) ?? this.model;
      })
    );
    this.paging$ = this.store.selectPaging();
    this.loading$ = this.store.selectLoading();
    this.initializing$ = this.initializingSubject.asObservable();
    this.waiting$ = this.waitingSubject.asObservable();
    this.hasError$ = this.store.selectError();

    this.subscribe(this.model$.pipe(
      debounceTime(400)
    ), model => this.initializingSubject.next(isUndefined(model)));
    this.subscribe(this.store.selectLoaded(), loaded => this.loadedChange.emit(loaded));
    this.subscribe(this.items$, items => this.availableChange.emit((items ?? []).length));

    if (this.options.defaultFilters$) {
      this.subscribe(this.options.defaultFilters$, defaultFilters => {
        this.filtersSubject.next(defaultFilters ?? []);
        this.defaultFilterSubject.next(defaultFilters ?? []);
      });
    }

    this.initModelSet();
  }

  /**
   * Initialises the model setter interval.
   */
  private initModelSet(): void {
    if (this.modelSetInterval) {
      clearInterval(this.modelSetInterval);
    }

    this.initializingSubject.next(true);

    const modelInterval = (): void => {
      this.modelSetInterval = setInterval(() => {
        if (!isUndefined(this.model)) {
          if (this.options.multiple ? !isEmpty(this.model) : !isNull(this.model)) {
            if (this.options.wholeObject ? this.model[this.options.idKey] : true) {
              this.store.getInitialPage(
                this.options.wholeObject ? this.model[this.options.idKey] : this.model,
                this.filtersSubject.getValue(),
                this.getExcludedIds(),
                this.options.skipGetOne ? false : undefined,
                this.options.skipGetOne && this.options.wholeObject ? [this.model] : undefined,
                [],
                false,
                this.options.optionalColumns
              );
            }
          } else if (this.options.firstToDefault) {
            this.store.getFirstPageWithDefault(this.filtersSubject.getValue(), this.getExcludedIds(), first => {
              const newModel = isNull(this.model) ? this.options.wholeObject ? first : first[this.options.idKey] : this.model;
              this.modelSubject.next(newModel);
              this.onModelChange(newModel);
            }, this.options.optionalColumns);
            this.firstOpen = false;
          }

          this.waitingSubject.next(false);
          clearInterval(this.modelSetInterval);
        }
      }, 500);
    };

    if (this.options.waitUntil$) {
      this.waitingSubject.next(true);
      this.subscribe(this.options.waitUntil$.pipe(
        take(1)
      ), () => modelInterval());
    } else {
      modelInterval();
    }
  }

  private subscribeToRefresh(): void {
    this.refreshSubscription = this.options.refresh$?.subscribe(() => this.refreshSelect());
  }

  private refreshSelect(): void {
    this.firstOpen = true;

    if (!this.options.onlyRefreshEntity) {
      this.store.clear();
      this.selectComponent?.clearModel();
    }

    this.initModelSet();

    if (isNull(this.model)) {
      this.initializingSubject.next(false);
    }
  }

  private subscribeToFilters(): void {
    this.subscribe(this.initializingSubject.asObservable().pipe(
      filter(initializing => !initializing),
      take(1)
    ), () => {
      this.subscribe(merge(
        this.filtersSubject.asObservable().pipe(
          distinctUntilChanged((a, b) => isEqual(a, b))
        ),
        this.searchSubject.asObservable().pipe(
          skip(1),
          debounceTime(400)
        ),
        this.defaultFilterSubject.asObservable().pipe(
          distinctUntilChanged((a, b) => isEqual(a, b))
        )
      ).pipe(
        map((value) => {
          this.options.defaultFilters$
            ? this.defaultFilterSubject.next(this.defaultFilterSubject.getValue() ?? [])
            : this.defaultFilterSubject.next([]);
          let newFilters: Filter[] = this.defaultFilterSubject.getValue();
          if (typeof value === 'string') {
            if (value !== '') {
              newFilters = [
                ...newFilters,
                (this.options.mapSearchFilter ? this.options.mapSearchFilter : (x => x))((this.options.additionalSearchProperties ? {
                  type: FilterTypes.Grouped,
                  combinedAs: CombineOperator.Or,
                  children: [this.options.displayKey, ...this.options.additionalSearchProperties].map(prop => ({
                    type: FilterTypes.DataTransferObject,
                    property: prop,
                    operator: FilterOperators.Contains,
                    value
                  }))
                } : {
                  property: this.options.displayKey,
                  operator: FilterOperators.Contains,
                  type: FilterTypes.DataTransferObject,
                  value
                }))
              ]
            }
          } else if (value) {
            newFilters = value;
          }
          this.filtersSubject.next(newFilters);
          return newFilters;
        }),
        distinctUntilChanged((a, b) => isEqual(a, b)),
        filter(() => !this.firstOpen),
        filter(() => !this.disabled)
      ), filters => {
        this.store.getFirstPage(filters, this.getExcludedIds(), this.options.optionalColumns)
      });
    });
  }

  /* istanbul ignore next */
  private subscribeToCustomInit(first: boolean = false): void {
    this.subscribe(this.customInit$.pipe(
      skip(first || this.options?.customInitExtraClear ? 0 : 1),
      take(1)
    ), options => {
      this.ngOnDestroy();

      if (this.options?.customInitExtraClear) {
        this.model = null;
      }

      setTimeout(() => this.subscribeToCustomInit());

      if (options) {
        this.options = options;
      }

      this.init();

      this.subscribeToRefresh();
      this.subscribeToFilters();
    });
  }

  /* istanbul ignore next */
  private onScroll(event: Event): void {
    if (this.selectComponent?.isOpen) {
      const className = ((event.target as any).className as string);

      if (!(
        className.includes('ng-dropdown-panel-items') ||
        className.includes('resize-sensor-shrink') ||
        className.includes('resize-sensor-expand')
      )) {
        this.selectComponent.close();
      }
    }
  }

  private openCrossCreationSidebar(displayTitle: string): void {
    // Configure sidebar
    this.crossCreationSidebar.crossCreationType = this.options.enableCrossCreation;
    this.crossCreationSidebar.service = this.options.entityService;
    this.crossCreationSidebar.displayTitle = displayTitle;

    // On sidebar close the list gets refreshed and the new entity becomes new model
    this.subscribe(this.crossCreationSidebar.refreshAfterCreate.asObservable().pipe(take(1)), (newModel) => {
      // Handle options
      const previousModel = cloneDeep(this.model);
      newModel = this.options.wholeObject ? newModel : newModel[this.options.idKey];
      newModel = this.options.multiple ? [...(previousModel ?? []), newModel] : newModel;
      // Refresh / re-init select
      this.refreshSelect();
      // Set new model
      setTimeout(() => this.onModelChange(newModel));
    });

    // Open sidebar
    setTimeout(() => this.crossCreationSidebar.onShow());
  }

  private getExcludedIds(): number[] {
    return (this.options.entityId ? [this.options.entityId] : []).concat(...(this.options?.excludedIds ?? []));
  }
}
