import {NgModule} from '@angular/core';
import {escalationLevelsServices} from './store';
import {CoreSharedModule} from '@nexnox-web/core-shared';
import {CorePortalEntityModule} from '@nexnox-web/core-portal';
import {TechPortalFeatureTicketSettingsEscalationLevelEditComponent} from './components';
import {EscalationLevelDetailComponent, EscalationLevelListComponent} from './containers';

@NgModule({
  declarations: [
    EscalationLevelListComponent,
    EscalationLevelDetailComponent,

    TechPortalFeatureTicketSettingsEscalationLevelEditComponent
  ],
  imports: [
    CoreSharedModule,
    CorePortalEntityModule
  ],
  exports: [
    TechPortalFeatureTicketSettingsEscalationLevelEditComponent
  ],
  providers: [
    ...escalationLevelsServices
  ]
})
export class EscalationLevelsDeclarationsModule {
}
