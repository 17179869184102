import {Inject, Injectable, Optional} from '@angular/core';
import {HttpHandler, HttpInterceptor} from '@angular/common/http';
import {HttpBaseHandler} from './http-base.handler';
import {CORE_SHARED_HTTP_AUTHORIZED_INTERCEPTORS} from '../../../tokens';

@Injectable()
export class HttpAuthorizedHandler extends HttpBaseHandler {
  constructor(
    protected next: HttpHandler,
    @Optional() @Inject(CORE_SHARED_HTTP_AUTHORIZED_INTERCEPTORS) private httpAuthorizedInterceptors: HttpInterceptor[]
  ) {
    super(next, httpAuthorizedInterceptors ? httpAuthorizedInterceptors : []);
  }
}
