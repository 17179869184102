import {ChangeDetectionStrategy, Component, Injector, Input, OnInit, ViewChild} from '@angular/core';
import {
  CorePortalCardboxAction,
  CorePortalEntityOverviewBaseComponent,
  DatatableActionButton
} from '@nexnox-web/core-portal';
import {
  AppEntityType,
  AppPermissions,
  ControllerOperationId,
  FilterOperators,
  FilterTypes,
  Mappers,
  ResourceDto
} from '@nexnox-web/core-shared';
import {locationResourceListStore} from '../../store';
import {CreateResourcesByDefinitionSidebarComponent} from "./../../sidebars";
import {faPlus} from "@fortawesome/free-solid-svg-icons/faPlus";
import {Observable} from "rxjs";

@Component({
  selector: 'nexnox-web-master-data-locations-location-resource-list',
  templateUrl: './location-resource-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LocationResourceListComponent extends CorePortalEntityOverviewBaseComponent<ResourceDto> implements OnInit {

  @ViewChild('definitionSidebar', {static: true}) public definitionSidebar: CreateResourcesByDefinitionSidebarComponent;

  @Input() public locationStereotypeId$: Observable<number>;

  public title = 'resources.subtitles.resource-list';
  public idProperty = 'resourceId';
  public displayProperty = 'name';
  public pageOperation = ControllerOperationId.ResourceControllerList;
  public datatableConfigName = 'ResourceDefault';
  public componentId = 'LocationResourceListComponent';
  public canDelete = true;

  constructor(
    protected injector: Injector
  ) {
    super(injector, locationResourceListStore, Mappers.ResourceListDto.serializedName, AppEntityType.Resource);
  }

  public ngOnInit(): void {
    super.ngOnInit();

    this.defaultFilter = [{
      property: 'isArchived',
      operator: FilterOperators.Equal,
      type: FilterTypes.DataTransferObject,
      value: 'false'
    }];
  }

  public getCardboxHeaderActions(): CorePortalCardboxAction[] {
    return [
      {
        label: 'core-shared.shared.skeletons.apply-skeleton',
        class: 'p-button p-button-primary',
        icon: faPlus,
        tooltip: 'core-shared.shared.skeletons.apply-skeleton',
        buttonSize: 'lg',

        permission: AppPermissions.CreateResourcesByLocationDefinition,
        callback: () => this.definitionSidebar.onShow()
      },
    ]
  }

  public getRowActionButtons(): DatatableActionButton[] {
    return this.getDefaultRowActionButtons(
      'resources.actions.edit-resource',
      resource => `resources/${resource.resourceId}`,
      [AppPermissions.UpdateResource],
      {
        module: 'inventory'
      }
    );
  }

  public onRefresh(): void {
    this.store.dispatch(locationResourceListStore.actions.getPage(undefined, undefined, undefined, {
      parentIds: this.parentIds,
      filterOperation: undefined
    }));
  }
}
