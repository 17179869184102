import {ChangeDetectionStrategy, Component, Injector} from '@angular/core';
import {ActionButton, CorePortalEntityDetailBaseComponent} from '@nexnox-web/core-portal';
import {AppPermissions, TicketByProcessDefinitionDto} from '@nexnox-web/core-shared';
import {processDetailStore} from '../../store';

@Component({
  selector: 'nexnox-web-ticket-settings-processes-process-detail',
  templateUrl: './process-detail.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProcessDetailComponent extends CorePortalEntityDetailBaseComponent<TicketByProcessDefinitionDto> {
  public title = 'core-portal.settings.subtitles.ticket-settings.process-detail';

  protected idParam = 'ticketByProcessDefinitionId';
  protected displayKey = 'name';

  constructor(
    protected injector: Injector
  ) {
    super(injector, processDetailStore);
  }

  protected async getActionButtons(): Promise<ActionButton[]> {
    return this.getDefaultActionButtons(
      'core-portal.settings.actions.ticket-settings.edit-process',
      'core-portal.settings.actions.ticket-settings.save-process',
      'core-portal.settings.actions.ticket-settings.delete-process',
      'core-portal.settings.descriptions.ticket-settings.delete-process',
      AppPermissions.UpdateTicketByProcessDefinition,
      AppPermissions.DeleteTicketByProcessDefinition,
      ['processes']
    );
  }
}
