import {processDetailStore, ProcessDetailStoreState, processListStore, ProcessListStoreState} from './stores';

export interface ProcessesState {
  processList: ProcessListStoreState;
  processDetail: ProcessDetailStoreState;
}

export const getInitialProcessesState = (): ProcessesState => ({
  processList: processListStore.getInitialState(),
  processDetail: processDetailStore.getInitialState()
});
