import {ChangeDetectionStrategy, Component, Injector, OnInit, ViewChild} from '@angular/core';
import {
  authStore,
  CorePortalAttachmentsComponent,
  CorePortalEntityEditBaseComponent,
  CorePortalFormlyMultiToggleTyping,
  CorePortalFormlyReadonlyTypes,
  CorePortalFormlyReadonlyTyping
} from '@nexnox-web/core-portal';
import {
  AddressDto,
  AddressSimpleDto,
  AttachmentForTechDto,
  CrossCreationTypes,
  FilterDto,
  HolidaySetDto,
  LocationDto
} from '@nexnox-web/core-shared';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {Validators} from '@angular/forms';
import {isEmpty, isNull, isString} from 'lodash';
import {select, Store} from '@ngrx/store';
import {filter, map, mergeMap, take} from 'rxjs/operators';
import {WeekDay} from '@angular/common';
import {CorePortalFeatureMasterDataHolidaySetService} from '@nexnox-web/core-portal/features/master-data/features/holidays';
import {LocationAddressService} from '../../store';
import {BehaviorSubject} from 'rxjs';
import {locationMailSettingsEnumOptions, locationStateEnumOptions} from "../../models";

@Component({
  selector: 'nexnox-web-master-data-locations-location-edit',
  templateUrl: './core-portal-feature-location-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CorePortalFeatureLocationEditComponent extends CorePortalEntityEditBaseComponent<LocationDto> implements OnInit {

  @ViewChild('attachmentsComponent') public attachmentsComponent: CorePortalAttachmentsComponent;

  public attachmentsSubject: BehaviorSubject<AttachmentForTechDto[]> = new BehaviorSubject<AttachmentForTechDto[]>([]);

  constructor(
    protected injector: Injector,
    protected store: Store<any>,
    private holidaySetService: CorePortalFeatureMasterDataHolidaySetService,
    private locationAddressService: LocationAddressService
  ) {
    super(injector);
  }

  public onAttachmentsChange(attachments: AttachmentForTechDto[]): void {
    this.setModel({ ...this.model, attachments });
    setTimeout(() => this.onModelChange(this.model));
  }

  protected setModel(model: LocationDto): void {
    super.setModel(model);

    this.attachmentsSubject.next(model?.attachments ?? []);
  }

  /* istanbul ignore next */
  protected createForm(): FormlyFieldConfig[] {
    const addressTemplate = (address: AddressSimpleDto): string => {
      let firstPart = address.street;
      let secondPart = address.city;

      if (address.houseNumber) {
        firstPart = `${ firstPart } ${ address.houseNumber }`;
      }

      if (address.zipcode) {
        secondPart = `${ address.zipcode } ${ secondPart }`;
      }

      return `${ firstPart }${ secondPart ? `, ${ secondPart }` : '' }`;
    };

    return [
      {
        key: 'name',
        type: 'input',
        wrappers: ['core-portal-translated'],
        className: 'col-md-12',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.name',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          type: 'text'
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.disabled': () => this.readonly
        },
        hideExpression: () => !this.creating
      },
      {
        key: 'emailAddress',
        type: 'core-portal-input-group-input',
        wrappers: ['core-portal-translated'],
        className: 'col-md-6',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.email-address',
            validationMessages: {
              email: 'core-portal.core.validation.email',
            }
          },
          corePortalInputGroupInput: {
            append: this.modelSubject.asObservable().pipe(
              filter(model => Boolean(model)),
              mergeMap(model => this.store.pipe(
                select(
                  model.tenantId ? authStore.selectors.selectTenant : authStore.selectors.selectActiveTenant,
                  model.tenantId ? { tenantId: model.tenantId } : undefined
                ),
                filter(tenant => Boolean(tenant)),
                map(tenant => [`@${ tenant.domain }`])
              ))
            ),
            withTransform: true
          },
          type: 'email'
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly
        },
        validators: {
          email: ctrl => !isString(ctrl.value) || !Validators.email(ctrl)
        }
      },
      {
        key: 'locationNumber',
        type: 'input',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.location-number',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.BASIC
          } as CorePortalFormlyReadonlyTyping,
          type: 'text'
        },
        expressionProperties: {
          'templateOptions.readonly': () => this.readonly,
          'templateOptions.disabled': () => this.readonly
        }
      },
      {
        key: 'phoneNumber',
        type: 'input',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.phone',
            validationMessages: {
              phone: 'core-portal.core.validation.phone'
            }
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.PHONE
          } as CorePortalFormlyReadonlyTyping,
          type: 'tel'
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        },
        validators: {
          phone: ctrl => !isString(ctrl.value) || !Validators.pattern(/^[+]*[(]?[0-9]{1,4}[)]?[-\s./0-9]*$/)(ctrl)
        },
        parsers: [
          (value: string) => !isNull(value) && isEmpty(value) ? null : value
        ]
      },
      {
        key: 'externalId',
        type: 'core-portal-external-id',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.external-id',
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.BASIC
          } as CorePortalFormlyReadonlyTyping,
          titleKey: 'name',
          modelSubject: this.modelSubject
        },
        expressionProperties: {
          'templateOptions.readonly': () => this.readonly,
          'templateOptions.disabled': () => this.readonly
        }
      },
      {
        key: 'info',
        type: 'input',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-12',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.info'
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.BASIC
          } as CorePortalFormlyReadonlyTyping,
          type: 'text'
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        }
      },
      {
        key: 'state',
        type: 'core-portal-ng-select',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-12',
        defaultValue: locationStateEnumOptions[0].value,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.current-state',
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.ENUM,
            enumOptions: locationStateEnumOptions,
            translate: true
          } as CorePortalFormlyReadonlyTyping,
          corePortalNgSelect: {
            items: locationStateEnumOptions,
            translate: true
          }
        },
        expressionProperties: {
          'templateOptions.readonly': () => true
        },
        hideExpression: () => this.creating
      },
      {
        type: 'core-portal-divider',
        className: 'col-md-12',
      },
      /* Settings */
      {
        wrappers: ['core-portal-translated'],
        className: 'col-md-12 font-weight-bold',
        fieldGroupClassName: 'row font-weight-normal field-group-mb-0',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-portal.master-data.location.fields.settings'
          }
        },
        fieldGroup: [
          {
            key: 'invoiceAddress',
            type: 'core-portal-entity-select',
            wrappers: ['core-portal-translated', 'core-portal-readonly'],
            className: 'col-md-6',
            defaultValue: null,
            templateOptions: {
              corePortalTranslated: {
                label: 'core-shared.shared.fields.invoice-address',
                validationMessages: {
                  required: 'core-portal.core.validation.required'
                }
              },
              corePortalReadonly: {
                type: CorePortalFormlyReadonlyTypes.ENTITY,
                displayKey: 'street',
                template: addressTemplate,
                link: (address: AddressDto) => address?.addressId ? ['/masterdata', 'addresses', address.addressId] : null,
                module: 'management'
              } as CorePortalFormlyReadonlyTyping,
              entityService: this.locationAddressService,
              idKey: 'addressId',
              displayKey: 'street',
              additionalSearchProperties: ['houseNumber', 'zipcode', 'city'],
              template: addressTemplate,
              wholeObject: true,
              skipGetOne: true,
              overrideGetPage: (pageNumber: number, pageSize: number, filters: FilterDto[]) => {
                const locationId = this.model.locationId;
                return this.locationAddressService.getPage(
                  undefined,
                  pageNumber,
                  filters,
                  undefined,
                  undefined,
                  undefined,
                  pageSize,
                  [locationId]
                ).pipe(take(1)).toPromise();
              },
              link: (address: AddressDto) => address?.addressId ? ['/masterdata', 'addresses', address.addressId] : null,
              module: 'management'
            },
            expressionProperties: {
              'templateOptions.disabled': () => this.readonly,
              'templateOptions.readonly': () => this.readonly
            },
            hideExpression: () => this.creating
          },
          {
            key: 'shippingAddress',
            type: 'core-portal-entity-select',
            wrappers: ['core-portal-translated', 'core-portal-readonly'],
            className: 'col-md-6',
            defaultValue: null,
            templateOptions: {
              corePortalTranslated: {
                label: 'core-shared.shared.fields.shipping-address',
                validationMessages: {
                  required: 'core-portal.core.validation.required'
                }
              },
              corePortalReadonly: {
                type: CorePortalFormlyReadonlyTypes.ENTITY,
                displayKey: 'street',
                template: addressTemplate,
                link: (address: AddressDto) => address?.addressId ? ['/masterdata', 'addresses', address.addressId] : null,
                module: 'management'
              } as CorePortalFormlyReadonlyTyping,
              entityService: this.locationAddressService,
              idKey: 'addressId',
              displayKey: 'street',
              additionalSearchProperties: ['houseNumber', 'zipcode', 'city'],
              template: addressTemplate,
              wholeObject: true,
              skipGetOne: true,
              overrideGetPage: (pageNumber: number, pageSize: number, filters: FilterDto[]) => {
                const locationId = this.model.locationId;
                return this.locationAddressService.getPage(
                  undefined,
                  pageNumber,
                  filters,
                  undefined,
                  undefined,
                  undefined,
                  pageSize,
                  [locationId]
                ).pipe(take(1)).toPromise();
              },
              link: (address: AddressDto) => address?.addressId ? ['/masterdata', 'addresses', address.addressId] : null,
              module: 'management'
            },
            expressionProperties: {
              'templateOptions.disabled': () => this.readonly,
              'templateOptions.readonly': () => this.readonly
            },
            hideExpression: () => this.creating
          },
          { key: 'holidaySetId' },
          {
            key: 'holidaySet',
            type: 'core-portal-entity-select',
            wrappers: ['core-portal-translated', 'core-portal-readonly'],
            className: 'col-md-6',
            defaultValue: null,
            templateOptions: {
              corePortalTranslated: {
                label: 'core-shared.shared.fields.holidays'
              },
              corePortalReadonly: {
                type: CorePortalFormlyReadonlyTypes.ENTITY,
                displayKey: 'name',
                link: (holidaySet: HolidaySetDto) => holidaySet?.holidaySetId ? ['/masterdata', 'holidays', holidaySet.holidaySetId] : null,
                module: 'management'
              } as CorePortalFormlyReadonlyTyping,
              entityService: this.holidaySetService,
              idKey: 'holidaySetId',
              displayKey: 'name',
              wholeObject: true,
              skipGetOne: true,
              enableCrossCreation: CrossCreationTypes.HOLIDAYS,
              link: (holidaySet: HolidaySetDto) => holidaySet?.holidaySetId ? ['/masterdata', 'holidays', holidaySet.holidaySetId] : null,
              module: 'management'
            },
            expressionProperties: {
              'templateOptions.disabled': () => this.readonly,
              'templateOptions.readonly': () => this.readonly
            },
            hooks: {
              onInit: field => this.subscribe(field.formControl.valueChanges, (value: HolidaySetDto) => {
                this.form.controls.holidaySetId.setValue(value?.holidaySetId ?? null);
              })
            }
          },

          {
            key: 'isIncomingEmailEnabled',
            type: 'core-portal-ng-select',
            wrappers: ['core-portal-translated', 'core-portal-readonly'],
            className: 'col-md-6',
            defaultValue: locationMailSettingsEnumOptions[0].value,
            templateOptions: {
              corePortalTranslated: {
                label: 'core-portal.master-data.location.fields.mail-reception',
              },
              corePortalReadonly: {
                type: CorePortalFormlyReadonlyTypes.ENUM,
                enumOptions: locationMailSettingsEnumOptions,
                translate: true
              } as CorePortalFormlyReadonlyTyping,
              corePortalNgSelect: {
                items: locationMailSettingsEnumOptions,
                translate: true
              }
            },
            expressionProperties: {
              'templateOptions.readonly': () => this.readonly,
              'templateOptions.disabled': () => this.readonly
            }
          },
          {
            key: 'openDays',
            type: 'core-portal-multi-toggle',
            wrappers: ['core-portal-translated'],
            className: 'col-md-12',
            templateOptions: {
              corePortalTranslated: {
                label: 'core-shared.shared.fields.open-days'
              },
              corePortalMultiToggle: {
                items: [
                  { label: 'core-shared.shared.week-days.monday', value: WeekDay.Monday },
                  { label: 'core-shared.shared.week-days.tuesday', value: WeekDay.Tuesday },
                  { label: 'core-shared.shared.week-days.wednesday', value: WeekDay.Wednesday },
                  { label: 'core-shared.shared.week-days.thursday', value: WeekDay.Thursday },
                  { label: 'core-shared.shared.week-days.friday', value: WeekDay.Friday },
                  { label: 'core-shared.shared.week-days.saturday', value: WeekDay.Saturday },
                  { label: 'core-shared.shared.week-days.sunday', value: WeekDay.Sunday }
                ],
                translate: true
              } as CorePortalFormlyMultiToggleTyping
            },
            expressionProperties: {
              'templateOptions.disabled': () => this.readonly
            }
          }
        ]
      },
      ...this.getStereotypeFields()
    ];
  }
}
