import {ChangeDetectionStrategy, Component, Injector, OnInit} from '@angular/core';
import {ActionButton, CorePortalEntityDetailBaseComponent} from '@nexnox-web/core-portal';
import {AppPermissions, FormDto, UuidService} from '@nexnox-web/core-shared';
import {EntityXsStoreSaveSuccessPayload} from '@nexnox-web/core-store';
import {formDetailStore} from '../../store';
import {faPlus} from '@fortawesome/free-solid-svg-icons/faPlus';
import {distinctUntilChanged, map, take} from 'rxjs/operators';
import {cloneDeep, isEmpty} from 'lodash';
import {lastValueFrom, Observable} from 'rxjs';
import {faExclamationTriangle} from '@fortawesome/free-solid-svg-icons/faExclamationTriangle';
import {faArrowRight} from '@fortawesome/free-solid-svg-icons/faArrowRight';
import {LocalFormFieldDto, LocalFormRowDto} from "@nexnox-web/orga-portal/features/forms/src/lib/models";

@Component({
  selector: 'nexnox-web-forms-form-detail',
  templateUrl: './form-detail.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormDetailComponent extends CorePortalEntityDetailBaseComponent<FormDto> implements OnInit {
  public title = 'orga-portal.forms.subtitles.form-detail';

  public isOld$: Observable<boolean>;

  public faExclamationTriangle = faExclamationTriangle;
  public faArrowRight = faArrowRight;

  protected idParam = 'formId';
  protected displayKey = 'title';

  constructor(
    protected injector: Injector,
    private uuidService: UuidService
  ) {
    super(injector, formDetailStore);
  }

  public ngOnInit(): void {
    super.ngOnInit();

    this.isOld$ = this.model$.pipe(
      distinctUntilChanged((a, b) => a?.formId === b?.formId && a?.activeFormId === b?.activeFormId),
      map(model => model?.formId < model?.activeFormId)
    );
  }

  /* istanbul ignore next */
  protected async getActionButtons(): Promise<ActionButton[]> {
    return [
      ...this.getDefaultActionButtons(
        'orga-portal.forms.actions.edit-form',
        'orga-portal.forms.actions.save-form',
        'orga-portal.forms.actions.delete-form',
        'orga-portal.forms.descriptions.delete-form',
        AppPermissions.UpdateForm,
        AppPermissions.DeleteForm,
        ['forms']
      ),
      {
        label: 'core-shared.shared.actions.duplicate',
        type: 'button',
        class: 'btn-outline-secondary',
        permission: AppPermissions.CreateForm,
        icon: faPlus,
        shouldShow: () => this.entity$.pipe(
          map(entity => Boolean(entity) && !isEmpty(entity))
        ),
        callback: async () => {
          const entity = await lastValueFrom(this.entity$.pipe(take(1)));

          if (entity) {
            this.entityCreatePresetService.setPreset('OrgaPortalFeatureFormEditComponent', {
              title: entity.title,
              description: entity.description,
              rows: (entity.rows ?? []).map(row => {
                const newRow = cloneDeep(row);

                delete newRow.formRowId;
                (newRow as LocalFormRowDto).localFormRowId = this.uuidService.generateUuid();

                for (const field of newRow.fields ?? []) {
                  delete field.formFieldId;
                  delete field.localFieldId;
                  (field as LocalFormFieldDto).localFormFieldId = this.uuidService.generateUuid();

                  for (const defaultValue of field.defaultValues ?? []) {
                    delete defaultValue.fieldValueId;
                  }
                }

                return newRow;
              })
            } as FormDto);
            await this.tenantRouter.navigate(['forms'], { fragment: 'create' });
          }
        }
      }
    ];
  }

  protected onSaveSuccess(payload: EntityXsStoreSaveSuccessPayload<FormDto, FormDto>): void {
    super.onSaveSuccess({ ...payload, doNotEmitEvents: true });

    this.tenantRouter.navigate(['forms', payload.entity.formId], { module: 'settings' });
  }
}
