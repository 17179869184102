import {SnapElement} from './snap-element.model';

export class SnapRect extends SnapElement {
  constructor(
    x: number,
    y: number,
    width: number,
    height: number,
    fill: string = 'black',
    stroke: string = 'transparent',
    strokeWidth?: number,
    parent: SnapElement = null
  ) {
    super(x, y, parent);

    this._width = width;
    this._height = height;
    this._fill = fill;
    this._stroke = stroke;
    this._strokeWidth = strokeWidth;
  }

  private _width: number;

  public get width(): number {
    return this._width;
  }

  public set width(width: number) {
    this._width = width;
    this.updateAttributes();
  }

  private _height: number;

  public get height(): number {
    return this._height;
  }

  public set height(height: number) {
    this._height = height;
    this.updateAttributes();
  }

  public updateAttributes(): void {
    super.updateAttributes();
    this.element.attr({
      width: this._width,
      height: this._height
    });
  }

  protected createElement(snapPaper: any): any {
    return snapPaper.rect(this.absoluteX, this.absoluteY, this._width, this._height);
  }
}
