import {Directive, ViewChild} from '@angular/core';
import {Sidebar} from 'primeng/sidebar';
import {BehaviorSubject, Observable} from 'rxjs';
import {UnsubscribeHelper} from '../../helper';

@Directive()
export abstract class CoreSharedSidebarBaseComponent extends UnsubscribeHelper {
  @ViewChild('sidebarComponent', { static: true }) public sidebarComponent: Sidebar;

  public visible$: Observable<boolean>;

  public transitionOptions = '300ms ease-in-out';

  private visibleSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  protected constructor() {
    super();

    this.visible$ = this.visibleSubject.asObservable();
  }

  public onVisibleChange(visible: boolean): void {
    if (!visible) {
      this.onHide();
    }
  }

  public onShow(...args: any[]): void {
    this.visibleSubject.next(true);
  }

  public onHide(): void {
    this.ngOnDestroy();

    this.visibleSubject.next(false);
    this.sidebarComponent.hide();
    // this.sidebarComponent.cd.detectChanges(); // PH: Commenting it out prevents a sidebar from re-initializing after hide
  }
}
