<ng-template #entityEditTemplate let-title="title">
  <nexnox-web-infos-info-edit #editComponent
                              (modelChange)="createModel$.next($event)"
                              [creating]="true"
                              [loading]="(loaded$ | async) === false"
                              [model]="createModel$ | async"
                              [readonly]="false"
                              [stereotyped]="false"
                              [title]="title">
  </nexnox-web-infos-info-edit>
</ng-template>

<nexnox-web-entity-overview #overviewComponent
                            [canExport]="true"
                            [entityEditTemplate]="entityEditTemplate"
                            [entityOverviewBaseComponent]="this"
                            [stereotyped]="false"
                            detailLink="/infos"
                            module="communication">
</nexnox-web-entity-overview>

<!-- Category Templates -->
<ng-template #categoryLabelTitleTemplate let-item="item">
  <nexnox-web-categories-category-label [category]="item"></nexnox-web-categories-category-label>
</ng-template>

<ng-template #categoryOptionTitleTemplate let-item="item" let-searchTerm="searchTerm">
  <nexnox-web-categories-category-label [category]="item"
                                        [searchTerm]="searchTerm"></nexnox-web-categories-category-label>
</ng-template>
