<div class="btn-group"
     container="body"
     dropdown
     placement="bottom right">
  <button [title]="'core-shared.shared.table.subtitles.datatable-views.title' | translate"
          class="p-button-text button- p-button-secondary p-ripple p-button-icon-only p-button-sm ml-2"
          dropdownToggle
          pButton
          type="button">
    <fa-icon [fixedWidth]="true"
             [icon]="faFilter">
    </fa-icon>
  </button>

  <ul *dropdownMenu
      class="dropdown-menu dropdown-menu-right bg-light-blue"
      role="menu">
    <ng-container *ngFor="let menu of (dropdownActions | async); let first=first">
      <hr *ngIf="menu?.menuTitle && !first" class="mt-0 mb-0">
      <li *ngIf="menu?.menuTitle && menu?.buttons?.length > 0"
          class="px-3 py-3 menu-title">
        {{ menu?.menuTitle | translate }}
      </li>

      <ng-container *ngFor="let button of menu?.buttons"
                    [ngTemplateOutletContext]="{ item: button, first: first }"
                    [ngTemplateOutlet]="dropdownItem">
      </ng-container>
    </ng-container>
  </ul>
</div>

<ng-template #dropdownItem let-first="first" let-item="item">
  <hr *ngIf="item?.useDivider && !first" class="mt-0 mb-0">
  <li *ngIf="item"
      [class.disabled]="false"
      [title]="item.title | translate"
      role="menuitem">
    <a (click)="item.action ? item.action() : null"
       [class.no-icon]="!item.icon"
       class="dropdown-item">
      <fa-icon *ngIf="item.icon as icon"
               [class.has-time-stamp]="item.hasTimeStamp"
               [icon]="icon">
      </fa-icon>
      <span>{{ item.title | translate }}</span>
    </a>
  </li>
</ng-template>
