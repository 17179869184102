<p-sidebar #sidebarComponent
           (visibleChange)="onVisibleChange($event)"
           [transitionOptions]="transitionOptions"
           [visible]="visible$ | async"
           appendTo="body"
           position="right"
           styleClass="p-sidebar-xl">

  <h4 class="mb-4">{{ 'resources.actions.select-resource' | translate }}</h4>

  <nexnox-web-resources-resource-datatable-search (selectionChange)="onSelectionChange($event)"
                                                  [location$]="locationSubject.asObservable()"
                                                  [selectionMode]="selectionModes.Single">
  </nexnox-web-resources-resource-datatable-search>

  <div class="w-100 d-flex align-items-center my-4 py-2">

    <div class="flex-grow-1 pr-4">
      <ng-container *ngIf="selectedResource">
        {{ 'core-portal.core.general.selection' | translate }}:
        <fa-icon [fixedWidth]="true"
                 [icon]="faFolder"
                 class="mr-1 ml-2"
                 size="sm"></fa-icon>
        <a [nexnoxWebTenantRouterLink]="['/resources', selectedResource?.resourceId]"
           class="btn-link"
           module="inventory"
           target="_blank">
          {{ selectedResource?.name }}
        </a>
      </ng-container>
    </div>

    <div>
      <button (click)="onAssignResource()"
              *ngIf="creating"
              [disabled]="!selectedResource"
              [icon]="faFolder | nexnoxWebFaIconStringPipe"
              [label]="'core-shared.shared.actions.assign-selection' | translate"
              class="p-button p-button-primary"
              pButton
              type="button">
      </button>

      <ng-container *ngIf="!creating">
        <button (click)="onUnassignResource()"
                [disabled]="!hasCurrentResource"
                [icon]="faTimes | nexnoxWebFaIconStringPipe"
                [label]="'core-shared.shared.actions.unassign-selection'  | translate"
                class="p-button p-button-secondary mr-3"
                pButton
                type="button">
        </button>

        <button (click)="onAssignResource()"
                [disabled]="!selectedResource"
                [icon]="faFolder | nexnoxWebFaIconStringPipe"
                [label]="'core-shared.shared.actions.assign-to-ticket' | translate"
                class="p-button p-button-secondary mr-3"
                pButton
                type="button">
        </button>

        <button (click)="onAssignResource(true)"
                [disabled]="!selectedResource"
                [icon]="faFolder | nexnoxWebFaIconStringPipe"
                [label]="'core-shared.shared.actions.assign-all' | translate"
                class="p-button p-button-primary"
                pButton
                type="button">
        </button>
      </ng-container>
    </div>
  </div>
</p-sidebar>
