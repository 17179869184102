import {InjectionToken, Injector, StaticProvider, Type} from '@angular/core';
import {AppEntityType, CoreSharedApiBaseService} from '@nexnox-web/core-shared';
import {CorePortalEntityActionsFacade, CorePortalEntityOverviewBaseComponent} from '../modules';

export interface CorePortalDashboardItemDatatableConfig {
  listComponent: Type<CorePortalEntityOverviewBaseComponent<any>>;
  listComponentProps?: Record<string, any>;
  actionsFacade?: Type<CorePortalEntityActionsFacade<any>>;
  datatableStore?: new (injector: Injector, config: CorePortalDashboardItemDatatableConfig) => any;
  serializedName: any;
  idKey: string;
  entityService: Type<CoreSharedApiBaseService>;
  entityType: AppEntityType;
  detailLink: string;
  module: string;
  providers?: StaticProvider[];
}

export interface CorePortalDashboardConfig {
  [pageOperation: string]: CorePortalDashboardItemDatatableConfig;
}

export const CORE_PORTAL_DASHBOARD_CONFIG = new InjectionToken<CorePortalDashboardConfig>(
  '@nexnox-web/core-portal: Dashboard Config'
);
