import {cloneDeep, isArray, isNull, isUndefined} from 'lodash';

type Sortable = any | any[];

export function sortDeep<T extends Sortable>(toSort: T, customizer?: (a: any, b: any) => number): T {
  const toSortClone = cloneDeep(toSort);

  if (isUndefined(toSortClone) || isNull(toSortClone)) {
    return toSortClone;
  }

  if (typeof toSortClone === 'object' && !isArray(toSortClone)) {
    return sortObjectDeep(toSortClone, customizer);
  } else if (isArray(toSortClone)) {
    return sortArrayDeep(toSortClone, customizer);
  } else {
    return toSortClone;
  }
}

function sortObjectDeep<T>(toSort: T, customizer?: (a: any, b: any) => number): T {
  const sorted: T = {} as T;
  for (const key of Object.keys(toSort).sort(customizer)) {
    sorted[key] = sortDeep(toSort[key], customizer);
  }

  return sorted;
}

function sortArrayDeep<T extends any[]>(toSort: T, customizer?: (a: any, b: any) => number): T {
  const sorted: T = [] as T;
  const preSorted: T = toSort.length && typeof toSort[0] === 'object' ? toSort.sort((a, b) => {
    return JSON.stringify(b).localeCompare(JSON.stringify(a));
  }) : toSort.sort();

  for (const sortedItem of preSorted.sort(customizer)) {
    sorted.push(sortDeep(sortedItem, customizer));
  }

  return sorted;
}
