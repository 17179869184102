import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {CorePortalPermissionGuard} from '@nexnox-web/core-portal';
import {AppPermissions, I18nBreadcrumbResolver, UnsavedChangesGuard, UnsavedCreateGuard} from '@nexnox-web/core-shared';
import {FormDetailComponent, FormListComponent} from './containers';

export const ORGA_PORTAL_FORMS_ROUTES: Routes = [
  {
    path: '',
    canActivate: [CorePortalPermissionGuard],
    canDeactivate: [UnsavedCreateGuard],
    component: FormListComponent,
    data: {
      permissions: [AppPermissions.DisplayForms]
    }
  },
  {
    path: ':formId',
    component: FormDetailComponent,
    canActivate: [CorePortalPermissionGuard],
    canDeactivate: [UnsavedChangesGuard],
    data: {
      permissions: [AppPermissions.ReadForm],
      breadcrumbs: I18nBreadcrumbResolver,
      tab: 'form',
      text: 'orga-portal.forms.subtitles.form-detail'
    }
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(ORGA_PORTAL_FORMS_ROUTES)
  ],
  exports: [
    RouterModule
  ]
})
export class FormsRoutingModule {

}
