import {ChangeDetectionStrategy, Component, Injector, Input} from '@angular/core';
import {
  CorePortalEntityEditBaseComponent,
  CorePortalFormlyNgSelectTyping,
  CorePortalFormlyReadonlyTypes,
  CorePortalFormlyReadonlyTyping,
  CorePortalFormlyTranslatedTyping,
  CorePortalStereotypeService
} from '@nexnox-web/core-portal';
import {
  AppEntityType,
  Filter,
  FilterOperators,
  FilterTypes,
  ResourceTicketSkeletonSelectorType,
  StateDto,
  StereotypeDto,
  TicketByProcessDefinitionDto
} from '@nexnox-web/core-shared';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {getFormlyPreSelectedField, resourceTicketSkeletonSelectorTypeEnumOptions} from '../../models';
import {TranslateService} from '@ngx-translate/core';
import {TechPortalFeatureTicketSettingsStateService} from '@nexnox-web/tech-portal/features/ticket-settings/features/states';
import {TechPortalFeatureTicketSettingsPriorityService} from '@nexnox-web/tech-portal/features/ticket-settings/features/priority-sets';
import {distinctUntilChanged, startWith} from 'rxjs/operators';
import {of} from "rxjs";
import {TechPortalFeatureVirtualResourceService} from "@nexnox-web/tech-portal/features/resources";

@Component({
  selector: 'nexnox-web-ticket-settings-processes-process-ticket-edit',
  templateUrl: './process-ticket-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProcessTicketEditComponent extends CorePortalEntityEditBaseComponent<TicketByProcessDefinitionDto> {

  @Input() public isLinkableDefinition: boolean;

  constructor(
    protected injector: Injector,
    private translate: TranslateService,
    private resourceService: TechPortalFeatureVirtualResourceService,
    private stateService: TechPortalFeatureTicketSettingsStateService,
    private priorityService: TechPortalFeatureTicketSettingsPriorityService,
    private stereotypeService: CorePortalStereotypeService
  ) {
    super(injector);
  }

  /* istanbul ignore next */
  protected createForm(): FormlyFieldConfig[] {
    return [
      // Title
      {
        wrappers: ['core-portal-translated'],
        className: 'col-md-12 font-weight-bold',
        fieldGroupClassName: 'row font-weight-normal',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.title',
            labelClass: 'd-block d-md-none'
          }
        },
        fieldGroup: getFormlyPreSelectedField('ticket.title', 'core-shared.shared.fields.title', {
          type: 'input',
          wrappers: ['core-portal-translated', 'core-portal-readonly'],
          templateOptions: {
            corePortalReadonly: {
              type: CorePortalFormlyReadonlyTypes.BASIC
            } as CorePortalFormlyReadonlyTyping,
            type: 'text'
          }
        }, () => this.readonly)
      },

      // Description
      {
        wrappers: ['core-portal-translated'],
        className: 'col-md-12 font-weight-bold',
        fieldGroupClassName: 'row font-weight-normal',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.description',
            labelClass: 'd-block d-md-none'
          }
        },
        fieldGroup: getFormlyPreSelectedField('ticket.description', 'core-shared.shared.fields.description', {
          type: 'textarea',
          wrappers: ['core-portal-translated', 'core-portal-readonly'],
          templateOptions: {
            corePortalReadonly: {
              type: CorePortalFormlyReadonlyTypes.BASIC
            } as CorePortalFormlyReadonlyTyping,
            rows: 3,
            type: 'text'
          }
        }, () => this.readonly, false)
      },

      // Stereotype
      {
        wrappers: ['core-portal-translated'],
        className: 'col-md-12 font-weight-bold',
        fieldGroupClassName: 'row font-weight-normal',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.stereotype',
            labelClass: 'd-block d-md-none'
          }
        },
        fieldGroup: getFormlyPreSelectedField('ticket.stereotype', 'core-shared.shared.fields.stereotype', {
          type: 'core-portal-entity-select',
          wrappers: ['core-portal-translated', 'core-portal-readonly'],
          defaultValue: null,
          templateOptions: {
            corePortalReadonly: {
              type: CorePortalFormlyReadonlyTypes.ENTITY,
              displayKey: 'name',
              link: (row: StereotypeDto) => row?.stereotypeId ? `/stereotypes/${ row.stereotypeId }` : null,
              module: 'settings'
            } as CorePortalFormlyReadonlyTyping,
            entityService: this.stereotypeService,
            idKey: 'stereotypeId',
            displayKey: 'name',
            wholeObject: true,
            skipGetOne: true,
            link: (row: StereotypeDto) => row?.stereotypeId ? `/stereotypes/${ row.stereotypeId }` : null,
            module: 'settings',
            defaultFilters$: of([
              {
                property: 'isArchived',
                type: FilterTypes.DataTransferObject,
                operator: FilterOperators.Equal,
                value: 'false'
              },
              {
                property: 'entityType',
                type: FilterTypes.DataTransferObject,
                operator: FilterOperators.Equal,
                value: AppEntityType.Ticket.toString()
              }
            ] as Filter[])
          },
          hooks: {
            onInit: field => this.subscribe(field.formControl.valueChanges.pipe(
              startWith(field.formControl.value),
              distinctUntilChanged()
            ), value => {
              if (!value) {
                field.form.get('ticket.state.value').setValue(null);
                field.form.get('ticket.state.isOverridable').setValue(true);
                field.form.get('ticket.priority.value').setValue(null);
                field.form.get('ticket.priority.isOverridable').setValue(true);
              }
            })
          }
        }, () => this.readonly, false, !this.isLinkableDefinition, () => this.isLinkableDefinition)
      },

      // State
      {
        wrappers: ['core-portal-translated'],
        className: 'col-md-12 font-weight-bold',
        fieldGroupClassName: 'row font-weight-normal',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.ticket-state',
            labelClass: 'd-block d-md-none'
          }
        },
        fieldGroup: getFormlyPreSelectedField(
          'ticket.state',
          'core-shared.shared.fields.ticket-state',
          {
            type: 'core-portal-entity-select',
            wrappers: ['core-portal-translated', 'core-portal-readonly'],
            defaultValue: null,
            templateOptions: {
              corePortalReadonly: {
                type: CorePortalFormlyReadonlyTypes.ENTITY,
                displayKey: 'name',
                link: (row: StateDto) => row?.stateId ? `/states/${ row.stateId }` : null,
                module: 'settings'
              } as CorePortalFormlyReadonlyTyping,
              entityService: this.stateService,
              idKey: 'stateId',
              displayKey: 'name',
              wholeObject: true,
              skipGetOne: true,
              link: (row: StateDto) => row?.stateId ? `/states/${ row.stateId }` : null,
              module: 'settings'
            },
            expressionProperties: {
              'templateOptions.disabled': () => this.readonly || !this.model?.ticket?.stereotype?.value
            }
          },
          () => this.readonly,
          true,
          true,
          () => !this.model?.ticket?.stereotype?.value
        )
      },

      // Priority
      {
        wrappers: ['core-portal-translated'],
        className: 'col-md-12 font-weight-bold',
        fieldGroupClassName: 'row font-weight-normal',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.priority',
            labelClass: 'd-block d-md-none'
          }
        },
        fieldGroup: getFormlyPreSelectedField(
          'ticket.priority',
          'core-shared.shared.fields.priority',
          {
            type: 'core-portal-entity-select',
            wrappers: ['core-portal-translated', 'core-portal-readonly'],
            defaultValue: null,
            templateOptions: {
              corePortalReadonly: {
                type: CorePortalFormlyReadonlyTypes.ENTITY,
                displayKey: 'name'
              } as CorePortalFormlyReadonlyTyping,
              entityService: this.priorityService,
              idKey: 'priorityId',
              displayKey: 'name',
              wholeObject: true,
              skipGetOne: true
            },
            expressionProperties: {
              'templateOptions.disabled': () => this.readonly || !this.model?.ticket?.stereotype?.value
            }
          },
          () => this.readonly,
          true,
          true,
          () => !this.model?.ticket?.stereotype?.value)
      },

      // Resource Selector

      {
        key: 'ticket.resourceSelector.type',
        type: 'core-portal-ng-select',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-8',
        defaultValue: ResourceTicketSkeletonSelectorType.All,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-portal.settings.fields.ticket-settings.processes.resource-selection',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          } as CorePortalFormlyTranslatedTyping,
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.ENUM,
            enumOptions: resourceTicketSkeletonSelectorTypeEnumOptions,
            translate: true
          } as CorePortalFormlyReadonlyTyping,
          corePortalNgSelect: {
            items: resourceTicketSkeletonSelectorTypeEnumOptions,
            translate: true
          } as CorePortalFormlyNgSelectTyping
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        },
        hideExpression: () => this.isLinkableDefinition
      },

      {
        key: 'ticket.resourceSelector.stereotypes',
        type: 'core-portal-entity-select',
        wrappers: ['core-portal-translated'],
        className: 'col-md-8',
        defaultValue: [],
        templateOptions: {
          corePortalTranslated: {
            label: 'core-portal.settings.fields.ticket-settings.processes.stereotypes'
          },
          entityService: this.stereotypeService,
          idKey: 'stereotypeId',
          displayKey: 'name',
          wholeObject: true,
          multiple: true,
          showAll: true,
          defaultFilters$: of([
            {
              property: 'isArchived',
              type: FilterTypes.DataTransferObject,
              operator: FilterOperators.Equal,
              value: 'false'
            },
            {
              property: 'entityType',
              type: FilterTypes.DataTransferObject,
              operator: FilterOperators.Equal,
              value: AppEntityType.Resource.toString()
            }
          ] as Filter[])
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly
        },
        hideExpression: () => this.model?.ticket?.resourceSelector?.type !== ResourceTicketSkeletonSelectorType.RestrictedByType || this.isLinkableDefinition
      },
      {
        key: 'ticket.resourceSelector.whitelist',
        type: 'core-portal-entity-select',
        wrappers: ['core-portal-translated'],
        className: 'col-md-8',
        defaultValue: [],
        templateOptions: {
          corePortalTranslated: {
            label: 'core-portal.settings.fields.ticket-settings.processes.resources'
          },
          entityService: this.resourceService,
          idKey: 'resourceId',
          displayKey: 'name',
          wholeObject: true,
          multiple: true,
          showAll: true,
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly
        },
        hideExpression: () => this.model?.ticket?.resourceSelector?.type !== ResourceTicketSkeletonSelectorType.Whitelisted || this.isLinkableDefinition
      },
      {
        key: 'ticket.resourceSelector.resource',
        type: 'core-portal-entity-select',
        wrappers: ['core-portal-translated'],
        className: 'col-md-8',
        defaultValue: [],
        templateOptions: {
          corePortalTranslated: {
            label: 'core-portal.settings.fields.ticket-settings.processes.resource'
          },
          entityService: this.resourceService,
          idKey: 'resourceId',
          displayKey: 'name',
          wholeObject: true,
          multiple: false
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly
        },
        hideExpression: () => this.model?.ticket?.resourceSelector?.type !== ResourceTicketSkeletonSelectorType.Fixed || this.isLinkableDefinition
      }
    ];
  }
}
