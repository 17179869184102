import {functionContactListStore, functionDetailStore, functionListStore} from './stores';
import {CorePortalFeatureMasterDataFunctionService, FunctionContactService} from './services';

export * from './services';
export * from './stores';

export * from './functions.state';
export * from './functions.selectors';

export const functionsReducers = {
  functionList: functionListStore.reducer,
  functionDetail: functionDetailStore.reducer,

  functionContactList: functionContactListStore.reducer
};

export const functionsEffects = [
  functionListStore.effects,
  functionDetailStore.effects,

  functionContactListStore.effects
];

export const functionsServices = [
  CorePortalFeatureMasterDataFunctionService,

  FunctionContactService
];
