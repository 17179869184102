import {ModuleWithProviders, NgModule} from "@angular/core";
import {ManufacturersRoutingModule} from "./manufacturers-routing.module";
import {ManufacturersDeclarationsModule} from "./manufacturers-declarations.module";
import {ManufacturersStoreModule} from "./manufacturers-store.module";

@NgModule({
  imports: [ManufacturersRoutingModule, ManufacturersDeclarationsModule, ManufacturersStoreModule]
})
export class ManufacturersModule {
  public static forFeature(): ModuleWithProviders<ManufacturersDeclarationsModule> {
    return { ngModule: ManufacturersDeclarationsModule };
  }
}
