import {Injectable, Injector} from '@angular/core';
import {CoreSharedApiBaseService, Product} from '@nexnox-web/core-shared';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable()
export class CorePortalVirtualResourceService extends CoreSharedApiBaseService {
  constructor(
    protected injector: Injector
  ) {
    super(injector, Product.TECH, 'resource/all');
  }

  public deleteOne<T>(id: number | string, parentIds?: Array<number | string>): Observable<T> {
    return this.httpRequestService.delete<T>(`resource/${ id }`).pipe(
      map(response => response.body)
    );
  }

  public getOne<T>(id: number | string, parentIds?: Array<number | string>): Observable<T> {
    return this.httpRequestService.get<T>(`resource/${ id }`).pipe(
      map(response => response.body)
    );
  }

  public createOne<T>(data: T, parentIds?: Array<number | string>): Observable<T> {
    return this.httpRequestService.post<T>(`resource`, data).pipe(
      map(response => response.body)
    );
  }

}
