<p-sidebar #sidebarComponent
           (visibleChange)="onVisibleChange($event)"
           [transitionOptions]="transitionOptions"
           [visible]="visible$ | async"
           appendTo="body"
           position="right"
           styleClass="p-sidebar-lg cross-creation">

  <ng-container #editComponentContainer>
  </ng-container>

  <div *ngIf="issue$ | async as issue" class="text-center">
    <a [nexnoxWebTenantRouterLink]="'/' + getIssueLink(issue)" module="communication" target="_blank">
      {{ 'core-shared.shared.actions.new-tab' | translate }}
    </a>
  </div>

</p-sidebar>
