<p-sidebar #sidebarComponent
           (visibleChange)="onVisibleChange($event)"
           [transitionOptions]="transitionOptions"
           [visible]="visible$ | async"
           appendTo="body"
           position="right"
           styleClass="p-sidebar-md">


  <div *ngIf="(loading$ | async) === false; else loadingTemplate"
       class="d-flex align-content-between flex-column h-100">
    <div class="w-100 flex-grow-1">

      <div class="row">
        <div class="col-12 mb-2">
          <h5>{{ 'tickets.actions.force-ticket-state' | translate }}</h5>
        </div>
      </div>
      <div class="row w-100">
        <form [formGroup]="form" class="w-100">
          <formly-form [(model)]="model"
                       [fields]="fields"
                       [form]="form"
                       class="row w-100">
          </formly-form>
        </form>
      </div>
      <div *ngIf="isForceState" class="row mt-3">
        <div class="col-md-12">
          <div class="alert alert-info">
            <fa-icon [fixedWidth]="true" [icon]="faExclamationTriangle" class="mr-2"></fa-icon>
            {{ 'tickets.descriptions.force-ticket-state-warning' | translate }}
          </div>
        </div>
      </div>
    </div>

    <div class="w-100 flex-shrink-1 d-flex justify-content-end">
      <button (click)="onHide()"
              [icon]="faTimes | nexnoxWebFaIconStringPipe"
              [label]="'core-portal.core.general.cancel' | translate"
              class="p-button p-button-secondary mr-3"
              pButton
              type="button">
      </button>

      <button (click)="onChangeState()"
              [disabled]="form?.invalid"
              [icon]="faExchangeAlt | nexnoxWebFaIconStringPipe"
              [label]="'tickets.actions.force-ticket-state'  | translate"
              class="p-button p-button-primary"
              pButton
              type="button">
      </button>
    </div>
  </div>


</p-sidebar>

<ng-template #loadingTemplate>
  <nexnox-web-loading-icon class="d-flex w-100 h-100 align-items-center justify-content-center flex-column p-5"
                           size="5x">
  </nexnox-web-loading-icon>
</ng-template>
