<nexnox-web-entity-edit [entityEditBaseComponent]="this"
                        [isTitleEditable]="false"
                        displayKey="displayName">
  <div *ngIf="model && !creating && model.lastLoggedIn"
       class="d-flex w-100 justify-content-end text-muted p-2">
    {{ 'core-portal.master-data.contact.descriptions.last-logged-in' | translate }} {{ model.lastLoggedIn | nexnoxWebDayjsRelative | async }}
  </div>
</nexnox-web-entity-edit>

<!-- Location Templates -->
<ng-template #locationSelectLabelTitleTemplate let-item="item">
  <nexnox-web-master-data-locations-location-location-number [label]="true"
                                                             [location]="item">
  </nexnox-web-master-data-locations-location-location-number>
</ng-template>

<ng-template #locationSelectOptionTitleTemplate let-item="item" let-searchTerm="searchTerm">
  <nexnox-web-master-data-locations-location-location-number [label]="false"
                                                             [location]="item"
                                                             [searchTerm]="searchTerm">
  </nexnox-web-master-data-locations-location-location-number>
</ng-template>

<!-- Change Mailaddress Sidebar -->
<nexnox-web-master-data-contacts-change-mailaddress-sidebar #changeMailAddressSidebarComponent>
</nexnox-web-master-data-contacts-change-mailaddress-sidebar>
