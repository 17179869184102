<ng-template #entityEditTemplate>

  <nexnox-web-missions-ticket-mission-create #modelComponent
                                             (modelChange)="onModelChange($event)"
                                             [creating]="isCreate$ | async"
                                             [loading]="loading$ | async"
                                             [model]="model$ | async"
                                             [readonly]="readonly$ | async"
                                             [stereotyped]="true"
                                             [stereotypes$]="stereotypes$"
                                             [title]="title | translate">
  </nexnox-web-missions-ticket-mission-create>
</ng-template>

<nexnox-web-entity-detail [entityDetailBaseComponent]="this"
                          [entityEditTemplate]="entityEditTemplate"
                          [useTabs]="false">
</nexnox-web-entity-detail>
