import {createDropdown, Plugin} from 'ckeditor5';

import {SimpleVideoFormView} from './simple-video-form.view';

export class SimpleVideoUI extends Plugin {

  public simpleVideoIcon = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M18.68 3.03c.6 0 .59-.03.59.55v12.84c0 .59.01.56-.59.56H1.29c-.6 0-.59.03-.59-.56V3.58c0-.58-.01-.55.6-.55h17.38zM15.77 15V5H4.2v10h11.57zM2 4v1h1V4H2zm0 2v1h1V6H2zm0 2v1h1V8H2zm0 2v1h1v-1H2zm0 2v1h1v-1H2zm0 2v1h1v-1H2zM17 4v1h1V4h-1zm0 2v1h1V6h-1zm0 2v1h1V8h-1zm0 2v1h1v-1h-1zm0 2v1h1v-1h-1zm0 2v1h1v-1h-1zM7.5 7.177a.4.4 0 0 1 .593-.351l5.133 2.824a.4.4 0 0 1 0 .7l-5.133 2.824a.4.4 0 0 1-.593-.35V7.176v.001z"/></svg>';

  public static get pluginName(): string {
    return 'SimpleVideoUI';
  }

  public init(): void {
    const t = this.editor.t;
    const config = this.editor.config;
    const command = this.editor.commands.get('insertSimpleVideo');

    this.editor.ui.componentFactory.add('simpleVideo', locale => {
      const dropdown = createDropdown(locale);
      const simpleVideoForm = new SimpleVideoFormView(getFormValidators(this.editor.t, config), this.editor.locale);

      this._setupDropdown(dropdown, simpleVideoForm, command);
      this._setupForm(dropdown, simpleVideoForm, command);

      return dropdown;
    });
  }

  private _setupDropdown(dropdown, form, command): void {
    const editor = this.editor;
    const config = editor.config;
    const t = editor.t;
    const button = dropdown.buttonView;

    dropdown.bind('isEnabled').to(command);
    dropdown.panelView.children.add(form);

    button.set({
      label: t('Insert video'),
      icon: this.simpleVideoIcon,
      tooltip: true
    });

    button.on('open', () => {
      form.disableCssTransitions();
      form.video = command.value || '';
      form.videoInputView.fieldView.select();
      form.focus();
      form.enableCssTransitions();
    }, { priority: 'low' });

    dropdown.on('submit', () => {
      if (form.isValid()) {
        // @ts-ignore
        for (const platform of config.get('simpleVideo.platforms')) {
          if (platform.match.test(form.video)) {
            const matches = platform.match.exec(form.video);
            const id = platform.getId(matches);

            editor.execute('insertSimpleVideo', id, platform);

            break;
          }
        }

        closeUI();
      }
    });

    dropdown.on('change:isOpen', () => form.resetFormStatus());
    dropdown.on('cancel', () => closeUI());

    function closeUI(): void {
      editor.editing.view.focus();
      dropdown.isOpen = false;
    }
  }

  private _setupForm(dropdown, form, command): void {
    form.delegate('submit', 'cancel').to(dropdown);
    form.videoInputView.bind('value').to(command, 'value');
    form.videoInputView.bind('isReadOnly').to(command, 'isEnabled', value => !value);
  }
}

function getFormValidators(t, config): any[] {
  return [
    form => {
      if (!form.video.length) {
        return t('The URL must not be empty.');
      }
    },
    form => {
      let isValid = false;

      for (const platform of config.get('simpleVideo.platforms')) {
        isValid = platform.match.test(form.video);

        if (isValid) {
          break;
        }
      }

      if (!isValid) {
        return t('The URL must be a valid URL.');
      }
    }
  ];
}
