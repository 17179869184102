<p-sidebar #sidebarComponent
           (visibleChange)="onVisibleChange($event)"
           [transitionOptions]="transitionOptions"
           [visible]="visible$ | async"
           appendTo="body"
           position="right"
           styleClass="p-sidebar-md">
  <div *ngIf="(loading$ | async) === false; else loadingTemplate"
       class="d-flex align-content-between flex-column h-100">

    <div class="w-100 flex-grow-1">
      <div class="row">
        <div *ngIf="isCloseLocation" class="col-12">
          <h5>{{ 'core-portal.master-data.location.fields.close-location' | translate }}</h5>

          <p>{{ 'core-portal.master-data.location.descriptions.close-info' | translate }}</p>
          <ul>
            <li class="mb-2">{{ 'core-portal.master-data.location.descriptions.close-bullet-1' | translate }}</li>
            <li class="mb-2">{{ 'core-portal.master-data.location.descriptions.close-bullet-2' | translate }}</li>
            <li class="mb-2">{{ 'core-portal.master-data.location.descriptions.close-bullet-3' | translate }}</li>
          </ul>

          <h5 class="mt-4">{{ 'core-portal.core.general.options' | translate }}</h5>

          <div class="row w-100">
            <form [formGroup]="form" class="w-100">
              <formly-form [(model)]="model"
                           [fields]="fields"
                           [form]="form"
                           class="row w-100">
              </formly-form>
              <small *ngIf="model.shouldMoveResources" class="form-text text-muted mx-2">
                {{ 'core-portal.master-data.location.descriptions.select-resources-location' | translate }}
              </small>
            </form>
          </div>
        </div>

        <div *ngIf="!isCloseLocation" class="col-12">
          <h5>{{ 'core-portal.master-data.location.fields.open-location' | translate }}</h5>
          <p>{{ 'core-portal.master-data.location.descriptions.open-info' | translate }}</p>
        </div>
      </div>
    </div>


    <div class="w-100 flex-shrink-1 d-flex justify-content-end">
      <button (click)="onHide()"
              [icon]="faTimes | nexnoxWebFaIconStringPipe"
              [label]="'core-portal.core.general.cancel' | translate"
              class="p-button p-button-secondary mr-3"
              pButton
              type="button">
      </button>

      <button (click)="onCloseLocation()"
              *ngIf="isCloseLocation"
              [disabled]="!form.valid"
              [icon]="faDoorClosed | nexnoxWebFaIconStringPipe"
              [label]="'core-portal.master-data.location.fields.close-location'  | translate"
              class="p-button p-button-primary"
              pButton
              type="button">
      </button>

      <button (click)="onOpenLocation()"
              *ngIf="!isCloseLocation"
              [disabled]="false"
              [icon]="faDoorOpen | nexnoxWebFaIconStringPipe"
              [label]="'core-portal.master-data.location.fields.open-location'  | translate"
              class="p-button p-button-primary"
              pButton
              type="button">
      </button>
    </div>
  </div>
</p-sidebar>

<ng-template #loadingTemplate>
  <nexnox-web-loading-icon class="d-flex w-100 h-100 align-items-center justify-content-center flex-column p-5"
                           size="5x">
  </nexnox-web-loading-icon>
</ng-template>

<!-- Location Templates -->
<ng-template #locationSelectLabelTitleTemplate let-item="item">
  <nexnox-web-master-data-locations-location-location-number [label]="true"
                                                             [location]="item">
  </nexnox-web-master-data-locations-location-location-number>
</ng-template>

<ng-template #locationSelectOptionTitleTemplate let-item="item" let-searchTerm="searchTerm">
  <nexnox-web-master-data-locations-location-location-number [label]="false"
                                                             [location]="item"
                                                             [searchTerm]="searchTerm">
  </nexnox-web-master-data-locations-location-location-number>
</ng-template>
