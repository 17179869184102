import {BaseXsStoreReducerTypes, EntityXsStore, EntityXsStoreState} from '@nexnox-web/core-store';
import {LocationDto} from '@nexnox-web/core-shared';
import {createLocationDetailXsStoreActions, LocationDetailXsStoreActions} from './location-detail-xs-store.actions';
import {createLocationDetailXsStoreReducer} from './location-detail-xs-store.reducer';

export class LocationDetailXsStore extends EntityXsStore<LocationDto> {
  public actions: LocationDetailXsStoreActions;

  protected createActions(label: string): LocationDetailXsStoreActions {
    return createLocationDetailXsStoreActions(label);
  }

  protected createReducerArray(
    initialState: EntityXsStoreState<LocationDto>
  ): BaseXsStoreReducerTypes<EntityXsStoreState<LocationDto>, LocationDetailXsStoreActions>[] {
    return [
      ...super.createReducerArray(initialState),
      ...createLocationDetailXsStoreReducer(this.actions, initialState)
    ];
  }
}
