<p-table #datatableComponent
         (onLazyLoad)="onPage($event)"
         [currentPageReportTemplate]="'{totalRecords} ' + ('core-shared.shared.table.total-items' | translate)"
         [customSort]="true"
         [dataKey]="idProp"
         [first]="offset * limit"
         [lazyLoadOnInit]="false"
         [lazy]="true"
         [loadingIcon]="faSpinner | nexnoxWebFaIconStringPipe:'fa-spin'"
         [loading]="showLoading ? loading || (this.datatableViewService?.storeLoading$ | async) : false"
         [paginator]="!!paging"
         [resetPageOnSort]="false"
         [resizableColumns]="true"
         [rowHover]="true"
         [rows]="limit"
         [showCurrentPageReport]="!!paging"
         [showFirstLastIcon]="true"
         [sortField]="currentSortOption?.sortField ? currentSortOption.sortField : null"
         [sortOrder]="currentSortOption?.sort ? (currentSortOption.sort === orders.Ascending ? 1 : -1) : 1"
         [totalRecords]="count"
         [value]="models$ ? (models$ | async) : models"
         responsiveLayout="scroll"
         styleClass="p-datatable-gridlines entity-datatable">
  <ng-template *ngIf="!hideHeader" pTemplate="caption">
    <div class="d-flex flex-row w-100 justify-content-end">
      <ng-container *ngFor="let headerAction of additionalHeaderActions">
        <button (click)="headerAction.onClick()"
                *ngIf="headerAction.show$ ? (headerAction.show$ | async) : true"
                [class]="'p-ripple p-button-icon-only p-button-sm ml-2 ' + headerAction.className"
                [disabled]="headerAction.disabled$ ? (headerAction.disabled$ | async) : false"
                [icon]="headerAction.icon | nexnoxWebFaIconStringPipe"
                [title]="headerAction.title | translate"
                pButton>
        </button>
      </ng-container>

      <nexnox-web-entity-datatable-settings-sidebar #settingsSidebarComponent
                                                    *ngIf="showSettings && datatableViewService"
                                                    [activeColumns]="activeColumns$ | async"
                                                    [columnTypings]="columnTypings"
                                                    [componentId]="componentId"
                                                    [dataColumns]="dataColumns"
                                                    [datatableConfig]="datatableConfig ?? localDatatableConfig"
                                                    [datatableViewService]="datatableViewService"
                                                    [disableDatatableViewSelect]="disableSettingsViewSelect"
                                                    [disableDatatableViews]="disableSettingsViews"
                                                    [enableViews]="enableViews"
                                                    [filters]="currentFilters$ | async"
                                                    [optionalColumns]="optionalColumns"
                                                    [pageOperation]="pageOperation"
                                                    [sortObject]="currentSortOption"
                                                    [stereotypeColumns]="stereotypeColumns"
                                                    [templates]="templates"
                                                    [viewType]="viewType">
      </nexnox-web-entity-datatable-settings-sidebar>

      <div *ngIf="showPredefinedViews && (datatableViewService.currentViewTitle$ | async) as viewTitle"
           class="applied-view-title ml-2 align-self-center">
        {{ viewTitle | translate }}
      </div>

      <nexnox-web-entity-datatable-view-dropdown (openSettings)="onShowSettingsSidebar()"
                                                 (resetFilters)="onClearFilters()"
                                                 [activeColumns]="activeColumns$ | async"
                                                 [columnTypings]="columnTypings"
                                                 [componentId]="componentId"
                                                 [dataColumns]="dataColumns"
                                                 [datatableConfig]="datatableConfig ?? localDatatableConfig"
                                                 [datatablePredefinedViewConfig]="datatablePredefinedViewConfig ?? null"
                                                 [datatableViewService]="datatableViewService"
                                                 [enableViews]="enableViews && showSettings"
                                                 [entityType]="entityType"
                                                 [optionalColumns]="optionalColumns"
                                                 [pageOperation]="pageOperation"
                                                 [showResetFiltersOnly]="!showPredefinedViews"
                                                 [sortObject]="currentSortOption"
                                                 [stereotypeColumns]="stereotypeColumns"
                                                 [viewType]="viewType">
      </nexnox-web-entity-datatable-view-dropdown>

      <button (click)="onGetCalendarSubscriptionLink()"
              *ngIf="enableCalendarSubscription && (hasCalendarSubscriptionPermission$ | async)"
              [icon]="faCalendarDay | nexnoxWebFaIconStringPipe"
              [title]="'missions.calendar-subscription.subscribe' | translate"
              class="p-button-text p-button-secondary p-ripple p-button-icon-only p-button-sm ml-2"
              pButton
              type="button">
      </button>

      <button (click)="onShowSettingsSidebar()"
              *ngIf="showSettings"
              [icon]="faCog | nexnoxWebFaIconStringPipe"
              [title]="'core-shared.shared.table.tooltip.settings' | translate"
              class="p-button-text p-button-secondary p-ripple p-button-icon-only p-button-sm ml-2"
              pButton
              type="button">
      </button>

      <button (click)="openExportSidebar()"
              *ngIf="enableViews && canExport && (canExportDatatable$ | async)"
              [icon]="faFileExport | nexnoxWebFaIconStringPipe"
              [title]="'core-shared.shared.table.tooltip.export' | translate"
              class="p-button-text p-button-secondary p-ripple p-button-icon-only p-button-sm ml-2"
              pButton
              type="button">
      </button>

      <button (click)="onCollapseAll()"
              *ngIf="detailTemplate && expandedRows > 0"
              [icon]="faCompress | nexnoxWebFaIconStringPipe"
              [title]="'core-shared.shared.table.tooltip.collapse-rows' | translate"
              class="p-button-text p-button-secondary p-ripple p-button-icon-only p-button-sm ml-2"
              pButton
              type="button">
      </button>

      <button (click)="onRefresh()"
              *ngIf="showRefresh"
              [disabled]="loading"
              [icon]="faSync | nexnoxWebFaIconStringPipe"
              [title]="'core-shared.shared.table.tooltip.refresh' | translate"
              class="p-button-text p-button-primary p-ripple p-button-icon-only p-button-sm ml-2"
              pButton
              type="button">
      </button>
    </div>
  </ng-template>

  <ng-template pTemplate="header">
    <tr>

      <th *ngIf="selectionMode === selectionModes.Multiple" style="width: 3rem">
        {{ 'core-portal.core.general.selection' | translate }}
        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
      </th>
      <th *ngIf="selectionMode === selectionModes.Single" style="width: 3rem">
        {{ 'core-portal.core.general.selection' | translate }}
        <!-- radio button placeholder -->
      </th>

      <th *ngIf="detailTemplate"
          [style.height.px]="hideFilters ? 45 : null"
          [style.max-width.px]="60.98"
          [style.min-width.px]="60.98"
          [style.width.px]="60.98">
      </th>

      <th *ngFor="let column of activeColumns$ | async; trackBy: trackColumnBy"
          [pSortableColumnDisabled]="!column.sortable || hideSorting"
          [pSortableColumn]="column.prop"
          [style.height.px]="hideFilters ? 45 : null"
          [style.max-width.px]="column.maxWidth"
          [style.min-width.px]="column.minWidth"
          [style.width.px]="column.width"
          pResizableColumn>
        <nexnox-web-entity-datatable-header #headerComponent
                                            (search)="onSearch(column, $event)"
                                            [column]="column"
                                            [currentFilters]="currentFilters$ | async"
                                            [disableFilters]="disableFilters"
                                            [hideFilters]="hideFilters"
                                            [hideSorting]="hideSorting"
                                            [templates]="templates">
        </nexnox-web-entity-datatable-header>
      </th>
    </tr>
  </ng-template>

  <ng-template let-expanded="expanded" let-row let-rowIndex="rowIndex" pTemplate="body">
    <tr [pSelectableRowIndex]="rowIndex" [pSelectableRow]="row">
      <td *ngIf="selectionMode === selectionModes.Multiple">
        <p-tableCheckbox [pSelectableRow]="row" [value]="row"></p-tableCheckbox>
      </td>
      <td *ngIf="selectionMode === selectionModes.Single">
        <p-tableRadioButton [pSelectableRow]="row" [value]="row"></p-tableRadioButton>
      </td>
      <td *ngIf="detailTemplate"
          [class.is-expanded]="expanded"
          [style.max-width.px]="60.98"
          [style.min-width.px]="60.98"
          [style.width.px]="60.98">
        <button (click)="onExpandRow(row)"
                *ngIf="!hideFilters && showDetailTemplateExpandButton(row)"
                [disabled]="loading"
                [icon]="(expanded ? faChevronDown : faChevronRight) | nexnoxWebFaIconStringPipe"
                class="p-button-text p-button-icon-only p-button-sm p-button-secondary"
                pButton
                type="button">
        </button>
      </td>

      <td *ngFor="let column of activeColumns$ | async; trackBy: trackColumnBy"
          [class.is-expanded]="expanded"
          [class.pr-0]="column.prop === displayProp || column.prop === idProp"
          [style.max-width.px]="column.maxWidth"
          [style.min-width.px]="column.minWidth"
          [style.padding.px]="column.padding"
          [style.width.px]="column.width">
        <div [class.align-items-center]="!column.minHeight && !column.height && !column.maxHeight"
             [class.cursor-pointer]="column.clickable === true"
             [class.overflow-auto]="column.minHeight || column.height || column.maxHeight"
             [style.height.px]="column.height"
             [style.max-height.px]="column.maxHeight"
             [style.min-height.px]="column.minHeight"
             class="d-flex justify-content-between h-100">
          <nexnox-web-entity-datatable-cell (click)="onCellClick(column, row, $event)"
                                            *ngIf="!column.cellTemplate; else cellTemplate"
                                            [column]="column"
                                            [detailFn]="detailFn"
                                            [detailLink]="detailLink"
                                            [idProp]="idProp"
                                            [isTitle]="column.prop === displayProp || column.prop === idProp"
                                            [module]="module"
                                            [row]="row">
          </nexnox-web-entity-datatable-cell>

          <ng-template #cellTemplate>
            <ng-container [ngTemplateOutletContext]="{ row: row }"
                          [ngTemplateOutlet]="column.cellTemplate">
            </ng-container>
          </ng-template>

          <ng-container *ngIf="column.prop === displayProp || column.prop === idProp">
            <nexnox-web-entity-datatable-last-column-cell (delete)="onDelete($event)"
                                                          [actionButtons]="actionButtons"
                                                          [canDelete]="canDelete"
                                                          [entityData]="entityData"
                                                          [idProp]="idProp"
                                                          [loading]="loading"
                                                          [row]="row">
            </nexnox-web-entity-datatable-last-column-cell>
          </ng-container>
        </div>
      </td>
    </tr>
  </ng-template>

  <ng-template let-row pTemplate="rowexpansion">
    <tr class="is-expanded">
      <td class="p-0 border-top-0"
          colspan="100%">
        <div [style.padding-left.px]="60.98"
             [style.padding-right.px]="60.98"
             class="py-3">
          <ng-container [ngTemplateOutletContext]="{ $implicit: row }"
                        [ngTemplateOutlet]="detailTemplate"></ng-container>
        </div>
      </td>
    </tr>
  </ng-template>

  <ng-template let-columns pTemplate="emptymessage">
    <tr>
      <td colspan="100">
        {{ 'core-shared.shared.table.empty' | translate }}
      </td>
    </tr>
  </ng-template>
</p-table>

<nexnox-web-entity-datatable-export-sidebar #exportSidebarComponent
                                            (accept)="onExport($event)">
</nexnox-web-entity-datatable-export-sidebar>

<nexnox-web-calendar-subscription-sidebar #calendarSubscriptionSidebarComponent>
</nexnox-web-calendar-subscription-sidebar>
