import {errorCodeDetailStore, ErrorCodeDetailStoreState, errorCodeListStore, ErrorCodeListStoreState} from './stores';

export interface ErrorCodesState {
  errorCodeList: ErrorCodeListStoreState;
  errorCodeDetail: ErrorCodeDetailStoreState;
}

export const getInitialErrorCodesState = (): ErrorCodesState => ({
  errorCodeList: errorCodeListStore.getInitialState(),
  errorCodeDetail: errorCodeDetailStore.getInitialState()
});
