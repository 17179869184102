<div class="d-flex align-items-center">
  <div *ngIf="image$ | async as image" class="image-attachment-thumbnail mr-3 mb-3">
    <p-image [alt]="image.name" [preview]="true" [src]="image.uri" appendTo="body" width="100"></p-image>
  </div>

  <div class="d-flex flex-grow-1">
    <form [formGroup]="form" class="w-100">
      <formly-form (modelChange)="onModelChange($event)"
                   [fields]="fields"
                   [form]="form"
                   [model]="model$ | async"
                   class="row">
      </formly-form>
    </form>
  </div>
</div>
