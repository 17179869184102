<nexnox-web-cardbox [indicatorNum]="(entities$ | async)?.length"
                    [isAccordion]="true"
                    [isAutoExpandOnIndicator]="true"
                    [isCollapsed]="true"
                    [loading]="(loading$ | async) || loading"
                    [title]="title | translate"
                    [transparent]="true"
                    paddingClasses="px-3 pb-3">
  <ng-container *ngIf="entities$ | async as entities">
    <ng-container *ngIf="entities?.length; else emptyTemplate">
      <ng-container *ngFor="let entity of entities; let last = last">
        <ng-container *ngIf="entity?.model as event">
          <div *ngIf="entityData$ | async as entityData"
               [class.mb-1]="!last"
               [style.height.px]="27"
               class="w-100 d-flex">
            <span
              [title]="event.triggersAt | nexnoxWebBsDate:'L LT' | async"
              class="d-flex flex-grow-1 align-items-center">

              <strong *ngIf="event.ruleToExpress?.level">{{ event.ruleToExpress.level.name }}</strong>
              <strong *ngIf="event.followUpState">{{ event.followUpState.name }}</strong>

              &nbsp;{{ event.triggersAt | nexnoxWebDayjsRelative | async }}
            </span>

            <button (click)="onCancelEvent(event.ticketEventId)"
                    *ngIf="(readonly$ | async) === false"
                    [disabled]="entityData[event.ticketEventId]?.loading.cancelOne"
                    [title]="'core-portal.core.general.cancel' | translate"
                    class="btn btn-sm btn-outline-secondary flex-shrink-0 flex-grow-0">
              <fa-icon *ngIf="!entityData[event.ticketEventId]?.loading.cancelOne" [icon]="faTrashAlt"></fa-icon>
              <nexnox-web-loading-icon *ngIf="entityData[event.ticketEventId]?.loading.cancelOne">
              </nexnox-web-loading-icon>
            </button>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="paging$ | async as paging">
      <ng-container *ngIf="paging.pageNumber < paging.totalPages">
        <button (click)="onMore(paging)"
                [disabled]="appendLoading$ | async"
                class="btn btn-outline-secondary btn-block mt-3">
          {{ 'core-portal.core.general.more' | translate }}
          <nexnox-web-loading-icon *ngIf="appendLoading$ | async"></nexnox-web-loading-icon>
        </button>
      </ng-container>
    </ng-container>

    <ng-template #emptyTemplate>
      {{ 'core-shared.shared.table.empty' | translate }}
    </ng-template>
  </ng-container>
</nexnox-web-cardbox>
