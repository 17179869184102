import {EntityXsStore, EntityXsStoreState} from '@nexnox-web/core-store';
import {AppEntityType, AppRoleDto, AreaAssignmentDto, RoleSimpleDto} from '@nexnox-web/core-shared';
import {createSelector} from '@ngrx/store';
import {selectContactsState} from '../../contacts.selectors';
import {ContactAreaService} from '../../services';

export interface ContactAreaDetailStoreState extends EntityXsStoreState<AreaAssignmentDto> {
}

/* istanbul ignore next */
export const contactAreaDetailStore = new EntityXsStore<AreaAssignmentDto>({
  actionLabel: 'Core Portal - Master Data - Contact - Area Detail',
  stateSelector: createSelector(selectContactsState, state => state.contactAreaDetail),
  serviceType: ContactAreaService,
  entityType: AppEntityType.None,
  stereotyped: false,
  prepareModel: (
    entity: AreaAssignmentDto,
    model: AreaAssignmentDto,
    base: (entity: AreaAssignmentDto, model: AreaAssignmentDto) => AreaAssignmentDto
  ) => {
    const newModel = base(entity, model);

    newModel.roles = (newModel.roles ?? []).map(role => ({
      ...role,
      appRoleId: role.id,
      title: role.name
    } as RoleSimpleDto & AppRoleDto));

    return newModel;
  },
  sanitizeModel: (
    model: AreaAssignmentDto,
    entity: AreaAssignmentDto,
    base: (model: AreaAssignmentDto, entity: AreaAssignmentDto) => AreaAssignmentDto
  ) => {
    const newModel = base(model, entity);

    newModel.roles = (newModel.roles ?? []).map((role: RoleSimpleDto & AppRoleDto) => ({
      id: role.id ?? role.appRoleId,
      name: role.name ?? role.title
    }));

    return newModel;
  }
});
