<div class="row">
  <div class="col-xl-8 pl-0 pr-0 pr-xl-2">
    <nexnox-web-solutions-solution-item-edit #editComponent
                                             (modelChange)="createModel$.next($event)"
                                             *ngIf="(readonly$ | async) === false && !readonly && canCreate"
                                             [creating]="true"
                                             [hasChat]="missionType !== missionTypes.Task"
                                             [headerActions]="createNoteHeaderActions"
                                             [model]="createModel$ | async"
                                             [readonly]="false"
                                             [relationIdProperty]="relationIdProperty"
                                             [relationId]="relationId"
                                             [solution$]="solution$"
                                             [solutionMembers]="(solution$ | async)?.members"
                                             [title]="createTitle | translate"
                                             [type]="solutionMemberType">
    </nexnox-web-solutions-solution-item-edit>
  </div>
</div>

<nexnox-web-solutions-solution [baseComponent]="this"
                               [readonly]="(readonly$ | async) || readonly">
</nexnox-web-solutions-solution>
