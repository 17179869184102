import {ChangeDetectionStrategy, Component, Injector} from '@angular/core';
import {ActionButton, CorePortalEntityDetailBaseComponent, CorePortalPermissionService} from '@nexnox-web/core-portal';
import {AppPermissions, DeviceDto} from '@nexnox-web/core-shared';
import {deviceDetailStore, devicePingListStore} from '../../store';
import {Observable} from 'rxjs';
import {faHeart} from '@fortawesome/free-solid-svg-icons/faHeart';
import {filter, map, mergeMap, take} from 'rxjs/operators';
import {select} from '@ngrx/store';
import {faSync} from '@fortawesome/free-solid-svg-icons/faSync';

@Component({
  selector: 'nexnox-web-devices-device-detail',
  templateUrl: './device-detail.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeviceDetailComponent extends CorePortalEntityDetailBaseComponent<DeviceDto> {
  public title = 'orga-portal.devices.subtitles.device-detail';

  public displayPings$: Observable<boolean>;

  protected idParam = 'id';
  protected displayKey = 'name';

  constructor(
    protected injector: Injector,
    private permissionService: CorePortalPermissionService
  ) {
    super(injector, deviceDetailStore);

    this.displayPings$ = this.permissionService.hasPermission$(AppPermissions.ReadPing);
  }

  /* istanbul ignore next */
  protected async getActionButtons(): Promise<ActionButton[]> {
    const dropdownButton = this.getDefaultActionButtons(
      null,
      null,
      'orga-portal.devices.actions.delete-device',
      'orga-portal.devices.descriptions.delete-device',
      null,
      AppPermissions.DeleteDevice,
      ['/devices']
    )[3];

    return [
      dropdownButton,
      {
        label: 'orga-portal.devices.actions.reboot-device',
        type: 'button',
        class: 'btn-outline-secondary',
        icon: faSync,
        permission: AppPermissions.UpdateDevice,
        callback: () => this.store.dispatch(deviceDetailStore.actions.reboot()),
        isLoading: () => this.loading$.pipe(
          mergeMap(loading => this.store.pipe(
            select(deviceDetailStore.selectors.selectEntityDataLoading, { key: 'reboot' }),
            map(rebootLoading => loading || rebootLoading)
          ))
        ),
        isDisabled: () => this.model$.pipe(
          map(model => !model.platform)
        )
      },
      {
        label: 'orga-portal.devices.actions.ping-device',
        type: 'button',
        class: 'btn-outline-secondary',
        icon: faHeart,
        permission: AppPermissions.CreatePing,
        callback: () => this.onPingAction(),
        isLoading: () => this.loading$.pipe(
          mergeMap(loading => this.store.pipe(
            select(devicePingListStore.selectors.selectLoading),
            map(pingsLoading => loading || pingsLoading)
          ))
        ),
        isDisabled: () => this.model$.pipe(
          map(model => !model.platform)
        )
      }
    ];
  }

  /* istanbul ignore next */
  protected async onPingAction(): Promise<void> {
    const deviceId = await this.model$.pipe(
      filter(model => Boolean(model)),
      take(1),
      map(model => model.id)
    ).toPromise();
    this.store.dispatch(devicePingListStore.actions.pingOne({ deviceId }));
  }
}
