import {ModuleWithProviders, NgModule} from '@angular/core';
import {CancellationReasonsDeclarationsModule} from './cancellation-reasons-declarations.module';
import {CancellationReasonsStoreModule} from './cancellation-reasons-store.module';
import {CancellationReasonsRoutingModule} from './cancellation-reasons-routing.module';

@NgModule({
  imports: [
    CancellationReasonsDeclarationsModule,
    CancellationReasonsRoutingModule,
    CancellationReasonsStoreModule
  ]
})
export class OrgaPortalFeatureCancellationReasonsModule {
  public static forFeature(): ModuleWithProviders<CancellationReasonsDeclarationsModule> {
    return { ngModule: CancellationReasonsDeclarationsModule };
  }
}
