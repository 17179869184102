<div class="input-group d-flex flex-nowrap">
  <div *ngIf="prepends$ | async as prependArray" class="input-group-prepend">
    <span *ngFor="let prependText of prependArray"
          [class.disabled]="formControl.disabled"
          class="input-group-text">
      {{ prependText }}
    </span>
  </div>

  <nexnox-web-input-with-transformers *ngIf="transformers$ | async as transformers"
                                      [formControl]="formControl"
                                      [formlyAttributes]="field"
                                      [hasError]="showError"
                                      [transformers]="transformers"
                                      [type]="to.type"
                                      class="d-flex flex-grow-1">
  </nexnox-web-input-with-transformers>

  <div *ngIf="appends$ | async as appendArray" class="input-group-append">
    <span *ngFor="let appendText of appendArray"
          [class.disabled]="to.disabled"
          class="input-group-text">
      {{ appendText }}
    </span>
  </div>
</div>
