<div class="mb-3">
  <p-checkbox (ngModelChange)="onStrictOrderChange($event)"
              [binary]="true"
              [disabled]="readonly"
              [label]="'orga-portal.tasks.fields.strict-order' | translate"
              [ngModel]="strictOrder$ | async">
  </p-checkbox>
</div>

<nexnox-web-rule-editor-list #subtasksEdit
                             (itemsChange)="onSubtasksChange($event)"
                             [asCards]="true"
                             [canSelectFn]="subtasksCanSelectFn"
                             [disabled]="readonly"
                             [inheritedFieldsFn]="subtasksFieldsFn"
                             [items]="sortedSubtasks$ | async"
                             [maxItems]="8"
                             [selectFn]="subtasksSelectFn"
                             [sortable]="true"
                             [title]="subtasksTitleFn"
                             sortKey="position">
</nexnox-web-rule-editor-list>
