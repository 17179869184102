import {PagedEntitiesXsStore, PagedEntitiesXsStoreState} from '@nexnox-web/core-store';
import {AppEntityType, CompanyDto} from '@nexnox-web/core-shared';
import {Action, createSelector} from '@ngrx/store';
import {selectAddressesState} from '../../addresses.selectors';
import {AddressCompanyService} from '../../services';
import {Injectable, Injector} from '@angular/core';

export interface AddressCompanyListStoreState extends PagedEntitiesXsStoreState<CompanyDto> {
}

/* istanbul ignore next */
export const addressCompanyListStore = new PagedEntitiesXsStore<CompanyDto>({
  actionLabel: 'Core Portal - Master Data - Address - Company List',
  stateSelector: createSelector(selectAddressesState, state => state.addressCompanyList),
  serviceType: AddressCompanyService,
  entityType: AppEntityType.Company,
  selectId: company => company.entity?.companyId ?? company.model?.companyId
});

export function addressCompanyListStoreReducer(state: AddressCompanyListStoreState, action: Action): any {
  return addressCompanyListStore.reducer(state, action);
}

@Injectable()
export class AddressCompanyListStoreEffects extends addressCompanyListStore.effects {
  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }
}
