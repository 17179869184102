import {PagedEntitiesXsStoreState} from '@nexnox-web/core-store';
import {AppEntityType, TicketEventCompleteDto} from '@nexnox-web/core-shared';
import {Action, createSelector} from '@ngrx/store';
import {selectTicketsState} from '../../tickets.selectors';
import {TicketEventService} from '../../services';
import {Injectable, Injector} from '@angular/core';
import {TicketEventsXsStore} from './ticket-events.xs-store';

export interface TicketEventsStoreState extends PagedEntitiesXsStoreState<TicketEventCompleteDto> {
}

/* istanbul ignore next */
export const ticketEventsStore = new TicketEventsXsStore({
  actionLabel: 'Tech Portal - Tickets - Ticket Events',
  stateSelector: createSelector(selectTicketsState, state => state.ticketEvents),
  serviceType: TicketEventService,
  entityType: AppEntityType.None,
  selectId: event => event.entity?.ticketEventId ?? event.model?.ticketEventId,
  stereotyped: false
});

export function ticketEventsStoreReducer(state: TicketEventService, action: Action): any {
  return ticketEventsStore.reducer(state, action);
}

@Injectable()
export class TicketEventsStoreEffects extends ticketEventsStore.effects {
  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }
}
