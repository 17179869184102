<div *ngIf="(createModeSubject | async) === true || id > 0" class="alert alert-primary d-flex align-items-center">
  <fa-icon [fixedWidth]="true" [icon]="faExclamationTriangle" class="mr-2"></fa-icon>
  {{ 'core-portal.settings.descriptions.contract-settings.info' | translate }}
</div>


<ng-template #entityEditTemplate>
  <nexnox-web-stereotypes-contract-settings-edit #modelComponent
                                                 (modelChange)="createModelSubject.next($event)"
                                                 [creating]="false"
                                                 [headerActions]="getCardBoxActionButtons()"
                                                 [id]="id"

                                                 [model]="createModelSubject | async"
                                                 [readonly]="readonly$ | async"
                                                 [title]="title | translate">
  </nexnox-web-stereotypes-contract-settings-edit>
</ng-template>

<div class="min-h-100">
  <nexnox-web-entity-detail *ngIf="id; else promptCreateReportTemplate"
                            [entityDetailBaseComponent]="this"
                            [entityEditTemplate]="entityEditTemplate"
                            [hideOverflow]="false"
                            [useTabs]="false">
  </nexnox-web-entity-detail>
</div>
<ng-template #promptCreateReportTemplate>
  <div *ngIf="(createModeSubject | async) === false; else entityEditTemplate"
       class="d-flex w-100 h-100 align-items-center justify-content-center flex-column py-5">
    <fa-icon [icon]="faTimes" class="d-flex justify-content-center mb-2" size="5x"></fa-icon>
    <h4 class="d-flex justify-content-center">
      {{ 'core-portal.settings.subtitles.stereotype-no-contract-settings.title' | translate }}
    </h4>
    <h6 class="d-flex justify-content-center">
      {{ 'core-portal.settings.subtitles.stereotype-no-contract-settings.subtitle' | translate }}
    </h6>

    <button (click)="setCreateMode()"
            *ngIf="(readonly$ | async) === false"
            [label]="'core-portal.settings.actions.create-contract-settings' | translate"
            class="p-button-secondary p-button-outlined"
            pButton
            type="button">
    </button>
  </div>
</ng-template>
