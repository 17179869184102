import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Injectable, Injector} from '@angular/core';
import {from, isObservable, Observable, of} from 'rxjs';
import {concatMap, first} from 'rxjs/operators';

@Injectable()
export class CorePortalSyncCanActivateGuard implements CanActivate {
  constructor(
    private injector: Injector
  ) {
  }

  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    return from(route.routeConfig.data?.guards).pipe(
      concatMap(guardType => {
        const guard: CanActivate = this.injector.get(guardType);
        const result = guard.canActivate(route, state);

        if (isObservable(result)) {
          return result;
        } else if (result instanceof Promise) {
          return from(result);
        } else {
          return of(result);
        }
      }),
      first(x => x === false || x instanceof UrlTree, true)
    );
  }
}
