<ng-template #entityEditTemplate let-id="id" let-title="title">
  <nexnox-web-master-data-contacts-contact-edit #modelComponent
                                                (modelChange)="onModelChange($event)"
                                                [creating]="false"
                                                [id]="id"
                                                [isChangeMailAddressloading$]="isChangeMailAddressLoading$"
                                                [loading]="loading$ | async"
                                                [model]="model$ | async"
                                                [readonly]="readonly$ | async"
                                                [stereotypes$]="stereotypes$"
                                                [title]="title">
  </nexnox-web-master-data-contacts-contact-edit>
</ng-template>

<nexnox-web-entity-detail #entityDetailComponent
                          [entityDetailBaseComponent]="this"
                          [entityEditTemplate]="entityEditTemplate"
                          [tabCommands]="['.']"
                          [tabHeading]="title | translate"
                          tabId="contact">
  <ng-container *ngIf="(loading$ | async) === false">
    <div *ngIf="(model$ | async).access?.isLockedOut as isLockedOut" class="alert alert-warning">
      <fa-icon [fixedWidth]="true" [icon]="faExclamationTriangle"></fa-icon>
      {{ 'core-portal.master-data.contact.descriptions.is-locked-out' | translate }}
    </div>
  </ng-container>

  <!-- Areas Tab -->
  <ng-template (selectTab)="onNavigateToTab(['areas'])"
               [heading]="'core-portal.master-data.subtitles.contact-area-list' | translate"
               [lazy]="true"
               nexnoxWebTabTemplate
               tabId="areas">
    <nexnox-web-master-data-contacts-contact-area-list *ngIf="passedIds"
                                                       [canCreate]="true"
                                                       [canRouteToDetail]="true"
                                                       [getEntityOnModeChange]="false"
                                                       [isEmbedded]="true"
                                                       [keepEditOnCreateSuccess]="true"
                                                       [noRouteToDetail]="true"
                                                       [parentIds]="passedIds"
                                                       detailLink="masterdata/contacts/{{ passedIds[0] }}/areas">
    </nexnox-web-master-data-contacts-contact-area-list>
  </ng-template>

  <!-- Open Editor Requests Tab -->
  <ng-template (selectTab)="onNavigateToTab(['requests'])"
               [heading]="'core-portal.master-data.subtitles.contact-open-requests' | translate"
               [lazy]="true"
               nexnoxWebTabTemplate
               tabId="requests">
    <nexnox-web-master-data-contacts-contact-open-request-list *ngIf="passedIds"
                                                               [canRouteToDetail]="false"
                                                               [getEntityOnModeChange]="false"
                                                               [isEmbedded]="true"
                                                               [keepEditOnCreateSuccess]="false"
                                                               [noRouteToDetail]="true"
                                                               [parentIds]="passedIds">
    </nexnox-web-master-data-contacts-contact-open-request-list>
  </ng-template>

  <!-- Links -->
  <ng-template (selectTab)="onNavigateToTab(['links'])"
               [hasError]="linksComponent ? !linksComponent.isModelValid() : false"
               [heading]="('tech-portal.subtitles.links' | translate) + (linksComponent ? (linksComponent.linksString$ | async) : '')"
               nexnoxWebTabTemplate
               tabId="links">
    <nexnox-web-tech-links #linksComponent
                           (linksChange)="onLinksChange($event, model)"
                           *ngIf="model$ | async as model"
                           [entityId]="model.contactId"
                           [links$]="links$"
                           [loading]="loading$ | async"
                           [readonly]="readonly$ | async"
                           [type]="linkedElementTypes.Contact">
    </nexnox-web-tech-links>
  </ng-template>

  <!-- Attachments -->
  <ng-template (selectTab)="onNavigateToTab(['attachments'])"
               [hasError]="attachmentsComponent ? !attachmentsComponent.isModelValid() : false"
               [heading]="('tech-portal.subtitles.attachments' | translate) + (attachmentsString$ | async)"
               nexnoxWebTabTemplate
               tabId="attachments">
    <nexnox-web-tech-attachments #attachmentsComponent
                                 #modelComponent
                                 (attachmentsChange)="onAttachmentsChange($event, model)"
                                 *ngIf="model$ | async as model"
                                 [attachments$]="attachments$"
                                 [loading]="loading$ | async"
                                 [readonly]="readonly$ | async">
    </nexnox-web-tech-attachments>
  </ng-template>
</nexnox-web-entity-detail>

<nexnox-web-master-data-contacts-force-password-sidebar #forcePasswordSidebarComponent>
</nexnox-web-master-data-contacts-force-password-sidebar>
