import {ChangeDetectionStrategy, Component, Injector} from '@angular/core';
import {ActionButton, CorePortalEntityOverviewBaseComponent, DatatableActionButton} from '@nexnox-web/core-portal';
import {AppEntityType, AppPermissions, AppTenantDto, Mappers} from '@nexnox-web/core-shared';
import {tenantListStore} from '../../store';

@Component({
  selector: 'nexnox-web-service-tenants-tenant-list',
  templateUrl: './tenant-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TenantListComponent extends CorePortalEntityOverviewBaseComponent<AppTenantDto> {
  public title = 'core-portal.service.tenants.list';
  public createTitle = 'core-portal.service.tenants.actions.create';
  public idProperty = 'tenantId';
  public displayProperty = 'displayName';
  public datatableConfigName = 'ServiceTenantDefault';
  public componentId = 'ServiceTenantListComponent';

  constructor(
    protected injector: Injector
  ) {
    super(injector, tenantListStore, Mappers.AppTenantListDto.serializedName, AppEntityType.None);
  }

  /* istanbul ignore next */
  public getRowActionButtons(): DatatableActionButton[] {
    return this.getDefaultRowActionButtons(
      'core-portal.service.tenants.actions.edit',
      (row: AppTenantDto) => `/tenants/${ row.tenantId }`,
      [AppPermissions.UpdateTenant],
      {
        module: 'service'
      }
    );
  }

  protected async getActionButtons(): Promise<ActionButton[]> {
    return this.getDefaultActionButtons('core-portal.service.tenants.actions.create', AppPermissions.CreateTenant);
  }
}
