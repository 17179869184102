import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  AppEntityType,
  CustomPropertySetDto,
  CustomPropertyType,
  CustomValueCompleteDto,
  FilledCustomValueDto,
  StereotypeDto,
  UnsubscribeHelper
} from '@nexnox-web/core-shared';
import {BehaviorSubject, NEVER, Observable} from 'rxjs';
import {ModelValid} from '../../../models';
import {cloneDeep, isEqual, uniqBy} from 'lodash';
import {distinctUntilChanged, filter, map, mergeMap, tap} from 'rxjs/operators';
import {LocalCustomPropertySetDto, LocalCustomPropertyValue, LocalFilledCustomValue} from '../../models';
import {sortDeep} from '@nexnox-web/lodash';

@Component({
  selector: 'nexnox-web-custom-property-sets-edit',
  templateUrl: './custom-property-sets-edit.component.html'
})
export class CorePortalCustomPropertySetsEditComponent extends UnsubscribeHelper implements OnInit, ModelValid {
  @Input() public stereotypes$: Observable<StereotypeDto[]> = NEVER;
  @Input() public selectedStereotypeId$: Observable<number> = NEVER;
  @Input() public selectedParentId$: Observable<number> = NEVER;
  @Input() public noMarginForLast = false;
  @Input() public inheritance = false;

  @Input() public creating: boolean;
  @Input() public readonly: boolean;
  @Input() public loading: boolean;

  @Output() public modelChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() public validChange: EventEmitter<void> = new EventEmitter<void>();

  public customPropertySets$: Observable<LocalCustomPropertySetDto[]>;
  public isRateable$: Observable<boolean>;
  public modelValidSubject: BehaviorSubject<{ [id: number]: boolean }> = new BehaviorSubject<{
    [p: number]: boolean
  }>(null);

  private _entityModel: any;
  private _model: LocalCustomPropertyValue;

  @Input()
  public set model(model: any) {
    this._entityModel = cloneDeep(model);
    this._model = {};
    if (this._entityModel?.customPropertyValues?.length) {
      const customPropertyValues = this.prepareModel(model.customPropertyValues);
      for (const customPropertyValue of customPropertyValues) {
        this._model[customPropertyValue.propertyId] = customPropertyValue;
      }
    }
  }

  public getModel(): LocalCustomPropertyValue {
    return this._model;
  }

  public ngOnInit(): void {
    const selectedStereotype$ = this.stereotypes$.pipe(
      filter(stereotypes => Boolean(stereotypes) && Boolean(stereotypes.length)),
      mergeMap(stereotypes => this.selectedStereotypeId$.pipe(
        map(stereotypeId => stereotypes.find(stereotype => stereotype.stereotypeId === stereotypeId)),
        filter(selectedStereotype => Boolean(selectedStereotype))
      ))
    );

    this.customPropertySets$ = selectedStereotype$.pipe(
      distinctUntilChanged((a, b) => a.stereotypeId === b.stereotypeId),
      map(selectedStereotype => cloneDeep(selectedStereotype?.customPropertySets ?? [])
        .map(x => ({
          ...x.customPropertySet,
          position: x.position
        }))
        .sort((a, b) => a.position < b.position ? -1 : 1)
        .map(customPropertySet => ({
          ...customPropertySet,
          properties: customPropertySet.properties.sort((a, b) => a.position < b.position ? -1 : 1)
        }))
      ),
      tap(customPropertySets => this.cleanupPropertyValues(customPropertySets))
    );

    this.isRateable$ = selectedStereotype$.pipe(
      map(stereotype => {
        const rateableEntityTypes = [AppEntityType.MissionInspectionReport];
        return rateableEntityTypes.includes(stereotype.entityType);
      })
    );

    this.subscribe(this.customPropertySets$.pipe(
      distinctUntilChanged((a, b) => isEqual(sortDeep(a), sortDeep(b)))
    ), customPropertySets => this.modelValidSubject.next(null));
  }

  public onModelChange(model: FilledCustomValueDto[]): void {
    const newModel = {
      ...this._entityModel,
      customPropertyValues: this.sanitizeModel(uniqBy([
        ...cloneDeep(model),
        ...cloneDeep(Object.values(this._model))
      ], 'propertyId'))
    };

    this.model = newModel;
    this.modelChange.emit(newModel);
  }

  public onModelValid(customPropertySet: LocalCustomPropertySetDto, valid: boolean): void {
    this.modelValidSubject.next({ ...this.modelValidSubject.getValue(), [customPropertySet.position]: valid });
    this.validChange.emit();
  }

  public isModelValid(): boolean {
    const modelValid = this.modelValidSubject.getValue();

    if (!modelValid) {
      return true;
    }

    return Object.keys(modelValid).every(key => modelValid[key]);
  }

  public isOwnModelValid(): boolean {
    return true;
  }

  public trackSetBy(index: number, item: CustomPropertySetDto): number {
    return item.customPropertySetId;
  }

  private cleanupPropertyValues(customPropertySets: CustomPropertySetDto[]): void {
    const customPropertyIds = customPropertySets?.map(cps => cps?.properties?.map(properties => properties.customPropertyId))?.flat() ?? [];
    const cleanModel = {};

    // For a switch between inherited stereotypes, same properties will be kept
    for (const id of customPropertyIds) {
      if (this._model[id]) {
        cleanModel[id] = this._model[id];
      }
    }

    this._model = cleanModel;
    this.onModelChange(Object.values(cleanModel));
  }

  private prepareModel(model: FilledCustomValueDto[]): LocalFilledCustomValue[] {
    return model.map(filledCustomValue => ({
      ...filledCustomValue,
      customValues: filledCustomValue.customValues.map(customValueRelation => {
        const customValueComplete: CustomValueCompleteDto = customValueRelation.customValue as CustomValueCompleteDto;

        return {
          ...customValueRelation,
          customValue: {
            ...customValueComplete,
            value: customValueComplete.isInherited ? customValueComplete.inheritedValue : customValueComplete.ownValue
          }
        };
      })
    }));
  }

  private sanitizeModel(model: LocalFilledCustomValue[]): FilledCustomValueDto[] {
    return model.map(localFilledCustomValue => ({
      ...localFilledCustomValue,
      customValues: localFilledCustomValue.customValues.map(localCustomValueRelation => {
        const localCustomValue = localCustomValueRelation.customValue;
        const newCustomValueRelation = {
          ...localCustomValueRelation,
          customValue: {
            ...localCustomValue,
            ownValue: localCustomValue.isInherited ? localCustomValue.ownValue : localCustomValue.value,
            inheritedValue: localCustomValue.inheritedValue
          }
        };
        delete newCustomValueRelation.customValue.value;

        if (localCustomValue.type === CustomPropertyType.Info) {
          delete newCustomValueRelation.customValue.ownValue;
          delete newCustomValueRelation.customValue.inheritedValue;
        }

        return newCustomValueRelation;
      })
    }));
  }
}
