<ng-template #entityEditTemplate let-title="title">
  <nexnox-web-notification-rules-edit #editComponent
                                      (modelChange)="createModel$.next($event)"
                                      [creating]="true"
                                      [loading]="(loaded$ | async) === false"
                                      [model]="createModel$ | async"
                                      [readonly]="false"
                                      [title]="title">
  </nexnox-web-notification-rules-edit>
</ng-template>

<nexnox-web-entity-overview #overviewComponent
                            [entityEditTemplate]="entityEditTemplate"
                            [entityOverviewBaseComponent]="this"
                            [showSettings]="false"
                            [stereotyped]="false"
                            detailLink="notification-rules"
                            module="settings">
</nexnox-web-entity-overview>
