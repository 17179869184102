import {AppEntityType} from '@nexnox-web/core-shared';
import {createSelector} from '@ngrx/store';
import {selectInventoryNumbersState} from '../../inventory-numbers.selector';
import {CorePortalFeatureInventoryNumberPatternService} from '../../services';
import {InventoryNumberDetailXsStore, InventoryNumberDetailXsStoreState} from "./inventory-number-detail.xs-store";

export interface InventoryNumberDetailStoreState extends InventoryNumberDetailXsStoreState {
}

/* istanbul ignore next */
export const inventoryNumberDetailStore = new InventoryNumberDetailXsStore({
  actionLabel: 'Core Portal - Settings - Inventory Numbers - Inventory Number Detail',
  stateSelector: createSelector(selectInventoryNumbersState, state => state.inventoryNumberDetail),
  entityType: AppEntityType.InventoryNumberPattern,
  serviceType: CorePortalFeatureInventoryNumberPatternService,
  stereotyped: false
});
