<nexnox-web-entity-edit *ngIf="!creating"
                        [entityEditBaseComponent]="this"
                        displayKey="title">
</nexnox-web-entity-edit>

<nexnox-web-tabs *ngIf="creating"
                 [internal]="true"
                 [isTransparent]="true"
                 startTab="entityEdit">
  <!-- Entity Edit Tab -->
  <ng-template [hasError]="!isOwnModelValid()"
               [heading]="title | translate"
               nexnoxWebTabTemplate
               tabId="entityEdit">
    <nexnox-web-entity-edit [entityEditBaseComponent]="this"></nexnox-web-entity-edit>
  </ng-template>

  <!-- Links -->
  <ng-template [hasError]="linksComponent ? !linksComponent.isModelValid() : false"
               [heading]="('tech-portal.subtitles.links' | translate) + (linksComponent ? (linksComponent.linksString$ | async) : '')"
               nexnoxWebTabTemplate
               tabId="links">
    <nexnox-web-tech-links #linksComponent
                           (linksChange)="onLinksChange($event)"
                           [links$]="linksSubject"
                           [loading]="loading"
                           [readonly]="readonly">
    </nexnox-web-tech-links>
  </ng-template>

  <!-- Attachments -->
  <ng-template [hasError]="attachmentsComponent ? !attachmentsComponent.isModelValid() : false"
               [heading]="('tech-portal.subtitles.attachments' | translate) + (attachmentsComponent ? (attachmentsComponent.attachmentsString$ | async) : '')"
               nexnoxWebTabTemplate
               tabId="attachments">
    <nexnox-web-tech-attachments #attachmentsComponent
                                 (attachmentsChange)="onAttachmentsChange($event)"
                                 [attachments$]="attachmentsSubject"
                                 [loading]="loading"
                                 [readonly]="readonly">
    </nexnox-web-tech-attachments>
  </ng-template>
</nexnox-web-tabs>
