import {TechPortalFeatureTicketSettingsStateService} from './services';
import {StateDetailStoreEffects, stateDetailStoreReducer, StateListStoreEffects, stateListStoreReducer} from './stores';

export * from './services';
export * from './stores';

export * from './states.state';
export * from './states.selectors';

export const statesReducers = {
  stateList: stateListStoreReducer,
  stateDetail: stateDetailStoreReducer
};

export const statesEffects = [
  StateListStoreEffects,
  StateDetailStoreEffects
];

export const statesServices = [
  TechPortalFeatureTicketSettingsStateService
];
