import {FieldType, FormlyTemplateOptions} from '@ngx-formly/core';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {CoreSharedImageService} from '@nexnox-web/core-shared';
import {ImageUploadAdapter} from './image-upload-adapter';
import {CORE_STORE_TENANT_ID_SELECTOR} from '@nexnox-web/core-store';
import {MemoizedSelector, select, Store} from '@ngrx/store';
import {TranslateService} from '@ngx-translate/core';

import {
  Bold,
  ClassicEditor,
  type EditorConfig,
  Essentials,
  Heading,
  Image,
  ImageBlock,
  ImageResize,
  ImageUpload,
  Italic,
  List,
  Paragraph,
  Table,
  TableToolbar,
  Underline,
  Undo
} from 'ckeditor5';

import {SimpleVideoPlugin} from './plugins/simple-video/simple-video.plugin';
import {NxMarkPlugin} from './plugins/nx-mark/nx-mark.plugin';

import {default as de} from 'node_modules/ckeditor5/dist/translations/de';
import {default as en} from 'node_modules/ckeditor5/dist/translations/en-gb';

export interface CorePortalFormlyEditorTyping {
  noHTML?: boolean;
  language?: string;
  insertContent$?: Observable<string>;
  focus$?: Observable<boolean>;
  buttons?: any[]; // todo: find the used button typing
}

interface FormlyEditorTemplateOptions extends FormlyTemplateOptions {
  corePortalEditor: CorePortalFormlyEditorTyping;
}

@Component({
  selector: 'nexnox-web-formly-editor',
  templateUrl: './formly-editor.component.html',
  styleUrls: ['./formly-editor.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FormlyEditorComponent extends FieldType implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('textAreaElement') public textArea: ElementRef;

  public declare readonly to: FormlyEditorTemplateOptions;

  public editor = ClassicEditor;
  public config: EditorConfig;
  public isLayoutReadySubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private tenantIdSubscription: Subscription;

  private insertContentSubscription: Subscription;
  private focusSubscription: Subscription;
  private languageSubscription: Subscription;

  constructor(
    private changeDetector: ChangeDetectorRef,
    private translate: TranslateService,
    private imageService: CoreSharedImageService,
    @Inject(CORE_STORE_TENANT_ID_SELECTOR) private tenantIdSelector: MemoizedSelector<any, number>,
    private store: Store<any>
  ) {
    super();
  }

  public ngOnInit(): void {

    this._setTranslations();

    // On language change re-init editor
    if (!this.to.corePortalEditor?.language) {
      this.languageSubscription = this.translate.onLangChange.subscribe(lang => {
        this.isLayoutReadySubject.next(false);
        setTimeout(() => this.ngAfterViewInit());
      })
    }
  }

  public ngAfterViewInit(): void {

    let appLanguage: string;
    let editorLanguage: string;

    if (this.to.corePortalEditor?.language) {
      appLanguage = this.to.corePortalEditor.language;
    } else {
      appLanguage = this.translate?.currentLang ?? 'de';
    }

    switch (appLanguage) {
      case 'en-US':
        editorLanguage = 'en-gb';
        break;
      default:
        editorLanguage = 'de';
        break;
    }

    this.config = {
      language: editorLanguage,
      toolbar: {
        shouldNotGroupWhenFull: false,
        items: [
          'heading',
          '|',
          'bold',
          'italic',
          'underline',
          'nxMark',
          '|',
          'bulletedList',
          'numberedList',
          // 'outdent',
          // 'indent',
          '|',
          // 'link',
          'insertTable',
          'uploadImage',
          'simpleVideo',
          '|',
          'undo',
          'redo'
        ],
      },
      balloonToolbar: [
        'heading',
        '|',
        'bold',
        'italic',
        'underline',
        'nxMark',
        '|',
        'bulletedList',
        'numberedList'
      ],
      plugins: [
        SimpleVideoPlugin,
        NxMarkPlugin,
        // AccessibilityHelp,
        // Autosave,
        Bold,
        // CloudServices,
        Essentials,
        Heading,
        Image,
        ImageBlock,
        // ImageInline,
        ImageResize,
        // ImageStyle,
        // ImageToolbar,
        ImageUpload,
        // Indent,
        // IndentBlock,
        Italic,
        // Link,
        List,
        Paragraph,
        // PasteFromOffice,
        // SelectAll,
        Table,
        // TableCaption,
        // TableCellProperties,
        // TableColumnResize,
        // TableProperties,
        TableToolbar,
        Underline,
        Undo
      ],
      heading: {
        options: [
          {
            model: 'paragraph',
            title: 'Paragraph',
            class: 'ck-heading_paragraph'
          },
          {
            model: 'heading1',
            view: 'h1',
            title: 'Heading 1',
            class: 'ck-heading_heading1'
          },
          {
            model: 'heading2',
            view: 'h2',
            title: 'Heading 2',
            class: 'ck-heading_heading2'
          },
          {
            model: 'heading3',
            view: 'h3',
            title: 'Heading 3',
            class: 'ck-heading_heading3'
          },
          {
            model: 'heading4',
            view: 'h4',
            title: 'Heading 4',
            class: 'ck-heading_heading4'
          },
          {
            model: 'heading5',
            view: 'h5',
            title: 'Heading 5',
            class: 'ck-heading_heading5'
          },
          {
            model: 'heading6',
            view: 'h6',
            title: 'Heading 6',
            class: 'ck-heading_heading6'
          }
        ]
      },
      table: {
        contentToolbar: [
          'tableColumn',
          'tableRow',
          'mergeTableCells',
          // 'tableProperties',
          // 'tableCellProperties'
        ]
      },
      image: {
        upload: {
          types: [
            'jpeg', 'png'
          ]
        },
        resizeUnit: 'px',
        // toolbar: ['imageStyle:inline', 'imageStyle:wrapText', 'imageStyle:breakText']
      },
      // link: {
      //   addTargetToExternalLinks: true,
      //   defaultProtocol: 'https://',
      //   decorators: {
      //     toggleDownloadable: {
      //       mode: 'manual',
      //       label: 'Downloadable',
      //       attributes: {
      //         download: 'file'
      //       }
      //     }
      //   }
      // }
    };

    this.isLayoutReadySubject.next(true);
    this.changeDetector.detectChanges();
  }

  /* istanbul ignore next */
  public ngOnDestroy(): void {
    if (this.tenantIdSubscription && !this.tenantIdSubscription.closed) {
      this.tenantIdSubscription.unsubscribe();
    }

    if (this.insertContentSubscription && !this.insertContentSubscription.closed) {
      this.insertContentSubscription.unsubscribe();
    }

    if (this.focusSubscription && !this.focusSubscription.closed) {
      this.focusSubscription.unsubscribe();
    }

    if (this.languageSubscription && !this.languageSubscription.closed) {
      this.languageSubscription.unsubscribe();
    }
  }

  /* istanbul ignore next */
  public onReady(editor: ClassicEditor): void {

    this._configurePlugins(editor);

    // insertContent$
    this._subscribeToInsertContent(editor);

    // focus$
    if (this.to.corePortalEditor?.focus$) {
      // Unsubscribe here if previously subscribed
      if (this.focusSubscription?.unsubscribe) {
        this.focusSubscription.unsubscribe();
      }
      // Logic
      this.focusSubscription = this.to.corePortalEditor.focus$.subscribe(() => {
        editor.editing.view.focus();
      });
    }
  }

  private _subscribeToInsertContent(editor?: ClassicEditor): void {
    if (this.to.corePortalEditor?.insertContent$) {
      if (this.insertContentSubscription?.unsubscribe) {
        this.insertContentSubscription.unsubscribe();
      }
      this.insertContentSubscription = this.to.corePortalEditor.insertContent$.subscribe((content) => this._insertContent(content, editor));
    }
  }

  private _insertContent(content: string, editor?: ClassicEditor): void {
    if (editor) {
      // Insert content at cursor (ckeditor)
      editor.model.change(writer => writer.insertText(content, editor.model.document.selection.getFirstPosition()));
    }
    if (this.textArea) {
      // Insert content at cursor (text area no-html)
      const start = this.textArea?.nativeElement?.selectionStart ?? 0;
      const end = this.textArea?.nativeElement?.selectionEnd ?? 0;
      const value = this.formControl.value ?? '';
      const newValue = `${ value.substring(0, start) }${ content }${ value.substring(end, value.lenght) }`;
      this.formControl.setValue(newValue.trim());
    }
  }

  /* istanbul ignore next */
  private _configurePlugins(editor: ClassicEditor): void {
    this.tenantIdSubscription = this.store.pipe(select(this.tenantIdSelector)).subscribe(tenantId => {
      editor.plugins.get('FileRepository').createUploadAdapter = (loader: any) =>
        new ImageUploadAdapter(loader, this.imageService, tenantId);
    });
  }

  private _setTranslations(): void {

    if (!window.CKEDITOR_TRANSLATIONS) {
      window.CKEDITOR_TRANSLATIONS = {};
    }

    // Assign default translations
    window.CKEDITOR_TRANSLATIONS['de'] = de['de'];
    window.CKEDITOR_TRANSLATIONS['en-gb'] = en['en-gb'];

    // Assign plugin translations
    const german = window.CKEDITOR_TRANSLATIONS['de'];

    // Add nxMark translations
    Object.assign(german.dictionary, {
      'Mark': 'Markieren',
      'Green marker': 'Grüner Marker',
      'Red marker': 'Roter Marker',
      'Remove mark': 'Marker entfernen'
    });

    // Add simpleVideo translations
    Object.assign(german.dictionary, {
      'Insert video': 'Video einfügen',
      'Video URL': 'Video Adresse',
      'Paste the URL in the input.': 'Adresse in das Eingabefeld einfügen. Youtube.com, Sharepoint oder Link zu einer mp4-Datei.',
      'The URL must not be empty.': 'Die Adresse darf nicht leer sein.',
      'The URL must be a valid URL.': 'Die Adresse muss eine gültige Adresse sein.'
    });
  }
}
