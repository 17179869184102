import {
  ApiNotificationService,
  AppEntityType,
  AppPermissions,
  ContextCloak,
  ControllerOperationId,
  CORE_SHARED_ENVIRONMENT,
  CoreSharedApiBaseService,
  CoreSharedExportService,
  CoreSharedLocalStorageService,
  CoreSharedModalService,
  DataTableColumnType,
  DataTableCustomColumnDto,
  DataTableDto,
  DataTableFilterDto,
  DataTableTransferColumnDto,
  DataTableViewType,
  Environment,
  Filter,
  FilterDto,
  FilterOperations,
  FilterOperators,
  FilterTypes,
  mapDatatableFilterToFilter,
  MonitorSet,
  Paging,
  SortObject,
  StereotypeDto,
  TenantInfoDto,
  UnsubscribeHelper
} from '@nexnox-web/core-shared';
import {
  Directive,
  EventEmitter,
  HostListener,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {
  BehaviorSubject,
  combineLatest,
  combineLatestWith,
  firstValueFrom,
  interval,
  lastValueFrom,
  Observable,
  of
} from 'rxjs';
import {select, Store} from '@ngrx/store';
import {
  ActionButton,
  CorePortalActionBarService,
  CorePortalContactService,
  CorePortalPageTitleService,
  CorePortalPermissionService,
  CorePortalStereotypeService,
  ErrorService
} from '../../../../../services';
import {
  PagedEntitiesXsStore,
  PagedEntitiesXsStoreCreateOneSuccessPayload,
  PagedEntitiesXsStoreEntity,
  PagedEntitiesXsStoreGetPageParentPayload
} from '@nexnox-web/core-store';
import {
  ApplyFilterPayload,
  DatatableActionButton,
  DatatableHeaderAction,
  DatatableLoadPagePayload,
  DatatablePreColumn,
  DatatableTableColumnType,
  DatatableTableColumnTyping
} from '../../../entity-datatable/models';
import {EntityData} from '../../../models';
import {authStore, headerStore} from '../../../../../store';
import {faPlus} from '@fortawesome/free-solid-svg-icons/faPlus';
import {delay, distinctUntilChanged, map, mergeMap, pairwise, startWith, switchMap, take} from 'rxjs/operators';
import {CorePortalEntityEditBaseComponent} from '../../../entity-edit';
import {faTimesCircle} from '@fortawesome/free-solid-svg-icons/faTimesCircle';
import {ActivatedRoute, PRIMARY_OUTLET, Router} from '@angular/router';
import {cloneDeep, flatten, isFunction, isNull, isUndefined, uniq} from 'lodash';
import {faMinus} from '@fortawesome/free-solid-svg-icons/faMinus';
import {CorePortalCardboxAction} from '../../../../cardbox/components';
import {Actions} from '@ngrx/effects';
import {CorePortalDatatableColumnService} from '../../../entity-datatable';
import {CompositeMapper} from '@azure/ms-rest-js';
import {
  CORE_PORTAL_DATATABLE_PREDEFINED_VIEWS_CONFIG,
  CORE_PORTAL_DATATABLE_STANDARD_CONFIG,
  CorePortalDatatablePredefinedViewConfig,
  CorePortalDatatableStandardConfig,
  PredefinedDatatableView
} from '../../../../../tokens';
import {CorePortalTenantRouter} from '../../../../../router-overrides';
import {faPencilAlt} from '@fortawesome/free-solid-svg-icons/faPencilAlt';
import {faExternalLinkAlt} from '@fortawesome/free-solid-svg-icons/faExternalLinkAlt';
import {Dictionary} from '@ngrx/entity';
import dayjs from 'dayjs';
import {
  CorePortalEntityActionsFacade,
  CorePortalEntitySelectorsFacade,
  CorePortalXsStoreEntityActionsFacade,
  CorePortalXsStoreEntitySelectorsFacade
} from '../../../facades';
import {CorePortalLinkDto} from "./../../../../../model/link.model";
import {TranslateService} from "@ngx-translate/core";

export interface DeleteEntityModel {
  titleKey: string;
  descriptionKey: string;
  confirmKey?: string;
  deletePermission: AppPermissions;
}

@Directive()
export abstract class CorePortalEntityOverviewBaseComponent<E extends object, M extends object = E> extends UnsubscribeHelper implements OnInit, OnDestroy {
  /**
   * If enabled, will disable most behaviour related to routing, like subscribing to the current route. Will also disable setting the
   * page title, action bar actions and creating new entities.
   *
   * @default false
   */
  @Input() public isEmbedded = false;

  /**
   * If enabled and {@link isEmbedded} is enabled, will route to detail after creation (If {@link canCreate} is enabled of course).
   *
   * @default false
   */
  @Input() public canRouteToDetail = false;

  /**
   * If enabled, will prevent routing to detail after creation in any context.
   *
   * @default false
   */
  @Input() public noRouteToDetail = false;

  /**
   * If enabled, will stay in edit/creation mode after creation. Only if {@link isEmbedded} is enabled.
   *
   * @default false
   */
  @Input() public keepEditOnCreateSuccess = false;

  /**
   * The link to detail. If {@link module} is not set, will use the current module instead.
   */
  @Input() public detailLink: string;

  /**
   * The module used with {@link detailLink}.
   */
  @Input() public module: string;

  /**
   * The parent ids sent to the entity service when fetching or executing an action.
   *
   * @default Empty
   */
  @Input() public parentIds: Array<string | number> = [];

  /**
   * If enabled, allows creation of entities if {@link isEmbedded} is enabled.
   *
   * @default false
   */
  @Input() public canCreate = false;

  /**
   * If enabled, will re-fetch the page when the readonly mode changes. Only relevant if {@link isEmbedded} is enabled.
   *
   * @default true
   */
  @Input() public getEntityOnModeChange = true;

  /**
   * If enabled, will not fetch the page initially on load.
   *
   * @default false
   */
  @Input() public noInitialGet = false;

  /**
   * If enabled, will disable the cache timeout and re-fetch.
   *
   * @default false
   */
  @Input() public noCacheTimeout = false;

  /**
   * A custom datatable config to use instead of one from local storage.
   */
  @Input() public customDatatableConfig: DataTableDto;

  /**
   * If enabled, will disable the view select in datatable settings.
   *
   * @default false
   */
  @Input() public disableSettingsViewSelect: boolean;

  /**
   * If enabled, will disable sorting by optional columns.
   *
   * @default false
   */
  @Input() public disableOptionalSorting: boolean;

  /**
   * If enabled, will disable translation of title and write the declared string instead
   *
   * @default false
   */
  @Input() public disableTitleTranslation = false;

  /**
   * Will use the provided filters instead of the saved filters from the store.
   */
  @Input() public savedFilters$: Observable<Filter[]>;

  /**
   * Will use the provided filters instead of the saved filters from the store for exporting.
   */
  @Input() public filtersForExport$: Observable<DataTableFilterDto[]>;

  /**
   * If enabled, allows deletion of entities.
   *
   * @default true
   */
  @Input() public canDelete = true;

  /**
   * Descentand id is used for csv exports, that have a parent object (eg: Missions in a resource)
   *
   * @default undefined
   */
  @Input() public descendantId: number;

  /**
   * A custom actions facade instead of the default Xs Store one.
   */
  @Input() public customActionsFacade: CorePortalEntityActionsFacade<E, M>;

  /**
   * A custom selectors facade instead of the default Xs Store one.
   */
  @Input() public customSelectorsFacade: CorePortalEntitySelectorsFacade<E, M>;
  @ViewChild('editComponent') public editComponent: CorePortalEntityEditBaseComponent<M>;
  @Output() public datatableConfigChange = new EventEmitter<DataTableDto>();
  /**
   * Allows children to apply a single filter and remove it
   */
  @Output() public applyFilter = new EventEmitter<ApplyFilterPayload>();
  @Output() public clearFilters = new EventEmitter<void>();
  public entities$: Observable<PagedEntitiesXsStoreEntity<E, M>[]>;
  public paging$: Observable<Paging>;
  public loading$: Observable<boolean>;
  public loadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public loaded$: Observable<boolean>;
  public loadedAt$: Observable<string>;
  public appendLoading$: Observable<boolean>;
  public hasError$: Observable<boolean>;
  public stereotypes$: Observable<StereotypeDto[]>;
  public stereotypesLoaded$: Observable<boolean>;
  public stateFilters$: Observable<Filter[]>;
  public stateSortObject$: Observable<SortObject>;
  public activeTenant$: Observable<TenantInfoDto>;
  public entityData$: Observable<EntityData>;
  public isCreateVisible$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public createModel$: BehaviorSubject<M> = new BehaviorSubject<M>({} as any);
  public readonly$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  /**
   * Is used when creating an entity.
   *
   * @default null
   */
  public createTitle: string = null;
  /**
   * Default excluded columns in addition to the ones from the datatable config.
   *
   * @default Empty
   */
  public excludedColumns: string[] = [];
  /**
   * Default columns to display in addition to the ones from the datatable config.
   *
   * @default Empty
   */
  public defaultColumns: string[] = [];
  /**
   * Default column typings in addition to the ones from the datatable config.
   *
   * @default Empty
   */
  public defaultColumnTypings: DatatableTableColumnTyping[] = [];
  /**
   * If enabled, will clear the store on component destruction.
   *
   * @default false
   */
  public shouldClear = false;
  /**
   * If enabled, allows saving and applying of datatable views.
   *
   * @default false
   */
  public enableViews = false;
  /**
   * The datatable view type used to display a list table, a map or a gantt diagram.
   *
   * @default null
   */
  public viewType: DataTableViewType;
  /**
   * If enabled, it hides tabs.
   *
   * @default false
   */
  public hideTabs = false;
  /**
   * The page operation used instead of the entity type to identify the datatable.
   */
  public pageOperation: ControllerOperationId = null;
  /**
   * Custom request body to be appended to export function
   */
  public requestBodyForExport: any = null;
  /**
   * The name of the datatable config to load from the global config
   */
  public datatableConfigName: string;
  /**
   * The templates needed for filters or other parts of the datatable requiring custom templates. Custom templates are needed for
   * select boxes in filters that need custom templates, for example an additional property being displayed.
   */
  public templates: Dictionary<TemplateRef<any>> = {};
  /**
   *  Workaround prevents unsaved changes dialog from getting called twice
   */
  public isDeactivateUnsavedChangesModal = false;
  public abstract title: string;
  public abstract idProperty: string;
  public abstract displayProperty: string;
  public route: ActivatedRoute;
  public predefinedDatatableViews: PredefinedDatatableView[];
  public monitors: MonitorSet<M>;
  public bypassMonitorsSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  /**
   * Is used to identify the component in local storage.
   */
  public abstract componentId: string;
  /**
   * Is used to return to origin container via a Backlink
   */
  protected goBack: () => void;
  /**
   * If enabled, will allow inheritance.
   *
   * @default false
   */
  protected inheritance = false;
  /**
   * Fields to override when {@link inheritance} is enabled and a preview has been fetched.
   *
   * @default Empty
   */
  protected previewFields: string[] = [];
  /**
   * Always fetch entities regardless of other configurations.
   */
  protected forceGetEntities = false;
  protected store: Store<any>;
  protected actionBarService: CorePortalActionBarService;
  protected modalService: CoreSharedModalService;
  protected permissionService: CorePortalPermissionService;
  protected actions$: Actions;
  protected errorService: ErrorService;
  protected router: Router;
  protected tenantRouter: CorePortalTenantRouter;
  protected datatableColumnService: CorePortalDatatableColumnService;
  protected entityService: CoreSharedApiBaseService;
  protected localStorageService: CoreSharedLocalStorageService;
  protected environment: Environment;
  protected pageTitleService: CorePortalPageTitleService;
  protected exportService: CoreSharedExportService;
  protected entityActionsFacade: CorePortalEntityActionsFacade<E, M>;
  protected entitySelectorsFacade: CorePortalEntitySelectorsFacade<E, M>;
  protected apiNotificationService: ApiNotificationService;
  protected defaultSortOptions: SortObject = null;
  protected defaultFilter: Filter[] = [];
  protected datatableConfig: CorePortalDatatableStandardConfig;
  protected datatablePredefinedViewConfig: CorePortalDatatablePredefinedViewConfig;
  protected detailLinkAdditionalSubject: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  protected contactService: CorePortalContactService
  protected translate: TranslateService

  protected constructor(
    protected injector: Injector,
    private entityStore: PagedEntitiesXsStore<E, M>,
    public serializedNameOrMapper: string | CompositeMapper,
    public entityType: AppEntityType
  ) {
    super();

    this.store = injector.get(Store) as Store<any>;
    this.actionBarService = injector.get(CorePortalActionBarService);
    this.modalService = injector.get(CoreSharedModalService);
    this.permissionService = injector.get(CorePortalPermissionService);
    this.route = injector.get(ActivatedRoute);
    this.actions$ = injector.get(Actions);
    this.errorService = injector.get(ErrorService);
    this.router = injector.get(Router);
    this.tenantRouter = injector.get(CorePortalTenantRouter);
    this.datatableColumnService = injector.get(CorePortalDatatableColumnService);
    this.localStorageService = injector.get(CoreSharedLocalStorageService);
    this.environment = injector.get(CORE_SHARED_ENVIRONMENT);
    this.pageTitleService = injector.get(CorePortalPageTitleService);
    this.exportService = injector.get(CoreSharedExportService);
    this.contactService = injector.get(CorePortalContactService);
    this.translate = injector.get(TranslateService);

    if (this.entityStore) this.entityService = injector.get(entityStore.serviceType);

    this.datatableConfig = injector.get(CORE_PORTAL_DATATABLE_STANDARD_CONFIG);
    this.datatablePredefinedViewConfig = injector.get(CORE_PORTAL_DATATABLE_PREDEFINED_VIEWS_CONFIG);

    this.activeTenant$ = this.store.pipe(select(authStore.selectors.selectActiveTenant));

    this.monitors = new MonitorSet<M>(injector, this.createModel$.asObservable(), this.loadingSubject, this.bypassMonitorsSubject);
  }

  public get readonly(): boolean {
    return this.readonly$.getValue();
  }

  @Input()
  public set readonly(readonly: boolean) {
    this.readonly$.next(readonly);
    this.bypassMonitorsSubject.next(readonly);
  }

  public ngOnInit(): void {
    this.bypassMonitorsSubject.next(true);
    this.setDefaults();
    this.init();
    this.subscribeToActions();
    this.subscribeToFragment();
    this.initMonitors();
  }

  public async init(): Promise<void> {
    const hasState = await this.loaded$.pipe(take(1)).toPromise();
    const loadedAt = await this.loadedAt$.pipe(take(1)).toPromise();
    const expired = !this.noCacheTimeout && loadedAt ? dayjs().isAfter(dayjs(loadedAt).add(15, 'minutes')) : false;
    const queryParams = await this.route.queryParams.pipe(take(1)).toPromise();

    if (!this.isEmbedded) {
      this.store.dispatch(headerStore.actions.setTitle({ title: this.title }));
      this.pageTitleService.setPageTitle(this.title);
      this.getActionButtons().then(actionButtons => this.actionBarService.setActions(actionButtons));

      if ((!this.noInitialGet && (!hasState || expired || queryParams?.reload)) || this.forceGetEntities) {
        this.getEntities();
      }
    } else if (
      (
        this.isEmbedded && !this.getEntityOnModeChange && !this.noInitialGet && (!hasState || expired || queryParams?.reload) ||
        this.forceGetEntities
      )
    ) {
      this.getEntities();
    }

    // 15 minute cache timeout
    if (!this.noCacheTimeout) this.subscribe(interval(60000 * 15), () => this.getEntities());
  }

  @HostListener('window:beforeunload', ['$event'])
  public async onBeforeWindowUnload(event: BeforeUnloadEvent): Promise<void> {
    if (!this.environment.production) {
      return;
    }

    if (!this.readonly) {
      event.returnValue = true;
    }
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();

    if (!this.isEmbedded) {
      this.actionBarService.reset();
    }

    if (this.shouldClear || this.isEmbedded) {
      this.entityActionsFacade.clear();
    }
  }

  public onLoadPage(payload: DatatableLoadPagePayload): void {
    this.getEntities(
      payload.pageNumber,
      payload.sortOptions,
      payload.filters ?? [],
      payload.pageSize
    );
  }

  public onBacklinkChange(backlink: CorePortalLinkDto): void {
    this.goBack = () => this.tenantRouter.navigate(backlink?.commands, {
      module: backlink?.module,
      fragment: backlink?.fragment
    });
  }

  public onNavigateToTab(commands: any[]): Promise<boolean> {
    return this.router.navigate(commands, { relativeTo: this.route, preserveFragment: true });
  }

  public onCreate(): void {
    this.isDeactivateUnsavedChangesModal = true;
    this.createOne(cloneDeep(this.editComponent?.getSanitizedModel() ?? this.createModel$.getValue()));
  }

  public onDelete(entity: E): void {
    const { deletePermission, titleKey, descriptionKey, confirmKey } = this.getDeleteEntityModel();
    this.permissionService.hasPermission(deletePermission).then(hasPermission => {
      if (hasPermission) {
        this.modalService.showConfirmationModal(titleKey, descriptionKey, 'error', confirmKey ?? titleKey)
          .then(() => this.deleteEntity(entity))
          .catch(() => null);
      }
    });
  }

  public getColumnTypings(stereotyped: boolean = true): DatatableTableColumnTyping[] {
    const columns = this.datatableColumnService
      .getDataColumns(this.serializedNameOrMapper, this.excludedColumns)
      .filter(x => x.serializedName);

    return (this.defaultColumnTypings).concat(...(stereotyped ? [
      ...flatten(columns.filter(x => x.serializedName === 'StereotypeSimpleListDto').map(x => this.getStereotypeColumnTyping(x, x.name)))
    ] : []));
  }

  public getRowActionButtons(): DatatableActionButton[] {
    return [];
  }

  public getAdditionalHeaderActions(): DatatableHeaderAction[] {
    return [];
  }

  public getDeleteEntityModel(): DeleteEntityModel {
    return null;
  }

  public hasPermission$(permission: AppPermissions): Observable<boolean> {
    return this.permissionService.hasPermission$(permission);
  }

  public createStandaloneHeaderActions(label?: string, permission?: AppPermissions): CorePortalCardboxAction[] {
    return [{
      icon: faPlus,
      label: label ?? 'core-shared.shared.fields.create',
      tooltip: label ?? 'core-shared.shared.fields.create',
      buttonSize: 'lg',
      class: 'p-button p-button-primary',
      shouldShow: () => permission ? this.hasPermission$(permission) : of(true),
      callback: () => this.gotoStandaloneCreate()
    }]
  }

  public hasUnsavedChanges(): boolean {
    return this.isCreateVisible$.getValue() ? !this.editComponent?.isFormPristine() : false;
  }

  protected initMonitors(): void {
    this.subscribeToMonitorBypass();
    this.subscribe<M>(this.monitors.modifiedModel$, (modified) => {
      this.editComponent.onModelChange(modified);
    });
  }

  protected subscribeToMonitorBypass(): void {
    // Bypass monitors on loading and readonly
    this.subscribe(
      combineLatest([this.readonly$.asObservable(), this.loading$, this.createModel$]).pipe(
        map(([readonly, loading, model]) => (!readonly && !this.hasUnsavedChanges() && !this.isCreateVisible$.value) || !model || loading || readonly),
        distinctUntilChanged()
      ), (bypass) => this.bypassMonitorsSubject.next(bypass)
    );
  }

  protected gotoStandaloneCreate(): void {
    const url = this.router.parseUrl(this.router.url).root.children[PRIMARY_OUTLET].segments.map((s) => s.path);
    this.router.navigate([...url, 'create']);
  }

  protected setDefaults(): void {
    this.entityActionsFacade = this.customActionsFacade ?? new CorePortalXsStoreEntityActionsFacade(this.injector, this.entityStore);
    this.entitySelectorsFacade = this.customSelectorsFacade ?? new CorePortalXsStoreEntitySelectorsFacade(this.injector, this.entityStore);

    this.entities$ = this.entitySelectorsFacade.selectEntities();
    this.paging$ = this.entitySelectorsFacade.selectPaging();

    // Store loading combined with export loading and loading subject
    this.loading$ = this.entitySelectorsFacade.selectLoading().pipe(
      combineLatestWith(this.exportService.getLoading(), this.loadingSubject),
      map(([a, b, c]) => a || b || c)
    );

    this.loaded$ = this.entitySelectorsFacade.selectLoaded();
    this.loadedAt$ = this.entitySelectorsFacade.selectLoadedAt();
    this.appendLoading$ = this.entitySelectorsFacade.selectAppendLoading();
    this.hasError$ = this.entitySelectorsFacade.selectHasError();
    this.stereotypes$ = this.entitySelectorsFacade.selectStereotypes();
    this.stereotypesLoaded$ = this.entitySelectorsFacade.selectStereotypesLoaded();
    this.stateFilters$ = this.entitySelectorsFacade.selectFilters();
    this.stateSortObject$ = this.entitySelectorsFacade.selectSort();
    this.entityData$ = this.entitySelectorsFacade.selectEntityData();

    if (this.datatableConfigName && this.datatableConfig && this.datatableConfig[this.datatableConfigName]) {
      const config = this.datatableConfig[this.datatableConfigName];
      this.excludedColumns = [...this.excludedColumns, ...config.excludedColumns];
      this.defaultColumns = [...this.defaultColumns, ...config.defaultColumns];
      this.defaultColumnTypings = [...this.defaultColumnTypings, ...config.columnTypings];
    }

    if (this.componentId && this.datatablePredefinedViewConfig && this.datatablePredefinedViewConfig[this.componentId]) {
      this.predefinedDatatableViews = this.datatablePredefinedViewConfig[this.componentId];
    }
  }

  /* istanbul ignore next */
  protected getStereotypeColumnTyping(column: DatatablePreColumn, key: string): DatatableTableColumnTyping[] {
    return [
      {
        key,
        name: key,
        type: DatatableTableColumnType.REFERENCE,
        idKey: 'stereotypeId',
        displayKey: 'name',
        service: CorePortalStereotypeService,
        permissions: [AppPermissions.ReadStereotype],
        filters$: of(column.entityType ? [
          {
            property: 'entityType',
            operator: FilterOperators.Equal,
            type: FilterTypes.DataTransferObject,
            value: column.entityType?.toString()
          },
          {
            property: 'isArchived',
            operator: FilterOperators.Equal,
            type: FilterTypes.DataTransferObject,
            value: 'false'
          }
        ] : [])
      },
      {
        key: `${ key }.isArchived`,
        name: `${ key }.is-archived`,
        type: DatatableTableColumnType.BOOLEAN
      }
    ];
  }

  /* istanbul ignore next */
  protected async getActionButtons(): Promise<ActionButton[]> {
    return [];
  }

  /* istanbul ignore next */
  protected getDefaultActionButtons(createKey: string, createPermission: AppPermissions): ActionButton[] {
    return [
      {
        label: 'core-portal.core.general.cancel',
        type: 'button',
        class: 'btn-outline-secondary',
        icon: faTimesCircle,
        shouldShow: () => this.isCreateVisible$,
        callback: () => this.onCancelAction()
      },
      {
        label: createKey,
        type: 'button',
        class: 'btn-primary',
        permission: createPermission,
        icon: faPlus,
        shouldShow: () => this.activeTenant$.pipe(map(activeTenant => Boolean(activeTenant))),
        callback: () => this.onCreateAction(),
        isDisabled: () => this.isCreateVisible$.asObservable().pipe(
          delay(0),
          mergeMap(isCreateVisible => this.createModel$.asObservable().pipe(
            map(() => isCreateVisible && (!this.editComponent || !this.editComponent.isModelValid()))
          ))
        ),
        isLoading: () => this.loading$
      }
    ];
  }

  /* istanbul ignore next */
  protected getDefaultRowActionButtons(
    tooltip: string,
    link: (row: M) => string,
    permissions: AppPermissions[] = [],
    options?: {
      module?: string;
      onClick?: (row: M) => void,
      show?: (row: M) => boolean
    }
  ): DatatableActionButton[] {
    return [
      {
        tooltip,
        icon: faPencilAlt,
        link: this.detailLink ? (row: M) => `${ this.detailLink }/${ row[this.idProperty] }` : link,
        module: this.module ?? options?.module,
        onClick: options?.onClick,
        fragment: 'edit',
        show: options?.show,
        permissions
      },
      {
        tooltip: 'core-shared.shared.actions.new-tab',
        icon: faExternalLinkAlt,
        link: this.detailLink ? (row: M) => `${ this.detailLink }/${ row[this.idProperty] }` : link,
        module: this.module ?? options?.module,
        target: '_blank'
      }
    ];
  }

  protected getEmbeddedRowActionButtons(): DatatableActionButton[] {
    return [
      {
        id: this.entityStore?.actions?.addOneToParent?.type ?? 'addOneToParent',
        icon: faPlus,
        tooltip: 'core-shared.shared.table.tooltip.add',
        style: 'p-button-primary',
        onClick: row => this.entityActionsFacade.addOneToParent({
          id: row[this.idProperty],
          parentIds: this.parentIds
        }),
        show: row => !this.readonly$.getValue() && !row.isInRelation,
        isolate: true
      },

      {
        id: this.entityStore?.actions?.removeOneFromParent?.type ?? 'removeOneFromParent',
        icon: faMinus,
        tooltip: 'core-shared.shared.table.tooltip.remove',
        style: 'p-button-secondary',
        onClick: row => this.entityActionsFacade.removeOneFromParent({
          id: row[this.idProperty],
          parentIds: this.parentIds
        }),
        show: row => !this.readonly$.getValue() && row.isInRelation,
        isolate: true
      }
    ];
  }

  /* istanbul ignore next */
  protected getCreateEntityCardboxHeaderActions(
    createKey: string,
    createPermission: AppPermissions
  ): CorePortalCardboxAction[] {
    return [
      {
        label: createKey,
        icon: faPlus,
        class: 'btn-outline-primary',
        permission: createPermission,
        shouldShow: () => this.readonly$.pipe(
          map(readonly => this.canCreate && !readonly)
        ),
        isDisabled: () => this.readonly$.pipe(
          delay(0),
          switchMap(() => this.createModel$.asObservable().pipe(
            delay(0),
            map(() => this.editComponent ? !this.editComponent.isModelValid() : true)
          ))
        ),
        isLoading: () => this.loading$,
        callback: () => {
          this.onCreate();
        }
      }
    ];
  }

  protected onCreateAction(): void {
    if (this.isCreateVisible$.getValue()) {
      this.onCreate();
    } else {
      window.location.hash = '#create';
    }
  }

  protected onEditAction(): void {
    window.location.hash = '#edit';
  }

  protected async onCancelAction(): Promise<void> {
    const cancel = (): void => {
      if (isFunction(this.goBack)) {
        this.goBack();
      } else {
        window.location.hash = '';
      }
    };

    if (await this.canDeactivate()) {
      this.isDeactivateUnsavedChangesModal = true;
      cancel();
    }
  }

  protected subscribeToActions(): void {
    this.subscribe(this.entitySelectorsFacade.selectCreateOneSuccess(), payload => this.onCreateOneSuccess(payload));
  }

  protected subscribeToFragment(): void {
    this.subscribe(this.route.fragment.pipe(startWith(null), pairwise()), async ([prevFragment, currFragment]) => {

      if (prevFragment === 'create' && !(await this.canDeactivate())) {
        window.location.hash = prevFragment;
        return;
      }

      switch (currFragment) {
        case 'create':
          this.setCreateMode();
          break;
        case 'edit':
          this.setEditMode();
          break;
        case null:
          this.setViewMode();
          break;
        default:
          window.location.hash = '';
          this.setViewMode();
          break;
      }
      this.isDeactivateUnsavedChangesModal = false;
    });
  }

  protected onCreateOneSuccess(payload: PagedEntitiesXsStoreCreateOneSuccessPayload<E, M>): void {
    if ((!this.isEmbedded || this.canRouteToDetail) && !this.noRouteToDetail) {
      if (this.detailLink) {
        this.tenantRouter.navigate([this.detailLink, payload.entity[this.idProperty]], {
          fragment: 'edit',
          module: this.module
        });
      } else {
        this.router.navigate([
          payload.entity[this.idProperty],
          ...this.detailLinkAdditionalSubject.getValue()
        ], {
          relativeTo: this.route,
          fragment: 'edit'
        });
      }
    } else if (this.noRouteToDetail && !this.keepEditOnCreateSuccess) {
      this.router.navigate([], { relativeTo: this.route, fragment: '' });
    } else {
      this.clearCreateModel();
    }
  }

  /**
   * Refreshes datatable with current paging and filtering options
   */
  protected async refreshList(): Promise<void> {
    const paging = await firstValueFrom(this.store.pipe(select(this.entityStore.selectors.selectPaging)));
    const sortObject = await firstValueFrom(this.store.pipe(select(this.entityStore.selectors.selectSortObject)));
    const filters = await firstValueFrom(this.store.pipe(select(this.entityStore.selectors.selectFilters)));
    return this.getEntities(paging?.pageNumber, sortObject, filters, paging?.pageSize);
  }

  /* istanbul ignore next */
  /**
   * Prepare the config and columns and fetch entities.
   *
   * @param page - The page number
   * @param sortOption - The sorting
   * @param filters - The filters
   * @param pageSize - The page size
   * @param append - Whether to append to current entries or not
   * @param datatableConfig - The datatable config
   * @param contextCloak - The context cloaking
   * @param additionalOptionalColumns - Additional optional columns
   */
  protected async getEntities(
    page: number = 1,
    sortOption?: SortObject,
    filters?: FilterDto[],
    pageSize?: number,
    append: boolean = false,
    datatableConfig?: DataTableDto,
    contextCloak?: ContextCloak,
    additionalOptionalColumns: string[] = []
  ): Promise<void> {
    let defaultSortOption = this.defaultSortOptions;
    let dataColumns: string[] = [];
    let stereotypeColumns: number[] = [];
    let optionalColumns: string[] = [];

    const config: DataTableDto = this.customDatatableConfig ?? await this.datatableColumnService.getDatatableConfig(
      this.pageOperation ?? this.entityType,
      this.componentId,
      datatableConfig ?? null,
      !this.enableViews
    );

    const sortColumn = (config?.columns ?? []).find(x => !isUndefined(x.sortOrder) && !isNull(x.sortOrder));
    let sortField;
    if (sortColumn) {
      if (this.datatableColumnService.isDataColumn(sortColumn)) {
        sortField = sortColumn.property;
      } else if (this.datatableColumnService.isStereotypeColumn(sortColumn)) {
        sortField = sortColumn.customPropertyId;
      }

      if (sortField) {
        defaultSortOption = {
          sortField,
          sort: sortColumn.sortOrder
        };
      }
    }

    dataColumns = (config?.columns ?? [])
      .filter(x => x.type === DataTableColumnType.ByTransfer)
      .map(x => (x as DataTableTransferColumnDto).property);
    stereotypeColumns = (config?.columns ?? [])
      .filter(x => x.type === DataTableColumnType.ByCustomProperty)
      .map(x => (x as DataTableCustomColumnDto).customPropertyId);

    optionalColumns = this.datatableColumnService.getDataColumns(this.serializedNameOrMapper, this.excludedColumns)
      .filter(x => Boolean(x.optional) && dataColumns?.findIndex(y => y === x.name) > -1)
      .map(x => x.name);

    let payloadFilters = filters ? filters : (config?.filters ?? []).map(filter => mapDatatableFilterToFilter(filter));

    // Load saved filters on first call (workaround)
    if (!filters?.length && this.savedFilters$ && (await lastValueFrom(this.savedFilters$?.pipe(take(1)), { defaultValue: [] }))?.length > 0) {
      payloadFilters = [...payloadFilters, ...(await lastValueFrom(this.savedFilters$?.pipe(take(1)), { defaultValue: [] }))];
    }


    const payloadSortObject = sortOption ? sortOption : defaultSortOption;

    // !!! workaround against empty config in the local storage to avoid empty columns
    // ToDo: find a solution to store a default local storage datatabledto with app config settings before the user hits the table settings sidebar
    if (config === null && dataColumns.length === 0 && optionalColumns.length === 0) {
      optionalColumns = this.defaultColumns;
    }

    this.entityActionsFacade.getPage({
      pageNumber: page,
      filters: payloadFilters,
      defaultFilters: this.defaultFilter,
      datatableConfig: config,
      sortOption: payloadSortObject,
      stereotypeColumns,
      optionalColumns: uniq([...optionalColumns, ...additionalOptionalColumns]),
      contextCloak,
      pageSize: pageSize ?? (config?.pageSize ?? undefined),
      parent: this.getParentObject(),
      append
    });
  }

  protected setCreateMode(): void {

    if (!this.isEmbedded || this.canCreate) {
      this.isCreateVisible$.next(true);
    }

    this.readonly$.next(false);
    this.bypassMonitorsSubject.next(false);

    if (this.isEmbedded && this.getEntityOnModeChange) {
      this.getEntities().then();
    }
  }

  protected setEditMode(): void {
    this.setCreateMode();
  }

  protected setViewMode(): void {

    if (!this.isEmbedded || this.canCreate) {
      this.isCreateVisible$.next(false);
      this.clearCreateModel();
    }

    this.readonly$.next(true);
    this.bypassMonitorsSubject.next(true);

    if (this.isEmbedded && this.getEntityOnModeChange) {
      this.getEntities().then();
    }
  }

  protected getParentObject(): PagedEntitiesXsStoreGetPageParentPayload {
    if (!this.isEmbedded) {
      return undefined;
    }

    return {
      filterOperation: this.readonly$.getValue() || !this.getEntityOnModeChange ? FilterOperations.Include : FilterOperations.All,
      parentIds: this.parentIds
    };
  }

  protected createOne(model: M): void {
    this.entityActionsFacade.createOne({ model, parentIds: this.parentIds, goBack: this.goBack });
  }

  protected deleteEntity(entity: E): void {
    this.entityActionsFacade.deleteOne({
      id: entity[this.idProperty],
      parentIds: this.parentIds
    });
  }

  /* istanbul ignore next */
  private resetStateAfterCreate(): void {
    if (!this.isEmbedded) {
      window.location.hash = '';
    } else {
      this.clearCreateModel();
    }
  }

  private clearCreateModel(): void {
    this.createModel$.next({} as any);
    this.editComponent?.reset();
  }

  private async canDeactivate(): Promise<boolean> {
    if (!this.editComponent?.isFormPristine() && !this.isDeactivateUnsavedChangesModal) {
      return await this.modalService.promptUnsavedChangesModal();
    } else {
      return true;
    }
  }
}
