import {Pipe, PipeTransform} from '@angular/core';
import {BsLocaleService} from 'ngx-bootstrap/datepicker';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import dayjs from 'dayjs';
import {formatDate} from 'ngx-bootstrap/chronos';

@Pipe({
  name: 'nexnoxWebBsDate'
})
export class BsDatePipe implements PipeTransform {
  constructor(
    private bsLocaleService: BsLocaleService
  ) {
  }

  public static transformDate(bsLocaleService: BsLocaleService, value: dayjs.Dayjs | Date | string,
                              format: string): Observable<string> {
    const date: dayjs.Dayjs = dayjs(value).local();

    return bsLocaleService.locale.asObservable().pipe(
      map(locale => formatDate(date.toDate(), format, locale))
    );
  }

  public transform(value: dayjs.Dayjs | Date | string, format: string): Observable<string> {
    return BsDatePipe.transformDate(this.bsLocaleService, value, format);
  }
}
