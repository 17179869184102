import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {faCheck, faPlus} from "@fortawesome/free-solid-svg-icons";
import {faTrash} from "@fortawesome/free-solid-svg-icons/faTrash";
import {
  CorePortalContactService,
  CorePortalDocumentTemplateService,
  CorePortalFormlyReadonlyTypes,
  CorePortalFormlyReadonlyTyping,
  CorePortalFunctionService,
  CorePortalTextTemplateService
} from "@nexnox-web/core-portal";
import {
  BasicTableColumnType,
  BasicTableConfig,
  CombineOperator,
  ContactControlReceiverDto,
  ControlContentType,
  ControlNotificationDto,
  ControlNotificationType,
  ControlReceiverType,
  DocumentTemplateContextType,
  DocumentTemplateSimpleDto,
  EmailControlReceiverDto,
  Filter,
  FilterDto,
  FilterKind,
  FilterOperators,
  FilterTypes,
  FunctionControlReceiverDto,
  GroupedFilterDto,
  TemplateContextType,
  TemplateSimpleDto,
  UnsubscribeHelper
} from "@nexnox-web/core-shared";
import {FormlyFieldConfig} from "@ngx-formly/core";
import {BindObservable} from "bind-observable";
import {cloneDeep, isEqual} from "lodash";
import {BehaviorSubject, distinctUntilChanged, filter, Observable} from "rxjs";
import {faPencilAlt} from "@fortawesome/free-solid-svg-icons/faPencilAlt";
import {
  controlNotificationReceiverTypeEnumOptions,
  controlNotificationTypeEnumOptions
} from "../../models/control-notification-type-enum.model";
import {map} from "rxjs/operators";

export interface ControlNotificationLocalDto extends ControlNotificationDto {
  type: ControlNotificationType;
  receiver: ContactControlReceiverDto | EmailControlReceiverDto | FunctionControlReceiverDto | any;
  emailSubjectTemplate: TemplateSimpleDto;
  emailContentTemplate: TemplateSimpleDto;
  attachments: DocumentTemplateSimpleDto[];
}

interface NotificationTableDto {
  type: ControlNotificationType;
  receiverType: ControlReceiverType;
  receiver: string;
}

@Component({
  selector: 'nexnox-web-controls-notification-edit',
  templateUrl: 'control-notification-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TechPortalFeatureControlNotificationEditComponent extends UnsubscribeHelper implements OnInit {

  @Input() public loading: boolean;
  @Input() @BindObservable() public readonly: boolean;
  public readonly$!: Observable<boolean>;
  // NOTE: this updates the notificationsSubject automatically
  @Output() public notificationsChanged: EventEmitter<ControlNotificationDto[]> = new EventEmitter<ControlNotificationDto[]>();
  public tableConfig: BasicTableConfig;
  public tableItems$: Observable<ControlNotificationDto[]>;
  public notificationForm: FormGroup;
  public notificationFields: FormlyFieldConfig[];
  public notificationModelSubject: BehaviorSubject<ControlNotificationLocalDto> = new BehaviorSubject<ControlNotificationLocalDto>(null);
  public isEditingNotification: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isEditingNotification$: Observable<boolean>;
  public faPlus = faPlus;
  public faCheck = faCheck;
  public faTrash = faTrash;
  private _editIndex: number;
  private notificationsSubject: BehaviorSubject<ControlNotificationLocalDto[]> = new BehaviorSubject<ControlNotificationLocalDto[]>([]);
  private controlContentTypeSubject: BehaviorSubject<ControlContentType> = new BehaviorSubject<ControlContentType>(ControlContentType.Message);
  private pdfAttachmentContextTypeFilter$: Observable<GroupedFilterDto[]>;
  private subjectContextTypeFilter$: Observable<Filter[]>;
  private contentContextTypeFilter$: Observable<Filter[]>;

  constructor(
    private contactService: CorePortalContactService,
    private functionsService: CorePortalFunctionService,
    private textTemplateService: CorePortalTextTemplateService,
    private documentTemplateService: CorePortalDocumentTemplateService
  ) {
    super();

    this.isEditingNotification$ = this.isEditingNotification.asObservable();
  }

  @Input()
  public set controlContentType(type: ControlContentType) {
    this.controlContentTypeSubject.next(type ?? ControlContentType.Message);
  }

  public get notifications(): ControlNotificationLocalDto[] {
    return this.notificationsSubject.getValue() ?? [];
  }

  @Input()
  public set notifications(notifications: ControlNotificationDto[]) {
    this.notificationsSubject.next((notifications ?? []) as ControlNotificationLocalDto[]);
  }

  public ngOnInit(): void {
    this.tableItems$ = this.notificationsSubject.asObservable().pipe(
      map((notifications: ControlNotificationLocalDto[]) => this._mapNotificationsToTableItems(notifications))
    );

    this.contentContextTypeFilter$ = this.controlContentTypeSubject.pipe(map(type => this._getContentContextTypeFilter(type)));
    this.subjectContextTypeFilter$ = this.controlContentTypeSubject.pipe(map(type => this._getSubjectContextTypeFilter(type)));
    this.pdfAttachmentContextTypeFilter$ = this.controlContentTypeSubject.pipe(map(type => this._getPDFAttachmentContextTypeFilters(type)));

    this.notificationForm = new FormGroup({});
    this.notificationFields = this.createFields();

    this.tableConfig = {
      columns: [
        {
          key: 'type',
          header: 'controls.fields.notification-type',
          type: BasicTableColumnType.Enum,
          enumOptions: controlNotificationTypeEnumOptions
        },
        {
          key: 'receiverType',
          header: 'controls.fields.receiver-type',
          type: BasicTableColumnType.Enum,
          enumOptions: controlNotificationReceiverTypeEnumOptions
        },
        {
          key: 'receiver',
          header: 'controls.fields.receiver',
          type: BasicTableColumnType.Text
        },
      ]
    }

    this.subscribe(this.readonly$, () => {
      this._updateTableActionsVisibility();
    })
  }

  public handleNotificationAction(): void {
    this.isEditingNotification.getValue() ? this.onEditNotification() : this.onAddNotification();
  }

  public onAddNotification(): void {
    const notifications: ControlNotificationLocalDto[] = [
      ...this.notificationsSubject.getValue(),
      cloneDeep(this._mapModelToLocalNotification())
    ];
    this.notificationsSubject.next(notifications);
    this.notificationsChanged.emit(notifications);
    this._resetForm();
  }

  public onDeleteNotification(index: number): void {
    const notifications: ControlNotificationLocalDto[] = cloneDeep(this.notifications ?? []);
    notifications.splice(index, 1);
    this.notificationsChanged.emit(notifications);
  }

  public onEditNotification(): void {
    const notifications: ControlNotificationLocalDto[] = cloneDeep(this.notifications);
    notifications[this._editIndex] = this._mapModelToLocalNotification();
    this.notificationsChanged.emit(notifications);
    this._resetForm();
    this.isEditingNotification.next(false);
  }

  public onCancelEdit(): void {
    this._resetForm();
    this.isEditingNotification.next(false);
  }

  private _toggleEditNotification(index: number): void {
    this._setNotificationIntoForm(this.notifications[index])
    this._editIndex = index;
    this.isEditingNotification.next(true);
  }

  private _resetForm(): void {
    this.notificationForm.reset();
    this.notificationForm.markAsUntouched();
    this.notificationModelSubject.next({} as ControlNotificationLocalDto);
  }

  private _mapNotificationsToTableItems(notifications: ControlNotificationLocalDto[]): NotificationTableDto[] {
    return notifications?.map((notification: ControlNotificationLocalDto) => {
      let receiver = '';

      switch (notification.receiver.type) {
        case ControlReceiverType.ContactReceiver:
          receiver = (notification.receiver as ContactControlReceiverDto).contact?.displayName;
          break;
        case ControlReceiverType.EmailReceiver:
          receiver = (notification.receiver as EmailControlReceiverDto).email;
          break;
        case ControlReceiverType.FunctionReceiver:
          receiver = (notification.receiver as FunctionControlReceiverDto | any).function?.name;
          break;
      }
      return {
        type: notification.type,
        receiverType: notification.receiver.type as ControlReceiverType,
        receiver
      } as NotificationTableDto;
    }) ?? [];
  }

  private _mapModelToLocalNotification(): ControlNotificationLocalDto {
    const model = cloneDeep(this.notificationModelSubject.getValue());
    let receiver = model.receiver;

    switch (receiver?.type) {
      case ControlReceiverType.ContactReceiver:
        (receiver as ContactControlReceiverDto) = {
          contact: receiver.contact,
          type: ControlReceiverType.ContactReceiver
        }
        break;
      case ControlReceiverType.EmailReceiver:
        (receiver as EmailControlReceiverDto) = {
          email: receiver.email,
          type: ControlReceiverType.EmailReceiver
        }
        break;
      case ControlReceiverType.FunctionReceiver:
        (receiver as FunctionControlReceiverDto | any) = {
          function: receiver.function,
          type: ControlReceiverType.FunctionReceiver
        }
        break;
    }

    return { ...model, receiver } as ControlNotificationLocalDto;
  }

  private _setNotificationIntoForm(notification: ControlNotificationLocalDto): void {
    let receiver: FunctionControlReceiverDto | EmailControlReceiverDto | ContactControlReceiverDto | any;

    switch (notification.receiver?.type) {
      case ControlReceiverType.ContactReceiver:
        receiver = {
          type: ControlReceiverType.ContactReceiver,
          contact: (notification.receiver as ContactControlReceiverDto)?.contact
        };
        break;
      case ControlReceiverType.EmailReceiver:
        receiver = {
          type: ControlReceiverType.EmailReceiver,
          email: (notification.receiver as EmailControlReceiverDto)?.email
        };
        break;
      case ControlReceiverType.FunctionReceiver:
        receiver = {
          type: ControlReceiverType.FunctionReceiver,
          function: notification.receiver?.function
        };
        break;
    }

    this.notificationModelSubject.next({ ...notification, receiver });
  }

  private _updateTableActionsVisibility(): void {
    this.tableConfig.actions = (this.readonly || this.isEditingNotification.getValue()) ? undefined : [
      {
        icon: faPencilAlt,
        tooltip: 'core-portal.core.general.edit',
        disabled: () => this.readonly || this.isEditingNotification.getValue(),
        click: (row, index) => this._toggleEditNotification(index)
      },
      {
        icon: faTrash,
        tooltip: 'controls.fields.remove-notification',
        disabled: () => this.readonly || this.isEditingNotification.getValue(),
        click: (row, index) => this.onDeleteNotification(index)
      }
    ];
  }

  private _getContentContextTypeFilter(type: ControlContentType): GroupedFilterDto[] {
    let contentContext: TemplateContextType;

    switch (type) {
      case ControlContentType.Message:
        contentContext = TemplateContextType.MessageIssueEmailContent;
        break;
      default:
        contentContext = TemplateContextType.IssueEmailContent;
    }

    return [
      {
        type: FilterTypes.Grouped,
        combinedAs: CombineOperator.Or,
        kind: FilterKind.Grouped,
        children: [
          {
            property: 'context',
            operator: FilterOperators.Equal,
            type: FilterTypes.DataTransferObject,
            value: TemplateContextType.IssueEmailContent.toString()
          },
          {
            property: 'context',
            operator: FilterOperators.Equal,
            type: FilterTypes.DataTransferObject,
            value: contentContext?.toString()
          }
        ] as FilterDto
      } as GroupedFilterDto
    ];
  }

  private _getSubjectContextTypeFilter(type: ControlContentType): GroupedFilterDto[] {
    let subjectContext: TemplateContextType;

    switch (type) {
      case ControlContentType.Message:
        subjectContext = TemplateContextType.MessageIssueEmailSubject;
        break;
      default:
        subjectContext = TemplateContextType.IssueEmailSubject;
    }

    return [
      {
        type: FilterTypes.Grouped,
        combinedAs: CombineOperator.Or,
        kind: FilterKind.Grouped,
        children: [
          {
            property: 'context',
            operator: FilterOperators.Equal,
            type: FilterTypes.DataTransferObject,
            value: TemplateContextType.IssueEmailSubject.toString()
          },
          {
            property: 'context',
            operator: FilterOperators.Equal,
            type: FilterTypes.DataTransferObject,
            value: subjectContext?.toString()
          }
        ] as FilterDto
      } as GroupedFilterDto
    ];
  }

  private _getPDFAttachmentContextTypeFilters(type: ControlContentType): GroupedFilterDto[] {
    let pdfContext: DocumentTemplateContextType;

    switch (type) {
      case ControlContentType.Message:
        pdfContext = DocumentTemplateContextType.MessageIssue;
        break;
      default:
        pdfContext = DocumentTemplateContextType.Issue;
    }

    return [
      {
        type: FilterTypes.Grouped,
        combinedAs: CombineOperator.Or,
        kind: FilterKind.Grouped,
        children: [
          {
            property: 'contextType',
            operator: FilterOperators.Equal,
            type: FilterTypes.DataTransferObject,
            value: DocumentTemplateContextType.Issue.toString()
          },
          {
            property: 'contextType',
            operator: FilterOperators.Equal,
            type: FilterTypes.DataTransferObject,
            value: pdfContext?.toString()
          }
        ] as FilterDto
      } as GroupedFilterDto
    ];
  }

  private createFields(): any {
    /* istanbul ignore next */
    return [
      { key: 'controlNotificationId' },
      {
        key: 'type',
        type: 'core-portal-ng-select',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-3',
        defaultValue: controlNotificationTypeEnumOptions[0].value,
        templateOptions: {
          corePortalTranslated: {
            label: 'controls.fields.notification-type',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.ENUM,
            enumOptions: controlNotificationTypeEnumOptions,
            translate: true
          } as CorePortalFormlyReadonlyTyping,
          corePortalNgSelect: {
            items: controlNotificationTypeEnumOptions,
            translate: true
          }
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        }
      },
      {
        key: 'receiver.type',
        type: 'core-portal-ng-select',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-3',
        defaultValue: ControlReceiverType.EmailReceiver,
        templateOptions: {
          corePortalTranslated: {
            label: 'controls.fields.receiver-type',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.ENUM,
            enumOptions: controlNotificationReceiverTypeEnumOptions,
            translate: true
          } as CorePortalFormlyReadonlyTyping,
          corePortalNgSelect: {
            items: controlNotificationReceiverTypeEnumOptions,
            translate: true
          }
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        }
      },
      {
        key: 'receiver.email',
        type: 'input',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        templateOptions: {
          type: 'email',
          corePortalTranslated: {
            label: 'core-shared.shared.fields.email-address',
            validationMessages: {
              email: 'core-portal.core.validation.email',
              required: 'core-portal.core.validation.required'
            }
          },
          required: true,
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.BASIC
          } as CorePortalFormlyReadonlyTyping
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.readonly': () => this.readonly
        },
        hideExpression: () => this.notificationForm.get('receiver.type')?.value !== ControlReceiverType.EmailReceiver,
        validators: {
          email: ctrl => !Validators.email(ctrl)
        }
      },
      {
        key: 'receiver.contact',
        type: 'core-portal-entity-select',
        wrappers: ['core-portal-translated'],
        className: 'col-md-6',
        defaultValue: null,
        templateOptions: {
          showError: true,
          corePortalTranslated: {
            label: 'core-shared.shared.fields.contact',
            validationMessages: {
              required: 'core-portal.core.validation.required',
              email: 'core-portal.core.validation.email'
            }
          },
          entityService: this.contactService,
          idKey: 'contactId',
          displayKey: 'displayName',
          wholeObject: true,
          addTagFn: (term: string) => ({ emailAddress: term, displayName: term }),
          required: true
        },
        hideExpression: () => this.notificationForm.get('receiver.type').value !== ControlReceiverType.ContactReceiver,
        validators: {
          email: ctrl => ctrl.value ?
            Boolean(ctrl.value.emailAddress) && !Validators.email(new FormControl(ctrl.value.emailAddress)) : true
        }
      },
      {
        key: 'receiver.function',
        type: 'core-portal-entity-select',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        defaultValue: null,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.function',
            validationMessages: {
              required: 'core-portal.core.validation.required',
              duplicate: 'core-portal.core.validation.duplicate'
            }
          },
          entityService: this.functionsService,
          idKey: 'functionId',
          displayKey: 'name',
          skipGetOne: true,
          wholeObject: true,
          clearable: false
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.readonly': () => this.readonly
        },
        hideExpression: () => this.notificationForm.get('receiver.type').value !== ControlReceiverType.FunctionReceiver
      },
      {
        key: 'emailSubjectTemplate',
        type: 'core-portal-entity-select',
        wrappers: ['core-portal-translated'],
        className: 'col-md-6',
        defaultValue: null,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-portal.settings.fields.text-template-for-mail-subject',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          entityService: this.textTemplateService,
          idKey: 'templateId',
          displayKey: 'title',
          clearable: false,
          wholeObject: true,
          refresh$: this.subjectContextTypeFilter$.pipe(filter(filter => !!filter)),
          defaultFilters$: this.subjectContextTypeFilter$
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        }
      },
      {
        key: 'emailContentTemplate',
        type: 'core-portal-entity-select',
        wrappers: ['core-portal-translated'],
        className: 'col-md-6',
        defaultValue: null,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-portal.settings.fields.text-template-for-mail-content',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          entityService: this.textTemplateService,
          idKey: 'templateId',
          displayKey: 'title',
          clearable: false,
          wholeObject: true,
          refresh$: this.contentContextTypeFilter$.pipe(filter(filter => !!filter)),
          defaultFilters$: this.contentContextTypeFilter$
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        },
      },
      {
        key: 'attachments',
        type: 'core-portal-entity-select',
        wrappers: ['core-portal-translated'],
        className: 'col-md-6',
        defaultValue: null,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.pdf-attachment',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.ENTITY,
            displayKey: 'title',
            link: (row: DocumentTemplateSimpleDto) => row?.documentTemplateId ? ['templates/document', row.documentTemplateId] : null,
            module: 'settings'
          } as CorePortalFormlyReadonlyTyping,
          entityService: this.documentTemplateService,
          idKey: 'documentTemplateId',
          displayKey: 'title',
          wholeObject: true,
          refresh$: this.pdfAttachmentContextTypeFilter$.pipe(distinctUntilChanged((pre, cur) => isEqual(pre, cur))),
          defaultFilters$: this.pdfAttachmentContextTypeFilter$,
          multiple: true,
          showAll: true
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly
        }
      }
    ]
  }

}
