import {FormlyFieldConfig} from '@ngx-formly/core';
import {InjectionToken, Injector} from '@angular/core';
import {CorePortalEntityEditBaseComponent} from '@nexnox-web/core-portal';

export interface CorePortalFeatureStereotypeAdditionalFields {
  [entityType: number]: FormlyFieldConfig[];
}

export type CorePortalFeatureStereotypeAdditionalFieldsToken = (
  injector: Injector,
  component: CorePortalEntityEditBaseComponent<any>
) => CorePortalFeatureStereotypeAdditionalFields;

export const CORE_PORTAL_FEATURE_STEREOTYPE_ADDITIONAL_FIELDS = new InjectionToken<CorePortalFeatureStereotypeAdditionalFieldsToken>(
  '@nexnox-web/core-portal/settings/stereotypes: Stereotype additional fields'
);
