import {CorePortalXsStoreEntityActionsFacade} from '@nexnox-web/core-portal';
import {SolutionDto} from '@nexnox-web/core-shared';
import {
  TechPortalFeatureSolutionActionsFacade,
  TechPortalFeatureSolutionActionsFacadeMarkItemAsSolutionPayload,
  TechPortalFeatureSolutionActionsFacadeUnmarkItemAsSolutionPayload
} from '../solution-actions';
import {TechPortalFeatureSolutionItemListXsStore} from '../../store';

export class XsStoreSolutionActionsFacade extends CorePortalXsStoreEntityActionsFacade<SolutionDto>
  implements TechPortalFeatureSolutionActionsFacade {
  protected entityStore: TechPortalFeatureSolutionItemListXsStore;

  public markItemAsSolution({ id, parentIds }: TechPortalFeatureSolutionActionsFacadeMarkItemAsSolutionPayload): void {
    this.store.dispatch(this.entityStore.actions.markItemAsSolution({
      id,
      parentIds,
      isSolution: true
    }));
  }

  public unmarkItemAsSolution({
                                id,
                                parentIds
                              }: TechPortalFeatureSolutionActionsFacadeUnmarkItemAsSolutionPayload): void {
    this.store.dispatch(this.entityStore.actions.markItemAsSolution({
      id,
      parentIds,
      isSolution: false
    }));
  }
}
