import {ChangeDetectionStrategy, Component, Injector, OnInit} from '@angular/core';
import {
  ActionButton,
  CorePortalEntityOverviewBaseComponent,
  DatatableActionButton,
  DeleteEntityModel
} from '@nexnox-web/core-portal';
import {
  AppEntityType,
  AppPermissions,
  ControlDto,
  ControlListDto,
  FilterOperators,
  FilterTypes,
  Mappers
} from '@nexnox-web/core-shared';
import {controlListStore} from '../../store/stores';

@Component({
  selector: 'nexnox-web-controls-control-list',
  templateUrl: './control-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TechPortalFeatureControlListComponent extends CorePortalEntityOverviewBaseComponent<ControlListDto> implements OnInit {
  public componentId = 'TechPortalFeatureControlListComponent';
  public title = 'controls.subtitles.control-list';
  public createTitle = 'controls.actions.create-control';
  public idProperty = 'controlId';
  public displayProperty = 'title';
  // public pageOperation = ControllerOperationId.ControlControllerList;
  public enableViews = false;
  public datatableConfigName = 'ControlDefault';

  constructor(
    protected injector: Injector
  ) {
    super(injector, controlListStore, Mappers.ControlListDto.serializedName, AppEntityType.None);
  }

  public ngOnInit(): void {
    this.defaultFilter = [{
      property: 'isArchived',
      operator: FilterOperators.Equal,
      type: FilterTypes.DataTransferObject,
      value: 'false'
    }];

    super.ngOnInit();
  }

  /* istanbul ignore next */
  public getRowActionButtons(): DatatableActionButton[] {
    return this.getDefaultRowActionButtons(
      'controls.actions.edit-control',
      (control: ControlDto) => control?.controlId ? `/controls/${ control.controlId }` : null,
      [AppPermissions.UpdateControl],
      {
        module: 'inventory'
      }
    );
  }

  public getDeleteEntityModel(): DeleteEntityModel {
    return {
      deletePermission: AppPermissions.DeleteControl,
      confirmKey: 'controls.actions.delete-control',
      descriptionKey: 'controls.descriptions.delete-control',
      titleKey: 'controls.actions.delete-control'
    };
  }

  protected async getActionButtons(): Promise<ActionButton[]> {
    return this.getDefaultActionButtons('controls.actions.create-control', AppPermissions.CreateControl);
  }
}
