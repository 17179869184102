import {ChangeDetectionStrategy, Component, Injector} from '@angular/core';
import {CorePortalEntityOverviewBaseComponent, DatatableActionButton} from '@nexnox-web/core-portal';
import {AppEntityType, AppPermissions, ContractDto, ControllerOperationId, Mappers} from '@nexnox-web/core-shared';
import {locationContractListStore} from '../../store';

@Component({
  selector: 'nexnox-web-master-data-locations-location-contract-list',
  templateUrl: './location-contract-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LocationContractListComponent extends CorePortalEntityOverviewBaseComponent<ContractDto> {
  public title = 'contracts.subtitles.contract-list';
  public idProperty = 'contractId';
  public displayProperty = 'title';
  public pageOperation = ControllerOperationId.ContractControllerList;
  public datatableConfigName = 'ContractDefault';
  public componentId = 'LocationContractListComponent';

  constructor(
    protected injector: Injector
  ) {
    super(injector, locationContractListStore, Mappers.ContractListDto.serializedName, AppEntityType.Contract);
  }

  /* istanbul ignore next */
  public getRowActionButtons(): DatatableActionButton[] {
    return this.getDefaultRowActionButtons(
      'contracts.actions.edit-contract',
      contract => `contracts/${ contract.contractId }`,
      [AppPermissions.UpdateContract],
      {
        module: 'inventory'
      }
    );
  }
}
