<!-- Inheritance -->
<nexnox-web-cardbox *ngIf="hasParent" [title]="'resources.subtitles.heredity' | translate">
  <form [formGroup]="form">
    <formly-form [(model)]="model"
                 [fields]="fields"
                 [form]="form"
                 class="row w-100">
    </formly-form>
  </form>
</nexnox-web-cardbox>

<!-- Issue templates list -->
<nexnox-web-issue-templates-sortable-list-edit (issueTemplatesChange)="onIssueTemplatesChanged($event)"
                                               [issueTemplatesSubject]="issueTemplatesSubject"
                                               [loading]="loading"
                                               [readonly]="readonly || model.inheritsIssueTemplate">
</nexnox-web-issue-templates-sortable-list-edit>
