import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BehaviorSubject, debounceTime, filter, Observable} from 'rxjs';
import {
  IssueTemplateSimpleDto,
  KnowledgeArticleSimpleDto,
  QuickAccessDashboardVisibility,
  QuickAccessIssueHistoryVisibility,
  QuickAccessIssueTemplateVisibility,
  QuickAccessKnowledgeVisibility,
  ResourceDto,
  UnsubscribeHelper
} from '@nexnox-web/core-shared';
import {faTrash} from '@fortawesome/free-solid-svg-icons/faTrash';
import {faPlus} from '@fortawesome/free-solid-svg-icons/faPlus';
import {distinctUntilChanged, map, pairwise, startWith} from "rxjs/operators";
import {FormControl, FormGroup} from "@angular/forms";
import {FormlyFieldConfig} from "@ngx-formly/core";
import {OrgaPortalKnowledgeArticleService} from "@nexnox-web/orga-portal-lib";
import {
  CorePortalFormlyNgSelectTyping,
  CorePortalFormlyReadonlyTypes,
  CorePortalFormlyReadonlyTyping,
  ModelValid
} from "@nexnox-web/core-portal";
import {isEmpty, isEqual} from "lodash";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons/faInfoCircle";
import {CorePortalIssueTemplateService} from "@nexnox-web/core-portal/features/settings/features/issue-templates";

export interface QuickAccessOptionsPayload {
  quickAccessConfigurationId: number;
  quickAccessKnowledgeConfigurationId: number;
  quickAccessIssueTemplateConfigurationId: number;
  quickAccessDashboardConfigurationId: number;
  knowledgeArticles: KnowledgeArticleSimpleDto[];
  knowledgeVisibility: QuickAccessKnowledgeVisibility;
  issueTemplates: IssueTemplateSimpleDto[];
  issueTemplateVisibility: QuickAccessIssueTemplateVisibility;
  issueTemplateHistoryVisibility: QuickAccessIssueHistoryVisibility;
  dashboardVisibility: QuickAccessDashboardVisibility;
  isShowingProperties: boolean;
  isInherited: boolean;
  isActivated: boolean;
}

export const issueTemplateVisibilityEnumOptions = [
  { label: 'resources.issue-template-visibility-types.0', value: QuickAccessIssueTemplateVisibility.None },
  { label: 'resources.issue-template-visibility-types.1', value: QuickAccessIssueTemplateVisibility.Own },
  { label: 'resources.issue-template-visibility-types.2', value: QuickAccessIssueTemplateVisibility.Explicit }
];

export const issueTemplateHistoryVisibilityOptions = [
  { label: 'resources.issue-template-history-visibility-types.0', value: QuickAccessIssueHistoryVisibility.Recent },
  { label: 'resources.issue-template-history-visibility-types.1', value: QuickAccessIssueHistoryVisibility.Hide },
];

export const knowledgeVisibilityEnumOptions = [
  { label: 'resources.knowledge-visibility-types.0', value: QuickAccessKnowledgeVisibility.None },
  { label: 'resources.knowledge-visibility-types.1', value: QuickAccessKnowledgeVisibility.Own },
  { label: 'resources.knowledge-visibility-types.2', value: QuickAccessKnowledgeVisibility.Explicit }
];

export const dashboardVisibilityEnumOptions = [
  { label: 'resources.dashboard-visibility-types.1', value: QuickAccessDashboardVisibility.None },
  { label: 'resources.dashboard-visibility-types.2', value: QuickAccessDashboardVisibility.Own },
];

@Component({
  selector: 'nexnox-web-resources-resource-quick-access',
  templateUrl: './resource-quick-access.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResourceQuickAccessComponent extends UnsubscribeHelper implements OnInit, ModelValid {

  @Input() public resourceSubject: BehaviorSubject<ResourceDto>;
  @Input() public readonly: boolean;
  @Input() public loading: boolean;

  @Output() public quickAccessChange: EventEmitter<QuickAccessOptionsPayload> = new EventEmitter<QuickAccessOptionsPayload>();

  public hasParent = false;
  public hasStereotype = false;

  public parentId$: Observable<number>;
  public isInherit$: Observable<boolean>;

  public form: FormGroup;
  public fields: FormlyFieldConfig[];
  public model: QuickAccessOptionsPayload;

  public faTrash = faTrash;
  public faPlus = faPlus;
  public faInfoCircle = faInfoCircle

  constructor(
    private articleService: OrgaPortalKnowledgeArticleService,
    private issueTemplateService: CorePortalIssueTemplateService
  ) {
    super();
  }

  /* istanbul ignore next */
  public ngOnInit(): void {

    this.form = new FormGroup({ isInherited: new FormControl(), isActivated: new FormControl(false) });

    // Init form
    this.subscribe(this.resourceSubject.pipe(
      startWith(null),
      pairwise(),
      filter(([prev, curr]) => !!curr && !isEqual(prev, curr) && !isEmpty(curr)), // workaround because distinctUntilChange is weird to use
      map(([_, curr]) => curr)
    ), (resource) => {
      this.hasParent = !!resource?.parent?.resourceId;
      this.hasStereotype = !!resource?.stereotypeId;
      this._setModel(resource);
    });

    // Trigger model change
    this.subscribe(this.form.valueChanges.pipe(
        startWith(null),
        pairwise(),
        filter(([prev, curr]) => !!curr && !isEqual(prev, curr) && !isEmpty(curr)), // workaround because distinctUntilChange is weird to use
        map(([_, curr]) => curr),
        debounceTime(1)
      ), () => this.onQuickAccessChanged()
    );

    this.isInherit$ = this.resourceSubject.pipe(map(r => r?.inheritsQuickAccessConfiguration), distinctUntilChanged());
    this.parentId$ = this.resourceSubject.pipe(map(r => r?.parent?.resourceId), distinctUntilChanged());


    this.fields = [
      { key: 'quickAccessConfigurationId' },
      { key: 'quickAccessKnowledgeConfigurationId' },
      { key: 'quickAccessIssueTemplateConfigurationId' },
      { key: 'quickAccessDashboardConfigurationId' },
      {
        key: 'isInherited',
        type: 'core-portal-switch',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-4',
        defaultValue: this.model?.isInherited,
        templateOptions: {
          corePortalTranslated: {
            label: 'resources.fields.inherit-quick-access'
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.BOOLEAN
          } as CorePortalFormlyReadonlyTyping
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly || !this.hasParent || !this.hasStereotype,
          'templateOptions.readonly': () => this.readonly
        }
      },
      {
        key: 'isActivated',
        type: 'core-portal-switch',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-4',
        defaultValue: this.model?.isActivated,
        templateOptions: {
          corePortalTranslated: {
            label: 'resources.fields.activate-quick-access'
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.BOOLEAN
          } as CorePortalFormlyReadonlyTyping
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly || this.model?.isInherited,
          'templateOptions.readonly': () => this.readonly
        }
      },
      {
        key: 'isShowingProperties',
        type: 'core-portal-switch',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-4',
        defaultValue: this.model?.isShowingProperties,
        templateOptions: {
          corePortalTranslated: {
            label: 'resources.fields.show-properties'
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.BOOLEAN
          } as CorePortalFormlyReadonlyTyping
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly || this.model?.isInherited,
          'templateOptions.readonly': () => this.readonly
        }
      },
      {
        type: 'core-portal-divider',
        className: 'col-md-12',
      },
      {
        key: 'knowledgeVisibility',
        type: 'core-portal-ng-select',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        defaultValue: knowledgeVisibilityEnumOptions[0].value,
        templateOptions: {
          corePortalTranslated: {
            label: 'resources.fields.knowledge-visibility',
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.ENUM,
            enumOptions: knowledgeVisibilityEnumOptions,
            translate: true
          } as CorePortalFormlyReadonlyTyping,
          corePortalNgSelect: {
            items: knowledgeVisibilityEnumOptions,
            translate: true,
            noClear: true,
            noSearch: true
          } as CorePortalFormlyNgSelectTyping
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly || this.model?.isInherited,
          'templateOptions.readonly': () => this.readonly
        }
      },
      {
        key: 'knowledgeArticles',
        type: 'core-portal-entity-select',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        defaultValue: null,
        templateOptions: {
          corePortalTranslated: {
            label: 'orga-portal.knowledge.subtitles.article-detail',
            validationMessages: {
              required: 'core-portal.core.validation.required',
            }
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.ENTITY,
            displayKey: 'title',
            link: (article: KnowledgeArticleSimpleDto) => article?.knowledgeArticleId ? ['knowledge', 'articles', article.knowledgeArticleId] : null,
            module: 'communication'
          } as CorePortalFormlyReadonlyTyping,
          entityService: this.articleService,
          idKey: 'knowledgeArticleId',
          displayKey: 'title',
          skipGetOne: true,
          wholeObject: true,
          clearable: true,
          multiple: true,
          showAll: true,
        },
        hideExpression: () => this.model?.knowledgeVisibility !== QuickAccessKnowledgeVisibility.Explicit,
        expressionProperties: {
          'templateOptions.required': () => true,
          'templateOptions.disabled': () => this.readonly || this.model?.isInherited
        }
      },
      {
        type: 'core-portal-divider',
        className: 'col-md-12',
      },
      {
        key: 'issueTemplateHistoryVisibility',
        type: 'core-portal-ng-select',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        defaultValue: issueTemplateHistoryVisibilityOptions[0].value,
        templateOptions: {
          corePortalTranslated: {
            label: 'resources.fields.issue-template-history-visibility',
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.ENUM,
            enumOptions: issueTemplateHistoryVisibilityOptions,
            translate: true
          } as CorePortalFormlyReadonlyTyping,
          corePortalNgSelect: {
            items: issueTemplateHistoryVisibilityOptions,
            translate: true,
            noClear: true,
            noSearch: true
          } as CorePortalFormlyNgSelectTyping
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly || this.model?.isInherited,
          'templateOptions.readonly': () => this.readonly
        }
      },
      {
        // Placeholder
        className: 'col-md-6'
      },
      {
        key: 'issueTemplateVisibility',
        type: 'core-portal-ng-select',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        defaultValue: issueTemplateVisibilityEnumOptions[0].value,
        templateOptions: {
          corePortalTranslated: {
            label: 'resources.fields.issue-template-visibility',
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.ENUM,
            enumOptions: issueTemplateVisibilityEnumOptions,
            translate: true
          } as CorePortalFormlyReadonlyTyping,
          corePortalNgSelect: {
            items: issueTemplateVisibilityEnumOptions,
            translate: true,
            noClear: true,
            noSearch: true
          } as CorePortalFormlyNgSelectTyping
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly || this.model?.isInherited,
          'templateOptions.readonly': () => this.readonly
        }
      },
      {
        key: 'issueTemplates',
        type: 'core-portal-entity-select',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-portal.settings.issue-template.titles.detail',
            validationMessages: {
              required: 'core-portal.core.validation.required',
            }
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.ENTITY,
            displayKey: 'name',
            link: (issueTemplate: IssueTemplateSimpleDto) => issueTemplate?.issueTemplateId ? ['settings', 'issues', issueTemplate.issueTemplateId] : null,
            module: 'communication'
          } as CorePortalFormlyReadonlyTyping,
          entityService: this.issueTemplateService,
          idKey: 'issueTemplateId',
          displayKey: 'name',
          skipGetOne: true,
          wholeObject: true,
          clearable: true,
          multiple: true,
          showAll: true,
        },
        hideExpression: () => this.model?.issueTemplateVisibility !== QuickAccessIssueTemplateVisibility.Explicit,
        expressionProperties: {
          'templateOptions.required': () => true,
          'templateOptions.disabled': () => this.readonly || this.model?.isInherited
        }
      },
      {
        type: 'core-portal-divider',
        className: 'col-md-12',
      },
      {
        key: 'dashboardVisibility',
        type: 'core-portal-ng-select',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        defaultValue: dashboardVisibilityEnumOptions[0].value,
        templateOptions: {
          corePortalTranslated: {
            label: 'resources.fields.dashboard-visibility',
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.ENUM,
            enumOptions: dashboardVisibilityEnumOptions,
            translate: true
          } as CorePortalFormlyReadonlyTyping,
          corePortalNgSelect: {
            items: dashboardVisibilityEnumOptions,
            translate: true,
            noClear: true,
            noSearch: true
          } as CorePortalFormlyNgSelectTyping
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly || this.model?.isInherited,
          'templateOptions.readonly': () => this.readonly
        }
      }
    ];
  }

  public isModelValid(): boolean {
    return this.isOwnModelValid();
  }

  public isOwnModelValid(): boolean {
    return this.form && !this.readonly ? this.form.valid : true;
  }

  public isOwnModelPristine(): boolean {
    return this.form.pristine;
  }

  public onQuickAccessChanged(value?: any): void {
    this.quickAccessChange.emit(value ?? this.model);
  }

  private _setModel(resource: ResourceDto, isInherit?: boolean): void {
    this.model = {
      quickAccessConfigurationId: resource?.quickAccessConfiguration?.quickAccessConfigurationId ?? 0,
      quickAccessKnowledgeConfigurationId: resource?.quickAccessConfiguration?.knowledgeConfiguration?.quickAccessKnowledgeConfigurationId ?? 0,
      quickAccessIssueTemplateConfigurationId: resource?.quickAccessConfiguration?.issueTemplateConfiguration?.quickAccessIssueTemplateConfigurationId ?? 0,
      quickAccessDashboardConfigurationId: resource?.quickAccessConfiguration?.dashboardConfiguration?.quickAccessDashboardConfigurationId ?? 0,
      isInherited: isInherit ?? resource?.inheritsQuickAccessConfiguration,
      isActivated: resource?.quickAccessConfiguration?.isActivated ?? false,
      isShowingProperties: resource?.quickAccessConfiguration?.isShowingProperties ?? false,
      knowledgeVisibility: resource?.quickAccessConfiguration?.knowledgeConfiguration?.visibility ?? QuickAccessKnowledgeVisibility.None,
      issueTemplateVisibility: resource?.quickAccessConfiguration?.issueTemplateConfiguration?.visibility ?? QuickAccessIssueTemplateVisibility.None,
      issueTemplateHistoryVisibility: resource?.quickAccessConfiguration?.issueTemplateConfiguration?.historyVisibility ?? QuickAccessIssueHistoryVisibility.Recent,
      knowledgeArticles: resource?.quickAccessConfiguration?.knowledgeConfiguration?.knowledgeArticles ?? [],
      issueTemplates: resource?.quickAccessConfiguration?.issueTemplateConfiguration?.issueTemplates ?? [],
      dashboardVisibility: resource?.quickAccessConfiguration?.dashboardConfiguration?.visibility ?? QuickAccessDashboardVisibility.None
    }
  }
}
