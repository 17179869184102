import {NgModule} from '@angular/core';
import {CoreSharedModule} from '@nexnox-web/core-shared';
import {CorePortalEntitySelectModule} from '../entity-select';
import {CorePortalEntityDatatableModule} from '../entity-datatable';
import {CorePortalEntitySelectDatatableSearchModalComponent} from './modals';

@NgModule({
  declarations: [
    CorePortalEntitySelectDatatableSearchModalComponent
  ],
  imports: [
    CoreSharedModule,
    CorePortalEntitySelectModule,
    CorePortalEntityDatatableModule
  ],
  exports: [
    CorePortalEntityDatatableModule,
    CorePortalEntitySelectModule,

    CorePortalEntitySelectDatatableSearchModalComponent
  ]
})
export class CorePortalEntitySelectDatatableSearchModule {
}
