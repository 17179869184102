import {Injectable} from '@angular/core';
import {v4 as uuidv4} from 'uuid';

@Injectable()
export class UuidService {
  private _uuid: string;

  public get uuid(): string {
    return this._uuid;
  }

  public generateUuid(): string {
    this._uuid = uuidv4();
    return this.uuid;
  }
}
