<nexnox-web-cardbox [loading]="loading"
                    [title]="'core-portal.settings.issue-template.published-resources' | translate"
                    paddingClasses="px-3 pb-3">

  <div *ngIf="resources$ | async as items" class="pt-2 px-2 pb-3">
    <nexnox-web-basic-table [config]="resourceTableConfig"
                            [data]="items">
    </nexnox-web-basic-table>
  </div>

</nexnox-web-cardbox>
