import {SnapElement} from './snap-element.model';
import {remove} from 'lodash';
import {SnapRect} from './snap-rect.model';

export class SnapGroup extends SnapElement {
  public boundingBox: SnapRect;

  protected children: SnapElement[] = [];

  constructor(
    x: number,
    y: number,
    parent: SnapElement = null
  ) {
    super(x, y, parent);
  }

  public addChild<T extends SnapElement>(snapElement: T): T {
    this.element.add(snapElement.createSnapElement(this.element));
    this.children.push(snapElement);
    snapElement.parent = this;
    this.updateAttributes();
    return snapElement;
  }

  public removeChild<T extends SnapElement>(snapElement: T): void {
    snapElement.remove();
    remove(this.children, child => child === snapElement);
    snapElement.parent = null;
    this.updateAttributes();
  }

  public updateAttributes(): void {
    super.updateAttributes();

    for (const child of this.children) {
      child.updateAttributes();
    }

    if (this.boundingBox) {
      this.updateBoundingBox(this.element.getBBox());
    }
  }

  public createSnapElement(snapPaper: any): any {
    this.element = super.createSnapElement(snapPaper);
    this.boundingBox = new SnapRect(
      -5,
      -5,
      0,
      0,
      'transparent',
      undefined,
      undefined,
      this
    );
    this.boundingBox.createSnapElement(this.element);

    this.updateAttributes();
    return this.element;
  }

  protected createElement(snapPaper: any): any {
    return snapPaper.group();
  }

  private updateBoundingBox(bBox: any): void {
    if (bBox.width > 0 && bBox.height > 0) {
      this.boundingBox.width = bBox.width;
      this.boundingBox.height = bBox.height;
    }
  }
}
