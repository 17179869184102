import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {FormGroup, Validators} from '@angular/forms';
import {faCheck} from '@fortawesome/free-solid-svg-icons/faCheck';
import {faSpinner} from '@fortawesome/free-solid-svg-icons/faSpinner';
import {CorePortalFormlyTranslatedTyping} from '@nexnox-web/core-portal';
import {CoreSharedSidebarBaseComponent} from '@nexnox-web/core-shared';
import {Actions, ofType} from '@ngrx/effects';
import {select, Store} from '@ngrx/store';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {Observable} from 'rxjs';
import {contactDetailStore} from '../../store';


export interface ChangeMailAddressEvent {
  email: string;
}

@Component({
  selector: 'nexnox-web-master-data-contacts-change-mailaddress-sidebar',
  templateUrl: './change-mailaddress-sidebar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChangeMailAddressSidebarComponent extends CoreSharedSidebarBaseComponent implements OnInit {

  public form: FormGroup;
  public model: ChangeMailAddressEvent;
  public fields: FormlyFieldConfig[];

  public loading$: Observable<boolean>;

  public faCheck = faCheck;
  public faSpinner = faSpinner;

  constructor(
    private store: Store,
    private actions$: Actions
  ) {
    super();

    this.loading$ = this.store.pipe(
      select(contactDetailStore.selectors.selectEntityDataLoading, { key: contactDetailStore.actions.changeMailAddress.type })
    );
  }

  public ngOnInit(): void {
    this.form = new FormGroup({});
    this.model = {} as ChangeMailAddressEvent;
    this.fields = this.createForm();
  }

  public onHide(): void {
    super.onHide();

    this.form.reset();
    this.form.markAsPristine();
  }

  public onShow(): void {
    super.onShow();
    this.subscribe(this.actions$.pipe(
      ofType(contactDetailStore.actions.changeMailAddressSuccess)
    ), () => this.onHide())
  }

  public onChangeMailAddress(): void {
    this.store.dispatch(contactDetailStore.actions.changeMailAddress({ email: this.model.email }));
  }

  /* istanbul ignore next */
  private createForm(): FormlyFieldConfig[] {
    return [
      {
        key: 'email',
        type: 'input',
        wrappers: ['core-portal-translated'],
        className: 'col-md-12',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.email-address',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          } as CorePortalFormlyTranslatedTyping,
          type: 'email',
          required: true
        },
        validators: {
          email: ctrl => !Validators.email(ctrl)
        }
      }
    ];
  }
}
