import {SnapElement} from './snap-element.model';

export class SnapCircle extends SnapElement {
  constructor(
    x: number,
    y: number,
    radius: number,
    fill: string = 'black',
    stroke: string = 'transparent',
    strokeWidth?: number,
    parent: SnapElement = null
  ) {
    super(x, y, parent);

    this._radius = radius;
    this._stroke = stroke;
    this._strokeWidth = strokeWidth;
    this._fill = fill;
  }

  private _radius: number;

  public get radius(): number {
    return this._radius;
  }

  public set radius(radius: number) {
    this._radius = radius;
    this.updateAttributes();
  }

  public updateAttributes(): void {
    super.updateAttributes();
    this.element.attr({
      cx: this.absoluteX,
      cy: this.absoluteY,
      cr: this._radius
    });
  }

  protected createElement(snapPaper: any): any {
    return snapPaper.circle(this.absoluteX, this.absoluteY, this._radius);
  }
}
