import {Directive, Injector, OnDestroy, OnInit} from "@angular/core";
import {UnsubscribeHelper} from "@nexnox-web/core-shared";
import {SettingsMenuConfigCategoryDto, SettingsMenuDto} from "../models";
import {
  CORE_PORTAL_SETTINGS_MENU_CONFIG,
  CorePortalSettingsMenuService
} from "@nexnox-web/core-portal/features/settings/menu";
import {CorePortalActionBarService, CorePortalPermissionService, CorePortalTenantRouter} from "@nexnox-web/core-portal";
import {ActivatedRoute, Router} from "@angular/router";

@Directive()
export abstract class SettingsMenuBaseComponent extends UnsubscribeHelper implements OnInit, OnDestroy {

  public currentMenu: Promise<SettingsMenuDto>;

  // Options
  public isExpandAllItemsOnStart = false;

  // Services & injectables
  public config: SettingsMenuConfigCategoryDto[];
  public router: Router;
  public route: ActivatedRoute;
  public tenantRouter: CorePortalTenantRouter;
  public actionBarService: CorePortalActionBarService;
  public permissionService: CorePortalPermissionService;
  public settingsMenuService: CorePortalSettingsMenuService;

  constructor(
    protected injector: Injector,
    public module: string = ''
  ) {
    super();
    this.config = this.injector.get(CORE_PORTAL_SETTINGS_MENU_CONFIG);
    this.router = this.injector.get(Router);
    this.route = this.injector.get(ActivatedRoute);
    this.tenantRouter = this.injector.get(CorePortalTenantRouter);
    this.actionBarService = this.injector.get(CorePortalActionBarService);
    this.permissionService = this.injector.get(CorePortalPermissionService);
    this.settingsMenuService = this.injector.get(CorePortalSettingsMenuService);
  }

  public ngOnInit(): void {

    // Validating permissions
    this.currentMenu = this.settingsMenuService.setupMenu(this.config ?? []);

    // Go to first item with access, when first route of menu has no access
    this.currentMenu.then((menu) => {
      if (this.router.url.includes(menu.chapters[0].categories[0].items[0].link.join('/'))) {
        this.gotToUrl(menu.chapters.map(chapter => chapter.categories).flat().find(category => category.hasAccess === true)?.items.find(i => i.hasAccess === true)?.link ?? ['/'])
      }
    });
  }

  public ngOnDestroy(): void {
    this.actionBarService.reset();
    super.ngOnDestroy();
  }

  public gotToUrl(segments: string[], fragment?: string): void {
    this.tenantRouter.navigate([...segments], { fragment });
  }

  public getImage(itemPath: string): string {
    return itemPath ? `background-image: url('assets/settings/bgd/${ itemPath }')` : '';
  }
}
