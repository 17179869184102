<div class="d-flex align-items-center">

  <input [readonly]="true"
         [value]="to.async ? (to.value$ | async) : (to.value | translate)"
         class="form-control flex-grow-1"
  />

  <button (click)="to.click(field)"
          [class]="to.buttonClass"
          [disabled]="to.readonly"
          [icon]="to.icon | nexnoxWebFaIconStringPipe"
          [label]="to.buttonLabel | translate"
          [title]="to.buttonLabel | translate"
          class="flex-grow-2"
          pButton
          type="button">
  </button>
</div>
