import {ChangeDetectionStrategy, Component, Injector} from '@angular/core';
import {CorePortalFeatureAuditListBaseComponent} from '@nexnox-web/core-portal/features/audit';
import {missionAuditStore} from '../../store';

@Component({
  selector: 'nexnox-web-missions-mission-audit',
  templateUrl: './mission-audit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MissionAuditComponent extends CorePortalFeatureAuditListBaseComponent {
  public componentId = 'MissionAuditComponent';

  constructor(
    protected injector: Injector
  ) {
    super(injector, missionAuditStore);
  }
}
