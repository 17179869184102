<ng-container *ngIf="(entityOverviewBaseComponent.isCreateVisible$ | async) && !disableCreate"
              [ngTemplateOutletContext]="{ title: entityOverviewBaseComponent.createTitle | translate }"
              [ngTemplateOutlet]="entityEditTemplate">
</ng-container>

<nexnox-web-tabs #tabsComponent
                 *ngIf="showTabs; else overviewTemplate"
                 [isTransparent]="true">
  <!-- Overview Tab -->
  <ng-template (selectTab)="onNavigateToTab(tabCommands)"
               [heading]="tabHeading"
               [tabId]="tabId"
               nexnoxWebTabTemplate>
    <ng-container *ngTemplateOutlet="overviewTemplate">
    </ng-container>
  </ng-template>

  <!-- Other Tabs -->
  <ng-template (selectTab)="tabTemplate.selectTab.emit()"
               *ngFor="let tabTemplate of tabTemplates"
               [hasError]="tabTemplate.hasError"
               [heading]="tabTemplate.heading"
               [hide]="tabTemplate.hide"
               [lazy]="tabTemplate.lazy"
               [tabId]="tabTemplate.tabId"
               nexnoxWebTabTemplate>
    <ng-container *ngIf="tabTemplate.template" [ngTemplateOutlet]="tabTemplate.template"></ng-container>
  </ng-template>
</nexnox-web-tabs>

<ng-template #overviewTemplate>
  <div [class.d-none]="hideIfCreate && (entityOverviewBaseComponent.isCreateVisible$ | async)"
       class="overflow-hidden min-h-100">
    <nexnox-web-cardbox
      [headerActions]="cardBoxHeaderActions ?? []"
      [loading]="(entityOverviewBaseComponent.hasError$ | async) === false && ((entityOverviewBaseComponent.loaded$ | async) === false || (stereotyped && (entityOverviewBaseComponent.stereotypesLoaded$ | async) === false))"
      [noDividerMarginBottom]="true"
      [noMarginBottom]="isEmbedded && !allowMargin"
      [shadowMarginBottom]="isEmbedded && !allowMargin"
      [title]="title ? title : (entityOverviewBaseComponent.disableTitleTranslation ? entityOverviewBaseComponent.title : (entityOverviewBaseComponent.title | translate))"
      ghostPaddingClasses="p-3">

      <ng-template #appendTitleTemplate *ngIf="appendCardboxTitleTemplate">
        <ng-container *ngTemplateOutlet="appendCardboxTitleTemplate"></ng-container>
      </ng-template>

      <nexnox-web-entity-datatable #datatableComponent
                                   (clickableCellEvent)="onCellClick($event)"
                                   (datatableConfigChange)="onDatatableConfigChange($event)"
                                   (delete)="bc.onDelete($event)"
                                   (loadPage)="onLoadPage($event)"
                                   *ngIf="entityOverviewBaseComponent as bc"
                                   [actionButtons]="rowActionButtons"
                                   [additionalHeaderActions]="additionalHeaderActions"
                                   [appendColumns]="appendColumns ?? []"
                                   [canDelete]="canDelete | async"
                                   [canExport]="canExport"
                                   [columnTypings]="columnTypings"
                                   [componentId]="bc.componentId"
                                   [datatableConfig]="bc.customDatatableConfig ?? datatableConfig"
                                   [datatablePredefinedViewConfig]="bc.predefinedDatatableViews"
                                   [defaultColumns]="bc.defaultColumns"
                                   [descendantId]="bc.descendantId"
                                   [detailFn]="detailFn"
                                   [detailLink]="bc.detailLink ?? detailLink"
                                   [detailTemplate]="detailTemplate"
                                   [disableFilters]="disableFilters"
                                   [disableOptionalSorting]="bc.disableOptionalSorting"
                                   [disableSettingsViewSelect]="bc.disableSettingsViewSelect || disableSettingsViewSelect"
                                   [disableSettingsViews]="disableSettingsViews"
                                   [displayProp]="bc.displayProperty"
                                   [enableCalendarSubscription]="enableCalendarSubscription"
                                   [enableViews]="bc.enableViews"
                                   [entityData]="bc.entityData$ | async"
                                   [entityType]="bc.entityType"
                                   [excludedColumns]="bc.excludedColumns"
                                   [filtersForExport]="bc.filtersForExport$ ? (bc.filtersForExport$ | async) : undefined"
                                   [hideFilters]="hideFilters"
                                   [idProp]="bc.idProperty"
                                   [loading]="bc.loading$ | async"
                                   [module]="bc.module ?? module"
                                   [pageOperation]="bc.pageOperation"
                                   [paging]="bc.paging$ | async"
                                   [requestBodyForExport]="bc.requestBodyForExport"
                                   [savedFilters]="!disableSavedFilters ? ((bc.savedFilters$ ?? bc.stateFilters$) | async) : null"
                                   [savedSortObject]="!disableSavedSortObject ? (bc.stateSortObject$ | async) : null"
                                   [showDetailTemplateExpandButtonFn]="showDetailTemplateExpandButtonFn"
                                   [showSettings]="showSettings"
                                   [stereotypes$]="bc.stereotypes$"
                                   [storeEntities$]="bc.entities$"
                                   [templates]="bc.templates"
                                   [viewType]="bc.viewType">
      </nexnox-web-entity-datatable>
    </nexnox-web-cardbox>
  </div>

  <div *ngIf="entityOverviewBaseComponent.isCreateVisible$ | async" class="pt-1"></div>
</ng-template>
