import {ChangeDetectionStrategy, Component, Injector, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {faPlus} from '@fortawesome/free-solid-svg-icons/faPlus';
import {
  authStore,
  CorePortalEntityEditBaseComponent,
  CorePortalFormlyReadonlyTypes,
  CorePortalFormlyReadonlyTyping,
  missionSettingsStore
} from '@nexnox-web/core-portal';
import {CorePortalFeatureArticleService} from '@nexnox-web/core-portal/features/articles';
import {
  ArticleDto,
  ArticleUsageDto,
  CoreSharedSortableListItem,
  MissionReportDto,
  NexnoxWebLocaleString,
  SettingType
} from '@nexnox-web/core-shared';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {cloneDeep, isEqual, round} from 'lodash';
import {BehaviorSubject, lastValueFrom, Observable, of, startWith, takeWhile} from 'rxjs';
import {distinctUntilChanged, filter, map, take} from 'rxjs/operators';
import {TranslateService} from "@ngx-translate/core";
import {select, Store} from "@ngrx/store";

interface AddArticle {
  article: ArticleDto;
  count: number;
  price: number;
  note?: string;
}

interface ArticlesValid {
  [articlePosition: number | string]: boolean;
}

@Component({
  selector: 'nexnox-web-missions-mission-report-edit',
  templateUrl: './mission-report-edit.component.html',
  styleUrls: ['./mission-report-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TechPortalFeatureMissionReportEditComponent extends CorePortalEntityEditBaseComponent<MissionReportDto>
  implements OnInit {
  public addArticleForm: FormGroup;
  public addArticleModelSubject: BehaviorSubject<AddArticle> = new BehaviorSubject<AddArticle>(null);
  public addArticlesFields: FormlyFieldConfig[];
  public addArticlesUnitSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  public missionReportDurationItems$: Observable<CoreSharedSortableListItem[]>;
  public missionReportUsedArticleItems$: Observable<CoreSharedSortableListItem[]>;
  public missionReportTotal$: Observable<string>;

  public faPlus = faPlus;

  private articlesValid$: Observable<boolean>;
  private articlesValidSubject: BehaviorSubject<ArticlesValid> = new BehaviorSubject<ArticlesValid>({});

  constructor(
    protected injector: Injector,
    private store: Store,
    private translate: TranslateService,
    private articleService: CorePortalFeatureArticleService
  ) {
    super(injector);

    this.missionReportDurationItems$ = this.modelSubject.asObservable().pipe(
      map((model: MissionReportDto) =>
        [
          {
            title: this.translate.instant('core-shared.shared.fields.travel'),
            deletable: false,
            getExternalData: () => ({
              model,
              title: 'core-shared.shared.fields.travel',
              position: 'travel'
            }),
            getOneTimeExternalData: () => ({
              fields: this.createEditArticleTravelDurationsForm('travel')
            })
          },
          {
            title: this.translate.instant('core-shared.shared.fields.work'),
            deletable: false,
            getExternalData: () => ({
              model,
              title: 'core-shared.shared.fields.work',
              position: 'work'
            }),
            getOneTimeExternalData: () => ({
              fields: this.createEditArticleWorkDurationsForm('work')
            })
          }
        ] as CoreSharedSortableListItem[]
      )
    );

    this.missionReportUsedArticleItems$ = this.modelSubject.asObservable().pipe(
      map(model => (model?.usedArticles ?? []).map((usedArticle: ArticleUsageDto) => ({
        title: usedArticle.article.name,
        position: usedArticle.position,
        deletable: true,
        getExternalData: () => ({ model: usedArticle }),
        getOneTimeExternalData: () => ({
          fields: this.createEditArticleForm(usedArticle.position, usedArticle.unit)
        })
      }) as CoreSharedSortableListItem).sort((a, b) => a.position - b.position))
    );

    this.missionReportTotal$ = this.modelSubject.asObservable().pipe(
      map((model) => NexnoxWebLocaleString.transformNumber(this.getTotal(model) ?? 0, 2))
    );

    this.articlesValid$ = this.articlesValidSubject.asObservable().pipe(
      distinctUntilChanged((a, b) => isEqual(a, b)),
      map(articlesValid => Object.values(articlesValid).every(x => x))
    );

    this.subscribe(this.articlesValid$.pipe(distinctUntilChanged()), articlesValid => {
      this.modelValidSubject.next({ ...this.modelValidSubject.getValue(), articlesValid });
      setTimeout(() => this.onModelChange(this.model));
    });
  }

  public ngOnInit(): void {
    super.ngOnInit();

    this.getDefaultWorkAndTravelCosts().then();
    this.resetArticlesForm();
  }

  public async getDefaultWorkAndTravelCosts(): Promise<void> {
    if (!this.readonly && !this.model?.missionReportId) {
      let gotSettings = false;
      const tenantId = await lastValueFrom(this.store.pipe(select(authStore.selectors.selectTenantId), filter(tenantId => Boolean(tenantId)), map(tenantId => ([tenantId])), take(1)));

      this.store.dispatch(missionSettingsStore.actions.get({
        parentIds: tenantId,
        id: SettingType.Mission
      }));

      this.subscribe(this.store.select(missionSettingsStore.selectors.selectModel).pipe(filter(settings => Boolean(settings)), takeWhile(() => !gotSettings)), (settings) => {
        if (settings.defaultTravelDurationCost && settings.defaultWorkDurationCost) {
          this.onModelChange({
            ...this.model,
            travelDurationInHours: 1,
            travelDurationCost: settings.defaultTravelDurationCost,
            workDurationInHours: 1,
            workDurationCost: settings.defaultWorkDurationCost
          });
          gotSettings = true;
        }
      });
    }
  }

  public onAddArticle(): void {
    const articleToAdd = cloneDeep(this.addArticleModelSubject.getValue());

    const newArticleUsage = {
      article: articleToAdd.article,
      count: articleToAdd.count,
      price: articleToAdd.price,
      unit: articleToAdd.article.unit,
      note: articleToAdd.note,
      total: round(articleToAdd.price * articleToAdd.count, 2)
    };


    const usedArticles = cloneDeep(this.model.usedArticles ?? []);
    usedArticles.push({ ...newArticleUsage, position: usedArticles.length });

    this.onModelChange({
      ...this.model,
      usedArticles
    });

    this.addArticlesUnitSubject.next(null);
    this.addArticleModelSubject.next({} as any);
    this.addArticleForm.reset();
  }

  public getTotal(model?: any): number {
    const { travelDurationInHours, workDurationInHours, travelDurationCost, workDurationCost } = model ?? this.model;
    let total = (
      (Number(travelDurationInHours) * Number(travelDurationCost)) + (Number(workDurationInHours) * Number(workDurationCost))
    ) ?? 0;
    (model.usedArticles ?? this.model.usedArticles ?? [])?.forEach((a: ArticleUsageDto) => total += (Number(a.price) * Number(a.count)));
    return total;
  }

  public onDurationsChange(event: { durationItem: MissionReportDto, valid: boolean }): void {
    const { travelDurationInHours, travelDurationCost, workDurationInHours, workDurationCost } = event.durationItem
    this.onModelChange({
      ...(this.model),
      travelDurationInHours,
      travelDurationCost,
      workDurationInHours,
      workDurationCost
    });
    this.modelSubject.next(this.model);
  }

  public onArticlesChange(items: CoreSharedSortableListItem[]): void {
    const usedArticles: ArticleUsageDto[] = items
      .sort((a, b) => a.position - b.position)
      .map(x => ({ ...x.getExternalData().model, position: x.position }));

    this.onModelChange({
      ...this.model,
      usedArticles
    });
  }

  public onArticleChange(position: number, event: { articleUsage: ArticleUsageDto, valid: boolean }): void {
    const usedArticles = cloneDeep(this.model?.usedArticles ?? []);
    const articleIndex = usedArticles.findIndex(x => x.position === position);

    usedArticles[articleIndex] = {
      ...event,
      total: round(event.articleUsage.price * event.articleUsage.count, 2)
    };

    this.onModelChange({
      ...this.model,
      usedArticles
    });
  }

  protected createForm(): FormlyFieldConfig[] {
    return this.getStereotypeFields(false);
  }

  /* istanbul ignore next */
  protected createAddArticleForm(): FormlyFieldConfig[] {
    return [
      {
        key: 'article',
        type: 'core-portal-entity-select',
        wrappers: ['core-portal-translated'],
        className: 'col-md-6',
        defaultValue: null,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.article',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          entityService: this.articleService,
          idKey: 'articleId',
          displayKey: 'name',
          wholeObject: true,
          skipGetOne: true
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly
        },
        hooks: {
          onInit: field => this.subscribe(field.formControl.valueChanges.pipe(
            distinctUntilChanged((a, b) => isEqual(a, b))
          ), (article: ArticleDto) => {
            this.addArticlesUnitSubject.next(article?.unit ?? null);
            field.form.controls.price.setValue(article?.sellingPrice ?? null);
          })
        }
      },
      {
        key: 'count',
        type: 'core-portal-input-group-input',
        wrappers: ['core-portal-translated'],
        className: 'col-md-3',
        defaultValue: 1,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.count',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          corePortalInputGroupInput: {
            append: this.addArticlesUnitSubject.asObservable().pipe(
              map(unit => unit ? [unit] : [])
            )
          },
          type: 'number',
          required: true,
          min: 0
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly
        }
      },
      {
        key: 'price',
        type: 'core-portal-input-group-input',
        wrappers: ['core-portal-translated'],
        className: 'col-md-3',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.selling-price',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          corePortalInputGroupInput: {
            append: of(['€'])
          },
          type: 'number',
          required: true,
          min: 0
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly
        }
      },
      {
        key: 'note',
        type: 'textarea',
        wrappers: ['core-portal-translated'],
        className: 'col-md-12',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.note'
          },
          type: 'text',
          rows: 2
        }
      }
    ];
  }

  /* istanbul ignore next */
  protected createEditArticleForm(position: number, unit: string): FormlyFieldConfig[] {
    return [
      {
        key: 'count',
        type: 'core-portal-input-group-input',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-6',
        defaultValue: 0,
        templateOptions: {
          corePortalTranslated: {
            hideLabel: true,
            formGroupClassName: 'mb-0'
          },
          validationMessages: {
            positive: {
              key: 'core-portal.core.validation.min',
              args: { min: 0 }
            }
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.BASIC,
            template: value => `${ value }${ unit ? ` ${ unit }` : '' }`
          } as CorePortalFormlyReadonlyTyping,
          corePortalInputGroupInput: {
            append: of(unit ? [unit] : [])
          },
          type: 'number',
          required: true,
          min: 0
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        },
        hooks: {
          onInit: field => this.subscribe(field.form.valueChanges.pipe(
            startWith(field.form.value),
            distinctUntilChanged()
          ), () => {
            const articlesValid = this.articlesValidSubject.getValue();
            this.articlesValidSubject.next({
              ...articlesValid,
              [position]: field.form.valid
            });
          })
        }
      },
      {
        key: 'price',
        type: 'core-portal-input-group-input',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-6',
        defaultValue: 0,
        templateOptions: {
          corePortalTranslated: {
            hideLabel: true,
            formGroupClassName: 'mb-0'
          },
          validationMessages: {
            positive: {
              key: 'core-portal.core.validation.min',
              args: { min: 0 }
            }
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.BASIC,
            template: value => `${ value } €`
          } as CorePortalFormlyReadonlyTyping,
          corePortalInputGroupInput: {
            append: of(['€'])
          },
          type: 'number',
          required: true,
          min: 0
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        },
        hooks: {
          onInit: field => this.subscribe(field.form.valueChanges.pipe(
            startWith(field.form.value),
            distinctUntilChanged()
          ), () => {
            const articlesValid = this.articlesValidSubject.getValue();
            this.articlesValidSubject.next({
              ...articlesValid,
              [position]: field.form.valid
            });
          })
        }
      }
    ];
  }

  /* istanbul ignore next */
  protected createEditArticleTravelDurationsForm(position: number | string): FormlyFieldConfig[] {
    return [
      {
        key: 'travelDurationInHours',
        type: 'core-portal-input-group-input',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-6',
        defaultValue: 0,
        templateOptions: {
          corePortalTranslated: {
            hideLabel: true,
            formGroupClassName: 'mb-0'
          },
          validationMessages: {
            positive: {
              key: 'core-portal.core.validation.min',
              args: { min: 0 }
            }
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.BASIC,
            template: value => `${ value } ${ this.translate.instant('core-shared.shared.time-picker.hours') }`
          } as CorePortalFormlyReadonlyTyping,
          corePortalInputGroupInput: {
            append: of([this.translate.instant('core-shared.shared.time-picker.hours')])
          },
          type: 'number',
          required: true,
          min: 0
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        },
        hooks: {
          onInit: field => this.subscribe(field.form.valueChanges.pipe(
            startWith(field.form.value),
            distinctUntilChanged()
          ), () => {
            const articlesValid = this.articlesValidSubject.getValue();
            this.articlesValidSubject.next({
              ...articlesValid,
              [position]: field.form.valid
            });
          })
        }
      },
      {
        key: 'travelDurationCost',
        type: 'core-portal-input-group-input',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-6',
        defaultValue: 0,
        templateOptions: {
          corePortalTranslated: {
            hideLabel: true,
            formGroupClassName: 'mb-0'
          },
          validationMessages: {
            positive: {
              key: 'core-portal.core.validation.min',
              args: { min: 0 }
            }
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.BASIC,
            template: value => `${ value } €`
          } as CorePortalFormlyReadonlyTyping,
          corePortalInputGroupInput: {
            append: of(['€'])
          },
          type: 'number',
          required: true,
          min: 0
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        },
        hooks: {
          onInit: field => this.subscribe(field.form.valueChanges.pipe(
            startWith(field.form.value),
            distinctUntilChanged()
          ), () => {
            const articlesValid = this.articlesValidSubject.getValue();
            this.articlesValidSubject.next({
              ...articlesValid,
              [position]: field.form.valid
            });
          })
        }
      }
    ];
  }

  /* istanbul ignore next */
  protected createEditArticleWorkDurationsForm(position: number | string): FormlyFieldConfig[] {
    return [
      {
        key: 'workDurationInHours',
        type: 'core-portal-input-group-input',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-6',
        defaultValue: 0,
        templateOptions: {
          corePortalTranslated: {
            hideLabel: true,
            formGroupClassName: 'mb-0'
          },
          validationMessages: {
            positive: {
              key: 'core-portal.core.validation.min',
              args: { min: 0 }
            }
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.BASIC,
            template: value => `${ value } ${ this.translate.instant('core-shared.shared.time-picker.hours') }`
          } as CorePortalFormlyReadonlyTyping,
          corePortalInputGroupInput: {
            append: of([this.translate.instant('core-shared.shared.time-picker.hours')])
          },
          type: 'number',
          required: true,
          min: 0
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        },
        hooks: {
          onInit: field => this.subscribe(field.form.valueChanges.pipe(
            startWith(field.form.value),
            distinctUntilChanged()
          ), () => {
            const articlesValid = this.articlesValidSubject.getValue();
            this.articlesValidSubject.next({
              ...articlesValid,
              [position]: field.form.valid
            });
          })
        }
      },
      {
        key: 'workDurationCost',
        type: 'core-portal-input-group-input',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-6',
        defaultValue: 0,
        templateOptions: {
          corePortalTranslated: {
            hideLabel: true,
            formGroupClassName: 'mb-0'
          },
          validationMessages: {
            positive: {
              key: 'core-portal.core.validation.min',
              args: { min: 0 }
            }
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.BASIC,
            template: value => `${ value ?? 0 } €`
          } as CorePortalFormlyReadonlyTyping,
          corePortalInputGroupInput: {
            append: of(['€'])
          },
          type: 'number',
          required: true,
          min: 0
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        },
        hooks: {
          onInit: field => this.subscribe(field.form.valueChanges.pipe(
            startWith(field.form.value),
            distinctUntilChanged()
          ), () => {
            const articlesValid = this.articlesValidSubject.getValue();
            this.articlesValidSubject.next({
              ...articlesValid,
              [position]: field.form.valid
            });
          })
        }
      }
    ];
  }

  private resetArticlesForm(): void {
    this.addArticleForm = new FormGroup({});
    this.addArticlesFields = this.createAddArticleForm();
  }
}
