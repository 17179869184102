import {AppPermissions} from "@nexnox-web/core-shared";


export interface SettingsMenuDto {
  chapters: SettingsMenuConfigChapterDto[]
}

export interface SettingsMenuConfigChapterDto {
  title: string;
  categories: SettingsMenuConfigCategoryDto[];
  isHidden?: boolean;
  isExpanded?: boolean;
  hasAccess?: boolean;
};

export interface SettingsMenuConfigCategoryDto {
  title: string;
  items: SettingsMenuConfigItemDto[];
  chapter?: string;
  isExpanded?: boolean;
  hasAccess?: boolean;
  isHidden?: boolean;
}

export interface SettingsMenuConfigItemDto {
  title: string;
  link: string[];
  permissions?: AppPermissions[];
  disableCreate?: boolean;
  hasAccess?: boolean;
  isHidden?: boolean;
}

