import {EntityXsStore, EntityXsStoreState} from '@nexnox-web/core-store';
import {AppEntityType, ResourcesByLocationDefinitionDto} from '@nexnox-web/core-shared';
import {Action, createSelector} from '@ngrx/store';
import {Injectable, Injector} from '@angular/core';
import {selectStereotypesState} from './../../stereotypes.selector';
import {ResourcesByLocationStereotypeService} from "./../../services";

export interface ResourcesByLocationStereotypeDetailStoreState extends EntityXsStoreState<ResourcesByLocationDefinitionDto> {
}

export const resourcesByLocationStereotypeDetailStore = new EntityXsStore<ResourcesByLocationDefinitionDto>({
  actionLabel: 'Core Portal - Settings - Stereotypes - Recourses By Location Stereotype Detail',
  stateSelector: createSelector(selectStereotypesState, state => state.resourcesByLocationStereotypeDetail),
  serviceType: ResourcesByLocationStereotypeService,
  stereotyped: false,
  entityType: AppEntityType.None
});

export function resourcesByLocationStereotypeDetailStoreReducer(state: ResourcesByLocationStereotypeDetailStoreState,
                                                                action: Action): any {
  return resourcesByLocationStereotypeDetailStore.reducer(state, action);
}

@Injectable()
export class ResourcesByLocationStereotypeDetailStoreEffects extends resourcesByLocationStereotypeDetailStore.effects {
  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }
}
