import {
  TechPortalContactService,
  TechPortalContractService,
  TechPortalLinkConfig,
  TechPortalMissionService,
  TechPortalResourceService,
  TechPortalTicketService
} from '@nexnox-web/tech-portal-lib';
import {LinkedElementType} from '@nexnox-web/core-shared';

export function provideLinksConfig(): TechPortalLinkConfig[] {
  return [
    {
      elementType: LinkedElementType.Ticket,
      idKey: 'ticketId',
      displayKey: 'title',
      detailLink: '/tickets',
      module: 'communication',
      serviceType: TechPortalTicketService,
      labelTranslation: 'core-shared.shared.entity-types.1'
    },
    {
      elementType: LinkedElementType.Mission,
      idKey: 'missionId',
      displayKey: 'title',
      detailLink: '/missions',
      module: 'inventory',
      serviceType: TechPortalMissionService,
      labelTranslation: 'core-shared.shared.entity-types.20'
    },
    {
      elementType: LinkedElementType.Resource,
      idKey: 'resourceId',
      displayKey: 'name',
      detailLink: '/resources',
      module: 'inventory',
      serviceType: TechPortalResourceService,
      labelTranslation: 'core-shared.shared.entity-types.3'
    },
    {
      elementType: LinkedElementType.Contract,
      idKey: 'contractId',
      displayKey: 'title',
      detailLink: '/contracts',
      module: 'inventory',
      serviceType: TechPortalContractService,
      labelTranslation: 'core-shared.shared.entity-types.21'
    },
    {
      elementType: LinkedElementType.Contact,
      idKey: 'contactId',
      displayKey: 'displayName',
      detailLink: '/masterdata/contacts',
      module: 'management',
      serviceType: TechPortalContactService,
      labelTranslation: 'core-shared.shared.entity-types.6'
    }
  ];
}
