import {ResourceTicketSkeletonSelectorType} from "@nexnox-web/core-shared";

export const resourceTicketSkeletonSelectorTypeEnumOptions = [
  { label: 'core-portal.settings.ticket-skeleton-selector-types.1', value: ResourceTicketSkeletonSelectorType.All },
  {
    label: 'core-portal.settings.ticket-skeleton-selector-types.2',
    value: ResourceTicketSkeletonSelectorType.RestrictedByType
  },
  {
    label: 'core-portal.settings.ticket-skeleton-selector-types.3',
    value: ResourceTicketSkeletonSelectorType.Whitelisted
  },
  { label: 'core-portal.settings.ticket-skeleton-selector-types.4', value: ResourceTicketSkeletonSelectorType.Fixed },
];
