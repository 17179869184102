<div [ngSwitch]="mode.asObservable() | async">
  <ng-container *ngSwitchCase="dataTableViewType.Map">
    <nexnox-web-missions-mission-map-view [disableSettingsViewSelect]="disableSettingsViewSelect"
                                          [mode]="mode">
    </nexnox-web-missions-mission-map-view>
  </ng-container>

  <ng-container *ngSwitchCase="dataTableViewType.Gantt">
    <nexnox-web-missions-mission-gantt-view [mode]="mode"
                                            [parentBaseComponent]="this">
    </nexnox-web-missions-mission-gantt-view>
  </ng-container>

  <ng-container *ngSwitchDefault>
    <ng-template #entityEditTemplate let-title="title">
      <nexnox-web-missions-mission-create
        #editComponent
        (modelChange)="createModel$.next($event)"
        [creating]="true"
        [loading]="(loaded$ | async) === false"
        [missionType]="createMissionTypeSubject | async"
        [model]="createModel$ | async"
        [readonly]="false"
        [stereotypes$]="stereotypes$"
        [title]="(isCreateVisible$ | async) === false ? title : (createTitle$ | async | translate)">
      </nexnox-web-missions-mission-create>
    </ng-template>

    <nexnox-web-entity-overview
      #overviewComponent
      [cardBoxHeaderActions]="getCardBoxHeaderActions()"
      [enableCalendarSubscription]="true"
      [entityEditTemplate]="entityEditTemplate"
      [entityOverviewBaseComponent]="this"
      detailLink="/missions"
      module="inventory">
    </nexnox-web-entity-overview>
  </ng-container>
</div>
