<div class="alert alert-info d-flex align-items-center w-100">
  <fa-icon [icon]="faQuestionCircle" class="mr-2"></fa-icon>
  <span>
    {{ 'core-portal.master-data.descriptions.new-functions' | translate }}
    <a (click)="onNavigateToAreas($event)"
       class="text-white font-weight-bold"
       href="">
      {{ 'core-portal.master-data.subtitles.contact-area-list' | translate }}
      <fa-icon [icon]="faArrowRight"></fa-icon>
    </a>
  </span>
</div>
