<ng-template #entityEditTemplate
             let-id="id"
             let-title="title">
  <nexnox-web-devices-device-edit #modelComponent
                                  (modelChange)="onModelChange($event)"
                                  [creating]="false"
                                  [id]="id"
                                  [loading]="loading$ | async"
                                  [model]="model$ | async"
                                  [readonly]="true"
                                  [stereotyped]="false"
                                  [title]="title">
  </nexnox-web-devices-device-edit>

  <ng-container *ngIf="displayPings$ | async">
    <nexnox-web-devices-device-ping-list *ngIf="(model$ | async)?.id as deviceId"
                                         [canCreate]="false"
                                         [deviceId]="deviceId"
                                         [getEntityOnModeChange]="false"
                                         [isEmbedded]="true">
    </nexnox-web-devices-device-ping-list>
  </ng-container>
</ng-template>

<nexnox-web-entity-detail [entityDetailBaseComponent]="this"
                          [entityEditTemplate]="entityEditTemplate"
                          [tabCommands]="['.']"
                          [tabHeading]="title | translate"
                          tabId="device">

  <!-- Metadata Tab -->
  <ng-template (selectTab)="onNavigateToTab(['metadata'])"
               *ngIf="true"
               [heading]="'orga-portal.devices.subtitles.metadata-list' | translate"
               [lazy]="true"
               nexnoxWebTabTemplate
               tabId="metadata">
    <nexnox-web-devices-device-metadata-list *ngIf="passedIds"
                                             [canCreate]="false"
                                             [canDelete]="false"
                                             [isEmbedded]="true"
                                             [parentIds]="passedIds">
    </nexnox-web-devices-device-metadata-list>
  </ng-template>
</nexnox-web-entity-detail>
