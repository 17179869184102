import {StereotypeDto} from '@nexnox-web/core-shared';
import {EntityXsStoreEntityData} from './models';

export interface EntityXsStoreState<E, M = E> {
  entity: E;
  model: M;
  loading: boolean;
  loaded: boolean;
  stereotypes: StereotypeDto[];
  stereotypesLoading: boolean;
  stereotypesLoaded: boolean;
  entityData: EntityXsStoreEntityData;
}

export const getInitialEntityXsStoreState = <E, M>(): EntityXsStoreState<E, M> => ({
  entity: null,
  model: null,
  loading: false,
  loaded: false,
  stereotypes: [],
  stereotypesLoading: false,
  stereotypesLoaded: false,
  entityData: { loading: {} }
});
