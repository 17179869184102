<p-selectButton [formControl]="formControl"
                [formlyAttributes]="field"
                [multiple]="true"
                [options]="to.corePortalMultiToggle?.items"
                optionLabel="label"
                optionValue="value">
  <ng-template let-item>
    <span>
      <ng-container
        *ngIf="to.corePortalMultiToggle?.translate; else notTranslatedTemplate">{{ item.label | translate }}</ng-container>

      <ng-template #notTranslatedTemplate>
        {{ item.label }}
      </ng-template>
    </span>
  </ng-template>
</p-selectButton>
