<div class="d-flex flex-row">
  <!-- Negative -->
  <div *ngIf="allowNegative"
       class="mr-1 mt-2">
    <p-checkbox (ngModelChange)="onNegativeChange($event)"
                [binary]="true"
                [checkboxIcon]="faMinus | nexnoxWebFaIconStringPipe"
                [ngModel]="isNegative$ | async">
    </p-checkbox>
  </div>

  <!-- Years -->
  <div *ngIf="showYears"
       class="input-group time-input-group mr-1">
    <input (blur)="onBlurYears()"
           (keyup.arrowDown)="onStepYears(true)"
           (keyup.arrowUp)="onStepYears(false)"
           (ngModelChange)="onYearsChange($event)"
           [class.is-invalid]="hasError"
           [disabled]="isDisabled$ | async"
           [maxLength]="4"
           [ngModel]="years$ | async"
           [title]="'core-shared.shared.time-picker.years' | translate"
           class="d-inline-block form-control time-input text-center"
           inputmode="decimal"
           mask="9999"
           type="text">
    <div class="input-group-append">
      <ng-container [ngTemplateOutletContext]="{
                      step: stepYearsFn,
                      downExpression: (years$ | async) <= 0
                    }"
                    [ngTemplateOutlet]="stepperTemplate">
      </ng-container>
    </div>
    <small class="text-muted flex-basis-100">{{ 'core-shared.shared.time-picker.years' | translate }}</small>
  </div>
  <span *ngIf="showYears && (showWeeks || showDays || showHours || showMinutes)" class="separator">:</span>

  <!-- Weeks -->
  <div *ngIf="showWeeks"
       [class.ml-1]="showYears"
       class="input-group time-input-group mr-1">
    <input (blur)="onBlurWeeks()"
           (keyup.arrowDown)="onStepWeeks(true)"
           (keyup.arrowUp)="onStepWeeks(false)"
           (ngModelChange)="onWeeksChange($event)"
           [class.is-invalid]="hasError"
           [disabled]="isDisabled$ | async"
           [maxLength]="4"
           [ngModel]="weeks$ | async"
           [title]="'core-shared.shared.time-picker.weeks' | translate"
           class="d-inline-block form-control time-input text-center"
           inputmode="decimal"
           mask="9999"
           type="text">
    <div class="input-group-append">
      <ng-container [ngTemplateOutletContext]="{
                      step: stepWeeksFn,
                      downExpression: (weeks$ | async) <= 0
                    }"
                    [ngTemplateOutlet]="stepperTemplate">
      </ng-container>
    </div>
    <small class="text-muted flex-basis-100">{{ 'core-shared.shared.time-picker.weeks' | translate }}</small>
  </div>
  <span *ngIf="showWeeks && (showDays || showHours || showMinutes)" class="separator">:</span>

  <!-- Days -->
  <div *ngIf="showDays"
       [class.ml-1]="showYears || showWeeks"
       class="input-group time-input-group mr-1">
    <input (blur)="onBlurDays()"
           (keyup.arrowDown)="onStepDays(true)"
           (keyup.arrowUp)="onStepDays(false)"
           (ngModelChange)="onDaysChange($event)"
           [class.is-invalid]="hasError"
           [disabled]="isDisabled$ | async"
           [maxLength]="4"
           [ngModel]="days$ | async"
           [title]="'core-shared.shared.time-picker.days' | translate"
           class="d-inline-block form-control time-input text-center"
           inputmode="decimal"
           mask="9999"
           type="text">
    <div class="input-group-append">
      <ng-container [ngTemplateOutletContext]="{
                      step: stepDaysFn,
                      downExpression: (days$ | async) <= 0
                    }"
                    [ngTemplateOutlet]="stepperTemplate">
      </ng-container>
    </div>
    <small class="text-muted flex-basis-100">{{ 'core-shared.shared.time-picker.days' | translate }}</small>
  </div>
  <span *ngIf="showDays && (showHours || showMinutes)" class="separator">:</span>

  <!-- Hours -->
  <div *ngIf="showHours"
       [class.ml-1]="showYears ||showWeeks || showDays"
       class="input-group time-input-group mr-1">
    <input (blur)="onBlurHours()"
           (keyup.arrowDown)="onStepHours(true)"
           (keyup.arrowUp)="onStepHours(false)"
           (ngModelChange)="onHoursChange($event)"
           [class.is-invalid]="hasError"
           [disabled]="isDisabled$ | async"
           [maxLength]="2"
           [ngModel]="hours$ | async"
           [title]="'core-shared.shared.time-picker.hours' | translate"
           class="d-inline-block form-control time-input text-center"
           inputmode="decimal"
           mask="Hh"
           type="text">
    <div class="input-group-append">
      <ng-container [ngTemplateOutletContext]="{
                      step: stepHoursFn,
                      upExpression: (hours$ | async) >= maxHours,
                      downExpression: (hours$ | async) <= minHours
                    }"
                    [ngTemplateOutlet]="stepperTemplate">
      </ng-container>
    </div>
    <small class="text-muted flex-basis-100">{{ 'core-shared.shared.time-picker.hours' | translate }}</small>
  </div>
  <span *ngIf="showHours && showMinutes" class="separator">:</span>

  <!-- Minutes -->
  <div *ngIf="showMinutes"
       [class.ml-1]="showYears ||showWeeks || showDays || showHours"
       class="input-group time-input-group">
    <input (blur)="onBlurMinutes()"
           (keyup.arrowDown)="onStepMinutes(true)"
           (keyup.arrowUp)="onStepMinutes(false)"
           (ngModelChange)="onMinutesChange($event)"
           [class.is-invalid]="hasError"
           [disabled]="isDisabled$ | async"
           [maxLength]="2"
           [ngModel]="minutes$ | async"
           [title]="'core-shared.shared.time-picker.minutes' | translate"
           class="d-inline-block form-control time-input text-center"
           inputmode="decimal"
           mask="m0"
           type="text">
    <div class="input-group-append">
      <ng-container [ngTemplateOutletContext]="{
                      step: stepMinutesFn,
                      upExpression: (minutes$ | async) >= maxMinutes,
                      downExpression: (minutes$ | async) <= minMinutes
                    }"
                    [ngTemplateOutlet]="stepperTemplate">
      </ng-container>
    </div>
    <small class="text-muted flex-basis-100">{{ 'core-shared.shared.time-picker.minutes' | translate }}</small>
  </div>

  <button (click)="onClear()"
          *ngIf="(valid$ | async) && (isDisabled$ | async) === false && canClear"
          [title]="'core-shared.shared.select.clear' | translate"
          class="btn align-self-start clear-btn">
    <fa-icon [icon]="faTimes"></fa-icon>
  </button>
</div>

<ng-template #stepperTemplate
             let-downExpression="downExpression"
             let-step="step"
             let-upExpression="upExpression">
  <span class="input-group-text step-text-container d-none d-xl-flex">
    <fa-icon (click)="step(false, !upExpression)"
             [class.disabled]="(isDisabled$ | async) || upExpression"
             [icon]="faCaretUp"
             class="step-icon-up">
    </fa-icon>

    <fa-icon (click)="step(true, !downExpression)"
             [class.disabled]="(isDisabled$ | async) || downExpression"
             [icon]="faCaretDown"
             class="step-icon-down">
    </fa-icon>
  </span>
</ng-template>
