import {NgModule} from '@angular/core';
import {CoreSharedModule, CoreSharedSortableListModule} from '@nexnox-web/core-shared';
import {CorePortalEntityModule} from '@nexnox-web/core-portal';
import {holidaysServices} from './store';
import {CorePortalFeatureMasterDataHolidaysEditComponent} from './components';
import {HolidaysDetailComponent, HolidaysListComponent} from './containers';

@NgModule({
  declarations: [
    HolidaysListComponent,
    HolidaysDetailComponent,

    CorePortalFeatureMasterDataHolidaysEditComponent
  ],
  imports: [
    CoreSharedModule,
    CorePortalEntityModule,
    CoreSharedSortableListModule
  ],
  exports: [
    CorePortalFeatureMasterDataHolidaysEditComponent
  ],
  providers: [
    ...holidaysServices
  ]
})
export class HolidaysDeclarationsModule {
}
