<!-- Inheritance -->
<nexnox-web-cardbox *ngIf="hasParent$ | async" [title]="'resources.subtitles.heredity' | translate">
  <form [formGroup]="form">
    <formly-form [(model)]="model"
                 [fields]="fields"
                 [form]="form"
                 class="row w-100">
    </formly-form>
  </form>
</nexnox-web-cardbox>

<!-- Primary table -->
<nexnox-web-cardbox
  [headerActions]="createPrimaryActions()"
  [loading]="loading"
  [title]="('resources.subtitles.primary-editor-suggestions' | translate) +
           (model?.isInheritSuggestions && this.hasParent ? ' (' + ('resources.subtitles.suggestion-inherited-by' | translate:{parent:parentResourceName}) +')' : '')"
  ghostPaddingClasses="p-3"
  noDividerMarginBottom="true"
  paddingClasses="p-0">
  <nexnox-web-basic-table [config]="primaryBasicTableConfig"
                          [data]="primarySuggestionsSubject | async">
  </nexnox-web-basic-table>
</nexnox-web-cardbox>

<!-- Alternative table -->
<nexnox-web-cardbox [headerActions]="createAlternativeActions()"
                    [loading]="loading"
                    [title]="('resources.subtitles.alternative-editor-suggestions' | translate) +
                             (model?.isInheritSuggestions && this.hasParent ? ' (' + ('resources.subtitles.suggestion-inherited-by' | translate:{parent:parentResourceName}) +')' : '')"
                    ghostPaddingClasses="p-3"
                    noDividerMarginBottom="true"
                    paddingClasses="p-0">
  <nexnox-web-basic-table [config]="alternativeBasicTableConfig"
                          [data]="alternativeSuggestionsSubject | async">
  </nexnox-web-basic-table>
</nexnox-web-cardbox>

<!-- Sidebar -->
<nexnox-web-resources-suggestion-edit-sidebar #suggestionEditSidebarComponent
                                              (suggestionSaved)="onSuggestionSaved($event)">
</nexnox-web-resources-suggestion-edit-sidebar>
