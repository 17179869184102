import {Plugin, toWidget} from 'ckeditor5';

import {InsertSimpleVideoCommand} from './insert-simple-video.command';

const sharepointSiteRegex = /^https:\/\/(.+?)\.sharepoint\.com\/:v:\/s\/(.+?)\/(.+?)(\?e=.+?)?$/i;
const sharepointPersonalRegex = /^https:\/\/(.+?)-my\.sharepoint\.com\/:v:\/g\/personal\/(.+?)\/(.+?)(\?e=.+?)?$/i;


export class SimpleVideoEditing extends Plugin {
  constructor(editor) {
    super(editor);

    this.editor.config.define('simpleVideo', {
      platforms: [
        {
          key: 'youtube',
          match: /^(https:\/\/(www\.)?youtube\.com\/watch\?v=)([A-Za-z0-9._%+-]+)$/,
          getId: matches => matches[3] && matches[3].length ? matches[3] : null,
          getEmbed: id => `https://www.youtube.com/embed/${ id }`
        },
        {
          key: 'mp4',
          match: /^(https):\/\/.+\/.+(\.mp4)$/i,
          getId: match => match[0],
          getEmbed: url => url
        },
        {
          key: 'sharepoint',
          match: sharepointSiteRegex,
          getId: match => match[0],
          getEmbed: url => createSharePointDownloadUrl(url)
        },
        {
          key: 'sharepointPersonal',
          match: sharepointPersonalRegex,
          getId: match => match[0],
          getEmbed: url => createSharePointDownloadUrl(url)
        }
      ]
    });
  }

  public static get pluginName(): string {
    return 'SimpleVideoEditing';
  }

  public init(): void {
    this._defineSchema();
    this._defineConverters();

    this.editor.commands.add('insertSimpleVideo', new InsertSimpleVideoCommand(this.editor));
  }

  private _defineSchema(): void {
    const schema = this.editor.model.schema;

    schema.register('simpleVideo', {
      isObject: true,
      allowIn: '$root',
      allowAttributes: ['src']
    });
  }

  private _defineConverters(): void {
    this.editor.conversion.for('upcast').elementToElement({
      view: {
        name: 'iframe'
      },
      model: (viewElement, { writer }) => upcastSimpleVideo(writer, viewElement)
    });

    this.editor.conversion.for('upcast').elementToElement({
      view: {
        name: 'video'
      },
      model: (viewElement, { writer }) => upcastSimpleVideo(writer, viewElement)
    });

    this.editor.conversion.for('dataDowncast').elementToElement({
      model: 'simpleVideo',
      view: (modelElement, { writer }) => {
        // @ts-ignore
        const isYoutube = modelElement.getAttribute('src').startsWith('https://www.youtube.com/');

        if (isYoutube) {
          return writer.createRawElement('iframe', {
            src: modelElement.getAttribute('src') || '',
            allow: 'fullscreen',
            width: 640,
            height: 360
          });
        } else {
          return writer.createRawElement('video', {
            src: modelElement.getAttribute('src') || '',
            width: 640,
            height: 'auto',
            controls: true,
            controlslist: 'nodownload',
            style: 'max-width:100%'
          });
        }
      }
    });

    this.editor.conversion.for('editingDowncast').elementToElement({
      model: 'simpleVideo',
      view: (modelElement, { writer }) => toWidget(downcastSimpleVideo(writer, modelElement), writer, {
        label: 'Simple video widget'
      })
    });
  }
}

function upcastSimpleVideo(writer, viewElement): any {
  const url = viewElement.getAttribute('src') || '';

  return writer.createElement('simpleVideo', {
    src: url
  });
}

function downcastSimpleVideo(writer, modelElement): any {
  const url = modelElement.getAttribute('src') || '';
  const isYoutube = url.startsWith('https://www.youtube.com/');

  const simpleVideo = writer.createContainerElement('figure', {
    class: 'simple-video'
  });

  const simpleVideoWrapper = writer.createContainerElement('div', {
    class: 'simple-video-wrapper'
  });

  if (isYoutube) {
    const iframeWrapper = writer.createContainerElement('div', {
      style: 'position: relative; margin: 0; padding-bottom: 100%; height: 0; padding-bottom: 56.2493%;'
    });

    const iframe = writer.createRawElement('iframe', {
      src: url,
      style: 'position: absolute; top: 0; left: 0;',
      frameborder: 0,
      width: 640,
      height: 360,
      allow: 'autoplay; encrypted-media; fullscreen'
    });

    writer.insert(writer.createPositionAt(iframeWrapper, 0), iframe);
    writer.insert(writer.createPositionAt(simpleVideoWrapper, 0), iframeWrapper);
    writer.insert(writer.createPositionAt(simpleVideo, 0), simpleVideoWrapper);
  } else {
    const videoWrapper = writer.createContainerElement('div', {
      style: 'position: relative; margin: 0; padding-bottom: 100%; height: 0; padding-bottom: 56.2493%;'
    });

    const video = writer.createRawElement('video', {
      src: url,
      style: 'position: absolute; top: 0; left: 0;',
      width: 640,
      height: 'auto',
      controls: true
    });

    writer.insert(writer.createPositionAt(videoWrapper, 0), video);
    writer.insert(writer.createPositionAt(simpleVideoWrapper, 0), videoWrapper);
    writer.insert(writer.createPositionAt(simpleVideo, 0), simpleVideoWrapper);
  }

  return simpleVideo;
}

function createSharePointDownloadUrl(raw = ''): any {
  if (raw.includes('sharepoint.com/:v:/') !== true) {
    return null;
  }

  const generateDownloadUrl = (host, site, secret): string => {
    return `https://${ host }.sharepoint.com/${ site }/_layouts/download.aspx?share=${ secret }`;
  };

  if (sharepointSiteRegex.test(raw)) {
    const [_, host, name, secret] = sharepointSiteRegex.exec(raw);
    return generateDownloadUrl(host, `sites/${ name }`, secret);
  }

  if (sharepointPersonalRegex.test(raw)) {
    const [_, host, name, secret] = sharepointPersonalRegex.exec(raw);
    return generateDownloadUrl(`${ host }-my`, `personal/${ name }`, secret);
  }

  throw new Error('The given video url doesn\'t match with regular sharepoint or sharepoint personal urls.');
}
