import {Directive, Injector, Input, OnInit, Type} from '@angular/core';
import {CompositeMapper} from '@azure/ms-rest-js';
import {
  AppEntityType,
  AppPermissions,
  CoreSharedApiBaseService,
  CoreSharedModalBaseComponent,
  DataTableDto,
  FilterDto,
  Paging,
  StereotypeDto
} from '@nexnox-web/core-shared';
import {PagedEntitiesXsStoreEntity} from '@nexnox-web/core-store';
import {flatten} from 'lodash';
import {NEVER, Observable, of} from 'rxjs';
import {CorePortalStereotypeService} from '../../../../../services/api';
import {CORE_PORTAL_DATATABLE_STANDARD_CONFIG, CorePortalDatatableStandardConfig} from '../../../../../tokens';
import {
  CorePortalDatatableColumnService,
  CorePortalEntityDatatableComponent,
  CorePortalEntityDatatableObservableStore,
  DatatableLoadPagePayload,
  DatatableTableColumnType,
  DatatableTableColumnTyping
} from '../../../entity-datatable';

@Directive()
export abstract class CorePortalEntitySelectDatatableSearchModalBaseComponent<T> extends CoreSharedModalBaseComponent implements OnInit {
  @Input() public defaultFilters: FilterDto[] = [];

  public entities$: Observable<PagedEntitiesXsStoreEntity<T>[]> = NEVER;
  public loading$: Observable<boolean> = NEVER;
  public loaded$: Observable<boolean> = of(false);
  public paging$: Observable<Paging> = NEVER;
  public stereotypes$: Observable<StereotypeDto[]> = NEVER;

  public datatableConfigName: string;
  public componentId: string;

  public excludedColumns: string[] = [];
  public defaultColumns: string[] = [];
  public defaultColumnTypings: DatatableTableColumnTyping[] = [];

  protected store: CorePortalEntityDatatableObservableStore<T>;
  protected datatableConfig: CorePortalDatatableStandardConfig;
  protected datatableColumnService: CorePortalDatatableColumnService;

  protected selectedEntity: T = null;
  protected stereotyped = true;

  protected constructor(
    protected injector: Injector,
    public idKey: keyof T,
    public displayKey: keyof T,
    protected serviceType: Type<CoreSharedApiBaseService>,
    public entityType: AppEntityType,
    protected serializedNameOrMapper: string | CompositeMapper
  ) {
    super(injector);

    this.store = new CorePortalEntityDatatableObservableStore<T>(
      this.injector,
      this.idKey.toString(),
      this.serviceType,
      this.entityType,
      undefined,
      typeof this.serializedNameOrMapper === 'string' ? this.serializedNameOrMapper : this.serializedNameOrMapper.serializedName
    );

    this.datatableConfig = this.injector.get(CORE_PORTAL_DATATABLE_STANDARD_CONFIG);
    this.datatableColumnService = this.injector.get(CorePortalDatatableColumnService);
  }

  /* istanbul ignore next */
  public ngOnInit(): void {
    super.ngOnInit();

    if (this.datatableConfigName && this.datatableConfig && this.datatableConfig[this.datatableConfigName]) {
      const config = this.datatableConfig[this.datatableConfigName];

      this.excludedColumns = [...this.excludedColumns, ...config.excludedColumns];
      this.defaultColumns = this.defaultColumns ? this.defaultColumns : config.defaultColumns;
      this.defaultColumnTypings = [...this.getDefaultColumnTypings(), ...this.defaultColumnTypings, ...config.columnTypings];
    }

    this.entities$ = this.store.selectEntities();
    this.loading$ = this.store.selectLoading();
    this.loaded$ = this.store.selectLoaded();
    this.paging$ = this.store.selectPaging();
    this.stereotypes$ = this.store.selectStereotypes();

    this.getEntities();
  }

  public createColumns(datatableComponent: CorePortalEntityDatatableComponent): void {
    datatableComponent.createColumns(this.serializedNameOrMapper);
  }

  public async onLoadPage(payload: DatatableLoadPagePayload): Promise<void> {
    const config = await this.getConfig();

    this.store.getPage(
      payload.pageNumber,
      payload.sortOptions,
      payload.filters,
      10,
      config
    );
  }

  public onDetail(row: T): void {
    this.selectedEntity = row;
    this.swalInstance.clickConfirm();
  }

  /* istanbul ignore next */
  protected async onPreConfirm(): Promise<T> {
    return this.selectedEntity;
  }

  /* istanbul ignore next */
  protected getDefaultColumnTypings(): DatatableTableColumnTyping[] {
    const columns = this.datatableColumnService
      .getDataColumns(this.serializedNameOrMapper, this.excludedColumns)
      .filter(x => x.serializedName);

    return (this.stereotyped ? [
      ...flatten(columns.filter(x => x.serializedName === 'StereotypeSimpleListDto').map(x => this.getStereotypeColumnTyping(x.name)))
    ] : []).concat(...this.defaultColumnTypings);
  }

  /* istanbul ignore next */
  protected getStereotypeColumnTyping(key: string): DatatableTableColumnTyping[] {
    return [
      {
        key,
        name: key,
        type: DatatableTableColumnType.REFERENCE,
        idKey: 'stereotypeId',
        displayKey: 'name',
        service: CorePortalStereotypeService,
        permissions: [AppPermissions.ReadStereotype]
      },
      {
        key: `${ key }.isArchived`,
        name: `${ key }.is-archived`,
        type: DatatableTableColumnType.BOOLEAN
      }
    ];
  }

  protected async getConfig(): Promise<DataTableDto> {
    return this.datatableColumnService.getDatatableConfig(this.entityType, this.componentId, null, true);
  }

  private async getEntities(): Promise<void> {
    const config = await this.getConfig();

    this.store.getPage(
      1,
      null,
      this.defaultFilters,
      10,
      config
    );
  }
}
