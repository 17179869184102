import {ChangeDetectionStrategy, Component, Injector} from '@angular/core';
import {
  ActionButton,
  CorePortalEntityOverviewBaseComponent,
  DatatableActionButton,
  DeleteEntityModel
} from '@nexnox-web/core-portal';
import {AppEntityType, AppPermissions, ExportType, Mappers, StateMachineDto} from '@nexnox-web/core-shared';
import {stateMachineListStore} from '../../store';

@Component({
  selector: 'nexnox-web-ticket-settings-state-machines-state-machine-list',
  templateUrl: './state-machine-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StateMachineListComponent extends CorePortalEntityOverviewBaseComponent<StateMachineDto> {
  public title = 'core-portal.settings.subtitles.ticket-settings.state-machine-list';
  public createTitle = 'core-portal.settings.subtitles.ticket-settings.state-machine-create';
  public idProperty = 'stateMachineId';
  public displayProperty = 'name';
  public datatableConfigName = 'SettingsTicketSettingsStateMachineDefault';
  public componentId = 'StateMachineListComponent';

  constructor(
    protected injector: Injector
  ) {
    super(injector, stateMachineListStore, Mappers.StateMachineListDto.serializedName, AppEntityType.None);
  }

  /* istanbul ignore next */
  public getRowActionButtons(): DatatableActionButton[] {
    return [
      ...this.getDefaultRowActionButtons(
        'core-portal.settings.actions.ticket-settings.edit-state-machine',
        (row: StateMachineDto) => `/state-machines/${ row.stateMachineId }`,
        [AppPermissions.UpdateStateMachine],
        {
          module: 'settings'
        }
      ),
      this.exportService.getRowActionExportButton(ExportType.STATE_MACHINE, this.idProperty)
    ];
  }

  public getDeleteEntityModel(): DeleteEntityModel {
    return {
      titleKey: 'core-portal.settings.actions.ticket-settings.delete-state-machine',
      descriptionKey: 'core-portal.settings.descriptions.ticket-settings.delete-state-machine',
      confirmKey: 'core-portal.settings.actions.ticket-settings.delete-state-machine',
      deletePermission: AppPermissions.DeleteStateMachine
    };
  }

  protected async getActionButtons(): Promise<ActionButton[]> {
    return this.getDefaultActionButtons(
      'core-portal.settings.actions.ticket-settings.create-state-machine',
      AppPermissions.CreateStateMachine
    );
  }
}
