import {ChangeDetectionStrategy, Component, Injector} from '@angular/core';
import {
  ActionButton,
  CorePortalEntityOverviewBaseComponent,
  DatatableActionButton,
  DeleteEntityModel
} from '@nexnox-web/core-portal';
import {
  AppEntityType,
  AppPermissions,
  ExportType,
  InventoryNumberPatternListDto,
  Mappers
} from '@nexnox-web/core-shared';
import {inventoryNumberListStore} from '../../store/stores';

@Component({
  selector: 'nexnox-web-settings-inventory-number-patterns-inventory-number-pattern-list',
  templateUrl: './inventory-number-pattern-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InventoryNumberPatternListComponent extends CorePortalEntityOverviewBaseComponent<InventoryNumberPatternListDto> {
  public title = 'core-portal.settings.subtitles.inventory-number-pattern-list';
  public createTitle = 'core-portal.settings.subtitles.inventory-number-pattern-create';
  public idProperty = 'inventoryNumberPatternId';
  public displayProperty = 'title';
  public datatableConfigName = 'SettingsInventoryNumberPatternDefault';
  public componentId = 'InventoryNumberPatternListComponent';

  constructor(
    protected injector: Injector
  ) {
    super(injector, inventoryNumberListStore, Mappers.InventoryNumberPatternListDto.serializedName, AppEntityType.None);
  }

  public getRowActionButtons(): DatatableActionButton[] {
    return [
      ...this.getDefaultRowActionButtons(
        'core-portal.settings.actions.edit-inventory-number-pattern',
        (row: InventoryNumberPatternListDto) => `/inventory-number-patterns/${ row.inventoryNumberPatternId }`,
        [AppPermissions.UpdateInventoryNumberPattern],
        {
          module: 'settings'
        }
      ),
      this.exportService.getRowActionExportButton(ExportType.INVENTORY_NUMBER_PATTERN, this.idProperty)
    ];
  }

  public getDeleteEntityModel(): DeleteEntityModel {
    return {
      titleKey: 'core-portal.settings.actions.delete-inventory-number-pattern',
      descriptionKey: 'core-portal.settings.descriptions.delete-inventory-number-pattern',
      confirmKey: 'core-portal.settings.actions.delete-inventory-number-pattern',
      deletePermission: AppPermissions.DeleteInventoryNumberPattern
    };
  }

  protected async getActionButtons(): Promise<ActionButton[]> {
    return this.getDefaultActionButtons(
      'core-portal.settings.actions.create-inventory-number-pattern',
      AppPermissions.CreateInventoryNumberPattern
    );
  }
}
