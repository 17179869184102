<div [class.h-100]="fullHeight"
     [class.is-transparent]="transparent"
     [class.mb-0]="noMarginBottom"
     [class.w-100]="fullWidth"
     class="cardbox w-100 h-100">
  <div class="d-flex align-items-center p-3 cardbox-header">
    <ngx-skeleton-loader [theme]="{
      display: 'flex',
      width: '200px',
      height: '28.5px',
      'margin-bottom': '0'
    }"></ngx-skeleton-loader>
  </div>

  <hr [class.mb-0]="noDividerMarginBottom" class="mt-0">

  <div [class]="paddingClasses">
    <ngx-skeleton-loader [theme]="{
      height: '20px',
      width: '90%'
    }"></ngx-skeleton-loader>
    <ngx-skeleton-loader [theme]="{
      height: '20px',
      width: '60%'
    }"></ngx-skeleton-loader>
    <ngx-skeleton-loader [theme]="{
      height: '20px'
    }"></ngx-skeleton-loader>
    <ngx-skeleton-loader [theme]="{
      height: '20px',
      width: '90%'
    }"></ngx-skeleton-loader>
    <ngx-skeleton-loader [theme]="{
      height: '20px',
      width: '30%'
    }"></ngx-skeleton-loader>
    <br><br>
    <ngx-skeleton-loader [theme]="{
      height: '20px',
      width: '50%'
    }"></ngx-skeleton-loader>
  </div>
</div>
