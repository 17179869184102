<nexnox-web-cardbox [headerActions]="headerActions"
                    [loading]="loading"
                    [title]="'orga-portal.forms.subtitles.form-rows' | translate"
                    ghostPaddingClasses="px-3 pb-3"
                    paddingClasses="px-3 pb-3">
  <nexnox-web-forms-form-row-edit #formRowEditComponent
                                  (delete)="onDelete(row.position)"
                                  (moveDown)="onMoveDown(row.position)"
                                  (moveUp)="onMoveUp(row.position)"
                                  (rowChange)="onRowChange($event, row.position)"
                                  *ngFor="let row of (sortedRows$ | async);
                                          let isFirst = first;
                                          let isLast = last;
                                          trackBy: trackRowsBy"
                                  [isFirst]="isFirst"
                                  [isLast]="isLast"
                                  [position]="row.position"
                                  [readonly]="readonly"
                                  [row]="row">
  </nexnox-web-forms-form-row-edit>

  <nexnox-web-forms-create-form-row-sidebar #createFormRowSidebarComponent
                                            (createFormRow)="onCreateRow($event)">
  </nexnox-web-forms-create-form-row-sidebar>

  <span *ngIf="!(sortedRows$ | async).length"
        class="text-muted">
    {{ 'core-shared.shared.table.empty' | translate }}
  </span>
</nexnox-web-cardbox>
