import {PagedEntitiesXsStore, PagedEntitiesXsStoreState} from '@nexnox-web/core-store';
import {AppEntityType, InventoryNumberPatternListDto} from '@nexnox-web/core-shared';
import {Action, createSelector} from '@ngrx/store';
import {selectInventoryNumbersState} from '../../inventory-numbers.selector';
import {CorePortalFeatureInventoryNumberPatternService} from '../../services';
import {Injectable, Injector} from '@angular/core';

export interface InventoryNumberListStoreState extends PagedEntitiesXsStoreState<InventoryNumberPatternListDto> {
}

/* istanbul ignore next */
export const inventoryNumberListStore = new PagedEntitiesXsStore<InventoryNumberPatternListDto>({
  actionLabel: 'Core Portal - Settings - Inventory Numbers - Inventory Number List',
  stateSelector: createSelector(selectInventoryNumbersState, state => state.inventoryNumberList),
  serviceType: CorePortalFeatureInventoryNumberPatternService,
  entityType: AppEntityType.None,
  selectId: pattern => pattern.entity?.inventoryNumberPatternId ?? pattern.model?.inventoryNumberPatternId
});

export function inventoryNumberListStoreReducer(state: InventoryNumberListStoreState, action: Action): any {
  return inventoryNumberListStore.reducer(state, action);
}

@Injectable()
export class InventoryNumberListStoreEffects extends inventoryNumberListStore.effects {
  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }
}
