import {ChangeDetectionStrategy, Component, Injector, Input, TemplateRef, ViewChild} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {
  CorePortalEntityEditBaseComponent,
  CorePortalFormlyActionWrapperTyping,
  CorePortalFormlyNgSelectTyping,
  CorePortalFormlyReadonlyTypes,
  CorePortalFormlyReadonlyTyping,
  CorePortalFunctionService,
  CorePortalRoleService
} from '@nexnox-web/core-portal';
import {CorePortalFeatureMasterDataCompanyService} from '@nexnox-web/core-portal/features/master-data/features/companies';
import {
  CompanyListSimpleDto,
  ContactDto,
  ContactNotificationBehaviour,
  CrossCreationTypes,
  culturesEnumOptions,
  LocationDto
} from '@nexnox-web/core-shared';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {isString} from 'lodash';
import {Observable} from 'rxjs';
import {ChangeMailAddressSidebarComponent} from '../../sidebars';
import {faPencilAlt} from "@fortawesome/free-solid-svg-icons/faPencilAlt";
import {
  CorePortalFeatureMasterDataLocationService,
  noClosedLocationsFilter$
} from "@nexnox-web/core-portal/features/master-data/features/locations";

const notificationBehaviorEnumOptions = [
  { value: ContactNotificationBehaviour.Unrestricted, label: 'core-shared.shared.contact-notification-behaviour.0' },
  { value: ContactNotificationBehaviour.Blocking, label: 'core-shared.shared.contact-notification-behaviour.-1' }
]

@Component({
  selector: 'nexnox-web-master-data-contacts-contact-edit',
  templateUrl: './contact-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CorePortalFeatureContactEditComponent extends CorePortalEntityEditBaseComponent<ContactDto> {

  @ViewChild('locationSelectLabelTitleTemplate', { static: true }) public locationSelectLabelTitleTemplate: TemplateRef<any>;
  @ViewChild('locationSelectOptionTitleTemplate', { static: true }) public locationSelectOptionTitleTemplate: TemplateRef<any>;

  @ViewChild('changeMailAddressSidebarComponent', { static: true }) public changeMailAddressSidebarComponent: ChangeMailAddressSidebarComponent;

  @Input() public isChangeMailAddressloading$: Observable<boolean>;

  constructor(
    protected injector: Injector,
    private companyService: CorePortalFeatureMasterDataCompanyService,
    private roleService: CorePortalRoleService,
    private functionService: CorePortalFunctionService,
    private locationService: CorePortalFeatureMasterDataLocationService
  ) {
    super(injector, 'CorePortalFeatureContactEditComponent');
  }

  /* istanbul ignore next */
  protected createForm(): FormlyFieldConfig[] {
    return [
      {
        key: 'firstName',
        type: 'input',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.first-name',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.BASIC
          } as CorePortalFormlyReadonlyTyping,
          type: 'text',
          change: () => this.firstNameOrLastNameChange()
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        }
      },
      {
        key: 'lastName',
        type: 'input',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.last-name',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.BASIC
          } as CorePortalFormlyReadonlyTyping,
          type: 'text',
          change: () => this.firstNameOrLastNameChange()
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        }
      },
      {
        key: 'displayName',
        type: 'input',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.display-name',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.BASIC
          } as CorePortalFormlyReadonlyTyping,
          type: 'text'
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        }
      },
      {
        key: 'emailAddress',
        type: 'input',
        wrappers: ['core-portal-translated', 'core-portal-readonly', 'core-portal-actions'],
        className: 'col-md-6',
        templateOptions: {
          type: 'email',
          corePortalTranslated: {
            label: 'core-shared.shared.fields.email-address',
            validationMessages: {
              email: 'core-portal.core.validation.email'
            }
          },
          corePortalActionWrapper: {
            actions: [{
              icon: faPencilAlt,
              tooltip: 'core-portal.core.general.edit',
              loading$: this.isChangeMailAddressloading$,
              hidden: () => this.creating,
              onClick: () => this.changeMailAddressSidebarComponent.onShow()
            }]
          } as CorePortalFormlyActionWrapperTyping,
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.BASIC
          } as CorePortalFormlyReadonlyTyping
        },
        expressionProperties: {
          'templateOptions.disabled': () => !this.creating,
          'templateOptions.readonly': () => this.readonly
        },
        validators: {
          email: ctrl => !Validators.email(ctrl)
        }
      },
      {
        key: 'phone',
        type: 'input',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.phone',
            validationMessages: {
              phone: 'core-portal.core.validation.phone'
            }
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.PHONE
          } as CorePortalFormlyReadonlyTyping,
          type: 'tel'
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        },
        validators: {
          phone: ctrl => this.validatePhone(ctrl)
        }
      },
      {
        key: 'mobile',
        type: 'input',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.mobile',
            validationMessages: {
              phone: 'core-portal.core.validation.phone'
            }
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.PHONE
          } as CorePortalFormlyReadonlyTyping,
          type: 'tel'
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        },
        validators: {
          phone: ctrl => this.validatePhone(ctrl)
        }
      },
      {
        key: 'company',
        type: 'core-portal-entity-select',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        defaultValue: null,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.company'
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.ENTITY,
            displayKey: 'name',
            link: (company: CompanyListSimpleDto) => company?.companyId ? ['/masterdata', 'companies', company.companyId] : null,
            module: 'management'
          } as CorePortalFormlyReadonlyTyping,
          entityService: this.companyService,
          idKey: 'companyId',
          displayKey: 'name',
          wholeObject: true,
          skipGetOne: true,
          link: (company: CompanyListSimpleDto) => company?.companyId ? ['/masterdata', 'companies', company.companyId] : null,
          module: 'management',
          enableCrossCreation: CrossCreationTypes.COMPANY
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        }
      },
      {
        key: 'pin',
        type: 'input',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        defaultValue: null,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.pin',
            validationMessages: {
              number: 'core-portal.core.validation.number',
              length: {
                key: 'core-portal.core.validation.length',
                args: { length: 5 }
              }
            }
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.BASIC
          } as CorePortalFormlyReadonlyTyping,
          type: 'text'
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        },
        validators: {
          number: ctrl => ctrl.value ? !isNaN(ctrl.value) : true,
          length: ctrl => ctrl.value?.toString()?.length ? ctrl.value.toString().length === 5 : true
        }
      },
      {
        key: 'badge',
        type: 'input',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.id-card'
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.BASIC
          } as CorePortalFormlyReadonlyTyping
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        }
      },
      {
        key: 'culture',
        type: 'core-portal-ng-select',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        defaultValue: culturesEnumOptions[0].value,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.culture',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.ENUM,
            enumOptions: culturesEnumOptions,
            translate: true
          } as CorePortalFormlyReadonlyTyping,
          corePortalNgSelect: {
            items: culturesEnumOptions,
            translate: true
          }
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        }
      },
      {
        key: 'access.since',
        type: 'input',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-12',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.locked-out-date'
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.DATE,
            format: 'L LT'
          } as CorePortalFormlyReadonlyTyping,
          readonly: true
        }
      },

      {
        key: 'notificationBehaviour',
        type: 'core-portal-ng-select',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        defaultValue: notificationBehaviorEnumOptions[0].value,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.receive-mail-notifications'
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.ENUM,
            enumOptions: notificationBehaviorEnumOptions,
            translate: true
          } as CorePortalFormlyReadonlyTyping,
          corePortalNgSelect: {
            items: notificationBehaviorEnumOptions,
            translate: true,
            noClear: true
          } as CorePortalFormlyNgSelectTyping
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        }
      },
      {
        key: 'externalId',
        type: 'core-portal-external-id',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.external-id',
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.BASIC
          } as CorePortalFormlyReadonlyTyping,
          titleKey: 'displayName',
          modelSubject: this.modelSubject
        },
        expressionProperties: {
          'templateOptions.readonly': () => this.readonly,
          'templateOptions.disabled': () => this.readonly
        }
      },
      ...this.getStereotypeFields(),
      ...this.getContactFromLocationFields()
    ];
  }

  private getContactFromLocationFields(): FormlyFieldConfig[] {
    const hidden = (): boolean => this.parentComponent !== 'LocationContactListComponent';

    return [
      {
        type: 'core-portal-divider',
        className: 'col-md-12',
        hideExpression: () => this.parentComponent !== 'LocationContactListComponent'
      },
      {
        key: 'location',
        type: 'core-portal-entity-select',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-12',
        defaultValue: null,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.location'
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.ENTITY,
            displayKey: 'name',
            link: (location: LocationDto) => location?.locationId ? ['/masterdata', 'locations', location.locationId] : null,
            module: 'management'
          } as CorePortalFormlyReadonlyTyping,
          entityService: this.locationService,
          idKey: 'locationId',
          displayKey: 'name',
          wholeObject: true,
          multiple: true,
          mapSearchFilter: CorePortalFeatureMasterDataLocationService.mapSearchFilter,
          search: CorePortalFeatureMasterDataLocationService.searchCompare,
          selectLabelTitleTemplate: this.locationSelectLabelTitleTemplate,
          selectOptionTitleTemplate: this.locationSelectOptionTitleTemplate,
          showAll: true,
          defaultFilters$: noClosedLocationsFilter$,
          hidden: hidden()
        },
        expressionProperties: {
          'templateOptions.readonly': () => true
        },
        hideExpression: hidden
      },
      {
        key: 'roles',
        type: 'core-portal-entity-select',
        wrappers: ['core-portal-translated'],
        className: 'col-md-6',
        defaultValue: null,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.roles',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          entityService: this.roleService,
          idKey: 'appRoleId',
          displayKey: 'title',
          wholeObject: true,
          multiple: true,
          showAll: true,
        },
        hideExpression: hidden
      },
      {
        key: 'functions',
        type: 'core-portal-entity-select',
        wrappers: ['core-portal-translated'],
        className: 'col-md-6',
        defaultValue: null,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.functions',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          entityService: this.functionService,
          idKey: 'functionId',
          displayKey: 'name',
          wholeObject: true,
          multiple: true,
          showAll: true,
        },
        hideExpression: hidden
      }
    ]
  }

  private firstNameOrLastNameChange(): void {
    const firstName = this.model && this.model.firstName ? this.model.firstName : '';
    const lastName = this.model && this.model.lastName ? this.model.lastName : '';

    this.form.get('displayName').setValue(`${ firstName } ${ lastName }`);
  }

  private validatePhone(ctrl: FormControl): boolean {
    return !isString(ctrl.value) || !Validators.pattern(/^[+]*[(]?[0-9]{1,4}[)]?[-\s./0-9]*$/)(ctrl);
  }
}
