<nexnox-web-entity-edit [entityEditBaseComponent]="this">
  <div
    *ngIf="model?.note?.type === noteTypes.Mail || model?.note?.type === noteTypes.Text || model?.note?.type === noteTypes.ChatMessage"
    class="px-2 pb-3">
    <h5 class="mb-3">{{ 'tech-portal.subtitles.attachments' | translate }}</h5>

    <nexnox-web-tech-attachments #attachmentsComponent
                                 #modelComponent
                                 (attachmentsChange)="onAttachmentsChange($event)"
                                 [attachments$]="attachments$"
                                 [canAddExisting]="true"
                                 [existingItems$]="solutionAttachmentItems$"
                                 [loading]="false"
                                 [readonly]="false"
                                 [withCardbox]="false">
    </nexnox-web-tech-attachments>
  </div>
</nexnox-web-entity-edit>

<ng-template #mailOptionTemplate let-item="item">
  <span *ngIf="item.emailAddress">&nbsp;<{{ item.emailAddress }}></span>
</ng-template>

<!-- Text Template Apply Sidebar -->
<nexnox-web-templates-text-template-apply-sidebar #textTemplateApplySidebar
                                                  (applied)="onTextTemplateApplied($event)"
                                                  [modelFieldsToInclude]="['id', 'template', 'solutionMember']"
                                                  [referenceModel]="model">
</nexnox-web-templates-text-template-apply-sidebar>
