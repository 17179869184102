import {
  MissionEditorQuestService,
  TechPortalFeatureMissionMyRequestsService,
  TechPortalFeatureMissionReceiptService,
  TechPortalFeatureMissionReportService,
  TechPortalFeatureMissionService
} from './services';
import {
  missionAuditStore,
  MissionAuditStoreEffects,
  missionDetailStore,
  MissionDetailStoreEffects,
  missionEditorQuestStore,
  MissionEditorQuestStoreEffects,
  missionGanttListStore,
  missionListStore,
  MissionMyRequestsEffects,
  missionMyRequestsStore,
  missionReceiptStore,
  MissionReceiptStoreEffects,
  missionReportStore,
  MissionReportStoreEffects,
  MissionSolutionStoreEffects,
  missionSolutionStoreReducer
} from './stores';

export * from './services';
export * from './stores';

export * from './missions.state';
export * from './missions.selectors';

export const missionsReducers = {
  missionList: missionListStore.reducer,
  missionDetail: missionDetailStore.reducer,

  missionSolution: missionSolutionStoreReducer,
  missionReport: missionReportStore.reducer,
  missionReceipt: missionReceiptStore.reducer,
  missionAudit: missionAuditStore.reducer,
  missionGanttList: missionGanttListStore.reducer,
  missionEditorQuest: missionEditorQuestStore.reducer,
  missionMyRequests: missionMyRequestsStore.reducer
};

export const missionsEffects = [
  missionListStore.effects,
  MissionDetailStoreEffects,

  MissionSolutionStoreEffects,
  MissionReportStoreEffects,
  MissionReceiptStoreEffects,
  MissionAuditStoreEffects,
  missionGanttListStore.effects,
  MissionEditorQuestStoreEffects,
  MissionMyRequestsEffects
];

export const missionsServices = [
  TechPortalFeatureMissionService,
  TechPortalFeatureMissionReportService,
  TechPortalFeatureMissionReceiptService,
  MissionEditorQuestService,
  TechPortalFeatureMissionMyRequestsService
];
