import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {CORE_SHARED_ENVIRONMENT} from '../../tokens';
import {Environment} from '../../models';
import {Observable} from 'rxjs';
import {timeout} from 'rxjs/operators';

@Injectable()
export class TimeoutInterceptor implements HttpInterceptor {
  constructor(
    @Inject(CORE_SHARED_ENVIRONMENT) private environment: Environment
  ) {
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const defaultTimeout = this.environment.defaultTimeout;
    return next.handle(request).pipe(
      timeout(defaultTimeout)
    );
  }
}
