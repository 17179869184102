import {NgModule} from '@angular/core';
import {CoreSharedModule} from '@nexnox-web/core-shared';
import {CorePortalEntityOverviewModule} from './entity-overview';
import {CorePortalEntityDatatableModule} from './entity-datatable';
import {CorePortalEntityEditModule} from './entity-edit';
import {CorePortalEntityDetailModule} from './entity-detail';
import {CorePortalEntityCreatePresetService} from './services';
import {CorePortalEntitySelectModule} from './entity-select';
import {CorePortalEntitySelectDatatableSearchModule} from './entity-select-datatable-search';

const exportedModules = [
  CorePortalEntityOverviewModule,
  CorePortalEntityDetailModule,
  CorePortalEntityDatatableModule,
  CorePortalEntityEditModule,
  CorePortalEntitySelectModule,
  CorePortalEntitySelectDatatableSearchModule
];

@NgModule({
  imports: [
    CoreSharedModule,
    ...exportedModules
  ],
  exports: [
    ...exportedModules
  ],
  providers: [
    CorePortalEntityCreatePresetService
  ]
})
export class CorePortalEntityModule {
}
