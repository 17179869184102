import {InjectionToken, NgModule} from '@angular/core';
import {ActionReducerMap, StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {statesEffects, statesReducers} from './store';

export const REDUCER_TOKEN = new InjectionToken<ActionReducerMap<any>>(
  '@nexnox-web/tech-portal/ticket-settings/states: Store Reducers'
);

@NgModule({
  imports: [
    StoreModule.forFeature('tech-portal-feature-ticket-settings-states', REDUCER_TOKEN),
    EffectsModule.forFeature(statesEffects)
  ],
  providers: [
    {
      provide: REDUCER_TOKEN,
      useValue: statesReducers
    }
  ]
})
export class StatesStoreModule {
}
