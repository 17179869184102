<nexnox-web-tabs [internal]="true"
                 [isTransparent]="true"
                 startTab="archive">
  <!-- Archive Tab -->
  <ng-template [heading]="'orga-portal.tasks.subtitles.archive' | translate"
               [lazy]="true"
               nexnoxWebTabTemplate
               tabId="archive">
    <nexnox-web-location-task-archive-list *ngIf="locationId"
                                           [canCreate]="false"
                                           [getEntityOnModeChange]="false"
                                           [isEmbedded]="true"
                                           [locationId]="locationId">
    </nexnox-web-location-task-archive-list>
  </ng-template>

  <!-- Active Tab -->
  <ng-template [heading]="'orga-portal.tasks.subtitles.active' | translate"
               [lazy]="true"
               nexnoxWebTabTemplate
               tabId="active">
    <nexnox-web-location-task-active-list *ngIf="locationId"
                                          [canCreate]="false"
                                          [getEntityOnModeChange]="false"
                                          [isEmbedded]="true"
                                          [locationId]="locationId">
    </nexnox-web-location-task-active-list>
  </ng-template>
</nexnox-web-tabs>
