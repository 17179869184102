<p-sidebar #sidebarComponent
           (visibleChange)="onVisibleChange($event)"
           [transitionOptions]="transitionOptions"
           [visible]="visible$ | async"
           appendTo="body"
           position="right"
           styleClass="p-sidebar-md">
  <h4>{{ 'missions.subtitles.editor-suggestions-current-editor' | translate }}</h4>
  <div class="d-flex flex-row mb-4">
    <ng-container *ngIf="currentSolutionContact; else noSolutionContactTemplate">
      <fa-icon [fixedWidth]="true"
               [icon]="faUser"
               class="mr-2"
               size="sm"></fa-icon>
      <a [nexnoxWebTenantRouterLink]="['/masterdata/contacts', currentSolutionContact.contactId]"
         class="btn-link"
         module="management"
         target="_blank">
        {{ currentSolutionContact.displayName }}
      </a>
    </ng-container>
    <ng-template #noSolutionContactTemplate>
      {{ 'core-shared.shared.select.empty' | translate }}
    </ng-template>
  </div>

  <h4 class="mt-4">{{ 'missions.subtitles.new-solution-contact' | translate }}</h4>
  <div class="row w-100">
    <form [formGroup]="form" class="w-100">
      <formly-form [(model)]="model"
                   [fields]="fields"
                   [form]="form"
                   class="row w-100">
      </formly-form>
    </form>
  </div>
  <div class="w-100 mt-2">
    <button (click)="onUnassignContact()"
            [icon]="faTimes | nexnoxWebFaIconStringPipe"
            [label]="'core-shared.shared.actions.unassign-selection'  | translate"
            class="p-button p-button-secondary mr-3"
            pButton
            type="button">
    </button>

    <button (click)="onAssignMe()"
            [icon]="faUser | nexnoxWebFaIconStringPipe"
            [label]="'core-shared.shared.actions.assign-me' | translate"
            class="p-button p-button-secondary mr-3"
            pButton
            type="button">
    </button>

    <button (click)="onAssignContact()"
            [disabled]="!form.valid"
            [icon]="faUser | nexnoxWebFaIconStringPipe"
            [label]="'core-shared.shared.actions.assign'  | translate"
            class="p-button p-button-primary"
            pButton
            type="button">
    </button>
  </div>
</p-sidebar>
