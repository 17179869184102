import {Injectable, Injector} from '@angular/core';
import {CoreSharedApiBaseService} from '@nexnox-web/core-shared';

@Injectable()
export class CorePortalDashboardService extends CoreSharedApiBaseService {
  constructor(
    protected injector: Injector
  ) {
    super(injector, null, 'dashboard');
  }
}
