<span class="d-inline-flex flex-row align-items-center">
  <fa-icon [icon]="faCircle"
           [style.color]="category.color"
           class="d-flex mr-1"
           size="xs">
  </fa-icon>

  <ng-container *ngIf="!searchTerm; else notLabelTemplate">
    <span [title]="category.name" class="default-text-container">
      <span>{{ category.name }}</span>
    </span>
  </ng-container>

  <ng-template #notLabelTemplate>
    <span [title]="category.name">
      <span [innerHTML]="category.name | nexnoxWebHighlight:searchTerm"></span>
    </span>
  </ng-template>
</span>
