<nexnox-web-entity-edit *ngIf="!creating"
                        [entityEditBaseComponent]="this"
                        displayKey="title">
  <div *ngIf="model && !creating"
       class="d-flex w-100 justify-content-end text-muted p-2">
    <ng-container *ngIf="model.lastModified">
      <span [title]="model.lastModified | nexnoxWebBsDate:'L LT' | async">
        {{ 'core-shared.shared.time-phrases.modified' | translate }} {{ model.lastModified | nexnoxWebDayjsRelative | async }}
      </span>
    </ng-container>
  </div>
</nexnox-web-entity-edit>

<nexnox-web-tabs *ngIf="creating"
                 [internal]="true"
                 [isTransparent]="true"
                 startTab="entityEdit">
  <!-- Entity Edit Tab -->
  <ng-template [hasError]="!isOwnModelValid()"
               [heading]="title | translate"
               nexnoxWebTabTemplate
               tabId="entityEdit">
    <nexnox-web-entity-edit [entityEditBaseComponent]="this"></nexnox-web-entity-edit>
  </ng-template>

  <!-- Links -->
  <ng-template [hasError]="linksComponent ? !linksComponent.isModelValid() : false"
               [heading]="('tech-portal.subtitles.links' | translate) + (linksComponent ? (linksComponent.linksString$ | async) : '')"
               nexnoxWebTabTemplate
               tabId="links">
    <nexnox-web-tech-links #linksComponent
                           (linksChange)="onLinksChange($event)"
                           [links$]="linksSubject"
                           [loading]="loading"
                           [readonly]="readonly">
    </nexnox-web-tech-links>
  </ng-template>

  <!-- Attachments -->
  <ng-template *ngIf="readSolutionPermission$ | async"
               [hasError]="attachmentsComponent ? !attachmentsComponent.isModelValid() : false"
               [heading]="('tech-portal.subtitles.attachments' | translate) + (attachmentsComponent ? (attachmentsComponent.attachmentsString$ | async) : '')"
               nexnoxWebTabTemplate
               tabId="attachments">
    <nexnox-web-tech-attachments #attachmentsComponent
                                 (attachmentsChange)="onAttachmentsChange($event)"
                                 [attachments$]="attachmentsSubject"
                                 [canAddExisting]="true"
                                 [existingItems$]="existingAttachments$"
                                 [loading]="loading"
                                 [readonly]="readonly">
    </nexnox-web-tech-attachments>
  </ng-template>
</nexnox-web-tabs>

<!-- Resource Templates -->
<ng-template #resourceDescriptionTemplate let-item="item">
  <nexnox-web-resources-resource-description [resource]="item">
  </nexnox-web-resources-resource-description>
</ng-template>

<!-- Text Template Apply Sidebar -->
<nexnox-web-templates-text-template-apply-sidebar #textTemplateApplySidebar
                                                  (applied)="onTextTemplateApplied($event)"
                                                  [contextType]="templateContextType$ | async"
                                                  [referenceModel]="model">
</nexnox-web-templates-text-template-apply-sidebar>

<!-- Assign Resource Sidebar -->
<ng-container *ngIf="isShowResourceAssignmentSidebar">
  <nexnox-web-mission-assign-resource-sidebar #assignResourceSidebar
                                              (assignResource)="onResourceChange($event.resource)"
                                              [create]="true"
                                              [location]="location$ | async">
  </nexnox-web-mission-assign-resource-sidebar>
</ng-container>
