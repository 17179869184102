import {ModuleWithProviders, NgModule} from '@angular/core';
import {CompaniesRoutingModule} from './companies-routing.module';
import {CompaniesDeclarationsModule} from './companies-declarations.module';
import {CompaniesStoreModule} from './companies-store.module';

@NgModule({
  declarations: [],
  imports: [
    CompaniesDeclarationsModule,
    CompaniesRoutingModule,
    CompaniesStoreModule
  ]
})
export class CorePortalFeatureMasterDataCompaniesModule {
  public static forFeature(): ModuleWithProviders<CompaniesDeclarationsModule> {
    return { ngModule: CompaniesDeclarationsModule };
  }
}
