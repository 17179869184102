import {ChangeDetectionStrategy, Component, Injector} from '@angular/core';
import {CorePortalEntitySelectDatatableSearchModalBaseComponent} from '@nexnox-web/core-portal';
import {AppEntityType, LocationDto, Mappers} from '@nexnox-web/core-shared';
import {CorePortalFeatureMasterDataLocationService} from '../../store/services';

@Component({
  selector: 'nexnox-web-locations-location-datatable-search-modal',
  templateUrl: './location-datatable-search-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CorePortalFeatureMasterDataLocationDatatableSearchModalComponent
  extends CorePortalEntitySelectDatatableSearchModalBaseComponent<LocationDto> {
  public datatableConfigName = 'MasterDataLocationDefault';

  constructor(
    protected injector: Injector
  ) {
    super(
      injector,
      'locationId',
      'name',
      CorePortalFeatureMasterDataLocationService,
      AppEntityType.Location,
      Mappers.LocationListDto.serializedName
    );
  }
}
