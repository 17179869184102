import {
  ApiNotificationService,
  CalendarConnectionDto,
  CORE_SHARED_ENVIRONMENT,
  CORE_SHARED_PRODUCT,
  CoreSharedHttpRequestService,
  DataTableDto,
  Environment,
  Product
} from '@nexnox-web/core-shared';
import {Inject, Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';

@Injectable()
export class DataTableCalendarSubscriptionService {

  constructor(
    @Inject(CORE_SHARED_PRODUCT) protected product: Product,
    @Inject(CORE_SHARED_ENVIRONMENT) protected environment: Environment,
    private apiNotificationService: ApiNotificationService,
    protected httpRequestService: CoreSharedHttpRequestService
  ) {
  }

  public getCalendarSubscriptionLink<CalendarConnectionDto>(dataTable: DataTableDto): Observable<CalendarConnectionDto> {
    return this.httpRequestService.post<CalendarConnectionDto>(`v2/calendar`, <CalendarConnectionDto><any>{
      calendarDataTable: dataTable
    }).pipe(
      catchError(error => {
        this.apiNotificationService.showTranslatedError('missions.calendar-subscription.loading-error');
        return throwError(error);
      }),
      map(response => response.body),
      tap(() => this.apiNotificationService.showTranslatedSuccess('missions.calendar-subscription.loading-success'))
    );
  }


  public copyTextToClipboard(text: string): void {
    if (!navigator?.clipboard) {
      this._copyToClipBoardFallback(text);
      return;
    }
    navigator.clipboard.writeText(text).then(() => {
      this.apiNotificationService.showTranslatedSuccess('missions.calendar-subscription.copy-success');
    }, (error) => {
      this.apiNotificationService.showTranslatedError('missions.calendar-subscription.copy-error');
    });
  }

  public generateSubscriptionLink(connection: CalendarConnectionDto): string {
    return `${ this.environment?.apiUrl ? this.environment.apiUrl[this.product] : null }${ connection.url }`;
  }

  private _copyToClipBoardFallback(text: string): void {
    const textArea = document.createElement("textarea");
    textArea.value = text;

    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      document.execCommand('copy');
      this.apiNotificationService.showTranslatedSuccess('missions.calendar-subscription.copy-success');
    } catch (error) {
      this.apiNotificationService.showTranslatedError('missions.calendar-subscription.copy-error');
    }

    document.body.removeChild(textArea);
  }
}
