import {ChangeDetectionStrategy, Component, Injector, Input, OnInit} from '@angular/core';
import {CorePortalEntityOverviewBaseComponent} from '@nexnox-web/core-portal';
import {AppEntityType, FilterOperators, FilterTypes, Mappers, PingDto} from '@nexnox-web/core-shared';
import {devicePingListStore} from '../../store';
import {ofType} from '@ngrx/effects';

@Component({
  selector: 'nexnox-web-devices-device-ping-list',
  templateUrl: './device-ping-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DevicePingListComponent extends CorePortalEntityOverviewBaseComponent<PingDto> implements OnInit {
  @Input() public deviceId: number;

  public title = 'orga-portal.devices.subtitles.ping-list';
  public idProperty = 'pingId';
  public displayProperty = 'pingId';
  public datatableConfigName = 'PingDefault';
  public componentId = 'DevicePingListComponent';

  constructor(
    protected injector: Injector
  ) {
    super(injector, devicePingListStore, Mappers.PingListDto.serializedName, AppEntityType.None);
  }

  public ngOnInit(): void {
    this.defaultFilter = [{
      property: 'deviceId',
      type: FilterTypes.DataTransferObject,
      operator: FilterOperators.Equal,
      value: this.deviceId.toString()
    }];

    super.ngOnInit();

    this.subscribe(this.actions$.pipe(ofType(devicePingListStore.actions.pingOneSuccess)), () => this.getEntities());
  }
}
