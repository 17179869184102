import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {authStore} from '@nexnox-web/core-portal';
import {dashboardStore} from './dashboard.store';
import {map} from 'rxjs/operators';
import {Observable} from "rxjs";

@Injectable()
export class DashboardBridgeEffects {
  public changeTenant$: Observable<any>;

  constructor(
    private store: Store<any>,
    private actions$: Actions
  ) {
    this.createEffects();
  }

  protected createEffects(): void {
    this.changeTenant$ = createEffect(() => this.actions$.pipe(
      ofType(authStore.actions.changeTenant),
      map(() => dashboardStore.actions.clear())
    ));
  }
}
