import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {HintCodeDto} from '@nexnox-web/core-shared';
import {BindObservable} from 'bind-observable';
import {Observable} from 'rxjs';
import {map, mergeMap} from 'rxjs/operators';

@Component({
  selector: 'nexnox-web-connected-hint-codes-hint-code-external-id',
  templateUrl: './hint-code-external-id.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TechPortalFeatureHintCodeExternalIdComponent implements OnInit {
  @Input() @BindObservable() public hintCode: HintCodeDto;
  public hintCode$!: Observable<HintCodeDto>;

  @Input() public isLabel = true;
  @Input() public searchTerm: string;

  public title$: Observable<string>;
  public titleHtml$: Observable<string>;
  public titlePrefix$: Observable<string>;
  public titlePrefixHtml$: Observable<string>;

  public static prepareTitlePrefix(hintCode: HintCodeDto): string {
    return `${ hintCode.externalId }: `;
  }

  public static prepareTitle(hintCode: HintCodeDto, prefix: string): string {
    return `${ prefix }${ hintCode.displayName }`;
  }

  public ngOnInit(): void {
    this.titlePrefix$ = this.hintCode$.pipe(
      map(hintCode => TechPortalFeatureHintCodeExternalIdComponent.prepareTitlePrefix(hintCode))
    );

    this.titlePrefixHtml$ = this.titlePrefix$.pipe(
      map(prefix => `<span class="text-muted">${ prefix }</span>`)
    );

    this.title$ = this.hintCode$.pipe(
      mergeMap(hintCode => this.titlePrefix$.pipe(
        map(prefix => TechPortalFeatureHintCodeExternalIdComponent.prepareTitle(hintCode, prefix))
      ))
    );

    this.titleHtml$ = this.hintCode$.pipe(
      mergeMap(hintCode => this.titlePrefixHtml$.pipe(
        map(prefix => TechPortalFeatureHintCodeExternalIdComponent.prepareTitle(hintCode, prefix))
      ))
    );
  }
}
