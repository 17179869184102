import {InjectionToken, NgModule} from '@angular/core';
import {ActionReducerMap, StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {issuesEffects, issuesReducers} from './store';

export const REDUCER_TOKEN = new InjectionToken<ActionReducerMap<any>>('@nexnox-web/tech-portal/issues: Store Reducers');

@NgModule({
  imports: [
    StoreModule.forFeature('tech-portal-feature-issues', REDUCER_TOKEN),
    EffectsModule.forFeature(issuesEffects)
  ],
  providers: [
    {
      provide: REDUCER_TOKEN,
      useValue: issuesReducers
    }
  ]
})
export class IssuesStoreModule {
}
