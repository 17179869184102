import {PagedEntitiesXsStore, PagedEntitiesXsStoreState} from '@nexnox-web/core-store';
import {AppEntityType, ResourceTaskDto} from '@nexnox-web/core-shared';
import {Action, createSelector} from '@ngrx/store';
import {Injectable, Injector} from '@angular/core';
import {selectResourceTasksState} from '../../resource-tasks.selector';
import {TechPortalFeatureResourceTaskService} from '../../services';

export interface ResourceTaskListStoreState extends PagedEntitiesXsStoreState<ResourceTaskDto> {
}

/* istanbul ignore next */
export const resourceTaskListStore = new PagedEntitiesXsStore<ResourceTaskDto>({
  actionLabel: 'Tech Portal - Resource Tasks - Resource Task List',
  stateSelector: createSelector(selectResourceTasksState, state => state.resourceTaskList),
  serviceType: TechPortalFeatureResourceTaskService,
  entityType: AppEntityType.None,
  selectId: resourceTask => resourceTask.entity?.resourceTaskId ?? resourceTask.model?.resourceTaskId,
  stereotyped: false
});

export function resourceTaskListStoreReducer(state: ResourceTaskListStoreState, action: Action): any {
  return resourceTaskListStore.reducer(state, action);
}

@Injectable()
export class ResourceTaskListStoreEffects extends resourceTaskListStore.effects {
  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }
}
