import {BaseXsStore, BaseXsStoreActions, createBaseXsStoreActions} from '../base';
import {CreatorAction, EmptyAction, PropsAction} from '../../types';
import {
  PagedEntitiesXsStoreAddRemoveParentPayload,
  PagedEntitiesXsStoreCreateOnePayload,
  PagedEntitiesXsStoreCreateOneSuccessPayload,
  PagedEntitiesXsStoreDeleteOnePayload,
  PagedEntitiesXsStoreGetPageCreator,
  PagedEntitiesXsStoreGetPageParentPayload,
  PagedEntitiesXsStoreGetPagePayload,
  PagedEntitiesXsStoreGetPageSuccessPayload,
  PagedEntitiesXsStoreGetStereotypesSuccess,
  PagedEntitiesXsStoreSetFiltersPayload
} from './paged-entities-xs-store.payloads';
import {createAction, props} from '@ngrx/store';
import {ContextCloak, FilterDto, FilterOperations, SortObject} from '@nexnox-web/core-shared';
import {PagedEntitiesXsStoreEntity} from './paged-entities-xs-store.state';

export interface PagedEntitiesXsStoreActions<E, M = E, SE = PagedEntitiesXsStoreEntity<E, M>>
  extends BaseXsStoreActions {
  getPage: CreatorAction<PagedEntitiesXsStoreGetPageCreator, PagedEntitiesXsStoreGetPagePayload>;
  getPageSuccess: PropsAction<PagedEntitiesXsStoreGetPageSuccessPayload<SE>>;

  appendPage: CreatorAction<PagedEntitiesXsStoreGetPageCreator, PagedEntitiesXsStoreGetPagePayload>;
  appendPageSuccess: PropsAction<PagedEntitiesXsStoreGetPageSuccessPayload<SE>>

  getStereotypes: EmptyAction;
  getStereotypesSuccess: PropsAction<PagedEntitiesXsStoreGetStereotypesSuccess>;

  createOne: PropsAction<PagedEntitiesXsStoreCreateOnePayload<M>>;
  createOneSuccess: PropsAction<PagedEntitiesXsStoreCreateOneSuccessPayload<E, M>>;

  addOneToParent: PropsAction<PagedEntitiesXsStoreAddRemoveParentPayload>;
  addOneToParentSuccess: PropsAction<PagedEntitiesXsStoreAddRemoveParentPayload>;

  removeOneFromParent: PropsAction<PagedEntitiesXsStoreAddRemoveParentPayload>;
  removeOneFromParentSuccess: PropsAction<PagedEntitiesXsStoreAddRemoveParentPayload>;

  deleteOne: PropsAction<PagedEntitiesXsStoreDeleteOnePayload>;
  deleteOneSuccess: PropsAction<PagedEntitiesXsStoreDeleteOnePayload>;

  setFilters: PropsAction<PagedEntitiesXsStoreSetFiltersPayload>;
  clear: EmptyAction;
}

export const createPagedEntitiesXsStoreActions = <E, M, SE = PagedEntitiesXsStoreEntity<E, M>>(
  label: string
): PagedEntitiesXsStoreActions<E, M, SE> => ({
  ...createBaseXsStoreActions(label),

  getPage: createAction(
    BaseXsStore.getType(label, 'Get page'),
    (
      pageNumber: number = 1,
      sortOptions: SortObject = null,
      filters: FilterDto[] = [],
      parent: PagedEntitiesXsStoreGetPageParentPayload = {
        filterOperation: FilterOperations.All,
        parentIds: []
      },
      pageSize?: number,
      customColumns: number[] = [],
      optionalColumns: string[] = [],
      contextCloak?: ContextCloak
    ) => ({ pageNumber, sortOptions, filters, parent, pageSize, customColumns, optionalColumns, contextCloak })
  ),
  getPageSuccess: createAction(
    BaseXsStore.getType(label, 'Get page Success'),
    props<PagedEntitiesXsStoreGetPageSuccessPayload<SE>>()
  ),

  appendPage: createAction(
    BaseXsStore.getType(label, 'Append page'),
    (
      pageNumber: number = 1,
      sortOptions: SortObject = null,
      filters: FilterDto[] = [],
      parent: PagedEntitiesXsStoreGetPageParentPayload = {
        filterOperation: FilterOperations.All,
        parentIds: []
      },
      pageSize?: number,
      customColumns: number[] = [],
      optionalColumns: string[] = [],
      contextCloak?: ContextCloak
    ) => ({ pageNumber, sortOptions, filters, parent, pageSize, customColumns, optionalColumns, contextCloak })
  ),
  appendPageSuccess: createAction(
    BaseXsStore.getType(label, 'Append page success'),
    props<PagedEntitiesXsStoreGetPageSuccessPayload<SE>>()
  ),

  getStereotypes: createAction(BaseXsStore.getType(label, 'Get stereotypes')),
  getStereotypesSuccess: createAction(
    BaseXsStore.getType(label, 'Get stereotypes success'),
    props<PagedEntitiesXsStoreGetStereotypesSuccess>()
  ),

  createOne: createAction(
    BaseXsStore.getType(label, 'Create one'),
    props<PagedEntitiesXsStoreCreateOnePayload<M>>()
  ),
  createOneSuccess: createAction(
    BaseXsStore.getType(label, 'Create one  success'),
    props<PagedEntitiesXsStoreCreateOneSuccessPayload<E, M>>()
  ),

  addOneToParent: createAction(
    BaseXsStore.getType(label, 'Add to parent'),
    props<PagedEntitiesXsStoreAddRemoveParentPayload>()
  ),
  addOneToParentSuccess: createAction(
    BaseXsStore.getType(label, 'Add to parent success'),
    props<PagedEntitiesXsStoreAddRemoveParentPayload>()
  ),

  removeOneFromParent: createAction(
    BaseXsStore.getType(label, 'Remove from parent'),
    props<PagedEntitiesXsStoreAddRemoveParentPayload>()
  ),
  removeOneFromParentSuccess: createAction(
    BaseXsStore.getType(label, 'Remove from parent success'),
    props<PagedEntitiesXsStoreAddRemoveParentPayload>()
  ),

  deleteOne: createAction(
    BaseXsStore.getType(label, 'Delete one'),
    props<PagedEntitiesXsStoreDeleteOnePayload>()
  ),
  deleteOneSuccess: createAction(
    BaseXsStore.getType(label, 'Delete one success'),
    props<PagedEntitiesXsStoreDeleteOnePayload>()
  ),

  setFilters: createAction(
    BaseXsStore.getType(label, 'Set filters'),
    props<PagedEntitiesXsStoreSetFiltersPayload>()
  ),

  clear: createAction(BaseXsStore.getType(label, 'Clear'))
});
