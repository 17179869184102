import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {
  CoreSharedSidebarBaseComponent,
  KnowledgePathProgressFunctionListDto,
  KnowledgePathProgressLocationListDto,
} from '@nexnox-web/core-shared';
import {IconDefinition} from "@fortawesome/fontawesome-common-types";
import {BehaviorSubject, Observable} from "rxjs";
import {faTimes, faUser} from '@fortawesome/free-solid-svg-icons';
import {map} from "rxjs/operators";

@Component({
  selector: 'nexnox-web-paths-progress-sidebar',
  templateUrl: './paths-progress-sidebar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PathsProgressSidebarComponent extends CoreSharedSidebarBaseComponent implements OnInit {
  @Input() public parentIds: Array<string | number> = [];

  public faUser: IconDefinition = faUser;
  public faTimes: IconDefinition = faTimes;

  public isFunction$: Observable<boolean>;
  public customParams$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  public model$: BehaviorSubject<KnowledgePathProgressFunctionListDto | KnowledgePathProgressLocationListDto> = new BehaviorSubject(null);

  constructor() {
    super();
  }

  public ngOnInit(): void {
    this.isFunction$ = this.model$.pipe(map((model) => Boolean((model as KnowledgePathProgressFunctionListDto).functionId)));
  }

  public onShow(model: KnowledgePathProgressFunctionListDto | KnowledgePathProgressLocationListDto): void {
    if (model) {
      this.model$.next(model);
      const modelType = model['function'] ? 'function' : 'location';
      const customParams = { [`${ modelType }Ids`]: [model[modelType].functionId ?? model[modelType].locationId] };
      this.customParams$.next(customParams);
    }
    super.onShow();
    this.ngOnInit();
  }

  public onHide(): void {
    super.onHide();
    this.resetAll();
  }

  private resetAll(): void {
    this.customParams$.next(null);
  }
}
