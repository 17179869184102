import {FieldType, FormlyTemplateOptions} from '@ngx-formly/core';
import {ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {BehaviorSubject, Subscription} from "rxjs";
import {faUserPlus} from '@fortawesome/free-solid-svg-icons/faUserPlus';
import {faTimes} from '@fortawesome/free-solid-svg-icons/faTimes';
import {IconDefinition} from "@fortawesome/fontawesome-common-types";
import {FormlyReceiversSidebarComponent} from "@nexnox-web/libs/core-portal/src/lib/modules/formly/types";
import {ReceiverItemLocalDto} from "./receivers.model";
import {CorePortalReceiversService} from '../../../../services';
import {ReceiverTabSettings, ReceiverType, receiverTypes} from "./receiver-type.enum";

interface FormlyReceiverTemplateOptions extends FormlyTemplateOptions {
  corePortalReceivers: CorePortalFormlyReceiverTyping;
}

export interface CorePortalFormlyReceiverTyping extends ReceiverTabSettings {
  model?: BehaviorSubject<any>;
}

@Component({
  selector: 'nexnox-web-formly-receivers',
  templateUrl: './formly-receivers.component.html',
  changeDetection: ChangeDetectionStrategy.Default
})
export class FormlyReceiversComponent extends FieldType implements OnInit, OnDestroy {
  @ViewChild('receiversSidebar') public receiversSidebar: FormlyReceiversSidebarComponent;

  public readonly to: FormlyReceiverTemplateOptions;

  public items: ReceiverItemLocalDto[] = [];
  public faUserPlus: IconDefinition = faUserPlus;
  public faTimes: IconDefinition = faTimes;

  public receiverTabs: { type: ReceiverType, shouldShow: boolean }[];

  private _subscriptions: Subscription[] = [];

  constructor(
    private corePortalReceiversService: CorePortalReceiversService
  ) {
    super();
  }

  public get isShowItemsLength(): boolean {
    return this.items?.length > 0;
  }

  public ngOnInit(): void {
    if (this.to?.corePortalReceivers?.model?.subscribe) {
      if (this.to?.corePortalReceivers) {
        this.receiverTabs = this.corePortalReceiversService.createReceiverTabs(this.to.corePortalReceivers);
      }
      this._subscriptions.push(this.to.corePortalReceivers.model.subscribe((): void => {
        this._mapEntitiesFromModel();
      }));
    }
  }

  public openSideBar(): void {
    if (!this.to.disabled) {
      this.receiversSidebar.onShow();
    }
  }

  public onAccept(): void {
    this._updateForm();
  }

  public navigateTo(itemUrl: string[]): void {
    if (this.to.disabled) {
      this.corePortalReceiversService.navigateToReceiver(itemUrl);
    }
  }

  public ngOnDestroy(): void {
    this._subscriptions.forEach((sub: Subscription) => sub.unsubscribe());
  }

  private _mapEntitiesFromModel(): void {
    this.items = receiverTypes.flatMap(({ key, type }) =>
      this.to?.corePortalReceivers?.model?.value?.[key]?.map((entity: any) =>
        this.corePortalReceiversService.mapEntity(entity, type)
      ) ?? []
    );
  }

  private _updateForm(): void {
    receiverTypes?.forEach(receiver =>
      this.form.get(receiver.type)?.setValue(this.items.filter((item: ReceiverItemLocalDto) => item.type === receiver.type)
        .map((item: ReceiverItemLocalDto) => item.entityDto) ?? [])
    );
  }
}
