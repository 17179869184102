<div *ngIf="!loading" class="d-flex flex-nowrap ml-2">
  <p-tieredMenu #menu
                [model]="menuItems$ | async"
                [popup]="true"
                appendTo="body">
  </p-tieredMenu>

  <button (click)="menu.toggle($event)"
          *ngIf="(menuItems$ | async)?.length"
          [disabled]="isMenuItemLoading$ | async"
          [icon]="menuIcon$ | async"
          class="p-button-text p-button-secondary p-ripple p-button-icon-only p-button-sm mr-1"
          pButton
          type="button">
  </button>

  <button (click)="menuItem.command ? menuItem.command() : undefined"
          *ngFor="let menuItem of isolatedMenuItems$ | async"
          [class]="'p-button-text p-ripple p-button-icon-only p-button-sm mr-1 ' + menuItem.styleClass"
          [disabled]="menuItem.disabled"
          [icon]="menuItem.icon | nexnoxWebFaIconStringPipe"
          [title]="menuItem.label"
          pButton
          type="button">
  </button>
</div>
