import {
  TechPortalFeatureSolutionItemListXsStore,
  TechPortalFeatureSolutionItemListXsStoreState
} from '@nexnox-web/tech-portal/features/solution';
import {Action, createSelector} from '@ngrx/store';
import {selectMissionsState} from '../../missions.selectors';
import {Injectable, Injector} from '@angular/core';

export interface MissionSolutionStoreState extends TechPortalFeatureSolutionItemListXsStoreState {
}

/* istanbul ignore next */
export const missionSolutionStore = new TechPortalFeatureSolutionItemListXsStore({
  actionLabel: 'Tech Portal - Missions - Mission - Solution',
  stateSelector: createSelector(selectMissionsState, state => state.missionSolution)
});

export function missionSolutionStoreReducer(state: MissionSolutionStoreState, action: Action): any {
  return missionSolutionStore.reducer(state, action);
}

@Injectable()
export class MissionSolutionStoreEffects extends missionSolutionStore.effects {
  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }
}
