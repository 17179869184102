import {CoreSharedApiBaseService, Product} from '@nexnox-web/core-shared';
import {Injectable, Injector} from '@angular/core';
import {Observable} from "rxjs";
import {map} from "rxjs/operators";

@Injectable()
export class OrgaPortalKnowledgePathService extends CoreSharedApiBaseService {
  constructor(
    protected injector: Injector
  ) {
    super(injector, Product.ORGA, 'v2/knowledgepath');
  }

  public deleteMigrationPaths<T>(id: number | string): Observable<T> {
    return this.httpRequestService.post<T>(`v2/knowledgepath/${ id }/migrate`, undefined).pipe(
      map(response => response.body)
    );
  }
}
