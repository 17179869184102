<nexnox-web-entity-edit [entityEditBaseComponent]="this"
                        [stereotyped]="true"
                        displayKey="title">
</nexnox-web-entity-edit>

<nexnox-web-cardbox [noDividerMarginBottom]="true" [title]="'issues.fields.products' | translate">
  <nexnox-web-basic-table [config]="productTableConfig"
                          [data]="products$ | async">
  </nexnox-web-basic-table>
</nexnox-web-cardbox>
