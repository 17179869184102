import {AuthStoreEffects, authStoreReducer} from './auth';
import {PackagesStoreEffects, packagesStoreReducer} from './packages';
import {coreGuiEffects, coreGuiReducers} from './gui';
import {generalTenantSettingsStore} from './general-tenant-settings';
import {generalTenantSettingsSubscriptionsStore} from "./general-tenant-settings-subscriptions";
import {missionSettingsStore} from "./mission-settings";

export * from './gui';
export * from './auth';
export * from './packages';
export * from './general-tenant-settings';
export * from './mission-settings';

export * from './core.state';
export * from './core.selectors';

export const coreReducers = {
  gui: coreGuiReducers,
  auth: authStoreReducer,
  packages: packagesStoreReducer,
  missionSettings: missionSettingsStore.reducer,
  generalTenantSettings: generalTenantSettingsStore.reducer,
  generalTenantSettingsSubscriptions: generalTenantSettingsSubscriptionsStore.reducer
};

export const coreEffects = [
  ...coreGuiEffects,
  AuthStoreEffects,
  PackagesStoreEffects,
  missionSettingsStore.effects,
  generalTenantSettingsStore.effects,
  generalTenantSettingsSubscriptionsStore.effects
];
