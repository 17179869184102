import {CoreSharedApiBaseService, Product} from '@nexnox-web/core-shared';
import {Injectable, Injector} from '@angular/core';

@Injectable()
export class TaskjobArchiveService extends CoreSharedApiBaseService {
  constructor(
    protected injector: Injector
  ) {
    super(injector, Product.ORGA, 'taskjobarchive');
  }
}
