import {ChangeDetectionStrategy, Component, Injector} from '@angular/core';
import {
  ActionButton,
  CorePortalEntityOverviewBaseComponent,
  DatatableActionButton,
  DeleteEntityModel
} from '@nexnox-web/core-portal';
import {AppEntityType, AppPermissions, HolidaySetDto, Mappers} from '@nexnox-web/core-shared';
import {holidaysListStore} from '../../store';

@Component({
  selector: 'nexnox-web-master-data-holidays-holidays-list',
  templateUrl: './holidays-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HolidaysListComponent extends CorePortalEntityOverviewBaseComponent<HolidaySetDto> {
  public title = 'core-portal.master-data.subtitles.holidays-list';
  public createTitle = 'core-portal.master-data.subtitles.holidays-create';
  public idProperty = 'holidaySetId';
  public displayProperty = 'name';
  public datatableConfigName = 'MasterDataHolidaysDefault';
  public componentId = 'HolidaysListComponent';

  constructor(
    protected injector: Injector
  ) {
    super(injector, holidaysListStore, Mappers.HolidaySetDto.serializedName, AppEntityType.None);
  }

  public getRowActionButtons(): DatatableActionButton[] {
    return this.getDefaultRowActionButtons(
      'core-portal.master-data.actions.edit-holidays',
      (row: HolidaySetDto) => `/masterdata/holidays/${ row.holidaySetId }`,
      [AppPermissions.UpdateHolidaySet],
      {
        module: 'management'
      }
    );
  }

  public getDeleteEntityModel(): DeleteEntityModel {
    return {
      titleKey: 'core-portal.master-data.actions.delete-holidays',
      descriptionKey: 'core-portal.master-data.descriptions.delete-holidays',
      confirmKey: 'core-portal.master-data.actions.delete-holidays',
      deletePermission: AppPermissions.DeleteHolidaySet
    };
  }

  protected async getActionButtons(): Promise<ActionButton[]> {
    return this.getDefaultActionButtons('core-portal.master-data.actions.create-holidays', AppPermissions.CreateHolidaySet);
  }
}
