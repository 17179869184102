import {ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {CoreSharedSidebarBaseComponent, ReservedInventoryNumberRequestDto} from '@nexnox-web/core-shared';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {IconDefinition} from "@fortawesome/fontawesome-common-types";
import {BehaviorSubject} from "rxjs";
import {faTimes, faUser} from '@fortawesome/free-solid-svg-icons';
import {CorePortalFormlyReadonlyTypes, CorePortalFormlyReadonlyTyping} from "@nexnox-web/core-portal";
import {isNumber} from "lodash";

@Component({
  selector: 'nexnox-web-settings-reserve-inventory-number-sidebar',
  templateUrl: './reserve-inventory-number-sidebar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReserveInventoryNumberSidebarComponent extends CoreSharedSidebarBaseComponent implements OnInit {
  @ViewChild('destinationTemplate', { static: true }) public destinationTemplate: TemplateRef<any>;

  @Output() public reserve: EventEmitter<any> = new EventEmitter<any>();

  public loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public form: FormGroup;
  public model: Partial<ReservedInventoryNumberRequestDto>;
  public fields: FormlyFieldConfig[];

  public faUser: IconDefinition = faUser;
  public faTimes: IconDefinition = faTimes;

  constructor() {
    super();
  }

  public ngOnInit(): void {
    this.form = new FormGroup({});
    this.fields = this.createForm();
    this.model = {} as any;
  }

  public onShow(): void {
    super.onShow();
    this.ngOnInit();
    this.loading$.next(false);
  }

  public onHide(): void {
    this.loading$.next(false);
    super.onHide();
  }

  public onAccept(): void {
    this.loading$.next(true);
    this.reserve.emit(this.model);
    this.onHide();
  }

  /* istanbul ignore next */
  private createForm(): FormlyFieldConfig[] {
    return [
      {
        key: 'name',
        type: 'input',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-12',
        defaultValue: '',
        templateOptions: {
          type: 'text',
          corePortalTranslated: {
            label: 'core-shared.shared.fields.name',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          required: false,
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.BASIC
          } as CorePortalFormlyReadonlyTyping
        },
        expressionProperties: {
          'templateOptions.required': () => false,
          'templateOptions.readonly': () => false
        }
      },
      {
        key: 'amount',
        type: 'input',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-12',
        defaultValue: 0,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.amount',
            validationMessages: {
              required: 'core-portal.core.validation.required',
              positive: {
                key: 'core-portal.core.validation.min',
                args: { min: 0 }
              }
            }
          },
          required: true,
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.BASIC
          } as CorePortalFormlyReadonlyTyping,
          type: 'number'
        },
        expressionProperties: {
          'templateOptions.required': () => true,
          'templateOptions.readonly': () => false
        },
        validators: {
          positive: ctrl => !isNumber(ctrl.value) ? true : ctrl.value >= 0
        }
      },
    ]
  }
}
