<ng-template #entityEditTemplate let-title="title">
  <nexnox-web-categories-category-edit #editComponent
                                       (modelChange)="createModel$.next($event)"
                                       [creating]="true"
                                       [loading]="(loaded$ | async) === false"
                                       [model]="createModel$ | async"
                                       [readonly]="false"
                                       [stereotyped]="false"
                                       [title]="title">
  </nexnox-web-categories-category-edit>
</ng-template>

<nexnox-web-entity-overview #overviewComponent
                            [entityEditTemplate]="entityEditTemplate"
                            [entityOverviewBaseComponent]="this"
                            [stereotyped]="false"
                            detailLink="/categories"
                            module="settings">
</nexnox-web-entity-overview>
