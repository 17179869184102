import {EntityAdapter, EntityState} from '@ngrx/entity';
import {Filter, Paging, SortObject, StereotypeDto} from '@nexnox-web/core-shared';
import {PagedEntitiesXsStoreEntityData} from './models';

export interface PagedEntitiesXsStoreEntity<EntityType, ModelType = EntityType> {
  entity: EntityType;
  model: ModelType;
}

export interface PagedEntitiesXsStoreState<E, M = E> extends EntityState<PagedEntitiesXsStoreEntity<E, M>> {
  paging: Paging;
  loading: boolean;
  loaded: boolean;
  loadedAt: string;
  appendLoading: boolean;
  hasError: boolean;
  stereotypes: StereotypeDto[];
  stereotypesLoading: boolean;
  stereotypesLoaded: boolean;
  filters: Filter[];
  sortObject: SortObject;
  entityData: PagedEntitiesXsStoreEntityData;
}

export const getInitialPagedEntitiesXsStoreState = <E, M>(
  adapter: EntityAdapter<PagedEntitiesXsStoreEntity<E, M>>
): PagedEntitiesXsStoreState<E, M> => ({
  ...adapter.getInitialState(),
  paging: null,
  loading: false,
  loaded: false,
  loadedAt: null,
  appendLoading: false,
  hasError: false,
  stereotypes: [],
  stereotypesLoading: false,
  stereotypesLoaded: false,
  filters: null,
  sortObject: null,
  entityData: {}
});
