import {CoreSharedApiBaseService, Product} from '@nexnox-web/core-shared';
import {Injectable, Injector} from '@angular/core';
import {Observable} from "rxjs";
import {map} from "rxjs/operators";

@Injectable()
export class TenantService extends CoreSharedApiBaseService {
  constructor(
    protected injector: Injector
  ) {
    super(injector, Product.ORGA, 'tenant');
  }

  public getUsage<T>(tenantId: number | string): Observable<T> {
    return this.httpRequestService.get<T>(`tenant/${ tenantId }/usage`).pipe(
      map(response => response.body)
    );
  }
}
