import {PagedEntitiesXsStore, PagedEntitiesXsStoreState} from '@nexnox-web/core-store';
import {AppEntityType, LocationGroupDto} from '@nexnox-web/core-shared';
import {Action, createSelector} from '@ngrx/store';
import {selectLocationGroupsState} from '../../location-groups.selectors';
import {CorePortalFeatureMasterDataLocationGroupService} from '../../services';
import {Injectable, Injector} from '@angular/core';

export interface LocationGroupListStoreState extends PagedEntitiesXsStoreState<LocationGroupDto> {
}

/* istanbul ignore next */
export const locationGroupListStore = new PagedEntitiesXsStore<LocationGroupDto>({
  actionLabel: 'Core Portal - Master Data - Location Group List',
  stateSelector: createSelector(selectLocationGroupsState, state => state.locationGroupList),
  serviceType: CorePortalFeatureMasterDataLocationGroupService,
  entityType: AppEntityType.LocationGroup,
  selectId: locationGroup => locationGroup.entity?.locationGroupId ?? locationGroup.model?.locationGroupId
});

export function locationGroupListStoreReducer(state: LocationGroupListStoreState, action: Action): any {
  return locationGroupListStore.reducer(state, action);
}

@Injectable()
export class LocationGroupListStoreEffects extends locationGroupListStore.effects {
  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }
}
