import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {DatatableCellValue, DatatableTableColumn, DatatableTableColumnType} from '../../models';
import {
  CustomCheckboxValueDto,
  CustomDateValueDto,
  CustomDropDownValueDto,
  CustomMultilineValueDto,
  CustomNumericValueDto,
  CustomPropertyType,
  CustomTextValueDto,
  CustomTimeValueDto,
  CustomValueDto,
  FilledCustomValueDto,
  FilledCustomValueRelationDto
} from '@nexnox-web/core-shared';
import {at, isNull, isUndefined} from 'lodash';
import dayjs from 'dayjs';
import {minutesTo} from '@nexnox-web/lodash';
import {BehaviorSubject, merge, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {faEye} from '@fortawesome/free-solid-svg-icons/faEye';

@Component({
  selector: 'nexnox-web-entity-datatable-cell',
  templateUrl: './entity-datatable-cell.component.html',
  styleUrls: ['./entity-datatable-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CorePortalEntityDatatableCellComponent {
  @Input() public idProp: string;
  @Input() public isTitle: boolean;
  @Input() public detailLink: string;
  @Input() public detailFn: (row: any) => void;
  @Input() public module: string;
  public cellValue$: Observable<DatatableCellValue>;
  public faEye = faEye;
  private columnSubject: BehaviorSubject<DatatableTableColumn> = new BehaviorSubject<DatatableTableColumn>(null);
  private rowSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor() {
    this.cellValue$ = merge(
      this.rowSubject.asObservable(),
      this.columnSubject.asObservable()
    ).pipe(
      map(() => this.getCellValue(this.column))
    );
  }

  public get row(): any {
    return this.rowSubject.getValue();
  }

  @Input()
  public set row(row: any) {
    this.rowSubject.next(row);
  }

  public get column(): DatatableTableColumn {
    return this.columnSubject.getValue();
  }

  @Input()
  public set column(column: DatatableTableColumn) {
    this.columnSubject.next(column);
  }

  /* istanbul ignore next */
  public getIconColor(value: any): string {
    return this.column.prependIcon.color(value);
  }

  private getCellValue(column: DatatableTableColumn): DatatableCellValue {
    if (column.prop === 'customPropertyValues') {
      return this.getCustomPropertyCellValue(column);
    }

    return this.getDtoCellValue(column);
  }

  private getDtoCellValue(column: DatatableTableColumn): DatatableCellValue {
    let value: any = at(this.row, this.column.prop);
    value = value?.length ? value[0] : null;

    switch (column.type) {
      case DatatableTableColumnType.ENUM:
        return this.getDtoEnumCellValue(column, value);
      case DatatableTableColumnType.BOOLEAN:
        return this.getDtoBooleanCellValue(column, value);
      case DatatableTableColumnType.DATE: {
        if (column.utc && typeof value === 'string' && !value.endsWith('Z')) {
          value = `${ value }Z`;
        }

        const date = dayjs(value).local();
        return Boolean(value) && date.isValid() ? {
          fullValue: this.row,
          value,
          date: true,
          dateFormat: column.format ?? 'L LT'
        } : { value: '' };
      }
      case DatatableTableColumnType.TIME: {
        const { hours, minutes } = minutesTo(value);
        const timeDate = dayjs(new Date(null, null, null, hours, minutes));
        return !isNull(value) && timeDate.isValid() ? {
          fullValue: this.row,
          value: timeDate.toString(),
          date: true,
          dateFormat: 'LT'
        } : { value: '' };
      }
      case DatatableTableColumnType.REFERENCE:
        return this.getDtoReferenceCellValue(column, value);
      case DatatableTableColumnType.PROGRESSBAR:
        return this.getDtoProgressBarPercentValue(column, value);
      case DatatableTableColumnType.PATH:
        return this.row[column.prop];
      case DatatableTableColumnType.ARRAY:
        return this.getArrayValue(value, column)
    }

    return {
      fullValue: this.row,
      value: value?.toString()?.trim() ?? ''
    };
  }

  private getDtoProgressBarPercentValue(column: DatatableTableColumn, value: any): any {
    const count = value ?? 0;
    const total = this.row[column.totalKey] ?? 100;
    const percent: number = (count / total) * 100;
    return {
      count,
      total,
      percent
    }
  }

  private getDtoBooleanCellValue(column: DatatableTableColumn, value: any): DatatableCellValue {
    const defaultLabel = `core-portal.core.general.${ value?.toString() }`;
    const trueLabel = column.trueLabel;
    const falseLabel = column.falseLabel;

    if (isNull(value) || isUndefined(value)) return { value: '' };
    const valueLabel = value ? (trueLabel ?? defaultLabel) : (falseLabel ?? defaultLabel);

    return !isNull(value) ? {
      fullValue: this.row,
      value: valueLabel,
      translated: true
    } : { value: '' };
  }

  private getDtoEnumCellValue(column: DatatableTableColumn, value: any): DatatableCellValue {
    const translatable = column.enumOptions.find(x => x.value === value);

    if (translatable) {
      return {
        fullValue: this.row,
        value: translatable.label,
        translated: true
      };
    }

    return { value: value?.toString() ?? '' };
  }

  private getDtoReferenceCellValue(column: DatatableTableColumn, value: any): DatatableCellValue {
    let cellValue;
    let link;

    if (column.link) {
      link = typeof column.link === 'string' ? column.link : column.link(value);
    }

    if (column.multiple) {
      cellValue = ((column.mapValues ? column.mapValues(value ?? []) : value) ?? []).map(x => ({
        value: x ? x[column.displayKey] : null,
        fullValue: x
      }));
    } else {
      cellValue = value ? value[column.displayKey] : null;
    }

    if (column.template) {
      cellValue = column.template(column.multiple ? cellValue.map(x => x.fullValue) : value);
    }

    return {
      fullValue: value,
      value: cellValue,
      link,
      withoutModule: column.withoutModule,
      module: column.module,
      fragment: column.fragment
    };
  }

  private getCustomPropertyCellValue(column: DatatableTableColumn): DatatableCellValue {
    if (!this.row.customPropertyValues) {
      return { value: '' };
    }

    const filledCustomValue: FilledCustomValueDto = this.row.customPropertyValues.find(
      x => x.propertyId === column.customPropertyId
    );
    const customValues: FilledCustomValueRelationDto[] = filledCustomValue?.customValues ?? [];
    const customValue: CustomValueDto = customValues[0]?.customValue ?? null;
    let value: any;

    if (!customValue) {
      return { value: '' };
    }

    switch (customValue.type) {
      case CustomPropertyType.Numeric: {
        value = (customValue as CustomNumericValueDto)[this.getCustomValueProperty(customValue)];
        return { value: value?.toString() };
      }
      case CustomPropertyType.Multiline: {
        value = (customValue as CustomMultilineValueDto)[this.getCustomValueProperty(customValue)];
        return { value: value?.toString()?.trim() };
      }
      case CustomPropertyType.Checkbox: {
        value = (customValue as CustomCheckboxValueDto)[this.getCustomValueProperty(customValue)];
        return !isNull(value) ? {
          value: `core-portal.core.general.${ value.toString() }`,
          translated: true
        } : { value: '' };
      }
      case CustomPropertyType.Date: {
        value = (customValue as CustomDateValueDto)[this.getCustomValueProperty(customValue)];
        const date = dayjs(value).local();
        return !isNull(value) && date.isValid() ? {
          value: date.toString(),
          date: true,
          dateFormat: 'LL'
        } : { value: '' };
      }
      case CustomPropertyType.TimeOfDay: {
        value = (customValue as CustomTimeValueDto)[this.getCustomValueProperty(customValue)];
        const { hours, minutes } = minutesTo(value);
        const timeDate = dayjs(new Date(null, null, null, hours, minutes));
        return !isNull(value) && timeDate.isValid() ? {
          value: timeDate.toString(),
          date: true,
          dateFormat: 'LT'
        } : { value: '' };
      }
      case CustomPropertyType.Dropdown: {
        value = (customValue as CustomDropDownValueDto)[this.getCustomValueProperty(customValue)];
        const translatable = (column.enumOptions ?? []).find(x => x.value === value?.toString());

        return { value: translatable?.label, translated: true };
      }
      default: {
        value = (customValue as CustomTextValueDto)[this.getCustomValueProperty(customValue)];
        return { value: value?.toString()?.trim() ?? '' };
      }
    }
  }

  private getCustomValueProperty(customValue: CustomValueDto): string {
    return customValue.isInherited ? 'inheritedValue' : 'ownValue';
  }

  private getArrayValue(array: any[], column): DatatableCellValue {
    array = array ?? [];
    const value = column.displayKey ? array.map((item) => this.getArrayDisplayValue(item, column.displayKey)) : array;
    return { value: value.join(column?.joinSymbol ?? ', ') };
  }

  private getArrayDisplayValue(item: any, displayKey: string): any {
    let layer: string[] = displayKey.split('.');
    layer = layer.length > 0 ? layer : [displayKey];
    let itemData: any = item;

    // key can be deep like: resource.contact.displayName
    for (let i = 0; i < layer.length; i++) {
      if (Boolean(itemData[layer[i]])) {
        itemData = itemData[layer[i]];
      } else {
        throw new Error(`Display key wrong at array column: ${ displayKey }`);
      }
    }
    return itemData;
  }
}
