import {createEntityXsStoreSelectors, EntityXsStoreSelectors} from '@nexnox-web/core-store';
import {InventoryNumberPatternDto} from '@nexnox-web/core-shared';
import {createSelector, MemoizedSelector} from '@ngrx/store';
import {IsDeepEqualWithCustomizer} from '@nexnox-web/lodash';
import {InventoryNumberDetailXsStoreState} from "./inventory-number-detail.xs-store";

export interface InventoryNumberDetailXsStoreSelectors extends EntityXsStoreSelectors<InventoryNumberPatternDto, InventoryNumberPatternDto, InventoryNumberDetailXsStoreState> {
  selectReserveLoading: MemoizedSelector<InventoryNumberDetailXsStoreState, boolean>;
  selectReserveLoaded: MemoizedSelector<InventoryNumberDetailXsStoreState, boolean>;
}

export const createInventoryNumberDetailXsStoreSelectors = (
  stateSelector: MemoizedSelector<any, InventoryNumberDetailXsStoreState>,
  sanitizeModel?: (model: InventoryNumberPatternDto, entity: InventoryNumberPatternDto) => InventoryNumberPatternDto,
  changeCustomizers?: IsDeepEqualWithCustomizer[]
): InventoryNumberDetailXsStoreSelectors => ({
  ...createEntityXsStoreSelectors(stateSelector, sanitizeModel, changeCustomizers),

  selectReserveLoading: createSelector(stateSelector, state => state.reserveLoading),
  selectReserveLoaded: createSelector(stateSelector, state => state.reserveLoaded)
});
