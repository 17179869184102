<div class="row w-100 mb-4">
  <div class="col-md-8 pl-0 pr-0 pr-md-2">
    <nexnox-web-entity-edit (nexnoxWebResized)="onEditComponentResized($event)"
                            [entityEditBaseComponent]="this"
                            [noMarginBottom]="true"
                            [style.display]="'block'"
                            displayKey="title">
    </nexnox-web-entity-edit>
  </div>

  <div class="col-md-4 pl-0 pl-md-2 pr-0">
    <nexnox-web-cardbox [fullHeight]="true"
                        [noMarginBottom]="true"
                        [style.display]="'block'"
                        [style.height.px]="variableHeightSubject | async"
                        [title]="'core-portal.settings.subtitles.templates.text-template-variables' | translate"
                        classes="d-flex flex-column overflow-isHidden"
                        paddingClasses="h-100 overflow-isHidden">
      <div class="w-100 h-100 px-3 pb-3 overflow-auto">
        <button (click)="onAddVariable(variable)"
                *ngFor="let variable of templateVariables$ | async"
                [disabled]="readonly"
                class="btn btn-link d-block"
                type="button">
          {{ variable }}
        </button>
      </div>
    </nexnox-web-cardbox>
  </div>
</div>

