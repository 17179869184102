<div class="d-flex align-items-center">
  <div class="d-flex flex-grow-1">
    <ng-container *ngTemplateOutlet="valueTemplate"></ng-container>
  </div>

  <div class="d-flex flex-shrink-0">
    <fa-icon *ngIf="(isComplex$ | async) || (isRelativeFilter$ | async)"
             [fixedWidth]="true"
             [icon]="faMagic"
             [title]="'core-shared.shared.table.tooltip.is-complex' | translate"
             class="text-secondary ml-2">
    </fa-icon>

    <p-sortIcon #sortIconComponent
                *ngIf="!hideSorting && column.sortable"
                [field]="column.prop"
                class="d-flex align-items-center">
    </p-sortIcon>
  </div>
</div>

<ng-template #valueTemplate>
  {{
    ((isDataColumn$ | async) || (isOptionalColumn$ | async) || column.translate) ?
      ('core-shared.shared.fields.' + (column.name | nexnoxWebDashCase) | translate) : column.name
  }}
</ng-template>

<ng-container *ngIf="!hideFilters && !column.noFilter">
  <ng-container *ngIf="(isRelativeFilter$ | async) === false && (isComplex$ | async) === false; else disableInput">
    <div (click)="$event.preventDefault(); $event.stopPropagation(); $event.stopImmediatePropagation()"
         *ngIf="(column.hasFilter || (isDataColumn$ | async) === false) && !column.disableFilter; else disableInput"
         class="mt-1">
      <nexnox-web-entity-datatable-filter #filterInput
                                          (searchBy)="search.emit($event)"
                                          [column]="column"
                                          [disabled]="disableFilters"
                                          [filter]="currentFilter$ | async"
                                          [templates]="templates">
      </nexnox-web-entity-datatable-filter>
    </div>
  </ng-container>
</ng-container>

<ng-template #disableInput>
  <div class="mt-1">
    <input [disabled]="true"
           [value]="disabledInputValue$ | async | translate"
           class="w-100 form-control"
           type='text'/>
  </div>
</ng-template>
