<ng-select (ngModelChange)="onSelectedSolutionMemberChange($event)"
           [items]="solution?.members"
           [loading]="loading"
           [ngModel]="selectedSolutionMember"
           [placeholder]="('tech-portal.actions.select-member' | translate) + '...'"
           [readonly]="loading"
           [searchFn]="searchFn"
           [searchable]="true"
           class="ng-form-select"
           groupBy="type">
  <ng-template let-item="item" ng-label-tmp>
    {{ item.title }}
  </ng-template>

  <ng-template let-item="item" let-searchTerm="searchTerm" ng-option-tmp>
    <span [innerHTML]="item.title | nexnoxWebHighlight:searchTerm"
          [title]="item.title">
    </span>
  </ng-template>

  <ng-template let-item="item" let-searchTerm="searchTerm" ng-optgroup-tmp>
    <span *ngIf="('solutions.solution-members.' + item.type) | translate as title"
          [innerHTML]="title | nexnoxWebHighlight:searchTerm"
          [title]="title">
    </span>
  </ng-template>

  <ng-template ng-notfound-tmp>
    <div class="ng-option disabled">{{ 'core-shared.shared.table.empty' | translate }}</div>
  </ng-template>
</ng-select>
