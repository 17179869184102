export *
  from '@nexnox-web/core-portal/features/master-data/features/locations/src/lib/containers/location-audit-list/location-audit-list.component';
export * from './location-list/location-list.component';
export * from './location-detail/location-detail.component';
export * from './location-address-list/location-address-list.component';
export * from './location-resource-list/location-resource-list.component';
export * from './location-mission-list/location-mission-list.component';
export * from './location-issue-list/location-issue-list.component';
export * from './location-task-list/location-task-list.component';
export * from './location-contract-list/location-contract-list.component';
export * from './location-contact-list/location-contact-list.component';
export * from './location-ticket-list/location-ticket-list.component';
export * from './location-task-active-list/location-task-active-list.component';
export * from './location-task-archive-list/location-task-archive-list.component';
export * from './location-tasks-planned-tasks/location-tasks-planned-tasks.component';
export * from './location-locationgroup-list/location-locationgroup-list.component';
export * from './location-address-detail-standalone/location-address-detail-standalone.component';
