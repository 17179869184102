import {AppEntityType, TaskJobDto} from '@nexnox-web/core-shared';
import {createSelector} from '@ngrx/store';
import {LocationTaskjobService} from '../../services';
import {selectLocationsState} from '../../locations.selectors';
import {LocationTaskActiveListXsStore,} from './location-task-active-list.xs-store';
import {PagedEntitiesXsStoreState} from "@nexnox-web/core-store";

export interface LocationTaskActiveListStoreState extends PagedEntitiesXsStoreState<TaskJobDto> {
}

/* istanbul ignore next */
export const locationTaskActiveListStore = new LocationTaskActiveListXsStore({
  actionLabel: 'Orga Portal - Location - Task Active List',
  stateSelector: createSelector(selectLocationsState, state => state.locationTaskActiveList),
  serviceType: LocationTaskjobService,
  entityType: AppEntityType.TaskJob,
  selectId: taskJob => taskJob.entity?.taskJobId ?? taskJob.model?.taskJobId,
  stereotyped: false
});
