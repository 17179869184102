import {ChangeDetectionStrategy, Component, Injector} from '@angular/core';
import {
  ActionButton,
  CorePortalEntityOverviewBaseComponent,
  DatatableActionButton,
  DeleteEntityModel
} from '@nexnox-web/core-portal';
import {
  AppEntityType,
  AppPermissions,
  ArticleDto,
  ControllerOperationId,
  FilterDto,
  FilterOperators,
  FilterTypes,
  Mappers
} from '@nexnox-web/core-shared';
import {articleListStore} from '../../store';

@Component({
  selector: 'nexnox-web-articles-article-list',
  templateUrl: './article-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ArticleListComponent extends CorePortalEntityOverviewBaseComponent<ArticleDto> {
  public title = 'articles.subtitles.article-list';
  public createTitle = 'articles.subtitles.article-create';
  public idProperty = 'articleId';
  public displayProperty = 'name';
  public componentId = 'ArticleListComponent';
  public datatableConfigName = 'ArticleDefault';

  public pageOperation = ControllerOperationId.ArticlesControllerList;
  public enableViews = true;

  protected defaultFilter: FilterDto[] = [
    {
      property: 'isArchived',
      operator: FilterOperators.Equal,
      value: 'false',
      type: FilterTypes.DataTransferObject
    }
  ];

  constructor(
    protected injector: Injector
  ) {
    super(injector, articleListStore, Mappers.ArticleListDto.serializedName, AppEntityType.Article);
  }

  /* istanbul ignore next */
  public getRowActionButtons(): DatatableActionButton[] {
    return this.getDefaultRowActionButtons(
      'articles.actions.edit-article',
      (row: ArticleDto) => `/articles/${ row.articleId }`,
      [AppPermissions.UpdateArticle],
      {
        module: 'inventory'
      }
    );
  }

  public getDeleteEntityModel(): DeleteEntityModel {
    return {
      titleKey: 'articles.actions.delete-article',
      descriptionKey: 'articles.descriptions.delete-article',
      confirmKey: 'articles.actions.delete-article',
      deletePermission: AppPermissions.DeleteArticle
    };
  }

  protected async getActionButtons(): Promise<ActionButton[]> {
    return this.getDefaultActionButtons('articles.actions.create-article', AppPermissions.CreateArticle);
  }
}
