import {ChangeDetectionStrategy, Component, Injector} from '@angular/core';
import {
  ActionButton,
  CorePortalEntityOverviewBaseComponent,
  DatatableActionButton,
  DeleteEntityModel
} from '@nexnox-web/core-portal';
import {
  AppEntityType,
  AppPermissions,
  ExportType,
  FilterDto,
  FilterOperators,
  FilterTypes,
  FunctionDto,
  Mappers
} from '@nexnox-web/core-shared';
import {functionListStore} from '../../store';

@Component({
  selector: 'nexnox-web-master-data-functions-function-list',
  templateUrl: './function-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FunctionListComponent extends CorePortalEntityOverviewBaseComponent<FunctionDto> {
  public title = 'core-portal.master-data.subtitles.function-list';
  public createTitle = 'core-portal.master-data.subtitles.function-create';
  public idProperty = 'functionId';
  public displayProperty = 'name';
  public datatableConfigName = 'MasterDataFunctionDefault';
  public componentId = 'FunctionListComponent';

  protected defaultFilter: FilterDto[] = [
    {
      property: 'isArchived',
      operator: FilterOperators.Equal,
      value: 'false',
      type: FilterTypes.DataTransferObject
    }
  ];

  constructor(
    protected injector: Injector
  ) {
    super(injector, functionListStore, Mappers.FunctionListDto.serializedName, AppEntityType.Function);
  }

  public getRowActionButtons(): DatatableActionButton[] {
    return [
      ...this.getDefaultRowActionButtons(
        'core-portal.master-data.actions.edit-function',
        (row: FunctionDto) => `/masterdata/functions/${ row.functionId }`,
        [AppPermissions.UpdateFunction],
        {
          module: 'management'
        }
      ),
      this.exportService.getRowActionExportButton(ExportType.FUNCTION, this.idProperty)
    ];
  }

  public getDeleteEntityModel(): DeleteEntityModel {
    return {
      titleKey: 'core-portal.master-data.actions.delete-function',
      descriptionKey: 'core-portal.master-data.descriptions.delete-function',
      confirmKey: 'core-portal.master-data.actions.delete-function',
      deletePermission: AppPermissions.DeleteFunction
    };
  }

  protected async getActionButtons(): Promise<ActionButton[]> {
    return this.getDefaultActionButtons('core-portal.master-data.actions.create-function', AppPermissions.CreateFunction);
  }
}
