import {ActionReducerMap, combineReducers} from '@ngrx/store';
import {coreEffects, coreReducers} from './core';
import {settingsEffects, settingsReducers, settingsServices} from './settings';
import {ProductSubscriptionService} from "../../../features/service/src/lib/tenants/store";

export * from './core';
export * from './settings';

export * from './core-portal.state';
export * from './core-portal.selectors';

export const corePortalReducers: ActionReducerMap<any> = {
  core: combineReducers(coreReducers),
  settings: combineReducers(settingsReducers)
};

export const corePortalEffects = [
  ...coreEffects,
  ...settingsEffects
];

export const corePortalServices = [
  ...settingsServices,
  ProductSubscriptionService
];
