import {PagedEntitiesXsStore, PagedEntitiesXsStoreState} from '@nexnox-web/core-store';
import {AppEntityType, IssueTemplateListDto} from '@nexnox-web/core-shared';
import {Action, createSelector} from '@ngrx/store';
import {selectIssueTemplateState} from '../../issue-template.selectors';
import {CorePortalIssueTemplateService} from '../../services';
import {Injectable, Injector} from '@angular/core';

export interface IssueTemplateListStoreState extends PagedEntitiesXsStoreState<IssueTemplateListDto> {
}

/* istanbul ignore next */
export const issueTemplateListStore = new PagedEntitiesXsStore<IssueTemplateListDto>({
  actionLabel: 'Core Portal - Settings - Issue templates - Issue Template List',
  stateSelector: createSelector(selectIssueTemplateState, state => state.issueTemplateList),
  serviceType: CorePortalIssueTemplateService,
  entityType: AppEntityType.IssueTemplate,
  stereotyped: false,
  selectId: issue => issue.entity?.issueTemplateId ?? issue.model?.issueTemplateId
});

export function issueTemplateListStoreReducer(state: IssueTemplateListStoreState, action: Action): any {
  return issueTemplateListStore.reducer(state, action);
}

@Injectable()
export class IssueTemplateListStoreEffects extends issueTemplateListStore.effects {
  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }
}
