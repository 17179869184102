import {ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {
  ApiNotificationService,
  CombineOperator,
  CoreSharedSidebarBaseComponent,
  Filter,
  FilterOperators,
  FilterTypes,
  RenderedTemplateDto,
  RenderTemplateDto,
  TemplateContextType,
  TemplateDto
} from '@nexnox-web/core-shared';
import {FormGroup} from '@angular/forms';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {faSpinner} from '@fortawesome/free-solid-svg-icons/faSpinner';
import {faCheck} from '@fortawesome/free-solid-svg-icons/faCheck';
import {TechPortalFeatureTextTemplateService} from '../../store';
import {TECH_PORTAL_TEXT_TEMPLATE_CONTEXT_CONFIG, TechPortalTextTemplateContextConfig} from '../../tokens';
import {isEqual} from 'lodash';
import {map} from 'rxjs/operators';

export interface TextTemplateApplySidebarOptions {
  prependFields?: FormlyFieldConfig[];
  appendFields?: FormlyFieldConfig[];
  defaultFilters$?: Observable<Filter[]>;
  refresh$?: Observable<any>;
}

interface TextTemplateApplyModel {
  template?: TemplateDto;

  [key: string]: any;
}

@Component({
  selector: 'nexnox-web-templates-text-template-apply-sidebar',
  templateUrl: './text-template-apply-sidebar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TechPortalFeatureTextTemplateApplySidebarComponent extends CoreSharedSidebarBaseComponent implements OnInit {
  @Input() public referenceModel: any;
  @Input() public contextType: TemplateContextType;
  @Input() public modelFieldsToInclude: string[];

  @Output() public applied = new EventEmitter<RenderedTemplateDto>();
  @Output() public templateChange = new EventEmitter<void>();

  public options: TextTemplateApplySidebarOptions = {};

  public form: FormGroup;
  public model: TextTemplateApplyModel;
  public fields: FormlyFieldConfig[];

  public loading$: Observable<boolean>;
  public hasContextType$: Observable<boolean>;

  public faCheck = faCheck;
  public faSpinner = faSpinner;

  private renderLoadingSubject = new BehaviorSubject<boolean>(false);
  private sanitizedModelSubject = new BehaviorSubject<RenderTemplateDto>({});

  constructor(
    protected templateService: TechPortalFeatureTextTemplateService,
    protected apiNotificationService: ApiNotificationService,
    @Inject(TECH_PORTAL_TEXT_TEMPLATE_CONTEXT_CONFIG) protected textTemplateConfig: TechPortalTextTemplateContextConfig
  ) {
    super();
  }

  public ngOnInit(): void {
    this.loading$ = this.renderLoadingSubject.asObservable();
    this.hasContextType$ = this.sanitizedModelSubject.asObservable().pipe(
      map(model => Boolean(model?.context?.type))
    );

    this.form = new FormGroup({});
  }

  public onShow(options: TextTemplateApplySidebarOptions = {}): void {
    super.onShow();

    this.options = options;

    this.fields = this.createForm();
    this.model = {};
  }

  public onHide(): void {
    super.onHide();

    this.sanitizedModelSubject.next({});
    this.renderLoadingSubject.next(false);

    this.fields = [];
    this.model = {};
    this.form.reset();
    this.form.markAsPristine();
  }

  public onApplyTemplate(): void {
    this.renderLoadingSubject.next(true);

    this.subscribe(
      this.templateService.renderTemplate(this.model.template.templateId, this.sanitizedModelSubject.getValue()),
      renderedTemplate => {
        this.onHide();
        this.applied.emit(renderedTemplate);
      },
      error => {
        this.apiNotificationService.handleApiError(error);
        this.renderLoadingSubject.next(false);
      }
    );
  }

  public onModelChange(model: TextTemplateApplyModel): void {
    if (this.model?.template?.templateId !== model?.template?.templateId) this.templateChange.emit();

    this.model = model;

    if (this.form.valid && model?.template?.templateId) {
      const textTemplateConfig = this.textTemplateConfig[model.template.context];
      const parentType = (textTemplateConfig?.parents ?? []).find(parent => isEqual(parent, model.template.context));
      const contextType = parentType ? parentType : (model.template.context);

      let sanitizedModel = {};
      if (this.textTemplateConfig[contextType]?.sanitize && this.referenceModel) {
        sanitizedModel = this.textTemplateConfig[contextType].sanitize(this.referenceModel) ?? {};
      }

      for (const modelField of (this.modelFieldsToInclude ?? [])) {
        if (!this.model[modelField]) continue;
        sanitizedModel[modelField] = this.model[modelField];
      }

      this.sanitizedModelSubject.next({
        context: { type: contextType, ...(sanitizedModel ?? {}) },
      });
    }
  }

  /* istanbul ignore next */
  private createForm(): FormlyFieldConfig[] {
    return [
      ...(this.options.prependFields ?? []),
      {
        key: 'template',
        type: 'core-portal-entity-select',
        wrappers: ['core-portal-translated'],
        className: 'col-md-12 px-0',
        defaultValue: null,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-portal.settings.subtitles.templates.text-template-detail',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          entityService: this.templateService,
          idKey: 'templateId',
          displayKey: 'title',
          clearable: false,
          firstToDefault: true,
          wholeObject: true,
          required: true,
          refresh$: this.options.refresh$,
          defaultFilters$: this.contextType ? of([
            {
              type: FilterTypes.Grouped,
              combinedAs: CombineOperator.Or,
              property: 'context',
              children: [
                {
                  property: 'context',
                  type: FilterTypes.DataTransferObject,
                  operator: FilterOperators.Equal,
                  value: this.contextType.toString()
                },
                ...(this.textTemplateConfig[this.contextType] ? (this.textTemplateConfig[this.contextType].parents ?? []).map(parent => ({
                  property: 'context',
                  type: FilterTypes.DataTransferObject,
                  operator: FilterOperators.Equal,
                  value: parent.toString()
                })) : [])
              ]
            }
          ] as Filter[]) : (this.options.defaultFilters$ ? this.options.defaultFilters$ : undefined)
        }
      },
      ...(this.options.appendFields ?? [])
    ];
  }
}
