<ng-container>
  <div class="sticky-top d-flex mb-3 justify-content-md-end w-100 multiselect-bg">
    <div class="d-flex align-items-md-center flex-wrap flex-column flex-md-row w-100 justify-content-start mb-3">

      <!-- Dropdown filter selector -->
      <p-multiSelect (ngModelChange)="onChangeShowFilter($event)"
                     [disabled]="(loaded$ | async) === false"
                     [filter]="false"
                     [ngModel]="filterItemsSubject | async"
                     [options]="filterItemOptions"
                     [placeholder]="'core-shared.shared.select.selected' | translate:{amount: 0}"
                     optionLabel="label"
                     optionValue="value"
                     styleClass="multiSelect mb-1 mb-md-0">
        <ng-template let-items pTemplate="selectedItems">
          <span *ngIf="items?.length >= filterItemOptions.length">
            {{ 'core-shared.shared.select.all' | translate }}</span>
          <span *ngIf="items?.length < filterItemOptions.length">
            {{ 'core-shared.shared.select.selected' | translate:{ amount: items?.length } }}</span>
        </ng-template>

        <ng-template let-item pTemplate="item">
          <span>{{ item.label | translate }}</span>
        </ng-template>
      </p-multiSelect>

      <!-- Refresh button -->
      <button (click)="onRefresh()"
              [disabled]="(loaded$ | async) === false || (loading$ | async)"
              [title]="'core-shared.shared.table.tooltip.refresh' | translate"
              class="btn btn-outline-secondary multiSelect-height ml-md-2">
        <fa-icon *ngIf="((loaded$ | async) && (loading$ | async) === false); else loadingTemplate"
                 [icon]="faSync"></fa-icon>

        <ng-template #loadingTemplate>
          <nexnox-web-loading-icon></nexnox-web-loading-icon>
        </ng-template>
      </button>
    </div>
  </div>

  <ng-container *ngIf="(loading$ | async) === false && filteredEvents$ | async as events; else loading">
    <ng-container *ngIf="(loaded$ | async) && events?.length > 0; else emptyTemplate">
      <nexnox-web-resources-resource-event-list-item *ngFor="let item of events; last as isLastItem"
                                                     [entityData]="entityData$ | async"
                                                     [isLastItem]="isLastItem"
                                                     [item]="item.entity"
                                                     [loading$]="loading$"
                                                     [readonly]="(readonly$ | async) || readonly">
      </nexnox-web-resources-resource-event-list-item>

      <nexnox-web-content-container-infinite-scroll (loadMore)="onMore(paging)"
                                                    *ngIf="paging$ | async as paging"
                                                    [disabled]="(loading$ | async)"
                                                    [error]="hasError$ | async"
                                                    [finished]="paging.pageNumber >= paging.totalPages"
                                                    [loading]="(appendLoading$ | async)">
      </nexnox-web-content-container-infinite-scroll>
    </ng-container>
  </ng-container>

</ng-container>

<ng-template #loading>
  <nexnox-web-resources-resource-event-list-item [loading]="true"></nexnox-web-resources-resource-event-list-item>
</ng-template>

<ng-template #emptyTemplate>
  <span>{{ 'core-shared.shared.table.empty' | translate }}</span>
</ng-template>
