import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'nexnoxWebLocaleStringPipe'
})
export class NexnoxWebLocaleString implements PipeTransform {
  public static transformNumber(value: number, digits?: number): string {
    return value.toLocaleString(undefined, {
      minimumFractionDigits: digits ?? 0,
      maximumFractionDigits: digits
    });
  }

  public transform(value: number, digits?: number): string {
    return NexnoxWebLocaleString.transformNumber(value, digits);
  }
}
