import {AuthXsStoreState, getInitialAuthXsStoreState} from './auth';
import {getInitialPackagesXsStoreState, PackagesXsStoreState} from './packages';
import {CoreGuiState, getInitialCoreGuiState} from './gui';
import {generalTenantSettingsStore, GeneralTenantSettingsStoreState} from './general-tenant-settings';
import {
  generalTenantSettingsSubscriptionsStore,
  GeneralTenantSettingsSubscriptionsStoreState
} from "./general-tenant-settings-subscriptions";
import {missionSettingsStore, MissionSettingsStoreState} from "./mission-settings";

export interface CoreState {
  gui: CoreGuiState;
  auth: AuthXsStoreState;
  packages: PackagesXsStoreState;
  missionSettings: MissionSettingsStoreState;
  generalTenantSettings: GeneralTenantSettingsStoreState;
  generalTenantSettingsSubscriptions: GeneralTenantSettingsSubscriptionsStoreState;
}

export const getInitialCoreState = (): CoreState => ({
  gui: getInitialCoreGuiState(),
  auth: getInitialAuthXsStoreState(),
  packages: getInitialPackagesXsStoreState(),
  missionSettings: missionSettingsStore.getInitialState(),
  generalTenantSettings: generalTenantSettingsStore.getInitialState(),
  generalTenantSettingsSubscriptions: generalTenantSettingsSubscriptionsStore.getInitialState()
});
