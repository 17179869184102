import {ModuleWithProviders, NgModule} from '@angular/core';
import {ContactsRoutingModule} from './contacts-routing.module';
import {ContactsDeclarationsModule} from './contacts-declarations.module';
import {ContactsStoreModule} from './contacts-store.module';

@NgModule({
  imports: [
    ContactsDeclarationsModule,
    ContactsRoutingModule,
    ContactsStoreModule
  ]
})
export class CorePortalFeatureMasterDataContactsModule {
  public static forFeature(): ModuleWithProviders<ContactsDeclarationsModule> {
    return { ngModule: ContactsDeclarationsModule };
  }
}
