import {ChangeDetectionStrategy, Component, Injector} from '@angular/core';
import {CorePortalEntityEditBaseComponent} from '@nexnox-web/core-portal';
import {LocationGroupDto} from '@nexnox-web/core-shared';
import {FormlyFieldConfig} from '@ngx-formly/core';

@Component({
  selector: 'nexnox-web-master-data-location-groups-location-group-edit',
  templateUrl: './location-group-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CorePortalFeatureLocationGroupEditComponent extends CorePortalEntityEditBaseComponent<LocationGroupDto> {
  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }

  protected createForm(): FormlyFieldConfig[] {
    return [
      {
        key: 'name',
        type: 'input',
        wrappers: ['core-portal-translated'],
        className: 'col-md-12',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.name',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          type: 'text'
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.disabled': () => this.readonly
        },
        hideExpression: () => !this.creating
      },
      {
        key: 'externalId',
        type: 'core-portal-external-id',
        wrappers: ['core-portal-translated'],
        className: 'col-md-12',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.external-id'
          },
          titleKey: 'name',
          modelSubject: this.modelSubject
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly
        }
      },
      ...this.getStereotypeFields()
    ];
  }
}
