<div class="d-flex flex-row">
  <nexnox-web-date-picker (ngModelChange)="onDateChange($event)"
                          [disabled]="disabled$ | async"
                          [hasError]="hasError"
                          [ngModel]="date$ | async"
                          class="d-flex flex-grow-1">
  </nexnox-web-date-picker>

  <nexnox-web-time-picker (ngModelChange)="onTimeChange($event)"
                          [canClear]="false"
                          [disabled]="disabled$ | async"
                          [hasError]="hasError"
                          [ngModel]="time$ | async"
                          class="d-flex">
  </nexnox-web-time-picker>

  <button (click)="onClear()"
          [class.invisible]="(valid$ | async) === false || (disabled$ | async) || !canClear"
          [title]="'core-shared.shared.select.clear' | translate"
          class="btn">
    <fa-icon [icon]="faTimes"></fa-icon>
  </button>
</div>

<div>
  <button (click)="onSetToNow()"
          [disabled]="disabled$ | async"
          class="btn btn-link"
          type="button">
    {{ 'core-shared.shared.date-time-picker.now' | translate }}
  </button>

  <div *ngIf="enabledRelativeTimes?.length"
       [isDisabled]="disabled$ | async"
       class="btn-group"
       dropdown>
    <button class="btn btn-link dropdown-toggle"
            dropdownToggle
            type="button">
      {{ 'core-shared.shared.date-time-picker.add-relative-time' | translate }}
    </button>

    <ul *dropdownMenu class="dropdown-menu">
      <li *ngFor="let relativeTime of enabledRelativeTimes">
        <a (click)="onSetRelativeTime(relativeTime)" class="dropdown-item">
          {{ ('core-shared.shared.date-time-picker.relative-times.' + relativeTimeTypes[relativeTime]) | translate }}
        </a>
      </li>
    </ul>
  </div>
</div>
