import {ChangeDetectionStrategy, Component, Injector, OnInit} from '@angular/core';
import {
  CorePortalEntityEditBaseComponent,
  CorePortalFormlyNgSelectTyping,
  CorePortalFormlyReadonlyTypes,
  CorePortalFormlyReadonlyTyping,
  CorePortalFormlyTranslatedTyping
} from '@nexnox-web/core-portal';
import {
  ConsumptionCodeDto,
  CoreSharedKnowledgeArticleService,
  KnowledgeArticleDto,
  ValueType
} from '@nexnox-web/core-shared';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {TranslateService} from '@ngx-translate/core';
import {Observable} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {TechPortalFeatureConsumptionCodeExternalIdComponent} from '..';

export const consumptionCodeValueTypeEnumOptions = [
  {label: 'tech-portal.connected.consumption-codes.value-types.1', value: ValueType.String},
  {label: 'tech-portal.connected.consumption-codes.value-types.2', value: ValueType.Integer}
];

@Component({
  selector: 'nexnox-web-connected-consumption-codes-consumption-code-edit',
  templateUrl: './consumption-code-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TechPortalFeatureConnectedConsumptionCodeEditComponent extends CorePortalEntityEditBaseComponent<ConsumptionCodeDto>
  implements OnInit {
  public titleFieldFn: any;
  public customTitle$: Observable<string>;

  constructor(
    private translate: TranslateService,
    private knowledgeService: CoreSharedKnowledgeArticleService,
    protected injector: Injector
  ) {
    super(injector);

    this.titleFieldFn = base => this.getTitleFields(base);
  }

  public ngOnInit(): void {
    super.ngOnInit();

    this.customTitle$ = this.modelSubject.asObservable().pipe(
      filter(model => Boolean(model)),
      map(model => TechPortalFeatureConsumptionCodeExternalIdComponent.prepareTitle(
        model,
        TechPortalFeatureConsumptionCodeExternalIdComponent.prepareTitlePrefix(model)
      ))
    );
  }

  protected createForm(): FormlyFieldConfig[] {
    return [
      ...(this.creating ? [this.getExternalIdField()] : []),
      {
        key: 'displayName',
        type: 'input',
        className: 'col-md-6',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        templateOptions: {
          type: 'text',
          corePortalTranslated: {
            label: 'core-shared.shared.fields.display-name',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          } as CorePortalFormlyTranslatedTyping,
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.BASIC
          } as CorePortalFormlyReadonlyTyping
        },
        expressionProperties: {
          'templateOptions.required': () => this.creating && !this.readonly,
          'templateOptions.disabled': () => !this.creating || this.readonly,
          'templateOptions.readonly': () => !this.creating || this.readonly
        },
        hideExpression: () => !this.creating
      },
      {
        key: 'unit',
        type: 'input',
        className: null,
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        templateOptions: {
          type: 'text',
          corePortalTranslated: {
            label: 'core-shared.shared.fields.unit',
          } as CorePortalFormlyTranslatedTyping,
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.BASIC
          } as CorePortalFormlyReadonlyTyping
        },
        expressionProperties: {
          className: () => this.creating ? 'col-md-2' : 'col-md-12',

          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        }
      },
      {
        key: 'valueType',
        type: 'core-portal-ng-select',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-3',
        defaultValue: ValueType.String,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.value',
          } as CorePortalFormlyTranslatedTyping,
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.ENUM,
            enumOptions: consumptionCodeValueTypeEnumOptions,
            translate: true
          } as CorePortalFormlyReadonlyTyping,
          corePortalNgSelect: {
            items: consumptionCodeValueTypeEnumOptions,
            translate: true
          } as CorePortalFormlyNgSelectTyping,
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        },
      },
      {
        key: 'description',
        type: 'core-portal-editor',
        className: 'col-md-12',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        templateOptions: {
          type: 'text',
          rows: 3,
          corePortalTranslated: {
            label: 'core-shared.shared.fields.description',
          } as CorePortalFormlyTranslatedTyping,
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.BASIC
          } as CorePortalFormlyReadonlyTyping,
          corePortalEditor: {
            noHTML: true,
            language: this.translate.currentLang,
          },
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        }
      },
      {
        key: 'knowledgeArticle',
        type: 'core-portal-entity-select',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6 field-group-mb-0',
        defaultValue: [],
        templateOptions: {
          corePortalTranslated: {
            label: 'orga-portal.shared.fields.knowledge-article'
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.ENTITY,
            displayKey: 'title',
            link: (knowledgeArticle: KnowledgeArticleDto) => knowledgeArticle?.knowledgeArticleId ?
              ['knowledge/articles', knowledgeArticle.knowledgeArticleId] : null,
            module: 'communication'
          } as CorePortalFormlyReadonlyTyping,
          entityService: this.knowledgeService,
          idKey: 'knowledgeArticleId',
          displayKey: 'title',
          wholeObject: true,
          multiple: false,
          showAll: true
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        }
      },
      {
        key: 'isVisible',
        type: 'core-portal-switch',
        wrappers: ['core-portal-translated'],
        className: 'col-md-4',
        defaultValue: false,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.detail.is-visible'
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.BOOLEAN
          } as CorePortalFormlyReadonlyTyping
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        }
      }
    ];
  }

  /* istanbul ignore next */
  protected getTitleFields(base: FormlyFieldConfig): FormlyFieldConfig[] {
    return [
      this.getExternalIdField(),
      {
        ...base,
        className: 'col-md-8 title-input'
      }
    ];
  }

  /* istanbul ignore next */
  protected getExternalIdField(): FormlyFieldConfig {
    return {
      key: 'externalId',
      type: 'input',
      className: null,
      wrappers: ['core-portal-translated', 'core-portal-readonly'],
      templateOptions: {
        type: 'text',
        corePortalTranslated: {
          label: 'core-shared.shared.fields.external-id',
          validationMessages: {
            required: 'core-portal.core.validation.required'
          }
        } as CorePortalFormlyTranslatedTyping,
        corePortalReadonly: {
          type: CorePortalFormlyReadonlyTypes.BASIC
        } as CorePortalFormlyReadonlyTyping
      },
      expressionProperties: {
        className: () => this.creating ? 'col-md-4' : 'col-md-4 title-input',
        'templateOptions.corePortalTranslated.hideLabel': () => !this.creating ? true : undefined,
        'templateOptions.corePortalTranslated.formGroupClassName': () => !this.creating ? 'mb-0' : undefined,

        'templateOptions.required': () => !this.readonly,
        'templateOptions.disabled': () => this.readonly,
        'templateOptions.readonly': () => this.readonly
      }
    };
  }
}
