import {AppEntityType} from '@nexnox-web/core-shared';
import {createSelector} from '@ngrx/store';
import {selectMissionsState} from '../../missions.selectors';
import {TechPortalFeatureMissionService} from '../../services';
import {MissionGanttListXsStore} from './mission-gantt-list.xs-store';

/* istanbul ignore next */
export const missionGanttListStore = new MissionGanttListXsStore({
  actionLabel: 'Tech Portal - Missions - Mission Gantt List',
  stateSelector: createSelector(selectMissionsState, state => state.missionGanttList),
  serviceType: TechPortalFeatureMissionService,
  entityType: AppEntityType.Mission,
  selectId: mission => mission.entity?.missionId ?? mission.model?.missionId
});
