import {NgModule} from '@angular/core';
import {
  CorePortalAttachmentsModule,
  CorePortalEntityModule,
  CorePortalLabelHelperModule,
  CorePortalRouterOverridesModule
} from '@nexnox-web/core-portal';
import {CorePortalFeatureMasterDataLocationsModule} from '@nexnox-web/core-portal/features/master-data/features/locations';
import {CorePortalFeatureAuditModule} from '@nexnox-web/core-portal/features/audit';
import {CoreSharedModule} from '@nexnox-web/core-shared';
import {TechPortalFeatureTicketSettingsStatesModule} from '@nexnox-web/tech-portal/features/ticket-settings/features/states';
import {TechPortalFeatureTicketSettingsPrioritySetsModule} from '@nexnox-web/tech-portal/features/ticket-settings/features/priority-sets';
import {TechPortalFeatureTicketSettingsEscalationLevelsModule} from '@nexnox-web/tech-portal/features/ticket-settings/features/escalation-levels';
import {TicketMissionLinksComponent, TicketRelatedTicketLinksComponent, TicketStatusEditComponent} from './components';
import {
  TechPortalFeatureTicketListComponent,
  TicketAuditComponent,
  TicketDetailComponent,
  TicketEventsComponent,
  TicketMissionDetailStandaloneComponent,
  TicketMissionsComponent,
  TicketSolutionComponent
} from './containers';
import {ticketsServices} from './store';
import {TicketAssignModalComponent, TicketFollowUpModalComponent} from './modals';
import {TechPortalFeatureMissionsModule} from '@nexnox-web/tech-portal/features/missions';
import {TechPortalFeatureSolutionModule} from '@nexnox-web/tech-portal/features/solution';
import {CorePortalFeatureMasterDataContactsModule} from '@nexnox-web/core-portal/features/master-data/features/contacts';
import {TechPortalModule} from '@nexnox-web/tech-portal-lib';
import {TechPortalFeatureResourcesModule} from '@nexnox-web/tech-portal/features/resources';
import {TechPortalFeatureContractsModule} from '@nexnox-web/tech-portal/features/contracts';
import {CorePortalFeatureMasterDataLocationGroupsModule} from '@nexnox-web/core-portal/features/master-data/features/location-groups';
import {CorePortalFeatureMasterDataFunctionsModule} from '@nexnox-web/core-portal/features/master-data/features/functions';
import {TechPortalFeatureTicketsMissionsModule} from '@nexnox-web/tech-portal/features/tickets-missions';
import {TicketStateChangeSidebarComponent} from "./sidebars";
import {CorePortalLabelService} from "@nexnox-web/core-portal/features/settings/features/labels/src/lib/store/services/label/label.service";
import {AddressesDeclarationsModule} from "@nexnox-web/core-portal/features/master-data/features/addresses/src/lib/addresses-declarations.module";
import {IssuesDeclarationsModule} from "@nexnox-web/tech-portal/features/issues/src/lib/issues-declarations.module";
import {TechPortalFeatureIssuesModule} from "@nexnox-web/tech-portal/features/issues";

@NgModule({
  declarations: [
    TechPortalFeatureTicketListComponent,
    TicketDetailComponent,
    TicketSolutionComponent,
    TicketEventsComponent,
    TicketAuditComponent,
    TicketMissionsComponent,
    TicketStatusEditComponent,
    TicketMissionLinksComponent,
    TicketRelatedTicketLinksComponent,
    TicketFollowUpModalComponent,
    TicketAssignModalComponent,
    TicketStateChangeSidebarComponent,
    TicketMissionDetailStandaloneComponent
  ],
  imports: [
    CoreSharedModule,
    CorePortalRouterOverridesModule,
    CorePortalEntityModule,
    TechPortalModule,
    TechPortalFeatureTicketsMissionsModule,
    TechPortalFeatureSolutionModule,
    TechPortalFeatureResourcesModule.forFeature(),
    CorePortalFeatureMasterDataContactsModule.forFeature(),
    CorePortalFeatureMasterDataFunctionsModule.forFeature(),
    CorePortalFeatureMasterDataLocationsModule.forFeature(),
    CorePortalFeatureMasterDataLocationGroupsModule.forFeature(),
    TechPortalFeatureTicketSettingsStatesModule.forFeature(),
    TechPortalFeatureTicketSettingsPrioritySetsModule.forFeature(),
    TechPortalFeatureTicketSettingsEscalationLevelsModule.forFeature(),
    TechPortalFeatureMissionsModule.forFeature(),
    TechPortalFeatureIssuesModule.forFeature(),
    CorePortalFeatureAuditModule,
    TechPortalFeatureContractsModule.forFeature(),
    CorePortalAttachmentsModule,
    CorePortalLabelHelperModule,
    AddressesDeclarationsModule,
    IssuesDeclarationsModule
  ],
  exports: [
    TechPortalFeatureTicketListComponent
  ],
  providers: [
    CorePortalLabelService,
    ...ticketsServices
  ]
})
export class TicketsDeclarationsModule {
}
