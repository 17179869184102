<nexnox-web-entity-overview #overviewComponent
                            [appendColumns]="appendColumns()"
                            [cardBoxHeaderActions]="getCardBoxHeaderActions()"
                            [datatableConfig]="customDatatableConfig"
                            [entityOverviewBaseComponent]="this"
                            detailLink="/missions"
                            module="inventory">
</nexnox-web-entity-overview>

<ng-template #ganttRowTemplate
             let-currentRow="row">
  <div class="gantt-block-container">
    <ng-container *ngIf="isFirstRow(ganttRows, currentRow)"
                  [ngTemplateOutlet]="ganttHeaderTemplate">
    </ng-container>
    <div (click)="expandDay(block, ganttRows)"
         *ngFor="let block of getGanttRow(ganttRows, currentRow)"
         [class.--expanded]="block.isExpanded"
         class="gantt-block">
      <div [class.gantt-day-empty]="block.isEmpty"
           [class.gantt-odd-week]="block.isOddWeek"
           class="gantt-day-cell">
        <div *ngIf="!block.isEmpty" [style.background-color]="'#' + block.contactColor"
             [style.left.%]="block.fillStart"
             [style.right.%]="block.fillEnd"
             class="gantt-day-bar">
        </div>
        <div *ngIf="block.isExpanded && !block.isEmpty" class="gantt-day-expanded-hour-scale">
          <div *ngFor="let hour of hourScaleArray">
            <span *ngIf="hour > 0">{{ hour }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #ganttHeaderTemplate>
  <div class="gantt-header">
    <div *ngFor="let block of ganttHeader; let index = index"
         [class.--expanded]="block.isExpanded"
         class="gantt-header-day-cell">
      <div class="gantt-header-month-title">
        <div *ngIf="block.isFirstOfMonth || index === 0">{{ block.monthTitle }}</div>
        &nbsp;
      </div>
      <div *ngIf="!isHideWeekNumbers" class="gantt-header-week-number">
        <div *ngIf="(block.isMonday || index === 0)">W {{ block.weekNumber }}</div>
        &nbsp;
      </div>
      <div [class.gantt-header-day-weekend]="block.isWeekend"
           class="gantt-header-day-number">
        {{ block.day }}
      </div>
    </div>
  </div>
</ng-template>
