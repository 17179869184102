import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {TechPortalFeatureMissionService} from "../../store/services";
import {
  ApiNotificationService,
  CombineOperator,
  ContactDto,
  CoreSharedGoogleMapsService,
  DataTableViewType,
  Filter,
  FilterOperators,
  FilterTypes,
  MissionDistanceListDto,
  MissionState,
  PointListDto,
  UnsubscribeHelper
} from "@nexnox-web/core-shared";
import {BehaviorSubject, combineLatestWith, filter, map, Observable, of, pairwise, Subject, takeWhile, tap} from "rxjs";
import {cloneDeep, isEqual} from "lodash";
import {
  CorePortalCardboxAction,
  CorePortalFormlyNgSelectTyping,
  CorePortalPermissionService,
  CorePortalTenantRouter
} from "@nexnox-web/core-portal";
import {faSpinner} from '@fortawesome/free-solid-svg-icons/faSpinner';
import {TranslateService} from "@ngx-translate/core";
import {faMapMarkerAlt} from '@fortawesome/free-solid-svg-icons/faMapMarkerAlt';
import {getCardBoxHeaderActions} from '../mission-table-header-actions/mission-table-header-actions';
import {FormlyFieldConfig} from "@ngx-formly/core";
import {CorePortalFeatureMasterDataContactService} from "@nexnox-web/core-portal/features/master-data/features/contacts";
import {techPortalFeatureMissionDistanceOptions} from "../../models";
import {FormGroup} from "@angular/forms";


export interface NearbyMissionMapDto {
  origin: PointListDto,
  circle: google.maps.Circle
  marker: google.maps.Marker,
  nearby: NearbyMissionsDto[]
}

export interface NearbyMissionsDto {
  point: PointListDto;
  marker: google.maps.Marker;
  infoWindow: google.maps.InfoWindow;
  missions: MissionDistanceListDto[];
}

@Component({
  selector: 'nexnox-web-missions-mission-map-view',
  templateUrl: './mission-map-view.component.html',
  styleUrls: ['./mission-map-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MissionMapViewComponent extends UnsubscribeHelper implements OnInit {
  @Input() public mode: BehaviorSubject<DataTableViewType>;
  @Input() public disableSettingsViewSelect = false;

  public missionMap: NearbyMissionMapDto;
  public missions: MissionDistanceListDto[] = [];

  public apiLoaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public componentReady$: Observable<boolean> = of(false);
  public loading$ = new Subject<boolean>();
  public showNoUserPositionDialog: boolean;

  public mapDiv: HTMLDivElement;
  public placesInput: HTMLInputElement;
  public map: google.maps.Map;
  public placeAutoComplete: google.maps.places.Autocomplete;

  public faSpinner = faSpinner;
  public faMapMarkerAlt = faMapMarkerAlt;

  public detailZoom = 17;

  public filterForm: FormGroup;
  public filterModel: { distance: number, missionContact: ContactDto };
  public filterFields: FormlyFieldConfig[];
  public paging: any;

  private _placesService: google.maps.places.PlacesService;
  private _geocoderService: google.maps.Geocoder;

  constructor(
    private _permissionService: CorePortalPermissionService,
    private _missionService: TechPortalFeatureMissionService,
    private _mapsService: CoreSharedGoogleMapsService,
    private _router: CorePortalTenantRouter,
    private _translate: TranslateService,
    private _contactService: CorePortalFeatureMasterDataContactService,
    private _apiNotificationService: ApiNotificationService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.loading$.next(true);
    this.initializeComponent();
    this.initializeFilterOptions();
  }

  public initializeFilterOptions(): void {
    this.filterForm = new FormGroup({});
    this.filterFields = this.createFilterForm();
    this.filterModel = {
      distance: 25, missionContact: null
    } as any;

    // Action when filters changed (distinct)
    this.subscribe(this.filterForm.valueChanges.pipe(pairwise(), filter(([prev, curr]) => !isEqual(prev, curr))),
      () => {
        if (this.missionMap?.origin) this.findMissions(this.missionMap.origin as google.maps.LatLngLiteral);
      });
  }

  public initializeComponent(): void {
    // Load maps api with service
    const isApiLoaded$ = this._mapsService.getApiStatus();
    this._mapsService.loadMapsApi();

    // Get elements
    const mapsDiv$ = this._mapsService.getElementById('map');
    const placesInput$ = this._mapsService.getElementById('places');

    // Wait and set html elements
    this.componentReady$ = isApiLoaded$.pipe(
      combineLatestWith(mapsDiv$, placesInput$),
      map(([isApi, mapDiv, placesInput]) => {
        if ((isApi && mapDiv && placesInput)) {
          this.mapDiv = mapDiv;
          this.placesInput = placesInput;
          return true;
        } else {
          return false;
        }
      })
    );

    // Initialize when ready
    this.componentReady$.pipe(
      tap((isReady) => {
        if (isReady) this.initializeMaps();
      }),
      takeWhile((isReady) => isReady !== true)
    ).subscribe();
  }

  public initializeMaps(): void {
    this.initMap();
    this.initPlaces();
    this.initGeoCoderService();
    this.initCenter();
  }

  public initMap(): void {
    // Create map
    this.map = new google.maps.Map(this.mapDiv);

    // Map options
    const mapOptions = {
      streetViewControl: false,
      zoom: this.detailZoom
    }

    this.map.setOptions(mapOptions);

    // Map style
    // Generate a style json here:
    // https://mapstyle.withgoogle.com/
    this.map.set('styles', [
      {
        "featureType": "administrative.land_parcel",
        "elementType": "labels",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "poi",
        "elementType": "labels",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      }
    ]);

    // Close info window event on outside click
    this.map.addListener('click', () => this._closeInfoWindows());
  }

  public initPlaces(): void {
    this._placesService = this._mapsService.getPlacesService(this.map);
    this.placeAutoComplete = new google.maps.places.Autocomplete(this.placesInput as HTMLInputElement, {});
    this.placeAutoComplete.bindTo('bounds', this.map);
    this.placeAutoComplete.addListener('place_changed', () => {
      const place = this.placeAutoComplete.getPlace();
      this.centerMap(place.geometry.location.toJSON());
      this.findMissions(place.geometry.location.toJSON());
    });

  }

  public initGeoCoderService(): void {
    this._geocoderService = this._mapsService.getGeocoderService();
  }

  public initCenter(): void {
    const germany = { lat: 51.165691, lng: 10.451526 };
    this.map.setZoom(6);
    this.map.setCenter(germany);
    this.loading$.next(false);
  }

  public getUserPosition(): void {
    this.loading$.next(true);
    navigator.geolocation.getCurrentPosition(position => this.findUserLocation({
        lat: position.coords.latitude,
        lng: position.coords.longitude
      }),
      () => {
        this.showNoUserPositionDialog = true;
        this.loading$.next(false)
      }
    );
  }

  public findUserLocation(location: google.maps.LatLngLiteral): void {
    this._geocoderService.geocode({ location }, (results, status) => {
      if (status === google.maps.GeocoderStatus.OK) {
        if (results.length > 0) {
          this.placesInput.value = results[0]?.formatted_address;
        }
        this.filterForm.get('distance').setValue(25);
        this.findMissions(location);
      } else {
        this._apiNotificationService.showTranslatedError('core-portal.core.error.google-places-status');
        return;
      }
    })
  }

  public centerMap(location: google.maps.LatLngLiteral): void {
    this.map.setCenter(location);
  }

  public loadMore(): void {
    this.findMissions(this.missionMap.origin as google.maps.LatLngLiteral, this.paging.pageNumber += 1, true)
  }

  public findMissions(origin: google.maps.LatLngLiteral, page: number = 0, appendItems?: boolean): void {
    this.loading$.next(true);
    this._missionService.getMissionsByDistance(origin, this.filterModel?.distance * 1000, this.getMissionFilter(), page, 50).subscribe(body => {

      if (appendItems) {
        this.missions = [...this.missions, ...body.items];
      } else {
        this.missions = body.items;
      }

      this.paging = body.paging;
      this.paging.displayedItems = this.missions?.length;
      this.generateMissionMap(origin, this.missions);

      this.centerMap(origin);
      if (this.filterModel.distance < 50) {
        this.map.setZoom(11);
      } else if (this.filterModel.distance >= 250) {
        this.map.setZoom(6);
      } else if (this.filterModel.distance >= 100) {
        this.map.setZoom(7);
      } else if (this.filterModel.distance >= 50) {
        this.map.setZoom(10);
      }
    });
  }

  public generateMissionMap(origin: PointListDto, missions: MissionDistanceListDto[]): void {

    // Clone missions
    missions = cloneDeep(missions);

    // reset mission map
    this.resetMissionMap();

    // place origin
    this.missionMap.origin = origin;

    // generate mission map object
    while (missions.length > 0) {
      const nearbyMissions: NearbyMissionsDto = {
        point: missions[0].shippingAddress.point,
        marker: null,
        infoWindow: null,
        missions: []
      }
      for (let j = 0; j < missions.length; j++) {
        if (isEqual(missions[j].shippingAddress.point, nearbyMissions.point)) {
          nearbyMissions.missions.push(missions[j]);
          missions.splice(j, 1);
          j--;
        }
      }
      this.missionMap.nearby.push(nearbyMissions);
    }

    // stop loading
    this.loading$.next(false);

    // place markers
    this.placeMarkers();
    this.placeCircle();
  }

  public placeMarkers(): void {

    // set origin marker
    this.missionMap.marker = new google.maps.Marker({
      position: this.missionMap.origin as google.maps.LatLngLiteral,
      animation: google.maps.Animation.DROP,
      map: this.map,
      icon: 'http://maps.google.com/mapfiles/kml/pushpin/wht-pushpin.png',
      zIndex: 1
    })

    // zoom on double click
    this.missionMap.marker.addListener('dblclick', (event) => {
      this.map.setCenter(event.latLng.toJSON());
      this.map.setZoom(this.detailZoom - 3);
    });

    // parse mission map object
    for (let i = 0; i < this.missionMap.nearby.length; i++) {
      const current = this.missionMap.nearby[i];

      // place marker
      current.marker = new google.maps.Marker({
        position: current.point as google.maps.LatLngLiteral,
        animation: google.maps.Animation.DROP,
        label: current.missions.length > 1 ? `${ current.missions.length }` : undefined,
        map: this.map,
        zIndex: 10 + i
      });

      // generate info window
      current.infoWindow = new google.maps.InfoWindow({
        content: this._generateInfoMarkup(current.missions),
      });

      // open info window with mission details
      current.marker.addListener("click", () => {
        current.infoWindow.open({
          anchor: current.marker,
          map: this.map,
          shouldFocus: false,
        });
      });

      // zoom on double click
      current.marker.addListener('dblclick', (event) => {
        this.map.setCenter(event.latLng.toJSON());
        this.map.setZoom(this.detailZoom);
      });
    }
  }

  public placeCircle(): void {
    this.missionMap.circle = new google.maps.Circle({
      strokeColor: "#545454",
      strokeOpacity: 0.5,
      strokeWeight: 3,
      fillColor: "#FF0000",
      fillOpacity: 0,
      map: this.map,
      center: this.missionMap.origin as google.maps.LatLngLiteral,
      radius: this.filterModel.distance * 1000,
    });
    // Close info window event on outside click
    this.missionMap.circle.addListener('click', () => this._closeInfoWindows());
  }

  public resetMissionMap(): void {
    for (let i = 0; i < this.missionMap?.nearby?.length; i++) {
      // remove marker
      this.missionMap.nearby[i].marker.setMap(null);
      delete this.missionMap.nearby[i].marker;
    }
    this.missionMap?.marker.setMap(null);
    delete this.missionMap?.marker;
    this.missionMap?.circle.setMap(null);
    delete this.missionMap?.circle;

    // initialize
    this.missionMap = {
      origin: null,
      marker: null,
      circle: null,
      nearby: []
    }
  }

  public getCardBoxHeaderActions(): CorePortalCardboxAction[] {
    return !this.disableSettingsViewSelect ? getCardBoxHeaderActions(this.mode, this._permissionService, this._translate, this._router) : [];
  }

  public createFilterForm(): FormlyFieldConfig[] {
    return [
      {
        key: 'distance',
        type: 'core-portal-ng-select',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        defaultValue: 25,
        templateOptions: {
          corePortalTranslated: {
            label: 'missions.fields.range'
          },
          corePortalNgSelect: {
            items: techPortalFeatureMissionDistanceOptions,
            translate: true,
          } as CorePortalFormlyNgSelectTyping
        }
      },
      {
        key: 'missionContact',
        type: 'core-portal-entity-select',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        defaultValue: undefined,
        templateOptions: {
          corePortalTranslated: {
            label: 'missions.fields.contact'
          },
          entityService: this._contactService,
          idKey: 'contactId',
          displayKey: 'displayName',
          wholeObject: true,
          skipGetOne: true,
          module: 'management'
        }
      }
    ];
  }

  public getMissionFilter(): Filter[] {
    const filters: Filter[] = [];

    filters.push({
      type: FilterTypes.Grouped,
      combinedAs: CombineOperator.Or,
      children: [
        {
          property: 'state',
          type: FilterTypes.DataTransferObject,
          operator: FilterOperators.Equal,
          value: MissionState.Instructed.toString()
        },
        {
          property: 'state',
          type: FilterTypes.DataTransferObject,
          operator: FilterOperators.Equal,
          value: MissionState.Created.toString()
        }
      ]
    });

    if (this.filterModel?.missionContact) {
      filters.push({
        property: 'solutionContact.contactId',
        type: FilterTypes.DataTransferObject,
        operator: FilterOperators.Equal,
        value: this.filterModel?.missionContact?.contactId?.toString()
      });
    }
    return filters;
  }

  private _closeInfoWindows(): void {
    this.missionMap.nearby.forEach((marker) => marker.infoWindow.close());
  }

  private _generateInfoMarkup(missions: MissionDistanceListDto[]): string {

    // title
    let markup = `<p>${ missions[0].shippingAddress.street } ${ missions[0].shippingAddress.houseNumber }, ${ missions[0].shippingAddress.zipcode } ${ missions[0].shippingAddress.city }</p>`;

    for (let i = 0; i < missions.length; i++) {
      // mission and link
      const route = this._router.createUrlTree([`missions/${ missions[i].missionId }`]);
      markup += `<p>${ this._translate.instant('missions.subtitles.mission') }: <a href="${ route }" target="_blank">${ missions[i].title }</a>`;

      // status
      markup += `<br>${ this._translate.instant('missions.fields.state') }: ${ this._translate.instant('missions.mission-states.' + missions[i].state) }`

      // solution contact
      markup += `<br>${ this._translate.instant('missions.fields.contact') }: `
      markup += missions[i].solutionContact?.displayName ?
        `${ missions[i].solutionContact?.displayName }</p>` :
        `(${ this._translate.instant('missions.descriptions.not-specified') })</p>`;
    }
    return markup;
  }
}
