import {EntityXsStore, EntityXsStoreState} from '@nexnox-web/core-store';
import {AppEntityType, DashboardDto} from '@nexnox-web/core-shared';
import {Action, createSelector} from '@ngrx/store';
import {selectDashboardsState} from '../../dashboards.selectors';
import {CorePortalDashboardService} from '../../services';
import {Injectable, Injector} from '@angular/core';

export interface DashboardDetailStoreState extends EntityXsStoreState<DashboardDto> {
}

/* istanbul ignore next */
export const dashboardDetailStore = new EntityXsStore<DashboardDto>({
  actionLabel: 'Core Portal - Settings - Dashboards - Dashboard Detail',
  stateSelector: createSelector(selectDashboardsState, state => state.dashboardDetail),
  serviceType: CorePortalDashboardService,
  entityType: AppEntityType.None,
  stereotyped: false
});

export function dashboardDetailStoreReducer(state: DashboardDetailStoreState, action: Action): any {
  return dashboardDetailStore.reducer(state, action);
}

@Injectable()
export class DashboardDetailStoreEffects extends dashboardDetailStore.effects {
  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }
}
