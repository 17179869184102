import {AppEntityType, DeviceMetaListDto} from '@nexnox-web/core-shared';
import {PagedEntitiesXsStoreState} from '@nexnox-web/core-store';
import {createSelector} from '@ngrx/store';
import {selectDevicesState} from '../../devices.selectors';
import {OrgaPortalFeatureDeviceMetadataService} from '../../services';
import {DeviceMetadataListXsStore} from './device-metadata-list.xs-store';


export interface DeviceMetadataListStoreState extends PagedEntitiesXsStoreState<DeviceMetaListDto> {
}

/* istanbul ignore next */
export const deviceMetadataListStore = new DeviceMetadataListXsStore({
  actionLabel: 'Tech Portal - Devices - Device Metadata List',
  stateSelector: createSelector(selectDevicesState, state => state.deviceMetadata),
  entityType: AppEntityType.None,
  stereotyped: false,
  serviceType: OrgaPortalFeatureDeviceMetadataService,
  selectId: device => device.entity?.deviceMetadataId ?? device.model?.deviceMetadataId
});
