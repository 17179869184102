import {FieldType, FormlyTemplateOptions} from '@ngx-formly/core';
import {Component, TemplateRef, Type} from '@angular/core';
import {
  CoreSharedApiBaseService,
  CoreSharedModalBaseComponent,
  Filter,
  FilterDto,
  PageableRequest
} from '@nexnox-web/core-shared';
import {Observable} from 'rxjs';
import {at, isArray} from 'lodash';
import {CorePortalEntitySelectOptions} from '../../../entity/entity-select/components';
import {AddTagFn} from '@ng-select/ng-select/lib/ng-select.component';

export interface EntitySelectTemplateOptions extends FormlyTemplateOptions {
  entityId?: number;
  idKey: string;
  displayKey: string;
  entityService: CoreSharedApiBaseService;
  multiple?: boolean;
  defaultFilters$?: Observable<FilterDto[]>;
  refresh$?: Observable<void>;
  onlyRefreshEntity?: boolean;
  compareWith?: (a: any, b: any) => boolean;
  search?: (term: string, item: any) => boolean;
  mapSearchFilter?: (filter: Filter) => Filter;
  wholeObject?: boolean;
  clearable?: boolean;
  excludedIds?: number[];
  firstToDefault?: boolean;
  skipGetOne?: boolean;
  waitUntil$: Observable<void>;
  customInit$?: Observable<CorePortalEntitySelectOptions>;
  customInitExtraClear?: boolean;
  overrideGetPage?: (pageNumber: number, pageSize: number, filters: FilterDto[]) => Promise<PageableRequest<any>>;
  link?: (value: any) => string | any[];
  module?: string;
  selectLabelTitleTemplate?: TemplateRef<any>;
  selectLabelTemplate?: TemplateRef<any>;
  selectOptionTitleTemplate?: TemplateRef<any>;
  selectOptionTemplate?: TemplateRef<any>;
  datatableSearch?: Type<CoreSharedModalBaseComponent>;
  appendTo?: string;
  addTagFn?: AddTagFn;
  showAll?: boolean;
  editable?: boolean;
  onEdit?: (event: MouseEvent, base: () => void) => void;
  optionalColumns?: string[];
}

@Component({
  selector: 'nexnox-web-formly-entity-select',
  templateUrl: './formly-entity-select.component.html'
})
export class FormlyEntitySelectComponent extends FieldType {
  public to: EntitySelectTemplateOptions;

  /* istanbul ignore next */
  public get modelAtKey(): any {
    const value = at(this.model, isArray(this.key) ? this.key : [this.key]);
    return value?.length ? value[0] : null;
  }

  public onModelChange(value: any): void {
    this.onTouched();
    this.onDirty();
    this.formControl.setValue(value);
  }

  public onTouched(): void {
    this.formControl.markAsTouched();
  }

  public onDirty(): void {
    this.formControl.markAsDirty();
  }
}
