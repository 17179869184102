<ng-container *ngIf="(loading$ | async) || !loading else loadingTemplate">
  <div *ngIf="item$ | async as item"
       [class.solution-item--message]="isCollapsableItem$ | async"
       class="solution-item px-2">

    <div class="solution-item__icon">
      <div class="solution-item__icon__circle">
        <fa-icon [icon]="getSolutionItemIcon(item)"
                 size="lg">
        </fa-icon>
      </div>
      <div [class.d-none]="isLastItem"
           class="solution-item__icon__vertical-line">
      </div>
    </div>

    <div class="solution-item__left-line">
      <div></div>
    </div>

    <div [class.solution-item__content--is-solution]="item.isSolution"
         class="solution-item__content mb-md-4 mb-sm-0">

      <div class="solution-item__content__header">
        <div (click)="toggleCollapsed()"
             [class.solution-item--pointer]="isCollapsableItem$ | async"
             class="solution-item__content__header__title">
          <div [class.solution-item__content__header__title--ellipsis]="isCollapsableItem$ | async">
            <nexnox-web-solutions-solution-item-title [isCollapsed]="isCollapsed"
                                                      [item$]="item$">
            </nexnox-web-solutions-solution-item-title>
          </div>
          <span *ngIf="isMissionCanceled && item?.note?.reason as reason"
                class="solution-item__content__header__title__info">
            <strong>{{ 'core-shared.shared.fields.reason' | translate }}:&nbsp;</strong>{{ reason }}
          </span>
          <div>
            <span class="solution-item__content__header__title__date">
              {{ createdAt$ | async | nexnoxWebBsDate:'dd L LT' | async }}
            </span>
            <span *ngIf="isCollapsed"
                  class="solution-item__content__header__title__info">
              {{ titleInfo$ | async }}
            </span>
          </div>
        </div>
        <div *ngIf="isCollapsableItem$ | async"
             class="solution-item__content__header__action-btns ml-2">

          <div *ngIf="$any(item?.note?.attachments).length"
               class="solution-item__content__header__action-btns__attachment mr-3 position-relative">
            <div class="solution-item__content__header__action-btns__attachment__icon ml-1">
              <fa-icon [icon]="faPaperClip"></fa-icon>
            </div>
            <div class="solution-item__content__header__action-btns__attachment__indicator">
              <div>
                {{ $any(item?.note?.attachments).length }}
              </div>
            </div>
          </div>

          <div>
            <nexnox-web-cardbox-actions [actions]="headerActions"
                                        [data]="item"
                                        [useNew]="true">
            </nexnox-web-cardbox-actions>
          </div>
        </div>
      </div>

      <div *ngIf="isCollapsableItem$ | async"
           [class.d-none]="isCollapsed"
           class="solution-item__content__message pb-2 position-relative">

        <div *ngIf="mailTo$ | async as mailTo"
             class="solution-item__content__message__detail">
          <strong>{{ 'solutions.descriptions.receiver' | translate }}: </strong><span>{{ mailTo }}</span>
        </div>

        <div *ngIf="mailCc$ | async as mailCc"
             class="solution-item__content__message__detail">
          <strong>{{ 'solutions.descriptions.cc' | translate }}: </strong><span>{{ mailCc }}</span>
        </div>

        <div *ngIf="mailFrom$ | async as mailFrom"
             class="solution-item__content__message__detail">
          <strong>{{ 'solutions.descriptions.sender' | translate }}: </strong><span>{{ mailFrom }}</span>
        </div>

        <div *ngIf="mailSubject$ | async as mailSubject"
             class="solution-item__content__message__detail">
          <strong>{{ 'solutions.descriptions.subject' | translate }}: </strong><span>{{ mailSubject }}</span>
        </div>

        <div *ngIf="phoneNumber$ | async as phoneNumber"
             class="solution-item__content__message__detail">
          <strong>{{ 'solutions.descriptions.solution-item-phone-caller' | translate: { phoneNumber: '' } }}</strong><span>{{ phoneNumber }}</span>
        </div>

        <div *ngIf="isMessage$ | async"
             [innerHTML]="description$ | async"
             class="solution-item__content__message__description mt-3">
        </div>

        <div *ngIf="isChangedStatusWithReason$ | async" class="solution-item__content__message__description mt-3">
          <span><strong>{{ 'solutions.descriptions.solution-item-reason' | translate }}</strong>: </span><span
          [innerHTML]="item.note.description"></span>
        </div>

        <div *ngFor="let attachment of $any(item.note?.attachments)">
          <a [href]="attachment.file.uri"
             class="solution-item__content__message__attachment mt-4"
             target="_blank">
            <div class="solution-item__content__message__attachment__thumbnail">
              <fa-icon *ngIf="!attachment.isImage"
                       [fixedWidth]="true"
                       [icon]="attachment?.fileIcon"
                       size="lg">
              </fa-icon>
              <p-image *ngIf="attachment.isImage"
                       [alt]="attachment.file.name"
                       [src]="attachment.file.uri">
              </p-image>
            </div>
            <div class="solution-item__content__message__attachment__name ml-2">
              {{ attachment.file?.name }}
            </div>
          </a>
        </div>

        <div *ngIf="isShowReplyAction$ | async"
             class="solution-item__content__message__reply-button pt-1">
          <button (click)="onReply(item)"
                  [icon]="faReply | nexnoxWebFaIconStringPipe"
                  [label]="'solutions.actions.reply-to-mail' | translate"
                  class="p-button-text p-button-secondary"
                  pButton
                  type="button">
          </button>
        </div>

      </div>
    </div>

    <div *ngIf="item.createdByName || (memberReference$ | async)"
         class="solution-item__right-line">
      <div></div>
    </div>

    <div class="solution-item__info pl-md-2 pb-md-0 pt-md-0 pt-2 pb-4">
      <div class="solution-item__info__name">{{ item.note?.chatMessage?.sender?.name ?? item.createdByName ?? '' }}
      </div>
      <div class="solution-item__info__in ml-sm-1 ml-md-0">
        <ng-container *ngIf="memberReference$ | async as memberReference">
          <ng-container *ngIf="memberReference.name">
            <span>
              {{ 'solutions.descriptions.in' | translate }}
              {{ 'solutions.solution-members.' + memberReference.type | translate }}:
            </span>
            <a [module]="memberReference.module"
               [nexnoxWebTenantRouterLink]="memberReference.link"
               target="_blank">
              {{ memberReference.name }}
            </a>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #loadingTemplate>
  <div class="solution-item px-2">
    <div class="solution-item__icon">
      <div class="solution-item__icon__circle">
        <nexnox-web-loading-icon [size]="'lg'"></nexnox-web-loading-icon>
      </div>
      <div [class.d-none]="true"
           class="solution-item__icon__vertical-line">
      </div>
    </div>

    <div class="solution-item__left-line">
      <div></div>
    </div>

    <div class="solution-item__content solution-item__content mb-md-4 mb-sm-0">

      <div class="solution-item__content__header">
        <div class="solution-item__content__header__title">
          <div class="mb-1">
            <ngx-skeleton-loader [theme]="{
              display: 'flex',
              width: '200px',
              height: '16.9px',
              'margin-bottom': '0'
            }"></ngx-skeleton-loader>
          </div>
          <ngx-skeleton-loader [theme]="{
            display: 'flex',
            width: '100px',
            height: '12px',
            'margin-bottom': '0'
          }"></ngx-skeleton-loader>
        </div>
      </div>
    </div>
  </div>
</ng-template>
