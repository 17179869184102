import {
  TechPortalFeatureTicketSettingsPriorityService,
  TechPortalFeatureTicketSettingsPrioritySetService
} from './services';
import {
  PrioritySetDetailStoreEffects,
  prioritySetDetailStoreReducer,
  PrioritySetListStoreEffects,
  prioritySetListStoreReducer
} from './stores';

export * from './services';
export * from './stores';

export * from './priority-sets.state';
export * from './priority-sets.selectors';

export const prioritySetsReducers = {
  prioritySetList: prioritySetListStoreReducer,
  prioritySetDetail: prioritySetDetailStoreReducer
};

export const prioritySetsEffects = [
  PrioritySetListStoreEffects,
  PrioritySetDetailStoreEffects
];

export const prioritySetsServices = [
  TechPortalFeatureTicketSettingsPrioritySetService,
  TechPortalFeatureTicketSettingsPriorityService
];
