<ng-template #entityEditTemplate let-id="id" let-title="title">
  <nexnox-web-issues-winterhalter-service-call-issue-edit #modelComponent
                                                          (modelChange)="onModelChange($event)"
                                                          [id]="id"
                                                          [loading]="loading$ | async"
                                                          [model]="model$ | async"
                                                          [readonly]="true"
                                                          [stereotyped]="true"
                                                          [stereotypes$]="stereotypes$"
                                                          [title]="title">
  </nexnox-web-issues-winterhalter-service-call-issue-edit>
</ng-template>

<nexnox-web-entity-detail [entityDetailBaseComponent]="this"
                          [entityEditTemplate]="entityEditTemplate"
                          [useTabs]="false">
</nexnox-web-entity-detail>

