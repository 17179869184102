import {
  addToolbarToDropdown,
  ButtonView,
  createDropdown,
  icons,
  Plugin,
  SplitButtonView,
  ToolbarSeparatorView
} from 'ckeditor5';

// @ts-ignore


export default class NxMarkUI extends Plugin {

  public markerSvg = '<svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path class="ck-icon__fill" d="M10.798 1.59 3.002 12.875l1.895 1.852 2.521 1.402 6.997-12.194z"/><path d="m2.556 16.727.234-.348c-.297-.151-.462-.293-.498-.426-.036-.137.002-.416.115-.837.094-.25.15-.449.169-.595a4.495 4.495 0 0 0 0-.725c-.209-.621-.303-1.041-.284-1.26.02-.218.178-.506.475-.862l6.77-9.414c.539-.91 1.605-.85 3.199.18 1.594 1.032 2.188 1.928 1.784 2.686l-5.877 10.36c-.158.412-.333.673-.526.782-.193.108-.604.179-1.232.21-.362.131-.608.237-.738.318-.13.081-.305.238-.526.47-.293.265-.504.397-.632.397-.096 0-.27-.075-.524-.226l-.31.41-1.6-1.12zm-.279.415 1.575 1.103-.392.515H1.19l1.087-1.618zm8.1-13.656-4.953 6.9L8.75 12.57l4.247-7.574c.175-.25-.188-.647-1.092-1.192-.903-.546-1.412-.652-1.528-.32zM8.244 18.5 9.59 17h9.406v1.5H8.245z"/></svg>';

  public static get pluginName(): string {
    return 'NxMarkUI';
  }

  public get options(): any {
    return [
      {
        key: 'green',
        color: 'yellowgreen',
        title: 'Green marker'
      },
      {
        key: 'red',
        color: 'red',
        title: 'Red marker'
      }
    ];
  }

  public init(): void {
    for (const option of this.options) {
      this._addMarkButton(option);
    }

    this._addRemoveMarkButton();
    this._addDropdown();
  }

  private _addMarkButton(option): void {
    const command = this.editor.commands.get('nxMark');

    this._addButton(`nxMark:${ option.key }`, option.title, this.markerSvg, option.key, button => {
      button.bind('isEnabled').to(command, 'isEnabled');
      button.bind('isOn').to(command, 'value', value => value === option.key);
      button.iconView.fillColor = option.color;
      button.isToggleable = true;
    });
  }

  private _addRemoveMarkButton(): void {
    const command = this.editor.commands.get('nxMark');

    this._addButton('nxMark:remove', 'Remove mark', icons.eraser, null, button => {
      button.bind('isEnabled').to(command, 'isEnabled');
    });
  }

  private _addButton(name, label, icon, value, decorateButton): void {
    const t = this.editor.t;

    this.editor.ui.componentFactory.add(name, locale => {
      const buttonView = new ButtonView(locale);

      buttonView.set({
        label: t(label),
        icon,
        tooltip: true
      });

      buttonView.on('execute', () => {
        this.editor.execute('nxMark', { value });
        this.editor.editing.view.focus();
      });

      decorateButton(buttonView);

      return buttonView;
    });
  }

  private _addDropdown(): void {
    const t = this.editor.t;
    const componentFactory = this.editor.ui.componentFactory;

    const startingHighlighter = this.options[0];
    const optionsMap = this.options.reduce((returnValue, option) => {
      returnValue[option.key] = option;
      return returnValue;
    }, {});

    componentFactory.add('nxMark', locale => {
      const command = this.editor.commands.get('nxMark');
      const dropdownView = createDropdown(locale, SplitButtonView);
      const splitButtonView = dropdownView.buttonView;

      splitButtonView.set({
        tooltip: t('Mark'),
        icon: this.markerSvg,
        // @ts-ignore
        lastExecuted: startingHighlighter.key,
        commandValue: startingHighlighter.key,
        isToggleable: true
      });

      // @ts-ignore
      splitButtonView.bind('color').to(command, 'value', value => getActiveOption(value, 'color'));
      // @ts-ignore
      splitButtonView.bind('commandValue').to(command, 'value', value => getActiveOption(value, 'key'));
      splitButtonView.bind('isOn').to(command, 'value', value => !!value);
      splitButtonView.delegate('execute').to(dropdownView);

      const buttons = this.options.map(option => {
        const buttonView = componentFactory.create(`nxMark:${ option.key }`);
        // @ts-ignore
        this.listenTo(buttonView, 'execute', () => dropdownView.buttonView.set({ lastExecuted: option.key }));
        return buttonView;
      });

      // @ts-ignore
      dropdownView.bind('isEnabled').toMany(buttons, 'isEnabled', (...areEnabled) => areEnabled.some(isEnabled => isEnabled));

      buttons.push(new ToolbarSeparatorView());
      buttons.push(componentFactory.create('nxMark:remove'));

      addToolbarToDropdown(dropdownView, buttons);
      bindToolbarIconStyleToActiveColor(dropdownView);

      // dropdownView.toolbarView.ariaLabel = t('Mark toolbar');

      splitButtonView.on('execute', () => {
        // @ts-ignore
        this.editor.execute('nxMark', { value: splitButtonView.commandValue });
        this.editor.editing.view.focus();
      });

      function getActiveOption(current, key): any {
        // @ts-ignore
        const whichHighlighter = !current || current === splitButtonView.lastExecuted ? splitButtonView.lastExecuted : current;

        return optionsMap[whichHighlighter][key];
      }


      // function getActiveOption(current, key): any {
      //   const whichMarker = current;
      //   return optionsMap[whichMarker][key];
      // }

      return dropdownView;
    });
  }
}

function bindToolbarIconStyleToActiveColor(dropdownView): void {
  const actionView = dropdownView.buttonView.actionView;
  actionView.iconView.bind('fillColor').to(dropdownView.buttonView, 'color');
}
