import {faCircle} from '@fortawesome/free-solid-svg-icons/faCircle';
import {
  CorePortalContactService,
  CorePortalDatatableStandardConfig,
  CorePortalFunctionService,
  corePortalGetDatatableConfigDefaults,
  CorePortalLocationService,
  CorePortalRoleService,
  CorePortalStereotypeService,
  DatatableTableColumnType
} from '@nexnox-web/core-portal';
import {CorePortalFeatureArticleKindService} from '@nexnox-web/core-portal/features/articles';
import {
  CorePortalFeatureMasterDataAddressService
} from '@nexnox-web/core-portal/features/master-data/features/addresses';
import {
  CorePortalFeatureMasterDataLocationGroupService
} from '@nexnox-web/core-portal/features/master-data/features/location-groups';
import {
  CorePortalFeatureMasterDataLocationService,
  locationStateEnumOptions
} from '@nexnox-web/core-portal/features/master-data/features/locations';
import {CorePortalFeatureResourceService} from '@nexnox-web/core-portal/features/resources';
import {
  tenantSubscriptionKindOptions,
  tenantSubscriptionTypeEnumOptions
} from '@nexnox-web/core-portal/features/service';
import {
  AddressSimpleDto,
  AppEntityType,
  AppPermissions,
  CategorizedRelationDto,
  CategoryDto,
  CombineOperator,
  ConsumptionCodeSimpleListDto,
  ContactSimpleDto,
  ContractDto,
  ControlSimpleDto,
  ErrorCodeSimpleListDto,
  EscalationLevelSimpleDto,
  Filter,
  FilterOperators,
  FilterTypes,
  FunctionSimpleDto,
  InfoDto,
  IssueDetailsListDto,
  IssueType,
  LocationDto,
  LocationGroupRelationListDto,
  LocationRelationListDto,
  LocationSimpleDto,
  MissionDto,
  MissionSimpleListDto,
  ResourceInTicketDto,
  ResourceManufacturerListDto,
  ResourceModelListDto,
  ResourceSimpleDto,
  ResourceTaskSimpleDto,
  RoleInAreaListDto,
  StateDto,
  StereotypeSimpleDto,
  TaskDto,
  TicketSimpleDto,
  TicketSimpleListDto
} from '@nexnox-web/core-shared';
import {categorySectorEnumOptions, OrgaPortalFeatureCategoryService} from '@nexnox-web/orga-portal/features/categories';
import {platformEnumOptions} from '@nexnox-web/orga-portal/features/devices';
import {
  executionPlanEnumOptions,
  OrgaPortalFeatureTaskService,
  taskJobStateEnumOptions,
  taskStatesEnumOptions,
  taskTypeEnumOptions
} from '@nexnox-web/orga-portal/features/tasks';
import {TechPortalMissionService, TechPortalTicketService} from '@nexnox-web/tech-portal-lib';
import {contractStateEnumOptions} from '@nexnox-web/tech-portal/features/contracts';
import {
  techPortalFeatureMissionQuestStatEnumOptions,
  TechPortalFeatureMissionService,
  techPortalFeatureMissionStateEnumOptions
} from '@nexnox-web/tech-portal/features/missions';
import {TechPortalFeatureResourceTaskService} from '@nexnox-web/tech-portal/features/resource-tasks';
import {documentContextTypeEnumOptions, textContextTypeEnumOptions} from '@nexnox-web/tech-portal/features/templates';
import {
  TechPortalFeatureTicketSettingsEscalationLevelService
} from '@nexnox-web/tech-portal/features/ticket-settings/features/escalation-levels';
import {
  TechPortalFeatureTicketSettingsPriorityService
} from '@nexnox-web/tech-portal/features/ticket-settings/features/priority-sets';
import {
  TechPortalFeatureTicketSettingsStateService
} from '@nexnox-web/tech-portal/features/ticket-settings/features/states';
import {of} from 'rxjs';
import {infoStateEnumOptions} from "@nexnox-web/orga-portal/features/infos";
import {
  areaAssignmentReachEnumOptions
} from "@nexnox-web/core-portal/features/master-data/features/contacts/src/lib/components";
import {resourceTypeEnumOptions} from "@nexnox-web/tech-portal/features/resources";
import {
  CorePortalManufacturerService,
  CorePortalModelService
} from "@nexnox-web/core-portal/features/settings/features/manufacturers";
import {resourceStateColoredEnumOptions} from "@nexnox-web/tech-portal/features/resources/src/lib/models";
import {invitationPurposeEnumOptions} from "@nexnox-web/core-portal/features/settings/features/invitations";
import {
  issueContentTypeEnumOptions,
  issueTypeEnumOptions
} from "@nexnox-web/tech-portal/features/issues/src/lib/models";
import {TechPortalFeatureIssueListService} from "@nexnox-web/tech-portal/features/issues/src/lib/store";
import {
  controlConditionTypeEnumOptions,
  TechPortalFeatureControlService
} from "@nexnox-web/tech-portal/features/controls";
import {TechPortalFeatureConnectedErrorCodeService} from "@nexnox-web/tech-portal/features/connected/error-codes";
import {
  TechPortalFeatureConnectedConsumptionCodeService
} from "@nexnox-web/tech-portal/features/connected/consumption-codes";
import {
  importProtocolStatusEnumOptions
} from "@nexnox-web/core-portal/features/settings/features/mass-import/src/lib/models";
import {
  controlIssueReasonTypeEnumOptions
} from "@nexnox-web/tech-portal/features/controls/src/lib/models/control-issue-type-enum.model";

const taskArchiveDefault: any = {
  excludedColumns: [
    'task.taskId',
    'task.title',
    'task.description',
    'task.type',
    'task.children',
    'task.formId',
    'task.requiresAuthentication',
    'task.hasStrictOrder',
    'task.position',
    'task.reasonSetId',
    'task.categoryRelations',
    'jobStatus',
    'children',
    'parentId',
    'locationId',
    'taskId',
    'filledFormId',
    'locationNumber',
    'locationName',
    'location.name',
    'location.locationId',
    'location.locationNumber',
    'attachment',
    'attachment.attachmentId',
    'attachment.tenantId',
    'attachment.type',
    'attachment.name',
    'attachment.url',
    'attachment.link',
    'attachment.path',
    'attachment.referenceId',
    'attachment.infoId',
    'currentJobStatus',
    'currentJobStatus.taskJobStatusId',
    'currentJobStatus.reason',
    'currentJobStatus.reason.taskJobStateReasonId',
    'currentJobStatus.reason.tenantId',
    'currentJobStatus.status',
    'location.externalId',
  ],
  defaultColumns: [
    'taskJobId',
    'executionDate',
    'currentStatus',
    'attachment',
    'location'
  ],
  columnTypings: [
    {
      key: 'task',
      type: DatatableTableColumnType.REFERENCE,
      idKey: 'taskId',
      displayKey: 'title',
      service: OrgaPortalFeatureTaskService,
      link: (task: TaskDto) => task?.taskId ? `/tasks/${task.taskId}` : null,
      module: 'communication',
      permissions: [AppPermissions.ReadTask],
      linkPermissions: [AppPermissions.ReadTask]
    },
    {
      key: 'executionDate',
      type: DatatableTableColumnType.DATE,
      utc: true
    },
    {
      key: 'currentStatus',
      type: DatatableTableColumnType.ENUM,
      name: 'current-state',
      enumOptions: taskJobStateEnumOptions
    },
    {
      key: 'location',
      type: DatatableTableColumnType.REFERENCE,
      idKey: 'locationId',
      displayKey: 'name',
      service: CorePortalFeatureMasterDataLocationService,
      link: (location: LocationSimpleDto) => location?.locationId ?
        `/masterdata/locations/${location.locationId}` : null,
      module: 'management',
      permissions: [AppPermissions.ReadLocation],
      linkPermissions: [AppPermissions.ReadLocation]
    }
  ]
};

export function provideDatatableStandardConfig(): CorePortalDatatableStandardConfig {
  return {
    ...corePortalGetDatatableConfigDefaults(),
    ...provideManagementDatatableConfig(),
    ...provideCommunicationDatatableConfig(),
    ...provideInventoryDatatableConfig(),
    ...provideServiceDatatableConfig(),
    ...provideSettingsDatatableConfig()
  };
}

function provideManagementDatatableConfig(): CorePortalDatatableStandardConfig {
  const dashboardTaskHistoryDefault: any = {
    excludedColumns: [
      'identifier'
    ],
    columnTypings: [
      {
        key: 'taskTitle',
        name: 'task',
        type: DatatableTableColumnType.TEXT
      },
      {
        key: 'locationName',
        name: 'location',
        type: DatatableTableColumnType.TEXT
      },

      {key: 'countOpen', type: DatatableTableColumnType.NUMBER},
      {key: 'countOverdue', type: DatatableTableColumnType.NUMBER},
      {key: 'countEscalated', type: DatatableTableColumnType.NUMBER},
      {key: 'countDone', type: DatatableTableColumnType.NUMBER},
      {key: 'countCanceled', type: DatatableTableColumnType.NUMBER}
    ]
  };

  return {
    /* Dashboard */
    DashboardOverviewTaskDefault: {
      excludedColumns: [
        'isAny',
        'identifier'
      ],
      defaultColumns: [
        'name',
        'countPending',
        'countOpen',
        'countOverdue',
        'countEscalated',
        'countDone',
        'countCanceled',
      ],
      columnTypings: [
        {key: 'countPending', type: DatatableTableColumnType.NUMBER},
        {key: 'countOpen', name: 'count-start', type: DatatableTableColumnType.NUMBER},
        {key: 'countOverdue', type: DatatableTableColumnType.NUMBER},
        {key: 'countEscalated', type: DatatableTableColumnType.NUMBER},
        {key: 'countDone', type: DatatableTableColumnType.NUMBER},
        {key: 'countCanceled', type: DatatableTableColumnType.NUMBER}
      ]
    },
    DashboardTaskHistoryByLocation: {
      ...dashboardTaskHistoryDefault,
      defaultColumns: [
        'locationId',
        'locationName',
        'countStart',
        'countOverdue',
        'countEscalated',
        'countDone',
        'countCanceled'
      ]
    },
    DashboardTaskHistoryByTask: {
      ...dashboardTaskHistoryDefault,
      defaultColumns: [
        'taskId',
        'taskTitle',
        'countStart',
        'countOverdue',
        'countEscalated',
        'countDone',
        'countCanceled'
      ]
    },

    /* Master Data */
    MasterDataContactDefault: {
      excludedColumns: [
        'customPropertyValues',
        'isInRelation',
        'position',
        'tenantId',
        'stereotypeId',
        'stereotypeRowVersion',
        'stereotype.tenantId',
        'stereotype.rowVersion',
        'stereotype.position',
        'stereotype.isArchived',
        'stereotype.externalId',
        'stereotype.stereotypeId',
        'user',
        'user.id',
        'user.lastLoggedIn'
      ],
      defaultColumns: [
        'contactId',
        'firstName',
        'lastName',
        'displayName',
        'user.email',
        'pin',
        'stereotype',
        'lockedSince',
        'loginExpiration'
      ],
      columnTypings: [
        {
          key: 'pin',
          type: DatatableTableColumnType.TEXT,
          hideByDefault: true
        },
        {
          key: 'user.email',
          type: DatatableTableColumnType.TEXT,
          name: 'email-address'
        },
        {
          key: 'isFree',
          type: DatatableTableColumnType.BOOLEAN,
          name: 'is-active'
        },
        {
          key: 'lockedSince',
          type: DatatableTableColumnType.DATE,
          name: 'locked-out-date',
          utc: true
        },
        {
          key: 'loginExpiration',
          type: DatatableTableColumnType.DATE,
          utc: true
        },
        {
          key: 'mobile',
          type: DatatableTableColumnType.PHONE
        },
        {
          key: 'phone',
          type: DatatableTableColumnType.PHONE
        },
        {
          key: 'locations',
          type: DatatableTableColumnType.REFERENCE,
          idKey: 'locationId',
          displayKey: 'name',
          service: CorePortalFeatureMasterDataLocationService,
          multiple: true,
          separator: ', '
        },
        {
          key: 'locationGroups',
          type: DatatableTableColumnType.REFERENCE,
          idKey: 'locationGroupId',
          displayKey: 'name',
          service: CorePortalFeatureMasterDataLocationGroupService,
          multiple: true,
          separator: ', '
        },
        {
          key: 'functions',
          type: DatatableTableColumnType.REFERENCE,
          idKey: 'functionId',
          displayKey: 'name',
          service: CorePortalFunctionService,
          multiple: true,
          separator: ', '
        },
        {
          key: 'roles',
          type: DatatableTableColumnType.REFERENCE,
          idKey: 'appRoleId',
          displayKey: 'title',
          filterProp: 'roles.id',
          service: CorePortalRoleService,
          multiple: true,
          separator: ', '
        },
      ]
    },
    MasterDataContactOpenEditorRequestDefault: {
      excludedColumns: [
        'mission.missionId',
        'mission.type',
        'mission.title',
        'mission.tenantId'
      ],
      defaultColumns: [
        'missionId',
        'mission',
        'createdAt'
      ],
      columnTypings: [
        {
          key: 'missionId',
          type: DatatableTableColumnType.NUMBER,
          name: 'mission-id'
        },
        {
          key: 'createdAt',
          type: DatatableTableColumnType.DATE,
          utc: true
        },
        {
          key: 'mission',
          type: DatatableTableColumnType.REFERENCE,
          idKey: 'missionId',
          displayKey: 'title',
          service: TechPortalMissionService,
          link: (mission: MissionDto) => mission?.missionId ? `/missions/${mission.missionId}` : null,
          module: 'inventory',
          permissions: [AppPermissions.ReadMission],
          linkPermissions: [AppPermissions.ReadMission]
        }
      ]
    },
    MasterDataFunctionDefault: {
      excludedColumns: [
        'customPropertyValues',
        'isInRelation',
        'tenantId',
        'stereotypeId',
        'stereotypeRowVersion',
        'rowVersion',
        'isArchived',
        'stereotype.tenantId',
        'stereotype.rowVersion',
        'stereotype.position',
        'stereotype.isArchived',
        'stereotype.externalId',
        'stereotype.stereotypeId',
        'phone', // ToDo: Use in the future
        'email' // ToDo: Use in the future
      ],
      defaultColumns: [
        'functionId',
        'name',
        'stereotype'
      ],
      columnTypings: []
    },
    MasterDataLocationDefault: {
      excludedColumns: [
        'tenantId',
        'rowVersion',
        'geoLatitude',
        'geoLongitude',
        'stereotypeRowVersion',
        'customPropertyValues',
        'stereotypeId',
        'isInRelation',
        'stereotype.tenantId',
        'stereotype.rowVersion',
        'stereotype.position',
        'stereotype.isArchived',
        'stereotype.externalId',
        'stereotype.stereotypeId',

        'invoiceAddress.tenantId',
        'invoiceAddress.rowVersion',
        'invoiceAddress.stereotypeRowVersion',
        'invoiceAddress.customPropertyValues',
        'invoiceAddress.addressId',
        'invoiceAddress.stereotypeId',
        'invoiceAddress.stereotype.stereotypeId',
        'invoiceAddress.stereotype.name',
        'invoiceAddress.stereotype.tenantId',
        'invoiceAddress.stereotype.rowVersion',
        'invoiceAddress.stereotype.isArchived',

        'shippingAddress.tenantId',
        'shippingAddress.rowVersion',
        'shippingAddress.stereotypeRowVersion',
        'shippingAddress.customPropertyValues',
        'shippingAddress.addressId',
        'shippingAddress.stereotypeId',
        'shippingAddress.stereotype.stereotypeId',
        'shippingAddress.stereotype.name',
        'shippingAddress.stereotype.tenantId',
        'shippingAddress.stereotype.rowVersion',
        'shippingAddress.stereotype.isArchived',
      ],
      defaultColumns: [
        'locationId',
        'name',
        'stereotype',
        'state'
      ],
      columnTypings: [
        {
          key: 'externalId',
          type: DatatableTableColumnType.TEXT
        },
        {
          key: 'phoneNumber',
          type: DatatableTableColumnType.PHONE,
          name: 'phone'
        },
        {
          key: 'state',
          type: DatatableTableColumnType.ENUM,
          name: 'current-state',
          enumOptions: locationStateEnumOptions
        }
      ]
    },
    MasterDataLocationGroupDefault: {
      excludedColumns: [
        'tenantId',
        'type',
        'isSystem',
        'rowVersion',
        'isInRelation',
        'stereotypeId',
        'stereotypeRowVersion',
        'customPropertyValues',
        'stereotype.tenantId',
        'stereotype.rowVersion',
        'stereotype.position',
        'stereotype.isArchived',
        'stereotype.externalId',
        'stereotype.stereotypeId',
      ],
      defaultColumns: [
        'locationGroupId',
        'name',
        'stereotype'
      ],
      columnTypings: [
        {
          key: 'externalId',
          type: DatatableTableColumnType.TEXT
        }
      ]
    },
    MasterDataCompanyDefault: {
      excludedColumns: [
        'tenantId',
        'parentCompanyId',
        'rowVersion',
        'stereotypeId',
        'customPropertyValues',
        'stereotypeRowVersion',
        'isInRelation',
        'stereotype.tenantId',
        'stereotype.rowVersion',
        'stereotype.position',
        'stereotype.isArchived',
        'stereotype.externalId',
        'stereotype.stereotypeId'
      ],
      defaultColumns: [
        'companyId',
        'name',
        'stereotype'
      ],
      columnTypings: []
    },
    MasterDataAddressDefault: {
      excludedColumns: [
        'tenantId',
        'rowVersion',
        'stereotypeId',
        'customPropertyValues',
        'stereotypeRowVersion',
        'isInRelation',
        'stereotype.tenantId',
        'stereotype.rowVersion',
        'stereotype.position',
        'stereotype.isArchived',
        'stereotype.externalId',
        'stereotype.stereotypeId',
        'point.lat',
        'point.lng',
        'point',
      ],
      defaultColumns: [
        'addressId',
        'street',
        'houseNumber',
        'city',
        'state',
        'zipcode',
        'countryCode',
        'stereotype'
      ],
      columnTypings: []
    },
    MasterDataHolidaysDefault: {
      excludedColumns: [
        'tenantId'
      ],
      defaultColumns: [
        'holidaySetId',
        'name'
      ],
      columnTypings: []
    },
    MasterDataAreaAssignmentDefault: {
      excludedColumns: [
        'tenantId',
        'contact.contactId',
        'contact.externalId',
      ],
      defaultColumns: [
        'areaAssignmentId',
        'locations',
        'locationGroups',
        'roles',
        'functions',
        'reach'
      ],
      columnTypings: [
        {
          key: 'locations',
          type: DatatableTableColumnType.REFERENCE,
          idKey: 'locationId',
          displayKey: 'name',
          service: CorePortalFeatureMasterDataLocationService,
          mapValues: (locations: LocationRelationListDto[]) => locations.map(x => x.location),
          filterProp: 'locations',
          multiple: true,
          separator: ', '
        },
        {
          key: 'locationGroups',
          type: DatatableTableColumnType.REFERENCE,
          idKey: 'locationGroupId',
          displayKey: 'name',
          service: CorePortalFeatureMasterDataLocationGroupService,
          mapValues: (locationGroups: LocationGroupRelationListDto[]) => locationGroups.map(x => x.locationGroup),
          filterProp: 'locationGroups',
          multiple: true,
          separator: ', '
        },
        {
          key: 'roles',
          type: DatatableTableColumnType.REFERENCE,
          idKey: 'appRoleId',
          displayKey: 'title',
          service: CorePortalRoleService,
          mapValues: (roles: RoleInAreaListDto[]) => roles.map(x => ({
            ...x.role,
            appRoleId: x.roleId,
            title: x.role.name
          })),
          filterProp: 'roles',
          multiple: true,
          separator: ', '
        },
        {
          key: 'functions',
          type: DatatableTableColumnType.REFERENCE,
          idKey: 'functionId',
          displayKey: 'name',
          service: CorePortalFunctionService,
          mapValues: (functions: { function: FunctionSimpleDto }[]) => functions.map(x => x.function),
          filterProp: 'functions',
          multiple: true,
          separator: ', '
        },
        {
          key: 'reach',
          type: DatatableTableColumnType.ENUM,
          enumOptions: areaAssignmentReachEnumOptions,
          disableFilter: true
        }
      ]
    },

    // Location task jobs
    LocationTaskArchiveDefault: {
      ...taskArchiveDefault,
      defaultColumns: [
        'taskJobId',
        'executionDate',
        'currentStatus',
        'task',
        'currentJobStatus.createdByContactName',
        'currentJobStatus.reason.content',
        'attachment'
      ],
      columnTypings: [
        ...taskArchiveDefault.columnTypings.filter(typing => typing.key !== 'task'),
        {
          key: 'task',
          type: DatatableTableColumnType.REFERENCE,
          idKey: 'taskId',
          displayKey: 'title',
          service: OrgaPortalFeatureTaskService,
          link: (task: TaskDto) => task?.taskId ? `/tasks/${task.taskId}` : null,
          module: 'communication',
          permissions: [AppPermissions.ReadTask],
          linkPermissions: [AppPermissions.ReadTask],
          filters$: of([
            {
              property: 'parentId',
              type: FilterTypes.DataTransferObject,
              operator: FilterOperators.Equal,
              value: null
            },
            {
              type: FilterTypes.Grouped,
              combinedAs: CombineOperator.And,
              children: [
                {
                  property: 'state',
                  type: FilterTypes.DataTransferObject,
                  operator: FilterOperators.NotEqual,
                  value: '3'
                },
                {
                  property: 'state',
                  type: FilterTypes.DataTransferObject,
                  operator: FilterOperators.NotEqual,
                  value: '2'
                }
              ]
            }
          ] as Filter[]),
        },
        {
          key: 'currentJobStatus.createdByContactName',
          type: DatatableTableColumnType.TEXT,
          name: 'created-by-user-name'
        },
        {
          key: 'currentJobStatus.reason.content',
          type: DatatableTableColumnType.TEXT,
          name: 'reason'
        }
      ],
    },
    LocationTaskArchiveActive: {
      ...taskArchiveDefault,
      defaultColumns: [
        'taskJobId',
        'executionDate',
        'currentStatus',
        'task',
        'currentJobStatus.createdByContactName',
        'currentJobStatus.reason.content',
        'attachment'
      ],
      columnTypings: [
        ...taskArchiveDefault.columnTypings.filter(typing => typing.key !== 'task'),
        {
          key: 'task',
          type: DatatableTableColumnType.REFERENCE,
          idKey: 'taskId',
          displayKey: 'title',
          service: OrgaPortalFeatureTaskService,
          link: (task: TaskDto) => task?.taskId ? `/tasks/${task.taskId}` : null,
          module: 'communication',
          permissions: [AppPermissions.ReadTask],
          linkPermissions: [AppPermissions.ReadTask],
          filters$: of([
            {
              property: 'parentId',
              type: FilterTypes.DataTransferObject,
              operator: FilterOperators.Equal,
              value: null
            },
            {
              type: FilterTypes.Grouped,
              combinedAs: CombineOperator.And,
              children: [
                {
                  property: 'state',
                  type: FilterTypes.DataTransferObject,
                  operator: FilterOperators.NotEqual,
                  value: '3'
                },
                {
                  property: 'state',
                  type: FilterTypes.DataTransferObject,
                  operator: FilterOperators.NotEqual,
                  value: '2'
                }
              ]
            }
          ] as Filter[]),
        },
        {
          key: 'currentJobStatus.createdByContactName',
          type: DatatableTableColumnType.TEXT,
          name: 'created-by-user-name'
        },
        {
          key: 'currentJobStatus.reason.content',
          type: DatatableTableColumnType.TEXT,
          name: 'reason'
        }
      ]
    },

    /* Devices */
    DeviceDefault: {
      excludedColumns: [
        'location.locationNumber',
        'location.externalId'
      ],
      defaultColumns: [
        'id',
        'name',
        'location',
        'platform',
        'hardwareId'
      ],
      columnTypings: [
        {
          key: 'id',
          type: DatatableTableColumnType.NUMBER,
          name: 'device-id'
        },
        {
          key: 'location',
          type: DatatableTableColumnType.REFERENCE,
          idKey: 'locationId',
          displayKey: 'name',
          service: CorePortalFeatureMasterDataLocationService,
          link: (location: LocationDto) => location ? `/masterdata/locations/${location.locationId}` : null,
          module: 'management',
          permissions: [AppPermissions.ReadLocation],
          linkPermissions: [AppPermissions.ReadLocation]
        },
        {
          key: 'location.phonenumber',
          type: DatatableTableColumnType.PHONE
        },
        {
          key: 'platform',
          type: DatatableTableColumnType.ENUM,
          enumOptions: platformEnumOptions
        }
      ]
    },
    PingDefault: {
      excludedColumns: [],
      defaultColumns: [
        'pingId',
        'sendAt',
        'arrivedAt',
        'appVersion'
      ],
      columnTypings: [
        {
          key: 'sendAt',
          type: DatatableTableColumnType.DATE,
          utc: true
        },
        {
          key: 'arrivedAt',
          type: DatatableTableColumnType.DATE,
          utc: true
        }
      ]
    },
    MetadataDefault: {
      excludedColumns: [],
      defaultColumns: [
        'deviceMetadataId',
        'version',
        'lastModified',
        'createdAt'
      ],
      columnTypings: [
        {
          key: 'lastModified',
          type: DatatableTableColumnType.DATE,
          utc: true
        },
        {
          key: 'createdAt',
          type: DatatableTableColumnType.DATE,
          utc: true
        }
      ]
    }
  };
}

function provideCommunicationDatatableConfig(): CorePortalDatatableStandardConfig {
  const issueDefault: any = {
    excludedColumns: [
      'tenantId',
      'contactEmailAddress',
      'customPropertyValues',

      'stereotypeId',
      'stereotypeRowVersion',

      'location.locationId',
      'location.locationNumber',
      'location.externalId',
      'location.name',

      'issue',
      'issue.issueId',
      'issue.issueType',
      'issue.tenantId',
      'issue.stereotype',

      'issue.location.locationId',
      'issue.location.name',
      'issue.location.locationNumber',
      'issue.location.externalId',

      'issue.resource.resourceId',
      'issue.resource.name',
      'issue.resource.inventoryNumberCount',
      'issue.resource.inventoryNumber',
      'issue.resource.locationId',
      'issue.resource.parentId',
      'issue.resource.location.locationId',
      'issue.resource.location.name',
      'issue.resource.location.locationNumber',
      'issue.resource.location.externalId',
      'issue.resource.stereotype',
      'issue.resource.stereotype.stereotypeId',
      'issue.resource.stereotype.name',
      'issue.resource.stereotype.position',
      'issue.resource.stereotype.tenantId',
      'issue.resource.stereotype.rowVersion',
      'issue.resource.stereotype.isArchived',
      'issue.resource.stereotype.externalId',
      'issue.resource.externalId',
      'issue.resource.isArchived',
      'issue.resource.path',
      'issue.resource.location',

      'priority.priorityId',
      'priority.name',
      'priority.tenantId',
      'priority.isArchived',

      'stereotype.name',
      'stereotype.tenantId',
      'stereotype.rowVersion',
      'stereotype.position',
      'stereotype.isArchived',
      'stereotype.externalId',
      'stereotype.stereotypeId',

      'resource.resourceId',
      'resource.name',
      'resource.inventoryNumberCount',
      'resource.inventoryNumber',
      'resource.locationId',
      'resource.parentId',
      'resource.location',
      'resource.location.name',
      'resource.stereotype',
      'resource.externalId',
      'resource.isArchived',
      'resource.path',
      'resource.stereotype.name',
      'resource.stereotype.stereotypeId',
      'resource.stereotype.position',
      'resource.stereotype.tenantId',
      'resource.stereotype.rowVersion',
      'resource.stereotype.isArchived',
      'resource.stereotype.externalId',

      'control.controlId',
      'control.title',

      'reason',
      'reason.type',
      'reason.type',
      'reason.frequency',
      'reason.errorCode.errorCodeId',
      'reason.errorCode.externalId',
      'reason.errorCode.displayName',
      'reason.consumptionCode.consumptionCodeId',
      'reason.consumptionCode.externalId',
      'reason.consumptionCode.displayName',
      'reason.consumptionCode.unit',
      'reason.triggerValue',
      'reason.triggerType',
      'reason.lowerLimit',
      'reason.upperLimit',
      'reason.limit',
      'reason.errorCode',
      'reason.consumptionCode'
    ],
    defaultColumns: [
      'issueId',
      'issue.title',
      'title',
      'control'
    ],
    columnTypings: [
      {
        key: 'issueId',
        type: DatatableTableColumnType.NUMBER,
        name: 'id'
      },
      {
        key: 'issue.title',
        type: DatatableTableColumnType.TEXT,
        name: 'title'
      },
      {
        key: 'createdByName',
        type: DatatableTableColumnType.TEXT,
        name: 'createdByUserName'
      },
      {
        key: 'contactFirstName',
        type: DatatableTableColumnType.TEXT,
        name: 'firstName'
      },
      {
        key: 'contactLastName',
        type: DatatableTableColumnType.TEXT,
        name: 'lastName'
      },
      {
        key: 'issueType',
        type: DatatableTableColumnType.ENUM,
        enumOptions: issueContentTypeEnumOptions
      },
      {
        key: 'stereotype',
        type: DatatableTableColumnType.REFERENCE,
        idKey: 'stereotypeId',
        displayKey: 'name',
        name: 'stereotype',
        service: CorePortalStereotypeService,
        filters$: of([{
          property: 'entityType',
          type: FilterTypes.DataTransferObject,
          operator: FilterOperators.Equal,
          value: AppEntityType.Issue.toString()
        }] as Filter[]),
        link: (value: StereotypeSimpleDto) => value ? `/stereotypes/${value.stereotypeId}` : null,
        module: 'settings',
        permissions: [AppPermissions.ReadStereotype],
        linkPermissions: [AppPermissions.DisplayStereoTypes]
      },
      {
        key: 'control',
        type: DatatableTableColumnType.REFERENCE,
        idKey: 'controlId',
        displayKey: 'title',
        name: 'control',
        service: TechPortalFeatureControlService,
        link: (value: ControlSimpleDto) => value ? `/controls/${value.controlId}` : null,
        module: 'inventory',
        permissions: [AppPermissions.ReadControl],
        linkPermissions: [AppPermissions.DisplayControls]
      },
      {
        key: 'issue.createdByName',
        type: DatatableTableColumnType.TEXT,
        name: 'createdByUserName'
      },
      {
        key: 'resource',
        type: DatatableTableColumnType.REFERENCE,
        idKey: 'resourceId',
        name: 'resource',
        displayKey: 'name',
        service: CorePortalFeatureResourceService,
        link: (value: ResourceSimpleDto) => value ? `/resources/${value.resourceId}` : null,
        module: 'inventory',
        permissions: [AppPermissions.ReadResource],
        linkPermissions: [AppPermissions.ReadResource]
      },
      {
        key: 'location',
        type: DatatableTableColumnType.REFERENCE,
        idKey: 'locationId',
        displayKey: 'name',
        name: 'location',
        service: CorePortalFeatureMasterDataLocationService,
        link: (value: LocationSimpleDto) => value ? `/masterdata/locations/${value.locationId}` : null,
        module: 'management',
        permissions: [AppPermissions.ReadLocation],
        linkPermissions: [AppPermissions.ReadLocation]
      },
      {
        key: 'issue.resource',
        type: DatatableTableColumnType.REFERENCE,
        idKey: 'resourceId',
        name: 'resource',
        displayKey: 'name',
        service: CorePortalFeatureResourceService,
        link: (value: ResourceSimpleDto) => value ? `/resources/${value.resourceId}` : null,
        module: 'inventory',
        permissions: [AppPermissions.ReadResource],
        linkPermissions: [AppPermissions.ReadResource]
      },
      {
        key: 'issue.location',
        type: DatatableTableColumnType.REFERENCE,
        idKey: 'locationId',
        displayKey: 'name',
        name: 'location',
        service: CorePortalFeatureMasterDataLocationService,
        link: (value: LocationSimpleDto) => value ? `/masterdata/locations/${value.locationId}` : null,
        module: 'management',
        permissions: [AppPermissions.ReadLocation],
        linkPermissions: [AppPermissions.ReadLocation]
      },
      {
        key: 'issue.createdAt',
        type: DatatableTableColumnType.DATE,
        name: 'createdAt',
        utc: true
      },
      {
        key: 'issue.lastModified',
        type: DatatableTableColumnType.DATE,
        name: 'lastModified',
        utc: true
      }
    ]
  }

  const infoMembershipDefault: any = {
    excludedColumns: [
      'location.externalId', 'contact.contactId', 'contact.externalId', 'location.locationNumber',
    ],
    defaultColumns: [
      'contact',
      'createdAt'
    ],
    columnTypings: [
      {
        key: 'contact',
        type: DatatableTableColumnType.REFERENCE,
        idKey: 'contactId',
        displayKey: 'displayName',
        service: CorePortalContactService,
        link: (contact: ContactSimpleDto) => contact?.contactId ?
          `/masterdata/contacts/${contact.contactId}` : null,
        module: 'management',
        permissions: [AppPermissions.ReadContact],
        linkPermissions: [AppPermissions.ReadContact],
        alternate: {
          idKey: 'functionId',
          displayKey: 'name',
          service: CorePortalFunctionService
        }
      },
      {
        key: 'createdAt',
        type: DatatableTableColumnType.DATE,
        utc: true
      },
      {
        key: 'location.phonenumber',
        type: DatatableTableColumnType.PHONE
      },
    ]
  };

  return {
    /* Tickets */
    TicketDefault: {
      excludedColumns: [
        'customPropertyValues',
        'stereotypeId',
        'tenantId',
        'stereotypeRowVersion',
        'rowVersion',
        'isArchived',
        'currentStateId',
        'escalationLevelId',
        'resourceId',

        'editor.stereotypeId',
        'editor.stereotypeRowVersion',
        'editor.customPropertyValues',
        'editor.position',
        'editor.contactId',
        'editor.user',
        'editor.user.id',
        'editor.stereotype.stereotypeId',
        'editor.stereotype.name',
        'editor.stereotype.isArchived',
        'editor.stereotype.tenantId',
        'editor.stereotype.rowVersion',
        'editor.stereotype.position',
        'editor.stereotype.externalId',

        'resource.stereotype.externalId',
        'resource.resourceId',
        'resource.name',
        'resource.path',
        'resource.pathJson',
        'resource.tenantId',
        'resource.rowVersion',
        'resource.stereotypeId',
        'resource.location.locationId',
        'resource.location.name',
        'resource.location.tenantId',
        'resource.location.phoneNumber',
        'resource.location.externalId',
        'resource.location.rowVersion',
        'resource.location.stereotypeId',
        'resource.location.state',
        'resource.location.customPropertyValues',
        'resource.location.stereotypeRowVersion',
        'resource.location.stereotype.rowVersion',
        'resource.location.stereotype.stereotypeId',
        'resource.location.stereotype.tenantId',
        'resource.location.stereotype.name',
        'resource.location.stereotype.isArchived',
        'resource.location.stereotype.position',
        'resource.location.invoiceAddress.tenantId',
        'resource.location.invoiceAddress.rowVersion',
        'resource.location.invoiceAddress.stereotypeRowVersion',
        'resource.location.invoiceAddress.customPropertyValues',
        'resource.location.invoiceAddress.addressId',
        'resource.location.invoiceAddress.stereotypeId',
        'resource.location.invoiceAddress.stereotype.stereotypeId',
        'resource.location.invoiceAddress.stereotype.name',
        'resource.location.invoiceAddress.stereotype.tenantId',
        'resource.location.invoiceAddress.stereotype.rowVersion',
        'resource.location.invoiceAddress.stereotype.isArchived',
        'resource.location.shippingAddress.tenantId',
        'resource.location.shippingAddress.rowVersion',
        'resource.location.shippingAddress.stereotypeRowVersion',
        'resource.location.shippingAddress.customPropertyValues',
        'resource.location.shippingAddress.addressId',
        'resource.location.shippingAddress.stereotypeId',
        'resource.location.shippingAddress.stereotype.stereotypeId',
        'resource.location.shippingAddress.stereotype.name',
        'resource.location.shippingAddress.stereotype.tenantId',
        'resource.location.shippingAddress.stereotype.rowVersion',
        'resource.location.shippingAddress.stereotype.isArchived',
        'resource.location.stereotype.externalId',
        'resource.stereotypeRowVersion',
        'resource.customPropertyValues',
        'resource.stereotype.tenantId',
        'resource.stereotype.rowVersion',
        'resource.stereotype.stereotypeId',
        'resource.stereotype.name',
        'resource.stereotype.isArchived',
        'resource.stereotype.position',

        'stereotype.tenantId',
        'stereotype.rowVersion',
        'stereotype.position',
        'stereotype.isArchived',
        'stereotype.externalId',
        'stereotype.stereotypeId',
        'stereotype.name',

        'currentState.tenantId',
        'currentState.rowVersion',
        'currentState.stereotypeId',
        'currentState.stereotypeRowVersion',
        'currentState.stateId',
        'currentState.name',
        'currentState.color',

        'priority.tenantId',
        'priority.priorityId',
        'priority.name',
        'priority.isArchived',

        'escalationLevel.tenantId',
        'escalationLevel.escalationLevelId',
        'escalationLevel.name',
        'currentState.externalId',

        'location.tenantId',
        'location.state',
        'location.phoneNumber',
        'location.locationNumber',
        'location.externalId',
        'location.rowVersion',
        'location.stereotypeId',
        'location.stereotypeRowVersion',
        'location.stereotype.stereotypeId',
        'location.stereotype.position',
        'location.stereotype.tenantId',
        'location.stereotype.rowVersion',
        'location.stereotype.isArchived',
        'location.stereotype.externalId',
        'location.customPropertyValues',

        'location.shippingAddress.addressId',
        'location.shippingAddress.street',
        'location.shippingAddress.houseNumber',
        'location.shippingAddress.city',
        'location.shippingAddress.state',
        'location.shippingAddress.zipcode',
        'location.shippingAddress.countryCode',

        'location.invoiceAddress.addressId',
        'location.invoiceAddress.street',
        'location.invoiceAddress.houseNumber',
        'location.invoiceAddress.city',
        'location.invoiceAddress.state',
        'location.invoiceAddress.zipcode',
        'location.invoiceAddress.countryCode',
      ],
      defaultColumns: [
        'ticketId',
        'title',
        'escalationLevel',
        'currentState',
        'editor',
        'stereotype',
        'priority',
        'resource',
        'labelRelations'
      ],
      columnTypings: [
        {
          key: 'description',
          type: DatatableTableColumnType.TEXT,
          maxHeight: 300
        },
        {
          key: 'createdAt',
          type: DatatableTableColumnType.DATE,
          utc: true
        },
        {
          key: 'lastModified',
          type: DatatableTableColumnType.DATE,
          utc: true
        },
        {
          key: 'locationNumber',
          type: DatatableTableColumnType.TEXT,
          fixedWidth: 160
        },

        // Escalation level
        {
          key: 'escalationLevel',
          type: DatatableTableColumnType.REFERENCE,
          idKey: 'escalationLevelId',
          displayKey: 'name',
          service: TechPortalFeatureTicketSettingsEscalationLevelService,
          link: (escalationLevel: EscalationLevelSimpleDto) => escalationLevel ?
            `/escalation-levels/${escalationLevel.escalationLevelId}` : null,
          module: 'settings',
          permissions: [AppPermissions.ReadEscalationLevel],
          linkPermissions: [AppPermissions.ReadEscalationLevel]
        },

        // Current state
        {
          key: 'currentState',
          type: DatatableTableColumnType.REFERENCE,
          idKey: 'stateId',
          displayKey: 'name',
          service: TechPortalFeatureTicketSettingsStateService,
          prependIcon: {
            color: (value: StateDto) => value?.color,
            icon: faCircle,
            size: 'xs'
          },
          permissions: [AppPermissions.ReadState]
        },
        {
          key: 'currentState.color',
          type: DatatableTableColumnType.TEXT,
          prependIcon: {
            color: (value: StateDto) => value?.color,
            icon: faCircle,
            size: 'xs'
          }
        },

        // Priority
        {
          key: 'priority',
          type: DatatableTableColumnType.REFERENCE,
          idKey: 'priorityId',
          displayKey: 'name',
          service: TechPortalFeatureTicketSettingsPriorityService,
          permissions: [AppPermissions.ReadPriority]
        },
        {
          key: 'priority.isArchived',
          type: DatatableTableColumnType.BOOLEAN
        },

        // Resource
        {
          key: 'resource',
          type: DatatableTableColumnType.REFERENCE,
          idKey: 'resourceId',
          displayKey: 'name',
          service: CorePortalFeatureResourceService,
          link: (resource: ResourceInTicketDto) => resource?.resourceId ? `/resources/${resource.resourceId}` : null,
          module: 'inventory',
          fragment: 'edit',
          permissions: [AppPermissions.ReadResource],
          linkPermissions: [AppPermissions.ReadResource]
        },
        {
          key: 'resource.location',
          type: DatatableTableColumnType.REFERENCE,
          idKey: 'locationId',
          displayKey: 'name',
          service: CorePortalFeatureMasterDataLocationService,
          link: (location: LocationDto) => location ? `/masterdata/locations/${location.locationId}` : null,
          module: 'management',
          permissions: [AppPermissions.ReadResource, AppPermissions.ReadLocation],
          linkPermissions: [AppPermissions.ReadLocation],
          alternate: {
            idKey: 'locationGroupId',
            displayKey: 'name',
            service: CorePortalFeatureMasterDataLocationGroupService
          }
        },
        {
          key: 'resource.isArchived',
          type: DatatableTableColumnType.BOOLEAN
        },
        {
          key: 'location.invoiceAddress',
          type: DatatableTableColumnType.REFERENCE,
          idKey: 'addressId',
          name: 'invoice-address',
          displayKey: 'street',
          additionalSearchProperties: ['houseNumber', 'zipcode', 'city'],
          template: (address: AddressSimpleDto) => {
            if (address?.street) {
              let firstPart = address.street;
              let secondPart = address.city;

              if (address.houseNumber) {
                firstPart = `${firstPart} ${address.houseNumber}`;
              }

              if (address.zipcode) {
                secondPart = `${address.zipcode} ${secondPart}`;
              }

              return `${firstPart}${secondPart ? `, ${secondPart}` : ''}`;
            }
          },
          service: CorePortalFeatureMasterDataAddressService,
          link: (value: AddressSimpleDto) => value ? `/masterdata/addresses/${value.addressId}` : null,
          module: 'management',
          permissions: [AppPermissions.ReadAddress],
          linkPermissions: [AppPermissions.DisplayAddresses]
        },
        {
          key: 'location.shippingAddress',
          name: 'shipping-address',
          type: DatatableTableColumnType.REFERENCE,
          idKey: 'addressId',
          displayKey: 'street',
          additionalSearchProperties: ['houseNumber', 'zipcode', 'city'],
          template: (address: AddressSimpleDto) => {
            if (address?.street) {
              let firstPart = address.street;
              let secondPart = address.city;

              if (address.houseNumber) {
                firstPart = `${firstPart} ${address.houseNumber}`;
              }

              if (address.zipcode) {
                secondPart = `${address.zipcode} ${secondPart}`;
              }

              return `${firstPart}${secondPart ? `, ${secondPart}` : ''}`;
            }
          },
          service: CorePortalFeatureMasterDataAddressService,
          link: (value: AddressSimpleDto) => value ? `/masterdata/addresses/${value.addressId}` : null,
          module: 'management',
          permissions: [AppPermissions.ReadAddress],
          linkPermissions: [AppPermissions.DisplayAddresses]
        },
        {
          key: 'resource.location.invoiceAddress',
          type: DatatableTableColumnType.REFERENCE,
          idKey: 'addressId',
          displayKey: 'street',
          additionalSearchProperties: ['houseNumber', 'zipcode', 'city'],
          template: (address: AddressSimpleDto) => {
            if (address?.street) {
              let firstPart = address.street;
              let secondPart = address.city;

              if (address.houseNumber) {
                firstPart = `${firstPart} ${address.houseNumber}`;
              }

              if (address.zipcode) {
                secondPart = `${address.zipcode} ${secondPart}`;
              }

              return `${firstPart}${secondPart ? `, ${secondPart}` : ''}`;
            }
          },
          service: CorePortalFeatureMasterDataAddressService,
          link: (value: AddressSimpleDto) => value ? `/masterdata/addresses/${value.addressId}` : null,
          module: 'management',
          permissions: [AppPermissions.ReadAddress],
          linkPermissions: [AppPermissions.DisplayAddresses]
        },
        {
          key: 'resource.location.shippingAddress',
          type: DatatableTableColumnType.REFERENCE,
          idKey: 'addressId',
          displayKey: 'street',
          additionalSearchProperties: ['houseNumber', 'zipcode', 'city'],
          template: (address: AddressSimpleDto) => {
            if (address?.street) {
              let firstPart = address.street;
              let secondPart = address.city;

              if (address.houseNumber) {
                firstPart = `${firstPart} ${address.houseNumber}`;
              }

              if (address.zipcode) {
                secondPart = `${address.zipcode} ${secondPart}`;
              }

              return `${firstPart}${secondPart ? `, ${secondPart}` : ''}`;
            }
          },
          service: CorePortalFeatureMasterDataAddressService,
          link: (value: AddressSimpleDto) => value ? `/masterdata/addresses/${value.addressId}` : null,
          module: 'management',
          permissions: [AppPermissions.ReadAddress],
          linkPermissions: [AppPermissions.DisplayAddresses]
        },
        {
          key: 'location.phonenumber',
          type: DatatableTableColumnType.PHONE
        },

        // Editor
        {
          key: 'editor',
          type: DatatableTableColumnType.REFERENCE,
          idKey: 'contactId',
          displayKey: 'displayName',
          service: CorePortalContactService,
          link: (editor: ContactSimpleDto) => editor ? `/masterdata/contacts/${editor.contactId}` : null,
          module: 'management',
          permissions: [AppPermissions.ReadContact],
          linkPermissions: [AppPermissions.ReadContact],
          alternate: {
            idKey: 'functionId',
            displayKey: 'name',
            service: CorePortalFunctionService
          }
        },
        {
          key: 'editor.mobile',
          type: DatatableTableColumnType.PHONE
        },
        {
          key: 'editor.phone',
          type: DatatableTableColumnType.PHONE
        },
        {
          key: 'labelRelations',
          displayKey: 'label.content',
          type: DatatableTableColumnType.ARRAY,
          disableSorting: true
        },
        {
          key: 'resource.type',
          name: 'resource-type',
          type: DatatableTableColumnType.ENUM,
          enumOptions: resourceTypeEnumOptions
        },
        {
          key: 'resource.currentState',
          name: 'resource-state',
          type: DatatableTableColumnType.ENUM,
          enumOptions: resourceStateColoredEnumOptions
        },
        {
          key: 'location',
          type: DatatableTableColumnType.REFERENCE,
          idKey: 'locationId',
          displayKey: 'name',
          service: CorePortalFeatureMasterDataLocationService,
          link: (value: LocationSimpleDto) => value ? `/masterdata/locations/${value.locationId}` : null,
          module: 'management',
          permissions: [AppPermissions.ReadLocation],
          linkPermissions: [AppPermissions.ReadLocation],
          alternate: {
            idKey: 'locationGroupId',
            displayKey: 'name',
            service: CorePortalFeatureMasterDataLocationGroupService
          }
        }
      ]
    },

    /* Infos */
    InfoDefault: {
      excludedColumns: [
        'attachments',
        'answerEnabled',
        'tenantId',
        'sender',
        'sender.tenantId',
        'sender.rowVersion',
        'sender.stereotypeRowVersion',
        'sender.stereotypeId',
        'sender.isArchived',
        'sender.name',
        'sender.functionId',
        'sender.externalId',
        'content'
      ],
      defaultColumns: [
        'infoId',
        'title',
        'categoryRelations',
        'state',
        'dateTime',
        'requestRead'
      ],
      columnTypings: [
        {
          key: 'dateTime',
          type: DatatableTableColumnType.DATE,
          utc: true
        },
        {
          key: 'requestRead',
          type: DatatableTableColumnType.BOOLEAN,
          name: 'read-status-required'
        },
        {
          key: 'isRead',
          type: DatatableTableColumnType.BOOLEAN,
          name: 'is-read'
        },
        {
          key: 'isActive',
          type: DatatableTableColumnType.BOOLEAN
        },
        {
          key: 'isExpired',
          type: DatatableTableColumnType.BOOLEAN
        },
        {
          key: 'expiryDate',
          type: DatatableTableColumnType.DATE,
          utc: true,
          name: 'expires-in'
        },
        {
          key: 'readAt',
          type: DatatableTableColumnType.DATE,
          utc: true,
          name: 'read-when'
        },
        {
          key: 'contentAsText',
          type: DatatableTableColumnType.TEXT,
          name: 'content'
        },
        {
          key: 'state',
          type: DatatableTableColumnType.ENUM,
          name: 'current-state',
          enumOptions: infoStateEnumOptions,
          prependIcon: {
            color: (value: InfoDto) => {
              switch (value?.state) {
                case infoStateEnumOptions[0].value:
                  return 'gray';
                case infoStateEnumOptions[1].value:
                  return 'green';
                case infoStateEnumOptions[2].value:
                  return 'orange';
                default:
                  return null;
              }
            },
            icon: faCircle,
            size: 'xs'
          }
        },

        // Categories
        {
          key: 'categoryRelations',
          name: 'categories',
          type: DatatableTableColumnType.REFERENCE,
          idKey: 'categoryId',
          displayKey: 'name',
          multiple: true,
          mapValues: (values: CategorizedRelationDto[]) => values.map(x => x.category),
          service: OrgaPortalFeatureCategoryService,
          filterProp: 'categoryRelations',
          prependIcon: {
            color: (value: CategoryDto) => value?.color,
            icon: faCircle,
            size: 'xs'
          },
          labelTitleTemplateKey: 'categoryLabelTitleTemplate',
          optionTitleTemplateKey: 'categoryOptionTitleTemplate',
          permissions: [AppPermissions.ReadCategory]
        }
      ]
    },

    // InfoProgress
    InfoProgressFunctionsDefault: {
      excludedColumns: [
        'name',
        'function.tenantId',
        'function.functionId',
        'function.name',
        'function.externalId',
        'function.stereotypeId',
        'function.isArchived',
        'function.rowVersion',
        'function.stereotypeRowVersion',
        'expectedReaderCount'
      ],
      defaultColumns: [
        'functionId',
        'function',
        'currentReaderCount'
      ],
      columnTypings: [
        {
          key: 'function',
          type: DatatableTableColumnType.REFERENCE,
          idKey: 'functionId',
          name: 'name',
          displayKey: 'name',
          service: CorePortalFunctionService,
          link: (f: FunctionSimpleDto) => f?.functionId ?
            `/masterdata/functions/${f?.functionId}` : null,
          module: 'management',
          clickable: true
        },
        {
          key: 'functionId',
          type: DatatableTableColumnType.NUMBER,
          name: 'id',
          clickable: true
        },
        {
          key: 'currentReaderCount',
          type: DatatableTableColumnType.PROGRESSBAR,
          totalKey: 'expectedReaderCount',
          clickable: true
        }
      ]
    },
    InfoProgressLocationsDefault: {
      excludedColumns: [
        'name',
        'location.locationId',
        'location.name',
        'location.locationNumber',
        'location.externalId'
      ],
      defaultColumns: [
        'locationId',
        'location',
        'currentReaderCount'
      ],
      columnTypings: [
        {
          key: 'location',
          type: DatatableTableColumnType.REFERENCE,
          idKey: 'locationId',
          name: 'name',
          displayKey: 'name',
          service: CorePortalLocationService,
          link: (location) => location?.locationId ?
            `/masterdata/locations/${location?.locationId}` : null,
          module: 'management',
          clickable: true
        },
        {
          key: 'locationId',
          type: DatatableTableColumnType.NUMBER,
          name: 'id',
          clickable: true
        },
        {
          key: 'currentReaderCount',
          type: DatatableTableColumnType.PROGRESSBAR,
          totalKey: 'expectedReaderCount',
          clickable: true
        }
      ]
    },
    InfoProgressContactsDefault: {
      excludedColumns: [
        'name',
        'contact.contactId',
        'contact.displayName',
        'contact.externalId',
        'readLocation.locationId',
        'readLocation.locationNumber',
        'readLocation.externalId',
        'readLocation.name',
      ],
      defaultColumns: [
        'contactId',
        'contact',
        'isExpected',
        'readWhen',
        'readLocation',
        'functions',
        'locations',
      ],
      columnTypings: [
        {
          key: 'contact',
          type: DatatableTableColumnType.REFERENCE,
          idKey: 'contactId',
          name: 'name',
          displayKey: 'displayName',
          service: CorePortalContactService,
          link: (contact) => contact?.contactId ?
            `/masterdata/contacts/${contact?.contactId}` : null,
          module: 'management'
        },
        {
          key: 'contactId',
          type: DatatableTableColumnType.NUMBER,
          name: 'id'
        },
        {
          key: 'isExpected',
          type: DatatableTableColumnType.BOOLEAN,
          name: 'expected'
        },
        {
          key: 'readWhen',
          type: DatatableTableColumnType.DATE,
          name: 'read-at',
          utc: true
        },
        {
          key: 'locations',
          displayKey: 'name',
          type: DatatableTableColumnType.ARRAY,
          disableSorting: true
        },
        {
          key: 'functions',
          displayKey: 'name',
          type: DatatableTableColumnType.ARRAY,
          disableSorting: true
        },
        {
          key: 'readLocation',
          type: DatatableTableColumnType.REFERENCE,
          idKey: 'locationId',
          displayKey: 'name',
          service: CorePortalFeatureMasterDataLocationService,
          link: (value: LocationSimpleDto) => value ? `/masterdata/locations/${value.locationId}` : null,
          module: 'management',
          permissions: [AppPermissions.ReadLocation],
          linkPermissions: [AppPermissions.ReadLocation]
        }
      ]
    },

    // KnowledgePathsProgress
    KnowledgePathsProgressFunctionsDefault: {
      excludedColumns: [
        'name',
        'function.tenantId',
        'function.functionId',
        'function.name',
        'function.externalId',
        'function.stereotypeId',
        'function.isArchived',
        'function.rowVersion',
        'function.stereotypeRowVersion',
        'expectedReaderCount'
      ],
      defaultColumns: [
        'functionId',
        'function',
        'currentReaderCount'
      ],
      columnTypings: [
        {
          key: 'function',
          type: DatatableTableColumnType.REFERENCE,
          idKey: 'functionId',
          name: 'name',
          displayKey: 'name',
          service: CorePortalFunctionService,
          link: (f: FunctionSimpleDto) => f?.functionId ?
            `/masterdata/functions/${f?.functionId}` : null,
          module: 'management',
          clickable: true
        },
        {
          key: 'functionId',
          type: DatatableTableColumnType.NUMBER,
          name: 'id',
          clickable: true
        },
        {
          key: 'currentReaderCount',
          type: DatatableTableColumnType.PROGRESSBAR,
          totalKey: 'expectedReaderCount',
          clickable: true
        }
      ]
    },
    KnowledgePathsProgressLocationsDefault: {
      excludedColumns: [
        'name',
        'location.locationId',
        'location.name',
        'location.locationNumber',
        'location.externalId'
      ],
      defaultColumns: [
        'locationId',
        'location',
        'currentReaderCount'
      ],
      columnTypings: [
        {
          key: 'location',
          type: DatatableTableColumnType.REFERENCE,
          idKey: 'locationId',
          name: 'name',
          displayKey: 'name',
          service: CorePortalLocationService,
          link: (location) => location?.locationId ?
            `/masterdata/locations/${location?.locationId}` : null,
          module: 'management',
          clickable: true
        },
        {
          key: 'locationId',
          type: DatatableTableColumnType.NUMBER,
          name: 'id',
          clickable: true
        },
        {
          key: 'currentReaderCount',
          type: DatatableTableColumnType.PROGRESSBAR,
          totalKey: 'expectedReaderCount',
          clickable: true
        }
      ]
    },
    KnowledgePathsProgressContactsDefault: {
      excludedColumns: [
        'name',
        'contact.contactId',
        'contact.displayName',
        'contact.externalId',
        'readLocation.locationId',
        'readLocation.locationNumber',
        'readLocation.externalId',
        'readLocation.name',
      ],
      defaultColumns: [
        'contactId',
        'contact',
        'isExpected',
        'readWhen',
        'readLocation',
        'functions',
        'locations',
      ],
      columnTypings: [
        {
          key: 'contact',
          type: DatatableTableColumnType.REFERENCE,
          idKey: 'contactId',
          name: 'name',
          displayKey: 'displayName',
          service: CorePortalContactService,
          link: (contact) => contact?.contactId ?
            `/masterdata/contacts/${contact?.contactId}` : null,
          module: 'management'
        },
        {
          key: 'contactId',
          type: DatatableTableColumnType.NUMBER,
          name: 'id'
        },
        {
          key: 'isExpected',
          type: DatatableTableColumnType.BOOLEAN,
          name: 'expected'
        },
        {
          key: 'readWhen',
          type: DatatableTableColumnType.DATE,
          name: 'read-at',
          utc: true
        },
        {
          key: 'locations',
          displayKey: 'name',
          type: DatatableTableColumnType.ARRAY,
          disableSorting: true
        },
        {
          key: 'functions',
          displayKey: 'name',
          type: DatatableTableColumnType.ARRAY,
          disableSorting: true
        },
        {
          key: 'readLocation',
          type: DatatableTableColumnType.REFERENCE,
          idKey: 'locationId',
          displayKey: 'name',
          service: CorePortalFeatureMasterDataLocationService,
          link: (value: LocationSimpleDto) => value ? `/masterdata/locations/${value.locationId}` : null,
          module: 'management',
          permissions: [AppPermissions.ReadLocation],
          linkPermissions: [AppPermissions.ReadLocation]
        }
      ]
    },

    /* Knowledge Article */
    KnowledgeArticleDefault: {
      excludedColumns: [
        'tenantId',
        'oldKnowledgePathId'
      ],
      defaultColumns: [
        'knowledgeArticleId',
        'title',
        'tags'
      ],
      columnTypings: []
    },

    /* Knowledge Path */
    KnowledgePathDefault: {
      excludedColumns: [
        'tenantId',
        'locations',
        'locationGroups'
      ],
      defaultColumns: [
        'knowledgePathId',
        'title',
        'categoryRelations'
      ],
      columnTypings: [
        // Categories
        {
          key: 'categoryRelations',
          name: 'categories',
          type: DatatableTableColumnType.REFERENCE,
          idKey: 'categoryId',
          displayKey: 'name',
          multiple: true,
          mapValues: (values: CategorizedRelationDto[]) => values.map(x => x.category),
          service: OrgaPortalFeatureCategoryService,
          filterProp: 'categoryRelations',
          prependIcon: {
            color: (value: CategoryDto) => value?.color,
            icon: faCircle,
            size: 'xs'
          },
          labelTitleTemplateKey: 'categoryLabelTitleTemplate',
          optionTitleTemplateKey: 'categoryOptionTitleTemplate',
          permissions: [AppPermissions.ReadCategory]
        }
      ]
    },

    /* Knowledge Exam */
    KnowledgeExamDefault: {
      excludedColumns: [
        'tenantId'
      ],
      defaultColumns: [
        'knowledgeExamId',
        'title'
      ],
      columnTypings: [
        // {
        //   key: 'knowledgeExamId',
        //   type: DatatableTableColumnType.REFERENCE,
        //   idKey: 'knowledgeExamId',
        //   name: 'id',
        //   displayKey: 'id',
        //   service: CorePortalContactService,
        //   link: (contact) => contact?.contactId ?
        //     `/masterdata/contacts/${contact?.contactId}` : null,
        //   module: 'management'
        // },
        {
          key: 'knowledgeExamId',
          type: DatatableTableColumnType.TEXT,
          name: 'id'
        },
      ]
    },

    /* Issues */
    IssueDefault: issueDefault,

    MessageIssueDefault: {
      excludedColumns: [
        ...issueDefault.excludedColumns,
        'issueType'
      ],
      defaultColumns: [
        ...issueDefault.defaultColumns,
      ],
      columnTypings: [
        ...issueDefault.columnTypings
      ]
    },

    MessageControlIssueDefault: {
      excludedColumns: [
        ...issueDefault.excludedColumns,
        'issueType'
      ],
      defaultColumns: [
        ...issueDefault.defaultColumns,
      ],
      columnTypings: [
        ...issueDefault.columnTypings
      ]
    },

    MissionIssueDefault: {
      excludedColumns: [
        ...issueDefault.excludedColumns,
        'mission.missionId',
        'mission.type',
        'mission.title',
        'mission.tenantId',
        'issueType'
      ],
      defaultColumns: [
        ...issueDefault.defaultColumns,
        'mission'
      ],
      columnTypings: [
        ...issueDefault.columnTypings,
        {
          key: 'mission',
          type: DatatableTableColumnType.REFERENCE,
          idKey: 'missionId',
          displayKey: 'title',
          service: TechPortalFeatureMissionService,
          link: (mission: MissionSimpleListDto) => mission?.missionId ?
            `/missions/${mission.missionId}` : null,
          module: 'inventory',
          permissions: [AppPermissions.ReadMission],
          linkPermissions: [AppPermissions.ReadMission]
        }
      ]
    },

    TicketIssueDefault: {
      excludedColumns: [
        ...issueDefault.excludedColumns,
        'ticket.ticketId',
        'ticket.isArchived',
        'ticket.title',
        'issueType'
      ],
      defaultColumns: [
        ...issueDefault.defaultColumns,
        'ticket',
      ],
      columnTypings: [
        ...issueDefault.columnTypings,
        {
          key: 'ticket',
          name: 'ticket',
          type: DatatableTableColumnType.REFERENCE,
          idKey: 'ticketId',
          displayKey: 'title',
          service: TechPortalTicketService,
          link: (value: TicketSimpleListDto) => value ? `/tickets/${value.ticketId}` : null,
          module: 'communication',
          permissions: [AppPermissions.ReadTicket],
          linkPermissions: [AppPermissions.ReadTicket]
        },
        {
          key: 'priority',
          type: DatatableTableColumnType.REFERENCE,
          idKey: 'priorityId',
          displayKey: 'name',
          service: TechPortalFeatureTicketSettingsPriorityService,
          module: 'settings',
          permissions: [AppPermissions.ReadIssue, AppPermissions.ReadPriority]
        }
      ]
    },

    WinterhalterServiceCallIssueDefault: {
      excludedColumns: [
        ...issueDefault.excludedColumns,
        'issueType'
      ],
      defaultColumns: [
        ...issueDefault.defaultColumns
      ],
      columnTypings: [
        ...issueDefault.columnTypings,
        {
          key: 'issue',
          type: DatatableTableColumnType.REFERENCE,
          idKey: 'issueId',
          displayKey: 'title',
          name: 'title',
          service: TechPortalFeatureIssueListService,
          link: (issue: IssueDetailsListDto) => issue?.issueId ?
            `/issues/winterhalterservicecall/${issue.issueId}` : null,
          module: 'communication',
          permissions: [AppPermissions.ReadIssue, AppPermissions.ReadWinterhalterServiceIssueContent],
          linkPermissions: [AppPermissions.ReadIssue, AppPermissions.ReadWinterhalterServiceIssueContent],
          filters$: of([{
            property: 'issueType',
            type: FilterTypes.DataTransferObject,
            operator: FilterOperators.Equal,
            value: IssueType.WinterhalterServiceCall.toString()
          }] as Filter[])
        },
      ]
    },

    WinterhalterProductOrderIssueDefault: {
      excludedColumns: [
        ...issueDefault.excludedColumns,
        'issueType'
      ],
      defaultColumns: [
        ...issueDefault.defaultColumns
      ],
      columnTypings: [
        ...issueDefault.columnTypings,
        {
          key: 'issue',
          type: DatatableTableColumnType.REFERENCE,
          idKey: 'issueId',
          displayKey: 'title',
          name: 'title',
          service: TechPortalFeatureIssueListService,
          link: (issue: IssueDetailsListDto) => issue?.issueId ?
            `/issues/winterhalterproductorder/${issue.issueId}` : null,
          module: 'communication',
          permissions: [AppPermissions.ReadIssue, AppPermissions.ReadWinterhalterOrderIssueContent],
          linkPermissions: [AppPermissions.ReadIssue, AppPermissions.ReadWinterhalterOrderIssueContent],
          filters$: of([{
            property: 'issueType',
            type: FilterTypes.DataTransferObject,
            operator: FilterOperators.Equal,
            value: IssueType.WinterhalterProductOrder.toString()
          }] as Filter[])
        },
      ]
    },

    /* Tasks */
    TaskDefault: {
      excludedColumns: [
        'executionPlan.executionId',
        'tenantId',
        'executionPlan',
        'parentId',
        'isEnabled',
        'executionPlanId',
        'reasonSetId'
      ],
      defaultColumns: [
        'taskId',
        'title',
        'categoryRelations',
        'description',
        'type',
        'state',
        'executionPlan.type'
      ],
      columnTypings: [
        {
          key: 'type',
          type: DatatableTableColumnType.ENUM,
          name: 'entity-type',
          enumOptions: taskTypeEnumOptions
        },
        {
          key: 'state',
          type: DatatableTableColumnType.ENUM,
          name: 'current-state',
          enumOptions: taskStatesEnumOptions
        },
        {
          key: 'executionPlan.type',
          type: DatatableTableColumnType.ENUM,
          name: 'execution-plan',
          enumOptions: executionPlanEnumOptions
        },
        {
          key: 'requiresAuthentication',
          type: DatatableTableColumnType.BOOLEAN
        },

        // Categories
        {
          key: 'categoryRelations',
          name: 'categories',
          type: DatatableTableColumnType.REFERENCE,
          idKey: 'categoryId',
          displayKey: 'name',
          multiple: true,
          mapValues: (values: CategorizedRelationDto[]) => values.map(x => x.category),
          service: OrgaPortalFeatureCategoryService,
          filterProp: 'categoryRelations',
          prependIcon: {
            color: (value: CategoryDto) => value?.color,
            icon: faCircle,
            size: 'xs'
          },
          labelTitleTemplateKey: 'categoryLabelTitleTemplate',
          optionTitleTemplateKey: 'categoryOptionTitleTemplate',
          permissions: [AppPermissions.ReadCategory]
        }
      ]
    },
    TaskArchiveDefault: {
      defaultColumns: [
        ...taskArchiveDefault.defaultColumns,
        'editorName',
        'currentJobStatus.reason.content'
      ],
      columnTypings: [
        ...taskArchiveDefault.columnTypings,
        {
          key: 'editorName',
          type: DatatableTableColumnType.TEXT,
          name: 'editor',

        },
        {
          key: 'currentJobStatus.reason.content',
          type: DatatableTableColumnType.TEXT,
          name: 'reason'
        }
      ],
      excludedColumns: [
        ...taskArchiveDefault.excludedColumns,
        'currentJobStatus.createdByContactName'
      ]
    },
    TaskArchiveActive: {
      ...taskArchiveDefault,
      excludedColumns: [
        ...taskArchiveDefault.excludedColumns,
        'currentJobStatus.createdByContactName',
        'currentJobStatus.reason.content'
      ]
    }
  };
}

function provideInventoryDatatableConfig(): CorePortalDatatableStandardConfig {
  return {
    /* Missions */
    MissionDefault: {
      excludedColumns: [
        'tenantId',
        'type',
        'solutionId',
        'ticketId',
        'stereotypeId',
        'stereotypeRowVersion',
        'customPropertyValues',
        'stereotype.tenantId',
        'stereotype.rowVersion',
        'stereotype.position',
        'stereotype.isArchived',
        'stereotype.externalId',
        'stereotype.stereotypeId',
        "rowVersion",
        "links",
        "attachments",
        "isLabelingSince",
        "labels",
        "creator",
        "solution",
        "similarEntities",
        "precursor",

        "isSendingMail",
        "lastEmailSend",
        "creator.id",
        "creator.name",
        "solution.solutionId",
        "solution.members",
        "solution.attachments",
        "solution.threadId",
        "solution.threadMailAddress",
        "reportId",

        "resource.location.phoneNumber",
        "resource.location.info",
        "resource.location.invoiceAddress.addressId",
        "resource.location.invoiceAddress.point.lat",
        "resource.location.invoiceAddress.point.lng",
        "resource.location.invoiceAddress.tenantId",
        "resource.location.invoiceAddress.rowVersion",
        "resource.location.shippingAddress.addressId",
        "resource.location.shippingAddress.point.lat",
        "resource.location.shippingAddress.point.lng",
        "resource.location.shippingAddress.tenantId",
        "resource.location.shippingAddress.rowVersion",
        "resource.location.stereotype.stereotypeId",
        "resource.location.stereotype.name",
        "resource.location.stereotype.tenantId",
        "resource.location.stereotype.isArchived",
        "resource.location.stereotype.entityType",
        "resource.location.stereotype.rowVersion",
        "resource.location.stereotype.externalId",
        "resource.location.addresses",
        "resource.stereotype.entityType",
        "resource.location.invoiceAddress.point",
        "resource.location.shippingAddress.point",

        "precursor.missionId",
        "precursor.type",
        "precursor.title",
        "precursor.tenantId",

        "origin.primaryIssue.issueId",
        "origin.primaryIssue.title",
        "origin.primaryIssue.issueType",
        "origin.primaryIssue",

        'sourceTicket.stereotypeId',
        'sourceTicket.stereotypeRowVersion',
        'sourceTicket.stereotype.rowVersion',
        'sourceTicket.currentState.tenantId',
        'sourceTicket.currentState.stateId',
        'sourceTicket.currentState.name',
        'sourceTicket.currentState.color',
        'sourceTicket.editor.contactId',
        'sourceTicket.editor.user',
        'sourceTicket.editor.user.id',
        'sourceTicket.editor.stereotype.stereotypeId',
        'sourceTicket.editor.stereotype.name',
        'sourceTicket.editor.stereotype.isArchived',
        'sourceTicket.editor.stereotype.rowVersion',
        'sourceTicket.editor.stereotype.tenantId',
        'sourceTicket.editor.stereotypeId',
        'sourceTicket.editor.stereotypeRowVersion',
        'sourceTicket.editor.customPropertyValues',
        'sourceTicket.priority.tenantId',
        'sourceTicket.priority.priorityId',
        'sourceTicket.priority.name',
        'sourceTicket.priority.isArchived',
        'sourceTicket.resource.resourceId',
        'sourceTicket.resource.name',
        'sourceTicket.resource.inventoryNumber',
        'sourceTicket.resource.path',
        'sourceTicket.resource.pathJson',
        'sourceTicket.resource.location.locationId',
        'sourceTicket.resource.location.name',
        'sourceTicket.resource.location.tenantId',
        'sourceTicket.resource.location.phoneNumber',
        'sourceTicket.resource.location.externalId',
        'sourceTicket.resource.location.rowVersion',
        'sourceTicket.resource.location.stereotypeId',
        'sourceTicket.resource.location.tenantId',
        'sourceTicket.resource.location.stereotypeRowVersion',
        'sourceTicket.resource.location.customPropertyValues',
        'sourceTicket.resource.location.stereotype.stereotypeId',
        'sourceTicket.resource.location.stereotype.name',
        'sourceTicket.resource.location.stereotype.isArchived',
        'sourceTicket.resource.location.stereotype.rowVersion',
        'sourceTicket.resource.location.stereotype.tenantId',
        'sourceTicket.resource.stereotypeId',
        'sourceTicket.resource.stereotypeRowVersion',
        'sourceTicket.resource.stereotype.stereotypeId',
        'sourceTicket.resource.stereotype.name',
        'sourceTicket.resource.stereotype.isArchived',
        'sourceTicket.resource.stereotype.rowVersion',
        'sourceTicket.resource.stereotype.tenantId',
        'sourceTicket.resource.customPropertyValues',
        'sourceTicket.escalationLevel.escalationLevelId',
        'sourceTicket.escalationLevel.name',
        'sourceTicket.escalationLevel.tenantId',
        'sourceTicket.resource.location.invoiceAddress.tenantId',
        'sourceTicket.resource.location.invoiceAddress.rowVersion',
        'sourceTicket.resource.location.invoiceAddress.stereotypeRowVersion',
        'sourceTicket.resource.location.invoiceAddress.customPropertyValues',
        'sourceTicket.resource.location.invoiceAddress.addressId',
        'sourceTicket.resource.location.invoiceAddress.stereotypeId',
        'sourceTicket.resource.location.invoiceAddress.stereotype.stereotypeId',
        'sourceTicket.resource.location.invoiceAddress.stereotype.name',
        'sourceTicket.resource.location.invoiceAddress.stereotype.tenantId',
        'sourceTicket.resource.location.invoiceAddress.stereotype.rowVersion',
        'sourceTicket.resource.location.invoiceAddress.stereotype.isArchived',
        'sourceTicket.resource.location.shippingAddress.tenantId',
        'sourceTicket.resource.location.shippingAddress.rowVersion',
        'sourceTicket.resource.location.shippingAddress.stereotypeRowVersion',
        'sourceTicket.resource.location.shippingAddress.customPropertyValues',
        'sourceTicket.resource.location.shippingAddress.addressId',
        'sourceTicket.resource.location.shippingAddress.stereotypeId',
        'sourceTicket.resource.location.shippingAddress.stereotype.stereotypeId',
        'sourceTicket.resource.location.shippingAddress.stereotype.name',
        'sourceTicket.resource.location.shippingAddress.stereotype.tenantId',
        'sourceTicket.resource.location.shippingAddress.stereotype.rowVersion',
        'sourceTicket.resource.location.shippingAddress.stereotype.isArchived',
        'sourceTicket.stereotype.stereotypeId',
        'sourceTicket.stereotype.name',
        'sourceTicket.stereotype.isArchived',
        'sourceTicket.stereotype.tenantId',
        'sourceTicket.customPropertyValues',
        'sourceTicket.resource.currentState',
        'sourceTicket.resource.type',
        "sourceTicket.resource.manufacturer",
        "sourceTicket.resource.model",
        "sourceTicket.resource.manufacturer.resourceManufacturerId",
        "sourceTicket.resource.manufacturer.name",
        "sourceTicket.resource.model.resourceModelId",
        "sourceTicket.resource.model.name",
        "sourceTicket.resource.model.resourceManufacturerId",

        'sourceTask.tenantId',
        'sourceTask.isArchived',
        'sourceTask.isCancelingObsoleteMissions',

        'sourceResource.pathJson',
        'sourceResource.path',
        'sourceResource.resourceId',
        'sourceResource.name',
        'sourceResource.tenantId',
        'sourceResource.stereotypeId',
        'sourceResource.stereotypeRowVersion',
        'sourceResource.customPropertyValues',
        'sourceResource.location.locationId',
        'sourceResource.location.name',
        'sourceResource.location.tenantId',
        'sourceResource.location.externalId',
        'sourceResource.location.phoneNumber',
        'sourceResource.location.rowVersion',
        'sourceResource.location.stereotypeId',
        'sourceResource.location.customPropertyValues',
        'sourceResource.location.stereotypeRowVersion',
        'sourceResource.location.stereotype.stereotypeId',
        'sourceResource.location.stereotype.rowVersion',
        'sourceResource.location.stereotype.isArchived',
        'sourceResource.location.stereotype.name',
        'sourceResource.location.stereotype.tenantId',
        'sourceResource.location.invoiceAddress.tenantId',
        'sourceResource.location.invoiceAddress.rowVersion',
        'sourceResource.location.invoiceAddress.stereotypeRowVersion',
        'sourceResource.location.invoiceAddress.customPropertyValues',
        'sourceResource.location.invoiceAddress.addressId',
        'sourceResource.location.invoiceAddress.stereotypeId',
        'sourceResource.location.invoiceAddress.stereotype.stereotypeId',
        'sourceResource.location.invoiceAddress.stereotype.name',
        'sourceResource.location.invoiceAddress.stereotype.tenantId',
        'sourceResource.location.invoiceAddress.stereotype.rowVersion',
        'sourceResource.location.invoiceAddress.stereotype.isArchived',
        'sourceResource.location.shippingAddress.tenantId',
        'sourceResource.location.shippingAddress.rowVersion',
        'sourceResource.location.shippingAddress.stereotypeRowVersion',
        'sourceResource.location.shippingAddress.customPropertyValues',
        'sourceResource.location.shippingAddress.addressId',
        'sourceResource.location.shippingAddress.stereotypeId',
        'sourceResource.location.shippingAddress.stereotype.stereotypeId',
        'sourceResource.location.shippingAddress.stereotype.name',
        'sourceResource.location.shippingAddress.stereotype.tenantId',
        'sourceResource.location.shippingAddress.stereotype.rowVersion',
        'sourceResource.location.shippingAddress.stereotype.isArchived',
        'sourceResource.stereotype.stereotypeId',
        'sourceResource.stereotype.rowVersion',
        'sourceResource.stereotype.name',
        'sourceResource.stereotype.isArchived',
        'sourceResource.stereotype.tenantId',
        "sourceResource.manufacturer.resourceManufacturerId",
        "sourceResource.manufacturer.name",
        "sourceResource.model.resourceModelId",
        "sourceResource.model.name",
        "sourceResource.model.resourceManufacturerId",
        "sourceResource.manufacturer",
        "sourceResource.model",

        'solutionContact.user',
        'solutionContact.user.id',
        'solutionContact.stereotypeId',
        'solutionContact.stereotype.stereotypeId',
        'solutionContact.stereotype.name',
        'solutionContact.stereotype.tenantId',
        'solutionContact.stereotype.rowVersion',
        'solutionContact.stereotype.isArchived',
        'solutionContact.stereotypeRowVersion',
        'solutionContact.customPropertyValues',
        "solutionContact.email",
        "solutionContact.userId",

        'invoiceAddress.tenantId',
        'invoiceAddress.rowVersion',
        'invoiceAddress.stereotypeRowVersion',
        'invoiceAddress.customPropertyValues',
        'invoiceAddress.addressId',
        'invoiceAddress.stereotypeId',
        'invoiceAddress.stereotype.stereotypeId',
        'invoiceAddress.stereotype.name',
        'invoiceAddress.stereotype.tenantId',
        'invoiceAddress.stereotype.rowVersion',
        'invoiceAddress.stereotype.isArchived',
        'invoiceAddress.point',
        'invoiceAddress.point.lat',
        'invoiceAddress.point.lng',

        'shippingAddress.tenantId',
        'shippingAddress.rowVersion',
        'shippingAddress.stereotypeRowVersion',
        'shippingAddress.customPropertyValues',
        'shippingAddress.addressId',
        'shippingAddress.stereotypeId',
        'shippingAddress.stereotype.stereotypeId',
        'shippingAddress.stereotype.name',
        'shippingAddress.stereotype.tenantId',
        'shippingAddress.stereotype.rowVersion',
        'shippingAddress.stereotype.isArchived',
        'shippingAddress.point',
        'shippingAddress.point.lat',
        'shippingAddress.point.lng',

        'location.locationId',
        'location.tenantId',
        'location.state',
        'location.phoneNumber',
        'location.locationNumber',
        'location.externalId',
        'location.rowVersion',
        'location.stereotypeId',
        'location.stereotypeRowVersion',
        'location.stereotype.entityType',
        'location.stereotype.stereotypeId',
        'location.stereotype.position',
        'location.stereotype.tenantId',
        'location.stereotype.rowVersion',
        'location.stereotype.isArchived',
        'location.stereotype.externalId',
        'location.customPropertyValues',
        'location.info',
        'location.addresses',

        'location.shippingAddress.addressId',
        'location.shippingAddress.street',
        'location.shippingAddress.houseNumber',
        'location.shippingAddress.city',
        'location.shippingAddress.state',
        'location.shippingAddress.zipcode',
        'location.shippingAddress.countryCode',
        'location.shippingAddress.rowVersion',
        'location.shippingAddress.point.lat',
        'location.shippingAddress.point.lng',
        'location.shippingAddress.tenantId',
        "location.shippingAddress.point",

        'location.invoiceAddress.addressId',
        'location.invoiceAddress.street',
        'location.invoiceAddress.houseNumber',
        'location.invoiceAddress.city',
        'location.invoiceAddress.state',
        'location.invoiceAddress.zipcode',
        'location.invoiceAddress.countryCode',
        'location.invoiceAddress.point.lat',
        'location.invoiceAddress.point.lng',
        'location.invoiceAddress.tenantId',
        'location.invoiceAddress.rowVersion',
        "location.invoiceAddress.point",

        'report', // ToDo: Remove in the future
        'report.id',
        'report.missionReportId',
        'report.stereotypeId',
        'report.stereotypeRowVersion',
        'report.customPropertyValues',
        'report.stereotype', // ToDo: Remove in the future
        'report.stereotype.stereotypeId',
        'report.stereotype.name',
        'report.stereotype.tenantId',
        'report.stereotype.rowVersion',
        'report.stereotype.isArchived',

        'sourceTicket.resource.location.stereotype.position',
        'sourceTicket.resource.stereotype.position',
        'sourceTicket.resource.isArchived',
        'sourceTicket.stereotype.position',
        'sourceTicket.editor.stereotype.position',
        'sourceTicket.isArchived',
        'solutionContact.stereotype.position',
        'shippingAddress.stereotype.position',
        'sourceResource.stereotype.position',
        'sourceResource.location.stereotype.position',
        'resource.location.stereotype.position',
        'invoiceAddress.stereotype.position',
        'resource.stereotype.position',
        'editor.stereotype.position',
        'sourceTask.isActivated',

        'quest',
        'quest.function.functionId',
        'quest.function.tenantId',
        'quest.function.name',
        'quest.function.stereotypeId',
        'quest.function.isArchived',
        'quest.function.rowVersion',
        'quest.function.stereotypeRowVersion',

        'sourceResource.isArchived',
        'sourceResource.location.state',
        'sourceTicket.resource.location.state',

        'solutionContact.stereotype.externalId',
        'quest.function.externalId',
        'sourceTicket.currentState.externalId',
        'sourceTicket.editor.stereotype.externalId',
        'sourceTicket.resource.location.stereotype.externalId',
        'sourceTicket.resource.stereotype.externalId',
        'sourceTicket.stereotype.externalId',
        'sourceResource.location.stereotype.externalId',
        'sourceResource.stereotype.externalId',
        'invoiceAddress.stereotype.externalId',
        'shippingAddress.stereotype.externalId',
      ],
      defaultColumns: [
        'missionId',
        'title',
        'sourceResource',
        'plannedStart',
        'actualEnd',
        'state',
        'solutionContact',
        'stereotype',
        'labelRelations'
      ],
      columnTypings: [
        {
          key: 'plannedStart',
          type: DatatableTableColumnType.DATE,
          utc: true
        },
        {
          key: 'plannedEnd',
          type: DatatableTableColumnType.DATE,
          utc: true
        },
        {
          key: 'actualStart',
          type: DatatableTableColumnType.DATE,
          utc: true
        },
        {
          key: 'actualEnd',
          type: DatatableTableColumnType.DATE,
          utc: true
        },
        {
          key: 'createdAt',
          type: DatatableTableColumnType.DATE,
          utc: true
        },
        {
          key: 'lastModified',
          type: DatatableTableColumnType.DATE,
          utc: true
        },
        {
          key: 'state',
          name: 'mission-state',
          type: DatatableTableColumnType.ENUM,
          enumOptions: techPortalFeatureMissionStateEnumOptions
        },
        {
          key: 'locationNumber',
          type: DatatableTableColumnType.TEXT,
          fixedWidth: 160
        },
        {
          key: 'isCompleted',
          type: DatatableTableColumnType.BOOLEAN
        },

        // Source ticket
        {
          key: 'sourceTicket',
          name: 'ticket',
          type: DatatableTableColumnType.REFERENCE,
          idKey: 'ticketId',
          displayKey: 'title',
          service: TechPortalTicketService,
          link: (value: TicketSimpleDto) => value ? `/tickets/${value.ticketId}` : null,
          module: 'communication',
          permissions: [AppPermissions.ReadTicket],
          linkPermissions: [AppPermissions.ReadTicket]
        },
        {
          key: 'sourceTicket.escalationLevel',
          type: DatatableTableColumnType.REFERENCE,
          idKey: 'escalationLevelId',
          displayKey: 'name',
          service: TechPortalFeatureTicketSettingsEscalationLevelService,
          link: (escalationLevel: EscalationLevelSimpleDto) => escalationLevel ?
            `/escalation-levels/${escalationLevel.escalationLevelId}` : null,
          module: 'settings',
          permissions: [AppPermissions.ReadTicket, AppPermissions.ReadEscalationLevel],
          linkPermissions: [AppPermissions.ReadEscalationLevel]
        },
        {
          key: 'sourceTicket.resource',
          type: DatatableTableColumnType.REFERENCE,
          idKey: 'resourceId',
          displayKey: 'name',
          service: CorePortalFeatureResourceService,
          link: (resource: ResourceInTicketDto) => resource?.resourceId ? `/resources/${resource.resourceId}` : null,
          module: 'inventory',
          fragment: 'edit',
          permissions: [AppPermissions.ReadTicket, AppPermissions.ReadResource],
          linkPermissions: [AppPermissions.ReadResource]
        },
        {
          key: 'sourceTicket.resource.location',
          type: DatatableTableColumnType.REFERENCE,
          idKey: 'locationId',
          displayKey: 'name',
          service: CorePortalFeatureMasterDataLocationService,
          link: (location: LocationDto) => location ? `/masterdata/locations/${location.locationId}` : null,
          module: 'management',
          permissions: [AppPermissions.ReadTicket, AppPermissions.ReadResource, AppPermissions.ReadLocation],
          linkPermissions: [AppPermissions.ReadLocation],
          alternate: {
            idKey: 'locationGroupId',
            displayKey: 'name',
            service: CorePortalFeatureMasterDataLocationGroupService
          }
        },
        {
          key: 'sourceTicket.resource.location.shippingAddress',
          type: DatatableTableColumnType.REFERENCE,
          idKey: 'addressId',
          displayKey: 'street',
          additionalSearchProperties: ['houseNumber', 'zipcode', 'city'],
          template: (address: AddressSimpleDto) => {
            if (address?.street) {
              let firstPart = address.street;
              let secondPart = address.city;

              if (address.houseNumber) {
                firstPart = `${firstPart} ${address.houseNumber}`;
              }

              if (address.zipcode) {
                secondPart = `${address.zipcode} ${secondPart}`;
              }

              return `${firstPart}${secondPart ? `, ${secondPart}` : ''}`;
            }
          },
          service: CorePortalFeatureMasterDataAddressService,
          link: (value: AddressSimpleDto) => value ? `/masterdata/addresses/${value.addressId}` : null,
          module: 'management',
          permissions: [AppPermissions.ReadAddress],
          linkPermissions: [AppPermissions.DisplayAddresses]
        },
        {
          key: 'sourceTicket.resource.location.invoiceAddress',
          type: DatatableTableColumnType.REFERENCE,
          idKey: 'addressId',
          displayKey: 'street',
          additionalSearchProperties: ['houseNumber', 'zipcode', 'city'],
          template: (address: AddressSimpleDto) => {
            if (address?.street) {
              let firstPart = address.street;
              let secondPart = address.city;

              if (address.houseNumber) {
                firstPart = `${firstPart} ${address.houseNumber}`;
              }

              if (address.zipcode) {
                secondPart = `${address.zipcode} ${secondPart}`;
              }

              return `${firstPart}${secondPart ? `, ${secondPart}` : ''}`;
            }
          },
          service: CorePortalFeatureMasterDataAddressService,
          link: (value: AddressSimpleDto) => value ? `/masterdata/addresses/${value.addressId}` : null,
          module: 'management',
          permissions: [AppPermissions.ReadAddress],
          linkPermissions: [AppPermissions.DisplayAddresses]
        },
        {
          key: 'sourceTicket.priority',
          type: DatatableTableColumnType.REFERENCE,
          idKey: 'priorityId',
          displayKey: 'name',
          service: TechPortalFeatureTicketSettingsPriorityService,
          permissions: [AppPermissions.ReadTicket, AppPermissions.ReadPriority]
        },
        {
          key: 'sourceTicket.currentState',
          type: DatatableTableColumnType.REFERENCE,
          idKey: 'stateId',
          displayKey: 'name',
          service: TechPortalFeatureTicketSettingsStateService,
          prependIcon: {
            color: (value: StateDto) => value?.color,
            icon: faCircle,
            size: 'xs'
          },
          permissions: [AppPermissions.ReadTicket, AppPermissions.ReadState]
        },
        {
          key: 'sourceTicket.editor',
          type: DatatableTableColumnType.REFERENCE,
          idKey: 'contactId',
          displayKey: 'displayName',
          service: CorePortalContactService,
          link: (editor: ContactSimpleDto) => editor ? `/masterdata/contacts/${editor.contactId}` : null,
          module: 'management',
          permissions: [AppPermissions.ReadTicket, AppPermissions.ReadContact],
          linkPermissions: [AppPermissions.ReadContact],
          alternate: {
            idKey: 'functionId',
            displayKey: 'name',
            service: CorePortalFunctionService
          }
        },
        {
          key: 'sourceTicket.editor.mobile',
          type: DatatableTableColumnType.PHONE
        },
        {
          key: 'sourceTicket.editor.phone',
          type: DatatableTableColumnType.PHONE
        },

        // Source Task
        {
          key: 'sourceTask',
          name: 'resource-task',
          type: DatatableTableColumnType.REFERENCE,
          idKey: 'resourceTaskId',
          displayKey: 'title',
          service: TechPortalFeatureResourceTaskService,
          link: (value: ResourceTaskSimpleDto) => value ? `/resource-tasks/${value.resourceTaskId}` : null,
          module: 'inventory',
          permissions: [AppPermissions.ReadResourceTask],
          linkPermissions: [AppPermissions.ReadResourceTask]
        },
        {
          key: 'sourceTask.isDeactivated',
          type: DatatableTableColumnType.BOOLEAN
        },
        {
          key: 'sourceTask.startDate',
          type: DatatableTableColumnType.DATE,
          utc: true,
          format: 'LL'
        },
        {
          key: 'sourceTask.endDate',
          type: DatatableTableColumnType.DATE,
          utc: true,
          format: 'LL'
        },

        // Source Resource
        {
          key: 'sourceResource',
          name: 'resource',
          type: DatatableTableColumnType.REFERENCE,
          idKey: 'resourceId',
          displayKey: 'name',
          service: CorePortalFeatureResourceService,
          link: (value: ResourceSimpleDto) => value ? `/resources/${value.resourceId}` : null,
          module: 'inventory',
          permissions: [AppPermissions.ReadResource],
          linkPermissions: [AppPermissions.ReadResource]
        },
        {
          key: 'sourceResource.location',
          type: DatatableTableColumnType.REFERENCE,
          idKey: 'locationId',
          displayKey: 'name',
          service: CorePortalFeatureMasterDataLocationService,
          link: (value: LocationSimpleDto) => value ? `/masterdata/locations/${value.locationId}` : null,
          module: 'management',
          permissions: [AppPermissions.ReadLocation],
          linkPermissions: [AppPermissions.ReadLocation],
          alternate: {
            idKey: 'locationGroupId',
            displayKey: 'name',
            service: CorePortalFeatureMasterDataLocationGroupService
          }
        },
        {
          key: 'sourceResource.location.shippingAddress',
          type: DatatableTableColumnType.REFERENCE,
          idKey: 'addressId',
          displayKey: 'street',
          additionalSearchProperties: ['houseNumber', 'zipcode', 'city'],
          template: (address: AddressSimpleDto) => {
            if (address?.street) {
              let firstPart = address.street;
              let secondPart = address.city;

              if (address.houseNumber) {
                firstPart = `${firstPart} ${address.houseNumber}`;
              }

              if (address.zipcode) {
                secondPart = `${address.zipcode} ${secondPart}`;
              }

              return `${firstPart}${secondPart ? `, ${secondPart}` : ''}`;
            }
          },
          service: CorePortalFeatureMasterDataAddressService,
          link: (value: AddressSimpleDto) => value ? `/masterdata/addresses/${value.addressId}` : null,
          module: 'management',
          permissions: [AppPermissions.ReadAddress],
          linkPermissions: [AppPermissions.DisplayAddresses]
        },
        {
          key: 'sourceResource.location.invoiceAddress',
          type: DatatableTableColumnType.REFERENCE,
          idKey: 'addressId',
          displayKey: 'street',
          additionalSearchProperties: ['houseNumber', 'zipcode', 'city'],
          template: (address: AddressSimpleDto) => {
            if (address?.street) {
              let firstPart = address.street;
              let secondPart = address.city;

              if (address.houseNumber) {
                firstPart = `${firstPart} ${address.houseNumber}`;
              }

              if (address.zipcode) {
                secondPart = `${address.zipcode} ${secondPart}`;
              }

              return `${firstPart}${secondPart ? `, ${secondPart}` : ''}`;
            }
          },
          service: CorePortalFeatureMasterDataAddressService,
          link: (value: AddressSimpleDto) => value ? `/masterdata/addresses/${value.addressId}` : null,
          module: 'management',
          permissions: [AppPermissions.ReadAddress],
          linkPermissions: [AppPermissions.DisplayAddresses]
        },
        {
          key: 'sourceResource.type',
          name: 'resource-type',
          type: DatatableTableColumnType.ENUM,
          enumOptions: resourceTypeEnumOptions
        },
        {
          key: 'sourceResource.currentState',
          name: 'resource-state',
          type: DatatableTableColumnType.ENUM,
          enumOptions: resourceStateColoredEnumOptions
        },
        {
          key: 'location.invoiceAddress',
          type: DatatableTableColumnType.REFERENCE,
          idKey: 'addressId',
          displayKey: 'street',
          additionalSearchProperties: ['houseNumber', 'zipcode', 'city'],
          template: (address: AddressSimpleDto) => {
            if (address?.street) {
              let firstPart = address.street;
              let secondPart = address.city;

              if (address.houseNumber) {
                firstPart = `${firstPart} ${address.houseNumber}`;
              }

              if (address.zipcode) {
                secondPart = `${address.zipcode} ${secondPart}`;
              }

              return `${firstPart}${secondPart ? `, ${secondPart}` : ''}`;
            }
          },
          service: CorePortalFeatureMasterDataAddressService,
          link: (value: AddressSimpleDto) => value ? `/masterdata/addresses/${value.addressId}` : null,
          module: 'management',
          permissions: [AppPermissions.ReadAddress],
          linkPermissions: [AppPermissions.DisplayAddresses]
        },
        {
          key: 'location.shippingAddress',
          type: DatatableTableColumnType.REFERENCE,
          idKey: 'addressId',
          displayKey: 'street',
          additionalSearchProperties: ['houseNumber', 'zipcode', 'city'],
          template: (address: AddressSimpleDto) => {
            if (address?.street) {
              let firstPart = address.street;
              let secondPart = address.city;

              if (address.houseNumber) {
                firstPart = `${firstPart} ${address.houseNumber}`;
              }

              if (address.zipcode) {
                secondPart = `${address.zipcode} ${secondPart}`;
              }

              return `${firstPart}${secondPart ? `, ${secondPart}` : ''}`;
            }
          },
          service: CorePortalFeatureMasterDataAddressService,
          link: (value: AddressSimpleDto) => value ? `/masterdata/addresses/${value.addressId}` : null,
          module: 'management',
          permissions: [AppPermissions.ReadAddress],
          linkPermissions: [AppPermissions.DisplayAddresses]
        },

        // Solution contact
        {
          key: 'solutionContact',
          type: DatatableTableColumnType.REFERENCE,
          idKey: 'contactId',
          displayKey: 'displayName',
          service: CorePortalContactService,
          link: (value: ContactSimpleDto) => value ? `/masterdata/contacts/${value.contactId}` : null,
          module: 'management',
          permissions: [AppPermissions.ReadContact],
          linkPermissions: [AppPermissions.DisplayContacts],
          alternate: {
            idKey: 'functionId',
            displayKey: 'name',
            service: CorePortalFunctionService
          }
        },

        {
          key: 'solutionContact.mobile',
          type: DatatableTableColumnType.PHONE
        },
        {
          key: 'solutionContact.phone',
          type: DatatableTableColumnType.PHONE
        },
        {
          key: 'editor.mobile',
          type: DatatableTableColumnType.PHONE
        },
        {
          key: 'editor.phone',
          type: DatatableTableColumnType.PHONE
        },
        // Invoice address
        {
          key: 'invoiceAddress',
          type: DatatableTableColumnType.REFERENCE,
          idKey: 'addressId',
          displayKey: 'street',
          additionalSearchProperties: ['houseNumber', 'zipcode', 'city'],
          template: (address: AddressSimpleDto) => {
            if (address?.street) {
              let firstPart = address.street;
              let secondPart = address.city;

              if (address.houseNumber) {
                firstPart = `${firstPart} ${address.houseNumber}`;
              }

              if (address.zipcode) {
                secondPart = `${address.zipcode} ${secondPart}`;
              }

              return `${firstPart}${secondPart ? `, ${secondPart}` : ''}`;
            }
          },
          service: CorePortalFeatureMasterDataAddressService,
          link: (value: AddressSimpleDto) => value ? `/masterdata/addresses/${value.addressId}` : null,
          module: 'management',
          permissions: [AppPermissions.ReadAddress],
          linkPermissions: [AppPermissions.DisplayAddresses]
        },

        // Shipping address
        {
          key: 'shippingAddress',
          type: DatatableTableColumnType.REFERENCE,
          idKey: 'addressId',
          displayKey: 'street',
          additionalSearchProperties: ['houseNumber', 'zipcode', 'city'],
          template: (address: AddressSimpleDto) => {
            if (address?.street) {
              let firstPart = address.street;
              let secondPart = address.city;

              if (address.houseNumber) {
                firstPart = `${firstPart} ${address.houseNumber}`;
              }

              if (address.zipcode) {
                secondPart = `${address.zipcode} ${secondPart}`;
              }

              return `${firstPart}${secondPart ? `, ${secondPart}` : ''}`;
            }
          },
          service: CorePortalFeatureMasterDataAddressService,
          link: (value: AddressSimpleDto) => value ? `/masterdata/addresses/${value.addressId}` : null,
          module: 'management',
          permissions: [AppPermissions.ReadAddress],
          linkPermissions: [AppPermissions.DisplayAddresses]
        },
        {
          key: 'quest.function',
          type: DatatableTableColumnType.REFERENCE,
          idKey: 'functionId',
          displayKey: 'name',
          service: CorePortalFunctionService
        },
        {
          key: 'quest.state',
          name: 'quest.state',
          type: DatatableTableColumnType.ENUM,
          enumOptions: techPortalFeatureMissionQuestStatEnumOptions
        },
        {
          key: 'cost',
          disableFilter: true,
          type: DatatableTableColumnType.CURRENCY
        },
        {
          key: 'labelRelations',
          displayKey: 'label.content',
          type: DatatableTableColumnType.ARRAY,
          disableSorting: true
        },
        {
          key: 'location',
          type: DatatableTableColumnType.REFERENCE,
          idKey: 'locationId',
          displayKey: 'name',
          service: CorePortalFeatureMasterDataLocationService,
          link: (value: LocationSimpleDto) => value ? `/masterdata/locations/${value.locationId}` : null,
          module: 'management',
          permissions: [AppPermissions.ReadLocation],
          linkPermissions: [AppPermissions.ReadLocation],
          alternate: {
            idKey: 'locationGroupId',
            displayKey: 'name',
            service: CorePortalFeatureMasterDataLocationGroupService
          }
        }
      ]
    },

    /* Resources */
    ResourceDefault: {
      excludedColumns: [
        'customPropertyValues',
        'stereotypeId',
        'tenantId',
        'stereotypeRowVersion',
        'rowVersion',
        'locationId',
        'parentId',
        'isInProgressSince',
        'pathJson',
        'stereotype.name',
        'stereotype.tenantId',
        'stereotype.rowVersion',
        'stereotype.position',
        'stereotype.isArchived',
        'stereotype.externalId',
        'stereotype.stereotypeId',
        'inventoryNumberCount',
        'location.stereotype.name',
        'location.stereotype.stereotypeId',
        'location.stereotype.tenantId',
        'location.stereotype.rowVersion',
        'location.stereotype.isArchived',
        'location.addresses',
        'location.locationNumber',
        'location.stereotype.position',
        'location.stereotype.externalId',
        'locationGroup.name',
        'locationGroup.locationGroupId',
        'locationGroup.externalId',
        'quickAccess',
        'quickAccessConfiguration.quickAccessConfigurationId',
        'quickAccessConfiguration',

        'manufacturer.resourceManufacturerId',
        'manufacturer.name',
        'model.resourceModelId',
        'model.name',
        'model.resourceManufacturerId'
      ],
      defaultColumns: [
        'resourceId',
        'name',
        'location',
        'stereotype'
      ],
      columnTypings: [
        {
          key: 'hasChildren',
          type: DatatableTableColumnType.BOOLEAN
        },
        {
          key: 'location',
          type: DatatableTableColumnType.REFERENCE,
          idKey: 'locationId',
          displayKey: 'name',
          service: CorePortalFeatureMasterDataLocationService,
          link: (value: LocationSimpleDto) => value ? `/masterdata/locations/${value.locationId}` : null,
          module: 'management',
          permissions: [AppPermissions.ReadLocation],
          linkPermissions: [AppPermissions.ReadLocation],
          alternate: {
            idKey: 'locationGroupId',
            displayKey: 'name',
            service: CorePortalFeatureMasterDataLocationGroupService
          }
        },
        {
          key: 'installationDate',
          type: DatatableTableColumnType.DATE,
          format: 'LL',
          utc: true
        },
        {
          key: 'isArchived',
          type: DatatableTableColumnType.BOOLEAN
        },
        {
          key: 'path',
          type: DatatableTableColumnType.PATH,
          disableSorting: true
        },
        {
          key: 'externalId',
          type: DatatableTableColumnType.TEXT
        },
        {
          key: 'location.state',
          type: DatatableTableColumnType.ENUM,
          enumOptions: locationStateEnumOptions
        },
        {
          key: 'location.stereotype',
          type: DatatableTableColumnType.REFERENCE,
          idKey: 'stereotypeId',
          displayKey: 'name',
          service: CorePortalStereotypeService,
          filters$: of([{
            property: 'entityType',
            type: FilterTypes.DataTransferObject,
            operator: FilterOperators.Equal,
            value: AppEntityType.Location.toString()
          }] as Filter[]),
          link: (value: StereotypeSimpleDto) => value ? `/stereotypes/${value.stereotypeId}` : null,
          module: 'settings',
          permissions: [AppPermissions.ReadStereotype],
          linkPermissions: [AppPermissions.DisplayStereoTypes]
        },
        {
          key: 'stereotype',
          type: DatatableTableColumnType.REFERENCE,
          idKey: 'stereotypeId',
          displayKey: 'name',
          service: CorePortalStereotypeService,
          filters$: of([{
            property: 'entityType',
            type: FilterTypes.DataTransferObject,
            operator: FilterOperators.Equal,
            value: AppEntityType.Resource.toString()
          }] as Filter[]),
          link: (value: StereotypeSimpleDto) => value ? `/stereotypes/${value.stereotypeId}` : null,
          module: 'settings',
          permissions: [AppPermissions.ReadStereotype],
          linkPermissions: [AppPermissions.DisplayStereoTypes]
        },
        {
          key: 'quickAccess.link',
          type: DatatableTableColumnType.LINK,
        },
        {
          key: 'quickAccessConfiguration.isActivated',
          type: DatatableTableColumnType.BOOLEAN,
        },
        {
          key: 'type',
          name: 'resource-type',
          type: DatatableTableColumnType.ENUM,
          enumOptions: resourceTypeEnumOptions
        },
        {
          key: 'locationGroup',
          type: DatatableTableColumnType.REFERENCE,
          idKey: 'locationGroupId',
          displayKey: 'name',
          service: CorePortalFeatureMasterDataLocationGroupService,
          mapValues: (locationGroups: LocationGroupRelationListDto[]) => locationGroups.map(x => x.locationGroup),
          filterProp: 'locationGroup',
          multiple: false,
          separator: ', '
        },
        {
          key: 'manufacturer',
          type: DatatableTableColumnType.REFERENCE,
          idKey: 'resourceManufacturerId',
          displayKey: 'name',
          service: CorePortalManufacturerService,
          link: (value: ResourceManufacturerListDto) => value ? `/manufacturers/${value.resourceManufacturerId}` : null,
          module: 'settings',
          permissions: [AppPermissions.ReadResourceManufacturer],
          linkPermissions: [AppPermissions.DisplayResourceManufacturers]
        },
        {
          key: 'model',
          type: DatatableTableColumnType.REFERENCE,
          idKey: 'resourceModelId',
          displayKey: 'name',
          service: CorePortalModelService,
          link: (value: ResourceModelListDto) => value ? `/manufacturers/${(value as any).resourceManufacturerId}/models/${value.resourceModelId}` : null,
          module: 'settings',
          permissions: [AppPermissions.ReadResourceModel],
          linkPermissions: [AppPermissions.DisplayResourceManufacturers]
        },
        {
          key: 'locationGroup',
          type: DatatableTableColumnType.REFERENCE,
          idKey: 'locationGroupId',
          displayKey: 'name',
          service: CorePortalFeatureMasterDataLocationGroupService,
          mapValues: (locationGroups: LocationGroupRelationListDto[]) => locationGroups.map(x => x.locationGroup),
          filterProp: 'locationGroup',
          multiple: false,
          separator: ', '
        },
        {
          key: 'currentState',
          name: 'resource-state',
          type: DatatableTableColumnType.ENUM,
          enumOptions: resourceStateColoredEnumOptions
        },
      ]
    },

    /* Articles */
    ArticleDefault: {
      excludedColumns: [
        'id',
        'stereotypeRowVersion',
        'tenantId',
        'stereotypeId',
        'customPropertyValues',

        'stereotype.tenantId',
        'stereotype.rowVersion',
        'stereotype.position',
        'stereotype.isArchived',
        'stereotype.externalId',
        'stereotype.stereotypeId',

        'kind.articleKindId',
        'kind.name'
      ],
      defaultColumns: [
        'name',
        'number',
        'stereotype'
      ],
      columnTypings: [
        {
          key: 'number',
          name: 'article-number',
          type: DatatableTableColumnType.NUMBER
        },
        {
          key: 'purchasingPrice',
          type: DatatableTableColumnType.NUMBER
        },
        {
          key: 'sellingPrice',
          type: DatatableTableColumnType.NUMBER
        },
        {
          key: 'kind',
          type: DatatableTableColumnType.REFERENCE,
          idKey: 'articleKindId',
          displayKey: 'name',
          service: CorePortalFeatureArticleKindService,
          permissions: [AppPermissions.ReadArticleKind]
        }
      ]
    },

    /* Article Usages */
    ArticleUsagesDefault: {
      excludedColumns: [
        'id',
        'mission.missionId',
        'mission.title',
        'mission.plannedStart',
        'mission.plannedEnd',
        'mission.actualStart',
        'mission.actualEnd',
        'mission.type',
        'mission.resource.inventoryNumber',
        'mission.resource.resourceId',
        'mission.resource.name',
        'mission.resource.location.name',
        'mission.resource.location.locationNumber',
        'mission.resource',
        'mission.resource.location',
        'mission.resource.location.locationId',
      ],
      defaultColumns: [
        'articleUsageId',
        'mission',
        'price',
        'count',
        'total',
        'note'
      ],
      columnTypings: [
        {
          key: 'articleUsageId',
          type: DatatableTableColumnType.NUMBER
        },
        {
          key: 'count',
          type: DatatableTableColumnType.NUMBER
        },
        {
          key: 'price',
          type: DatatableTableColumnType.CURRENCY,
          name: 'single-price'

        },
        {
          key: 'total',
          type: DatatableTableColumnType.CURRENCY,
          name: 'total-amount'
        },
        {
          key: 'note',
          type: DatatableTableColumnType.TEXT
        },
        {
          key: 'mission',
          type: DatatableTableColumnType.REFERENCE,
          idKey: 'missionId',
          displayKey: 'title',
          service: TechPortalMissionService,
          link: (mission: MissionDto) => mission?.missionId ? `/missions/${mission.missionId}` : null,
          module: 'inventory',
          permissions: [AppPermissions.ReadMission],
          linkPermissions: [AppPermissions.ReadMission]
        }
      ]
    },

    /* Controls */
    ControlDefault: {
      excludedColumns: [
        'tenantId',
        'isArchived'
      ],
      defaultColumns: [
        'controlId',
        'title',
        'condition',
        'isActivated',
      ],
      columnTypings: [
        {
          key: 'controlId',
          type: DatatableTableColumnType.NUMBER,
          name: 'id'
        },
        {
          key: 'condition',
          type: DatatableTableColumnType.ENUM,
          name: 'condition',
          enumOptions: controlConditionTypeEnumOptions
        },
        {
          key: 'isActivated',
          type: DatatableTableColumnType.BOOLEAN
        },
        {
          key: 'startDate',
          name: 'actual-start',
          type: DatatableTableColumnType.DATE,
          utc: true,
          format: 'LL'
        },
        {
          key: 'endDate',
          name: 'actual-end',
          type: DatatableTableColumnType.DATE,
          utc: true,
          format: 'LL'
        },
      ]
    },

    /* Control Issues */
    ControlIssueDefault: {
      excludedColumns: [
        'issue',
        'issueType',
        'issue.tenantId',
        'issue.locationId',
        'issue.issueId',
        'issue.issueType',
        'issue.location',
        'issue.location.name',
        'issue.location.locationNumber',
        'issue.location.externalId',
        'issue.location.locationId',
        'issue.resource',
        'issue.resource.name',
        'issue.resource.resourceId',
        'issue.resource.inventoryNumberCount',
        'issue.resource.inventoryNumber',
        'issue.resource.locationId',
        'issue.resource.parentId',
        'issue.resource.location.locationId',
        'issue.resource.location.name',
        'issue.resource.location.locationNumber',
        'issue.resource.location.externalId',
        'issue.resource.stereotype.stereotypeId',
        'issue.resource.stereotype',
        'issue.resource.stereotype.name',
        'issue.resource.stereotype.position',
        'issue.resource.stereotype.tenantId',
        'issue.resource.stereotype.rowVersion',
        'issue.resource.stereotype.isArchived',
        'issue.resource.stereotype.externalId',
        'issue.resource.externalId',
        'issue.resource.isArchived',
        'issue.resource.path',
        'issue.resource.location',
        'issue.resource.stereotype',
        'issue.createdAt',
        'issue.lastModified',
        'issue.createdByName',
        'issue.lastModified',
        'issue.location',
        'issue.resource',

        'control.controlId',
        'control.title',

        'reason',
        'reason.type',
        'reason.type',
        'reason.frequency',
        'reason.errorCode.errorCodeId',
        'reason.errorCode.externalId',
        'reason.errorCode.displayName',
        'reason.consumptionCode.consumptionCodeId',
        'reason.consumptionCode.externalId',
        'reason.consumptionCode.displayName',
        'reason.consumptionCode.unit',
        'reason.triggerValue',
        'reason.triggerType',
        'reason.lowerLimit',
        'reason.upperLimit',
        'reason.limit',
        'reason.errorCode',
        'reason.consumptionCode'
      ],
      defaultColumns: [
        'issueId',
        'issue.title',
        'control',
        'reason.type'
      ],
      columnTypings: [
        {
          key: 'issueId',
          type: DatatableTableColumnType.TEXT,
          name: 'id'
        },
        {
          key: 'issue.title',
          type: DatatableTableColumnType.TEXT,
          name: 'title'
        },
        {
          key: 'reason.type',
          name: 'reason',
          type: DatatableTableColumnType.ENUM,
          enumOptions: controlIssueReasonTypeEnumOptions
        },
        {
          key: 'control',
          type: DatatableTableColumnType.REFERENCE,
          idKey: 'controlId',
          displayKey: 'title',
          name: 'control',
          service: TechPortalFeatureControlService,
          link: (value: ControlSimpleDto) => value ? `/controls/${value.controlId}` : null,
          module: 'inventory',
          permissions: [AppPermissions.ReadControl],
          linkPermissions: [AppPermissions.DisplayControls]
        }
      ]
    },

    /* Resource Tasks */
    ResourceTaskDefault: {
      excludedColumns: [
        'tenantId',
        'isCancelingObsoleteMissions',
        'isArchived'
      ],
      defaultColumns: [
        'resourceTaskId',
        'title',
        'startDate',
        'endDate',
        'isActivated'
      ],
      columnTypings: [
        {
          key: 'startDate',
          name: 'actual-start',
          type: DatatableTableColumnType.DATE,
          utc: true,
          format: 'LL'
        },
        {
          key: 'endDate',
          name: 'actual-end',
          type: DatatableTableColumnType.DATE,
          utc: true,
          format: 'LL'
        },
        {
          key: 'startOffSet',
          name: 'start-offset',
          type: DatatableTableColumnType.TEXT
        },
        {
          key: 'timeOfDay',
          name: 'execution-time',
          type: DatatableTableColumnType.TEXT
        },
        {
          key: 'isActivated',
          type: DatatableTableColumnType.BOOLEAN
        }
      ]
    },

    /* Contracts */
    ContractDefault: {
      excludedColumns: [
        'rowVersion',
        'customPropertyValues',
        'stereotypeId',
        'stereotypeRowVersion',
        'tenantId',
        'duePeriod',

        'stereotype.tenantId',
        'stereotype.rowVersion',
        'stereotype.position',
        'stereotype.isArchived',
        'stereotype.externalId',
        'stereotype.stereotypeId',
        'stereotype.name',
      ],
      defaultColumns: [
        'contractId',
        'title',
        'description',
        'state'
      ],
      columnTypings: [
        {
          key: 'state',
          name: 'current-state',
          type: DatatableTableColumnType.ENUM,
          enumOptions: contractStateEnumOptions,
          prependIcon: {
            color: (value: ContractDto) => {
              switch (value?.state) {
                case contractStateEnumOptions[0].value:
                  return 'gray';
                case contractStateEnumOptions[1].value:
                  return 'green';
                case contractStateEnumOptions[2].value:
                  return 'orange';
                default:
                  return null;
              }
            },
            icon: faCircle,
            size: 'xs'
          }
        },
        {
          key: 'startDate',
          name: 'actual-start',
          type: DatatableTableColumnType.DATE,
          utc: true
        },
        {
          key: 'endDate',
          name: 'actual-end',
          type: DatatableTableColumnType.DATE,
          utc: true
        },
        {
          key: 'dueDate',
          type: DatatableTableColumnType.DATE,
          utc: true
        }
      ]
    },
    /* My Requests */
    MyRequestsDefault: {
      excludedColumns: [],
      defaultColumns: [
        'missionId',
        'mission',
        'createdAt'
      ],
      columnTypings: [
        {
          key: 'createdAt',
          type: DatatableTableColumnType.DATE,
          utc: true
        },
        {
          key: 'missionId',
          type: DatatableTableColumnType.NUMBER,
          name: 'mission-id'
        },
        {
          key: 'mission',
          type: DatatableTableColumnType.REFERENCE,
          idKey: 'missionId',
          displayKey: 'title',
          service: TechPortalMissionService,
          link: (mission: MissionDto) => mission?.missionId ? `/missions/${mission.missionId}` : null,
          module: 'inventory',
          permissions: [AppPermissions.ReadMission],
          linkPermissions: [AppPermissions.ReadMission]
        },
      ]
    }
  };
}

function provideServiceDatatableConfig(): CorePortalDatatableStandardConfig {
  return {
    /* Tenants */
    ServiceTenantDefault: {
      excludedColumns: [],
      defaultColumns: [
        'tenantId',
        'displayName',
        'domain',
        'isActive',
        'brand',
        'internalCustomNumber',
        'nextDemoExpirationDate'
      ],
      columnTypings: [
        {
          key: 'isActive',
          type: DatatableTableColumnType.BOOLEAN
        },
        {
          key: 'nextDemoExpirationDate',
          type: DatatableTableColumnType.DATE,
          utc: true,
          format: 'LL',
        }
      ]
    },
    ServiceTenantSubscriptionDefault: {
      excludedColumns: [],
      defaultColumns: [
        'productSubscriptionId',
        'edition',
        'kind',
        'amount',
        'dealCode',
        'expiresIn',
        'createdAt'
      ],
      columnTypings: [
        {
          key: 'edition',
          type: DatatableTableColumnType.ENUM,
          name: 'edition',
          enumOptions: tenantSubscriptionTypeEnumOptions
        },
        {
          key: 'kind',
          type: DatatableTableColumnType.ENUM,
          enumOptions: tenantSubscriptionKindOptions
        },
        {
          key: 'expiresIn',
          type: DatatableTableColumnType.DATE,
          utc: true,
          format: 'LL',
        },
        {
          key: 'createdAt',
          type: DatatableTableColumnType.DATE,
          utc: true
        }
      ]
    },
    ServiceTenantSystemContactDefault: {
      excludedColumns: [],
      defaultColumns: [
        'contactId',
        'displayName',
        'emailAddress',
        'firstName',
        'lastName'
      ],
      columnTypings: []
    }
  };
}

function provideSettingsDatatableConfig(): CorePortalDatatableStandardConfig {
  return {
    /* Categories */
    CategoryDefault: {
      excludedColumns: [],
      defaultColumns: [
        'categoryId',
        'name',
        'color',
        'sector'
      ],
      columnTypings: [
        {
          key: 'color',
          type: DatatableTableColumnType.TEXT,
          prependIcon: {
            icon: faCircle,
            color: (value: CategoryDto) => value?.color,
            size: 'xs'
          }
        },
        {
          key: 'sector',
          name: 'display',
          type: DatatableTableColumnType.ENUM,
          enumOptions: categorySectorEnumOptions
        }
      ]
    },

    /* Labels */
    LabelsDefault: {
      excludedColumns: [],
      defaultColumns: [
        'labelId',
        'content'
      ],
      columnTypings: [
        {
          key: 'labelId',
          name: 'id',
          type: DatatableTableColumnType.NUMBER,

        },
        {
          key: 'content',
          type: DatatableTableColumnType.TEXT
        }
      ]
    },

    /* Invitations */
    InvitationsDefault: {
      excludedColumns: [
        'tenantId'
      ],
      defaultColumns: [
        'invitationId',
        'name',
        'code',
        'purpose',
        'isRevoked',
        'createdAt'
      ],
      columnTypings: [
        {
          key: 'invitationId',
          name: 'id',
          type: DatatableTableColumnType.NUMBER,
        },
        {
          key: 'name',
          type: DatatableTableColumnType.TEXT
        },
        {
          key: 'code',
          name: 'invitation-code',
          type: DatatableTableColumnType.TEXT
        },
        {
          key: 'purpose',
          type: DatatableTableColumnType.ENUM,
          enumOptions: invitationPurposeEnumOptions
        },
        {
          key: 'isRevoked',
          type: DatatableTableColumnType.BOOLEAN
        },
        {
          key: 'lastModified',
          type: DatatableTableColumnType.DATE,
          utc: true,
          format: 'L LT'
        },
        {
          key: 'createdAt',
          type: DatatableTableColumnType.DATE,
          utc: true,
          format: 'L LT'
        }
      ]
    },

    /* Ticket Settings */
    SettingsTicketSettingsStateDefault: {
      excludedColumns: [
        'tenantId',
        'rowVersion',
        'kind'
      ],
      defaultColumns: [
        'stateId',
        'name',
        'color'
      ],
      columnTypings: [
        {
          key: 'color',
          type: DatatableTableColumnType.TEXT,
          prependIcon: {
            icon: faCircle,
            color: (value: StateDto) => value?.color,
            size: 'xs'
          }
        }
      ]
    },
    NotificationRulesDefault: {
      excludedColumns: [
        'tenantId'
      ],
      defaultColumns: [
        'notificationRuleId',
        'title'
      ],
      columnTypings: []
    },
    SettingsTicketSettingsStateMachineDefault: {
      excludedColumns: [
        'tenantId',
        'rowVersion'
      ],
      defaultColumns: [
        'stateMachineId',
        'name'
      ],
      columnTypings: []
    },
    SettingsTicketSettingsPrioritySetDefault: {
      excludedColumns: [
        'tenantId',
        'rowVersion',
        'id'
      ],
      defaultColumns: [
        'prioritySetId',
        'name'
      ],
      columnTypings: []
    },
    SettingsTicketSettingsEscalationLevelDefault: {
      excludedColumns: [
        'tenantId',
        'id'
      ],
      defaultColumns: [
        'escalationLevelId',
        'name'
      ],
      columnTypings: []
    },
    SettingsTicketSettingsProcessDefault: {
      excludedColumns: [],
      defaultColumns: [
        'ticketByProcessDefinitionId',
        'name'
      ],
      columnTypings: []
    },

    /* Dashboards */
    SettingsDashboardDefault: {
      excludedColumns: [
        'tenantId',
        'rowVersion'
      ],
      defaultColumns: [
        'dashboardId',
        'name'
      ],
      columnTypings: []
    },

    AppOverviewDefault: {
      excludedColumns: [],
      defaultColumns: [
        'appOverviewId',
        'title'
      ],
      columnTypings: []
    },

    /* Cancellation Reasons */
    CancellationReasonsDefault: {
      excludedColumns: [],
      defaultColumns: [
        'taskJobStateReasonSetId',
        'title'
      ],
      columnTypings: []
    },

    /* Forms */
    FormDefault: {
      excludedColumns: [],
      defaultColumns: [
        'formId',
        'title',
        'description'
      ],
      columnTypings: [
        {
          key: 'isArchived',
          type: DatatableTableColumnType.BOOLEAN
        }
      ]
    },

    /* Chat Definitions */
    ChatDefinitionDefault: {
      excludedColumns: [],
      defaultColumns: [
        'chatByFormDefinitionId',
        'title'
      ],
      columnTypings: []
    },

    /* Mail Nodes */
    SettingsMailNodeDefault: {
      excludedColumns: [
        'tenantId'
      ],
      defaultColumns: [
        'mailNodeId',
        'title'
      ],
      columnTypings: []
    },

    /* Mass Import */
    SettingsImportProtocolDefault: {
      excludedColumns: [
        'tenantId',
        'importConfigurationId',
        'importContentBlobPath'
      ],
      defaultColumns: [
        'importProtocolId',
        'title',
        'createdAt',
        'isColdRun',
        'status',
      ],
      columnTypings: [
        {
          key: 'importProtocolId',
          type: DatatableTableColumnType.TEXT,
          name: 'id'
        },
        {
          key: 'title',
          type: DatatableTableColumnType.TEXT,
          name: 'xml-title'
        },
        {
          key: 'isColdRun',
          type: DatatableTableColumnType.BOOLEAN,
          name: 'is-cold-run'
        },
        {
          key: 'createdAt',
          type: DatatableTableColumnType.DATE,
          utc: true,
          format: 'L LT'
        },
        {
          key: 'status',
          type: DatatableTableColumnType.ENUM,
          name: 'status',
          enumOptions: importProtocolStatusEnumOptions
        },
        {
          key: 'importContentBlobPath',
          type: DatatableTableColumnType.LINK,
          name: 'import-content-blob-path'
        }
      ]
    },

    /* Import Configuration */
    SettingsImportConfigurationsDefault: {
      excludedColumns: [
        'tenantId'
      ],
      defaultColumns: [
        'importConfigurationId',
        'title',
      ],
      columnTypings: [
        {
          key: 'importConfigurationId',
          type: DatatableTableColumnType.TEXT,
          name: 'id',
          clickable: true
        },
        {
          key: 'title',
          type: DatatableTableColumnType.TEXT,
          name: 'title',
          clickable: true
        },
      ]
    },

    /* Searchlists */
    SettingsSearchListsDefault: {
      excludedColumns: [
        'tenantId'
      ],
      defaultColumns: [
        'searchListId',
        'title'
      ],
      columnTypings: []
    },

    /* Text Templates */
    TemplatesTextDefault: {
      excludedColumns: [
        'tenantId'
      ],
      defaultColumns: [
        'templateId',
        'title',
        'context'
      ],
      columnTypings: [
        {
          key: 'context',
          type: DatatableTableColumnType.ENUM,
          enumOptions: textContextTypeEnumOptions.map(enumOption => ({
            ...enumOption,
            label: enumOption.readonlyLabel ?? enumOption.label
          }))
        }
      ]
    },
    TemplatesDocumentDefault: {
      excludedColumns: [
        'tenantId'
      ],
      defaultColumns: [
        'documentTemplateId',
        'title',
        'contextType',
        'isDefault'
      ],
      columnTypings: [
        {
          key: 'contextType',
          name: 'context',
          type: DatatableTableColumnType.ENUM,
          enumOptions: documentContextTypeEnumOptions.map(enumOption => ({
            ...enumOption,
            label: enumOption.readonlyLabel ?? enumOption.label
          }))
        },
        {
          key: 'isDefault',
          type: DatatableTableColumnType.BOOLEAN
        }
      ]
    },

    SettingsTicketsByLocationDefinitionDefault: {
      excludedColumns: [
        'stereotype',
        'tenantId',
        'stereotype.stereotypeId',
        'stereotype.position',
        'stereotype.tenantId',
        'stereotype.isArchived',
        'stereotype.externalId',
        'stereotype.rowVersion'
      ],
      defaultColumns: [
        'ticketsByLocationDefinitionId',
        'title'
      ],
      columnTypings: [
        {
          key: 'ticketsByLocationDefinitionId',
          name: 'id',
          type: DatatableTableColumnType.NUMBER
        }
      ]
    },

    SettingsResourcesByLocationDefinitionDefault: {
      excludedColumns: [
        'stereotype',
        'tenantId',
        'stereotype.stereotypeId',
        'stereotype.position',
        'stereotype.tenantId',
        'stereotype.rowVersion',
        'stereotype.isArchived',
        'stereotype.externalId'
      ],
      defaultColumns: [
        'resourcesByLocationDefinitionId',
        'title'
      ],
      columnTypings: [
        {
          key: 'resourcesByLocationDefinitionId',
          name: 'id',
          type: DatatableTableColumnType.NUMBER
        }
      ]
    },

    /* Inventory number patterns */
    SettingsInventoryNumberPatternDefault: {
      excludedColumns: [
        'tenantId'
      ],
      defaultColumns: [
        'inventoryNumberPatternId',
        'title'
      ],
      columnTypings: []
    },

    /* Mail logs */
    SettingsMailLogsDefault: {
      excludedColumns: [
        'tenantId'
      ],
      defaultColumns: [
        'id',
        'receivers',
        'subject',
        'createdAt',
      ],
      columnTypings: [
        {
          key: 'id',
          type: DatatableTableColumnType.NUMBER
        },
        {
          key: 'receivers',
          type: DatatableTableColumnType.HTML,
          disableSorting: true,
          filterProp: 'receivers.address'
        },
        {
          key: 'createdAt',
          type: DatatableTableColumnType.DATE,
          utc: true,
          format: 'L LT'
        },
      ]
    },

    /* Manufacturer Settings */
    SettingsManufacturerDefault: {
      excludedColumns: [],
      defaultColumns: [
        'resourceManufacturerId',
        'name'
      ],
      columnTypings: [
        {
          key: 'resourceManufacturerId',
          type: DatatableTableColumnType.NUMBER,
          name: 'id'
        }
      ]
    },

    /* Manufacturer Model Settings */
    SettingsModelDefault: {
      excludedColumns: [],
      defaultColumns: [
        'resourceModelId',
        'name'
      ],
      columnTypings: [
        {
          key: 'resourceModelId',
          type: DatatableTableColumnType.NUMBER,
          name: 'id'
        }
      ]
    },

    /* Timeline Settings */
    SettingsTimelineDefault: {
      excludedColumns: [],
      defaultColumns: [
        'timelineSettingId',
        'isInstructedMissionsOnly',
        'stereotypes',
        'locationGroups',
        'locations'
      ],
      columnTypings: [
        {
          key: 'timelineSettingId',
          type: DatatableTableColumnType.NUMBER,
          name: 'id'
        },
        {
          key: 'isInstructedMissionsOnly',
          type: DatatableTableColumnType.BOOLEAN,
        },
        {
          key: 'stereotypes',
          type: DatatableTableColumnType.ARRAY,
          displayKey: 'name',
          disableSorting: true
        },
        {
          key: 'locationGroups',
          type: DatatableTableColumnType.ARRAY,
          displayKey: 'name',
          disableSorting: true
        },
        {
          key: 'locations',
          type: DatatableTableColumnType.ARRAY,
          displayKey: 'name',
          disableSorting: true
        }
      ]
    },

    /* Issue templates */
    SettingsIssueDefault: {
      excludedColumns: [
        'tenantId'
      ],
      defaultColumns: [
        'issueTemplateId',
        'name',
        'contentType',
        'externalId'
      ],
      columnTypings: [
        {
          key: 'issueTemplateId',
          type: DatatableTableColumnType.NUMBER,
          name: 'id'
        },
        {
          key: 'contentType',
          type: DatatableTableColumnType.ENUM,
          enumOptions: issueContentTypeEnumOptions
        },
      ]
    },

    /* Consumption Codes */
    ConsumptionCodeDefault: {
      excludedColumns: [],
      defaultColumns: [
        'consumptionCodeId',
        'displayName',
        'unit'
      ],
      columnTypings: [
        {
          key: 'externalId',
          type: DatatableTableColumnType.TEXT
        }
      ]
    },

    /* Error Codes */
    ErrorCodeDefault: {
      excludedColumns: [],
      defaultColumns: [
        'errorCodeId',
        'displayName'
      ],
      columnTypings: [
        {
          key: 'externalId',
          type: DatatableTableColumnType.TEXT
        }
      ]
    },

    /* Hint Codes */
    HintCodeDefault: {
      excludedColumns: [],
      defaultColumns: [
        'hintCodeId',
        'displayName'
      ],
      columnTypings: [
        {
          key: 'externalId',
          type: DatatableTableColumnType.TEXT
        }
      ]
    },

    /* Definitions */
    ConnectedDefinitionDefault: {
      excludedColumns: [],
      defaultColumns: [
        'connectedDefinitionId',
        'displayName',
      ],
      columnTypings: [
        {
          key: 'externalId',
          type: DatatableTableColumnType.TEXT
        }
      ]
    },

    /* Resource Occurred Consumptions */
    ResourceOccurredConsumptionsDefault: {
      excludedColumns: [
        'eventTime',
        'resource.resourceId',
        'resource.inventoryNumberCount',
        'resource.inventoryNumber',
        'resource.locationId',
        'resource.parentId',
        'resource.location.locationId',
        'resource.location.externalId',
        'resource.stereotype.stereotypeId',
        'resource.stereotype.position',
        'resource.stereotype.tenantId',
        'resource.stereotype.rowVersion',
        'resource.stereotype.isArchived',
        'resource.stereotype.externalId',
        'resource.externalId',
        'resource.path',
        'consumption',
        'consumption.externalId',
        'consumption.consumptionCodeId',
      ],
      defaultColumns: [
        'consumptionCodeId',
        'displayName',
        'lastModified',
        'value'
      ],
      columnTypings: [
        {
          key: 'consumptionCodeId',
          type: DatatableTableColumnType.NUMBER
        },
        {
          key: 'occurredConsumptionId',
          type: DatatableTableColumnType.NUMBER,
          name: 'id'
        },
        {
          key: 'displayName',
          type: DatatableTableColumnType.TEXT,
          addIfNotAvailable: true
        },
        {
          key: 'externalId',
          type: DatatableTableColumnType.TEXT,
          addIfNotAvailable: true
        },
        {
          key: 'lastModified',
          type: DatatableTableColumnType.DATE,
          utc: true,
          format: 'L LT'
        }
      ]
    },

    /* Resource Occurred Errors */
    ResourceOccurredErrorsDefault: {
      excludedColumns: [
        'eventTime',
        'resource.resourceId',
        'resource.inventoryNumberCount',
        'resource.inventoryNumber',
        'resource.locationId',
        'resource.parentId',
        'resource.location.locationId',
        'resource.location.externalId',
        'resource.stereotype.stereotypeId',
        'resource.stereotype.position',
        'resource.stereotype.tenantId',
        'resource.stereotype.rowVersion',
        'resource.stereotype.isArchived',
        'resource.stereotype.externalId',
        'resource.externalId',
        'resource.path',
        'error.errorCodeId',
        'error.externalId',
        'error'
      ],
      defaultColumns: [
        'occurredErrorId',
        'displayName',
        'lastModified',
        'isActive'
      ],
      columnTypings: [
        {
          key: 'occurredErrorId',
          type: DatatableTableColumnType.NUMBER,
          addIfNotAvailable: true
        },
        {
          key: 'errorCodeId',
          type: DatatableTableColumnType.NUMBER,
          addIfNotAvailable: true
        },
        {
          key: 'displayName',
          type: DatatableTableColumnType.TEXT,
          addIfNotAvailable: true
        },
        {
          key: 'externalId',
          type: DatatableTableColumnType.TEXT,
          addIfNotAvailable: true
        },
        {
          key: 'lastModified',
          type: DatatableTableColumnType.DATE,
          utc: true,
          format: 'L LT'
        },
        {
          key: 'isActive',
          type: DatatableTableColumnType.BOOLEAN
        }
      ]
    },
    /* Resource Occurred Errors */
    ResourceOccurredHintsDefault: {
      excludedColumns: [
        'eventTime',
        'resource.resourceId',
        'resource.inventoryNumberCount',
        'resource.inventoryNumber',
        'resource.locationId',
        'resource.parentId',
        'resource.location.locationId',
        'resource.location.externalId',
        'resource.stereotype.stereotypeId',
        'resource.stereotype.position',
        'resource.stereotype.tenantId',
        'resource.stereotype.rowVersion',
        'resource.stereotype.isArchived',
        'resource.stereotype.externalId',
        'resource.externalId',
        'resource.path',
        'hint.hintCodeId',
        'hint.externalId',
        'hint'
      ],
      defaultColumns: [
        'occurredHintId',
        'displayName',
        'lastModified',
        'isActive'
      ],
      columnTypings: [
        {
          key: 'occurredHintId',
          type: DatatableTableColumnType.NUMBER,
          addIfNotAvailable: true
        },
        {
          key: 'hintCodeId',
          type: DatatableTableColumnType.NUMBER,
          addIfNotAvailable: true
        },
        {
          key: 'displayName',
          type: DatatableTableColumnType.TEXT,
          addIfNotAvailable: true
        },
        {
          key: 'externalId',
          type: DatatableTableColumnType.TEXT,
          addIfNotAvailable: true
        },
        {
          key: 'lastModified',
          type: DatatableTableColumnType.DATE,
          utc: true,
          format: 'L LT'
        },
        {
          key: 'isActive',
          type: DatatableTableColumnType.BOOLEAN
        }
      ]
    },

    /* Resource Control Protocol Consumptions */
    ResourceControlProtocolConsumptionsDefault: {
      excludedColumns: [
        'resource.resourceId',
        'resource.name',
        'resource.inventoryNumberCount',
        'resource.inventoryNumber',
        'resource.locationId',
        'resource.parentId',
        'resource.location.locationId',
        'resource.location.name',
        'resource.location.locationNumber',
        'resource.location.externalId',
        'resource.stereotype.stereotypeId',
        'resource.stereotype.name',
        'resource.stereotype.position',
        'resource.stereotype.tenantId',
        'resource.stereotype.rowVersion',
        'resource.stereotype.isArchived',
        'resource.stereotype.externalId',
        'resource.externalId',
        'resource.path',
        'resource.path.index',
        'resource.path.id',
        'resource.path.name',
        'error.errorCodeId',
        'error.externalId',
        'error.displayName',
        'issues',
        'issues.issueId',
        'issues.title',
        'issues.issueType',
        'issues.reason',
        'issues.reason.type',
        'consumption.externalId',
        'consumption.displayName',
        'consumption.consumptionCodeId',
        'consumption.unit',
        'eventId'
      ],
      defaultColumns: [
        "occurredConnectedConsumptionHistoryId",
        'consumption',
        'code',
        'occuredAt'
      ],
      columnTypings: [
        {
          key: 'occurredConnectedConsumptionHistoryId',
          type: DatatableTableColumnType.NUMBER,
          name: 'id',
          addIfNotAvailable: true
        },
        {
          key: 'code',
          type: DatatableTableColumnType.TEXT
        },
        {
          key: 'occuredAt',
          type: DatatableTableColumnType.DATE,
          name: 'occurredAt',
          utc: true
        },
        {
          key: 'isArchived',
          type: DatatableTableColumnType.BOOLEAN
        },
        {
          key: 'consumption',
          type: DatatableTableColumnType.REFERENCE,
          name: 'occurred-consumption',
          idKey: 'consumptionCodeId',
          displayKey: 'displayName',
          service: TechPortalFeatureConnectedConsumptionCodeService,
          link: (consumption: ConsumptionCodeSimpleListDto) => consumption?.consumptionCodeId ? `/connected/${consumption.consumptionCodeId}` : null,
          module: 'settings',
          permissions: [AppPermissions.ReadOccurredConnectedConsumption],
          linkPermissions: [AppPermissions.ReadOccurredConnectedConsumption]
        },
      ]
    },

    /* Resource Control Protocol Errors */
    ResourceControlProtocolErrorsDefault: {
      excludedColumns: [
        'resource.resourceId',
        'resource.name',
        'resource.inventoryNumberCount',
        'resource.inventoryNumber',
        'resource.locationId',
        'resource.parentId',
        'resource.location.locationId',
        'resource.location.name',
        'resource.location.locationNumber',
        'resource.location.externalId',
        'resource.stereotype.stereotypeId',
        'resource.stereotype.name',
        'resource.stereotype.position',
        'resource.stereotype.tenantId',
        'resource.stereotype.rowVersion',
        'resource.stereotype.isArchived',
        'resource.stereotype.externalId',
        'resource.externalId',
        'resource.path',
        'resource.path.index',
        'resource.path.id',
        'resource.path.name',
        'issues',
        'issues.issueId',
        'issues.title',
        'issues.issueType',
        'issues.reason',
        'issues.reason.type',
        'issues',
        'eventId',
        'error.errorCodeId',
        'error.externalId',
        'error.displayName'
      ],
      defaultColumns: [
        "occurredConnectedErrorHistoryId",
        'error',
        'code',
        'occuredAt'
      ],
      columnTypings: [
        {
          key: "occurredConnectedErrorHistoryId",
          type: DatatableTableColumnType.NUMBER,
          name: "id"
        },
        {
          key: 'code',
          type: DatatableTableColumnType.TEXT
        },
        {
          key: 'occuredAt',
          type: DatatableTableColumnType.DATE,
          name: 'occurredAt',
          utc: true
        },
        {
          key: 'isActive',
          type: DatatableTableColumnType.BOOLEAN
        },
        {
          key: 'isArchived',
          type: DatatableTableColumnType.BOOLEAN
        },
        {
          key: 'error',
          type: DatatableTableColumnType.REFERENCE,
          name: 'occurred-error',
          idKey: 'errorCodeId',
          displayKey: 'displayName',
          service: TechPortalFeatureConnectedErrorCodeService,
          link: (error: ErrorCodeSimpleListDto) => error?.errorCodeId ? `/connected/${error.errorCodeId}` : null,
          module: 'settings',
          permissions: [AppPermissions.ReadOccurredConnectedError],
          linkPermissions: [AppPermissions.ReadOccurredConnectedError]
        },
      ]
    },

  };
}
