<div (visible)="onVisibleChange($event)"
     *ngIf="!finished"
     class="infinite-scroll-loading-icon w-100 d-flex justify-content-center"
     nexnoxWebVisibleObserver>
  <nexnox-web-loading-icon *ngIf="loading && !error"
                           size="lg">
  </nexnox-web-loading-icon>
  <button (click)="onReload()"
          *ngIf="error"
          class="btn btn-outline-danger mb-4">
    {{ 'core-shared.shared.table.tooltip.refresh' | translate }}
  </button>
</div>
