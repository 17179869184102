<ng-template #entityEditTemplate
             let-id="id"
             let-title="title">
  <div class="row w-100">
    <div class="col-xl-8 pl-0 pr-0 pr-xl-2">
      <nexnox-web-tickets-ticket-edit #modelComponent
                                      #ticketEditComponent
                                      (assignContact)="onAssignContact($event)"
                                      (assignResource)="onAssignResource($event)"
                                      (modelChange)="onModelChange($event)"
                                      (unassignContact)="onUnassignContact()"
                                      (unassignResource)="onUnassignResource()"
                                      [assignContactLoading$]="loadingAssignContact$"
                                      [assignResourceLoading$]="loadingAssignRessource$"
                                      [creating]="false"
                                      [id]="id"
                                      [loading]="loading$ | async"
                                      [model]="model$ | async"
                                      [readonly]="readonly$ | async"
                                      [stereotypes$]="stereotypes$"
                                      [title]="title">
      </nexnox-web-tickets-ticket-edit>
    </div>

    <div class="col-xl-4 pl-0 pl-xl-2 pr-0">
      <nexnox-web-tickets-ticket-status-edit #modelComponent
                                             (editEditor)="onAssignToAction()"
                                             (modelChange)="onModelChange($event)"
                                             [creating]="false"
                                             [loading]="loading$ | async"
                                             [model]="model$ | async"
                                             [readonly]="readonly$ | async"
                                             [stereotyped]="false"
                                             [stereotypes$]="stereotypes$"
                                             [title]="'tickets.subtitles.ticket-status' | translate:{
                                              state: (model$ | async)?.currentState?.name
                                             }">
      </nexnox-web-tickets-ticket-status-edit>

      <nexnox-web-mission-ticket-issue-status *ngIf="primaryIssue$ | async"
                                              [issue$]="primaryIssue$">
      </nexnox-web-mission-ticket-issue-status>

      <nexnox-web-tickets-ticket-events *ngIf="passedIds"
                                        [canCreate]="false"
                                        [getEntityOnModeChange]="false"
                                        [isEmbedded]="true"
                                        [loading]="loading$ | async"
                                        [parentIds]="passedIds">
      </nexnox-web-tickets-ticket-events>

      <nexnox-web-tickets-ticket-mission-links [loading]="loading$ | async"
                                               [model$]="model$">
      </nexnox-web-tickets-ticket-mission-links>

      <nexnox-web-tickets-related-ticket-links [loading]="loading$ | async"
                                               [model$]="model$">
      </nexnox-web-tickets-related-ticket-links>


      <ng-container *ngIf="model$ | async as model">
        <nexnox-web-entity-labels (labelsChange)="onLabelsChange($event, model)"
                                  *ngIf="displayLabelPermission$ | async"
                                  [entityId]="this.id"
                                  [labelType]="labelType"
                                  [labels]="labels$ | async"
                                  [loading]="loading$ | async"
                                  [tenantId]="tenantId$ | async">
        </nexnox-web-entity-labels>
      </ng-container>
    </div>
  </div>
</ng-template>

<nexnox-web-entity-detail [entityDetailBaseComponent]="this"
                          [entityEditTemplate]="entityEditTemplate"
                          [tabCommands]="['.']"
                          [tabHeading]="title | translate"
                          tabId="label">
  <!-- Solution Tab -->
  <ng-template (selectTab)="onNavigateToTab(['solution'])"
               *ngIf="readSolutionPermission$ | async"
               [heading]="'solutions.subtitles.solution' | translate"
               nexnoxWebTabTemplate
               tabId="solution">
    <ng-container *ngIf="model$ | async as model">
      <nexnox-web-tickets-ticket-solution *ngIf="passedIds"
                                          [canCreate]="true"
                                          [hasChat]="model?.hasChat"
                                          [isEmbedded]="true"
                                          [relationId]="model?.ticketId"
                                          [solution]="$any(model?.solution)"
                                          relationIdProperty="ticketId">
      </nexnox-web-tickets-ticket-solution>
    </ng-container>
  </ng-template>

  <!-- Missions Tab -->
  <ng-template (selectTab)="onNavigateToTab(['missions'])"
               *ngIf="readMissionPermission$ | async"
               [heading]="'missions.subtitles.missions' | translate"
               [lazy]="true"
               nexnoxWebTabTemplate
               tabId="missions">
    <ng-container *ngIf="model$ | async as model">
      <nexnox-web-tickets-ticket-missions *ngIf="passedIds && model.solution"
                                          [canCreate]="false"
                                          [canDelete]="true"
                                          [canRouteToDetail]="true"
                                          [detailLink]="'/missions/all'"
                                          [isEmbedded]="true"
                                          [parentIds]="passedIds"
                                          [solution]="$any((model$ | async)?.solution)"
                                          module="inventory">
      </nexnox-web-tickets-ticket-missions>
    </ng-container>
  </ng-template>

  <!-- Links -->
  <ng-template (selectTab)="onNavigateToTab(['links'])"
               [hasError]="linksComponent ? !linksComponent.isModelValid() : false"
               [heading]="('tech-portal.subtitles.links' | translate) + (linksComponent ? (linksComponent.linksString$ | async) : '')"
               nexnoxWebTabTemplate
               tabId="links">
    <nexnox-web-tech-links #linksComponent
                           #modelComponent
                           (linksChange)="onLinksChange($event, model)"
                           *ngIf="model$ | async as model"
                           [entityId]="model.ticketId"
                           [links$]="links$"
                           [loading]="loading$ | async"
                           [readonly]="readonly$ | async"
                           [type]="linkedElementTypes.Ticket">
    </nexnox-web-tech-links>
  </ng-template>

  <!-- Attachments -->
  <ng-template (selectTab)="onNavigateToTab(['attachments'])"
               [hasError]="(attachmentsComponent ? !attachmentsComponent.isModelValid() : false) || (solutionAttachmentsComponent ? !solutionAttachmentsComponent.isModelValid() : false)"
               [heading]="('tech-portal.subtitles.attachments' | translate) + (attachmentsString$ | async)"
               nexnoxWebTabTemplate
               tabId="attachments">
    <nexnox-web-tech-attachments #attachmentsComponent
                                 #modelComponent
                                 (attachmentsChange)="onAttachmentsChange($event, model)"
                                 *ngIf="model$ | async as model"
                                 [attachments$]="attachments$"
                                 [loading]="loading$ | async"
                                 [readonly]="readonly$ | async">
    </nexnox-web-tech-attachments>

    <nexnox-web-tech-attachments #modelComponent
                                 #solutionAttachmentsComponent
                                 *ngIf="readSolutionPermission$ | async"
                                 [attachments$]="solutionAttachments$"
                                 [loading]="loading$ | async"
                                 [readonly]="true"
                                 cardboxTitle="tech-portal.subtitles.solution-attachments">
    </nexnox-web-tech-attachments>
  </ng-template>

  <!-- Audit Tab -->
  <ng-template (selectTab)="onNavigateToTab(['audit'])"
               *ngIf="readAuditPermission$ | async"
               [heading]="'core-portal.audit.title' | translate"
               [lazy]="true"
               nexnoxWebTabTemplate
               tabId="audit">
    <nexnox-web-tickets-ticket-audit *ngIf="passedIds"
                                     [isEmbedded]="true"
                                     [parentIds]="[entityTypes.Ticket, passedIds[0]]">
    </nexnox-web-tickets-ticket-audit>
  </ng-template>
</nexnox-web-entity-detail>

<nexnox-web-ticket-state-change-sidebar #ticketStateChangeSidebar>
</nexnox-web-ticket-state-change-sidebar>
