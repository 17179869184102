import {NgModule} from '@angular/core';
import {CorePortalModule} from '@nexnox-web/core-portal';
import {CoreSharedModule, CoreSharedSortableListModule} from '@nexnox-web/core-shared';
import {
  TechPortalFeatureControlEditComponent,
  TechPortalFeatureControlNotificationEditComponent,
  TechPortalFeatureControlResourceEditComponent,
} from './components';
import {
  ControlDetailComponent,
  TechPortalFeatureControlIssueListComponent,
  TechPortalFeatureControlListComponent
} from './containers';
import {controlsServices} from './store';
import {ConsumptionCodesDeclarationsModule} from "../../../connected/consumption-codes/src/lib/consumption-codes-declarations.module";

@NgModule({
  declarations: [
    ControlDetailComponent,
    TechPortalFeatureControlListComponent,
    TechPortalFeatureControlIssueListComponent,

    TechPortalFeatureControlNotificationEditComponent,
    TechPortalFeatureControlEditComponent,
    TechPortalFeatureControlResourceEditComponent
  ],
  imports: [
    CoreSharedModule,
    CorePortalModule,
    CoreSharedSortableListModule,
    ConsumptionCodesDeclarationsModule
  ],
  exports: [
    TechPortalFeatureControlEditComponent
  ],
  providers: [
    ...controlsServices
  ]
})
export class ControlsDeclarationsModule {
}
