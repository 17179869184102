import {EntityXsStore, EntityXsStoreState} from '@nexnox-web/core-store';
import {AppEntityType, ArticleDto} from '@nexnox-web/core-shared';
import {Action, ActionReducer, createSelector} from '@ngrx/store';
import {selectArticlesState} from '../../articles.selector';
import {CorePortalFeatureArticleService} from '../../services';
import {Injectable, Injector} from '@angular/core';

export interface ArticleDetailStoreState extends EntityXsStoreState<ArticleDto> {
}

/* istanbul ignore next */
export const articleDetailStore = new EntityXsStore<ArticleDto>({
  actionLabel: 'Core Portal - Articles - Article Detail',
  stateSelector: createSelector(selectArticlesState, state => state.articleDetail),
  serviceType: CorePortalFeatureArticleService,
  entityType: AppEntityType.Article
});

export function articleDetailStoreReducer(state: ArticleDetailStoreState, action: Action): ActionReducer<any> {
  return articleDetailStore.reducer(state, action);
}

@Injectable()
export class ArticleDetailStoreEffects extends articleDetailStore.effects {
  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }
}
