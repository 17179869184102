import {PagedEntitiesXsStore, PagedEntitiesXsStoreState} from '@nexnox-web/core-store';
import {AppEntityType, ContactDto} from '@nexnox-web/core-shared';
import {Action, createSelector} from '@ngrx/store';
import {selectCompaniesState} from '../../companies.selectors';
import {CompanyContactService} from '../../services';
import {Injectable, Injector} from '@angular/core';

export interface CompanyContactListStoreState extends PagedEntitiesXsStoreState<ContactDto> {
}

/* istanbul ignore next */
export const companyContactListStore = new PagedEntitiesXsStore<ContactDto>({
  actionLabel: 'Core Portal - Master Data - Company - Contact List',
  stateSelector: createSelector(selectCompaniesState, state => state.companyContactList),
  serviceType: CompanyContactService,
  entityType: AppEntityType.Contact,
  selectId: contact => contact.entity?.contactId ?? contact.model?.contactId
});

export function companyContactListStoreReducer(state: CompanyContactListStoreState, action: Action): any {
  return companyContactListStore.reducer(state, action);
}

@Injectable()
export class CompanyContactListStoreEffects extends companyContactListStore.effects {
  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }
}
