<nexnox-web-cardbox [indicatorNum]="(relatedTickets$ |async)?.length"
                    [isAccordion]="true"
                    [isAutoExpandOnIndicator]="true"
                    [isCollapsed]="true"
                    [title]="'tickets.subtitles.ticket-related' | translate"
                    [transparent]="true"
                    paddingClasses="px-3">
  <div class="d-flex flex-column pb-2">
    <div *ngFor="let ticket of relatedTickets$ | async"
         class="d-flex flex-row- mb-2">
      <fa-icon [fixedWidth]="true"
               [icon]="faEnvelope"
               class="mr-2"
               size="sm"></fa-icon>
      <a [nexnoxWebTenantRouterLink]="['tickets', ticket.ticketId]"
         class="btn-link"
         module="communication"
         target="_blank">
        {{ ticket.title }}
      </a>
    </div>

    <div *ngIf="!(relatedTickets$ | async)?.length" class="mb-2">{{ 'core-shared.shared.table.empty' | translate }}
    </div>
  </div>
</nexnox-web-cardbox>
