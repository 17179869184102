import {HeaderStoreEffects, headerStoreReducer} from './header';
import {combineReducers} from '@ngrx/store';

export * from './header';

export * from './gui.state';
export * from './gui.selectors';

export const coreGuiReducers = combineReducers({
  header: headerStoreReducer
});

export const coreGuiEffects = [
  HeaderStoreEffects
];
