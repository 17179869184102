import {FieldType} from '@ngx-formly/core';
import {Component, OnInit} from '@angular/core';
import * as countries from 'i18n-iso-countries';
import {Observable} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {map, startWith} from 'rxjs/operators';

interface Country {
  code: string;
  name: string;
}

interface Item {
  title: string;
  countries: Country[];
}

@Component({
  selector: 'nexnox-web-formly-ng-select-country',
  templateUrl: './formly-ng-select-country.component.html'
})
export class FormlyNgSelectCountryComponent extends FieldType implements OnInit {
  public items$: Observable<Item[]>;

  private recommendedCountries = [
    'DE',
    'AT',
    'CH'
  ];

  constructor(
    private translateService: TranslateService
  ) {
    super();
  }

  public ngOnInit(): void {
    const allItems$ = this.translateService.onLangChange.pipe(
      startWith(null),
      map(() => Object.keys(countries.getAlpha2Codes())),
      map(codes => codes.map(code => ({
        code,
        name: countries.getName(code, this.translateService.currentLang?.split('-')[0] ?? null)
      }))),
      map(items => items.sort((a, b) => a.name?.localeCompare(b.name)))
    );

    this.items$ = allItems$.pipe(
      map(allItems => [
        {
          title: 'core-shared.shared.select.recommended',
          countries: allItems.filter(country => this.recommendedCountries.includes(country.code))
        },
        {
          title: 'core-shared.shared.select.other',
          countries: allItems.filter(country => !this.recommendedCountries.includes(country.code))
        }
      ])
    );
  }
}
