import {
  PagedEntitiesXsStore,
  PagedEntitiesXsStoreCreateOneSuccessPayload,
  PagedEntitiesXsStoreState
} from '@nexnox-web/core-store';
import {AddressDto, AppEntityType} from '@nexnox-web/core-shared';
import {createSelector} from '@ngrx/store';
import {LocationAddressService} from '../../services';
import {selectLocationsState} from '../../locations.selectors';
import {Injectable, Injector} from '@angular/core';

export interface LocationAddressListStoreState extends PagedEntitiesXsStoreState<AddressDto> {
}

/* istanbul ignore next */
export const locationAddressListStore = new PagedEntitiesXsStore<AddressDto>({
  actionLabel: 'Core Portal - Master Data - Location - Address List',
  stateSelector: createSelector(selectLocationsState, state => state.locationAddressList),
  serviceType: LocationAddressService,
  entityType: AppEntityType.Address,
  selectId: address => address.entity?.addressId ?? address.model?.addressId
});

@Injectable()
export class LocationAddressListStoreEffects extends locationAddressListStore.effects {
  protected createWithParentIds = false;

  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }

  protected createOneSuccessActionCallback(payload: PagedEntitiesXsStoreCreateOneSuccessPayload<AddressDto, AddressDto>): void {
    super.createOneSuccessActionCallback(payload);

    this.store.dispatch(this.actions.addOneToParent({ id: payload.entity.addressId, parentIds: payload.parentIds }));
  }
}
