<p-sidebar #sidebarComponent
           (visibleChange)="onVisibleChange($event)"
           [transitionOptions]="transitionOptions"
           [visible]="visible$ | async"
           appendTo="body"
           position="right"
           styleClass="p-sidebar-md">
  <h4>{{ 'core-portal.settings.actions.templates.apply-text-template' | translate }}</h4>

  <div class="d-flex flex-grow-1 flex-column h-100 mt-1">
    <form [formGroup]="form" class="w-100">
      <formly-form (modelChange)="onModelChange($event)"
                   [fields]="fields"
                   [form]="form"
                   [model]="model"
                   class="row w-100">
      </formly-form>
    </form>
  </div>

  <div class="d-flex flex-shrink-0 justify-content-end pt-3">
    <button (click)="onApplyTemplate()"
            [disabled]="form.invalid || (hasContextType$ | async) === false"
            [icon]="faCheck | nexnoxWebFaIconStringPipe"
            [label]="'core-portal.settings.actions.templates.apply-text-template' | translate"
            [loadingIcon]="faSpinner | nexnoxWebFaIconStringPipe:'fa-spin'"
            [loading]="loading$ | async"
            class="p-button-primary ml-2"
            pButton
            type="button">
    </button>
  </div>
</p-sidebar>
