import {InjectionToken, NgModule} from '@angular/core';
import {ActionReducerMap, StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {addressesEffects, addressesReducers} from './store';

export const REDUCER_TOKEN = new InjectionToken<ActionReducerMap<any>>(
  '@nexnox-web/core-portal/master-data/addresses: Store Reducers'
);

@NgModule({
  imports: [
    StoreModule.forFeature('core-portal-feature-master-data-addresses', REDUCER_TOKEN),
    EffectsModule.forFeature(addressesEffects)
  ],
  providers: [
    {
      provide: REDUCER_TOKEN,
      useValue: addressesReducers
    }
  ]
})
export class AddressesStoreModule {
}
