import {
  BaseXsStore,
  BaseXsStoreReducerTypes,
  EmptyAction,
  EntityXsStore,
  EntityXsStoreActions,
  EntityXsStoreEffects,
  EntityXsStoreGetSuccessPayload,
  entityXsStoreSetLoadingForAction,
  EntityXsStoreState,
  PropsAction
} from '@nexnox-web/core-store';
import {
  AppEntityType,
  CoreSharedApiBaseService,
  InventoryNumberPatternDto,
  ReservedInventoryNumberRequestDto
} from '@nexnox-web/core-shared';
import {Action, createAction, props} from '@ngrx/store';
import {Injectable, Injector, Type} from '@angular/core';
import {createEffect, ofType} from '@ngrx/effects';
import {catchError, exhaustMap, map, tap} from 'rxjs/operators';
import {of} from 'rxjs';
import {immerOn} from 'ngrx-immer/store';
import {CorePortalFeatureInventoryNumberPatternService} from "../../services";
import {
  createInventoryNumberDetailXsStoreSelectors,
  InventoryNumberDetailXsStoreSelectors
} from "./inventory-number-detail-xs-store.selectors";

export interface InventoryNumberDetailXsStoreState extends EntityXsStoreState<InventoryNumberPatternDto> {
  reserveLoading: boolean;
  reserveLoaded: boolean;
}

export interface InventoryNumberDetailXsStoreActions extends EntityXsStoreActions<InventoryNumberPatternDto> {
  reserveInventoryNumber: PropsAction<ReservedInventoryNumberRequestDto>;
  reserveInventoryNumberSuccess: EmptyAction;
}

export class InventoryNumberDetailXsStore extends EntityXsStore<InventoryNumberPatternDto, InventoryNumberPatternDto, InventoryNumberDetailXsStoreState> {
  public actions: InventoryNumberDetailXsStoreActions;
  public selectors: InventoryNumberDetailXsStoreSelectors;

  public getInitialState(): InventoryNumberDetailXsStoreState {
    return {
      ...super.getInitialState(),
      reserveLoading: false,
      reserveLoaded: false
    };
  }

  protected createSelectors(): InventoryNumberDetailXsStoreSelectors {
    return createInventoryNumberDetailXsStoreSelectors(
      this.options.stateSelector,
      EntityXsStore.getSanitizeModelFn(this.options),
      EntityXsStore.getChangeCustomizers(this.options)
    );
  }

  protected createActions(label: string): InventoryNumberDetailXsStoreActions {
    return {
      ...super.createActions(label),

      reserveInventoryNumber: createAction(
        BaseXsStore.getType(label, 'Reserve'),
        props<ReservedInventoryNumberRequestDto>()
      ),
      reserveInventoryNumberSuccess: createAction(BaseXsStore.getType(label, 'Reserve success'))
    };
  }

  protected createReducerArray(
    initialState: InventoryNumberDetailXsStoreState
  ): BaseXsStoreReducerTypes<InventoryNumberDetailXsStoreState, InventoryNumberDetailXsStoreActions>[] {
    return [
      ...super.createReducerArray({ ...initialState }),

      immerOn(this.actions.reserveInventoryNumber, draft => {
        draft.entityData = entityXsStoreSetLoadingForAction(draft.entityData, {
          reserve: true
        });
        draft.reserveLoading = true;
        draft.reserveLoaded = false;
      }),
      immerOn(this.actions.reserveInventoryNumberSuccess, draft => {
        draft.entityData = entityXsStoreSetLoadingForAction(draft.entityData, {
          reserve: false
        });
        draft.reserveLoading = false;
        draft.reserveLoaded = true;
      })
    ];
  }

  protected createEffects(
    serviceType: Type<CoreSharedApiBaseService>,
    entityType: AppEntityType,
    prepareEntity: (entity: InventoryNumberPatternDto) => InventoryNumberPatternDto,
    prepareModel: (entity: InventoryNumberPatternDto, model: InventoryNumberPatternDto) => InventoryNumberPatternDto,
    sanitizeModel: (model: InventoryNumberPatternDto, entity: InventoryNumberPatternDto) => InventoryNumberPatternDto,
    ...args
  ): Type<EntityXsStoreEffects<InventoryNumberPatternDto, InventoryNumberPatternDto, InventoryNumberDetailXsStoreState>> {
    const actions = this.actions;
    const selectors = this.selectors;

    @Injectable()
    class Effects extends EntityXsStoreEffects<InventoryNumberPatternDto> {
      public reserveInventoryNumber$: any;
      public reserveInventoryNumberSuccess$: any;

      protected actions: InventoryNumberDetailXsStoreActions;
      protected service: CorePortalFeatureInventoryNumberPatternService;

      constructor(
        protected injector: Injector
      ) {
        super(injector, actions, selectors, serviceType, entityType, prepareEntity, prepareModel, sanitizeModel, false, false);
      }

      protected createEffects(): void {
        super.createEffects();

        this.reserveInventoryNumber$ = createEffect(() => this.actions$.pipe(
          ofType(this.actions.reserveInventoryNumber),
          exhaustMap((body) => this.service.reserveInventoryNumber(body).pipe(
            map(() => this.actions.reserveInventoryNumberSuccess()),
            catchError(error => of(this.actions.error({ error, action: this.actions.reserveInventoryNumber })))
          ))
        ));

        this.reserveInventoryNumberSuccess$ = createEffect(() => this.actions$.pipe(
          ofType(this.actions.reserveInventoryNumberSuccess),
          tap((action) => {
            this.actionCallback(action, false);
          })
        ), { dispatch: false });
      }

      protected actionCallback(action: Action, isError: boolean): void {
        super.actionCallback(action, isError);

        this.checkAction(this.actions.reserveInventoryNumberSuccess, action, () => this.reserveSuccessActionCallback());
      }

      protected getSuccessActionCallback(payload: EntityXsStoreGetSuccessPayload<ReservedInventoryNumberRequestDto, ReservedInventoryNumberRequestDto>): void {
        super.getSuccessActionCallback(payload);
      }

      protected reserveSuccessActionCallback(): void {
        this.apiNotificationService.showTranslatedSuccess('core-portal.settings.toasts.inventory-number-patterns.reserved');
      }
    }

    return Effects;
  }
}
