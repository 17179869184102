import {AppEntityType, AppRoleDto, CoreSharedApiBaseService} from '@nexnox-web/core-shared';
import {Inject, Injectable, Injector} from '@angular/core';
import {flatten, groupBy, keys, uniqBy} from 'lodash';
import {CORE_PORTAL_ROLES_CONFIG, CorePortalRolesConfig} from '../../../../../tokens';

// @ts-ignore
export interface LocalAppRoleDto extends AppRoleDto {
  packages?: {
    [key: string]: {
      [key: string]: boolean;
    }
  }
}

@Injectable()
export class CorePortalRoleService extends CoreSharedApiBaseService {
  private static rolesConfig: CorePortalRolesConfig[];

  constructor(
    protected injector: Injector,
    @Inject(CORE_PORTAL_ROLES_CONFIG) private rolesConfig: CorePortalRolesConfig[]
  ) {
    super(injector, null, 'role');

    CorePortalRoleService.rolesConfig = rolesConfig;
  }

  public static prepareEntity(
    entity: AppRoleDto,
    base: (entity: AppRoleDto) => AppRoleDto
  ): AppRoleDto {
    const excludedPackages = [20, 21, 330, 331, 340, 341];

    return {
      ...base(entity),
      packages: (entity?.packages ?? []).filter(x => !excludedPackages.includes(x.package))
    };
  }

  public static prepareModel(
    entity: AppRoleDto,
    model: LocalAppRoleDto,
    base: (entity: AppRoleDto, model: LocalAppRoleDto) => LocalAppRoleDto
  ): LocalAppRoleDto {
    const packages = uniqBy(entity?.packages ?? [], localPackage => localPackage.package);
    const appFunctions = {};

    for (const config of CorePortalRoleService.rolesConfig ?? []) {
      appFunctions[config.type] = config.permissions.map(x => x.value);
    }

    const packageGroups: any = groupBy(packages, localPackage => {
      const entityType = keys(appFunctions).find(x => appFunctions[x].includes(localPackage.package));
      return entityType ? entityType : AppEntityType.None.toString();
    });

    for (const packageGroup of keys(packageGroups)) {
      packageGroups[packageGroup] = {};

      if (appFunctions[packageGroup]) {
        for (const value of appFunctions[packageGroup]) {
          packageGroups[packageGroup][value.toString()] = packages.findIndex(x => x.package === value) > -1;
        }
      }
    }

    return {
      ...base(entity, model),
      packages: packageGroups
    } as LocalAppRoleDto;
  }

  public static sanitizeModel(
    model: LocalAppRoleDto,
    entity: AppRoleDto,
    base: (model: LocalAppRoleDto, entity: AppRoleDto) => AppRoleDto
  ): AppRoleDto {
    const packageEntityGroups = model?.packages ?? {};
    const packageGroups = flatten<any>(keys(packageEntityGroups).map(packageGroupKey => packageEntityGroups[packageGroupKey]));
    const packages = flatten(packageGroups.map(x => keys(x).filter(y => Boolean(x[y]))));

    return {
      ...base(model, entity),
      packages: packages.map(x => ({
        package: +x
      }))
    } as AppRoleDto;
  }
}
