<ng-template #entityEditTemplate let-title="title">
  <nexnox-web-settings-stereotypes-stereotype-edit #editComponent
                                                   (modelChange)="createModel$.next($event)"
                                                   [creating]="true"
                                                   [loading]="(loaded$ | async) === false"
                                                   [model]="createModel$ | async"
                                                   [module]="module"
                                                   [readonly]="false"
                                                   [stereotyped]="false"
                                                   [title]="title">
  </nexnox-web-settings-stereotypes-stereotype-edit>
</ng-template>

<nexnox-web-entity-overview #overviewComponent
                            [detailLink]="detailLink"
                            [entityEditTemplate]="entityEditTemplate"
                            [entityOverviewBaseComponent]="this"
                            [module]="module"
                            [stereotyped]="false">
</nexnox-web-entity-overview>
