import {Injector} from '@angular/core';
import {Action, ActionCreator, Store} from '@ngrx/store';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {ApiNotificationService} from '@nexnox-web/core-shared';
import {BaseXsStoreActions} from '@nexnox-web/core-store';
import {HttpErrorResponse} from '@angular/common/http';
import {tap} from 'rxjs/operators';

export abstract class BaseXsStoreEffects {
  public error$: any;

  protected store: Store<any>;
  protected actions$: Actions;
  protected apiNotificationService: ApiNotificationService;

  protected constructor(
    protected injector: Injector,
    protected actions: BaseXsStoreActions,
    protected selectors: any,
    createEffects: boolean = true
  ) {
    this.store = injector.get(Store) as Store<any>;
    this.actions$ = injector.get(Actions) as Actions;
    this.apiNotificationService = injector.get(ApiNotificationService);

    if (createEffects) {
      this.createEffects();
    }
  }

  protected createEffects(): void {
    this.error$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.error),
      tap(action => this.actionCallback(action, true))
    ), { dispatch: false });
  }

  protected actionCallback(action: Action, isError: boolean): void {
    this.checkAction(this.actions.error, action, ({ error, action: errorAction, suppressNotification }) =>
      this.errorActionCallback(action, error, errorAction, suppressNotification));

    if (isError) {
      return;
    }
  }

  protected errorActionCallback(
    action: Action, error: string | Error | HttpErrorResponse,
    errorAction?: Action,
    suppressNotification?: boolean
  ): void {
    if (!suppressNotification) this.apiNotificationService.handleApiError(error);
  }

  protected checkAction<PayloadType>(
    expectedAction: ActionCreator<string, (props: any) => PayloadType & Action>,
    action: Action,
    callback: (action: PayloadType & Action) => void
  ): void {
    if (action.type === expectedAction.type) {
      callback(action as PayloadType & Action);
    }
  }
}
