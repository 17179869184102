import {
  getInitialPagedEntitiesXsStoreState,
  PagedEntitiesXsStoreEntity,
  PagedEntitiesXsStoreState
} from './paged-entities-xs-store.state';
import {createPagedEntitiesXsStoreActions, PagedEntitiesXsStoreActions} from './paged-entities-xs-store.actions';
import {createEntityAdapter, EntityAdapter, IdSelector} from '@ngrx/entity';
import {Comparer} from '@ngrx/entity/src/models';
import {Injectable, Injector, Type} from '@angular/core';
import {PagedEntitiesXsStoreEffects} from './paged-entities-xs-store.effects';
import {AppEntityType, CoreSharedApiBaseService} from '@nexnox-web/core-shared';
import {createPagedEntitiesXsStoreReducer} from './paged-entities-xs-store.reducer';
import {createPagedEntitiesXsStoreSelectors, PagedEntitiesXsStoreSelectors} from './paged-entities-xs-store.selectors';
import {ModelXsStore, ModelXsStoreOptions} from '../model';
import {isUndefined} from 'lodash';
import {BaseXsStoreReducerTypes} from '../base';

export interface PagedEntitiesXsStoreOptions<E, M = E, S extends PagedEntitiesXsStoreState<E, M> = PagedEntitiesXsStoreState<E, M>>
  extends ModelXsStoreOptions<E, M, S> {
  selectId: IdSelector<PagedEntitiesXsStoreEntity<E, M>>;
  sortComparer?: Comparer<PagedEntitiesXsStoreEntity<E, M>> | false;
  defaultPageSize?: number;
  serviceType: Type<CoreSharedApiBaseService>;
  entityType?: AppEntityType;
  stereotyped?: boolean;
  removeTenantIdOnCreate?: boolean;
}

export class PagedEntitiesXsStore<E, M = E, S extends PagedEntitiesXsStoreState<E, M> = PagedEntitiesXsStoreState<E, M>>
  extends ModelXsStore<E, M> {
  public actions: PagedEntitiesXsStoreActions<E, M>;
  public effects: Type<PagedEntitiesXsStoreEffects<E, M, S>>;
  public selectors: PagedEntitiesXsStoreSelectors<E, M, S>;
  public adapter: EntityAdapter<PagedEntitiesXsStoreEntity<E, M>>;
  public serviceType: Type<CoreSharedApiBaseService>;

  constructor(
    protected options: PagedEntitiesXsStoreOptions<E, M, S>
  ) {
    super({
      ...options,
      createEffectsArgs: options.createEffectsArgs ?? [
        options.serviceType,
        options.entityType,
        PagedEntitiesXsStore.getPrepareEntityFn(options),
        PagedEntitiesXsStore.getPrepareModelFn(options),
        PagedEntitiesXsStore.getSanitizeModelFn(options)
      ]
    });

    this.adapter = this.createAdapter();
    this.serviceType = this.options.serviceType;
  }

  public getInitialState(): S {
    if (!this.adapter) {
      this.adapter = this.createAdapter();
    }

    return getInitialPagedEntitiesXsStoreState<E, M>(this.adapter) as S;
  }

  protected createActions(label: string): PagedEntitiesXsStoreActions<E, M> {
    return createPagedEntitiesXsStoreActions(label);
  }

  protected createReducerArray(initialState: S): BaseXsStoreReducerTypes<S, PagedEntitiesXsStoreActions<E, M>>[] | any[] {
    return [
      ...super.createReducerArray(initialState),
      ...createPagedEntitiesXsStoreReducer(this.actions, this.adapter, initialState)
    ];
  }

  protected createEffects(
    serviceType: Type<CoreSharedApiBaseService>,
    entityType: AppEntityType,
    prepareEntity: (entity: E) => E,
    prepareModel: (entity: E, model: M) => M,
    sanitizeModel: (model: M, entity: E) => E,
    ...args: any[]
  ): Type<PagedEntitiesXsStoreEffects<E, M, S>> {
    const actions = this.actions;
    const selectors = this.selectors;
    const stereotyped = !isUndefined(this.options.stereotyped) ? this.options.stereotyped : true;
    const options = this.options;

    @Injectable()
    class Effects extends PagedEntitiesXsStoreEffects<E, M, S> {
      constructor(
        protected injector: Injector
      ) {
        super(injector, actions, selectors, serviceType, entityType, prepareEntity, prepareModel, sanitizeModel, stereotyped, options);
      }
    }

    return Effects;
  }

  protected createSelectors(): PagedEntitiesXsStoreSelectors<E, M, S> {
    return {
      ...super.createSelectors(),
      ...createPagedEntitiesXsStoreSelectors(this.options.stateSelector, this.adapter)
    };
  }

  protected createAdapter(): EntityAdapter<PagedEntitiesXsStoreEntity<E, M>> {
    return this.adapter ?? createEntityAdapter({
      selectId: this.options.selectId,
      sortComparer: this.options.sortComparer ?? undefined
    });
  }
}
