import {ChangeDetectionStrategy, Component, EventEmitter, Injector, Input, OnInit, Output} from '@angular/core';
import {CorePortalEntityEditBaseComponent} from "@nexnox-web/libs/core-portal/src/lib/modules/entity";
import {
  AppEntityType,
  ContactDto,
  Filter,
  FilterKind,
  FilterOperators,
  FilterTypes,
  ResourceDto,
  StereotypeDto,
  TicketByLocationSkeletonDto,
  TicketsByLocationDefinitionDto
} from '@nexnox-web/core-shared';
import {FormlyFieldConfig} from "@ngx-formly/core";
import {cloneDeep, isEqual} from "lodash";
import {faPlus} from '@fortawesome/free-solid-svg-icons/faPlus';
import {
  CorePortalFormlyReadonlyTypes,
  CorePortalFormlyReadonlyTyping,
  CorePortalFormlyTranslatedTyping,
  CorePortalStereotypeService,
  FormlyTableLabelModes,
} from "@nexnox-web/core-portal";
import {Observable, of} from "rxjs";
import {distinctUntilChanged, map} from "rxjs/operators";
import {CorePortalFeatureMasterDataContactService} from "@nexnox-web/core-portal/features/master-data/features/contacts/src/lib/store/services/contact/contact.service";
import {CorePortalFeatureResourceService} from "@nexnox-web/core-portal/features/resources/src/lib/services/resource/resource.service";
import {CorePortalTicketSettingsPriorityService} from "@nexnox-web/core-portal/features/settings/features/stereotypes/src/lib/store/services/priority/priority.service";
import {CorePortalTicketSettingsStateService} from "@nexnox-web/core-portal/features/settings/features/stereotypes/src/lib/store/services/state/state.service";

@Component({
  selector: 'nexnox-web-settings-stereotypes-tickets-by-location-definition-edit',
  templateUrl: './tickets-by-location-stereotype-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TicketsByLocationStereotypeEditComponent extends CorePortalEntityEditBaseComponent<TicketsByLocationDefinitionDto> implements OnInit {

  @Input() public stereotypeId: number;
  @Output() public formTableValidityChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  public faPlus = faPlus;
  public skeletons$: Observable<TicketByLocationSkeletonDto[]>
  public labelModes = FormlyTableLabelModes;

  constructor(
    protected injector: Injector,
    public stereotypeService: CorePortalStereotypeService,
    private resourceService: CorePortalFeatureResourceService,
    private priorityService: CorePortalTicketSettingsPriorityService,
    private contactService: CorePortalFeatureMasterDataContactService,
    private stateService: CorePortalTicketSettingsStateService
  ) {
    super(injector, 'TicketsByLocationStereotypeEditComponent');
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.skeletons$ = this.modelSubject.asObservable().pipe(
      distinctUntilChanged((prev, curr) => isEqual(prev, curr)),
      map(model => model?.tickets)
    );
  }

  public onSkeletonsChange(skeletons: TicketByLocationSkeletonDto[]): void {
    const newModel = { ...this.model, tickets: cloneDeep(skeletons) };
    this.onModelChange(newModel);
  }

  /* istanbul ignore next */
  public createForm(): FormlyFieldConfig[] {
    return [
      { key: 'stereotypeId', defaultValue: this.stereotypeId },
      { key: 'tickets', defaultValue: [] },
      {
        key: 'title',
        type: 'input',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.title',
            validationMessages: {
              required: 'core-portal.core.validation.required',
            },
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.BASIC
          },
          required: true,
          type: 'text'
        },
      },
    ];
  }

  /* istanbul ignore next */
  public createSkeletonFields(): FormlyFieldConfig[] {
    return [
      { key: 'ticketSkeletonId', defaultValue: 0 },
      {
        key: 'title',
        type: 'input',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-4',
        templateOptions: {
          required: true,
          corePortalTranslated: {
            label: 'core-shared.shared.fields.title',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.BASIC
          } as CorePortalFormlyReadonlyTyping,
          type: 'text'
        }
      },
      {
        key: 'state',
        type: 'core-portal-entity-select',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-4',
        defaultValue: null,
        templateOptions: {
          required: true,
          corePortalTranslated: {
            label: 'tickets.fields.current-state',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.ENTITY,
            displayKey: 'name'
          } as CorePortalFormlyReadonlyTyping,
          entityService: this.stateService,
          idKey: 'stateId',
          displayKey: 'name',
          firstToDefault: true,
          clearable: false,
          wholeObject: true,
          skipGetOne: true
        }
      },
      {
        key: 'priority',
        type: 'core-portal-entity-select',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-4',
        defaultValue: null,
        templateOptions: {
          required: true,
          corePortalTranslated: {
            label: 'core-shared.shared.fields.priority',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.ENTITY,
            displayKey: 'name'
          } as CorePortalFormlyReadonlyTyping,
          entityService: this.priorityService,
          idKey: 'priorityId',
          displayKey: 'name',
          wholeObject: true,
          clearable: false,
          firstToDefault: true,
          skipGetOne: true
        }
      },
      {
        key: 'ticketStereotype',
        type: 'core-portal-entity-select',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-4',
        defaultValue: null,
        templateOptions: {
          required: true,
          corePortalTranslated: {
            label: 'core-shared.shared.fields.stereotype',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.ENTITY,
            displayKey: 'name',
            link: (stereotype: StereotypeDto) => stereotype?.stereotypeId ? ['stereotypes', stereotype.stereotypeId] : null,
            module: 'settings'
          } as CorePortalFormlyReadonlyTyping,
          entityService: this.stereotypeService,
          idKey: 'stereotypeId',
          displayKey: 'name',
          wholeObject: true,
          skipGetOne: true,
          clearable: true,
          defaultFilters$: of([{
            property: 'entityType',
            type: FilterTypes.DataTransferObject,
            operator: FilterOperators.Equal,
            value: AppEntityType.Ticket.toString()
          }] as Filter[]),
          module: 'settings'
        }
      },
      {
        key: 'resource',
        type: 'core-portal-entity-select',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-4',
        defaultValue: null,
        templateOptions: {
          corePortalTranslated: {
            label: 'tickets.fields.resource',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            },
          } as CorePortalFormlyTranslatedTyping,

          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.ENTITY,
            displayKey: 'name',
            link: (resource: ResourceDto) => resource?.resourceId ? ['/resources', resource.resourceId] : null,
            module: 'inventory',
            suffix: (model) => model?.resource?.location && this.creating ? `(${ model.resource.location.name })` : '',
          } as CorePortalFormlyReadonlyTyping,
          entityService: this.resourceService,
          idKey: 'resourceId',
          displayKey: 'name',
          wholeObject: true,
          skipGetOne: true
        }
      },
      {
        key: 'editor',
        type: 'core-portal-entity-select',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-4',
        defaultValue: null,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.editor'
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.ENTITY,
            displayKey: 'displayName',
            link: (contact: ContactDto) => contact?.contactId ? ['/masterdata', 'contacts', contact.contactId] : null,
            module: 'management'
          },
          entityService: this.contactService,
          idKey: 'contactId',
          displayKey: 'displayName',
          wholeObject: true,
          skipGetOne: true,
          defaultFilters$: of([{
            type: FilterTypes.DataTransferObject,
            operator: FilterOperators.Default,
            property: 'isFree',
            kind: FilterKind.Default,
            value: 'true'
          }] as Filter[])
        }
      }
    ];
  }
}
