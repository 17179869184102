import {Brands, Environment} from '@nexnox-web/core-shared';

export const environment: Environment = {
  production: false,
  title: 'semco ONE',
  version: '20250327.5',
  brand: Brands.Semco,
  apiUrl: {
    identity: 'https://one-dev.api.semco.app/identity',
    orga: 'https://one-dev.api.semco.app/orga',
    data: 'https://one-dev.api.semco.app/data',
    tech: 'https://one-dev.api.semco.app/tech'
  },
  google: {
    apiKey: 'AIzaSyBINOesU6mmaz46mq5tDVKlfUBCfKXnEnA'
  },
  defaultPageSize: 15,
  defaultLanguage: 'de-DE',
  defaultTimeout: 120000
};
