import {
  BaseXsStore,
  BaseXsStoreActions,
  BaseXsStoreReducerTypes,
  EmptyAction,
  PropsAction
} from '@nexnox-web/core-store';
import {DashboardXsStoreSetPayload} from './dashboard-xs-store.payloads';
import {createAction, createSelector, MemoizedSelector, MemoizedSelectorWithProps, on, props} from '@ngrx/store';
import {DashboardValueItem} from './dashboard-value-item.model';

export interface DashboardXsStoreState {
  items: DashboardValueItem[];
  loaded: boolean;
}

export interface DashboardXsStoreActions extends BaseXsStoreActions {
  set: PropsAction<DashboardXsStoreSetPayload>;
  clear: EmptyAction;
}

export interface DashboardXsStoreSelectors {
  selectItems: MemoizedSelector<DashboardXsStoreState, DashboardValueItem[]>;
  selectItem: MemoizedSelectorWithProps<DashboardXsStoreState, { position: number }, DashboardValueItem>;
  selectLoaded: MemoizedSelector<DashboardXsStoreState, boolean>;
}

export class DashboardXsStore extends BaseXsStore<DashboardXsStoreState> {
  public actions: DashboardXsStoreActions;
  public selectors: DashboardXsStoreSelectors;

  public getInitialState(): DashboardXsStoreState {
    return {
      ...super.getInitialState(),
      items: [],
      loaded: false
    };
  }

  public createActions(label: string): DashboardXsStoreActions {
    return {
      ...super.createActions(label),

      set: createAction(
        BaseXsStore.getType(label, 'Set'),
        props<DashboardXsStoreSetPayload>()
      ),
      clear: createAction(BaseXsStore.getType(label, 'Clear'))
    };
  }

  public createReducerArray(
    initialState: DashboardXsStoreState
  ): BaseXsStoreReducerTypes<DashboardXsStoreState, DashboardXsStoreActions>[] {
    return [
      ...super.createReducerArray(initialState),

      on(this.actions.set, (state, { items }) => ({ ...state, items, loaded: true })),
      on(this.actions.clear, () => initialState)
    ];
  }

  protected createSelectors(): DashboardXsStoreSelectors {
    const stateSelector = this.options.stateSelector;

    const selectItems = createSelector(stateSelector, state => state.items);

    return {
      ...super.createSelectors(),

      selectItems,
      selectItem: createSelector(selectItems, (items,
                                               { position }) => (items ?? []).find(x => x.position === position)),
      selectLoaded: createSelector(stateSelector, state => state.loaded)
    };
  }
}
