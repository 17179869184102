<ng-container *ngIf="cellValue$ | async as cellValue">
  <ng-container *ngIf="!column.hideByDefault || !cellValue.value; else hiddenByDefaultTemplate"
                [ngTemplateOutlet]="valueContainerTemplate">
  </ng-container>

  <ng-template #hiddenByDefaultTemplate>
    <p-inplace class="d-inline-flex align-items-center" styleClass="entity-datatable-cell-inplace">
      <ng-template pTemplate="display">
        <fa-icon [fixedWidth]="true" [icon]="faEye" class="mr-1" size="sm"></fa-icon>
        <span>{{ 'core-portal.core.general.show' | translate }}</span>
      </ng-template>

      <ng-template pTemplate="content">
        <ng-container [ngTemplateOutlet]="valueContainerTemplate"></ng-container>
      </ng-template>
    </p-inplace>
  </ng-template>

  <ng-template #valueContainerTemplate>
    <ng-container *ngIf="cellValue.link; else (isTitle ? titleTemplate : valueTemplate)"
                  [ngTemplateOutlet]="tenantLinkTemplate"></ng-container>
  </ng-template>

  <ng-template #tenantLinkTemplate>
    <a [fragment]="!column.clickable ? cellValue.fragment : undefined"
       [module]="!column.clickable ? cellValue.module : undefined"
       [nexnoxWebTenantRouterLink]="!column.clickable ? cellValue.link : undefined"
       [target]="!column.clickable ? '_blank' : undefined"
       [withoutModule]="!column.clickable ? cellValue.withoutModule : undefined"
       class="btn btn-link text-left d-flex align-items-center p-0">
      <ng-container *ngTemplateOutlet="valueTemplate"></ng-container>
    </a>
  </ng-template>

  <ng-template #titleTemplate>
    <a *ngIf="detailLink; else detailFnTemplate"
       [module]="module"
       [nexnoxWebTenantRouterLink]="!column.clickable ? [detailLink, row[idProp]] : undefined"
       class="btn btn-link text-left d-flex align-items-center p-0">
      <ng-container *ngTemplateOutlet="valueTemplate"></ng-container>
    </a>
  </ng-template>

  <ng-template #detailFnTemplate>
    <a (click)="detailFn(row)"
       *ngIf="detailFn; else valueTemplate"
       class="btn btn-link text-left d-flex align-items-center p-0">
      <ng-container *ngTemplateOutlet="valueTemplate"></ng-container>
    </a>
  </ng-template>

  <ng-template #valueTemplate>
    <ng-container *ngIf="cellValue.fullValue && !column.multiple"
                  [ngTemplateOutletContext]="{ $implicit: cellValue.fullValue }"
                  [ngTemplateOutlet]="prependIconTemplate">
    </ng-container>

    <ng-container *ngIf="!column.multiple; else multipleTemplate"
                  [ngTemplateOutletContext]="{ $implicit: cellValue }"
                  [ngTemplateOutlet]="cellValueTemplate">
    </ng-container>

    <ng-template #multipleTemplate>
      <div *ngFor="let item of cellValue.value; let isLast = last"
           [class.mr-1]="!isLast || column.separator"
           class="d-flex align-items-center">
        <ng-container *ngIf="item"
                      [ngTemplateOutletContext]="{ $implicit: item.fullValue }"
                      [ngTemplateOutlet]="prependIconTemplate">
        </ng-container>

        <ng-container [ngTemplateOutletContext]="{ $implicit: item }"
                      [ngTemplateOutlet]="cellValueTemplate">
        </ng-container>
        <span *ngIf="!isLast && column.separator">{{ column.separator }}</span>
      </div>
    </ng-template>

    <ng-template #cellValueTemplate let-value>
      <ng-container [ngSwitch]="column.type">

        <ng-container *ngSwitchCase="'link'">
          <a *ngIf="!column.clickable" [href]="cellValue.value"
             class="btn btn-link text-left d-flex align-items-center p-0"
             target="_blank">
            {{ cellValue.value }}
          </a>
        </ng-container>

        <ng-container *ngSwitchCase="'path'">
          <nexnox-web-entity-datatable-path-column [path]="value"></nexnox-web-entity-datatable-path-column>
        </ng-container>

        <ng-container *ngSwitchCase="'progressbar'">
          <div class="d-flex gap-2">
            <div style="min-width: 80px">
              <p-progressBar [showValue]="false" [value]="value.percent" color="var(--primary)"></p-progressBar>
            </div>
            <span>{{ value.count }} / {{ value.total }}</span>
          </div>

        </ng-container>

        <ng-container *ngSwitchCase="'currency'">
          <div class="text-right w-100">{{ +value.value | nexnoxWebLocaleStringPipe:2 }} €</div>
        </ng-container>

        <ng-container *ngSwitchCase="'phone'">
          <a href="tel:{{value.value}}">{{ value.value }}</a>
        </ng-container>

        <ng-container *ngSwitchCase="'html'">
          <span [innerHtml]="value.value"></span>
        </ng-container>

        <ng-container *ngSwitchDefault>
          <span *ngIf="!value.translated; else translatedValueTemplate">
            <span *ngIf="!value.date; else dateValueTemplate"> {{ value.value }} </span>
          </span>
        </ng-container>

        <ng-template #translatedValueTemplate>
          <span>{{ value.value | translate }}</span>
        </ng-template>

        <ng-template #dateValueTemplate>
          <span>{{ value.value | nexnoxWebBsDate:value.dateFormat | async }}</span>
        </ng-template>
      </ng-container>
    </ng-template>

    <ng-template #prependIconTemplate let-value>
      <fa-icon *ngIf="column.prependIcon && (column.prependIcon.show ? column.prependIcon.show(value) : true)"
               [class]="'d-flex ' + ('mr-' + (column.prependIcon.margin ?? 1))"
               [icon]="column.prependIcon.iconFn ? column.prependIcon.iconFn(value) : column.prependIcon.icon"
               [size]="column.prependIcon.size"
               [style.color]="getIconColor(value)">
      </fa-icon>
    </ng-template>
  </ng-template>

</ng-container>
