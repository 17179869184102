import {Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {CoreSharedSidebarBaseComponent} from '@nexnox-web/core-shared';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {IconDefinition} from "@fortawesome/fontawesome-common-types";
import {BehaviorSubject} from "rxjs";
import {faTimes, faUser} from '@fortawesome/free-solid-svg-icons';
import {ReceiverItemLocalDto} from "../receivers.model";
import {ReceiverField, ReceiverType} from "../receiver-type.enum";
import {
  CorePortalContactService,
  CorePortalFunctionService,
  CorePortalLocationGroupService,
  CorePortalLocationService,
  CorePortalReceiversService,
  noClosedLocationsFilter$
} from "libs/core-portal/src/lib/services";
import {ForcePasswordEvent} from "libs/core-portal/features/master-data/features/contacts/src/lib/sidebars";
import {isEqual} from "lodash";
import {CorePortalFormlyNgSelectTyping} from "@nexnox-web/libs/core-portal/src";

@Component({
  selector: 'nexnox-web-formly-receivers-sidebar',
  templateUrl: './formly-receivers-sidebar.component.html',
  styleUrls: [
    './formly-receivers-sidebar.component.scss',
    '../../../../tabs/components/tabs/tabs.component.scss'
  ]
})
export class FormlyReceiversSidebarComponent extends CoreSharedSidebarBaseComponent implements OnInit {
  @Input() public receiverTabs: { type: ReceiverType, shouldShow: boolean }[] = [];
  @ViewChild('locationSelectLabelTitleTemplate', { static: true }) public locationSelectLabelTitleTemplate: TemplateRef<any>;
  @ViewChild('locationSelectOptionTitleTemplate', { static: true }) public locationSelectOptionTitleTemplate: TemplateRef<any>;
  @Output() public accept: EventEmitter<void> = new EventEmitter();
  public receivers: { [tab: string]: ReceiverItemLocalDto[]; } = {};
  public loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public form: FormGroup;
  public model: object;
  public fields: FormlyFieldConfig[];
  public faUser: IconDefinition = faUser;
  public faTimes: IconDefinition = faTimes;
  @Input() private items: ReceiverItemLocalDto[] | any = [];
  private _currentTab: ReceiverType;

  constructor(
    private functionService: CorePortalFunctionService,
    private locationService: CorePortalLocationService,
    private locationGroupService: CorePortalLocationGroupService,
    private contactService: CorePortalContactService,
    private corePortalReceiversService: CorePortalReceiversService
  ) {
    super();
  }

  public get isRemoveButtonDisabled(): boolean {
    return Object.keys(this.receivers)?.length === 0;
  }

  public ngOnInit(): void {
    this.form = new FormGroup({});
    this.fields = this.createForm();
    this.receiverTabs = this.receiverTabs.filter(r => r.shouldShow);
    this._currentTab = this.receiverTabs[0]?.type
  }

  public getTranslationField(field: string): string {
    return `core-shared.shared.fields.${ ReceiverField[field].translationKey }`;
  }

  public updateTab(receiverType: ReceiverType): void {
    this._currentTab = receiverType;
  }

  public navigateTo(url: string[]): void {
    this.corePortalReceiversService.navigateToReceiver(url);
  }

  public onShow(): void {
    this._createReceivers();
    this.loading$.next(false);
    super.onShow();
  }

  public onHide(): void {
    super.onHide();
  }

  public onAssignReceivers(): void {
    this.loading$.next(true);
    this._currentTab = this.receiverTabs[0].type;
    this._updateItemsFromReceivers();
    this.accept.emit();
    this.onHide();
  }

  public onModelChange(event: any): void {
    if (event[ReceiverField[this._currentTab].translationKey]) {
      this.receivers[this._currentTab] =
        event[ReceiverField[this._currentTab].translationKey]
          .map((element: any) => this.corePortalReceiversService.mapEntity(element, this._currentTab));
    } else {
      delete this.receivers[this._currentTab];
    }
    this._updateModelFromReceivers();
  }

  public removeAll(): void {
    this._clearReceivers();
  }

  public removeOne(objToRemove: object, currentTab: string): void {
    if (this.receivers[currentTab].length === 1) {
      delete this.receivers[currentTab];
    } else {
      this.receivers[currentTab] =
        this.receivers[currentTab].filter(
          (obj: object) => !isEqual(obj, objToRemove));
    }
    this._updateModelFromReceivers();
  }

  private _resetModel(): void {
    this.model = {} as ForcePasswordEvent;
  }

  private _createReceivers(): void {
    this._clearReceivers();
    this.items.forEach((item: ReceiverItemLocalDto) => {
      this.receivers[item.type]?.length > 0 ?
        this.corePortalReceiversService.pushIfNotExists(this.receivers[item.type], item) :
        this.receivers[item.type] = [item];
    });
    this._updateModelFromReceivers();
  }

  private _clearReceivers(): void {
    this._resetModel();
    for (const prop in this.receivers) {
      if (this.receivers.hasOwnProperty(prop)) {
        delete this.receivers[prop];
      }
    }
  }

  private _updateModelFromReceivers(): void {
    this._resetModel();
    Object.keys(this.receivers)?.forEach((receiverType: ReceiverType) => {
      const receiverArray: ReceiverItemLocalDto[] = this.receivers[receiverType];
      if (receiverArray) {
        this.model[ReceiverField[receiverType].translationKey] =
          receiverArray.map((receiver: ReceiverItemLocalDto) => receiver.entityDto);
      } else {
        delete this.model[ReceiverField[receiverType].translationKey];
      }
    });
  }

  // Items are only used outside the sidebar, so they need to be updated when closing it
  private _updateItemsFromReceivers(): void {
    this.items?.splice(0, this.items.length);
    Object.keys(this.receivers).forEach((receiverType: ReceiverType) => {
      const receiverArray: ReceiverItemLocalDto[] = this.receivers[receiverType];
      if (receiverArray?.length > 0) {
        receiverArray.forEach((item: ReceiverItemLocalDto) => {
          this.items.push(item);
        });
      }
    });
  }

  private createForm(): FormlyFieldConfig[] {
    return this.receiverTabs.filter(receiver => receiver.shouldShow).map(receiver => {
      switch (receiver.type) {
        case ReceiverType.LOCATION_GROUPS:
          return {
            key: 'location-groups',
            type: 'core-portal-entity-select',
            wrappers: ['core-portal-translated'],
            className: 'col-md-12',
            defaultValue: null,
            templateOptions: {
              entityService: this.locationGroupService,
              idKey: 'locationGroupId',
              displayKey: 'name',
              wholeObject: true,
              multiple: true,
              showAll: false
            },
            hideExpression: () => this._currentTab !== ReceiverType.LOCATION_GROUPS
          };
        case ReceiverType.LOCATIONS:
          return {
            key: 'locations',
            type: 'core-portal-entity-select',
            wrappers: ['core-portal-translated'],
            className: 'col-md-12',
            defaultValue: null,
            templateOptions: {
              entityService: this.locationService,
              idKey: 'locationId',
              displayKey: 'name',
              wholeObject: true,
              multiple: true,
              showAll: false,
              mapSearchFilter: CorePortalLocationService.mapSearchFilter,
              search: CorePortalLocationService.searchCompare,
              selectLabelTitleTemplate: this.locationSelectLabelTitleTemplate,
              selectOptionTitleTemplate: this.locationSelectOptionTitleTemplate,
              defaultFilters$: noClosedLocationsFilter$
            },
            hideExpression: () => this._currentTab !== ReceiverType.LOCATIONS
          };
        case ReceiverType.FUNCTIONS:
          return {
            key: 'functions',
            type: 'core-portal-entity-select',
            wrappers: ['core-portal-translated'],
            className: 'col-md-12',
            defaultValue: null,
            templateOptions: {
              entityService: this.functionService,
              idKey: 'functionId',
              displayKey: 'name',
              wholeObject: true,
              multiple: true,
              showAll: false
            },
            hideExpression: () => this._currentTab !== ReceiverType.FUNCTIONS
          };
        case ReceiverType.CONTACTS:
          return {
            key: 'contacts',
            type: 'core-portal-entity-select',
            wrappers: ['core-portal-translated'],
            className: 'col-md-12',
            defaultValue: null,
            templateOptions: {
              entityService: this.contactService,
              idKey: 'contactId',
              displayKey: 'displayName',
              wholeObject: true,
              multiple: true,
              skipGetOne: true,
              showAll: false
            } as CorePortalFormlyNgSelectTyping,
            hideExpression: () => this._currentTab !== ReceiverType.CONTACTS
          };
        default:
          return {};
      }
    });
  }
}
