import {TechPortalFeatureTicketSettingsProcessService} from './services';
import {processDetailStore, processListStore} from './stores';

export * from './services';
export * from './stores';

export * from './processes.state';
export * from './processes.selectors';

export const processesReducers = {
  processList: processListStore.reducer,
  processDetail: processDetailStore.reducer
};

export const processesEffects = [
  processListStore.effects,
  processDetailStore.effects
];

export const processesServices = [
  TechPortalFeatureTicketSettingsProcessService
];
