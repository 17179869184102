import {ModuleWithProviders, NgModule} from '@angular/core';
import {CorePortalModule} from '@nexnox-web/core-portal';
import {CorePortalFeatureMasterDataLocationsModule} from '@nexnox-web/core-portal/features/master-data/features/locations';
import {CoreSharedSortableListModule} from '@nexnox-web/core-shared';
import {ButtonModule} from 'primeng/button';
import {ImageModule} from 'primeng/image';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {TechPortalLinksComponent, TechPortalSolutionMemberSelectComponent} from './components';
import {TechPortalExportByTemplateModalComponent} from './modals';
import {
  TechPortalContactService,
  TechPortalContractService,
  TechPortalIssueTemplateService,
  TechPortalMissionService,
  TechPortalResourceService,
  TechPortalTicketService
} from './services';
import {TECH_PORTAL_LINKS_CONFIG_TOKEN} from './tokens';

const exportedComponents = [
  TechPortalLinksComponent,
  TechPortalSolutionMemberSelectComponent
];

const exportedModals = [
  TechPortalExportByTemplateModalComponent
];

const apiServices = [
  TechPortalTicketService,
  TechPortalIssueTemplateService,
  TechPortalResourceService,
  TechPortalMissionService,
  TechPortalContractService,
  TechPortalContactService
];

@NgModule({
  declarations: [
    ...exportedComponents,
    ...exportedModals
  ],
  imports: [
    CorePortalModule,
    CoreSharedSortableListModule,
    ImageModule,
    ButtonModule,
    OverlayPanelModule,
    CorePortalFeatureMasterDataLocationsModule.forFeature()
  ],
  exports: [
    ...exportedComponents,
    ...exportedModals
  ],
  providers: [
    ...apiServices
  ]
})
export class TechPortalModule {
  public static forRoot(): ModuleWithProviders<TechPortalModule> {
    return {
      ngModule: TechPortalModule,
      providers: [
        {
          provide: TECH_PORTAL_LINKS_CONFIG_TOKEN,
          useValue: []
        }
      ]
    };
  }
}
