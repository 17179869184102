import {ChangeDetectionStrategy, Component, Injector, TemplateRef, ViewChild} from '@angular/core';
import {
  CorePortalEntityEditBaseComponent,
  CorePortalFormlyReadonlyTypes,
  CorePortalFormlyReadonlyTyping,
  CorePortalFormlyReceiverTyping,
  CorePortalFunctionService
} from '@nexnox-web/core-portal';
import {CrossCreationTypes, FunctionDto, InfoDto, OrgaAttachmentDto} from '@nexnox-web/core-shared';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {TranslateService} from '@ngx-translate/core';
import {OrgaPortalAttachmentsComponent} from '../../../../../../src/lib/components/attachments/attachments.component';
import {BehaviorSubject} from 'rxjs';
import {OrgaPortalFeatureCategoryService} from '../../../../../../features/categories';
import dayjs from 'dayjs';
import {infoStateEnumOptions} from "../../models";

@Component({
  selector: 'nexnox-web-infos-info-edit',
  templateUrl: './info-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrgaPortalFeatureInfoEditComponent extends CorePortalEntityEditBaseComponent<InfoDto> {

  @ViewChild('categorySelectLabelTitleTemplate', { static: true }) public categorySelectLabelTitleTemplate: TemplateRef<any>;
  @ViewChild('categorySelectOptionTitleTemplate', { static: true }) public categorySelectOptionTitleTemplate: TemplateRef<any>;

  @ViewChild('attachmentsComponent') public attachmentsComponent: OrgaPortalAttachmentsComponent;

  public attachmentsSubject: BehaviorSubject<OrgaAttachmentDto[]> = new BehaviorSubject<OrgaAttachmentDto[]>([]);

  constructor(
    protected injector: Injector,
    private translate: TranslateService,
    private functionService: CorePortalFunctionService,
    private categoryService: OrgaPortalFeatureCategoryService
  ) {
    super(injector, 'OrgaPortalFeatureInfoEditComponent');
  }

  public onAttachmentsChange(attachments: OrgaAttachmentDto[]): void {
    this.setModel({ ...this.model, attachments });
    setTimeout(() => this.onModelChange(this.model));
  }

  public onModelChange(model: InfoDto): void {
    this.modelValidSubject.next({
      ...this.modelValidSubject.getValue(),
      attachments: this.attachmentsComponent ? this.attachmentsComponent.isModelValid() : true
    });

    super.onModelChange(model);
  }

  /* istanbul ignore next */
  protected createForm(): FormlyFieldConfig[] {
    return [
      { key: 'contacts' },
      { key: 'functions' },
      { key: 'locations' },
      { key: 'locationGroups' },
      {
        key: 'title',
        type: 'input',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-12',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.title',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.BASIC
          } as CorePortalFormlyReadonlyTyping,
          type: 'text'
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        },
        hideExpression: () => !this.creating
      },
      {
        key: 'state',
        type: 'core-portal-ng-select',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-12',
        defaultValue: infoStateEnumOptions[0].value,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.current-state'
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.ENUM,
            enumOptions: infoStateEnumOptions,
            translate: true
          } as CorePortalFormlyReadonlyTyping,
          corePortalNgSelect: {
            items: infoStateEnumOptions,
            translate: true
          }
        },
        expressionProperties: {
          'templateOptions.readonly': () => true
        },
        hideExpression: () => this.creating,
      },
      {
        key: 'sender',
        type: 'core-portal-entity-select',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        defaultValue: null,
        templateOptions: {
          corePortalTranslated: {
            label: 'orga-portal.infos.fields.sender-function',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.ENTITY,
            displayKey: 'name',
            link: (functionProperty: FunctionDto) => functionProperty?.functionId ?
              ['/masterdata/functions', functionProperty.functionId] : null,
            module: 'management'
          } as CorePortalFormlyReadonlyTyping,
          entityService: this.functionService,
          idKey: 'functionId',
          displayKey: 'name',
          wholeObject: true,
          firstToDefault: false,
          skipGetOne: true,
          clearable: false,
          link: (functionProperty: FunctionDto) => functionProperty?.functionId ?
            ['/masterdata/functions', functionProperty.functionId] : null,
          module: 'management'
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        }
      },
      {
        key: 'categories',
        type: 'core-portal-entity-select',
        wrappers: ['core-portal-translated'],
        className: 'col-md-6',
        defaultValue: null,
        templateOptions: {
          corePortalTranslated: {
            label: 'orga-portal.infos.fields.categories'
          },
          entityService: this.categoryService,
          idKey: 'categoryId',
          displayKey: 'name',
          wholeObject: true,
          multiple: true,
          enableCrossCreation: CrossCreationTypes.CATEGORY,
          selectLabelTitleTemplate: this.categorySelectLabelTitleTemplate,
          selectOptionTitleTemplate: this.categorySelectOptionTitleTemplate,
          showAll: true
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly
        }
      },
      {
        key: 'receivers',
        type: 'core-portal-receivers',
        wrappers: ['core-portal-translated'],
        className: 'col-md-12',
        defaultValue: null,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.receivers'
          },
          corePortalReceivers: {
            model: this.modelSubject
          } as CorePortalFormlyReceiverTyping
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly
        }
      },
      {
        key: 'dateTime',
        type: 'core-portal-date-time-picker',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        defaultValue: dayjs().startOf('hour').format('YYYY-MM-DDTHH:mm:ssZ'),
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.date-time',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.DATE,
            format: 'L LT'
          } as CorePortalFormlyReadonlyTyping
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        }
      },
      {
        key: 'expiryDate',
        type: 'core-portal-date-time-picker',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        defaultValue: null,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.expiry-date',
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.DATE,
            format: 'L LT'
          } as CorePortalFormlyReadonlyTyping
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        }
      },
      {
        key: 'answerEnabled',
        type: 'core-portal-switch',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        templateOptions: {
          corePortalTranslated: {
            label: 'orga-portal.infos.fields.answer-enabled'
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.BOOLEAN
          } as CorePortalFormlyReadonlyTyping
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        }
      },
      {
        key: 'requestRead',
        type: 'core-portal-switch',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        templateOptions: {
          corePortalTranslated: {
            label: 'orga-portal.infos.fields.request-read'
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.BOOLEAN
          } as CorePortalFormlyReadonlyTyping
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        }
      },
      {
        key: 'content',
        type: 'core-portal-editor',
        wrappers: ['core-portal-translated'],
        className: 'col-md-12',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.content',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          corePortalEditor: {
            language: this.translate.currentLang
          }
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.disabled': () => this.readonly
        }
      }
    ];
  }

  protected setModel(model: InfoDto): void {
    super.setModel(model);

    this.attachmentsSubject.next(model?.attachments ?? []);
  }

  protected setReadonly(readonly: boolean): void {
    super.setReadonly(readonly);

    this.attachmentsComponent?.onReset();
  }
}
