import {EntityXsStore, EntityXsStoreState} from '@nexnox-web/core-store';
import {AppEntityType, ContractDto} from '@nexnox-web/core-shared';
import {Action, createSelector} from '@ngrx/store';
import {selectContractsState} from '../../contracts.selector';
import {TechPortalFeatureContractService} from '../../services';
import {Injectable, Injector} from '@angular/core';

export interface ContractDetailStoreState extends EntityXsStoreState<ContractDto> {
}

/* istanbul ignore next */
export const contractDetailStore = new EntityXsStore<ContractDto>({
  actionLabel: 'Tech Portal - Contracts - Contract Detail',
  stateSelector: createSelector(selectContractsState, state => state.contractDetail),
  serviceType: TechPortalFeatureContractService,
  entityType: AppEntityType.Contract
});

export function contractDetailStoreReducer(state: ContractDetailStoreState, action: Action): any {
  return contractDetailStore.reducer(state, action);
}

@Injectable()
export class ContractDetailStoreEffects extends contractDetailStore.effects {
  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }
}
