<nexnox-web-cardbox *ngIf="(resourceSubject.asObservable() | async)?.quickAccess?.link"
                    [noTitle]="true"
                    paddingClasses="p-3">
  <fa-icon [fixedWidth]="true" [icon]="faInfoCircle" class="mr-2"></fa-icon>
  <span [innerHtml]="'resources.descriptions.quick-access-link' | translate"></span>&nbsp;
  <span><a [href]="resourceSubject.value?.quickAccess?.link"
           target="_blank">{{ resourceSubject.value?.quickAccess?.link }}</a></span>
</nexnox-web-cardbox>

<nexnox-web-cardbox [loading]="loading" [title]="'resources.subtitles.quick-access-options' | translate"
                    paddingClasses="p-3">
  <form [formGroup]="form">
    <formly-form [(model)]="model"
                 [fields]="fields"
                 [form]="form"
                 class="row w-100">
    </formly-form>
  </form>
</nexnox-web-cardbox>
