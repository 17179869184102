import {AppEntityType} from '@nexnox-web/core-shared';

export const corePortalSettingsStereotypesEntityTypeOptions = [
  { value: AppEntityType.Ticket, label: 'core-shared.shared.entity-types.1' },
  { value: AppEntityType.Resource, label: 'core-shared.shared.entity-types.3' },
  { value: AppEntityType.Address, label: 'core-shared.shared.entity-types.4' },
  { value: AppEntityType.Company, label: 'core-shared.shared.entity-types.5' },
  { value: AppEntityType.Contact, label: 'core-shared.shared.entity-types.6' },
  { value: AppEntityType.Function, label: 'core-shared.shared.entity-types.7' },
  { value: AppEntityType.Location, label: 'core-shared.shared.entity-types.8' },
  { value: AppEntityType.LocationGroup, label: 'core-shared.shared.entity-types.11' },
  { value: AppEntityType.Mission, label: 'core-shared.shared.entity-types.20' },
  { value: AppEntityType.Contract, label: 'core-shared.shared.entity-types.21' },
  { value: AppEntityType.Article, label: 'core-shared.shared.entity-types.22' },
  { value: AppEntityType.MissionReport, label: 'core-shared.shared.entity-types.23' },
  { value: AppEntityType.Issue, label: 'core-shared.shared.entity-types.44' }
];
