import {NgModule} from '@angular/core';
import {CoreSharedModule} from '@nexnox-web/core-shared';
import {CorePortalEntityModule} from '@nexnox-web/core-portal';
import {articlesServices} from './store';
import {CorePortalFeatureArticleEditComponent} from './components';
import {ArticleDetailComponent, ArticleListComponent, ArticleUsagesListComponent} from './containers';

@NgModule({
  declarations: [
    CorePortalFeatureArticleEditComponent,

    ArticleListComponent,
    ArticleDetailComponent,

    ArticleUsagesListComponent
  ],
  imports: [
    CoreSharedModule,
    CorePortalEntityModule
  ],
  providers: [
    ...articlesServices
  ]
})
export class ArticlesDeclarationsModule {
}
