<tabset #tabsetComponent [class.is-transparent]="isTransparent">
  <tab (selectTab)="onSelectTab(tabTemplate)"
       *ngFor="let tabTemplate of tabTemplates; let isFirst = first; let isLast = last"
       [customClass]="(isLast ? 'is-last ' : '') + (tabTemplate.hide ? 'is-hidden' : '')"
       [disabled]="tabTemplate.hide"
       [id]="tabTemplate.tabId">
    <ng-template tabHeading>
      <span [class.has-error]="tabTemplate.hasError" class="heading">
        {{ tabTemplate.heading | translate }}

        <span *ngIf="tabTemplate.hasError" class="text-danger">*</span>
      </span>
    </ng-template>

    <ng-container
      *ngIf="tabTemplate.template && (tabTemplate.lazy ? (isTabActive(tabTemplate.tabId) || tabTemplate.wasActive) : true)"
      [ngTemplateOutlet]="tabTemplate.template">
    </ng-container>
  </tab>
</tabset>
