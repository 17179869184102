import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot, UrlSegment} from '@angular/router';
import {CoreSharedBreadcrumb} from '../models';
import {Observable, of} from 'rxjs';
import {template} from 'lodash';

export class CoreSharedBreadcrumbsResolver implements Resolve<CoreSharedBreadcrumb[]> {
  public resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<CoreSharedBreadcrumb[]> | Promise<CoreSharedBreadcrumb[]> | CoreSharedBreadcrumb[] {
    const data = route.routeConfig?.data;

    if (!data) {
      return [];
    }

    const path = this.getFullPath(route);

    let text = typeof (data.breadcrumbs) === 'string' ? data.breadcrumbs : data.breadcrumbs.text || data.text || path;
    const compiledText = template(text);
    text = compiledText(route.data);

    const crumbs: CoreSharedBreadcrumb[] = [{ text, path, permissions: [] }];

    return of(crumbs);
  }

  public getFullPath(route: ActivatedRouteSnapshot): string {
    const fullPath = (routes: ActivatedRouteSnapshot[]): string => routes.reduce((a,
                                                                                  v) => a += this.relativePath(v.url), '');

    return fullPath(route.pathFromRoot);
  }

  public getPathUntil(route: ActivatedRouteSnapshot, segment: string): string {
    const fullPath = (routes: ActivatedRouteSnapshot[]): string => routes.reduce((a,
                                                                                  v) => a += this.relativePath(v.url), '');
    const pathUntil = (name: string): string => fullPath(route.pathFromRoot.slice(0, route.pathFromRoot.findIndex(x => x.routeConfig?.path == name)));

    return pathUntil(segment);
  }

  private relativePath(segments: UrlSegment[]): string {
    return segments.reduce((a, v) => a += '/' + v.path, '');
  }
}
