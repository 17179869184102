import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {ConsumptionCodeDto} from '@nexnox-web/core-shared';
import {BindObservable} from 'bind-observable';
import {Observable} from 'rxjs';
import {map, mergeMap} from 'rxjs/operators';

@Component({
  selector: 'nexnox-web-connected-consumption-codes-consumption-code-external-id',
  templateUrl: './consumption-code-external-id.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TechPortalFeatureConsumptionCodeExternalIdComponent implements OnInit {
  @Input() @BindObservable() public consumptionCode: ConsumptionCodeDto;
  public consumptionCode$!: Observable<ConsumptionCodeDto>;

  @Input() public isLabel = true;
  @Input() public searchTerm: string;

  public title$: Observable<string>;
  public titleHtml$: Observable<string>;
  public titlePrefix$: Observable<string>;
  public titlePrefixHtml$: Observable<string>;

  public static prepareTitlePrefix(consumptionCode: ConsumptionCodeDto): string {
    return `${ consumptionCode.externalId }: `;
  }

  public static prepareTitle(consumptionCode: ConsumptionCodeDto, prefix: string): string {
    return `${ prefix }${ consumptionCode.displayName }`;
  }

  public ngOnInit(): void {
    this.titlePrefix$ = this.consumptionCode$.pipe(
      map(consumptionCode => TechPortalFeatureConsumptionCodeExternalIdComponent.prepareTitlePrefix(consumptionCode))
    );

    this.titlePrefixHtml$ = this.titlePrefix$.pipe(
      map(prefix => `<span class="text-muted">${ prefix }</span>`)
    );

    this.title$ = this.consumptionCode$.pipe(
      mergeMap(consumptionCode => this.titlePrefix$.pipe(
        map(prefix => TechPortalFeatureConsumptionCodeExternalIdComponent.prepareTitle(consumptionCode, prefix))
      ))
    );

    this.titleHtml$ = this.consumptionCode$.pipe(
      mergeMap(consumptionCode => this.titlePrefixHtml$.pipe(
        map(prefix => TechPortalFeatureConsumptionCodeExternalIdComponent.prepareTitle(consumptionCode, prefix))
      ))
    );
  }
}
