<div class="overflow-auto pr-1 w-100 h-100">
  <ng-container *ngIf="(loading$ | async) === false; else loadingTemplate">
    <ng-container *ngIf="entity$ | async as entity">

      <!-- in progress warning -->
      <div *ngIf="entity.isInProgressSince"
           class="alert alert-warning d-flex align-items-center">
        <fa-icon [fixedWidth]="true" [icon]="faExclamationTriangle" class="mr-2"></fa-icon>
        {{ 'resources.subtitles.resource-in-progress' | translate:{ since: (entity.isInProgressSince | nexnoxWebDayjsRelative:true | async) } }}

        <button (click)="onRefresh()" class="btn btn-sm ml-auto text-white" type="button">
          <fa-icon [fixedWidth]="true" [icon]="faSync"></fa-icon>
        </button>
      </div>

      <!-- in safe warning -->
      <div *ngIf="entity?.safe"
           class="alert alert-warning d-flex align-items-center justify-space-between">
        <fa-icon [fixedWidth]="true" [icon]="faExclamationTriangle" class="mr-2"></fa-icon>
        <div
          class="w-100 flex-grow-1">{{ 'resources.descriptions.resource-in-safe' | translate:{ contacts: (safeContacts$ | async) } }}
        </div>
        <button (click)="resourceSafeSidebar.onShow(false)"
                [icon]="faPencil | nexnoxWebFaIconStringPipe"
                [loadingIcon]="faSpinner | nexnoxWebFaIconStringPipe: 'fa-spin'"
                [loading]="loading$ | async"
                class="p-button-rounded p-button-secondary p-button-text ml-2 self"
                pButton
                style="color:white"
                type="button">
        </button>
      </div>

      <!-- virtuality warning -->
      <div *ngIf="entity?.type === resourceTypes.Virtual"
           class="alert alert-warning d-flex align-items-center">
        <fa-icon [fixedWidth]="true" [icon]="faBoxes" class="mr-2"></fa-icon>
        <div>{{ 'resources.descriptions.virtual-resource-auto' | translate }}</div>
        <div>
          &nbsp;
          <a [nexnoxWebTenantRouterLink]="'/resources/'+entity?.parent?.resourceId"
             module="inventory"
             target="_blank">
            {{ entity?.parent?.name }}
          </a>
        </div>
      </div>
    </ng-container>

    <ng-template #entityEditTemplate let-id="id" let-title="title">
      <nexnox-web-resources-resource-edit #modelComponent
                                          (modelChange)="onModelChange($event)"
                                          [creating]="false"
                                          [id]="id"
                                          [loading]="loading$ | async"
                                          [model]="model$ | async"
                                          [readonly]="(readonly$ | async) || !!(entity$ | async)?.isInProgressSince"
                                          [stereotypes$]="stereotypes$"
                                          [title]="title">
      </nexnox-web-resources-resource-edit>
    </ng-template>

    <nexnox-web-entity-detail [entityDetailBaseComponent]="this"
                              [entityEditTemplate]="entityEditTemplate"
                              [tabHeading]="title | translate"
                              [useTabs]="false">
    </nexnox-web-entity-detail>
  </ng-container>

  <ng-template #loadingTemplate>
    <div class="cardbox px-3 pt-4 pb-3">
      <div class="row">
        <div class="col-12 d-flex justify-content-between">
          <ngx-skeleton-loader [theme]="{
            width: '250px',
            height: '28px',
            margin: '0'
          }"></ngx-skeleton-loader>
        </div>
      </div>

      <div class="row mt-4">
        <div class="col-12">
          <ngx-skeleton-loader [theme]="{
            height: '20px',
            width: '90%'
          }"></ngx-skeleton-loader>
          <ngx-skeleton-loader [theme]="{
            height: '20px',
            width: '60%'
          }"></ngx-skeleton-loader>
          <ngx-skeleton-loader [theme]="{
            height: '20px'
          }"></ngx-skeleton-loader>
          <ngx-skeleton-loader [theme]="{
            height: '20px',
            width: '90%'
          }"></ngx-skeleton-loader>
          <ngx-skeleton-loader [theme]="{
            height: '20px',
            width: '30%'
          }"></ngx-skeleton-loader>
          <br><br>
          <ngx-skeleton-loader [theme]="{
            height: '20px',
            width: '50%'
          }"></ngx-skeleton-loader>
        </div>
      </div>
    </div>
  </ng-template>
</div>

<nexnox-web-resource-safe-sidebar #resourceSafeSidebar
                                  (putInSafe)="onPutInSafe($event)"
                                  (removeFromSafe)="onRemoveFromSafe($event)"
                                  [resource]="model$ | async">
</nexnox-web-resource-safe-sidebar>

<nexnox-web-resource-change-state-sidebar #changeStateSidebar
                                          (accept)="onChangeState($event)">
</nexnox-web-resource-change-state-sidebar>

<nexnox-web-resource-event-list-sidebar #resourceEventListSidebar
                                        [parentId$]="resourceId$">
</nexnox-web-resource-event-list-sidebar>
