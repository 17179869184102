import {AppEntityType, ErrorCodeDto} from '@nexnox-web/core-shared';
import {PagedEntitiesXsStore, PagedEntitiesXsStoreState} from '@nexnox-web/core-store';
import {createSelector} from '@ngrx/store';
import {selectErrorCodesState} from '../../error-codes.selectors';
import {TechPortalFeatureConnectedErrorCodeService} from '../../services';

export interface ErrorCodeListStoreState extends PagedEntitiesXsStoreState<ErrorCodeDto> {
}

/* istanbul ignore next */
export const errorCodeListStore = new PagedEntitiesXsStore<ErrorCodeDto>({
  actionLabel: 'Tech Portal - Connect - Error Codes - Error Code List',
  stateSelector: createSelector(selectErrorCodesState, state => state.errorCodeList),
  serviceType: TechPortalFeatureConnectedErrorCodeService,
  entityType: AppEntityType.None,
  selectId: errorCode => errorCode.entity?.errorCodeId ?? errorCode.model?.errorCodeId,
  stereotyped: false
});
