import {getInitialTextTemplatesState, TextTemplatesState} from './stores/text-templates';
import {DocumentTemplatesState, getInitialDocumentTemplatesState} from './stores/document-templates';

export interface TemplatesState {
  textTemplates: TextTemplatesState;
  documentTemplates: DocumentTemplatesState;
}

export const getInitialTemplatesState = (): TemplatesState => ({
  textTemplates: getInitialTextTemplatesState(),
  documentTemplates: getInitialDocumentTemplatesState()
});
