import {PagedEntitiesXsStore, PagedEntitiesXsStoreState} from '@nexnox-web/core-store';
import {AppEntityType, ProductSubscriptionDto} from '@nexnox-web/core-shared';
import {createSelector} from '@ngrx/store';
import {selectTenantsState} from '../../tenants.selectors';
import {ProductSubscriptionService} from '../../services';

export interface TenantSubscriptionListStoreState extends PagedEntitiesXsStoreState<ProductSubscriptionDto> {
}

/* istanbul ignore next */
export const tenantSubscriptionListStore = new PagedEntitiesXsStore<ProductSubscriptionDto>({
  actionLabel: 'Core Portal - Service - Tenants - Tenant Subscription List',
  stateSelector: createSelector(selectTenantsState, state => state.tenantSubscriptionList),
  serviceType: ProductSubscriptionService,
  entityType: AppEntityType.None,
  selectId: sub => sub.entity?.productSubscriptionId ?? sub.model?.productSubscriptionId,
  stereotyped: false,
  removeTenantIdOnCreate: true
});
