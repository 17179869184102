import {ChangeDetectionStrategy, Component, Injector} from '@angular/core';
import {
  ActionButton,
  CorePortalEntityOverviewBaseComponent,
  DatatableActionButton,
  DeleteEntityModel
} from '@nexnox-web/core-portal';
import {AppEntityType, AppPermissions, DashboardDto, ExportType, Mappers} from '@nexnox-web/core-shared';
import {dashboardListStore} from '../../store/stores';

@Component({
  selector: 'nexnox-web-settings-dashboards-dashboard-list',
  templateUrl: './dashboard-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardListComponent extends CorePortalEntityOverviewBaseComponent<DashboardDto> {
  public title = 'core-portal.settings.subtitles.dashboard-list';
  public createTitle = 'core-portal.settings.subtitles.dashboard-create';
  public idProperty = 'dashboardId';
  public displayProperty = 'name';
  public datatableConfigName = 'SettingsDashboardDefault';
  public componentId = 'DashboardListComponent';

  constructor(
    protected injector: Injector
  ) {
    super(injector, dashboardListStore, Mappers.DashboardListDto.serializedName, AppEntityType.None);
  }

  public getRowActionButtons(): DatatableActionButton[] {
    return [
      ...this.getDefaultRowActionButtons(
        'core-portal.settings.actions.edit-dashboard',
        (row: DashboardDto) => `dashboards/${ row.dashboardId }`,
        [AppPermissions.UpdateDashboard],
        {
          module: 'settings'
        }
      ),
      this.exportService.getRowActionExportButton(ExportType.DASHBOARD, this.idProperty)
    ]
  }

  public getDeleteEntityModel(): DeleteEntityModel {
    return {
      titleKey: 'core-portal.settings.actions.delete-dashboard',
      descriptionKey: 'core-portal.settings.descriptions.delete-dashboard',
      confirmKey: 'core-portal.settings.actions.delete-dashboard',
      deletePermission: AppPermissions.DeleteDashboard
    };
  }

  protected async getActionButtons(): Promise<ActionButton[]> {
    return this.getDefaultActionButtons('core-portal.settings.actions.create-dashboard', AppPermissions.CreateDashboard);
  }
}
