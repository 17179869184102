<nexnox-web-entity-edit [entityEditBaseComponent]="this"
                        [stereotyped]="false"
                        displayKey="title">
</nexnox-web-entity-edit>

<nexnox-web-forms-form-rows-edit #formRowsEditComponent
                                 (rowsChange)="onRowsChange($event)"
                                 [loading]="loading"
                                 [readonly]="readonly"
                                 [rows$]="rowsSubject">
</nexnox-web-forms-form-rows-edit>
