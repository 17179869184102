import {ModuleWithProviders, NgModule} from '@angular/core';
import {HolidaysDeclarationsModule} from './holidays-declarations.module';
import {HolidaysRoutingModule} from './holidays-routing.module';
import {HolidaysStoreModule} from './holidays-store.module';

@NgModule({
  imports: [
    HolidaysDeclarationsModule,
    HolidaysRoutingModule,
    HolidaysStoreModule
  ]
})
export class CorePortalFeatureMasterDataHolidaysModule {
  public static forFeature(): ModuleWithProviders<HolidaysDeclarationsModule> {
    return { ngModule: HolidaysDeclarationsModule };
  }
}
