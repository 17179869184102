import {ChangeDetectionStrategy, Component, Inject, Injector, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {
  CORE_PORTAL_DASHBOARD_CONFIG,
  CorePortalDashboardConfig,
  CorePortalDatatableService,
  CorePortalEntityEditBaseComponent,
  CorePortalEntitySelectOptions,
  CorePortalFormlyReceiverTyping
} from '@nexnox-web/core-portal';
import {
  CoreSharedSortableListItem,
  DashboardDto,
  DashboardItemDto,
  DashboardItemTypes,
  DashboardTableReferenceDto,
  DataTableDto
} from '@nexnox-web/core-shared';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {map} from 'rxjs/operators';
import {isUndefined, uniq} from 'lodash';

@Component({
  selector: 'nexnox-web-settings-dashboards-dashboard-edit',
  templateUrl: './dashboard-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CorePortalFeatureSettingsDashboardEditComponent extends CorePortalEntityEditBaseComponent<DashboardDto> implements OnInit {
  @ViewChild('selectOptionTemplate', { static: true }) public selectOptionTemplate: TemplateRef<any>;

  @ViewChild('locationSelectLabelTitleTemplate', { static: true }) public locationSelectLabelTitleTemplate: TemplateRef<any>;
  @ViewChild('locationSelectOptionTitleTemplate', { static: true }) public locationSelectOptionTitleTemplate: TemplateRef<any>;

  public selectedItem: DataTableDto = null;
  public selectOptions: CorePortalEntitySelectOptions;

  public items$: Observable<CoreSharedSortableListItem[]>;

  private itemsSubject: BehaviorSubject<DashboardItemDto[]> = new BehaviorSubject<DashboardItemDto[]>([]);
  private refreshSelectSubject: Subject<void> = new Subject<void>();

  constructor(
    protected injector: Injector,
    private datatableService: CorePortalDatatableService,
    @Inject(CORE_PORTAL_DASHBOARD_CONFIG) public dashboardConfig: CorePortalDashboardConfig,
  ) {
    super(injector);
  }

  public ngOnInit(): void {
    super.ngOnInit();

    // ToDo: Won't work in the future if there are more entity types
    this.selectOptions = {
      idKey: 'dataTableId',
      displayKey: 'name',
      entityService: this.datatableService,
      wholeObject: true,
      clearable: true,
      excludedIds: [],
      refresh$: this.refreshSelectSubject.asObservable(),
      selectOptionTemplate: this.selectOptionTemplate
    };

    this.items$ = this.itemsSubject.asObservable().pipe(
      map(items => this.mapItemsToAccordionItems(items))
    );

    this.subscribe(this.items$.pipe(
      map(items => uniq(items.map(item => {
        const externalData: DashboardItemDto = item.getExternalData();

        if (externalData.type === DashboardItemTypes.DataTable) {
          return (externalData as DashboardTableReferenceDto).dataTableId;
        }

        return null;
      }).filter(x => Boolean(x))))
    ), ids => {
      this.selectOptions = {
        ...this.selectOptions,
        excludedIds: ids
      };
    });
  }

  public onItemsChange(items: CoreSharedSortableListItem[]): void {
    this.itemsSubject.next((items ?? []).map(item => ({
      ...item.getExternalData(),
      position: item.position
    })));
    this.onModelChange({ ...this.model, items: this.itemsSubject.getValue() });
    this.refreshSelectSubject.next();
  }

  public onAddItem(): void {
    this.itemsSubject.next([
      ...this.itemsSubject.getValue(),
      {
        position: this.itemsSubject.getValue().length,
        type: DashboardItemTypes.DataTable,
        dataTableId: this.selectedItem.dataTableId,
        dashboardId: this.model.dashboardId,
        dataTable: this.selectedItem
      } as DashboardTableReferenceDto
    ]);
    this.onModelChange({ ...this.model, items: this.itemsSubject.getValue() });
    this.refreshSelectSubject.next();
  }

  /* istanbul ignore next */
  protected createForm(): FormlyFieldConfig[] {
    return [
      { key: 'contacts' },
      { key: 'functions' },
      { key: 'locations' },
      { key: 'locationGroups' },
      {
        key: 'name',
        type: 'input',
        wrappers: ['core-portal-translated'],
        className: 'col-md-12',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.name',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          type: 'text'
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.disabled': () => this.readonly
        }
      },
      {
        key: 'receivers',
        type: 'core-portal-receivers',
        wrappers: ['core-portal-translated'],
        className: 'col-md-12',
        defaultValue: null,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.receivers'
          },
          corePortalReceivers: {
            model: this.modelSubject
          } as CorePortalFormlyReceiverTyping
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly
        }
      }
    ];
  }

  protected setModel(model: DashboardDto): void {
    super.setModel(model);
    this.itemsSubject.next(model?.items ?? []);
  }

  private mapItemsToAccordionItems(items: DashboardItemDto[]): CoreSharedSortableListItem[] {
    return (items ?? []).map(item => {
      let title = `${ item.type }-${ item.position }`;

      if (item.type === DashboardItemTypes.DataTable) {
        title = (item as DashboardTableReferenceDto).dataTable.name;
      }

      return {
        title,
        position: item.position,
        getExternalData: () => item
      };
    });
  }

  private isDatatableItem(item: DashboardItemDto): item is DashboardTableReferenceDto {
    return !isUndefined((item as DashboardTableReferenceDto).dataTableId);
  }
}
