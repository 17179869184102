<nexnox-web-tabs [internal]="true"
                 [isTransparent]="true"
                 startTab="functions">
  <!-- Functions -->
  <ng-template [heading]="'core-shared.shared.fields.functions' | translate"
               nexnoxWebTabTemplate
               tabId="functions">
    <nexnox-web-paths-path-progress-function-list (openSidebar)="openSidebar($event)"
                                                  [canCreate]="false"
                                                  [getEntityOnModeChange]="false"
                                                  [isEmbedded]="true"
                                                  [parentIds]="parentIds">
    </nexnox-web-paths-path-progress-function-list>
  </ng-template>

  <!-- Locations -->
  <ng-template [heading]="'core-shared.shared.fields.locations' | translate"
               nexnoxWebTabTemplate
               tabId="locations">
    <nexnox-web-paths-path-progress-location-list (openSidebar)="openSidebar($event)"
                                                  [canCreate]="false"
                                                  [getEntityOnModeChange]="false"
                                                  [isEmbedded]="true"
                                                  [parentIds]="parentIds">
    </nexnox-web-paths-path-progress-location-list>
  </ng-template>

  <!-- Contacts -->
  <ng-template [heading]="'core-shared.shared.fields.contacts' | translate"
               nexnoxWebTabTemplate
               tabId="contacts">
    <nexnox-web-paths-path-progress-contact-list [canCreate]="false"
                                                 [getEntityOnModeChange]="false"
                                                 [isRemoveOfExpectedColumn]="true"
                                                 [parentIds]="parentIds">
    </nexnox-web-paths-path-progress-contact-list>
  </ng-template>

</nexnox-web-tabs>

<nexnox-web-paths-progress-sidebar #progressSidebarComponent
                                   [parentIds]="parentIds">
</nexnox-web-paths-progress-sidebar>
