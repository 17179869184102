import {ChangeDetectionStrategy, Component, Injector} from '@angular/core';
import {
  ActionButton,
  CorePortalEntityOverviewBaseComponent,
  DatatableActionButton,
  DeleteEntityModel
} from '@nexnox-web/core-portal';
import {AppEntityType, AppPermissions, ContractDto, ControllerOperationId, Mappers} from '@nexnox-web/core-shared';
import {contractListStore} from '../../store/stores';

@Component({
  selector: 'nexnox-web-contracts-contract-list',
  templateUrl: './contract-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TechPortalFeatureContractListComponent extends CorePortalEntityOverviewBaseComponent<ContractDto> {
  public title = 'contracts.subtitles.contract-list';
  public createTitle = 'contracts.subtitles.contract-create';
  public idProperty = 'contractId';
  public displayProperty = 'title';
  public pageOperation = ControllerOperationId.ContractControllerList;
  public componentId = 'ContractListComponent';
  public enableViews = true;
  public datatableConfigName = 'ContractDefault';

  constructor(
    protected injector: Injector
  ) {
    super(injector, contractListStore, Mappers.ContractListDto.serializedName, AppEntityType.Contract);
  }

  /* istanbul ignore next */
  public getRowActionButtons(): DatatableActionButton[] {
    return this.getDefaultRowActionButtons(
      'contracts.actions.edit-contract',
      (row: ContractDto) => `/contracts/${ row.contractId }`,
      [AppPermissions.UpdateContract],
      {
        module: 'inventory'
      }
    );
  }

  public getDeleteEntityModel(): DeleteEntityModel {
    return {
      titleKey: 'contracts.actions.delete-contract',
      descriptionKey: 'contracts.descriptions.delete-contract',
      confirmKey: 'contracts.actions.delete-contract',
      deletePermission: AppPermissions.DeleteContract
    };
  }

  protected async getActionButtons(): Promise<ActionButton[]> {
    return this.getDefaultActionButtons('contracts.actions.create-contract', AppPermissions.CreateContract);
  }
}
