import {DashboardItemDatatableStore} from '@nexnox-web/core-portal/features/dashboard';
import {AppEntityType, ContactSimpleDto, MissionDto, MissionState} from '@nexnox-web/core-shared';
import produce from 'immer';
import {pagedEntitiesXsStoreSetLoadingForId} from '@nexnox-web/core-store';
import {TechPortalFeatureMissionService} from '../../store';
import {select, Store} from '@ngrx/store';
import {authStore, CorePortalDashboardItemDatatableConfig} from '@nexnox-web/core-portal';
import {Injector} from '@angular/core';
import {firstValueFrom} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';

export class TechPortalFeatureDashboardMissionDatatableStore extends DashboardItemDatatableStore<MissionDto> {
  protected entityService: TechPortalFeatureMissionService;
  protected store: Store;
  protected translate: TranslateService;

  constructor(
    protected injector: Injector,
    protected dashboardConfig: CorePortalDashboardItemDatatableConfig
  ) {
    super(
      injector,
      'missionId',
      TechPortalFeatureMissionService,
      AppEntityType.Mission,
      [],
      dashboardConfig
    );

    this.store = this.injector.get(Store);
    this.translate = this.injector.get(TranslateService);
  }

  public assignTo(id: number, contact: ContactSimpleDto, parentIds?: Array<number | string>): void {
    this.entityService.assignTo(id, contact.contactId, parentIds).toPromise()
      .then(() => this.assignToSuccess(id, contact))
      .catch(error => this.handleError(error));

    this.setState(produce(this.getState(), draft => {
      draft.entityData = pagedEntitiesXsStoreSetLoadingForId(draft.entityData, id, {
        assignTo: true
      });
    }), 'ASSIGN_TO');
  }

  public assignToSuccess(id: number, contact: ContactSimpleDto): void {
    this.apiNotificationService.showTranslatedSuccess('missions.toasts.assign-to', { contact: contact.displayName });

    const state = this.getState();
    this.setState(this.adapter.updateOne({
      id: id.toString(),
      changes: {
        entity: {
          ...state.entities[id.toString()].entity,
          solutionContact: contact
        },
        model: {
          ...state.entities[id.toString()].model,
          solutionContact: contact
        }
      }
    }, {
      ...state,
      entityData: pagedEntitiesXsStoreSetLoadingForId(state.entityData, id, {
        assignTo: false
      })
    }), 'ASSIGN_TO_SUCCESS');
  }

  public async assignToMe(id: number, parentIds?: Array<number | string>): Promise<void> {
    const activeTenant = await firstValueFrom(this.store.pipe(select(authStore.selectors.selectActiveTenant)));

    this.entityService.assignToMe(id, parentIds).toPromise()
      .then(() => this.assignToMeSuccess(id, (activeTenant?.names ?? []).length ? activeTenant.names[0] : null))
      .catch(error => this.handleError(error));

    this.setState(produce(this.getState(), draft => {
      draft.entityData = pagedEntitiesXsStoreSetLoadingForId(draft.entityData, id, {
        assignToMe: true
      });
    }), 'ASSIGN_TO_ME');
  }

  public assignToMeSuccess(id: number, contact: ContactSimpleDto): void {
    this.apiNotificationService.showTranslatedSuccess('missions.toasts.assign-to-me');

    const state = this.getState();
    this.setState(this.adapter.updateOne({
      id: id.toString(),
      changes: {
        entity: {
          ...state.entities[id.toString()].entity,
          solutionContact: contact
        },
        model: {
          ...state.entities[id.toString()].model,
          solutionContact: contact
        }
      }
    }, {
      ...state,
      entityData: pagedEntitiesXsStoreSetLoadingForId(state.entityData, id, {
        assignToMe: false
      })
    }), 'ASSIGN_TO_ME_SUCCESS');
  }

  public changeMissionState(id: number, state: MissionState): void {
    this.entityService.changeState(id, state).toPromise()
      .then(({ plannedStart, plannedEnd, actualStart, actualEnd, lastEmailSend }) => this.changeMissionStateSuccess(
        id,
        state,
        plannedStart,
        plannedEnd,
        actualStart,
        actualEnd,
        lastEmailSend,
        this.translate.instant(`missions.mission-states.${ state }`)
      ))
      .catch(error => this.handleError(error));

    this.setState(produce(this.getState(), draft => {
      draft.entityData = pagedEntitiesXsStoreSetLoadingForId(draft.entityData, id, {
        changeState: true
      });
    }), 'CHANGE_MISSION_STATE');
  }

  public changeMissionStateSuccess(
    id: number,
    missionState: MissionState,
    plannedStart: string,
    plannedEnd: string,
    actualStart: string,
    actualEnd: string,
    lastEmailSend: string,
    name: string
  ): void {
    this.apiNotificationService.showTranslatedSuccess('missions.toasts.state-changed', { name });

    const state = this.getState();
    this.setState(this.adapter.updateOne({
      id: id.toString(),
      changes: {
        entity: {
          ...state.entities[id.toString()].entity,
          state: missionState,
          plannedStart,
          plannedEnd,
          actualStart,
          actualEnd,
          lastEmailSend
        },
        model: {
          ...state.entities[id.toString()].model,
          state: missionState,
          plannedStart,
          plannedEnd,
          actualStart,
          actualEnd,
          lastEmailSend
        }
      }
    }, {
      ...state,
      entityData: pagedEntitiesXsStoreSetLoadingForId(state.entityData, id, {
        changeState: false
      })
    }), 'CHANGE_MISSION_STATE_SUCCESS');
  }

  public complete(id: number): void {
    this.entityService.complete(id).toPromise()
      .then(() => this.completeSuccess(id))
      .catch(error => this.handleError(error));

    this.setState(produce(this.getState(), draft => {
      draft.entityData = pagedEntitiesXsStoreSetLoadingForId(draft.entityData, id, {
        changeState: true
      });
    }), 'COMPLETE');
  }

  public completeSuccess(id: number): void {
    this.apiNotificationService.showTranslatedSuccess('missions.toasts.completed');
    const state = this.getState();

    this.setState(this.adapter.updateOne({
      id: id.toString(),
      changes: {
        entity: {
          ...state.entities[id.toString()].entity,
          isCompleted: true
        },
        model: {
          ...state.entities[id.toString()].model,
          isCompleted: true
        }
      }
    }, {
      ...state,
      entityData: pagedEntitiesXsStoreSetLoadingForId(state.entityData, id, {
        changeState: false
      })
    }), 'COMPLETE_SUCCESS');
  }

  public resume(id: number): void {
    this.entityService.resume(id).toPromise()
      .then(() => this.resumeSuccess(id))
      .catch(error => this.handleError(error));

    this.setState(produce(this.getState(), draft => {
      draft.entityData = pagedEntitiesXsStoreSetLoadingForId(draft.entityData, id, {
        resume: true
      });
    }), 'RESUME');
  }

  public resumeSuccess(id: number): void {
    this.apiNotificationService.showTranslatedSuccess('missions.toasts.resumed');

    const state = this.getState();
    this.setState(this.adapter.updateOne({
      id: id.toString(),
      changes: {
        entity: {
          ...state.entities[id.toString()].entity,
          isCompleted: false
        },
        model: {
          ...state.entities[id.toString()].model,
          isCompleted: false
        }
      }
    }, {
      ...state,
      entityData: pagedEntitiesXsStoreSetLoadingForId(state.entityData, id, {
        resume: false
      })
    }), 'RESUME_SUCCESS');
  }

  public async export(id: number, templateId?: string | number): Promise<void> {
    this.entityService.export(id, templateId).toPromise()
      .then(({ uri }) => this.exportSuccess(id, uri))
      .catch(error => this.handleError(error));

    this.setState(produce(this.getState(), draft => {
      draft.entityData = pagedEntitiesXsStoreSetLoadingForId(draft.entityData, id, {
        export: true
      });
    }), 'EXPORT');
  }

  public exportSuccess(id: number, uri: string): void {
    window.open(uri, '_blank');

    this.setState(produce(this.getState(), draft => {
      draft.entityData = pagedEntitiesXsStoreSetLoadingForId(draft.entityData, id, {
        export: false
      });
    }), 'EXPORT_SUCCESS');
  }
}
