import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {
  AttachmentTypes,
  FileOrgaAttachmentDto,
  Filter,
  FilterOperators,
  FilterTypes,
  FolderOrgaAttachmentDto,
  KnowledgeArticleDto,
  KnowledgeArticleOrgaAttachmentDto,
  LinkOrgaAttachmentDto,
  OrgaAttachmentDto,
  UnsubscribeHelper
} from '@nexnox-web/core-shared';
import {
  CorePortalFormlyFileUploadTyping,
  CorePortalFormlyReadonlyTypes,
  CorePortalFormlyReadonlyTyping,
  CoreSharedRuleEditorListComponent,
  InheritedField,
  ModelValid
} from '@nexnox-web/core-portal';
import {distinctUntilChanged, map, startWith} from 'rxjs/operators';
import {Validators} from '@angular/forms';
import {cloneDeep, isEqual} from 'lodash';
import {OrgaPortalKnowledgeArticleService} from '../../services';
import {orgaPortalAttachmentTypeEnumOptions} from '../../model';


@Component({
  selector: 'nexnox-web-orga-attachments',
  templateUrl: './attachments.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrgaPortalAttachmentsComponent extends UnsubscribeHelper implements OnInit, ModelValid {
  @Input() public attachments$: Observable<OrgaAttachmentDto[]>;
  @Input() public readonly: boolean;
  @Input() public loading: boolean;
  @Input() public withCardbox = true;
  @ViewChild('attachmentsEdit') public attachmentsEdit: CoreSharedRuleEditorListComponent;
  @Output() public attachmentsChange: EventEmitter<OrgaAttachmentDto[]> = new EventEmitter<OrgaAttachmentDto[]>();
  public attachmentsString$: Observable<string>;
  public attachmentTitleFn: any;
  public attachmentFieldsFn: any;
  private knowledgeArticleIdSubject: BehaviorSubject<number> = new BehaviorSubject<number>(null);

  constructor(
    private knowledgeArticleService: OrgaPortalKnowledgeArticleService
  ) {
    super();

    this.attachmentTitleFn = (attachment: OrgaAttachmentDto) => {
      switch (attachment.type) {
        case AttachmentTypes.File:
          return (attachment as FileOrgaAttachmentDto).file?.name;
        case AttachmentTypes.Knowledge:
          return (attachment as KnowledgeArticleOrgaAttachmentDto).knowledgeArticle?.title;
        case AttachmentTypes.Link:
          return (attachment as LinkOrgaAttachmentDto).name;
        case AttachmentTypes.Folder:
          return (attachment as FolderOrgaAttachmentDto).path;
        default:
          return null;
      }
    };
    this.attachmentFieldsFn = () => this.createAttachmentFields();
  }

  @Input()
  public set knowledgeArticleId(knowledgeArticleId: number) {
    this.knowledgeArticleIdSubject.next(knowledgeArticleId);
  }

  public ngOnInit(): void {
    this.attachmentsString$ = this.attachments$.pipe(
      map(attachments => {
        if (!attachments?.length) {
          return '';
        }

        return ` (${ attachments.length })`;
      })
    );
  }

  public onAttachmentsChange(attachments: OrgaAttachmentDto[]): void {
    this.attachmentsChange.emit(attachments.map(attachment => {
      const newAttachment: any = cloneDeep(attachment);

      if (newAttachment.type !== AttachmentTypes.File) {
        delete newAttachment.file;
      }

      if (newAttachment.type !== AttachmentTypes.Knowledge) {
        delete newAttachment.knowledgeArticle;
        delete newAttachment.knowledgeArticleId;
      }

      if (newAttachment.type !== AttachmentTypes.Link) {
        delete newAttachment.name;
        delete newAttachment.link;
      }

      if (newAttachment.type !== AttachmentTypes.Folder) {
        delete newAttachment.path;
      }

      return newAttachment;
    }));
  }

  public onReset(): void {
    this.attachmentsEdit?.ngOnInit();
  }

  public isModelValid(): boolean {
    return this.attachmentsEdit ? this.attachmentsEdit.areItemsValid() : true;
  }

  public isOwnModelValid(): boolean {
    return this.attachmentsEdit ? this.attachmentsEdit.isModelValid() : true;
  }

  public isOwnModelPristine(): boolean {
    return this.attachmentsEdit ? this.attachmentsEdit.isPristine() : true;
  }

  /* istanbul ignore next */
  public createAttachmentFields(): InheritedField[] {
    return [
      {
        formlyConfig: {
          type: 'core-portal-ng-select',
          wrappers: ['core-portal-translated', 'core-portal-readonly'],
          defaultValue: AttachmentTypes.File,
          templateOptions: {
            corePortalTranslated: {
              label: 'core-shared.shared.fields.kind',
              validationMessages: {
                required: 'core-portal.core.validation.required'
              }
            },
            corePortalReadonly: {
              type: CorePortalFormlyReadonlyTypes.ENUM,
              enumOptions: orgaPortalAttachmentTypeEnumOptions,
              translate: true
            } as CorePortalFormlyReadonlyTyping,
            corePortalNgSelect: {
              items: orgaPortalAttachmentTypeEnumOptions,
              translate: true
            }
          },
          expressionProperties: {
            'templateOptions.required': () => !this.readonly,
            'templateOptions.readonly': () => this.readonly
          }
        },
        ownValueKey: 'type',
        hide: (_, creating) => !creating,
        disable: (_, creating, disabled) => disabled || !creating,
        noPR: true
      },

      // File
      {
        formlyConfig: {
          type: 'core-portal-file-upload',
          wrappers: ['core-portal-translated'],
          templateOptions: {
            corePortalTranslated: {
              label: 'core-shared.shared.fields.file',
              validationMessages: {
                required: 'core-portal.core.validation.required'
              }
            },
            corePortalFileUpload: {
              accept: '.pdf,.png,.jpg,.jpeg'
            } as CorePortalFormlyFileUploadTyping
          },
          expressionProperties: {
            'templateOptions.required': () => !this.readonly,
            'templateOptions.disabled': () => this.readonly
          },
          hooks: {
            onInit: field => this.subscribe(field.formControl.valueChanges.pipe(
              startWith(field.formControl.value),
              distinctUntilChanged((a, b) => isEqual(a, b))
            ), value => {
              field.form.controls.fileId.setValue(value?.fileId ?? null);
            })
          }
        },
        ownValueKey: 'file',
        additionalFields: [{ key: 'fileId' }],
        hide: (_, __, model?: OrgaAttachmentDto) => !model || model.type !== AttachmentTypes.File,
        noPR: true
      },

      // Knowledge article
      {
        formlyConfig: {
          type: 'core-portal-entity-select',
          wrappers: ['core-portal-translated', 'core-portal-readonly'],
          defaultValue: null,
          templateOptions: {
            corePortalTranslated: {
              label: 'orga-portal.shared.fields.knowledge-article'
            },
            corePortalReadonly: {
              type: CorePortalFormlyReadonlyTypes.ENTITY,
              displayKey: 'title',
              link: (article: KnowledgeArticleDto) => article?.knowledgeArticleId ? ['/knowledge/articles', article.knowledgeArticleId] : null,
              module: 'communication'
            } as CorePortalFormlyReadonlyTyping,
            entityService: this.knowledgeArticleService,
            idKey: 'knowledgeArticleId',
            displayKey: 'title',
            wholeObject: true,
            skipGetOne: true,
            clearable: false,
            defaultFilters$: this.knowledgeArticleIdSubject.asObservable().pipe(
              map(knowledgeArticleId => (knowledgeArticleId ? [{
                property: 'knowledgeArticleId',
                type: FilterTypes.DataTransferObject,
                operator: FilterOperators.NotEqual,
                value: knowledgeArticleId.toString()
              }] : []) as Filter[])
            ),
            link: (article: KnowledgeArticleDto) => article?.knowledgeArticleId ? ['/knowledge/articles', article.knowledgeArticleId] : null,
            module: 'communication'
          },
          expressionProperties: {
            'templateOptions.required': () => !this.readonly,
            'templateOptions.disabled': () => this.readonly
          },
          hooks: {
            onInit: field => this.subscribe(field.formControl.valueChanges.pipe(
              startWith(field.formControl.value),
              distinctUntilChanged((a, b) => isEqual(a, b))
            ), value => {
              field.form.controls.knowledgeArticleId.setValue(value?.knowledgeArticleId ?? null);
            })
          }
        },
        ownValueKey: 'knowledgeArticle',
        additionalFields: [{ key: 'knowledgeArticleId' }],
        hide: (_, __, model?: OrgaAttachmentDto) => !model || model.type !== AttachmentTypes.Knowledge,
        noPR: true
      },

      // Link
      {
        formlyConfig: {
          type: 'input',
          wrappers: ['core-portal-translated', 'core-portal-readonly'],
          templateOptions: {
            corePortalTranslated: {
              label: 'core-shared.shared.fields.name',
              validationMessages: {
                required: 'core-portal.core.validation.required'
              }
            },
            corePortalReadonly: {
              type: CorePortalFormlyReadonlyTypes.BASIC
            } as CorePortalFormlyReadonlyTyping,
            type: 'text'
          },
          expressionProperties: {
            'templateOptions.required': () => !this.readonly,
            'templateOptions.readonly': () => this.readonly
          }
        },
        ownValueKey: 'name',
        hide: (_, __, model?: OrgaAttachmentDto) => !model || model.type !== AttachmentTypes.Link || this.readonly,
        noPR: true
      },
      {
        formlyConfig: {
          type: 'input',
          wrappers: ['core-portal-translated', 'core-portal-readonly'],
          templateOptions: {
            corePortalTranslated: {
              label: 'core-shared.shared.fields.link',
              validationMessages: {
                required: 'core-portal.core.validation.required',
                url: 'core-portal.core.validation.url'
              }
            },
            corePortalReadonly: {
              type: CorePortalFormlyReadonlyTypes.BASIC
            } as CorePortalFormlyReadonlyTyping,
            type: 'text'
          },
          expressionProperties: {
            'templateOptions.required': () => !this.readonly,
            'templateOptions.readonly': () => this.readonly
          },
          validators: {
            url: ctrl =>
              Validators.pattern(/^(https:\/\/)(www\.)?.+$/)(ctrl) ? null : { url: true }
          }
        },
        ownValueKey: 'link',
        hide: (_, __, model?: OrgaAttachmentDto) => !model || model.type !== AttachmentTypes.Link,
        noPR: true
      },

      // Folder
      {
        formlyConfig: {
          type: 'orga-portal-folder-select',
          wrappers: ['core-portal-translated'],
          templateOptions: {
            corePortalTranslated: {
              label: 'core-shared.shared.fields.folder'
            }
          },
          expressionProperties: {
            'templateOptions.required': () => !this.readonly,
            'templateOptions.disabled': () => this.readonly
          }
        },
        ownValueKey: 'path',
        hide: (_, __, model?: OrgaAttachmentDto) => !model || model.type !== AttachmentTypes.Folder,
        noPR: true
      }
    ];
  }
}
