import {
  BaseXsStore,
  BaseXsStoreActions,
  BaseXsStoreReducerTypes,
  createBaseXsStoreActions,
  PropsAction
} from '@nexnox-web/core-store';
import {getInitialHeaderXsStoreState, HeaderXsStoreState} from './header-xs-store.state';
import {createAction, createSelector, MemoizedSelector, props} from '@ngrx/store';
import {immerOn} from 'ngrx-immer/store';

export interface HeaderXsStoreActions extends BaseXsStoreActions {
  setTitle: PropsAction<{ title: string }>;
  setShowShell: PropsAction<{ showShell: boolean }>;
}

export interface HeaderXsStoreSelectors {
  selectTitle: MemoizedSelector<HeaderXsStoreState, string>;
  selectShowShell: MemoizedSelector<HeaderXsStoreState, boolean>;
}

export class HeaderXsStore extends BaseXsStore<HeaderXsStoreState> {
  public actions: HeaderXsStoreActions;
  public selectors: HeaderXsStoreSelectors;

  public getInitialState(): HeaderXsStoreState {
    return getInitialHeaderXsStoreState();
  }

  protected createActions(label: string): HeaderXsStoreActions {
    return {
      ...createBaseXsStoreActions(label),

      setTitle: createAction(
        BaseXsStore.getType(label, 'Set title'),
        props<{ title: string }>()
      ),

      setShowShell: createAction(
        BaseXsStore.getType(label, 'Set show shell'),
        props<{ showShell: boolean }>()
      )
    };
  }

  protected createReducerArray(
    initialState: HeaderXsStoreState
  ): BaseXsStoreReducerTypes<HeaderXsStoreState, HeaderXsStoreActions>[] {
    return [
      ...super.createReducerArray(initialState),

      immerOn(this.actions.setTitle, (draft, { title }) => {
        draft.title = title;
      }),

      immerOn(this.actions.setShowShell, (draft, { showShell }) => {
        draft.showShell = showShell;
      })
    ];
  }

  protected createSelectors(): HeaderXsStoreSelectors {
    const { stateSelector } = this.options;

    return {
      ...super.createSelectors(),

      selectTitle: createSelector(stateSelector, state => state.title),
      selectShowShell: createSelector(stateSelector, state => state.showShell)
    };
  }
}
