import {Injectable, Injector} from '@angular/core';
import {AppEntityType, ContactSimpleDto, EditorQuestDto, SuggestedEditorsDto} from '@nexnox-web/core-shared';
import {EntityXsStoreState} from '@nexnox-web/core-store';
import {createEffect, ofType} from '@ngrx/effects';
import {Action, createSelector} from '@ngrx/store';
import {TranslateService} from '@ngx-translate/core';
import {of} from 'rxjs';
import {catchError, exhaustMap, map, tap} from 'rxjs/operators';
import {selectMissionsState} from '../../missions.selectors';
import {MissionEditorQuestService} from '../../services';
import {MissionEditorQuestXsStore, MissionEditorQuestXsStoreActions} from './mission-editor-quest.xs-store';
import {missionDetailStore} from "../../stores";

export interface LocalEditorQuestDto {
  quest: EditorQuestDto;
  editors: SuggestedEditorsDto;
}

export interface MissionEditorQuestStoreState extends EntityXsStoreState<LocalEditorQuestDto> {
}

/* istanbul ignore next */
export const missionEditorQuestStore = new MissionEditorQuestXsStore({
  actionLabel: 'Tech Portal - Missions - Mission Editor Quest',
  stateSelector: createSelector(selectMissionsState, state => state.missionEditorQuest),
  serviceType: MissionEditorQuestService,
  entityType: AppEntityType.None,
  stereotyped: false
});

@Injectable()
export class MissionEditorQuestStoreEffects extends missionEditorQuestStore.effects {

  public getQuest$: any;
  public getQuestSuccess$: any;

  public getSuggestions$: any;
  public getSuggestionsSuccess$: any;

  public startQuest$: any;
  public startQuestSuccess$: any;

  public cancelQuest$: any;
  public cancelQuestSuccess$: any;

  public acceptContact$: any;
  public acceptContactSuccess$: any;

  public declineContact$: any;
  public declineContactSuccess$: any;

  public commissionContact$: any;
  public commissionContactSuccess$: any;

  protected actions: MissionEditorQuestXsStoreActions;
  protected service: MissionEditorQuestService;

  constructor(
    protected injector: Injector,
    private translate: TranslateService
  ) {
    super(injector);
  }

  protected createEffects(): void {
    super.createEffects();

    this.getQuest$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.getQuest),
      exhaustMap(({ missionId }) => this.service.getQuest(missionId).pipe(
          catchError(error => error.status === 404 ? of({ quest: null }) :
            of(this.actions.error({
              error,
              action: this.actions.getQuest
            }))),
          map(quest => this.actions.getQuestSuccess({ quest }))
        )
      ))
    );

    this.getQuestSuccess$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.getQuestSuccess)
    ), { dispatch: false });

    this.getSuggestions$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.getSuggestions),
      exhaustMap(({ resourceId }) => this.service.getSuggestions(resourceId).pipe(
        map(suggestions => this.actions.getSuggestionsSuccess({ suggestions })),
        catchError(error => of(this.actions.error({
          error,
          action: this.actions.getSuggestions
        })))
      ))
    ));

    this.getSuggestionsSuccess$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.getSuggestionsSuccess)
    ), { dispatch: false });

    this.startQuest$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.startQuest),
      exhaustMap(({ missionId, functionId }) => this.service.startQuest(missionId, functionId).pipe(
        map(() => this.actions.startQuestSuccess({ missionId })),
        catchError(error => of(this.actions.error({
          error,
          action: this.actions.startQuest
        })))
      ))
    ));

    this.startQuestSuccess$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.startQuestSuccess),
      tap(action => this.actionCallback(action, false))
    ), { dispatch: false });


    this.cancelQuest$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.cancelQuest),
      exhaustMap(({ missionId }) => this.service.cancelQuest(missionId).pipe(
        map(() => this.actions.cancelQuestSuccess({ missionId })),
        catchError(error => of(this.actions.error({
          error,
          action: this.actions.cancelQuest
        })))
      ))
    ));

    this.cancelQuestSuccess$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.cancelQuestSuccess),
      tap(action => this.actionCallback(action, false))
    ), { dispatch: false });

    this.acceptContact$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.acceptContact),
      exhaustMap(({ missionId, contactId }) => this.service.acceptContact(missionId, contactId).pipe(
        map(() => this.actions.acceptContactSuccess({ missionId })),
        catchError(error => of(this.actions.error({
          error,
          action: this.actions.acceptContact
        })))
      ))
    ));

    this.acceptContactSuccess$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.acceptContactSuccess),
      tap(action => this.actionCallback(action, false))
    ), { dispatch: false });

    this.declineContact$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.declineContact),
      exhaustMap(({ missionId, contactId }) => this.service.declineContact(missionId, contactId).pipe(
        map(() => this.actions.declineContactSuccess({ missionId })),
        catchError(error => of(this.actions.error({
          error,
          action: this.actions.declineContact
        })))
      ))
    ));

    this.declineContactSuccess$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.declineContactSuccess),
      tap(action => this.actionCallback(action, false))
    ), { dispatch: false });

    this.commissionContact$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.commissionContact),
      exhaustMap(({ missionId, contactId }) => this.service.commissionContact(missionId, contactId).pipe(
        map((contact) => this.actions.commissionContactSuccess({ missionId, contact })),
        catchError(error => of(this.actions.error({
          error,
          action: this.actions.commissionContact
        })))
      ))
    ));

    this.commissionContactSuccess$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.commissionContactSuccess),
      tap(action => this.actionCallback(action, false))
    ), { dispatch: false });
  }

  protected actionCallback(action: Action, isError: boolean): void {
    super.actionCallback(action, isError);

    this.checkAction(this.actions.cancelQuestSuccess, action, ({ missionId }) => this.cancelQuestSuccessCallback(missionId));
    this.checkAction(this.actions.startQuestSuccess, action, ({ missionId }) => this.startQuestSuccessCallback(missionId));
    this.checkAction(this.actions.acceptContactSuccess, action, ({ missionId }) => this.acceptContactSuccessCallback(missionId));
    this.checkAction(this.actions.declineContactSuccess, action, ({ missionId }) => this.declineContactSuccessCallback(missionId));
    this.checkAction(this.actions.commissionContactSuccess, action, ({
                                                                       missionId,
                                                                       contact
                                                                     }) => this.commissionContactSuccessCallback(missionId, contact))
  }

  protected startQuestSuccessCallback(missionId: number | string): void {
    this.store.dispatch(this.actions.getQuest({ missionId }));
    this.apiNotificationService.showTranslatedSuccess('missions.toasts.quest-started');
  }

  protected cancelQuestSuccessCallback(missionId: number | string): void {
    this.store.dispatch(this.actions.getQuest({ missionId }));
    this.apiNotificationService.showTranslatedSuccess('missions.toasts.quest-canceled');
  }

  protected acceptContactSuccessCallback(missionId: number | string): void {
    this.store.dispatch(this.actions.getQuest({ missionId }));
    this.apiNotificationService.showTranslatedSuccess('missions.toasts.contact-accepted');
  }

  protected declineContactSuccessCallback(missionId: number | string): void {
    this.store.dispatch(this.actions.getQuest({ missionId }));
    this.apiNotificationService.showTranslatedSuccess('missions.toasts.contact-declined');
  }

  protected commissionContactSuccessCallback(missionId: number | string, contact: ContactSimpleDto): void {
    this.store.dispatch(this.actions.getQuest({ missionId }));
    this.store.dispatch(missionDetailStore.actions.updateSolutionContactOffline({ contact }));
    this.apiNotificationService.showTranslatedSuccess('missions.toasts.contact-accepted');
  }
}
