import {
  NotificationRulesContractsStoreEffects,
  notificationRulesContractsStoreReducer,
  NotificationRulesDetailStoreEffects,
  notificationRulesDetailStoreReducer,
  NotificationRulesInfosStoreEffects,
  notificationRulesInfosStoreReducer,
  NotificationRulesListStoreEffects,
  notificationRulesListStoreReducer,
  NotificationRulesMissionsStoreEffects,
  notificationRulesMissionsStoreReducer,
  NotificationRulesTicketsStoreEffects,
  notificationRulesTicketsStoreReducer
} from './stores';
import {
  CorePortalFeatureNotificationRulesService,
  CorePortalFeatureNotificationRulesTriggerTypeRestrictionService
} from './services';

export * from './services';
export * from './stores';

export * from './notification-rules.state';
export * from './notification-rules.selector';

export const notificationRulesReducers = {
  notificationRulesList: notificationRulesListStoreReducer,
  notificationRulesDetail: notificationRulesDetailStoreReducer,
  notificationRulesMissions: notificationRulesMissionsStoreReducer,
  notificationRulesTickets: notificationRulesTicketsStoreReducer,
  notificationRulesContracts: notificationRulesContractsStoreReducer,
  notificationRulesInfos: notificationRulesInfosStoreReducer
};

export const notificationRulesEffects = [
  NotificationRulesListStoreEffects,
  NotificationRulesDetailStoreEffects,
  NotificationRulesMissionsStoreEffects,
  NotificationRulesTicketsStoreEffects,
  NotificationRulesContractsStoreEffects,
  NotificationRulesInfosStoreEffects
];

export const notificationRulesServices = [
  CorePortalFeatureNotificationRulesService,
  CorePortalFeatureNotificationRulesTriggerTypeRestrictionService
];
