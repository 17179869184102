<ng-template #entityEditTemplate let-title="title">
  <nexnox-web-ticket-settings-escalation-levels-escalation-level-edit #editComponent
                                                                      (modelChange)="createModel$.next($event)"
                                                                      [creating]="true"
                                                                      [loading]="(loaded$ | async) === false"
                                                                      [model]="createModel$ | async"
                                                                      [readonly]="false"
                                                                      [title]="title">
  </nexnox-web-ticket-settings-escalation-levels-escalation-level-edit>
</ng-template>

<nexnox-web-entity-overview #overviewComponent
                            [entityEditTemplate]="entityEditTemplate"
                            [entityOverviewBaseComponent]="this"
                            [showSettings]="false"
                            detailLink="/escalation-levels"
                            module="settings">
</nexnox-web-entity-overview>
