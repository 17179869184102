import {ChangeDetectionStrategy, Component, Injector, ViewChild} from '@angular/core';
import {ActionButton, CorePortalEntityDetailBaseComponent} from '@nexnox-web/core-portal';
import {AppPermissions, ResourceManufacturerDto} from '@nexnox-web/core-shared';
import {manufacturerDetailStore} from '../../store/stores';
import {MergeManufacturerSidebarComponent} from "../../sidebars";
import {BehaviorSubject, firstValueFrom} from "rxjs";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'nexnox-web-settings-manufacturer-detail',
  templateUrl: './manufacturer-detail.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ManufacturerDetailComponent extends CorePortalEntityDetailBaseComponent<ResourceManufacturerDto> {
  @ViewChild('mergeManufacturerSidebar', { static: true }) public mergeManufacturerSidebar: MergeManufacturerSidebarComponent;

  public title = 'core-portal.settings.subtitles.manufacturer-detail';
  public manufacturerToMergeSubject: BehaviorSubject<ResourceManufacturerDto> = new BehaviorSubject<ResourceManufacturerDto>({});

  protected idParam = 'resourceManufacturerId';
  protected displayKey = 'name';

  constructor(
    protected injector: Injector,
    private translate: TranslateService
  ) {
    super(injector, manufacturerDetailStore);
  }

  public async mergeManufacturer(newManufacturer: ResourceManufacturerDto): Promise<void> {
    if (this.manufacturerToMergeSubject?.getValue()) {
      this.store.dispatch(manufacturerDetailStore.actions.merge({
        manufacturer: this.manufacturerToMergeSubject.getValue(),
        newManufacturerId: newManufacturer.resourceManufacturerId
      }));
    }
  }

  protected async getActionButtons(): Promise<ActionButton[]> {
    return this.getDefaultActionButtons(
      'core-portal.settings.actions.edit-manufacturer',
      'core-portal.settings.actions.save-manufacturer',
      'core-portal.settings.actions.delete-manufacturer',
      'core-portal.settings.descriptions.delete-manufacturer',
      AppPermissions.UpdateResourceManufacturer,
      AppPermissions.DeleteResourceManufacturer,
      undefined,
      undefined,
      [
        {
          label: this.translate.instant('core-portal.settings.actions.merge-manufacturer'),
          type: 'button',
          permission: AppPermissions.MergeResourceManufacturer,
          isLoading: () => this.loading$,
          callback: () => this.openMergeManufacturerSidebar()
        }
      ]
    );
  }

  private async openMergeManufacturerSidebar(): Promise<void> {
    this.manufacturerToMergeSubject.next(await firstValueFrom(this.store.select(manufacturerDetailStore.selectors.selectEntity)));
    if (this.manufacturerToMergeSubject?.getValue() !== null) {
      this.mergeManufacturerSidebar.onShow();
    }
  }
}
