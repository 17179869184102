import {ChangeDetectionStrategy, Component, Injector} from '@angular/core';
import {ActionButton, CorePortalEntityDetailBaseComponent} from '@nexnox-web/core-portal';
import {AppPermissions, EscalationLevelDto} from '@nexnox-web/core-shared';
import {escalationLevelDetailStore} from '../../store/stores';

@Component({
  selector: 'nexnox-web-ticket-settings-escalation-levels-escalation-level-detail',
  templateUrl: './escalation-level-detail.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EscalationLevelDetailComponent extends CorePortalEntityDetailBaseComponent<EscalationLevelDto> {
  public title = 'core-portal.settings.subtitles.ticket-settings.escalation-level-detail';

  protected idParam = 'escalationLevelId';
  protected displayKey = 'name';

  constructor(
    protected injector: Injector
  ) {
    super(injector, escalationLevelDetailStore);
  }

  protected async getActionButtons(): Promise<ActionButton[]> {
    return this.getDefaultActionButtons(
      'core-portal.settings.actions.ticket-settings.edit-escalation-level',
      'core-portal.settings.actions.ticket-settings.save-escalation-level',
      'core-portal.settings.actions.ticket-settings.delete-escalation-level',
      'core-portal.settings.descriptions.ticket-settings.delete-escalation-level',
      AppPermissions.UpdateEscalationLevel,
      AppPermissions.DeleteEscalationLevel,
      ['escalation-levels']
    );
  }
}
