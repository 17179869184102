<nexnox-web-modal #modalComponent
                  [cancelButtonText]="'core-portal.core.general.cancel' | translate"
                  [preConfirmFn]="preConfirmFn"
                  [showConfirmButton]="false"
                  [title]="'core-shared.shared.select.advanced-search' | translate">
  <div class="row w-100">
    <nexnox-web-entity-select-datatable-search-modal [baseComponent]="this" class="w-100">
    </nexnox-web-entity-select-datatable-search-modal>
  </div>
</nexnox-web-modal>
