import {ModuleWithProviders, NgModule} from '@angular/core';
import {AddressesDeclarationsModule} from './addresses-declarations.module';
import {AddressesRoutingModule} from './addresses-routing.module';
import {AddressesStoreModule} from './addresses-store.module';

@NgModule({
  imports: [
    AddressesDeclarationsModule,
    AddressesRoutingModule,
    AddressesStoreModule
  ]
})
export class CorePortalFeatureMasterDataAddressesModule {
  public static forFeature(): ModuleWithProviders<AddressesDeclarationsModule> {
    return { ngModule: AddressesDeclarationsModule };
  }
}
