import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {CorePortalPermissionGuard} from '@nexnox-web/core-portal';
import {DeviceDetailComponent, DeviceListComponent} from './containers';
import {AppPermissions, I18nBreadcrumbResolver, UnsavedChangesGuard, UnsavedCreateGuard} from '@nexnox-web/core-shared';

export const ORGA_PORTAL_DEVICES_ROUTES: Routes = [
  {
    path: '',
    canActivate: [CorePortalPermissionGuard],
    canDeactivate: [UnsavedCreateGuard],
    component: DeviceListComponent,
    data: {
      permissions: [AppPermissions.DisplayDevices]
    }
  },
  {
    path: ':id',
    component: DeviceDetailComponent,
    canActivate: [CorePortalPermissionGuard],
    canDeactivate: [UnsavedChangesGuard],
    data: {
      permissions: [AppPermissions.ReadDevice],
      breadcrumbs: I18nBreadcrumbResolver,
      tab: 'device',
      text: 'orga-portal.devices.subtitles.device-detail'
    },
    children: [
      {
        path: 'metadata',
        data: {
          tab: 'metadata'
        },
        children: []
      }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(ORGA_PORTAL_DEVICES_ROUTES)
  ],
  exports: [
    RouterModule
  ]
})
export class DevicesRoutingModule {
}
