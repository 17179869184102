<div *ngIf="(to.readonly && to.corePortalReadonly)"
     [ngSwitch]="to.corePortalReadonly?.type">
  <ng-container *ngSwitchCase="types.BASIC"
                [ngTemplateOutletContext]="{
                  value: $any(to.corePortalReadonly).template ? $any(to.corePortalReadonly).template(basicValue$ | async) : (basicValue$ | async),
                  padding: 11.5
                }"
                [ngTemplateOutlet]="basicTemplate">
  </ng-container>

  <ng-container *ngSwitchCase="types.BOOLEAN"
                [ngTemplateOutletContext]="{
                  value: (hasValue$ | async) ? ((value$ | async) ? 'core-portal.core.general.true' : 'core-portal.core.general.false') : null,
                  translateForce: true,
                  padding: 11.5
                }"
                [ngTemplateOutlet]="basicTemplate">
  </ng-container>

  <ng-container *ngSwitchCase="types.DATE"
                [ngTemplateOutletContext]="{
                  value: (hasValue$ | async) ? ((dateValue$ | async) | nexnoxWebBsDate:$any(to.corePortalReadonly).format | async) : null,
                  padding: 11.5
                }"
                [ngTemplateOutlet]="basicTemplate">
  </ng-container>

  <ng-container *ngSwitchCase="types.TIME"
                [ngTemplateOutletContext]="{
                  value: (timeValue$ | async) | nexnoxWebBsDate:'LT' | async,
                  padding: 11.5
                }"
                [ngTemplateOutlet]="basicTemplate">
  </ng-container>

  <ng-container *ngSwitchCase="types.ENUM"
                [ngTemplateOutletContext]="{
                  value: (hasValue$ | async) ? (enumValue$ | async) : null ,
                  padding: 11,
                  link: (hasValue$ | async) && $any(to.corePortalReadonly).link ? $any(to.corePortalReadonly).link(value$ | async) : undefined,
                  module: $any(to.corePortalReadonly).module
                }"
                [ngTemplateOutlet]="basicTemplate">
  </ng-container>

  <ng-container *ngSwitchCase="types.ENTITY"
                [ngTemplateOutletContext]="{
                  value: (hasValue$ | async) ? (entityValue$ | async) : null,
                  model: (hasValue$ | async) ? (value$ | async) : null,
                  padding: 11,
                  link: (hasValue$ | async) && $any(to.corePortalReadonly).link ? $any(to.corePortalReadonly).link(value$ | async) : undefined,
                  customLinkAction: (hasValue$ | async) && $any(to.corePortalReadonly).linkAction ? $any(to.corePortalReadonly).linkAction : undefined,
                  suffix: $any(to.corePortalReadonly).suffix ? $any(to.corePortalReadonly).suffix : undefined,
                  module: $any(to.corePortalReadonly).module
                }"
                [ngTemplateOutlet]="basicTemplate">
  </ng-container>

  <ng-container *ngSwitchCase="types.IMAGE"
                [ngTemplateOutletContext]="{
                  value: (hasValue$ | async) ? (imageValue$ | async) : null
                }"
                [ngTemplateOutlet]="imageTemplate">
  </ng-container>

  <ng-container *ngSwitchCase="types.HTML"
                [ngTemplateOutletContext]="{
                  value: (hasValue$ | async) ? $any(to.corePortalReadonly).template ? $any(to.corePortalReadonly).template(htmlValue$ | async) :  (htmlValue$ | async) : ''
                }"
                [ngTemplateOutlet]="htmlTemplate">
  </ng-container>

  <ng-container *ngSwitchCase="types.PHONE"
                [ngTemplateOutletContext]="{
                  value: (hasValue$ | async) ? (value$ | async) : '',
                  padding: 11.5
                }"
                [ngTemplateOutlet]="phoneTemplate">
  </ng-container>
</div>

<!-- Basic Template -->
<ng-template #basicTemplate
             let-customLinkAction="customLinkAction"
             let-link="link"
             let-model="model"
             let-module="module"
             let-padding="padding"
             let-suffix="suffix"
             let-translateForce="translateForce"
             let-value="value">
  <div class="d-flex flex-row align-items-center">
    <fa-icon *ngIf="to.corePortalReadonly.icon"
             [icon]="to.corePortalReadonly.icon"
             [style.color]="to.corePortalReadonly.iconColor"
             [style.padding-top.px]="7"
             class="align-self-start">
    </fa-icon>

    <div *ngIf="link || customLinkAction; else spanTemplate" class="d-flex w-100">
      <a *ngIf="!customLinkAction" [module]="module"
         [nexnoxWebTenantRouterLink]="link"
         [style.padding-left.px]="padding"
         class="form-control-plaintext-stripped"
         target="_blank">
        <ng-container [ngTemplateOutlet]="valueTemplate"></ng-container>
      </a>
      <a (click)="customLinkAction(model)" *ngIf="customLinkAction"
         [style.padding-left.px]="padding"
         class="cursor-pointer form-control-plaintext-stripped">
        <ng-container [ngTemplateOutlet]="valueTemplate"></ng-container>
      </a>
    </div>

    <ng-template #spanTemplate>
      <span [style.padding-left.px]="padding"
            class="form-control-plaintext whitespace-pre-line text-break">
        <ng-container [ngTemplateOutlet]="valueTemplate"></ng-container>
      </span>
      <span *ngIf="suffix">
        {{ suffix(model) }}
      </span>
    </ng-template>

    <ng-template #valueTemplate>
      <ng-container *ngIf="hasValue$ | async; else noValueTemplate">

        <div class="d-flex flex-wrap align-items-baseline">
          <ng-container *ngIf="isPlainText; else urlTemplate">
              <span class="mr-1">
                {{ to.corePortalReadonly.translate || translateForce ? (value | translate) : value }}
              </span>
          </ng-container>
          <span *ngIf="suffix" class="small">
            {{ suffix(model) }}
          </span>
        </div>
      </ng-container>

      <ng-template #noValueTemplate>
        {{ 'core-shared.shared.select.empty' | translate }}
      </ng-template>

      <ng-template #urlTemplate>
        <span [innerHtml]="value" class="mr-1"></span>
      </ng-template>
    </ng-template>

    <ng-template #suffixTemplate>

    </ng-template>
  </div>
</ng-template>

<!-- Image Template -->
<ng-template #imageTemplate
             let-value="value">
  <a [href]="value"
     target="_blank">
    <img [src]="value"
         class="img-fluid readonly-image">
  </a>
</ng-template>

<!-- HTML Template -->
<ng-template #htmlTemplate
             let-value="value">
  <div class="d-flex align-items-start">
    <fa-icon *ngIf="to.corePortalReadonly.icon"
             [icon]="to.corePortalReadonly.icon"
             [style.color]="to.corePortalReadonly.iconColor">
    </fa-icon>
    <div [innerHTML]="bypassHTMLSanitizer(value)"
         class="readonly-html-value ml-2">
    </div>
  </div>
</ng-template>

<!-- Phone Template -->
<ng-template #phoneTemplate
             let-padding="padding"
             let-value="value">
  <div class="d-flex align-items-start">
    <fa-icon *ngIf="to.corePortalReadonly.icon"
             [icon]="to.corePortalReadonly.icon"
             [style.color]="to.corePortalReadonly.iconColor">
    </fa-icon>
    <div [style.padding-left.px]="padding" class="form-control-plaintext">
      <ng-container *ngIf="hasValue$ | async; else noValueTemplate">
        <a href="tel:{{value}}">{{ value }}</a>
      </ng-container>
    </div>
    <ng-template #noValueTemplate>
      {{ 'core-shared.shared.select.empty' | translate }}
    </ng-template>
  </div>
</ng-template>

<!-- Field Component -->
<div *ngIf="!to.readonly">
  <ng-template #fieldComponent></ng-template>
</div>
