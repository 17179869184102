import {ChangeDetectionStrategy, Component, Injector, Input, OnInit} from '@angular/core';
import {
  AppEntityType,
  AuditDto,
  AuditKind,
  AuditOfCollectionDto,
  AuditOfPropertyDto,
  AuditPropertyType,
  CoreSharedModalBaseComponent
} from '@nexnox-web/core-shared';
import {CorePortalFeatureAuditDetailService} from '../../store';
import {BehaviorSubject, finalize, Observable, tap} from 'rxjs';
import {map, share} from 'rxjs/operators';
import {auditKindEnumOptionTypes} from "../../models";

export interface RelatedAudit {
  auditId?: number,
  kind?: AuditKind,
  createdAt?: string,
  entityType?: AppEntityType,
  entityId?: number,
  createdByUserName?: string,
  createdByUserId?: number,
  properties?: AuditOfPropertyDto[],
  collections?: AuditOfCollectionDto[],
  relatedAudits?: RelatedAudit[];
}

@Component({
  selector: 'nexnox-web-audit-audit-detail-modal',
  templateUrl: './audit-detail-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CorePortalFeatureAuditDetailModalComponent extends CoreSharedModalBaseComponent implements OnInit {

  @Input() public auditId: number;
  @Input() public entityType: AppEntityType;
  @Input() public entityId: number;

  public loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public audit$: Observable<AuditDto>;
  public properties$: Observable<AuditOfPropertyDto[]>;
  public collections$: Observable<AuditOfCollectionDto[]>;
  public relatedAudits$: Observable<RelatedAudit[]>;

  public readonly auditKindEnumOptionTypes = auditKindEnumOptionTypes;
  public propertyTypes = AuditPropertyType;

  private previousRelatedAuditIds: number[] = [];

  constructor(
    protected injector: Injector,
    private auditDetailService: CorePortalFeatureAuditDetailService
  ) {
    super(injector);
  }

  public get hasPreviousRelatedAudits(): boolean {
    return this.previousRelatedAuditIds?.length > 1 ?? false;
  }

  /* istanbul ignore next */
  public ngOnInit(): void {
    this.openRelatedAudit(this.auditId);
  }

  public openRelatedAudit(auditId?: number): void {
    this.loading$.next(true);
    if (auditId) {
      this.previousRelatedAuditIds.push(auditId);
    } else if (this.previousRelatedAuditIds.length > 0) {
      this.previousRelatedAuditIds.pop();
    }
    this.auditId = this.previousRelatedAuditIds[this.previousRelatedAuditIds.length - 1];
    this.fetchCurrentAuditData();
  }

  private fetchCurrentAuditData(): void {
    this.audit$ = this.auditDetailService.getOne(this.auditId, [this.entityType, this.entityId]).pipe(
      tap(() => this.loading$.next(true)),
      finalize(() => this.loading$.next(false)),
      share()
    );
    this.properties$ = this.audit$.pipe(
      map(audit => audit.properties)
    );
    this.collections$ = this.audit$.pipe(
      map(audit => audit.collections)
    );
    this.relatedAudits$ = this.audit$.pipe(
      map(audit => audit.relatedAudits)
    );
  }
}
