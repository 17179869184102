<nexnox-web-entity-edit *ngIf="!creating"
                        [entityEditBaseComponent]="this"
                        displayKey="name">
  <div *ngIf="model?.lastModified && model?.createdAt" class="d-flex w-100 justify-content-end text-muted p-2">
    <span [title]="model.lastModified | nexnoxWebBsDate:'L LT' | async">
      {{ 'core-shared.shared.time-phrases.modified' | translate }} {{ model.lastModified | nexnoxWebDayjsRelative | async }}
    </span>
    ,&nbsp;
    <span [title]="model.createdAt | nexnoxWebBsDate:'L LT' | async">
      {{ 'core-shared.shared.time-phrases.created' | translate }} {{ model.createdAt | nexnoxWebDayjsRelative | async }}
    </span>
  </div>
</nexnox-web-entity-edit>

<nexnox-web-tabs *ngIf="creating"
                 [internal]="true"
                 [isTransparent]="true"
                 startTab="entityEdit">
  <!-- Entity Edit Tab -->
  <ng-template [hasError]="!isOwnModelValid()"
               [heading]="title | translate"
               nexnoxWebTabTemplate
               tabId="entityEdit">
    <nexnox-web-entity-edit [entityEditBaseComponent]="this"></nexnox-web-entity-edit>
  </ng-template>
  <!-- Attachments -->
  <ng-template [heading]="('core-portal.core.general.attachments' | translate) + (attachmentsComponent ? (attachmentsComponent.attachmentsString$ | async) : '')"
               nexnoxWebTabTemplate
               tabId="attachments">

    <nexnox-web-tech-attachments #attachmentsComponent
                                 (attachmentsChange)="onAttachmentsChange($event)"
                                 [attachments$]="attachmentsSubject"
                                 [loading]="loading"
                                 [readonly]="readonly">
    </nexnox-web-tech-attachments>
  </ng-template>
</nexnox-web-tabs>
