import {ChangeDetectionStrategy, Component, forwardRef, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {UnsubscribeHelper} from '../../helper';
import {distinctUntilChanged, map, startWith, tap, withLatestFrom} from 'rxjs/operators';
import {isEqual, isNull} from 'lodash';
import dayjs from 'dayjs';
import {minutesTo} from '@nexnox-web/lodash';
import {faTimes} from '@fortawesome/free-solid-svg-icons/faTimes';

export enum CoreSharedDateTimePickerRelativeTimeType {
  MINUTES_30 = 30,
  HOURS_1 = 60,
  HOURS_2 = 120,
  HOURS_3 = 180,
  HOURS_4 = 240,
  HOURS_8 = 480,
  DAYS_1 = 1440,
  DAYS_3 = 4320,
  DAYS_7 = 10080
}

@Component({
  selector: 'nexnox-web-date-time-picker',
  templateUrl: './date-time-picker.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => DateTimePickerComponent)
    }
  ]
})
export class DateTimePickerComponent extends UnsubscribeHelper implements OnInit, ControlValueAccessor {
  @Input() public hasError: boolean;
  @Input() public canClear = true;
  @Input() public enabledRelativeTimes: CoreSharedDateTimePickerRelativeTimeType[] = [
    CoreSharedDateTimePickerRelativeTimeType.MINUTES_30,
    CoreSharedDateTimePickerRelativeTimeType.HOURS_1,
    CoreSharedDateTimePickerRelativeTimeType.HOURS_2,
    CoreSharedDateTimePickerRelativeTimeType.HOURS_3,
    CoreSharedDateTimePickerRelativeTimeType.HOURS_4,
    CoreSharedDateTimePickerRelativeTimeType.HOURS_8,
    CoreSharedDateTimePickerRelativeTimeType.DAYS_1,
    CoreSharedDateTimePickerRelativeTimeType.DAYS_3,
    CoreSharedDateTimePickerRelativeTimeType.DAYS_7
  ];

  public value$: Observable<string>;
  public valueSet$: Observable<boolean>;
  public valid$: Observable<boolean>;
  public disabled$: Observable<boolean>;

  public date$: Observable<string>;
  public time$: Observable<number>;

  public faTimes = faTimes;
  public relativeTimeTypes = CoreSharedDateTimePickerRelativeTimeType;

  private updateSubject: Subject<void> = new Subject<void>();
  private validSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private disabledSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private dateSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private timeSubject: BehaviorSubject<number> = new BehaviorSubject<number>(null);

  private onChange: any;
  private onTouched: any;

  constructor() {
    super();
  }

  public ngOnInit(): void {
    this.date$ = this.dateSubject.asObservable();
    this.time$ = this.timeSubject.asObservable();
    this.value$ = this.updateSubject.asObservable().pipe(
      withLatestFrom(this.date$),
      map(([_, date]) => date),
      withLatestFrom(this.time$),
      distinctUntilChanged((a, b) => isEqual(a, b)),
      tap(() =>
        this.validSubject.next(this.isDateValid(this.dateSubject.getValue()) && this.isTimeValid(this.timeSubject.getValue()))
      ),
      map(([date, time]) => this.convertDateAndTimeToString(date, time))
    );
    this.valueSet$ = this.value$.pipe(
      map(value => Boolean(value)),
      startWith(false)
    );
    this.valid$ = this.validSubject.asObservable();
    this.disabled$ = this.disabledSubject.asObservable();

    this.subscribe(this.value$, value => {
      if (this.onChange && this.onTouched) {
        const dateValid = this.isDateValid(this.dateSubject.getValue());
        const timeValid = this.isTimeValid(this.timeSubject.getValue());

        if (dateValid && timeValid) {
          this.onChange(value);
        } else {
          this.onChange(null);
        }

        this.onTouched();
      }
    });
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public onDateChange(value: string, update: boolean = true): void {
    const date = dayjs(value);
    this.dateSubject.next(date?.isValid() ? date.local().format('YYYY-MM-DDTHH:mm:ss') : null);

    if (!this.timeSubject.getValue() && date?.isValid()) {
      this.onTimeChange(0, false);
    }

    if (update) {
      this.updateSubject.next();
    }
  }

  public onTimeChange(value: number, update: boolean = true): void {
    this.timeSubject.next(value);

    if (update) {
      this.updateSubject.next();
    }
  }

  public onSetToNow(): void {
    this.writeValue(dayjs().utc().format('YYYY-MM-DDTHH:mm:ss'));
  }

  public onSetRelativeTime(type: CoreSharedDateTimePickerRelativeTimeType): void {
    this.writeValue(dayjs().add(type, 'minute').utc().format('YYYY-MM-DDTHH:mm:ss'));
  }

  public onClear(): void {
    this.onDateChange(null, false);
    this.onTimeChange(null, false);
    this.updateSubject.next();
  }

  public writeValue(value: string): void {
    const date = dayjs.utc(value).local();

    if (value && date.isValid()) {
      this.onDateChange(date.format('YYYY-MM-DD'), false);
      this.onTimeChange(date.minute() + (date.hour() * 60), false);
    } else {
      this.onClear();
    }

    this.updateSubject.next();
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabledSubject.next(isDisabled);
  }

  public isDateValid(date: string): boolean {
    return date && dayjs(date).isValid();
  }

  public isTimeValid(minutes: number): boolean {
    return !isNull(minutes) && minutes > -1 && minutes < 1440;
  }

  private convertDateAndTimeToString(date: string, time: number): string {
    const { hours, minutes } = minutesTo(time);
    const timeString = (isNull(hours) || isNull(minutes) ? '00:00:00' : `${ hours }:${ minutes }:00`);
    const value = dayjs(`${ dayjs(date).format('YYYY-MM-DD') }T${ timeString }`);

    return value?.isValid() ? value.utc().format() : null;
  }
}
