import {ModuleWithProviders, NgModule} from '@angular/core';
import {CORE_STORE_CHANGE_TENANT_ACTION, CORE_STORE_TENANT_ID_SELECTOR} from './tokens';
import {MemoizedSelector} from '@ngrx/store';
import {AnyAction} from './types';

@NgModule()
export class CoreStoreModule {
  public static forRoot(
    tenantIdSelector: MemoizedSelector<any, number> = null,
    changeTenantAction: AnyAction = null
  ): ModuleWithProviders<CoreStoreModule> {
    return {
      ngModule: CoreStoreModule,
      providers: [
        {
          provide: CORE_STORE_TENANT_ID_SELECTOR,
          useValue: tenantIdSelector
        },
        {
          provide: CORE_STORE_CHANGE_TENANT_ACTION,
          useValue: changeTenantAction
        }
      ]
    };
  }
}
