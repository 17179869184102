import {EntityXsStore, EntityXsStoreState} from '@nexnox-web/core-store';
import {AppEntityType, LocationGroupDto} from '@nexnox-web/core-shared';
import {Action, createSelector} from '@ngrx/store';
import {selectLocationGroupsState} from '../../location-groups.selectors';
import {CorePortalFeatureMasterDataLocationGroupService} from '../../services';
import {Injectable, Injector} from '@angular/core';

export interface LocationGroupDetailStoreState extends EntityXsStoreState<LocationGroupDto> {
}

/* istanbul ignore next */
export const locationGroupDetailStore = new EntityXsStore<LocationGroupDto>({
  actionLabel: 'Core Portal - Master Data - Location Group Detail',
  stateSelector: createSelector(selectLocationGroupsState, state => state.locationGroupDetail),
  serviceType: CorePortalFeatureMasterDataLocationGroupService,
  entityType: AppEntityType.LocationGroup
});

export function locationGroupDetailStoreReducer(state: LocationGroupDetailStoreState, action: Action): any {
  return locationGroupDetailStore.reducer(state, action);
}

@Injectable()
export class LocationGroupDetailStoreEffects extends locationGroupDetailStore.effects {
  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }
}
