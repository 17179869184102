import {ModuleWithProviders, NgModule} from '@angular/core';
import {TasksDeclarationsModule} from './tasks-declarations.module';
import {TasksStoreModule} from './tasks-store.module';
import {TasksRoutingModule} from './tasks-routing.module';

@NgModule({
  imports: [
    TasksDeclarationsModule,
    TasksRoutingModule,
    TasksStoreModule
  ]
})
export class OrgaPortalFeatureTasksModule {
  public static forFeature(): ModuleWithProviders<TasksDeclarationsModule> {
    return { ngModule: TasksDeclarationsModule };
  }
}
