<ng-template #entityEditTemplate
             let-title="title">
  <nexnox-web-settings-manufacturer-edit #editComponent
                                         (modelChange)="createModel$.next($event)"
                                         [creating]="true"
                                         [loading]="(loaded$ | async) === false"
                                         [model]="createModel$ | async"
                                         [readonly]="false"
                                         [stereotyped]="false"
                                         [title]="title">
  </nexnox-web-settings-manufacturer-edit>
</ng-template>

<nexnox-web-merge-manufacturer-sidebar #mergeManufacturerSidebar
                                       (merge)="mergeManufacturer($event)"
                                       [manufacturer]="manufacturerToMergeSubject">
</nexnox-web-merge-manufacturer-sidebar>

<nexnox-web-entity-overview #overviewComponent
                            [entityEditTemplate]="entityEditTemplate"
                            [entityOverviewBaseComponent]="this"
                            [stereotyped]="false"
                            detailLink="manufacturers"
                            module="settings">
</nexnox-web-entity-overview>
