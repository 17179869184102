import {ChangeDetectionStrategy, Component, Injector, Input} from '@angular/core';
import {
  CorePortalEntityEditBaseComponent,
  CorePortalFormlyReadonlyTypes,
  CorePortalFormlyReadonlyTyping
} from '@nexnox-web/core-portal';
import {
  CombineOperator,
  DocumentTemplateContextType,
  DocumentTemplateSimpleDto,
  Filter,
  FilterOperators,
  FilterTypes,
  TicketByProcessDefinitionDto
} from '@nexnox-web/core-shared';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {TechPortalFeatureDocumentTemplateService} from '@nexnox-web/tech-portal/features/templates';
import {of} from 'rxjs';
import {distinctUntilChanged, startWith} from "rxjs/operators";

@Component({
  selector: 'nexnox-web-ticket-settings-processes-process-chat-edit',
  templateUrl: './process-chat-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProcessChatEditComponent extends CorePortalEntityEditBaseComponent<TicketByProcessDefinitionDto> {

  @Input() public isLinkableDefinition = false;

  constructor(
    protected injector: Injector,
    private documentTemplateService: TechPortalFeatureDocumentTemplateService
  ) {
    super(injector);
  }

  /* istanbul ignore next */
  protected createForm(): FormlyFieldConfig[] {
    return [
      {
        key: 'chat.isCreating',
        type: 'core-portal-switch',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-12',
        defaultValue: true,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.is-creating'
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.BOOLEAN
          } as CorePortalFormlyReadonlyTyping
        },
        expressionProperties: {
          'templateOptions.readonly': () => this.readonly,
          'templateOptions.disabled': () => this.readonly
        },
        hooks: {
          onInit: field => this.subscribe(field.formControl.valueChanges.pipe(
            startWith(field.formControl.value),
            distinctUntilChanged()
          ), value => {
            if (!value) {
              field.form.get('chat.attachmentTemplate').setValue(null);
            }
          })
        }
      },
      {
        key: 'chat.attachmentTemplate',
        type: 'core-portal-entity-select',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-12',
        defaultValue: null,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.template',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.ENTITY,
            displayKey: 'title',
            link: (row: DocumentTemplateSimpleDto) =>
              row?.documentTemplateId ? `/templates/document/${ row.documentTemplateId }` : null,
            module: 'settings'
          } as CorePortalFormlyReadonlyTyping,
          entityService: this.documentTemplateService,
          idKey: 'documentTemplateId',
          displayKey: 'title',
          wholeObject: true,
          skipGetOne: true,
          clearable: false,
          link: (row: DocumentTemplateSimpleDto) =>
            row?.documentTemplateId ? `/templates/document/${ row.documentTemplateId }` : null,
          module: 'settings',
          defaultFilters$: of([{
            type: FilterTypes.Grouped,
            combinedAs: CombineOperator.Or,
            children: [
              {
                type: FilterTypes.DataTransferObject,
                operator: FilterOperators.Equal,
                property: 'contextType',
                value: DocumentTemplateContextType.ExportTicket.toString()
              },
              {
                type: FilterTypes.DataTransferObject,
                operator: FilterOperators.Equal,
                property: 'contextType',
                value: DocumentTemplateContextType.ExportMissionByTicket.toString()
              }
            ]
          }] as Filter[])
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.readonly': () => this.readonly,
          'templateOptions.disabled': () => this.readonly || !this.model.chat.isCreating
        }
      }
    ];
  }
}
