import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {IconDefinition} from '@fortawesome/fontawesome-common-types';
import {faExchangeAlt} from '@fortawesome/free-solid-svg-icons/faExchangeAlt';
import {faQuestion} from '@fortawesome/free-solid-svg-icons/faQuestion';
import {ResourceEventListDto, ResourceEventType, ResourceState,} from '@nexnox-web/core-shared';
import {PagedEntitiesXsStoreEntityData} from '@nexnox-web/core-store';
import {BehaviorSubject, Observable} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {SafeHtml} from '@angular/platform-browser';
import {TranslateService} from "@ngx-translate/core";
import {faFolder} from "@fortawesome/free-solid-svg-icons/faFolder";

@Component({
  selector: 'nexnox-web-resources-resource-event-list-item',
  templateUrl: './resource-event-list-item.component.html',
  styleUrls: ['./resource-event-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResourceEventListItemComponent {

  @Input() public loading = false;
  @Input() public readonly: boolean;
  @Input() public loading$: Observable<boolean>;
  @Input() public loaded$: Observable<boolean>;
  @Input() public entityData: PagedEntitiesXsStoreEntityData;
  @Input() public isLastItem: boolean;
  public item$: Observable<ResourceEventListDto>;
  public createdAt$: Observable<string>;
  public description$: Observable<SafeHtml>;
  public isCollapsed$: Observable<boolean>;
  public isExpanded$: Observable<boolean>;
  private itemSubject: BehaviorSubject<ResourceEventListDto> = new BehaviorSubject<ResourceEventListDto>(null);

  constructor(private translate: TranslateService) {
    this.item$ = this.itemSubject.asObservable();

    this.isExpanded$ = this.isCollapsed$?.pipe(map((collapsed) => !collapsed));

    this.description$ = this.item$?.pipe(
      filter(item => Boolean(item))
    );

    this.createdAt$ = this.item$?.pipe(
      filter(item => Boolean(item)),
      map(item => item.createdAt)
    );
  }

  public get item(): ResourceEventListDto {
    return this.itemSubject.getValue();
  }

  @Input()
  public set item(item: ResourceEventListDto) {
    this.itemSubject.next(item);
  }

  public getTranslatedItem(item: ResourceEventListDto): string {
    switch (item?.type) {
      case ResourceEventType.ChangedState:
        return item.newState
          ? this.translate.instant('resources.descriptions.resource-event-item-state-change', { newState: this._getStateName(item.newState) })
          : this.translate.instant('resources.descriptions.resource-event-item-state-change-simple');
      case ResourceEventType.ChangedLocation:
        if (item.oldLocation && item.newLocation) {
          return this.translate.instant('resources.descriptions.resource-event-item-location-change',
            {
              oldLocation: item.oldLocation.name,
              newLocation: item.newLocation.name
            });
        }
        if (item.oldLocation) {
          return this.translate.instant('resources.descriptions.resource-event-item-location-removed', {
            oldLocation: item.oldLocation.name
          });
        }
        if (item.newLocation) {
          return this.translate.instant('resources.descriptions.resource-event-item-location-added', {
            newLocation: item.newLocation.name
          });
        }
        return this.translate.instant('resources.descriptions.resource-event-item-location-change-simple');
      default:
        return this.translate.instant('resources.descriptions.resource-event-item-state-change-simple');
    }
  }

  /* istanbul ignore next */
  public getResourceEventItemIcon(resourceEventItem: ResourceEventListDto): IconDefinition {
    switch (resourceEventItem?.type) {
      case ResourceEventType.ChangedState:
        return faExchangeAlt;
      case ResourceEventType.ChangedLocation:
        return faFolder;
      default:
        return faQuestion;
    }
  }

  private _getStateName(state: ResourceState): string {
    return this.translate.instant(`resources.resource-states.${ state }`);
  }
}
