<nexnox-web-entity-edit [entityEditBaseComponent]="this"
                        [isTitleEditable]="false"
                        [stereotyped]="false"
                        [useTitle]="true"
                        displayKey="name">
</nexnox-web-entity-edit>

<nexnox-web-cardbox [loading]="loading"
                    [title]="'core-portal.master-data.subtitles.holidays-detail' | translate"
                    paddingClasses="px-3 pb-3">
  <!-- Add Holiday -->
  <div *ngIf="!readonly" class="mb-3">
    <form [formGroup]="addHolidayForm">
      <formly-form (modelChange)="addHolidayModelSubject.next($event)"
                   [fields]="addHolidayFields"
                   [form]="addHolidayForm"
                   [model]="addHolidayModelSubject | async"
                   class="row w-100">
      </formly-form>
    </form>

    <div class="d-flex justify-content-end w-100">
      <button (click)="onAddHoliday()"
              [disabled]="addHolidayForm.invalid"
              [icon]="faPlus | nexnoxWebFaIconStringPipe"
              [label]="'core-portal.master-data.actions.holidays-add-holiday' | translate"
              [title]="'core-shared.shared.actions.add' | translate"
              class="p-button-secondary p-button-outlined mr-2"
              pButton
              type="button">
      </button>
    </div>
  </div>

  <!-- Holidays -->
  <div class="pt-2 px-2 pb-3">
    <nexnox-web-sortable-list (itemsChange)="onHolidaysChange($event)"
                              [customTrackByFn]="trackByHolidayIdFn"
                              [disabled]="readonly"
                              [editable]="false"
                              [hideItemName]="true"
                              [items]="holidays$ | async"
                              [notTitle]="true"
                              [sortable]="false">
      <ng-template let-item="item" nexnoxWebSortableListItemTemplate>
        <form [formGroup]="item.form" class="row w-100 align-items-center">
          <formly-form (modelChange)="onHolidayChange(item.id, $event)"
                       [fields]="item.fields"
                       [form]="item.form"
                       [model]="item.model"
                       class="col-12 px-0 row w-100 align-items-center">
          </formly-form>
        </form>
      </ng-template>
    </nexnox-web-sortable-list>
  </div>
</nexnox-web-cardbox>
