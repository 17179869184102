<ng-template #entityEditTemplate>

  <nexnox-web-settings-model-edit #modelComponent
                                  (modelChange)="onModelChange($event)"
                                  [creating]="isCreate$ | async"
                                  [loading]="loading$ | async"
                                  [model]="model$ | async"
                                  [readonly]="readonly$ | async"
                                  [stereotyped]="false"
                                  [title]="title | translate">
  </nexnox-web-settings-model-edit>
</ng-template>

<nexnox-web-move-model-sidebar #moveModelSidebar
                               (move)="moveModel($event)"
                               [modelToMove]="modelToMergeOrMoveSubject">
</nexnox-web-move-model-sidebar>

<nexnox-web-merge-model-sidebar #mergeModelSidebar
                                (merge)="mergeModel($event)"
                                [modelToMerge]="modelToMergeOrMoveSubject">
</nexnox-web-merge-model-sidebar>

<nexnox-web-entity-detail [entityDetailBaseComponent]="this"
                          [entityEditTemplate]="entityEditTemplate"
                          [useTabs]="false">
</nexnox-web-entity-detail>
