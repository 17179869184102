<p-sidebar #sidebarComponent
           (visibleChange)="onVisibleChange($event)"
           [transitionOptions]="transitionOptions"
           [visible]="visible$ | async"
           appendTo="body"
           position="right"
           styleClass="p-sidebar-lg">

  <h4>{{ 'missions.subtitles.editor-suggestions-current-editor' | translate }}</h4>
  <div class="d-flex flex-row mb-4">
    <ng-container *ngIf="currentSolutionContact$ | async as solutionContact; else noSolutionContactTemplate">
      <fa-icon [fixedWidth]="true"
               [icon]="faUser"
               class="mr-2"
               size="sm"></fa-icon>
      <a [nexnoxWebTenantRouterLink]="['/masterdata/contacts', solutionContact.contactId]"
         class="btn-link"
         module="management"
         target="_blank">
        {{ solutionContact.displayName }}
      </a>
    </ng-container>
    <ng-template #noSolutionContactTemplate>
      {{ 'core-shared.shared.select.empty' | translate }}
    </ng-template>
  </div>

  <ng-container *ngIf="quest$ | async as quest">
    <div class="row no-gutters mb-4 d-flex align-items-center">
      <div class="p-0">
        <h4 class="mb-0">{{ title$ | async }}</h4>
        <span class="small color-grey-700 mb-4">{{ info$ | async }}</span>
      </div>
      <div class="p-0 pl-4">
        <button (click)="onCancelQuest(quest)"
                [class.d-none]="quest.state !== questStates.InProcess"
                [icon]="faTimes | nexnoxWebFaIconStringPipe"
                [label]="'missions.actions.cancel-quest' | translate"
                class="p-button p-button-primary"
                pButton
                type="button">
        </button>

        <button (click)="onRestartQuest(quest)"
                [class.d-none]="quest.state < 3"
                [icon]="faArrowCircleRight | nexnoxWebFaIconStringPipe"
                [label]="'missions.actions.restart-quest' | translate"
                class="p-button p-button-primary"
                pButton
                type="button">
        </button>
      </div>
    </div>

    <div *ngIf="quest.state === questStates.Suggested" class="row no-gutters">
      <div class="col-6 p-0">
        <button (click)="onStartQuest(quest)"
                [icon]="faUsers | nexnoxWebFaIconStringPipe"
                [label]="'missions.actions.start-quest' | translate"
                class="p-button p-button-primary"
                pButton
                type="button">
        </button>
      </div>
    </div>

    <nexnox-web-basic-table *ngIf="quest.state !== questStates.Suggested"
                            [config]="config"
                            [data]="quest.requests"
                            [loading]="loading">
    </nexnox-web-basic-table>
  </ng-container>
</p-sidebar>
