<ng-container *ngIf="(selectedStereotypeId$ | async) && !loading">
  <ng-container *ngIf="customPropertySets$ | async as customPropertySets">
    <nexnox-web-cardbox *ngFor="let customPropertySet of customPropertySets; trackBy: trackSetBy; let isLast = last"
                        [noMarginBottom]="isLast && noMarginForLast"
                        [shadowMarginBottom]="isLast && noMarginForLast"
                        [title]="customPropertySet.name"
                        ghostPaddingClasses="p-3"
                        paddingClasses="px-2">
      <nexnox-web-custom-property-set-edit (modelChange)="onModelChange($event)"
                                           (modelValid)="onModelValid(customPropertySet, $event)"
                                           [creating]="creating"
                                           [customPropertySet]="customPropertySet"
                                           [inheritance]="inheritance"
                                           [isRateable]="isRateable$ | async"
                                           [model]="getModel()"
                                           [readonly]="readonly">
      </nexnox-web-custom-property-set-edit>
    </nexnox-web-cardbox>
  </ng-container>
</ng-container>
