import {NgModule} from '@angular/core';
import {CoreSharedModule} from '@nexnox-web/core-shared';
import {DashboardRoutingModule} from './dashboard-routing.module';
import {DashboardComponent, DashboardWrapperComponent} from './containers';
import {CorePortalFeatureSettingsDashboardsModule} from '@nexnox-web/core-portal/features/settings/features/dashboards';
import {
  CorePortalCardboxModule,
  CorePortalEntityDatatableModule,
  CorePortalEntitySelectModule
} from '@nexnox-web/core-portal';
import {NewDashboardItemDatatableComponent} from './components';
import {DashboardStoreModule} from './dashboard-store.module';
import {MissionsStoreModule} from "@nexnox-web/tech-portal/features/missions/src/lib/missions-store.module";

@NgModule({
  declarations: [
    DashboardWrapperComponent,
    DashboardComponent,
    NewDashboardItemDatatableComponent
  ],
  imports: [
    CoreSharedModule,
    CorePortalEntitySelectModule,
    CorePortalCardboxModule,
    CorePortalEntityDatatableModule,
    DashboardRoutingModule,
    DashboardStoreModule,
    CorePortalFeatureSettingsDashboardsModule.forFeature(),
    MissionsStoreModule
  ]
})
export class CorePortalFeatureDashboardModule {
}
