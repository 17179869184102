import {EntityXsStore, EntityXsStoreState} from '@nexnox-web/core-store';
import {AppEntityType, TaskJobStateReasonSetDto} from '@nexnox-web/core-shared';
import {createSelector} from '@ngrx/store';
import {selectCancellationReasonsState} from '../../cancellation-reasons.selectors';
import {OrgaPortalFeatureCancellationReasonsService} from '../../services';

export interface CancellationReasonsDetailStoreState extends EntityXsStoreState<TaskJobStateReasonSetDto> {
}

/* istanbul ignore next */
export const cancellationReasonsDetailStore = new EntityXsStore<TaskJobStateReasonSetDto>({
  actionLabel: 'Orga Portal - Cancellation Reasons - Cancellation Reasons Detail',
  stateSelector: createSelector(selectCancellationReasonsState, state => state.cancellationReasonsDetail),
  serviceType: OrgaPortalFeatureCancellationReasonsService,
  entityType: AppEntityType.None,
  stereotyped: false
});
