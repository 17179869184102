import {ChangeDetectionStrategy, Component, Injector, OnInit, Type, ViewChild, ViewContainerRef} from '@angular/core';
import {
  AppEntityType,
  CoreSharedApiBaseService,
  CoreSharedSidebarBaseComponent,
  ResourceInMissionDto
} from '@nexnox-web/core-shared';
import {BehaviorSubject, distinctUntilKeyChanged, firstValueFrom} from "rxjs";
import {CorePortalEntityEditBaseComponent} from "@nexnox-web/core-portal";
import {filter, map, shareReplay, take} from "rxjs/operators";
import {TechPortalFeatureFeatureResourceEditComponent} from "../../components/resource-edit/resource-edit.component";
import {TechPortalFeatureResourceService} from "../../store/services";

@Component({
  selector: 'nexnox-web-resource-detail-sidebar',
  templateUrl: './resource-detail-sidebar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResourceDetailSidebarComponent extends CoreSharedSidebarBaseComponent implements OnInit {
  @ViewChild('editComponentContainer', {
    read: ViewContainerRef,
    static: true
  }) public editContainer!: ViewContainerRef;

  public editComponentType: Type<CorePortalEntityEditBaseComponent<any>>;
  public editComponent: CorePortalEntityEditBaseComponent<any>;
  public resource$: BehaviorSubject<ResourceInMissionDto> = new BehaviorSubject<ResourceInMissionDto>(null);

  private resourceId: number;
  private resourceService: CoreSharedApiBaseService;
  private visibleResourceEditTabs = ['attachments', 'knowledge-articles'];

  constructor(
    private injector: Injector
  ) {
    super();
    this.resourceService = this.injector.get(TechPortalFeatureResourceService);
    this.editComponentType = TechPortalFeatureFeatureResourceEditComponent;
  }

  public async ngOnInit(): Promise<void> {
    if (this.resource$) {
      this.subscribe(this.resource$.pipe(
        filter(resource => Boolean(resource?.resourceId)),
        distinctUntilKeyChanged('resourceId')), (resource) => this._initSidebar(resource)
      );
    }
  }

  public restoreDefaults(): void {
    this.editContainer?.clear();
  }

  public onHide(): void {
    super.onHide();
    this.resource$.next(null);
  }

  public onShow(resource: ResourceInMissionDto): void {
    super.onShow();
    this.resource$.next(resource);
  }

  private _initSidebar(resource: ResourceInMissionDto): void {
    this.restoreDefaults();

    this.resourceId = resource.resourceId;

    this._createEditComponent().then();
  }

  private async _createEditComponent(): Promise<void> {
    // Pass config params
    this.editComponent = this.editContainer.createComponent(this.editComponentType).instance as CorePortalEntityEditBaseComponent<TechPortalFeatureFeatureResourceEditComponent>;

    // Configure edit
    this.editComponent.readonly = true;
    this.editComponent.loading = true;
    this.editComponent.title = 'resources.subtitles.resource-detail';
    (this.editComponent as TechPortalFeatureFeatureResourceEditComponent).visibleTabs = this.visibleResourceEditTabs;

    // Stereotypes
    // They get loaded once and then cached
    this.editComponent.stereotypes$ = this.resourceService.getStereotypes(AppEntityType.Resource, false).pipe(
      take(1),
      // Validate to empty array
      map((stereotypes) => stereotypes ?? []),
      // Cache
      shareReplay({
        bufferSize: 1,
        refCount: false,
      })
    );
    // Start loading stereotypes in any case (even if not stereotyped) to ensure clean startup loading animation
    this.subscribe(this.editComponent.stereotypes$.pipe(take(1)), async () => {
      // Get model
      this.editComponent.model = await firstValueFrom(this.resourceService.getOne(this.resourceId).pipe(take(1)));
      // Stop loading
      this.editComponent.loading = false;
    });
  }

}
