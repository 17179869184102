import {EntityXsStore, EntityXsStoreState} from '@nexnox-web/core-store';
import {AppEntityType, GeneralSettingDto} from '@nexnox-web/core-shared';
import {createSelector} from '@ngrx/store';
import {selectCoreState} from '../core.selectors';
import {CorePortalTenantSettingsService} from '../../../services/api';

export interface GeneralTenantSettingsStoreState extends EntityXsStoreState<GeneralSettingDto> {
}

export const generalTenantSettingsStore = new EntityXsStore<GeneralSettingDto>({
  actionLabel: 'Core Portal - Core - General Tenant Settings',
  stateSelector: createSelector(selectCoreState, state => state.generalTenantSettings),
  entityType: AppEntityType.None,
  serviceType: CorePortalTenantSettingsService,
  stereotyped: false
});
