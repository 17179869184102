import {
  DataTableCustomFilterDto,
  DataTableFilterDto,
  DataTableFilterType,
  DataTableGroupedFilterDto,
  DataTableTransferFilterDto,
  FilterDto,
  FilterKind,
  FilterTypes,
  GroupedFilterDto
} from './openapi-generated/src/models';
import {cloneDeep, isNull, isUndefined} from 'lodash';

export interface FilterDtoWithLabel extends FilterDto {
  label?: string;
  isComplex?: boolean;
}

export interface GroupedFilterDtoWithChildrenWithLabel extends GroupedFilterDto {
  children?: FilterDtoWithLabel[];
}

export type Filter = FilterDtoWithLabel & GroupedFilterDtoWithChildrenWithLabel;

export function mapDatatableFilterToFilter(
  filterField: DataTableTransferFilterDto & DataTableCustomFilterDto & DataTableGroupedFilterDto
): Filter {
  let filterProperty: string;
  let filterType: FilterTypes;

  switch (filterField.type) {
    case DataTableFilterType.ByTransfer:
      filterProperty = filterField.property;
      filterType = FilterTypes.DataTransferObject;
      break;
    case DataTableFilterType.ByCustomProperty:
      filterProperty = filterField.customPropertyId?.toString();
      filterType = FilterTypes.Custom;
      break;
    case DataTableFilterType.ByGroup: {
      // ToDo: Check if child is group and remap
      const children = filterField.children ?? [];
      const getProperty = (child: DataTableTransferFilterDto & DataTableCustomFilterDto & DataTableGroupedFilterDto): string => {
        return child.children ? null : (!isUndefined(child.customPropertyId) ? child.customPropertyId.toString() : child.property);
      }

      if (children.length > 1) {
        if (children.every(x => getProperty(x) === getProperty(children[0]))) {
          filterProperty = getProperty(children[0]);
        }
      } else {
        filterProperty = getProperty(children[0]);
      }

      filterType = FilterTypes.Grouped;
      break;
    }
  }

  return {
    type: filterType,
    operator: filterField.operator,
    property: filterProperty ?? filterField.property,
    kind: filterField.kind,
    value: filterField.value?.toString() ?? undefined,
    children: filterField.children?.map(child => mapDatatableFilterToFilter(child)),
    combinedAs: filterField.combinator,
    label: filterField.label
  };
}

export function mapFilterToDatatableFilter(filter: Filter): DataTableFilterDto {
  let filterProperty: string;
  let filterType: DataTableFilterType;

  switch (filter.type) {
    case FilterTypes.DataTransferObject:
      filterProperty = filter.property;
      filterType = DataTableFilterType.ByTransfer;
      break;
    case FilterTypes.Custom:
      filterType = DataTableFilterType.ByCustomProperty;
      break;
    case FilterTypes.Grouped:
      filterProperty = filter.property;
      filterType = DataTableFilterType.ByGroup;
      break;
    default:
      filterType = DataTableFilterType.Base;
      break;
  }

  return {
    property: filterProperty,
    customPropertyId: filter.type === FilterTypes.Custom ? parseInt(filter.property, 10) : undefined,
    operator: filter.operator,
    type: filterType,
    kind: filter.kind,
    value: filter.value,
    label: filter.label,
    children: filter.children?.map(x => mapFilterToDatatableFilter(x)) ?? undefined,
    combinator: filter.combinedAs ?? undefined
  } as DataTableFilterDto;
}

export function isFilterComplex(filter: Filter): boolean {
  if (filter.type === FilterTypes.Grouped) {
    return !filter.property;
  }

  return !isUndefined(filter.kind) && filter.kind !== FilterKind.Default;
}

export function isFilterDuplicate(
  filter: Filter,
  allFilters: Filter[],
  firstIteration: boolean = true,
  isGrouped: boolean = false
): boolean {
  const lookupFilters = cloneDeep(allFilters);

  if (firstIteration) {
    const filterIndex = lookupFilters.findIndex(x => x.property === filter.property);

    if (filterIndex > -1 && (lookupFilters[filterIndex].type !== FilterTypes.Grouped || isGrouped)) {
      lookupFilters.splice(filterIndex, 1);
    }
  }

  for (const lookupFilter of lookupFilters) {
    if (filter.property === lookupFilter.property) {
      return true;
    }

    if (lookupFilter.children?.length && isFilterDuplicate(filter, lookupFilter.children, false)) {
      return true;
    }
  }

  return false;
}

type CustomFilter<T> = Filter & T & { children?: T[] };

export function replaceFilterDeep<T>(
  filter: CustomFilter<T>,
  key: keyof T,
  allFilters: CustomFilter<T>[],
  replaceFn: (reference: CustomFilter<T>, deleteFilter: () => void) => void
): boolean {
  let returnValue = false;
  let deleteIndex: number;

  const deleteFilter = (index: number): number => deleteIndex = index;

  for (let i = 0; i < allFilters.length; i++) {
    if (allFilters[i][key] === filter[key]) {
      replaceFn(allFilters[i], () => deleteFilter(i));
      returnValue = true;
      break;
    }

    if (allFilters[i].children?.length && replaceFilterDeep(filter, key, allFilters[i].children, replaceFn)) {
      returnValue = true;
      break;
    }
  }

  if (!isUndefined(deleteIndex)) {
    allFilters.splice(deleteIndex, 1);
  }

  return returnValue;
}

export function isFilterValid(filter: Filter): boolean {
  const isNullOrUndefined = (value: any): boolean => isNull(value) || isUndefined(value);

  if (isNullOrUndefined(filter.type)) {
    return false;
  }

  if (filter.type !== FilterTypes.Grouped) {
    return !isNullOrUndefined(filter.property) && !isNullOrUndefined(filter.operator) && !isNullOrUndefined(filter.value);
  } else {
    return !isNullOrUndefined(filter.combinedAs) && filter.children?.length && (filter.children ?? []).every(x => isFilterValid(x));
  }
}
