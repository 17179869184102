import {PagedEntitiesXsStore, PagedEntitiesXsStoreState} from '@nexnox-web/core-store';
import {AppEntityType, ContractDto} from '@nexnox-web/core-shared';
import {Action, createSelector} from '@ngrx/store';
import {selectContractsState} from '../../contracts.selector';
import {TechPortalFeatureContractService} from '../../services';
import {Injectable, Injector} from '@angular/core';

export interface ContractListStoreState extends PagedEntitiesXsStoreState<ContractDto> {
}

/* istanbul ignore next */
export const contractListStore = new PagedEntitiesXsStore<ContractDto>({
  actionLabel: 'Tech Portal - Contracts - Contract List',
  stateSelector: createSelector(selectContractsState, state => state.contractList),
  serviceType: TechPortalFeatureContractService,
  entityType: AppEntityType.Contract,
  selectId: contract => contract.entity?.contractId ?? contract.model?.contractId
});

export function contractListStoreReducer(state: ContractListStoreState, action: Action): any {
  return contractListStore.reducer(state, action);
}

@Injectable()
export class ContractListStoreEffects extends contractListStore.effects {
  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }
}
