import {PagedEntitiesXsStore, PagedEntitiesXsStoreState} from '@nexnox-web/core-store';
import {AppEntityType, InfoDto} from '@nexnox-web/core-shared';
import {createSelector} from '@ngrx/store';
import {selectInfosState} from '../../infos.selectors';
import {OrgaPortalFeatureInfoService} from '../../services';

export interface InfoListStoreState extends PagedEntitiesXsStoreState<InfoDto> {
}

/* istanbul ignore next */
export const infoListStore = new PagedEntitiesXsStore<InfoDto>({
  actionLabel: 'Orga Portal - Infos - Info List',
  stateSelector: createSelector(selectInfosState, state => state.infoList),
  serviceType: OrgaPortalFeatureInfoService,
  entityType: AppEntityType.Info,
  selectId: info => info.entity?.infoId ?? info.model?.infoId,
  stereotyped: false
});
