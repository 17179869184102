import {PagedEntitiesXsStore, PagedEntitiesXsStoreState} from '@nexnox-web/core-store';
import {AppEntityType, SearchListListDto} from '@nexnox-web/core-shared';
import {Action, createSelector} from '@ngrx/store';
import {selectSearchListsState} from '../../search-lists.selectors';
import {CorePortalSearchListsService} from '../../services';
import {Injectable, Injector} from '@angular/core';

export interface SearchListsListStoreState extends PagedEntitiesXsStoreState<SearchListListDto> {
}

/* istanbul ignore next */
export const searchListsListStore = new PagedEntitiesXsStore<SearchListListDto>({
  actionLabel: 'Core Portal - Settings - Search Lists - Search Lists List',
  stateSelector: createSelector(selectSearchListsState, state => state.searchListsList),
  serviceType: CorePortalSearchListsService,
  entityType: AppEntityType.None,
  // @ts-ignore
  selectId: searchList => searchList.entity?.searchListId ?? searchList.model?.searchListId
});

export function searchListsListStoreReducer(state: SearchListsListStoreState, action: Action): any {
  return searchListsListStore.reducer(state, action);
}

@Injectable()
export class SearchListsListStoreEffects extends searchListsListStore.effects {
  constructor(
    protected override injector: Injector
  ) {
    super(injector);
  }
}
