import {EntityXsStore, EntityXsStoreState} from '@nexnox-web/core-store';
import {AppEntityType, PrioritySetDto} from '@nexnox-web/core-shared';
import {Action, createSelector} from '@ngrx/store';
import {selectPrioritySetsState} from '../../priority-sets.selectors';
import {TechPortalFeatureTicketSettingsPrioritySetService} from '../../services';
import {Injectable, Injector} from '@angular/core';

export interface PrioritySetDetailStoreState extends EntityXsStoreState<PrioritySetDto> {
}

/* istanbul ignore next */
export const prioritySetDetailStore = new EntityXsStore<PrioritySetDto>({
  actionLabel: 'Tech Portal - Ticket Settings - Priority Sets - Priority Set Detail',
  stateSelector: createSelector(selectPrioritySetsState, state => state.prioritySetDetail),
  serviceType: TechPortalFeatureTicketSettingsPrioritySetService,
  entityType: AppEntityType.None,
  stereotyped: false
});

export function prioritySetDetailStoreReducer(state: PrioritySetDetailStoreState, action: Action): any {
  return prioritySetDetailStore.reducer(state, action);
}

@Injectable()
export class PrioritySetDetailStoreEffects extends prioritySetDetailStore.effects {
  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }
}
