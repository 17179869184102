import {Injectable} from '@angular/core';
import {CoreSharedColorUtilityService,} from "../color-utility/color-utility.service";

export interface ICorePortalHexColorTheme {
  primary: string;
  primaryLight: string;
  contentBackground: string;
  sidebarBackground1: string;
  sidebarBackground2: string;
  sidebarBackground3: string;
  sidebarTextColor1: string;
  sidebarTextColor2: string;
}

@Injectable()
export class CoreSharedColorThemeService {

  constructor(
    private colorUtilityService: CoreSharedColorUtilityService
  ) {
  }

  public getLightTheme(primaryHexColor: string): ICorePortalHexColorTheme {
    const hsl = this.colorUtilityService.hexToHSL(primaryHexColor);

    return {
      primary: primaryHexColor,
      primaryLight: this.colorUtilityService.hslToHEX({ ...hsl, s: 60, l: 95 }),
      contentBackground: this.colorUtilityService.hslToHEX({ ...hsl, s: 3, l: 95 }),
      sidebarBackground1: this.colorUtilityService.hslToHEX({ ...hsl, s: 3, l: 88 }),
      sidebarBackground2: this.colorUtilityService.hslToHEX({ ...hsl, s: 3, l: 85 }),
      sidebarBackground3: this.colorUtilityService.hslToHEX({ ...hsl, s: 3, l: 81 }),
      sidebarTextColor1: this.colorUtilityService.hslToHEX({ ...hsl, s: 20, l: 10 }),
      sidebarTextColor2: this.colorUtilityService.hslToHEX({ ...hsl })
    }
  }
}
