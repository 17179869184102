import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
  selector: 'nexnox-web-tasks-planned-tasks',
  templateUrl: './tasks-planned-tasks.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TasksPlannedTasksComponent {
  @Input() public taskId: number;
  @Input() public isCollection: boolean;
}
