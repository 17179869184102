import {ChangeDetectionStrategy, Component, Injector, Input, OnInit} from '@angular/core';
import {IconDefinition} from '@fortawesome/fontawesome-common-types';
import {faFile} from '@fortawesome/free-solid-svg-icons/faFile';
import {faFileImage} from '@fortawesome/free-solid-svg-icons/faFileImage';
import {faFilePdf} from '@fortawesome/free-solid-svg-icons/faFilePdf';
import {faSpinner} from '@fortawesome/free-solid-svg-icons/faSpinner';
import {CoreSharedModalBaseComponent, CoreSharedSortableListItem, FileDto} from '@nexnox-web/core-shared';
import {BehaviorSubject, isObservable, NEVER, Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';

@Component({
  selector: 'nexnox-web-existing-attachments-modal',
  templateUrl: './existing-attachments-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CorePortalExistingAttachmentsModalComponent extends CoreSharedModalBaseComponent implements OnInit {
  @Input() public existingItems$: Observable<CoreSharedSortableListItem[]> = NEVER;

  public loading$: Observable<boolean>;

  public faSpinner = faSpinner;

  private selectedItemsSubject: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }

  public ngOnInit(): void {
    super.ngOnInit();

    if (isObservable(this.existingItems$)) {
      this.loading$ = this.existingItems$.pipe(
        map(() => false),
        startWith(true)
      );
    }
  }

  /* istanbul ignore next */
  public onSelectedItemsChange(items: any[]): void {
    this.selectedItemsSubject.next(items);
    items.length ? this.enableConfirmButton() : this.disableConfirmButton();
  }

  /* istanbul ignore next */
  public getFileImage(file: FileDto): IconDefinition {
    if (file.mimeType.includes('image')) {
      return faFileImage;
    } else if (file.mimeType.includes('pdf')) {
      return faFilePdf;
    }

    return faFile;
  }

  /* istanbul ignore next */
  protected onConfirmButtonAvailable(): void {
    super.onConfirmButtonAvailable();
    this.disableConfirmButton();
  }

  /* istanbul ignore next */
  protected onPreConfirm(): any {
    return this.selectedItemsSubject.getValue();
  }
}
