import {ChangeDetectionStrategy, Component, Injector} from '@angular/core';
import {CorePortalEntityOverviewBaseComponent, DatatableActionButton} from '@nexnox-web/core-portal';
import {AppEntityType, AppPermissions, Mappers} from '@nexnox-web/core-shared';
import {LocalOccurredHintListDto, resourceOccurredHintsListStore} from '../../../store';

@Component({
  selector: 'nexnox-web-resources-resource-occurred-hints',
  templateUrl: './occurred-hints-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResourceOccurredHintsComponent extends CorePortalEntityOverviewBaseComponent<LocalOccurredHintListDto> {
  public title = 'resources.subtitles.occurred-hints';
  public idProperty = 'hintCodeId';
  public displayProperty = 'displayName';
  public componentId = 'ResourceOccurredHintsComponent';
  public datatableConfigName = 'ResourceOccurredHintsDefault';

  constructor(
    protected injector: Injector
  ) {
    super(injector, resourceOccurredHintsListStore, Mappers.OccurredHintListDto.serializedName, AppEntityType.None);
  }

  public getRowActionButtons(): DatatableActionButton[] {
    return [
      ...this.getDefaultRowActionButtons(
        'tech-portal.connected.hint-codes.actions.edit',
        occurredHint => occurredHint?.hintCodeId ? `connected/hint-codes/${ occurredHint.hintCodeId }` : null,
        [AppPermissions.UpdateResourceTask],
        {
          module: 'settings'
        }
      )
    ];
  }

}
