import {
  CorePortalFeatureAuditListXsStore,
  CorePortalFeatureAuditListXsStoreState
} from '@nexnox-web/core-portal/features/audit';
import {Action, createSelector} from '@ngrx/store';
import {selectContractsState} from '../../contracts.selector';
import {Injectable, Injector} from '@angular/core';

export interface ContractAuditStoreState extends CorePortalFeatureAuditListXsStoreState {
}

/* istanbul ignore next */
export const contractAuditStore = new CorePortalFeatureAuditListXsStore({
  actionLabel: 'Tech Portal - Contracts - Contract Audit',
  stateSelector: createSelector(selectContractsState, state => state.contractAudit)
});

export function contractAuditStoreReducer(state: ContractAuditStoreState, action: Action): any {
  return contractAuditStore.reducer(state, action);
}

@Injectable()
export class ContractAuditStoreEffects extends contractAuditStore.effects {
  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }
}
