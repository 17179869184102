<nexnox-web-entity-edit *ngIf="!creating"
                        [entityEditBaseComponent]="this"
                        [stereotyped]="false"
                        displayKey="title">
</nexnox-web-entity-edit>

<nexnox-web-tabs *ngIf="creating"
                 [internal]="true"
                 [isTransparent]="true"
                 startTab="entityEdit">
  <!-- Entity Edit Tab -->
  <ng-template [hasError]="!isOwnModelValid()"
               [heading]="title | translate"
               nexnoxWebTabTemplate
               tabId="entityEdit">
    <nexnox-web-entity-edit [entityEditBaseComponent]="this"></nexnox-web-entity-edit>
  </ng-template>

  <!-- Attachments -->
  <ng-template [hasError]="attachmentsComponent ? !attachmentsComponent.isModelValid() : false"
               [heading]="('orga-portal.subtitles.attachments' | translate) + (attachmentsComponent ? (attachmentsComponent.attachmentsString$ | async) : '')"
               nexnoxWebTabTemplate
               tabId="attachments">
    <nexnox-web-orga-attachments #attachmentsComponent
                                 (attachmentsChange)="onAttachmentsChange($event)"
                                 [attachments$]="attachmentsSubject"
                                 [loading]="loading"
                                 [readonly]="readonly">
    </nexnox-web-orga-attachments>
  </ng-template>
</nexnox-web-tabs>

<!-- Category Templates -->
<ng-template #categorySelectLabelTitleTemplate let-item="item">
  <nexnox-web-categories-category-label [category]="item"></nexnox-web-categories-category-label>
</ng-template>

<ng-template #categorySelectOptionTitleTemplate let-item="item" let-searchTerm="searchTerm">
  <nexnox-web-categories-category-label [category]="item"
                                        [searchTerm]="searchTerm"></nexnox-web-categories-category-label>
</ng-template>
