import {ChangeDetectionStrategy, Component, Injector, Input, OnInit} from '@angular/core';
import {CorePortalEntityOverviewBaseComponent, DatatableActionButton} from '@nexnox-web/core-portal';
import {
  AppEntityType,
  FilterOperators,
  FilterTypes,
  Mappers,
  TaskJobDto,
  TaskJobListDto,
  TaskJobState
} from '@nexnox-web/core-shared';
import {locationTaskActiveListStore, LocationTaskjobService} from '../../store';
import {faTimesCircle} from '@fortawesome/free-solid-svg-icons/faTimesCircle';
import {ofType} from '@ngrx/effects';
import {filter} from 'rxjs/operators';

@Component({
  selector: 'nexnox-web-location-task-active-list',
  templateUrl: './location-task-active-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LocationTaskActiveListComponent extends CorePortalEntityOverviewBaseComponent<TaskJobListDto> implements OnInit {
  @Input() public locationId: number;

  public title = 'orga-portal.tasks.subtitles.active';
  public idProperty = 'taskJobId';
  public displayProperty = null;
  public datatableConfigName = 'LocationTaskArchiveActive';
  public componentId = 'LocationTaskActiveListComponent';
  public showDetailTemplateExpandButtonFn: (row: TaskJobDto) => boolean;

  constructor(
    protected injector: Injector,
    private taskjobService: LocationTaskjobService
  ) {
    super(injector, locationTaskActiveListStore, Mappers.TaskJobListDto.serializedName, AppEntityType.TaskJob);
  }

  public ngOnInit(): void {
    this.defaultFilter = [{
      property: 'locationId',
      type: FilterTypes.DataTransferObject,
      operator: FilterOperators.Equal,
      value: this.locationId.toString()
    }];
    this.showDetailTemplateExpandButtonFn = (row: TaskJobDto) => row?.children?.length > 0;
    super.ngOnInit();
  }

  public onCancelTaskJob(taskJob: TaskJobDto): void {
    this.modalService.showConfirmationModal(
      'orga-portal.tasks.actions.cancel-taskjob',
      'orga-portal.tasks.descriptions.cancel-taskjob',
      'error',
      'orga-portal.tasks.actions.cancel-taskjob'
    ).then(() => this.store.dispatch(locationTaskActiveListStore.actions.cancelOne({
      id: taskJob.taskJobId,
      parentId: taskJob.parentId
    }))).catch(() => null);
  }

  /* istanbul ignore next */
  public getRowActionButtons(): DatatableActionButton[] {
    return [
      {
        tooltip: 'core-portal.core.general.cancel',
        style: 'p-button-primary',
        isolate: true,
        icon: faTimesCircle,
        onClick: (row: TaskJobDto) => this.onCancelTaskJob(row),
        show: (row: TaskJobListDto) => row.currentStatus !== TaskJobState.Canceled && row.currentStatus !== TaskJobState.Done
      }
    ];
  }

  protected subscribeToActions(): void {
    super.subscribeToActions();

    this.subscribe(this.actions$.pipe(
      ofType(locationTaskActiveListStore.actions.cancelOneSuccess),
      filter(({ parentId }) => Boolean(parentId))
    ), () => this.getEntities());
  }
}
