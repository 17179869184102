import {NgModule} from '@angular/core';
import {CorePortalEntityModule} from '@nexnox-web/core-portal';
import {CoreSharedModule} from '@nexnox-web/core-shared';
import {TechPortalModule} from '@nexnox-web/tech-portal-lib';
import {
  TechPortalFeatureConnectedErrorCodeEditComponent,
  TechPortalFeatureErrorCodeExternalIdComponent
} from './components';
import {ErrorCodeDetailComponent, ErrorCodeListComponent} from './containers';
import {errorCodeServices} from './store';

@NgModule({
  declarations: [
    ErrorCodeListComponent,
    ErrorCodeDetailComponent,
    TechPortalFeatureConnectedErrorCodeEditComponent,
    TechPortalFeatureErrorCodeExternalIdComponent
  ],
  imports: [
    CoreSharedModule,
    CorePortalEntityModule,
    TechPortalModule
  ],
  providers: [
    ...errorCodeServices
  ],
  exports: [
    TechPortalFeatureConnectedErrorCodeEditComponent,
    TechPortalFeatureErrorCodeExternalIdComponent
  ]
})
export class ErrorCodesDeclarationsModule {
}
