<div class="mb-4">
  <nexnox-web-resources-resource-occurred-consumptions [canDelete]="false"
                                                       [getEntityOnModeChange]="false"
                                                       [isEmbedded]="true"
                                                       [parentIds]="parentIds">
  </nexnox-web-resources-resource-occurred-consumptions>
</div>
<div class="mb-4">
  <nexnox-web-resources-resource-occurred-errors [canDelete]="false"
                                                 [getEntityOnModeChange]="false"
                                                 [isEmbedded]="true"
                                                 [parentIds]="parentIds">
  </nexnox-web-resources-resource-occurred-errors>
</div>
<nexnox-web-resources-resource-occurred-hints [canDelete]="false"
                                              [getEntityOnModeChange]="false"
                                              [isEmbedded]="true"
                                              [parentIds]="parentIds">
</nexnox-web-resources-resource-occurred-hints>
