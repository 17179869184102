import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  BasicTableColumnType,
  BasicTableConfig,
  ContactSimpleDto,
  CoreSharedSidebarBaseComponent,
  FunctionDto,
  SuggestedContactDto,
  SuggestedEditorDto,
  SuggestedEditorsDto,
  SuggestedFunctionDto
} from '@nexnox-web/core-shared';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {faUser} from '@fortawesome/free-solid-svg-icons/faUser';
import {faUsers} from '@fortawesome/free-solid-svg-icons/faUsers';
import {faUserCheck} from '@fortawesome/free-solid-svg-icons/faUserCheck';
import {FormGroup} from "@angular/forms";
import {FormlyFieldConfig} from "@ngx-formly/core";
import {CorePortalFunctionService} from "@nexnox-web/core-portal";

export interface IEditorTableLocalDto {
  item: SuggestedEditorDto;
  name: string;
  type: string;
}

@Component({
  selector: 'nexnox-web-missions-editor-suggestions-sidebar',
  templateUrl: './editor-suggestions-sidebar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditorSuggestionsSidebarComponent extends CoreSharedSidebarBaseComponent implements OnInit {

  @Input() public editors$: Observable<SuggestedEditorsDto & IEditorTableLocalDto> = of(null);
  @Input() public currentSolutionContact$: Observable<ContactSimpleDto> = of(null);
  @Input() public loading: boolean;

  @Output() public startQuest: EventEmitter<FunctionDto> = new EventEmitter<FunctionDto>();
  @Output() public commissionContact: EventEmitter<ContactSimpleDto> = new EventEmitter<ContactSimpleDto>();


  // Function form
  public form: FormGroup;
  public model: any;
  public fields: FormlyFieldConfig[];

  public config: BasicTableConfig;

  public faUser = faUser;
  public faUserCheck = faUserCheck;
  public faUsers = faUsers;

  constructor(
    private functionService: CorePortalFunctionService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.editors$ = this.editors$.pipe(
      map(editors => this._mapEditorsTable(editors?.editors))
    );

    this.config = {
      columns: [
        {
          key: 'name',
          header: 'core-shared.shared.fields.contact',
          type: BasicTableColumnType.Link,
          link: (row) => row?.item?.contactId ? {
            commands: ['masterdata', 'contacts', row.item?.contactId],
            module: 'management'
          } : row?.item?.functionId ? {
            commands: ['masterdata', 'functions', row.item?.functionId],
            module: 'management'
          } : null
        },
        {
          key: 'type',
          header: 'missions.subtitles.mission-quest-type',
          type: BasicTableColumnType.TextTranslation
        }
      ],
      actions: [
        {
          icon: this.faUserCheck,
          tooltip: 'missions.actions.instruct-contact',
          label: 'missions.actions.instruct',
          class: 'p-button-secondary p-button-outlined',
          click: (row) => row.item?.contactId ? this.onCommissionContact(row.item) : null,
          hidden: (row) => !row.item?.contactId
        }, {
          icon: this.faUsers,
          tooltip: 'missions.actions.start-quest',
          label: 'missions.actions.start-quest',
          class: 'p-button-secondary p-button-outlined',
          click: (row) => row.item?.functionId ? this.onStartQuest(row.item) : null,
          hidden: (row) => !row.item?.functionId
        }
      ]
    };

    this._initializeFunctionForm();
  }

  public onHide(): void {
    super.onHide();
  }

  public onCommissionContact(contact: SuggestedContactDto): void {
    this.commissionContact.emit(contact);
    this.onHide();
  }

  public onStartQuest(functionGroup: SuggestedFunctionDto): void {
    this.startQuest.emit(functionGroup);
    this.onHide();
  }

  /* istanbul ignore next */
  private _initializeFunctionForm(): void {
    this.form = new FormGroup({});
    this.model = {};
    this.fields = [
      {
        key: 'functionGroup',
        type: 'core-portal-entity-select',
        wrappers: ['core-portal-translated'],
        className: 'w-100',
        defaultValue: 'all',
        templateOptions: {
          corePortalTranslated: {
            label: ''
          },
          entityService: this.functionService,
          idKey: 'functionId',
          displayKey: 'name',
          wholeObject: true,
          multiple: false,
          showAll: true,
          required: true
        }
      },
    ];
  }

  private _mapEditorsTable(editors: SuggestedEditorDto[]): any {
    return (editors ?? []).map(item => {
      const local = {} as IEditorTableLocalDto;
      local.item = item;
      local.name = item.name;
      local.type = 'missions.suggestion-target-types.' + item.type;
      return local;
    });
  }
}
