import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {
  CoreSharedAutofocusDirective,
  CoreSharedDisableFormControlDirective,
  CoreSharedResizedDirective,
  DisableRouterLinkDirective,
  LoadingIconDirective,
  VisibleObserverDirective
} from './directives';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {
  BasicTableComponent,
  CoreSharedInputWithTransformersComponent,
  DatePickerComponent,
  DateTimePickerComponent,
  DualListBoxComponent,
  LoadingIconComponent,
  ModalComponent,
  SnapComponent,
  StaticToastComponent,
  TimePickerComponent
} from './components';
import {ArticlePreviewSidebarComponent} from "./sidebars";
import {FormsModule} from '@angular/forms';
import {NgxMaskModule} from 'ngx-mask';
import {SweetAlert2Module} from '@sweetalert2/ngx-sweetalert2';
import {TranslateModule} from '@ngx-translate/core';
import {ConfirmModalComponent, DatepickerModalComponent} from './modals';
import {NgSelectModule} from '@ng-select/ng-select';
import {I18nBreadcrumbResolver, I18nEntityBreadcrumbResolver} from './breadcrumb-resolvers';
import {UnsavedChangesGuard, UnsavedCreateGuard} from './guards';
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import {CoreSharedSharedModule} from './modules/shared';
import {NexnoxWebBypassStylePipe, NexnoxWebDayjsRelativePipe} from './pipes';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {CheckboxModule} from 'primeng/checkbox';
import {CalendarModule} from 'primeng/calendar';
import {TableModule} from 'primeng/table';
import {SidebarModule} from "primeng/sidebar";
import {ProgressBarModule} from "primeng/progressbar";
import {CoreSharedLocationNumberTemplateComponent} from "./templates";

const sharedModules = [
  CoreSharedSharedModule
];

export const exportedComponents = [
  BasicTableComponent,
  LoadingIconComponent,
  DatePickerComponent,
  TimePickerComponent,
  DateTimePickerComponent,
  ModalComponent,
  DualListBoxComponent,
  SnapComponent,
  StaticToastComponent,
  CoreSharedInputWithTransformersComponent
];

export const exportedModals = [
  ConfirmModalComponent,
  DatepickerModalComponent
];

export const exportedDirectives = [
  DisableRouterLinkDirective,
  LoadingIconDirective,
  CoreSharedResizedDirective,
  CoreSharedDisableFormControlDirective,
  CoreSharedAutofocusDirective,
  VisibleObserverDirective
];

export const exportedGuards = [
  UnsavedChangesGuard,
  UnsavedCreateGuard
];

export const exportedBreadcrumbResolvers = [
  I18nBreadcrumbResolver,
  I18nEntityBreadcrumbResolver
];

export const exportedPipes = [
  NexnoxWebDayjsRelativePipe,
  NexnoxWebBypassStylePipe
];

export const exportedSidebars = [
  ArticlePreviewSidebarComponent
];

export const exportedTemplates = [
  CoreSharedLocationNumberTemplateComponent
]

@NgModule({
  declarations: [
    ...exportedComponents,
    ...exportedModals,
    ...exportedDirectives,
    ...exportedPipes,
    ...exportedSidebars,
    ...exportedTemplates],
  imports: [
    CommonModule,
    FontAwesomeModule,
    FormsModule,
    NgxMaskModule.forChild(),
    SweetAlert2Module.forChild(),
    TranslateModule,
    NgSelectModule,
    BsDatepickerModule,
    BsDropdownModule,
    CheckboxModule,
    CalendarModule,
    TableModule,
    SidebarModule,
    ProgressBarModule,
    ...sharedModules,
  ],
  exports: [
    ...exportedComponents,
    ...exportedModals,
    ...exportedDirectives,
    ...exportedPipes,
    ...exportedSidebars,
    ...sharedModules,
    ...exportedTemplates
  ],
  providers: [
    ...exportedGuards,
    ...exportedBreadcrumbResolvers
  ]
})
export class CoreSharedDeclarationsModule {
}
