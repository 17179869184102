<nexnox-web-entity-edit [entityEditBaseComponent]="this"
                        [stereotyped]="false"
                        displayKey="name">
</nexnox-web-entity-edit>

<nexnox-web-ticket-settings-processes-process-ticket-edit #ticketEditComponent
                                                          (modelChange)="onModelChange($event)"
                                                          [creating]="creating"
                                                          [isLinkableDefinition]="isLinkableDefinition$ | async"
                                                          [loading]="loading"
                                                          [model]="model"
                                                          [readonly]="readonly"
                                                          [title]="'core-portal.settings.subtitles.ticket-settings.process-ticket' | translate">
</nexnox-web-ticket-settings-processes-process-ticket-edit>

<nexnox-web-ticket-settings-processes-process-mission-edit #missionEditComponent
                                                           (modelChange)="onModelChange($event)"
                                                           [creating]="creating"
                                                           [loading]="loading"
                                                           [model]="model"
                                                           [readonly]="readonly"
                                                           [title]="'core-portal.settings.subtitles.ticket-settings.process-mission' | translate">
</nexnox-web-ticket-settings-processes-process-mission-edit>

<nexnox-web-ticket-settings-processes-process-chat-edit #chatEditComponent
                                                        (modelChange)="onModelChange($event)"
                                                        [creating]="creating"
                                                        [isLinkableDefinition]="isLinkableDefinition$  | async"
                                                        [loading]="loading"
                                                        [model]="model"
                                                        [readonly]="readonly"
                                                        [title]="'core-portal.settings.subtitles.ticket-settings.process-chat' | translate">
</nexnox-web-ticket-settings-processes-process-chat-edit>

<!-- Location Templates -->
<ng-template #locationSelectLabelTitleTemplate let-item="item">
  <nexnox-web-master-data-locations-location-location-number [label]="true"
                                                             [location]="item">
  </nexnox-web-master-data-locations-location-location-number>
</ng-template>

<ng-template #locationSelectOptionTitleTemplate let-item="item" let-searchTerm="searchTerm">
  <nexnox-web-master-data-locations-location-location-number [label]="false"
                                                             [location]="item"
                                                             [searchTerm]="searchTerm">
  </nexnox-web-master-data-locations-location-location-number>
</ng-template>
