<ng-container *ngIf="(loading$ | async) === false else loadingTemplate">
  <ng-container *ngIf="model$ | async as model">
    <ng-container *ngIf="model?.items?.length > 0  else noReceiptTemplate">
      <div class="row">
        <div class="col-xl-8 pl-0 pr-0 pr-xl-2">
          <nexnox-web-cardbox [noDividerMarginBottom]="true"
                              [title]="'missions.subtitles.mission-receipt' | translate">
            <nexnox-web-basic-table [config]="tableConfig"
                                    [data]="items$ | async">
            </nexnox-web-basic-table>
          </nexnox-web-cardbox>
        </div>
        <div class="col-xl-4 pl-0 pl-xl-2 pr-0">
          <nexnox-web-cardbox [paddingClasses]="'p-4'"
                              [title]="'core-shared.shared.table.total-items' | translate">
            <div class="d-flex flex-column mb-3">
              <label class="d-flex align-items-center form-label">
                {{ 'missions.fields.mission-receipt.before-tax' | translate }}
              </label>
              <div class="d-flex flex-row pl-3 form-control-plaintext">
                {{ model.beforeTax | nexnoxWebLocaleStringPipe:2 }} €
              </div>
            </div>

            <div class="d-flex flex-column mb-3">
              <label class="d-flex align-items-center form-label">
                {{ 'missions.fields.mission-receipt.after-tax' | translate }}
              </label>
              <div class="d-flex flex-row pl-3 form-control-plaintext">
                {{ model.afterTax | nexnoxWebLocaleStringPipe:2 }} €
              </div>
            </div>
          </nexnox-web-cardbox>
        </div>
      </div>
    </ng-container>
  </ng-container>
</ng-container>

<ng-template #noReceiptTemplate>
  <div class="d-flex w-100 h-100 align-items-center justify-content-center flex-column py-5">
    <fa-icon [icon]="faTimes"
             class="d-flex justify-content-center mb-2"
             size="5x"></fa-icon>
    <h4 class="d-flex justify-content-center">{{ 'missions.no-receipt.title' | translate }}</h4>
    <h6 class="d-flex justify-content-center">{{ 'missions.no-receipt.subtitle' | translate }}</h6>
  </div>
</ng-template>

<ng-template #loadingTemplate>
  <div class="row">
    <div class="col-xl-8 pl-0 pr-0 pr-xl-2">
      <nexnox-web-cardbox [loading]="true"
                          [paddingClasses]="'p-4'"
                          [title]="'missions.subtitles.mission-receipt' | translate">
      </nexnox-web-cardbox>
    </div>
    <div class="col-xl-4 pl-0 pl-xl-2 pr-0">
      <nexnox-web-cardbox [loading]="true"
                          [paddingClasses]="'p-4'"
                          [title]="'core-shared.shared.table.total-items' | translate">
      </nexnox-web-cardbox>
    </div>
  </div>
</ng-template>
