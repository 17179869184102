import {BaseXsStoreActions, createBaseXsStoreActions} from './base-xs-store.actions';
import {Action, ActionReducer, createReducer, MemoizedSelector, ReducerTypes} from '@ngrx/store';
import {Injectable, Injector, Type} from '@angular/core';
import {BaseXsStoreEffects} from './base-xs-store.effects';
import {isEqual, uniqWith} from 'lodash';
import {ActionCreator} from '@ngrx/store/src/models';

export type PackActionsTypes<Creators extends {
  [Key in keyof Creators]: Creators[Key] extends ActionCreator ? ActionCreator : never;
}> = Array<Creators[keyof Creators]>;

export type BaseXsStoreReducerTypes<State, Creators extends {
  [Key in keyof Creators]: Creators[Key] extends ActionCreator ? ActionCreator : never;
}> = ReducerTypes<State, PackActionsTypes<Creators>>;

export interface BaseXsStoreOptions<State> {
  actionLabel: string;
  stateSelector: MemoizedSelector<any, State>;
  initialState?: State;
  createEffectsArgs?: any[];
}

export class BaseXsStore<State> {
  public actions: BaseXsStoreActions;
  public reducer: ActionReducer<State, Action>;
  public effects: Type<BaseXsStoreEffects>;
  public selectors: any;

  constructor(
    protected options: BaseXsStoreOptions<State>
  ) {
    this.actions = this.createActions(options.actionLabel);
    this.reducer = this.createReducer(options.initialState ?? this.getInitialState());
    this.selectors = this.createSelectors();
    this.effects = this.createEffects(...(options.createEffectsArgs ?? []));
  }

  public static getType(label: string, type: string): string {
    return `[${ label }] ${ type }`;
  }

  public getInitialState(): State {
    return null;
  }

  protected createActions(label: string): BaseXsStoreActions {
    return createBaseXsStoreActions(label);
  }

  protected createReducer(initialState: State): ActionReducer<State, Action> {
    return createReducer(
      initialState,
      ...uniqWith(this.createReducerArray(initialState).reverse(), (a, b) => isEqual(a.types, b.types)).reverse()
    );
  }

  protected createReducerArray(initialState: State): BaseXsStoreReducerTypes<State, BaseXsStoreActions>[] {
    return [];
  }

  protected createEffects(...args: any[]): Type<BaseXsStoreEffects> {
    const actions = this.actions;
    const selectors = this.selectors;

    @Injectable()
    class Effects extends BaseXsStoreEffects {
      constructor(
        protected injector: Injector
      ) {
        super(injector, actions, selectors);
      }
    }

    return Effects;
  }

  protected createSelectors(): any {
    return {};
  }
}
