import {Injectable, Injector} from '@angular/core';
import {
  CommonDestinationDto,
  ControllerOperationId,
  CoreSharedApiBaseService,
  DataTableColumnDto,
  DataTableFilterDto,
  Product,
  ReportDataTableDto
} from '@nexnox-web/core-shared';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable()
export class CorePortalDatatableService extends CoreSharedApiBaseService {
  constructor(
    protected injector: Injector
  ) {
    super(injector, null, 'datatable');
  }

  public export(
    pageOperation: ControllerOperationId,
    destination: CommonDestinationDto,
    columns: DataTableColumnDto[],
    filters: DataTableFilterDto[],
    totalItems: number,
    tenantId: number,
    culture: string,
    requestBody: any = {},
    descendantId?: number
  ): Observable<void> {

    let apiPath = undefined;

    switch (pageOperation) {
      case ControllerOperationId.InfoProgressContactControllerId:
      case ControllerOperationId.InfoProgressLocationControllerId:
      case ControllerOperationId.InfoProgressFunctionControllerId:
      case ControllerOperationId.InfoControllerList:
      case ControllerOperationId.KnowledgeArticleControllerList:
      case ControllerOperationId.TaskControllerList:
      case ControllerOperationId.TaskJobDataLocationControllerList:
      case ControllerOperationId.TaskJobDataTaskControllerList:
        apiPath = this.environment.apiUrl[Product.ORGA];
        break;
    }

    return this.httpRequestService.post<void>('export', {
      pageOperation,
      destination,
      columns,
      filters,
      totalItems,
      tenantId,
      culture,
      descendantId,
      timezone: 'Europe/Berlin',
      requestBody
    } as ReportDataTableDto, null, null, true, apiPath).pipe(
      map(response => response.body)
    );
  }
}
