<div class="row settings-container">
  <!-- MENU -->
  <div class="col-sm-2 menu-container menu-scrollbar">
    <nexnox-web-settings-menu-sidebar [menu$]="menu$"
                                      [module]="module">
    </nexnox-web-settings-menu-sidebar>
  </div>

  <!-- SETTINGS CONTENT -->
  <div class="col-sm-10 overflow-auto h-100 px-0">
    <div class="mr-3 h-100 settings-content">
      <ng-content></ng-content>
    </div>
  </div>
</div>
