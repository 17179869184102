import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {FieldType, FormlyFieldConfig, FormlyTemplateOptions} from '@ngx-formly/core';
import {Observable} from "rxjs";

export interface FormlyLabelActionTemplateOptions extends FormlyTemplateOptions {
  value?: string;
  value$?: Observable<string>;
  async?: boolean;
  buttonLabel?: string;
  buttonClass?: string;
  click: (field: FormlyFieldConfig) => any;
  icon?;
}

@Component({
  selector: 'nexnox-web-formly-label-action',
  templateUrl: './formly-label-action.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormlyLabelActionComponent extends FieldType implements OnInit {

  public readonly to: FormlyLabelActionTemplateOptions;

  public ngOnInit(): void {
    // Security for empty properties
    this.to.click = this.to.click ?? (() => {
    });
    this.to.buttonClass = this.to.buttonClass ?? 'p-button-secondary p-button-outlined';
    this.to.buttonLabel = this.to.buttonLabel ?? '';
    this.to.icon = this.to.icon ?? {};
    this.to.value = this.to.value ?? 'No value found in template options (undefined)';
    this.to.async = this.to.async ?? false;
  }
}
