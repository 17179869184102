import {ChangeDetectionStrategy, Component, ElementRef, Inject, Injector, ViewChild} from '@angular/core';
import {CoreSharedImageService, CoreSharedModalBaseComponent, FileDto} from '@nexnox-web/core-shared';
import {SignaturePad} from 'ngx-signaturepad';
import {BehaviorSubject, Observable} from 'rxjs';
import {MemoizedSelector, select, Store} from '@ngrx/store';
import {mergeMap, take} from 'rxjs/operators';
import {HttpEventType} from '@angular/common/http';
import {CORE_STORE_TENANT_ID_SELECTOR} from '@nexnox-web/core-store';
import {faTrashAlt} from '@fortawesome/free-solid-svg-icons/faTrashAlt';

@Component({
  selector: 'nexnox-web-formly-signature-modal',
  templateUrl: './formly-signature-modal.component.html',
  styleUrls: ['./formly-signature-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormlySignatureModalComponent extends CoreSharedModalBaseComponent {
  @ViewChild('signaturePadWrapper', { static: true, read: ElementRef }) public signaturePadWrapper: ElementRef;
  @ViewChild('signaturePad') public signaturePad: SignaturePad;

  public signaturePadOptions = {
    canvasWidth: 9999,
    canvasHeight: 150,
    backgroundColor: '#ffffff'
  };

  public isEmpty$: Observable<boolean>;
  public isUploading$: Observable<boolean>;

  public faTrashAlt = faTrashAlt;

  private emptySubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private uploadSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    protected injector: Injector,
    @Inject(CORE_STORE_TENANT_ID_SELECTOR) private tenantIdSelector: MemoizedSelector<any, number>,
    private store: Store<any>,
    private imageService: CoreSharedImageService
  ) {
    super(injector);

    this.isEmpty$ = this.emptySubject.asObservable();
    this.isUploading$ = this.uploadSubject.asObservable();
  }

  public onDrawComplete(): void {
    this.emptySubject.next(this.signaturePad.isEmpty());
    this.emptySubject.getValue() ? this.disableConfirmButton() : this.enableConfirmButton();
  }

  public onClear(): void {
    this.signaturePad.clear();
    this.onDrawComplete();
  }

  public async uploadSignature(): Promise<FileDto> {
    if (this.emptySubject.getValue()) return;

    this.uploadSubject.next(true);
    const data = this.signaturePad.toDataURL('image/jpeg');
    const wrapper = this.signaturePadWrapper?.nativeElement;
    const cutData = await this.imageService.resizeImage(
      data,
      wrapper?.clientWidth,
      150,
      undefined,
      1,
      false
    ).toPromise();

    const uploadedImage = await new Promise<FileDto>((resolve, reject) => {
      this.store.pipe(
        select(this.tenantIdSelector),
        take(1),
        mergeMap(tenantId => this.imageService.uploadImage(cutData, tenantId, 1))
      ).subscribe(fileUploadEvent => {
        if (fileUploadEvent.type === HttpEventType.Response) {
          resolve(fileUploadEvent.body);
        }
      }, error => reject(error));
    });

    if (uploadedImage?.uri) {
      this.emptySubject.next(false);
    } else {
      this.signaturePad.clear();
      this.emptySubject.next(true);
    }

    this.uploadSubject.next(false);
    return uploadedImage;
  }

  /* istanbul ignore next */
  protected onConfirmButtonAvailable(): void {
    super.onConfirmButtonAvailable();
    this.disableConfirmButton();
  }

  /* istanbul ignore next */
  protected async onPreConfirm(): Promise<any> {
    return await this.uploadSignature();
  }
}
