<nexnox-web-cardbox [loading]="loading"
                    [title]="'controls.fields.notifications' | translate"
                    paddingClasses="px-3 pb-3">

  <div *ngIf="!readonly"
       class="mb-3">
    <form [formGroup]="notificationForm">
      <formly-form (modelChange)="notificationModelSubject.next($event)"
                   [fields]="notificationFields"
                   [form]="notificationForm"
                   [model]="notificationModelSubject.asObservable() | async"
                   class="row w-100">
      </formly-form>
    </form>

    <div class="d-flex justify-content-end w-100">
      <button (click)="onCancelEdit()"
              *ngIf="isEditingNotification$ | async"
              [label]="'core-portal.core.general.cancel' | translate"
              [title]="'core-portal.core.general.cancel' | translate"
              class="p-button-secondary p-button-outlined mr-2"
              pButton
              type="button">
      </button>
      <button (click)="handleNotificationAction()"
              [disabled]="notificationForm.invalid"
              [icon]="((isEditingNotification$ | async) ?  faCheck : faPlus)  | nexnoxWebFaIconStringPipe"
              [label]="((isEditingNotification$ | async) ? 'core-shared.shared.actions.accept' : 'core-shared.shared.actions.add') | translate"
              [title]="((isEditingNotification$ | async) ? 'core-shared.shared.actions.accept' : 'core-shared.shared.actions.add') | translate"
              class="p-button-secondary p-button-outlined mr-2"
              pButton
              type="button">
      </button>
    </div>
  </div>

  <div *ngIf="tableItems$ | async as items" class="pt-2 px-2 pb-3">
    <nexnox-web-basic-table [config]="tableConfig"
                            [data]="items">
    </nexnox-web-basic-table>
  </div>

</nexnox-web-cardbox>
