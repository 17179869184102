import {FieldType, FormlyTemplateOptions} from '@ngx-formly/core';
import {Component} from '@angular/core';

export interface CorePortalFormlyMultiToggleOption {
  label: string;
  value: string | number;
}

export interface CorePortalFormlyMultiToggleTyping {
  items?: CorePortalFormlyMultiToggleOption[];
  translate?: boolean;
}

interface FormlyMultiToggleTemplateOptions extends FormlyTemplateOptions {
  corePortalMultiToggle: CorePortalFormlyMultiToggleTyping;
}

@Component({
  selector: 'nexnox-web-formly-multi-toggle',
  templateUrl: './formly-multi-toggle.component.html'
})
export class FormlyMultiToggleComponent extends FieldType {
  public readonly to: FormlyMultiToggleTemplateOptions;
}
