import {EntityXsStore, EntityXsStoreState} from '@nexnox-web/core-store';
import {AppEntityType, SearchListDto} from '@nexnox-web/core-shared';
import {Action, createSelector} from '@ngrx/store';
import {CorePortalSearchListsService} from '../../services';
import {Injectable, Injector} from '@angular/core';
import {selectSearchListsState} from '../../search-lists.selectors';

export interface SearchListsDetailStoreState extends EntityXsStoreState<SearchListDto> {
}

/* istanbul ignore next */
export const searchListsDetailStore = new EntityXsStore<SearchListDto>({
  actionLabel: 'Core Portal - Settings - Search Lists - Search List Detail',
  stateSelector: createSelector(selectSearchListsState, state => state.searchListsDetail),
  serviceType: CorePortalSearchListsService,
  entityType: AppEntityType.None,
  stereotyped: false
});

export function searchListsDetailStoreReducer(state: SearchListsDetailStoreState, action: Action): any {
  return searchListsDetailStore.reducer(state, action);
}

@Injectable()
export class SearchListsDetailStoreEffects extends searchListsDetailStore.effects {
  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }
}
