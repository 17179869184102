<ng-template #entityEditTemplate>
  <ng-container *ngIf="isCreate$">
    <nexnox-web-settings-stereotypes-tickets-by-location-definition-edit #modelComponent
                                                                         (formTableValidityChange)="formTableValidSubject.next($event)"
                                                                         (modelChange)="onModelChange($event)"
                                                                         [creating]="isCreate$ | async"
                                                                         [loading]="loading$ | async"
                                                                         [model]="model$ | async"
                                                                         [readonly]="readonly$ | async"
                                                                         [stereotypeId]="stereotypeId"
                                                                         [title]="title | translate">
    </nexnox-web-settings-stereotypes-tickets-by-location-definition-edit>
  </ng-container>
</ng-template>

<nexnox-web-entity-detail [entityDetailBaseComponent]="this"
                          [entityEditTemplate]="entityEditTemplate"
                          [useTabs]="false">
</nexnox-web-entity-detail>

