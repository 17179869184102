import {CoreSharedApiBaseService, Product} from '@nexnox-web/core-shared';
import {Injectable, Injector} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable()
export class OrgaPortalFeatureInfoService extends CoreSharedApiBaseService {
  constructor(
    protected injector: Injector
  ) {
    super(injector, Product.ORGA, 'v2/info');
  }

  public push(id: number | string): Observable<any> {
    return this.httpRequestService.get(`${ this.buildPathForOne(id) }/push`).pipe(
      map(response => response.body)
    );
  }
}
