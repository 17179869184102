import {PagedEntitiesXsStore, PagedEntitiesXsStoreState} from '@nexnox-web/core-store';
import {AppEntityType, ArticleDto} from '@nexnox-web/core-shared';
import {Action, ActionReducer, createSelector} from '@ngrx/store';
import {selectArticlesState} from '../../articles.selector';
import {CorePortalFeatureArticleService} from '../../services';
import {Injectable, Injector} from '@angular/core';

export interface ArticleListStoreState extends PagedEntitiesXsStoreState<ArticleDto> {
}

/* istanbul ignore next */
export const articleListStore = new PagedEntitiesXsStore<ArticleDto>({
  actionLabel: 'Core Portal - Articles - Article List',
  stateSelector: createSelector(selectArticlesState, state => state.articleList),
  serviceType: CorePortalFeatureArticleService,
  entityType: AppEntityType.Article,
  selectId: article => article.entity?.articleId ?? article.model?.articleId
});

export function articleListStoreReducer(state: ArticleListStoreState, action: Action): ActionReducer<any> {
  return articleListStore.reducer(state, action);
}

@Injectable()
export class ArticleListStoreEffects extends articleListStore.effects {
  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }
}
