<div class="d-flex">
  <div (click)="openSideBar()"
       [class.receiver-container]="!to.disabled"
       class="form-control d-flex align-items-baseline"
  >
    <div class="text-truncate">
      <ng-container *ngFor="let item of items; let last = last">
        <a (click)="navigateTo(item.url)"
           class="link"
           style="cursor: pointer !important">
          {{ item.name }}
        </a><span>{{ last ? '' : ',&nbsp;' }}</span>
      </ng-container>
    </div>
    <div *ngIf="isShowItemsLength"
         class="small ml-2 text-nowrap">
      ({{ items.length }} {{ 'core-shared.shared.table.total-items' | translate | lowercase }})
    </div>
  </div>
  <button
    (click)="openSideBar()"
    [hidden]="to.disabled"
    class="btn btn-link">
    <fa-icon [icon]="faUserPlus"></fa-icon>
  </button>
</div>

<nexnox-web-formly-receivers-sidebar #receiversSidebar
                                     (accept)="onAccept()"
                                     *ngIf="receiverTabs as tabs"
                                     [items]="items"
                                     [receiverTabs]="tabs">
</nexnox-web-formly-receivers-sidebar>
