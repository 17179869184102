<ng-template #entityEditTemplate let-id="id" let-title="title">
  <nexnox-web-controls-control-edit #modelComponent
                                    (modelChange)="onModelChange($event)"
                                    [creating]="false"
                                    [id]="id"
                                    [loading]="loading$ | async"
                                    [model]="model$ | async"
                                    [readonly]="(readonly$ | async)"
                                    [stereotyped]="false"
                                    [title]="title">
  </nexnox-web-controls-control-edit>
</ng-template>

<nexnox-web-entity-detail [entityDetailBaseComponent]="this"
                          [entityEditTemplate]="entityEditTemplate"
                          [tabCommands]="['.']"
                          [tabHeading]="title | translate"
                          tabId="control">

  <!-- Control notification edit tab -->
  <ng-template (selectTab)="onNavigateToTab(['notifications'])"
               [heading]="'controls.fields.notifications' | translate"
               nexnoxWebTabTemplate
               tabId="notifications">
    <nexnox-web-controls-notification-edit (notificationsChanged)="onNotificationsChanged($event, model)"
                                           *ngIf="(model$ | async) as model"
                                           [controlContentType]="model.contentType"
                                           [loading]="loading$ | async"
                                           [notifications]="model.notifications ?? []"
                                           [readonly]="readonly$ | async">
    </nexnox-web-controls-notification-edit>
  </ng-template>

  <!--   Control resource edit tab -->
  <ng-template (selectTab)="onNavigateToTab(['resources'])"
               [hasError]="isNoResourceFilter$ | async"
               [heading]="'resources.subtitles.resource-list' | translate"
               nexnoxWebTabTemplate
               tabId="resources">
    <nexnox-web-control-resources-edit (datatableConfigChange)="onDatatableChanged($event, model)"
                                       *ngIf="passedIds && (model$ | async) as model"
                                       [canCreate]="false"
                                       [canRouteToDetail]="false"
                                       [dataTable]="model.dataTable"
                                       [isEmbedded]="true"
                                       [loading]="loading$ | async"
                                       [parentIds]="passedIds"
                                       [readonly]="readonly$ | async">
    </nexnox-web-control-resources-edit>
  </ng-template>

  <!--   Control issue list tab -->
  <ng-template (selectTab)="onNavigateToTab(['issues'])"
               [heading]="'core-portal.core.header.breadcrumbs.issues.issue-list' | translate"
               nexnoxWebTabTemplate
               tabId="issues">
    <nexnox-web-controls-control-issue-list (datatableConfigChange)="onDatatableChanged($event, model)"
                                            *ngIf="passedIds && (model$ | async) as model"
                                            [canCreate]="false"
                                            [canRouteToDetail]="false"
                                            [isEmbedded]="true"
                                            [parentIds]="passedIds"
                                            [readonly]="readonly$ | async">
    </nexnox-web-controls-control-issue-list>
  </ng-template>
</nexnox-web-entity-detail>


