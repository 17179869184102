import {ModuleWithProviders, NgModule} from '@angular/core';
import {ContractsStoreModule} from './contracts-store.module';
import {ContractsDeclarationsModule} from './contracts-declarations.module';
import {ContractsRoutingModule} from './contracts-routing.module';

@NgModule({
  imports: [
    ContractsDeclarationsModule,
    ContractsRoutingModule,
    ContractsStoreModule
  ]
})
export class TechPortalFeatureContractsModule {
  public static forFeature(): ModuleWithProviders<ContractsDeclarationsModule> {
    return { ngModule: ContractsDeclarationsModule };
  }
}
