import {BaseXsStoreReducerTypes, entityXsStoreSetLoadingForAction, EntityXsStoreState} from '@nexnox-web/core-store';
import {LocationDto, LocationState} from '@nexnox-web/core-shared';
import {LocationDetailXsStoreActions} from './location-detail-xs-store.actions';
import {immerOn} from 'ngrx-immer/store';

export const createLocationDetailXsStoreReducer = (
  actions: LocationDetailXsStoreActions,
  initialState: EntityXsStoreState<LocationDto>
): BaseXsStoreReducerTypes<EntityXsStoreState<LocationDto>, LocationDetailXsStoreActions>[] => ([
  immerOn(actions.closeLocation, draft => {
    draft.entityData = entityXsStoreSetLoadingForAction(draft.entityData, {
      [actions.closeLocation.type]: true
    });
  }),
  immerOn(actions.closeLocationSuccess, draft => {
    draft.entity = { ...draft.entity, state: LocationState.Closed };
    draft.model = { ...draft.model, state: LocationState.Closed };
    draft.entityData = entityXsStoreSetLoadingForAction(draft.entityData, {
      [actions.closeLocation.type]: false
    });
  }),

  immerOn(actions.openLocation, draft => {
    draft.entityData = entityXsStoreSetLoadingForAction(draft.entityData, {
      [actions.openLocation.type]: true
    });
  }),
  immerOn(actions.openLocationSuccess, draft => {
    draft.entity = { ...draft.entity, state: LocationState.Open };
    draft.model = { ...draft.model, state: LocationState.Open };
    draft.entityData = entityXsStoreSetLoadingForAction(draft.entityData, {
      [actions.openLocation.type]: false
    });
  })
]);
