<nexnox-web-cardbox [headerActions]="headerActions"
                    [loading]="loading"
                    [title]="title"
                    [withAccent]="true"
                    ghostPaddingClasses="px-3 pb-3"
                    paddingClasses="px-2">
  <div class="pt-2 px-2 pb-3">
    <!-- Working and Travel costs -->
    <div class="d-flex row align-items-center w-100 mb-2 mt-2">
      <h5>{{ 'core-shared.shared.fields.service' | translate }}</h5>
    </div>

    <nexnox-web-sortable-list *ngIf="(missionReportDurationItems$ | async) as items"
                              [disabled]="readonly"
                              [editable]="false"
                              [items]="items"
                              [sortable]="false">
      <ng-template let-item="item" nexnoxWebSortableListItemTemplate>
        <nexnox-web-missions-mission-report-article-duration-item (durationsChanged)="onDurationsChange($event)"
                                                                  *ngIf="item"
                                                                  [item]="item"
                                                                  [readonly]="readonly"
                                                                  [title]="item.title">
        </nexnox-web-missions-mission-report-article-duration-item>
      </ng-template>
    </nexnox-web-sortable-list>
  </div>

  <!-- Material-->
  <div *ngIf="!readonly" class="d-flex row align-items-center w-100 mb-2 mt-4 pl-2">
    <h5>{{ 'missions.actions.mission-report-add-article' | translate }}</h5>
  </div>

  <!-- Add Article -->
  <div *ngIf="!readonly" class="mb-3">
    <form [formGroup]="addArticleForm">
      <formly-form (modelChange)="addArticleModelSubject.next($event)"
                   [fields]="addArticlesFields"
                   [form]="addArticleForm"
                   [model]="addArticleModelSubject | async"
                   class="row w-100">
      </formly-form>
    </form>

    <div class="d-flex justify-content-end w-100">
      <button (click)="onAddArticle()"
              [disabled]="addArticleForm.invalid"
              [icon]="faPlus | nexnoxWebFaIconStringPipe"
              [label]="'missions.actions.mission-report-add-article' | translate"
              [title]="'core-shared.shared.actions.add' | translate"
              class="p-button-secondary p-button-outlined mr-2"
              pButton
              type="button">
      </button>
    </div>
  </div>

  <!-- Articles -->
  <div *ngIf="(missionReportUsedArticleItems$ | async).length > 0"
       class="d-flex row align-items-center w-100 mb-2 mt-3 pl-2">
    <h5>{{ 'core-shared.shared.fields.material' | translate }}</h5>
  </div>
  <div class="pt-2 px-2 pb-3">
    <nexnox-web-sortable-list (itemsChange)="onArticlesChange($event)"
                              *ngIf="(missionReportUsedArticleItems$ | async) as items"
                              [disabled]="readonly"
                              [editable]="false"
                              [items]="items"
                              [sortable]="true">
      <ng-template let-item="item" let-position="position" nexnoxWebSortableListItemTemplate>
        <!-- Article item -->
        <nexnox-web-missions-mission-report-article-item
          (articleChanged)="onArticleChange(position, $event)"
          [item]="item"
          [readonly]="readonly"
          [title]="item.title">
        </nexnox-web-missions-mission-report-article-item>
      </ng-template>
    </nexnox-web-sortable-list>

    <hr class="my-4">
    <div class="d-flex justify-content-end w-100">
      <h5>{{ 'missions.subtitles.mission-report-total' | translate }}: <u>{{ missionReportTotal$ | async }} €</u></h5>
    </div>

  </div>
</nexnox-web-cardbox>

<nexnox-web-entity-edit [customTitle]="'core-portal.settings.title' | translate"
                        [disableActions]="true"
                        [entityEditBaseComponent]="this"
                        [hideAccent]="true"
                        [isTitleEditable]="false"
                        [useTitle]="false">
</nexnox-web-entity-edit>
