import { Environment } from '@nexnox-web/core-shared';

export const environment: Environment = {
  production: false,
  title: 'semco ONE',
  version: '20241114.13',
  apiUrl: {
    identity: 'https://identity.dev.api.semco.app',
    orga: 'https://orga.dev.api.semco.app',
    data: 'https://data.dev.api.semco.app',
    tech: 'https://tech.dev.api.semco.app'
  },
  google: {
    apiKey: 'AIzaSyBINOesU6mmaz46mq5tDVKlfUBCfKXnEnA'
  },
  email: {
    bugReport: 'info@semco-services.de'
  },
  defaultPageSize: 15,
  defaultLanguage: 'de-DE',
  defaultTimeout: 120000
};
