import {
  addressCompanyListStore,
  AddressCompanyListStoreState,
  addressDetailStore,
  AddressDetailStoreState,
  addressListStore,
  AddressListStoreState,
  addressLocationListStore,
  AddressLocationListStoreState
} from './stores';

export interface AddressesState {
  addressDetail: AddressDetailStoreState;
  addressList: AddressListStoreState;
  addressLocationList: AddressLocationListStoreState;
  addressCompanyList: AddressCompanyListStoreState;
}

export const getInitialAddressesState = (): AddressesState => ({
  addressDetail: addressDetailStore.getInitialState(),
  addressList: addressListStore.getInitialState(),
  addressLocationList: addressLocationListStore.getInitialState(),
  addressCompanyList: addressCompanyListStore.getInitialState()
});
