import {ChangeDetectionStrategy, Component, Injector} from '@angular/core';
import {CorePortalEntityOverviewBaseComponent, DatatableActionButton} from '@nexnox-web/core-portal';
import {
  AppEntityType,
  ControllerOperationId,
  CoreSharedIssueLinkUtilityService,
  Mappers,
  MessageControlListDto
} from '@nexnox-web/core-shared';
import {controlIssueListStore} from '../../store/stores';
import {faExternalLinkAlt} from "@fortawesome/free-solid-svg-icons/faExternalLinkAlt";

@Component({
  selector: 'nexnox-web-controls-control-issue-list',
  templateUrl: './control-issue-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TechPortalFeatureControlIssueListComponent extends CorePortalEntityOverviewBaseComponent<MessageControlListDto> {
  public componentId = 'TechPortalFeatureControlIssueListComponent';
  public title = 'core-portal.core.header.breadcrumbs.issues.issue-list';
  public createTitle = 'controls.actions.create-issue-control';
  public pageOperation = ControllerOperationId.IssueControllerId;
  public enableViews = true;
  public idProperty = 'issueId';
  public module = 'communication';
  public displayProperty = 'issue.title';
  public datatableConfigName = 'ControlIssueDefault';

  public detailFn = (row: MessageControlListDto): void => {
    this.tenantRouter.navigate([this.issueUtilityService.getIssueSegments(row)], {module: this.module}).then()
  };

  constructor(
    protected injector: Injector,
    private issueUtilityService: CoreSharedIssueLinkUtilityService
  ) {
    super(injector, controlIssueListStore, Mappers.MessageControlListDto.serializedName, AppEntityType.None);
  }

  /* istanbul ignore next */
  public getRowActionButtons(): DatatableActionButton[] {
    return [{
      tooltip: 'core-shared.shared.actions.new-tab',
      icon: faExternalLinkAlt,
      link: (issue: MessageControlListDto) => issue.issueId ? this.issueUtilityService.getIssueLink(issue) : null,
      module: this.module,
      target: '_blank'
    }]
  }
}
