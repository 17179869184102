import {ChangeDetectionStrategy, Component, Injector} from '@angular/core';
import {CorePortalEntityOverviewBaseComponent, DatatableActionButton} from '@nexnox-web/core-portal';
import {
  AppEntityType,
  AppPermissions,
  ControllerOperationId,
  CoreSharedIssueLinkUtilityService,
  IssueListDto,
  Mappers
} from '@nexnox-web/core-shared';
import {issueListStore} from "../../store";
import {faExternalLinkAlt} from "@fortawesome/free-solid-svg-icons/faExternalLinkAlt";
import {Observable} from "rxjs";

@Component({
  selector: 'nexnox-web-issues-issue-list',
  templateUrl: './issue-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TechPortalFeatureIssueListComponent extends CorePortalEntityOverviewBaseComponent<IssueListDto> {

  public title = 'issues.subtitles.issue-list';
  public createTitle = 'issues.subtitles.issue-create';
  public idProperty = 'issueId';
  public displayProperty = 'title';
  public pageOperation = ControllerOperationId.IssueControllerId;
  public componentId = 'TechPortalFeatureIssueListComponent';
  public enableViews = true;
  public datatableConfigName = 'IssueDefault';
  public canDelete = false;

  public shouldShowMessageControlIssuesTab$: Observable<boolean>;
  public shouldShowMessageIssuesTab$: Observable<boolean>;
  public shouldShowMissionIssuesTab$: Observable<boolean>;
  public shouldShowTicketIssuesTab$: Observable<boolean>;
  public shouldShowWinterhalterServiceCallIssuesTab$: Observable<boolean>;
  public shouldShowWinterhalterProductOrderIssuesTab$: Observable<boolean>;

  constructor(
    protected injector: Injector,
    private issueUtilityService: CoreSharedIssueLinkUtilityService
  ) {
    super(injector, issueListStore, Mappers.IssueListDto.serializedName, AppEntityType.Issue);

    this.shouldShowMessageControlIssuesTab$ = this.permissionService.hasPermission$(AppPermissions.ReadMessageControlContent);
    this.shouldShowMessageIssuesTab$ = this.permissionService.hasPermission$(AppPermissions.ReadMessageIssueContent);
    this.shouldShowMissionIssuesTab$ = this.permissionService.hasPermission$(AppPermissions.ReadMissionIssueContent);
    this.shouldShowTicketIssuesTab$ = this.permissionService.hasPermission$(AppPermissions.ReadTicketIssueContent);
    this.shouldShowWinterhalterServiceCallIssuesTab$ = this.permissionService.hasPermission$(AppPermissions.ReadWinterhalterServiceIssueContent);
    this.shouldShowWinterhalterProductOrderIssuesTab$ = this.permissionService.hasPermission$(AppPermissions.ReadWinterhalterOrderIssueContent);
  }

  public detailFn = (row: IssueListDto): void => {
    this.tenantRouter.navigate(this.issueUtilityService.getIssueSegments(row)).then()
  };

  /* istanbul ignore next */
  public getRowActionButtons(): DatatableActionButton[] {
    return [
      {
        tooltip: 'core-shared.shared.actions.new-tab',
        icon: faExternalLinkAlt,
        link: (row: IssueListDto) => this.issueUtilityService.getIssueLink(row),
        module: 'communication',
        target: '_blank'
      }
    ];
  }
}
