<ng-container *ngIf="!to.corePortalEditor?.noHTML; else noHTML">
  <div #editorContainerElement class="editor-container editor-container_document-editor">
    <div #editorToolbarElement class="editor-container__toolbar"></div>
    <div class="editor-container__editor-wrapper">
      <div class="editor-container__editor">
        <div #editorElement>
          <ckeditor (ready)="onReady($event)"
                    *ngIf="isLayoutReadySubject | async; else loading"
                    [config]="config"
                    [disableTwoWayDataBinding]="false"
                    [editor]="editor"
                    [formControl]="formControl"
                    [formlyAttributes]="field">
          </ckeditor>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #noHTML>
  <textarea #textAreaElement
            [formControl]="formControl"
            [formlyAttributes]="field"
            [rows]="to.rows"
            class="form-control">
  </textarea>
</ng-template>

<ng-container *ngIf="!to.disabled && !to.corePortalEditor?.noHTML">
  <div *ngIf="$any(to.corePortalEditor?.buttons) as buttons">
    <button (click)="button.click()"
            *ngFor="let button of buttons"
            [class.d-none]="button.show$ ? (button.show$ | async) === false : false"
            class="btn btn-outline-secondary mt-2">
      {{ button.label | translate }}
    </button>
  </div>
</ng-container>

<ng-template #loading>
  <input [value]="'core-portal.core.general.loading' | translate" class="form-control w-100" disabled>
</ng-template>
