import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {
  CoreSharedSidebarBaseComponent,
  IAssignResourcDto,
  LocationSimpleDto,
  ResourceDto
} from '@nexnox-web/core-shared';
import {DatatableSelectionMode} from "@nexnox-web/core-portal";
import {faTimes} from '@fortawesome/free-solid-svg-icons/faTimes';
import {BehaviorSubject} from "rxjs";
import {cloneDeep} from "lodash";
import {faFolder} from '@fortawesome/free-solid-svg-icons/faFolder';


@Component({
  selector: 'nexnox-web-mission-assign-resource-sidebar',
  templateUrl: './assign-resource-sidebar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MissionAssignResourceSidebarComponent extends CoreSharedSidebarBaseComponent {

  @Input() public create: boolean;
  @Output() public assignResource: EventEmitter<IAssignResourcDto> = new EventEmitter<IAssignResourcDto>();
  public locationSubject: BehaviorSubject<LocationSimpleDto> = new BehaviorSubject<LocationSimpleDto>(null);
  public selectionModes = DatatableSelectionMode;
  public selectedResource: ResourceDto;
  public faTimes = faTimes;
  public faFolder = faFolder;

  constructor() {
    super();
  }

  public get location(): LocationSimpleDto {
    return this.locationSubject.getValue();
  };

  @Input()
  public set location(location: LocationSimpleDto) {
    this.locationSubject.next(location);
  }

  public onAssignResource(isUpdateRelations: boolean = false): void {
    this.assignResource.emit({ resource: this.selectedResource, isUpdateRelations: isUpdateRelations });
    this.onHide();
  }

  public onSelectionChange(resource: any): void {
    this.selectedResource = cloneDeep(resource);
  }
}
