import {
  BaseXsStore,
  BaseXsStoreReducerTypes,
  createPagedEntitiesXsStoreActions,
  PagedEntitiesXsStore,
  PagedEntitiesXsStoreEffects,
  PagedEntitiesXsStoreState
} from '@nexnox-web/core-store';
import {AppEntityType, CoreSharedApiBaseService, PingDto} from '@nexnox-web/core-shared';
import {DevicePingListXsStoreActions, DevicePingListXsStorePingOnePayload} from './device-ping-list-xs-store.actions';
import {Action, createAction, props, select} from '@ngrx/store';
import {Injectable, Injector, Type} from '@angular/core';
import {OrgaPortalFeaturePingService} from '../../services';
import {createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap, withLatestFrom} from 'rxjs/operators';
import {authStore} from '@nexnox-web/core-portal';
import {of} from 'rxjs';
import {immerOn} from 'ngrx-immer/store';

export interface DevicePingListXsStoreState extends PagedEntitiesXsStoreState<PingDto> {
}

export class DevicePingListXsStore extends PagedEntitiesXsStore<PingDto, PingDto, DevicePingListXsStoreState> {
  public actions: DevicePingListXsStoreActions;

  protected createActions(label: string): DevicePingListXsStoreActions {
    return {
      ...createPagedEntitiesXsStoreActions(label),

      pingOne: createAction(
        BaseXsStore.getType(label, 'Ping'),
        props<DevicePingListXsStorePingOnePayload>()
      ),
      pingOneSuccess: createAction(
        BaseXsStore.getType(label, 'Ping success'),
        props<DevicePingListXsStorePingOnePayload>()
      )
    };
  }

  protected createReducerArray(
    initialState: DevicePingListXsStoreState
  ): BaseXsStoreReducerTypes<DevicePingListXsStoreState, DevicePingListXsStoreActions>[] {
    return [
      ...super.createReducerArray(initialState),

      immerOn(this.actions.pingOne, draft => {
        draft.loading = true;
      })
    ];
  }

  protected createEffects(
    serviceType: Type<CoreSharedApiBaseService>,
    entityType: AppEntityType,
    prepareEntity: (entity: PingDto) => PingDto,
    prepareModel: (entity: PingDto, model: PingDto) => PingDto,
    sanitizeModel: (model: PingDto, entity: PingDto) => PingDto,
    ...args
  ): Type<PagedEntitiesXsStoreEffects<PingDto, PingDto, DevicePingListXsStoreState>> {
    const actions = this.actions;
    const selectors = this.selectors;

    @Injectable()
    class Effects extends PagedEntitiesXsStoreEffects<PingDto> {
      public pingOne$: any;
      public pingOneSuccess$: any;

      protected actions: DevicePingListXsStoreActions;
      protected service: OrgaPortalFeaturePingService;

      constructor(
        protected injector: Injector
      ) {
        super(injector, actions, selectors, serviceType, entityType, prepareEntity, prepareModel, sanitizeModel, false);
      }

      protected createEffects(): void {
        super.createEffects();

        this.pingOne$ = createEffect(() => this.actions$.pipe(
          ofType(this.actions.pingOne),
          withLatestFrom(this.store.pipe(select(authStore.selectors.selectTenantId))),
          switchMap(([{ deviceId }, tenantId]) => this.service.ping(deviceId, tenantId).pipe(
            map(() => this.actions.pingOneSuccess({ deviceId })),
            catchError(error => of(this.actions.error({ error, action: this.actions.pingOne })))
          ))
        ));

        this.pingOneSuccess$ = createEffect(() => this.actions$.pipe(
          ofType(this.actions.pingOneSuccess),
          map(action => this.actionCallback(action, false))
        ), { dispatch: false });
      }

      protected actionCallback(action: Action, isError: boolean = false): void {
        super.actionCallback(action, isError);

        this.checkAction(this.actions.pingOneSuccess, action, () => this.pingOneSuccess());
      }

      protected pingOneSuccess(): void {
        this.apiNotificationService.showTranslatedSuccess('orga-portal.devices.toasts.device-pinged');
      }
    }

    return Effects;
  }
}
