import {Route, RouterModule, Routes} from '@angular/router';
import {
  ResourcesByLocationStereotypeDetailComponent,
  StereotypeDetailComponent,
  StereotypeListComponent,
  TicketsByLocationStereotypeDetailComponent
} from './containers';
import {NgModule} from '@angular/core';
import {AppPermissions, I18nBreadcrumbResolver, UnsavedChangesGuard, UnsavedCreateGuard} from '@nexnox-web/core-shared';
import {CorePortalPermissionGuard} from '@nexnox-web/core-portal';
import {
  AddressStereotypeListWrapperComponent,
  ArticleStereotypeListWrapperComponent,
  CompanyStereotypeListWrapperComponent,
  ContactStereotypeListWrapperComponent,
  ContractStereotypeListWrapperComponent,
  FunctionStereotypeListWrapperComponent,
  IssueStereotypeListWrapperComponent,
  LocationGroupStereotypeListWrapperComponent,
  LocationStereotypeListWrapperComponent,
  MissionReportStereotypeListWrapperComponent,
  MissionStereotypeListWrapperComponent,
  ResourceStereotypeListWrapperComponent,
  TicketStereotypeListWrapperComponent
} from "./wrappers";

function generateBaseStereotypeRoutes(path: string, wrapperComponent: any, hasDetailId: boolean = false): Routes {
  const routes: Routes = [
    {
      path,
      canActivate: [CorePortalPermissionGuard],
      canDeactivate: [UnsavedCreateGuard],
      component: wrapperComponent
    }
  ];
  if (hasDetailId) {
    routes.push({
      path: `${ path }/:stereotypeId`,
      ...stereotypeDetailRoute
    })
  }
  return routes;
}

const stereotypeDetailRoute: Route = {
  component: StereotypeDetailComponent,
  canActivate: [CorePortalPermissionGuard],
  canDeactivate: [UnsavedChangesGuard],
  data: {
    permissions: [AppPermissions.ReadStereotype],
    breadcrumbs: I18nBreadcrumbResolver,
    tab: 'stereotype',
    text: 'core-portal.core.header.breadcrumbs.settings.stereotype'
  },
  children: [
    {
      path: 'contract-settings',
      data: {
        tab: 'contract-settings'
      },
      children: []
    },
    {
      path: 'mission-restrictions',
      data: {
        tab: 'mission-restrictions'
      },
      children: []
    },
    {
      path: 'resources-by-location',
      data: {
        tab: 'resources-by-location',
        breadcrumbs: I18nBreadcrumbResolver,
        permissions: AppPermissions.ReadResourcesByLocationDefinition,
        text: 'core-shared.shared.skeletons.resource-skeletons'
      },
      children: []
    },
    {
      path: 'tickets-by-location',
      data: {
        tab: 'tickets-by-location',
        breadcrumbs: I18nBreadcrumbResolver,
        permissions: AppPermissions.ReadTicketsByLocationDefinition,
        text: 'core-shared.shared.skeletons.ticket-skeletons'
      },
      children: []
    }
  ]
};

export const CORE_PORTAL_SETTINGS_STEREOTYPES_ROUTES: Routes = [
  ...generateBaseStereotypeRoutes('address-stereotypes', AddressStereotypeListWrapperComponent, true),
  ...generateBaseStereotypeRoutes('article-stereotypes', ArticleStereotypeListWrapperComponent, true),
  ...generateBaseStereotypeRoutes('company-stereotypes', CompanyStereotypeListWrapperComponent, true),
  ...generateBaseStereotypeRoutes('contact-stereotypes', ContactStereotypeListWrapperComponent, true),
  ...generateBaseStereotypeRoutes('contract-stereotypes', ContractStereotypeListWrapperComponent, true),
  ...generateBaseStereotypeRoutes('function-stereotypes', FunctionStereotypeListWrapperComponent, true),
  ...generateBaseStereotypeRoutes('issue-stereotypes', IssueStereotypeListWrapperComponent, true),
  ...generateBaseStereotypeRoutes('location-group-stereotypes', LocationGroupStereotypeListWrapperComponent, true),
  ...generateBaseStereotypeRoutes('location-stereotypes', LocationStereotypeListWrapperComponent, true),
  {
    path: 'location-stereotypes/:stereotypeId/resources-by-location',
    data: {
      tab: 'resources-by-location',
      breadcrumbs: I18nBreadcrumbResolver,
      permissions: AppPermissions.ReadResourcesByLocationDefinition,
      text: 'core-shared.shared.skeletons.resource-skeletons'
    },
    children: [
      {
        path: 'create',
        component: ResourcesByLocationStereotypeDetailComponent,
        canActivate: [CorePortalPermissionGuard],
        canDeactivate: [UnsavedChangesGuard],
        data: {
          permissions: [AppPermissions.CreateResourcesByLocationDefinition],
          breadcrumbs: I18nBreadcrumbResolver,
          text: 'core-shared.shared.skeletons.skeleton'
        }
      },
      {
        path: ':resourcesByLocationDefinitionId',
        component: ResourcesByLocationStereotypeDetailComponent,
        canActivate: [CorePortalPermissionGuard],
        canDeactivate: [UnsavedChangesGuard],
        data: {
          permissions: [AppPermissions.UpdateResourcesByLocationDefinition],
          breadcrumbs: I18nBreadcrumbResolver,
          text: 'core-shared.shared.skeletons.skeleton'
        }
      }
    ]
  },
  {
    path: 'location-stereotypes/:stereotypeId/tickets-by-location',
    data: {
      tab: 'tickets-by-location',
      breadcrumbs: I18nBreadcrumbResolver,
      permissions: AppPermissions.ReadTicketsByLocationDefinition,
      text: 'core-shared.shared.skeletons.ticket-skeletons'
    },
    children: [
      {
        path: 'create',
        component: TicketsByLocationStereotypeDetailComponent,
        canActivate: [CorePortalPermissionGuard],
        canDeactivate: [UnsavedChangesGuard],
        data: {
          permissions: [AppPermissions.CreateTicketsByLocationDefinition],
          breadcrumbs: I18nBreadcrumbResolver,
          text: 'core-shared.shared.skeletons.skeleton'
        },
      },
      {
        path: ':ticketsByLocationDefinitionId',
        component: TicketsByLocationStereotypeDetailComponent,
        canActivate: [CorePortalPermissionGuard],
        canDeactivate: [UnsavedChangesGuard],
        data: {
          permissions: [AppPermissions.UpdateTicketsByLocationDefinition],
          breadcrumbs: I18nBreadcrumbResolver,
          text: 'core-shared.shared.skeletons.skeleton'
        }
      }
    ]
  },
  ...generateBaseStereotypeRoutes('mission-report-stereotypes', MissionReportStereotypeListWrapperComponent, true),
  ...generateBaseStereotypeRoutes('mission-stereotypes', MissionStereotypeListWrapperComponent, true),
  ...generateBaseStereotypeRoutes('resource-stereotypes', ResourceStereotypeListWrapperComponent, true),
  ...generateBaseStereotypeRoutes('ticket-stereotypes', TicketStereotypeListWrapperComponent, true),

  // all stereotypes (old path, fallback route)
  {
    path: '',
    canActivate: [CorePortalPermissionGuard],
    canDeactivate: [UnsavedCreateGuard],
    component: StereotypeListComponent
  },
  {
    path: ':stereotypeId',
    ...stereotypeDetailRoute
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(CORE_PORTAL_SETTINGS_STEREOTYPES_ROUTES)
  ],
  exports: [
    RouterModule
  ]
})
export class StereotypesRoutingModule {
}
