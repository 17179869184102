import {ChangeDetectionStrategy, Component} from '@angular/core';
import {faExclamationTriangle} from '@fortawesome/free-solid-svg-icons/faExclamationTriangle';

@Component({
  selector: 'nexnox-web-no-permission',
  templateUrl: './no-permission.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CorePortalNoPermissionComponent {
  public faExclamationTriangle = faExclamationTriangle;
}
