import {ImportProtocolStatus, ImportType, ReportLevel} from "@nexnox-web/core-shared";

export const importProtocolStatusEnumOptions = [
  { value: ImportProtocolStatus.Processing, label: 'core-portal.settings.import-protocol.import-protocol-status.0' },
  { value: ImportProtocolStatus.Successful, label: 'core-portal.settings.import-protocol.import-protocol-status.1' },
  { value: ImportProtocolStatus.Failed, label: 'core-portal.settings.import-protocol.import-protocol-status.2' },
]

export const importConfigurationTypeEnumOptions = [
  {
    label: 'core-portal.settings.import-configurations.import-types.0',
    value: ImportType.User
  },
  {
    label: 'core-portal.settings.import-configurations.import-types.1',
    value: ImportType.Receipt
  },
  {
    label: 'core-portal.settings.import-configurations.import-types.2',
    value: ImportType.Resource
  },
  {
    label: 'core-portal.settings.import-configurations.import-types.3',
    value: ImportType.ResourceManufacturer
  },
];

export const importProtocolMessageReportLevelTypeEnumOptions = [
  {
    label: 'core-portal.settings.import-protocol.report-level.0',
    value: ReportLevel.Info
  },
  {
    label: 'core-portal.settings.import-protocol.report-level.1',
    value: ReportLevel.Error
  },
  {
    label: 'core-portal.settings.import-protocol.report-level.2',
    value: ReportLevel.Warning
  }
]
