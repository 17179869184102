<nexnox-web-settings-issue-template-edit #modelComponent
                                         (modelChange)="onModelChange($event)"
                                         *ngIf="(model$ | async).contentType; else loadingTemplate"
                                         [creating]="false"
                                         [id]="id"
                                         [issueContentType]="(model$ | async).contentType"
                                         [loading]="loading$ | async"
                                         [model]="model$ | async"
                                         [readonly]="readonly$ | async"
                                         [stereotyped]="false"
                                         [title]="title">
</nexnox-web-settings-issue-template-edit>

<ng-template #loadingTemplate>
  <nexnox-web-cardbox [loading]="true">
  </nexnox-web-cardbox>
</ng-template>
