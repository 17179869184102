import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {CorePortalPermissionGuard} from '@nexnox-web/core-portal';
import {CategoryDetailComponent, CategoryListComponent} from './containers';
import {AppPermissions, I18nBreadcrumbResolver, UnsavedChangesGuard, UnsavedCreateGuard} from '@nexnox-web/core-shared';

export const ORGA_PORTAL_CATEGORIES_ROUTES: Routes = [
  {
    path: '',
    canActivate: [CorePortalPermissionGuard],
    canDeactivate: [UnsavedCreateGuard],
    component: CategoryListComponent,
    data: {
      permissions: [AppPermissions.DisplayCategories]
    }
  },
  {
    path: ':categoryId',
    component: CategoryDetailComponent,
    canActivate: [CorePortalPermissionGuard],
    canDeactivate: [UnsavedChangesGuard],
    data: {
      permissions: [AppPermissions.ReadCategory],
      breadcrumbs: I18nBreadcrumbResolver,
      tab: 'category',
      text: 'orga-portal.categories.subtitles.category-detail'
    }
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(ORGA_PORTAL_CATEGORIES_ROUTES)
  ],
  exports: [
    RouterModule
  ]
})
export class CategoriesRoutingModule {
}
