import {AppEntityType, CoreSharedApiBaseService} from '@nexnox-web/core-shared';
import {
  CorePortalDashboardItemDatatableConfig,
  CorePortalEntityDatatableObservableStore,
  CorePortalEntityDatatableObservableStoreState,
  ErrorService
} from '@nexnox-web/core-portal';
import {Injector, Type} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';

export interface DashboardItemDatatableStoreState<E, M = E> extends CorePortalEntityDatatableObservableStoreState<E, M> {
}

export class DashboardItemDatatableStore<E, M = E> extends CorePortalEntityDatatableObservableStore<E, M> {
  private errorService: ErrorService;

  constructor(
    protected injector: Injector,
    protected idKey: string,
    protected entityServiceType: Type<CoreSharedApiBaseService>,
    protected entityType: AppEntityType,
    protected excludedColumns: string[],
    protected dashboardConfig: CorePortalDashboardItemDatatableConfig
  ) {
    super(injector, idKey, entityServiceType, entityType, excludedColumns, dashboardConfig.serializedName, true);

    this.errorService = injector.get(ErrorService);
  }

  public handleError(error: HttpErrorResponse | string): void {
    super.handleError(error);

    this.errorService.navigateToError(error);
  }
}
