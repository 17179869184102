import {ChangeDetectionStrategy, Component, Injector} from '@angular/core';
import {CorePortalEntityOverviewBaseComponent, DatatableActionButton} from '@nexnox-web/core-portal';
import {
  AppEntityType,
  ControllerOperationId,
  CoreSharedIssueLinkUtilityService,
  IssueListDto,
  Mappers,
  MessageIssueListDto
} from '@nexnox-web/core-shared';
import {messageIssueListStore} from "../../store";
import {faCircle} from '@fortawesome/free-solid-svg-icons/faCircle';
import {faExternalLinkAlt} from "@fortawesome/free-solid-svg-icons/faExternalLinkAlt";

@Component({
  selector: 'nexnox-web-issues-message-issue-list',
  templateUrl: './message-issue-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TechPortalFeatureMessageIssueListComponent extends CorePortalEntityOverviewBaseComponent<MessageIssueListDto> {

  public title = 'issues.subtitles.message-issue-list';
  public createTitle = 'issues.subtitles.message-issue-create';
  public idProperty = 'issueId';
  public displayProperty = 'issue.title';
  public pageOperation = ControllerOperationId.MessageIssueControllerId;
  public componentId = 'TechPortalFeatureMessageIssueListComponent';
  public enableViews = true;
  public datatableConfigName = 'MessageIssueDefault';
  public canDelete = false;

  public faCircle = faCircle;

  constructor(
    protected injector: Injector,
    private issueUtilityService: CoreSharedIssueLinkUtilityService
  ) {
    super(injector, messageIssueListStore, Mappers.MessageIssueListDto.serializedName, AppEntityType.Issue);
  }

  public detailFn = (row: IssueListDto): void => {
    this.tenantRouter.navigate(this.issueUtilityService.getIssueSegments(row)).then()
  };

  /* istanbul ignore next */
  public getRowActionButtons(): DatatableActionButton[] {
    return [
      {
        tooltip: 'core-shared.shared.actions.new-tab',
        icon: faExternalLinkAlt,
        link: (row: MessageIssueListDto) => this.issueUtilityService.getIssueLink(row),
        module: 'communication',
        target: '_blank'
      }
    ];
  }
}
