import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  AppPermissions,
  ContactSimpleDto,
  CoreSharedSidebarBaseComponent,
  Filter,
  FilterKind,
  FilterOperators,
  FilterTypes,
  IAssignContactDto
} from '@nexnox-web/core-shared';
import {faUser} from '@fortawesome/free-solid-svg-icons/faUser';
import {FormGroup} from "@angular/forms";
import {FormlyFieldConfig} from "@ngx-formly/core";
import {CorePortalContactService, CorePortalPermissionService} from "@nexnox-web/core-portal";
import {faTimes} from '@fortawesome/free-solid-svg-icons/faTimes';
import {BehaviorSubject, Observable, of} from "rxjs";
import {isEqual} from "lodash";

@Component({
  selector: 'nexnox-web-mission-assign-contact-sidebar',
  templateUrl: './assign-contact-sidebar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MissionAssignContactSidebarComponent extends CoreSharedSidebarBaseComponent implements OnInit {

  @Input() public currentSolutionContact: ContactSimpleDto;
  @Input() public loading: boolean;
  @Output() public assignContact: EventEmitter<IAssignContactDto> = new EventEmitter<IAssignContactDto>();
  @Output() public unassignContact: EventEmitter<undefined> = new EventEmitter();

  public canUnassign$: Observable<boolean>;
  public canAssign$: Observable<boolean>;
  public canAssignMe$: Observable<boolean>;

  public refreshSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public form: FormGroup;
  public model: { contact: ContactSimpleDto };
  public fields: FormlyFieldConfig[];

  public faTimes = faTimes;
  public faUser = faUser;

  constructor(
    private contactService: CorePortalContactService,
    private permissionService: CorePortalPermissionService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.form = new FormGroup({});
    this.fields = this.createForm();
    this.model = {} as any;

    this.canUnassign$ = this.permissionService.hasPermission$(AppPermissions.AssignMissionEditor);
    this.canAssign$ = this.permissionService.hasPermission$(AppPermissions.AssignMissionEditor);
    this.canAssignMe$ = this.permissionService.hasPermission$(AppPermissions.AssignMissionToMe);
  }

  public onAssignMe(): void {
    this.assignContact.emit({ contact: null, isAssignMe: true });
    this.onHide();
  }

  public onUnassignContact(): void {
    this.unassignContact.emit()
    this.onHide();
  }

  public onAssignContact(): void {
    this.assignContact.emit({ contact: this.model.contact });
    this.onHide();
  }

  public onShow(): void {
    this._setCurrentEditor();
    super.onShow();
  }

  public onHide(): void {
    this._setCurrentEditor();
    super.onHide();
  }

  /* istanbul ignore next */
  protected createForm(): FormlyFieldConfig[] {
    return [
      {
        key: 'contact',
        type: 'core-portal-entity-select',
        wrappers: ['core-portal-translated'],
        className: 'col-md-12 px-0',
        defaultValue: null,
        templateOptions: {
          corePortalTranslated: {
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          entityService: this.contactService,
          idKey: 'contactId',
          displayKey: 'displayName',
          clearable: false,
          wholeObject: true,
          required: true,
          refresh$: this.refreshSubject.asObservable(),
          defaultFilters$: of([{
            type: FilterTypes.DataTransferObject,
            operator: FilterOperators.Default,
            property: 'isFree',
            kind: FilterKind.Default,
            value: 'true'
          }] as Filter[])
        }
      }
    ];
  }

  private _setCurrentEditor(): void {
    if (!isEqual(this.form.get('contact').value, this.currentSolutionContact)) {
      this.form.get('contact').setValue(this.currentSolutionContact ?? null);
    }
    this.refreshSubject.next(true);
  }
}
