<p-sidebar #sidebarComponent
           (visibleChange)="onVisibleChange($event)"
           [transitionOptions]="transitionOptions"
           [visible]="visible$ | async"
           appendTo="body"
           position="right"
           styleClass="p-sidebar-md">
  <ng-container *ngIf="datatableViewService && (loading$ | async) === false; else loadingTemplate">
    <h4>{{ 'core-shared.shared.datatable-settings.title' | translate }}</h4>

    <div class="d-flex flex-grow-1 flex-column h-100 mt-1 overflow-auto px-1">
      <div class="d-flex flex-column">
        <div class="form-group w-100">
          <label class="col-form-label pl-0"
                 for="page-size-input">
            {{ 'core-shared.shared.table.subtitles.datatable-views.page-size' | translate }}
          </label>

          <input (ngModelChange)="pageSizeSubject.next($event)"
                 [disabled]="(datatableViewService.storeLoading$ | async) || disableDatatableViews"
                 [ngModel]="pageSizeSubject | async"
                 class="form-control"
                 id="page-size-input"
                 required
                 type="number">
        </div>

        <div *ngIf="enableViews" class="form-group w-100">
          <label class="col-form-label pl-0"
                 for="view-select">
            {{ 'core-shared.shared.table.subtitles.datatable-views.datatable-view' | translate }}
          </label>

          <div class="d-flex flex-column h-fit-content" id="view-select">
            <nexnox-web-entity-select (modelChange)="onSelectedDatatableViewChange($event)"
                                      [addTagFn]="onSaveCurrentConfigAsNewFn"
                                      [disabled]="disableDatatableViews || disableDatatableViewSelect || (datatableViewService.storeLoading$ | async)"
                                      [model]="selectedDatatableView$ | async"
                                      [options]="datatableViewSelectOptions"
                                      class="w-100">
            </nexnox-web-entity-select>

            <div class="d-flex flex-row mt-2">
              <button (click)="onReset()"
                      *ngIf="(selectedDatatableView$ | async)?.dataTableId"
                      [disabled]="datatableViewService.storeLoading$ | async"
                      [icon]="(datatableViewService.storeLoading$ | async) ? ((faSpinner | nexnoxWebFaIconStringPipe) + ' fa-spin') : (faSync | nexnoxWebFaIconStringPipe)"
                      [label]="'core-shared.shared.datatable-settings.actions.reset' | translate"
                      class="p-button-text p-button-secondary mr-2"
                      pButton
                      type="button">
              </button>

              <button
                (click)="onDeleteCurrentConfig()"
                *ngIf="(selectedDatatableView$ | async)?.dataTableId && !disableDatatableViews && !disableDatatableViewSelect"
                [disabled]="datatableViewService.storeLoading$ | async"
                [icon]="(datatableViewService.storeLoading$ | async) ? ((faSpinner | nexnoxWebFaIconStringPipe) + ' fa-spin') : (faTrashAlt | nexnoxWebFaIconStringPipe)"
                [label]="'core-shared.shared.datatable-settings.actions.delete-view' | translate"
                class="p-button-text p-button-danger"
                pButton
                type="button">
              </button>
            </div>
          </div>
        </div>
      </div>

      <nexnox-web-tabs #tabsComponent
                       [internal]="true"
                       [isTransparent]="true"
                       class="w-100"
                       startTab="columns">
        <ng-template [hasError]="(columnsValid$ | async) === false"
                     [heading]="'core-shared.shared.datatable-settings.tabs.columns' | translate"
                     nexnoxWebTabTemplate
                     tabId="columns">
          <nexnox-web-sortable-list (itemsChange)="onColumnsChange($event)"
                                    [disabled]="(datatableViewService.storeLoading$ | async) || disableDatatableViews"
                                    [hideItemName]="true"
                                    [items]="columnsItems$ | async"
                                    [sortable]="true">
            <!-- Columns -->
            <ng-template let-listItem="item" nexnoxWebSortableListItemTemplate>
              <ng-select (ngModelChange)="onColumnChange(listItem, $event)"
                         [class.is-invalid]="listItem.option === undefined"
                         [clearable]="false"
                         [disabled]="datatableViewService.storeLoading$ | async"
                         [items]="availableColumns$ | async"
                         [ngModel]="listItem.identifier"
                         [searchFn]="searchColumnFn"
                         [searchable]="true"
                         bindValue="identifier"
                         class="d-flex ng-form-select flex-grow-1"
                         groupBy="option">
                <ng-template let-item="item" ng-label-tmp>
                  <span *ngIf="item.option === undefined; else labelTemplate"></span>

                  <ng-template #labelTemplate>
                  <span *ngIf="item.option !== columnOptions.STEREOTYPE; else stereotypeTemplate">
                    {{ 'core-shared.shared.fields.' + (item.name | nexnoxWebDashCase) | translate }}
                  </span>
                  </ng-template>

                  <ng-template #stereotypeTemplate>
                    <span>{{ item.name }}</span>
                  </ng-template>
                </ng-template>

                <ng-template let-item="item" let-searchTerm="searchTerm" ng-option-tmp>
                <span *ngIf="item.option !== columnOptions.STEREOTYPE; else stereotypeTemplate"
                      [innerHTML]="'core-shared.shared.fields.' + (item.name | nexnoxWebDashCase) | translate | nexnoxWebHighlight:searchTerm"
                      [title]="'core-shared.shared.fields.' + (item.name | nexnoxWebDashCase) | translate">
                </span>

                  <ng-template #stereotypeTemplate>
                    <span [innerHTML]="item.name | nexnoxWebHighlight:searchTerm" [title]="item.name"></span>
                  </ng-template>
                </ng-template>

                <ng-template let-item="item" ng-optgroup-tmp>
                  {{ 'core-shared.shared.column-types.' + item.option | translate }}
                </ng-template>
              </ng-select>
            </ng-template>
          </nexnox-web-sortable-list>

          <button (click)="onAddColumn()"
                  [disabled]="datatableViewService.storeLoading$ | async"
                  [icon]="faPlus | nexnoxWebFaIconStringPipe"
                  [label]="'core-shared.shared.datatable-settings.actions.add-column' | translate"
                  class="p-button-text p-button-secondary mt-2"
                  pButton
                  type="button">
          </button>
        </ng-template>

        <!-- Sorting -->
        <ng-template [hasError]="(sortingValid$ | async) === false"
                     [heading]="'core-shared.shared.datatable-settings.tabs.sorting' | translate"
                     nexnoxWebTabTemplate
                     tabId="sorting">
          <div *ngIf="sortObject$ | async as sortObject; else noSortingTemplate"
               class="d-flex flex-row">
            <ng-select (ngModelChange)="onSortFieldChanged($event)"
                       [class.is-invalid]="!sortObject.sortField"
                       [clearable]="false"
                       [disabled]="datatableViewService.storeLoading$ | async"
                       [items]="sortableColumns$ | async"
                       [ngModel]="sortObject.sortField"
                       [searchable]="false"
                       bindValue="identifier"
                       class="d-flex ng-form-select flex-grow-1"
                       groupBy="option">
              <ng-template let-item="item" ng-label-tmp>
                <span *ngIf="item.option === undefined; else labelTemplate"></span>

                <ng-template #labelTemplate>
                <span *ngIf="item.option !== columnOptions.STEREOTYPE; else stereotypeTemplate">
                  {{ 'core-shared.shared.fields.' + (item.name | nexnoxWebDashCase) | translate }}
                </span>
                </ng-template>

                <ng-template #stereotypeTemplate>
                  <span>{{ item.name }}</span>
                </ng-template>
              </ng-template>

              <ng-template let-item="item" ng-option-tmp>
              <span *ngIf="item.option !== columnOptions.STEREOTYPE; else stereotypeTemplate"
                    [title]="'core-shared.shared.fields.' + (item.name | nexnoxWebDashCase) | translate">
                {{ 'core-shared.shared.fields.' + (item.name | nexnoxWebDashCase) | translate }}
              </span>

                <ng-template #stereotypeTemplate>
                  <span [title]="item.name">{{ item.name }}</span>
                </ng-template>
              </ng-template>

              <ng-template let-item="item" ng-optgroup-tmp>
                {{ 'core-shared.shared.column-types.' + item.option | translate }}
              </ng-template>
            </ng-select>

            <button (click)="onSortOrderChanged()"
                    [disabled]="datatableViewService.storeLoading$ | async"
                    [icon]="(sortObject.sort === sortOrders.Ascending ? faSortAmountUpAlt : faSortAmountDown) | nexnoxWebFaIconStringPipe"
                    [title]="'core-shared.shared.datatable-settings.actions.change-sort' | translate"
                    class="p-button-text p-button-secondary ml-2"
                    pButton
                    type="button">
            </button>

            <button (click)="onRemoveSortObject()"
                    [disabled]="datatableViewService.storeLoading$ | async"
                    [icon]="faTimes | nexnoxWebFaIconStringPipe"
                    [title]="'core-shared.shared.table.tooltip.remove' | translate"
                    class="p-button-text p-button-secondary ml-2"
                    pButton
                    type="button">
            </button>
          </div>

          <ng-template #noSortingTemplate>
            <div>{{ 'core-shared.shared.table.empty' | translate }}</div>
          </ng-template>

          <button (click)="onAddSortObject()"
                  [disabled]="!!(sortObject$ | async) || (datatableViewService.storeLoading$ | async)"
                  [icon]="faPlus | nexnoxWebFaIconStringPipe"
                  [label]="'core-shared.shared.datatable-settings.actions.add-column' | translate"
                  class="p-button-text p-button-secondary mt-2"
                  pButton
                  type="button">
          </button>
        </ng-template>

        <!-- Filters -->
        <ng-template [hasError]="(filtersValid$ | async) === false"
                     [heading]="'core-shared.shared.datatable-settings.tabs.filters' | translate"
                     nexnoxWebTabTemplate
                     tabId="filters">
          <span *ngIf="!(filters$ | async)?.length">{{ 'core-shared.shared.table.empty' | translate }}</span>

          <p-tree [class.is-empty]="!(filters$ | async)?.length"
                  [trackBy]="filterTrackByFn"
                  [value]="filterTreeItems$ | async"
                  styleClass="p-2 custom-tree">
            <ng-template let-node pTemplate="default">
              <div class="d-flex flex-column ml-2">
                <div class="d-flex flex-row">
                  <ng-select (ngModelChange)="onFilterChanged(node.data.parent, node.data.index, { property: $event, value: null })"
                             *ngIf="!node.data.isGrouped"
                             [class.is-invalid]="!node.data.hasProperty"
                             [clearable]="false"
                             [disabled]="datatableViewService.storeLoading$ | async"
                             [items]="allColumns$ | async"
                             [ngModel]="node.data.property"
                             [searchFn]="searchFilterFn"
                             [searchable]="true"
                             bindValue="identifier"
                             class="d-flex ng-form-select flex-grow-1"
                             groupBy="option">
                    <ng-template let-item="item" ng-label-tmp>
                      <span *ngIf="item.option === undefined; else labelTemplate"></span>

                      <ng-template #labelTemplate>
                        <span *ngIf="item.option !== columnOptions.STEREOTYPE; else stereotypeTemplate">
                          {{ 'core-shared.shared.fields.' + (item.name | nexnoxWebDashCase) | translate }}
                        </span>
                      </ng-template>

                      <ng-template #stereotypeTemplate>
                        <span>{{ item.name }}</span>
                      </ng-template>
                    </ng-template>

                    <ng-template let-item="item" ng-option-tmp>
                      <span *ngIf="item.option !== columnOptions.STEREOTYPE; else stereotypeTemplate"
                            [title]="'core-shared.shared.fields.' + (item.name | nexnoxWebDashCase) | translate">
                        {{ 'core-shared.shared.fields.' + (item.name | nexnoxWebDashCase) | translate }}
                      </span>

                      <ng-template #stereotypeTemplate>
                        <span [title]="item.name">{{ item.name }}</span>
                      </ng-template>
                    </ng-template>

                    <ng-template let-item="item" ng-optgroup-tmp>
                      {{ 'core-shared.shared.column-types.' + item.option | translate }}
                    </ng-template>
                  </ng-select>

                  <ng-select (ngModelChange)="onFilterChanged(node.data.parent, node.data.index, { combinedAs: $event })"
                             *ngIf="node.data.isGrouped"
                             [class.is-invalid]="!node.data.hasCombinedAs || !node.data.hasChildren"
                             [clearable]="false"
                             [disabled]="datatableViewService.storeLoading$ | async"
                             [items]="combinedAsItems"
                             [ngModel]="node.data.combinedAs"
                             [searchable]="false"
                             bindValue="value"
                             class="d-flex ng-form-select flex-grow-1">
                    <ng-template let-item="item" ng-label-tmp>
                      <span [title]="item.label | translate">{{ item.label | translate }}</span>
                    </ng-template>

                    <ng-template let-item="item" ng-option-tmp>
                      <span [title]="item.label | translate">{{ item.label | translate }}</span>
                    </ng-template>
                  </ng-select>

                  <div class="d-flex ml-2">
                    <button (click)="onFilterChanged(node.data.parent, node.data.index, { type: filterTypes.Grouped, combinedAs: combinedAs.Or, property: undefined, value: undefined, operator: undefined, children: [node.data.filter] })"
                            *ngIf="!node.data.isGrouped && node.data.canAdd"
                            [disabled]="datatableViewService.storeLoading$ | async"
                            [icon]="faObjectGroup | nexnoxWebFaIconStringPipe"
                            [title]="'core-shared.shared.datatable-settings.actions.make-group' | translate"
                            class="p-button-text p-button-secondary"
                            pButton
                            type="button">
                    </button>

                    <button (click)="onRemoveFilter(node.data.filter)"
                            [class.ml-2]="!node.data.isGrouped && node.data.canAdd"
                            [disabled]="datatableViewService.storeLoading$ | async"
                            [icon]="faTimes | nexnoxWebFaIconStringPipe"
                            [title]="'core-shared.shared.table.tooltip.remove' | translate"
                            class="p-button-text p-button-secondary"
                            pButton
                            type="button">
                    </button>
                  </div>
                </div>

                <div *ngIf="!node.data.isGrouped" class="d-flex flex-row mt-2">
                  <nexnox-web-entity-datatable-filter (searchBy)="filterChangeSubject.next($any({ parentFilter: node.data.parent, index: node.data.index, changes: $event }))"
                                                      *ngIf="node.data.filterColumn"
                                                      [clearable]="false"
                                                      [column]="node.data.filterColumn"
                                                      [disabled]="datatableViewService.storeLoading$ | async"
                                                      [filter]="node.data.filter"
                                                      [isDatatableSettings]="true"
                                                      [showAll]="true"
                                                      [showError]="!node.data.hasValue"
                                                      [templates]="templates"
                                                      class="w-100">
                  </nexnox-web-entity-datatable-filter>
                </div>
              </div>
            </ng-template>

            <ng-template let-node pTemplate="add">
              <button (click)="onAddFilter(node.data)"
                      [icon]="faPlus | nexnoxWebFaIconStringPipe"
                      [label]="'core-shared.shared.datatable-settings.actions.add-filter' | translate"
                      class="p-button-text p-button-secondary"
                      pButton
                      type="button">
              </button>
            </ng-template>
          </p-tree>
        </ng-template>
      </nexnox-web-tabs>
    </div>

    <div class="d-flex flex-shrink-0 justify-content-end pt-3">
      <button (click)="onApply()"
              [disabled]="(valid$ | async) === false || (datatableViewService.storeLoading$ | async)"
              [icon]="(datatableViewService.storeLoading$ | async) ? ((faSpinner | nexnoxWebFaIconStringPipe) + ' fa-spin') : (faCheck | nexnoxWebFaIconStringPipe)"
              [label]="'core-shared.shared.datatable-settings.actions.apply' | translate"
              class="p-button-outlined p-button-primary ml-2"
              pButton
              type="button">
      </button>

      <button
        (click)="onSaveAndApply()"
        *ngIf="(selectedDatatableView$ | async)?.dataTableId && !disableDatatableViews && !disableDatatableViewSelect"
        [disabled]="(valid$ | async) === false || (datatableViewService.storeLoading$ | async)"
        [icon]="(datatableViewService.storeLoading$ | async) ? ((faSpinner | nexnoxWebFaIconStringPipe) + ' fa-spin') : (faSave | nexnoxWebFaIconStringPipe)"
        [label]="'core-shared.shared.datatable-settings.actions.save-and-apply' | translate"
        class="p-button-primary ml-2"
        pButton
        type="button">
      </button>
    </div>
  </ng-container>
</p-sidebar>

<ng-template #loadingTemplate>
  <nexnox-web-loading-icon class="d-flex w-100 h-100 align-items-center justify-content-center flex-column p-5"
                           size="5x">
  </nexnox-web-loading-icon>
</ng-template>
