<nexnox-web-entity-datatable #datatableComponent
                             (loadPage)="bc.onLoadPage($event)"
                             (selectionChange)="selectionChange.emit($event)"
                             *ngIf="baseComponent as bc"
                             [canDelete]="false"
                             [columnTypings]="bc.defaultColumnTypings"
                             [componentId]="bc.componentId"
                             [defaultColumns]="bc.defaultColumns"
                             [defaultFilters]="bc.defaultFilters"
                             [detailFn]="detailFn"
                             [disableOptionalSorting]="true"
                             [displayProp]="bc.displayKey?.toString()"
                             [entityType]="bc.entityType"
                             [excludedColumns]="bc.excludedColumns"
                             [idProp]="bc.idKey?.toString()"
                             [loading]="bc.loading$ | async"
                             [paging]="bc.paging$ | async"
                             [savedFilters]="savedFilters"
                             [selectionMode]="selectionMode"
                             [showSettings]="true"
                             [stereotypes$]="bc.stereotypes$"
                             [storeEntities$]="bc.entities$">
</nexnox-web-entity-datatable>
