import {Injectable} from '@angular/core';
import {ClipboardService} from 'ngx-clipboard';
import {filter} from 'rxjs/operators';
import {ApiNotificationService} from '../api-notification/api-notification.service';
import {UnsubscribeHelper} from '../../helper';

@Injectable()
export class ClipboardToastService extends UnsubscribeHelper {
  constructor(
    private clipboardService: ClipboardService,
    private notificationService: ApiNotificationService
  ) {
    super();

    this.handleClipboardResponse();
  }

  private handleClipboardResponse(): void {
    this.subscribe(this.clipboardService.copyResponse$.pipe(
      filter(response => response?.isSuccess)
    ), response => this.notificationService.showTranslatedSuccess(response.successMessage ?? 'core-shared.shared.toast.copied'));
  }
}
