import {InjectionToken, NgModule} from '@angular/core';
import {ActionReducerMap, StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {resourcesEffects, resourcesReducers} from './store';

export const REDUCER_TOKEN = new InjectionToken<ActionReducerMap<any>>('@nexnox-web/tech-portal/resources: Store Reducers');

@NgModule({
  imports: [
    StoreModule.forFeature('tech-portal-feature-resources', REDUCER_TOKEN),
    EffectsModule.forFeature(resourcesEffects)
  ],
  providers: [
    {
      provide: REDUCER_TOKEN,
      useValue: resourcesReducers
    }
  ]
})
export class ResourcesStoreModule {
}
