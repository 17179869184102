import {Injectable} from '@angular/core';
import {
  ReceiverField,
  ReceiverSimpleDto,
  ReceiverType,
  receiverTypes
} from "../../modules/formly/types/formly-receivers/receiver-type.enum";
import {ReceiverItemLocalDto} from "../../modules/formly/types/formly-receivers/receivers.model";
import {ContactSimpleDto, FunctionSimpleDto, LocationGroupSimpleDto, LocationSimpleDto} from "@nexnox-web/core-shared";
import {UrlTree} from "@angular/router";
import {CorePortalTenantRouter} from "../../router-overrides";
import {isEqual} from "lodash";
import {CorePortalFormlyReceiverTyping} from "../../modules/formly/types";

@Injectable()
export class CorePortalReceiversService {

  constructor(private router: CorePortalTenantRouter) {
  }

  public mapEntity(entity: any, type: ReceiverType): ReceiverItemLocalDto {
    const id = (entity.contactId ?? entity.locationId ?? entity.functionId ?? entity.locationGroupId);
    const name = (entity.displayName ?? entity.name);
    const url: string[] = this._getEntityUrlByType(id, type);
    const entityDto = this._createEntityDtoByReceiverType(entity, type);

    return { id, name, url, type, entityDto };
  }

  public navigateToReceiver(itemUrl: string[]): void {
    const url: UrlTree = this.router.createUrlTree(itemUrl, { module: 'management' });
    window.open(url.toString(), '_blank');
  }

  public pushIfNotExists(objectArray: object[], objectToAdd: object): void {
    if (!objectArray.find((obj: object) => isEqual(obj, objectToAdd))) {
      objectArray.push(objectToAdd);
    }
  }

  public createReceiverTabs(corePortalReceivers: CorePortalFormlyReceiverTyping): {
    type: ReceiverType,
    shouldShow: boolean
  }[] {
    return receiverTypes.map(receiver => {
      switch (receiver.type) {
        case ReceiverType.LOCATION_GROUPS:
          return { type: receiver.type, shouldShow: corePortalReceivers?.useLocationGroupTab ?? true };
        case ReceiverType.LOCATIONS:
          return { type: receiver.type, shouldShow: corePortalReceivers?.useLocationTab ?? true };
        case ReceiverType.FUNCTIONS:
          return { type: receiver.type, shouldShow: corePortalReceivers?.useFunctionTab ?? true };
        case ReceiverType.CONTACTS:
          return { type: receiver.type, shouldShow: corePortalReceivers?.useContactTab ?? true };
        default:
          return;
      }
    })
  }

  private _getEntityUrlByType(id: number, receiverType: ReceiverType): string[] {
    return ['masterdata', ReceiverField[receiverType].url, id?.toString()];
  }

  private _createEntityDtoByReceiverType(entity: any, receiverType: ReceiverType): ReceiverSimpleDto {
    switch (receiverType) {
      case ReceiverType.CONTACTS:
        return {
          contactId: entity.contactId,
          displayName: entity.displayName,
          userId: entity.userId ?? entity.user?.id
        } as ContactSimpleDto;
      case ReceiverType.FUNCTIONS:
        return {
          functionId: entity.functionId,
          name: entity.name,
          externalId: entity.externalId
        } as FunctionSimpleDto;
      case ReceiverType.LOCATIONS:
        return {
          locationId: entity.locationId,
          name: entity.name,
          externalId: entity.externalId,
          locationNumber: entity.locationNumber
        } as LocationSimpleDto;
      case ReceiverType.LOCATION_GROUPS:
        return {
          externalId: entity.externalId,
          locationGroupId: entity.locationGroupId,
          name: entity.name
        } as LocationGroupSimpleDto;
    }
  }

}
