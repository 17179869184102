import {
  TechPortalFeatureMissionActionsFacade,
  TechPortalFeatureMissionActionsFacadeAssignToMePayload,
  TechPortalFeatureMissionActionsFacadeAssignToPayload,
  TechPortalFeatureMissionActionsFacadeChangeStatePayload,
  TechPortalFeatureMissionActionsFacadeCompleteOrResumePayload,
  TechPortalFeatureMissionActionsFacadeExportPayload
} from '../mission-actions';
import {CorePortalXsStoreEntityActionsFacade} from '@nexnox-web/core-portal';
import {MissionDto} from '@nexnox-web/core-shared';
import {MissionListXsStore} from '../../store/stores/mission-list/mission-list.xs-store';

export class XsStoreMissionActionsFacade extends CorePortalXsStoreEntityActionsFacade<MissionDto>
  implements TechPortalFeatureMissionActionsFacade {
  protected entityStore: MissionListXsStore;

  public assignToMe({ id, parentIds }: TechPortalFeatureMissionActionsFacadeAssignToMePayload): void {
    this.store.dispatch(this.entityStore.actions.assignToMe({ id, parentIds }));
  }

  public assignTo({ id, parentIds, contact }: TechPortalFeatureMissionActionsFacadeAssignToPayload): void {
    this.store.dispatch(this.entityStore.actions.assignTo({ id, parentIds, contact }));
  }

  public changeState({ id, state }: TechPortalFeatureMissionActionsFacadeChangeStatePayload): void {
    this.store.dispatch(this.entityStore.actions.changeState({ id, state }));
  }

  public complete({ id }: TechPortalFeatureMissionActionsFacadeCompleteOrResumePayload): void {
    this.store.dispatch(this.entityStore.actions.complete({ id }));
  }

  public resume({ id }: TechPortalFeatureMissionActionsFacadeCompleteOrResumePayload): void {
    this.store.dispatch(this.entityStore.actions.resume({ id }));
  }

  public export({ id, templateId }: TechPortalFeatureMissionActionsFacadeExportPayload): void {
    this.store.dispatch(this.entityStore.actions.export({ id, templateId }));
  }
}
