<div class="w-100 h-100">
  <nexnox-web-cardbox [fullHeight]="true"
                      [fullWidth]="true"
                      [noMarginBottom]="true"
                      [noTitle]="true"
                      paddingClasses="px-3 pb-3">
    <div class="d-flex w-100 h-100 align-items-center justify-content-center flex-column">
      <fa-icon [icon]="faExclamationTriangle" class="mb-3" size="5x"></fa-icon>
      <h4 class="d-flex justify-content-center">{{ 'core-portal.no-permission.title' | translate }}</h4>
      <h6 class="d-flex justify-content-center">{{ 'core-portal.no-permission.subtitle' | translate }}</h6>
    </div>
  </nexnox-web-cardbox>
</div>
