import {CorePortalFeatureMasterDataHolidaySetService} from './services';
import {holidaysDetailStore, holidaysListStore} from './stores';

export * from './services';
export * from './stores';

export * from './holidays.state';
export * from './holidays.selectors';

export const holidaysReducers = {
  holidaysList: holidaysListStore.reducer,
  holidaysDetail: holidaysDetailStore.reducer
};

export const holidaysEffects = [
  holidaysListStore.effects,
  holidaysDetailStore.effects
];

export const holidaysServices = [
  CorePortalFeatureMasterDataHolidaySetService
];
