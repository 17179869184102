import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {faSpinner} from '@fortawesome/free-solid-svg-icons/faSpinner';
import {Observable} from "rxjs";
import {FormlyFieldConfig} from "@ngx-formly/core";
import {UnsubscribeHelper} from "@nexnox-web/core-shared";
import {cloneDeep} from "lodash";
import {faTimes} from '@fortawesome/free-solid-svg-icons/faTimes';
import {faCheck} from '@fortawesome/free-solid-svg-icons/faCheck';
import {distinctUntilChanged, filter} from "rxjs/operators";
import {FormlyTableRowValidityDto} from "@nexnox-web/libs/core-portal/src/lib/modules/formly/components";

export interface FormlyTableRowDto {
  localId: number;
  fields: FormlyFieldConfig[];
  model: any;
  valid: boolean;
}

export interface FormlyTableHeaderDto {
  label: string;
  class: string;
}

export enum FormlyTableLabelModes {
  Regular = 0,
  Complex = 1
}

@Component({
  selector: 'nexnox-web-formly-table',
  templateUrl: './formly-table.component.html',
  styles: [':host ::ng-deep .form-group {margin-bottom: 0}', '.createRow {background:#f8f9fa}']
})
export class FormlyTableComponent extends UnsubscribeHelper implements OnInit {

  @Input() public fields: FormlyFieldConfig[];
  @Input() public model$: Observable<any[] | null>;
  @Input() public model: any[] = [];
  @Input() public loading = false;
  @Input() public labelDisplayMode = FormlyTableLabelModes.Regular;
  @Input() public complexHeader = 'My complex header';

  @Output() public modelChange: EventEmitter<any[]> = new EventEmitter<any[]>();
  @Output() public validChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  public header: FormlyTableHeaderDto[];
  public rows: FormlyTableRowDto[] = [];
  public columns: any[] = [{ key: undefined, label: 'One single column' }];
  public labelModes = FormlyTableLabelModes;

  public faSpinner = faSpinner;
  public faTimes = faTimes;
  public faCheck = faCheck;

  private idCounter = 0;

  constructor() {
    super();
  }

  public ngOnInit(): void {
    this.mapHeader(this.fields);
    this.refreshTable();

    if (this.model$) {
      this.subscribe(
        this.model$.pipe(
          distinctUntilChanged(),
          filter((model) => Boolean(model))
        ),
        (model: any[]) => {
          this.model = model ?? [];
          this.refreshTable();
          this.emitChangedModel();
          this.emitChangedValidity();
        });
    }
  }

  public onModelChange({ localId, model }): void {
    const index = this.findIndexByLocalId(localId);
    this.model[index] = model;
    this.emitChangedModel();
    this.emitChangedValidity();
  }

  public onDeleteRow(localId: number): void {
    const index = this.findIndexByLocalId(localId);
    this.model.splice(index, 1);
    this.emitChangedModel();
    this.refreshTable();
    this.emitChangedValidity();
  }

  public onAddRow(row: any): void {
    this.model.push(row);
    this.emitChangedModel();
    this.refreshTable();
    this.emitChangedValidity();
  }

  public onRowValidityChange(validity: FormlyTableRowValidityDto): void {
    const index = this.findIndexByLocalId(validity.localId);
    this.rows[index].valid = validity.isValid;
    this.emitChangedValidity();
  }

  private mapRows(config: FormlyFieldConfig[], model: any[]): void {

    this.rows = [];

    for (let m = 0; m < model.length; m++) {
      const localId = this.idCounter++;
      const rowConfig: FormlyFieldConfig[] = this.cloneFieldConfig();

      for (let c = 0; c < config.length; c++) {
        const field = config[c].key.toString();
        rowConfig[c].defaultValue = model[m][field] ?? null;
      }

      this.rows.push({
        localId: localId,
        model: cloneDeep(model[m] ?? null),
        fields: rowConfig,
        valid: true
      });
    }
  }

  private emitChangedModel(): void {
    this.modelChange.emit(cloneDeep(this.model));
  }

  private emitChangedValidity(): void {
    const isValid = this.rows.length > 0 ? !this.rows.some((row) => row.valid === false) : false;
    this.validChange.emit(isValid);
  }

  private refreshTable(): void {
    this.mapRows(this.fields ?? [], this.model ?? []);
  }

  private findIndexByLocalId(localId: number): number {
    return this.rows.findIndex((r) => r.localId === localId);
  }

  private mapHeader(config: FormlyFieldConfig[]): void {
    this.header = [];
    for (let c = 0; c < config?.length; c++) {
      if (config[c]?.type) {
        this.header.push({
          label: config[c]?.templateOptions?.corePortalTranslated?.label ?? config[c].key.toString(),
          class: config[c]?.className
        });
      }
    }
  }

  // Workaround to not deep copy the whole object, especially the service in core-portal-entity-select
  // This fastens up the table actions
  private cloneFieldConfig(): FormlyFieldConfig[] {
    const fieldsCopy = [];
    for (let f = 0; f < this.fields.length; f++) {
      // Cache service
      const service = this.fields[f]?.templateOptions?.entityService;
      if (service) this.fields[f].templateOptions.entityService = undefined;
      // Copy deep
      fieldsCopy.push(cloneDeep(this.fields[f]));
      // Return service
      if (service) {
        fieldsCopy[f].templateOptions.entityService = service;
        fieldsCopy[f].templateOptions.appendTo = 'body'; // Prevents z-index issues
        this.fields[f].templateOptions.entityService = service;
      }
    }
    return fieldsCopy;
  }
}
