import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import {
  CustomCheckboxDto,
  CustomDatePropertyDto,
  CustomDropDownDto,
  CustomEmailPropertyDto,
  CustomInfoDto,
  CustomMultilinePropertyDto,
  CustomNumericPropertyDto,
  CustomPhoneDto,
  CustomPictureDto,
  CustomPropertyType,
  CustomSignatureDto,
  CustomTextPropertyDto,
  CustomTimePropertyDto,
  UnsubscribeHelper
} from '@nexnox-web/core-shared';
import {FormGroup} from '@angular/forms';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {cloneDeep, flatten} from 'lodash';
import {CustomPropertyTemplates} from './custom-property-templates';
import {LocalCustomPropertySetDto, LocalCustomPropertyValue, LocalFilledCustomValue} from '../../models';

@Component({
  selector: 'nexnox-web-custom-property-set-edit',
  templateUrl: './custom-property-set-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomPropertySetEditComponent extends UnsubscribeHelper implements OnInit, AfterViewInit, OnDestroy {
  @Input() public customPropertySet: LocalCustomPropertySetDto;
  @Input() public creating: boolean;
  @Input() public readonly: boolean;
  @Input() public isRateable: boolean;
  @Input() public inheritance: boolean;

  @Output() public modelChange: EventEmitter<LocalFilledCustomValue[]> = new EventEmitter<LocalFilledCustomValue[]>();
  @Output() public modelValid: EventEmitter<boolean> = new EventEmitter<boolean>();

  public form: FormGroup;
  public fields: FormlyFieldConfig[];

  private customPropertyTemplates: CustomPropertyTemplates;

  constructor(
    private injector: Injector
  ) {
    super();
  }

  private _model: LocalCustomPropertyValue;

  public get model(): LocalCustomPropertyValue {
    return this._model;
  }

  @Input()
  public set model(model: LocalCustomPropertyValue) {
    this._model = cloneDeep(model);
  }

  public ngOnInit(): void {
    this.customPropertyTemplates = new CustomPropertyTemplates(
      this.injector,
      () => this.creating,
      () => this.readonly,
      () => this.isRateable,
      this.inheritance
    );
    this.form = new FormGroup({});
    this.fields = this.createForm();

    this.subscribe(this.form.statusChanges, () => this.modelValid.emit(this.form.valid));
  }

  public ngAfterViewInit(): void {
    this.modelValid.emit(this.form.valid);
    this.onModelChange(this._model);
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();

    this.modelValid.emit(true);
  }

  public onModelChange(model: LocalCustomPropertyValue): void {
    const mapModel: LocalFilledCustomValue[] = Object.values({ ...this._model, ...model }).map(filledCustomValue => ({
      ...filledCustomValue,
      customValues: Object.values(filledCustomValue.customValues).map(customValueRelation => {
        const customValue = customValueRelation.customValue;
        const ownValue = customValue.isInherited ? customValue.inheritedValue : customValue.value;

        return {
          ...customValueRelation,
          customValue: {
            ...customValue,
            value: ownValue
          }
        };
      })
    }));

    this.modelChange.emit(mapModel.filter(x => Boolean(x)));
  }

  private createForm(): FormlyFieldConfig[] {
    if (!this.customPropertySet) {
      return [];
    }

    return flatten(this.customPropertySet.properties.map(property => {
      switch (property.type) {
        case CustomPropertyType.Text:
          return this.customPropertyTemplates.text(property as CustomTextPropertyDto);
        case CustomPropertyType.Numeric:
          return this.customPropertyTemplates.numeric(property as CustomNumericPropertyDto);
        case CustomPropertyType.Multiline:
          return this.customPropertyTemplates.multilineText(property as CustomMultilinePropertyDto);
        case CustomPropertyType.Email:
          return this.customPropertyTemplates.email(property as CustomEmailPropertyDto);
        case CustomPropertyType.Checkbox:
          return this.customPropertyTemplates.checkbox(property as CustomCheckboxDto);
        case CustomPropertyType.Date:
          return this.customPropertyTemplates.date(property as CustomDatePropertyDto);
        case CustomPropertyType.TimeOfDay:
          return this.customPropertyTemplates.time(property as CustomTimePropertyDto);
        case CustomPropertyType.Dropdown:
          return this.customPropertyTemplates.dropdown(property as CustomDropDownDto);
        case CustomPropertyType.Phone:
          return this.customPropertyTemplates.phone(property as CustomPhoneDto);
        case CustomPropertyType.Signature:
          return this.customPropertyTemplates.signature(property as CustomSignatureDto);
        case CustomPropertyType.Picture:
          return this.customPropertyTemplates.image(property as CustomPictureDto);
        case CustomPropertyType.Info:
          return this.customPropertyTemplates.info(property as CustomInfoDto);
        default:
          return this.customPropertyTemplates.default(property);
      }
    }));
  }
}
