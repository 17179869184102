import {PagedEntitiesXsStoreEffects} from '@nexnox-web/core-store';
import {AppEntityType, CoreSharedApiBaseService, SolutionItemDto} from '@nexnox-web/core-shared';
import {Injector, Type} from '@angular/core';
import {TechPortalFeatureSolutionItemService, TechPortalFeatureSolutionService} from '../../services';
import {TechPortalFeatureSolutionItemListXsStoreActions} from './solution-item-list-xs-store.actions';
import {createEffect, ofType} from '@ngrx/effects';
import {catchError, groupBy, map, mergeMap, switchMap, tap} from 'rxjs/operators';
import {of} from 'rxjs';
import {Action} from '@ngrx/store';
import {TechPortalFeatureSolutionItemListXsStoreSelectors} from './solution-item-list.xs-store';

export abstract class TechPortalFeatureSolutionItemListXsStoreEffects extends PagedEntitiesXsStoreEffects<SolutionItemDto> {
  public markItemAsSolution$: any;
  public markItemAsSolutionSuccess$: any;

  protected service: TechPortalFeatureSolutionItemService;
  protected solutionService: TechPortalFeatureSolutionService;

  protected constructor(
    protected injector: Injector,
    protected actions: TechPortalFeatureSolutionItemListXsStoreActions,
    protected selectors: TechPortalFeatureSolutionItemListXsStoreSelectors,
    serviceType: Type<CoreSharedApiBaseService>,
    protected entityType: AppEntityType,
    protected prepareEntity: (entity: SolutionItemDto) => SolutionItemDto,
    protected prepareModel: (entity: SolutionItemDto, model: SolutionItemDto) => SolutionItemDto,
    protected sanitizeModel: (model: SolutionItemDto, entity: SolutionItemDto) => SolutionItemDto,
    createEffects: boolean = true
  ) {
    super(injector, actions, selectors, serviceType, entityType, prepareEntity, prepareModel, sanitizeModel, false);

    this.solutionService = injector.get(TechPortalFeatureSolutionService);

    if (createEffects) {
      this.createEffects();
    }
  }

  protected createEffects(): void {
    super.createEffects();

    this.markItemAsSolution$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.markItemAsSolution),
      groupBy(({ id }) => id),
      mergeMap(group => group.pipe(
        switchMap(({ id, isSolution, parentIds }) => this.service.markItemAsSolution(id, isSolution, parentIds).pipe(
          map(() => this.actions.markItemAsSolutionSuccess({ id, isSolution, parentIds })),
          catchError(error => of(this.actions.error({ error, action: this.actions.markItemAsSolution })))
        ))
      ))
    ));

    this.markItemAsSolutionSuccess$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.markItemAsSolutionSuccess),
      tap(action => this.actionCallback(action, false))
    ), { dispatch: false });
  }

  protected actionCallback(action: Action, isError: boolean = false): void {
    super.actionCallback(action, isError);

    this.checkAction(this.actions.markItemAsSolutionSuccess, action, ({ isSolution }) =>
      this.markItemAsSolutionSuccessActionCallback(isSolution));
  }

  protected markItemAsSolutionSuccessActionCallback(isSolution: boolean): void {
    this.apiNotificationService.showTranslatedSuccess(`solutions.toasts.solution-item-${ isSolution ? 'marked' : 'unmarked' }`);
  }
}
