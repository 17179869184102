<header class="header">
  <ul class="d-flex flex-shrink-0 header__menu">
    <li class="header__menu__link">
      <button (click)="onToggleSidebar()"
              [icon]="faBars | nexnoxWebFaIconStringPipe:'fa-lg'"
              class="sidebar-button p-button-text p-button-icon-only p-button-secondary p-button-h-100 px-4"
              pButton
              type="button">
      </button>
    </li>
  </ul>

  <div class="d-flex flex-grow-1 align-items-center px-3 overflow-hidden">
    <nexnox-web-breadcrumbs [breadcrumbs]="breadcrumbs$ | async"
                            [loading]="(loggedIn$ | async) === false"
                            class="w-100 h-100">
    </nexnox-web-breadcrumbs>
  </div>

  <ul class="header__menu d-flex flex-shrink-0">
    <li *ngIf="(tenantItems$ | async)?.length > 1;" class="header__menu__tenant-select d-none d-md-flex mr-2">
      <nexnox-web-layout-tenant-select *ngIf="(loading$ | async) === false else tenantSelectLoadingTemplate"
                                       [activeTenant$]="activeTenant$"
                                       [tenantItems$]="tenantItems$"
                                       class="w-100">
      </nexnox-web-layout-tenant-select>
      <ng-template #tenantSelectLoadingTemplate>
        <ngx-skeleton-loader [style.width.%]="100"
                             [theme]="{ width: '100%', height: '37px', margin: '0' }">
        </ngx-skeleton-loader>
      </ng-template>
    </li>

    <li *ngIf="(loading$ | async) === false; else buttonLoadingTemplate" class="header__menu__link mr-2">
      <nexnox-web-layout-app-settings-sidebar #appUserSettingsSidebarComponent
                                              (changePassword)="onChangePassword()"
                                              (logout)="onLogout()"
                                              [activeTenant$]="activeTenant$"
                                              [tenantItems$]="tenantItems$">
      </nexnox-web-layout-app-settings-sidebar>
      <button (click)="onOpenUserSettings()"
              [icon]="faUser | nexnoxWebFaIconStringPipe"
              [pTooltip]="'core-portal.core.header.tooltip.user-settings' | translate"
              class="p-button-text p-button-icon-only p-button-secondary p-button-lg p-button-h-100 px-4"
              pButton
              tooltipPosition="left"
              type="button">
      </button>
    </li>

    <li *ngIf="(loading$ | async) === false; else buttonLoadingTemplate" class="header__menu__link mr-2">
      <button (click)="onGoToHelp()"
              [icon]="faQuestionCircle | nexnoxWebFaIconStringPipe"
              [pTooltip]="'core-portal.core.header.modules.manual' | translate"
              class="p-button-text p-button-icon-only p-button-secondary p-button-lg p-button-h-100 px-4"
              pButton
              tooltipPosition="left"
              type="button">
      </button>
    </li>
  </ul>
</header>

<ng-template #buttonLoadingTemplate>
  <ngx-skeleton-loader [theme]="{ width: '44px', height: '60px', margin: '0' }"></ngx-skeleton-loader>
</ng-template>
