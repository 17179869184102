import {Component, Input} from '@angular/core';

@Component({
  selector: 'nexnox-web-ghost-hierarchical-tree-view-children',
  templateUrl: './ghost-hierarchical-tree-view-children.component.html',
  styleUrls: ['./ghost-hierarchical-tree-view-children.component.scss']
})
export class GhostHierarchicalTreeViewChildrenComponent {
  @Input() public nestLevel = 0;
}
