<p-confirmPopup key="delete-sortable-list-item"></p-confirmPopup>

<div (dndDrop)="onDrop($event)"
     *ngIf="items$ | async as listItems"
     [dndDisableDropIf]="disabled || !sortable"
     [dndDisableIf]="disabled || !sortable"
     [dndDropzone]="dropType ? [dropType] : undefined"
     [dndEffectAllowed]="!disabled && sortable ? 'move' : 'none'"
     class="list-group">
  <div [class.list-group-item]="!asCards"
       [class.mb-3]="asCards"
       [class.p-card]="asCards"
       class="placeholder border-primary"
       dndPlaceholderRef>
  </div>

  <ng-container *ngIf="listItems.length; else emptyTemplate">
    <div *ngFor="let item of listItems; trackBy: trackItemsBy"
         [class.border-item]="!asCards"
         [class.list-group-item]="!asCards"
         [class.mb-4]="asCards"
         [class.p-card]="asCards"
         [class.pb-0]="noPaddingBottom"
         [class.pt-3]="asCards"
         [class.px-3]="asCards"
         [dndDisableDragIf]="disabled || !sortable"
         [dndDisableIf]="disabled || !sortable"
         [dndDraggable]="item"
         [dndEffectAllowed]="!disabled && sortable ? 'move' : 'none'"
         [dndType]="dropType"
         class="list-item-container">
      <div [class.mb-2]="asCards"
           class="d-flex flex-row align-items-center">
        <div *ngIf="!disabled && sortable"
             class="d-flex flex-shrink-0 align-items-center justify-content-center item-handle mr-2"
             dndHandle>
          <fa-icon [icon]="faGripVertical"></fa-icon>
        </div>

        <div *ngIf="selectable && selectMultiple"
             class="d-flex mr-2">
          <p-checkbox (ngModelChange)="onSelectionChange(item, $event)"
                      [binary]="true"
                      [ngModel]="false"
                      [readonly]="disabled"
                      [value]="false">
          </p-checkbox>
        </div>

        <div *ngIf="!hideItemName" [class.flex-column]="notTitle"
             [class.item-title]="!notTitle"
             class="d-flex flex-grow-1 item-title align-items-center">
          <span *ngIf="!editable || disabled" [class.font-weight-bold]="itemNameBold">{{ item.title }}</span>

          <input (ngModelChange)="onUpdateTitle(item, $event)"
                 *ngIf="editable && !disabled"
                 [ngModel]="item.title"
                 class="form-control">
        </div>

        <div *ngIf="hideItemName" [class.flex-column]="notTitle"
             [class.item-title]="!notTitle"
             class="d-flex flex-grow-1 align-items-center">
          <ng-container *ngIf="itemTemplate?.template"
                        [ngTemplateOutletContext]="{ item: item.externalData, position: item.position }"
                        [ngTemplateOutlet]="itemTemplate.template">
          </ng-container>
        </div>

        <div *ngIf="actions?.length > 0">
          <button (click)="action.click(item)"
                  *ngFor="let action of actions"
                  [class.d-none]="action?.hidden ? action.hidden(item) : false"
                  [class]="action.class ?? 'p-button-text p-button-secondary p-button p-button-icon-only'"
                  [disabled]="action?.disabled ? action.disabled(item) : false"
                  [icon]="action.icon | nexnoxWebFaIconStringPipe"
                  [label]="action.label | translate"
                  [title]="action.tooltip | translate"
                  class="m-0 ml-2"
                  pButton
                  type="button">
          </button>
        </div>

        <div class="d-flex ml-2">
          <button (click)="onSelect(item)"
                  *ngIf="selectable && (canSelectFn ? canSelectFn(item.externalData) : true) && !selectMultiple"
                  [icon]="faArrowRight | nexnoxWebFaIconStringPipe"
                  [title]="'core-shared.shared.table.tooltip.select' | translate"
                  class="p-button-text p-button-secondary"
                  pButton
                  type="button">
          </button>

          <button (click)="onDelete(item, $event)"
                  *ngIf="deletable && item.deletable && !disabled"
                  [icon]="faTimes | nexnoxWebFaIconStringPipe"
                  [title]="'core-shared.shared.table.tooltip.delete' | translate"
                  class="p-button-text p-button-secondary"
                  pButton
                  type="button">
          </button>
        </div>
      </div>

      <ng-container *ngIf="itemTemplate?.template && !hideItemName"
                    [ngTemplateOutletContext]="{ item: item.externalData }"
                    [ngTemplateOutlet]="itemTemplate.template">
      </ng-container>
    </div>
  </ng-container>

  <ng-template #emptyTemplate>
    <ng-container *ngIf="asCards; else emptyValueTemplate">
      <div class="p-card p-3">
        <ng-container *ngTemplateOutlet="emptyValueTemplate"></ng-container>
      </div>
    </ng-container>

    <ng-template #emptyValueTemplate>
      {{ 'core-shared.shared.table.empty' | translate }}
    </ng-template>
  </ng-template>
</div>

