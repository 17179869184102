<nexnox-web-entity-edit [entityEditBaseComponent]="this"
                        [stereotyped]="false">

  <div class="row mt-3">
    <div class="col-12 mb-3">
      <nexnox-web-formly-table (modelChange)="onSkeletonsChange($event)"
                               (validChange)="formTableValidityChange.emit($event)"
                               [complexHeader]="'core-shared.shared.fields.tickets'"
                               [fields]="createSkeletonFields()"
                               [labelDisplayMode]=" labelModes.Complex"
                               [model$]="skeletons$">
      </nexnox-web-formly-table>
    </div>
  </div>
</nexnox-web-entity-edit>

