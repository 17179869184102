import {CustomValueCompleteDto, FilledCustomValueDto} from '@nexnox-web/core-shared';
import {cloneDeep, isEqual, isNull, max} from 'lodash';
import {IsDeepEqualWithCustomizer} from '@nexnox-web/lodash';

export const entityXsStoreCustomPropertyValuesChangeCustomizer: IsDeepEqualWithCustomizer = {
  path: 'customPropertyValues',
  isEqual: (a: FilledCustomValueDto[], b: FilledCustomValueDto[]) => {
    const newA = cloneDeep(a) ?? [];
    const newB = cloneDeep(b) ?? [];
    const valuesLength = max([newA.length, newB.length]);

    const getValue = (filledCustomValue: FilledCustomValueDto): any => {
      const customValue: CustomValueCompleteDto | undefined = filledCustomValue?.customValues?.[0]?.customValue;
      const isInherited = customValue?.isInherited;
      const value = isInherited ? customValue?.inheritedValue : customValue?.ownValue;

      return isNull(value) ? undefined : value;
    };

    for (let i = 0; i < valuesLength; i++) {
      if (!isEqual(getValue(newA[i]), getValue(newB[i]))) return false;
    }

    return true;
  }
};
