import {PagedEntitiesXsStore, PagedEntitiesXsStoreState} from '@nexnox-web/core-store';
import {AddressDto, AppEntityType} from '@nexnox-web/core-shared';
import {Action, createSelector} from '@ngrx/store';
import {selectAddressesState} from '../../addresses.selectors';
import {CorePortalFeatureMasterDataAddressService} from '../../services';
import {Injectable, Injector} from '@angular/core';

export interface AddressListStoreState extends PagedEntitiesXsStoreState<AddressDto> {
}

/* istanbul ignore next */
export const addressListStore = new PagedEntitiesXsStore<AddressDto>({
  actionLabel: 'Core Portal - Master Data - Address List',
  stateSelector: createSelector(selectAddressesState, state => state.addressList),
  serviceType: CorePortalFeatureMasterDataAddressService,
  entityType: AppEntityType.Address,
  selectId: address => address.entity?.addressId ?? address.model?.addressId
});

export function addressListStoreReducer(state: AddressListStoreState, action: Action): any {
  return addressListStore.reducer(state, action);
}

@Injectable()
export class AddressListStoreEffects extends addressListStore.effects {
  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }
}
