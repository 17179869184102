import {BaseXsStore, createEntityXsStoreActions, EntityXsStoreActions, PropsAction} from '@nexnox-web/core-store';
import {ResourceModelDto} from '@nexnox-web/core-shared';
import {createAction, props} from '@ngrx/store';
import {ModelDetailXsStoreMergePayload, ModelDetailXsStoreMovePayload} from "./model-detail-xs-store.payloads";

export interface ModelDetailXsStoreActions extends EntityXsStoreActions<ResourceModelDto> {
  merge: PropsAction<ModelDetailXsStoreMergePayload>;
  mergeSuccess: PropsAction<{ returnPath: any[] }>;

  move: PropsAction<ModelDetailXsStoreMovePayload>;
  moveSuccess: PropsAction<{ returnPath: any[] }>;
}

export const createModelDetailXsStoreActions = (label: string): ModelDetailXsStoreActions => ({
  ...createEntityXsStoreActions(label),

  merge: createAction(
    BaseXsStore.getType(label, 'Merge'),
    props<ModelDetailXsStoreMergePayload>()
  ),
  mergeSuccess: createAction(
    BaseXsStore.getType(label, 'Merge Success'),
    props<{ returnPath: any[] }>()
  ),

  move: createAction(
    BaseXsStore.getType(label, 'Move'),
    props<ModelDetailXsStoreMovePayload>()
  ),
  moveSuccess: createAction(
    BaseXsStore.getType(label, 'Move Success'),
    props<{ returnPath: any[] }>()
  )
});
