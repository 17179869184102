<ng-template #entityEditTemplate let-id="id" let-title="title">
  <nexnox-web-infos-info-edit #modelComponent
                              (modelChange)="onModelChange($event)"
                              [creating]="false"
                              [id]="id"
                              [loading]="loading$ | async"
                              [model]="model$ | async"
                              [readonly]="readonly$ | async"
                              [stereotyped]="false"
                              [title]="title">
  </nexnox-web-infos-info-edit>
</ng-template>

<nexnox-web-entity-detail *ngIf="loaded$ | async; else loadingTemplate"
                          [entityDetailBaseComponent]="this"
                          [entityEditTemplate]="entityEditTemplate"
                          [tabCommands]="['.']"
                          [tabHeading]="title | translate"
                          tabId="info">

  <!-- Progress -->
  <ng-template (selectTab)="onNavigateToTab(['progress'])"
               *ngIf="isShowProgressTab$ | async"
               [heading]="'orga-portal.infos.subtitles.info-progress' | translate"
               [lazy]="true"
               nexnoxWebTabTemplate
               tabId="progress">
    <nexnox-web-infos-info-progress *ngIf="passedIds"
                                    [parentIds]="passedIds">
    </nexnox-web-infos-info-progress>
  </ng-template>

  <!-- Attachments -->
  <ng-template (selectTab)="onNavigateToTab(['attachments'])"
               [hasError]="attachmentsComponent ? !attachmentsComponent.isModelValid() : false"
               [heading]="('orga-portal.subtitles.attachments' | translate) + (attachmentsComponent ? (attachmentsComponent.attachmentsString$ | async) : '')"
               nexnoxWebTabTemplate
               tabId="attachments">
    <nexnox-web-orga-attachments #attachmentsComponent
                                 #modelComponent
                                 (attachmentsChange)="onAttachmentsChange($event, model)"
                                 *ngIf="model$ | async as model"
                                 [attachments$]="attachments$"
                                 [loading]="loading$ | async"
                                 [readonly]="readonly$ | async">
    </nexnox-web-orga-attachments>
  </ng-template>
</nexnox-web-entity-detail>

<ng-template #loadingTemplate>
  <nexnox-web-cardbox [loading]="true">
  </nexnox-web-cardbox>
</ng-template>
