import {ChangeDetectionStrategy, Component, Injector} from '@angular/core';
import {ActionButton, CorePortalEntityDetailBaseComponent} from '@nexnox-web/core-portal';
import {AppPermissions, ExportType, FunctionDto} from '@nexnox-web/core-shared';
import {functionDetailStore} from '../../store';

@Component({
  selector: 'nexnox-web-master-data-functions-function-detail',
  templateUrl: './function-detail.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FunctionDetailComponent extends CorePortalEntityDetailBaseComponent<FunctionDto> {
  public title = 'core-portal.master-data.subtitles.function-detail';

  protected idParam = 'functionId';
  protected displayKey = 'name';

  constructor(
    protected injector: Injector
  ) {
    super(injector, functionDetailStore);
  }

  protected async getActionButtons(): Promise<ActionButton[]> {
    return this.getDefaultActionButtons(
      'core-portal.master-data.actions.edit-function',
      'core-portal.master-data.actions.save-function',
      'core-portal.master-data.actions.delete-function',
      'core-portal.master-data.descriptions.delete-function',
      AppPermissions.UpdateFunction,
      AppPermissions.DeleteFunction,
      ['/masterdata/functions'],
      undefined,
      undefined,
      ExportType.FUNCTION
    );
  }
}
