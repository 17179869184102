import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ContactDto, CoreSharedSidebarBaseComponent} from '@nexnox-web/core-shared';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {IconDefinition} from "@fortawesome/fontawesome-common-types";
import {BehaviorSubject} from "rxjs";
import {faTimes, faUser} from '@fortawesome/free-solid-svg-icons';
import {CorePortalContactService} from "libs/core-portal/src/lib/services/api/contact/contact.service";
import {TranslateService} from "@ngx-translate/core";


@Component({
  selector: 'nexnox-web-entity-datatable-export-sidebar',
  templateUrl: './entity-datatable-export-sidebar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EntityDatatableExportSidebarComponent extends CoreSharedSidebarBaseComponent implements OnInit {
  @Input() public totalInvitations: number;

  @Output() public accept: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('locationSelectLabelTitleTemplate', { static: true }) public locationSelectLabelTitleTemplate: TemplateRef<any>;
  @ViewChild('locationSelectOptionTitleTemplate', { static: true }) public locationSelectOptionTitleTemplate: TemplateRef<any>;
  @ViewChild('destinationTemplate', { static: true }) public destinationTemplate: TemplateRef<any>;
  @ViewChild('confirmButton', { static: true }) public confirmButton: HTMLButtonElement;

  public loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public form: FormGroup;
  public model: { contact: ContactDto, totalItems: number };
  public fields: FormlyFieldConfig[];

  public faUser: IconDefinition = faUser;
  public faTimes: IconDefinition = faTimes;

  constructor(
    private contactService: CorePortalContactService,
    private translate: TranslateService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.form = new FormGroup({});
    this.fields = this.createForm();
    this.model = {} as any;
  }

  public onShow(): void {
    super.onShow();
    this.ngOnInit();
    this.loading$.next(false);
  }

  public onHide(): void {
    this.loading$.next(false);
    super.onHide();
  }

  public onAccept(): void {
    this.loading$.next(true);
    this.accept.emit(this.model);
    this.onHide();
  }

  /* istanbul ignore next */
  private createForm(): FormlyFieldConfig[] {
    return [
      {
        key: 'contact',
        type: 'core-portal-entity-select',
        wrappers: ['core-portal-translated'],
        className: 'col-md-12',
        defaultValue: null,
        templateOptions: {
          showError: true,
          corePortalTranslated: {
            label: 'core-shared.shared.fields.receiver',
            validationMessages: {
              required: 'core-portal.core.validation.required',
              email: 'core-portal.core.validation.email'
            }
          },
          entityService: this.contactService,
          idKey: 'contactId',
          displayKey: 'displayName',
          wholeObject: true,
          addTagFn: (term: string) => ({ emailAddress: term, displayName: term }),
          selectLabelTemplate: this.destinationTemplate,
          selectOptionTemplate: this.destinationTemplate,
          required: true
        },
        validators: {
          email: ctrl => ctrl.value ?
            Boolean(ctrl.value.emailAddress) && !Validators.email(new FormControl(ctrl.value.emailAddress)) : true
        }
      },
      {
        key: 'totalItems',
        type: 'input',
        wrappers: ['core-portal-translated'],
        className: 'col-md-12',
        defaultValue: null,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.total-items'
          },
          type: 'number'
        },
        expressionProperties: {
          'templateOptions.placeholder': this.translate.stream('core-portal.core.general.all')
        }
      }
    ]
  }
}
