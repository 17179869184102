import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  ApiNotificationService,
  AppEntityType,
  ApplyFromTicketsByLocationDto,
  AppPermissions,
  ContactDto,
  CoreSharedSidebarBaseComponent,
  Filter,
  FilterKind,
  FilterOperators,
  FilterTypes,
  PreviewTicketByLocationDto,
  ResourceDto,
  StereotypeDto
} from '@nexnox-web/core-shared';
import {faUser} from '@fortawesome/free-solid-svg-icons/faUser';
import {FormGroup} from "@angular/forms";
import {FormlyFieldConfig} from "@ngx-formly/core";
import {
  CorePortalFormlyNgSelectOption,
  CorePortalFormlyReadonlyTypes,
  CorePortalFormlyReadonlyTyping,
  CorePortalFormlyTranslatedTyping,
  CorePortalPermissionService,
  CorePortalStereotypeService,
  FormlyTableLabelModes
} from "@nexnox-web/core-portal";
import {faTimes} from '@fortawesome/free-solid-svg-icons/faTimes';
import {BehaviorSubject, distinctUntilChanged, Observable, of, Subject} from "rxjs";
import {CorePortalFeatureMasterDataLocationService} from "./../../store";
import {filter, map, mergeMap} from "rxjs/operators";
import {CorePortalFeatureResourceService} from "@nexnox-web/core-portal/features/resources/src/lib/services/resource/resource.service";
import {cloneDeep} from "lodash";
import {
  CorePortalTicketSettingsPriorityService,
  CorePortalTicketSettingsStateService
} from "@nexnox-web/core-portal/features/settings/features/stereotypes/src/lib/store/services";
import {CorePortalFeatureMasterDataContactService} from "@nexnox-web/core-portal/features/master-data/features/contacts/src/lib/store/services/contact/contact.service";

interface SkeletonModelDto {
  skeletons: PreviewTicketByLocationDto[]
}

@Component({
  selector: 'nexnox-web-location-create-tickets-by-definition-sidebar',
  templateUrl: './create-tickets-by-definition-sidebar.component.html',
  styles: ['.sidebar-form-container {overflow: auto; overflow-y: scroll}'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateTicketsByDefinitionSidebarComponent extends CoreSharedSidebarBaseComponent implements OnInit {

  @Input() public locationStereotypeId$: Observable<number>;
  @Input() public locationId: number | string;
  @Output() public refreshList: EventEmitter<boolean> = new EventEmitter<boolean>();

  public canCreate$: Observable<boolean>;
  public loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public skeletons$: Observable<PreviewTicketByLocationDto[]>;

  public stereotypeId: number;
  public itemsSubject: Subject<CorePortalFormlyNgSelectOption[]> = new Subject<CorePortalFormlyNgSelectOption[]>();
  public tableValidSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public tickets: PreviewTicketByLocationDto[]

  public form: FormGroup;
  public model: SkeletonModelDto;
  public modelSubject: BehaviorSubject<SkeletonModelDto> = new BehaviorSubject<SkeletonModelDto>({ skeletons: [] });
  public fields: FormlyFieldConfig[];

  public labelModes = FormlyTableLabelModes;

  public faTimes = faTimes;
  public faUser = faUser;

  constructor(
    private locationService: CorePortalFeatureMasterDataLocationService,
    private permissionService: CorePortalPermissionService,
    private apiNotificationService: ApiNotificationService,
    private stereotypeService: CorePortalStereotypeService,
    private resourceService: CorePortalFeatureResourceService,
    private priorityService: CorePortalTicketSettingsPriorityService,
    private contactService: CorePortalFeatureMasterDataContactService,
    private stateService: CorePortalTicketSettingsStateService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.loading$.next(true);
    this.form = new FormGroup({});
    this.fields = this.createForm();
    this.canCreate$ = this.permissionService.hasPermission$(AppPermissions.CreateResourcesByLocationDefinition);

    this.skeletons$ = this.modelSubject.asObservable().pipe(map(model => model.skeletons));

    this.subscribe(this.locationStereotypeId$.pipe(
      distinctUntilChanged(),
      filter(id => Boolean(id)),
      map(id => this.stereotypeId = id),
      mergeMap((id) => this.locationService.getPreviewTicketsByLocation(id))
    ), (preview) => {
      const items = preview.definitions.map((definition) => ({ label: definition.title, value: definition.tickets }));
      this.loading$.next(false);
      setTimeout(() => {
        this.itemsSubject.next(items);
        this.form.updateValueAndValidity();
      });

    });
  }

  public isCreateDisabled(): boolean {
    return (this.tickets ?? []).length <= 0 || !this.tableValidSubject.getValue();
  }

  public onCreate(): void {
    this.loading$.next(true);
    this.subscribe(this.locationService.applyTickets(this.mapTickets(), this.locationId),
      () => {
        this.apiNotificationService.showTranslatedSuccess('core-shared.shared.toast.entity-added');
        this.refreshList.emit(true);
        this.loading$.next(false);
        this.onHide();
      },
      (error) => {
        this.apiNotificationService.handleApiError(error);
        this.loading$.next(false);
      }
    )
  }

  public onModelChange(model): void {
    this.modelSubject.next(model);
  }

  public onTicketsChange(resources): void {
    this.tickets = resources;
  }

  public onShow(): void {
    this.ngOnInit();
    super.onShow();
  }

  public onHide(): void {
    this.modelSubject.next({ skeletons: [] });
    this.form.reset();
    super.onHide();
  }

  public mapTickets(): ApplyFromTicketsByLocationDto {
    const tickets = cloneDeep(this.modelSubject.getValue().skeletons ?? []);
    const mappedTickets = [];
    for (let s = 0; s < tickets.length; s++) {
      const ticket = tickets[s];
      mappedTickets.push({
        ...ticket,
        stereotypeId: ticket?.stereotype.stereotypeId,
        stereotypeRowVersion: ticket?.stereotype?.rowVersion,
        stereotype: undefined
      });
    }
    return { tickets: mappedTickets };
  }

  /* istanbul ignore next */
  public createSkeletonFields(): FormlyFieldConfig[] {
    return [
      { key: 'ticketSkeletonId', defaultValue: 0 },
      {
        key: 'title',
        type: 'input',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-4',
        templateOptions: {
          required: true,
          corePortalTranslated: {
            label: 'core-shared.shared.fields.title',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.BASIC
          } as CorePortalFormlyReadonlyTyping,
          type: 'text'
        }
      },
      {
        key: 'currentState',
        type: 'core-portal-entity-select',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-4',
        defaultValue: null,
        templateOptions: {
          required: true,
          corePortalTranslated: {
            label: 'tickets.fields.current-state',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.ENTITY,
            displayKey: 'name'
          } as CorePortalFormlyReadonlyTyping,
          entityService: this.stateService,
          idKey: 'stateId',
          displayKey: 'name',
          firstToDefault: true,
          clearable: false,
          wholeObject: true,
          skipGetOne: true
        }
      },
      {
        key: 'priority',
        type: 'core-portal-entity-select',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-4',
        defaultValue: null,
        templateOptions: {
          required: true,
          corePortalTranslated: {
            label: 'core-shared.shared.fields.priority',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.ENTITY,
            displayKey: 'name'
          } as CorePortalFormlyReadonlyTyping,
          entityService: this.priorityService,
          idKey: 'priorityId',
          displayKey: 'name',
          wholeObject: true,
          clearable: false,
          firstToDefault: true,
          skipGetOne: true
        }
      },
      {
        key: 'stereotype',
        type: 'core-portal-entity-select',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-4',
        defaultValue: null,
        templateOptions: {
          required: true,
          corePortalTranslated: {
            label: 'core-shared.shared.fields.stereotype',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.ENTITY,
            displayKey: 'name',
            link: (stereotype: StereotypeDto) => stereotype?.stereotypeId ? ['stereotypes', stereotype.stereotypeId] : null,
            module: 'settings'
          } as CorePortalFormlyReadonlyTyping,
          entityService: this.stereotypeService,
          idKey: 'stereotypeId',
          displayKey: 'name',
          wholeObject: true,
          skipGetOne: true,
          clearable: true,
          firstToDefault: true,
          defaultFilters$: of([{
            property: 'entityType',
            type: FilterTypes.DataTransferObject,
            operator: FilterOperators.Equal,
            value: AppEntityType.Ticket.toString()
          }, {
            property: 'isArchived',
            type: FilterTypes.DataTransferObject,
            operator: FilterOperators.Equal,
            value: false.toString()
          }] as Filter[]),
          module: 'settings'
        }
      },
      {
        key: 'resource',
        type: 'core-portal-entity-select',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-4',
        defaultValue: null,
        templateOptions: {
          corePortalTranslated: {
            label: 'tickets.fields.resource',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            },
          } as CorePortalFormlyTranslatedTyping,

          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.ENTITY,
            displayKey: 'name',
            link: (resource: ResourceDto) => resource?.resourceId ? ['/resources', resource.resourceId] : null,
            module: 'inventory',
            suffix: (model) => model?.resource?.location ? `(${ model.resource.location.name })` : '',
          } as CorePortalFormlyReadonlyTyping,
          entityService: this.resourceService,
          idKey: 'resourceId',
          displayKey: 'name',
          wholeObject: true,
          skipGetOne: true,
          defaultFilters$: of([
            {
              property: 'locationId',
              operator: FilterOperators.Equal,
              value: this.locationId?.toString(),
              type: FilterTypes.DataTransferObject
            }
          ])
        }
      },
      {
        key: 'editor',
        type: 'core-portal-entity-select',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-4',
        defaultValue: null,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.editor'
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.ENTITY,
            displayKey: 'displayName',
            link: (contact: ContactDto) => contact?.contactId ? ['/masterdata', 'contacts', contact.contactId] : null,
            module: 'management'
          },
          entityService: this.contactService,
          idKey: 'contactId',
          displayKey: 'displayName',
          wholeObject: true,
          skipGetOne: true,
          defaultFilters$: of([{
            type: FilterTypes.DataTransferObject,
            operator: FilterOperators.Default,
            property: 'isFree',
            kind: FilterKind.Default,
            value: 'true'
          }] as Filter[])
        }
      }
    ];
  }

  /* istanbul ignore next */
  protected createForm(): FormlyFieldConfig[] {
    return [
      {
        key: 'skeletons',
        type: 'core-portal-ng-select',
        wrappers: ['core-portal-translated'],
        className: 'col-md-6',
        defaultValue: null,
        templateOptions: {
          corePortalTranslated: {
            label: '',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          corePortalNgSelect: {
            items$: this.itemsSubject.asObservable()
          }
        }
      },
    ];
  }
}
