import {BaseXsStore, BaseXsStoreReducerTypes} from '@nexnox-web/core-store';
import {AuthXsStoreState, getInitialAuthXsStoreState} from './auth-xs-store.state';
import {AuthXsStoreSelectors, createAuthXsStoreSelectors} from './auth-xs-store.selectors';
import {AuthXsStoreActions, createAuthXsStoreActions} from './auth-xs-store.actions';
import {createAuthXsStoreReducer} from './auth-xs-store.reducer';

export class AuthXsStore extends BaseXsStore<AuthXsStoreState> {
  public actions: AuthXsStoreActions;
  public selectors: AuthXsStoreSelectors;

  public getInitialState(): AuthXsStoreState {
    return getInitialAuthXsStoreState();
  }

  protected createActions(label: string): AuthXsStoreActions {
    return createAuthXsStoreActions(label);
  }

  protected createReducerArray(
    initialState: AuthXsStoreState
  ): BaseXsStoreReducerTypes<AuthXsStoreState, AuthXsStoreActions>[] {
    return [
      ...super.createReducerArray(initialState),
      ...createAuthXsStoreReducer(this.actions, initialState)
    ];
  }

  protected createSelectors(): AuthXsStoreSelectors {
    return {
      ...super.createSelectors(),
      ...createAuthXsStoreSelectors(this.options.stateSelector)
    };
  }
}
