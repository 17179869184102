import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {SolutionDto, SolutionMemberDto} from '@nexnox-web/core-shared';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'nexnox-web-solution-member-select',
  templateUrl: './solution-member-select.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TechPortalSolutionMemberSelectComponent {
  @Input() public selectedSolutionMember: SolutionMemberDto = null;
  @Input() public solution: SolutionDto;
  @Input() public loading: boolean;

  @Output() public selectedSolutionMemberChange: EventEmitter<SolutionMemberDto> = new EventEmitter<SolutionMemberDto>();

  constructor(
    private translate: TranslateService
  ) {
  }

  public searchFn = (term: string, item: SolutionMemberDto): boolean => this.search(term, item);

  public onSelectedSolutionMemberChange(solutionMember: SolutionMemberDto): void {
    this.selectedSolutionMemberChange.emit(solutionMember);
  }

  /* istanbul ignore next */
  private search(term: string, item: SolutionMemberDto): boolean {
    if (item.title.toLowerCase().includes(term.toLowerCase())) {
      return true;
    } else if (this.translate.instant(`solutions.solution-members.${ item.type }`).toLowerCase().includes(term.toLowerCase())) {
      return true;
    }

    return false;
  }
}
