import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Injector,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {
  CorePortalAttachmentsComponent,
  CorePortalEntityEditBaseComponent,
  CorePortalFormlyActionWrapperTyping,
  CorePortalFormlyReadonlyTypes,
  CorePortalFormlyReadonlyTyping,
  CorePortalPermissionService
} from '@nexnox-web/core-portal';
import {faPencilAlt} from '@fortawesome/free-solid-svg-icons/faPencilAlt';
import {CorePortalFeatureMasterDataContactService} from '@nexnox-web/core-portal/features/master-data/features/contacts';
import {CorePortalFeatureResourceService} from '@nexnox-web/core-portal/features/resources';
import {
  AppPermissions,
  AttachmentForTechDto,
  ContactDto,
  IAssignContactDto,
  IAssignResourcDto,
  LinkDto,
  MissionDto,
  MissionType,
  RenderedTemplateDto,
  ResourceDto,
  TemplateContextType
} from '@nexnox-web/core-shared';
import {timespanTo} from '@nexnox-web/lodash';
import {TechPortalLinksComponent} from '@nexnox-web/tech-portal-lib';
import {TechPortalFeatureTextTemplateApplySidebarComponent} from '@nexnox-web/tech-portal/features/templates';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {TranslateService} from '@ngx-translate/core';
import dayjs from 'dayjs';
import {BehaviorSubject, Observable} from 'rxjs';
import {distinctUntilChanged, map, mergeMap, skip, startWith} from 'rxjs/operators';
import {MissionAssignContactSidebarComponent, MissionAssignResourceSidebarComponent} from "../../sidebars";
import {ResourceDetailSidebarComponent} from "../../../../../resources/src/lib/sidebars";

// Due to generic use of MissionEditComponent in several features,
// this interface acts like a bridge to different Mission Typings
export interface ILocalMissionDto extends MissionDto {
  offsetByTemplate?: any;
  state?: any;
  isCompleted?: boolean;
}

@Component({
  selector: 'nexnox-web-missions-mission-edit',
  templateUrl: './mission-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TechPortalFeatureMissionEditComponent extends CorePortalEntityEditBaseComponent<ILocalMissionDto> implements OnInit {
  @Input() public assignContactLoading$: Observable<boolean>;
  @Input() public assignResourceLoading$: Observable<boolean>;

  @Output() public assignContact: EventEmitter<IAssignContactDto> = new EventEmitter<IAssignContactDto>();
  @Output() public unassignContact: EventEmitter<undefined> = new EventEmitter();

  @Output() public assignResource: EventEmitter<IAssignResourcDto> = new EventEmitter<IAssignResourcDto>();

  @ViewChild('linksComponent') public linksComponent: TechPortalLinksComponent;
  @ViewChild('attachmentsComponent') public attachmentsComponent: CorePortalAttachmentsComponent;
  @ViewChild('resourceDescriptionTemplate', { static: true }) public resourceDescriptionTemplate: TemplateRef<any>;
  @ViewChild('assignContactSidebar', { static: true }) public assignContactSidebar: MissionAssignContactSidebarComponent;
  @ViewChild('assignResourceSidebar', { static: false }) public assignResourceSidebar: MissionAssignResourceSidebarComponent;
  @ViewChild('textTemplateApplySidebar', { static: true }) public textTemplateApplySidebar: TechPortalFeatureTextTemplateApplySidebarComponent;
  @ViewChild('resourceDetailSidebar', { static: true }) public resourceDetailSidebar: ResourceDetailSidebarComponent;

  public missionType: MissionType;

  public linksSubject: BehaviorSubject<LinkDto[]> = new BehaviorSubject<LinkDto[]>([]);
  public attachmentsSubject: BehaviorSubject<AttachmentForTechDto[]> = new BehaviorSubject<AttachmentForTechDto[]>([]);

  public templateContextType$: Observable<TemplateContextType>;

  public readSolutionPermission$: Observable<boolean>;

  public isShowResourceAssignmentSidebar = false;

  private selectedResourceIdSubject: BehaviorSubject<number> = new BehaviorSubject<number>(null);

  constructor(
    protected injector: Injector,
    private contactService: CorePortalFeatureMasterDataContactService,
    private translate: TranslateService,
    private permissionService: CorePortalPermissionService,
    private resourceService: CorePortalFeatureResourceService,
  ) {
    super(injector);

    this.readSolutionPermission$ = this.permissionService.hasPermission$(AppPermissions.ReadSolution);

    this.templateContextType$ = this.modelSubject.asObservable().pipe(
      map(model => {
        switch (model?.type) {
          case MissionType.Ticket:
            return TemplateContextType.MissionByTicketDescription;
          case MissionType.Task:
            return TemplateContextType.MissionByTaskDescription;
          default:
            return TemplateContextType.MissionDescription;
        }
      })
    );
  }

  public ngOnInit(): void {
    super.ngOnInit();
  }

  public onLinksChange(links: LinkDto[]): void {
    this.setModel({ ...this.model, links });
    setTimeout(() => this.onModelChange(this.model));
  }

  public onAttachmentsChange(attachments: AttachmentForTechDto[]): void {
    this.setModel({ ...this.model, attachments });
    setTimeout(() => this.onModelChange(this.model));
  }

  public onTextTemplateApplied(template: RenderedTemplateDto): void {
    this.setModel({ ...this.model, description: template.content });
    setTimeout(() => this.onModelChange(this.model));
  }

  public onModelChange(model: MissionDto): void {
    this.modelValidSubject.next({
      ...this.modelValidSubject.getValue(),
      links: this.linksComponent ? this.linksComponent.isModelValid() : true,
      attachments: this.attachmentsComponent ? this.attachmentsComponent.isModelValid() : true
    });

    super.onModelChange(model);
  }

  /* istanbul ignore next */
  protected createForm(): FormlyFieldConfig[] {
    return [
      {
        key: 'title',
        type: 'input',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-12',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.title',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.BASIC
          } as CorePortalFormlyReadonlyTyping,
          type: 'text'
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        },
        hideExpression: () => !this.creating
      },
      {
        key: 'description',
        type: 'core-portal-editor',
        wrappers: ['core-portal-translated'],
        className: 'col-md-12',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.description'
          },
          corePortalEditor: {
            language: this.translate.currentLang,
            buttons: [{
              label: 'core-portal.settings.actions.templates.apply-text-template',
              show$: this.permissionService.hasPermission$(AppPermissions.ReadTemplate),
              click: () => this.textTemplateApplySidebar.onShow()
            }]
          }
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly
        }
      },
      {
        type: 'core-portal-divider',
        className: 'col-md-12'
      },
      {
        key: 'solutionContact',
        type: 'core-portal-entity-select',
        wrappers: ['core-portal-translated', 'core-portal-actions', 'core-portal-readonly'],
        className: 'col-md-6',
        defaultValue: null,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.editor'
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.ENTITY,
            displayKey: 'displayName',
            link: (contact: ContactDto) => contact?.contactId ? ['/masterdata', 'contacts', contact.contactId] : null,
            module: 'management'
          } as CorePortalFormlyReadonlyTyping,
          corePortalActionWrapper: {
            actions: [{
              icon: faPencilAlt,
              tooltip: 'core-portal.core.general.edit',
              loading$: this.assignContactLoading$,
              hidden: (model: MissionDto) => this.creating || model.isCompleted,
              onClick: () => this.assignContactSidebar.onShow(),
              permissions$: [AppPermissions.AssignMissionEditor, AppPermissions.AssignMissionToMe].map((p) => this.permissionService.hasPermission$(p)),
            }]
          } as CorePortalFormlyActionWrapperTyping,
          entityService: this.contactService,
          idKey: 'contactId',
          displayKey: 'displayName',
          wholeObject: true,
          skipGetOne: true,
          link: (contact: ContactDto) => contact?.contactId ? ['/masterdata', 'contacts', contact.contactId] : null,
          module: 'management'
        },
        expressionProperties: {
          'templateOptions.readonly': () => !this.creating
        }
      },
      {
        key: 'resource',
        type: 'core-portal-entity-select',
        wrappers: ['core-portal-translated', 'core-portal-actions', 'core-portal-readonly'],
        className: 'col-md-6',
        defaultValue: null,
        templateOptions: {
          corePortalTranslated: {
            rateable: true,
            label: 'core-shared.shared.fields.resource',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            },
            descriptionTemplate: this.resourceDescriptionTemplate
          },
          corePortalActionWrapper: {
            actions: [{
              icon: faPencilAlt,
              tooltip: 'core-portal.core.general.edit',
              loading$: this.assignResourceLoading$,
              hidden: (model) => this.creating || model.isCompleted,
              onClick: () => this._openResourceSidebar(),
              permissions$: [this.permissionService.hasPermission$(AppPermissions.AssignMissionResource)]
            }]
          } as CorePortalFormlyActionWrapperTyping,
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.ENTITY,
            displayKey: 'name',
            linkAction: (resource: ResourceDto) => this.resourceDetailSidebar.onShow(resource)
          } as CorePortalFormlyReadonlyTyping,
          entityService: this.resourceService,
          idKey: 'resourceId',
          displayKey: 'name',
          wholeObject: true,
          skipGetOne: true,
          link: (resource: ResourceDto) => resource?.resourceId ? ['/resources', resource.resourceId] : null,
          module: 'inventory',
        },
        expressionProperties: {
          'templateOptions.required': () => true,
          'templateOptions.readonly': () => true,
        },
        hooks: {
          onInit: field => this.subscribe(field.formControl.valueChanges.pipe(
            startWith(field.formControl.value),
            distinctUntilChanged()
          ), (resource: ResourceDto) => this.selectedResourceIdSubject.next(resource?.resourceId))
        }
      },

      {
        type: 'core-portal-divider',
        className: 'col-md-12'
      },

      {
        key: 'plannedStart',
        type: 'core-portal-date-time-picker',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.planned-start',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.DATE,
            format: 'L LT'
          } as CorePortalFormlyReadonlyTyping
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.disabled': this.permissionService.hasPermission$(AppPermissions.UpdatePlannedMissionDates).pipe(
            mergeMap(hasPermission => this.readonlySubject.asObservable().pipe(
              map(readonly => !hasPermission || readonly)
            ))
          ),
          'templateOptions.readonly': () => this.readonly
        },
        hooks: {
          onInit: field => this.subscribe(field.formControl.valueChanges.pipe(
            distinctUntilChanged(),
            skip(!this.creating ? 1 : 0)
          ), (value: string) => {
            let date = dayjs.utc(value);

            if (!value || !date.isValid()) {
              this.form.controls.plannedEnd.setValue(null);
              return;
            }

            if (this.model.offsetByTemplate) {
              const { days, hours, minutes } = timespanTo(this.model.offsetByTemplate);
              date = date
                .add(days, 'day')
                .add(hours, 'hour')
                .add(minutes, 'minute');
            }

            this.form.controls.plannedEnd.setValue(date.format());
          })
        }
      },
      {
        key: 'plannedEnd',
        type: 'core-portal-date-time-picker',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.planned-end',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.DATE,
            format: 'L LT'
          } as CorePortalFormlyReadonlyTyping
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.disabled': this.permissionService.hasPermission$(AppPermissions.UpdatePlannedMissionDates).pipe(
            mergeMap(hasPermission => this.readonlySubject.asObservable().pipe(
              map(readonly => !hasPermission || readonly)
            ))
          ),
          'templateOptions.readonly': () => this.readonly
        }
      },
      {
        key: 'actualStart',
        type: 'core-portal-date-time-picker',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.actual-start'
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.DATE,
            format: 'L LT'
          } as CorePortalFormlyReadonlyTyping
        },
        expressionProperties: {
          'templateOptions.disabled': this.permissionService.hasPermission$(AppPermissions.UpdateActualMissionDates).pipe(
            mergeMap(hasPermission => this.readonlySubject.asObservable().pipe(
              map(readonly => !hasPermission || readonly)
            ))
          ),
          'templateOptions.readonly': () => this.readonly
        }
      },
      {
        key: 'actualEnd',
        type: 'core-portal-date-time-picker',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.actual-end'
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.DATE,
            format: 'L LT'
          } as CorePortalFormlyReadonlyTyping
        },
        expressionProperties: {
          'templateOptions.disabled': this.permissionService.hasPermission$(AppPermissions.UpdateActualMissionDates).pipe(
            mergeMap(hasPermission => this.readonlySubject.asObservable().pipe(
              map(readonly => !hasPermission || readonly)
            ))
          ),
          'templateOptions.readonly': () => this.readonly
        }
      },
      ...this.getStereotypeFields()
    ];
  }

  protected setModel(model: MissionDto): void {
    super.setModel(model);

    this.linksSubject.next(model?.links ?? []);
    this.attachmentsSubject.next(model?.attachments ?? []);
  }

  protected setReadonly(readonly: boolean): void {
    super.setReadonly(readonly);

    this.linksComponent?.onReset();
    this.attachmentsComponent?.onReset();
  }

  private _openResourceSidebar(): void {
    // Workaround for saved filters to prevent sidebar from loading table
    // before a location has been initialized
    this.isShowResourceAssignmentSidebar = true;
    setTimeout(() => {
      this.assignResourceSidebar.onShow();
      this.subscribe(this.assignResourceSidebar.visible$, visible => {
        this.isShowResourceAssignmentSidebar = visible;
      });
    });
  }
}
