import {NgModule} from '@angular/core';
import {CorePortalEntityModule} from '@nexnox-web/core-portal';
import {CoreSharedModule} from '@nexnox-web/core-shared';
import {TenantEditComponent, TenantSubscriptionEditComponent, TenantUsageComponent} from './components';
import {
  TenantDetailComponent,
  TenantListComponent,
  TenantSubscriptionListComponent,
  TenantSystemContactListComponent
} from './containers';
import {CreateSystemContactSidebarComponent} from './sidebars';
import {tenantsServices} from './store';

@NgModule({
  declarations: [
    TenantEditComponent,
    TenantSubscriptionEditComponent,

    TenantListComponent,
    TenantDetailComponent,
    TenantSubscriptionListComponent,
    TenantSystemContactListComponent,
    CreateSystemContactSidebarComponent,
    TenantUsageComponent,
  ],
  imports: [
    CoreSharedModule,
    CorePortalEntityModule
  ],
  exports: [
    TenantUsageComponent
  ],
  providers: [
    ...tenantsServices
  ]
})
export class TenantsDeclarationsModule {
}
