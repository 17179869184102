import {Injectable} from '@angular/core';
import {UuidService} from '../uuid/uuid.service';

@Injectable()
export class CoreSharedApplicationUuidService {
  private uuid: string;

  constructor(
    private uuidService: UuidService
  ) {
  }

  public generateUuid(): string {
    this.uuid = this.uuidService.generateUuid();
    return this.uuid;
  }

  public getUuid(): string {
    return this.uuid;
  }
}
