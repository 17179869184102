import {PagedEntitiesXsStore, PagedEntitiesXsStoreState} from '@nexnox-web/core-store';
import {AppEntityType, HintCodeDto} from '@nexnox-web/core-shared';
import {createSelector} from '@ngrx/store';
import {selectHintCodesState} from '../../hint-codes.selectors';
import {TechPortalFeatureConnectedHintCodeService} from '../../services';

export interface HintCodeListStoreState extends PagedEntitiesXsStoreState<HintCodeDto> {
}

/* istanbul ignore next */
export const hintCodeListStore = new PagedEntitiesXsStore<HintCodeDto>({
  actionLabel: 'Tech Portal - Connected - Hint Codes - Hint Code List',
  stateSelector: createSelector(selectHintCodesState, state => state.hintCodeList),
  serviceType: TechPortalFeatureConnectedHintCodeService,
  entityType: AppEntityType.None,
  selectId: hintCode => hintCode.entity?.hintCodeId ?? hintCode.model?.hintCodeId,
  stereotyped: false
});
