import {EntityXsStore, EntityXsStoreState} from '@nexnox-web/core-store';
import {AppEntityType, HintCodeDto} from '@nexnox-web/core-shared';
import {createSelector} from '@ngrx/store';
import {TechPortalFeatureConnectedHintCodeService} from '../../services';
import {selectHintCodesState} from '../../hint-codes.selectors';

export interface HintCodeDetailStoreState extends EntityXsStoreState<HintCodeDto> {
}

/* istanbul ignore next */
export const hintCodeDetailStore = new EntityXsStore<HintCodeDto>({
  actionLabel: 'Tech Portal - Connected - Hint Codes - Hint Code Detail',
  stateSelector: createSelector(selectHintCodesState, state => state.hintCodeDetail),
  serviceType: TechPortalFeatureConnectedHintCodeService,
  entityType: AppEntityType.None,
  stereotyped: false
});
