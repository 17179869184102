import {FieldType} from '@ngx-formly/core';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {ColorEvent} from 'ngx-color';
import {Subject, Subscription} from 'rxjs';
import {debounceTime} from 'rxjs/operators';

@Component({
  selector: 'nexnox-web-formly-color-picker',
  templateUrl: './formly-color-picker.component.html',
  styleUrls: ['./formly-color-picker.component.scss']
})
export class FormlyColorPickerComponent extends FieldType implements OnInit, OnDestroy {
  private colorSubject = new Subject<string>();

  private colorSubscription: Subscription;

  public ngOnInit(): void {
    this.colorSubscription = this.colorSubject.asObservable().pipe(
      debounceTime(400)
    ).subscribe(color => this.formControl.setValue(color));
  }

  public ngOnDestroy(): void {
    if (this.colorSubscription && !this.colorSubscription.closed) {
      this.colorSubscription.unsubscribe();
    }
  }

  public onChange(colorEvent: ColorEvent): void {
    this.colorSubject.next(colorEvent.color.hex);
  }
}
