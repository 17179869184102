import {HttpClient} from "@angular/common/http";
import {Inject, Injectable} from "@angular/core";
import {ApiNotificationService, CORE_SHARED_ENVIRONMENT, Environment} from "@nexnox-web/core-shared";
import {BehaviorSubject, catchError, map, Observable, of, Subject} from "rxjs";
import {UnsubscribeHelper} from "../../helper";
import {LangChangeEvent, TranslateService} from "@ngx-translate/core";
import {filter, mergeMap, startWith, take} from "rxjs/operators";
import {isUndefined} from "lodash";

@Injectable({ providedIn: 'root' })
export class CoreSharedGoogleMapsService extends UnsubscribeHelper {

  private _geocoderService: google.maps.Geocoder;
  private _isApiLoaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _timeout = 7000;

  constructor(
    @Inject(CORE_SHARED_ENVIRONMENT) private environment: Environment,
    private _http: HttpClient,
    private _translate: TranslateService,
    private _apiNotificationService: ApiNotificationService
  ) {
    super();
  }

  public get isApiLoaded(): boolean {
    return this._isApiLoaded$.value;
  }

  public failureFn = (): void => this._apiNotificationService.showTranslatedError('core-portal.core.error.google-api-auth-failure');

  public loadMapsApi(): Observable<boolean> {
    if (this.isApiLoaded === false) {
      this.subscribe(
        this._translate.onLangChange
          .pipe(
            startWith({ lang: this._translate.currentLang }),
            filter((e: LangChangeEvent) => !isUndefined(e.lang)),
            take(1),
            mergeMap((e: LangChangeEvent) => this._http.jsonp(`https://maps.googleapis.com/maps/api/js?key=${ this.environment.google.apiKey }&language=${ e.lang.split('-')[0] }&libraries=places`, 'callback')
              .pipe(
                map(() => true),
                catchError((error) => {
                  console.log(error);
                  this._apiNotificationService.handleApiError(error);
                  return of(false)
                })
              ))
          ),
        (isLoaded) => this._isApiLoaded$.next(isLoaded)
      );
    }

    (window as any).gm_authFailure = this.failureFn;

    return this.getApiStatus();
  }

  public getApiStatus(): Observable<boolean> {
    return this._isApiLoaded$.asObservable();
  }

  public getPlacesService(map: google.maps.Map): google.maps.places.PlacesService {
    // since a places-service needs a map for initialization,
    // it is not recommended to treat it as singleton
    return new google.maps.places.PlacesService(map);
  }

  public getGeocoderService(): google.maps.Geocoder {
    if (!this._geocoderService) {
      this._geocoderService = new google.maps.Geocoder();
    }
    return this._geocoderService;
  }

  public getElementById(id: string): Observable<any> {
    const subject = new Subject<any>();
    this._waitForElement(id, subject);
    return subject.asObservable();
  }

  /* istanbul ignore next */
  private _waitForElement(id, subject): void {
    let element: HTMLElement;

    // Find interval
    const interval = setInterval(() => {
      element = document.getElementById(id);
      if (element) {
        subject.next(element);
        clearInterval(interval);
      }
    }, 250);

    // Undefined timeout
    setTimeout(() => {
      clearInterval(interval);
      if (!element) {
        subject.error(new Error(`Timeout exception: No element with id '${ id }' found.`));
      }
    }, this._timeout);
  }
}

