import {Injectable, Injector} from '@angular/core';
import {authStore} from '@nexnox-web/core-portal';
import {AppEntityType, TicketByContractDefinitionDto} from '@nexnox-web/core-shared';
import {createEffect, ofType} from '@ngrx/effects';
import {Action, createSelector, select} from '@ngrx/store';
import {of} from 'rxjs';
import {catchError, map, switchMap, take, tap, withLatestFrom} from 'rxjs/operators';
import {
  ContractSettingsXsStore,
  ContractSettingsXsStoreActions,
  ContractSettingsXsStoreState
} from './contract-settings.xs-store';
import {selectStereotypesState} from '../../stereotypes.selector';
import {CorePortalStereotypeContractSettingsService} from "../../services";

export interface ContractSettingsStoreState extends ContractSettingsXsStoreState {
}

/* istanbul ignore next */
export const contractSettingsStore = new ContractSettingsXsStore({
  actionLabel: 'Core Portal - Settings - Stereotypes - Contract Settings',
  stateSelector: createSelector(selectStereotypesState, state => state.contractSettings),
  serviceType: CorePortalStereotypeContractSettingsService,
  entityType: AppEntityType.None,
  stereotyped: false
});

@Injectable()
export class ContractSettingsStoreEffects extends contractSettingsStore.effects {
  public create$: any;
  public createSuccess$: any;

  public actions: ContractSettingsXsStoreActions;

  protected service: CorePortalStereotypeContractSettingsService;

  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }

  protected createEffects(): void {
    super.createEffects();

    this.create$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.create),
      withLatestFrom(this.store.pipe(select(authStore.selectors.selectActiveTenant))),
      switchMap(([{ model, contractStereotypeId }, activeTenant]) => this.service.createOne({
        ...model,
        contractStereotypeId,
        tenantId: activeTenant.tenantId
      }).pipe(
        map(contractSettings => this.actions.createSuccess({
          entity: this.prepareEntity(contractSettings),
          model: this.prepareModel(this.prepareEntity(contractSettings), {} as any)
        })),
        catchError(error => of(this.actions.error({ error, action: this.actions.create })))
      ))
    ));

    this.createSuccess$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.createSuccess),
      tap(action => this.actionCallback(action, false))
    ), { dispatch: false });
  }

  protected actionCallback(action: Action, isError: boolean): void {
    super.actionCallback(action, isError);

    this.checkAction(this.actions.createSuccess, action, ({ entity }) =>
      this.createSuccessActionCallback(entity.definitionId));
  }

  protected async createSuccessActionCallback(definitionId: number): Promise<void> {
    this.apiNotificationService.showTranslatedSuccess('core-shared.shared.toast.entity-created');

    const entity = await this.store.pipe(select(contractSettingsStore.selectors.selectEntity), take(1)).toPromise();
    const model = await this.store.pipe(select(contractSettingsStore.selectors.selectModel), take(1)).toPromise();
    this.store.dispatch(contractSettingsStore.actions.saveSuccess({
      entity: { ...entity, definitionId },
      model: { ...model, definitionId },
      doNotEmitEvents: true
    }));
  }

  protected async deleteSuccessActionCallback(returnPath?: any[]): Promise<void> {
    this.store.dispatch(contractSettingsStore.actions.saveSuccess({
      entity: {} as TicketByContractDefinitionDto,
      model: {} as TicketByContractDefinitionDto,
      doNotEmitEvents: true
    }));

    super.deleteSuccessActionCallback(returnPath);
  }
}
