import {OrgaPortalFeatureFormService} from './services';
import {formDetailStore, formListStore} from './stores';

export * from './services';
export * from './stores';

export * from './forms.state';
export * from './forms.selectors';

export const formsReducers = {
  formList: formListStore.reducer,
  formDetail: formDetailStore.reducer
};

export const formsEffects = [
  formListStore.effects,
  formDetailStore.effects
];

export const formsServices = [
  OrgaPortalFeatureFormService
];
