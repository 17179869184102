import {ChangeDetectionStrategy, Component, Injector, OnInit} from '@angular/core';
import {
  CorePortalEntityEditBaseComponent,
  CorePortalFormlyReadonlyTypes,
  CorePortalFormlyReadonlyTyping,
  CorePortalFormlyTranslatedTyping
} from '@nexnox-web/core-portal';
import {SearchListDto} from '@nexnox-web/core-shared';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {distinctUntilChanged, filter, map, Observable, take} from "rxjs";
import {isArray, isEqual} from "lodash";

interface LocalSearchListDto extends SearchListDto {
  searchListInput?: string;
}

@Component({
  selector: 'nexnox-web-settings-searchlists-searchlist-edit',
  templateUrl: './search-list-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CorePortalFeatureSettingsSearchListEditComponent extends CorePortalEntityEditBaseComponent<LocalSearchListDto> implements OnInit {

  private items$: Observable<string[]>;


  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }

  public ngOnInit(): void {
    super.ngOnInit();

    this.items$ = this.modelSubject.pipe(
      map(model => model.items),
      filter(items => isArray(items)),
      distinctUntilChanged((previous, current) => isEqual(previous, current))
    );
  }

  /* istanbul ignore next */
  protected createForm(): FormlyFieldConfig[] {
    return [
      {
        key: 'title',
        type: 'input',
        wrappers: ['core-portal-translated'],
        className: 'col-md-12',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.title',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          type: 'text'
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.disabled': () => this.readonly
        },
        hideExpression: () => !this.creating
      },
      {
        key: 'searchListInput',
        type: 'core-portal-editor',
        className: 'col-md-6',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        templateOptions: {
          type: 'text',
          rows: 15,
          corePortalTranslated: {
            label: 'core-portal.settings.fields.search-list-terms',
          } as CorePortalFormlyTranslatedTyping,
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.BASIC
          } as CorePortalFormlyReadonlyTyping,
          corePortalEditor: {
            noHTML: true
          },
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        },
        hooks: {
          onInit: (field) => {
            this.subscribe(field.formControl.valueChanges, (value) => {
              this.model.items = this._convertInputToItems(value);
            });

            this.subscribe(this.items$.pipe(take(1)), (items) => {
              this.model.searchListInput = this._convertItemsToInput(items);
            });
          }
        }
      }
    ];
  }

  private _convertInputToItems(value: string): string[] {
    return (value ?? '').trim().split('\n');
  }

  private _convertItemsToInput(items: string[]): string {
    items = items ?? [];
    let input = '';

    for (let i = 0; i < items.length; i++) {
      input = `${ input }${ (items[i] ?? '').toString() }\n`;
    }
    return input.trim();
  }
}
