<div *ngIf="isItemVisible$ | async"
     [class.sidebar-item--child]="level > 0"
     class="sidebar-item">
  <ng-container *ngIf="item$ | async as sidebarItem">
    <a [class.sidebar-item__content--expanded]="sidebarItem.expanded"
       [module]="sidebarItem.module"
       [nexnoxWebDisableRouterLink]="!!sidebarItem.children"
       [nexnoxWebTenantRouterLink]="sidebarItem.path"
       class="sidebar-item__content"
       nexnoxWebTenantRouterLinkActive="sidebar-item__content--active"
    >
      <div (click)="onItemClicked(!sidebarItem.children, $event)"
           [style.padding-left]="0.75 + (level * 2.25) + 'rem'"
           class="sidebar-item__content__inner-content">
        <span class="sidebar-item__content__active"></span>
        <fa-icon *ngIf="sidebarItem.icon"
                 [fixedWidth]="true"
                 [icon]="sidebarItem.icon"
                 class="mr-2"
                 size="sm">
        </fa-icon>
        {{ sidebarItem.title | translate }}

        <fa-icon *ngIf="sidebarItem.children"
                 [icon]="sidebarItem.expanded ? faChevronUp : faChevronDown"
                 class="sidebar-item__content__expand">
        </fa-icon>
      </div>
    </a>

    <div [@expandedCollapsed]="sidebarItem.expanded ? 'expanded' : 'collapsed'"
         [class.--expanded]="sidebarItem.expanded"
         class="sidebar-item__children">
      <ng-container *ngFor="let childItem of sidebarItem.children; trackBy: trackChildrenBy">
        <nexnox-web-sidebar-item (click)="onItemClicked(!childItem.children)"
                                 [checkPermissionFn]="checkPermissionFn"
                                 [item]="childItem"
                                 [level]="level + 1"
                                 [moduleIsAnimating$]="moduleIsAnimating$"
                                 [parent]="this">
        </nexnox-web-sidebar-item>
      </ng-container>
    </div>

  </ng-container>
</div>
