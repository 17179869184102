import {LocationSimpleDto, Paging, ResourceSimpleListDto, ResourceState, ResourceType} from '@nexnox-web/core-shared';
import {
  BaseXsStore,
  BaseXsStoreActions,
  BaseXsStoreOptions,
  BaseXsStoreReducerTypes,
  EmptyAction,
  PropsAction
} from '@nexnox-web/core-store';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {
  ResourceUIEntitiesXsStoreGetChildrenPayload,
  ResourceUIEntitiesXsStoreGetChildrenSuccessPayload,
  ResourceUIEntitiesXsStoreRemoveOnePayload,
  ResourceUIEntitiesXsStoreUpsertManyPayload
} from './resource-ui-entities-xs-store.payload';
import {createAction, createSelector, MemoizedSelector, on, props} from '@ngrx/store';
import {values} from 'lodash';
import {EntitySelectors} from '@ngrx/entity/src/models';
import {Injectable, Injector, Type} from '@angular/core';
import {ResourceUIEntitiesXsStoreEffects} from './resource-ui-entitites-xs-store.effects';
import {resourceEntitiesStore} from '../resource-entities';
import {immerOn} from 'ngrx-immer/store';

export interface ResourceUIEntitiesXsStoreEntity {
  resourceId: number;
  parentId: number;
  paging: Paging;
  loading: boolean;
  loaded: boolean;
  appendLoading: boolean;
  type: ResourceType;
  isInProgress: boolean;
  state: ResourceState;
  location: LocationSimpleDto;
}

export const getInitialResourceUIEntity = (resourceId: number, parentId: number = null, type: ResourceType = 0,
                                           isInProgress: boolean = false,
                                           state: ResourceState = ResourceState.Operation,
                                           location: LocationSimpleDto = null): ResourceUIEntitiesXsStoreEntity => ({
  resourceId,
  parentId,
  type,
  isInProgress,
  state,
  location,
  paging: null,
  loading: false,
  loaded: false,
  appendLoading: false
});

export interface ResourceUIEntitiesXsStoreState extends EntityState<ResourceUIEntitiesXsStoreEntity> {
}

export interface ResourceUIEntitiesXsStoreActions extends BaseXsStoreActions {
  upsertMany: PropsAction<ResourceUIEntitiesXsStoreUpsertManyPayload>;

  getChildRoot: PropsAction<ResourceUIEntitiesXsStoreGetChildrenPayload>;
  getChildRootSuccess: PropsAction<ResourceUIEntitiesXsStoreGetChildrenSuccessPayload>;

  getChildNext: PropsAction<ResourceUIEntitiesXsStoreGetChildrenPayload>;
  getChildNextSuccess: PropsAction<ResourceUIEntitiesXsStoreGetChildrenSuccessPayload>;

  removeOne: PropsAction<ResourceUIEntitiesXsStoreRemoveOnePayload>;

  clear: EmptyAction;
}

export interface ResourceUIEntitiesXsStoreSelectors
  extends EntitySelectors<ResourceUIEntitiesXsStoreEntity, ResourceUIEntitiesXsStoreState> {
  selectUIEntity: (resourceId: number) => MemoizedSelector<ResourceUIEntitiesXsStoreState, ResourceUIEntitiesXsStoreEntity>;

  selectPaging: (resourceId: number) => MemoizedSelector<ResourceUIEntitiesXsStoreState, Paging>;
  selectLoading: (resourceId: number) => MemoizedSelector<ResourceUIEntitiesXsStoreState, boolean>;
  selectLoaded: (resourceId: number) => MemoizedSelector<ResourceUIEntitiesXsStoreState, boolean>;
  selectAppendLoading: (resourceId: number) => MemoizedSelector<ResourceUIEntitiesXsStoreState, boolean>;

  selectFiltered: (ids: Array<string | number>) => MemoizedSelector<ResourceUIEntitiesXsStoreState, ResourceUIEntitiesXsStoreEntity[]>;
  selectMerged: (ids: Array<string | number>) =>
    MemoizedSelector<ResourceUIEntitiesXsStoreState, Array<ResourceUIEntitiesXsStoreEntity & ResourceSimpleListDto>>;

  selectChildren: (parentId: number) => MemoizedSelector<ResourceUIEntitiesXsStoreState, ResourceUIEntitiesXsStoreEntity[]>;
  selectMergedChildren: (parentId: number) => MemoizedSelector<ResourceUIEntitiesXsStoreState, Array<ResourceUIEntitiesXsStoreEntity & ResourceSimpleListDto>>;

  selectInProgress: () => MemoizedSelector<ResourceUIEntitiesXsStoreState, ResourceUIEntitiesXsStoreEntity[]>;
}

export class ResourceUIEntitiesXsStore extends BaseXsStore<ResourceUIEntitiesXsStoreState> {
  public actions: ResourceUIEntitiesXsStoreActions;
  public effects: Type<ResourceUIEntitiesXsStoreEffects>;
  public selectors: ResourceUIEntitiesXsStoreSelectors;

  protected adapter: EntityAdapter<ResourceUIEntitiesXsStoreEntity>;

  constructor(
    protected options: BaseXsStoreOptions<ResourceUIEntitiesXsStoreState>
  ) {
    super(options);

    this.adapter = this.createAdapter();
  }

  public getInitialState(): ResourceUIEntitiesXsStoreState {
    if (!this.adapter) {
      this.adapter = this.createAdapter();
    }

    return {
      ...this.adapter.getInitialState(),
      ...super.getInitialState()
    };
  }

  protected createActions(label: string): ResourceUIEntitiesXsStoreActions {
    return {
      ...super.createActions(label),

      upsertMany: createAction(
        BaseXsStore.getType(label, 'Upsert many'),
        props<ResourceUIEntitiesXsStoreUpsertManyPayload>()
      ),

      getChildRoot: createAction(
        BaseXsStore.getType(label, 'Get child root'),
        props<ResourceUIEntitiesXsStoreGetChildrenPayload>()
      ),
      getChildRootSuccess: createAction(
        BaseXsStore.getType(label, 'Get child root success'),
        props<ResourceUIEntitiesXsStoreGetChildrenSuccessPayload>()
      ),

      getChildNext: createAction(
        BaseXsStore.getType(label, 'Get child next'),
        props<ResourceUIEntitiesXsStoreGetChildrenPayload>()
      ),
      getChildNextSuccess: createAction(
        BaseXsStore.getType(label, 'Get child next success'),
        props<ResourceUIEntitiesXsStoreGetChildrenSuccessPayload>()
      ),

      removeOne: createAction(
        BaseXsStore.getType(label, 'Remove one'),
        props<ResourceUIEntitiesXsStoreRemoveOnePayload>()
      ),

      clear: createAction(BaseXsStore.getType(label, 'Clear'))
    };
  }

  protected createReducerArray(
    initialState: ResourceUIEntitiesXsStoreState
  ): BaseXsStoreReducerTypes<ResourceUIEntitiesXsStoreState, ResourceUIEntitiesXsStoreActions>[] {
    return [
      ...super.createReducerArray(initialState),

      on(this.actions.upsertMany, (state, { items }) => {
        const resourceUIItems = items.map(item => getInitialResourceUIEntity(item.resourceId, item.parentId, item.type, Boolean(item.isInProgressSince), item.currentState, item.location));
        return this.adapter.upsertMany(resourceUIItems, state);
      }),

      on(this.actions.getChildRoot, (state, { parentId }) => {
        const entitiesToDelete = values(state.entities).filter(x => x.parentId === parentId);
        const newState = this.adapter.removeMany(entitiesToDelete.map(x => x.resourceId), state);

        return {
          ...newState,
          entities: {
            ...newState.entities,
            [parentId]: {
              ...newState.entities[parentId],
              loading: true
            }
          }
        };
      }),

      immerOn(this.actions.getChildRootSuccess, (draft, { parentId, paging }) => {
        draft.entities[parentId].loading = false;
        draft.entities[parentId].loaded = true;
        draft.entities[parentId].paging = paging;
      }),

      immerOn(this.actions.getChildNext, (draft, { parentId }) => {
        draft.entities[parentId].appendLoading = true;
      }),

      immerOn(this.actions.getChildNextSuccess, (draft, { parentId, paging }) => {
        draft.entities[parentId].appendLoading = false;
        draft.entities[parentId].paging = paging;
      }),

      on(this.actions.removeOne, (state, { resourceId }) => this.adapter.removeOne(resourceId, state)),

      on(this.actions.clear, () => this.getInitialState())
    ];
  }

  protected createEffects(...args: any[]): Type<ResourceUIEntitiesXsStoreEffects> {
    const actions = this.actions;
    const selectors = this.selectors;

    @Injectable()
    class Effects extends ResourceUIEntitiesXsStoreEffects {
      constructor(
        protected injector: Injector
      ) {
        super(injector, actions, selectors);
      }
    }

    return Effects;
  }

  /* istanbul ignore next */
  protected createSelectors(): ResourceUIEntitiesXsStoreSelectors {
    const stateSelector = this.options.stateSelector;
    const adapterSelectors = this.adapter.getSelectors(stateSelector);
    const selectUIEntity = (resourceId: number): MemoizedSelector<ResourceUIEntitiesXsStoreState, ResourceUIEntitiesXsStoreEntity> =>
      createSelector(stateSelector, state => state.entities[resourceId]);
    const selectFiltered =
      (ids: Array<string | number>): MemoizedSelector<ResourceUIEntitiesXsStoreState, ResourceUIEntitiesXsStoreEntity[]> =>
        createSelector(
          adapterSelectors.selectAll,
          all => all.filter(x => ids.includes(x.resourceId))
        );
    const selectChildren = (parentId: number): MemoizedSelector<ResourceUIEntitiesXsStoreState, ResourceUIEntitiesXsStoreEntity[]> =>
      createSelector(
        adapterSelectors.selectAll,
        resourceEntitiesStore.selectors.selectChildren(parentId),
        (all, children) => all.filter(x => children.find(y => y.resourceId === x.resourceId))
      );

    return {
      ...super.createSelectors(),
      ...adapterSelectors,

      selectUIEntity,
      selectPaging: (resourceId: number) => createSelector(selectUIEntity(resourceId), state => state?.paging),
      selectLoading: (resourceId: number) => createSelector(selectUIEntity(resourceId), state => state?.loading),
      selectLoaded: (resourceId: number) => createSelector(selectUIEntity(resourceId), state => state?.loaded),
      selectAppendLoading: (resourceId: number) => createSelector(selectUIEntity(resourceId), state => state?.appendLoading),

      selectFiltered,
      selectMerged: (ids: Array<string | number>) => createSelector(
        selectFiltered(ids),
        resourceEntitiesStore.selectors.selectFiltered(ids),
        (filteredUIEntities: ResourceUIEntitiesXsStoreEntity[], filteredResources: ResourceSimpleListDto[]) =>
          filteredUIEntities.map(uiEntity => {
            const resource = filteredResources.find(x => x.resourceId === uiEntity.resourceId);
            return { ...uiEntity, ...(resource ?? {}), };
          })
      ),

      selectChildren,
      selectMergedChildren: (parentId: number) => createSelector(
        selectChildren(parentId),
        resourceEntitiesStore.selectors.selectChildren(parentId),
        (childrenUIEntities, childrenEntities) => childrenUIEntities.map(uiEntity => {
          const resource = childrenEntities.find(x => x.resourceId === uiEntity.resourceId);
          return { ...uiEntity, ...(resource ?? {}) };
        })
      ),
      selectInProgress: () => createSelector(
        adapterSelectors.selectAll,
        all => all.filter(item => item.isInProgress)
      )
    };
  }

  protected createAdapter(): EntityAdapter<ResourceUIEntitiesXsStoreEntity> {
    return this.adapter ?? createEntityAdapter({
      selectId: entity => entity.resourceId
    });
  }
}
