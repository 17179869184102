import {Component, Injector} from '@angular/core';
import {CoreSharedModalBaseComponent} from '../../base-components';

@Component({
  selector: 'nexnox-web-confirm-modal',
  templateUrl: './confirm-modal.component.html'
})
export class ConfirmModalComponent extends CoreSharedModalBaseComponent {
  public options = {
    titleKey: '',
    textKey: '',
    icon: 'question',
    confirmKey: 'core-portal.core.general.okay',
    cancelKey: 'core-portal.core.general.cancel'
  };

  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }

  public getConfirmStyle(): string {
    switch (this.options.icon) {
      case 'question':
        return 'primary';
      case 'error':
        return 'danger';
      default:
        return this.options.icon;
    }
  }
}
