import {SettingsMenuConfigCategoryDto} from "@nexnox-web/core-portal/features/settings/menu/src/lib/models";
import {AppPermissions} from "@nexnox-web/core-shared";

export function provideSettingsMenuConfig(): SettingsMenuConfigCategoryDto[] {
  return [

    // Masterdata
    {
      title: 'core-portal.settings.menu.categories.master-data',
      chapter: 'core-portal.core.header.modules.management',
      items: [
        {
          title: 'core-portal.settings.menu.items.address-stereotypes',
          link: ['stereotypes', 'address-stereotypes'],
          permissions: [AppPermissions.DisplayStereoTypes, AppPermissions.DisplayAddresses]
        },
        {
          title: 'core-portal.settings.menu.items.company-stereotypes',
          link: ['stereotypes', 'company-stereotypes'],
          permissions: [AppPermissions.DisplayStereoTypes, AppPermissions.DisplayCompanies]
        },
        {
          title: 'core-portal.settings.menu.items.function-stereotypes',
          link: ['stereotypes', 'function-stereotypes'],
          permissions: [AppPermissions.DisplayStereoTypes, AppPermissions.DisplayFunctions]
        },
        {
          title: 'core-portal.settings.menu.items.contact-stereotypes',
          link: ['stereotypes', 'contact-stereotypes'],
          permissions: [AppPermissions.DisplayStereoTypes, AppPermissions.DisplayContracts]
        },
        {
          title: 'core-portal.settings.menu.items.location-stereotypes',
          link: ['stereotypes', 'location-stereotypes'],
          permissions: [AppPermissions.DisplayStereoTypes, AppPermissions.DisplayLocations]
        },
        {
          title: 'core-portal.settings.menu.items.location-group-stereotypes',
          link: ['stereotypes', 'location-group-stereotypes'],
          permissions: [AppPermissions.DisplayStereoTypes, AppPermissions.DisplayLocationGroups]
        }
      ]
    },
    {
      title: 'core-portal.core.header.breadcrumbs.master-data.contacts',
      chapter: 'core-portal.core.header.modules.management',
      items: [
        {
          title: 'core-portal.settings.menu.items.invitation-codes',
          link: ['invitations'],
          permissions: [AppPermissions.ReadInvitation]
        }
      ]
    },
    {
      title: 'core-portal.settings.menu.categories.notifications',
      chapter: 'core-portal.core.header.modules.management',
      items: [
        {
          title: 'core-portal.settings.menu.items.notifications',
          link: ['notification-rules'],
          permissions: [AppPermissions.DisplayNotificationRules]
        },
        {
          title: 'core-portal.settings.menu.items.text-templates',
          link: ['templates', 'notification-text-templates'],
          permissions: [AppPermissions.DisplayNotificationRules]
        },
      ]
    },
    {
      title: 'core-portal.settings.menu.categories.management',
      chapter: 'core-portal.core.header.modules.management',
      items: [
        {
          title: 'core-portal.settings.menu.items.dashboards',
          link: ['dashboards'],
          permissions: [AppPermissions.UpdateDashboard]
        }
      ]
    },

    // Communication
    {
      title: 'core-portal.settings.menu.categories.tickets',
      chapter: 'core-portal.core.header.modules.communication',
      items: [
        {
          title: 'core-portal.settings.menu.items.mail-nodes',
          link: ['mail-nodes'],
          permissions: [AppPermissions.DisplayMailNodes]
        },
        {
          title: 'core-portal.settings.menu.items.escalation-levels',
          link: ['escalation-levels'],
          permissions: [AppPermissions.UpdateEscalationLevel, AppPermissions.DisplayTickets]
        },
        {
          title: 'core-portal.settings.menu.items.document-templates',
          link: ['templates', 'ticket-document-templates'],
          permissions: [AppPermissions.UpdateDocumentTemplate, AppPermissions.DisplayTickets]
        },
        {
          title: 'core-portal.settings.menu.items.priorities',
          link: ['priority-sets'],
          permissions: [AppPermissions.DisplayPriorities, AppPermissions.DisplayTickets]
        },
        {
          title: 'core-portal.settings.menu.items.processes',
          link: ['processes'],
          permissions: [AppPermissions.DisplayProcesses]
        },
        {
          title: 'core-portal.settings.menu.items.status',
          link: ['states'],
          permissions: [AppPermissions.DisplayStateMachines]
        },
        {
          title: 'core-portal.settings.menu.items.state-machine',
          link: ['state-machines'],
          permissions: [AppPermissions.DisplayStateMachines]
        },
        {
          title: 'core-portal.settings.menu.items.types',
          link: ['stereotypes', 'ticket-stereotypes'],
          permissions: [AppPermissions.DisplayStereoTypes, AppPermissions.DisplayTickets]
        }
      ],
    },
    {
      title: 'core-portal.settings.menu.categories.issues',
      chapter: 'core-portal.core.header.modules.communication',
      items: [
        {
          title: 'core-portal.core.sidebar.menu.settings.issue-templates',
          link: ['issues'],
          permissions: [AppPermissions.DisplayIssueTemplates]
        },
        {
          title: 'core-portal.settings.menu.items.document-templates',
          link: ['templates', 'issue-document-templates'],
          permissions: [AppPermissions.UpdateDocumentTemplate, AppPermissions.DisplayIssueTemplates]
        },
        {
          title: 'core-portal.settings.menu.items.types',
          link: ['stereotypes', 'issue-stereotypes'],
          permissions: [AppPermissions.DisplayStereoTypes, AppPermissions.DisplayIssueTemplates]
        },
        {
          title: 'core-portal.settings.menu.items.text-templates',
          link: ['templates', 'issue-text-templates'],
          permissions: [AppPermissions.DisplayStereoTypes, AppPermissions.DisplayArticles]
        }
      ],
    },
    {
      title: 'core-portal.settings.menu.categories.solution',
      chapter: 'core-portal.core.header.modules.communication',
      items: [
        {
          title: 'core-portal.settings.menu.items.text-templates',
          link: ['templates', 'solution-text-templates'],
          permissions: [AppPermissions.DisplayStereoTypes, AppPermissions.DisplayArticles]
        }
      ]
    },
    {
      title: 'core-portal.settings.menu.categories.tasks',
      chapter: 'core-portal.core.header.modules.communication',
      items: [
        {
          title: 'core-portal.settings.menu.items.cancellation-reasons',
          link: ['cancellation-reasons'],
          permissions: [AppPermissions.DisplayTasks]
        },
      ]
    },
    {
      title: 'core-portal.settings.menu.categories.forms',
      chapter: 'core-portal.core.header.modules.communication',
      items: [
        {
          title: 'core-portal.settings.menu.items.all-forms',
          link: ['forms'],
          permissions: [AppPermissions.DisplayForms]
        },
        {
          title: 'core-portal.settings.menu.items.chat-definitions',
          link: ['chat-definitions'],
          permissions: [AppPermissions.DisplayForms]
        },
        {
          title: 'core-portal.settings.menu.items.document-templates',
          link: ['templates', 'form-document-templates'],
          permissions: [AppPermissions.UpdateTemplate, AppPermissions.DisplayForms]
        },
        {
          title: 'core-portal.settings.menu.items.search-lists',
          link: ['search-lists'],
          permissions: [AppPermissions.DisplaySearchLists]
        },
      ]
    },

    // Inventory
    {
      title: 'core-portal.settings.menu.categories.missions',
      chapter: 'core-portal.core.header.modules.inventory',
      items: [
        {
          title: 'core-portal.settings.menu.items.document-templates',
          link: ['templates', 'mission-document-templates'],
          permissions: [AppPermissions.UpdateDocumentTemplate, AppPermissions.DisplayMissions]
        },
        {
          title: 'core-portal.settings.menu.items.text-templates',
          link: ['templates', 'mission-text-templates'],
          permissions: [AppPermissions.UpdateTemplate, AppPermissions.DisplayMissions]
        },
        {
          title: 'core-portal.settings.menu.items.types',
          link: ['stereotypes', 'mission-stereotypes'],
          permissions: [AppPermissions.DisplayStereoTypes, AppPermissions.DisplayMissions]
        },
        {
          title: 'core-portal.core.header.modules.settings',
          link: ['admin', 'app', 'mission-settings'],
          permissions: [AppPermissions.DisplaySettings, AppPermissions.DisplayMissions]
        }
      ]
    },
    {
      title: 'core-portal.settings.menu.categories.resources',
      chapter: 'core-portal.core.header.modules.inventory',
      items: [
        {
          title: 'core-portal.settings.menu.items.definitions',
          link: ['connected', 'definitions'],
          permissions: [AppPermissions.DisplayConnectedDefinition, AppPermissions.DisplayResources]
        },
        {
          title: 'core-portal.settings.menu.items.error-codes',
          link: ['connected', 'error-codes'],
          permissions: [AppPermissions.UpdateErrorCode, AppPermissions.DisplayResources]
        },
        {
          title: 'core-portal.settings.menu.items.consumption-codes',
          link: ['connected', 'consumption-codes'],
          permissions: [AppPermissions.UpdateConsumptionCode, AppPermissions.DisplayResources]
        },
        {
          title: 'core-portal.settings.menu.items.hint-codes',
          link: ['connected', 'hint-codes'],
          permissions: [AppPermissions.UpdateHintCode, AppPermissions.DisplayResources]
        },
        {
          title: 'core-portal.settings.menu.items.inventory-number-patterns',
          link: ['inventory-number-patterns'],
          permissions: [AppPermissions.DisplayInventoryNumberPatterns, AppPermissions.DisplayResources]
        },
        {
          title: 'core-portal.settings.menu.items.manufacturers',
          link: ['manufacturers'],
          permissions: [AppPermissions.DisplayResourceManufacturers, AppPermissions.DisplayResources]
        },
        {
          title: 'core-portal.settings.menu.items.types',
          link: ['stereotypes', 'resource-stereotypes'],
          permissions: [AppPermissions.DisplayStereoTypes, AppPermissions.DisplayResources]
        }
      ]
    },
    {
      title: 'core-portal.settings.menu.categories.solution',
      chapter: 'core-portal.core.header.modules.inventory',
      items: [
        {
          title: 'core-portal.settings.menu.items.text-templates',
          link: ['templates', 'solution-text-templates'],
          permissions: [AppPermissions.DisplayStereoTypes, AppPermissions.DisplayArticles]
        }
      ]
    },
    {
      title: 'core-portal.settings.menu.categories.more',
      chapter: 'core-portal.core.header.modules.inventory',
      items: [
        {
          title: 'core-portal.settings.menu.items.article-stereotypes',
          link: ['stereotypes', 'article-stereotypes'],
          permissions: [AppPermissions.DisplayStereoTypes, AppPermissions.DisplayArticles]

        },
        {
          title: 'core-portal.settings.menu.items.mission-report-stereotypes',
          link: ['stereotypes', 'mission-report-stereotypes'],
          permissions: [AppPermissions.DisplayStereoTypes, AppPermissions.DisplayMissions]
        },
        {
          title: 'core-portal.settings.menu.items.contract-stereotypes',
          link: ['stereotypes', 'contract-stereotypes'],
          permissions: [AppPermissions.DisplayStereoTypes, AppPermissions.DisplayContracts]
        }
      ]
    },

    // Settings
    {
      title: 'core-portal.core.sidebar.menu.settings.system',
      chapter: 'core-portal.core.header.modules.settings',
      items: [
        {
          title: 'core-portal.core.sidebar.menu.settings.general',
          link: ['admin', 'system', 'general'],
          permissions: [AppPermissions.DisplaySettings]
        },
        {
          title: 'core-portal.core.sidebar.menu.settings.appearance',
          link: ['admin', 'system', 'appearance'],
          permissions: [AppPermissions.DisplaySettings]
        },
        {
          title: 'core-portal.core.sidebar.menu.settings.labels',
          link: ['labels'],
          permissions: [AppPermissions.DisplayLabels]
        }
      ]
    },
    {
      title: 'core-portal.core.sidebar.menu.settings.app',
      chapter: 'core-portal.core.header.modules.settings',
      items: [
        {
          title: 'core-portal.core.sidebar.menu.settings.general',
          link: ['admin', 'app', 'general'],
          permissions: [AppPermissions.DisplaySettings]
        },
        {
          title: 'orga-portal.categories.subtitles.category-list',
          link: ['categories'],
          permissions: [AppPermissions.DisplayCategories]
        },
        {
          title: 'orga-portal.app-overviews.detail',
          link: ['app-overviews'],
          permissions: [AppPermissions.DisplayAppOverviews]
        },
        {
          title: 'core-portal.settings.subtitles.timeline-settings-item',
          link: ['app','timeline-settings'],
          permissions: [AppPermissions.UpdateTimelineSetting]
        }
      ]
    },
    {
      title: 'core-portal.settings.menu.categories.admin',
      chapter: 'core-portal.core.header.modules.settings',
      items: [
        {
          title: 'core-portal.settings.subtitles.mail-log-list',
          link: ['mail-logs'],
          permissions: [AppPermissions.ReadMailLog]
        },
        {
          title: 'core-portal.core.sidebar.menu.settings.roles',
          link: ['roles'],
          permissions: [AppPermissions.DisplayRole]
        },
        {
          title: 'core-portal.core.sidebar.menu.settings.import-manager',
          link: ['import'],
          permissions: [AppPermissions.ImportEntity]
        },
        {
          title: 'core-portal.core.sidebar.menu.settings.mass-import',
          link: ['mass-import'],
          permissions: [AppPermissions.UseMassImport]
        }
      ]
    }
  ]
}
