import {InjectionToken, NgModule} from '@angular/core';
import {ActionReducerMap, StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {templatesEffects, templatesReducers} from './store';

export const REDUCER_TOKEN = new InjectionToken<ActionReducerMap<any>>('@nexnox-web/tech-portal/templates: Store Reducers');

@NgModule({
  imports: [
    StoreModule.forFeature('tech-portal-feature-templates', REDUCER_TOKEN),
    EffectsModule.forFeature(templatesEffects)
  ],
  providers: [
    {
      provide: REDUCER_TOKEN,
      useValue: templatesReducers
    }
  ]
})
export class TemplatesStoreModule {
}
