import {AreaAssignmentDto, CoreSharedApiBaseService, CoreSharedHttpOrgaRequestService} from '@nexnox-web/core-shared';
import {Injectable, Injector} from '@angular/core';
import {Observable} from "rxjs";
import {map} from "rxjs/operators";

@Injectable()
export class LocationContactService extends CoreSharedApiBaseService {
  constructor(
    protected injector: Injector,
    protected httpOrga: CoreSharedHttpOrgaRequestService
  ) {
    super(injector, null, 'contact', ['location']);
  }

  public addAreaAssignment(contactId: number | string, model: AreaAssignmentDto): Observable<AreaAssignmentDto> {
    return this.httpOrga.post<AreaAssignmentDto>(`contact/${ contactId }/area`, model).pipe(
      map(response => response.body)
    );
  }
}
