import {ModuleWithProviders, NgModule} from '@angular/core';
import {EscalationLevelsDeclarationsModule} from './escalation-levels-declarations.module';
import {EscalationLevelsStoreModule} from './escalation-levels-store.module';
import {EscalationLevelsRoutingModule} from './escalation-levels-routing.module';

@NgModule({
  imports: [
    EscalationLevelsDeclarationsModule,
    EscalationLevelsRoutingModule,
    EscalationLevelsStoreModule
  ]
})
export class TechPortalFeatureTicketSettingsEscalationLevelsModule {
  public static forFeature(): ModuleWithProviders<EscalationLevelsDeclarationsModule> {
    return { ngModule: EscalationLevelsDeclarationsModule };
  }
}
