import {Injectable} from '@angular/core';
import {
  CoreSharedBreadcrumb,
  CoreSharedBreadcrumbsResolver,
  I18nBreadcrumbResolver,
  I18nEntityBreadcrumbResolver
} from '@nexnox-web/core-shared';
import {ActivatedRouteSnapshot} from '@angular/router';
import {select, Store} from '@ngrx/store';
import {catchError, Observable} from 'rxjs';
import {companyAddressDetailStore} from "./../../store";
import {map, withLatestFrom} from "rxjs/operators";
import {TranslateService} from "@ngx-translate/core";


@Injectable({
  providedIn: 'root'
})
export class CompanyAddressBreadcrumbsResolver extends CoreSharedBreadcrumbsResolver {
  constructor(
    private store: Store<any>,
    private translate: TranslateService,
    private i18nEntityBreadcrumbResolver: I18nEntityBreadcrumbResolver,
    private i18nBreadcrumbResolver: I18nBreadcrumbResolver
  ) {
    super();
  }

  public resolve(route: ActivatedRouteSnapshot): Observable<CoreSharedBreadcrumb[]> {
    const baseRoute = this.getPathUntil(route, 'companies');
    const companyId = route.paramMap.get('companyId');
    const addressId = route.paramMap.get('addressId');


    return this.store.pipe(
      select(companyAddressDetailStore.selectors.selectEntity),
      withLatestFrom(
        this.translate.stream('core-portal.core.header.breadcrumbs.master-data.company'),
        this.translate.stream('core-portal.core.header.breadcrumbs.master-data.addresses'),
        this.translate.stream(!addressId ? 'core-portal.master-data.actions.create-address' : 'core-portal.core.header.breadcrumbs.master-data.address')
      ),
      map(([address, companyText, addressesText, addressText]) => [
        {
          path: `${ baseRoute }/companies/${ companyId }`,
          text: this.i18nEntityBreadcrumbResolver.getLocalBreadcrumb(`${ baseRoute }/companies/${ companyId }`) ?? companyText
        },
        {
          path: `${ baseRoute }/companies/${ companyId }/addresses`,
          text: addressesText
        },
        {
          path: `${ baseRoute }/companies/${ companyId }/addresses/${ addressId ? addressId : 'create' }`,
          text: address['street'] ?? addressText
        }
      ]),
      catchError(error => this.i18nBreadcrumbResolver.resolve(route))
    );
  }
}
