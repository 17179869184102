import {NgModule} from '@angular/core';
import {auditServices} from './store';
import {CorePortalFeatureAuditListComponent} from './containers';
import {CorePortalEntityOverviewModule, CorePortalModule} from '@nexnox-web/core-portal';
import {CorePortalFeatureAuditDetailModalComponent} from './modals';

@NgModule({
  declarations: [
    CorePortalFeatureAuditListComponent,

    CorePortalFeatureAuditDetailModalComponent
  ],
  imports: [
    CorePortalModule,
    CorePortalEntityOverviewModule
  ],
  exports: [
    CorePortalFeatureAuditListComponent,

    CorePortalFeatureAuditDetailModalComponent
  ],
  providers: [
    ...auditServices
  ]
})
export class CorePortalFeatureAuditModule {
}
