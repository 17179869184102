import {PagedEntitiesXsStore, PagedEntitiesXsStoreState} from '@nexnox-web/core-store';
import {AppEntityType, ResourceDto} from '@nexnox-web/core-shared';
import {Action, createSelector} from '@ngrx/store';
import {selectLocationsState} from '../../locations.selectors';
import {Injectable, Injector} from '@angular/core';
import {CorePortalFeatureLocationResourceService} from '../../services';

export interface LocationResourceListStoreState extends PagedEntitiesXsStoreState<ResourceDto> {
}

/* istanbul ignore next */
export const locationResourceListStore = new PagedEntitiesXsStore<ResourceDto>({
  actionLabel: 'Core Portal - Master Data - Location - Resource List',
  stateSelector: createSelector(selectLocationsState, state => state.locationResourceList),
  serviceType: CorePortalFeatureLocationResourceService,
  entityType: AppEntityType.Resource,
  selectId: resource => resource.entity?.resourceId ?? resource.model?.resourceId
});

export function locationResourceListStoreReducer(state: LocationResourceListStoreState, action: Action): any {
  return locationResourceListStore.reducer(state, action);
}

@Injectable()
export class LocationResourceListStoreEffects extends locationResourceListStore.effects {
  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }
}
