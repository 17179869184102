<ng-template #entityEditTemplate>
  <nexnox-web-master-data-contacts-contact-area-edit #modelComponent
                                                     (modelChange)="onModelChange($event)"
                                                     [creating]="isCreate$ | async"
                                                     [loading]="loading$ | async"
                                                     [model]="model$ | async"
                                                     [readonly]="readonly$ | async"
                                                     [stereotyped]="false"
                                                     [title]="title | translate">
  </nexnox-web-master-data-contacts-contact-area-edit>
</ng-template>

<nexnox-web-entity-detail [entityDetailBaseComponent]="this"
                          [entityEditTemplate]="entityEditTemplate"
                          [useTabs]="false">
</nexnox-web-entity-detail>
