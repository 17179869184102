<div [style.height]="height"
     [style.width]="width"
     id="map">
</div>
<div *ngIf="height === '0'" class="mb-2">
  {{ 'core-portal.core.general.no-point-defined' | translate }}
</div>
<ng-container *ngIf="isManualPlacesSearch">
  <div [style.width]="width"
       class="d-flex">
    <input [disabled]="form.disabled"
           [style.width]="'auto'"
           class="form-control flex-grow-1"
           id="places"
           type="text"/>
    <button (click)="takeOverAddress()"
            [disabled]="form.disabled || addressKeys.length === 0"
            class="p-button-secondary p-button-outlined">
      {{ 'core-portal.core.general.takeover-and-search' | translate }}
    </button>
  </div>
</ng-container>

<ng-container *ngIf="isNoPointSetButValidAddress && form.enabled">
  <div [style.width]="width"
       class="d-flex">
    <button (click)="takeOverAddress()"
            [disabled]="form.disabled || addressKeys.length === 0"
            class="p-button-secondary p-button-outlined">
      {{ 'core-portal.core.general.takeover-and-search' | translate }}
    </button>
  </div>
</ng-container>
