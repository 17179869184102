import {TechPortalFeatureDocumentTemplateService, TechPortalFeatureTextTemplateService} from './services';
import {combineReducers} from '@ngrx/store';
import {
  documentTemplatesEffects,
  documentTemplatesReducers,
  textTemplatesEffects,
  textTemplatesReducers
} from './stores';

export * from './services';
export * from './stores';

export * from './templates.state';
export * from './templates.selectors';

export const templatesReducers = {
  textTemplates: combineReducers(textTemplatesReducers),
  documentTemplates: combineReducers(documentTemplatesReducers)
};

export const templatesEffects = [
  ...textTemplatesEffects,
  ...documentTemplatesEffects
];

export const templatesServices = [
  TechPortalFeatureTextTemplateService,
  TechPortalFeatureDocumentTemplateService
];
