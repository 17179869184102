import {NgModule} from '@angular/core';
import {ResourceDetailSidebarComponent} from "./sidebars";
import {CoreSharedModule} from "@nexnox-web/core-shared";
import {CorePortalEntityModule, CorePortalRouterOverridesModule} from "@nexnox-web/core-portal";

@NgModule({
  declarations: [
    ResourceDetailSidebarComponent
  ],
  imports: [
    CoreSharedModule,
    CorePortalEntityModule,
    CorePortalRouterOverridesModule,
  ],
  exports: [
    ResourceDetailSidebarComponent
  ]
})
export class ResourceDetailSidebarModule {
}
