import {PagedEntitiesXsStore, PagedEntitiesXsStoreState} from '@nexnox-web/core-store';
import {AppEntityType, StereotypeDto} from '@nexnox-web/core-shared';
import {createSelector} from '@ngrx/store';
import {selectStereotypesState} from '../../stereotypes.selector';
import {CorePortalStereotypeService} from '@nexnox-web/core-portal';

export interface StereotypeListStoreState extends PagedEntitiesXsStoreState<StereotypeDto> {
}

/* istanbul ignore next */
export const stereotypeListStore = new PagedEntitiesXsStore<StereotypeDto>({
  actionLabel: 'Core Portal - Settings - Stereotypes - Stereotype List',
  stateSelector: createSelector(selectStereotypesState, state => state.stereotypeList),
  serviceType: CorePortalStereotypeService,
  entityType: AppEntityType.Stereotype,
  stereotyped: false,
  selectId: stereotype => stereotype.entity?.stereotypeId ?? stereotype.model?.stereotypeId
});
