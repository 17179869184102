import {BehaviorSubject, Observable} from 'rxjs';
import {
  ObservableStore,
  ObservableStoreSettings,
  StateHistory,
  StateWithPropertyChanges
} from '@codewithdan/observable-store';
import {cloneDeep} from 'lodash';

/**
 * Executes a function on `state` and returns a version of T
 * @param state - the original state model
 */
export type stateFunc<T> = (state: T) => Partial<T>;

// ToDo: Test in the future
/* istanbul ignore next */
/**
 * Core functionality for ObservableStore
 * providing getState(), setState() and additional functionality
 *
 * @deprecated
 */
export class CoreStoreObservableStore<T> {
  /**
   * Subscribe to store changes in the particlar slice of state updated by a Service.
   * If the store contains 'n' slices of state each being managed by one of 'n' services, then changes in any
   * of the other slices of state will not generate values in the `stateChanged` stream.
   * Returns an RxJS Observable containing the current store state
   * (or a specific slice of state if a `stateSliceSelector` has been specified).
   */
  public stateChanged: Observable<T>;
  /**
   * Subscribe to store changes in the particlar slice of state updated by a Service and also include the properties that changed as well.
   * Upon subscribing to `stateWithPropertyChanges` you will get back an object containing state
   * (which has the current slice of store state)
   * and `stateChanges` (which has the individual properties/data that were changed in the store).
   */
  public stateWithPropertyChanges: Observable<StateWithPropertyChanges<T>>;
  public actions$: Observable<{ action: string, state: T }>;
  private settings: ObservableStoreSettings;
  private stateDispatcher$ = new BehaviorSubject<T>(null);
  private stateWithChangesDispatcher$ = new BehaviorSubject<StateWithPropertyChanges<T>>(null);
  private actionsDispatcher$ = new BehaviorSubject<{ action: string; state: T }>(null);
  private storeState: Readonly<any> = null;
  private settingsDefaults: ObservableStoreSettings = {
    trackStateHistory: false,
    logStateChanges: false,
    includeStateChangesOnSubscribe: false,
    stateSliceSelector: null
  };

  constructor(settings: ObservableStoreSettings) {
    this.settings = { ...this.settingsDefaults, ...settings, ...ObservableStore.globalSettings };
    this.stateChanged = this.stateDispatcher$.asObservable();
    this.stateWithPropertyChanges = this.stateWithChangesDispatcher$.asObservable();
    this.actions$ = this.actionsDispatcher$.asObservable();
  }

  private _stateHistory: any[] = [];

  /**
   * Retrieve state history. Assumes trackStateHistory setting was set on the store.
   */
  public get stateHistory(): StateHistory<T>[] {
    return this._stateHistory;
  }

  /**
   * Used to initialize the store's starting state. An error will be thrown if this is called and store state already exists.
   * No notifications are sent out when the store state is initialized by calling initializeStoreState().
   */
  public static initializeState(state: any): void {
    this.initializeState(state);
  }

  /**
   * Retrieve store's state. If using TypeScript (optional) then the state type defined when the store
   * was created will be returned rather than `any`.
   */
  protected getState(deepCloneReturnedState: boolean = true): T {
    return this._getStateOrSlice(deepCloneReturnedState);
  }

  /**
   * Retrieve a specific property from the store's state which can be more efficient than getState()
   * since only the defined property value will be returned (and cloned) rather than the entire
   * store value. If using TypeScript (optional) then the generic property type used with the
   * function call will be the return type.
   */
  protected getStateProperty<TProp>(propertyName: string, deepCloneReturnedState: boolean = true): TProp {
    return this.getStoreState(propertyName, deepCloneReturnedState);
  }

  /**
   * Set store state. Pass the state to be updated as well as the action that is occuring.
   * The state value can be a function [(see example)](https://github.com/danwahlin/observable-store#store-api).
   * The latest store state is returned.
   * The dispatchState parameter can be set to false if you do not want to send state change notifications to subscribers.
   */
  protected setState(state: Partial<T> | stateFunc<T>,
                     action?: string,
                     dispatchState: boolean = true,
                     deepCloneState: boolean = true): T {

    // Needed for tracking below (don't move or delete)
    const previousState = this.getState();

    switch (typeof state) {
      case 'function': {
        const newState = state(this.getState());
        this._updateState(newState, deepCloneState);
        break;
      }
      case 'object': {
        this._updateState(state, deepCloneState);
        break;
      }
      default:
        throw Error('Pass an object or a function for the state parameter when calling setState().');
    }

    if (this.settings.trackStateHistory) {
      this._stateHistory.push({
        action,
        beginState: previousState,
        endState: this.getState()
      });
    }

    if (dispatchState) {
      this.dispatchState(state as any);
    }

    if (action) {
      this.actionsDispatcher$.next({ action, state: state as any });
    }

    if (this.settings.logStateChanges) {
      const caller = (this.constructor) ? '\r\nCaller: ' + this.constructor.name : '';
      console.log('%cSTATE CHANGED', 'font-weight: bold', '\r\nAction: ', action, caller, '\r\nState: ', state);
    }

    return this.getState(deepCloneState);
  }

  /**
   * Add a custom state value and action into the state history. Assumes `trackStateHistory` setting was set
   * on store or using the global settings.
   */
  protected logStateAction(state: any, action: string): void {
    if (this.settings.trackStateHistory) {
      this._stateHistory.push({
        action,
        beginState: this.getState(),
        endState: cloneDeep(state)
      });
    }
  }

  /**
   *  Reset the store's state history to an empty array.
   */
  protected resetStateHistory(): void {
    this._stateHistory = [];
  }

  /**
   * Dispatch the store's state without modifying the store state. Service state can be dispatched as well as the global store state.
   * If `dispatchGlobalState` is false then global state will not be dispatched to subscribers (defaults to `true`).
   */
  protected dispatchState(stateChanges: Partial<T>): void {
    // Get store state or slice of state
    const clonedStateOrSlice = this._getStateOrSlice(true);

    //  Get full store state
    const clonedGlobalState = this.getStoreState();

    // includeStateChangesOnSubscribe is deprecated
    if (this.settings.includeStateChangesOnSubscribe) {
      console.warn('includeStateChangesOnSubscribe is deprecated. ' +
        'Subscribe to stateChangedWithChanges or globalStateChangedWithChanges instead.');
      this.stateDispatcher$.next({ state: clonedStateOrSlice, stateChanges } as any);
    } else {
      this.stateDispatcher$.next(clonedStateOrSlice);
      this.stateWithChangesDispatcher$.next({ state: clonedStateOrSlice, stateChanges });
    }
  }

  private _updateState(state: Partial<T>, deepCloneState: boolean): void {
    this.setStoreState(state, deepCloneState);
  }

  private _getStateOrSlice(deepCloneReturnedState: boolean): Readonly<Partial<T>> {
    const storeState = this.getStoreState(null, deepCloneReturnedState);
    if (this.settings.stateSliceSelector) {
      return this.settings.stateSliceSelector(storeState);
    }
    return storeState;
  }

  private initializeState(state: any): void {
    if (this.storeState) {
      throw Error('The store state has already been initialized. initializeStoreState() can ' +
        'only be called once BEFORE any store state has been set.');
    }

    return this.setStoreState(state);
  }

  private getStoreState(propertyName: string = null, deepCloneReturnedState: boolean = true): any {
    let state = null;

    if (this.storeState) {
      if (propertyName) {
        if (this.storeState.hasOwnProperty(propertyName)) {
          state = this.storeState[propertyName];
        }
      } else {
        state = this.storeState;
      }

      if (state && deepCloneReturnedState) {
        state = cloneDeep(state);
      }
    }

    return state;
  }

  private setStoreState(state: any, deepCloneState: boolean = true): void {
    const currentStoreState = this.getStoreState(null, deepCloneState);

    if (deepCloneState) {
      this.storeState = { ...currentStoreState, ...cloneDeep(state) };
    } else {
      this.storeState = { ...currentStoreState, ...state };
    }
  }

  private clearStoreState(): void {
    this.storeState = null;
  }
}
