import {ChangeDetectionStrategy, Component, Injector} from '@angular/core';
import {
  ActionButton,
  CorePortalEntityOverviewBaseComponent,
  DatatableActionButton,
  DeleteEntityModel
} from '@nexnox-web/core-portal';
import {AppEntityType, AppPermissions, CategoryDto, Mappers} from '@nexnox-web/core-shared';
import {categoryListStore} from '../../store';

@Component({
  selector: 'nexnox-web-categories-category-list',
  templateUrl: './category-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CategoryListComponent extends CorePortalEntityOverviewBaseComponent<CategoryDto> {
  public title = 'orga-portal.categories.subtitles.category-list';
  public createTitle = 'orga-portal.categories.actions.create-category';
  public idProperty = 'categoryId';
  public displayProperty = 'name';
  public datatableConfigName = 'CategoryDefault';
  public componentId = 'CategoryListComponent';

  constructor(
    protected injector: Injector
  ) {
    super(injector, categoryListStore, Mappers.CategoryListDto.serializedName, AppEntityType.None);
  }

  public getRowActionButtons(): DatatableActionButton[] {
    return this.getDefaultRowActionButtons(
      'orga-portal.categories.actions.edit-category',
      (row: CategoryDto) => `/categories/${ row.categoryId }`,
      [AppPermissions.UpdateCategory],
      {
        module: 'settings'
      }
    );
  }

  public getDeleteEntityModel(): DeleteEntityModel {
    return {
      titleKey: 'orga-portal.categories.actions.delete-category',
      descriptionKey: 'orga-portal.categories.descriptions.delete-category',
      confirmKey: 'orga-portal.categories.actions.delete-category',
      deletePermission: AppPermissions.DeleteCategory
    };
  }

  protected async getActionButtons(): Promise<ActionButton[]> {
    return this.getDefaultActionButtons('orga-portal.categories.actions.create-category', AppPermissions.CreateCategory);
  }
}
