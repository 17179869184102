import {CoreSharedImageService} from '@nexnox-web/core-shared';
import {HttpEventType} from '@angular/common/http';

/* istanbul ignore next */
export class ImageUploadAdapter {
  constructor(
    private loader: any,
    private imageService: CoreSharedImageService,
    private tenantId: number | string
  ) {
  }

  public upload(): Promise<any> {
    return (this.loader.file as Promise<File>)
      .then((file: File) => new Promise<any>((resolve, reject) => this.sendRequest(file, resolve, reject)))
      .catch(error => Promise.reject(error));
  }

  public abort(): void {
    // ToDo: Abort
  }

  private sendRequest(file: File, resolve: (value?: any) => any, reject: (reason?: any) => any): void {
    this.imageService.uploadImage(file, this.tenantId).subscribe(fileUploadEvent => {
      if (fileUploadEvent.type === HttpEventType.Response) {
        resolve({ default: fileUploadEvent.body.uri });
      }

      if (fileUploadEvent.type === HttpEventType.UploadProgress) {
        this.loader.uploadTotal = fileUploadEvent.total;
        this.loader.uploaded = fileUploadEvent.loaded;
      }
    }, error => reject(error));
  }
}


