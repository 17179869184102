import {ChangeDetectionStrategy, Component, Injector} from '@angular/core';
import {ActionButton, CorePortalEntityDetailBaseComponent} from '@nexnox-web/core-portal';
import {AppPermissions, AppTenantDto} from '@nexnox-web/core-shared';
import {tenantDetailStore} from '../../store';

@Component({
  selector: 'nexnox-web-service-tenants-tenant-detail',
  templateUrl: './tenant-detail.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TenantDetailComponent extends CorePortalEntityDetailBaseComponent<AppTenantDto> {
  public title = 'core-portal.service.tenants.detail';

  protected idParam = 'tenantId';
  protected displayKey = 'domain';
  protected canDelete = false;

  constructor(
    protected injector: Injector
  ) {
    super(injector, tenantDetailStore);
  }

  /* istanbul ignore next */
  protected async getActionButtons(): Promise<ActionButton[]> {
    return this.getDefaultActionButtons(
      'core-portal.service.tenants.actions.edit',
      'core-portal.service.tenants.actions.save',
      'core-portal.service.tenants.actions.delete',
      'core-portal.service.tenants.descriptions.delete',
      AppPermissions.UseExpiredSubscription,
      AppPermissions.UseExpiredSubscription,
      ['/tenants']
    );
  }
}
