<div *ngIf="(actions$ | async)?.length && (isOneShowing$ | async)"
     class="action-container w-100 py-0">
  <div class="d-flex container-fluid justify-space-between pt-0 pb-2">
    <div class="d-flex overflow-auto whitespace-nowrap">
      <ng-container *ngFor="let action of leftActions$ | async; trackBy: trackActionsBy">
        <ng-container [ngTemplateOutletContext]="{ action: action }" [ngTemplateOutlet]="itemTemplate"></ng-container>
      </ng-container>
    </div>

    <div class="d-flex ml-2">
      <ng-container *ngFor="let action of rightActions$ | async; trackBy: trackActionsBy; let isLast = isLast">
        <ng-container [ngTemplateOutletContext]="{ action: action, isLast: isLast }"
                      [ngTemplateOutlet]="itemTemplate"></ng-container>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #itemTemplate let-action="action" let-isLast="isLast">
  <div *ngIf="(isDropDown(action.type) | async) === false; else dropdown"
       [class.d-none]="!action.shouldShowValue"
       class="btn-group mr-2">
    <button (click)="action.callback($event, action)"
            [attr.title]="action.tooltip ? (action.tooltip | translate) : undefined"
            [disabled]="action.isDisabledValue"
            class="btn {{ action.class }}">
      <fa-icon *ngIf="action.icon"
               [icon]="action.icon"
               [nexnoxWebLoadingIcon]="action.isLoadingValue"
               class="mr-2">
      </fa-icon>

      <ng-container [ngTemplateOutletContext]="{ action: action }"
                    [ngTemplateOutlet]="buttonTemplate">
      </ng-container>
    </button>

    <button (click)="action.splitCallback($event, action)"
            *ngIf="action.splitCallback"
            [disabled]="action.isSplitDisabledValue"
            class="btn {{ action.class }} dropdown-toggle dropdown-toggle-split">
    </button>
  </div>

  <ng-template #dropdown>
    <div [class.d-none]="!action.shouldShowValue"
         [isDisabled]="action.isDisabledValue && (action.isSplitDisabled ? action.isSplitDisabledValue : true)"
         [placement]="action.alignRight ? 'bottom right' : 'bottom left'"
         class="btn-group mr-2"
         container="body"
         dropdown>
      <ng-container *ngIf="!action.isSplitDisabled; else splitTemplate">
        <button [attr.title]="action.tooltip ? (action.tooltip | translate) : undefined"
                [class.dropdown-toggle]="!action.noArrow"
                [class.is-disabled]="action.isDisabledValue"
                [disabled]="action.isDisabledValue"
                class="btn {{ action.class }}"
                dropdownToggle
                type="button">
          <fa-icon *ngIf="action.icon"
                   [class.mr-2]="action.label"
                   [fixedWidth]="true"
                   [icon]="action.icon"
                   [nexnoxWebLoadingIcon]="action.isLoadingValue">
          </fa-icon>

          <ng-container [ngTemplateOutletContext]="{ action: action }"
                        [ngTemplateOutlet]="buttonTemplate">
          </ng-container>
          <span *ngIf="!action.noArrow" class="caret"></span>
        </button>
      </ng-container>

      <ng-template #splitTemplate>
        <button (click)="action.callback($event, action)"
                [attr.title]="action.tooltip ? (action.tooltip | translate) : undefined"
                [disabled]="action.isDisabledValue"
                class="btn {{ action.class }}"
                type="button">
          <fa-icon *ngIf="getAsyncIcon(action.icon) as icon"
                   [icon]="icon | async"
                   [nexnoxWebLoadingIcon]="action.isLoadingValue"
                   class="mr-2">
          </fa-icon>

          <ng-container [ngTemplateOutletContext]="{ action: action }"
                        [ngTemplateOutlet]="buttonTemplate">
          </ng-container>
        </button>

        <button [disabled]="action.isSplitDisabledValue"
                class="btn {{ action.class }} dropdown-toggle dropdown-toggle-split"
                dropdownToggle>
        </button>
      </ng-template>

      <ul *dropdownMenu
          [class.dropdown-menu-right]="action.alignRight"
          class="dropdown-menu"
          role="menu"
          style="top: 0 !important;">
        <li *ngFor="let subAction of action.buttonsValue"
            [class.disabled]="subAction.isDisabledValue"
            role="menuitem">
          <a (click)="subAction.callback ? subAction.callback($event, subAction) : ''"
             [class.d-none]="!subAction.shouldShowValue"
             [class.disabled]="subAction.isDisabledValue"
             class="dropdown-item">
            <fa-icon *ngIf="subAction.icon"
                     [icon]="subAction.icon"
                     [nexnoxWebLoadingIcon]="subAction.isLoadingValue"
                     class="mr-2">
            </fa-icon>

            <ng-container [ngTemplateOutletContext]="{ action: subAction }"
                          [ngTemplateOutlet]="buttonTemplate">
            </ng-container>
          </a>
        </li>
      </ul>
    </div>
  </ng-template>
</ng-template>

<ng-template #buttonTemplate let-action="action">
  <ng-container *ngIf="!action.noTranslate; else noTranslateTemplate">
    {{ (action.label$ ? (action.label$ | async) : action.label) | translate }}
  </ng-container>

  <ng-template #noTranslateTemplate>
    {{ action.label$ ? (action.label$ | async) : action.label }}
  </ng-template>
</ng-template>
