import {AppEntityType, OpenEditorRequestOnContactListDto} from '@nexnox-web/core-shared';
import {Action, createSelector} from '@ngrx/store';
import {Injectable, Injector} from '@angular/core';
import {selectContactsState} from '../../contacts.selectors';
import {PagedEntitiesXsStore, PagedEntitiesXsStoreState} from '@nexnox-web/core-store';
import {ContactOpenRequestsService} from "../../services";

export interface ContactOpenRequestListStoreState extends PagedEntitiesXsStoreState<OpenEditorRequestOnContactListDto> {
}

/* istanbul ignore next */
export const contactOpenRequestListStore = new PagedEntitiesXsStore<OpenEditorRequestOnContactListDto>({
  actionLabel: 'Core Portal - Master Data - Contact - Open Editor Request List',
  stateSelector: createSelector(selectContactsState, state => state.contactOpenRequestList),
  serviceType: ContactOpenRequestsService,
  entityType: AppEntityType.None,
  selectId: (mission) => mission?.entity?.missionId ?? mission?.model?.missionId,
  stereotyped: false
});

export function ContactOpenRequestListStoreReducer(state: ContactOpenRequestListStoreState, action: Action): any {
  return contactOpenRequestListStore.reducer(state, action);
}

@Injectable()
export class ContactOpenRequestListStoreEffects extends contactOpenRequestListStore.effects {
  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }
}
