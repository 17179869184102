import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {faSpinner} from '@fortawesome/free-solid-svg-icons/faSpinner';
import {ApiNotificationService, FilterOperators, FilterTypes, Paging, ResourceDto} from '@nexnox-web/core-shared';
import {TreeNode} from 'primeng/api';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {catchError, map, take} from 'rxjs/operators';
import {CorePortalFeatureResourceService} from '../../services';
import {OverlayPanel} from 'primeng/overlaypanel';

@Component({
  selector: 'nexnox-web-resources-resource-select-children',
  templateUrl: './resource-select-children.component.html',
  styleUrls: ['./resource-select-children.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CorePortalFeatureResourceSelectChildrenComponent implements OnInit {
  @Input() public readonly: boolean;
  @Output() public selectResource: EventEmitter<ResourceDto> = new EventEmitter<ResourceDto>();
  @ViewChild('resourceChildrenPanel', { static: true }) public resourceChildrenPanel: OverlayPanel;
  public resource$: Observable<ResourceDto>;
  public nodes$: Observable<TreeNode[]>;
  public faSpinner = faSpinner;
  private resourceSubject: BehaviorSubject<ResourceDto> = new BehaviorSubject<ResourceDto>(null);
  private nodesSubject: BehaviorSubject<TreeNode[]> = new BehaviorSubject<TreeNode[]>([]);
  private pagingSubject: BehaviorSubject<Paging> = new BehaviorSubject<Paging>(null);

  constructor(
    private changeDetector: ChangeDetectorRef,
    private resourceService: CorePortalFeatureResourceService,
    private apiNotificationService: ApiNotificationService
  ) {
  }

  @Input()
  public set resource(resource: ResourceDto) {
    if (resource && resource?.resourceId !== this.resourceSubject.getValue()?.resourceId) this.onResourceChanged(resource);
    this.resourceSubject.next(resource);
  }

  public ngOnInit(): void {
    this.resource$ = this.resourceSubject.asObservable();
    this.nodes$ = this.nodesSubject.asObservable();
  }

  public onResourceChanged(resource: ResourceDto): void {
    this.getChildren(resource).pipe(
      map(({ children, paging }) => [
        ...children.map((x: ResourceDto) => this.mapResourceToNode(x, resource.resourceId)),
        ...(paging.pageNumber < paging.totalPages ? [{
          key: 'root-more',
          type: 'more',
          data: { resource, paging }
        }] : [])
      ])
    ).toPromise()
      .then(nodes => this.nodesSubject.next(nodes))
      .catch(() => null);
  }

  public onSelect(node: TreeNode): void {
    if (!node) return;
    const resource = node.data?.resource;
    if (!resource) return;

    if (node.type === 'more') {
      this.getChildren(node.data.resource, node.data.paging)
        .toPromise()
        .then(({ children, paging }) => {
          const parentNode = node.parent;

          if (!parentNode) {
            this.pagingSubject.next(paging);
            const newNodes = this.nodesSubject.getValue();
            newNodes.pop();
            this.nodesSubject.next([
              ...newNodes,
              ...children.map((x: ResourceDto) => this.mapResourceToNode(x, this.resourceSubject.getValue().resourceId)),
              ...(paging.pageNumber < paging.totalPages ? [{
                key: `${ node.data.resource.resourceId }-more`,
                type: 'more',
                data: { resource: node.data.resource, paging }
              }] : [])
            ]);
            return;
          }

          parentNode.data.paging = paging;
          parentNode.children.pop();
          parentNode.children.push(...[
            ...children.map((x: ResourceDto) => this.mapResourceToNode(x, node.data.resource.resourceId)),
            ...(paging.pageNumber < paging.totalPages ? [{
              key: `${ node.data.resource.resourceId }-more`,
              type: 'more',
              data: { resource: node.data.resource, paging }
            }] : [])
          ]);
        })
        .catch(() => null);
      return;
    }

    this.selectResource.emit(resource);
    this.resourceChildrenPanel.hide();
  }

  public onExpand(node: TreeNode): void {
    if (!node) return;

    this.getChildren(node.data.resource, node.data.paging)
      .toPromise()
      .then(({ children, paging }) => {
        node.data.paging = paging;
        node.children = [
          ...children.map((x: ResourceDto) => this.mapResourceToNode(x, node.data.resource.resourceId)),
          ...(paging.pageNumber < paging.totalPages ? [{
            key: `${ node.data.resource.resourceId }-more`,
            type: 'more',
            data: { resource: node.data.resource, paging }
          }] : [])
        ];
        this.changeDetector.detectChanges();
      })
      .catch(() => null);
  }

  public onCollapse(node: TreeNode): void {
    if (!node || !node.data?.paging) return;
    node.data.paging = null;
    node.children = [];
    this.changeDetector.detectChanges();
  }

  private getChildren(resource: ResourceDto, paging?: Paging): Observable<{ children: ResourceDto[], paging: Paging }> {
    return this.resourceService.getPage(
      undefined,
      paging?.pageNumber ? (paging.pageNumber < paging.totalPages ? paging.pageNumber + 1 : paging.pageNumber) : 1,
      [
        {
          property: 'isArchived',
          type: FilterTypes.DataTransferObject,
          operator: FilterOperators.Equal,
          value: 'false'
        },
        {
          property: 'parentId',
          type: FilterTypes.DataTransferObject,
          operator: FilterOperators.Equal,
          value: resource.resourceId.toString()
        }
      ],
      undefined,
      undefined,
      ['hasChildren']
    ).pipe(
      take(1),
      map(response => ({
        children: response.items,
        paging: response.paging
      })),
      catchError(error => {
        this.apiNotificationService.handleApiError(error);
        return throwError(error);
      })
    );
  }

  private mapResourceToNode(resource: ResourceDto, resourceId: number): TreeNode {
    return {
      key: resource.resourceId.toString(),
      label: `${ resource.inventoryNumber ? `${ resource.inventoryNumber }: ` : '' }${ resource.name }`,
      leaf: !resource.hasChildren || resource.resourceId === resourceId,
      data: { resource }
    };
  }
}
