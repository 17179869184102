import {ChangeDetectionStrategy, Component, EventEmitter, Injector, Input, OnInit, Output} from '@angular/core';
import {
  AppEntityType,
  ControllerOperationId,
  DataTableColumnType,
  DataTableDto,
  DataTableUsage,
  FilterDto,
  mapDatatableFilterToFilter,
  Mappers,
  ResourceDto,
  ResourceTaskSelectorDto,
  SortObject
} from '@nexnox-web/core-shared';
import {
  CORE_PORTAL_DATATABLE_STANDARD_CONFIG,
  CorePortalEntityOverviewBaseComponent,
  DatatableLoadPagePayload,
  ModelValid
} from '@nexnox-web/core-portal';
import {resourceTaskResourcesStore} from '../../store';
import {BehaviorSubject, Observable} from 'rxjs';
import {filter, skip, take} from 'rxjs/operators';
import {uniqBy} from 'lodash';
import {faInfoCircle} from '@fortawesome/free-solid-svg-icons/faInfoCircle';

const defaultSelectorDto = {
  pageOperation: ControllerOperationId.ResourceControllerList,
  usage: DataTableUsage.Selector,
  name: 'ResourceTaskResourceSelector'
};

@Component({
  selector: 'nexnox-web-resource-tasks-resource-selection-edit',
  templateUrl: './resource-selection-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResourceSelectionEditComponent extends CorePortalEntityOverviewBaseComponent<ResourceDto> implements OnInit, ModelValid {
  public componentId = 'ResourceSelectionEditComponent';
  @Input() public creating: boolean;
  @Input() public loading: boolean;
  @Output() public selectorChange: EventEmitter<ResourceTaskSelectorDto> = new EventEmitter<ResourceTaskSelectorDto>();
  public title = 'resources.subtitles.resource-list';
  public idProperty = 'resourceId';
  public displayProperty = 'name';
  public pageOperation = ControllerOperationId.ResourceControllerList;
  public enableViews = true;
  public datatableConfigName = 'ResourceDefault';
  public ready$: Observable<any>;
  public datatableConfig$: Observable<DataTableDto>;
  public faInfoCircle = faInfoCircle;
  private readySubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private selectorSubject: BehaviorSubject<ResourceTaskSelectorDto> = new BehaviorSubject<ResourceTaskSelectorDto>(null);
  private datatableConfigSubject: BehaviorSubject<DataTableDto> = new BehaviorSubject<DataTableDto>(null);

  constructor(
    protected injector: Injector
  ) {
    super(injector, resourceTaskResourcesStore, Mappers.ResourceListDto.serializedName, AppEntityType.Resource);

    // Change default columns here for resource selector of resource task
    this.defaultColumns = ['resourceId', 'name', 'location', 'location.stereotype', 'stereotype'];
  }

  public get selector(): ResourceTaskSelectorDto {
    const selector = this.selectorSubject.getValue();
    const config = this.datatableConfigSubject.getValue();

    return {
      ...(selector ?? {}),
      view: {
        ...(selector?.view ?? {}),
        columns: config?.columns,
        filters: config?.filters,
        pageSize: config?.pageSize,
        ...defaultSelectorDto
      }
    };
  }

  @Input()
  public set selector(selector: ResourceTaskSelectorDto) {
    const defaultConfig = this.injector.get(CORE_PORTAL_DATATABLE_STANDARD_CONFIG).ResourceDefault;

    let defaultColumns = this.datatableColumnService.getDataColumns(this.serializedNameOrMapper, defaultConfig.excludedColumns);
    defaultColumns = defaultColumns.filter(x => this.defaultColumns.find(y => y === x.name));

    this.selectorSubject.next(selector);
    this.datatableConfigSubject.next({
      columns: selector?.view?.columns ?? defaultColumns.map((x, index) => ({
        position: index + 1,
        title: x.name,
        type: DataTableColumnType.ByTransfer,
        sortOrder: null,
        property: x.name
      })),
      filters: selector?.view?.filters ?? [],
      pageSize: selector?.view?.pageSize ?? 15,
      ...defaultSelectorDto
    });
  }

  public ngOnInit(): void {
    this.setDefaults();

    this.ready$ = this.readySubject.asObservable().pipe(
      filter(ready => ready),
      take(1)
    );

    this.datatableConfig$ = this.datatableConfigSubject.asObservable().pipe(
      filter(config => Boolean(config))
    );

    this.datatableConfig$.pipe(
      skip(this.creating ? 0 : 1),
      take(1)
    ).subscribe(() => {
      this.selectorChange.emit(this.selector);
      super.ngOnInit();
      setTimeout(() => this.readySubject.next(true));
    });
  }

  // Intended. Don't get entities when the datatable requests it, just when the config changes.
  public onLoadPage(payload: DatatableLoadPagePayload): void {
    super.onLoadPage({ ...payload, filtersChanged: true }); // PH: super function call reactivated but with filtersChanged always true, see Bug 3274
  }

  public onDatatableConfigChange(datatableConfig: DataTableDto): void {
    this.datatableConfigSubject.next(datatableConfig);
    this.selectorChange.emit(this.selector);
    setTimeout(() => this.getEntities());
  }

  public isModelValid(): boolean {
    return true;
  }

  public isOwnModelValid(): boolean {
    return true;
  }

  protected async getEntities(
    page: number = 1,
    sortOption?: SortObject,
    filters?: FilterDto[],
    pageSize?: number,
    append: boolean = false
  ): Promise<void> {
    return super.getEntities(
      page,
      sortOption,
      uniqBy([
        ...(filters ?? []),
        ...(this.selector?.view?.filters ?? []).map(x => mapDatatableFilterToFilter(x))
      ], x => x.property),
      this.selector?.view?.pageSize ?? pageSize,
      append,
      this.datatableConfigSubject.getValue()
    );
  }
}
