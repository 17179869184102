import {Injectable} from '@angular/core';
import {AppEntityType, CoreSharedHttpTechRequestService, IMonitorService, ResourceDto} from '@nexnox-web/core-shared';
import {Observable, of} from "rxjs";
import {map} from "rxjs/operators";

export interface InheritanceManufacturerModelPreviewPayload {
  entityType: AppEntityType;
}

@Injectable()
export class InheritanceManufacturerModelService implements IMonitorService<ResourceDto> {

  constructor(private httpRequestService: CoreSharedHttpTechRequestService) {
  }

  public modifyModel(payload: InheritanceManufacturerModelPreviewPayload, key: string,
                     model: ResourceDto): Observable<ResourceDto> {

    if (model[key] === true) {

      return this.httpRequestService.post<ResourceDto>(`resource/preview`, {
        entity: payload.entityType,
        parentId: model?.parent?.resourceId,
        stereotypeId: model?.stereotypeId
      }).pipe(
        map(response => response.body),
        map(preview => {
          return {
            ...model,
            // Check manufacturer
            manufacturer: model.inheritsManufacturer === true ? preview.manufacturer : model.manufacturer,
            // Check model
            model: model.inheritsModel === true ? preview.model : model.model
          };
        })
      );
    } else {
      return of(model);
    }
  }
}
