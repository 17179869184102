<nexnox-web-entity-edit *ngIf="!creating"
                        [entityEditBaseComponent]="this"
                        [stereotyped]="false"
                        displayKey="title">
</nexnox-web-entity-edit>

<nexnox-web-tabs *ngIf="creating"
                 [internal]="true"
                 [isTransparent]="true"
                 startTab="entityEdit">

  <!-- Entity Edit Tab -->
  <ng-template [hasError]="!isOwnModelValid()"
               [heading]="title | translate"
               nexnoxWebTabTemplate
               tabId="entityEdit">
    <nexnox-web-entity-edit [entityEditBaseComponent]="this" [stereotyped]="false"></nexnox-web-entity-edit>
  </ng-template>

  <!-- Control notification edit tab -->
  <ng-template [heading]="'controls.fields.notifications' | translate"
               nexnoxWebTabTemplate
               tabId="notifications">
    <nexnox-web-controls-notification-edit (notificationsChanged)="onNotificationsChanged($event)"
                                           [controlContentType]="model.contentType"
                                           [loading]="loading"
                                           [notifications]="notifications"
                                           [readonly]="readonly">
    </nexnox-web-controls-notification-edit>
  </ng-template>

  <!-- Resource Selection -->
  <ng-template [hasError]="!isResourceFilter"
               [heading]="'resources.subtitles.resource-list' | translate"
               nexnoxWebTabTemplate
               tabId="resourceSelection">
    <nexnox-web-control-resources-edit (datatableChange)="onDatatableChange($event)"
                                       *ngIf="model"
                                       [canCreate]="false"
                                       [canRouteToDetail]="false"
                                       [creating]="creating"
                                       [dataTable]="model?.dataTable"
                                       [isEmbedded]="true"
                                       [loading]="loading"
                                       [parentIds]="[model.controlId ?? 0]"
                                       [readonly]="readonly">
    </nexnox-web-control-resources-edit>
  </ng-template>

  <!-- Issues Tab -->
  <ng-template *ngIf="(showIssuesTab$ | async)"
               [heading]="'core-portal.core.header.breadcrumbs.issues.issue-list' | translate"
               [lazy]="true"
               nexnoxWebTabTemplate
               tabId="issues">
    <nexnox-web-controls-control-issue-list *ngIf="model"
                                            [canCreate]="false"
                                            [getEntityOnModeChange]="false"
                                            [isEmbedded]="true"
                                            [parentIds]="[model.controlId ?? 0]">
    </nexnox-web-controls-control-issue-list>
  </ng-template>
</nexnox-web-tabs>

<!-- Consumption Code Templates -->
<ng-template #consumptionCodeSelectLabelTitleTemplate let-item="item">
  <nexnox-web-connected-consumption-codes-consumption-code-external-id [consumptionCode]="item"
                                                                       [isLabel]="true">
  </nexnox-web-connected-consumption-codes-consumption-code-external-id>
</ng-template>

<ng-template #consumptionCodeSelectOptionTitleTemplate let-item="item" let-searchTerm="searchTerm">
  <nexnox-web-connected-consumption-codes-consumption-code-external-id [consumptionCode]="item"
                                                                       [isLabel]="false"
                                                                       [searchTerm]="searchTerm">
  </nexnox-web-connected-consumption-codes-consumption-code-external-id>
</ng-template>
