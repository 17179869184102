<p-table [columns]="config?.columns ?? []"
         [loadingIcon]="faSpinner | nexnoxWebFaIconStringPipe:'fa-spin'"
         [loading]="loading"
         [ngClass]="config?.class ?? ''"
         [resizableColumns]="true"
         [scrollable]="true"
         [tableStyle]="{'width': '100%'}"
         [value]="data ?? []"
         columnResizeMode="expand">
  <ng-template let-columns pTemplate="header">
    <tr>
      <th *ngFor="let col of columns" [class.text-right]="col.align === 'right'">
        {{ col.header | translate }}
      </th>
      <th *ngIf="config?.actions?.length > 0" class="text-right">
        {{ 'core-shared.shared.table.actions' | translate }}
      </th>
    </tr>
  </ng-template>
  <ng-template let-columns="columns" let-rowData let-rowIndex="rowIndex" pTemplate="body">
    <tr>
      <td *ngFor="let col of columns" [class.text-right]="col.align === 'right'" class="text-wrap">

        <div [ngClass]="col.class ?? ''">
          <ng-container [ngSwitch]="col.type">

            <ng-container *ngSwitchCase="columnType.Text">
              {{ (col.prefix ?? '') + getRowData(rowData, col.key) + (col.suffix ?? '') }}
            </ng-container>

            <ng-container *ngSwitchCase="columnType.Boolean">
              {{ (col.prefix ?? '') + (getBooleanValue(rowData, col.key) | translate) + (col.suffix ?? '') }}
            </ng-container>

            <ng-container *ngSwitchCase="columnType.TextTranslation">
              {{ (col.prefix ?? '') + (getRowData(rowData, col.key) | translate) + (col.suffix ?? '') }}
            </ng-container>

            <ng-container *ngSwitchCase="columnType.Enum">
              {{ (col.prefix ?? '') + (getEnumTranslation(col?.enumOptions, getRowData(rowData, col.key)) | translate) + (col.suffix ?? '') }}
            </ng-container>

            <ng-container *ngSwitchCase="columnType.Link">
              <ng-container *ngIf="col.link(rowData) as linkData">
                <a *ngIf="!linkData.isExternalLink" [href]="getURL(col.link(rowData))" target="_blank">
                  {{ (col.prefix ?? '') + getRowData(rowData, col.key) + (col.suffix ?? '') }}
                </a>
                <a *ngIf="linkData.isExternalLink" [href]="getRowData(rowData, col.key)" target="_blank">
                  {{ (linkData.externalLinkLabel | translate) ?? getRowData(rowData, col.key) }}
                </a>
              </ng-container>
            </ng-container>

            <ng-container *ngSwitchCase="columnType.Date">
              {{ getRowData(rowData, col.key) | nexnoxWebBsDate: config?.dateFormat | async }}
            </ng-container>

            <ng-container *ngSwitchCase="columnType.Currency">
              {{ getRowData(rowData, col.key) | nexnoxWebLocaleStringPipe:2 }} €
            </ng-container>

            <ng-container *ngSwitchCase="columnType.HumanizedTimeSpan">
              {{ getHumanizedTimeSpan(rowData, col.key) }}
            </ng-container>

            <ng-container *ngSwitchCase="columnType.UsageOfTotal">
              <div class="d-flex">
                <div [class.red-progress]="getUsageOfTotal(rowData, col.key).percent > 100" class="w-75">
                  <p-progressBar [showValue]="false"
                                 [value]="getUsageOfTotal(rowData, col.key).percent">
                  </p-progressBar>
                </div>
                <div class="w-25 text-right">
                  {{ getUsageOfTotal(rowData, col.key).usage }} / {{ getUsageOfTotal(rowData, col.key).total }}
                </div>
              </div>
            </ng-container>

          </ng-container>
        </div>
      </td>
      <td *ngIf="config.actions?.length > 0" class="text-right">
        <ng-container *ngIf="!(rowData?.isRowLoading ?? false); else loadingTemplate">
          <button (click)="action.click(rowData, rowIndex)"
                  *ngFor="let action of config.actions"
                  [class.d-none]="action?.hidden ? action.hidden(rowData) : (action.hidden$ | async) ?? false"
                  [class]="action.class ?? 'p-button-secondary p-button-text'"
                  [disabled]="action?.disabled ? action.disabled(rowData) : (action.disabled$ | async) ?? false"
                  [icon]="action.icon | nexnoxWebFaIconStringPipe"
                  [label]="action.label | translate"
                  [title]="action.tooltip | translate"
                  class="m-0 ml-2"
                  pButton
                  type="button">
          </button>
        </ng-container>
      </td>
    </tr>
  </ng-template>

  <ng-template pTemplate="emptymessage">
    <div class="p-3">
      {{ 'core-shared.shared.table.empty' | translate }}
    </div>
  </ng-template>

  <ng-template #loadingTemplate>
    <button [icon]="faSpinner | nexnoxWebFaIconStringPipe"
            [loadingIcon]="faSpinner | nexnoxWebFaIconStringPipe:'fa-spin'"
            [loading]="true"
            class="p-button-secondary p-button-text p-0 m-0 ml-2"
            pButton
            type="button">
    </button>
  </ng-template>

</p-table>
