<div class="d-flex w-100 h-100 align-items-start">
  <div (resizing)="onResizing($event)"
       [style.width.px]="resourceListWidth"
       class="d-md-flex d-none flex-shrink-0 mr-4 resource-list h-100"
       mwlResizable>
    <nexnox-web-resources-resource-tree-view #resourceListComponent
                                             (refresh)="onRefresh()"
                                             [parent]="this"
                                             class="w-100">
    </nexnox-web-resources-resource-tree-view>

    <div [resizeEdges]="{ right: true }" class="list-resize-handle" mwlResizeHandle></div>
  </div>

  <div class="d-flex w-100 h-100 resource-detail">
    <router-outlet (activate)="onOutletActivate($event)"></router-outlet>
  </div>
</div>


