import {ChangeDetectionStrategy, Component, Injector, ViewChild} from '@angular/core';
import {CorePortalCardboxAction, CorePortalEntityOverviewBaseComponent} from '@nexnox-web/core-portal';
import {
  AppEntityType,
  AppPermissions,
  ControllerOperationId,
  Mappers,
  ReservedInventoryNumberListDto,
  ReservedInventoryNumberRequestDto
} from '@nexnox-web/core-shared';
import {inventoryNumberDetailStore, reservationListStore} from '../../store/stores';
import {faBookmark} from "@fortawesome/free-solid-svg-icons/faBookmark";
import {ReserveInventoryNumberSidebarComponent} from "../../sidebars";
import {firstValueFrom, take} from "rxjs";
import {select} from "@ngrx/store";
import {map} from "rxjs/operators";

@Component({
  selector: 'nexnox-web-settings-inventory-number-patterns-reservation-list',
  templateUrl: './reservation-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReservationListComponent extends CorePortalEntityOverviewBaseComponent<ReservedInventoryNumberListDto> {
  @ViewChild('reserveInventoryNumberSidebar', { static: true }) public reserveInventoryNumberSidebar: ReserveInventoryNumberSidebarComponent;

  public title = 'core-portal.settings.subtitles.inventory-number-reservation-list';
  public idProperty = '';
  public displayProperty = 'name';
  public datatableConfigName = 'SettingsInventoryNumberReservationsDefault';
  public componentId = 'ReservationListComponent';
  public pageOperation = ControllerOperationId.ReservedInventoryNumberList;
  public enableViews = true;

  constructor(
    protected injector: Injector
  ) {
    super(injector, reservationListStore, Mappers.ReservedInventoryNumberListDto.serializedName, AppEntityType.None);
    this.subscribe(this.store.pipe(
      select(inventoryNumberDetailStore.selectors.selectReserveLoaded)
    ), (reserved) => {
      if (reserved) {
        this.refreshList();
      }
    });
  }

  public async onReserve(model: Partial<ReservedInventoryNumberRequestDto>): Promise<void> {
    const inventoryNumberPatternId: number = await firstValueFrom(this.store.pipe(
      select(inventoryNumberDetailStore.selectors.selectEntity),
      map(entity => entity.inventoryNumberPatternId),
      take(1)
    ));

    this.store.dispatch(inventoryNumberDetailStore.actions.reserveInventoryNumber({
      ...model,
      inventoryNumberPatternId
    }));
  }

  public getCardBoxHeaderActions(): CorePortalCardboxAction[] {
    return [{
      icon: faBookmark,
      class: 'p-button p-button-primary',
      label: 'core-portal.settings.actions.inventory-number-patterns.reserve',
      buttonSize: 'lg',
      permission: undefined,
      callback: () => this.reserveInventoryNumberSidebar.onShow(),
      shouldShow: () => this.permissionService.hasPermission$(AppPermissions.DisplayMissionPlan)
    }];
  }
}
