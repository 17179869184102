import {ChangeDetectionStrategy, Component, ElementRef, Injector, OnInit, ViewChild} from '@angular/core';
import {CorePortalEntityEditBaseComponent} from '@nexnox-web/core-portal';
import {CoreSharedSortableListItem, PriorityDto, PrioritySetDto} from '@nexnox-web/core-shared';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Component({
  selector: 'nexnox-web-ticket-settings-priority-sets-priority-set-edit',
  templateUrl: './priority-set-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TechPortalFeatureTicketSettingsPrioritySetEditComponent extends CorePortalEntityEditBaseComponent<PrioritySetDto>
  implements OnInit {
  public priorityName: string = null;
  public priorities$: Observable<CoreSharedSortableListItem[]>;

  @ViewChild('priorityNameControl') private priorityNameControl: ElementRef;

  private prioritiesSubject: BehaviorSubject<PriorityDto[]> = new BehaviorSubject<PriorityDto[]>([]);

  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }

  public ngOnInit(): void {
    super.ngOnInit();

    this.priorities$ = this.prioritiesSubject.asObservable().pipe(
      map(priorities => this.mapPrioritiesToSortableItems(priorities))
    );
  }

  public onAddPriority(): void {
    this.prioritiesSubject.next([
      ...this.prioritiesSubject.getValue(),
      { name: this.priorityName } as PriorityDto
    ]);
    this.onModelChange({ ...this.model, priorities: this.prioritiesSubject.getValue() });
    this.priorityName = null;
  }

  public onPrioritiesChange(priorities: CoreSharedSortableListItem[]): void {
    this.prioritiesSubject.next(priorities.map(item => ({
      ...item.getExternalData(),
      name: item.title
    })));
    this.onModelChange({ ...this.model, priorities: this.prioritiesSubject.getValue() });
  }

  protected createForm(): FormlyFieldConfig[] {
    return [
      {
        key: 'name',
        type: 'input',
        wrappers: ['core-portal-translated'],
        className: 'col-md-6',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.name',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          type: 'text'
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.disabled': () => this.readonly
        }
      },
      {
        key: 'externalId',
        type: 'core-portal-external-id',
        wrappers: ['core-portal-translated'],
        className: 'col-md-6',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.external-id',
          },
          titleKey: 'name',
          modelSubject: this.modelSubject
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly
        }
      }
    ];
  }

  protected setModel(model: PrioritySetDto): void {
    super.setModel(model);
    this.prioritiesSubject.next(model?.priorities ?? []);
  }

  private mapPrioritiesToSortableItems(priorities: PriorityDto[]): CoreSharedSortableListItem[] {
    return priorities.map((priority, index) => ({
      title: priority.name,
      position: index,
      getExternalData: () => priority
    }));
  }
}
