import {FormFieldTypes} from '@nexnox-web/core-shared';

export const formFieldTypeEnumOptions = [
  { label: 'orga-portal.forms.form-field-types.1', value: FormFieldTypes.Info },
  { label: 'orga-portal.forms.form-field-types.2', value: FormFieldTypes.Text },
  { label: 'orga-portal.forms.form-field-types.3', value: FormFieldTypes.Multiline },
  { label: 'orga-portal.forms.form-field-types.4', value: FormFieldTypes.Number },
  { label: 'orga-portal.forms.form-field-types.5', value: FormFieldTypes.Date },
  { label: 'orga-portal.forms.form-field-types.6', value: FormFieldTypes.Time },
  { label: 'orga-portal.forms.form-field-types.7', value: FormFieldTypes.Picture },
  { label: 'orga-portal.forms.form-field-types.8', value: FormFieldTypes.Signature },
  { label: 'orga-portal.forms.form-field-types.9', value: FormFieldTypes.Checkbox },
  { label: 'orga-portal.forms.form-field-types.10', value: FormFieldTypes.Dropdown },
  { label: 'orga-portal.forms.form-field-types.11', value: FormFieldTypes.Attachment },
  { label: 'orga-portal.forms.form-field-types.12', value: FormFieldTypes.SearchList }
];
