import {createAction, props} from '@ngrx/store';
import {BaseXsStoreErrorPayload} from './base-xs-store.payloads';
import {PropsAction} from '../../types';

export interface BaseXsStoreActions {
  error: PropsAction<BaseXsStoreErrorPayload>;
}

export const createBaseXsStoreActions = (label: string): BaseXsStoreActions => ({
  error: createAction(
    `[${ label }] Error`,
    props<BaseXsStoreErrorPayload>()
  )
});
