import {ChangeDetectionStrategy, Component, Injector, OnInit} from '@angular/core';
import {
  CorePortalEntityEditBaseComponent,
  CorePortalFormlyNgSelectTyping,
  CorePortalFormlyReadonlyTypes,
  CorePortalFormlyReadonlyTyping,
  CorePortalFormlyTranslatedTyping,
  CorePortalVirtualResourceService
} from '@nexnox-web/core-portal';
import {
  ControlSimpleDto,
  ControlTriggerType,
  IssueReasonType,
  LocationDto,
  MessageControlDto,
  ReachedConsumptionIssueReasonDto,
  ResourceSimpleDto
} from '@nexnox-web/core-shared';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {
  CorePortalFeatureMasterDataLocationService
} from "@nexnox-web/core-portal/features/master-data/features/locations";
import {
  messageControlReasonConsumptionTriggerTypeEnumOptions,
  messageControlReasonReadonlyTypeEnumOptions,
  messageControlReasonTypeEnumOptions
} from "../../models";
import {TechPortalFeatureControlService} from "../../../../../controls";

@Component({
  selector: 'nexnox-web-issues-message-control-issue-edit',
  templateUrl: './message-control-issue-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MessageControlIssueEditComponent extends CorePortalEntityEditBaseComponent<MessageControlDto> implements OnInit {

  public reasonFields: FormlyFieldConfig[];

  constructor(
    protected injector: Injector,
    private resourceService: CorePortalVirtualResourceService,
    private locationService: CorePortalFeatureMasterDataLocationService,
    private controlService: TechPortalFeatureControlService
  ) {
    super(injector);
  }

  public ngOnInit(): void {
    this.createReasonForm();
    this.reasonFields = this.createReasonForm();
    super.ngOnInit();
  }

  /* istanbul ignore next */
  protected createForm(): FormlyFieldConfig[] {
    return [
      {
        key: 'title',
        type: 'input',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        templateOptions: {
          type: 'text',
          corePortalTranslated: {
            label: 'core-shared.shared.fields.name',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          }
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        },
        hideExpression: () => !this.creating
      },
      {
        key: 'resource',
        type: 'core-portal-entity-select',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        defaultValue: null,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-portal.settings.fields.ticket-settings.processes.resource'
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.ENTITY,
            displayKey: 'name',
            link: (resource: ResourceSimpleDto) => resource?.resourceId ? ['resources', resource.resourceId] : null,
            module: 'inventory'
          } as CorePortalFormlyReadonlyTyping,
          entityService: this.resourceService,
          idKey: 'resourceId',
          displayKey: 'name',
          wholeObject: true,
          multiple: false
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        }
      },
      {
        key: 'location',
        type: 'core-portal-entity-select',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        defaultValue: null,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.location'
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.ENTITY,
            displayKey: 'name',
            link: (location: LocationDto) => location?.locationId ? ['/masterdata', 'locations', location.locationId] : null,
            module: 'management'
          } as CorePortalFormlyReadonlyTyping,
          entityService: this.locationService,
          idKey: 'locationId',
          displayKey: 'name',
          wholeObject: true,
          multiple: true,
          showAll: true
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        }
      },
      {
        key: 'control',
        type: 'core-portal-entity-select',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        defaultValue: null,
        templateOptions: {
          corePortalTranslated: {
            label: 'controls.subtitles.control'
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.ENTITY,
            displayKey: 'title',
            link: (control: ControlSimpleDto) => control?.controlId ? ['controls', control.controlId] : null,
            module: 'inventory'
          } as CorePortalFormlyReadonlyTyping,
          entityService: this.controlService,
          idKey: 'controlId',
          displayKey: 'name',
          wholeObject: true,
          multiple: true,
          showAll: true
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        }
      },
      {
        key: 'createdAt',
        type: 'core-portal-date-time-picker',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        defaultValue: null,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.created-at',
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.DATE,
            format: 'L LT'
          } as CorePortalFormlyReadonlyTyping
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        }
      }
    ];
  }

  /* istanbul ignore next */
  protected createReasonForm(): FormlyFieldConfig[] {
    return [
      {
        key: 'reason.type',
        type: 'core-portal-ng-select',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        defaultValue: null,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.reason',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          } as CorePortalFormlyTranslatedTyping,
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.ENUM,
            enumOptions: messageControlReasonReadonlyTypeEnumOptions,
            translate: true
          } as CorePortalFormlyReadonlyTyping,
          corePortalNgSelect: {
            items: messageControlReasonTypeEnumOptions,
            translate: true
          } as CorePortalFormlyNgSelectTyping
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.readonly': () => this.readonly || !this.creating
        }
      },

      // Reached Error Reason
      {
        key: 'reason.errorCode.displayName',
        type: 'input',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        templateOptions: {
          type: 'text',
          corePortalTranslated: {
            label: 'core-shared.shared.fields.name',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.BASIC
          }
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        },
        hideExpression: (model: MessageControlDto) => model.reason.type !== IssueReasonType.ReachedError
      },
      {
        key: 'reason.errorCode.externalId',
        type: 'core-portal-external-id',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.external-id',
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.BASIC
          } as CorePortalFormlyReadonlyTyping
        },
        expressionProperties: {
          'templateOptions.readonly': () => this.readonly,
          'templateOptions.disabled': () => this.readonly
        },
        hideExpression: (model: MessageControlDto) => this.creating || model.reason.type !== IssueReasonType.ReachedError
      },

      // Reached Consumption Reason
      {
        key: 'reason.consumptionCode.displayName',
        type: 'input',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        templateOptions: {
          type: 'text',
          corePortalTranslated: {
            label: 'core-shared.shared.fields.name',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.BASIC
          }
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        },
        hideExpression: (model: MessageControlDto) => model.reason.type !== IssueReasonType.ReachedConsumption
      },
      {
        key: 'reason.consumptionCode.externalId',
        type: 'core-portal-external-id',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.external-id',
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.BASIC
          } as CorePortalFormlyReadonlyTyping
        },
        expressionProperties: {
          'templateOptions.readonly': () => this.readonly,
          'templateOptions.disabled': () => this.readonly
        },
        hideExpression: (model: MessageControlDto) => this.creating || model.reason.type !== IssueReasonType.ReachedConsumption
      },
      {
        key: 'reason.triggerType',
        type: 'core-portal-ng-select',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6 field-group-md-mb-0',
        defaultValue: null,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.trigger',
            hideRequiredMarker: true
          } as CorePortalFormlyTranslatedTyping,
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.ENUM,
            enumOptions: messageControlReasonConsumptionTriggerTypeEnumOptions,
            translate: true
          } as CorePortalFormlyReadonlyTyping,
          corePortalNgSelect: {
            items: messageControlReasonConsumptionTriggerTypeEnumOptions,
            translate: true
          } as CorePortalFormlyNgSelectTyping,
          required: true
        },
        expressionProperties: {
          'templateOptions.readonly': () => this.readonly,
          'templateOptions.disabled': () => this.readonly
        },
        hideExpression: (model: MessageControlDto) => model.reason.type !== IssueReasonType.ReachedConsumption
      },
      {
        key: 'reason.triggerValue',
        type: 'input',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.value'
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.BASIC
          } as CorePortalFormlyReadonlyTyping,
          type: 'text'
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        },
        hideExpression: (model: MessageControlDto) => model.reason.type !== IssueReasonType.ReachedConsumption
      },
      {
        key: 'reason.consumptionCode.unit',
        type: 'input',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.unit'
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.BASIC
          } as CorePortalFormlyReadonlyTyping,
          type: 'text'
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        },
        hideExpression: (model: MessageControlDto) => model.reason.type !== IssueReasonType.ReachedConsumption
      },
      {
        key: 'reason.limit',
        type: 'core-portal-input-group-input',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6 field-group-mb-0 mt-2',
        defaultValue: 0,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.limit',
            hideRequiredMarker: true
          } as CorePortalFormlyTranslatedTyping,
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.BASIC
          } as CorePortalFormlyReadonlyTyping,
          corePortalInputGroupInput: {},
          type: 'number',
          required: true
        },
        expressionProperties: {
          'templateOptions.readonly': () => this.readonly
        },
        hideExpression: (model: MessageControlDto) => model.reason.type !== IssueReasonType.ReachedConsumption ||
          (model.reason as ReachedConsumptionIssueReasonDto).triggerType !== ControlTriggerType.Stepped
      },
      {
        key: 'reason.lowerLimit',
        type: 'core-portal-input-group-input',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6 field-group-mb-0 mt-2',
        defaultValue: 0,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.lower-limit',
            hideRequiredMarker: true
          } as CorePortalFormlyTranslatedTyping,
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.BASIC
          } as CorePortalFormlyReadonlyTyping,
          corePortalInputGroupInput: {},
          type: 'number',
          required: true
        },
        expressionProperties: {
          'templateOptions.readonly': () => this.readonly,
          'templateOptions.disabled': () => this.readonly
        },
        hideExpression: (model: MessageControlDto) => model.reason.type !== IssueReasonType.ReachedConsumption ||
          (model.reason as ReachedConsumptionIssueReasonDto).triggerType !== ControlTriggerType.OutOfRange
      },
      {
        key: 'reason.upperLimit',
        type: 'core-portal-input-group-input',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6 field-group-mb-0 mt-2',
        defaultValue: 0,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.upper-limit',
            hideRequiredMarker: true
          } as CorePortalFormlyTranslatedTyping,
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.BASIC
          } as CorePortalFormlyReadonlyTyping,
          corePortalInputGroupInput: {},
          type: 'number',
          required: true
        },
        expressionProperties: {
          'templateOptions.readonly': () => this.readonly,
          'templateOptions.disabled': () => this.readonly
        },
        hideExpression: (model: MessageControlDto) => model.reason.type !== IssueReasonType.ReachedConsumption ||
          (model.reason as ReachedConsumptionIssueReasonDto).triggerType !== ControlTriggerType.OutOfRange
      },

      // Fixed Time Reason
      {
        key: 'reason.frequency',
        type: 'core-portal-timepicker',
        className: 'col-md-6 mt-3',
        wrappers: ['core-portal-translated'],
        templateOptions: {
          corePortalTranslated: {
            label: 'controls.fields.frequency',
            hideRequiredMarker: true
          },
          corePortalTimepicker: {
            mode: 'timespan',
            showYears: true,
            showWeeks: true,
            showDays: true,
            showHours: false,
            showMinutes: false
          },
          required: true
        },
        expressionProperties: {
          'templateOptions.readonly': () => this.readonly,
          'templateOptions.disabled': () => this.readonly
        },
        hideExpression: (model: MessageControlDto) => model.reason?.type !== IssueReasonType.ReachedFixedTime
      }
    ];
  }
}
