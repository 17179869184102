import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {StateDetailComponent, StateListComponent} from './containers';
import {I18nBreadcrumbResolver, UnsavedChangesGuard} from '@nexnox-web/core-shared';

export const TECH_PORTAL_STATES_ROUTES: Routes = [
  {
    path: '',
    component: StateListComponent
  },
  {
    path: ':stateId',
    component: StateDetailComponent,
    canDeactivate: [UnsavedChangesGuard],
    data: {
      breadcrumbs: I18nBreadcrumbResolver,
      tab: 'state',
      text: 'core-portal.core.header.breadcrumbs.settings.ticket-settings.state'
    }
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(TECH_PORTAL_STATES_ROUTES)
  ],
  exports: [
    RouterModule
  ]
})
export class StatesRoutingModule {
}
