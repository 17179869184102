import {IssueReasonType} from "@nexnox-web/core-shared";

export const controlIssueReasonTypeEnumOptions: { label: string, value: IssueReasonType }[] = [
  {
    label: 'controls.issue-reason-types.1',
    value: IssueReasonType.ReachedFixedTime
  },
  {
    label: 'controls.issue-reason-types.2',
    value: IssueReasonType.ReachedError
  },
  {
    label: 'controls.issue-reason-types.3',
    value: IssueReasonType.ReachedConsumption
  },
  {
    label: 'controls.issue-reason-types.4',
    value: IssueReasonType.ReceivedMail
  },
];
