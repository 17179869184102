<ng-container *ngIf="!disabled">
  <ng-container *ngIf="!asCards; else cardTemplate">
    <div class="d-flex flex-column mb-3">
      <ng-container *ngTemplateOutlet="createTemplate"></ng-container>
    </div>
  </ng-container>
</ng-container>

<ng-template #cardTemplate>
  <nexnox-web-cardbox [title]="'core-shared.shared.select.add-item' | translate"
                      paddingClasses="px-3 pb-3">
    <ng-container *ngTemplateOutlet="createTemplate"></ng-container>
  </nexnox-web-cardbox>
</ng-template>

<ng-template #createTemplate>
  <form [formGroup]="form" class="w-100">
    <formly-form [(model)]="model"
                 [fields]="fields"
                 [form]="form"
                 class="row">
    </formly-form>
  </form>

  <div class="d-flex w-100 justify-content-end">
    <button (click)="onAddItem()"
            [disabled]="!model || form.invalid || (tooManyItems$ | async)"
            class="btn btn-outline-primary">
      {{ 'core-shared.shared.select.add-item' | translate }}
    </button>
  </div>
</ng-template>

<div *ngIf="!disabled && (tooManyItems$ | async)"
     [class.alert-info]="(tooManyItemsError$ | async) === false"
     [class.alert-warning]="tooManyItemsError$ | async"
     class="alert d-flex align-items-center w-100">
  <fa-icon [icon]="(tooManyItemsError$ | async) ? faExclamationTriangle : faInfoCircle" class="mr-2"></fa-icon>
  <span>
    {{ 'core-portal.shared.rule-editor.descriptions.max-items-reached' | translate }}
  </span>
</div>

<nexnox-web-sortable-list (itemsChange)="onItemsChange($event)"
                          (selectItem)="selectFn($event)"
                          [asCards]="asCards"
                          [canSelectFn]="canSelectFn"
                          [disabled]="disabled"
                          [editable]="false"
                          [items]="items$ | async"
                          [noPaddingBottom]="true"
                          [selectable]="$any(selectFn)"
                          [sortable]="sortable">
  <ng-template let-item="item" nexnoxWebSortableListItemTemplate>
    <nexnox-web-rule-editor-list-item #ruleEditorListItemComponent
                                      (itemChange)="onItemChange(item.index, $event)"
                                      [disabled]="disabled"
                                      [imageKey]="imageKey"
                                      [inheritedFieldsFn]="inheritedFieldsFn"
                                      [inherited]="inheritedKey ? item[inheritedKey] : false"
                                      [item]="item">
    </nexnox-web-rule-editor-list-item>
  </ng-template>
</nexnox-web-sortable-list>
