import {Directive, Injector, OnInit, ViewChild} from '@angular/core';
import {ModalComponent} from '../../components';
import {SweetAlert2LoaderService} from '@sweetalert2/ngx-sweetalert2';
import Swal from 'sweetalert2';
import {UnsubscribeHelper} from '../../helper';

@Directive()
export abstract class CoreSharedModalBaseComponent extends UnsubscribeHelper implements OnInit {
  @ViewChild('modalComponent', { static: true }) public modalComponent: ModalComponent;

  public preConfirmFn: any;

  protected swalLoader: SweetAlert2LoaderService;
  protected swalInstance: typeof Swal;

  protected constructor(
    protected injector: Injector
  ) {
    super();

    this.preConfirmFn = async () => await this.onPreConfirm();
  }

  protected get confirmButton(): HTMLElement {
    return this.swalInstance?.getConfirmButton();
  }

  public ngOnInit(): void {
    this.swalLoader = this.injector.get(SweetAlert2LoaderService);
    this.swalLoader.swal.then(swal => {
      this.swalInstance = swal;

      const confirmButtonInterval = setInterval(() => {
        if (this.confirmButton) {
          clearInterval(confirmButtonInterval);
          this.onConfirmButtonAvailable();
        }
      }, 50);
    });
  }

  protected onConfirmButtonAvailable(): void {
  }

  protected async onPreConfirm(): Promise<any> {
    return null;
  }

  protected enableConfirmButton(): void {
    if (this.confirmButton) {
      this.confirmButton.removeAttribute('disabled');
    }
  }

  protected disableConfirmButton(): void {
    if (this.confirmButton) {
      this.confirmButton.setAttribute('disabled', '');
    }
  }
}
