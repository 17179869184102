import {ChangeDetectionStrategy, Component, Injector, Input, OnInit} from '@angular/core';
import {
  CoreSharedApiBaseService,
  CoreSharedModalBaseComponent,
  DocumentTemplateContextType,
  DocumentTemplateDto,
  FilterDto,
  FilterOperators,
  FilterTypes
} from '@nexnox-web/core-shared';
import {FormGroup} from '@angular/forms';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {BehaviorSubject} from 'rxjs';
import {filter, map, startWith, take} from 'rxjs/operators';
import {CorePortalFormlyNgSelectTyping} from '@nexnox-web/core-portal';

@Component({
  selector: 'nexnox-web-tech-export-by-template-modal',
  templateUrl: './export-by-template-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TechPortalExportByTemplateModalComponent extends CoreSharedModalBaseComponent implements OnInit {
  @Input() public service: CoreSharedApiBaseService;
  @Input() public contextTypes: { value: DocumentTemplateContextType, label: string }[];

  public form: FormGroup;
  public model: { contextType: DocumentTemplateContextType, template: DocumentTemplateDto };
  public fields: FormlyFieldConfig[];

  private currentContextTypeSubject: BehaviorSubject<DocumentTemplateContextType> =
    new BehaviorSubject<DocumentTemplateContextType>(null);

  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }

  public ngOnInit(): void {
    super.ngOnInit();

    this.form = new FormGroup({});
    this.fields = this.createForm();
    this.model = {} as any;

    this.subscribe(this.form.valueChanges, () => {
      this.form.valid ? this.enableConfirmButton() : this.disableConfirmButton();
    });
  }

  /* istanbul ignore next */
  protected createForm(): FormlyFieldConfig[] {
    return [
      {
        key: 'contextType',
        type: 'core-portal-ng-select',
        wrappers: ['core-portal-translated'],
        className: 'col-md-6 pl-0 pr-md-2 pr-0',
        defaultValue: this.contextTypes[0].value,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.context',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          corePortalNgSelect: {
            items: this.contextTypes,
            translate: true
          } as CorePortalFormlyNgSelectTyping,
          required: true
        },
        hooks: {
          onInit: field => this.subscribe(field.formControl.valueChanges.pipe(
            startWith(field.formControl.value)
          ), value => {
            this.model.template = null;
            this.currentContextTypeSubject.next(value);
          })
        }
      },
      {
        key: 'template',
        type: 'core-portal-entity-select',
        wrappers: ['core-portal-translated'],
        className: 'col-md-6 pl-md-2 pl-0 pr-0',
        defaultValue: null,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.template',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          entityService: this.service,
          idKey: 'documentTemplateId',
          displayKey: 'title',
          clearable: false,
          firstToDefault: true,
          wholeObject: true,
          defaultFilters$: this.currentContextTypeSubject.asObservable().pipe(
            map(contextType => ([{
              property: 'contextType',
              operator: FilterOperators.Equal,
              type: FilterTypes.DataTransferObject,
              value: contextType?.toString()
            }] as FilterDto[]))
          ),
          waitUntil$: this.currentContextTypeSubject.asObservable().pipe(
            filter(contextType => Boolean(contextType)),
            take(1)
          ),
          refresh$: this.currentContextTypeSubject.asObservable(),
          required: true
        },
        expressionProperties: {
          'templateOptions.disabled': () => !this.currentContextTypeSubject.getValue()
        }
      }
    ];
  }

  protected onPreConfirm(): any {
    return this.model;
  }

  protected onConfirmButtonAvailable(): void {
    super.onConfirmButtonAvailable();

    this.disableConfirmButton();
  }
}
