import {ModuleWithProviders, NgModule} from '@angular/core';
import {ErrorCodesDeclarationsModule} from './error-codes-declarations.module';
import {ErrorCodesRoutingModule} from './error-codes-routing.module';
import {ErrorCodesStoreModule} from './error-codes-store.module';

@NgModule({
  imports: [
    ErrorCodesDeclarationsModule,
    ErrorCodesRoutingModule,
    ErrorCodesStoreModule
  ]
})
export class TechPortalFeatureConnectedErrorCodesModule {
  public static forFeature(): ModuleWithProviders<ErrorCodesDeclarationsModule> {
    return { ngModule: ErrorCodesDeclarationsModule };
  }
}
