import {ChangeDetectionStrategy, Component, Injector, TemplateRef, ViewChild} from '@angular/core';
import {
  CorePortalEntityEditBaseComponent,
  CorePortalFormlyNgSelectTyping,
  CorePortalFormlyReadonlyTypes,
  CorePortalFormlyReadonlyTyping,
  CorePortalFormlyTranslatedTyping,
  CorePortalPermissionService
} from '@nexnox-web/core-portal';
import {
  AppEntityType,
  AppPermissions,
  AttachmentForTechDto,
  ConsumptionCodeDto,
  ControlConditionType,
  ControlConsumptionConditionDto,
  ControlDto,
  ControlErrorConditionDto,
  ControlFixedTimeConditionDto,
  ControlNotificationDto,
  ControlTriggerDto,
  ControlTriggerType,
  CrossCreationTypes,
  DataTableDto,
  ErrorCodeDto,
  LinkDto,
  LinkedElementType
} from '@nexnox-web/core-shared';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {Observable, of} from 'rxjs';
import {map, mergeMap} from "rxjs/operators";
import {timespanTo} from "@nexnox-web/lodash";
import dayjs from "dayjs";
import {formatDate} from "ngx-bootstrap/chronos";
import {BsLocaleService} from "ngx-bootstrap/datepicker";
import {TranslateService} from "@ngx-translate/core";
import {
  controlConditionTypeEnumOptions,
  controlConsumptionTriggerTypeEnumOptions,
  controlContentTypeEnumOptions
} from "../../models";
import {TechPortalFeatureConnectedConsumptionCodeService} from "../../../../../connected/consumption-codes";
import {TechPortalFeatureConnectedErrorCodeService} from "../../../../../connected/error-codes";

@Component({
  selector: 'nexnox-web-controls-control-edit',
  templateUrl: './control-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TechPortalFeatureControlEditComponent extends CorePortalEntityEditBaseComponent<ControlDto> {
  @ViewChild('consumptionCodeSelectLabelTitleTemplate', { static: true })
  public consumptionCodeSelectLabelTitleTemplate: TemplateRef<any>;

  @ViewChild('consumptionCodeSelectOptionTitleTemplate', { static: true })
  public consumptionCodeSelectOptionTitleTemplate: TemplateRef<any>;

  public links$: Observable<LinkDto[]>;
  public attachments$: Observable<AttachmentForTechDto[]>;

  public entityTypes = AppEntityType;
  public linkedElementTypes = LinkedElementType;

  public titleFieldFn: any;
  public customTitle$: Observable<string>;

  public isResourceFilter: boolean;

  public showIssuesTab$: Observable<boolean>;

  constructor(
    protected injector: Injector,
    private bsLocaleService: BsLocaleService,
    private translate: TranslateService,
    private permissionService: CorePortalPermissionService,
    private consumptionCodeService: TechPortalFeatureConnectedConsumptionCodeService,
    private errorCodeService: TechPortalFeatureConnectedErrorCodeService
  ) {
    super(injector);

    this.showIssuesTab$ = this.permissionService.hasPermission$(AppPermissions.ReadControlIssue);
  }

  public get notifications(): ControlNotificationDto[] {
    return this.model?.notifications as ControlNotificationDto[];
  }

  public onNotificationsChanged(notifications: any): void {
    this.onModelChange({ ...this.model, notifications });
  }

  public onDatatableChange(dataTable: DataTableDto): void {
    this.onModelChange({ ...this.model, dataTable });
  }

  public onModelChange(model: ControlDto): void {
    const newModel = {
      ...model,
      condition: {
        ...model.condition
      } as ControlErrorConditionDto | ControlFixedTimeConditionDto | ControlConsumptionConditionDto,
      messageContent: model.messageContent ?? {},
      missionContent: model.missionContent ?? {},
      notifications: model.notifications ?? [],
      dataTable: model.dataTable ?? {}
    };
    this.modelValidSubject.next({
      ...this.modelValidSubject.getValue(),
      resourceFilter: this.isResourceFilterApplied(newModel.dataTable)
    });

    this.modelSubject.next(newModel);
    super.onModelChange(newModel);
  }

  public isResourceFilterApplied(datatable?: DataTableDto): boolean {
    datatable = datatable ?? this.model?.dataTable;
    this.isResourceFilter = Boolean(datatable?.filters && datatable.filters.length > 0);
    return this.isResourceFilter;
  }

  public isOwnModelPristine(): boolean {
    return this.form.pristine;
  }

  /* istanbul ignore next */
  protected createForm(): FormlyFieldConfig[] {
    return [
      { key: 'controlNotificationId' },
      {
        key: 'title',
        type: 'input',
        wrappers: ['core-portal-translated'],
        className: 'col-md-6',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.name',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          type: 'text'
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.disabled': () => this.readonly
        },
        hideExpression: () => !this.creating
      },

      {
        key: 'contentType',
        type: 'core-portal-ng-select',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        defaultValue: controlContentTypeEnumOptions[0].value,
        templateOptions: {
          corePortalTranslated: {
            label: 'controls.fields.content-type',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.ENUM,
            enumOptions: controlContentTypeEnumOptions,
            translate: true
          } as CorePortalFormlyReadonlyTyping,
          corePortalNgSelect: {
            items: controlContentTypeEnumOptions,
            translate: true
          }
        },
        expressionProperties: {
          'templateOptions.readonly': () => true
        }
      },
      {
        key: 'rawIssueTitleTemplate',
        type: 'input',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        defaultValue: null,
        templateOptions: {
          corePortalTranslated: {
            label: 'controls.fields.raw-issue-title-template'
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.BASIC
          } as CorePortalFormlyReadonlyTyping,
          type: 'text'
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        }
      },
      {
        key: 'startDate',
        type: 'core-portal-datepicker',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.actual-start',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.DATE,
            format: 'LL'
          } as CorePortalFormlyReadonlyTyping
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        }
      },
      {
        key: 'endDate',
        type: 'core-portal-datepicker',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.actual-end',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.DATE,
            format: 'LL'
          } as CorePortalFormlyReadonlyTyping
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        },
      },
      {
        key: 'isActivated',
        type: 'core-portal-switch',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-4',
        defaultValue: this.model?.isActivated ?? false,
        templateOptions: {
          corePortalTranslated: {
            label: 'controls.fields.activated'
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.BOOLEAN
          } as CorePortalFormlyReadonlyTyping
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        }
      },

      /* Condition */
      {
        wrappers: ['core-portal-translated'],
        className: 'col-md-12 mt-3 px-0',
        fieldGroupClassName: 'row',
        templateOptions: {
          corePortalTranslated: {
            label: 'controls.fields.condition',
            title: true,
            labelClass: 'pl-2'
          }
        },
        fieldGroup: [
          {
            key: 'condition.type',
            type: 'core-portal-ng-select',
            wrappers: ['core-portal-translated', 'core-portal-readonly'],
            className: 'col-md-12 field-group-mb-0',
            defaultValue: controlConditionTypeEnumOptions[0].value,
            templateOptions: {
              corePortalReadonly: {
                type: CorePortalFormlyReadonlyTypes.ENUM,
                enumOptions: controlConditionTypeEnumOptions,
                translate: true
              } as CorePortalFormlyReadonlyTyping,
              corePortalNgSelect: {
                items: controlConditionTypeEnumOptions,
                translate: true
              } as CorePortalFormlyNgSelectTyping,
              required: true,
              description$: this.modelSubject.asObservable().pipe(
                mergeMap(model => {
                  let key: string;
                  const conditionType: ControlConditionType = model?.condition?.type;

                  switch (conditionType) {
                    case ControlConditionType.FixedTime: {
                      const { frequency, timeOfOccurrence } = (model.condition as ControlFixedTimeConditionDto);
                      if (!frequency || !timeOfOccurrence) return of('');

                      key = 'controls.descriptions.fixed-condition';
                      const time: { years: number, weeks: number, days: number } = timespanTo(frequency);
                      const occurrenceParts = timeOfOccurrence?.split(':');
                      const offsetDays = parseInt(frequency?.split('.')[0], 10);

                      let nextExecution: dayjs.Dayjs = dayjs().startOf('day');

                      if (occurrenceParts) {
                        const hours = parseInt(occurrenceParts[0], 10);
                        const minutes = parseInt(occurrenceParts[1], 10);

                        nextExecution = nextExecution.hour(hours).minute(minutes);

                        if (dayjs().isAfter(nextExecution)) {
                          nextExecution = nextExecution.add(offsetDays, 'day');
                        } else if (offsetDays > 0) {
                          nextExecution = nextExecution.add(offsetDays - 1, 'day');
                        }
                      }

                      return this.bsLocaleService.locale.asObservable().pipe(
                        map(locale => formatDate(nextExecution.toDate(), 'LL', locale)),
                        map(next => this.translate.instant(key, { ...time, next }))
                      );
                    }
                    case ControlConditionType.Consumption: {
                      const { trigger, consumption } = (model.condition as ControlConsumptionConditionDto);
                      if (!consumption || !trigger) return of(null);

                      switch (trigger?.type) {
                        case ControlTriggerType.Stepped:
                          key = 'controls.descriptions.consumption-stepped-condition';
                          break;
                        case ControlTriggerType.OutOfRange:
                          key = 'controls.descriptions.consumption-out-of-range-condition';
                          break;
                        default:
                          return of(null);
                      }
                      const unit = (consumption as ConsumptionCodeDto)?.unit;

                      return this.translate.stream(key, {
                        parameter: consumption?.displayName,
                        value: (trigger as ControlTriggerDto).type,
                        unit: unit ? ` ${ unit }` : ''
                      });
                    }
                    case ControlConditionType.Error: {
                      const { error } = (model.condition as ControlErrorConditionDto);
                      if (!error) return of(null);
                      key = 'controls.descriptions.error-condition';

                      return this.translate.stream(key, {
                        error: error?.displayName
                      });
                    }
                  }

                  return of(null);
                })
              )
            },
            expressionProperties: {
              'templateOptions.readonly': () => this.readonly,
              'templateOptions.disabled': () => this.readonly
            }
          }
        ],
        expressionProperties: {
          'templateOptions.required': () => !this.readonly
        }
      },

      /* Error */
      {
        wrappers: ['core-portal-translated'],
        className: 'col-md-12 mt-3 px-0',
        fieldGroupClassName: 'row',
        templateOptions: {
          corePortalTranslated: {
            label: 'resource-tasks.subtitles.error',
            title: true,
            labelClass: 'pl-2'
          }
        },
        fieldGroup: [
          {
            key: 'condition.error',
            type: 'core-portal-entity-select',
            wrappers: ['core-portal-translated', 'core-portal-readonly'],
            className: 'col-md-12 field-group-mb-0',
            defaultValue: null,
            templateOptions: {
              corePortalReadonly: {
                type: CorePortalFormlyReadonlyTypes.ENTITY,
                displayKey: 'displayName',
                link: (row: ErrorCodeDto) => row?.errorCodeId ? ['/connected/error-codes', row.errorCodeId] : null,
                module: 'settings'
              } as CorePortalFormlyReadonlyTyping,
              entityService: this.errorCodeService,
              idKey: 'errorCodeId',
              displayKey: 'displayName',
              wholeObject: true,
              skipGetOne: true,
              enableCrossCreation: CrossCreationTypes.ERROR_CODE,
              link: (row: ErrorCodeDto) => row?.errorCodeId ? ['/connected/error-codes', row.errorCodeId] : null,
              module: 'settings',
              selectLabelTitleTemplate: this.consumptionCodeSelectLabelTitleTemplate,
              selectOptionTitleTemplate: this.consumptionCodeSelectOptionTitleTemplate,
              required: true
            },
            expressionProperties: {
              'templateOptions.readonly': () => this.readonly,
              'templateOptions.disabled': () => this.readonly
            }
          },
          this.getCooldownField()
        ],
        expressionProperties: {
          'templateOptions.required': () => !this.readonly && this.model?.condition?.type === ControlConditionType.Error
        },
        hideExpression: () => this.model?.condition?.type !== ControlConditionType.Error
      },

      /* Consumption */
      {
        wrappers: ['core-portal-translated'],
        className: 'col-md-12 mt-3 px-0',
        fieldGroupClassName: 'row',
        templateOptions: {
          corePortalTranslated: {
            label: 'controls.fields.consumption',
            title: true,
            labelClass: 'pl-2'
          }
        },
        fieldGroup: [
          {
            key: 'condition.consumption',
            type: 'core-portal-entity-select',
            wrappers: ['core-portal-translated', 'core-portal-readonly'],
            className: 'col-md-6 field-group-md-mb-0',
            defaultValue: null,
            templateOptions: {
              corePortalTranslated: {
                label: 'core-shared.shared.fields.consumption-code',
                hideRequiredMarker: true
              } as CorePortalFormlyTranslatedTyping,
              corePortalReadonly: {
                type: CorePortalFormlyReadonlyTypes.ENTITY,
                displayKey: 'displayName',
                link: (row: ConsumptionCodeDto) => row?.consumptionCodeId ? ['/connected/consumption-codes', row.consumptionCodeId] : null,
                module: 'settings'
              } as CorePortalFormlyReadonlyTyping,
              entityService: this.consumptionCodeService,
              idKey: 'consumptionCodeId',
              displayKey: 'displayName',
              wholeObject: true,
              skipGetOne: true,
              enableCrossCreation: CrossCreationTypes.CONSUMPTION_CODE,
              link: (row: ConsumptionCodeDto) => row?.consumptionCodeId ? ['/connected/consumption-codes', row.consumptionCodeId] : null,
              module: 'settings',
              selectLabelTitleTemplate: this.consumptionCodeSelectLabelTitleTemplate,
              selectOptionTitleTemplate: this.consumptionCodeSelectOptionTitleTemplate,
              required: true
            },
            expressionProperties: {
              'templateOptions.readonly': () => this.readonly,
              'templateOptions.disabled': () => this.readonly
            }
          },

          {
            key: 'condition.trigger.type',
            type: 'core-portal-ng-select',
            wrappers: ['core-portal-translated', 'core-portal-readonly'],
            className: 'col-md-6 field-group-md-mb-0',
            defaultValue: null,
            templateOptions: {
              corePortalTranslated: {
                label: 'core-shared.shared.fields.trigger',
                hideRequiredMarker: true
              } as CorePortalFormlyTranslatedTyping,
              corePortalReadonly: {
                type: CorePortalFormlyReadonlyTypes.ENUM,
                enumOptions: controlConsumptionTriggerTypeEnumOptions,
                translate: true
              } as CorePortalFormlyReadonlyTyping,
              corePortalNgSelect: {
                items: controlConsumptionTriggerTypeEnumOptions,
                translate: true
              } as CorePortalFormlyNgSelectTyping,
              required: true
            },
            expressionProperties: {
              'templateOptions.readonly': () => this.readonly,
              'templateOptions.disabled': () => this.readonly
            }
          },

          {
            key: 'condition.trigger.limit',
            type: 'core-portal-input-group-input',
            wrappers: ['core-portal-translated', 'core-portal-readonly'],
            className: 'col-md-3 field-group-mb-0 mt-2',
            defaultValue: 0,
            templateOptions: {
              corePortalTranslated: {
                label: 'core-shared.shared.fields.limit',
                hideRequiredMarker: true
              } as CorePortalFormlyTranslatedTyping,
              corePortalReadonly: {
                type: CorePortalFormlyReadonlyTypes.BASIC
              } as CorePortalFormlyReadonlyTyping,
              corePortalInputGroupInput: {},
              type: 'number',
              required: true
            },
            expressionProperties: {
              'templateOptions.readonly': () => this.readonly,
              'templateOptions.disabled': () => this.readonly
            },
            hideExpression: () => (this.model?.condition as ControlConsumptionConditionDto)?.trigger.type !== ControlTriggerType.Stepped
          },

          {
            key: 'condition.trigger.lowerLimit',
            type: 'core-portal-input-group-input',
            wrappers: ['core-portal-translated', 'core-portal-readonly'],
            className: 'col-md-3 field-group-mb-0 mt-2',
            defaultValue: 0,
            templateOptions: {
              corePortalTranslated: {
                label: 'core-shared.shared.fields.lower-limit',
                hideRequiredMarker: true
              } as CorePortalFormlyTranslatedTyping,
              corePortalReadonly: {
                type: CorePortalFormlyReadonlyTypes.BASIC
              } as CorePortalFormlyReadonlyTyping,
              corePortalInputGroupInput: {},
              type: 'number',
              required: true
            },
            expressionProperties: {
              'templateOptions.readonly': () => this.readonly,
              'templateOptions.disabled': () => this.readonly
            },
            hideExpression: () => (this.model?.condition as ControlConsumptionConditionDto)?.trigger?.type !== ControlTriggerType.OutOfRange
          },
          {
            key: 'condition.trigger.upperLimit',
            type: 'core-portal-input-group-input',
            wrappers: ['core-portal-translated', 'core-portal-readonly'],
            className: 'col-md-3 field-group-mb-0 mt-2',
            defaultValue: 0,
            templateOptions: {
              corePortalTranslated: {
                label: 'core-shared.shared.fields.upper-limit',
                hideRequiredMarker: true
              } as CorePortalFormlyTranslatedTyping,
              corePortalReadonly: {
                type: CorePortalFormlyReadonlyTypes.BASIC
              } as CorePortalFormlyReadonlyTyping,
              corePortalInputGroupInput: {},
              type: 'number',
              required: true
            },
            expressionProperties: {
              'templateOptions.readonly': () => this.readonly,
              'templateOptions.disabled': () => this.readonly
            },
            hideExpression: () => (this.model?.condition as ControlConsumptionConditionDto)?.trigger?.type !== ControlTriggerType.OutOfRange
          },
          this.getCooldownField()
        ],
        expressionProperties: {
          'templateOptions.required': () => !this.readonly && this.model?.condition?.type === ControlConditionType.Consumption
        },
        hideExpression: () => this.model?.condition?.type !== ControlConditionType.Consumption
      },

      /* Fixed time */
      {
        wrappers: ['core-portal-translated'],
        className: 'col-md-12 mt-3 px-0',
        fieldGroupClassName: 'row',
        templateOptions: {
          corePortalTranslated: {
            label: 'controls.fields.fixed-time',
            title: true,
            labelClass: 'pl-2'
          }
        },
        fieldGroup: [
          {
            key: 'condition.timeOfOccurrence',
            type: 'core-portal-timepicker',
            wrappers: ['core-portal-translated'],
            className: 'col-md-6 mt-3',
            templateOptions: {
              corePortalTranslated: {
                label: 'core-shared.shared.fields.execution-time',
                hideRequiredMarker: true
              },
              corePortalTimepicker: {
                mode: 'timespan'
              },
              required: true
            },
            expressionProperties: {
              'templateOptions.required': () => !this.readonly,
              'templateOptions.disabled': () => this.readonly
            }
          },

          {
            key: 'condition.frequency',
            type: 'core-portal-timepicker',
            className: 'col-md-6 mt-3',
            wrappers: ['core-portal-translated'],
            templateOptions: {
              corePortalTranslated: {
                label: 'controls.fields.frequency',
                hideRequiredMarker: true
              },
              corePortalTimepicker: {
                mode: 'timespan',
                showYears: true,
                showWeeks: true,
                showDays: true,
                showHours: false,
                showMinutes: false
              },
              required: true
            },
            expressionProperties: {
              'templateOptions.readonly': () => this.readonly,
              'templateOptions.disabled': () => this.readonly
            }
          }
        ],
        expressionProperties: {
          'templateOptions.required': () => !this.readonly && this.model?.condition?.type === ControlConditionType.FixedTime
        },
        hideExpression: () => this.model?.condition?.type !== ControlConditionType.FixedTime
      }
    ];
  }

  private getCooldownField(): FormlyFieldConfig {
    return {
      key: 'condition.cooldown',
      type: 'core-portal-timepicker',
      wrappers: ['core-portal-translated'],
      className: 'col-md-6 mt-2',
      templateOptions: {
        corePortalTranslated: {
          label: 'controls.fields.cooldown',
          hideRequiredMarker: true
        },
        corePortalTimepicker: {
          mode: 'timespan'
        },
        required: true
      },
      expressionProperties: {
        'templateOptions.required': () => !this.readonly,
        'templateOptions.disabled': () => this.readonly
      }
    };
  }
}
