<ng-template #entityEditTemplate let-id="id" let-title="title">
  <nexnox-web-ticket-settings-state-machines-state-machine-edit #modelComponent
                                                                (modelChange)="onModelChange($event)"
                                                                [creating]="false"
                                                                [id]="id"
                                                                [loading]="loading$ | async"
                                                                [model]="model$ | async"
                                                                [readonly]="readonly$ | async"
                                                                [stereotypes$]="stereotypes$"
                                                                [title]="title">
  </nexnox-web-ticket-settings-state-machines-state-machine-edit>
</ng-template>

<nexnox-web-entity-detail [entityDetailBaseComponent]="this"
                          [entityEditTemplate]="entityEditTemplate"
                          [tabCommands]="['.']"
                          [tabHeading]="title | translate"
                          tabId="stateMachine">
</nexnox-web-entity-detail>
