import {ChangeDetectionStrategy, Component, Injector} from '@angular/core';
import {
  CorePortalCardboxAction,
  CorePortalEntityOverviewBaseComponent,
  DatatableActionButton,
  DeleteEntityModel
} from '@nexnox-web/core-portal';
import {AppEntityType, AppPermissions, AreaAssignmentDto, Mappers} from '@nexnox-web/core-shared';
import {Observable} from 'rxjs';
import {contactAreaListStore} from '../../store';
import {PRIMARY_OUTLET} from "@angular/router";

@Component({
  selector: 'nexnox-web-master-data-contacts-contact-area-list',
  templateUrl: './contact-area-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactAreaListComponent extends CorePortalEntityOverviewBaseComponent<AreaAssignmentDto> {
  public title = 'core-portal.master-data.subtitles.contact-area-list';
  public createTitle = 'core-portal.master-data.actions.create-contact-area';
  public idProperty = 'areaAssignmentId';
  public displayProperty = null;
  public datatableConfigName = 'MasterDataAreaAssignmentDefault';
  public componentId = 'ContactAreaListComponent';

  public canCreate$: Observable<boolean>;

  public createStandaloneAreaHeaderActions: CorePortalCardboxAction[];

  constructor(
    protected injector: Injector
  ) {
    super(injector, contactAreaListStore, Mappers.AreaAssignmentListDto.serializedName, AppEntityType.None);

    this.canCreate$ = this.permissionService.hasPermission$(AppPermissions.CreateAreaAssignment);
    this.createStandaloneAreaHeaderActions = this.createStandaloneHeaderActions(
      'core-portal.master-data.actions.create-contact-area',
      AppPermissions.CreateAreaAssignment
    );
  }

  /* istanbul ignore next */
  public getRowActionButtons(): DatatableActionButton[] {
    return this.getDefaultRowActionButtons(
      'core-portal.master-data.actions.edit-contact-area',
      (row: AreaAssignmentDto) => `/masterdata/contacts/${ this.parentIds[0] }/areas/${ row.areaAssignmentId }`,
      [AppPermissions.UpdateAreaAssignment],
      {
        module: 'management'
      }
    );
  }

  public gotoStandaloneCreate(): void {
    const url = this.router.parseUrl(this.router.url).root.children[PRIMARY_OUTLET].segments.map((s) => s.path);
    this.router.navigate([...url, 'create'])
  }

  public getDeleteEntityModel(): DeleteEntityModel {
    return {
      titleKey: 'core-portal.master-data.actions.delete-contact-area',
      descriptionKey: 'core-portal.master-data.descriptions.delete-contact-area',
      confirmKey: 'core-portal.master-data.actions.delete-contact-area',
      deletePermission: AppPermissions.DeleteAreaAssignment
    };
  }
}
