import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {ResourceDto} from '@nexnox-web/core-shared';
import {BehaviorSubject, Observable} from 'rxjs';
import {map, mergeMap} from 'rxjs/operators';

@Component({
  selector: 'nexnox-web-resources-resource-inventory-number',
  templateUrl: './resource-inventory-number.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CorePortalFeatureResourceInventoryNumberComponent {
  @Input() public label = true;
  @Input() public searchTerm: string;
  public title$: Observable<string>;
  public titleHtml$: Observable<string>;
  public titlePrefix$: Observable<string>;
  public titlePrefixHtml$: Observable<string>;
  private resourceSubject: BehaviorSubject<ResourceDto> = new BehaviorSubject<ResourceDto>(null);

  constructor() {
    this.titlePrefix$ = this.resourceSubject.asObservable().pipe(
      map(resource => CorePortalFeatureResourceInventoryNumberComponent.prepareTitlePrefix(resource))
    );

    this.titlePrefixHtml$ = this.titlePrefix$.pipe(
      map(prefix => `<span class="text-muted">${ prefix }</span>`)
    );

    this.title$ = this.resourceSubject.asObservable().pipe(
      mergeMap(resource => this.titlePrefix$.pipe(
        map(prefix => CorePortalFeatureResourceInventoryNumberComponent.prepareTitle(resource, prefix))
      ))
    );

    this.titleHtml$ = this.resourceSubject.asObservable().pipe(
      mergeMap(resource => this.titlePrefixHtml$.pipe(
        map(prefix => CorePortalFeatureResourceInventoryNumberComponent.prepareTitle(resource, prefix))
      ))
    );
  }

  @Input()
  public set resource(resource: ResourceDto) {
    this.resourceSubject.next(resource);
  }

  public static prepareTitlePrefix(resource: ResourceDto, fallback?: string): string {
    return resource.inventoryNumber ? `${ resource.inventoryNumber }: ` : (fallback ?? `${ resource.resourceId }: `);
  }

  public static prepareTitle(resource: ResourceDto, prefix: string): string {
    return `${ prefix }${ resource.name }`;
  }
}
