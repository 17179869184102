import {ChangeDetectionStrategy, Component, Injector, OnInit} from '@angular/core';
import {
  AppEntityType,
  AppPermissions,
  ControllerOperationId,
  CoreSharedIssueLinkUtilityService,
  IssueListDto,
  Mappers
} from '@nexnox-web/core-shared';
import {resourceIssuesStore} from '../../store/stores';
import {CorePortalEntityOverviewBaseComponent, DatatableActionButton, DeleteEntityModel} from '@nexnox-web/core-portal';

@Component({
  selector: 'nexnox-web-resources-resource-issues',
  templateUrl: './resource-issues.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResourceIssuesComponent extends CorePortalEntityOverviewBaseComponent<IssueListDto> implements OnInit {
  public title = 'issues.title';
  public idProperty = 'issueId';
  public displayProperty = 'title';
  public pageOperation = ControllerOperationId.IssueControllerId;
  public componentId = 'ResourceIssuesComponent';
  public enableViews = true;
  public datatableConfigName = 'IssueDefault';

  constructor(
    protected injector: Injector,
    private issueUtilityService: CoreSharedIssueLinkUtilityService
  ) {
    super(injector, resourceIssuesStore, Mappers.IssueListDto.serializedName, AppEntityType.Issue);
  }

  public detailFn = (row: IssueListDto): void => {
    this.tenantRouter.navigate(this.issueUtilityService.getIssueSegments(row), { module: 'communication' }).then()
  };

  /* istanbul ignore next */
  public getRowActionButtons(): DatatableActionButton[] {
    return this.getDefaultRowActionButtons(
      'issues.actions.edit-issue',
      (row: IssueListDto) => this.issueUtilityService.getIssueLink(row),
      [AppPermissions.UpdateIssue],
      {
        module: 'communication'
      }
    );
  }

  public getDeleteEntityModel(): DeleteEntityModel {
    return {
      titleKey: 'issues.actions.delete-issue',
      descriptionKey: 'issues.descriptions.delete-issue',
      confirmKey: 'issues.actions.delete-issue',
      deletePermission: AppPermissions.DeleteIssue
    };
  }
}
