import {AppEntityType, ResourceDto} from '@nexnox-web/core-shared';
import {selectResourcesState} from '../../resources.selectors';
import {Action, ActionReducer, createSelector, select} from '@ngrx/store';
import {TechPortalFeatureResourceService} from '../../services';
import {Injectable, Injector} from '@angular/core';
import {
  ResourceDetailXsStore,
  ResourceDetailXsStoreActions,
  ResourceDetailXsStoreState
} from './resource-detail.xs-store';
import {createEffect, ofType} from '@ngrx/effects';
import {catchError, map, mergeMap, switchMap, tap, withLatestFrom} from 'rxjs/operators';
import {of} from 'rxjs';
import {resourceUIEntitiesStore} from '../resource-ui-entities';
import {resourceEntitiesStore} from "../resource-entities";
import {resourceTreeViewStore} from "../resource-tree-view";

export interface ResourceDetailStoreState extends ResourceDetailXsStoreState {
}

export const resourceDetailStore = new ResourceDetailXsStore({
  actionLabel: 'Core Portal - Resources - Resource Detail',
  stateSelector: createSelector(selectResourcesState, state => state.resourceDetail),
  entityType: AppEntityType.Resource,
  serviceType: TechPortalFeatureResourceService,
  inheritance: false
});

export function resourceDetailStoreReducer(state: ResourceDetailStoreState, action: Action): ActionReducer<any> {
  return resourceDetailStore.reducer(state, action);
}

@Injectable()
export class ResourceDetailStoreEffects extends resourceDetailStore.effects {

  public changeState$: any;
  public changeStateSuccess$: any;

  public putInSafe$: any;
  public putInSafeSuccess$: any;

  public removeFromSafe$: any;
  public removeFromSafeSuccess$: any;

  protected actions: ResourceDetailXsStoreActions;
  protected service: TechPortalFeatureResourceService;

  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }

  protected createEffects(): void {
    super.createEffects();

    this.changeState$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.changeState),
      withLatestFrom(this.store.pipe(select(this.selectors.selectModel))),
      mergeMap(([payload, model]) =>
        this.service.changeState(model.resourceId, payload.state, payload.location, payload.isKeepingLocation).pipe(
          map(() => this.actions.changeStateSuccess({
            state: payload.state,
            location: payload.location,
            isKeepingLocation: payload.isKeepingLocation
          })),
          catchError(error => of(this.actions.error({ error, action: this.actions.changeState })))
        )
      )
    ));

    this.changeStateSuccess$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.changeStateSuccess),
      tap(action => this.actionCallback(action, false))
    ), { dispatch: false });

    this.putInSafe$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.putInSafe),
      withLatestFrom(this.store.pipe(select(this.selectors.selectModel))),
      mergeMap(([payload, model]) => this.service.safeMove(payload, model.resourceId).pipe(
        map(() => this.actions.putInSafeSuccess(payload)),
        catchError(error => of(this.actions.error({ error, action: this.actions.putInSafe }))
        ))
      ))
    );

    this.putInSafeSuccess$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.putInSafeSuccess),
      tap(action => this.actionCallback(action, false))
    ), { dispatch: false });

    this.removeFromSafe$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.removeFromSafe),
      switchMap((payload) => this.service.safeTakeOut(payload.resourceId).pipe(
        map(() => this.actions.removeFromSafeSuccess()),
        catchError(error => of(this.actions.error({ error, action: this.actions.removeFromSafe }))
        ))
      ))
    );

    this.removeFromSafeSuccess$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.removeFromSafeSuccess),
      tap(action => this.actionCallback(action, false))
    ), { dispatch: false });
  }

  protected actionCallback(action: Action, isError: boolean): void {
    super.actionCallback(action, isError);

    this.checkAction(this.actions.saveSuccess, action, ({ entity }) => this.saveActionCallback(entity));
    this.checkAction(this.actions.putInSafeSuccess, action, () => this.putInSafeActionCallback());
    this.checkAction(this.actions.removeFromSafeSuccess, action, () => this.removeFromSafeActionCallback());
    this.checkAction(this.actions.changeStateSuccess, action, () => this.changeStateActionCallback());
  }

  protected saveActionCallback(resource: ResourceDto): void {
    this.store.dispatch(resourceEntitiesStore.actions.addMany({
      resources: [{
        ...resource,
        isInProgressSince: new Date().toUTCString()
      }]
    }));
    this.store.dispatch(resourceUIEntitiesStore.actions.upsertMany({
      items: [{
        ...resource,
        isInProgressSince: new Date().toUTCString()
      }]
    }));
    this.store.dispatch(resourceTreeViewStore.actions.getResourcesInProgress());
  }

  protected changeStateActionCallback(): void {
    this.apiNotificationService.showTranslatedSuccess('resources.descriptions.state-changed-success-message')
  }

  protected putInSafeActionCallback(): void {
    this.apiNotificationService.showTranslatedSuccess('resources.descriptions.put-resource-in-safe-success-message')
  }

  protected removeFromSafeActionCallback(): void {
    this.apiNotificationService.showTranslatedSuccess('resources.descriptions.remove-resource-from-safe-success-message')
  }
}
