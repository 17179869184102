<div class="input-group">
  <div class="input-group-prepend">
    <div #popover="bs-popover"
         (click)="popover.show()"
         [class.is-disabled]="formControl.disabled"
         [outsideClick]="true"
         [popover]="colorPickerTemplate"
         [style.background-color]="formControl.value ? formControl.value : 'white'"
         class="input-group-text color-preview"
         container="body"
         containerClass="popover-no-padding"
         placement="right"
         triggers="">
    </div>
  </div>

  <input [class.is-invalid]="showError"
         [formControl]="formControl"
         [formlyAttributes]="field"
         class="form-control"
         type="text">
</div>

<ng-template #colorPickerTemplate>
  <color-sketch (onChange)="onChange($event)"
                [color]="formControl.value ? formControl.value : ''"
                [disableAlpha]="true">
  </color-sketch>
</ng-template>
