import {NgModule} from '@angular/core';
import {CoreSharedModule} from '@nexnox-web/core-shared';
import {CorePortalEntityModule, CorePortalRuleEditorListModule} from '@nexnox-web/core-portal';
import {tasksServices} from './store';
import {
  OrgaPortalFeatureTaskEditComponent,
  TaskExecutionsComponent,
  TaskImpactsComponent,
  TaskSubtasksComponent
} from './components';
import {
  TaskArchiveListComponent,
  TaskDetailComponent,
  TaskListComponent,
  TasksPlannedTasksComponent
} from './containers';
import {CorePortalFeatureMasterDataLocationsModule} from '@nexnox-web/core-portal/features/master-data/features/locations';
import {CorePortalFeatureMasterDataLocationGroupsModule} from '@nexnox-web/core-portal/features/master-data/features/location-groups';
import {OrgaPortalFeatureCategoriesModule} from '@nexnox-web/orga-portal/features/categories';
import {OrgaPortalFeatureCancellationReasonsModule} from '@nexnox-web/orga-portal/features/cancellation-reasons';
import {OrgaPortalFeatureFormsModule} from '@nexnox-web/orga-portal/features/forms';
import {TaskActiveListComponent} from './containers/task-active-list/task-active-list.component';

@NgModule({
  declarations: [
    OrgaPortalFeatureTaskEditComponent,
    TaskSubtasksComponent,
    TaskExecutionsComponent,
    TaskImpactsComponent,
    TasksPlannedTasksComponent,
    TaskArchiveListComponent,
    TaskActiveListComponent,

    TaskListComponent,
    TaskDetailComponent
  ],
  imports: [
    CoreSharedModule,
    CorePortalEntityModule,
    CorePortalRuleEditorListModule,

    CorePortalFeatureMasterDataLocationsModule.forFeature(),
    CorePortalFeatureMasterDataLocationGroupsModule.forFeature(),
    OrgaPortalFeatureCategoriesModule.forFeature(),
    OrgaPortalFeatureCancellationReasonsModule.forFeature(),
    OrgaPortalFeatureFormsModule.forFeature()
  ],
  providers: [
    ...tasksServices
  ]
})
export class TasksDeclarationsModule {
}
