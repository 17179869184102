import {
  inventoryNumberDetailStore,
  InventoryNumberListStoreEffects,
  inventoryNumberListStoreReducer,
  ReservationListStoreEffects,
  reservationListStoreReducer
} from './stores';
import {CorePortalFeatureInventoryNumberPatternService, CorePortalFeatureReservationService} from './services';

export * from './services';
export * from './stores';

export * from './inventory-numbers.state';
export * from './inventory-numbers.selector';

export const inventoryNumbersReducers = {
  inventoryNumberList: inventoryNumberListStoreReducer,
  inventoryNumberDetail: inventoryNumberDetailStore.reducer,
  reservationList: reservationListStoreReducer
};

export const inventoryNumbersEffects = [
  InventoryNumberListStoreEffects,
  inventoryNumberDetailStore.effects,
  ReservationListStoreEffects
];

export const inventoryNumbersServices = [
  CorePortalFeatureInventoryNumberPatternService,
  CorePortalFeatureReservationService
];
