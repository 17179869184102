import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {FormGroup, Validators} from "@angular/forms";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {faTrash} from "@fortawesome/free-solid-svg-icons/faTrash";
import {
  CorePortalContactService,
  CorePortalFormlyReadonlyTypes,
  CorePortalFormlyReadonlyTyping,
  CorePortalFunctionService
} from "@nexnox-web/core-portal";
import {
  BasicTableColumnType,
  BasicTableConfig,
  ContactNotificationTargetDto,
  ContactSimpleDto,
  EmailNotificationTargetDto,
  FunctionSimpleDto,
  NotificationTargetDto,
  NotificationTargetType,
  UnsubscribeHelper
} from "@nexnox-web/core-shared";
import {FormlyFieldConfig} from "@ngx-formly/core";
import {BindObservable} from "bind-observable";
import {cloneDeep, find} from "lodash";
import {BehaviorSubject, Observable} from "rxjs";
import {map} from "rxjs/operators";
import {notificationTargetTypeOptions} from "../../models";

export interface NotificationTargetLocalDto {
  type: NotificationTargetType;
  title?: string;
  contact?: ContactSimpleDto;
  functionProperty?: FunctionSimpleDto;
  function?: FunctionSimpleDto;
  email?: string;
}

@Component({
  selector: 'nexnox-web-settings-notification-rules-target-edit',
  templateUrl: 'notification-rules-target-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationRulesTargetEditComponent extends UnsubscribeHelper implements OnInit {

  @Input() public loading: boolean;
  @Input() @BindObservable() public readonly: boolean;
  public readonly$!: Observable<boolean>;
  @Output() public targetsChanged = new EventEmitter<any>();
  public targetsSubject: BehaviorSubject<NotificationTargetDto[]> = new BehaviorSubject<NotificationTargetDto[]>([]);
  public localTargetItems$: Observable<NotificationTargetLocalDto[]>;
  public targetForm: FormGroup;
  public targetFields: FormlyFieldConfig[];
  public targetModelSubject: BehaviorSubject<NotificationTargetLocalDto> = new BehaviorSubject<NotificationTargetLocalDto>(null);
  public targetTableConfig: BasicTableConfig;
  public faPlus = faPlus;
  public faTrash = faTrash;

  constructor(
    private contactService: CorePortalContactService,
    private functionsService: CorePortalFunctionService
  ) {
    super();
  }

  public get targets(): NotificationTargetDto[] {
    return this.targetsSubject.getValue();
  }

  @Input()
  public set targets(targets: NotificationTargetDto[]) {
    this.targetsSubject.next(targets ?? []);
  }

  public ngOnInit(): void {
    this.localTargetItems$ = this.targetsSubject.asObservable().pipe(
      map(targets => this._mapTargetsToLocalTargets(targets))
    );

    this.targetForm = new FormGroup({});
    this.targetFields = this.createFields();
    this.targetTableConfig = {
      columns: [
        {
          key: 'title',
          header: 'core-portal.settings.notification-rules.target',
          type: BasicTableColumnType.Text
        },
        {
          key: 'type',
          header: 'core-portal.settings.notification-rules.target-type',
          type: BasicTableColumnType.Enum,
          enumOptions: notificationTargetTypeOptions
        }
      ],
      actions: [
        {
          icon: faTrash,
          tooltip: 'core-portal.settings.notification-rules.remove-target',
          disabled: () => this.readonly,
          click: (row, index) => this.onDeleteTarget(row, index)
        }
      ]
    }
  }

  public onAddTarget(): void {
    const targets = cloneDeep(this.targets ?? []);
    targets.push(cloneDeep(this.targetModelSubject.getValue()));
    this.targets = targets;

    this.targetModelSubject.next({} as any);
    this.targetForm.reset();
    this.targetForm.markAsUntouched();
    this.targetsChanged.emit(cloneDeep(targets));
  }

  public onDeleteTarget(target: NotificationTargetLocalDto, index: number): void {
    const targets = cloneDeep(this.targets ?? []);
    targets.splice(index, 1);
    this.targets = targets;
    this.targetsChanged.emit(cloneDeep(targets));
  }

  public createFields(): any {

    /* istanbul ignore next */
    return [
      {
        key: 'type',
        type: 'core-portal-ng-select',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-5',
        defaultValue: notificationTargetTypeOptions[0].value,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-portal.settings.notification-rules.target-type',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.ENUM,
            enumOptions: notificationTargetTypeOptions,
            translate: true
          } as CorePortalFormlyReadonlyTyping,
          corePortalNgSelect: {
            items: notificationTargetTypeOptions,
            translate: true
          }
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        }
      },
      {
        key: 'email',
        type: 'input',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-7',
        templateOptions: {
          type: 'email',
          corePortalTranslated: {
            label: 'core-shared.shared.fields.email-address',
            validationMessages: {
              email: 'core-portal.core.validation.email',
              duplicate: 'core-portal.core.validation.duplicate'
            }
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.BASIC
          } as CorePortalFormlyReadonlyTyping
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.readonly': () => this.readonly
        },
        hideExpression: () => this.targetForm.get('type').value !== NotificationTargetType.Email,
        validators: {
          email: ctrl => !Validators.email(ctrl),
          duplicate: control => !find(this.targetsSubject.getValue(), value => (value as EmailNotificationTargetDto)?.email === control?.value)
        }
      },
      {
        key: 'contact',
        type: 'core-portal-entity-select',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-7',
        defaultValue: null,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.contact',
            validationMessages: {
              required: 'core-portal.core.validation.required',
              duplicate: 'core-portal.core.validation.duplicate'
            }
          },
          entityService: this.contactService,
          idKey: 'contactId',
          displayKey: 'displayName',
          skipGetOne: true,
          wholeObject: true,
          clearable: false
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.readonly': () => this.readonly
        },
        hideExpression: () => this.targetForm.get('type').value !== NotificationTargetType.Contact,
        validators: {
          duplicate: control => !find(this.targetsSubject.getValue(), value => (value as ContactNotificationTargetDto)?.contact?.contactId === control?.value?.contactId)
        }
      },
      {
        key: 'function',
        type: 'core-portal-entity-select',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-7',
        defaultValue: null,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.function',
            validationMessages: {
              required: 'core-portal.core.validation.required',
              duplicate: 'core-portal.core.validation.duplicate'
            }
          },
          entityService: this.functionsService,
          idKey: 'functionId',
          displayKey: 'name',
          skipGetOne: true,
          wholeObject: true,
          clearable: false
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.readonly': () => this.readonly
        },
        hideExpression: () => this.targetForm.get('type').value !== NotificationTargetType.Function,
        validators: {
          duplicate: control => !find(this.targetsSubject.getValue(), value => (value as any)?.function?.functionId === control?.value?.functionId)
        }
      }
    ]
  }

  private _mapTargetsToLocalTargets(targets: EmailNotificationTargetDto[]): NotificationTargetLocalDto[] {
    return targets.map(target => {

      const localTarget = {} as NotificationTargetLocalDto;
      localTarget.type = target.type

      switch (localTarget.type) {
        case NotificationTargetType.Contact:
          localTarget.contact = (target as ContactNotificationTargetDto)?.contact;
          localTarget.title = (target as ContactNotificationTargetDto)?.contact?.displayName;
          break;
        case NotificationTargetType.Email:
          localTarget.email = (target as EmailNotificationTargetDto)?.email;
          localTarget.title = (target as EmailNotificationTargetDto)?.email;
          break;
        case NotificationTargetType.Function:
          localTarget.function = (target as any)?.function;
          localTarget.title = (target as any)?.function?.name;
          break;
      }

      return localTarget;
    });
  }
}
