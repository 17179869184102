import {BaseXsStoreReducerTypes, EntityXsStore, EntityXsStoreState} from '@nexnox-web/core-store';
import {ContactDto} from '@nexnox-web/core-shared';
import {ContactDetailXsStoreActions, createContactDetailXsStoreActions} from './contact-detail-xs-store.actions';
import {createContactDetailXsStoreReducer} from './contact-detail-xs-store.reducer';

export class ContactDetailXsStore extends EntityXsStore<ContactDto> {
  public actions: ContactDetailXsStoreActions;

  protected createActions(label: string): ContactDetailXsStoreActions {
    return createContactDetailXsStoreActions(label);
  }

  protected createReducerArray(
    initialState: EntityXsStoreState<ContactDto>
  ): BaseXsStoreReducerTypes<EntityXsStoreState<ContactDto>, ContactDetailXsStoreActions>[] {
    return [
      ...super.createReducerArray(initialState),
      ...createContactDetailXsStoreReducer(this.actions, initialState)
    ];
  }
}
