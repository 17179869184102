import {createEntityXsStoreSelectors, EntityXsStoreSelectors} from '@nexnox-web/core-store';
import {StateDto, TicketDto} from '@nexnox-web/core-shared';
import {TicketDetailXsStoreState} from './ticket-detail-xs-store.state';
import {createSelector, MemoizedSelector} from '@ngrx/store';
import {IsDeepEqualWithCustomizer} from '@nexnox-web/lodash';

export interface TicketDetailXsStoreSelectors extends EntityXsStoreSelectors<TicketDto, TicketDto, TicketDetailXsStoreState> {
  selectPreviewStates: MemoizedSelector<TicketDetailXsStoreState, StateDto[]>;
  selectPreviewStatesLoading: MemoizedSelector<TicketDetailXsStoreState, boolean>;
  selectPreviewStatesLoaded: MemoizedSelector<TicketDetailXsStoreState, boolean>;
}

export const createTicketDetailXsStoreSelectors = (
  stateSelector: MemoizedSelector<any, TicketDetailXsStoreState>,
  sanitizeModel?: (model: TicketDto, entity: TicketDto) => TicketDto,
  changeCustomizers?: IsDeepEqualWithCustomizer[]
): TicketDetailXsStoreSelectors => ({
  ...createEntityXsStoreSelectors(stateSelector, sanitizeModel, changeCustomizers),

  selectPreviewStates: createSelector(stateSelector, state => state.previewStates),
  selectPreviewStatesLoading: createSelector(stateSelector, state => state.previewStatesLoading),
  selectPreviewStatesLoaded: createSelector(stateSelector, state => state.previewStatesLoaded)
});
