<span [class.d-none]="resource?.path?.length < 2"
      [style.display]="'inline-block'"
      class="text-muted"
      id="resource-path">
  <ng-container
    *ngFor="let path of (resource?.path ? resource.path : []); trackBy: trackByResourcePath; let isFirst = first; let isLast = last">
    <ng-container *ngIf="!isLast">
      <span *ngIf="!isFirst">></span> {{ path.name }}
    </ng-container>
  </ng-container>
</span>
