import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {faCircle} from '@fortawesome/free-solid-svg-icons';
import {CategoryDto} from '@nexnox-web/core-shared';

@Component({
  selector: 'nexnox-web-categories-category-label',
  templateUrl: './category-label.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrgaPortalFeatureCategoryLabelComponent {
  @Input() public category: CategoryDto;
  @Input() public searchTerm: string;

  public faCircle = faCircle;
}
