<p-treeSelect #treeSelectComponent
              (ngModelChange)="onValueChange($event)"
              (onNodeExpand)="onExpand($event)"
              (onShow)="onShow()"
              [disabled]="to.disabled"
              [ngModel]="value$ | async"
              [options]="treeSelectOptions$ | async"
              appendTo="body"
              selectionMode="single">
  <ng-template let-node pTemplate="value">
    <span *ngIf="node">{{ node.data }}</span>
  </ng-template>
</p-treeSelect>
