import {NgModule} from '@angular/core';
import {
  CorePortalCardboxModule,
  CorePortalEntityModule,
  CorePortalRouterOverridesModule
} from '@nexnox-web/core-portal';
import {CoreSharedModule} from '@nexnox-web/core-shared';
import {
  MessageControlIssueDetailComponent,
  MessageIssueDetailComponent,
  MissionIssueDetailComponent,
  TechPortalFeatureIssueListComponent,
  TechPortalFeatureMessageControlIssueListComponent,
  TechPortalFeatureMessageIssueListComponent,
  TechPortalFeatureMissionIssueListComponent,
  TechPortalFeatureTicketIssueListComponent,
  TechPortalFeatureWinterhalterProductOrderIssueListComponent,
  TechPortalFeatureWinterhalterServiceCallIssueListComponent,
  TicketIssueDetailComponent,
  WinterhalterProductOrderIssueDetailComponent,
  WinterhalterServiceCallIssueDetailComponent
} from './containers';
import {issuesServices} from './store';
import {
  MessageControlIssueEditComponent,
  MessageIssueEditComponent,
  MissionIssueEditComponent,
  MissionTicketIssueStatusComponent,
  TicketIssueEditComponent,
  WinterhalterProductOrderIssueEditComponent,
  WinterhalterServiceCallIssueEditComponent
} from "./components";
import {IssueDetailSidebarComponent} from "./sidebars";
import {TechPortalModule} from '@nexnox-web/tech-portal-lib';
import {TechPortalFeatureControlService} from "../../../controls";

@NgModule({
  declarations: [
    TechPortalFeatureIssueListComponent,
    TechPortalFeatureMessageIssueListComponent,
    TechPortalFeatureMessageControlIssueListComponent,
    TechPortalFeatureMissionIssueListComponent,
    TechPortalFeatureTicketIssueListComponent,
    TechPortalFeatureWinterhalterServiceCallIssueListComponent,
    TechPortalFeatureWinterhalterProductOrderIssueListComponent,

    MessageIssueDetailComponent,
    MessageControlIssueDetailComponent,
    MissionIssueDetailComponent,
    TicketIssueDetailComponent,
    WinterhalterServiceCallIssueDetailComponent,
    WinterhalterProductOrderIssueDetailComponent,

    MessageIssueEditComponent,
    MessageControlIssueEditComponent,
    MissionIssueEditComponent,
    TicketIssueEditComponent,
    WinterhalterServiceCallIssueEditComponent,
    WinterhalterProductOrderIssueEditComponent,

    IssueDetailSidebarComponent,
    MissionTicketIssueStatusComponent
  ],
  imports: [
    CoreSharedModule,
    CorePortalEntityModule,
    CorePortalCardboxModule,
    TechPortalModule,
    CorePortalRouterOverridesModule
  ],
  exports: [
    TechPortalFeatureIssueListComponent,
    MissionTicketIssueStatusComponent
  ],
  providers: [
    ...issuesServices,
    TechPortalFeatureControlService
  ]
})
export class IssuesDeclarationsModule {
}
