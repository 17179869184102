import {ChangeDetectionStrategy, Component, EventEmitter, Injector, Input, OnInit, Output} from '@angular/core';
import {
  AppEntityType,
  ControllerOperationId,
  DataTableColumnType,
  DataTableDto,
  DataTableUsage,
  FilterDto,
  mapDatatableFilterToFilter,
  Mappers,
  ResourceDto,
  SortObject
} from '@nexnox-web/core-shared';
import {
  CORE_PORTAL_DATATABLE_STANDARD_CONFIG,
  CorePortalEntityOverviewBaseComponent,
  DatatableLoadPagePayload
} from '@nexnox-web/core-portal';
import {controlResourcesStore} from '../../store';
import {BehaviorSubject, Observable} from 'rxjs';
import {filter, skip, take} from 'rxjs/operators';
import {uniqBy} from 'lodash';
import {faInfoCircle} from '@fortawesome/free-solid-svg-icons/faInfoCircle';

const defaultDataTableDto = {
  pageOperation: ControllerOperationId.ResourceControllerList,
  usage: DataTableUsage.Selector,
  name: 'ControlResources'
};

@Component({
  selector: 'nexnox-web-control-resources-edit',
  templateUrl: './control-resource-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TechPortalFeatureControlResourceEditComponent extends CorePortalEntityOverviewBaseComponent<ResourceDto> implements OnInit {
  @Input() public creating: boolean;
  @Input() public loading: boolean;
  @Output() public datatableChange: EventEmitter<DataTableDto> = new EventEmitter<DataTableDto>();
  public componentId = 'TechPortalFeatureControlResourceEditComponent';
  public title = 'resources.subtitles.resource-list';
  public idProperty = 'resourceId';
  public displayProperty = 'name';
  public pageOperation = ControllerOperationId.ResourceControllerList;
  public enableViews = true;
  public datatableConfigName = 'ResourceDefault';
  public ready$: Observable<any>;
  public datatableConfig$: Observable<DataTableDto>;
  public faInfoCircle = faInfoCircle;
  private readySubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private datatableConfigSubject: BehaviorSubject<DataTableDto> = new BehaviorSubject<DataTableDto>(null);

  constructor(
    protected injector: Injector
  ) {
    super(injector, controlResourcesStore, Mappers.ResourceListDto.serializedName, AppEntityType.Resource);

    // Change default columns here for resource selector of control resources
    this.defaultColumns = ['resourceId', 'name', 'location', 'location.stereotype', 'stereotype'];
  }

  public get dataTable(): DataTableDto {
    return this.datatableConfigSubject.getValue();
  }

  @Input()
  public set dataTable(datatableConfig: DataTableDto) {
    const defaultConfig = this.injector.get(CORE_PORTAL_DATATABLE_STANDARD_CONFIG).ResourceDefault;
    let columns = datatableConfig?.columns;

    if (!columns || columns?.length === 0) {
      columns = this.datatableColumnService.getDataColumns(this.serializedNameOrMapper, defaultConfig.excludedColumns)
        .filter(x => this.defaultColumns.find(y => y === x.name)).map((x, index) => ({
          position: index + 1,
          title: x.name,
          type: DataTableColumnType.ByTransfer,
          sortOrder: null,
          property: x.name
        }));
    }

    this.datatableConfigSubject.next({
      ...(datatableConfig ?? {}),
      columns: columns ?? [],
      filters: datatableConfig?.filters ?? [],
      pageSize: datatableConfig?.pageSize ?? 15,
      ...defaultDataTableDto
    });
  }

  public ngOnInit(): void {
    this.setDefaults();

    this.ready$ = this.readySubject.asObservable().pipe(
      filter(ready => !!ready),
      take(1)
    );

    this.datatableConfig$ = this.datatableConfigSubject.asObservable().pipe(
      filter(config => Boolean(config))
    );

    this.subscribe(this.datatableConfig$.pipe(
      skip(this.creating ? 0 : 1),
      take(1)
    ), () => {
      this.datatableChange.emit(this.dataTable);
      super.ngOnInit();
      setTimeout(() => this.readySubject.next(true));
    });
  }

  // Intended. Don't get entities when the datatable requests it, just when the config changes.
  public onLoadPage(payload: DatatableLoadPagePayload): void {
    super.onLoadPage({ ...payload, filtersChanged: true }); // PH: super function call reactivated but with filtersChanged always true, see Bug 3274
  }

  public onDatatableConfigChange(datatableConfig: DataTableDto): void {
    this.datatableConfigSubject.next(datatableConfig);
    this.datatableChange.emit(this.dataTable);
    setTimeout(() => this.getEntities());
  }

  protected async getEntities(
    page: number = 1,
    sortOption?: SortObject,
    filters?: FilterDto[],
    pageSize?: number,
    append: boolean = false
  ): Promise<void> {
    return super.getEntities(
      page,
      sortOption,
      uniqBy([
        ...(filters ?? []),
        ...(this.dataTable?.filters ?? []).map(x => mapDatatableFilterToFilter(x))
      ], x => x.property),
      this.dataTable?.pageSize ?? pageSize,
      append,
      this.dataTable
    );
  }
}
