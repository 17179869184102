import {NgModule} from '@angular/core';
import {StateDetailComponent, StateListComponent} from './containers';
import {TechPortalFeatureStateEditComponent} from './components';
import {CoreSharedModule} from '@nexnox-web/core-shared';
import {CorePortalEntityModule} from '@nexnox-web/core-portal';
import {statesServices} from './store';

@NgModule({
  declarations: [
    StateListComponent,
    StateDetailComponent,

    TechPortalFeatureStateEditComponent
  ],
  imports: [
    CoreSharedModule,
    CorePortalEntityModule
  ],
  exports: [
    TechPortalFeatureStateEditComponent
  ],
  providers: [
    ...statesServices
  ]
})
export class StatesDeclarationsModule {
}
