import {Plugin, Widget} from 'ckeditor5';

import {SimpleVideoEditing} from './simple-video.editing';
import {SimpleVideoUI} from './simple-video.ui';

export class SimpleVideoPlugin extends Plugin {
  public static get pluginName(): string {
    return 'SimpleVideo';
  }

  public static get requires(): any {
    return [SimpleVideoEditing, SimpleVideoUI, Widget];
  }

  public init(): void {
  }
}
