import {PagedEntitiesXsStore, PagedEntitiesXsStoreState} from '@nexnox-web/core-store';
import {AppEntityType, AppRoleDto} from '@nexnox-web/core-shared';
import {Action, createSelector} from '@ngrx/store';
import {selectContactsState} from '../../contacts.selectors';
import {ContactRoleService} from '../../services';
import {Injectable, Injector} from '@angular/core';

export interface ContactRoleListStoreState extends PagedEntitiesXsStoreState<AppRoleDto> {
}

/* istanbul ignore next */
export const contactRoleListStore = new PagedEntitiesXsStore<AppRoleDto>({
  actionLabel: 'Core Portal - Master Data - Contact - Role List',
  stateSelector: createSelector(selectContactsState, state => state.contactRoleList),
  serviceType: ContactRoleService,
  entityType: AppEntityType.Role,
  selectId: role => role.entity?.appRoleId ?? role.model?.appRoleId
});

export function contactRoleListStoreReducer(state: ContactRoleListStoreState, action: Action): any {
  return contactRoleListStore.reducer(state, action);
}

@Injectable()
export class ContactRoleListStoreEffects extends contactRoleListStore.effects {
  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }
}
