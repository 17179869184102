import {ModuleWithProviders, NgModule} from '@angular/core';
import {PrioritySetsDeclarationsModule} from './priority-sets-declarations.module';
import {PrioritySetsStoreModule} from './priority-sets-store.module';
import {PrioritySetsRoutingModule} from './priority-sets-routing.module';

@NgModule({
  imports: [
    PrioritySetsDeclarationsModule,
    PrioritySetsRoutingModule,
    PrioritySetsStoreModule
  ]
})
export class TechPortalFeatureTicketSettingsPrioritySetsModule {
  public static forFeature(): ModuleWithProviders<PrioritySetsDeclarationsModule> {
    return { ngModule: PrioritySetsDeclarationsModule };
  }
}
