import {NgModule} from '@angular/core';
import {CoreSharedModule} from '@nexnox-web/core-shared';
import {
  CorePortalCardboxModule,
  CorePortalEntityDatatableModule,
  CorePortalEntityModule
} from "@nexnox-web/core-portal";
import {SearchListsDetailComponent, SearchListsListComponent} from "./containers";
import {searchListsServices} from "./store";
import {CorePortalFeatureSettingsSearchListEditComponent} from "./components";
import {DashboardsDeclarationsModule} from "@nexnox-web/core-portal/features/settings/features/dashboards/src/lib/dashboards-declarations.module";
import {ContactsDeclarationsModule} from "@nexnox-web/core-portal/features/master-data/features/contacts/src/lib/contacts-declarations.module";

@NgModule({
  declarations: [
    CorePortalFeatureSettingsSearchListEditComponent,

    SearchListsListComponent,
    SearchListsDetailComponent,
  ],
  imports: [
    CoreSharedModule,
    CorePortalEntityModule,
    CorePortalCardboxModule,
    CorePortalEntityDatatableModule,
    DashboardsDeclarationsModule,
    ContactsDeclarationsModule
  ],
  providers: [
    ...searchListsServices
  ]
})
export class SearchListsDeclarationsModule {
}
