import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {BehaviorSubject, combineLatest, fromEvent, merge, Observable, of, Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged, map, switchMap, take} from 'rxjs/operators';
import {CorePortalCardboxAction} from './cardbox-action.model';
import {MenuItem} from 'primeng/api';
import {faEllipsisV} from '@fortawesome/free-solid-svg-icons/faEllipsisV';
import {NexnoxWebFaIconString, UnsubscribeHelper} from '@nexnox-web/core-shared';
import {faSpinner} from '@fortawesome/free-solid-svg-icons/faSpinner';
import {TranslateService} from '@ngx-translate/core';
import {CorePortalPermissionService} from '../../../../services';
import {IconDefinition} from '@fortawesome/fontawesome-common-types';
import {BindObservable} from 'bind-observable';

interface CardboxHeaderAction {
  label?: string;
  class?: string;
  icon?: IconDefinition;
  tooltip?: string;
  buttonSize?: 'sm' | 'md' | 'lg';

  callback?: (data?: any, event?: MouseEvent, action?: CorePortalCardboxAction) => any;
  dropdownItems?: MenuItem[];
  badgeText?: string;
  badgeClass?: string;
  isLoading?: boolean;
  isDisabled?: boolean;
  shouldShow?: boolean;
}

@Component({
  selector: 'nexnox-web-cardbox-actions',
  templateUrl: './cardbox-actions.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardboxActionsComponent extends UnsubscribeHelper implements OnInit, AfterViewInit, OnDestroy {
  @Input() public useNew: boolean;
  @Input() public hasFlexContent: boolean;
  @Input() public data: any;

  @Input() @BindObservable() public readonly: boolean;
  public readonly$!: Observable<boolean>;
  @ViewChild('actionsContainer', { static: true }) public actionsContainer: ElementRef<HTMLDivElement>;
  public actions$: Observable<CardboxHeaderAction[]>;
  public actionsAsDropdownItems$: Observable<MenuItem[]>;
  public showMenuButton$: Observable<boolean>;
  public minWidth = 39.98;
  public maxHeight = 39.98;
  public faSpinner = faSpinner;
  public faEllipsisV = faEllipsisV;
  public trackActionsBy: any;
  private actionsSubject: BehaviorSubject<CorePortalCardboxAction[]> = new BehaviorSubject<CorePortalCardboxAction[]>([]);
  private updateSubject: Subject<void> = new Subject<void>();

  constructor(
    private changeDetector: ChangeDetectorRef,
    private permissionService: CorePortalPermissionService,
    private translate: TranslateService
  ) {
    super();

    this.trackActionsBy = (index: number, item: CardboxHeaderAction) => index;
  }

  public get actions(): CorePortalCardboxAction[] {
    return this.actionsSubject.getValue();
  }

  @Input()
  public set actions(actions: CorePortalCardboxAction[]) {
    this.actionsSubject.next(actions);
  }

  /* istanbul ignore next */
  public ngOnInit(): void {
    this.actions$ = this.actionsSubject.asObservable().pipe(
      switchMap(actions => combineLatest([of(null), ...actions.map(action => of(action).pipe(
        switchMap(() => this.shouldShow(action).pipe(
          switchMap(shouldShow => (action.isDisabled ? action.isDisabled(this.data, action) : of(false)).pipe(
            switchMap(isDisabled => (action.isLoading ? action.isLoading(this.data, action) : of(false)).pipe(
              switchMap(isLoading => (action.badgeText ? action.badgeText(this.data, action) : of(null)).pipe(
                switchMap(badgeText => (action.dropdownItems ? action.dropdownItems(this.data, action) : of(null)).pipe(
                  map(dropdownItems => ({
                    label: action.label,
                    class: action.class,
                    icon: action.icon,
                    tooltip: action.tooltip,
                    buttonSize: action.buttonSize ?? 'md',

                    shouldShow,
                    isDisabled: isDisabled || isLoading,
                    isLoading,
                    badgeText,
                    badgeClass: action.badgeClass,
                    dropdownItems: dropdownItems?.map(dropdownItem => ({
                      ...dropdownItem,
                      command: event => {
                        if (dropdownItem.command) dropdownItem.command(event);
                        this.updateItems();
                      }
                    })),
                    callback: action.callback ? ((data, event) => {
                      action.callback(data, event, action);
                      this.updateItems();
                    }) : null
                  } as CardboxHeaderAction))
                ))
              ))
            ))
          ))
        ))
      ))])),
      map(actions => actions.filter(action => action)),
      distinctUntilChanged()
    );

    this.actionsAsDropdownItems$ = this.actions$.pipe(
      map(actions => actions.map(action => {
        const menuItem: MenuItem = {
          label: this.translate.instant(action.label ?? action.tooltip),
          icon: action.isLoading ? NexnoxWebFaIconString.transformIcon(faSpinner, 'fa-spin') :
            NexnoxWebFaIconString.transformIcon(action.icon),
          visible: action.shouldShow,
          disabled: action.isDisabled,
          command: event => {
            if (action.callback) action.callback(this.data, event);
            this.updateItems();
          }
        };

        if (action.badgeText) {
          menuItem.label = `${ menuItem.label } (${ action.badgeText })`;
        }

        if (action.dropdownItems) {
          menuItem.items = action.dropdownItems.map(x => ({
            ...x,
            command: event => {
              if (x.command) x.command(event);
              this.updateItems();
            }
          }));

          if (!action.dropdownItems.length) menuItem.visible = false;
        }

        return menuItem;
      }))
    );

    this.showMenuButton$ = merge(
      this.updateSubject.asObservable(),
      fromEvent(window, 'resize').pipe(
        debounceTime(400),
        distinctUntilChanged()
      )
    ).pipe(
      map(() => Boolean(this.actionsContainer.nativeElement.offsetTop))
    );

    this.subscribe(this.readonly$.pipe(distinctUntilChanged()), () => setTimeout(() => this.updateSubject.next()));

    this.subscribe(this.translate.onLangChange.pipe(
      distinctUntilChanged((a, b) => a.lang === b.lang)
    ), () => this.actionsSubject.next(this.actionsSubject.getValue()));
  }

  public ngAfterViewInit(): void {
    setTimeout(() => this.updateSubject.next());
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();

    this.actionsSubject.complete();

    this.updateSubject.next();
    this.updateSubject.complete();
  }

  private shouldShow(action: CorePortalCardboxAction): Observable<boolean> {
    if (action.shouldShow) {
      return action.shouldShow(this.data, action).pipe(
        switchMap(shouldShow => {
          if (action.permission) {
            return this.permissionService.hasPermission$(action.permission).pipe(
              take(1),
              map(hasPermission => shouldShow && hasPermission)
            );
          }

          return of(shouldShow);
        })
      );
    }

    return of(true);
  }

  private updateItems(): void {
    setTimeout(() => {
      this.actionsSubject.next(this.actionsSubject.getValue());
      this.changeDetector.detectChanges();
      setTimeout(() => this.updateSubject.next());
    });
  }
}
