<ng-template #entityEditTemplate let-title="title">
  <nexnox-web-templates-text-template-edit #editComponent
                                           (modelChange)="createModel$.next($event)"
                                           [creating]="true"
                                           [loading]="(loaded$ | async) === false"
                                           [model]="createModel$ | async"
                                           [readonly]="false"
                                           [stereotypes$]="stereotypes$"
                                           [title]="title"
                                           [usageType]="templateUsageType">
  </nexnox-web-templates-text-template-edit>
</ng-template>

<nexnox-web-entity-overview #overviewComponent
                            [detailLink]="detailLink"
                            [entityEditTemplate]="entityEditTemplate"
                            [entityOverviewBaseComponent]="this"
                            [module]="module"
                            [showSettings]="false">
</nexnox-web-entity-overview>
