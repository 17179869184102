<nexnox-web-entity-edit [entityEditBaseComponent]="this"
                        [stereotyped]="false"
                        displayKey="name">
</nexnox-web-entity-edit>

<nexnox-web-settings-stereotypes-custom-property-sets-edit #customPropertySetsEditComponent
                                                           (modelChange)="onCustomPropertySetsChange($event)"
                                                           [customPropertyDropdownOptions]="customPropertyDropdownOptions"
                                                           [loading]="loading"
                                                           [model]="model"
                                                           [readonly]="readonly">
</nexnox-web-settings-stereotypes-custom-property-sets-edit>
