import {InjectionToken, NgModule} from '@angular/core';
import {ActionReducerMap, StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {escalationLevelsEffects, escalationLevelsReducers} from './store';

export const REDUCER_TOKEN = new InjectionToken<ActionReducerMap<any>>(
  '@nexnox-web/tech-portal/ticket-settings/escalation-levels: Store Reducers'
);

@NgModule({
  imports: [
    StoreModule.forFeature('tech-portal-feature-ticket-settings-escalation-levels', REDUCER_TOKEN),
    EffectsModule.forFeature(escalationLevelsEffects)
  ],
  providers: [
    {
      provide: REDUCER_TOKEN,
      useValue: escalationLevelsReducers
    }
  ]
})
export class EscalationLevelsStoreModule {
}
