import {ChangeDetectionStrategy, Component, Injector} from '@angular/core';
import {
  ActionButton,
  CorePortalEntityOverviewBaseComponent,
  DatatableActionButton,
  DeleteEntityModel
} from '@nexnox-web/core-portal';
import {AppEntityType, AppPermissions, Mappers, SearchListListDto} from '@nexnox-web/core-shared';
import {searchListsListStore} from "@nexnox-web/core-portal/features/settings/features/search-lists/src/lib/store";


@Component({
  selector: 'nexnox-web-settings-searchlists-searchlist-list',
  templateUrl: 'search-lists-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchListsListComponent extends CorePortalEntityOverviewBaseComponent<SearchListListDto> {
  public title = 'core-portal.settings.subtitles.search-list-list';
  public idProperty = 'searchListId';
  public displayProperty = 'title';
  public datatableConfigName = 'SettingsSearchListsDefault';
  public componentId = 'SearchListsListComponent';

  constructor(
    protected override injector: Injector
  ) {
    // @ts-ignore
    super(injector, searchListsListStore, Mappers.SearchListListDto.serializedName, AppEntityType.None);
  }

  public getRowActionButtons(): DatatableActionButton[] {
    return this.getDefaultRowActionButtons(
      'core-portal.settings.actions.edit-search-list',
      (row: SearchListListDto) => `search-lists/${ row.searchListId }`,
      [AppPermissions.UpdateSearchList],
      {
        module: 'settings'
      }
    );
  }

  public getDeleteEntityModel(): DeleteEntityModel {
    return {
      titleKey: 'core-portal.settings.actions.delete-search-list',
      descriptionKey: 'core-portal.settings.descriptions.delete-search-list',
      confirmKey: 'core-portal.settings.actions.delete-search-list',
      deletePermission: AppPermissions.DeleteSearchList
    };
  }

  protected async getActionButtons(): Promise<ActionButton[]> {
    return this.getDefaultActionButtons('core-portal.settings.actions.create-search-list', AppPermissions.CreateSearchList);
  }
}
