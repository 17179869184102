import {
  BaseXsStore,
  BaseXsStoreReducerTypes,
  PagedEntitiesXsStore,
  PagedEntitiesXsStoreActions,
  pagedEntitiesXsStoreSetLoadingForId,
  PagedEntitiesXsStoreState,
  PropsAction
} from "@nexnox-web/core-store";
import {OccurredHintListDto} from "@nexnox-web/core-shared";
import {createAction, on, props} from "@ngrx/store";
import {immerOn} from "ngrx-immer/store";
import {LocalOccurredHintListDto} from "@nexnox-web/tech-portal/features/resources/src/lib/store/stores/resource-occurred-hints/resource-occurred-hints.store";

export interface ResourceOccurredHintListXsStoreDeletePayload {
  occurredHintId: number | string;
  hintCodeId: number | string;
}

export interface ResourceOccurredHintsListXsStoreState extends PagedEntitiesXsStoreState<OccurredHintListDto, LocalOccurredHintListDto> {
}

export interface ResourceOccurredHintsListXsStoreActions extends PagedEntitiesXsStoreActions<OccurredHintListDto, LocalOccurredHintListDto> {
  deleteOccurredHint: PropsAction<ResourceOccurredHintListXsStoreDeletePayload>;
  deleteOccurredHintSuccess: PropsAction<ResourceOccurredHintListXsStoreDeletePayload>;
}

export class ResourceOccurredHintsListXsStore extends PagedEntitiesXsStore<OccurredHintListDto, LocalOccurredHintListDto, ResourceOccurredHintsListXsStoreState> {

  public actions: ResourceOccurredHintsListXsStoreActions;

  protected createActions(label: string): ResourceOccurredHintsListXsStoreActions {
    return {
      ...super.createActions(label),

      deleteOccurredHint: createAction(
        BaseXsStore.getType(label, 'Delete Occurred Hint'),
        props<ResourceOccurredHintListXsStoreDeletePayload>()
      ),
      deleteOccurredHintSuccess: createAction(
        BaseXsStore.getType(label, 'Delete Occurred Hint success'),
        props<ResourceOccurredHintListXsStoreDeletePayload>()
      )
    };
  }

  protected createReducerArray(
    initialState: ResourceOccurredHintsListXsStoreState
  ): BaseXsStoreReducerTypes<ResourceOccurredHintsListXsStoreState, ResourceOccurredHintsListXsStoreActions>[] {
    return [
      ...super.createReducerArray(initialState),

      immerOn(this.actions.deleteOccurredHint, (draft, { occurredHintId }) => {
        draft.entityData = pagedEntitiesXsStoreSetLoadingForId(draft.entityData, occurredHintId, {
          deleteOccurredHint: true
        });
      }),

      on(this.actions.deleteOccurredHintSuccess, (state,
                                                  { hintCodeId }) => this.adapter.removeOne(hintCodeId.toString(), {
        ...state,
        paging: {
          ...state.paging,
          totalItems: (state.paging?.totalItems ?? 1) - 1
        },
        entityData: pagedEntitiesXsStoreSetLoadingForId(state.entityData, hintCodeId, {
          deleteOccurredHint: false
        }),
        hasError: false
      }))
    ];
  }
}
