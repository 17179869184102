import {
  BaseXsStore,
  BaseXsStoreReducerTypes,
  EmptyAction,
  EntityXsStore,
  EntityXsStoreActions,
  EntityXsStoreEffects,
  entityXsStoreSetLoadingForAction,
  EntityXsStoreState
} from '@nexnox-web/core-store';
import {AppEntityType, CoreSharedApiBaseService, InfoDto} from '@nexnox-web/core-shared';
import {Action, createAction, select} from '@ngrx/store';
import {Injectable, Injector, Type} from '@angular/core';
import {createEffect, ofType} from '@ngrx/effects';
import {catchError, exhaustMap, map, tap, withLatestFrom} from 'rxjs/operators';
import {OrgaPortalFeatureInfoService} from '../../services';
import {of} from 'rxjs';
import {immerOn} from 'ngrx-immer/store';

export interface InfoDetailXsStoreState extends EntityXsStoreState<InfoDto> {
}

export interface InfoDetailXsStoreActions extends EntityXsStoreActions<InfoDto> {
  push: EmptyAction;
  pushSuccess: EmptyAction;
}

export class InfoDetailXsStore extends EntityXsStore<InfoDto, InfoDto, InfoDetailXsStoreState> {
  public actions: InfoDetailXsStoreActions;

  protected createActions(label: string): InfoDetailXsStoreActions {
    return {
      ...super.createActions(label),

      push: createAction(BaseXsStore.getType(label, 'Push')),
      pushSuccess: createAction(BaseXsStore.getType(label, 'Push success'))
    };
  }

  protected createReducerArray(
    initialState: InfoDetailXsStoreState
  ): BaseXsStoreReducerTypes<InfoDetailXsStoreState, InfoDetailXsStoreActions>[] {
    return [
      ...super.createReducerArray(initialState),

      immerOn(this.actions.push, draft => {
        draft.entityData = entityXsStoreSetLoadingForAction(draft.entityData, {
          push: true
        });
      }),
      immerOn(this.actions.pushSuccess, draft => {
        draft.entityData = entityXsStoreSetLoadingForAction(draft.entityData, {
          push: false
        });
      })
    ];
  }

  protected createEffects(
    serviceType: Type<CoreSharedApiBaseService>,
    entityType: AppEntityType,
    prepareEntity: (entity: InfoDto) => InfoDto,
    prepareModel: (entity: InfoDto, model: InfoDto) => InfoDto,
    sanitizeModel: (model: InfoDto, entity: InfoDto) => InfoDto,
    ...args
  ): Type<EntityXsStoreEffects<InfoDto, InfoDto, InfoDetailXsStoreState>> {
    const actions = this.actions;
    const selectors = this.selectors;

    @Injectable()
    class Effects extends EntityXsStoreEffects<InfoDto> {
      public push$: any;
      public pushSuccess$: any;

      protected actions: InfoDetailXsStoreActions;
      protected service: OrgaPortalFeatureInfoService;

      constructor(
        protected injector: Injector
      ) {
        super(injector, actions, selectors, serviceType, entityType, prepareEntity, prepareModel, sanitizeModel, false, false);
      }

      protected createEffects(): void {
        super.createEffects();

        this.push$ = createEffect(() => this.actions$.pipe(
          ofType(this.actions.push),
          withLatestFrom(this.store.pipe(select(this.selectors.selectEntity))),
          exhaustMap(([_, entity]) => this.service.push(entity.infoId).pipe(
            map(() => this.actions.pushSuccess()),
            catchError(error => of(this.actions.error({ error, action: this.actions.push })))
          ))
        ));

        this.pushSuccess$ = createEffect(() => this.actions$.pipe(
          ofType(this.actions.pushSuccess),
          tap(action => this.actionCallback(action, false))
        ), { dispatch: false });
      }

      protected actionCallback(action: Action, isError: boolean): void {
        super.actionCallback(action, isError);

        this.checkAction(this.actions.pushSuccess, action, () => this.pushSuccess());
      }

      protected pushSuccess(): void {
        this.apiNotificationService.showTranslatedSuccess('orga-portal.infos.toasts.info-pushed');
      }
    }

    return Effects;
  }
}
