import {
  SolutionMissionService,
  TicketEventService,
  TicketMissionPreviewService,
  TicketMissionService,
  TicketStatemachineService
} from './services';
import {
  TicketAuditStoreEffects,
  ticketAuditStoreReducer,
  ticketDetailStore,
  TicketDetailStoreEffects,
  TicketEventsStoreEffects,
  ticketEventsStoreReducer,
  ticketListStore,
  TicketMissionDetailStoreEffects,
  ticketMissionDetailStoreReducer,
  TicketMissionsStoreEffects,
  ticketMissionsStoreReducer,
  TicketSolutionStoreEffects,
  ticketSolutionStoreReducer
} from './stores';

export * from './services';
export * from './stores';

export * from './tickets.state';
export * from './tickets.selectors';

export const ticketsReducers = {
  ticketList: ticketListStore.reducer,
  ticketDetail: ticketDetailStore.reducer,

  ticketSolution: ticketSolutionStoreReducer,
  ticketEvents: ticketEventsStoreReducer,
  ticketAudit: ticketAuditStoreReducer,
  ticketMissions: ticketMissionsStoreReducer,
  ticketMissionDetail: ticketMissionDetailStoreReducer
};

export const ticketsEffects = [
  ticketListStore.effects,
  TicketDetailStoreEffects,

  TicketSolutionStoreEffects,
  TicketEventsStoreEffects,
  TicketAuditStoreEffects,
  TicketMissionsStoreEffects,
  TicketMissionDetailStoreEffects
];

export const ticketsServices = [
  TicketMissionPreviewService,
  TicketEventService,
  TicketMissionService,
  SolutionMissionService,
  TicketStatemachineService,
  TicketMissionService
];
