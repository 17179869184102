import {ChangeDetectionStrategy, Component, Injector} from '@angular/core';
import {CorePortalEntityOverviewBaseComponent, DatatableActionButton} from '@nexnox-web/core-portal';
import {AppEntityType, AppPermissions, ControllerOperationId, Mappers, MissionDto} from '@nexnox-web/core-shared';
import {locationMissionListStore} from '../../store';

@Component({
  selector: 'nexnox-web-master-data-locations-location-mission-list',
  templateUrl: './location-mission-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LocationMissionListComponent extends CorePortalEntityOverviewBaseComponent<MissionDto> {
  public title = 'missions.subtitles.missions';
  public idProperty = 'missionId';
  public displayProperty = 'title';
  public pageOperation = ControllerOperationId.MissionControllerList;
  public datatableConfigName = 'MissionDefault';
  public componentId = 'LocationMissionListComponent';

  constructor(
    protected injector: Injector
  ) {
    super(injector, locationMissionListStore, Mappers.MissionCompleteDto.serializedName, AppEntityType.Mission);
  }

  public getRowActionButtons(): DatatableActionButton[] {
    return this.getDefaultRowActionButtons(
      'missions.actions.edit-mission',
      mission => `missions/${ mission.missionId }`,
      [AppPermissions.UpdateMission],
      {
        module: 'inventory'
      }
    );
  }
}
