import {NgModule} from '@angular/core';
import {
  CorePortalAttachmentsModule,
  CorePortalEntityModule,
  CorePortalHierarchicalModule,
  CorePortalModule,
  CorePortalRouterOverridesModule,
  CorePortalRuleEditorListModule
} from '@nexnox-web/core-portal';
import {CorePortalFeatureAuditModule} from '@nexnox-web/core-portal/features/audit';
import {CorePortalFeatureMasterDataContactsModule} from '@nexnox-web/core-portal/features/master-data/features/contacts';
import {CorePortalFeatureMasterDataLocationGroupsModule} from '@nexnox-web/core-portal/features/master-data/features/location-groups';
import {CorePortalFeatureMasterDataLocationsModule} from '@nexnox-web/core-portal/features/master-data/features/locations';
import {
  CorePortalFeatureResourcesDeclarationsModule,
  CorePortalFeatureResourcesModule
} from '@nexnox-web/core-portal/features/resources';
import {CorePortalInventoryNumbersModule} from '@nexnox-web/core-portal/features/settings/features/inventory-numbers';
import {CoreSharedModule, CoreSharedSortableListModule} from '@nexnox-web/core-shared';
import {TechPortalModule} from '@nexnox-web/tech-portal-lib';
import {TechPortalFeatureMissionsModule} from '@nexnox-web/tech-portal/features/missions';
import {TechPortalFeatureTicketSettingsEscalationLevelsModule} from '@nexnox-web/tech-portal/features/ticket-settings/features/escalation-levels';
import {TechPortalFeatureTicketSettingsPrioritySetsModule} from '@nexnox-web/tech-portal/features/ticket-settings/features/priority-sets';
import {TechPortalFeatureTicketSettingsStatesModule} from '@nexnox-web/tech-portal/features/ticket-settings/features/states';
import {ResizableModule} from 'angular-resizable-element';
import {
  EditorSuggestionsComponent,
  EscalationRulesComponent,
  ResourceArticlesComponent,
  ResourceEventListComponent,
  ResourceEventListItemComponent,
  ResourceIssueTemplatesComponent,
  ResourceQuickAccessComponent,
  TechPortalFeatureFeatureResourceEditComponent
} from './components';
import {
  ResourceAuditComponent,
  ResourceConnectedComponent,
  ResourceControlProtocolComponent,
  ResourceControlProtocolConsumptionsComponent,
  ResourceControlProtocolErrorsComponent,
  ResourceDetailComponent,
  ResourceIssuesComponent,
  ResourceMissionsComponent,
  ResourceOccurredConsumptionsComponent,
  ResourceOccurredErrorsComponent,
  ResourceOccurredHintsComponent,
  ResourcesComponent,
  ResourceTicketsComponent,
  ResourceTreeViewComponent,
  TechPortalFeatureResourceListComponent,
} from './containers';
import {
  InheritanceManufacturerModelService,
  InheritsQuickAccessPreviewService,
  InheritsSuggestionsPreviewService,
  ResourceInventoryNumberPreviewService,
  ResourcePreviewService,
  resourcesServices
} from './store';
import {
  EventListSidebarComponent,
  ResourceChangeStateSidebarComponent,
  SafeSidebarComponent,
  SuggestionEditSidebarComponent
} from "./sidebars";
import {OrgaPortalFeatureKnowledgePathsModule} from "@nexnox-web/orga-portal/features/knowledge/paths";
import {IssueTemplatesSortableListEditComponent} from "./sortable-lists";

import {CorePortalFeatureIssueTemplatesModule} from "@nexnox-web/core-portal/features/settings/features/issue-templates";
import {MultiSelectModule} from "primeng/multiselect";
import {TechPortalFeatureTicketSettingsProcessService} from "@nexnox-web/tech-portal/features/ticket-settings/features/processes";

@NgModule({
  declarations: [
    ResourcesComponent,
    ResourceTreeViewComponent,
    ResourceDetailComponent,
    TechPortalFeatureResourceListComponent,
    ResourceAuditComponent,
    ResourceEventListItemComponent,
    ResourceEventListComponent,
    ResourceTicketsComponent,
    ResourceIssuesComponent,
    ResourceMissionsComponent,
    ResourceConnectedComponent,
    ResourceOccurredConsumptionsComponent,
    ResourceOccurredErrorsComponent,
    ResourceOccurredHintsComponent,
    ResourceArticlesComponent,
    ResourceIssueTemplatesComponent,
    ResourceQuickAccessComponent,

    ResourceControlProtocolComponent,
    ResourceControlProtocolErrorsComponent,
    ResourceControlProtocolConsumptionsComponent,

    EscalationRulesComponent,
    EditorSuggestionsComponent,

    ResourceChangeStateSidebarComponent,
    SuggestionEditSidebarComponent,
    EventListSidebarComponent,
    SafeSidebarComponent,
    IssueTemplatesSortableListEditComponent,

    TechPortalFeatureFeatureResourceEditComponent
  ],
  imports: [
    CoreSharedModule,
    CorePortalEntityModule,
    ResizableModule,
    CorePortalHierarchicalModule,
    CorePortalRuleEditorListModule,
    CorePortalAttachmentsModule,
    CoreSharedSortableListModule,

    CorePortalFeatureResourcesModule.forFeature(),
    CorePortalFeatureMasterDataLocationsModule.forFeature(),
    CorePortalFeatureMasterDataLocationGroupsModule.forFeature(),
    CorePortalFeatureMasterDataContactsModule.forFeature(),
    CorePortalFeatureAuditModule,
    TechPortalModule,
    TechPortalFeatureTicketSettingsPrioritySetsModule.forFeature(),
    TechPortalFeatureTicketSettingsStatesModule.forFeature(),
    TechPortalFeatureTicketSettingsEscalationLevelsModule.forFeature(),
    TechPortalFeatureMissionsModule.forFeature(),
    CorePortalInventoryNumbersModule.forFeature(),
    CorePortalFeatureIssueTemplatesModule.forFeature(),
    OrgaPortalFeatureKnowledgePathsModule.forFeature(),
    CorePortalRouterOverridesModule,
    MultiSelectModule,
    CorePortalModule
  ],
  exports: [
    CorePortalFeatureResourcesDeclarationsModule,

    TechPortalFeatureFeatureResourceEditComponent,
    ResourceEventListItemComponent
  ],
  providers: [
    ...resourcesServices,
    TechPortalFeatureTicketSettingsProcessService,

    // Monitor services
    ResourcePreviewService,
    ResourceInventoryNumberPreviewService,
    InheritanceManufacturerModelService,
    InheritsSuggestionsPreviewService,
    InheritsQuickAccessPreviewService
  ]
})
export class ResourcesDeclarationsModule {
}
