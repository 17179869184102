import {AfterViewInit, Directive, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output} from "@angular/core";
import {Subject} from "rxjs";
import {delay, filter} from "rxjs/operators";


interface IntersectionSubject {
  entry: IntersectionObserverEntry;
  observer: IntersectionObserver;
}

@Directive({
  selector: '[nexnoxWebVisibleObserver]'
})
export class VisibleObserverDirective implements OnDestroy, OnInit, AfterViewInit {
  @Input() public debounceTime = 0;
  @Input() public threshold = 0.2;
  @Output() public visible: EventEmitter<boolean> = new EventEmitter<boolean>();

  private options: IntersectionObserverInit = {
    threshold: this.threshold,
    rootMargin: '0px'
  };

  private observer: IntersectionObserver | null = null;
  private subject$: Subject<any> = new Subject<any>();

  constructor(private element: ElementRef) {
  }

  public ngOnInit(): void {
    this.createObserver();
  }

  public ngAfterViewInit(): void {
    this.startObservingElements();
  }

  public ngOnDestroy(): void {
    this.observer?.disconnect();

    this.subject$.next({} as any);
    this.subject$.complete();
  }

  private createObserver(): void {
    this.observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (this.isIntersecting(entry)) {
          this.subject$.next({ entry, observer });
        }
      });
    }, this.options);
  }

  private isIntersecting(entry: IntersectionObserverEntry): boolean {
    return entry.isIntersecting || entry.intersectionRatio > 0;
  }

  private startObservingElements(): void {
    if (this.observer) {
      this.observer.observe(this.element.nativeElement);

      this.subject$
        .pipe(delay(this.debounceTime), filter(Boolean))
        .subscribe(async (subject: IntersectionSubject | any): Promise<void> => {
          const target: HTMLElement | null | undefined = subject.entry?.target;

          if (target) {
            (await this.isVisible(target))
              ? this.visible.emit(true)
              : this.visible.emit(false);
          }
        });
    }
  }

  private isVisible(element: HTMLElement): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      const observer: IntersectionObserver = new IntersectionObserver(
        ([entry]) => {
          resolve(entry.intersectionRatio >= this.threshold);
        }, this.options
      );

      if (element) {
        observer?.observe(element);
      }
    });
  }
}
