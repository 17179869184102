<div class="mb-4">
  <nexnox-web-resources-control-protocol-consumptions [canDelete]="false"
                                                      [getEntityOnModeChange]="false"
                                                      [isEmbedded]="true"
                                                      [parentIds]="parentIds">
  </nexnox-web-resources-control-protocol-consumptions>
</div>
<nexnox-web-resources-control-protocol-errors [canDelete]="false"
                                              [getEntityOnModeChange]="false"
                                              [isEmbedded]="true"
                                              [parentIds]="parentIds">
</nexnox-web-resources-control-protocol-errors>
