import {Injectable, Injector} from '@angular/core';
import {CorePortalEmbeddedEntityBaseService} from '@nexnox-web/core-portal';
import {CoreSharedApiBaseService} from '@nexnox-web/core-shared';

@Injectable()
export class ContactService extends CoreSharedApiBaseService {
  constructor(
    protected injector: Injector
  ) {
    super(injector, null, 'contact');
  }
}

@Injectable()
export class CompanyContactService extends CorePortalEmbeddedEntityBaseService {
  constructor(
    protected injector: Injector
  ) {
    super(injector, ContactService, null, 'contact', ['company']);
  }
}
