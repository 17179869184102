<p-sidebar #sidebarComponent
           (visibleChange)="onVisibleChange($event)"
           [transitionOptions]="transitionOptions"
           [visible]="visible$ | async"
           appendTo="body"
           position="right"
           styleClass="p-sidebar-md">
  <div *ngIf="(loading$ | async) === false; else loadingTemplate"
       class="d-flex align-content-between flex-column h-100">

    <h4 class="pl-2">{{ 'core-shared.shared.fields.receivers' | translate }}</h4>

    <tabset class="receiver-tab">
      <tab (selectTab)="updateTab(tab.type)"
           *ngFor="let tab of receiverTabs; let isLast = last"
           [customClass]="(isLast ? 'is-last ' : '')"
           [id]="tab.type">
        <ng-template tabHeading>
          <span class="heading">
            {{ getTranslationField(tab.type) | translate }}
          </span>
        </ng-template>
      </tab>
    </tabset>

    <form [formGroup]="form" class="w-100 mt-1">
      <formly-form (modelChange)="onModelChange($event)"
                   [(model)]="model"
                   [fields]="fields"
                   [form]="form"
                   class="row w-100">
      </formly-form>
    </form>

    <div class="px-2 overflow-y-auto my-4">
      <ng-container *ngFor="let field of receivers | keyvalue; let first = first">
        <ng-container *ngIf="field.value?.length > 0">
          <h5 [ngClass]="!first ? 'mt-4' : ''">
            {{ getTranslationField(field.key) | translate }}
            <span> ({{ field.value.length }})</span>
          </h5>
          <ng-container *ngFor="let item of field.value">
            <div class="form-control d-flex justify-space-between">
              <div class="flex-shrink-1 text-truncate">
                <a (click)="navigateTo(item.url)"
                   class="link cursor-pointer">
                  {{ item.name }}
                </a>
              </div>
              <div (click)="removeOne(item, field.key)" class="cursor-pointer px-1">
                <fa-icon [icon]="faTimes" size="sm"></fa-icon>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>

    <div class="w-100 d-flex flex-shrink-1 gap-2 justify-content-end mt-auto mb-0">
      <button (click)="removeAll()"
              [disabled]="isRemoveButtonDisabled"
              [label]="'core-shared.shared.actions.remove-all' | translate"
              class="p-button p-button-text p-button-secondary"
              pButton
              type="button">
      </button>
      <button (click)="onAssignReceivers()"
              [disabled]="!form.valid"
              [icon]="faUser | nexnoxWebFaIconStringPipe"
              [label]="'core-shared.shared.actions.accept' | translate"
              class="p-button p-button-primary"
              pButton
              type="button">
      </button>

    </div>
  </div>
</p-sidebar>

<!-- Location Templates -->
<ng-template #locationSelectLabelTitleTemplate let-item="item">
  <nexnox-web-core-shared-location-number [label]="true"
                                          [location]="item">
  </nexnox-web-core-shared-location-number>
</ng-template>

<ng-template #locationSelectOptionTitleTemplate let-item="item" let-searchTerm="searchTerm">
  <nexnox-web-core-shared-location-number [label]="false"
                                          [location]="item"
                                          [searchTerm]="searchTerm">
  </nexnox-web-core-shared-location-number>
</ng-template>

<ng-template #loadingTemplate>
  <nexnox-web-loading-icon class="d-flex w-100 h-100 align-items-center justify-content-center flex-column p-5"
                           size="5x">
  </nexnox-web-loading-icon>
</ng-template>
