import {CoreSharedApiBaseService, Product} from '@nexnox-web/core-shared';
import {Injectable, Injector} from '@angular/core';
import {map} from "rxjs/operators";
import {Observable} from "rxjs";

@Injectable()
export class TechPortalFeatureResourceOccurredErrorsService extends CoreSharedApiBaseService {
  constructor(
    protected injector: Injector
  ) {
    super(injector, Product.TECH, 'occurredError', ['resource']);
  }

  public deleteOccurredError<T>(occurredErrorId: string | number): Observable<T> {
    return this.httpRequestService.delete<T>(`v2/occurrederror/${ occurredErrorId }`).pipe(
      map(response => response.body)
    );
  }
}
