<ng-template #entityEditTemplate let-title="title">
  <nexnox-web-contracts-contract-edit #editComponent
                                      (modelChange)="createModel$.next($event)"
                                      [creating]="true"
                                      [loading]="(loaded$ | async) === false"
                                      [model]="createModel$ | async"
                                      [readonly]="false"
                                      [stereotypes$]="stereotypes$"
                                      [title]="title">
  </nexnox-web-contracts-contract-edit>
</ng-template>

<nexnox-web-entity-overview #overviewComponent
                            [entityEditTemplate]="entityEditTemplate"
                            [entityOverviewBaseComponent]="this"
                            detailLink="/contracts"
                            module="inventory">
</nexnox-web-entity-overview>
