import {Component} from '@angular/core';
import {ToasterConfig} from 'angular2-toaster';
import {Title} from '@angular/platform-browser';
import {environment} from '../environments/environment';

@Component({
  selector: 'nexnox-web-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  public toasterConfig: ToasterConfig = new ToasterConfig({
    positionClass: 'toast-bottom-left',
    mouseoverTimerStop: true,
    newestOnTop: false,
    timeout: {
      error: 0,
      success: 2000,
      warning: 2000,
      info: 2000,
      wait: 2000
    }
  });

  constructor(
    private titleService: Title
  ) {
    this.titleService.setTitle(environment.title);
  }
}
