import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import {HierarchicalTreeViewItem} from '../../models';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {ResourceState, ResourceType, UnsubscribeHelper} from '@nexnox-web/core-shared';
import {faSpinner} from '@fortawesome/free-solid-svg-icons/faSpinner';
import {faPlus} from '@fortawesome/free-solid-svg-icons/faPlus';
import {faHashtag, faHouse} from '@fortawesome/free-solid-svg-icons';
import {resourceStateEnumOptions} from "@nexnox-web/tech-portal/features/resources/src/lib/models";

@Component({
  selector: 'nexnox-web-hierarchical-tree-view-item',
  templateUrl: './hierarchical-tree-view-item.component.html',
  styleUrls: ['./hierarchical-tree-view-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HierarchicalTreeViewItemComponent extends UnsubscribeHelper implements OnInit, AfterViewInit, OnDestroy {
  @Input() public item: HierarchicalTreeViewItem;
  @Input() public nestLevel: number;
  @Input() public parent: HierarchicalTreeViewItemComponent;
  @Input() public boundingElement: HTMLElement;
  @Input() public itemSelectedEvent: Observable<HierarchicalTreeViewItem>;
  @Input() public canSelectItem: () => Promise<void>;
  @Input() public disabled: boolean;

  @Output() public selectItem: EventEmitter<HierarchicalTreeViewItemComponent> = new EventEmitter<HierarchicalTreeViewItemComponent>();
  @Output() public expandItem: EventEmitter<HierarchicalTreeViewItemComponent> = new EventEmitter<HierarchicalTreeViewItemComponent>();
  @Output() public moreItem: EventEmitter<HierarchicalTreeViewItemComponent> = new EventEmitter<HierarchicalTreeViewItemComponent>();

  @ViewChildren('hierarchicalTreeViewItemComponent') public children: QueryList<HierarchicalTreeViewItemComponent>;

  public isExpanded = new BehaviorSubject<boolean>(false);
  public isSelected = new BehaviorSubject<boolean>(false);
  public isElementVisible: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public itemChildren$: Observable<HierarchicalTreeViewItem[]>;
  public itemChildrenLoading$: Observable<boolean>;
  public itemChildrenNextLoading$: Observable<boolean>;

  public resourceState = ResourceState;
  public resourceType = ResourceType;

  public faSpinner = faSpinner;
  public faPlus = faPlus;
  public faHouse = faHouse;
  public faHashtag = faHashtag;

  private intersectionObserver: IntersectionObserver;
  private _treeViewItemElement: ElementRef;

  @ViewChild('treeViewItemElement')
  public set treeViewItemElement(treeViewItemElement: ElementRef) {
    this._treeViewItemElement = treeViewItemElement;
  }

  public ngOnInit(): void {
    if (this.item && this.item.hasChildren) {
      this.itemChildren$ = this.item.getChildren ? this.item.getChildren() : of([]);
      this.itemChildrenLoading$ = this.item.getChildrenLoading ? this.item.getChildrenLoading() : of(false);
      this.itemChildrenNextLoading$ = this.item.getNextLoading ? this.item.getNextLoading() : of(false);
    }

    if (this.itemSelectedEvent) {
      this.subscribeToItemSelectedEvent();
    }
  }

  public ngAfterViewInit(): void {
    if (this._treeViewItemElement) {
      this.intersectionObserver = new IntersectionObserver(entries => {
        this.isElementVisible.next(entries.some(entry => entry.isIntersecting));
      }, {
        root: this.boundingElement,
        threshold: 0.5
      });
      this.intersectionObserver.observe(this._treeViewItemElement.nativeElement);
    }
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();

    if (this.intersectionObserver) {
      this.intersectionObserver.disconnect();
    }
  }

  public onClick(): void {
    if (this.item.hasChildren) {
      this.onToggleChildren();
    }

    if (!this.isSelected.getValue()) {
      this.onSelect();
    }
  }

  public onSelect(emitEvents: boolean = true): void {
    const onSelectItem = (): void => {
      if (emitEvents) {
        this.selectItem.emit(this);
      }
      this.isSelected.next(true);

      if (!this.isElementVisible.getValue() && this._treeViewItemElement) {
        this._treeViewItemElement.nativeElement.scrollIntoView({ behavior: 'smooth' });
      }
    };

    if (!this.canSelectItem) {
      onSelectItem();
    } else {
      this.canSelectItem().then(() => onSelectItem()).catch(() => null);
    }
  }

  public onToggleChildren(emitEvents: boolean = true): void {
    this.isExpanded.next(!this.isExpanded.getValue());

    if (this.isExpanded.getValue() && emitEvents) {
      this.expandItem.emit(this);
    }
  }

  public onExpandChildren(emitEvents: boolean = true): void {
    this.isExpanded.next(true);

    if (emitEvents) {
      this.expandItem.emit(this);
    }
  }

  public onCollapseChildren(): void {
    this.isExpanded.next(false);
  }

  public onMore(): void {
    this.moreItem.emit(this);
  }

  public onChildSelectedItem(selectedItemComponent: HierarchicalTreeViewItemComponent): void {
    this.selectItem.emit(selectedItemComponent);
  }

  public onChildExpandedItem(expandedItemComponent: HierarchicalTreeViewItemComponent): void {
    this.expandItem.emit(expandedItemComponent);
  }

  public onChildMoreItem(moreItemComponent: HierarchicalTreeViewItemComponent): void {
    this.moreItem.emit(moreItemComponent);
  }

  public getMoreButtonPaddingLeft(nestLevel: number): string {
    return `${ (nestLevel + 1.25) + 2.75 }rem`;
  }

  public trackItemsById(index: number, item: HierarchicalTreeViewItem): number {
    return item.id;
  }

  public getResourceStateKey(state: ResourceState): string {
    return resourceStateEnumOptions.find(option => option.value === state)?.label;
  }

  private subscribeToItemSelectedEvent(): void {
    this.subscribe(this.itemSelectedEvent, selectedItem => {
      if (!selectedItem || this.item.id !== selectedItem.id) {
        this.isSelected.next(false);
      }
    });
  }
}
