import {Injectable} from '@angular/core';
import {
  Clients,
  CoreSharedHttpIdentityRequestService,
  CoreSharedLocalStorageService,
  LocalStorageKeys,
  MeDto,
  PasswordLoginDto,
  RefreshTokenV3Dto,
  RequestPasswordResetEmailDto,
  ResetPasswordDto,
  TenantInfoDto,
  TokenDto
} from '@nexnox-web/core-shared';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import dayjs from "dayjs";
import {LastTenantStorageDto} from "../../model/tenant-storage.model";

@Injectable()
export class CorePortalAuthService {

  public activeTenantSubject: BehaviorSubject<TenantInfoDto> = new BehaviorSubject<TenantInfoDto>(null);

  constructor(
    private requestService: CoreSharedHttpIdentityRequestService,
    private localStorageService: CoreSharedLocalStorageService
  ) {
  }

  public getActiveTenantId(): number {
    return this.activeTenantSubject.getValue()?.tenantId ?? this.localStorageService.get(LocalStorageKeys.LAST_TENANT)?.tenantId ?? undefined;
  }

  public login(email: string, passwordBase64: string): Observable<TokenDto> {
    return this.requestService.post<TokenDto>(
      'login/password',
      { email, passwordBase64 } as PasswordLoginDto,
      null,
      null,
      false
    ).pipe(
      map(response => response.body)
    );
  }

  public refreshLoginV3(refreshToken: string, tenantId?: number): Observable<TokenDto> {
    return this.requestService.post<TokenDto>(
      'v3/login/refresh',
      { refreshToken, tenantId, client: Clients.Portal } as RefreshTokenV3Dto,
      null,
      null,
      false
    ).pipe(
      map(response => response.body)
    );
  }

  public changePassword(email: string, passwordBase64: string, newPasswordBase64: string): Observable<void> {
    return this.requestService.post<void>(
      'login/changePassword',
      { email, passwordBase64, newPasswordBase64 } as PasswordLoginDto,
      null,
      null,
      false
    ).pipe(
      map(response => response.body)
    );
  }

  public resetPassword(email: string, newPasswordBase64: string, token: string): Observable<void> {
    return this.requestService.post<void>(
      'login/resetPassword',
      { email, newPasswordBase64, token } as ResetPasswordDto,
      null,
      null,
      false
    ).pipe(
      map(response => response.body)
    );
  }

  public requestPasswordResetByEmail(email: string, brand): Observable<void> {
    return this.requestService.post<void>(
      'login/requestPasswordReset',
      { email, brand } as RequestPasswordResetEmailDto,
      null,
      null,
      false
    ).pipe(
      map(response => response.body)
    );
  }

  public saveRefreshToken(refreshToken: string): void {
    this.localStorageService.set(LocalStorageKeys.REFRESH_TOKEN, refreshToken);
  }

  public getRefreshToken(): string {
    return this.localStorageService.get(LocalStorageKeys.REFRESH_TOKEN);
  }

  public clearRefreshToken(): void {
    this.localStorageService.remove(LocalStorageKeys.REFRESH_TOKEN);
    this.localStorageService.remove(LocalStorageKeys.ALL_TOKEN);
  }

  public hasTrialSubscriptions(tenant: TenantInfoDto): boolean {
    return (tenant?.subscriptions ?? [])
      .some((subscription) => Boolean(subscription?.expiresIn) &&
        new Date(subscription.expiresIn) > new Date() &&
        new Date(subscription.expiresIn) < dayjs(new Date()).add(14, 'days').toDate()
      );
  }

  public getShortestSubscriptionExpiry(tenant: TenantInfoDto): string {
    const shortestExpiry = (tenant?.subscriptions ?? [])
      .filter(a => Boolean(a?.expiresIn) && new Date(a.expiresIn) > new Date())
      .sort((a, b) => new Date(a.expiresIn).getDate() - new Date(b.expiresIn).getDate());
    return shortestExpiry[0]?.expiresIn;
  }

  public getActiveTenantFromInfo(info: MeDto): TenantInfoDto {
    const lastTenantDomain = this.localStorageService.get(LocalStorageKeys.LAST_TENANT_DOMAIN);
    return info.tenants.find(tenant => tenant.domain === lastTenantDomain) ?? info.tenants[0];
  }

  public saveActiveTenant(tenant: TenantInfoDto): void {
    this.activeTenantSubject.next(tenant);
    if (tenant) {
      this.localStorageService.set(LocalStorageKeys.LAST_TENANT, {
        tenantId: tenant.tenantId,
        domain: tenant.domain
      } as LastTenantStorageDto)
    }
  }
}
