<ng-template #entityEditTemplate let-id="id" let-title="title">
  <nexnox-web-templates-text-template-edit #modelComponent
                                           (modelChange)="onModelChange($event)"
                                           [creating]="false"
                                           [id]="id"
                                           [loading]="loading$ | async"
                                           [model]="model$ | async"
                                           [readonly]="readonly$ | async"
                                           [stereotypes$]="stereotypes$"
                                           [title]="title"
                                           [usageType]="templateUsageType">
  </nexnox-web-templates-text-template-edit>
</ng-template>

<nexnox-web-entity-detail [entityDetailBaseComponent]="this"
                          [entityEditTemplate]="entityEditTemplate"
                          [useTabs]="false">
</nexnox-web-entity-detail>
