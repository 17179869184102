import {
  ContractAuditStoreEffects,
  contractAuditStoreReducer,
  ContractDetailStoreEffects,
  contractDetailStoreReducer,
  ContractListStoreEffects,
  contractListStoreReducer,
  ContractTicketStoreEffects,
  contractTicketStoreReducer
} from './stores';
import {TechPortalFeatureContractService, TechPortalFeatureContractTicketService} from './services';

export * from './services';
export * from './stores';

export * from './contracts.state';
export * from './contracts.selector';

export const contractsReducers = {
  contractList: contractListStoreReducer,
  contractDetail: contractDetailStoreReducer,
  contractAudit: contractAuditStoreReducer,
  contractTicket: contractTicketStoreReducer
};

export const contractsEffects = [
  ContractListStoreEffects,
  ContractDetailStoreEffects,
  ContractAuditStoreEffects,
  ContractTicketStoreEffects
];

export const contractsServices = [
  TechPortalFeatureContractService,
  TechPortalFeatureContractTicketService
];
