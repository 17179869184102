import {Pipe, PipeTransform} from '@angular/core';
import {default as Fraction} from 'fraction.js';

@Pipe({
  name: 'nexnoxWebFraction'
})
export class NexnoxWebFractionPipe implements PipeTransform {
  public transform(value: number, maxValue: number): string {
    const fraction = new Fraction((value ?? 0) / 12);
    return `${ fraction.n }/${ fraction.d }`;
  }
}
