import {EntityXsStore, EntityXsStoreState} from '@nexnox-web/core-store';
import {AppEntityType, EscalationLevelDto} from '@nexnox-web/core-shared';
import {Action, createSelector} from '@ngrx/store';
import {selectEscalationLevelsState} from '../../escalation-levels.selectors';
import {TechPortalFeatureTicketSettingsEscalationLevelService} from '../../services';
import {Injectable, Injector} from '@angular/core';

export interface EscalationLevelDetailStoreState extends EntityXsStoreState<EscalationLevelDto> {
}

/* istanbul ignore next */
export const escalationLevelDetailStore = new EntityXsStore<EscalationLevelDto>({
  actionLabel: 'Tech Portal - Ticket Settings - Escalation Levels - Escalation Level Detail',
  stateSelector: createSelector(selectEscalationLevelsState, state => state.escalationLevelDetail),
  serviceType: TechPortalFeatureTicketSettingsEscalationLevelService,
  entityType: AppEntityType.None,
  stereotyped: false
});

export function escalationLevelDetailStoreReducer(state: EscalationLevelDetailStoreState, action: Action): any {
  return escalationLevelDetailStore.reducer(state, action);
}

@Injectable()
export class EscalationLevelDetailStoreEffects extends escalationLevelDetailStore.effects {
  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }
}
