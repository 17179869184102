<ng-container *ngIf="menu$ | async as menu">

  <!-- Menu toggle on mobile -->
  <div (click)="toggleMenu()" *ngIf="isMobile$ | async" class="px-3 mt-3 cursor-pointer">
    <fa-icon [class]="'mr-3'"
             [icon]="faBars"
             [size]="'sm'">
    </fa-icon>
    <span>Menu</span>
  </div>

  <!-- Menu content -->
  <div [@expandedCollapsed]="!isHideMenu ? 'expanded' : 'collapsed'" class="menu px-3">

    <!-- Filter -->
    <div class="sticky-input-wrapper d-sm-block d-none">
      <div class="p-input-icon-left w-100 my-3">
        <i class="pi pi-search"></i>
        <input (ngModelChange)="onFilterMenu(filter)" [(ngModel)]="filter" class="w-100" pInputText type="text"/>
      </div>
    </div>

    <!-- Items -->
    <ng-container *ngFor="let chapter of menu.chapters">

      <div (click)="toggleChapterCategories(chapter)"
           *ngIf="chapter.hasAccess && !chapter.isHidden"
           class="chapter-title">
        {{ chapter.title | translate }}
      </div>

      <ng-container *ngFor="let category of chapter.categories">
        <div *ngIf="category.hasAccess && !category.isHidden" class="mb-3">
          <div (click)="toggleCategory(category, menu)"
               class="category-title d-flex justify-space-between">
            <div>
              {{ category.title | translate }}
            </div>
            <div>
              <fa-icon [icon]="category.isExpanded ? faChevronUp : faChevronDown"></fa-icon>
            </div>
          </div>

          <div [@expandedCollapsed]="category.isExpanded ? 'expanded' : 'collapsed'">
            <ng-container *ngFor="let item of category.items">
              <div (click)="this.hideMenu()"
                   *ngIf="item.hasAccess && !item.isHidden"
                   [class.collapsed]="!category.isExpanded"
                   class="item">
                <a [nexnoxWebTenantRouterLinkActive]="'active'"
                   [nexnoxWebTenantRouterLink]="item.link"
                   class="item-link"
                   module="{{module}}">
                  {{ item.title  | translate }}
                </a>
              </div>
            </ng-container>
          </div>
        </div>
      </ng-container>

    </ng-container>
  </div>
</ng-container>
