import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {
  TechPortalFeatureTicketListComponent,
  TicketDetailComponent,
  TicketMissionDetailStandaloneComponent
} from './containers';
import {AppPermissions, I18nBreadcrumbResolver, UnsavedChangesGuard, UnsavedCreateGuard} from '@nexnox-web/core-shared';
import {CorePortalComponentConfigGuard, CorePortalPermissionGuard} from '@nexnox-web/core-portal';
import {TicketMissionsBreadcrumbsResolver} from "@nexnox-web/tech-portal/features/tickets/src/lib/resolvers";

export const TECH_PORTAL_TICKETS_ROUTES: Routes = [
  {
    path: '',
    canActivate: [CorePortalPermissionGuard, CorePortalComponentConfigGuard],
    canDeactivate: [UnsavedCreateGuard],
    component: TechPortalFeatureTicketListComponent,
    data: {
      permissions: [AppPermissions.DisplayTickets],
      requiredComponentConfig: ['allowList']
    }
  },
  {
    path: ':ticketId',
    component: TicketDetailComponent,
    canActivate: [CorePortalPermissionGuard],
    canDeactivate: [UnsavedChangesGuard],
    data: {
      permissions: [AppPermissions.ReadTicket, AppPermissions.ReadStereotype],
      breadcrumbs: I18nBreadcrumbResolver,
      tab: 'ticket',
      text: 'core-portal.core.header.breadcrumbs.tickets.ticket-detail'
    },
    children: [
      {
        path: 'solution',
        data: {
          tab: 'solution'
        },
        children: []
      },
      {
        path: 'missions',
        data: {
          tab: 'missions',
          breadcrumbs: I18nBreadcrumbResolver,
          text: 'core-portal.core.header.breadcrumbs.missions.mission-list'
        },
        children: []
      },
      {
        path: 'links',
        data: {
          tab: 'links'
        },
        children: []
      },
      {
        path: 'attachments',
        data: {
          tab: 'attachments'
        },
        children: []
      },
      {
        path: 'audit',
        canActivate: [CorePortalPermissionGuard],
        data: {
          permissions: [AppPermissions.ReadAudit],
          tab: 'audit'
        },
        children: []
      }
    ]
  },

  {
    path: ':ticketId/missions/create',
    component: TicketMissionDetailStandaloneComponent,
    canActivate: [CorePortalPermissionGuard],
    canDeactivate: [UnsavedCreateGuard],
    data: {
      permissions: [AppPermissions.CreateMission],
      breadcrumbs: TicketMissionsBreadcrumbsResolver,
      text: 'core-portal.core.header.breadcrumbs.missions.mission-detail'
    }
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(TECH_PORTAL_TICKETS_ROUTES)
  ],
  exports: [
    RouterModule
  ]
})
export class TicketsRoutingModule {
}
