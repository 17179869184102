<ng-container *ngIf="!performanceMode; else performanceModeTemplate"
              [ngTemplateOutlet]="calendarTemplate"></ng-container>

<ng-template #performanceModeTemplate>
  <button (click)="onOpenModal()"
          [icon]="faPencilAlt | nexnoxWebFaIconStringPipe"
          [label]="valueAsDate$ | async | nexnoxWebBsDate:'LL' | async"
          class="p-button-secondary p-button-text"
          iconPos="right"
          pButton
          type="button">
  </button>
</ng-template>

<ng-template #calendarTemplate>
  <p-calendar #calendarComponent
              (ngModelChange)="onValueChange($event)"
              (onSelect)="onSelect()"
              [autoZIndex]="false"
              [dateFormat]="'core-shared.shared.primeng.date-format' | translate"
              [disabled]="disabled$ | async"
              [firstDayOfWeek]="1"
              [maxDate]="bsMaxDate"
              [minDate]="bsMinDate"
              [ngModel]="isRange ? (valueAsDateRange$ | async) : (valueAsDate$ | async)"
              [numberOfMonths]="numberOfMonths ?? 1"
              [selectionMode]="isRange ? 'range' : 'single'"
              [showButtonBar]="true"
              [showWeek]="true"
              [styleClass]="'date-picker-input' + (hasError ? ' is-invalid' : '')"
              appendTo="body"
              class="w-100">
  </p-calendar>
</ng-template>
