<nexnox-web-entity-edit [entityEditBaseComponent]="this"
                        [stereotyped]="false"
                        displayKey="title">
</nexnox-web-entity-edit>
<nexnox-web-knowledge-paths-articles-edit #articlesComponent
                                          (articlesChange)="onArticlesChange($event)"
                                          [actions]="createArticleActions()"
                                          [articlesSubject]="articlesSubject"
                                          [loading]="loading$ | async"
                                          [readonly]="readonly">

</nexnox-web-knowledge-paths-articles-edit>

<nexnox-web-knowledge-paths-exams-edit #examsComponent
                                       (examsChange)="onExamsChange($event)"
                                       *ngIf="hasExamPermission$ | async"
                                       [actions]="createExamActions()"
                                       [examsSubject]="examsSubject"
                                       [loading]="loading$ | async"
                                       [readonly]="readonly">

</nexnox-web-knowledge-paths-exams-edit>

<nexnox-web-resource-article-preview-sidebar #articlePreviewSidebar>
</nexnox-web-resource-article-preview-sidebar>
