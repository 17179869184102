import {ChangeDetectionStrategy, Component, Injector} from '@angular/core';
import {AppEntityType, Mappers, ResourceDto} from '@nexnox-web/core-shared';
import {CorePortalEntitySelectDatatableSearchModalBaseComponent} from '@nexnox-web/core-portal';
import {CorePortalFeatureResourceService} from '../../services';

@Component({
  selector: 'nexnox-web-resources-resource-datatable-search-modal',
  templateUrl: './resource-datatable-search-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TechPortalFeatureResourceDatatableSearchModalComponent
  extends CorePortalEntitySelectDatatableSearchModalBaseComponent<ResourceDto> {
  public datatableConfigName = 'ResourceDefault';
  public componentId = 'TechPortalFeatureResourceDatatableSearchModalComponent';

  constructor(
    protected injector: Injector
  ) {
    super(
      injector,
      'resourceId',
      'name',
      CorePortalFeatureResourceService,
      AppEntityType.Resource,
      Mappers.ResourceListDto.serializedName
    );
  }
}
