import {ChangeDetectionStrategy, Component, Injector, Input, OnInit} from '@angular/core';
import {
  CorePortalEntityEditBaseComponent,
  CorePortalFormlyFileUploadTyping,
  CorePortalFormlyNgSelectOption,
  CorePortalFormlyNgSelectTyping,
  CorePortalFormlyReadonlyTypes,
  CorePortalFormlyReadonlyTyping
} from '@nexnox-web/core-portal';
import {DocumentTemplateDto} from '@nexnox-web/core-shared';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {
  documentContextTypeEnumOptions,
  documentContextTypeForFormsEnumOptions,
  documentContextTypeForIssuesEnumOptions,
  documentContextTypeForMissionsEnumOptions,
  documentContextTypeForTicketsEnumOptions,
  documentCultureEnumOptions,
  TemplateUsageTypes
} from '../../../models'

@Component({
  selector: 'nexnox-web-templates-document-template-edit',
  templateUrl: './document-template-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TechPortalFeatureDocumentTemplateEditComponent extends CorePortalEntityEditBaseComponent<DocumentTemplateDto> implements OnInit {

  @Input() public usageType: TemplateUsageTypes;

  private contextTypeOptions: CorePortalFormlyNgSelectOption[];

  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }

  public ngOnInit(): void {

    switch (this.usageType) {
      case TemplateUsageTypes.Mission:
        this.contextTypeOptions = documentContextTypeForMissionsEnumOptions;
        break;
      case TemplateUsageTypes.Ticket:
        this.contextTypeOptions = documentContextTypeForTicketsEnumOptions;
        break;
      case TemplateUsageTypes.Form:
        this.contextTypeOptions = documentContextTypeForFormsEnumOptions;
        break;
      case TemplateUsageTypes.Issue:
        this.contextTypeOptions = documentContextTypeForIssuesEnumOptions;
        break;
      default:
        this.contextTypeOptions = documentContextTypeEnumOptions;
    }
    ;

    super.ngOnInit();
  }

  protected createForm(): FormlyFieldConfig[] {
    return [
      {
        key: 'title',
        type: 'input',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-12',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.title',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.BASIC
          } as CorePortalFormlyReadonlyTyping,
          type: 'text'
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        },
        hideExpression: () => !this.creating
      },
      {
        key: 'externalId',
        type: 'core-portal-external-id',
        wrappers: ['core-portal-translated'],
        className: this.creating ? 'col-md-6' : 'col-md-12',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.external-id',
          },
          titleKey: 'title',
          modelSubject: this.modelSubject
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly
        }
      },
      {
        key: 'contextType',
        type: 'core-portal-ng-select',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        defaultValue: this.contextTypeOptions[0].value,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.context',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.ENUM,
            enumOptions: this.contextTypeOptions.map(enumOption => ({
              ...enumOption,
              label: enumOption.readonlyLabel ?? enumOption.label
            })),
            translate: true
          } as CorePortalFormlyReadonlyTyping,
          corePortalNgSelect: {
            items: this.contextTypeOptions,
            translate: true,
            noSearch: true
          } as CorePortalFormlyNgSelectTyping
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.readonly': () => this.readonly,
          'templateOptions.disabled': () => this.readonly || !this.creating
        }
      },
      {
        key: 'culture',
        type: 'core-portal-ng-select',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        defaultValue: documentCultureEnumOptions[0].value,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.culture',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.ENUM,
            enumOptions: documentCultureEnumOptions,
            translate: true
          } as CorePortalFormlyReadonlyTyping,
          corePortalNgSelect: {
            items: documentCultureEnumOptions,
            translate: true
          }
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.readonly': () => this.readonly,
          'templateOptions.disabled': () => this.readonly
        }
      },
      {
        key: 'template',
        type: 'core-portal-file-upload',
        wrappers: ['core-portal-translated'],
        className: 'col px-col',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.template',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          corePortalFileUpload: {
            accept: '.docx',
            overrideFileType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
          } as CorePortalFormlyFileUploadTyping,
          firstToDefault: true,
          clearable: false,
          required: true
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.disabled': () => this.readonly
        }
      },
      {
        key: 'isDefault',
        type: 'core-portal-switch',
        wrappers: ['core-portal-translated'],
        className: 'col-auto px-col',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.is-default'
          }
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly
        }
      }
    ];
  }
}
