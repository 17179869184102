<nexnox-web-cardbox [indicatorNum]="labels.length"
                    [isAccordion]="true"
                    [isAutoExpandOnIndicator]="true"
                    [isCollapsed]="true"
                    [title]="'core-portal.core.sidebar.menu.settings.labels' | translate"
                    [transparent]="true"
                    paddingClasses="px-3 pb-3">
  <ng-container *ngIf="displayMode !== modes.EDIT else editTemplate">
    <div class="d-flex align-items-center flex-wrap gap-1">

      <!-- DISPLAY MODE -->
      <ng-container *ngIf="displayMode === modes.DISPLAY">
        <ng-container *ngFor="let label of labelSubject.asObservable() | async"
                      [ngTemplateOutletContext]="{label: label}"
                      [ngTemplateOutlet]="labelTemplate">
        </ng-container>
      </ng-container>

      <!-- EMPTY MODE -->
      <div *ngIf="displayMode === modes.EMPTY">
        <span>{{ 'core-shared.shared.table.empty' | translate }}</span>
      </div>

      <button #addButton
              (click)="onChangeToEditMode()"
              [disabled]="loadingSubject | async"
              [icon]="faPlus | nexnoxWebFaIconStringPipe"
              [loading]="loadingSubject | async"
              [title]="'core-portal.settings.actions.add-label' | translate"
              class="m-0 ml-2 p-button-text p-button-secondary p-button-rounded p-button p-button-icon-only"
              pButton
              type="button">
      </button>
    </div>
  </ng-container>
</nexnox-web-cardbox>

<!-- EDIT MODE -->
<ng-template #editTemplate>
  <div class="d-flex align-items-center flex-wrap gap-1">
    <ng-container *ngFor="let label of labelSubject.asObservable() | async"
                  [ngTemplateOutletContext]="{label: label}"
                  [ngTemplateOutlet]="labelTemplate">
    </ng-container>
    <div class="label-create">
      <nexnox-web-entity-select (modelChange)="onAddLabel($event)"
                                [addTagFn]="saveLabelFn"
                                [disabled]="loadingSubject | async"
                                [options]="labelCreateOptions"
                                class="w-100">
      </nexnox-web-entity-select>
    </div>
  </div>
</ng-template>

<!-- LABEL TEMPLATE -->
<ng-template #labelTemplate let-label="label">
  <div class="label">
    <span>{{ label.content }}</span>
    <button (click)="onRemoveLabel(label)"
            [disabled]="loadingSubject | async"
            [icon]="faMinus | nexnoxWebFaIconStringPipe"
            [loading]="loadingSubject | async"
            [title]="'core-portal.settings.actions.remove-label' | translate"
            class="m-0 ml-2 p-button-text p-button-secondary p-button-rounded p-button p-button-icon-only"
            pButton
            type="button">
    </button>
  </div>
</ng-template>
