import {AppEntityType, ErrorCodeDto} from '@nexnox-web/core-shared';
import {EntityXsStore, EntityXsStoreState} from '@nexnox-web/core-store';
import {createSelector} from '@ngrx/store';
import {selectErrorCodesState} from '../../error-codes.selectors';
import {TechPortalFeatureConnectedErrorCodeService} from '../../services';

export interface ErrorCodeDetailStoreState extends EntityXsStoreState<ErrorCodeDto> {
}

/* istanbul ignore next */
export const errorCodeDetailStore = new EntityXsStore<ErrorCodeDto>({
  actionLabel: 'Tech Portal - Connect - Error Codes - Error Code Detail',
  stateSelector: createSelector(selectErrorCodesState, state => state.errorCodeDetail),
  serviceType: TechPortalFeatureConnectedErrorCodeService,
  entityType: AppEntityType.None,
  stereotyped: false
});
