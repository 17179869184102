import {PagedEntitiesXsStore, PagedEntitiesXsStoreState} from '@nexnox-web/core-store';
import {AppEntityType, StateDto} from '@nexnox-web/core-shared';
import {Action, createSelector} from '@ngrx/store';
import {selectStatesState} from '../../states.selectors';
import {TechPortalFeatureTicketSettingsStateService} from '../../services';
import {Injectable, Injector} from '@angular/core';

export interface StateListStoreState extends PagedEntitiesXsStoreState<StateDto> {
}

/* istanbul ignore next */
export const stateListStore = new PagedEntitiesXsStore<StateDto>({
  actionLabel: 'Tech Portal - Ticket Settings - States - State List',
  stateSelector: createSelector(selectStatesState, state => state.stateList),
  serviceType: TechPortalFeatureTicketSettingsStateService,
  entityType: AppEntityType.None,
  selectId: state => state.entity?.stateId ?? state.model?.stateId
});

export function stateListStoreReducer(state: StateListStoreState, action: Action): any {
  return stateListStore.reducer(state, action);
}

@Injectable()
export class StateListStoreEffects extends stateListStore.effects {
  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }
}
