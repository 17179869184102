import {
  BaseXsStore,
  createEntityXsStoreActions,
  EmptyAction,
  EntityXsStoreActions,
  PropsAction
} from '@nexnox-web/core-store';
import {ContactSimpleDto, ResourceDto, StateDto, TicketDto} from '@nexnox-web/core-shared';
import {createAction, props} from '@ngrx/store';

export interface TicketDetailXsStoreGetPreviewStatesSuccessPayload {
  states: StateDto[];
}

export interface TicketDetailXsStoreUpdateStatePayload {
  state: StateDto;
  reason: string;
  parentIds?: Array<number | string>;
}

export interface TicketDetailXsStoreUpdateStateSuccessPayload {
  state: StateDto;
}

export interface TicketDetailXsStoreFollowUpPayload {
  stateId: number;
  triggersAt: string;
  parentIds: Array<number | string>;
}

export interface TicketDetailXsStoreAssignToMePayload {
  parentIds?: Array<number | string>;
}

export interface TicketDetailXsStoreAssignToMeSuccessPayload {
  contact: ContactSimpleDto;
  parentIds?: Array<number | string>;
}

export interface TicketDetailXsStoreAssignToPayload {
  contact: ContactSimpleDto;
  parentIds?: Array<number | string>;
}

export interface TicketDetailXsStoreAssignToSuccessPayload {
  contact: ContactSimpleDto;
  parentIds?: Array<number | string>;
}

export interface TicketDetailXsStoreUnassignPayload {
  parentIds?: Array<number | string>;
}

export interface TicketDetailXsStoreExportPayload {
  templateId?: number | string;
}

export interface TicketDetailXsStoreExportSuccessPayload {
  uri: string;
  templateId?: number | string;
}

export interface TicketDetailXsStoreAssignResourcePayload {
  ticketId: number | string;
  resource: ResourceDto;
  isUpdateRelations: boolean;
}

export interface TicketDetailXsStoreActions extends EntityXsStoreActions<TicketDto> {
  getPreviewStates: EmptyAction;
  getPreviewStatesSuccess: PropsAction<TicketDetailXsStoreGetPreviewStatesSuccessPayload>;

  changeState: PropsAction<TicketDetailXsStoreUpdateStatePayload>;
  changeStateSuccess: PropsAction<TicketDetailXsStoreUpdateStateSuccessPayload>;

  followUp: PropsAction<TicketDetailXsStoreFollowUpPayload>;
  followUpSuccess: PropsAction<TicketDetailXsStoreFollowUpPayload>;

  assignContactToMe: PropsAction<TicketDetailXsStoreAssignToMePayload>;
  assignContactToMeSuccess: PropsAction<TicketDetailXsStoreAssignToMeSuccessPayload>;

  assignContact: PropsAction<TicketDetailXsStoreAssignToPayload>;
  assignContactSuccess: PropsAction<TicketDetailXsStoreAssignToSuccessPayload>;

  unassignContact: PropsAction<TicketDetailXsStoreUnassignPayload>;
  unassignContactSuccess: PropsAction<TicketDetailXsStoreUnassignPayload>;

  assignResource: PropsAction<TicketDetailXsStoreAssignResourcePayload>;
  assignResourceSuccess: PropsAction<TicketDetailXsStoreAssignResourcePayload>;

  unassignResource: PropsAction<TicketDetailXsStoreUnassignPayload>;
  unassignResourceSuccess: PropsAction<TicketDetailXsStoreUnassignPayload>;

  export: PropsAction<TicketDetailXsStoreExportPayload>;
  exportSuccess: PropsAction<TicketDetailXsStoreExportSuccessPayload>;
}

export const createTicketDetailXsStoreActions = (label: string): TicketDetailXsStoreActions => ({
  ...createEntityXsStoreActions(label),

  getPreviewStates: createAction(BaseXsStore.getType(label, 'Get preview states')),
  getPreviewStatesSuccess: createAction(
    BaseXsStore.getType(label, 'Get preview states success'),
    props<TicketDetailXsStoreGetPreviewStatesSuccessPayload>()
  ),

  changeState: createAction(
    BaseXsStore.getType(label, 'Change state'),
    props<TicketDetailXsStoreUpdateStatePayload>()
  ),
  changeStateSuccess: createAction(
    BaseXsStore.getType(label, 'Change state success'),
    props<TicketDetailXsStoreUpdateStateSuccessPayload>()
  ),

  followUp: createAction(
    BaseXsStore.getType(label, 'Follow up'),
    props<TicketDetailXsStoreFollowUpPayload>()
  ),
  followUpSuccess: createAction(
    BaseXsStore.getType(label, 'Follow up success'),
    props<TicketDetailXsStoreFollowUpPayload>()
  ),

  assignContactToMe: createAction(
    BaseXsStore.getType(label, 'Assign to me'),
    props<TicketDetailXsStoreAssignToMePayload>()
  ),
  assignContactToMeSuccess: createAction(
    BaseXsStore.getType(label, 'Assign to me success'),
    props<TicketDetailXsStoreAssignToMeSuccessPayload>()
  ),

  assignContact: createAction(
    BaseXsStore.getType(label, 'Assign to'),
    props<TicketDetailXsStoreAssignToPayload>()
  ),
  assignContactSuccess: createAction(
    BaseXsStore.getType(label, 'Assign to success'),
    props<TicketDetailXsStoreAssignToSuccessPayload>()
  ),

  unassignContact: createAction(
    BaseXsStore.getType(label, 'Unassign'),
    props<TicketDetailXsStoreUnassignPayload>()
  ),
  unassignContactSuccess: createAction(
    BaseXsStore.getType(label, 'Unassign success'),
    props<TicketDetailXsStoreUnassignPayload>()
  ),

  assignResource: createAction(
    BaseXsStore.getType(label, 'Assign resource'),
    props<TicketDetailXsStoreAssignResourcePayload>()
  ),
  assignResourceSuccess: createAction(
    BaseXsStore.getType(label, 'Assign resource success'),
    props<TicketDetailXsStoreAssignResourcePayload>()
  ),

  unassignResource: createAction(
    BaseXsStore.getType(label, 'Unassign resource'),
    props<TicketDetailXsStoreUnassignPayload>()
  ),
  unassignResourceSuccess: createAction(
    BaseXsStore.getType(label, 'Unassign resource success'),
    props<TicketDetailXsStoreUnassignPayload>()
  ),

  export: createAction(
    BaseXsStore.getType(label, 'Export'),
    props<TicketDetailXsStoreExportPayload>()
  ),
  exportSuccess: createAction(
    BaseXsStore.getType(label, 'Export success'),
    props<TicketDetailXsStoreExportSuccessPayload>()
  )
});
