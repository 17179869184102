import {ChangeDetectionStrategy, Component, Inject, Injector, Input, OnInit} from '@angular/core';
import {
  CorePortalEntityEditBaseComponent,
  CorePortalFormlyNgSelectOption,
  CorePortalFormlyNgSelectTyping,
  CorePortalFormlyReadonlyTypes,
  CorePortalFormlyReadonlyTyping
} from '@nexnox-web/core-portal';
import {
  CoreSharedModalService,
  CoreSharedTextConverterService,
  Mappers,
  ResizedEvent,
  TemplateContextType,
  TemplateDto
} from '@nexnox-web/core-shared';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {flatten, keys} from 'lodash';
import {CompositeMapper, CompositeMapperType, Mapper} from '@azure/ms-rest-js';
import {TECH_PORTAL_TEXT_TEMPLATE_CONTEXT_CONFIG, TechPortalTextTemplateContextConfig} from '../../../tokens';
import {BehaviorSubject, lastValueFrom, Observable, Subject} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {
  TemplateUsageTypes,
  textContextTypeEnumOptions,
  textContextTypeForIssuesEnumOptions,
  textContextTypeForMissionsEnumOptions,
  textContextTypeForNotificationsEnumOptions,
  textContextTypeForSolutionsEnumOptions
} from '../../../models';
import {TextTemplatePreviewModalComponent} from '../../../modals';
import {TechPortalFeatureTextTemplateService} from '../../../store';

@Component({
  selector: 'nexnox-web-templates-text-template-edit',
  templateUrl: './text-template-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TechPortalFeatureTextTemplateEditComponent extends CorePortalEntityEditBaseComponent<TemplateDto> implements OnInit {

  @Input() public usageType: TemplateUsageTypes;

  public templateVariables$: Observable<string[]>;

  public variableHeightSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public previewLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private contextSubject: BehaviorSubject<TemplateContextType> = new BehaviorSubject<TemplateContextType>(null);

  private insertContentSubject: Subject<string> = new Subject<string>();
  private focusSubject: Subject<void> = new Subject<void>();

  private contextTypeOptions: CorePortalFormlyNgSelectOption[];

  constructor(
    protected injector: Injector,
    private translate: TranslateService,
    @Inject(TECH_PORTAL_TEXT_TEMPLATE_CONTEXT_CONFIG) private textTemplateConfig: TechPortalTextTemplateContextConfig,
    private templateService: TechPortalFeatureTextTemplateService,
    private modalService: CoreSharedModalService,
    private converterService: CoreSharedTextConverterService
  ) {
    super(injector);
  }

  public ngOnInit(): void {

    switch (this.usageType) {
      case TemplateUsageTypes.Mission:
        this.contextTypeOptions = textContextTypeForMissionsEnumOptions;
        break;
      case TemplateUsageTypes.Solution:
        this.contextTypeOptions = textContextTypeForSolutionsEnumOptions;
        break;
      case TemplateUsageTypes.Notification:
        this.contextTypeOptions = textContextTypeForNotificationsEnumOptions;
        break;
      case TemplateUsageTypes.Issue:
        this.contextTypeOptions = textContextTypeForIssuesEnumOptions;
        break;
      default:
        this.contextTypeOptions = textContextTypeEnumOptions;
    }

    super.ngOnInit();

    this.templateVariables$ = this.contextSubject.asObservable().pipe(
      map(contextType => {
        if (contextType) {
          const templateConfig = this.textTemplateConfig[contextType];

          if (templateConfig) {
            const mapper = Mappers[templateConfig.serializedName];

            if (mapper) {
              return this.processMapper(mapper, `${ templateConfig.prefix }.`);
            }
          }
        }

        return [];
      })
    );
  }

  public onAddVariable(variable: string): void {
    this.insertContentSubject.next(`{{ ${ variable } }}`);
    this.focusSubject.next();
  }

  /* istanbul ignore next */
  public onEditComponentResized(event: ResizedEvent): void {
    this.variableHeightSubject.next(event.newHeight);
  }

  public onPreview(content: string, context: TemplateContextType, loadingSubject?: BehaviorSubject<boolean>): void {
    this.previewLoadingSubject.next(true);
    lastValueFrom(this.templateService.previewTemplate(content, context)
    ).then(response => {
      this.modalService.showModal(TextTemplatePreviewModalComponent, /* istanbul ignore next */ instance => {
        instance.preview = response?.content ?? '';
      }).then(() => loadingSubject?.next(false)).catch(() => loadingSubject?.next(false));
      loadingSubject?.next(false);
    })
      .catch(() => loadingSubject?.next(false));
  }

  /* istanbul ignore next */
  protected createForm(): FormlyFieldConfig[] {
    return [
      {
        key: 'title',
        type: 'input',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-12',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.title',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.BASIC
          } as CorePortalFormlyReadonlyTyping,
          type: 'text'
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        },
        hideExpression: () => !this.creating
      },
      {
        key: 'context',
        type: 'core-portal-ng-select',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        defaultValue: this.contextTypeOptions[0].value,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.context',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.ENUM,
            enumOptions: this.contextTypeOptions.map(enumOption => ({
              ...enumOption,
              label: enumOption.readonlyLabel ?? enumOption.label
            })),
            translate: true
          } as CorePortalFormlyReadonlyTyping,
          corePortalNgSelect: {
            items: this.contextTypeOptions,
            translate: true,
            noSearch: true
          } as CorePortalFormlyNgSelectTyping
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.readonly': () => this.readonly,
          'templateOptions.disabled': () => this.readonly || !this.creating
        },
        hooks: {
          onInit: field => this.subscribe(field.formControl.valueChanges.pipe(
            startWith(field.formControl.value)
          ), value => this.contextSubject.next(value))
        }
      },
      {
        key: 'externalId',
        type: 'core-portal-external-id',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.external-id',
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.BASIC
          } as CorePortalFormlyReadonlyTyping,
          titleKey: 'title',
          modelSubject: this.modelSubject
        },
        expressionProperties: {
          'templateOptions.readonly': () => this.readonly,
          'templateOptions.disabled': () => this.readonly
        }
      },
      {
        key: 'content',
        type: 'core-portal-editor',
        wrappers: ['core-portal-translated'],
        className: 'col-md-12',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.content',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          corePortalEditor: {
            language: this.translate.currentLang,
            insertContent$: this.insertContentSubject.asObservable(),
            focus$: this.focusSubject.asObservable()
          }
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.corePortalEditor.noHTML': (model) => this._isNoHTML(model)
        }
      }
    ];
  }

  private _isNoHTML(model: any): boolean {
    // Init text context types for plain text
    const textContexts = [
      TemplateContextType.InfoNotificationSubject,
      TemplateContextType.MissionNotificationSubject,
      TemplateContextType.TicketNotificationSubject,
      TemplateContextType.IssueEmailSubject,
      TemplateContextType.MessageIssueEmailSubject,
      TemplateContextType.MissionIssueEmailSubject,
      TemplateContextType.TicketIssueEmailSubject,
      TemplateContextType.WinterhalterIssueEmailSubject
    ];
    // Check
    const isNoHTML = textContexts.includes(this.contextSubject.value);
    // Convert html to text
    if (isNoHTML && model?.content) {
      model.content = this.converterService.convertHTMLtoText(model.content);
    }
    return isNoHTML;
  }

  /* istanbul ignore next */
  private processMapper(mapper: Mapper, previous: string = ''): string[] {
    if ((mapper as CompositeMapper).type?.modelProperties) {
      return flatten(keys((mapper as CompositeMapper).type.modelProperties)
        .map(key => (mapper as CompositeMapper).type.modelProperties[key])
        .map(property => this.processMapper(property, previous)));
    } else if ((mapper.type as CompositeMapperType)?.className) {
      return this.processMapper(Mappers[(mapper.type as CompositeMapperType)?.className], `${ previous }${ mapper.serializedName }.`);
    } else {
      return [`${ previous }${ mapper.serializedName }`];
    }
  }
}
