<div class="d-flex flex-row w-100 dual-list-box">
  <div class="d-flex flex-grow-1 justify-content-center w-100">
    <ng-container [ngTemplateOutletContext]="{
                    items$: availableItems$,
                    model$: markedAvailableItems$,
                    search$: availableItemsSearch$,
                    emitter: availableItemsChange
                  }"
                  [ngTemplateOutlet]="ngSelectTemplate">
    </ng-container>
  </div>

  <div class="d-flex flex-grow-0 flex-shrink-0 flex-column justify-content-center align-items-center mx-3">
    <button (click)="onMoveAllRight()"
            [disabled]="disabled || (moveAllRightEnabled$ | async) === false"
            class="btn btn-outline-secondary mb-2"
            type="button">
      <fa-icon [fixedWidth]="true" [icon]="faAngleDoubleRight" size="lg"></fa-icon>
    </button>

    <button (click)="onMoveRight()"
            [disabled]="disabled || (moveRightEnabled$ | async) === false"
            class="btn btn-outline-secondary mb-2 mt-2"
            type="button">
      <fa-icon [fixedWidth]="true" [icon]="faAngleRight" size="lg"></fa-icon>
    </button>

    <button (click)="onMoveLeft()"
            [disabled]="disabled || (moveLeftEnabled$ | async) === false"
            class="btn btn-outline-secondary mb-2 mt-2"
            type="button">
      <fa-icon [fixedWidth]="true" [icon]="faAngleLeft" size="lg"></fa-icon>
    </button>

    <button (click)="onMoveAllLeft()"
            [disabled]="disabled || (moveAllLeftEnabled$ | async) === false"
            class="btn btn-outline-secondary mt-2"
            type="button">
      <fa-icon [fixedWidth]="true" [icon]="faAngleDoubleLeft" size="lg"></fa-icon>
    </button>
  </div>

  <div class="d-flex flex-grow-1 justify-content-center w-100">
    <ng-container [ngTemplateOutletContext]="{
                    items$: activeItems$,
                    model$: markedActiveItems$,
                    search$: activeItemsSearch$,
                    emitter: activeItemsChange,
                    sort: true
                  }"
                  [ngTemplateOutlet]="ngSelectTemplate">
    </ng-container>
  </div>
</div>

<ng-template #ngSelectTemplate
             let-emitter="emitter"
             let-items$="items$"
             let-model$="model$"
             let-search$="search$"
             let-sort="sort">
  <ng-select #ngSelectComponent
             (ngModelChange)="onMarkedChanged($event, model$)"
             [class.disabled]="disabled"
             [clearSearchOnAdd]="false"
             [disabled]="disabled"
             [isOpen]="true"
             [items]="items$ | async"
             [multiple]="true"
             [ngModel]="model$ | async"
             [searchFn]="searchFn"
             [searchable]="true"
             [selectableGroupAsModel]="false"
             [selectableGroup]="true"
             class="dual-list-box__select_embed w-100"
             dropdownPosition="bottom"
             groupBy="items">
    <ng-template ng-header-tmp>
      <div class="input-group w-100">
        <div class="input-group-prepend">
          <span class="input-group-text"><fa-icon [icon]="faSearch"></fa-icon></span>
        </div>

        <input (ngModelChange)="onSearch($event, ngSelectComponent, search$)"
               [disabled]="disabled || isSearchDisabled(items$ | async)"
               [ngModel]="getSearchTerm(search$ | async)"
               class="form-control"
               type="search"/>
      </div>
    </ng-template>

    <ng-template let-item="item" let-searchTerm="searchTerm" ng-optgroup-tmp>
      <span *ngIf="item.translated; else notTranslated"
            [innerHTML]="item.title | translate | nexnoxWebHighlight:searchTerm"
            [title]="item.title | translate">
      </span>

      <ng-template #notTranslated>
        <span [innerHTML]="item.title | nexnoxWebHighlight:searchTerm" [title]="item.title"></span>
      </ng-template>
    </ng-template>

    <ng-template let-item="item" let-item$="item$" let-searchTerm="searchTerm" ng-option-tmp>
      <div class="d-flex w-100 align-items-center">
        <div class="d-flex flex-grow-1">
          <span *ngIf="item.translated; else notTranslated"
                [title]="item.title | translate"
                class="w-100">
            <span [innerHTML]="item.title | translate | nexnoxWebHighlight:searchTerm"></span>
            <span *ngIf="item.suffix" class="text-muted"> ({{ item.suffix | translate }})</span>
          </span>

          <ng-template #notTranslated>
            <span [title]="item.title" class="w-100">
              <span [innerHTML]="item.title | nexnoxWebHighlight:searchTerm"></span>
              <span *ngIf="item.suffix" class="text-muted"> ({{ item.suffix | translate }})</span>
            </span>
          </ng-template>
        </div>

        <div *ngIf="sort" class="d-flex">
          <ng-container *ngIf="items$ | async">
            <button (click)="onMoveItemDown($event, item, items$, emitter)"
                    *ngIf="item.position < item$?.parent?.children?.length"
                    [class.mr-1]="item.position > 1"
                    class="btn btn-sm btn-outline-secondary"
                    type="button">
              <fa-icon [fixedWidth]="true" [icon]="faArrowDown" size="xs"></fa-icon>
            </button>

            <button (click)="onMoveItemUp($event, item, items$, emitter)"
                    *ngIf="item.position > 1"
                    class="btn btn-sm btn-outline-secondary"
                    type="button">
              <fa-icon [fixedWidth]="true" [icon]="faArrowUp" size="xs"></fa-icon>
            </button>
          </ng-container>
        </div>
      </div>
    </ng-template>

    <ng-template ng-notfound-tmp>
      <div class="d-flex w-100 h-100 justify-content-center align-items-center p-3">
        <span class="text-muted">{{ 'core-shared.shared.table.empty' | translate }}</span>
      </div>
    </ng-template>
  </ng-select>
</ng-template>
