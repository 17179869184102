import {
  DocumentTemplateDetailXsStore,
  DocumentTemplateDetailXsStoreActions,
  DocumentTemplateDetailXsStoreState
} from './document-template-detail.xs-store';
import {Action, createSelector, select} from '@ngrx/store';
import {Injectable, Injector} from '@angular/core';
import {selectDocumentTemplatesState} from '../document-templates.selectors';
import {TechPortalFeatureDocumentTemplateService} from '../../../services';
import {AppEntityType} from '@nexnox-web/core-shared';
import {createEffect, ofType} from '@ngrx/effects';
import {catchError, exhaustMap, map, tap, withLatestFrom} from 'rxjs/operators';
import {of} from 'rxjs';

export interface DocumentTemplateDetailStoreState extends DocumentTemplateDetailXsStoreState {
}

/* istanbul ignore next */
export const documentTemplateDetailStore = new DocumentTemplateDetailXsStore({
  actionLabel: 'Tech Portal - Templates - Document Template Detail',
  stateSelector: createSelector(selectDocumentTemplatesState, state => state.documentTemplateDetail),
  serviceType: TechPortalFeatureDocumentTemplateService,
  entityType: AppEntityType.DocumentTemplate,
  stereotyped: false
});

export function documentTemplateDetailStoreReducer(state: DocumentTemplateDetailStoreState, action: Action): any {
  return documentTemplateDetailStore.reducer(state, action);
}

@Injectable()
export class DocumentTemplateDetailStoreEffects extends documentTemplateDetailStore.effects {
  public actions: DocumentTemplateDetailXsStoreActions;
  public service: TechPortalFeatureDocumentTemplateService;

  public preview$: any;
  public previewSuccess$: any;

  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }

  protected createEffects(): void {
    super.createEffects();

    this.preview$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.preview),
      withLatestFrom(this.store.pipe(select(this.selectors.selectModel))),
      exhaustMap(([_, model]) => this.service.previewTemplate(model?.template?.fileId, model?.contextType).pipe(
        map(({ uri }) => this.actions.previewSuccess({ uri })),
        catchError(error => of(this.actions.error({ error, action: this.actions.preview })))
      ))
    ));

    this.previewSuccess$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.previewSuccess),
      tap(action => this.actionCallback(action, false))
    ), { dispatch: false });
  }

  protected actionCallback(action: Action, isError: boolean): void {
    super.actionCallback(action, isError);

    this.checkAction(this.actions.previewSuccess, action, ({ uri }) => this.previewSuccessActionCallback(uri));
  }

  protected previewSuccessActionCallback(uri: string): void {
    window.open(uri, '_blank');
  }
}
