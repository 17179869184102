import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {TenantInfoDto} from '@nexnox-web/core-shared';
import {distinctUntilKeyChanged, NEVER, Observable} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {select, Store} from '@ngrx/store';
import {authStore} from '../../../../store';

@Component({
  selector: 'nexnox-web-layout-tenant-select',
  templateUrl: './tenant-select.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TenantSelectComponent implements OnInit {
  @Input() public activeTenant$: Observable<TenantInfoDto> = NEVER;
  @Input() public tenantItems$: Observable<{ label: string; id: number }[]>;
  @Input() public embedded: boolean;

  public activeTenantItem$: Observable<{ label: string; id: number }>;
  public loading$: Observable<boolean>;

  constructor(
    private store: Store<any>
  ) {
  }

  public ngOnInit(): void {
    this.activeTenantItem$ = this.store.select(authStore.selectors.selectActiveTenant).pipe(
      filter(activeTenant => Boolean(activeTenant)),
      distinctUntilKeyChanged('tenantId'),
      map(activeTenant => ({ id: activeTenant?.tenantId, label: activeTenant.name })),
    );
    this.loading$ = this.store.pipe(select(authStore.selectors.selectLoading));
  }

  public onTenantChange({ id }): void {
    this.store.dispatch(authStore.actions.changeTenant({ tenantId: id }));
  }
}
