<div class="d-flex align-items-center w-100">

  <!-- field component -->
  <div [ngClass]="to.readonly ? 'flex-shrink-1' : 'flex-grow-1'" class="field-component">
    <ng-template #fieldComponent></ng-template>
  </div>

  <!-- actions -->
  <div [class.flex-shrink-1]="to.readonly">
    <ng-container *ngIf="visible$ | async else loadingTemplate">
      <ng-container *ngFor="let action of actions">
        <button (click)="action.onClick()"
                *ngIf="!action.hidden(model) && action.permission$ | async"
                [disabled]="action.disabled(model)"
                class="btn btn-link datatable-btn p-0 ml-2"
                title="{{ action?.tooltip | translate }}">
          <fa-icon [icon]="action.icon" size="sm"></fa-icon>
        </button>
      </ng-container>
    </ng-container>
  </div>
</div>

<ng-template #loadingTemplate>
  <nexnox-web-loading-icon class="btn-link p-0 ml-2"></nexnox-web-loading-icon>
</ng-template>
