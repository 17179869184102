import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {faSpinner} from '@fortawesome/free-solid-svg-icons/faSpinner';
import {CorePortalFormlyReadonlyTypes, CorePortalPermissionService} from '@nexnox-web/core-portal';
import {
  AppPermissions,
  ContactDto,
  CoreSharedSidebarBaseComponent,
  ResourceDto,
  SafeDto
} from '@nexnox-web/core-shared';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {Observable} from 'rxjs';
import {CorePortalFeatureMasterDataContactService} from "@nexnox-web/core-portal/features/master-data/features/contacts/src/lib/store/services/contact/contact.service";
import {faExclamationTriangle} from "@fortawesome/free-solid-svg-icons/faExclamationTriangle";
import {faLock} from '@fortawesome/free-solid-svg-icons/faLock';
import {faLockOpen} from "@fortawesome/free-solid-svg-icons/faLockOpen";

@Component({
  selector: 'nexnox-web-resource-safe-sidebar',
  templateUrl: './safe-sidebar.component.html',
  styleUrls: ['./safe-sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SafeSidebarComponent extends CoreSharedSidebarBaseComponent implements OnInit {

  @Input() public resource: ResourceDto;

  @Output() public putInSafe: EventEmitter<SafeDto> = new EventEmitter<SafeDto>();
  @Output() public removeFromSafe: EventEmitter<number | string> = new EventEmitter<number | string>();

  public isOutOfSave: boolean;
  public isRestoreResource = false;
  public hasDeleteSafePermission$: Observable<boolean>;
  public refresh: boolean;

  public form: FormGroup;
  public model: SafeDto;
  public fields: FormlyFieldConfig[];

  public loading$: Observable<boolean>;

  public faLock = faLock;
  public faLockOpen = faLockOpen;
  public faSpinner = faSpinner;
  public faExclamationTriangle = faExclamationTriangle;

  constructor(
    private contactService: CorePortalFeatureMasterDataContactService,
    private permissionService: CorePortalPermissionService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.hasDeleteSafePermission$ = this.permissionService.hasPermission$(AppPermissions.DeleteSafe);
    this.form = new FormGroup({});
    this.model = {} as SafeDto;
    this.fields = this.createForm();
  }

  public restoreDefaults(): void {
    this.form.reset();
    this.form.markAsPristine();
    this.refresh = true;
    this.isRestoreResource = false;
  }

  public onHide(): void {
    super.onHide();
    this.restoreDefaults();
  }

  public onShow(isRestore: boolean = false): void {
    this.isRestoreResource = isRestore;
    this.isOutOfSave = !Boolean(this.resource?.safe);
    this.model.contacts = this.resource?.safe?.contacts ?? [];
    this.refresh = false;
    super.onShow();
  }

  public onPutInSave(): void {
    this.putInSafe.emit(this.model)
    this.onHide();
  }

  public onRemoveFromSave(): void {
    this.removeFromSafe.emit(this.resource.resourceId)
    this.onHide();
  }

  public setRestoreTab(): void {
    this.isRestoreResource = true;
  }

  public setDefaultTab(): void {
    this.isRestoreResource = false;
  }

  /* istanbul ignore next */
  private createForm(): FormlyFieldConfig[] {
    return [
      {
        key: 'contacts',
        type: 'core-portal-entity-select',
        className: 'col-12',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        defaultValue: null,
        templateOptions: {
          corePortalTranslated: {
            label: 'resources.fields.safe-keeper',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.ENTITY,
            displayKey: 'displayName',
            link: (contact: ContactDto) => contact?.contactId ? ['/masterdata', 'contacts', contact.contactId] : null,
            module: 'management'
          },
          entityService: this.contactService,
          idKey: 'contactId',
          displayKey: 'displayName',
          wholeObject: true,
          multiple: true,
          clearable: true,
          showAll: true,

          link: (contact: ContactDto) => contact?.contactId ? ['/masterdata', 'contacts', contact.contactId] : null,
          module: 'management'
        },
        expressionProperties: {
          'templateOptions.readonly': () => this.refresh
        }
      }
    ];
  }
}
