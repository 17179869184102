import {
  AdvancedEntityAdapter,
  BaseXsStore,
  BaseXsStoreReducerTypes,
  createAdvancedEntityAdapter,
  createPagedEntitiesXsStoreActions,
  PagedEntitiesXsStore,
  PagedEntitiesXsStoreEntity,
  PagedEntitiesXsStoreOptions,
  PagedEntitiesXsStoreSelectors,
  pagedEntitiesXsStoreSetLoadingForId,
  PagedEntitiesXsStoreState
} from '@nexnox-web/core-store';
import {AppEntityType, CoreSharedApiBaseService, SolutionItemDto} from '@nexnox-web/core-shared';
import {createAction, on, props} from '@ngrx/store';
import {cloneDeep} from 'lodash';
import {TechPortalFeatureSolutionItemService} from '../../services';
import {
  TechPortalFeatureSolutionItemListXsStoreActions,
  TechPortalFeatureSolutionItemListXsStoreMarkItemAsSolutionPayload
} from './solution-item-list-xs-store.actions';
import {Injectable, Injector, Type} from '@angular/core';
import {TechPortalFeatureSolutionItemListXsStoreEffects} from './solution-item-list-xs-store.effects';
import {immerOn} from 'ngrx-immer/store';

export interface TechPortalFeatureSolutionItemListXsStoreState extends PagedEntitiesXsStoreState<SolutionItemDto> {
}

export interface TechPortalFeatureSolutionItemListXsStoreOptions
  extends Omit<PagedEntitiesXsStoreOptions<SolutionItemDto, SolutionItemDto, TechPortalFeatureSolutionItemListXsStoreState>,
    'selectId' | 'serviceType' | 'stereotyped' | 'entityType'> {
}

export interface TechPortalFeatureSolutionItemListXsStoreSelectors extends PagedEntitiesXsStoreSelectors<SolutionItemDto> {
}

export class TechPortalFeatureSolutionItemListXsStore
  extends PagedEntitiesXsStore<SolutionItemDto, SolutionItemDto, TechPortalFeatureSolutionItemListXsStoreState> {
  public actions: TechPortalFeatureSolutionItemListXsStoreActions;
  public selectors: TechPortalFeatureSolutionItemListXsStoreSelectors;
  public adapter: AdvancedEntityAdapter<PagedEntitiesXsStoreEntity<SolutionItemDto>>;

  /* istanbul ignore next */
  constructor(
    private specialOptions: TechPortalFeatureSolutionItemListXsStoreOptions
  ) {
    super({
      ...specialOptions,
      selectId: solutionItem => solutionItem?.entity?.solutionItemId ?? solutionItem?.model?.solutionItemId,
      serviceType: TechPortalFeatureSolutionItemService,
      entityType: AppEntityType.None,
      stereotyped: false
    });
  }

  public getInitialState(): TechPortalFeatureSolutionItemListXsStoreState {
    return {
      ...super.getInitialState()
    };
  }

  protected createActions(label: string): TechPortalFeatureSolutionItemListXsStoreActions {
    return {
      ...createPagedEntitiesXsStoreActions(label),

      markItemAsSolution: createAction(
        BaseXsStore.getType(label, 'Mark item as solution'),
        props<TechPortalFeatureSolutionItemListXsStoreMarkItemAsSolutionPayload>()
      ),
      markItemAsSolutionSuccess: createAction(
        BaseXsStore.getType(label, 'Mark item as solution success'),
        props<TechPortalFeatureSolutionItemListXsStoreMarkItemAsSolutionPayload>()
      )
    };
  }

  protected createReducerArray(
    initialState: TechPortalFeatureSolutionItemListXsStoreState
  ): BaseXsStoreReducerTypes<TechPortalFeatureSolutionItemListXsStoreState, TechPortalFeatureSolutionItemListXsStoreActions>[] {
    return [
      ...super.createReducerArray(initialState),

      on(this.actions.createOneSuccess, (state, { entity, model }) => this.adapter.addOneAt({
        entity: cloneDeep(entity),
        model: cloneDeep(model)
      }, 0, {
        ...state,
        paging: {
          ...state.paging,
          totalItems: (state.paging?.totalItems ?? 0) + 1
        },
        loading: false
      })),

      immerOn(this.actions.markItemAsSolution, (draft, { id, isSolution }) => {
        draft.entityData = pagedEntitiesXsStoreSetLoadingForId(draft.entityData, id, {
          [isSolution ? 'markAsSolution' : 'unmarkAsSolution']: true
        });
      }),
      on(this.actions.markItemAsSolutionSuccess, (state, { id, isSolution }) => this.adapter.updateOne({
        id,
        changes: {
          entity: {
            ...state.entities[id].entity,
            isSolution
          },
          model: {
            ...state.entities[id].model,
            isSolution
          }
        }
      }, {
        ...state,
        entityData: pagedEntitiesXsStoreSetLoadingForId(state.entityData, id, {
          [isSolution ? 'markAsSolution' : 'unmarkAsSolution']: false
        })
      })),

      immerOn(this.actions.error, draft => {
        draft.loading = false;
        draft.stereotypesLoading = false;
        draft.hasError = true;
        draft.entityData = {};
      }),
    ];
  }

  protected createEffects(
    serviceType: Type<CoreSharedApiBaseService>,
    entityType: AppEntityType,
    prepareEntity: (entity: SolutionItemDto) => SolutionItemDto,
    prepareModel: (entity: SolutionItemDto, model: SolutionItemDto) => SolutionItemDto,
    sanitizeModel: (model: SolutionItemDto, entity: SolutionItemDto) => SolutionItemDto,
    ...args
  ): Type<TechPortalFeatureSolutionItemListXsStoreEffects> {
    const actions = this.actions;
    const selectors = this.selectors;

    @Injectable()
    class Effects extends TechPortalFeatureSolutionItemListXsStoreEffects {
      constructor(
        protected injector: Injector
      ) {
        super(injector, actions, selectors, serviceType, entityType, prepareEntity, prepareModel, sanitizeModel);
      }
    }

    return Effects;
  }

  protected createSelectors(): TechPortalFeatureSolutionItemListXsStoreSelectors {
    return super.createSelectors();
  }

  protected createAdapter(): AdvancedEntityAdapter<PagedEntitiesXsStoreEntity<SolutionItemDto>> {
    return this.adapter ?? createAdvancedEntityAdapter({
      selectId: this.options.selectId,
      sortComparer: this.options.sortComparer ?? undefined
    });
  }
}
