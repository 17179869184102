import {uniqBy} from 'lodash';
import produce from 'immer';
import {HttpErrorResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {filter, map, take} from 'rxjs/operators';
import {
  ApiNotificationService,
  CoreSharedApiBaseService,
  FilterDto,
  FilterOperations,
  PageableRequest,
  Paging
} from '@nexnox-web/core-shared';
import {CoreStoreObservableStore} from '@nexnox-web/core-store';

export interface CorePortalEntitySelectStoreState {
  items: any[];
  paging: Paging;
  loading: boolean;
  loaded: boolean;
  error: boolean;
}

export const initialCorePortalEntitySelectStoreState: CorePortalEntitySelectStoreState = {
  items: [],
  paging: null,
  loading: false,
  loaded: false,
  error: false
};

export const PAGE_SIZE = 10;

export class CorePortalEntitySelectStore extends CoreStoreObservableStore<CorePortalEntitySelectStoreState> {
  /**
   * @param entityService
   * @param apiNotificationService
   * @param idKey - ID property of the entity
   * @param overrideGetPage - Custom get page function
   */
  constructor(
    private entityService: CoreSharedApiBaseService,
    private apiNotificationService: ApiNotificationService,
    private idKey: string,
    private overrideGetPage?: (pageNumber: number, pageSize: number,
                               filters: FilterDto[]) => Promise<PageableRequest<any>>
  ) {
    super({
      trackStateHistory: true
    });

    this.setState(initialCorePortalEntitySelectStoreState, 'INIT_STATE');
  }

  /**
   * Fetches the initial page. Is used if at least one entity is already selected. Fetches the first page and the selected entity, unless
   * otherwise configured.
   *
   * @param entityId - The entity id of the selected entity
   * @param filters - Filters
   * @param excludeIds - IDs to exclude
   * @param getOne - Whether to fetch the selected entity or assume it is not needed or will be added later on
   * @param appendItems - Items to append
   * @param prependItems - Items to prepend
   * @param getAll - Whether to fetch the first page or just use {@link appendItems} and {@link prependItems}
   * @param optionalColumns - Optional columns that need to be resolved
   */
  public getInitialPage(
    entityId: number,
    filters: FilterDto[] = [],
    excludeIds: number[] = [],
    getOne: boolean = true,
    appendItems: any[] = [],
    prependItems: any[] = [],
    getAll: boolean = true,
    optionalColumns?: string[]
  ): void {
    this.setState(produce(this.getState(), draft => {
      draft.loading = true;
    }), 'GET_INITIAL_PAGE');

    if (getAll) {
      this.getPage(1, filters, optionalColumns)
        .then(response => {
          if (getOne) {
            this.entityService
              .getOne(entityId)
              .toPromise()
              .then(one => this.getPageSuccess({
                ...response,
                items: this.filterExcludedItems(
                  uniqBy([...prependItems, ...response.items, one, ...appendItems], item => item[this.idKey]),
                  excludeIds
                )
              }))
              .catch(error => this.handleError(error));
          } else {
            this.getPageSuccess({
              ...response,
              items: this.filterExcludedItems(
                uniqBy([...prependItems, ...response.items, ...appendItems], item => item[this.idKey]),
                excludeIds
              )
            });
          }
        })
        .catch(error => this.handleError(error));
    } else {
      if (getOne) {
        this.entityService
          .getOne(entityId)
          .toPromise()
          .then(one => this.getPageSuccess({
            items: this.filterExcludedItems(
              uniqBy([one, ...appendItems], item => item[this.idKey]),
              excludeIds
            ),
            paging: null,
            filterOptions: []
          }))
          .catch(error => this.handleError(error));
      } else {
        this.getPageSuccess({
          items: this.filterExcludedItems(
            uniqBy(appendItems, item => item[this.idKey]),
            excludeIds
          ),
          paging: null,
          filterOptions: []
        });
      }
    }
  }

  /**
   * Fetches the first page. Is used if no entities are selected.
   *
   * @param filters - Filters
   * @param excludeIds - IDs to exclude
   * @param optionalColumns - Optional columns that need to be resolved
   */
  public getFirstPage(
    filters: FilterDto[] = [],
    excludeIds: number[] = [],
    optionalColumns?: string[]
  ): void {
    this.setState(produce(this.getState(), draft => {
      draft.loading = true;
    }), 'GET_FIRST_PAGE');

    this.getPage(1, filters, optionalColumns)
      .then(response => this.getPageSuccess({
        ...response,
        items: this.filterExcludedItems(response.items, excludeIds)
      }))
      .catch(error => this.handleError(error));
  }

  /**
   * Fetches the first page and callbacks with the first item. Is used when the first item in the page is set to the default selected
   * option.
   *
   * @param filters - Filters
   * @param excludeIds - Excluded IDs
   * @param callback - Callback with the first item of the page
   * @param optionalColumns - Optional columns that need to be resolved
   */
  public getFirstPageWithDefault(
    filters: FilterDto[],
    excludeIds: number[],
    callback: (first: any) => void,
    optionalColumns?: string[]
  ): void {
    this.setState(produce(this.getState(), draft => {
      draft.loading = true;
    }), 'GET_FIRST_PAGE_WITH_DEFAULT');

    this.getPage(1, filters, optionalColumns)
      .then(response => {
        const items = this.filterExcludedItems(
          uniqBy(response.items, item => item[this.idKey]),
          excludeIds
        );

        if (items?.length) {
          callback(items[0]);
        }

        this.getPageSuccess({
          ...response,
          items
        });
      })
      .catch(error => this.handleError(error));
  }

  /**
   * Fetches the next page after the first page has been fetched if there is a next page to be fetched.
   *
   * @param filters - Filters
   * @param excludeIds - Excluded IDs
   * @param optionalColumns - Optional columns that need to be resolved
   */
  public getNextPage(
    filters: FilterDto[] = [],
    excludeIds: number[] = [],
    optionalColumns?: string[]
  ): void {
    this.setState(produce(this.getState(), draft => {
      draft.loading = true;
    }), 'GET_NEXT_PAGE');

    const state = this.getState();
    if (state.paging?.pageNumber < state.paging?.totalPages) {
      this.getPage(state.paging.pageNumber + 1, filters, optionalColumns)
        .then(response => this.getPageSuccess({
          ...response,
          items: this.filterExcludedItems(
            uniqBy([...state.items, ...response.items], item => item[this.idKey]),
            excludeIds
          )
        }))
        .catch(error => this.handleError(error));
    } else {
      this.handleError('core-portal.core.error.unknown');
    }
  }

  /**
   * Puts the fetched items into the state.
   *
   * @param pageable - Response from the API
   */
  public getPageSuccess(pageable: PageableRequest<any>): void {
    this.setState(produce(this.getState(), draft => {
      draft.items = pageable.items;
      draft.paging = pageable.paging;
      draft.loading = false;
      draft.loaded = true;
      draft.error = false;
    }), 'GET_PAGE_SUCCESS');
  }

  /**
   * Resets the state to the initial state.
   */
  public clear(): void {
    this.setState(initialCorePortalEntitySelectStoreState, 'CLEAR');
  }

  /**
   * Shows the appropriate error via the {@link ApiNotificationService} and adjusts the state.
   *
   * @param error - The error received
   */
  public handleError(error: HttpErrorResponse | Error | string): void {
    this.apiNotificationService.handleApiError(error);

    this.setState({
      loading: false,
      loaded: false,
      error: true
    }, 'ERROR');
  }

  /**
   * @return Whether there is a next page or not
   */
  public hasNextPage(): boolean {
    const state = this.getState();
    return state.paging?.pageNumber < state.paging?.totalPages;
  }

  public selectItems(excludedIds: () => number[]): Observable<any[]> {
    return this.stateChanged.pipe(
      map(state => state.items),
      map(items => items.filter(item => !(excludedIds() ?? []).includes(item[this.idKey])))
    );
  }

  public selectPaging(): Observable<Paging> {
    return this.stateChanged.pipe(
      map(state => state.paging)
    );
  }

  public selectLoading(): Observable<boolean> {
    return this.stateChanged.pipe(
      map(state => state.loading)
    );
  }

  public selectLoaded(): Observable<boolean> {
    return this.stateChanged.pipe(
      map(state => state.loaded)
    );
  }

  public selectError(): Observable<boolean> {
    return this.stateChanged.pipe(
      map(state => state.error)
    );
  }

  public getLastAction(): string {
    if (!this.stateHistory.length) {
      return null;
    }

    return this.stateHistory[this.stateHistory.length - 1].action;
  }

  public untilNotLoading(): Promise<boolean> {
    return this.selectLoading().pipe(
      filter(loading => !loading),
      take(1)
    ).toPromise();
  }

  /**
   * Fetches a page from the API.
   *
   * @param pageNumber - The page number
   * @param filters - Filters
   * @param optionalColumns - Optional columns that need to be resolved
   */
  private getPage(pageNumber: number, filters: FilterDto[] = [],
                  optionalColumns: string[] = []): Promise<PageableRequest<any>> {
    if (this.overrideGetPage) {
      return this.overrideGetPage(pageNumber, PAGE_SIZE, filters);
    }

    return this.entityService.getPage(
      null,
      pageNumber,
      filters,
      FilterOperations.Include,
      [],
      optionalColumns,
      PAGE_SIZE
    ).toPromise();
  }

  private filterExcludedItems(items: any[], excludedIds: number[]): any[] {
    return items.filter(item => !excludedIds.includes(item[this.idKey]));
  }
}
