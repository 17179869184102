<p-sidebar #sidebarComponent
           (visibleChange)="onVisibleChange($event)"
           [transitionOptions]="transitionOptions"
           [visible]="visible$ | async"
           appendTo="body"
           position="right"
           styleClass="p-sidebar-xl">
  <div *ngIf="(model$ | async); else loadingTemplate"
       class="d-flex align-content-between flex-column h-100">

    <h6 class="pl-2 mb-2">{{ (model$ | async)?.name }}</h6>

    <div *ngIf="(visible$ | async) && (customParams$ | async)" class="overflow-y-auto">
      <nexnox-web-infos-info-progress-contact-read-unread-list [customParams]="customParams$ | async"
                                                               [isRemoveOfExpectedColumn]="isFunction$ | async"
                                                               [parentIds]="parentIds">
      </nexnox-web-infos-info-progress-contact-read-unread-list>
    </div>

    <div class="w-100 d-flex flex-shrink-1 gap-2 justify-content-end mt-auto mb-0">
      <button (click)="onHide()"
              [label]="'core-portal.core.general.close' | translate"
              class="p-button p-button-text p-button-secondary"
              pButton
              type="button">
      </button>
    </div>
  </div>
</p-sidebar>

<ng-template #loadingTemplate>
  <nexnox-web-loading-icon class="d-flex w-100 h-100 align-items-center justify-content-center flex-column p-5"
                           size="5x">
  </nexnox-web-loading-icon>
</ng-template>
