import {EntityXsStore, EntityXsStoreState} from '@nexnox-web/core-store';
import {
  AppEntityType,
  CollectionTaskDto,
  DailyDateTimePatternDto,
  DateTimePatternV1Dto,
  DocumentTaskDto,
  TaskDto
} from '@nexnox-web/core-shared';
import {createSelector} from '@ngrx/store';
import {selectTasksState} from '../../tasks.selectors';
import {OrgaPortalFeatureTaskService} from '../../services';
import {cloneDeep} from 'lodash';
import {WeekDay} from '@angular/common';

export interface TaskDetailStoreState extends EntityXsStoreState<TaskDto> {
}

/* istanbul ignore next */
export const taskDetailStore = new EntityXsStore<TaskDto>({
  actionLabel: 'Orga Portal - Tasks - Task Detail',
  stateSelector: createSelector(selectTasksState, state => state.taskDetail),
  serviceType: OrgaPortalFeatureTaskService,
  entityType: AppEntityType.Task,
  stereotyped: false,
  prepareEntity: (entity, base) => {
    const newEntity = base(entity) as TaskDto & DocumentTaskDto & CollectionTaskDto;

    if (OrgaPortalFeatureTaskService.isExecutionPlanWithPatterns(newEntity.executionPlan) && newEntity.executionPlan?.patterns?.length) {
      newEntity.executionPlan.patterns = newEntity.executionPlan.patterns.map(pattern => {
        const newPattern = cloneDeep(pattern) as DateTimePatternV1Dto & DailyDateTimePatternDto;
        const weekDays: WeekDay[] = [];

        if (newPattern.monday) weekDays.push(WeekDay.Monday);
        if (newPattern.tuesday) weekDays.push(WeekDay.Tuesday);
        if (newPattern.wednesday) weekDays.push(WeekDay.Wednesday);
        if (newPattern.thursday) weekDays.push(WeekDay.Thursday);
        if (newPattern.friday) weekDays.push(WeekDay.Friday);
        if (newPattern.saturday) weekDays.push(WeekDay.Saturday);
        if (newPattern.sunday) weekDays.push(WeekDay.Sunday);
        (newPattern as any).weekDays = weekDays;

        if (newPattern.startDate && newPattern.endDate) {
          (newPattern as any).dateRange = `${ newPattern.startDate } - ${ newPattern.endDate }`;
        }

        return newPattern;
      });
    }

    return newEntity;
  },
  sanitizeModel: OrgaPortalFeatureTaskService.sanitizeModel
});
