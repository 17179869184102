<nexnox-web-entity-edit [entityEditBaseComponent]="this"
                        [stereotyped]="false">

  <div class="row mt-3">
    <div class="col-12 mb-3">
      <h5 class="mb-3">{{ 'core-shared.shared.fields.resources' | translate }}</h5>
      <nexnox-web-formly-table (modelChange)="onSkeletonsChange($event)"
                               (validChange)="formTableValidityChange.emit($event)"
                               [fields]="createSkeletonFields()"
                               [model$]="skeletons$">
      </nexnox-web-formly-table>
    </div>
  </div>
</nexnox-web-entity-edit>

