import {PagedEntitiesXsStore, PagedEntitiesXsStoreState} from '@nexnox-web/core-store';
import {AppEntityType, MessageControlListDto} from '@nexnox-web/core-shared';
import {Action, ActionReducer, createSelector} from '@ngrx/store';
import {selectControlsState} from '../../controls.selectors';
import {TechPortalFeatureControlIssueService} from '../../services';
import {Injectable, Injector} from '@angular/core';

export interface ControlIssueListStoreState extends PagedEntitiesXsStoreState<MessageControlListDto> {
}

/* istanbul ignore next */
export const controlIssueListStore = new PagedEntitiesXsStore<MessageControlListDto>({
  actionLabel: 'Core Portal - Controls - Control Issue List',
  stateSelector: createSelector(selectControlsState, state => state.controlIssueList),
  serviceType: TechPortalFeatureControlIssueService,
  entityType: AppEntityType.None,
  selectId: issue => issue.entity?.issueId ?? issue.model?.issueId
});

export function controlIssueListStoreReducer(state: ControlIssueListStoreState, action: Action): ActionReducer<any> {
  return controlIssueListStore.reducer(state, action);
}

@Injectable()
export class ControlIssueListStoreEffects extends controlIssueListStore.effects {
  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }
}
