import {NgModule} from '@angular/core';
import {
  LocationGroupDetailComponent,
  LocationGroupListComponent,
  LocationGroupLocationListComponent
} from './containers';
import {CorePortalFeatureLocationGroupEditComponent} from './components';
import {CoreSharedModule} from '@nexnox-web/core-shared';
import {CorePortalEntityModule} from '@nexnox-web/core-portal';
import {locationGroupsServices} from './store';
import {CorePortalFeatureMasterDataLocationsModule} from '@nexnox-web/core-portal/features/master-data/features/locations';

@NgModule({
  declarations: [
    LocationGroupListComponent,
    LocationGroupDetailComponent,
    LocationGroupLocationListComponent,

    CorePortalFeatureLocationGroupEditComponent
  ],
  imports: [
    CoreSharedModule,
    CorePortalEntityModule,

    CorePortalFeatureMasterDataLocationsModule.forFeature()
  ],
  providers: [
    ...locationGroupsServices
  ],
  exports: [
    CorePortalFeatureLocationGroupEditComponent
  ]
})
export class LocationGroupsDeclarationsModule {
}
