<nexnox-web-cardbox [headerActions]="headerActions"
                    [loading]="loading"
                    [title]="'core-portal.settings.subtitles.custom-property-sets' | translate"
                    ghostPaddingClasses="px-3 pb-3"
                    paddingClasses="px-3 pb-3">
  <nexnox-web-settings-stereotypes-custom-property-set-edit #customPropertySetEditComponent
                                                            (delete)="onDelete(customSetReference.position)"
                                                            (modelChange)="onCustomPropertySetChange($event, customSetReference.position)"
                                                            (moveDown)="onMoveDown(customSetReference.position)"
                                                            (moveUp)="onMoveUp(customSetReference.position)"
                                                            *ngFor="let customSetReference of (customPropertySets$ | async | nexnoxWebSortCustomPropertySets);
                                                                    let isFirst = first;
                                                                    let isLast = last;
                                                                    trackBy: trackSetsBy"
                                                            [activeTenant$]="activeTenant$"
                                                            [customPropertyDropdownOptions]="customPropertyDropdownOptions"
                                                            [customPropertySetType]="customPropertySetType"
                                                            [customPropertySet]="customSetReference.customPropertySet"
                                                            [customSetReference]="customSetReference"
                                                            [isFirst]="isFirst"
                                                            [isInherited]="customSetReference.isInherited"
                                                            [isLast]="isLast"
                                                            [isRateable]="isRateable$ | async"
                                                            [position]="customSetReference.position"
                                                            [readonly]="readonly">
  </nexnox-web-settings-stereotypes-custom-property-set-edit>

  <span *ngIf="!(customPropertySets$ | async).length" class="text-muted">
    {{ 'core-portal.settings.descriptions.no-custom-property-sets' | translate }}
  </span>
</nexnox-web-cardbox>
