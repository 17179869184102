<div [class]="isLast ? 'mb-0' : 'mb-5'"
     [id]="customSetReference.customSetReferenceAnchorId">
  <p-panel styleClass="panel-mp">
    <ng-template pTemplate="header">
      <div class="d-flex flex-row w-100">
        <div class="d-flex align-items-center flex-wrap position-relative">
          <p-badge *ngIf="isInherited"
                   [value]="'core-portal.settings.descriptions.is-inherited' | translate"
                   styleClass="d-flex bg-secondary mr-2 align-middle">
          </p-badge>
        </div>

        <div class="d-flex flex-grow-1 align-items-center mw-100">
          <nexnox-web-cardbox-actions [actions]="headerActions"
                                      [hasFlexContent]="true"
                                      [readonly]="readonly"
                                      [useNew]="true"
                                      class="w-100">
            <div [style.min-width.px]="50" class="w-100">
              <span *ngIf="readonly || isInherited; else nameEditTemplate"
                    [title]="customPropertySet.name"
                    class="text-ellipsis">
                {{ customPropertySet.name }}
              </span>

              <ng-template #nameEditTemplate>
                <input #nameEditInput
                       (ngModelChange)="onNameChange($event)"
                       [ngModel]="customPropertySet.name"
                       [required]="true"
                       [style.height.px]="29"
                       class="form-control"
                       pInputText
                       title=""
                       type="text">
              </ng-template>
            </div>
          </nexnox-web-cardbox-actions>
        </div>
      </div>
    </ng-template>

    <div class="row">
      <div *ngFor="let customProperty of customPropertySet.properties;
                   let isFirst = first;
                   let isLast = last;
                   trackBy: trackPropertiesBy"
           class="col-md-12 mb-3">
        <nexnox-web-settings-stereotypes-custom-property-edit #customPropertyEditComponent
                                                              (delete)="onDelete(customProperty.position)"
                                                              (modelChange)="onCustomPropertyChange($event, customProperty.position)"
                                                              (moveDown)="onMoveDown(customProperty.position)"
                                                              (moveUp)="onMoveUp(customProperty.position)"
                                                              [customPropertySetType]="customPropertySetType"
                                                              [customProperty]="customProperty"
                                                              [isFirst]="isFirst"
                                                              [isLast]="isLast"
                                                              [isRateable]="isRateable"
                                                              [readonly]="readonly || isInherited">
        </nexnox-web-settings-stereotypes-custom-property-edit>
      </div>
    </div>

    <div *ngIf="!customPropertySet.properties?.length" class="px-1 pb-3">
      <span class="text-muted">
        {{ 'core-portal.settings.descriptions.no-custom-properties' | translate }}
      </span>
    </div>
  </p-panel>
</div>
