import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {
  ApiNotificationService,
  AppEntityType,
  CoreSharedApiBaseService,
  CoreSharedSidebarBaseComponent,
  CrossCreationConfig,
  CrossCreationTypes,
  TenantInfoDto
} from '@nexnox-web/core-shared';
import {lastValueFrom, merge, Observable} from 'rxjs';
import {CorePortalEntityEditBaseComponent} from "../../../entity-edit/base-components/entity-edit-base/entity-edit-base.component";
import {authStore} from "../../../../../store/core/auth/auth.store";
import {CORE_PORTAL_CROSS_CREATION_CONFIG} from "../../../../../tokens/cross-creation.token";
import {TranslateService} from "@ngx-translate/core";
import {faTimes} from "@fortawesome/free-solid-svg-icons/faTimes";
import {map, shareReplay, startWith, take, withLatestFrom} from "rxjs/operators";
import {values} from "lodash";
import {select, Store} from "@ngrx/store";
import {faPlus} from "@fortawesome/free-solid-svg-icons/faPlus";
import {CorePortalPermissionService} from "../../../../../services/permission/permission.service";

@Component({
  selector: 'nexnox-web-cross-creation-sidebar',
  templateUrl: './cross-creation-sidebar.component.html',
  styleUrls: ['./cross-creation-sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CrossCreationSidebarComponent extends CoreSharedSidebarBaseComponent implements OnInit {

  @ViewChild('editComponentContainer', {
    read: ViewContainerRef,
    static: true
  }) public editContainer!: ViewContainerRef;

  @Input() public crossCreationType: CrossCreationTypes;

  @Input() public service: CoreSharedApiBaseService;

  @Input() public displayTitle: string;

  @Output() public refreshAfterCreate: EventEmitter<object> = new EventEmitter<object>();

  public disablePrimaryAction$: Observable<boolean>;

  public entityType: AppEntityType;

  public config: CrossCreationConfig;

  public editComponent: CorePortalEntityEditBaseComponent<any>;

  public hasCreatePermission: boolean;

  public isInitialized: boolean;

  public readonly faTimes = faTimes;
  public readonly faPlus = faPlus;

  constructor(
    @Inject(CORE_PORTAL_CROSS_CREATION_CONFIG) private crossCreationConfig: CrossCreationConfig[],
    private translate: TranslateService,
    private store: Store,
    public apiNotificationService: ApiNotificationService,
    private permissionService: CorePortalPermissionService
  ) {
    super();
  }

  public async ngOnInit(): Promise<void> {
    this.config = this.crossCreationConfig.find((item) => item.crossCreationType === this.crossCreationType);
    this.entityType = this.config?.entityType;
    this.hasCreatePermission = await this.permissionService.hasPermission(this.config?.createPermission);
  }

  public restoreDefaults(): void {
    this.editContainer.clear();
    this.isInitialized = false;
  }

  public onHide(): void {
    super.onHide();
    this.restoreDefaults();
  }

  public onShow(): void {
    if (this.hasCreatePermission) {
      if (!this.isInitialized) this._createEditComponent();
      super.onShow();
    } else {
      this.apiNotificationService.showTranslatedError('core-portal.user.signin.access-denied');
    }
  }

  public async onCreate(): Promise<void> {
    this.editComponent.loading = true;

    const tenantId = await lastValueFrom(this.store.pipe(
      select(authStore.selectors.selectActiveTenant),
      map((tenant: TenantInfoDto) => tenant?.tenantId),
      take(1)
    ));

    this.subscribe(this.service.createOne({ ...this.editComponent.model, rowVersion: [], tenantId }).pipe(take(1)),
      (response) => {
        this.refreshAfterCreate.emit(response);
        this.apiNotificationService.showTranslatedSuccess('core-shared.shared.toast.entity-created');
        this.onHide();
      },
      (error) => {
        this.apiNotificationService.handleApiError(error);
        this.onHide();
      });
  }

  private async _createEditComponent(): Promise<void> {

    // Pass config params
    this.editComponent = this.editContainer.createComponent(this.config.editComponent).instance as unknown as CorePortalEntityEditBaseComponent<any>;
    this.editComponent.model = { [this.config.displayKey]: this.displayTitle };
    this.editComponent.title = this.translate.instant(this.config.createTitle);

    // Configure create
    this.editComponent.creating = true;
    this.editComponent.readonly = false;
    this.editComponent.isCrossCreation = true;

    // Stereotypes
    // They get loaded once and then cached
    this.editComponent.stereotypes$ = this.service.getStereotypes(this.entityType, true).pipe(
      take(1),
      // Validate to empty array
      map((stereotypes) => stereotypes ?? []),
      // Cache
      shareReplay({
        bufferSize: 1,
        refCount: false,
      })
    );
    // Start loading stereotypes in any case (even if not stereotyped) to ensure clean startup loading animation
    this.editComponent.loading = true;
    this.subscribe(this.editComponent.stereotypes$.pipe(take(1)), () => this.editComponent.loading = false);

    // Validation
    const shouldDisablePrimaryAction$ = this.editComponent.modelValidSubject.pipe(
      map(dictionary => values(dictionary).some(value => value === false) || !this.editComponent?.isOwnModelValid())
    ).pipe(startWith(!this.editComponent?.isOwnModelValid()));

    this.disablePrimaryAction$ = merge(
      // disable on invalid model
      shouldDisablePrimaryAction$,
      // disable on loading
      this.editComponent.loadingSubject.pipe(
        withLatestFrom(shouldDisablePrimaryAction$),
        map(([isLoading, shouldDisable]) => isLoading || shouldDisable)
      )
    );

    // Mark initialization
    this.isInitialized = true;
  }
}
