<nexnox-web-entity-overview #overviewComponent
                            [cardBoxHeaderActions]="headerActions"
                            [detailLink]="'/manufacturers/' + parentIds[0] + '/model'"
                            [entityOverviewBaseComponent]="this"
                            [stereotyped]="false">
</nexnox-web-entity-overview>

<nexnox-web-move-model-sidebar #moveModelSidebar
                               (move)="moveModel($event)"
                               [modelToMove]="modelToMergeOrMoveSubject">
</nexnox-web-move-model-sidebar>

<nexnox-web-merge-model-sidebar #mergeModelSidebar
                                (merge)="mergeModel($event)"
                                [modelToMerge]="modelToMergeOrMoveSubject">
</nexnox-web-merge-model-sidebar>
