import {ModuleWithProviders, NgModule} from "@angular/core";
import {IssueTemplatesDeclarationsModule} from "./issue-templates-declarations.module";
import {IssueTemplatesRoutingModule} from "./issue-templates-routing.module";
import {IssueTemplatesStoreModule} from "./issue-templates-store.module";

@NgModule({
  imports: [IssueTemplatesRoutingModule, IssueTemplatesDeclarationsModule, IssueTemplatesStoreModule]
})
export class CorePortalFeatureIssueTemplatesModule {
  public static forFeature(): ModuleWithProviders<IssueTemplatesDeclarationsModule> {
    return { ngModule: IssueTemplatesDeclarationsModule };
  }
}
