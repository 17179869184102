import {
  CoreSharedApiBaseService,
  Product,
  RenderedTemplateDto,
  RenderTemplateDto,
  TemplateContextType
} from '@nexnox-web/core-shared';
import {Injectable, Injector} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable()
export class TechPortalFeatureTextTemplateService extends CoreSharedApiBaseService {
  constructor(
    protected injector: Injector
  ) {
    super(injector, Product.TECH, 'template');
  }

  public previewTemplate(template: string, context: TemplateContextType): Observable<RenderedTemplateDto> {
    return this.httpRequestService.post(`${ this.resource }/preview`, { template, context }).pipe(
      map(response => response.body)
    );
  }

  public renderTemplate(templateId: number, model: RenderTemplateDto): Observable<RenderedTemplateDto> {
    return this.httpRequestService.post(`${ this.buildPathForOne(templateId) }/render`, model).pipe(
      map(response => response.body)
    );
  }
}
