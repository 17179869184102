import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {ErrorCodeDto} from '@nexnox-web/core-shared';
import {BindObservable} from 'bind-observable';
import {Observable} from 'rxjs';
import {map, mergeMap} from 'rxjs/operators';

@Component({
  selector: 'nexnox-web-connected-error-codes-error-code-external-id',
  templateUrl: './error-code-external-id.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TechPortalFeatureErrorCodeExternalIdComponent implements OnInit {
  @Input() @BindObservable() public errorCode: ErrorCodeDto;
  public errorCode$!: Observable<ErrorCodeDto>;

  @Input() public isLabel = true;
  @Input() public searchTerm: string;

  public title$: Observable<string>;
  public titleHtml$: Observable<string>;
  public titlePrefix$: Observable<string>;
  public titlePrefixHtml$: Observable<string>;

  public static prepareTitlePrefix(errorCode: ErrorCodeDto): string {
    return `${ errorCode.externalId }: `;
  }

  public static prepareTitle(errorCode: ErrorCodeDto, prefix: string): string {
    return `${ prefix }${ errorCode.displayName }`;
  }

  public ngOnInit(): void {
    this.titlePrefix$ = this.errorCode$.pipe(
      map(errorCode => TechPortalFeatureErrorCodeExternalIdComponent.prepareTitlePrefix(errorCode))
    );

    this.titlePrefixHtml$ = this.titlePrefix$.pipe(
      map(prefix => `<span class="text-muted">${ prefix }</span>`)
    );

    this.title$ = this.errorCode$.pipe(
      mergeMap(errorCode => this.titlePrefix$.pipe(
        map(prefix => TechPortalFeatureErrorCodeExternalIdComponent.prepareTitle(errorCode, prefix))
      ))
    );

    this.titleHtml$ = this.errorCode$.pipe(
      mergeMap(errorCode => this.titlePrefixHtml$.pipe(
        map(prefix => TechPortalFeatureErrorCodeExternalIdComponent.prepareTitle(errorCode, prefix))
      ))
    );
  }
}
