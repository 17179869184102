import {ChangeDetectionStrategy, Component, Injector, OnInit, ViewChild} from '@angular/core';
import {
  ActionButton,
  CorePortalAttachmentsComponent,
  CorePortalEntityDetailBaseComponent,
  CorePortalPermissionService
} from '@nexnox-web/core-portal';
import {
  AppEntityType,
  AppPermissions,
  AttachmentForTechDto,
  ClosingLocationOptionsDto,
  LocationDto,
  LocationState
} from '@nexnox-web/core-shared';
import {locationDetailStore} from '../../store';
import {Observable} from 'rxjs';
import {faPlus} from '@fortawesome/free-solid-svg-icons/faPlus';
import {map, take} from 'rxjs/operators';
import {isEmpty} from 'lodash';
import {faDoorClosed} from "@fortawesome/free-solid-svg-icons/faDoorClosed";
import {faDoorOpen} from "@fortawesome/free-solid-svg-icons/faDoorOpen";
import {LocationStatusSidebarComponent} from "./../../sidebars";
import {ofType} from "@ngrx/effects";

@Component({
  selector: 'nexnox-web-master-data-locations-location-detail',
  templateUrl: './location-detail.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LocationDetailComponent extends CorePortalEntityDetailBaseComponent<LocationDto> implements OnInit {

  @ViewChild('attachmentsComponent') public attachmentsComponent: CorePortalAttachmentsComponent;
  @ViewChild('locationStatusSidebar') public locationStatusSidebar: LocationStatusSidebarComponent;

  public title = 'core-portal.master-data.subtitles.location-detail';
  public attachments$: Observable<AttachmentForTechDto[]>;
  public locationStereotypeId$: Observable<number>;
  public locationId$: Observable<string | number>;

  public displayAddressesTab$: Observable<boolean>;
  public displayContactsTab$: Observable<boolean>;
  public displayResourcesTab$: Observable<boolean>;
  public displayMissionsTab$: Observable<boolean>;
  public displayIssuesTab$: Observable<boolean>;
  public displayTicketsTab$: Observable<boolean>;
  public displayTasksTab$: Observable<boolean>;
  public displayPlannedTab$: Observable<boolean>;
  public displayContractsTab$: Observable<boolean>;
  public displayAttachmentsTab$: Observable<boolean>;
  public displayLocationGroupsTab$: Observable<boolean>;
  public closeSidebar$: Observable<any>;
  public readAuditPermission$: Observable<boolean>;
  public entityTypes = AppEntityType;
  protected idParam = 'locationId';
  protected displayKey = 'name';

  constructor(
    protected injector: Injector,
    private permissionService: CorePortalPermissionService
  ) {
    super(injector, locationDetailStore);

    this.locationId$ = this.model$.pipe(map(model => model?.locationId));

    this.displayAddressesTab$ = this.permissionService.hasPermission$(AppPermissions.DisplayAddresses);
    this.displayContactsTab$ = this.permissionService.hasPermission$(AppPermissions.DisplayContacts);
    this.displayResourcesTab$ = this.permissionService.hasPermission$(AppPermissions.DisplayResources);
    this.displayIssuesTab$ = this.permissionService.hasPermission$(AppPermissions.ReadIssue);
    this.displayMissionsTab$ = this.permissionService.hasPermission$(AppPermissions.DisplayMissions);
    this.displayTicketsTab$ = this.permissionService.hasPermission$(AppPermissions.DisplayTickets);
    this.displayTasksTab$ = this.permissionService.hasPermission$(AppPermissions.DisplayResourceTasks);
    this.displayPlannedTab$ = this.permissionService.hasPermission$(AppPermissions.ReadTask);
    this.displayContractsTab$ = this.permissionService.hasPermission$(AppPermissions.DisplayContracts);
    this.displayAttachmentsTab$ = this.permissionService.hasPermission$(AppPermissions.ReadAttachment);
    this.displayLocationGroupsTab$ = this.permissionService.hasPermission$(AppPermissions.DisplayLocationGroups);
    this.readAuditPermission$ = this.permissionService.hasPermission$(AppPermissions.ReadAudit);
  }

  public ngOnInit(): void {
    super.ngOnInit();

    this.attachments$ = this.model$.pipe(
      map(model => model?.attachments ?? [])
    );

    this.locationStereotypeId$ = this.model$.pipe(
      map(model => model?.stereotypeId)
    );

    // Location state sidebar hides after success actions
    this.closeSidebar$ = this.actions$.pipe(ofType(
      locationDetailStore.actions.closeLocationSuccess,
      locationDetailStore.actions.openLocationSuccess,
      locationDetailStore.actions.error
    ));
    this.subscribe(this.closeSidebar$, () => {
      this.locationStatusSidebar?.onHide()
    });
  }

  public onAttachmentsChange(attachments: AttachmentForTechDto[], model: LocationDto): void {
    this.onModelChange({ ...model, attachments });
  }

  public onCloseLocation(model: ClosingLocationOptionsDto): void {
    this.store.dispatch(locationDetailStore.actions.closeLocation(model));
  }

  public onOpenLocation(): void {
    this.store.dispatch(locationDetailStore.actions.openLocation());
  }

  /* istanbul ignore next */
  protected async getActionButtons(): Promise<ActionButton[]> {
    return [
      ...this.getDefaultActionButtons(
        'core-portal.master-data.actions.edit-location',
        'core-portal.master-data.actions.save-location',
        'core-portal.master-data.actions.delete-location',
        'core-portal.master-data.descriptions.delete-location',
        AppPermissions.UpdateLocation,
        AppPermissions.DeleteLocation,
        ['/masterdata/locations']
      ),
      {
        label: 'core-portal.master-data.actions.create-ticket-from-location',
        type: 'button',
        class: 'btn-outline-secondary',
        permission: AppPermissions.CreateTicket,
        icon: faPlus,
        shouldShow: () => this.entity$.pipe(
          map(entity => Boolean(entity) && !isEmpty(entity))
        ),
        callback: async () => {
          const entity = await this.entity$.pipe(take(1)).toPromise();

          if (entity) {
            this.entityCreatePresetService.setPreset('TechPortalFeatureTicketEditComponent', {
              locationId: entity.locationId,
              location: entity
            });
            await this.tenantRouter.navigate(['/tickets'], { module: 'communication', fragment: 'create' });
          }
        }
      },
      {
        label: 'core-portal.master-data.actions.create-mission-from-location',
        type: 'button',
        class: 'btn-outline-secondary',
        permission: AppPermissions.CreateMission,
        icon: faPlus,
        shouldShow: () => this.entity$.pipe(
          map(entity => Boolean(entity) && !isEmpty(entity))
        ),
        callback: async () => {
          const entity = await this.entity$.pipe(take(1)).toPromise();

          if (entity) {
            this.entityCreatePresetService.setPreset('TechPortalFeatureMissionCreateComponent', {
              preset: {
                location: entity
              }
            });
            await this.tenantRouter.navigate(['/missions/all'], { module: 'inventory', fragment: 'create' });
          }
        }
      },
      {
        label: 'core-portal.master-data.actions.create-contract-from-location',
        type: 'button',
        class: 'btn-outline-secondary',
        permission: AppPermissions.CreateContract,
        icon: faPlus,
        shouldShow: () => this.entity$.pipe(
          map(entity => Boolean(entity) && !isEmpty(entity))
        ),
        callback: async () => {
          const entity = await this.entity$.pipe(take(1)).toPromise();

          if (entity) {
            this.entityCreatePresetService.setPreset('ContractResponsibilityEditComponent', { locations: [entity] });
            await this.tenantRouter.navigate(['/contracts'], { module: 'inventory', fragment: 'create' });
          }
        }
      },
      {
        label: 'core-portal.master-data.location.fields.close-location',
        type: 'button',
        class: 'btn-outline-secondary',
        permission: AppPermissions.UpdateLocation,
        icon: faDoorClosed,
        shouldShow: () => this.model$.pipe(map(model => model?.state === LocationState.Open)),
        callback: () => this.locationStatusSidebar.onShow(true)
      },
      {
        label: 'core-portal.master-data.location.fields.open-location',
        type: 'button',
        class: 'btn-outline-secondary',
        permission: AppPermissions.UpdateLocation,
        icon: faDoorOpen,
        shouldShow: () => this.model$.pipe(map(model => model?.state === LocationState.Closed)),
        callback: () => this.locationStatusSidebar.onShow(false)
      }
    ];
  }
}
