import {
  BaseXsStore,
  BaseXsStoreReducerTypes,
  createPagedEntitiesXsStoreActions,
  PagedEntitiesXsStore,
  PagedEntitiesXsStoreActions,
  PagedEntitiesXsStoreEffects,
  pagedEntitiesXsStoreSetLoadingForId,
  PagedEntitiesXsStoreState,
  PropsAction
} from '@nexnox-web/core-store';
import {AppEntityType, TicketEventCompleteDto} from '@nexnox-web/core-shared';
import {Action, createAction, on, props} from '@ngrx/store';
import {Injectable, Injector, Type} from '@angular/core';
import {createEffect, ofType} from '@ngrx/effects';
import {catchError, groupBy, map, mergeMap, switchMap, tap} from 'rxjs/operators';
import {TicketEventService} from '../../services';
import {of} from 'rxjs';
import {immerOn} from 'ngrx-immer/store';

export interface TicketEventsXsStoreCancelOnePayload {
  id: number | string;
  parentIds?: Array<number | string>;
}

export interface TicketEventsXsStoreActions extends PagedEntitiesXsStoreActions<TicketEventCompleteDto> {
  cancelOne: PropsAction<TicketEventsXsStoreCancelOnePayload>;
  cancelOneSuccess: PropsAction<TicketEventsXsStoreCancelOnePayload>;
}

export class TicketEventsXsStore extends PagedEntitiesXsStore<TicketEventCompleteDto> {
  public actions: TicketEventsXsStoreActions;

  protected createActions(label: string): TicketEventsXsStoreActions {
    return {
      ...createPagedEntitiesXsStoreActions(label),

      cancelOne: createAction(
        BaseXsStore.getType(label, 'Cancel one'),
        props<TicketEventsXsStoreCancelOnePayload>()
      ),
      cancelOneSuccess: createAction(
        BaseXsStore.getType(label, 'Cancel one success'),
        props<TicketEventsXsStoreCancelOnePayload>()
      )
    };
  }

  protected createReducerArray(
    initialState: PagedEntitiesXsStoreState<TicketEventCompleteDto>
  ): BaseXsStoreReducerTypes<PagedEntitiesXsStoreState<TicketEventCompleteDto>, TicketEventsXsStoreActions>[] {
    return [
      ...super.createReducerArray(initialState),

      immerOn(this.actions.cancelOne, (draft, { id }) => {
        draft.entityData = pagedEntitiesXsStoreSetLoadingForId(draft.entityData, id, {
          cancelOne: true
        });
      }),

      on(this.actions.cancelOneSuccess, (state, { id }) => this.adapter.removeOne(id.toString(), {
        ...state,
        entityData: pagedEntitiesXsStoreSetLoadingForId(state.entityData, id, {
          cancelOne: false
        })
      }))
    ];
  }

  protected createEffects(
    serviceType: Type<TicketEventService>,
    entityType: AppEntityType,
    prepareEntity: (entity: TicketEventCompleteDto) => TicketEventCompleteDto,
    prepareModel: (entity: TicketEventCompleteDto, model: TicketEventCompleteDto) => TicketEventCompleteDto,
    sanitizeModel: (model: TicketEventCompleteDto, entity: TicketEventCompleteDto) => TicketEventCompleteDto,
    ...args
  ): Type<PagedEntitiesXsStoreEffects<TicketEventCompleteDto>> {
    const actions = this.actions;
    const selectors = this.selectors;

    @Injectable()
    class Effects extends PagedEntitiesXsStoreEffects<TicketEventCompleteDto> {
      public cancelOne$: any;
      public cancelOneSuccess$: any;

      protected actions: TicketEventsXsStoreActions;
      protected service: TicketEventService;

      constructor(
        protected injector: Injector
      ) {
        super(injector, actions, selectors, serviceType, entityType, prepareEntity, prepareModel, sanitizeModel, false);
      }

      protected createEffects(): void {
        super.createEffects();

        this.cancelOne$ = createEffect(() => this.actions$.pipe(
          ofType(this.actions.cancelOne),
          groupBy(({ id }) => id),
          mergeMap(group => group.pipe(
            switchMap(({ id, parentIds }) => this.service.cancelOne(id, parentIds ?? []).pipe(
              map(() => this.actions.cancelOneSuccess({ id, parentIds })),
              catchError(error => of(this.actions.error({ error, action: this.actions.cancelOne })))
            ))
          ))
        ));

        this.cancelOneSuccess$ = createEffect(() => this.actions$.pipe(
          ofType(this.actions.cancelOneSuccess),
          tap(action => this.actionCallback(action, false))
        ), { dispatch: false });
      }

      protected actionCallback(action: Action, isError: boolean): void {
        super.actionCallback(action, isError);

        this.checkAction(this.actions.cancelOneSuccess, action, () => this.cancelOneSuccessActionCallback());
      }

      protected cancelOneSuccessActionCallback(): void {
        this.apiNotificationService.showTranslatedSuccess('tickets.toasts.event-cancelled');
      }
    }

    return Effects;
  }
}
