import {ChangeDetectionStrategy, Component, Injector, Input, OnInit} from '@angular/core';
import {
  AppEntityType,
  Mappers,
  Orders,
  Paging,
  ResourceEventListDto,
  ResourceEventType,
  SortObject
} from '@nexnox-web/core-shared';
import {map, mergeMap} from 'rxjs/operators';
import {BehaviorSubject, Observable} from "rxjs";
import {TranslateService} from "@ngx-translate/core";
import {faSync} from "@fortawesome/free-solid-svg-icons/faSync";
import {CorePortalEntityOverviewBaseComponent} from "@nexnox-web/core-portal";
import {resourceEventListStore} from "../../store";

@Component({
  selector: 'nexnox-web-resources-resource-event-list',
  templateUrl: './resource-event-list.component.html',
  styleUrls: ['./resource-event-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResourceEventListComponent extends CorePortalEntityOverviewBaseComponent<ResourceEventListDto> implements OnInit {
  @Input() public relationId: number;
  @Input() public relationIdProperty: string;

  public componentId = 'ResourceEventListComponent';
  public title = 'resources.subtitles.resource-events';
  public createTitle = 'resources.subtitles.resource-event-item-create';
  public idProperty = 'resourceEventId';
  public displayProperty = null;

  public declare translate: TranslateService;
  public faSync = faSync;

  public filteredEvents$: Observable<any[]>;

  public filterItemsSubject: BehaviorSubject<ResourceEventType[]> = new BehaviorSubject<ResourceEventType[]>([
    ResourceEventType.ChangedLocation,
    ResourceEventType.ChangedState
  ]);

  public filterItemOptions = [
    { label: 'resources.resource-event-types.0', value: ResourceEventType.ChangedState },
    { label: 'resources.resource-event-types.1', value: ResourceEventType.ChangedLocation }
  ];

  protected defaultSortOptions: SortObject = {
    sortField: 'createdAt',
    sort: Orders.Descending
  };

  constructor(
    protected injector: Injector
  ) {
    super(injector, resourceEventListStore, Mappers.ResourceEventListDto.serializedName, AppEntityType.Resource);
  }

  public ngOnInit(): void {
    super.ngOnInit();

    this.filteredEvents$ = this.filterItemsSubject.pipe(
      mergeMap(filters =>
        this.entities$?.pipe(map((events) =>
          events.filter(event => filters.includes(event.entity.type))))));
  }

  public onChangeShowFilter(filters: ResourceEventType[]): void {
    this.filterItemsSubject?.next(filters);
  }

  public onRefresh(): void {
    this.onLoadPage({
      filters: undefined,
      sortOptions: undefined,
      pageNumber: undefined
    });
  }

  public onMore(paging: Paging): void {
    this.getEntities(
      paging.pageNumber < paging.totalPages ? paging.pageNumber + 1 : paging.pageNumber,
      undefined,
      undefined,
      undefined,
      true
    ).then(null);
  }
}
