<ng-template #entityEditTemplate let-title="title">
  <nexnox-web-service-tenants-tenant-edit #editComponent
                                          (modelChange)="createModel$.next($event)"
                                          [creating]="true"
                                          [loading]="(loaded$ | async) === false"
                                          [model]="createModel$ | async"
                                          [readonly]="false"
                                          [stereotyped]="false"
                                          [title]="title">
  </nexnox-web-service-tenants-tenant-edit>
</ng-template>

<nexnox-web-entity-overview #overviewComponent
                            [entityEditTemplate]="entityEditTemplate"
                            [entityOverviewBaseComponent]="this"
                            [stereotyped]="false"
                            detailLink="/tenants"
                            module="service">
</nexnox-web-entity-overview>
