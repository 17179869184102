import {
  BaseXsStore,
  BaseXsStoreReducerTypes,
  createEntityXsStoreActions,
  EmptyAction,
  EntityXsStore,
  EntityXsStoreActions,
  entityXsStoreSetLoadingForAction,
  EntityXsStoreState,
  PropsAction
} from '@nexnox-web/core-store';
import {createAction, props} from '@ngrx/store';
import {
  MissionDetailXsStoreAddFollowUpsPayload,
  MissionDetailXsStoreAddFollowUpsSuccessPayload,
  MissionDetailXsStoreAssignToMePayload,
  MissionDetailXsStoreAssignToPayload,
  MissionDetailXsStoreChangeResourcePayload,
  MissionDetailXsStoreChangeStatePayload,
  MissionDetailXsStoreChangeStateSuccessPayload,
  MissionDetailXsStoreExportPayload,
  MissionDetailXsStoreExportSuccessPayload,
  MissionDetailXsStoreSendMailSuccessPayload,
  MissionDetailXsStoreUnassignPayload
} from './mission-detail-xs-store.payloads';
import {immerOn} from 'ngrx-immer/store';
import {ILocalMissionDto} from "../../../components";
import {MissionCompleteDto} from "@nexnox-web/core-shared";

export interface MissionDetailXsStoreActions extends EntityXsStoreActions<ILocalMissionDto> {
  changeState: PropsAction<MissionDetailXsStoreChangeStatePayload>;
  changeStateSuccess: PropsAction<MissionDetailXsStoreChangeStateSuccessPayload>;

  sendMail: EmptyAction;
  sendMailSuccess: PropsAction<MissionDetailXsStoreSendMailSuccessPayload>;

  export: PropsAction<MissionDetailXsStoreExportPayload>;
  exportSuccess: PropsAction<MissionDetailXsStoreExportSuccessPayload>;

  complete: EmptyAction;
  completeSuccess: PropsAction<MissionCompleteDto>;

  resume: EmptyAction;
  resumeSuccess: EmptyAction;

  assignContactToMe: PropsAction<MissionDetailXsStoreAssignToMePayload>;
  assignContactToMeSuccess: PropsAction<MissionDetailXsStoreAssignToPayload>;

  assignContact: PropsAction<MissionDetailXsStoreAssignToPayload>;
  assignContactSuccess: PropsAction<MissionDetailXsStoreAssignToPayload>;

  unassignContact: PropsAction<MissionDetailXsStoreUnassignPayload>;
  unassignContactSuccess: PropsAction<MissionDetailXsStoreUnassignPayload>;

  assignResource: PropsAction<MissionDetailXsStoreChangeResourcePayload>;
  assignResourceSuccess: PropsAction<MissionDetailXsStoreChangeResourcePayload>;

  addFollowUps: PropsAction<MissionDetailXsStoreAddFollowUpsPayload>;
  addFollowUpsSuccess: PropsAction<MissionDetailXsStoreAddFollowUpsSuccessPayload>;

  updateSolutionContactOffline: PropsAction<MissionDetailXsStoreAssignToPayload>
  updateSolutionContactOfflineSuccess: PropsAction<MissionDetailXsStoreAssignToPayload>;
}

export class MissionDetailXsStore extends EntityXsStore<ILocalMissionDto> {
  public actions: MissionDetailXsStoreActions;

  protected createActions(label: string): MissionDetailXsStoreActions {
    return {
      ...createEntityXsStoreActions(label),

      changeState: createAction(
        BaseXsStore.getType(label, 'Change state'),
        props<MissionDetailXsStoreChangeStatePayload>()
      ),
      changeStateSuccess: createAction(
        BaseXsStore.getType(label, 'Change state success'),
        props<MissionDetailXsStoreChangeStateSuccessPayload>()
      ),

      sendMail: createAction(BaseXsStore.getType(label, 'Send mail')),
      sendMailSuccess: createAction(
        BaseXsStore.getType(label, 'Send mail success'),
        props<MissionDetailXsStoreSendMailSuccessPayload>()
      ),

      export: createAction(
        BaseXsStore.getType(label, 'Export'),
        props<MissionDetailXsStoreExportPayload>()
      ),
      exportSuccess: createAction(
        BaseXsStore.getType(label, 'Export success'),
        props<MissionDetailXsStoreExportSuccessPayload>()
      ),

      complete: createAction(BaseXsStore.getType(label, 'Complete')),
      completeSuccess: createAction(
        BaseXsStore.getType(label, 'Complete success'),
        props<MissionCompleteDto>()
      ),

      resume: createAction(BaseXsStore.getType(label, 'Resume')),
      resumeSuccess: createAction(BaseXsStore.getType(label, 'Resume success')),

      assignContactToMe: createAction(
        BaseXsStore.getType(label, 'Assign to me'),
        props<MissionDetailXsStoreAssignToMePayload>()
      ),
      assignContactToMeSuccess: createAction(
        BaseXsStore.getType(label, 'Assign to me success'),
        props<MissionDetailXsStoreAssignToPayload>()
      ),

      assignContact: createAction(
        BaseXsStore.getType(label, 'Assign to'),
        props<MissionDetailXsStoreAssignToPayload>()
      ),
      assignContactSuccess: createAction(
        BaseXsStore.getType(label, 'Assign to success'),
        props<MissionDetailXsStoreAssignToPayload>()
      ),

      unassignContact: createAction(
        BaseXsStore.getType(label, 'Unassign'),
        props<MissionDetailXsStoreUnassignPayload>()
      ),
      unassignContactSuccess: createAction(
        BaseXsStore.getType(label, 'Unassign success'),
        props<MissionDetailXsStoreUnassignPayload>()
      ),

      assignResource: createAction(
        BaseXsStore.getType(label, 'Assign resource'),
        props<MissionDetailXsStoreChangeResourcePayload>()
      ),
      assignResourceSuccess: createAction(
        BaseXsStore.getType(label, 'Assign resource success'),
        props<MissionDetailXsStoreChangeResourcePayload>()
      ),

      addFollowUps: createAction(
        BaseXsStore.getType(label, 'Add follow ups'),
        props<MissionDetailXsStoreAddFollowUpsPayload>()
      ),
      addFollowUpsSuccess: createAction(
        BaseXsStore.getType(label, 'Add follow ups success'),
        props<MissionDetailXsStoreAddFollowUpsSuccessPayload>()
      ),

      updateSolutionContactOffline: createAction(
        BaseXsStore.getType(label, 'Update solution contact offline'),
        props<MissionDetailXsStoreAssignToPayload>()
      ),
      updateSolutionContactOfflineSuccess: createAction(
        BaseXsStore.getType(label, 'Update solution contact offline success'),
        props<MissionDetailXsStoreAssignToPayload>()
      )
    };
  }

  protected createReducerArray(
    initialState: EntityXsStoreState<ILocalMissionDto>
  ): BaseXsStoreReducerTypes<EntityXsStoreState<ILocalMissionDto>, MissionDetailXsStoreActions>[] {
    return [
      ...super.createReducerArray(initialState),

      immerOn(this.actions.changeState, draft => {
        draft.loading = true;
      }),
      immerOn(this.actions.changeStateSuccess, (draft, {
        state,
        plannedStart,
        plannedEnd,
        actualStart,
        actualEnd,
        lastEmailSend
      }) => {
        draft.entity = { ...draft.entity, state, plannedStart, plannedEnd, actualStart, actualEnd, lastEmailSend };
        draft.model = { ...draft.model, state, plannedStart, plannedEnd, actualStart, actualEnd, lastEmailSend };
        draft.loading = false;
      }),

      immerOn(this.actions.sendMail, draft => {
        draft.entityData = entityXsStoreSetLoadingForAction(draft.entityData, {
          sendMail: true
        });
      }),
      immerOn(this.actions.sendMailSuccess, (draft, { lastEmailSend }) => {
        draft.entity = { ...draft.entity, lastEmailSend };
        draft.model = { ...draft.model, lastEmailSend };
        draft.entityData = entityXsStoreSetLoadingForAction(draft.entityData, {
          sendMail: false
        });
      }),

      immerOn(this.actions.export, draft => {
        draft.entityData = entityXsStoreSetLoadingForAction(draft.entityData, {
          export: true
        });
      }),
      immerOn(this.actions.exportSuccess, draft => {
        draft.entityData = entityXsStoreSetLoadingForAction(draft.entityData, {
          export: false
        });
      }),

      immerOn(this.actions.complete, draft => {
        draft.loading = true;
      }),
      immerOn(this.actions.completeSuccess, (draft) => {
        draft.entity = {
          ...draft.entity,
          isCompleted: true
        };
        draft.model = {
          ...draft.model,
          isCompleted: true
        };
        draft.loading = false;
      }),

      immerOn(this.actions.resume, draft => {
        draft.loading = true;
      }),
      immerOn(this.actions.resumeSuccess, (draft) => {
        draft.entity = {
          ...draft.entity,
          isCompleted: false
        };
        draft.model = {
          ...draft.model,
          isCompleted: false
        };
        draft.loading = false;
      }),

      immerOn(this.actions.assignContactToMe, draft => {
        draft.entityData = entityXsStoreSetLoadingForAction(draft.entityData, {
          changeContact: true
        });
      }),
      immerOn(this.actions.assignContactToMeSuccess, (draft, { contact }) => {
        if (contact) {
          draft.entity = {
            ...draft.entity,
            solutionContact: contact
          };

          draft.model = {
            ...draft.model,
            solutionContact: contact
          };
        }

        draft.entityData = entityXsStoreSetLoadingForAction(draft.entityData, {
          changeContact: false
        });
      }),

      immerOn(this.actions.assignContact, draft => {
        draft.entityData = entityXsStoreSetLoadingForAction(draft.entityData, {
          changeContact: true
        });
      }),
      immerOn(this.actions.assignContactSuccess, (draft, { contact }) => {
        if (contact) {
          draft.entity = {
            ...draft.entity,
            solutionContact: contact
          };

          draft.model = {
            ...draft.model,
            solutionContact: contact
          };
        }

        draft.entityData = entityXsStoreSetLoadingForAction(draft.entityData, {
          changeContact: false
        });
      }),

      immerOn(this.actions.unassignContact, draft => {
        draft.entityData = entityXsStoreSetLoadingForAction(draft.entityData, {
          changeContact: true
        });
      }),
      immerOn(this.actions.unassignContactSuccess, draft => {
        draft.entity = {
          ...draft.entity,
          solutionContact: null
        };
        draft.model = {
          ...draft.model,
          solutionContact: null
        };

        draft.entityData = entityXsStoreSetLoadingForAction(draft.entityData, {
          changeContact: false
        });
      }),

      immerOn(this.actions.addFollowUps, draft => {
        draft.loading = true;
      }),
      immerOn(this.actions.addFollowUpsSuccess, (draft, { missions }) => {
        draft.entity = {
          ...draft.entity,
          successors: [
            ...(draft.entity?.successors ?? []),
            ...missions
          ]
        };
        draft.model = {
          ...draft.model,
          successors: [
            ...(draft.model?.successors ?? []),
            ...missions
          ]
        };
        draft.loading = false;
      }),

      immerOn(this.actions.updateSolutionContactOffline, draft => {
        draft.loading = true;
      }),
      immerOn(this.actions.updateSolutionContactOfflineSuccess, (draft, { contact }) => {
        draft.entity = {
          ...draft.entity,
          solutionContact: contact
        };
        draft.model = {
          ...draft.model,
          solutionContact: contact
        };
        draft.loading = false;
      }),

      immerOn(this.actions.assignResource, draft => {
        draft.entityData = entityXsStoreSetLoadingForAction(draft.entityData, {
          changeResource: true
        });
      }),
      immerOn(this.actions.assignResourceSuccess, (draft, { resource }) => {
        draft.entity = {
          ...draft.entity,
          resource: resource,
          location: resource?.location ?? null,
          shippingAddress: resource?.location?.shippingAddress ?? null,
          invoiceAddress: resource?.location?.invoiceAddress ?? null
        };
        draft.model = {
          ...draft.model,
          resource: resource,
          location: resource?.location ?? null,
          shippingAddress: resource?.location?.shippingAddress ?? null,
          invoiceAddress: resource?.location?.invoiceAddress ?? null
        };

        draft.entityData = entityXsStoreSetLoadingForAction(draft.entityData, {
          changeResource: false
        });
      }),
    ];
  }
}
