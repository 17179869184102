<div class="row">
  <div class="col-10">
    <form [formGroup]="form">
      <formly-form [fields]="initializedFields"
                   [form]="form"
                   [model]="model"
                   class="row">
      </formly-form>
    </form>
  </div>
  <div class="col-2 no-gutters p-0 text-right">
    <button (click)="onAdd()"
            [class.d-none]="mode !== rowModes.Create"
            [class]="'p-button-secondary p-button-text'"
            [disabled]="form.invalid ?? false"
            [icon]="faPlus | nexnoxWebFaIconStringPipe"
            [label]="'core-shared.shared.actions.add' | translate"
            class="m-0 ml-2"
            pButton
            type="button">
    </button>
    <button (click)="onConfirm()"
            [class.d-none]="mode !== rowModes.Edit"
            [class]="'p-button-secondary p-button-text'"
            [disabled]="form.invalid ?? false"
            [icon]="faCheck| nexnoxWebFaIconStringPipe"
            [title]="'core-shared.shared.fields.ok' | translate"
            class="m-0 ml-2"
            pButton
            type="button">
    </button>
    <button (click)="onCancel()"
            [class.d-none]="mode !== rowModes.Edit"
            [class]="'p-button-secondary p-button-text'"
            [icon]="faTimes| nexnoxWebFaIconStringPipe"
            [title]="'core-portal.core.general.cancel' | translate"
            class="m-0 ml-2"
            pButton
            type="button">
    </button>
    <button (click)="onEdit()"
            [class.d-none]="mode !== rowModes.Readonly"
            [class]="'p-button-secondary p-button-text'"
            [disabled]="form.invalid ?? false"
            [icon]="faPencil | nexnoxWebFaIconStringPipe"
            [title]="'core-portal.core.general.edit' | translate"
            class="m-0 ml-2"
            pButton
            type="button">
    </button>
    <button (click)="onDelete()"
            [class.d-none]="mode !== rowModes.Readonly"
            [class]="'p-button-secondary p-button-text'"
            [icon]="faTrash| nexnoxWebFaIconStringPipe"
            [title]="'core-shared.shared.table.tooltip.delete' | translate"
            class="m-0 ml-2"
            pButton
            type="button">
    </button>
  </div>
</div>
