import {ChangeDetectionStrategy, Component, HostListener, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {
  ApiNotificationService,
  AppEntityType,
  AppPermissions,
  CORE_SHARED_ENVIRONMENT,
  CoreSharedModalService,
  Environment,
  StereotypeDto,
  TenantInfoDto,
  TicketDto,
  UnsubscribeHelper
} from '@nexnox-web/core-shared';
import {MemoizedSelector, select, Store} from '@ngrx/store';
import {delay, filter, map, shareReplay, switchMap, take} from 'rxjs/operators';
import {missionDetailStore} from '../../store';
import {ActivatedRoute} from '@angular/router';
import {Actions, ofType} from '@ngrx/effects';
import {authStore, CorePortalActionBarService, CorePortalTenantRouter} from '@nexnox-web/core-portal';
import {BehaviorSubject, Observable} from 'rxjs';
import {
  TechPortalFeatureMissionTicketService,
  TechPortalFeatureTicketEditComponent
} from '@nexnox-web/tech-portal/features/tickets-missions';
import {TechPortalTicketService} from '@nexnox-web/tech-portal-lib';
import {faTimesCircle} from '@fortawesome/free-solid-svg-icons/faTimesCircle';
import {faPlus} from '@fortawesome/free-solid-svg-icons/faPlus';
import {cloneDeep} from 'lodash';
import {CORE_STORE_TENANT_ID_SELECTOR} from '@nexnox-web/core-store';

@Component({
  selector: 'nexnox-web-missions-mission-create-ticket',
  templateUrl: './mission-create-ticket.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MissionCreateTicketComponent extends UnsubscribeHelper implements OnInit, OnDestroy {
  @ViewChild('editComponent', { static: true }) public editComponent: TechPortalFeatureTicketEditComponent;

  public model$: Observable<TicketDto>;

  public missionLoaded$: Observable<boolean>;
  public stereotypes$: Observable<StereotypeDto[]>;
  public activeTenant$: Observable<TenantInfoDto>;

  public missionId: number;

  private modelSubject = new BehaviorSubject<TicketDto>({} as TicketDto);
  private loadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private store: Store<any>,
    private tenantRouter: CorePortalTenantRouter,
    private route: ActivatedRoute,
    private actions$: Actions,
    private ticketService: TechPortalTicketService,
    private actionBarService: CorePortalActionBarService,
    private modalService: CoreSharedModalService,
    @Inject(CORE_SHARED_ENVIRONMENT) private environment: Environment,
    private missionTicketService: TechPortalFeatureMissionTicketService,
    private apiNotificationService: ApiNotificationService,
    @Inject(CORE_STORE_TENANT_ID_SELECTOR) private tenantIdSelector: MemoizedSelector<any, number>
  ) {
    super();
  }

  public ngOnInit(): void {
    this.model$ = this.modelSubject.asObservable();
    this.missionLoaded$ = this.store.pipe(select(missionDetailStore.selectors.selectLoaded));
    this.stereotypes$ = this.ticketService.getStereotypes(AppEntityType.Ticket, true).pipe(
      take(1),
      shareReplay(1)
    );
    this.activeTenant$ = this.store.pipe(select(authStore.selectors.selectActiveTenant));

    this.setActions();
    this.getMission();

    this.subscribeToActions();
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();

    this.missionId = undefined;
    this.modelSubject.next({} as TicketDto);
    this.loadingSubject.next(false);
    this.actionBarService.reset();
  }

  @HostListener('window:beforeunload', ['$event'])
  public async onBeforeWindowUnload(event: BeforeUnloadEvent): Promise<void> {
    if (!this.environment.production) {
      return;
    }

    event.returnValue = true;
  }

  public onModelChange(model: TicketDto): void {
    this.modelSubject.next(cloneDeep(model));
  }

  public onCancel(): void {
    this.modalService.showConfirmationModal(
      'core-shared.shared.unsaved-changes.title',
      'core-shared.shared.unsaved-changes.text',
      'warning',
      'core-portal.core.general.yes'
    )
      .then(() => this.tenantRouter.navigate(['/missions', this.missionId], { module: 'inventory' }))
      .catch(() => null);
  }

  public async onCreate(): Promise<void> {
    this.loadingSubject.next(true);

    const tenantId = await this.store.pipe(select(this.tenantIdSelector), take(1)).toPromise();
    this.missionTicketService.startTicket(this.missionId, {
      ...cloneDeep(this.modelSubject.getValue()),
      tenantId
    })
      .toPromise()
      .then(ticket => {
        this.apiNotificationService.showTranslatedSuccess('core-shared.shared.toast.entity-created');
        this.tenantRouter.navigate(['/tickets', ticket.ticketId], { module: 'communication' });
        this.loadingSubject.next(false);
      })
      .catch(error => {
        this.apiNotificationService.handleApiError(error);
        this.loadingSubject.next(false);
      });
  }

  public async getMission(): Promise<void> {
    const isLoaded = await this.store.pipe(select(missionDetailStore.selectors.selectLoaded), take(1)).toPromise();

    if (!isLoaded) {
      this.missionId = parseInt(this.route.snapshot.paramMap.get('missionId'), 10);
      this.store.dispatch(missionDetailStore.actions.get({ id: this.missionId }));
    }
  }

  public subscribeToActions(): void {
    this.subscribe(this.actions$.pipe(
      ofType(missionDetailStore.actions.error),
      filter(({ action }) => action.type === missionDetailStore.actions.get.type)
    ), () => this.tenantRouter.navigate(['/missions'], { module: 'inventory' }));
  }

  /* istanbul ignore next */
  private setActions(): void {
    this.actionBarService.setActions([
      {
        label: 'core-portal.core.general.cancel',
        type: 'button',
        class: 'btn-outline-secondary',
        icon: faTimesCircle,
        isLoading: () => this.missionLoaded$.pipe(
          switchMap(loaded => this.loadingSubject.asObservable().pipe(
            map(loading => loading || !loaded)
          ))
        ),
        callback: () => this.onCancel()
      },
      {
        label: 'missions.actions.create-ticket',
        type: 'button',
        class: 'btn-primary',
        permission: AppPermissions.CreateTicket,
        icon: faPlus,
        shouldShow: () => this.activeTenant$.pipe(map(activeTenant => Boolean(activeTenant))),
        callback: () => this.onCreate(),
        isDisabled: () => this.model$.pipe(
          delay(1),
          map(() => !this.editComponent?.isModelValid())
        ),
        isLoading: () => this.missionLoaded$.pipe(
          switchMap(loaded => this.loadingSubject.asObservable().pipe(
            map(loading => loading || !loaded)
          ))
        )
      }
    ]);
  }
}
