import {Injectable} from '@angular/core';
import {CoreSharedHttpRequestService} from '../http';
import {from, Observable, throwError} from 'rxjs';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {ApiNotificationService} from '../api-notification/api-notification.service';
import {FileDto} from '../../models';
import {HttpEvent} from '@angular/common/http';

@Injectable()
export class CoreSharedFileService {
  constructor(
    private httpRequestService: CoreSharedHttpRequestService,
    private apiNotificationService: ApiNotificationService
  ) {
  }

  public getFile(id: number): Observable<FileDto> {
    return this.httpRequestService.get<FileDto>(`file/${ id }`).pipe(
      map(response => response.body)
    );
  }

  public uploadFile(file: File, tenantId: number | string, fileType?: string): Observable<HttpEvent<FileDto>> {
    return this.convertFileToData(file).pipe(
      mergeMap(data => this.uploadFileData(data, file.name, tenantId, fileType))
    );
  }

  public uploadFileData(
    data: string,
    fileName: string,
    tenantId: number | string,
    contentType?: string
  ): Observable<HttpEvent<FileDto>> {
    return this.uploadBlob(this.convertDataToBlob(data, contentType), fileName, tenantId);
  }

  public uploadBlob(blob: Blob, fileName: string, tenantId: number | string): Observable<HttpEvent<FileDto>> {
    const body = new FormData();
    body.append('file', blob, fileName);

    return this.httpRequestService.postWithEvents<FileDto>(`file/tenant/${ tenantId }`, body).pipe(
      catchError(error => {
        this.apiNotificationService.showTranslatedError('core-shared.shared.toast.file-upload-failed');
        return throwError(error);
      })
    );
  }

  public convertFileToData(file: File): Observable<string> {
    const fileReader = new FileReader();

    return from(new Promise<string>((resolve, reject) => {
      fileReader.onload = (event: ProgressEvent<FileReader>) => resolve(event.target.result?.toString());
      fileReader.onabort = reject;
      fileReader.onerror = reject;

      fileReader.readAsDataURL(file);
    }));
  }

  public convertDataToBlob(data: string, contentType?: string): Blob {
    try {
      const byteString = atob(data.split(',')[1]);
      const mimeString = data.split(',')[0].split(':')[1].split(';')[0];
      const arrayBuffer = new ArrayBuffer(byteString.length);
      const uint8Array = new Uint8Array(arrayBuffer);

      for (let i = 0; i < byteString.length; i++) {
        uint8Array[i] = byteString.charCodeAt(i);
      }

      return new Blob([arrayBuffer], { type: contentType ?? mimeString });
    } catch (error) {
      throw new Error('Failed to convert data to blob');
    }
  }
}
