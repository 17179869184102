import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {CoreSharedBreadcrumbsService} from './services';
import {CoreSharedBreadcrumbsComponent} from './components';

@NgModule({
  declarations: [
    CoreSharedBreadcrumbsComponent
  ],
  imports: [
    CommonModule,
    RouterModule
  ],
  exports: [
    CoreSharedBreadcrumbsComponent
  ]
})
export class CoreSharedBreadcrumbsModule {
  public static forRoot(): ModuleWithProviders<CoreSharedBreadcrumbsModule> {
    return {
      ngModule: CoreSharedBreadcrumbsModule,
      providers: [
        CoreSharedBreadcrumbsService
      ]
    };
  }
}
