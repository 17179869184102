<div *ngIf="resource" class="d-flex flex-column small">

  <!-- Path -->
  <div *ngIf="resource?.path?.length > 1" [style.padding-left.px]="11">
    <span class="text-muted">
    <ng-container
      *ngFor="let path of (resource?.path ? resource.path : []); trackBy: trackByResourcePath; let isFirst = first; let isLast = last">
      <ng-container *ngIf="!isLast">
        <span *ngIf="!isFirst">></span> {{ path.name }}
      </ng-container>
    </ng-container>
      </span>
  </div>
</div>
