<ng-template #entityEditTemplate let-id="id" let-title="title">
  <nexnox-web-master-functions-function-edit #modelComponent
                                             (modelChange)="onModelChange($event)"
                                             [creating]="false"
                                             [id]="id"
                                             [loading]="loading$ | async"
                                             [model]="model$ | async"
                                             [readonly]="readonly$ | async"
                                             [stereotypes$]="stereotypes$"
                                             [title]="title">
  </nexnox-web-master-functions-function-edit>
</ng-template>

<nexnox-web-entity-detail [entityDetailBaseComponent]="this"
                          [entityEditTemplate]="entityEditTemplate"
                          [tabCommands]="['.']"
                          [tabHeading]="title | translate"
                          tabId="function">
  <!-- Contacts Tab -->
  <ng-template (selectTab)="onNavigateToTab(['contacts'])"
               [heading]="'core-portal.master-data.subtitles.contact-list' | translate"
               [lazy]="true"
               nexnoxWebTabTemplate
               tabId="contacts">
    <nexnox-web-master-data-functions-contact-list *ngIf="passedIds"
                                                   [getEntityOnModeChange]="false"
                                                   [isEmbedded]="true"
                                                   [parentIds]="passedIds">
    </nexnox-web-master-data-functions-contact-list>
  </ng-template>
</nexnox-web-entity-detail>
