import {PagedEntitiesXsStore, PagedEntitiesXsStoreState} from '@nexnox-web/core-store';
import {AppEntityType, StateMachineDto} from '@nexnox-web/core-shared';
import {Action, createSelector} from '@ngrx/store';
import {selectStateMachinesState} from '../../state-machines.selectors';
import {TechPortalFeatureTicketSettingsStateMachineService} from '../../services';
import {Injectable, Injector} from '@angular/core';

export interface StateMachineListStoreState extends PagedEntitiesXsStoreState<StateMachineDto> {
}

/* istanbul ignore next */
export const stateMachineListStore = new PagedEntitiesXsStore<StateMachineDto>({
  actionLabel: 'Tech Portal - Ticket Settings - State Machines - State Machine List',
  stateSelector: createSelector(selectStateMachinesState, state => state.stateMachineList),
  serviceType: TechPortalFeatureTicketSettingsStateMachineService,
  entityType: AppEntityType.None,
  selectId: stateMachine => stateMachine.entity?.stateMachineId ?? stateMachine.model?.stateMachineId
});

export function stateMachineListStoreReducer(state: StateMachineListStoreState, action: Action): void {
  return stateMachineListStore.reducer(state, action);
}

@Injectable()
export class StateMachineListStoreEffects extends stateMachineListStore.effects {
  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }
}
