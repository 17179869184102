import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Observable, of} from 'rxjs';
import {AppPermissions, FollowUpMissionDto, MissionSimpleDto} from '@nexnox-web/core-shared';
import {faClipboardCheck} from '@fortawesome/free-solid-svg-icons/faClipboardCheck';
import {map} from 'rxjs/operators';
import {CorePortalCardboxAction} from '@nexnox-web/core-portal';
import {faPlus} from '@fortawesome/free-solid-svg-icons/faPlus';
import {BindObservable} from 'bind-observable';
import {CreateMissionSuccessorsSidebarComponent} from '../../sidebars';

@Component({
  selector: 'nexnox-web-missions-mission-successors-edit',
  templateUrl: './mission-successors-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MissionSuccessorsEditComponent implements OnInit {
  @Input() public successors$: Observable<MissionSimpleDto[]> = of([]);

  @Input() @BindObservable() public loading: boolean;
  public loading$!: Observable<boolean>;

  @Input() @BindObservable() public readonly: boolean;
  public readonly$!: Observable<boolean>;

  @ViewChild('createMissionSuccessorsSidebarComponent', { static: true })
  public createMissionSuccessorsSidebarComponent: CreateMissionSuccessorsSidebarComponent;

  @Output() public successorsAdded: EventEmitter<FollowUpMissionDto[]> = new EventEmitter<FollowUpMissionDto[]>();

  public hasSuccessors$: Observable<boolean>;

  public headerActions: CorePortalCardboxAction[];

  public faClipboardCheck = faClipboardCheck;

  constructor() {
    this.headerActions = [{
      label: 'core-portal.core.general.add',
      icon: faPlus,
      class: 'btn-outline-secondary',
      permission: AppPermissions.CreateMission,
      callback: () => this.createMissionSuccessorsSidebarComponent.onShow(),
      shouldShow: () => this.readonly$.pipe(
        map(readonly => !readonly)
      ),
      isLoading: () => this.loading$
    }];
  }

  public ngOnInit(): void {
    this.hasSuccessors$ = this.successors$.pipe(
      map(successors => Boolean(successors?.length))
    );
  }

  public onAddSuccessors(successors: FollowUpMissionDto[]): void {
    this.successorsAdded.emit(successors);
  }
}
