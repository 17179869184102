import {ChangeDetectionStrategy, Component, EventEmitter, Injector, Output} from '@angular/core';
import {CorePortalEntityOverviewBaseComponent} from '@nexnox-web/core-portal';
import {
  AppEntityType,
  ControllerOperationId,
  KnowledgePathProgressLocationListDto,
  Mappers
} from '@nexnox-web/core-shared';
import {pathsProgressLocationListStore} from '../../store';

@Component({
  selector: 'nexnox-web-paths-path-progress-location-list',
  templateUrl: './paths-progress-location-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PathsProgressLocationListComponent extends CorePortalEntityOverviewBaseComponent<KnowledgePathProgressLocationListDto> {
  @Output() public openSidebar: EventEmitter<any> = new EventEmitter<any>();

  public title = 'core-shared.shared.fields.locations';
  public idProperty = 'locationId';
  public displayProperty = null;
  public datatableConfigName = 'KnowledgePathsProgressLocationsDefault';
  public componentId = 'PathsProgressLocationListComponent';
  public enableViews = true;
  public pageOperation = ControllerOperationId.KnowledgePathProgressLocationControllerId;

  constructor(
    protected injector: Injector
  ) {
    super(injector, pathsProgressLocationListStore, Mappers.KnowledgePathProgressLocationListDto.serializedName, AppEntityType.Location);
  }

  public ngOnInit(): void {
    super.ngOnInit();

    if (this.parentIds[0]) {
      this.descendantId = Number(this.parentIds[0]);
    }
  }

  public onOpenSidebar(event: any): void {
    this.openSidebar.emit(event.model);
  }
}
