import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpEvent, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {HttpApiClient, HttpAuthorizedClient} from '../clients';
import {CORE_SHARED_ENVIRONMENT, CORE_SHARED_PRODUCT} from '../../../tokens';
import {Environment, Product} from '../../../models';

@Injectable()
export class CoreSharedHttpRequestService {
  constructor(
    protected httpAuthorizedClient: HttpAuthorizedClient,
    protected httpApiClient: HttpApiClient,
    @Inject(CORE_SHARED_PRODUCT) protected product: Product,
    @Inject(CORE_SHARED_ENVIRONMENT) protected environment: Environment
  ) {
  }

  public get<T>(
    path: string,
    headers: HttpHeaders = null,
    params: HttpParams = null,
    authorized: boolean = true,
    apiPath: string = this.getDefaultUrl()
  ): Observable<HttpResponse<T>> {
    return this.getHttpClient(authorized).get<T>(`${ apiPath }/${ path }`, {
      headers,
      observe: 'response',
      params
    });
  }

  public post<T>(
    path: string,
    body: any,
    headers: HttpHeaders = null,
    params: HttpParams = null,
    authorized: boolean = true,
    apiPath: string = this.getDefaultUrl()
  ): Observable<HttpResponse<T>> {
    return this.getHttpClient(authorized).post<T>(`${ apiPath }/${ path }`, body, {
      headers,
      observe: 'response',
      params
    });
  }

  public postWithEvents<T>(
    path: string,
    body: any,
    headers: HttpHeaders = null,
    params: HttpParams = null,
    authorized: boolean = true,
    apiPath: string = this.getDefaultUrl(),
    reportProgress: boolean = true
  ): Observable<HttpEvent<T>> {
    return this.getHttpClient(authorized).post<T>(`${ apiPath }/${ path }`, body, {
      headers,
      observe: 'events',
      reportProgress,
      params
    });
  }

  public put<T>(
    path: string,
    body: T,
    headers: HttpHeaders = null,
    params: HttpParams = null,
    authorized: boolean = true,
    apiPath: string = this.getDefaultUrl()
  ): Observable<HttpResponse<T>> {
    return this.getHttpClient(authorized).put<T>(`${ apiPath }/${ path }`, body, {
      headers,
      observe: 'response',
      params
    });
  }

  public delete<T>(
    path: string,
    id: number | string = null,
    headers: HttpHeaders = null,
    params: HttpParams = null,
    authorized: boolean = true,
    apiPath: string = this.getDefaultUrl()
  ): Observable<HttpResponse<T>> {
    const preparedPath = id ? `${ apiPath }/${ path }/${ id }` : `${ apiPath }/${ path }`;
    return this.getHttpClient(authorized).delete<T>(preparedPath, {
      headers,
      observe: 'response',
      params
    });
  }

  protected getDefaultUrl(): string {
    return this.environment?.apiUrl ? this.environment.apiUrl[this.product] : null;
  }

  private getHttpClient(authorized: boolean): HttpClient {
    return authorized ? this.httpAuthorizedClient : this.httpApiClient;
  }
}
