import {cloneDeep, uniq} from 'lodash';

export function isUnique(array: any[]): boolean {
  if (!array) {
    return false;
  }

  if (!array.length) {
    return true;
  }

  const uniqueArray = uniq(cloneDeep(array));
  return array.length === uniqueArray.length;
}
