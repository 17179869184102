import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {HttpErrorResponse} from '@angular/common/http';
import {CoreSharedLocalStorageService, LocalStorageKeys, UuidService} from '@nexnox-web/core-shared';
import {CorePortalTenantRouter} from '../../router-overrides';

@Injectable()
export class ErrorService {
  constructor(
    private router: Router,
    private tenantRouter: CorePortalTenantRouter,
    private uuidService: UuidService,
    private localStorageService: CoreSharedLocalStorageService
  ) {
  }

  public async navigateToError(error: string | HttpErrorResponse | Error): Promise<void> {
    const uuid = this.uuidService.generateUuid();
    this.localStorageService.set(LocalStorageKeys.LAST_ERROR, {
      uuid,
      url: this.router.url,
      error
    });

    await this.tenantRouter.navigate(['error', uuid]);
  }

  public async navigateToEmptyError(): Promise<void> {
    this.localStorageService.remove(LocalStorageKeys.LAST_ERROR);
    await this.tenantRouter.navigate(['error']);
  }
}
