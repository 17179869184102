import {Injectable, Injector} from '@angular/core';
import {CoreSharedApiBaseService, Product} from '@nexnox-web/core-shared';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable()
export class TicketEventService extends CoreSharedApiBaseService {
  constructor(
    protected injector: Injector
  ) {
    super(injector, Product.TECH, 'event', ['ticket']);
  }

  public cancelOne<T>(id: number | string, parentIds?: Array<number | string>): Observable<T> {
    return this.httpRequestService.post<T>(`${ this.buildPathForOne(id, parentIds) }/cancel`, null).pipe(
      map(response => response.body)
    );
  }

  public followUp(stateId: number, triggersAt: string, parentIds: Array<number | string>): Observable<any> {
    return this.httpRequestService.post(`${ this.buildPath(parentIds) }/followup`, {
      state: { stateId },
      triggersAt
    }).pipe(
      map(response => response.body)
    );
  }
}
