import {ChangeDetectionStrategy, Component, Injector} from '@angular/core';
import {ActionButton, CorePortalEntityDetailBaseComponent, CorePortalPermissionService} from '@nexnox-web/core-portal';
import {AppPermissions, ResourceTaskDto, ResourceTaskSelectorDto} from '@nexnox-web/core-shared';
import {resourceTaskDetailStore} from '../../store';
import {Observable, withLatestFrom} from 'rxjs';
import {map} from "rxjs/operators";

@Component({
  selector: 'nexnox-web-resource-tasks-resource-task-detail',
  templateUrl: './resource-task-detail.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResourceTaskDetailComponent extends CorePortalEntityDetailBaseComponent<ResourceTaskDto> {
  public title = 'resource-tasks.subtitles.resource-task-detail';

  public canDisplayMissions$: Observable<boolean>;
  public isNoResourceFilter$: Observable<boolean>;

  protected idParam = 'resourceTaskId';
  protected displayKey = 'title';

  constructor(
    protected injector: Injector,
    private permissionService: CorePortalPermissionService
  ) {
    super(injector, resourceTaskDetailStore);

    this.canDisplayMissions$ = this.permissionService.hasPermission$(AppPermissions.DisplayMissions);
    this.isNoResourceFilter$ = this.model$.pipe(map(model => !model?.selector || ((model?.selector?.view?.filters ?? []).length <= 0)));
  }

  public onSelectorChange(selector: ResourceTaskSelectorDto, model: ResourceTaskDto): void {
    this.onModelChange({ ...model, selector });
  }

  protected shouldDisablePrimaryAction(): Observable<boolean> {
    return super.shouldDisablePrimaryAction().pipe(withLatestFrom(this.isNoResourceFilter$), map(([shouldDisable, noFilter]) => shouldDisable || noFilter));
  }

  protected async getActionButtons(): Promise<ActionButton[]> {
    return this.getDefaultActionButtons(
      'resource-tasks.actions.edit-resource-task',
      'resource-tasks.actions.save-resource-task',
      'resource-tasks.actions.delete-resource-task',
      'resource-tasks.descriptions.delete-resource-task',
      AppPermissions.UpdateResourceTask,
      AppPermissions.DeleteResourceTask,
      ['/resource-tasks']
    );
  }
}
