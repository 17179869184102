import {BaseXsStoreReducerTypes, EntityXsStore, entityXsStoreSetLoadingForAction} from '@nexnox-web/core-store';
import {TicketDto} from '@nexnox-web/core-shared';
import {TicketDetailXsStoreState} from './ticket-detail-xs-store.state';
import {createTicketDetailXsStoreActions, TicketDetailXsStoreActions} from './ticket-detail-xs-store.actions';
import {createTicketDetailXsStoreSelectors, TicketDetailXsStoreSelectors} from './ticket-detail-xs-store.selectors';
import {immerOn} from 'ngrx-immer/store';

export class TicketDetailXsStore extends EntityXsStore<TicketDto, TicketDto, TicketDetailXsStoreState> {
  public actions: TicketDetailXsStoreActions;
  public selectors: TicketDetailXsStoreSelectors;

  public getInitialState(): TicketDetailXsStoreState {
    return {
      ...super.getInitialState(),
      previewStates: [],
      previewStatesLoading: false,
      previewStatesLoaded: false
    };
  }

  protected createActions(label: string): TicketDetailXsStoreActions {
    return createTicketDetailXsStoreActions(label);
  }

  protected createReducerArray(
    initialState: TicketDetailXsStoreState
  ): BaseXsStoreReducerTypes<TicketDetailXsStoreState, TicketDetailXsStoreActions>[] {
    return [
      ...super.createReducerArray(initialState),

      immerOn(this.actions.getPreviewStates, draft => {
        draft.previewStatesLoading = true;
      }),

      immerOn(this.actions.getPreviewStatesSuccess, (draft, { states }) => {
        draft.previewStates = states;
        draft.previewStatesLoading = false;
        draft.previewStatesLoaded = true;
      }),

      immerOn(this.actions.changeState, draft => {
        draft.loading = true;
        draft.previewStatesLoading = true;
      }),

      immerOn(this.actions.changeStateSuccess, (draft, { state }) => {
        draft.entity = {
          ...draft.entity,
          currentState: state
        };

        draft.model = {
          ...draft.model,
          currentState: state
        };

        draft.loading = false;
        draft.previewStatesLoading = false;
      }),

      immerOn(this.actions.followUp, draft => {
        draft.entityData = entityXsStoreSetLoadingForAction(draft.entityData, {
          followUp: true
        });
      }),
      immerOn(this.actions.followUpSuccess, draft => {
        draft.entityData = entityXsStoreSetLoadingForAction(draft.entityData, {
          followUp: false
        });
      }),

      immerOn(this.actions.assignContactToMe, draft => {
        draft.entityData = entityXsStoreSetLoadingForAction(draft.entityData, {
          changeContact: true
        });
      }),
      immerOn(this.actions.assignContactToMeSuccess, (draft, { contact }) => {
        if (contact) {
          draft.entity = {
            ...draft.entity,
            editor: contact
          };

          draft.model = {
            ...draft.model,
            editor: contact
          };
        }

        draft.entityData = entityXsStoreSetLoadingForAction(draft.entityData, {
          changeContact: false
        });
      }),

      immerOn(this.actions.assignContact, draft => {
        draft.entityData = entityXsStoreSetLoadingForAction(draft.entityData, {
          changeContact: true
        });
      }),
      immerOn(this.actions.assignContactSuccess, (draft, { contact }) => {
        if (contact) {
          draft.entity = {
            ...draft.entity,
            editor: contact
          };

          draft.model = {
            ...draft.model,
            editor: contact
          };
        }

        draft.entityData = entityXsStoreSetLoadingForAction(draft.entityData, {
          changeContact: false
        });
      }),

      immerOn(this.actions.unassignContact, draft => {
        draft.entityData = entityXsStoreSetLoadingForAction(draft.entityData, {
          changeContact: true
        });
      }),
      immerOn(this.actions.unassignContactSuccess, draft => {
        draft.entity = {
          ...draft.entity,
          editor: null
        };
        draft.model = {
          ...draft.model,
          editor: null
        };

        draft.entityData = entityXsStoreSetLoadingForAction(draft.entityData, {
          changeContact: false
        });
      }),

      immerOn(this.actions.assignResource, draft => {
        draft.entityData = entityXsStoreSetLoadingForAction(draft.entityData, {
          changeResource: true
        });
      }),
      immerOn(this.actions.assignResourceSuccess, (draft, { resource }) => {
        if (resource) {
          draft.entity = {
            ...draft.entity,
            resource: resource,
            location: resource?.location ?? null
          };
          draft.model = {
            ...draft.model,
            resource: resource,
            location: resource?.location ?? null
          };
        }

        draft.entityData = entityXsStoreSetLoadingForAction(draft.entityData, {
          changeResource: false
        });
      }),

      immerOn(this.actions.unassignResource, draft => {
        draft.entityData = entityXsStoreSetLoadingForAction(draft.entityData, {
          changeResource: true
        });
      }),
      immerOn(this.actions.unassignResourceSuccess, draft => {
        draft.entity = {
          ...draft.entity,
          resource: null
        };
        draft.model = {
          ...draft.model,
          resource: null
        };

        draft.entityData = entityXsStoreSetLoadingForAction(draft.entityData, {
          changeResource: false
        });
      }),

      immerOn(this.actions.export, draft => {
        draft.entityData = entityXsStoreSetLoadingForAction(draft.entityData, {
          export: true
        });
      }),

      immerOn(this.actions.exportSuccess, draft => {
        draft.entityData = entityXsStoreSetLoadingForAction(draft.entityData, {
          export: false
        });
      }),

      immerOn(this.actions.error, draft => {
        draft.loading = false;
        draft.stereotypesLoading = false;
        draft.previewStatesLoading = false;
        draft.entityData = { loading: {} };
      })
    ];
  }

  protected createSelectors(): TicketDetailXsStoreSelectors {
    return createTicketDetailXsStoreSelectors(
      this.options.stateSelector,
      EntityXsStore.getSanitizeModelFn(this.options),
      EntityXsStore.getChangeCustomizers(this.options)
    );
  }
}
