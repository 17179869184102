import {
  AfterViewInit,
  ChangeDetectorRef,
  Directive,
  EventEmitter,
  Injector,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {StereotypeDto, UnsubscribeHelper} from '@nexnox-web/core-shared';
import {ModelValid} from '../../../models';
import {BehaviorSubject, combineLatestWith, merge, NEVER, Observable, of, ReplaySubject} from 'rxjs';
import {FormGroup} from '@angular/forms';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {cloneDeep, isEmpty, values} from 'lodash';
import {
  catchError,
  delay,
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
  pairwise,
  startWith,
  take
} from 'rxjs/operators';
import {CorePortalCardboxAction} from '../../../../cardbox/components';
import {CorePortalFormlyReadonlyTypes, CorePortalFormlyReadonlyTyping} from '../../../../formly/wrappers';
import {CorePortalEntityCreatePresetService, ModelAdditionPreset} from '../../../services';
import {CorePortalLinkDto} from "@nexnox-web/core-portal";
import {ActivatedRoute, Router} from "@angular/router";

@Directive()
export abstract class CorePortalEntityEditBaseComponent<T extends object> extends UnsubscribeHelper implements OnInit, AfterViewInit, ModelValid {

  /**
   * Title for the cardbox.
   */
  @Input() public title: string;

  /**
   * ID of the entity when {@link creating} is disabled.
   */
  @Input() public id: number | string;

  /**
   * Whether the entity is being created or edited.
   *
   * @default false
   */
  @Input() public creating = false;
  /**
   * The stereotypes used to edit the stereotype and custom properties of the entity. Only relevant if {@link stereotyped} is enabled.
   *
   * @default NEVER
   */
  @Input() public stereotypes$: Observable<StereotypeDto[]> = NEVER;
  /**
   * Whether the entity is stereotyped or not.
   *
   * @default true
   */
  @Input() public stereotyped = true;
  /**
   * The header actions for the cardbox.
   *
   * @default Empty
   */
  @Input() public headerActions: CorePortalCardboxAction[] = [];
  /**
   * The footer actions for the cardbox.
   *
   * @default Empty
   */
  @Input() public footerActions: CorePortalCardboxAction[] = [];
  @Input() public module: string;
  @Input() public isCrossCreation = false;
  @Output() public modelChange: EventEmitter<T> = new EventEmitter<T>();
  @Output() public backlinkChange: EventEmitter<CorePortalLinkDto> = new EventEmitter<CorePortalLinkDto>();
  /**
   * Output triggers when a create preset has been applied from the local storage
   * For example: Create resource from resource
   * @void
   */
  @Output() public createPresetApplied: EventEmitter<void> = new EventEmitter<void>();
  public form: FormGroup;
  public fields: FormlyFieldConfig[];
  public selectedStereotypeId$: Observable<number>;
  public selectedStereotype$: Observable<StereotypeDto>;
  public selectedParentId$: Observable<number>;
  public stereotypeIdPair$: Observable<[number, number]>;
  public modelValidSubject: BehaviorSubject<{ [key: string]: boolean }> = new BehaviorSubject<{
    [key: string]: boolean
  }>({});
  public loadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  protected route: ActivatedRoute;
  protected router: Router;
  /**
   * Adds a stereotype filter function to the stereotype field.
   * See getStereotypeFields() for more details.
   *
   * @default true
   */
  protected additionalStereotypeFilterFn: Observable<(stereotype: StereotypeDto) => boolean> = of(() => true);
  protected modelSubject: BehaviorSubject<T> = new BehaviorSubject<T>(null);
  protected readonlySubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  protected selectedParentIdSubject: ReplaySubject<number> = new ReplaySubject<number>();
  protected presetAppliedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  protected entityCreatePresetService: CorePortalEntityCreatePresetService;
  protected changeDetector: ChangeDetectorRef;
  protected parentComponent: string;
  private selectedStereotypeIdSubject: ReplaySubject<number> = new ReplaySubject<number>();

  protected constructor(
    protected injector: Injector,
    protected createPresetId?: string
  ) {
    super();

    this.entityCreatePresetService = this.injector.get(CorePortalEntityCreatePresetService);
    this.changeDetector = this.injector.get(ChangeDetectorRef);
    this.route = injector.get(ActivatedRoute);
    this.router = injector.get(Router);
  }

  public get loading(): boolean {
    return this.loadingSubject.getValue();
  }

  /**
   * Whether the component is loading or not.
   */
  @Input()
  public set loading(value) {
    this.loadingSubject.next(value);
  }

  public get model(): T {
    return this.modelSubject.getValue();
  }

  @Input()
  public set model(model: T) {
    this.setModel(model);
  }

  public get readonly(): boolean {
    return this.readonlySubject.getValue();
  }

  @Input()
  public set readonly(readonly: boolean) {
    this.setReadonly(readonly);
  }

  public ngOnInit(): void {
    this.ngOnInitSimple();
    this.ngOnInitForm();

    if (this.stereotyped) {
      this.ngOnInitSubscriptions();
    }
  }

  /**
   * Sets the important observables.
   */
  public ngOnInitSimple(): void {
    this.selectedStereotypeId$ = this.selectedStereotypeIdSubject.asObservable().pipe(
      delay(0)
    );

    this.selectedParentId$ = this.selectedParentIdSubject.asObservable().pipe(
      delay(0)
    );

    this.stereotypeIdPair$ = this.selectedStereotypeId$.pipe(
      startWith(null),
      distinctUntilChanged(),
      pairwise()
    );

    this.selectedStereotype$ = this.selectedStereotypeId$.pipe(
      distinctUntilChanged(),
      mergeMap(stereotypeId => this.stereotypes$.pipe(
        map(stereotypes => stereotypes.find(x => x.stereotypeId === stereotypeId))
      ))
    );
  }

  /**
   * Initialises the form.
   */
  public ngOnInitForm(): void {
    this.form = new FormGroup({});
    this.fields = this.createForm();
  }

  /**
   * Subscribes to the selected stereotype and sets the stereotype row version.
   */
  public ngOnInitSubscriptions(): void {

    this.subscribe(this.stereotypeIdPair$.pipe(
      mergeMap(([_, second]) => this.stereotypes$.pipe(
        map(stereotypes => stereotypes.find(x => x.stereotypeId === second)),
        take(1)
      ))
    ), async stereotype => {
      if (this.creating) {
        this.loadingSubject.next(true);
      }

      this.onModelChange({ ...cloneDeep(this.model), stereotypeRowVersion: stereotype?.rowVersion ?? [] });
      this.loadingSubject.next(false);
    });
  }

  public ngAfterViewInit(): void {
    if (this.creating && this.createPresetId) {
      const createAdditionPreset: ModelAdditionPreset = this.entityCreatePresetService.getPreset(this.createPresetId);

      if (createAdditionPreset?.backlink) {
        this.backlinkChange.emit(cloneDeep(createAdditionPreset.backlink));
        delete createAdditionPreset.backlink;
      }

      if (createAdditionPreset?.parentComponentId) {
        this.parentComponent = createAdditionPreset.parentComponentId
        delete createAdditionPreset.parentComponentId;
      }

      if (createAdditionPreset) {
        this.setModel(createAdditionPreset as any);
        this.changeDetector.detectChanges();
      }
    }

    setTimeout(() => this.presetAppliedSubject.next(true));
    setTimeout(() => this.createPresetApplied.emit());
  }

  /**
   * Sets the selected stereotype id, deletes customPropertyValues if not {@link stereotyped} and updates the model.
   *
   * @param model - The changed model
   */
  public onModelChange(model: T): void {
    if ('stereotypeId' in model) {
      this.selectedStereotypeIdSubject.next((model as any).stereotypeId);
    }

    const newModel = { ...this.model, ...cloneDeep(model) };
    if (!this.stereotyped) {
      delete (newModel as any).customPropertyValues;
    }

    this.modelSubject.next(newModel);
    this.modelChange.emit(this.model);

    // Re-trigger validation
    setTimeout(() => this.modelValidSubject.next({
      ...this.modelValidSubject.getValue(),
      ownModel: this.isOwnModelValid()
    }));
  }

  public getSanitizedModel(): T {
    return this.model;
  }

  public isModelValid(): boolean {
    return this.isOwnModelValid() && values(this.modelValidSubject.getValue()).every(value => Boolean(value));
  }

  public isOwnModelValid(): boolean {
    return this.form?.valid;
  }

  public reset(): void {
    this.modelSubject.next({} as any);
    this.modelValidSubject.next({});

    this.ngOnDestroy();
    this.ngOnInit();
  }

  public changeFieldHook(field: FormlyFieldConfig, fn: (value: T) => void): void {
    this.subscribe(field.formControl.valueChanges, value => fn(value));
  }

  public isFormPristine(): boolean {
    return this.form.pristine;
  }

  public onNavigateToTab(commands: any[]): void {
    if (this.id) {
      this.router.navigate(commands, { relativeTo: this.route, preserveFragment: true });
    }
  }

  protected abstract createForm(): FormlyFieldConfig[];

  /* istanbul ignore next */
  protected getStereotypeFields(withDivider: boolean = true, halfWidth: boolean = false): FormlyFieldConfig[] {
    const mapAndSortStereotypeItems = (stereotypes: StereotypeDto[]): {
      label: string,
      value: number,
      position: number
    }[] => stereotypes
      .map(stereotype => ({ value: stereotype.stereotypeId, label: stereotype.name, position: stereotype.position }))
      .sort((a, b) => {
        return a.position - b.position;
      });
    const stereotypeItems$ = this.stereotypes$.pipe(
      map(stereotypes => mapAndSortStereotypeItems(stereotypes))
    );

    const filteredStereotypeItems$ = this.stereotypes$.pipe(
      combineLatestWith(this.additionalStereotypeFilterFn),
      map(([stereotypes, filterFn]) => mapAndSortStereotypeItems(stereotypes.filter(x => !x.isArchived).filter(filterFn))),
      distinctUntilChanged()
    );

    this.subscribe(filteredStereotypeItems$, ((availableTypes) => {
      const currentId: number = this.form.get('stereotypeId')?.value;

      if (currentId) {
        const isCurrentAvailable = availableTypes.some(x => x.value === currentId);

        if (isCurrentAvailable == false) {
          this.form.get('stereotypeId').setValue(null);
        }
      }
    }));

    return [
      ...(withDivider ? [{
        type: 'core-portal-divider',
        className: 'col-md-12'
      }] : []),
      {
        key: 'stereotypeId',
        type: 'core-portal-ng-select',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: halfWidth ? 'col-md-6' : 'col-md-12',
        defaultValue: null,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.stereotype',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.ENUM,
            enumOptions: filteredStereotypeItems$,
            link: (stereotypeId: number) => stereotypeId ? ['stereotypes', stereotypeId] : null,
            module: 'settings'
          } as CorePortalFormlyReadonlyTyping,
          corePortalNgSelect: {
            items$: filteredStereotypeItems$,
            link: (stereotypeId: number) => stereotypeId ? ['stereotypes', stereotypeId] : null,
            module: 'settings'
          }
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.disabled': () => this.loadingSubject.getValue(),
          'templateOptions.readonly': () => this.readonly
        },
        hooks: {
          onInit: field => {
            this.stereotypeFieldInit(field);
          }
        }
      }
    ];
  }

  protected setModel(model: T): void {
    const newModel = { ...this.model, ...cloneDeep(model) };
    this.modelSubject.next(newModel);
    this.setStereotypeId(newModel);
  }

  protected setReadonly(readonly: boolean): void {
    this.readonlySubject.next(readonly);
  }

  protected stereotypeFieldInit(field: FormlyFieldConfig): void {
    this.setDefaultStereotype(field);
  }

  protected updateFieldHook(field: FormlyFieldConfig, fields: string[]): void {
    const fields$: Observable<any>[] = fields.map(x => field.form.get(x).valueChanges);
    this.subscribe(merge(...fields$), () => this.updateFieldValidity(field));
  }

  protected updateFieldValidity(field: FormlyFieldConfig): void {
    field.formControl.markAsTouched();
    field.formControl.updateValueAndValidity({
      emitEvent: false
    });
  }

  private setDefaultStereotype(field: FormlyFieldConfig): void {
    if (this.creating) {
      this.subscribe(this.stereotypes$.pipe(
        filter(stereotypes => Boolean(stereotypes) && Boolean(stereotypes.length)),
        take(1),
        map(stereotypes => {
          const sortedStereotypes = cloneDeep(stereotypes).sort((a, b) => a.isDefault ? -1 : 1);
          return sortedStereotypes[0];
        }),
        catchError(() => NEVER)
      ), stereotype => {
        if (!field.formControl.value) {
          this.modelSubject.next({ ...this.model, stereotypeId: stereotype.stereotypeId });
          this.setStereotypeId(this.model, field);
        }
      });
    }
  }

  private setStereotypeId(model: T, field?: FormlyFieldConfig): void {
    if (!isEmpty(model) && 'stereotypeId' in model) {
      this.selectedStereotypeIdSubject.next((model as any).stereotypeId);
      field?.formControl?.setValue((model as any).stereotypeId);
    } else if (isEmpty(model)) {
      this.selectedStereotypeIdSubject.next(null);
      field?.formControl?.setValue(null);
    }
  }
}
