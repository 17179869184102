<!-- Inheritance -->
<nexnox-web-cardbox *ngIf="hasParent" [title]="'resources.subtitles.heredity' | translate">
  <form [formGroup]="form">
    <formly-form [(model)]="model"
                 [fields]="fields"
                 [form]="form"
                 class="row w-100">
    </formly-form>
  </form>
</nexnox-web-cardbox>

<!-- Article list -->
<nexnox-web-knowledge-paths-articles-edit (articlesChange)="onArticlesChanged($event)"
                                          [actions]="createArticleActions()"
                                          [articlesSubject]="articlesSubject"
                                          [loading]="loading"
                                          [readonly]="readonly || model.inheritsKnowledge">
</nexnox-web-knowledge-paths-articles-edit>

<nexnox-web-resource-article-preview-sidebar #articlePreviewSidebar>
</nexnox-web-resource-article-preview-sidebar>
