<nexnox-web-entity-edit [entityEditBaseComponent]="this"
                        [stereotyped]="true"
                        displayKey="title">
</nexnox-web-entity-edit>

<ng-container *ngIf="(modelSubject | async) as model">
  <nexnox-web-cardbox [title]="'core-shared.shared.fields.reason' | translate"
                      ghostPaddingClasses="p-3"
                      paddingClasses="px-2 pb-3">
    <form [formGroup]="form">
      <formly-form (modelChange)="onModelChange($event)"
                   [fields]="reasonFields"
                   [form]="form"
                   [model]="model"
                   class="form row">
      </formly-form>
    </form>
  </nexnox-web-cardbox>
</ng-container>
