<ng-template #entityEditTemplate let-id="id" let-title="title">
  <nexnox-web-master-data-addresses-address-edit #modelComponent
                                                 (modelChange)="onModelChange($event)"
                                                 [creating]="false"
                                                 [id]="id"
                                                 [loading]="loading$ | async"
                                                 [model]="model$ | async"
                                                 [readonly]="readonly$ | async"
                                                 [stereotypes$]="stereotypes$"
                                                 [title]="title">
  </nexnox-web-master-data-addresses-address-edit>
</ng-template>

<nexnox-web-entity-detail [entityDetailBaseComponent]="this"
                          [entityEditTemplate]="entityEditTemplate"
                          [tabCommands]="['.']"
                          [tabHeading]="title | translate"
                          tabId="address">
  <!-- Locations Tab -->
  <ng-template (selectTab)="onNavigateToTab(['locations'])"
               *ngIf="readLocationsPermission$ | async"
               [heading]="'core-portal.master-data.subtitles.location-list' | translate"
               [lazy]="true"
               nexnoxWebTabTemplate
               tabId="locations">
    <nexnox-web-master-data-addresses-address-location-list *ngIf="passedIds"
                                                            [canCreate]="false"
                                                            [isEmbedded]="true"
                                                            [parentIds]="passedIds">
    </nexnox-web-master-data-addresses-address-location-list>
  </ng-template>

  <!-- Companies Tab -->
  <ng-template (selectTab)="onNavigateToTab(['companies'])"
               *ngIf="readCompaniesPermission$ | async"
               [heading]="'core-portal.master-data.subtitles.company-list' | translate"
               [lazy]="true"
               nexnoxWebTabTemplate
               tabId="companies">
    <nexnox-web-master-data-addresses-address-company-list *ngIf="passedIds"
                                                           [canCreate]="false"
                                                           [isEmbedded]="true"
                                                           [parentIds]="passedIds">
    </nexnox-web-master-data-addresses-address-company-list>
  </ng-template>
</nexnox-web-entity-detail>
