<nexnox-web-entity-edit *ngIf="!creating"
                        [entityEditBaseComponent]="this"
                        [stereotyped]="false"
                        displayKey="title">
</nexnox-web-entity-edit>

<nexnox-web-tabs *ngIf="creating"
                 [internal]="true"
                 [isTransparent]="true"
                 startTab="entityEdit">
  <!-- Entity Edit Tab -->
  <ng-template [hasError]="!isOwnModelValid()"
               [heading]="title | translate"
               nexnoxWebTabTemplate
               tabId="entityEdit">
    <nexnox-web-entity-edit [entityEditBaseComponent]="this"></nexnox-web-entity-edit>
  </ng-template>

  <!-- Subtasks Tab -->
  <ng-template [hasError]="subtasksComponent ? !subtasksComponent.isModelValid() : false"
               [heading]="'orga-portal.tasks.subtitles.subtasks' | translate"
               [hide]="model?.type !== concreteTaskTypes.Collection"
               nexnoxWebTabTemplate
               tabId="subtasks">
    <nexnox-web-tasks-task-subtasks #subtasksComponent
                                    (strictOrderChange)="onStrictOrderChange($event)"
                                    (subtasksChange)="onSubtasksChange($event)"
                                    [loading]="loading"
                                    [readonly]="readonly"
                                    [strictOrder$]="strictOrder$"
                                    [subtasks$]="subtasksSubject">
    </nexnox-web-tasks-task-subtasks>
  </ng-template>

  <!-- Executions Tab -->
  <ng-template [hasError]="executionsComponent ? !executionsComponent.isModelValid() : false"
               [heading]="'orga-portal.tasks.subtitles.executions' | translate"
               [hide]="$any(model)?.executionPlan?.type !== executionTypes.Planned"
               nexnoxWebTabTemplate
               tabId="executions">
    <nexnox-web-tasks-task-executions #executionsComponent
                                      (executionPatternsChange)="onExecutionsChange($event)"
                                      [executionPatterns$]="executionPatternsSubject"
                                      [loading]="loading"
                                      [readonly]="readonly">
    </nexnox-web-tasks-task-executions>
  </ng-template>

  <!-- Impacts Tab -->
  <ng-template [hasError]="impactsComponent ? !impactsComponent.isModelValid() : false"
               [heading]="'orga-portal.tasks.subtitles.impacts' | translate"
               nexnoxWebTabTemplate
               tabId="impacts">
    <nexnox-web-tasks-task-impacts #impactsComponents
                                   (impactsChange)="onImpactsChange($event)"
                                   [impacts$]="impactsSubject"
                                   [loading]="loading"
                                   [readonly]="readonly"
                                   [task$]="model$">
    </nexnox-web-tasks-task-impacts>
  </ng-template>
</nexnox-web-tabs>

<!-- Location Templates -->
<ng-template #locationSelectLabelTitleTemplate let-item="item">
  <nexnox-web-master-data-locations-location-location-number [label]="true"
                                                             [location]="item">
  </nexnox-web-master-data-locations-location-location-number>
</ng-template>

<ng-template #locationSelectOptionTitleTemplate let-item="item" let-searchTerm="searchTerm">
  <nexnox-web-master-data-locations-location-location-number [label]="false"
                                                             [location]="item"
                                                             [searchTerm]="searchTerm">
  </nexnox-web-master-data-locations-location-location-number>
</ng-template>

<!-- Category Templates -->
<ng-template #categorySelectLabelTitleTemplate let-item="item">
  <nexnox-web-categories-category-label [category]="item"></nexnox-web-categories-category-label>
</ng-template>

<ng-template #categorySelectOptionTitleTemplate let-item="item" let-searchTerm="searchTerm">
  <nexnox-web-categories-category-label [category]="item"
                                        [searchTerm]="searchTerm"></nexnox-web-categories-category-label>
</ng-template>
