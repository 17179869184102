import {
  CorePortalFeatureAuditListXsStore,
  CorePortalFeatureAuditListXsStoreState
} from '@nexnox-web/core-portal/features/audit';
import {Action, ActionReducer, createSelector} from '@ngrx/store';
import {selectResourcesState} from '../../resources.selectors';
import {Injectable, Injector} from '@angular/core';

export interface ResourceAuditStoreState extends CorePortalFeatureAuditListXsStoreState {
}

/* istanbul ignore next */
export const resourceAuditStore = new CorePortalFeatureAuditListXsStore({
  actionLabel: 'Tech Portal - Resoruces - Resource Audit',
  stateSelector: createSelector(selectResourcesState, state => state.resourceAudit)
});

export function resourceAuditStoreReducer(state: ResourceAuditStoreState, action: Action): ActionReducer<any> {
  return resourceAuditStore.reducer(state, action);
}

@Injectable()
export class ResourceAuditStoreEffects extends resourceAuditStore.effects {
  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }
}
