<ng-template #entityEditTemplate let-title="title">
  <nexnox-web-knowledge-paths-edit #editComponent
                                   (modelChange)="createModel$.next($event)"
                                   [creating]="true"
                                   [loading]="(loaded$ | async) === false"
                                   [model]="createModel$ | async"
                                   [readonly]="false"
                                   [stereotyped]="false"
                                   [title]="title">
  </nexnox-web-knowledge-paths-edit>
</ng-template>

<nexnox-web-entity-overview #overviewComponent
                            [canExport]="true"
                            [entityEditTemplate]="entityEditTemplate"
                            [entityOverviewBaseComponent]="this"
                            [stereotyped]="false"
                            detailLink="/knowledge/paths"
                            module="communication">
</nexnox-web-entity-overview>
