import {ChangeDetectionStrategy, Component, Injector} from '@angular/core';
import {ActionButton, CorePortalEntityDetailBaseComponent} from '@nexnox-web/core-portal';
import {AppPermissions, TaskJobStateReasonSetDto} from '@nexnox-web/core-shared';
import {cancellationReasonsDetailStore} from '../../store';

@Component({
  selector: 'nexnox-web-cancellation-reasons-cancellation-reasons-detail',
  templateUrl: './cancellation-reasons-detail.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CancellationReasonsDetailComponent extends CorePortalEntityDetailBaseComponent<TaskJobStateReasonSetDto> {
  public title = 'orga-portal.cancellation-reasons.subtitles.cancellation-reasons-detail';

  protected idParam = 'taskJobStateReasonSetId';
  protected displayKey = 'title';

  constructor(
    protected injector: Injector
  ) {
    super(injector, cancellationReasonsDetailStore);
  }

  protected async getActionButtons(): Promise<ActionButton[]> {
    return this.getDefaultActionButtons(
      'orga-portal.cancellation-reasons.actions.edit-cancellation-reasons',
      'orga-portal.cancellation-reasons.actions.save-cancellation-reasons',
      'orga-portal.cancellation-reasons.actions.delete-cancellation-reasons',
      'orga-portal.cancellation-reasons.descriptions.delete-cancellation-reasons',
      AppPermissions.UpdateTaskJobStateReasonSet,
      AppPermissions.DeleteTaskJobStateReasonSet,
      ['/cancellation-reasons']
    );
  }
}
