import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {CorePortalPermissionGuard} from '@nexnox-web/core-portal';
import {CancellationReasonsDetailComponent, CancellationReasonsListComponent} from './containers';
import {AppPermissions, I18nBreadcrumbResolver, UnsavedChangesGuard, UnsavedCreateGuard} from '@nexnox-web/core-shared';

export const ORGA_PORTAL_CANCELLATION_REASONS_ROUTES: Routes = [
  {
    path: '',
    canActivate: [CorePortalPermissionGuard],
    canDeactivate: [UnsavedCreateGuard],
    component: CancellationReasonsListComponent,
    data: {
      permissions: [AppPermissions.DisplayTasks]
    }
  },
  {
    path: ':taskJobStateReasonSetId',
    component: CancellationReasonsDetailComponent,
    canActivate: [CorePortalPermissionGuard],
    canDeactivate: [UnsavedChangesGuard],
    data: {
      permissions: [AppPermissions.ReadTaskJobStateReasonSet],
      breadcrumbs: I18nBreadcrumbResolver,
      tab: 'cancellationReasons',
      text: 'orga-portal.cancellation-reasons.subtitles.cancellation-reasons-detail'
    }
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(ORGA_PORTAL_CANCELLATION_REASONS_ROUTES)
  ],
  exports: [
    RouterModule
  ]
})
export class CancellationReasonsRoutingModule {
}
