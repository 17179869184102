import {OrgaPortalFeatureCategoryService} from './services';
import {categoryDetailStore, categoryListStore} from './stores';

export * from './services';
export * from './stores';

export * from './categories.state';
export * from './categories.selectors';

export const categoriesReducers = {
  categoryList: categoryListStore.reducer,
  categoryDetail: categoryDetailStore.reducer
};

export const categoriesEffects = [
  categoryListStore.effects,
  categoryDetailStore.effects
];

export const categoriesServices = [
  OrgaPortalFeatureCategoryService
];
