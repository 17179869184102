import {NgModule} from '@angular/core';
import {BreadcrumbsComponent} from './containers';
import {BreadcrumbsGhostComponent} from './ghosts';
import {NgxSkeletonLoaderModule} from 'ngx-skeleton-loader';
import {CoreSharedModule} from '@nexnox-web/core-shared';

const exportedComponents = [
  BreadcrumbsComponent
];

const exportedGhosts = [
  BreadcrumbsGhostComponent
];

@NgModule({
  declarations: [
    ...exportedComponents,
    ...exportedGhosts
  ],
  exports: [
    ...exportedComponents,
    ...exportedGhosts
  ],
  imports: [
    CoreSharedModule,
    NgxSkeletonLoaderModule
  ]
})
export class CorePortalBreadcrumbsModule {
}
