import {SnapElement} from './snap-element.model';

import 'snapsvg-cjs';

declare const Snap: any;

export class SnapPaper extends SnapElement {
  private readonly snapPaper: any;

  constructor(
    query: string
  ) {
    super(0, 0);

    this.snapPaper = Snap(query);
  }

  public addElement<T extends SnapElement>(snapElement: T): T {
    this.elementMap.set(snapElement, snapElement.createSnapElement(this.snapPaper));
    return snapElement;
  }

  public removeElement<T extends SnapElement>(snapElement: T): void {
    snapElement.remove();
    this.elementMap.delete(snapElement);
  }

  public clear(): void {
    this.elementMap.clear();
    this.snapPaper.clear();
  }
}
