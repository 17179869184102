import {Directive, Injector, OnDestroy, OnInit} from '@angular/core';
import {ActionButton} from '../../../../../services';
import {CorePortalEntityDetailBaseComponent} from '../entity-detail-base/entity-detail-base.component'
import {PRIMARY_OUTLET} from "@angular/router";
import {AppPermissions} from "@nexnox-web/core-shared";
import {BehaviorSubject, merge, Observable} from "rxjs";
import {faSave} from "@fortawesome/free-solid-svg-icons/faSave";
import {faTimesCircle} from "@fortawesome/free-solid-svg-icons/faTimesCircle";
import {EntityXsStore, EntityXsStoreSaveSuccessPayload} from "@nexnox-web/core-store";
import {distinctUntilChanged, map, mergeMap, startWith, withLatestFrom} from "rxjs/operators";

@Directive()
export abstract class CorePortalEntityDetailStandaloneBaseComponent<E> extends CorePortalEntityDetailBaseComponent<E> implements OnInit, OnDestroy {

  public title: string;
  public isCreate: boolean;

  public isCreate$: Observable<boolean>;
  public disablePrimaryAction$: Observable<boolean>;

  private hasUnsavedChanges$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  protected constructor(
    protected injector: Injector,
    protected entityStore: EntityXsStore<E>,
  ) {
    super(injector, entityStore);
  }

  public ngOnInit(): void {
    super.ngOnInit();

    this.store.dispatch(this.entityStore.actions.getStereotypes({ excludeArchived: true }));

    this.isCreate$ = this.model$.pipe(map(model => !this.route?.snapshot?.paramMap?.get(this.idParam) ?? !model[this.idParam]), distinctUntilChanged());
    this.subscribe(this.isCreate$, isCreate => {
      this.isCreate = isCreate;
      isCreate ? this.onEditAction() : this.setViewMode();
    });
    this.subscribe(this.hasUnsavedChanges(), (hasUnsavedChanges) => {
      this.hasUnsavedChanges$.next(hasUnsavedChanges);
      this.isDeactivateUnsavedChangesModal = !hasUnsavedChanges;
    });

    // Validation
    const shouldDisablePrimaryAction$ = this.isModelValid$.pipe(map(isValid => !isValid));

    this.disablePrimaryAction$ = merge(
      // disable on invalid model
      shouldDisablePrimaryAction$,
      // disable on loading
      this.loading$.pipe(
        withLatestFrom(shouldDisablePrimaryAction$),
        map(([isLoading, shouldDisable]) => isLoading || shouldDisable)
      )
    );
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  protected onSaveOrCreate(): void {
    if (this.isCreate) {
      this.onCreateNative(this.getReturnPathForSaveOrCreate());
    } else if (window.location.hash === '#edit') {
      this.onSaveAction(this.getReturnPathForSaveOrCreate());
    } else {
      this.onEditAction();
      this.setEditMode();
    }
  }

  protected onSaveSuccess(payload: EntityXsStoreSaveSuccessPayload<E, E>): void {
    super.onSaveSuccess({ ...payload, doNotEmitEvents: true });
  }

  protected onCancelAndRedirect(): void {
    if (!this.hasUnsavedChanges$.getValue() && !this.isCreate) {
      this.setViewMode();
      window.location.hash = '';
    } else {
      // When protected with canDeactivate a modal pops up before route changes
      this.router.navigate(this.getReturnPathForCancel()).then();
    }
  }

  protected getStandaloneActionButtons(
    createKey: string,
    createPermission: AppPermissions,
    editKey: string,
    editPermission: AppPermissions,
    saveKey: string,
    savePermission: AppPermissions
  ): ActionButton[] {
    return [
      {
        type: 'button',
        class: 'btn-outline-secondary',
        label: 'core-portal.core.general.cancel',
        icon: faTimesCircle,
        isLoading: () => this.loading$,
        shouldShow: () => this.readonly$.pipe(map(readonly => !readonly)),
        callback: () => this.onCancelAndRedirect()
      },
      {
        type: "button",
        class: 'btn-primary',
        label: createKey,
        icon: faSave,
        permission: createPermission,
        shouldShow: () => this.isCreate$,
        isDisabled: () => this.disablePrimaryAction$,
        isLoading: () => this.loading$,
        callback: () => this.onSaveOrCreate()
      },
      {
        type: "button",
        class: 'btn-primary',
        label: editKey,
        icon: faSave,
        permission: editPermission,
        shouldShow: () => this.isCreate$.pipe(mergeMap(isCreate => this.readonly$.pipe(map(readonly => !isCreate && readonly)))),
        isDisabled: () => this.disablePrimaryAction$,
        isLoading: () => this.loading$,
        callback: () => this.onSaveOrCreate()
      },
      {
        type: "button",
        class: 'btn-primary',
        label: saveKey,
        icon: faSave,
        permission: savePermission,
        shouldShow: () => this.isCreate$.pipe(mergeMap(isCreate => this.readonly$.pipe(map(readonly => !isCreate && !readonly)))),
        isDisabled: () => this.disablePrimaryAction$,
        isLoading: () => this.loading$,
        callback: () => this.onSaveOrCreate()
      }
    ]
  }

  protected getReturnPathForCancel(): Array<string> {
    return this.popCurrentPath();
  }

  protected getReturnPathForSaveOrCreate(): Array<string> {
    return this.popCurrentPath();
  }

  protected popCurrentPath(): Array<string> {
    const url = this.router.parseUrl(this.router.url).root.children[PRIMARY_OUTLET].segments.map((s) => s.path);
    url.pop();
    return url;
  }

  protected setTitle(createTitle: string, editTitle: string): void {
    this.subscribe(this.isCreate$.pipe(startWith(true)), (isCreate) => {
      this.title = isCreate ? createTitle : editTitle;
      this.pageTitleService.setPageTitle(this.title);
    });
  }

}
