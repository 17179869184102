import {Injectable} from '@angular/core';
import {CanDeactivate} from '@angular/router';
import {from, Observable, of} from 'rxjs';
import {UnsavedChanges} from '../unsaved-changes/unsaved-changes.model';
import {CoreSharedModalService} from '../../services';
import {tap} from 'rxjs/operators';

@Injectable()
export class UnsavedCreateGuard implements CanDeactivate<UnsavedChanges> {

  constructor(
    private modalService: CoreSharedModalService,
  ) {
  }

  public canDeactivate(component: UnsavedChanges | any): Observable<boolean> {
    if (!component || !component.hasUnsavedChanges || component.isDeactivateUnsavedChangesModal) {
      return of(true);
    }
    return component.hasUnsavedChanges() ? this.dialog(component) : of(true);
  }

  private dialog(component: any): Observable<boolean> {
    return from(this.modalService.promptUnsavedChangesModal()).pipe(
      tap(answer => component.isDeactivateUnsavedChangesModal = answer)
    );
  }
}
