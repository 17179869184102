import {ChangeDetectionStrategy, Component, Injector} from '@angular/core';
import {
  ActionButton,
  CorePortalEntityOverviewBaseComponent,
  DatatableActionButton,
  DeleteEntityModel
} from '@nexnox-web/core-portal';
import {AppEntityType, AppPermissions, InvitationDto, InvitationListDto, Mappers} from '@nexnox-web/core-shared';
import {invitationsDetailStore, invitationsListStore} from '../../store/stores';
import {faHandshakeSlash} from "@fortawesome/free-solid-svg-icons/faHandshakeSlash";
import {faHandshake} from "@fortawesome/free-solid-svg-icons/faHandshake";
import {ofType} from "@ngrx/effects";
import {take} from "rxjs/operators";

@Component({
  selector: 'nexnox-web-settings-invitations-list',
  templateUrl: './invitations-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InvitationsListComponent extends CorePortalEntityOverviewBaseComponent<InvitationListDto> {
  public title = 'core-portal.settings.invitations.titles.list';
  public createTitle = 'core-portal.settings.invitations.actions.create';
  public idProperty = 'invitationId';
  public displayProperty = 'name';
  public datatableConfigName = 'InvitationsDefault';
  public componentId = 'InvitationsListComponent';
  public detailLink = 'invitations';

  constructor(
    protected injector: Injector
  ) {
    super(injector, invitationsListStore, Mappers.InvitationListDto.serializedName, AppEntityType.None);
  }

  public getRowActionButtons(): DatatableActionButton[] {
    return [
      ...this.getDefaultRowActionButtons(
        'core-portal.settings.invitations.actions.edit',
        (row: InvitationListDto) => `invitations/${ row.invitationId }`,
        [AppPermissions.UpdateInvitation],
        {
          module: 'settings',
          // Navigate to readonly detail without #edit fragment
          onClick: row => this.tenantRouter.navigate([`${ this.detailLink }/${ row[this.idProperty] }`], { module: 'settings' })
        }
      ),
      {
        tooltip: 'core-portal.settings.invitations.actions.revoke',
        icon: faHandshakeSlash,
        onClick: (row) => {
          this.store.dispatch(invitationsDetailStore.actions.revokeInvitation({ invitation: row as InvitationDto }));
          this.actions$.pipe(
            ofType(invitationsDetailStore.actions.revokeInvitationSuccess),
            take(1)
          ).subscribe(() => {
            this.refreshList();
          });
        },
        show: (row: InvitationListDto) => row.isRevoked !== true,
        permissions: [AppPermissions.UpdateInvitation]
      },
      {
        tooltip: 'core-portal.settings.invitations.actions.reactivate',
        icon: faHandshake,
        onClick: (row) => {
          this.store.dispatch(invitationsDetailStore.actions.reactivateInvitation({ invitation: row as InvitationDto }));
          this.actions$.pipe(
            ofType(invitationsDetailStore.actions.reactivateInvitationSuccess),
            take(1)
          ).subscribe(() => {
            this.refreshList();
          });
        },
        show: (row: InvitationListDto) => row.isRevoked === true,
        permissions: [AppPermissions.UpdateInvitation]
      }
    ];
  }

  public getDeleteEntityModel(): DeleteEntityModel {
    return {
      titleKey: 'core-portal.settings.invitations.actions.delete',
      descriptionKey: 'core-portal.settings.invitations.descriptions.delete',
      confirmKey: 'core-portal.settings.invitations.actions.delete',
      deletePermission: AppPermissions.DeleteInvitation
    };
  }

  public onCreate(): void {
    this.isDeactivateUnsavedChangesModal = true;
    this.entityActionsFacade.createOne({
      model: this.createModel$.getValue(),
      parentIds: this.parentIds,
      goBack: () => this.tenantRouter.navigate(['invitations'], { module: 'settings' })
    });
  }

  protected async getActionButtons(): Promise<ActionButton[]> {
    return this.getDefaultActionButtons(
      'core-portal.settings.invitations.actions.create',
      AppPermissions.CreateInvitation
    );
  }

}
