import {DeviceListXsStore, DeviceListXsStoreState} from './device-list.xs-store';
import {createSelector} from '@ngrx/store';
import {selectDevicesState} from '../../devices.selectors';
import {OrgaPortalFeatureDeviceService} from '../../services';
import {AppEntityType} from '@nexnox-web/core-shared';

export interface DeviceListStoreState extends DeviceListXsStoreState {
}

/* istanbul ignore next */
export const deviceListStore = new DeviceListXsStore({
  actionLabel: 'Orga Portal - Devices - Device List',
  stateSelector: createSelector(selectDevicesState, state => state.deviceList),
  serviceType: OrgaPortalFeatureDeviceService,
  entityType: AppEntityType.None,
  selectId: device => device.entity?.id ?? device.model?.id,
  stereotyped: false
});
