<nexnox-web-cardbox #cardboxComponent
                    (titleChange)="onTitleChange($event)"
                    *ngIf="!noCardbox && entityEditBaseComponent as bc; else formTemplate"
                    [customAccent]="customAccent"
                    [footerActions]="!disableActions ? bc.footerActions : undefined"
                    [headerActions]="!disableActions ? bc.headerActions : undefined"
                    [iconColor]="iconColor"
                    [iconSize]="iconSize"
                    [icon]="icon"
                    [isAccordion]="isAccordion"
                    [isCollapsed]="isCollapsed"
                    [isTitleEditable]="!bc.readonly && !bc.creating && $any(displayKey) && isTitleEditable"
                    [loading]="bc.loadingSubject?.asObservable() | async"
                    [noMarginBottom]="noMarginBottom"
                    [prependTitle]="bc.id && !useTitle ? '#' + bc.id : null"
                    [titleFieldFn]="titleFieldFn"
                    [titleKey]="displayKey"
                    [titleModel]="bc.model"
                    [title]="(useTitle || !displayKey || bc.creating) ? (customTitle ? customTitle : bc.title) : (bc.model ? bc.model[displayKey] : null)"
                    [transparent]="isTransparent"
                    [withAccent]="!hideAccent"
                    ghostPaddingClasses="px-3 pb-3"
                    paddingClasses="px-2">
  <ng-container *ngIf="!noForm" [ngTemplateOutlet]="formTemplate"></ng-container>
  <ng-content></ng-content>
</nexnox-web-cardbox>

<ng-template #formTemplate>
  <form *ngIf="entityEditBaseComponent as bc"
        [formGroup]="bc.form">
    <formly-form (modelChange)="onModelChange($event, true)"
                 [fields]="bc.fields"
                 [form]="bc.form"
                 [model]="bc.model"
                 class="row">
    </formly-form>
  </form>
</ng-template>

<ng-container *ngIf="entityEditBaseComponent as bc">
  <nexnox-web-custom-property-sets-edit #customPropertySetsEditComponent
                                        (modelChange)="onModelChange($event)"
                                        (validChange)="onCustomPropertyValidChange()"
                                        *ngIf="bc.stereotyped && stereotyped"
                                        [creating]="bc.creating"
                                        [inheritance]="inheritance"
                                        [loading]="bc.loadingSubject.asObservable() | async"
                                        [model]="bc.model"
                                        [noMarginForLast]="noMarginForLastCustomProperty"
                                        [readonly]="bc.readonly"
                                        [selectedStereotypeId$]="bc.selectedStereotypeId$"
                                        [stereotypes$]="bc.stereotypes$">
  </nexnox-web-custom-property-sets-edit>
</ng-container>
