import {ChangeDetectionStrategy, Component, EventEmitter, Output} from '@angular/core';
import {faArrowRight} from '@fortawesome/free-solid-svg-icons/faArrowRight';
import {faQuestionCircle} from '@fortawesome/free-solid-svg-icons/faQuestionCircle';

@Component({
  selector: 'nexnox-web-contact-new-functions-alert',
  templateUrl: './contact-new-functions-alert.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactNewFunctionsAlertComponent {
  @Output() public navigateToAreas: EventEmitter<void> = new EventEmitter<void>();

  public faQuestionCircle = faQuestionCircle;
  public faArrowRight = faArrowRight;

  public onNavigateToAreas(event: Event): void {
    event.preventDefault();
    this.navigateToAreas.emit();
  }
}
