import {IssueContentType, IssueType} from "@nexnox-web/core-shared";

export const issueContentTypeEnumOptions: {
  label: string,
  value: IssueContentType
}[] =
  [
    {
      label: 'issues.content-types.1',
      value: IssueContentType.Message
    },
    {
      label: 'issues.content-types.2',
      value: IssueContentType.Ticket
    },
    {
      label: 'issues.content-types.3',
      value: IssueContentType.Mission
    },
    {
      label: 'issues.content-types.4',
      value: IssueContentType.WinterhalterServiceCall
    },
    {
      label: 'issues.content-types.5',
      value: IssueContentType.WinterhalterProductOrder
    },
  ];

export const issueTypeEnumOptions: {
  label: string,
  value: IssueType
}[] =
  [
    {
      label: 'issues.issue-types.1',
      value: IssueType.Message
    },
    {
      label: 'issues.issue-types.2',
      value: IssueType.Ticket
    },
    {
      label: 'issues.issue-types.3',
      value: IssueType.Mission
    },
    {
      label: 'issues.issue-types.4',
      value: IssueType.WinterhalterServiceCall
    },
    {
      label: 'issues.issue-types.5',
      value: IssueType.WinterhalterProductOrder
    },
    {
      label: 'issues.issue-types.6',
      value: IssueType.ControlMessage
    },
    {
      label: 'issues.issue-types.7',
      value: IssueType.ControlMission
    },
    {
      label: 'issues.issue-types.8',
      value: IssueType.MailIssue
    }
  ];
