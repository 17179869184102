import {ChangeDetectionStrategy, Component, Injector} from '@angular/core';
import {CorePortalFeatureAuditListBaseComponent} from '@nexnox-web/core-portal/features/audit';
import {contractAuditStore} from '../../store/stores';

@Component({
  selector: 'nexnox-web-contracts-contract-audit',
  templateUrl: './contract-audit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContractAuditComponent extends CorePortalFeatureAuditListBaseComponent {
  public componentId = 'ContractAuditComponent';

  constructor(
    protected injector: Injector
  ) {
    super(injector, contractAuditStore);
  }
}
