import {InjectionToken, NgModule} from '@angular/core';
import {ActionReducerMap, StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {cancellationReasonsEffects, cancellationReasonsReducers} from './store';

export const REDUCER_TOKEN = new InjectionToken<ActionReducerMap<any>>(
  '@nexnox-web/orga-portal/cancellation-reasons: Store Reducers'
);

@NgModule({
  imports: [
    StoreModule.forFeature('orga-portal-feature-cancellation-reasons', REDUCER_TOKEN),
    EffectsModule.forFeature(cancellationReasonsEffects)
  ],
  providers: [
    {
      provide: REDUCER_TOKEN,
      useValue: cancellationReasonsReducers
    }
  ]
})
export class CancellationReasonsStoreModule {
}
