<ng-template #entityEditTemplate let-id="id" let-title="title">
  <nexnox-web-master-data-companies-company-edit #modelComponent
                                                 (modelChange)="onModelChange($event)"
                                                 [creating]="false"
                                                 [id]="id"
                                                 [loading]="loading$ | async"
                                                 [model]="model$ | async"
                                                 [readonly]="readonly$ | async"
                                                 [stereotypes$]="stereotypes$"
                                                 [title]="title">
  </nexnox-web-master-data-companies-company-edit>
</ng-template>

<nexnox-web-entity-detail [entityDetailBaseComponent]="this"
                          [entityEditTemplate]="entityEditTemplate"
                          [tabCommands]="['.']"
                          [tabHeading]="title | translate"
                          tabId="company">
  <!-- Contacts Tab -->
  <ng-template (selectTab)="onNavigateToTab(['contacts'])"
               [heading]="'core-portal.master-data.subtitles.contact-list' | translate"
               [lazy]="true"
               nexnoxWebTabTemplate
               tabId="contacts">
    <nexnox-web-master-data-companies-company-contact-list *ngIf="passedIds"
                                                           [getEntityOnModeChange]="false"
                                                           [isEmbedded]="true"
                                                           [parentIds]="passedIds">
    </nexnox-web-master-data-companies-company-contact-list>
  </ng-template>

  <!-- Addresses Tab -->
  <ng-template (selectTab)="onNavigateToTab(['addresses'])"
               *ngIf="displayAddressesPermission$ | async"
               [heading]="'core-portal.master-data.subtitles.address-list' | translate"
               [lazy]="true"
               nexnoxWebTabTemplate
               tabId="addresses">
    <nexnox-web-master-data-companies-company-address-list *ngIf="passedIds"
                                                           [canCreate]="true"
                                                           [isEmbedded]="true"
                                                           [parentIds]="passedIds">
    </nexnox-web-master-data-companies-company-address-list>
  </ng-template>
</nexnox-web-entity-detail>
