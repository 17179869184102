import {ModuleWithProviders, NgModule} from '@angular/core';
import {DevicesDeclarationsModule} from './devices-declarations.module';
import {DevicesRoutingModule} from './devices-routing.module';
import {DevicesStoreModule} from './devices-store.module';

@NgModule({
  imports: [
    DevicesDeclarationsModule,
    DevicesRoutingModule,
    DevicesStoreModule
  ]
})
export class OrgaPortalFeatureDevicesModule {
  public static forFeature(): ModuleWithProviders<DevicesDeclarationsModule> {
    return { ngModule: DevicesDeclarationsModule };
  }
}
