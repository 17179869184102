import {Injectable, Injector} from '@angular/core';
import {CoreSharedApiBaseService, Product} from '@nexnox-web/core-shared';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable()
export class TechPortalFeatureSolutionItemService extends CoreSharedApiBaseService {
  constructor(
    protected injector: Injector
  ) {
    super(injector, Product.TECH, 'item', ['solution']);
  }

  public markItemAsSolution(id: number | string, isSolution: boolean,
                            parentIds: Array<number | string>): Observable<any> {
    return this.httpRequestService.post(`${ this.buildPathForOne(id, parentIds) }/markAsSolution`, {
      itemId: id,
      isSolution
    }).pipe(
      map(response => response.body)
    );
  }
}
