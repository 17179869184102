import {InjectionToken, NgModule} from '@angular/core';
import {ActionReducerMap, StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {stereotypesEffects, stereotypesReducers} from './store';

export const REDUCER_TOKEN = new InjectionToken<ActionReducerMap<any>>(
  '@nexnox-web/core-portal/settings/stereotypes: Store reducers'
);

@NgModule({
  imports: [
    StoreModule.forFeature('core-portal-feature-settings-stereotypes', REDUCER_TOKEN),
    EffectsModule.forFeature(stereotypesEffects)
  ],
  providers: [
    {
      provide: REDUCER_TOKEN,
      useValue: stereotypesReducers
    }
  ]
})
export class StereotypesStoreModule {
}
