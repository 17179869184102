import {ChangeDetectionStrategy, Component, Injector, Input, TemplateRef, ViewChild} from '@angular/core';
import {
  CorePortalEntityEditBaseComponent,
  CorePortalFormlyNgSelectTyping,
  CorePortalFormlyReadonlyTypes,
  CorePortalFormlyReadonlyTyping,
  CorePortalFormlyTranslatedTyping
} from '@nexnox-web/core-portal';
import {
  ChildTaskSimpleDto,
  CollectionTaskDto,
  CombineOperator,
  ConcreteTaskTypes,
  CrossCreationTypes,
  DateTimePatternV1Dto,
  DocumentTaskDto,
  DocumentTemplateContextType,
  ExecuteableTaskDto,
  ExecutionPlanDto,
  ExecutionTypes,
  FilterOperators,
  FilterTypes,
  FormDto,
  ImpactDto,
  PlannedExecutionDto,
  TaskDto,
  TaskJobStateReasonSetDto,
  TaskTypes
} from '@nexnox-web/core-shared';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {executionPlanEnumOptions, taskTypeEnumOptions} from '../../models';
import {
  CorePortalFeatureMasterDataLocationService,
  noClosedLocationsFilter$
} from '@nexnox-web/core-portal/features/master-data/features/locations';
import {CorePortalFeatureMasterDataLocationGroupService} from '@nexnox-web/core-portal/features/master-data/features/location-groups';
import {OrgaPortalFeatureCategoryService} from '@nexnox-web/orga-portal/features/categories';
import {OrgaPortalFeatureCancellationReasonsService} from '@nexnox-web/orga-portal/features/cancellation-reasons';
import {distinctUntilChanged, filter, map, skip, skipUntil, startWith} from 'rxjs/operators';
import {OrgaPortalFeatureFormService} from '@nexnox-web/orga-portal/features/forms';
import {TaskExecutionsComponent} from '../task-executions/task-executions.component';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {TaskImpactsComponent} from '../task-impacts/task-impacts.component';
import {TaskSubtasksComponent} from '../task-subtasks/task-subtasks.component';
import {timespanTo} from '@nexnox-web/lodash';
import {TechPortalFeatureDocumentTemplateService} from "@nexnox-web/tech-portal/features/templates";

@Component({
  selector: 'nexnox-web-tasks-task-edit',
  templateUrl: './task-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrgaPortalFeatureTaskEditComponent extends CorePortalEntityEditBaseComponent<TaskDto> {
  @Input() public isChild: boolean;

  @ViewChild('locationSelectLabelTitleTemplate', { static: true }) public locationSelectLabelTitleTemplate: TemplateRef<any>;
  @ViewChild('locationSelectOptionTitleTemplate', { static: true }) public locationSelectOptionTitleTemplate: TemplateRef<any>;

  @ViewChild('categorySelectLabelTitleTemplate', { static: true }) public categorySelectLabelTitleTemplate: TemplateRef<any>;
  @ViewChild('categorySelectOptionTitleTemplate', { static: true }) public categorySelectOptionTitleTemplate: TemplateRef<any>;

  @ViewChild('subtasksComponent') public subtasksComponent: TaskSubtasksComponent;
  @ViewChild('executionsComponent') public executionsComponent: TaskExecutionsComponent;
  @ViewChild('impactsComponents') public impactsComponent: TaskImpactsComponent;

  public model$: Observable<TaskDto>;
  public strictOrder$: Observable<boolean>;

  public subtasksSubject: BehaviorSubject<ChildTaskSimpleDto[]> = new BehaviorSubject<ChildTaskSimpleDto[]>([]);
  public executionPatternsSubject: BehaviorSubject<DateTimePatternV1Dto[]> = new BehaviorSubject<DateTimePatternV1Dto[]>([]);
  public impactsSubject: BehaviorSubject<ImpactDto[]> = new BehaviorSubject<ImpactDto[]>([]);

  public concreteTaskTypes = ConcreteTaskTypes;
  public executionTypes = ExecutionTypes;

  constructor(
    protected injector: Injector,
    private locationService: CorePortalFeatureMasterDataLocationService,
    private locationGroupService: CorePortalFeatureMasterDataLocationGroupService,
    private categoryService: OrgaPortalFeatureCategoryService,
    private cancellationReasonService: OrgaPortalFeatureCancellationReasonsService,
    private formService: OrgaPortalFeatureFormService,
    private documentTemplateService: TechPortalFeatureDocumentTemplateService
  ) {
    super(injector, 'OrgaPortalFeatureTaskEditComponent');

    this.model$ = this.modelSubject.asObservable();

    this.strictOrder$ = this.model$.pipe(
      map(model => (model as CollectionTaskDto)?.hasStrictOrder)
    );
  }

  public onStrictOrderChange(hasStrictOrder: boolean): void {
    this.setModel({ ...this.model, hasStrictOrder } as TaskDto & CollectionTaskDto);
    setTimeout(() => this.onModelChange(this.model));
  }

  public onSubtasksChange(subtasks: ChildTaskSimpleDto[]): void {
    this.setModel({
      ...this.model,
      children: subtasks
    } as TaskDto & CollectionTaskDto);
    setTimeout(() => this.onModelChange(this.model));
  }

  public onExecutionsChange(executionPatterns: DateTimePatternV1Dto[]): void {
    this.setModel({
      ...this.model,
      executionPlan: {
        ...((this.model as ExecuteableTaskDto).executionPlan ?? {}),
        patterns: executionPatterns
      } as ExecutionPlanDto & PlannedExecutionDto
    } as ExecuteableTaskDto);
    setTimeout(() => this.onModelChange(this.model));
  }

  public onImpactsChange(impacts: ImpactDto[]): void {
    this.setModel({ ...this.model, impacts });
    setTimeout(() => this.onModelChange(this.model));
  }

  public onModelChange(model: TaskDto): void {
    this.modelValidSubject.next({
      ...this.modelValidSubject.getValue(),
      subtasks: this.subtasksComponent ? this.subtasksComponent.isModelValid() : true,
      executions: this.executionsComponent ? this.executionsComponent.isModelValid() : true,
      impacts: this.impactsComponent ? this.impactsComponent.isModelValid() : true
    });

    super.onModelChange(model);
  }

  /* istanbul ignore next */
  protected createForm(): FormlyFieldConfig[] {
    return [
      {
        key: 'title',
        type: 'input',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.title',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.BASIC
          } as CorePortalFormlyReadonlyTyping,
          type: 'text'
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        },
        hideExpression: () => !this.creating
      },
      {
        key: 'type',
        type: 'core-portal-ng-select',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        defaultValue: TaskTypes.Confirmation,
        templateOptions: {
          corePortalTranslated: {
            label: 'orga-portal.tasks.fields.task-type',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          } as CorePortalFormlyTranslatedTyping,
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.ENUM,
            enumOptions: taskTypeEnumOptions,
            translate: true
          } as CorePortalFormlyReadonlyTyping,
          corePortalNgSelect: {
            items: taskTypeEnumOptions,
            translate: true
          } as CorePortalFormlyNgSelectTyping
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.disabled': () => !this.creating || this.readonly,
          'templateOptions.readonly': () => this.readonly
        },
        hooks: {
          onInit: field => {
            if (!this.creating) return;

            this.subscribe(field.formControl.valueChanges.pipe(
              startWith(field.formControl.value),
              distinctUntilChanged(),
              skip(1),
              skipUntil(this.presetAppliedSubject.asObservable().pipe(
                filter(presetApplied => presetApplied)
              ))
            ), () => {
              this.onModelChange({
                ...this.model,
                executionPlan: {
                  type: (this.model as ExecuteableTaskDto).executionPlan?.type ?? ExecutionTypes.Planned
                },
                form: null,
                impacts: null,
                children: null,
                isActive: true,
                generatePdf: false,
                generateCsv: false,
                requiresAuthentication: false
              } as TaskDto & DocumentTaskDto & CollectionTaskDto);
            });
          }
        },
        hideExpression: () => this.isChild
      },
      {
        key: 'executionPlan.type',
        type: 'core-portal-ng-select',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        defaultValue: ExecutionTypes.Planned,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.execution-plan',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.ENUM,
            enumOptions: executionPlanEnumOptions,
            translate: true
          } as CorePortalFormlyReadonlyTyping,
          corePortalNgSelect: {
            items: executionPlanEnumOptions,
            translate: true
          } as CorePortalFormlyNgSelectTyping
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        },
        hooks: {
          onInit: field => this.subscribe(field.formControl.valueChanges.pipe(
            startWith(field.formControl.value),
            distinctUntilChanged(),
            skip(this.creating ? 1 : 2),
            skipUntil(this.presetAppliedSubject.asObservable().pipe(
              filter(presetApplied => presetApplied)
            ))
          ), () => {
            this.onModelChange({
              ...this.model,
              executionPlan: {
                ...((this.model as ExecuteableTaskDto).executionPlan ?? {}),
                patterns: undefined,
                startMinuteOffset: undefined
              } as ExecutionPlanDto & PlannedExecutionDto
            } as TaskDto & CollectionTaskDto);
          })
        },
        hideExpression: () => this.isChild
      },
      {
        key: 'externalId',
        type: 'core-portal-external-id',
        wrappers: ['core-portal-translated'],
        className: 'col-md-6',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.external-id',
          },
          titleKey: 'title',
          modelSubject: this.modelSubject
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly
        }
      },
      {
        key: 'description',
        type: 'input',
        wrappers: ['core-portal-translated'],
        className: 'col-md-6',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.description'
          },
          type: 'text'
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly
        }
      },
      {
        key: 'categories',
        type: 'core-portal-entity-select',
        wrappers: ['core-portal-translated'],
        className: 'col-md-6',
        defaultValue: null,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.categories'
          },
          entityService: this.categoryService,
          idKey: 'categoryId',
          displayKey: 'name',
          wholeObject: true,
          multiple: true,
          enableCrossCreation: CrossCreationTypes.CATEGORY,
          selectLabelTitleTemplate: this.categorySelectLabelTitleTemplate,
          selectOptionTitleTemplate: this.categorySelectOptionTitleTemplate,
          showAll: true
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly
        },
        hideExpression: () => this.isChild
      },

      // Form
      {
        key: 'form',
        type: 'core-portal-entity-select',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        defaultValue: null,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.form',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          } as CorePortalFormlyTranslatedTyping,
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.ENTITY,
            displayKey: 'title',
            link: (form: FormDto) => form?.formId ? ['forms', form.formId] : null,
            module: 'settings'
          } as CorePortalFormlyReadonlyTyping,
          entityService: this.formService,
          idKey: 'formId',
          displayKey: 'title',
          wholeObject: true,
          link: (form: FormDto) => form?.formId ? ['forms', form.formId] : null,
          module: 'settings'
        },
        expressionProperties: {
          'templateOptions.required': (model: TaskDto) => model?.type === ConcreteTaskTypes.Document && !this.readonly,
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        },
        hideExpression: (model: TaskDto) => model?.type !== ConcreteTaskTypes.Document && model?.type !== ConcreteTaskTypes.ChildDocument
      },

      {
        key: 'documentTemplate',
        type: 'core-portal-entity-select',
        wrappers: ['core-portal-translated'],
        className: 'col-md-6',
        defaultValue: null,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.template'
          },
          entityService: this.documentTemplateService,
          idKey: 'documentTemplateId',
          displayKey: 'title',
          wholeObject: true,
          firstToDefault: true,
          clearable: false,
          required: true,
          multiple: false,
          defaultFilters$: of([{
            type: FilterTypes.Grouped,
            combinedAs: CombineOperator.Or,
            children: [
              {
                type: FilterTypes.DataTransferObject,
                operator: FilterOperators.Equal,
                property: 'contextType',
                value: DocumentTemplateContextType.FilledFormByTask
              }
            ]
          }]),
          showAll: true
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly
        },
        hideExpression: (model: TaskDto) =>
          model?.type !== ConcreteTaskTypes.Document &&
          model?.type !== ConcreteTaskTypes.ChildDocument &&
          model?.type !== ConcreteTaskTypes.Collection
      },

      {
        key: 'reasonSet',
        type: 'core-portal-entity-select',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        defaultValue: null,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.cancellation-reasons'
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.ENTITY,
            displayKey: 'title',
            link: (reasonSet: TaskJobStateReasonSetDto) => reasonSet?.taskJobStateReasonSetId ?
              ['/cancellation-reasons', reasonSet.taskJobStateReasonSetId] : null,
            module: 'settings'
          } as CorePortalFormlyReadonlyTyping,
          entityService: this.cancellationReasonService,
          idKey: 'taskJobStateReasonSetId',
          displayKey: 'title',
          wholeObject: true,
          enableCrossCreation: CrossCreationTypes.CANCELATION_REASON,
          link: (reasonSet: TaskJobStateReasonSetDto) => reasonSet?.taskJobStateReasonSetId ?
            ['/cancellation-reasons', reasonSet.taskJobStateReasonSetId] : null,
          module: 'settings'
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        },
        hideExpression: () => this.isChild
      },

      // Planned
      {
        key: 'executionPlan.startMinuteOffset',
        type: 'core-portal-timepicker',
        wrappers: ['core-portal-translated'],
        className: 'col-md-12',
        defaultValue: '0.00:00:00',
        templateOptions: {
          corePortalTranslated: {
            label: 'orga-portal.tasks.fields.start-minute-offset',
            description: 'orga-portal.tasks.field-descriptions.start-minute-offset',
            validationMessages: {
              required: 'core-portal.core.validation.required',
              valid: 'core-portal.core.validation.valid-time'
            }
          } as CorePortalFormlyTranslatedTyping,
          corePortalTimepicker: {
            mode: 'timespan',
            showYears: false,
            showWeeks: false,
            showDays: true,
            showHours: true,
            showMinutes: true,
            clearable: false
          }
        },
        expressionProperties: {
          'templateOptions.required': (model: TaskDto) =>
            (model as ExecuteableTaskDto)?.executionPlan?.type === ExecutionTypes.Planned && !this.readonly,
          'templateOptions.disabled': () => this.readonly
        },
        validators: {
          valid: ctrl => {
            if (!ctrl.value) return true;

            const { minutes } = timespanTo(ctrl.value, ['years', 'weeks', 'days', 'hours']);
            const { minutes: maxMinutes } = timespanTo('7.00:00:00', ['years', 'weeks', 'days', 'hours']);

            return minutes >= 0 && minutes <= maxMinutes;
          }
        },
        hideExpression: (model: TaskDto) => this.isChild || (model as ExecuteableTaskDto)?.executionPlan?.type !== ExecutionTypes.Planned
      },

      {
        key: 'isActive',
        type: 'core-portal-switch',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        defaultValue: true,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.is-active'
          } as CorePortalFormlyTranslatedTyping,
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.BOOLEAN
          } as CorePortalFormlyReadonlyTyping
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        },
        hideExpression: () => this.isChild
      },
      {
        key: 'generatePdf',
        type: 'core-portal-switch',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.generate-pdf'
          } as CorePortalFormlyTranslatedTyping,
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.BOOLEAN
          } as CorePortalFormlyReadonlyTyping
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        },
        hideExpression: (model: TaskDto) =>
          this.isChild || model?.type !== ConcreteTaskTypes.Document && model?.type !== ConcreteTaskTypes.Collection
      },
      {
        key: 'generateCsv',
        type: 'core-portal-switch',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.generate-csv'
          } as CorePortalFormlyTranslatedTyping,
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.BOOLEAN
          } as CorePortalFormlyReadonlyTyping
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        },
        hideExpression: (model: TaskDto) =>
          this.isChild || model?.type !== ConcreteTaskTypes.Document && model?.type !== ConcreteTaskTypes.Collection
      },
      {
        key: 'requiresAuthentication',
        type: 'core-portal-switch',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.requires-authentication'
          } as CorePortalFormlyTranslatedTyping,
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.BOOLEAN
          } as CorePortalFormlyReadonlyTyping
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        },
        hideExpression: (model: TaskDto) => model?.type === ConcreteTaskTypes.Collection
      },

      {
        wrappers: ['core-portal-translated'],
        className: 'col-md-12 field-group-mb-0 mt-3 px-0',
        fieldGroupClassName: 'row',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.available-for',
            title: true,
            labelClass: 'pl-2'
          } as CorePortalFormlyTranslatedTyping
        },
        fieldGroup: [
          {
            key: 'locations',
            type: 'core-portal-entity-select',
            wrappers: ['core-portal-translated'],
            className: 'col-md-6',
            defaultValue: null,
            templateOptions: {
              corePortalTranslated: {
                label: 'core-shared.shared.fields.locations'
              },
              entityService: this.locationService,
              idKey: 'locationId',
              displayKey: 'name',
              wholeObject: true,
              multiple: true,
              mapSearchFilter: CorePortalFeatureMasterDataLocationService.mapSearchFilter,
              search: CorePortalFeatureMasterDataLocationService.searchCompare,
              selectLabelTitleTemplate: this.locationSelectLabelTitleTemplate,
              selectOptionTitleTemplate: this.locationSelectOptionTitleTemplate,
              showAll: true,
              defaultFilters$: noClosedLocationsFilter$
            },
            expressionProperties: {
              'templateOptions.disabled': () => this.readonly
            }
          },
          {
            key: 'locationGroups',
            type: 'core-portal-entity-select',
            wrappers: ['core-portal-translated'],
            className: 'col-md-6',
            defaultValue: null,
            templateOptions: {
              corePortalTranslated: {
                label: 'core-shared.shared.fields.location-groups'
              },
              entityService: this.locationGroupService,
              idKey: 'locationGroupId',
              displayKey: 'name',
              wholeObject: true,
              multiple: true,
              showAll: true
            },
            expressionProperties: {
              'templateOptions.disabled': () => this.readonly
            }
          }
        ],
        hideExpression: () => this.isChild
      }
    ];
  }

  protected setModel(model: TaskDto): void {
    super.setModel(model);

    this.subtasksSubject.next((model as CollectionTaskDto)?.children ?? []);
    this.executionPatternsSubject
      .next(((model as ExecuteableTaskDto).executionPlan as ExecutionPlanDto & PlannedExecutionDto)?.patterns ?? []);
    this.impactsSubject.next(model.impacts ?? []);
  }

  protected setReadonly(readonly: boolean): void {
    super.setReadonly(readonly);

    this.subtasksComponent?.onReset();
    this.executionsComponent?.onReset();
    this.impactsComponent?.onReset();
  }
}
