<ng-template #entityEditTemplate let-id="id" let-title="title">
  <nexnox-web-contracts-contract-edit #modelComponent
                                      (modelChange)="onModelChange($event)"
                                      [creating]="false"
                                      [id]="id"
                                      [loading]="loading$ | async"
                                      [model]="model$ | async"
                                      [readonly]="readonly$ | async"
                                      [stereotypes$]="stereotypes$"
                                      [title]="title">
  </nexnox-web-contracts-contract-edit>
</ng-template>

<nexnox-web-entity-detail [entityDetailBaseComponent]="this"
                          [entityEditTemplate]="entityEditTemplate"
                          [tabCommands]="['.']"
                          [tabHeading]="title | translate"
                          tabId="contract">
  <!-- Links -->
  <ng-template (selectTab)="onNavigateToTab(['links'])"
               [hasError]="linksComponent ? !linksComponent.isModelValid() : false"
               [heading]="('tech-portal.subtitles.links' | translate) + (linksComponent ? (linksComponent.linksString$ | async) : '')"
               nexnoxWebTabTemplate
               tabId="links">
    <nexnox-web-tech-links #linksComponent
                           (linksChange)="onLinksChange($event, model)"
                           *ngIf="model$ | async as model"
                           [entityId]="model.contractId"
                           [links$]="links$"
                           [loading]="loading$ | async"
                           [readonly]="readonly$ | async"
                           [type]="linkedElementTypes.Contract">
    </nexnox-web-tech-links>
  </ng-template>

  <!-- Attachments -->
  <ng-template (selectTab)="onNavigateToTab(['attachments'])"
               [hasError]="attachmentsComponent ? !attachmentsComponent.isModelValid() : false"
               [heading]="('tech-portal.subtitles.attachments' | translate) + (attachmentsComponent ? (attachmentsComponent.attachmentsString$ | async) : '')"
               nexnoxWebTabTemplate
               tabId="attachments">
    <nexnox-web-tech-attachments #attachmentsComponent
                                 (attachmentsChange)="onAttachmentsChange($event, model)"
                                 *ngIf="model$ | async as model"
                                 [attachments$]="attachments$"
                                 [loading]="loading$ | async"
                                 [readonly]="readonly$ | async">
    </nexnox-web-tech-attachments>
  </ng-template>

  <!-- Ticket List -->
  <ng-template (selectTab)="onNavigateToTab(['tickets'])"
               *ngIf="true"
               [heading]="'tickets.title' | translate"
               [lazy]="true"
               nexnoxWebTabTemplate
               tabId="tickets">
    <nexnox-web-contracts-contract-tickets *ngIf="passedIds"
                                           [isEmbedded]="true"
                                           [parentIds]="[passedIds[0]]">
    </nexnox-web-contracts-contract-tickets>
  </ng-template>

  <!-- Audit -->
  <ng-template (selectTab)="onNavigateToTab(['audit'])"
               *ngIf="readAuditPermission$ | async"
               [heading]="'core-portal.audit.title' | translate"
               [lazy]="true"
               nexnoxWebTabTemplate
               tabId="audit">
    <nexnox-web-contracts-contract-audit *ngIf="passedIds"
                                         [isEmbedded]="true"
                                         [parentIds]="[entityTypes.Contract, passedIds[0]]">
    </nexnox-web-contracts-contract-audit>
  </ng-template>
</nexnox-web-entity-detail>

