import {
  companyAddressDetailStore,
  CompanyAddressDetailStoreState,
  companyAddressListStore,
  CompanyAddressListStoreState,
  companyContactListStore,
  CompanyContactListStoreState,
  companyDetailStore,
  CompanyDetailStoreState,
  companyListStore,
  CompanyListStoreState
} from './stores';

export interface CompaniesState {
  companyList: CompanyListStoreState;
  companyDetail: CompanyDetailStoreState;

  companyContactList: CompanyContactListStoreState;
  companyAddressList: CompanyAddressListStoreState;
  companyAddressDetail: CompanyAddressDetailStoreState
}

export const getInitialCompaniesState = (): CompaniesState => ({
  companyList: companyListStore.getInitialState(),
  companyDetail: companyDetailStore.getInitialState(),

  companyContactList: companyContactListStore.getInitialState(),
  companyAddressList: companyAddressListStore.getInitialState(),
  companyAddressDetail: companyAddressDetailStore.getInitialState()
});
