import {ChangeDetectionStrategy, Component, Injector} from '@angular/core';
import {faExclamationTriangle} from '@fortawesome/free-solid-svg-icons/faExclamationTriangle';
import {faSync} from '@fortawesome/free-solid-svg-icons/faSync';
import {ActionButton, CorePortalEntityDetailBaseComponent, CorePortalPermissionService} from '@nexnox-web/core-portal';
import {AppPermissions, ControlDto, ControlNotificationDto, DataTableDto} from '@nexnox-web/core-shared';
import {controlDetailStore} from '../../store/stores';
import {faSpinner} from "@fortawesome/free-solid-svg-icons/faSpinner";
import {Actions} from "@ngrx/effects";
import {ControlDetailXsStore} from "../../store";
import {map, mergeMap} from "rxjs/operators";
import {Observable} from "rxjs";
import {UrlTree} from "@angular/router";

@Component({
  selector: 'nexnox-web-controls-control-detail',
  templateUrl: './control-detail.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ControlDetailComponent extends CorePortalEntityDetailBaseComponent<ControlDto> {
  public title = 'controls.subtitles.control';

  public isNoResourceFilter$: Observable<boolean>;
  public showIssues$: Observable<boolean>;

  public faExclamationTriangle = faExclamationTriangle;
  public faSync = faSync;
  public faSpinner = faSpinner;

  protected idParam = 'controlId';
  protected displayKey = 'name';

  protected declare entityStore: ControlDetailXsStore;
  protected declare actions$: Actions;

  constructor(
    protected injector: Injector,
    private permissionService: CorePortalPermissionService
  ) {
    super(injector, controlDetailStore);

    this.isNoResourceFilter$ = this.model$.pipe(map(model => !model?.dataTable || ((model?.dataTable?.filters ?? []).length <= 0)));
    this.showIssues$ = this.permissionService.hasPermission$(AppPermissions.ReadControlIssue);
  }

  public onNotificationsChanged(notifications: ControlNotificationDto[], model: ControlDto): void {
    this.onModelChange({ ...model, notifications: notifications ?? [] });
  }

  public onDatatableChanged(dataTable: DataTableDto, model: ControlDto): void {
    this.onModelChange({ ...model, dataTable });
  }

  public override shouldDisablePrimaryAction(): Observable<boolean> {
    return this.model$.pipe(
      mergeMap((model) => super.shouldDisablePrimaryAction().pipe(
        mergeMap((shouldDisable) => this.isNoResourceFilter$.pipe(
          map(noFilter => shouldDisable || noFilter))
        ))
      )
    );
  }

  protected override onSaveAction(returnPath?: any[] | UrlTree): void {
    this.isDeactivateUnsavedChangesModal = true;
    this.onSave(returnPath);
  }

  /* istanbul ignore next */
  protected async getActionButtons(): Promise<ActionButton[]> {
    return this.getDefaultActionButtons(
      'controls.actions.edit-control',
      'controls.actions.save-control',
      'controls.actions.delete-control',
      'controls.descriptions.delete-control',
      AppPermissions.UpdateControl,
      AppPermissions.DeleteControl,
      ['/controls']
    );
  }
}
