<ng-template #entityEditTemplate let-id="id" let-title="title">
  <nexnox-web-settings-inventory-number-patterns-inventory-number-pattern-edit #modelComponent
                                                                               (modelChange)="onModelChange($event)"
                                                                               [creating]="false"
                                                                               [id]="id"
                                                                               [loading]="loading$ | async"
                                                                               [model]="model$ | async"
                                                                               [readonly]="readonly$ | async"
                                                                               [stereotypes$]="stereotypes$"
                                                                               [title]="title">
  </nexnox-web-settings-inventory-number-patterns-inventory-number-pattern-edit>
</ng-template>

<nexnox-web-entity-detail [entityDetailBaseComponent]="this"
                          [entityEditTemplate]="entityEditTemplate"
                          [tabCommands]="['.']"
                          [tabHeading]="title | translate"
                          tabId="inventoryNumberPattern">
  <!-- Reservations Tab -->
  <ng-template (selectTab)="onNavigateToTab(['reservations'])"
               [heading]="'core-portal.settings.subtitles.inventory-number-reservation-list' | translate"
               [lazy]="true"
               nexnoxWebTabTemplate
               tabId="reservations">
    <nexnox-web-settings-inventory-number-patterns-reservation-list *ngIf="passedIds"
                                                                    [canCreate]="false"
                                                                    [isEmbedded]="true"
                                                                    [parentIds]="passedIds">
    </nexnox-web-settings-inventory-number-patterns-reservation-list>
  </ng-template>

</nexnox-web-entity-detail>
