import {
  ChangeDetectionStrategy,
  Component,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {
  authStore,
  CorePortalCardboxAction,
  CorePortalEntityOverviewBaseComponent,
  CorePortalTenantRouter,
  DatatableTableColumn,
  DeleteEntityModel
} from '@nexnox-web/core-portal';
import {
  AppEntityType,
  AppPermissions,
  ControllerOperationId,
  DataTableViewType,
  Mappers,
  MissionDto,
  MissionType,
  Orders,
  TenantInfoDto
} from '@nexnox-web/core-shared';
import {PagedEntitiesXsStoreEntity} from '@nexnox-web/core-store';
import {select} from '@ngrx/store';
import {cloneDeep, isEqual} from 'lodash';
import {BehaviorSubject} from 'rxjs';
import {distinctUntilChanged, filter, tap} from 'rxjs/operators';
import {TechPortalFeatureMissionActionsFacade, XsStoreMissionActionsFacade} from '../../facades';
import {missionGanttListStore} from '../../store';
import {getCardBoxHeaderActions} from '../../components';
import {TranslateService} from "@ngx-translate/core";
import dayjs from 'dayjs';

export interface IGanttViewRow {
  id: number;
  days: IGanttViewDay[];
}

export interface IGanttViewDay {
  date: string;
  fillStart: number;
  fillEnd: number;
  contactColor: string;
  isEmpty: boolean;
  isOddWeek: boolean;
  isExpanded: boolean;
}

export interface IGanttViewHeaderDay {
  date: string;
  day: string;
  weekNumber: number;
  monthTitle: string;
  isWeekend: boolean;
  isMonday: boolean;
  isFirstOfMonth: boolean;
  isExpanded: boolean;
}

@Component({
  selector: 'nexnox-web-missions-mission-gantt-view',
  templateUrl: './mission-gantt-view.component.html',
  styleUrls: ['./mission-gantt-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TechPortalFeatureMissionGanttViewComponent extends CorePortalEntityOverviewBaseComponent<MissionDto> implements OnInit, OnDestroy {
  @ViewChild('ganttRowTemplate', { static: true }) public ganttRowTemplate: TemplateRef<any>;

  @Input() public mode: BehaviorSubject<DataTableViewType>;
  @Input() public parentBaseComponent: CorePortalEntityOverviewBaseComponent<MissionDto>;

  public title = 'missions.actions.switch-plan-view';
  public createTitle = 'missions.actions.create-mission';
  public idProperty = 'missionId';
  public displayProperty = 'title';
  public pageOperation = ControllerOperationId.MissionControllerList;
  public componentId = 'TechPortalFeatureMissionGanttViewComponent';
  public enableViews = true;

  public createMissionTypeSubject: BehaviorSubject<MissionType> = new BehaviorSubject<MissionType>(MissionType.Manual);

  // Gantt properties
  public ganttStartDate: string;
  public ganttEndDate: string;
  public ganttDuration: number;
  public ganttRows: IGanttViewRow[] = [];
  public ganttHeader: IGanttViewHeaderDay[] = [];
  public isHideWeekNumbers: boolean;
  public readonly contactIdColors = ["fac89e", "e3e891", "c2fc99", "a3fcb3", "92e8d5", "96c8f2", "ada8ff", "ce94f7", "ed94dd", "fea8bb"];
  public availableColors: string[] = [];
  public contactIdColorDictionary: { [contactId: number]: string; } = {};
  public hourScaleArray = [0, 0, 3, 0, 0, 6, 0, 0, 9, 0, 0, 12, 0, 0, 15, 0, 0, 18, 0, 0, 21, 0, 0];

  protected declare entityActionsFacade: TechPortalFeatureMissionActionsFacade;

  // Gantt settings
  private ganttMinDays = 14;
  private ganttMaxDays = 70;
  private ganttMinWidth = 700;

  private activeTenantSubject = new BehaviorSubject<TenantInfoDto>(null);
  private canUpdateClosedMissionSubject = new BehaviorSubject<boolean>(false);

  constructor(
    protected injector: Injector,
    public tenantRouter: CorePortalTenantRouter,
    public translate: TranslateService
  ) {
    super(injector, missionGanttListStore, Mappers.MissionCompleteDto.serializedName, AppEntityType.Mission);

    this.defaultColumns = [
      'title',
      'sourceResource',
      'location',
      'state',
      'plannedStart',
      'plannedEnd',
      'solutionContact'
    ];

    this.excludedColumns = [
      'missionId',
      'actualStart',
      'actualEnd',
      'labelRelations'
    ];

    this.defaultSortOptions = {
      sortField: 'plannedStart',
      sort: Orders.Ascending
    };
  }

  public ngOnInit(): void {
    this.extractParentBaseComponentData();

    this.subscribe(this.mode.asObservable(), (mode) => {
      this.viewType = mode as any;
    });

    this.customActionsFacade = this.customActionsFacade ?? new XsStoreMissionActionsFacade(this.injector, missionGanttListStore);

    super.ngOnInit();

    this.subscribe(this.entities$.pipe(
      filter(entities => Boolean(entities.length)),
      tap(entities => this.initializeGanttView(entities))
    ));

    this.subscribe(this.store.pipe(
      select(authStore.selectors.selectActiveTenant),
      distinctUntilChanged((a, b) => isEqual(a, b))
    ), tenant => this.activeTenantSubject.next(tenant));

  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();

    this.createMissionTypeSubject.next(MissionType.Manual);
    this.activeTenantSubject.next(null);
    this.canUpdateClosedMissionSubject.next(false);
  }

  public appendColumns(): DatatableTableColumn[] {
    return [
      {
        minWidth: this.ganttMinWidth,
        sortable: false,
        cellTemplate: this.ganttRowTemplate,
        name: '',
        prop: '',
        noFilter: true,
        disableFilter: true,
        padding: 0,
      } as DatatableTableColumn
    ]
  }

  public getDeleteEntityModel(): DeleteEntityModel {
    return {
      titleKey: 'missions.actions.delete-mission',
      descriptionKey: 'missions.descriptions.delete-mission',
      confirmKey: 'missions.actions.delete-mission',
      deletePermission: AppPermissions.DeleteMission
    };
  }

  public getCardBoxHeaderActions(): CorePortalCardboxAction[] {
    return !this.disableSettingsViewSelect ? getCardBoxHeaderActions(this.mode, this.permissionService, this.translate, this.tenantRouter) : [];
  }

  public getGanttRow(ganttBlocks, row): IGanttViewDay[] {
    return ganttBlocks.filter(b => b.id === row.missionId)[0]?.days ?? [];
  }

  public isFirstRow(ganttBlocks, row): boolean {
    return row.missionId === (ganttBlocks[0]?.id ?? undefined);
  }

  public expandDay(block, rows: IGanttViewRow[]): void {
    const isExpanded = !block.isExpanded;
    for (const row of rows) {
      row.days.forEach(day => day.isExpanded = false);
      row.days.find(day => isEqual(day.date, block.date)).isExpanded = isExpanded;
    }
    this.ganttHeader.forEach(day => day.isExpanded = false);
    this.ganttHeader.find(day => isEqual(dayjs(day.date).startOf('day').toISOString(), dayjs(block.date).startOf('day').toISOString())).isExpanded = isExpanded;
  }

  private initializeGanttView(entities: PagedEntitiesXsStoreEntity<MissionDto, MissionDto>[]): void {
    this.ganttStartDate = entities.map(m => m.model.plannedStart).sort()[0];
    this.ganttEndDate = entities.map(m => m.model.plannedEnd).filter(plannedEnd => Boolean(plannedEnd)).sort().pop();
    this.ganttDuration = 1 + Math.max(this.ganttMinDays, Math.min(this.ganttMaxDays, Math.ceil(this.dayjsDE(this.ganttEndDate).diff(this.ganttStartDate, 'day', true))));
    this.ganttRows = this.generateGanttView(entities);
    this.ganttHeader = this.generateGanttHeader();
    this.isHideWeekNumbers = this.ganttDuration > 49;
  }

  private generateGanttHeader(): IGanttViewHeaderDay[] {
    const headerDays: IGanttViewHeaderDay[] = new Array(this.ganttDuration);

    for (let i = 0; i < headerDays.length; i++) {
      const day = this.dayjsDE(this.ganttStartDate).add(i, 'day');
      headerDays[i] = {
        date: day.toISOString(),
        day: day.format('DD'),
        weekNumber: day.week(),
        monthTitle: day.format('MMMM YYYY'),
        isWeekend: day.day() === 0 || day.day() === 6,
        isMonday: day.day() === 1,
        isFirstOfMonth: day.date() === 1,
        isExpanded: false
      };
    }
    return headerDays;
  }

  private generateGanttView(entities: PagedEntitiesXsStoreEntity<MissionDto, MissionDto>[]): IGanttViewRow[] {
    const ganttView: IGanttViewRow[] = [];
    for (let i = 0; i < entities.length; i++) {
      const entity: MissionDto = entities[i].entity;
      ganttView.push({ id: entity.missionId, days: this.generateGanttRow(entity) });
    }
    return ganttView;
  }

  private generateGanttRow(entity: MissionDto): IGanttViewDay[] {
    const ganttViewDays: IGanttViewDay[] = new Array(this.ganttDuration);

    for (let i = 0; i < ganttViewDays.length; i++) {
      const day = dayjs(this.ganttStartDate).startOf('date').utc(true).add(i, 'day').toISOString();

      ganttViewDays[i] = {
        date: day,
        fillStart: this.getFillStart(day, entity.plannedStart, entity.plannedEnd),
        fillEnd: this.getFillEnd(day, entity.plannedStart, entity.plannedEnd),
        isEmpty: this.isEmptyGanttDay(day, entity.plannedStart, entity.plannedEnd),
        contactColor: this.getContactIdColor(entity?.solutionContact?.contactId),
        isOddWeek: this.isOdd(this.dayjsDE(this.ganttStartDate).add(i, 'day').week()),
        isExpanded: false
      };
    }
    return ganttViewDays;
  }

  private isEmptyGanttDay(date: string, start: string, end: string): boolean {
    return !(this.dayjsDE(date).isAfter(start, 'date') && this.dayjsDE(date).isBefore(end, 'date') || this.dayjsDE(date).isSame(start, 'date') || this.dayjsDE(date).isSame(end, 'date'));
  }

  private isOdd(number: number): boolean {
    return number % 2 !== 0;
  }

  private getFillStart(date: string, start: string, end: string): number {
    // Calculation
    const differenceInHours = (+dayjs(start).utc(true).toDate() - +dayjs(date).toDate()) / 36e5;

    return Math.max((differenceInHours * 100) / 24, 0);
  }

  private getFillEnd(date: string, start: string, end: string): number {
    // Settings
    const equalDateDuration = 3 // hours;
    // Calculation
    const differenceInHours = (+dayjs(end).utc(true).toDate() - +dayjs(date).toDate()) / 36e5;
    const percent = Math.min(Math.abs(differenceInHours * 100 / 24), 100);

    return !isEqual(start, end) ? (100 - percent) : (100 - (percent + (equalDateDuration * 100 / 24)));
  }

  // workaround:
  // app i18n settings influence dayjs behavior
  // to ensure a consistent gantt result within different languages,
  // the chart is calculated with german settings only
  private dayjsDE(value: Date | dayjs.Dayjs | string | number): dayjs.Dayjs {
    return dayjs(value).locale('de');
  }

  private getContactIdColor(id: number): string {
    if (this.availableColors.length === 0) {
      this.availableColors = cloneDeep(this.contactIdColors);
    }
    if (!this.contactIdColorDictionary[id]) {
      this.contactIdColorDictionary[id] = id > 0 ? this.availableColors.pop() : 'dddddd';
    }
    return this.contactIdColorDictionary[id];
  }

  private extractParentBaseComponentData(): void {
    if (this.parentBaseComponent !== undefined) {
      this.enableViews = this.parentBaseComponent?.enableViews;
      this.disableSettingsViewSelect = this.parentBaseComponent?.disableSettingsViewSelect;
      this.datatableConfigName = this.parentBaseComponent?.datatableConfigName;
      this.customDatatableConfig = this.parentBaseComponent?.customDatatableConfig;
      this.title = this.disableSettingsViewSelect ? this.parentBaseComponent.title : this.title;
    }
  }

}
