import {Attribute, Directive, ElementRef, Injector, Input, OnDestroy, Renderer2} from '@angular/core';
import {ActivatedRoute, Router, RouterLink} from '@angular/router';
import {LocationStrategy} from '@angular/common';
import {Subscription} from 'rxjs';
import {CorePortalCurrentTenantService} from '../../services/current-tenant/current-tenant.service';
import {clone, flatten, isString} from 'lodash';
import {distinctUntilChanged, map, switchMap} from 'rxjs/operators';
import {CorePortalCurrentModuleService} from '../../services/current-module/current-module.service';

/* tslint:disable */

/* eslint-disable */

@Directive({
  selector: ':not(a):not(area)[nexnoxWebTenantRouterLink]'
})
export class CorePortalTenantRouterLinkDirective extends RouterLink implements OnDestroy {
  private currentTenantService: CorePortalCurrentTenantService;
  private currentModuleService: CorePortalCurrentModuleService;
  private currentTenantName: string;
  private currentModule: string;
  private rawCommands: any[] | string;
  private tenantDomainSubscription: Subscription;

  constructor(
    router: Router,
    route: ActivatedRoute,
    @Attribute('tabindex') tabIndex: string,
    renderer: Renderer2,
    el: ElementRef,
    injector: Injector
  ) {
    super(router, route, tabIndex, renderer, el);

    this.currentTenantService = injector.get(CorePortalCurrentTenantService);
    this.currentModuleService = injector.get(CorePortalCurrentModuleService);

    this.tenantDomainSubscription = this.currentTenantService.currentTenantDomainSubject.asObservable().pipe(
      distinctUntilChanged(),
      switchMap(tenantDomain => this.currentModuleService.currentModuleSubject.asObservable().pipe(
        map(currentModule => ([tenantDomain, currentModule]))
      ))
    ).subscribe(([tenantDomain, module]) => {
      this.currentTenantName = tenantDomain;
      this.currentModule = module;
      this.updateCommands();
    });
  }

  @Input()
  public set nexnoxWebTenantRouterLink(commands: any[] | string | null | undefined) {
    this.rawCommands = commands;
    this.updateCommands();
  }

  private _withoutModule: boolean;

  @Input()
  public set withoutModule(withoutModule: boolean) {
    this._withoutModule = withoutModule;
    this.updateCommands();
  }

  private _module: string;

  @Input()
  public set module(module: string) {
    this._module = module;
    this.updateCommands();
  }

  public ngOnDestroy(): void {
    if (this.tenantDomainSubscription && !this.tenantDomainSubscription.closed) {
      this.tenantDomainSubscription.unsubscribe();
    }
  }

  private updateCommands(): void {
    const commands = clone(this.rawCommands);

    if (Array.isArray(commands) && commands.length && (commands[0]?.toString() as string).startsWith('/')) {
      commands[0] = (commands[0]?.toString() as string).substring(1);
    }

    if (commands != null && this.currentTenantName) {
      let prefixCommands = ['/tenant', this.currentTenantName];

      if (!this._withoutModule) {
        prefixCommands = [...prefixCommands, this._module ?? this.currentModule];
      }

      (this as any).commands = Array.isArray(commands) ? [...prefixCommands, ...commands] : [`${ prefixCommands.join('/') }${ commands }`];
    } else {
      (this as any).commands = [];
    }
  }
}

@Directive({
  selector: 'a[nexnoxWebTenantRouterLink],area[nexnoxWebTenantRouterLink]'
})
export class CorePortalTenantRouterLinkWithHrefDirective extends RouterLink implements OnDestroy {
  private currentTenantService: CorePortalCurrentTenantService;
  private currentModuleService: CorePortalCurrentModuleService;
  private currentTenantDomain: string;
  private currentModule: string;
  private rawCommands: any[] | string;
  private tenantDomainSubscription: Subscription;

  constructor(
    router: Router,
    route: ActivatedRoute,
    locationStrategy: LocationStrategy,
    renderer: Renderer2,
    el: ElementRef,
    injector: Injector
  ) {
    super(router, route, '', renderer, el, locationStrategy);

    this.currentTenantService = injector.get(CorePortalCurrentTenantService);
    this.currentModuleService = injector.get(CorePortalCurrentModuleService);

    this.tenantDomainSubscription = this.currentTenantService.currentTenantDomainSubject.asObservable().pipe(
      distinctUntilChanged(),
      switchMap(tenantDomain => this.currentModuleService.currentModuleSubject.asObservable().pipe(
        map(currentModule => ([tenantDomain, currentModule]))
      ))
    ).subscribe(([tenantDomain, module]) => {
      this.currentTenantDomain = tenantDomain;
      this.currentModule = module;
      this.updateCommands();
    });
  }

  @Input()
  public set nexnoxWebTenantRouterLink(commands: any[] | string | null | undefined) {
    this.rawCommands = commands;
    this.updateCommands();
  }

  private _withoutModule: boolean;

  @Input()
  public set withoutModule(withoutModule: boolean) {
    this._withoutModule = withoutModule;
    this.updateCommands();
  }

  private _module: string;

  @Input()
  public set module(module: string) {
    this._module = module;
    this.updateCommands();
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();

    if (this.tenantDomainSubscription && !this.tenantDomainSubscription.closed) {
      this.tenantDomainSubscription.unsubscribe();
    }
  }

  private updateCommands(): void {
    let commands = clone(this.rawCommands);

    if (Array.isArray(commands) && commands.length) {
      if (isString(commands[0]?.toString()) && commands[0].toString().startsWith('/')) {
        commands[0] = commands[0].toString().substring(1);
      }

      for (let i = 0; i < commands.length; i++) {
        if (isString(commands[i]?.toString()) && commands[i].toString().includes('/')) {
          commands[i] = commands[i].toString().split('/');
        }
      }

      commands = flatten(commands);
    }

    if (commands != null && this.currentTenantDomain && this.currentModule) {
      let prefixCommands = ['/tenant', this.currentTenantDomain];

      if (!this._withoutModule) {
        prefixCommands = [...prefixCommands, this._module ?? this.currentModule];
      }

      (this as any).commands = Array.isArray(commands) ? [...prefixCommands, ...commands] : [`${ prefixCommands.join('/') }${ commands }`];
    } else {
      (this as any).commands = [];
    }
  }
}
