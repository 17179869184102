<div class="w-100 overflow-hidden">
  <div class="d-flex flex-row p-1">
    <div (click)="onAddSignature()"
         [class.disabled]="to.disabled"
         class="signature-pad-wrapper">
      <img *ngIf="(signatureUrl$ | async); else noSignatureTemplate"
           [src]="signatureUrl$ | async"
           class="img-fluid w-100">

      <ng-template #noSignatureTemplate>
        <a class="d-flex align-self-center">{{ 'core-shared.shared.custom-property-signature.add' | translate }}</a>
      </ng-template>
    </div>

    <div class="d-flex flex-column ml-2">
      <button (click)="onClear()"
              [disabled]="(signatureUrl$ | async) === null || to.disabled"
              [icon]="faTrashAlt | nexnoxWebFaIconStringPipe"
              [title]="'core-shared.shared.select.clear' | translate"
              class="p-button-secondary p-button-text p-button-icon-only"
              pButton
              type="button">
      </button>
    </div>
  </div>
</div>
