import {Filter, Paging, SortObject, StereotypeDto} from '@nexnox-web/core-shared';
import {PagedEntitiesXsStore, PagedEntitiesXsStoreEntity, PagedEntitiesXsStoreEntityData} from '@nexnox-web/core-store';
import {Observable} from 'rxjs';
import {CorePortalEntitySelectorsFacade} from '../entity-selectors/entity-selectors.facade';
import {select, Store} from '@ngrx/store';
import {Injector} from '@angular/core';
import {Actions, ofType} from '@ngrx/effects';

export class CorePortalXsStoreEntitySelectorsFacade<E, M = E> implements CorePortalEntitySelectorsFacade<E, M> {
  protected store: Store<any>;
  protected actions$: Actions;

  constructor(
    protected injector: Injector,
    protected entityStore: PagedEntitiesXsStore<E, M>
  ) {
    this.store = this.injector.get(Store);
    this.actions$ = this.injector.get(Actions);
  }

  public selectEntities(): Observable<PagedEntitiesXsStoreEntity<E, M>[]> {
    return this.store.pipe(select(this.entityStore.selectors.selectAll));
  }

  public selectPaging(): Observable<Paging> {
    return this.store.pipe(select(this.entityStore.selectors.selectPaging));
  }

  public selectSort(): Observable<SortObject> {
    return this.store.pipe(select(this.entityStore.selectors.selectSortObject));
  }

  public selectFilters(): Observable<Filter[]> {
    return this.store.pipe(select(this.entityStore.selectors.selectFilters));
  }

  public selectEntityData(): Observable<PagedEntitiesXsStoreEntityData> {
    return this.store.pipe(select(this.entityStore.selectors.selectEntityData));
  }

  public selectLoading(): Observable<boolean> {
    return this.store.pipe(select(this.entityStore.selectors.selectLoading));
  }

  public selectAppendLoading(): Observable<boolean> {
    return this.store.pipe(select(this.entityStore.selectors.selectAppendLoading));
  }

  public selectLoaded(): Observable<boolean> {
    return this.store.pipe(select(this.entityStore.selectors.selectLoaded));
  }

  public selectLoadedAt(): Observable<string> {
    return this.store.pipe(select(this.entityStore.selectors.selectLoadedAt));
  }

  public selectHasError(): Observable<boolean> {
    return this.store.pipe(select(this.entityStore.selectors.selectHasError));
  }

  public selectStereotypes(): Observable<StereotypeDto[]> {
    return this.store.pipe(select(this.entityStore.selectors.selectStereotypes));
  }

  public selectStereotypesLoaded(): Observable<boolean> {
    return this.store.pipe(select(this.entityStore.selectors.selectStereotypesLoaded));
  }

  /* Callbacks */

  public selectCreateOneSuccess(): Observable<{ entity: E; model: M }> {
    return this.actions$.pipe(ofType(this.entityStore.actions.createOneSuccess));
  }
}
