<ng-template #entityEditTemplate let-title="title">
  <nexnox-web-settings-dashboards-dashboard-edit #editComponent
                                                 (modelChange)="createModel$.next($event)"
                                                 [creating]="true"
                                                 [loading]="(loaded$ | async) === false"
                                                 [model]="createModel$ | async"
                                                 [readonly]="false"
                                                 [stereotypes$]="stereotypes$"
                                                 [title]="title">
  </nexnox-web-settings-dashboards-dashboard-edit>
</ng-template>

<nexnox-web-entity-overview #overviewComponent
                            [entityEditTemplate]="entityEditTemplate"
                            [entityOverviewBaseComponent]="this"
                            detailLink="dashboards"
                            module="settings">
</nexnox-web-entity-overview>
