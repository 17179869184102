import {
  BaseXsStore,
  BaseXsStoreReducerTypes,
  createPagedEntitiesXsStoreActions,
  PagedEntitiesXsStore,
  PagedEntitiesXsStoreEffects,
  pagedEntitiesXsStoreSetLoadingForId,
  PagedEntitiesXsStoreState
} from '@nexnox-web/core-store';
import {AppEntityType, CoreSharedApiBaseService, ResourceModelListDto} from '@nexnox-web/core-shared';
import {
  ModelListXsStoreActions,
  ModelListXsStoreMergePayload,
  ModelListXsStoreMovePayload
} from './model-list-xs-store.actions';
import {Action, createAction, on, props} from '@ngrx/store';
import {Injectable, Injector, Type} from '@angular/core';
import {createEffect, ofType} from '@ngrx/effects';
import {catchError, groupBy, map, mergeMap, switchMap, tap} from 'rxjs/operators';
import {of} from 'rxjs';
import {immerOn} from 'ngrx-immer/store';
import {CorePortalManufacturerModelService} from "../../services";

export interface ModelListXsStoreState extends PagedEntitiesXsStoreState<ResourceModelListDto> {
}

export class ModelListXsStore extends PagedEntitiesXsStore<ResourceModelListDto, ResourceModelListDto, ModelListXsStoreState> {
  public actions: ModelListXsStoreActions;

  protected createActions(label: string): ModelListXsStoreActions {
    return {
      ...createPagedEntitiesXsStoreActions(label),

      mergeOne: createAction(
        BaseXsStore.getType(label, 'Merge'),
        props<ModelListXsStoreMergePayload>()
      ),
      mergeOneSuccess: createAction(
        BaseXsStore.getType(label, 'Merge success'),
        props<ModelListXsStoreMergePayload>()
      ),

      moveOne: createAction(
        BaseXsStore.getType(label, 'Move'),
        props<ModelListXsStoreMovePayload>()
      ),
      moveOneSuccess: createAction(
        BaseXsStore.getType(label, 'Move success'),
        props<ModelListXsStoreMovePayload>()
      )
    };
  }

  protected createReducerArray(
    initialState: ModelListXsStoreState
  ): BaseXsStoreReducerTypes<ModelListXsStoreState, ModelListXsStoreActions>[] {
    return [
      ...super.createReducerArray(initialState),


      immerOn(this.actions.mergeOne, (draft, { model }) => {
        draft.entityData = pagedEntitiesXsStoreSetLoadingForId(draft.entityData, model.resourceModelId, {
          deleteOne: true
        });
      }),

      on(this.actions.mergeOneSuccess, (state, { model }) =>
        this.adapter.removeOne(model.resourceModelId?.toString(), {
          ...state,
          paging: {
            ...state.paging,
            totalItems: (state.paging?.totalItems ?? 1) - 1
          },
          entityData: pagedEntitiesXsStoreSetLoadingForId(state.entityData, model.resourceModelId, {
            deleteOne: false
          }),
          hasError: false
        })),


      immerOn(this.actions.moveOne, (draft, { model }) => {
        draft.entityData = pagedEntitiesXsStoreSetLoadingForId(draft.entityData, model.resourceModelId, {
          deleteOne: true
        });
      }),

      on(this.actions.moveOneSuccess, (state, { model }) =>
        this.adapter.removeOne(model.resourceModelId?.toString(), {
          ...state,
          paging: {
            ...state.paging,
            totalItems: (state.paging?.totalItems ?? 1) - 1
          },
          entityData: pagedEntitiesXsStoreSetLoadingForId(state.entityData, model.resourceModelId, {
            deleteOne: false
          }),
          hasError: false
        }))
    ];
  }

  protected createEffects(
    serviceType: Type<CoreSharedApiBaseService>,
    entityType: AppEntityType,
    prepareEntity: (entity: ResourceModelListDto) => ResourceModelListDto,
    prepareModel: (entity: ResourceModelListDto, model: ResourceModelListDto) => ResourceModelListDto,
    sanitizeModel: (model: ResourceModelListDto, entity: ResourceModelListDto) => ResourceModelListDto,
    ...args
  ): Type<PagedEntitiesXsStoreEffects<ResourceModelListDto, ResourceModelListDto, ModelListXsStoreState>> {
    const actions = this.actions;
    const selectors = this.selectors;

    @Injectable()
    class Effects extends PagedEntitiesXsStoreEffects<ResourceModelListDto> {
      public mergeOne$: any;
      public mergeOneSuccess$: any;

      public moveOne$: any;
      public moveOneSuccess$: any;

      protected actions: ModelListXsStoreActions;
      protected service: CorePortalManufacturerModelService;

      constructor(
        protected injector: Injector
      ) {
        super(injector, actions, selectors, serviceType, entityType, prepareEntity, prepareModel, sanitizeModel, false);
      }

      protected createEffects(): void {
        super.createEffects();

        this.mergeOne$ = createEffect(() => this.actions$.pipe(
          ofType(this.actions.mergeOne),
          groupBy(({ model }) => model.resourceModelId),
          mergeMap(group => group.pipe(
            switchMap(({
                         model,
                         newModelId
                       }) => this.service.mergeModel(model, newModelId).pipe(
              map(() => this.actions.mergeOneSuccess({ model, newModelId })),
              catchError(error => of(this.actions.error({ error, action: this.actions.mergeOne })))
            ))
          ))
        ));

        this.mergeOneSuccess$ = createEffect(() => this.actions$.pipe(
          ofType(this.actions.mergeOneSuccess),
          tap(action => this.actionCallback(action, false))
        ), { dispatch: false });


        this.moveOne$ = createEffect(() => this.actions$.pipe(
          ofType(this.actions.moveOne),
          groupBy(({ model }) => model.resourceModelId),
          mergeMap(group => group.pipe(
            switchMap(({
                         model,
                         newManufacturerId
                       }) => this.service.moveModel(model, newManufacturerId).pipe(
              map(() => this.actions.moveOneSuccess({ model, newManufacturerId })),
              catchError(error => of(this.actions.error({ error, action: this.actions.moveOne })))
            ))
          ))
        ));

        this.moveOneSuccess$ = createEffect(() => this.actions$.pipe(
          ofType(this.actions.moveOneSuccess),
          tap(action => this.actionCallback(action, false))
        ), { dispatch: false });

      }

      protected actionCallback(action: Action, isError: boolean = false): void {
        super.actionCallback(action, isError);

        this.checkAction(this.actions.mergeOneSuccess, action, () => this.mergeOneSuccess());
        this.checkAction(this.actions.moveOneSuccess, action, () => this.moveOneSuccess());
      }

      protected mergeOneSuccess(): void {
        this.apiNotificationService.showTranslatedSuccess('core-portal.settings.toasts.resource-model.merge-success');
      }

      protected moveOneSuccess(): void {
        this.apiNotificationService.showTranslatedSuccess('core-portal.settings.toasts.resource-model.move-success');
      }
    }

    return Effects;
  }
}
