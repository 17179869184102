import {
  DocumentTemplateListStoreEffects,
  documentTemplateListStoreReducer
} from './document-template-list/document-template-list.store';
import {
  DocumentTemplateDetailStoreEffects,
  documentTemplateDetailStoreReducer
} from './document-template-detail/document-template-detail.store';

export * from './document-template-list/document-template-list.store';
export * from './document-template-detail';

export * from './document-templates.state';
export * from './document-templates.selectors';

export const documentTemplatesReducers = {
  documentTemplateList: documentTemplateListStoreReducer,
  documentTemplateDetail: documentTemplateDetailStoreReducer
};

export const documentTemplatesEffects = [
  DocumentTemplateListStoreEffects,
  DocumentTemplateDetailStoreEffects
];
