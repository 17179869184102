import {
  TechPortalFeatureControlIssueService,
  TechPortalFeatureControlResourceService,
  TechPortalFeatureControlService
} from './services';
import {
  ControlDetailStoreEffects,
  controlDetailStoreReducer,
  ControlIssueListStoreEffects,
  controlIssueListStoreReducer,
  ControlListStoreEffects,
  controlListStoreReducer,
  ControlResourcesStoreEffects,
  controlResourcesStoreReducer
} from './stores';

export * from './services';
export * from './stores';
export * from './controls.state';
export * from './controls.selectors';

export const controlsReducers = {
  controlDetail: controlDetailStoreReducer,
  controlList: controlListStoreReducer,
  controlIssueList: controlIssueListStoreReducer,
  controlResources: controlResourcesStoreReducer
};

export const controlsEffects = [
  ControlDetailStoreEffects,
  ControlListStoreEffects,
  ControlIssueListStoreEffects,
  ControlResourcesStoreEffects
];

export const controlsServices = [
  TechPortalFeatureControlService,
  TechPortalFeatureControlIssueService,
  TechPortalFeatureControlResourceService
];
