import {ChangeDetectionStrategy, Component, Injector, OnInit, ViewChild} from '@angular/core';
import {faExchangeAlt} from '@fortawesome/free-solid-svg-icons/faExchangeAlt';
import {faFileExport} from '@fortawesome/free-solid-svg-icons/faFileExport';
import {faPlus} from '@fortawesome/free-solid-svg-icons/faPlus';
import {faUserClock} from '@fortawesome/free-solid-svg-icons/faUserClock';
import {
  ActionButton,
  authStore,
  CorePortalAttachmentsComponent,
  CorePortalEntityDetailBaseComponent,
  CorePortalPermissionService
} from '@nexnox-web/core-portal';
import {CorePortalFeatureMasterDataContactService} from '@nexnox-web/core-portal/features/master-data/features/contacts';
import {
  AppEntityType,
  AppPermissions,
  AttachmentForTechDto,
  IAssignContactDto,
  IAssignResourcDto,
  IssueSimpleDto,
  LabelDescriptorType,
  LabelDto,
  LabelSimpleDto,
  LinkDto,
  LinkedElementType,
  StateDto,
  TicketDto
} from '@nexnox-web/core-shared';
import {TechPortalExportByTemplateModalComponent, TechPortalLinksComponent} from '@nexnox-web/tech-portal-lib';
import {
  documentContextTypeEnumOptions,
  TechPortalFeatureDocumentTemplateService
} from '@nexnox-web/tech-portal/features/templates';
import {TechPortalFeatureTicketEditComponent} from '@nexnox-web/tech-portal/features/tickets-missions';
import {select} from '@ngrx/store';
import {isArray} from 'lodash';
import {combineLatest, Observable, startWith} from 'rxjs';
import {filter, map, mergeMap, switchMap, take} from 'rxjs/operators';
import {TicketAssignModalComponent, TicketFollowUpModalComponent} from '../../modals';
import {ticketDetailStore, TicketDetailXsStore, TicketStatemachineService} from '../../store';
import {TicketStateChangeSidebarComponent} from "@nexnox-web/tech-portal/features/tickets/src/lib/sidebars";
import {XsStoreTicketActionsFacade} from "@nexnox-web/tech-portal/features/tickets/src/lib/facades";


@Component({
  selector: 'nexnox-web-tickets-ticket-detail',
  templateUrl: './ticket-detail.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TicketDetailComponent extends CorePortalEntityDetailBaseComponent<TicketDto> implements OnInit {
  @ViewChild('ticketEditComponent') public ticketEditComponent: TechPortalFeatureTicketEditComponent;

  @ViewChild('attachmentsComponent') public attachmentsComponent: CorePortalAttachmentsComponent;
  @ViewChild('solutionAttachmentsComponent') public solutionAttachmentsComponent: CorePortalAttachmentsComponent;
  @ViewChild('linksComponent') public linksComponent: TechPortalLinksComponent;
  @ViewChild('ticketStateChangeSidebar', { static: true }) public ticketStateChangeSidebar: TicketStateChangeSidebarComponent;

  public title = 'tickets.subtitles.ticket-detail';

  public readMissionPermission$: Observable<boolean>;
  public readSolutionPermission$: Observable<boolean>;
  public readAuditPermission$: Observable<boolean>;
  public displayLabelPermission$: Observable<boolean>;

  public attachments$: Observable<AttachmentForTechDto[]>;
  public solutionAttachments$: Observable<AttachmentForTechDto[]>;
  public attachmentsString$: Observable<string>;
  public labels$: Observable<LabelSimpleDto[]>;
  public links$: Observable<LinkDto[]>;
  public ticketStereotypeId$: Observable<number>;
  public ticketStateMachineId$: Observable<number>;
  public primaryIssue$: Observable<IssueSimpleDto>;

  public loadingAssignContact$: Observable<boolean>;
  public loadingAssignRessource$: Observable<boolean>;

  public entityTypes = AppEntityType;
  public linkedElementTypes = LinkedElementType;

  public tenantId$: Observable<number>;
  public labelType = LabelDescriptorType.Ticket;
  declare protected entityStore: TicketDetailXsStore;
  protected idParam = 'ticketId';
  protected displayKey = 'title';

  constructor(
    protected injector: Injector,
    private stateMachineService: TicketStatemachineService,
    private permissionService: CorePortalPermissionService,
    private documentTemplateService: TechPortalFeatureDocumentTemplateService,
    private contactService: CorePortalFeatureMasterDataContactService
  ) {
    super(injector, ticketDetailStore);

    this.readMissionPermission$ = this.permissionService.hasPermission$(AppPermissions.ReadMission);
    this.readSolutionPermission$ = this.permissionService.hasPermission$(AppPermissions.ReadSolution);
    this.readAuditPermission$ = this.permissionService.hasPermission$(AppPermissions.ReadAudit);
    this.displayLabelPermission$ = this.permissionService.hasPermission$(AppPermissions.DisplayLabels);
  }

  /* istanbul ignore next */
  public ngOnInit(): void {
    super.ngOnInit();

    this.tenantId$ = this.store.pipe(
      select(authStore.selectors.selectTenantId)
    );

    this.attachments$ = this.model$.pipe(
      map(model => model?.attachments ?? [])
    );

    this.solutionAttachments$ = this.model$.pipe(
      map(model => model?.solution?.attachments ?? [])
    );

    this.labels$ = this.model$.pipe(
      map(model => model?.labels ?? [])
    );

    this.attachmentsString$ = this.model$.pipe(
      map(model => {
        const attachments = model?.attachments ?? [];
        const solutionAttachments = model?.solution?.attachments ?? [];
        const sum = (attachments?.length ?? 0) + (solutionAttachments?.length ?? 0);

        if (!sum) return '';
        return ` (${ sum })`;
      })
    );

    this.links$ = this.model$.pipe(
      map(model => model?.links ?? [])
    );

    this.ticketStereotypeId$ = this.model$.pipe(
      map(model => model?.stereotypeId ?? null)
    );

    this.primaryIssue$ = this.model$.pipe(
      map(model => model?.origin?.primaryIssue)
    );

    this.ticketStateMachineId$ = combineLatest([this.stereotypes$, this.model$]).pipe(
      map(([stereotypes, model]) => stereotypes.find(st => st.stereotypeId === model.stereotypeId)?.stateMachine?.stateMachineId ?? 0)
    );

    this.loadingAssignContact$ = this.store.pipe(
      select(this.entityStore.selectors.selectEntityDataLoading, { key: 'changeContact' }),
      startWith(false),
    );

    this.loadingAssignRessource$ = this.store.pipe(
      select(this.entityStore.selectors.selectEntityDataLoading, { key: 'changeResource' }),
      startWith(false),
    );

    this.subscribe(this.readonly$.asObservable(), () => {
      this.attachmentsComponent?.onReset();
      this.solutionAttachmentsComponent?.onReset();
      this.linksComponent?.onReset();
    });
  }

  public onTicketStateChange(state: StateDto): void {
    this.subscribe(this.ticketStereotypeId$.pipe(take(1)), stereotypeId => {
      this.ticketStateChangeSidebar.ticketId = +this.id ?? null;
      this.ticketStateChangeSidebar.ticketState = state;
      this.ticketStateChangeSidebar.ticketActionFacade = new XsStoreTicketActionsFacade(this.injector, ticketDetailStore);
      this.ticketStateChangeSidebar.onShow();
    });
  }

  public onAttachmentsChange(attachments: AttachmentForTechDto[], model: TicketDto): void {
    this.onModelChange({ ...model, attachments });
  }

  public onLinksChange(links: LinkDto[], model: TicketDto): void {
    this.onModelChange({ ...model, links });
  }

  public onLabelsChange(labels: LabelDto[], model: TicketDto): void {
    this.onModelChange({ ...model, labels });
  }

  public onAssignToAction(): void {
    this.modalService.showModal(TicketAssignModalComponent, instance => {
      instance.service = this.contactService;
    })
      .then(({ value: { contact } }) => this.store.dispatch(this.entityStore.actions.assignContact({ contact })))
      .catch(() => null);
  }

  public onAssignResource(payload: IAssignResourcDto): void {
    this.store.dispatch(ticketDetailStore.actions.assignResource({
      ticketId: this.id,
      resource: payload.resource,
      isUpdateRelations: payload.isUpdateRelations
    }))
  }

  public onUnassignResource(): void {
    this.store.dispatch(ticketDetailStore.actions.unassignResource({
      parentIds: this.parentIds
    }))
  }

  public onAssignContact(payload: IAssignContactDto): void {
    if (payload.isAssignMe) {
      this.store.dispatch(this.entityStore.actions.assignContactToMe({}))
    } else {
      this.store.dispatch(this.entityStore.actions.assignContact({ contact: payload.contact }));
    }
  }

  public onUnassignContact(): void {
    this.store.dispatch(this.entityStore.actions.unassignContact({}))
  }


  /* istanbul ignore next */
  protected async getActionButtons(): Promise<ActionButton[]> {
    return [
      ...this.getDefaultActionButtons(
        'tickets.actions.edit-ticket',
        'tickets.actions.save-ticket',
        'tickets.actions.delete-ticket',
        'tickets.descriptions.delete-ticket',
        AppPermissions.UpdateTicket,
        AppPermissions.DeleteTicket,
        ['/tickets'],
        'communication'
      ),
      {
        label: 'tickets.actions.change-ticket-state',
        type: 'dropdown',
        icon: faExchangeAlt,
        class: 'btn-outline-primary',
        isLoading: () => this.store.pipe(
          select(this.entityStore.selectors.selectPreviewStatesLoaded),
          switchMap(previewStatesLoaded => this.store.pipe(
            select(this.entityStore.selectors.selectPreviewStatesLoading),
            switchMap(previewStatesLoading => this.store.pipe(
              select(this.entityStore.selectors.selectLoading),
              map(loading => loading || !previewStatesLoaded || previewStatesLoading)
            ))
          ))
        ),
        buttons: this.store.pipe(
          select(this.entityStore.selectors.selectPreviewStates),
          map(previewStates => [
            ...previewStates.map(previewState => ({
              label: previewState.name,
              noTranslate: true,
              type: 'button',
              callback: () => this.onTicketStateChange(previewState)
            })),
            {
              label: 'tickets.actions.force-ticket-state',
              icon: faPlus,
              type: 'button',
              permission: AppPermissions.ForceTicketState,
              callback: async () => {
                this.subscribe(this.ticketStereotypeId$.pipe(take(1)), stereotypeId => {
                  this.ticketStateChangeSidebar.ticketId = +this.id ?? null;
                  this.ticketStateChangeSidebar.stateMachineId$ = this.stereotypes$.pipe(map(stereotypes => stereotypes.find(st => st.stereotypeId === stereotypeId)?.stateMachine?.stateMachineId));
                  this.ticketStateChangeSidebar.ticketActionFacade = new XsStoreTicketActionsFacade(this.injector, ticketDetailStore);
                  this.ticketStateChangeSidebar.onShow();
                });
              }
            }
          ] as ActionButton[])
        ),
        permission: AppPermissions.UpdateTicket
      },
      {
        label: 'tickets.actions.follow-up',
        icon: faUserClock,
        type: 'button',
        class: 'btn-outline-primary',
        isLoading: () => this.store.pipe(
          select(this.entityStore.selectors.selectEntityDataLoading, { key: 'followUp' }),
          switchMap(followUpLoading => this.store.pipe(
            select(this.entityStore.selectors.selectLoading),
            switchMap(loading => this.store.pipe(
              select(this.entityStore.selectors.selectPreviewStatesLoading),
              map(previewStatesLoading => loading || followUpLoading || previewStatesLoading)
            ))
          ))
        ),
        permission: AppPermissions.UpdateTicket,
        callback: () => this.modalService.showModal(TicketFollowUpModalComponent, async instance => {
          instance.states = await this.store.pipe(
            select(this.entityStore.selectors.selectPreviewStates),
            filter(states => isArray(states)),
            take(1)
          ).toPromise();
        })
          .then(({ value: { stateId, triggersAt } }) => this.store.dispatch(this.entityStore.actions.followUp({
            stateId,
            triggersAt,
            parentIds: [this.id]
          })))
          .catch(() => null)
      },
      {
        label: 'tickets.actions.export-ticket',
        type: 'button',
        icon: faFileExport,
        class: 'btn-outline-secondary',
        isLoading: () => this.store.pipe(
          select(this.entityStore.selectors.selectEntityDataLoading, { key: 'export' }),
          mergeMap(exportLoading => this.store.pipe(
            select(this.entityStore.selectors.selectLoading),
            map(loading => loading || exportLoading)
          ))
        ),
        permission: AppPermissions.ReadDocumentTemplate,
        callback: () => this.store.dispatch(this.entityStore.actions.export({})),
        splitCallback: () => this.modalService.showModal(TechPortalExportByTemplateModalComponent, instance => {
          instance.service = this.documentTemplateService;
          instance.contextTypes = [documentContextTypeEnumOptions[0]];
        })
          .then(({ value: { template } }) => this.store.dispatch(this.entityStore.actions.export({
            templateId: template.documentTemplateId
          })))
          .catch(() => null)
      }
    ];
  }

  protected onSave(): void {
    const resourceSync = this.ticketEditComponent?.resourceSyncSubject?.getValue() &&
      Boolean(this.ticketEditComponent?.resourceIdSubject.getValue());

    this.store.dispatch(this.entityStore.actions.save({
      id: this.id,
      parentIds: this.parentIds,
      queryParams: [
        ['isSyncResource', resourceSync]
      ]
    }));
  }
}
