<ng-template #entityEditTemplate let-title="title">
  <nexnox-web-master-data-contacts-contact-edit #editComponent
                                                (backlinkChange)="onBacklinkChange($event)"
                                                (modelChange)="createModel$.next($event)"
                                                [creating]="true"
                                                [loading]="(loaded$ | async) === false"
                                                [model]="createModel$ | async"
                                                [readonly]="false"
                                                [stereotypes$]="stereotypes$"
                                                [title]="title">
  </nexnox-web-master-data-contacts-contact-edit>
</ng-template>

<nexnox-web-entity-overview #overviewComponent
                            [entityEditTemplate]="entityEditTemplate"
                            [entityOverviewBaseComponent]="this"
                            detailLink="/masterdata/contacts"
                            module="management">
</nexnox-web-entity-overview>

<nexnox-web-master-data-contacts-bulk-area-assignment-sidebar #bulkAreaAssignmentSidebar
                                                              (accept)="onBulkAreaAssign($event)"
                                                              [contactCount]="contactCount"
                                                              [filters]="stateFilters$ | async">
</nexnox-web-master-data-contacts-bulk-area-assignment-sidebar>
