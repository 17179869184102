import {PagedEntitiesXsStore, PagedEntitiesXsStoreState} from '@nexnox-web/core-store';
import {AppEntityType, NotificationRuleListDto} from '@nexnox-web/core-shared';
import {Action, createSelector} from '@ngrx/store';
import {Injectable, Injector} from '@angular/core';
import {selectNotificationRulesState} from "../../notification-rules.selector";
import {CorePortalFeatureNotificationRulesService} from "../../services";

export interface NotificationRulesListStoreState extends PagedEntitiesXsStoreState<NotificationRuleListDto> {
}

/* istanbul ignore next */
export const notificationRulesListStore = new PagedEntitiesXsStore<NotificationRuleListDto>({
  actionLabel: 'Core Portal - Settings - Notification Rules - Notification Rules List',
  stateSelector: createSelector(selectNotificationRulesState, state => state.notificationRulesList),
  serviceType: CorePortalFeatureNotificationRulesService,
  entityType: AppEntityType.None,
  selectId: rule => rule.entity?.notificationRuleId ?? rule.model?.notificationRuleId,
  stereotyped: false
});

export function notificationRulesListStoreReducer(state: NotificationRulesListStoreState, action: Action): any {
  return notificationRulesListStore.reducer(state, action);
}

@Injectable()
export class NotificationRulesListStoreEffects extends notificationRulesListStore.effects {
  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }
}
