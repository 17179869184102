import {Injectable, Injector} from '@angular/core';
import {
  CoreSharedApiBaseService,
  Product,
  ResourceModelDto,
  ResourceModelMergeDto,
  ResourceModelMoveDto
} from '@nexnox-web/core-shared';
import {Observable} from "rxjs";
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class CorePortalModelService extends CoreSharedApiBaseService {

  public useFilterOperation = false;

  constructor(
    protected injector: Injector
  ) {
    super(injector, Product.TECH, 'v2/model');
  }

  public createOne<T>(data: T, parentIds?: Array<number | string>): Observable<T> {
    return this.httpRequestService.post<T>(`v2/manufacturer/${ parentIds }/model`, data).pipe(
      map(response => response.body)
    );
  }

  public mergeModel(model: ResourceModelDto, newModelId: number | string): Observable<ResourceModelMergeDto> {
    return this.httpRequestService.post<ResourceModelMergeDto>
    (`v2/model/${ model.resourceModelId }/merge`, { newModelId: newModelId }).pipe(
      map(response => response.body)
    )
  }

  public moveModel(model: ResourceModelDto, newManufacturerId: number | string): Observable<ResourceModelMoveDto> {
    return this.httpRequestService.post<ResourceModelMoveDto>
    (`v2/model/${ model.resourceModelId }/move`, { newManufacturerId: newManufacturerId }).pipe(
      map(response => response.body)
    )
  }
}
