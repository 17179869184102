import {ActivatedRouteSnapshot, CanActivate, convertToParamMap, Router, RouterStateSnapshot} from '@angular/router';
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {select, Store} from '@ngrx/store';
import {filter, map, switchMap, take} from 'rxjs/operators';
import {CoreSharedLocalStorageService, LocalStorageKeys} from '@nexnox-web/core-shared';
import {authStore} from '../../store/core/auth';

@Injectable()
export class CorePortalAuthGuard implements CanActivate {
  public static notGuardedRoutes: string[] = [
    '/user/change-password',
    '/user/reset-password'
  ];

  public guardCounterSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  constructor(
    private store: Store<any>,
    private router: Router,
    private localStorage: CoreSharedLocalStorageService
  ) {
  }

  public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const url = this.router.serializeUrl({
      ...this.router.parseUrl(state.url),
      queryParams: {},
      queryParamMap: convertToParamMap({})
    });

    return this.store.pipe(
      select(authStore.selectors.selectLoaded),
      switchMap(loaded => {
        this.guardCounterSubject.next(this.guardCounterSubject.getValue() + 1);

        if (!loaded) {
          /**
           * This is a NEVER, because if all of these are false we want the BootstrapService to handle navigating us away,
           * which should in theory cancel this NEVER. If not, oops memory leak.
           *
           * If this wouldn't be a NEVER it would flash the screen, probably (Implemented this a while ago, so it might not be true).
           * Also it's fine if it allows us through to the application, because there is another guard after this one.
           */
          return of(true).pipe(
            filter(() => !(
              this.guardCounterSubject.getValue() === 1 &&
              this.localStorage.get(LocalStorageKeys.REFRESH_TOKEN) && url === '/user/signin'
            ))
          );
        }

        return this.store.pipe(
          select(authStore.selectors.selectLoggedIn),
          map(authenticated => {
            if (authenticated) {
              if (url === '/user/signin') {
                this.router.navigate(['/']);
                return false;
              }

              return true;
            }

            if (url !== '/user/signin' && !CorePortalAuthGuard.notGuardedRoutes.some(route => url === route)) {
              this.router.navigate(['/user/signin']);
              return false;
            }

            return true;
          }),
          take(1)
        );
      }),
      take(1)
    );
  }
}
