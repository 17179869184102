<nexnox-web-entity-overview #overviewComponent
                            (selectInitialTab)="onNavigateToTab($event)"
                            [detailFn]="detailFn"
                            [disableCreate]="true"
                            [entityOverviewBaseComponent]="this"
                            [tabCommands]="['.']"
                            [tabHeading]="'core-shared.shared.select.all' | translate"
                            [useTabs]="true">
  <!-- Message Issues -->
  <ng-template (selectTab)="onNavigateToTab(['message'])"
               *ngIf="shouldShowMessageIssuesTab$ | async"
               [heading]="'issues.subtitles.message-issue-list' | translate"
               [lazy]="true"
               nexnoxWebTabTemplate
               tabId="message">
    <nexnox-web-issues-message-issue-list></nexnox-web-issues-message-issue-list>
  </ng-template>

  <!-- Mission Issues -->
  <ng-template (selectTab)="onNavigateToTab(['mission'])"
               *ngIf="shouldShowMissionIssuesTab$ | async"
               [heading]="'issues.subtitles.mission-issue-list' | translate"
               [lazy]="true"
               nexnoxWebTabTemplate
               tabId="mission">
    <nexnox-web-issues-mission-issue-list></nexnox-web-issues-mission-issue-list>
  </ng-template>

  <!-- Ticket Issues -->
  <ng-template (selectTab)="onNavigateToTab(['ticket'])"
               *ngIf="shouldShowTicketIssuesTab$ | async"
               [heading]="'issues.subtitles.ticket-issue-list' | translate"
               [lazy]="true"
               nexnoxWebTabTemplate
               tabId="ticket">
    <nexnox-web-issues-ticket-issue-list></nexnox-web-issues-ticket-issue-list>
  </ng-template>

  <!-- Winterhalter Service Call Issues -->
  <ng-template (selectTab)="onNavigateToTab(['winterhalter-service-call'])"
               *ngIf="shouldShowWinterhalterServiceCallIssuesTab$ | async"
               [heading]="'issues.subtitles.winterhalter-service-call-issue-list' | translate"
               [lazy]="true"
               nexnoxWebTabTemplate
               tabId="winterhalter-service-call">
    <nexnox-web-issues-winterhalter-service-call-issue-list></nexnox-web-issues-winterhalter-service-call-issue-list>
  </ng-template>

  <!-- Winterhalter Product Order Issues -->
  <ng-template (selectTab)="onNavigateToTab(['winterhalter-product-order'])"
               *ngIf="shouldShowWinterhalterProductOrderIssuesTab$ | async"
               [heading]="'issues.subtitles.winterhalter-product-order-issue-list' | translate"
               [lazy]="true"
               nexnoxWebTabTemplate
               tabId="winterhalter-product-order">
    <nexnox-web-issues-winterhalter-product-order-issue-list></nexnox-web-issues-winterhalter-product-order-issue-list>
  </ng-template>

  <!-- Message Control Issues -->
  <ng-template (selectTab)="onNavigateToTab(['message-control'])"
               *ngIf="shouldShowMessageIssuesTab$ | async"
               [heading]="'issues.subtitles.message-control-issue-list' | translate"
               [lazy]="true"
               nexnoxWebTabTemplate
               tabId="message-control">
    <nexnox-web-issues-message-control-issue-list></nexnox-web-issues-message-control-issue-list>
  </ng-template>
</nexnox-web-entity-overview>
