import {
  AttachmentFormFieldDto,
  AttachmentTypes,
  CoreSharedFileService,
  DropDownFormFieldDto,
  DropDownStyles,
  KnowledgeArticleDto,
  NumberFormFieldDto,
  SearchListSimpleDto,
  UnsubscribeHelper
} from '@nexnox-web/core-shared';
import {FormlyFieldConfig, FormlyTemplateOptions} from '@ngx-formly/core';
import {merge, Observable} from 'rxjs';
import {
  CorePortalFormlyFileUploadTyping,
  CorePortalFormlyNgSelectTyping,
  CorePortalFormlyReadonlyTypes,
  CorePortalFormlyReadonlyTyping,
  CorePortalFormlyTranslatedTyping
} from '@nexnox-web/core-portal';
import {isEmpty, isEqual, isFinite, isNumber} from 'lodash';
import {distinctUntilChanged, startWith} from 'rxjs/operators';
import {FormControl, Validators} from '@angular/forms';
import {orgaPortalAttachmentTypeEnumOptions, OrgaPortalKnowledgeArticleService} from '@nexnox-web/orga-portal-lib';
import {LocalFormFieldDto} from '../../models';
import {CorePortalSearchListsService} from "@nexnox-web/core-portal/features/settings/features/search-lists/src/lib/store";

export interface ExpressionProperties {
  [property: string]: string | ((model: any, formState: any, field?: FormlyFieldConfig) => any) | Observable<any>;
}

export interface FormFieldOptions {
  formlyType?: string;
  inputType?: string;
  valuePath?: string;
  className?: string;
  prependFields?: FormlyFieldConfig[];
  additionalFields?: FormlyFieldConfig[];
  templateOptions?: Partial<FormlyTemplateOptions>;
  validationMessages?: any;
  expressionProperties?: ExpressionProperties;
  validators?: any;
  hooks?: any;
  noDefault?: boolean;
  noRequired?: boolean;
  noReadonly?: boolean;
}

export const dropdownStyleEnumOptions = [
  { label: 'orga-portal.forms.dropdown-styles.0', value: DropDownStyles.Default },
  { label: 'orga-portal.forms.dropdown-styles.1', value: DropDownStyles.ChooseList }
];

/* istanbul ignore next */
export class FormFieldTemplates extends UnsubscribeHelper {
  constructor(
    private readonlyFn: () => boolean
  ) {
    super();
  }

  public default(options?: FormFieldOptions): FormlyFieldConfig[] {
    const defaultValuePath = 'defaultValues.0.value';

    const formlyType = options?.formlyType ?? 'input';
    const inputType = options?.inputType ?? 'text';
    const valuePath = options?.valuePath ?? defaultValuePath;
    const className = options?.className ?? 'col-md-12';
    const prependFields = options?.prependFields ?? [];
    const additionalFields = options?.additionalFields ?? [];
    const templateOptions = options?.templateOptions ?? {};
    const validationMessages = options?.validationMessages ?? {};
    const expressionProperties = options?.expressionProperties ?? {};
    const validators = options?.validators ?? {};
    const hooks = options?.hooks ?? {};
    const noDefault = options?.noDefault;
    const noRequired = options?.noRequired;
    const noReadonly = options?.noReadonly;

    return [
      ...prependFields,
      ...(!noDefault ? [{
        key: valuePath,
        type: formlyType,
        wrappers: ['core-portal-translated', ...(noReadonly ? [] : ['core-portal-readonly'])],
        className,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.default-value',
            validationMessages: {
              ...validationMessages
            }
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.BASIC
          },
          type: inputType,
          ...templateOptions
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonlyFn(),
          'templateOptions.readonly': () => this.readonlyFn(),
          ...expressionProperties
        },
        validators,
        hooks
      }] : []),
      ...additionalFields,
      ...(!noRequired ? [{
        key: 'isRequired',
        type: 'core-portal-switch',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.is-required'
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.BOOLEAN
          } as CorePortalFormlyReadonlyTyping
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonlyFn(),
          'templateOptions.readonly': () => this.readonlyFn()
        }
      }] : [])
    ];
  }

  public info(): FormlyFieldConfig[] {
    return this.default({
      noDefault: true,
      noRequired: true,
      prependFields: [
        {
          key: 'content',
          type: 'textarea',
          wrappers: ['core-portal-translated', 'core-portal-readonly'],
          className: 'col-md-12',
          templateOptions: {
            corePortalTranslated: {
              label: 'core-shared.shared.fields.content',
              validationMessages: {
                required: 'core-portal.core.validation.required'
              }
            },
            corePortalReadonly: {
              type: CorePortalFormlyReadonlyTypes.BASIC
            } as CorePortalFormlyReadonlyTyping,
            rows: 3,
            type: 'text'
          },
          expressionProperties: {
            'templateOptions.required': () => !this.readonlyFn(),
            'templateOptions.disabled': () => this.readonlyFn(),
            'templateOptions.readonly': () => this.readonlyFn()
          }
        }
      ]
    });
  }

  public text(): FormlyFieldConfig[] {
    return this.default({
      inputType: 'text',
      templateOptions: {
        corePortalReadonly: {
          type: CorePortalFormlyReadonlyTypes.BASIC
        } as CorePortalFormlyReadonlyTyping
      },
      ...this.getSharedTextConfig()
    });
  }

  public multilineText(): FormlyFieldConfig[] {
    return this.default({
      formlyType: 'textarea',
      inputType: 'text',
      templateOptions: {
        corePortalReadonly: {
          type: CorePortalFormlyReadonlyTypes.BASIC
        } as CorePortalFormlyReadonlyTyping,
        rows: 3
      },
      ...this.getSharedTextConfig()
    });
  }

  public numeric(): FormlyFieldConfig[] {
    return this.default({
      inputType: 'number',
      className: 'col-md-9',
      templateOptions: {
        corePortalReadonly: {
          type: CorePortalFormlyReadonlyTypes.BASIC,
          format: true
        } as CorePortalFormlyReadonlyTyping
      },
      validationMessages: {
        min: {
          key: 'core-portal.core.validation.min',
          args: { min: null }
        },
        max: {
          key: 'core-portal.core.validation.max',
          args: { max: null }
        }
      },
      expressionProperties: {
        'templateOptions.corePortalTranslated.validationMessages.min.args': model => ({ min: model?.minValue ?? null }),
        'templateOptions.corePortalTranslated.validationMessages.max.args': model => ({ max: model?.maxValue ?? null })
      },
      validators: {
        min: (ctrl, field) => this.defaultValueMinValidator(ctrl, field),
        max: (ctrl, field) => this.defaultValueMaxValidator(ctrl, field)
      },
      hooks: {
        onInit: field => this.updateFieldHook(field, ['minValue', 'maxValue'])
      },
      additionalFields: [
        {
          key: 'unit',
          type: 'input',
          wrappers: ['core-portal-translated', 'core-portal-readonly'],
          className: 'col-md-3',
          templateOptions: {
            corePortalTranslated: {
              label: 'core-shared.shared.fields.unit'
            },
            corePortalReadonly: {
              type: CorePortalFormlyReadonlyTypes.BASIC
            } as CorePortalFormlyReadonlyTyping,
            type: 'text'
          },
          expressionProperties: {
            'className': (model: LocalFormFieldDto) => model.width <= 3 ? 'col-md-12' : 'col-md-3',
            'templateOptions.disabled': () => this.readonlyFn(),
            'templateOptions.readonly': () => this.readonlyFn()
          }
        },
        {
          key: 'setPointMin',
          type: 'input',
          wrappers: ['core-portal-translated', 'core-portal-readonly'],
          className: 'col-md-6',
          templateOptions: {
            corePortalTranslated: {
              label: 'core-shared.shared.fields.set-point-min'
            },
            corePortalReadonly: {
              type: CorePortalFormlyReadonlyTypes.BASIC,
              format: true
            } as CorePortalFormlyReadonlyTyping,
            type: 'number'
          },
          expressionProperties: {
            'className': (model: LocalFormFieldDto) => model.width <= 3 ? 'col-md-12' : 'col-md-6',
            'templateOptions.disabled': () => this.readonlyFn(),
            'templateOptions.readonly': () => this.readonlyFn()
          },
          hooks: {
            onInit: field => this.updateFieldHook(field, ['setPointMax'])
          },
          hideExpression: (model: NumberFormFieldDto) => !model.isRateable
        },
        {
          key: 'setPointMax',
          type: 'input',
          wrappers: ['core-portal-translated', 'core-portal-readonly'],
          className: 'col-md-6',
          templateOptions: {
            corePortalTranslated: {
              label: 'core-shared.shared.fields.set-point-max',
              validationMessages: {
                min: {
                  key: 'core-portal.core.validation.min',
                  args: { min: null }
                }
              }
            },
            corePortalReadonly: {
              type: CorePortalFormlyReadonlyTypes.BASIC,
              format: true
            } as CorePortalFormlyReadonlyTyping,
            type: 'number'
          },
          expressionProperties: {
            'className': (model: LocalFormFieldDto) => model.width <= 3 ? 'col-md-12' : 'col-md-6',
            'templateOptions.disabled': () => this.readonlyFn(),
            'templateOptions.readonly': () => this.readonlyFn(),
            'templateOptions.corePortalTranslated.validationMessages.min.args': model => ({
              min: isNumber(model.setPointMin) ? model.setPointMin : null
            })
          },
          validators: {
            min: (ctrl, field) => this.defaultValueMinValidator(ctrl, field, 'setPointMin')
          },
          hooks: {
            onInit: field => this.updateFieldHook(field, ['setPointMin'])
          },
          hideExpression: (model: NumberFormFieldDto) => !model.isRateable
        },
        {
          key: 'negativeTolerance',
          type: 'input',
          wrappers: ['core-portal-translated', 'core-portal-readonly'],
          className: 'col-md-6',
          templateOptions: {
            corePortalTranslated: {
              label: 'core-shared.shared.fields.negative-tolerance'
            },
            corePortalReadonly: {
              type: CorePortalFormlyReadonlyTypes.BASIC,
              format: true
            } as CorePortalFormlyReadonlyTyping,
            type: 'number'
          },
          expressionProperties: {
            'className': (model: LocalFormFieldDto) => model.width <= 3 ? 'col-md-12' : 'col-md-6',
            'templateOptions.disabled': () => this.readonlyFn(),
            'templateOptions.readonly': () => this.readonlyFn()
          },
          hideExpression: (model: NumberFormFieldDto) => !model.isRateable
        },
        {
          key: 'positiveTolerance',
          type: 'input',
          wrappers: ['core-portal-translated', 'core-portal-readonly'],
          className: 'col-md-6',
          templateOptions: {
            corePortalTranslated: {
              label: 'core-shared.shared.fields.positive-tolerance'
            },
            corePortalReadonly: {
              type: CorePortalFormlyReadonlyTypes.BASIC,
              format: true
            } as CorePortalFormlyReadonlyTyping,
            type: 'number'
          },
          expressionProperties: {
            'className': (model: LocalFormFieldDto) => model.width <= 3 ? 'col-md-12' : 'col-md-6',
            'templateOptions.disabled': () => this.readonlyFn(),
            'templateOptions.readonly': () => this.readonlyFn()
          },
          hideExpression: (model: NumberFormFieldDto) => !model.isRateable
        },
        {
          key: 'minValue',
          type: 'input',
          wrappers: ['core-portal-translated', 'core-portal-readonly'],
          className: 'col-md-6',
          templateOptions: {
            corePortalTranslated: {
              label: 'core-shared.shared.fields.min'
            },
            corePortalReadonly: {
              type: CorePortalFormlyReadonlyTypes.BASIC,
              format: true
            } as CorePortalFormlyReadonlyTyping,
            type: 'number'
          },
          expressionProperties: {
            'className': (model: LocalFormFieldDto) => model.width <= 3 ? 'col-md-12' : 'col-md-6',
            'templateOptions.disabled': () => this.readonlyFn(),
            'templateOptions.readonly': () => this.readonlyFn()
          },
          hooks: {
            onInit: field => this.updateFieldHook(field, ['defaultValues.0.value', 'maxValue'])
          }
        },
        {
          key: 'maxValue',
          type: 'input',
          wrappers: ['core-portal-translated', 'core-portal-readonly'],
          className: 'col-md-6',
          templateOptions: {
            corePortalTranslated: {
              label: 'core-shared.shared.fields.max',
              validationMessages: {
                min: {
                  key: 'core-portal.core.validation.min',
                  args: { min: null }
                }
              }
            },
            corePortalReadonly: {
              type: CorePortalFormlyReadonlyTypes.BASIC,
              format: true
            } as CorePortalFormlyReadonlyTyping,
            type: 'number'
          },
          expressionProperties: {
            'className': (model: LocalFormFieldDto) => model.width <= 3 ? 'col-md-12' : 'col-md-6',
            'templateOptions.disabled': () => this.readonlyFn(),
            'templateOptions.readonly': () => this.readonlyFn(),
            'templateOptions.corePortalTranslated.validationMessages.min.args': model => ({
              min: isNumber(model.minValue) ? model.minValue : null
            })
          },
          validators: {
            min: (ctrl, field) => this.defaultValueMinValidator(ctrl, field)
          },
          hooks: {
            onInit: field => this.updateFieldHook(field, ['defaultValues.0.value', 'minValue'])
          }
        },
        {
          key: 'isRateable',
          type: 'core-portal-switch',
          wrappers: ['core-portal-translated', 'core-portal-readonly'],
          className: 'col-md-6',
          defaultValue: false,
          templateOptions: {
            corePortalTranslated: {
              label: 'core-shared.shared.fields.is-rateable'
            },
            corePortalReadonly: {
              type: CorePortalFormlyReadonlyTypes.BOOLEAN
            } as CorePortalFormlyReadonlyTyping
          },
          expressionProperties: {
            'className': (model: LocalFormFieldDto) => model.width <= 3 ? 'col-md-12' : 'col-md-6',
            'templateOptions.disabled': () => this.readonlyFn(),
            'templateOptions.readonly': () => this.readonlyFn()
          },
          hooks: {
            onInit: field => field.formControl.updateValueAndValidity()
          }
        }
      ]
    });
  }

  public date(): FormlyFieldConfig[] {
    return this.default({
      noDefault: true
    });
  }

  public time(): FormlyFieldConfig[] {
    return this.default({
      noDefault: true
    });
  }

  public picture(): FormlyFieldConfig[] {
    return this.default({
      noDefault: true
    });
  }

  public signature(): FormlyFieldConfig[] {
    return this.default({
      noDefault: true
    });
  }

  public checkbox(): FormlyFieldConfig[] {
    return this.default({
      formlyType: 'core-portal-switch',
      inputType: 'boolean',
      className: 'col-md-6',
      templateOptions: {
        corePortalReadonly: {
          type: CorePortalFormlyReadonlyTypes.BOOLEAN
        } as CorePortalFormlyReadonlyTyping
      },
      expressionProperties: {
        'className': (model: LocalFormFieldDto) => model.width <= 3 ? 'col-md-12' : 'col-md-6',
      }
    });
  }

  public dropdown(): FormlyFieldConfig[] {
    return this.default({
      valuePath: 'defaultValues',
      formlyType: 'orga-portal-forms-form-field-dropdown',
      noReadonly: true,
      validationMessages: {
        oneOption: 'core-portal.core.validation.one-option',
        filled: 'core-portal.core.validation.filled'
      },
      validators: {
        oneOption: ctrl => Boolean(ctrl.value?.length),
        filled: ctrl => Boolean(ctrl.value?.every(value => !isEmpty(value.value)))
      },
      templateOptions: {
        orgaPortalFormFieldDropdown: {
          isRateable: false
        }
      },
      expressionProperties: {
        'templateOptions.orgaPortalFormFieldDropdown.isRateable': (model: DropDownFormFieldDto) => model?.isRateable
      },
      prependFields: [{
        key: 'style',
        type: 'core-portal-ng-select',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-12',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.kind',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          } as CorePortalFormlyTranslatedTyping,
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.ENUM,
            enumOptions: dropdownStyleEnumOptions,
            translate: true
          } as CorePortalFormlyReadonlyTyping,
          corePortalNgSelect: {
            items: dropdownStyleEnumOptions,
            translate: true,
            noClear: true
          } as CorePortalFormlyNgSelectTyping
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonlyFn(),
          'templateOptions.disabled': () => this.readonlyFn(),
          'templateOptions.readonly': () => this.readonlyFn()
        }
      }],
      additionalFields: [{
        key: 'isRateable',
        type: 'core-portal-switch',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        defaultValue: false,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.is-rateable'
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.BOOLEAN
          } as CorePortalFormlyReadonlyTyping
        },
        expressionProperties: {
          'className': (model: LocalFormFieldDto) => model.width <= 3 ? 'col-md-12' : 'col-md-6',
          'templateOptions.disabled': () => this.readonlyFn(),
          'templateOptions.readonly': () => this.readonlyFn()
        },
        hooks: {
          onInit: field => field.formControl.updateValueAndValidity()
        }
      }]
    });
  }

  public attachment(
    fileService: CoreSharedFileService,
    knowledgeArticleService: OrgaPortalKnowledgeArticleService
  ): FormlyFieldConfig[] {
    return this.default({
      noDefault: true,
      noRequired: true,
      prependFields: [
        {
          key: 'attachment.type',
          type: 'core-portal-ng-select',
          wrappers: ['core-portal-translated', 'core-portal-readonly'],
          className: 'col-md-12',
          defaultValue: AttachmentTypes.File,
          templateOptions: {
            corePortalTranslated: {
              label: 'core-shared.shared.fields.kind',
              validationMessages: {
                required: 'core-portal.core.validation.required'
              }
            },
            corePortalReadonly: {
              type: CorePortalFormlyReadonlyTypes.ENUM,
              enumOptions: orgaPortalAttachmentTypeEnumOptions,
              translate: true
            } as CorePortalFormlyReadonlyTyping,
            corePortalNgSelect: {
              items: orgaPortalAttachmentTypeEnumOptions,
              translate: true,
              noClear: true
            } as CorePortalFormlyNgSelectTyping
          },
          expressionProperties: {
            'templateOptions.required': () => !this.readonlyFn(),
            'templateOptions.disabled': () => this.readonlyFn(),
            'templateOptions.readonly': () => this.readonlyFn()
          }
        },

        // File
        { key: 'attachment.fileId' },
        {
          key: 'attachment.file',
          type: 'core-portal-file-upload',
          wrappers: ['core-portal-translated'],
          className: 'col-md-12',
          templateOptions: {
            corePortalTranslated: {
              label: 'core-shared.shared.fields.file',
              validationMessages: {
                required: 'core-portal.core.validation.required'
              }
            },
            corePortalFileUpload: {
              accept: '.pdf,.png,.jpg,.jpeg'
            } as CorePortalFormlyFileUploadTyping
          },
          expressionProperties: {
            'templateOptions.required': () => !this.readonlyFn(),
            'templateOptions.disabled': () => this.readonlyFn()
          },
          hooks: {
            onInit: field => this.subscribe(field.formControl.valueChanges.pipe(
              startWith(field.formControl.value),
              distinctUntilChanged((a, b) => isEqual(a, b))
            ), value => {
              field.form.get('attachment.fileId').setValue(value?.fileId ?? null);
            })
          },
          hideExpression: (model: AttachmentFormFieldDto) => model.attachment?.type !== AttachmentTypes.File
        },

        // Knowledge article
        { key: 'attachment.knowledgeArticleId' },
        {
          key: 'attachment.knowledgeArticle',
          type: 'core-portal-entity-select',
          wrappers: ['core-portal-translated', 'core-portal-readonly'],
          className: 'col-md-12',
          defaultValue: null,
          templateOptions: {
            corePortalTranslated: {
              label: 'orga-portal.shared.fields.knowledge-article'
            },
            corePortalReadonly: {
              type: CorePortalFormlyReadonlyTypes.ENTITY,
              displayKey: 'title',
              link: (article: KnowledgeArticleDto) => article?.knowledgeArticleId ? ['/knowledge/articles', article.knowledgeArticleId] : null,
              module: 'communication'
            } as CorePortalFormlyReadonlyTyping,
            entityService: knowledgeArticleService,
            idKey: 'knowledgeArticleId',
            displayKey: 'title',
            wholeObject: true,
            skipGetOne: true,
            clearable: false,
            link: (article: KnowledgeArticleDto) => article?.knowledgeArticleId ? ['/knowledge/articles', article.knowledgeArticleId] : null,
            module: 'communication'
          },
          expressionProperties: {
            'templateOptions.required': () => !this.readonlyFn(),
            'templateOptions.readonly': () => this.readonlyFn(),
            'templateOptions.disabled': () => this.readonlyFn()
          },
          hooks: {
            onInit: field => this.subscribe(field.formControl.valueChanges.pipe(
              startWith(field.formControl.value),
              distinctUntilChanged((a, b) => isEqual(a, b))
            ), value => {
              field.form.get('attachment.knowledgeArticleId').setValue(value?.knowledgeArticleId ?? null);
            })
          },
          hideExpression: (model: AttachmentFormFieldDto) => model.attachment?.type !== AttachmentTypes.Knowledge
        },

        // Link
        {
          key: 'attachment.name',
          type: 'input',
          wrappers: ['core-portal-translated', 'core-portal-readonly'],
          className: 'col-md-6',
          templateOptions: {
            corePortalTranslated: {
              label: 'core-shared.shared.fields.name',
              validationMessages: {
                required: 'core-portal.core.validation.required'
              }
            },
            corePortalReadonly: {
              type: CorePortalFormlyReadonlyTypes.BASIC
            } as CorePortalFormlyReadonlyTyping,
            type: 'text'
          },
          expressionProperties: {
            'className': (model: LocalFormFieldDto) => model.width <= 3 ? 'col-md-12' : 'col-md-6',
            'templateOptions.required': () => !this.readonlyFn(),
            'templateOptions.disabled': () => this.readonlyFn(),
            'templateOptions.readonly': () => this.readonlyFn()
          },
          hideExpression: (model: AttachmentFormFieldDto) => model.attachment?.type !== AttachmentTypes.Link
        },
        {
          key: 'attachment.link',
          type: 'input',
          wrappers: ['core-portal-translated', 'core-portal-readonly'],
          className: 'col-md-6',
          templateOptions: {
            corePortalTranslated: {
              label: 'core-shared.shared.fields.link',
              validationMessages: {
                required: 'core-portal.core.validation.required',
                url: 'core-portal.core.validation.url'
              }
            },
            corePortalReadonly: {
              type: CorePortalFormlyReadonlyTypes.BASIC
            } as CorePortalFormlyReadonlyTyping,
            type: 'text'
          },
          expressionProperties: {
            'className': (model: LocalFormFieldDto) => model.width <= 3 ? 'col-md-12' : 'col-md-6',
            'templateOptions.required': () => !this.readonlyFn(),
            'templateOptions.disabled': () => this.readonlyFn(),
            'templateOptions.readonly': () => this.readonlyFn()
          },
          validators: {
            url: ctrl => Validators.pattern(/^(https:\/\/)(www\.)?.+$/)(ctrl) ? null : { url: true }
          },
          hideExpression: (model: AttachmentFormFieldDto) => model.attachment?.type !== AttachmentTypes.Link
        },

        // Folder
        {
          key: 'attachment.path',
          type: 'orga-portal-folder-select',
          wrappers: ['core-portal-translated'],
          className: 'col-md-12',
          templateOptions: {
            corePortalTranslated: {
              label: 'core-shared.shared.fields.folder'
            }
          },
          expressionProperties: {
            'templateOptions.required': () => !this.readonlyFn(),
            'templateOptions.disabled': () => this.readonlyFn()
          },
          hideExpression: (model: AttachmentFormFieldDto) => model.attachment?.type !== AttachmentTypes.Folder
        }
      ]
    });
  }

  public searchList(service: CorePortalSearchListsService): FormlyFieldConfig[] {
    return this.default({
      noDefault: true,
      noRequired: false,
      prependFields: [
        { key: 'searchListId' },
        {
          key: 'searchList',
          type: 'core-portal-entity-select',
          wrappers: ['core-portal-translated', 'core-portal-readonly'],
          className: 'col-md-12',
          defaultValue: null,
          templateOptions: {
            corePortalTranslated: {
              label: 'core-portal.settings.subtitles.search-list-detail'
            },
            corePortalReadonly: {
              type: CorePortalFormlyReadonlyTypes.ENTITY,
              displayKey: 'title',
              link: (list: SearchListSimpleDto) => list?.searchListId ? ['search-lists', list.searchListId] : null
            } as CorePortalFormlyReadonlyTyping,
            entityService: service,
            idKey: 'searchListId',
            displayKey: 'title',
            wholeObject: true,
            skipGetOne: true,
            clearable: false,
            link: (list: SearchListSimpleDto) => list?.searchListId ? ['search-lists', list.searchListId] : null
          },
          expressionProperties: {
            'templateOptions.required': () => !this.readonlyFn(),
            'templateOptions.readonly': () => this.readonlyFn(),
            'templateOptions.disabled': () => this.readonlyFn()
          },
          hooks: {
            onInit: field => this.subscribe(field.formControl.valueChanges.pipe(
              startWith(field.formControl.value),
              distinctUntilChanged((a, b) => isEqual(a, b))
            ), value => {
              field.form.get('searchListId').setValue(value?.searchListId ?? null);
            })
          }
        },
      ]
    });
  }

  private getSharedTextConfig(): Partial<FormFieldOptions> {
    return {
      validationMessages: {
        minLength: {
          key: 'core-portal.core.validation.min-length',
          args: { minLength: null }
        },
        maxLength: {
          key: 'core-portal.core.validation.max-length',
          args: { maxLength: null }
        }
      },
      expressionProperties: {
        'templateOptions.corePortalTranslated.validationMessages.minLength.args': model => ({ minLength: model?.minLength }),
        'templateOptions.corePortalTranslated.validationMessages.maxLength.args': model => ({ maxLength: model?.maxLength })
      },
      validators: {
        minLength: (ctrl, field) => {
          const minLength = field.form.controls.minLength?.value ?? null;
          return ctrl.value?.length && isNumber(minLength) ? ctrl.value?.length >= minLength : true;
        },
        maxLength: (ctrl, field) => {
          const maxLength = field.form.controls.maxLength?.value ?? null;
          return ctrl.value?.length && isNumber(maxLength) ? ctrl.value?.length <= maxLength : true;
        }
      },
      hooks: {
        onInit: field => this.updateFieldHook(field, ['minLength', 'maxLength'])
      },
      additionalFields: [
        {
          key: 'minLength',
          type: 'input',
          wrappers: ['core-portal-translated', 'core-portal-readonly'],
          className: 'col-md-6',
          templateOptions: {
            corePortalTranslated: {
              label: 'core-shared.shared.fields.min-length',
              validationMessages: {
                min: {
                  key: 'core-portal.core.validation.min',
                  args: { min: 0 }
                }
              }
            },
            corePortalReadonly: {
              type: CorePortalFormlyReadonlyTypes.BASIC
            } as CorePortalFormlyReadonlyTyping,
            type: 'number'
          },
          expressionProperties: {
            'className': (model: LocalFormFieldDto) => model.width <= 3 ? 'col-md-12' : 'col-md-6',
            'templateOptions.disabled': () => this.readonlyFn(),
            'templateOptions.readonly': () => this.readonlyFn()
          },
          hooks: {
            onInit: field => this.updateFieldHook(field, ['defaultValues.0.value', 'maxLength'])
          }
        },
        {
          key: 'maxLength',
          type: 'input',
          wrappers: ['core-portal-translated', 'core-portal-readonly'],
          className: 'col-md-6',
          templateOptions: {
            corePortalTranslated: {
              label: 'core-shared.shared.fields.max-length',
              validationMessages: {
                min: {
                  key: 'core-portal.core.validation.min',
                  args: { min: null }
                }
              }
            },
            corePortalReadonly: {
              type: CorePortalFormlyReadonlyTypes.BASIC
            } as CorePortalFormlyReadonlyTyping,
            type: 'number'
          },
          expressionProperties: {
            'className': (model: LocalFormFieldDto) => model.width <= 3 ? 'col-md-12' : 'col-md-6',
            'templateOptions.disabled': () => this.readonlyFn(),
            'templateOptions.readonly': () => this.readonlyFn(),
            'templateOptions.corePortalTranslated.validationMessages.min.args': model => ({
              min: isFinite(model.minLength) && model.minLength > 1 ? model.minLength : 1
            })
          },
          validators: {
            min: (ctrl, field) => {
              const minLength = field.form.controls.minLength?.value ?? null;
              const validCheck = ctrl.value >= minLength && ctrl.value > 0;
              return !isFinite(ctrl.value) ? true : isFinite(minLength) ? validCheck : ctrl.value > 0;
            }
          },
          hooks: {
            onInit: field => this.updateFieldHook(field, ['defaultValues.0.value', 'minLength'])
          }
        }
      ]
    };
  }

  private defaultValueMinValidator(ctrl: FormControl, field: FormlyFieldConfig, minKey: string = 'minValue'): boolean {
    const minValue = field.form.controls[minKey]?.value ?? null;
    return !isNumber(ctrl.value) ? true : (isNumber(minValue) ? ctrl.value >= minValue : true);
  }

  private defaultValueMaxValidator(ctrl: FormControl, field: FormlyFieldConfig): boolean {
    const maxValue = field.form.controls.maxValue?.value ?? null;
    return !isNumber(ctrl.value) ? true : (isNumber(maxValue) ? ctrl.value <= maxValue : true);
  }

  private updateFieldHook(field: FormlyFieldConfig, fields: string[]): void {
    const fields$: Observable<any>[] = fields.map(x => field.form.get(x)?.valueChanges.pipe(
      distinctUntilChanged((a, b) => isEqual(a, b))
    )).filter(x => Boolean(x));
    this.subscribe(merge(...fields$), () => this.updateFieldValidity(field));
  }

  private updateFieldValidity(field: FormlyFieldConfig): void {
    field.formControl.markAsTouched();
    field.formControl.updateValueAndValidity({ emitEvent: false });
  }
}
