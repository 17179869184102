import {ChangeDetectionStrategy, Component, forwardRef, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {BehaviorSubject, Observable} from 'rxjs';
import {distinctUntilChanged, filter, map} from 'rxjs/operators';
import {UnsubscribeHelper} from '../../helper';
import {isUndefined} from 'lodash';

export interface CoreSharedInputTransformer {
  direction: 'input' | 'output';
  transformFn: (value: any, transformer: CoreSharedInputTransformer,
                connectedTransformer?: CoreSharedInputTransformer) => any;
  connectedTransformerIndex?: number;
  data?: any;
}

@Component({
  selector: 'nexnox-web-input-with-transformers',
  templateUrl: './input-with-transformers.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => CoreSharedInputWithTransformersComponent)
    }
  ]
})
export class CoreSharedInputWithTransformersComponent extends UnsubscribeHelper implements OnInit, ControlValueAccessor {
  @Input() public type = 'text';
  @Input() public hasError: boolean;
  @Input() public transformers: CoreSharedInputTransformer[] = [];

  public value$: Observable<any>;
  public disabled$: Observable<boolean>;

  private valueSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private disabledSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private onChange: any;
  private onTouched: any;

  constructor() {
    super();
  }

  public ngOnInit(): void {
    this.value$ = this.valueSubject.asObservable().pipe(
      distinctUntilChanged(),
      map(value => {
        for (const transformer of this.transformers.filter(x => x.direction === 'input')) {
          const transformerIndex = transformer.connectedTransformerIndex;
          value = transformer.transformFn(
            value,
            transformer,
            !isUndefined(transformerIndex) ? this.transformers[transformerIndex] : undefined
          );
        }

        return value;
      })
    );
    this.disabled$ = this.disabledSubject.asObservable();

    this.subscribe(this.value$.pipe(
      filter(() => this.onChange && this.onTouched)
    ), value => {
      for (const transformer of this.transformers.filter(x => x.direction === 'output')) {
        const transformerIndex = transformer.connectedTransformerIndex;
        value = transformer.transformFn(
          value,
          transformer,
          !isUndefined(transformerIndex) ? this.transformers[transformerIndex] : undefined
        );
      }

      this.onChange(value);
      this.onTouched();
    });
  }

  public onValueChange(value: any): void {
    this.valueSubject.next(value);
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public writeValue(value: any): void {
    this.valueSubject.next(value);
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabledSubject.next(isDisabled);
  }
}
