<ng-template #entityEditTemplate>
  <nexnox-web-missions-mission-report-edit #modelComponent
                                           (modelChange)="onModelChange($event)"
                                           [creating]="false"
                                           [headerActions]="editHeaderActions"
                                           [loading]="loading$ | async"
                                           [model]="model$ | async"
                                           [readonly]="readonly$ | async"
                                           [stereotypes$]="stereotypes$"
                                           [title]="'missions.subtitles.mission-report' | translate">
  </nexnox-web-missions-mission-report-edit>
</ng-template>

<nexnox-web-entity-detail *ngIf="reportId; else promptCreateReportTemplate"
                          [entityDetailBaseComponent]="this"
                          [entityEditTemplate]="entityEditTemplate"
                          [hideOverflow]="false"
                          [useTabs]="false">
</nexnox-web-entity-detail>

<ng-template #promptCreateReportTemplate>
  <div class="min-h-100">
    <div *ngIf="(creatingSubject | async) === false; else createReportTemplate"
         class="d-flex w-100 h-100 align-items-center justify-content-center flex-column py-5">
      <fa-icon [icon]="faTimes" class="d-flex justify-content-center mb-2" size="5x"></fa-icon>
      <h4 class="d-flex justify-content-center">{{ 'missions.no-report.title' | translate }}</h4>
      <h6 class="d-flex justify-content-center">{{ 'missions.no-report.subtitle' | translate }}</h6>

      <button (click)="onCreateMode()"
              *ngIf="(readonly$ | async) === false"
              [label]="'missions.subtitles.mission-report-create' | translate"
              class="p-button-secondary p-button-outlined"
              pButton
              type="button">
      </button>
    </div>
  </div>
</ng-template>

<ng-template #createReportTemplate>
  <nexnox-web-missions-mission-report-edit #createComponent
                                           (modelChange)="createModelSubject.next($event)"
                                           [creating]="true"
                                           [headerActions]="createHeaderActions"
                                           [loading]="loading$ | async"
                                           [model]="createModelSubject | async"
                                           [readonly]="false"
                                           [stereotypes$]="stereotypes$"
                                           [title]="'missions.subtitles.mission-report-create' | translate">
  </nexnox-web-missions-mission-report-edit>
</ng-template>
