import {
  BaseXsStore,
  BaseXsStoreReducerTypes,
  createEntityXsStoreActions,
  EntityXsStore,
  EntityXsStoreEffects,
  entityXsStoreSetLoadingForAction,
  EntityXsStoreState
} from '@nexnox-web/core-store';
import {AppEntityType, CoreSharedApiBaseService, DeviceDto} from '@nexnox-web/core-shared';
import {DeviceDetailXsStoreActions} from './device-detail-xs-store.actions';
import {Action, createAction, select} from '@ngrx/store';
import {Injectable, Injector, Type} from '@angular/core';
import {createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap, tap, withLatestFrom} from 'rxjs/operators';
import {OrgaPortalFeatureDeviceService} from '../../services';
import {of} from 'rxjs';
import {immerOn} from 'ngrx-immer/store';

export interface DeviceDetailXsStoreState extends EntityXsStoreState<DeviceDto> {
}

export class DeviceDetailXsStore extends EntityXsStore<DeviceDto, DeviceDto, DeviceDetailXsStoreState> {
  public actions: DeviceDetailXsStoreActions;

  protected createActions(label: string): DeviceDetailXsStoreActions {
    return {
      ...createEntityXsStoreActions(label),

      reboot: createAction(BaseXsStore.getType(label, 'Reboot')),
      rebootSuccess: createAction(BaseXsStore.getType(label, 'Reboot success'))
    };
  }

  protected createReducerArray(
    initialState: DeviceDetailXsStoreState
  ): BaseXsStoreReducerTypes<DeviceDetailXsStoreState, DeviceDetailXsStoreActions>[] {
    return [
      ...super.createReducerArray(initialState),

      immerOn(this.actions.reboot, draft => {
        draft.entityData = entityXsStoreSetLoadingForAction(draft.entityData, {
          reboot: true
        });
      }),
      immerOn(this.actions.rebootSuccess, draft => {
        draft.entityData = entityXsStoreSetLoadingForAction(draft.entityData, {
          reboot: false
        });
      })
    ];
  }

  protected createEffects(
    serviceType: Type<CoreSharedApiBaseService>,
    entityType: AppEntityType,
    prepareEntity: (entity: DeviceDto) => DeviceDto,
    prepareModel: (entity: DeviceDto, model: DeviceDto) => DeviceDto,
    sanitizeModel: (model: DeviceDto, entity: DeviceDto) => DeviceDto,
    ...args
  ): Type<EntityXsStoreEffects<DeviceDto, DeviceDto, DeviceDetailXsStoreState>> {
    const actions = this.actions;
    const selectors = this.selectors;

    @Injectable()
    class Effects extends EntityXsStoreEffects<DeviceDto> {
      public reboot$: any;
      public rebootSuccess$: any;

      protected actions: DeviceDetailXsStoreActions;
      protected service: OrgaPortalFeatureDeviceService;

      constructor(
        protected injector: Injector
      ) {
        super(injector, actions, selectors, serviceType, entityType, prepareEntity, prepareModel, sanitizeModel, false, false);
      }

      protected createEffects(): void {
        super.createEffects();

        this.reboot$ = createEffect(() => this.actions$.pipe(
          ofType(this.actions.reboot),
          withLatestFrom(this.store.pipe(select(this.selectors.selectEntity))),
          switchMap(([_, entity]) => this.service.reboot(entity.id).pipe(
            map(() => this.actions.rebootSuccess()),
            catchError(error => of(this.actions.error({ error, action: this.actions.reboot })))
          ))
        ));

        this.rebootSuccess$ = createEffect(() => this.actions$.pipe(
          ofType(this.actions.rebootSuccess),
          tap(action => this.actionCallback(action, false))
        ), { dispatch: false });
      }

      protected actionCallback(action: Action, isError: boolean): void {
        super.actionCallback(action, isError);

        this.checkAction(this.actions.rebootSuccess, action, () => this.rebootSuccess());
      }

      protected rebootSuccess(): void {
        this.apiNotificationService.showTranslatedSuccess('orga-portal.devices.toasts.device-rebooted');
      }
    }

    return Effects;
  }
}
