import {Component} from '@angular/core';
import {FieldType, FormlyTemplateOptions} from '@ngx-formly/core';
import {CoreSharedDateTimePickerRelativeTimeType} from '@nexnox-web/core-shared';

export interface CorePortalFormlyDateTimePickerTyping {
  canClear?: boolean;
  enabledRelativeTimes?: CoreSharedDateTimePickerRelativeTimeType[];
  min?: string;
  max?: string;
}

interface FormlyDateTimePickerOptions extends FormlyTemplateOptions {
  corePortalDateTimePicker?: CorePortalFormlyDateTimePickerTyping;
}

@Component({
  selector: 'nexnox-web-formly-date-time-picker',
  templateUrl: './formly-date-time-picker.component.html'
})
export class FormlyDateTimePickerComponent extends FieldType {
  public to: FormlyDateTimePickerOptions;
}
