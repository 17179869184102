import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  Input,
  QueryList,
  ViewChild
} from '@angular/core';
import {TabTemplateDirective} from '../../directives';
import {TabsetComponent} from 'ngx-bootstrap/tabs';
import {Observable} from 'rxjs';
import {delay, filter, take} from 'rxjs/operators';

@Component({
  selector: 'nexnox-web-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TabsComponent implements AfterViewInit {
  @Input() public internal = false;
  @Input() public startTab: string;
  @Input() public isTransparent = false;
  @Input() public ready$: Observable<any>;

  @ViewChild('tabsetComponent', { static: true }) public tabsetComponent: TabsetComponent;

  @ContentChildren(TabTemplateDirective) public tabTemplates: QueryList<TabTemplateDirective>;

  public ngAfterViewInit(): void {
    if (this.internal && this.startTab) {
      if (this.ready$) {
        this.ready$.pipe(
          filter(x => Boolean(x)),
          take(1),
          delay(1)
        ).subscribe(() => this.activateTab(this.startTab));
      } else {
        this.activateTab(this.startTab);
      }
    }
  }

  public onSelectTab(tabTemplate: TabTemplateDirective): void {
    if (this.internal) {
      this.activateTab(tabTemplate.tabId);
      return;
    }

    tabTemplate.wasActive = true;
    tabTemplate.selectTab?.emit();
  }

  public activateTab(id: string): void {
    setTimeout(() => {
      const tab = this.tabsetComponent?.tabs?.find(x => x.id === id);

      if (tab) {
        tab.active = true;

        const tabTemplate = this.tabTemplates?.find(x => x.tabId === id);
        if (tabTemplate) {
          tabTemplate.wasActive = true;
        }
      }
    });
  }

  public isTabActive(id: string): boolean {
    return Boolean(this.tabsetComponent?.tabs?.find(x => x.id === id)?.active);
  }
}
