<ng-container *ngIf="trigger$ | async as trigger">
  <nexnox-web-settings-notification-rules-restriction-mission-edit
    (restrictionChange)="restrictionChanged.emit($event)"
    *ngIf="isMissionRestriction"
    [canCreate]="false"
    [canRouteToDetail]="false"
    [creating]="creating"
    [isEmbedded]="true"
    [loading]="loading"
    [readonly]="readonly"
    [restriction]="restriction$ | async">
  </nexnox-web-settings-notification-rules-restriction-mission-edit>

  <nexnox-web-settings-notification-rules-restriction-ticket-edit
    (restrictionChange)="restrictionChanged.emit($event)"
    *ngIf="isTicketRestriction"
    [canCreate]="false"
    [canRouteToDetail]="false"
    [creating]="creating"
    [isEmbedded]="true"
    [loading]="loading"
    [readonly]="readonly"
    [restriction]="restriction$ | async">
  </nexnox-web-settings-notification-rules-restriction-ticket-edit>

  <nexnox-web-settings-notification-rules-restriction-contract-edit
    (restrictionChange)="restrictionChanged.emit($event)"
    *ngIf="isContractRestriction"
    [canCreate]="false"
    [canRouteToDetail]="false"
    [creating]="creating"
    [isEmbedded]="true"
    [loading]="loading"
    [readonly]="readonly"
    [restriction]="restriction$ | async">
  </nexnox-web-settings-notification-rules-restriction-contract-edit>

  <nexnox-web-settings-notification-rules-restriction-info-edit
    (restrictionChange)="restrictionChanged.emit($event)"
    *ngIf="isInfoRestriction"
    [canCreate]="false"
    [canRouteToDetail]="false"
    [creating]="creating"
    [isEmbedded]="true"
    [loading]="loading"
    [readonly]="readonly"
    [restriction]="restriction$ | async">
  </nexnox-web-settings-notification-rules-restriction-info-edit>
</ng-container>
