import {HttpClient} from '@angular/common/http';
import {HttpAuthorizedHandler} from '../handlers';
import {Injectable} from '@angular/core';

@Injectable()
export class HttpAuthorizedClient extends HttpClient {
  constructor(
    private httpAuthorizedHandler: HttpAuthorizedHandler
  ) {
    super(httpAuthorizedHandler);
  }
}
