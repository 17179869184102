import {InjectionToken, NgModule} from '@angular/core';
import {ActionReducerMap, StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {prioritySetsEffects, prioritySetsReducers} from './store';

export const REDUCER_TOKEN = new InjectionToken<ActionReducerMap<any>>(
  '@nexnox-web/tech-portal/ticket-settings/priority-sets: Store Reducers'
);

@NgModule({
  imports: [
    StoreModule.forFeature('tech-portal-feature-ticket-settings-priority-sets', REDUCER_TOKEN),
    EffectsModule.forFeature(prioritySetsEffects)
  ],
  providers: [
    {
      provide: REDUCER_TOKEN,
      useValue: prioritySetsReducers
    }
  ]
})
export class PrioritySetsStoreModule {
}
