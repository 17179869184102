import {DashboardXsStore, DashboardXsStoreState} from './dashboard.xs-store';
import {createSelector} from '@ngrx/store';
import {selectDashboardState} from '../../dashboard.selector';

export interface DashboardStoreState extends DashboardXsStoreState {
}

/* istanbul ignore next */
export const dashboardStore = new DashboardXsStore({
  actionLabel: 'Core Portal - Dashboard - Dashboard',
  stateSelector: createSelector(selectDashboardState, state => state.dashboard)
});
