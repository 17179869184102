<div #boundingElement *ngIf="!loading; else loadingTemplate" class="w-100 tree-view">
  <ng-container *ngFor="let item of treeViewItems; trackBy: trackItemsById">
    <nexnox-web-hierarchical-tree-view-item #hierarchicalTreeViewItemComponent
                                            (expandItem)="onExpandItem($event)"
                                            (moreItem)="onMoreItem($event)"
                                            (selectItem)="onSelectItem($event)"
                                            [boundingElement]="boundingElement"
                                            [canSelectItem]="canSelectItem"
                                            [disabled]="disabled"
                                            [itemSelectedEvent]="itemSelectedEvent"
                                            [item]="item"
                                            [nestLevel]="0">
    </nexnox-web-hierarchical-tree-view-item>
  </ng-container>

  <ng-container *ngIf="paging && paging.pageNumber < paging.totalPages">
    <div (click)="onMore()" *ngIf="!nextLoading; else moreLoadingTemplate"
         class="more-btn">
      <fa-icon [icon]="faPlus" class="d-flex align-items-center"></fa-icon>
      <span class="ml-2">{{ 'core-shared.shared.pagination.more' | translate }}</span>
    </div>
  </ng-container>
</div>

<ng-template #loadingTemplate>
  <nexnox-web-ghost-hierarchical-tree-view></nexnox-web-ghost-hierarchical-tree-view>
</ng-template>

<ng-template #moreLoadingTemplate>
  <nexnox-web-ghost-hierarchical-tree-view-children></nexnox-web-ghost-hierarchical-tree-view-children>
</ng-template>
