import {InjectionToken, NgModule} from '@angular/core';
import {ActionReducerMap, StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {devicesEffects, devicesReducers} from './store';

export const REDUCER_TOKEN = new InjectionToken<ActionReducerMap<any>>(
  '@nexnox-web/orga-portal/devices: Store Reducers'
);

@NgModule({
  imports: [
    StoreModule.forFeature('orga-portal-feature-devices', REDUCER_TOKEN),
    EffectsModule.forFeature(devicesEffects)
  ],
  providers: [
    {
      provide: REDUCER_TOKEN,
      useValue: devicesReducers
    }
  ]
})
export class DevicesStoreModule {
}
