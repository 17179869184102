import {ChangeDetectionStrategy, Component, Injector} from '@angular/core';
import {
  ActionButton,
  CorePortalEntityOverviewBaseComponent,
  DatatableActionButton,
  DeleteEntityModel
} from '@nexnox-web/core-portal';
import {AppEntityType, AppPermissions, ControllerOperationId, LocationGroupDto, Mappers} from '@nexnox-web/core-shared';
import {locationGroupListStore} from '../../store';

@Component({
  selector: 'nexnox-web-master-data-location-groups-location-group-list',
  templateUrl: './location-group-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LocationGroupListComponent extends CorePortalEntityOverviewBaseComponent<LocationGroupDto> {
  public title = 'core-portal.master-data.subtitles.location-group-list';
  public createTitle = 'core-portal.master-data.subtitles.location-group-create';
  public idProperty = 'locationGroupId';
  public displayProperty = 'name';
  public datatableConfigName = 'MasterDataLocationGroupDefault';
  public componentId = 'LocationGroupListComponent';

  public pageOperation = ControllerOperationId.LocationGroupControllerList;
  public enableViews = true;

  constructor(
    protected injector: Injector
  ) {
    super(injector, locationGroupListStore, Mappers.LocationGroupListDto.serializedName, AppEntityType.LocationGroup);
  }

  public getRowActionButtons(): DatatableActionButton[] {
    return this.getDefaultRowActionButtons(
      'core-portal.master-data.actions.edit-location-group',
      (row: LocationGroupDto) => `/masterdata/locationgroups/${ row.locationGroupId }`,
      [AppPermissions.UpdateLocationGroup],
      {
        module: 'management'
      }
    );
  }

  public getDeleteEntityModel(): DeleteEntityModel {
    return {
      titleKey: 'core-portal.master-data.actions.delete-location-group',
      descriptionKey: 'core-portal.master-data.descriptions.delete-location-group',
      confirmKey: 'core-portal.master-data.actions.delete-location-group',
      deletePermission: AppPermissions.DeleteLocationGroup
    };
  }

  protected async getActionButtons(): Promise<ActionButton[]> {
    return this.getDefaultActionButtons('core-portal.master-data.actions.create-location-group', AppPermissions.CreateLocationGroup);
  }
}
