<p-sidebar #sidebarComponent
           (visibleChange)="onVisibleChange($event)"
           [transitionOptions]="transitionOptions"
           [visible]="visible$ | async"
           appendTo="body"
           position="right"
           styleClass="p-sidebar-md">
  <div *ngIf="(loading$ | async) === false; else loadingTemplate"
       class="d-flex align-content-between flex-column h-100">

    <h4 class="pl-2">{{ 'core-portal.settings.descriptions.merge-manufacturer' | translate }}</h4>

    <div class="row no-gutters w-100">
      <form [formGroup]="form" class="w-100">
        <formly-form [(model)]="model"
                     [fields]="fields"
                     [form]="form"
                     class="row w-100">
        </formly-form>
        <small class="form-text text-muted mx-2">{{ descriptionTranslation$ | async }}</small>
      </form>
    </div>

    <div class="w-100 d-flex flex-shrink-1 gap-2 justify-content-end mt-auto mb-0">
      <button (click)="onHide()"
              [label]="'core-portal.core.general.cancel' | translate"
              class="p-button p-button-secondary"
              pButton
              type="button">
      </button>
      <button (click)="onAccept()"
              [disabled]="!form.valid"
              [label]="'core-portal.settings.actions.merge-manufacturer'| translate"
              class="p-button p-button-danger"
              pButton
              type="button">
      </button>
    </div>
  </div>
</p-sidebar>

<ng-template #loadingTemplate>
  <nexnox-web-loading-icon class="d-flex w-100 h-100 align-items-center justify-content-center flex-column p-5"
                           size="5x">
  </nexnox-web-loading-icon>
</ng-template>

<ng-template #destinationTemplate let-item="item">
  <span *ngIf="item.emailAddress">&nbsp;<{{ item.emailAddress }}></span>
</ng-template>
