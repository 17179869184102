import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SidebarItem} from '../../models';
import {AppPermissions, UnsubscribeHelper} from '@nexnox-web/core-shared';
import {faChevronDown} from '@fortawesome/free-solid-svg-icons/faChevronDown';
import {sidebarItemAnimations} from './sidebar-item.animations';
import {faChevronUp} from '@fortawesome/free-solid-svg-icons/faChevronUp';
import {BehaviorSubject, combineLatest, Observable, of} from 'rxjs';
import {distinctUntilChanged, map, switchMap} from 'rxjs/operators';
import {CorePortalTenantRouter} from '../../../../router-overrides/';
import {BindObservable} from 'bind-observable';

@Component({
  selector: 'nexnox-web-sidebar-item',
  templateUrl: './sidebar-item.component.html',
  styleUrls: ['./sidebar-item.component.scss'],
  animations: sidebarItemAnimations
})
export class SidebarItemComponent extends UnsubscribeHelper implements OnInit {
  @Input() @BindObservable() public item: SidebarItem;
  public item$!: Observable<SidebarItem>;

  @Input() public parent: SidebarItemComponent;
  @Input() public level = 0;
  @Input() public checkPermissionFn: (permission: AppPermissions) => Observable<boolean>;
  @Input() public moduleIsAnimating$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  @Output() public itemClicked: EventEmitter<boolean> = new EventEmitter<boolean>();

  public hasPermission$: Observable<boolean>;
  public childrenVisible$: Observable<boolean>;
  public isItemVisible$: Observable<boolean>;

  public trackChildrenBy: any;

  public faChevronDown = faChevronDown;
  public faChevronUp = faChevronUp;

  constructor(
    private tenantRouter: CorePortalTenantRouter
  ) {
    super();
  }

  public ngOnInit(): void {

    this.hasPermission$ = this.item$.pipe(
      switchMap(item => item.permission ? this.checkPermissionFn(item.permission) : of(true))
    );
    this.childrenVisible$ = this.item$.pipe(
      switchMap(item => this.isItemVisible(item))
    );

    this.isItemVisible$ = this.item$.pipe(
      switchMap(item => this.hasPermission$.pipe(
        switchMap(hasPermission => this.childrenVisible$.pipe(
          map(childrenVisible => item && hasPermission && childrenVisible)
        ))
      ))
    );

    this.trackChildrenBy = (index: number, item: SidebarItem) => `${ item.module }-${ item.path }`;

    this.subscribe(this.item$.pipe(distinctUntilChanged()), item => {
      if (this.tenantRouter.isActive(item.path, false)) {
        this.bubbleExpand();
      }
    });
  }

  public toggleExpanded(event: MouseEvent): void {
    if (this.item.children?.length) {
      event.preventDefault();
      event.stopPropagation();
      event.stopImmediatePropagation();
    }
    this.item.expanded = !this.item.expanded;
  }

  public bubbleExpand(): void {
    this.item.expanded = true;

    this.parent?.bubbleExpand();
  }

  public onItemClicked(isRouteChange: boolean, event?: MouseEvent): void {
    if (isRouteChange) {
      this.itemClicked.emit(isRouteChange);
    }
    if (event) {
      this.toggleExpanded(event);
    }
  }

  private isItemVisible(item: SidebarItem): Observable<boolean> {
    if (!item.children?.length) {
      return item.permission ? this.checkPermissionFn(item.permission) : of(true);
    }

    return combineLatest(item.children.map(child => this.isItemVisible(child))).pipe(
      map(itemsVisible => itemsVisible.some(x => x))
    );
  }
}
