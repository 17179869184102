import {Directive, TemplateRef} from '@angular/core';

@Directive({
  selector: '[nexnoxWebSortableListItemTemplate]'
})
export class CoreSharedSortableListItemTemplateDirective {
  constructor(
    public template: TemplateRef<any>
  ) {
  }
}
