import {NgModule} from '@angular/core';
import {CoreSharedModule} from '@nexnox-web/core-shared';
import {CorePortalEntityModule, CorePortalModule} from '@nexnox-web/core-portal';
import {infosServices} from './store';
import {OrgaPortalFeatureInfoEditComponent} from './components';
import {
  InfoDetailComponent,
  InfoListComponent,
  InfoProgressComponent,
  InfoProgressContactListComponent,
  InfoProgressContactReadUnreadListComponent,
  InfoProgressFunctionListComponent,
  InfoProgressLocationListComponent
} from './containers';
import {CorePortalFeatureMasterDataLocationsModule} from '@nexnox-web/core-portal/features/master-data/features/locations';
import {CorePortalFeatureMasterDataLocationGroupsModule} from '@nexnox-web/core-portal/features/master-data/features/location-groups';
import {OrgaPortalModule} from '@nexnox-web/orga-portal-lib';
import {OrgaPortalFeatureCategoriesModule} from '@nexnox-web/orga-portal/features/categories';

import {InputTextModule} from "primeng/inputtext";
import {InfoProgressSidebarComponent} from "./sidebars";

@NgModule({
  declarations: [
    OrgaPortalFeatureInfoEditComponent,

    InfoListComponent,
    InfoDetailComponent,

    InfoProgressContactListComponent,
    InfoProgressContactReadUnreadListComponent,
    InfoProgressComponent,
    InfoProgressFunctionListComponent,
    InfoProgressLocationListComponent,
    InfoProgressSidebarComponent
  ],
  exports: [
    OrgaPortalFeatureInfoEditComponent
  ],
  imports: [
    CoreSharedModule,
    CorePortalEntityModule,

    OrgaPortalModule,
    OrgaPortalFeatureCategoriesModule.forFeature(),
    CorePortalFeatureMasterDataLocationsModule.forFeature(),
    CorePortalFeatureMasterDataLocationGroupsModule.forFeature(),
    CorePortalModule,
    InputTextModule
  ],
  providers: [
    ...infosServices
  ]
})
export class InfosDeclarationsModule {
}
