import {hintCodeDetailStore, HintCodeDetailStoreState, hintCodeListStore, HintCodeListStoreState} from './stores';

export interface HintCodesState {
  hintCodeList: HintCodeListStoreState;
  hintCodeDetail: HintCodeDetailStoreState;
}

export const getInitialHintCodesState = (): HintCodesState => ({
  hintCodeList: hintCodeListStore.getInitialState(),
  hintCodeDetail: hintCodeDetailStore.getInitialState()
});
