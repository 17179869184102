import {
  inventoryNumberDetailStore,
  InventoryNumberDetailStoreState,
  inventoryNumberListStore,
  InventoryNumberListStoreState,
  reservationListStore,
  ReservationListStoreState
} from './stores';

export interface InventoryNumbersState {
  inventoryNumberList: InventoryNumberListStoreState;
  inventoryNumberDetail: InventoryNumberDetailStoreState;
  reservationList: ReservationListStoreState;
}

export const getInitialInventoryNumbersState = (): InventoryNumbersState => ({
  inventoryNumberList: inventoryNumberListStore.getInitialState(),
  inventoryNumberDetail: inventoryNumberDetailStore.getInitialState(),
  reservationList: reservationListStore.getInitialState()
});
