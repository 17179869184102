<p-overlayPanel #resourceChildrenPanel
                [focusOnShow]="true"
                [showCloseIcon]="true"
                appendTo="body"
                styleClass="children-popover">
  <ng-template pTemplate>
    <p-tree (onNodeCollapse)="onCollapse($event.node)"
            (onNodeExpand)="onExpand($event.node)"
            (onNodeSelect)="onSelect($event.node)"
            [emptyMessage]="'core-shared.shared.table.empty' | translate"
            [loadingIcon]="faSpinner | nexnoxWebFaIconStringPipe:'fa-spin'"
            [loading]="!(nodes$ | async)?.length"
            [value]="nodes$ | async"
            selectionMode="single">
      <ng-template pTemplate="more">
        <b>{{ 'core-shared.shared.pagination.more' | translate }}</b>
      </ng-template>
    </p-tree>
  </ng-template>
</p-overlayPanel>

<a (click)="resourceChildrenPanel.show($event)"
   *ngIf="(resource$ | async)?.hasChildren && !readonly"
   role="button">
  {{ 'resources.actions.show-children' | translate }}
</a>
