import {Inject, Injectable} from '@angular/core';
import {Environment, Product} from '../../../models';
import {CORE_SHARED_ENVIRONMENT} from '../../../tokens';
import {HttpApiClient, HttpAuthorizedClient} from '../clients';
import {CoreSharedHttpRequestService} from '../http-request/http-request.service';

@Injectable()
export class CoreSharedHttpOrgaRequestService extends CoreSharedHttpRequestService {
  constructor(
    protected httpAuthorizedClient: HttpAuthorizedClient,
    protected httpApiClient: HttpApiClient,
    @Inject(CORE_SHARED_ENVIRONMENT) protected environment: Environment
  ) {
    super(httpAuthorizedClient, httpApiClient, Product.ORGA, environment);
  }

  protected getDefaultUrl(): string {
    return this.environment?.apiUrl?.orga;
  }
}
