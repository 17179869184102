import {DashboardItemEntityActionsFacade} from '@nexnox-web/core-portal/features/dashboard';
import {
  TechPortalFeatureTicketActionsFacade,
  TechPortalFeatureTicketActionsFacadeAssignToMePayload,
  TechPortalFeatureTicketActionsFacadeAssignToPayload,
  TechPortalFeatureTicketActionsFacadeChangeStatePayload,
  TechPortalFeatureTicketActionsFacadeExportPayload
} from '../ticket-actions';
import {TechPortalFeatureDashboardTicketDatatableStore} from './dashboard-ticket.datatable-store';

export class TechPortalFeatureDashboardTicketActionsFacade extends DashboardItemEntityActionsFacade
  implements TechPortalFeatureTicketActionsFacade {
  protected datatableStore: TechPortalFeatureDashboardTicketDatatableStore;

  public assignTo({ id, parentIds, contact }: TechPortalFeatureTicketActionsFacadeAssignToPayload): void {
    this.datatableStore.assignTo(id, contact, parentIds);
  }

  public assignToMe({ id, parentIds }: TechPortalFeatureTicketActionsFacadeAssignToMePayload): void {
    this.datatableStore.assignToMe(id, parentIds);
  }

  public changeState({ id, state, parentIds, reason }: TechPortalFeatureTicketActionsFacadeChangeStatePayload): void {
    this.datatableStore.changeTicketState(id, state, reason, parentIds);
  }

  public export({ id, templateId }: TechPortalFeatureTicketActionsFacadeExportPayload): void {
    this.datatableStore.export(id, templateId);
  }
}
