<ng-template #entityEditTemplate let-title="title">
  <nexnox-web-master-functions-function-edit #editComponent
                                             (modelChange)="createModel$.next($event)"
                                             [creating]="true"
                                             [loading]="(loaded$ | async) === false"
                                             [model]="createModel$ | async"
                                             [readonly]="false"
                                             [stereotypes$]="stereotypes$"
                                             [title]="title">
  </nexnox-web-master-functions-function-edit>
</ng-template>

<nexnox-web-entity-overview #overviewComponent
                            [entityEditTemplate]="entityEditTemplate"
                            [entityOverviewBaseComponent]="this"
                            detailLink="/masterdata/functions"
                            module="management">
</nexnox-web-entity-overview>
