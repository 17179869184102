import {ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {faPlus} from '@fortawesome/free-solid-svg-icons/faPlus';
import {CoreSharedSidebarBaseComponent, FollowUpMissionDto} from '@nexnox-web/core-shared';
import {isEqual} from 'lodash';
import {BehaviorSubject, Observable} from 'rxjs';
import {distinctUntilChanged, map} from 'rxjs/operators';
import {
  CorePortalFormlyReadonlyTypes,
  CorePortalFormlyReadonlyTyping,
  CorePortalFormlyTranslatedTyping,
  CoreSharedRuleEditorListComponent,
  InheritedField
} from '@nexnox-web/core-portal';
import dayjs from 'dayjs';

@Component({
  selector: 'nexnox-web-missions-create-mission-successors-sidebar',
  templateUrl: './create-mission-successors-sidebar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateMissionSuccessorsSidebarComponent extends CoreSharedSidebarBaseComponent implements OnInit {
  @ViewChild('successorsEditComponent') public successorsEditComponent: CoreSharedRuleEditorListComponent;

  @Output() public successorsAdded: EventEmitter<FollowUpMissionDto[]> = new EventEmitter<FollowUpMissionDto[]>();

  public successors$: Observable<FollowUpMissionDto[]>;
  public isValid$: Observable<boolean>;

  public titleFn: (mission: FollowUpMissionDto) => string;
  public fieldsFn: () => InheritedField[];

  public faPlus = faPlus;

  private successorsSubject: BehaviorSubject<FollowUpMissionDto[]> = new BehaviorSubject<FollowUpMissionDto[]>([]);

  constructor() {
    super();

    this.titleFn = (mission: FollowUpMissionDto) => mission.title;
    this.fieldsFn = () => this.createSuccessorsFields();
  }

  public ngOnInit(): void {
    this.successors$ = this.successorsSubject.asObservable().pipe(
      distinctUntilChanged((a, b) => isEqual(a, b))
    );

    this.isValid$ = this.successors$.pipe(
      map(successors => Boolean(successors?.length) && this.successorsEditComponent?.areItemsValid())
    );
  }

  public onHide(): void {
    super.onHide();

    this.ngOnDestroy();
    this.successorsSubject.next([]);
    this.successorsEditComponent?.ngOnInit();
    this.successorsEditComponent?.changeDetector?.detectChanges();
  }

  public onSuccessorsChange(successors: FollowUpMissionDto[]): void {
    this.successorsSubject.next(successors);
  }

  public onAddSuccessors(): void {
    this.successorsAdded.emit(this.successorsSubject.getValue());
    this.onHide();
  }

  /* istanbul ignore next */
  private createSuccessorsFields(): InheritedField[] {
    return [
      {
        formlyConfig: {
          type: 'input',
          wrappers: ['core-portal-translated', 'core-portal-readonly'],
          templateOptions: {
            corePortalTranslated: {
              label: 'core-shared.shared.fields.title',
              validationMessages: {
                required: 'core-portal.core.validation.required'
              }
            } as CorePortalFormlyTranslatedTyping,
            corePortalReadonly: {
              type: CorePortalFormlyReadonlyTypes.BASIC
            } as CorePortalFormlyReadonlyTyping,
            type: 'text',
            required: true
          }
        },
        ownValueKey: 'title',
        smartExpressionProperties: {
          className: (_, __, creating) => !creating ? 'col-12 col-md pl-0 pr-0' : 'col-md-12',
          'templateOptions.readonly': (_, __, creating) => !creating
        }
      },
      {
        formlyConfig: {
          type: 'core-portal-date-time-picker',
          wrappers: ['core-portal-translated', 'core-portal-readonly'],
          templateOptions: {
            corePortalTranslated: {
              label: 'core-shared.shared.fields.planned-start',
              validationMessages: {
                required: 'core-portal.core.validation.required'
              }
            } as CorePortalFormlyTranslatedTyping,
            corePortalReadonly: {
              type: CorePortalFormlyReadonlyTypes.DATE,
              format: 'L LT'
            } as CorePortalFormlyReadonlyTyping,
            required: true
          },
          hooks: {
            onInit: field => this.subscribe(field.formControl.valueChanges.pipe(
              distinctUntilChanged()
            ), (value: string) => {
              const date = dayjs.utc(value);

              if (!value || !date.isValid()) {
                field.form.controls.plannedEnd.setValue(null);
                return;
              }

              if (field.form.controls.plannedEnd.value) return;

              field.form.controls.plannedEnd.setValue(date.format());
            })
          }
        },
        ownValueKey: 'plannedStart',
        smartExpressionProperties: {
          className: (_, __, creating) => !creating ? 'col-12 col-md pl-0 pr-0' : 'col-md-6',
          'templateOptions.readonly': (_, __, creating) => !creating
        }
      },
      {
        formlyConfig: {
          type: 'core-portal-date-time-picker',
          wrappers: ['core-portal-translated', 'core-portal-readonly'],
          className: 'col-md-6',
          templateOptions: {
            corePortalTranslated: {
              label: 'core-shared.shared.fields.planned-end',
              validationMessages: {
                required: 'core-portal.core.validation.required'
              }
            } as CorePortalFormlyTranslatedTyping,
            corePortalReadonly: {
              type: CorePortalFormlyReadonlyTypes.DATE,
              format: 'L LT'
            } as CorePortalFormlyReadonlyTyping,
            required: true
          }
        },
        ownValueKey: 'plannedEnd',
        smartExpressionProperties: {
          className: (_, __, creating) => !creating ? 'col-12 col-md pl-0 pr-0' : 'col-md-6',
          'templateOptions.readonly': (_, __, creating) => !creating
        }
      }
    ];
  }
}
