<ng-sidebar-container contentClass="sidebar__content">
  <ng-sidebar (openedChange)="onOpenedChanged($event)"
              [autoCollapseOnInit]="false"
              [autoCollapseWidth]="1199"
              [closeOnClickOutside]="mode === 'over'"
              [keyClose]="mode === 'over'"
              [mode]="mode"
              [opened]="isOpen"
              sidebarClass="sidebar">

    <!-- container -->
    <div class="d-flex max-h-[100%] h-100 flex-column">

      <!-- header -->
      <div class="header flex-shrink-0 d-flex flex-column">
        <div *ngIf="showTrialVersionBanner$ | async" class="trial-flag small">
          <span class="trial-version">{{ 'core-shared.shared.fields.subscription' | translate }}</span>&nbsp;
          <span>{{ 'core-shared.shared.trial.ends' | translate }} {{ trialVersionExpiry$ | async | nexnoxWebDayjsRelative | async }}</span>
          <span class="small">
            &nbsp; (
            <a class="white" module="settings"
               nexnoxWebTenantRouterLink="/admin/system/general">{{ 'core-shared.shared.actions.show-more' | translate }}</a>
            )
          </span>
        </div>
        <div class="logo flex-grow-1">
          <div class="wrapper">
            <img *ngIf="logoPath" [src]="logoPath" class="img-fluid">
          </div>
        </div>
      </div>

      <!-- modules -->
      <div class="divider-top flex-grow-1 overflow-y-hidden d-flex flex-column">
        <ng-container *ngIf="loggedIn">
          <ng-container *ngIf="sidebarContent$ | async as sidebarContent">
            <ng-container *ngFor="let module of sidebarContent">

              <div *ngIf="!module.hide"
                   [ngClass]="module.expanded ? 'flex-shrink-1' : 'flex-shrink-0'"
                   [style.max-height.px]="module.expanded ? (module.maxHeight + 52) : 50"
                   class="sidebar-item divider-bottom overflow-y-hidden animate-item">

                <div class="sidebar-item__content flex-shrink-0 flex-basis-100">
                  <div (click)="toggleModule(module, sidebarContent)" class="sidebar-item__content__inner-content">
                    <fa-icon [fixedWidth]="true"
                             [icon]="module.icon"
                             class="mr-2"
                             size="lg">
                    </fa-icon>
                    {{ module.name | translate }}
                  </div>
                </div>

                <!-- module sidebar items -->
                <div [class.divider-top]="module.expanded || (isAnimating$.asObservable() | async)"
                     [class.overflow-y-hidden]="isAnimating$.asObservable() | async"
                     class="items flex-shrink-1 w-100 sidebar-scrollbar overflow-y-auto">
                  <nexnox-web-sidebar-item (itemClicked)="onItemClicked($event)"
                                           *ngFor="let item of module.items; trackBy: trackItemsBy;"
                                           [checkPermissionFn]="checkPermissionFn"
                                           [item]="item"
                                           [level]="0"
                                           [moduleIsAnimating$]="isAnimating$">
                  </nexnox-web-sidebar-item>
                </div>
              </div>
            </ng-container>
          </ng-container>
        </ng-container>
      </div>

      <!-- settings menu item -->
      <div *ngIf="settingsItem$ | async as item" class="w-100 align-self-end flex-shrink-0">
        <div class="sidebar-item">
          <a [module]="item.module"
             [nexnoxWebTenantRouterLink]="item.path"
             class="sidebar-item__content"
             nexnoxWebTenantRouterLinkActive="sidebar-item__content--active">
            <div class="sidebar-item__content__inner-content">
              <span class="sidebar-item__content__active"></span>
              <fa-icon [fixedWidth]="true"
                       [icon]="faCog"
                       class="mr-2"
                       size="lg">
              </fa-icon>
              {{ 'core-portal.settings.menu.title' | translate }}
            </div>
          </a>
        </div>
      </div>
    </div>
  </ng-sidebar>

  <div ng-sidebar-content>
    <ng-content></ng-content>
  </div>
</ng-sidebar-container>
