import {ChangeDetectionStrategy, Component, Injector} from '@angular/core';
import {
  ActionButton,
  CorePortalEntityOverviewBaseComponent,
  DatatableActionButton,
  DeleteEntityModel
} from '@nexnox-web/core-portal';
import {
  AppEntityType,
  AppPermissions,
  DeviceCodeChallengeDto,
  DeviceDto,
  DeviceListDto,
  Mappers,
  Toast
} from '@nexnox-web/core-shared';
import {deviceListStore} from '../../store';
import {faSync} from '@fortawesome/free-solid-svg-icons/faSync';
import {faHeart} from '@fortawesome/free-solid-svg-icons/faHeart';
import {PagedEntitiesXsStoreCreateOneSuccessPayload} from '@nexnox-web/core-store';
import {BehaviorSubject} from 'rxjs';
import {BsLocaleService} from "ngx-bootstrap/datepicker";
import dayjs from "dayjs";
import {formatDate} from "ngx-bootstrap/chronos";

@Component({
  selector: 'nexnox-web-devices-device-list',
  templateUrl: './device-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeviceListComponent extends CorePortalEntityOverviewBaseComponent<DeviceDto> {
  public title = 'orga-portal.devices.subtitles.device-list';
  public createTitle = 'orga-portal.devices.actions.create-device';
  public idProperty = 'id';
  public displayProperty = 'name';
  public datatableConfigName = 'DeviceDefault';
  public componentId = 'DeviceListComponent';
  public noRouteToDetail = true;

  public challengesSubject: BehaviorSubject<DeviceCodeChallengeDto[]> = new BehaviorSubject<DeviceCodeChallengeDto[]>([]);
  public challengeToastsSubject: BehaviorSubject<Toast[]> = new BehaviorSubject<Toast[]>([]);

  constructor(
    protected injector: Injector,
    private bsLocaleService: BsLocaleService
  ) {
    super(injector, deviceListStore, Mappers.DeviceListDto.serializedName, AppEntityType.None);

    this.subscribe(this.challengesSubject.asObservable(), (challenges) => {
      this._mapChallengesToToasts(challenges);
    })
  }

  public onCloseChallenge(index: number): void {
    const challenges = this.challengesSubject.getValue();
    challenges.splice(index, 1);
    this.challengesSubject.next(challenges);
  }

  /* istanbul ignore next */
  public getRowActionButtons(): DatatableActionButton[] {
    return [
      ...this.getDefaultRowActionButtons(
        'orga-portal.devices.actions.edit-device',
        (row: DeviceDto) => `/devices/${ row.id }`,
        [AppPermissions.UpdateDevice],
        {
          module: 'management'
        }
      ),
      {
        tooltip: 'orga-portal.devices.actions.reboot-device',
        icon: faSync,
        onClick: (device: DeviceListDto) => this.modalService.showConfirmationModal(
          'orga-portal.devices.actions.reboot-device',
          'orga-portal.devices.descriptions.reboot-device',
          'error'
        )
          .then(() => this.store.dispatch(deviceListStore.actions.rebootOne({ id: device.id })))
          .catch(() => null),
        permissions: [AppPermissions.UpdateDevice],
        show: (row: DeviceDto) => Boolean(row.platform)
      },
      {
        tooltip: 'orga-portal.devices.actions.ping-device',
        icon: faHeart,
        onClick: (device: DeviceListDto) => this.store.dispatch(deviceListStore.actions.pingOne({ id: device.id })),
        permissions: [AppPermissions.UpdateDevice, AppPermissions.CreatePing],
        show: (row: DeviceDto) => Boolean(row.platform)
      }
    ];
  }

  public getDeleteEntityModel(): DeleteEntityModel {
    return {
      titleKey: 'orga-portal.devices.actions.delete-device',
      descriptionKey: 'orga-portal.devices.descriptions.delete-device',
      confirmKey: 'orga-portal.devices.actions.delete-device',
      deletePermission: AppPermissions.DeleteDevice
    };
  }

  protected async getActionButtons(): Promise<ActionButton[]> {
    return this.getDefaultActionButtons('orga-portal.devices.actions.create-device', AppPermissions.CreateDevice);
  }

  /* istanbul ignore next */
  protected onCreateOneSuccess(payload: PagedEntitiesXsStoreCreateOneSuccessPayload<DeviceDto, DeviceDto>): void {
    super.onCreateOneSuccess(payload);

    if (payload?.entity?.challenges) {
      for (const challenge of payload.entity.challenges ?? []) {
        this.challengesSubject.next([...this.challengesSubject.getValue(), challenge]);
      }
    }
  }

  private _mapChallengesToToasts(challenges: DeviceCodeChallengeDto[]): void {
    const next: Toast[] = [];
    challenges.forEach(challenge => {
      next.push(
        {
          title: 'orga-portal.devices.toasts.device-created',
          translationParams: { pin: challenge.pin, expiry: this._getFormattedDate(challenge.expiryDate, 'L LT') },
        })
    });
    this.challengeToastsSubject.next(next);
  }

  private _getFormattedDate(value: dayjs.Dayjs | Date | string, format: string): string {
    const date: dayjs.Dayjs = dayjs(value).local();

    return formatDate(date.toDate(), format, this.bsLocaleService.locale.getValue())
  }
}
