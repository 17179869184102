<nexnox-web-cardbox [headerActions]="headerActions"
                    [isAccordion]="true"
                    [isCollapsed]="false"
                    [loading]="loading"
                    [title]="'issues.subtitles.issue-detail' | translate"
                    [transparent]="true"
                    paddingClasses="px-3 pb-3">
  <div *ngIf="issue$ | async as issue" class="d-flex flex-column">
    <label class="form-label">{{ 'core-shared.shared.fields.name' | translate }}</label>

    <div class="d-flex flex-row pl-3 mb-3">
      <fa-icon [icon]="faInbox" class="mr-2"></fa-icon>
      <a (click)="openIssueSidebar()" class="btn-link cursor-pointer">
        <span>{{ issue.title }}</span>
      </a>
    </div>

    <label class="form-label">{{ 'core-shared.shared.fields.issue-type' | translate }}</label>
    <div class="d-flex flex-row pl-3">
      <span>{{ getTypeLabel(issue.issueType)| translate }}</span>
    </div>
  </div>
</nexnox-web-cardbox>
<nexnox-web-issue-detail-sidebar #issueDetailSidebar
                                 [issue$]="issue$">
</nexnox-web-issue-detail-sidebar>
