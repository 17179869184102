import {
  BaseXsStoreReducerTypes,
  PagedEntitiesXsStore,
  PagedEntitiesXsStoreActions,
  PagedEntitiesXsStoreEffects,
  PagedEntitiesXsStoreState
} from '@nexnox-web/core-store';
import {AppEntityType, MissionIssueListDto} from '@nexnox-web/core-shared';
import {Action} from '@ngrx/store';
import {Injectable, Injector, Type} from '@angular/core';
import {MissionIssueService} from "@nexnox-web/tech-portal/features/issues/src/lib/store";

// import {TechPortalIssueTemplateService} from '@nexnox-web/tech-portal-lib';

export interface MissionIssueListXsStoreActions extends PagedEntitiesXsStoreActions<MissionIssueListDto> {
}

export class MissionIssueListXsStore extends PagedEntitiesXsStore<MissionIssueListDto> {
  public actions: MissionIssueListXsStoreActions;

  protected createActions(label: string): MissionIssueListXsStoreActions {
    return {
      ...super.createActions(label)
    };
  }

  protected createReducerArray(
    initialState: PagedEntitiesXsStoreState<MissionIssueListDto>
  ): BaseXsStoreReducerTypes<PagedEntitiesXsStoreState<MissionIssueListDto>, MissionIssueListXsStoreActions>[] {
    return [
      ...super.createReducerArray(initialState)
    ];
  }

  protected createEffects(
    serviceType: Type<MissionIssueService>,
    entityType: AppEntityType,
    prepareEntity: (entity: MissionIssueListDto) => MissionIssueListDto,
    prepareModel: (entity: MissionIssueListDto, model: MissionIssueListDto) => MissionIssueListDto,
    sanitizeModel: (model: MissionIssueListDto, entity: MissionIssueListDto) => MissionIssueListDto,
    ...args
  ): Type<PagedEntitiesXsStoreEffects<MissionIssueListDto>> {
    const actions = this.actions;
    const selectors = this.selectors;

    @Injectable()
    class Effects extends PagedEntitiesXsStoreEffects<MissionIssueListDto> {
      protected service: MissionIssueService;

      constructor(
        protected injector: Injector
      ) {
        super(injector, actions, selectors, serviceType, entityType, prepareEntity, prepareModel, sanitizeModel, true);
      }

      protected createEffects(): void {
        super.createEffects();
      }

      protected actionCallback(action: Action, isError: boolean = false): void {
        super.actionCallback(action, isError);
      }
    }

    return Effects;
  }
}
