import {ChangeDetectionStrategy, Component, Injector} from '@angular/core';
import {CorePortalEntityOverviewBaseComponent, DatatableActionButton} from '@nexnox-web/core-portal';
import {AppEntityType, DeviceMetaListDto, Mappers} from '@nexnox-web/core-shared';
import {deviceMetadataListStore} from '../../store';

@Component({
  selector: 'nexnox-web-devices-device-metadata-list',
  templateUrl: './device-metadata-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeviceMetadataListComponent extends CorePortalEntityOverviewBaseComponent<DeviceMetaListDto> {
  public title = 'orga-portal.devices.subtitles.metadata-list';
  public idProperty = 'deviceMetadataId';
  public displayProperty = 'deviceMetadataId';
  public componentId = 'DeviceMetadataListComponent';
  public enableViews = false;
  public datatableConfigName = 'MetadataDefault';

  constructor(
    protected injector: Injector
  ) {
    super(injector, deviceMetadataListStore, Mappers.DeviceMetaListDto.serializedName, AppEntityType.None);
  }

  /* istanbul ignore next */
  public getRowActionButtons(): DatatableActionButton[] {
    return [];
  }
}
