<div class="row no-gutters w-100 align-items-center">
  <ng-container *ngIf="item.fields">
    <form [formGroup]="form" class="col-9 offset-1">
      <formly-form (modelChange)="onItemChange($event)"
                   [fields]="item.fields"
                   [form]="form"
                   [model]="item.model"
                   class="row w-100 align-items-center">
      </formly-form>
    </form>
  </ng-container>

  <div class="d-flex justify-content-end align-items-center flex-grow-1">
    {{ total | nexnoxWebLocaleStringPipe:2 }} €
  </div>
</div>

