import {NgModule} from '@angular/core';
import {FunctionContactListComponent, FunctionDetailComponent, FunctionListComponent} from './containers';
import {CorePortalFeatureFunctionEditComponent} from './components';
import {CorePortalEntityModule} from '@nexnox-web/core-portal';
import {CoreSharedModule} from '@nexnox-web/core-shared';
import {functionsServices} from './store';

@NgModule({
  declarations: [
    FunctionListComponent,
    FunctionDetailComponent,

    FunctionContactListComponent,

    CorePortalFeatureFunctionEditComponent
  ],
  imports: [
    CoreSharedModule,
    CorePortalEntityModule
  ],
  providers: [
    ...functionsServices
  ],
  exports: [
    CorePortalFeatureFunctionEditComponent
  ]
})
export class FunctionsDeclarationsModule {
}
