<p-sidebar #sidebarComponent
           (visibleChange)="onVisibleChange($event)"
           [transitionOptions]="transitionOptions"
           [visible]="visible$ | async"
           appendTo="body"
           position="right"
           styleClass="p-sidebar-xl gray-bg">
  <div *ngIf="(loading$ | async) === false else loadingTemplate" class="d-flex align-content-between flex-column h-100">

    <div *ngIf="visible$ | async">
      <nexnox-web-tabs [isTransparent]="true">
        <ng-template (selectTab)="changeTab(tabs[0])"
                     [heading]="translatedTab(tabs[0])"
                     [lazy]="true"
                     nexnoxWebTabTemplate>
        </ng-template>
        <ng-template (selectTab)="changeTab(tabs[1])"
                     [heading]="translatedTab(tabs[1])"
                     [lazy]="true"
                     nexnoxWebTabTemplate>
        </ng-template>
      </nexnox-web-tabs>
    </div>

    <ng-container
      *ngTemplateOutlet="currentTab === tabs[0] ? areaAssignmentTemplate : selectedContactsListTemplate">
    </ng-container>

    <div class="w-100 d-flex flex-shrink-1 gap-2 justify-content-end mt-auto mb-0 pt-2">
      <button (click)="onHide()"
              [icon]="faTimes | nexnoxWebFaIconStringPipe"
              [label]="'core-portal.core.general.cancel' | translate"
              class="p-button p-button-secondary"
              pButton
              type="button">
      </button>

      <button (click)="onAccept()"
              [disabled]="(canAssign$ | async) === false"
              [icon]="faCheck | nexnoxWebFaIconStringPipe"
              [label]="'tickets.actions.assign-to'  | translate"
              class="p-button p-button-primary"
              pButton
              type="button">
      </button>
    </div>
  </div>
</p-sidebar>

<ng-template #loadingTemplate>
  <nexnox-web-loading-icon class="d-flex w-100 h-100 align-items-center justify-content-center flex-column p-5"
                           size="5x">
  </nexnox-web-loading-icon>
</ng-template>

<ng-template #areaAssignmentTemplate>
  <nexnox-web-master-data-contacts-contact-area-edit
    (modelChange)="onModelChange($event)"
    [(model)]="model"
    [readonly]="false"
    [title]="'core-portal.master-data.contact.tooltip.bulk-create-area-assignment' | translate"
  >
  </nexnox-web-master-data-contacts-contact-area-edit>
  <small class="form-text text-muted mx-2">{{ description$ | async }}</small>
</ng-template>

<ng-template #selectedContactsListTemplate>
  <div *ngIf="paging$ | async; else loadingTemplate" class="overflow-y-auto">
    <div class="sticky-input-wrapper sticky-top d-sm-block d-none mb-3">
      <div class="p-input-icon-left w-100">
        <i class="pi pi-search"></i>
        <input (ngModelChange)="onFilterMenu()"
               [(ngModel)]="searchValue"
               [placeholder]="'core-portal.core.general.placeholder.search-by-display-name-or-email' | translate"
               class="w-100"
               pInputText
               type="text"
        />
      </div>
    </div>
    <nexnox-web-basic-table [config]="contactListTableConfig"
                            [data]="contacts$ | async">
    </nexnox-web-basic-table>
    <div class="pt-3">
      <nexnox-web-content-container-infinite-scroll (loadMore)="loadMore()"
                                                    *ngIf="paging$ | async as paging"
                                                    [disabled]="(loading$ | async)"
                                                    [finished]="paging.pageNumber >= paging.totalPages"
                                                    [loading]="(appendLoading$ | async)">
      </nexnox-web-content-container-infinite-scroll>
    </div>
  </div>
</ng-template>
