import {ChangeDetectionStrategy, Component, Injector, ViewChild} from '@angular/core';
import {
  ActionButton,
  CorePortalEntityOverviewBaseComponent,
  DatatableActionButton,
  DeleteEntityModel
} from '@nexnox-web/core-portal';
import {
  AppEntityType,
  AppPermissions,
  ControllerOperationId,
  Mappers,
  ResourceManufacturerDto,
  ResourceManufacturerListDto,
  ResourceModelDto
} from '@nexnox-web/core-shared';
import {manufacturerListStore} from '../../store/stores';
import {faCompressAlt} from "@fortawesome/free-solid-svg-icons";
import {MergeManufacturerSidebarComponent} from "../../sidebars";
import {BehaviorSubject} from "rxjs";

@Component({
  selector: 'nexnox-web-settings-manufacturer-list',
  templateUrl: './manufacturer-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ManufacturerListComponent extends CorePortalEntityOverviewBaseComponent<ResourceManufacturerListDto> {
  @ViewChild('mergeManufacturerSidebar', { static: true }) public mergeManufacturerSidebar: MergeManufacturerSidebarComponent;

  public manufacturerToMergeSubject: BehaviorSubject<ResourceModelDto> = new BehaviorSubject<ResourceModelDto>({});

  public title = 'core-portal.settings.subtitles.manufacturer-list';
  public createTitle = 'core-portal.settings.actions.create-manufacturer';
  public idProperty = 'resourceManufacturerId';
  public displayProperty = 'name';
  public datatableConfigName = 'SettingsManufacturerDefault';
  public componentId = 'ManufacturerListComponent';
  public enableViews = true;
  public pageOperation = ControllerOperationId.ResourceManufacturerControllerId;

  constructor(
    protected injector: Injector
  ) {
    super(injector, manufacturerListStore, Mappers.ResourceManufacturerListDto.serializedName, AppEntityType.None);
  }

  public mergeManufacturer(newManufacturer: ResourceManufacturerDto): void {
    if (this.manufacturerToMergeSubject.getValue()) {
      this.store.dispatch(manufacturerListStore.actions.mergeOne({
        manufacturer: this.manufacturerToMergeSubject.getValue(),
        newManufacturerId: newManufacturer.resourceManufacturerId
      }));
    }
  }

  public getRowActionButtons(): DatatableActionButton[] {
    return [
      ...this.getDefaultRowActionButtons(
        'core-portal.settings.actions.edit-manufacturer',
        (row: ResourceManufacturerListDto) => `manufacturers/${ row.resourceManufacturerId }`,
        [AppPermissions.UpdateResourceManufacturer],
        {
          module: 'settings'
        }
      ),
      {
        tooltip: 'core-portal.settings.actions.merge-manufacturer',
        permissions: [AppPermissions.MergeResourceModel],
        icon: faCompressAlt,
        onClick: (row) => this.onMergeAction(row),
        show: () => this.canDelete
      },
    ];
  }

  public getDeleteEntityModel(): DeleteEntityModel {
    return {
      titleKey: 'core-portal.settings.actions.delete-manufacturer',
      descriptionKey: 'core-portal.settings.descriptions.delete-manufacturer',
      confirmKey: 'core-portal.settings.actions.delete-manufacturer',
      deletePermission: AppPermissions.DeleteResourceManufacturer
    };
  }

  protected async getActionButtons(): Promise<ActionButton[]> {
    return this.getDefaultActionButtons(
      'core-portal.settings.actions.create-manufacturer',
      AppPermissions.CreateResourceManufacturer
    );
  }

  private async onMergeAction(row: any): Promise<void> {
    if (row?.resourceManufacturerId) {
      this.manufacturerToMergeSubject.next(row);
      this.mergeManufacturerSidebar.onShow();
    }
  }
}
